import React, { useState } from "react";
import {
  displayMessageStatusIcon,
  formatText,
  getToolTipPosition,
  sanitizedMessage,
} from "../../Utils/chat/chatFunctions";
import {
  chatMessageDayandDateConvert,
  formatChatHoverDateTime,
} from "../../Utils/DateFunctions";
import { Document, Page, pdfjs } from "react-pdf";
import ChatDate from "../chat-date/ChatDate";
import TextMessage from "../text-message/TextMessage";
import ChatImage from "../chat-image/ChatImage";
import ChatVideo from "../chat-video/ChatVideo";
import ChatPdf from "../chat-pdf/ChatPdf";
import ChatWord from "../chat-word/ChatWord";
import ChatExcel from "../chat-excel/ChatExcel";
import ChatAudio from "../chat-audio/ChatAudio";
import ChatPresentation from "../chat-presentation/ChatPresentation";
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ChatMessageContainer = ({
  index,
  val,
  newMessages,
  chatDetails,
  hasMoreMessages,
  pageForChatMessages,
}) => {
  const messageKey = val.id;
  const dateKey = `date-${messageKey}`;
  return (
    <React.Fragment key={messageKey}>
      {index == 0 && pageForChatMessages > 0
        ? hasMoreMessages && (
            <div className="date-and-time">
              <div className="txtwrp">
                <div uk-spinner=""></div>
              </div>
            </div>
          )
        : null}
      {index == 0 && (
        <div className="date-and-time" key={dateKey}>
          <div className="txtwrp">
            <p>{chatMessageDayandDateConvert(val.timestamp)}</p>
          </div>
        </div>
      )}

      {index !== 0
        ? chatMessageDayandDateConvert(newMessages[index - 1].timestamp) !==
            chatMessageDayandDateConvert(val?.timestamp) && (
            // here we can display messsage dates based on users messages
            <ChatDate data={val} />
          )
        : null}

      <div
        className={
          val.isSent === false || val.isSent === "false"
            ? "rec-msg"
            : "send-msg"
        }
        key={messageKey}
      >
        {/* for text message */}
        {(val.messageType === "text" || val.messageType === "TEXT") && (
          <TextMessage data={val} />
        )}

        {/*for image  */}
        {((val?.messageType === "media" &&
          val?.filePath !== null &&
          (chatDetails.channel === "INSTAGRAM" ||
            val?.filePath?.includes("svg+xml") ||
            val?.filePath?.includes("png") ||
            val?.filePath?.includes("jpeg") ||
            val?.filePath?.includes("jpg"))) ||
          (val?.messageType === "image" &&
            val?.mimeType?.includes("image"))) && <ChatImage data={val} />}

        {/*for video  */}
        {((val?.messageType === "media" &&
          val?.filePath !== null &&
          val?.filePath?.includes("mp4")) ||
          (val?.messageType === "video" &&
            val?.mimeType?.includes("video"))) && <ChatVideo data={val} />}

        {/* for pdf */}
        {((val?.messageType === "media" &&
          val?.filePath !== null &&
          val?.filePath?.includes("pdf")) ||
          (val?.messageType === "document" &&
            val?.mimeType?.includes("pdf"))) && <ChatPdf data={val} />}

        {/* for word */}
        {((val?.messageType === "media" &&
          val?.filePath !== null &&
          (val?.filePath?.includes(
            "officedocument.wordprocessingml.document"
          ) ||
            val?.filePath?.includes(".msword"))) ||
          (val?.messageType === "document" &&
            val?.filePath !== null &&
            val?.filePath?.includes("wordprocessingml.document"))) && (
          <ChatWord data={val} />
        )}

        {/* for excel file  */}
        {((val?.messageType === "media" &&
          val?.filePath !== null &&
          (val?.filePath?.includes("ms-excel") ||
            val?.filePath?.includes(".csv") ||
            val?.filePath?.includes("spreadsheetml.sheet"))) ||
          (val?.messageType === "document" &&
            val?.filePath !== null &&
            val?.filePath?.includes("octet-stream"))) && (
          <ChatExcel data={val} />
        )}

        {/* for audio file  */}
        {((val?.messageType === "media" &&
          val?.filePath !== null &&
          val?.filePath?.includes("ogg")) ||
          (val?.messageType === "audio" && val?.mimeType?.includes("audio")) ||
          (val?.messageType === "media" && val?.filePath?.includes("mp3"))) && (
          <ChatAudio data={val} />
        )}

        {/* for presentation  */}
        {((val?.messageType === "media" &&
          val?.filePath !== null &&
          val?.filePath?.includes("presentation")) ||
          (val?.messageType === "document" &&
            val?.mimeType?.includes("presentation"))) && (
          <ChatPresentation data={val} />
        )}

        {val?.messageType === "location" && (
          <div
            className="txtdiv"
            uk-tooltip={`title: ${formatChatHoverDateTime(
              val.timestamp
            )}; pos: ${getToolTipPosition(val.isSent)}`}
          >
            <div>
              <iframe
                width="100%"
                height="300"
                src={`https://www.google.com/maps?q=${val.latitude},${val.longitude}&output=embed`}
              ></iframe>
            </div>
            {/* {displayMessageStatusIcon(val.messageStatus)} */}
          </div>
        )}

        {val?.messageType === "contacts" && (
          <div
            className="txtdiv"
            uk-tooltip={`title: ${formatChatHoverDateTime(
              val.timestamp
            )}; pos: ${getToolTipPosition(val.isSent)}`}
          >
            <div className="contactCardMain">
              {val?.contacts?.map((item, ind) => {
                return (
                  <div key={ind} className="contactCard">
                    <div>{item.name.formatted_name}</div>
                    {item?.phones?.map((phone, num) => {
                      return <div key={num}>{phone.phone}</div>;
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {val?.messageType === "list" && (
          <div
            className="txtdiv"
            uk-tooltip={`title: ${formatChatHoverDateTime(
              val.timestamp
            )}; pos: ${getToolTipPosition(val.isSent)}`}
          >
            {displayMessageStatusIcon(val.messageStatus)}
            <div className="contactCardMain">
              {val.headerValue !== null && <div>{val.headerValue}</div>}
              <br />

              {val.list.length > 0 &&
                val.list.map((item, ind) => {
                  return (
                    <div key={ind}>
                      <div>{item.title}</div>
                      <div>
                        {item.rows.map((newItem, indee) => {
                          return <div key={indee}>{newItem.description}</div>;
                        })}
                      </div>
                      <br />
                    </div>
                  );
                })}
              {val.footerValue !== null && <div>{val.footerValue}</div>}
            </div>
          </div>
        )}
        {val?.messageType === "buttons" && (
          <div
            className="txtdiv"
            uk-tooltip={`title: ${formatChatHoverDateTime(
              val.timestamp
            )}; pos: ${getToolTipPosition(val.isSent)}`}
          >
            {displayMessageStatusIcon(val.messageStatus)}
            <div className="contactCardMain">
              {val.headerValue !== null && <div>{val.headerValue}</div>}

              {val.buttons.length > 0 &&
                val.buttons.map((item, ind) => {
                  return (
                    <buttons className="chatbtn" key={ind}>
                      {item.title}
                    </buttons>
                  );
                })}
              {val.footerValue !== null && <div>{val.footerValue}</div>}
            </div>
          </div>
        )}
        {val?.messageType === "button" && (
          <div
            className="txtdiv"
            uk-tooltip={`title: ${formatChatHoverDateTime(
              val.timestamp
            )}; pos: ${getToolTipPosition(val.isSent)}`}
          >
            {(val.isSent == "false" || val.isSent == false) &&
              (val.body === null ? (
                <pre>{val.body} </pre>
              ) : (
                <pre
                  dangerouslySetInnerHTML={sanitizedMessage(
                    formatText(val.body)
                  )}
                ></pre>
              ))}
            {/* {displayMessageStatusIcon(val.messageStatus)} */}
            {(val.isSent == "true" || val.isSent == true) &&
              (val.body === null ? (
                <p>{val.body} </p>
              ) : (
                <p
                  dangerouslySetInnerHTML={sanitizedMessage(
                    formatText(val.body)
                  )}
                ></p>
              ))}
          </div>
        )}

        {val?.messageType === "interactive" && (
          <div
            className="txtdiv"
            uk-tooltip={`title: ${formatChatHoverDateTime(
              val.timestamp
            )}; pos: ${getToolTipPosition(val.isSent)}`}
          >
            {val.body === null ? (
              " "
            ) : (
              <div className={val.filePath === null ? "" : "txtAndImgDiv"}>
                {val.filePath !== null && <img src={val.filePath} alt="" />}
                <pre>
                  {(() => {
                    let message = "";

                    try {
                      // Parse the JSON body
                      const parsedBody = JSON.parse(val.body);

                      // Check if 'flow_token' exists
                      if (parsedBody.hasOwnProperty("flow_token")) {
                        message = "Form submitted successfully";
                      } else {
                        message = JSON.stringify(parsedBody);
                      }
                    } catch (error) {
                      // If parsing fails, show the body as it is
                      message = val.body;
                    }

                    return formatText(message);
                  })()}
                </pre>
              </div>
            )}
          </div>
        )}
        {/* ***************************************** for received messages ************************************  */}
        {/* <div ref={index === newMessages.length - 1 ? bottomRef : null}></div> */}
      </div>
    </React.Fragment>
  );
};

export default ChatMessageContainer;
