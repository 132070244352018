import React, { useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import {
  Calendar,
  ChevronDown,
  // Alarm,
  // Calendar,
  // ChevronDown,
  // Timer,
  Download,
} from "@carbon/icons-react";
import moment from "moment/moment";
import UIkit from "uikit";
import { useEffect } from "react";
import { apiGet } from "../../Utils/apiServices";
import axios from "axios";
import { useAuth } from "../../Context/AuthProvider";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";
import CalendarDate from "../../components/Calendar/CalendarDate";
import whatsappIcon from "../../assets/images/wa.png";
import DoughnutChart from "../../components/Graph/DoughnutChart";
import DynamicDoughnutChart from "../../components/Graph/DynamicDoughChart";
import { generateColors } from "../../Utils/GraphFunctions";
import { encryptedValue } from "../../Utils/RouteHashConversion";
import { useMemo } from "react";
import Pagination from "../../components/Pagination/Pagination";
import { hasPermissions } from "../../Utils/RoutePermissions";
const baseUrl = process.env.REACT_APP_BASEURL;

const LiveChatTags = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);

  const CurrentCompanyName = CompanyName(CompanyHtml);
  const { auth } = useAuth();
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [analytics, setAnalytics] = useState();
  const [channel, setChannel] = useState("ALL");
  const [selectedDateFilter, setSelectedDateFilter] = useState("This month");
  const [isApiCall, setIsApiCall] = useState(false);
  const [RouteStats, setRouteStats] = useState(null);
  const [size, setSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  // const [currentPage, setCurrentPage] = useState(0);
  const [isSelected, setIsSelected] = useState(false);

  const [tagGraphData, setTagGraphData] = useState({
    totalCount: [],
    lables: [],
    data: [],
    isCalled: false,
  });

  const [liveChatTagsData, setLiveChatTagsData] = useState({
    labels: [],
    graphData: [],
    tagPerformances: [],
    alldata: [],
    sum: 0,
    isCalled: false,
  });

  const colors = generateColors(liveChatTagsData?.labels.length);

  const pageTabs = [
    {
      route: "/analytics",
      name: "Overview",
      permissions: ["FINANCE-DATA-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/live-chat-analytics",
      name: "Live Chat",
      permissions: ["ANALYTICS-MANAGER", "LIVE-CHAT-MANAGER"],
      active: true,
      allPermissionsMandatory: true,
    },
    {
      route: "/chatbot-analytics",
      name: "Chatbot",
      permissions: ["CHATBOT-SUPERVISOR", "CHAT-BOT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/statistics",
      name: "Statistics",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/package-mau",
      name: "Package",
      permissions: ["ANALYTICS-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/orders",
      name: "Orders",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/transfer-logs",
      name: "Transfer Logs",
      permissions: [],
      active: false,
      allPermissionsMandatory: true,
    },
  ];
  const navigate = useNavigate();

  const clearAllStates = () => {
    setLiveChatTagsData({
      labels: [],
      graphData: [],
      tagPerformances: [],
      alldata: [],
      sum: 0,
      isCalled: false,
    });
    setTagGraphData({
      totalCount: [],
      lables: [],
      data: [],
      isCalled: false,
    });
  };
  const getLiveChatTags = (page) => {
    apiGet(
      // `/routing/stats?page=${page}&size=${size}`,
      `/detail-analytics/liveChatTags/${STARTDATE}/${ENDDATE}`,
      onSuccessLiveChatTags,
      onFailureLiveChatTags
    );
  };

  const onSuccessLiveChatTags = (response) => {
    setIsApiCall(false);
    setIsSelected(false);
    // console.log(response);
    let totalTagCount = response.data.tagAnalytics.reduce(
      (sum, item) => sum + item.tagCount,
      0
    );
    let graphLables = [];
    response.data.tagAnalytics.map((val) => {
      return graphLables.push(val.tagName);
    });

    let data = [];
    response.data.tagAnalytics.map((val) => {
      return data.push(val.tagCount);
    });

    setTagGraphData({
      totalCount: totalTagCount,
      lables: graphLables,
      data: data,
      isCalled: true,
    });

    var graphlabels = [];
    var graphData = [];
    var newdata = response.data.tagAnalytics;
    newdata.map((val) => {
      graphlabels.push(val.tagName);
      graphData.push(val.tagCount);
    });

    // console.log(graphlabels);
    // console.log(graphData);

    let sum = graphData?.reduce((accumulator, currentValue) => {
      return parseInt(accumulator) + parseInt(currentValue);
    }, 0);
    // console.log(sum);
    // console.log(graphlabels);
    setCurrentPage(1);
    setLiveChatTagsData({
      labels: graphlabels,
      graphData: graphData,
      tagPerformances: response.data.tagPerformances,
      alldata: response.data.tagAnalytics,
      sum: sum,
      isCalled: true,
    });

    // setRouteStats(response?.data);
    // setPageCount(response?.data?.routeRecords?.totalPages);
  };

  const onFailureLiveChatTags = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (isSelected === true) {
      setIsApiCall(true);
    }
  }, [isSelected]);

  useEffect(() => {
    getLiveChatTags(0);
  }, []);
  useEffect(() => {
    if (isApiCall == true) {
      if (selectedDateFilter === "Custom range") {
        if (isSelected === true) {
          getLiveChatTags(0);
        }
      } else {
        getLiveChatTags(0);
      }
    }
  }, [isApiCall]);

  //   useEffect(() => {
  //     getLiveChatTags(0);
  //   }, [size]);
  const filterDropdown = [
    "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];

  const channels = [
    {
      icon: null,
      name: "All Channels",
      apiSendName: "ALL",
      disabled: false,
    },
    // {
    //   icon: whatsAppImg,
    //   name: "WhatsApp",
    //   apiSendName: "WHATSAPP",
    //   disabled: false,
    // },
    // {
    //   icon: messengerIcon,
    //   name: "Facebook",
    //   apiSendName: "FACEBOOK",
    //   disabled: true,
    // },
    // {
    //   icon: instaIcon,
    //   name: "Instagram",
    //   apiSendName: "INSTAGRAM",
    //   disabled: true,
    // },
  ];

  // const handleDatePickerCloseRouting = () => {
  //   if (selectedDateFilter === "Custom range") {
  //     setIsSelected(true);
  //   }
  //   UIkit.drop("#datepickerdrop").hide(true);
  // };

  const onChangeRouting = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
  };

  const handleDateFilter = (filter) => {
    setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    if (filter !== "Custom range") {
      setIsApiCall(true);
    }
  };

  const handleDatePickerClose = () => {
    if (selectedDateFilter === "Custom range") {
      setIsSelected(true);
    }
    UIkit.drop("#datepickerdrop").hide(true);
  };

  const onChange = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
  };

  // const handlePageChange = (data) => {
  //   getLiveChatTags(data.selected);
  //   setCurrentPage(data.selected);
  // };

  // useEffect(() => {
  //   getLiveChatTags(0);
  // }, [size]);

  const exportData = async () => {
    try {
      const response = await axios.get(baseUrl + `/routing/export/stats`, {
        responseType: "arraybuffer", // Ensure the response is treated as binary data
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Routing.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return liveChatTagsData?.tagPerformances?.slice(
      firstPageIndex,
      lastPageIndex
    );
  }, [currentPage]);

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="boradcastWrp">
        <div className="boradcastTabbing">
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #newAnalyticsTab"
          >
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                <li
                  onClick={() => {
                    navigate(val.route, { replace: true });
                  }}
                  className={val.active === true ? "uk-active" : ""}
                  key={index}
                >
                  <a>{val.name}</a>
                </li>
              ) : null;
            })}
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="newAnalyticsTab">
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                val.name === "Live Chat" ? (
                  <li key={index}>
                    <div className="overviewContent">
                      <div className="uk-container">
                        <div className="uk-grid uk-flex-middle" uk-grid="">
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsTabWrp"
                              style={{ margin: "0 0 20px" }}
                            >
                              <div className="whatappTabBtn">
                                <ul
                                  className="uk-subnav uk-subnav-pill"
                                  uk-switcher="connect: .whatsappTabBtn"
                                >
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-analytics", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Overview</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-agent", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Agents</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-routing", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Routes</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-voice-of-customer", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Voice of customer</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-channels", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a href="#">Channels</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-tags", {
                                        replace: true,
                                      });
                                    }}
                                    className="uk-active"
                                  >
                                    <a href="#">Tags</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsWhatsappContent"
                              style={{ marginTop: "16px" }}
                            >
                              <ul className="uk-switcher uk-margin whatsappTabBtn">
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li>
                                  <div
                                    className="overviewContent uk-padding-remove"
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <div className="uk-container">
                                      <div className="overviewTopDropdown">
                                        <div
                                          className="uk-grid uk-flex-middle"
                                          uk-grid=""
                                        >
                                          <div className="uk-width-1-2">
                                            <div className="pageHeading uk-margin-remove-bottom">
                                              <h3>Live Chat Tags</h3>
                                            </div>
                                          </div>
                                          <div className="uk-width-1-2 btnSection">
                                            <div className="agentFilterWrp">
                                              {/* <div className="allChannelDropDown">
                                              <div className="uk-inline tableFilterDropdown broadcastChannelFilter">
                                                <button
                                                  type="button"
                                                  className="dropdownBtn"
                                                >
                                                  All Active Channels{" "}
                                                  <ChevronDown />
                                                </button>
                                                <div
                                                  uk-dropdown="mode: click"
                                                  className="dropdownNav"
                                                >
                                                  <span className="dropdownHeading">
                                                    Channels
                                                  </span>
                                                  <ul>
                                                    {channels.map(
                                                      (val, index) => {
                                                        return (
                                                          <li
                                                            key={index}
                                                            className={
                                                              channel ===
                                                              val.apiSendName
                                                                ? "activeBtn"
                                                                : ""
                                                            }
                                                            onClick={() => {
                                                              setChannel(
                                                                val.apiSendName
                                                              );
                                                            }}
                                                          >
                                                            <button
                                                              type="button"
                                                              disabled={
                                                                val.disabled ===
                                                                true
                                                                  ? true
                                                                  : false
                                                              }
                                                              className={
                                                                val.disabled ===
                                                                true
                                                                  ? "opactityBtn"
                                                                  : ""
                                                              }
                                                            >
                                                              {index !== 0 && (
                                                                <img
                                                                  src={val.icon}
                                                                  alt=""
                                                                />
                                                              )}
                                                              {val.name}
                                                            </button>
                                                          </li>
                                                        );
                                                      }
                                                    )}
                                                  </ul>
                                                </div>
                                              </div>
                                            </div> */}
                                              <div className="uk-inline">
                                                <button className="calenderBtn rangeBtn">
                                                  <Calendar />{" "}
                                                  {selectedDateFilter}
                                                  <span className="uk-inline">
                                                    <ChevronDown />
                                                  </span>
                                                </button>
                                                <div
                                                  uk-dropdown="mode: click"
                                                  className="calendarDropdown"
                                                  id="dateDropdownSection"
                                                >
                                                  <div className="dropDownHeading">
                                                    <span>Select a range</span>
                                                  </div>
                                                  <ul>
                                                    {filterDropdown?.map(
                                                      (val, index) => {
                                                        const isLastItem =
                                                          filterDropdown.length -
                                                            1 ===
                                                          index;
                                                        const isSecondLastItem =
                                                          filterDropdown.length -
                                                            2 ===
                                                          index;

                                                        return (
                                                          <React.Fragment
                                                            key={index}
                                                          >
                                                            <li
                                                              className={
                                                                selectedDateFilter ===
                                                                val
                                                                  ? "activeBtn"
                                                                  : ""
                                                              }
                                                              onClick={() => {
                                                                if (
                                                                  val !==
                                                                  "Custom range"
                                                                ) {
                                                                  clearAllStates();
                                                                }
                                                                handleDateFilter(
                                                                  val
                                                                );
                                                              }}
                                                            >
                                                              {isLastItem ? (
                                                                <div className="uk-inline uk-text-left">
                                                                  <button
                                                                    className="rangeBtn"
                                                                    type="button"
                                                                  >
                                                                    <span className="rangeBtnCircle"></span>{" "}
                                                                    Custom range
                                                                  </button>
                                                                  <div
                                                                    id="datepickerdrop"
                                                                    uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                                    className="date-picker-container"
                                                                  >
                                                                    <CalendarDate
                                                                      onChange={
                                                                        onChange
                                                                      }
                                                                    />
                                                                    <div className="date-picker-btn-container uk-text-right">
                                                                      <button
                                                                        className="date-selection-btn"
                                                                        onClick={
                                                                          handleDatePickerClose
                                                                        }
                                                                      >
                                                                        Ok
                                                                      </button>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                <button
                                                                  type="button"
                                                                  className="rangeBtn"
                                                                >
                                                                  <span className="rangeBtnCircle"></span>{" "}
                                                                  {val}
                                                                </button>
                                                              )}
                                                            </li>
                                                            {isSecondLastItem && (
                                                              <hr
                                                                style={{
                                                                  margin:
                                                                    "5px 0",
                                                                }}
                                                              />
                                                            )}
                                                          </React.Fragment>
                                                        );
                                                      }
                                                    )}
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="uk-width-1-1 uk-margin-remove">
                                            <div className="overviewMainContent">
                                              <div className="mainBox">
                                                <div className="boxHeading">
                                                  <span>
                                                    Top most used tags in live
                                                    chats
                                                  </span>
                                                </div>
                                                <div className="boxContent">
                                                  <div
                                                    className="uk-grid"
                                                    uk-grid=""
                                                  >
                                                    <div className="uk-width-1-2">
                                                      <div className="chartWrp fullDonutSize">
                                                        {/* {liveChatTagsData.isCalled ===
                                                        true && */}
                                                        {tagGraphData?.totalCount >
                                                        0 ? (
                                                          <DynamicDoughnutChart
                                                            degree={360}
                                                            graphData={
                                                              tagGraphData?.data
                                                            }
                                                            graphLabels={
                                                              tagGraphData?.lables
                                                            }
                                                            colors={colors}
                                                          />
                                                        ) : (
                                                          <DoughnutChart
                                                            degree={360}
                                                            backgroudColor={[
                                                              "#B4B4B4",
                                                            ]}
                                                            borderColor={[
                                                              "#B4B4B4",
                                                            ]}
                                                            graphData={[1]}
                                                            graphlabels={["-"]}
                                                          />
                                                        )}
                                                        <div
                                                          className="chartData"
                                                          style={{ top: "36%" }}
                                                        >
                                                          <h3>
                                                            {
                                                              liveChatTagsData?.sum
                                                            }
                                                          </h3>
                                                          <p>
                                                            Total Tags used in
                                                            Live Chats
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-1-2">
                                                      <div className="boxTableWrp">
                                                        <table className="uk-table uk-table-striped">
                                                          <thead>
                                                            <tr>
                                                              <th>RANKING</th>
                                                              <th className="uk-table-expand">
                                                                TAG
                                                              </th>
                                                              <th className="uk-table-shrink">
                                                                COUNT
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {liveChatTagsData
                                                              ?.alldata.length >
                                                            0 ? (
                                                              liveChatTagsData?.alldata.map(
                                                                (
                                                                  val,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <tr
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      <td>
                                                                        {index +
                                                                          1}
                                                                      </td>
                                                                      <td>
                                                                        <span
                                                                          style={{
                                                                            backgroundColor:
                                                                              colors[
                                                                                index
                                                                              ],
                                                                          }}
                                                                        ></span>
                                                                        {
                                                                          val.tagName
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {
                                                                          val.tagCount
                                                                        }
                                                                      </td>
                                                                    </tr>
                                                                  );
                                                                }
                                                              )
                                                            ) : (
                                                              <tr>
                                                                <td
                                                                  colSpan={8}
                                                                  className="dataNotFound"
                                                                >
                                                                  {liveChatTagsData.isCalled ===
                                                                  false ? (
                                                                    <div uk-spinner=""></div>
                                                                  ) : (
                                                                    "Data Not Found"
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            )}
                                                            {/* <tr>
                                                            <td>1</td>
                                                            <td>
                                                              <span className="blueDot"></span>
                                                              Order
                                                            </td>
                                                            <td>871</td>
                                                          </tr>
                                                          <tr>
                                                            <td>1</td>
                                                            <td>
                                                              <span className="lightGreen"></span>
                                                              Order
                                                            </td>
                                                            <td>871</td>
                                                          </tr>
                                                          <tr>
                                                            <td>1</td>
                                                            <td>
                                                              <span className="darkOrange"></span>
                                                              Order
                                                            </td>
                                                            <td>871</td>
                                                          </tr>
                                                          <tr>
                                                            <td>1</td>
                                                            <td>
                                                              <span className="darkDrayDot"></span>
                                                              Order
                                                            </td>
                                                            <td>871</td>
                                                          </tr>
                                                          <tr>
                                                            <td>1</td>
                                                            <td>
                                                              <span className="darkBlueDot"></span>
                                                              Order
                                                            </td>
                                                            <td>871</td>
                                                          </tr>
                                                          <tr>
                                                            <td>1</td>
                                                            <td>
                                                              <span className="yellowDot"></span>
                                                              Order
                                                            </td>
                                                            <td>871</td>
                                                          </tr>
                                                          <tr>
                                                            <td>1</td>
                                                            <td>
                                                              <span className="lightBlueDot"></span>
                                                              Order
                                                            </td>
                                                            <td>871</td>
                                                          </tr>
                                                          <tr>
                                                            <td>1</td>
                                                            <td>
                                                              <span className="grayDot"></span>
                                                              Order
                                                            </td>
                                                            <td>871</td>
                                                          </tr> */}
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="mainBox">
                                                <div className="boxHeading">
                                                  <span>
                                                    Performance by Tag
                                                  </span>
                                                </div>
                                                <div className="boxFullTable tableCenter">
                                                  <table className="uk-table uk-table-striped">
                                                    <thead>
                                                      <tr>
                                                        <th className="uk-table-expand">
                                                          TAG
                                                        </th>
                                                        <th>TOTAL</th>
                                                        <th>OPEN</th>
                                                        <th>WAITING</th>
                                                        <th>SOLVED</th>
                                                        <th>CLOSED</th>
                                                        <th>UNASSIGNED</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {liveChatTagsData
                                                        ?.tagPerformances
                                                        .length > 0 ? (
                                                        currentTableData?.map(
                                                          (val, index) => {
                                                            return (
                                                              <tr key={index}>
                                                                <td className="channelName">
                                                                  <Link
                                                                    to={`/live-chat-tags-detail/${encryptedValue(
                                                                      val.tagName
                                                                    )}/${
                                                                      val.tagId
                                                                    }/${STARTDATE}/${ENDDATE}`}
                                                                  >
                                                                    {
                                                                      val.tagName
                                                                    }
                                                                  </Link>
                                                                </td>
                                                                <td>
                                                                  {" "}
                                                                  {
                                                                    val.totalChat
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {" "}
                                                                  {val.openChat}
                                                                </td>
                                                                <td>
                                                                  {val.waiting}
                                                                </td>
                                                                <td>
                                                                  {val.solved}
                                                                </td>
                                                                <td>
                                                                  {val.closed}
                                                                </td>
                                                                <td>
                                                                  {
                                                                    val.unassigned
                                                                  }
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )
                                                      ) : (
                                                        <tr>
                                                          <td
                                                            colSpan={8}
                                                            className="dataNotFound"
                                                          >
                                                            {liveChatTagsData.isCalled ===
                                                            false ? (
                                                              <div uk-spinner=""></div>
                                                            ) : (
                                                              "Data Not Found"
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )}
                                                      {/* <tr>
                                                      <td className="channelName">
                                                        <Link to="/live-chat-tags-detail">
                                                          Eid Campaign
                                                        </Link>
                                                      </td>
                                                      <td>3,432</td>
                                                      <td>1</td>
                                                      <td>1</td>
                                                      <td>3425</td>
                                                      <td>3425</td>
                                                      <td>5</td>
                                                    </tr> */}
                                                    </tbody>
                                                  </table>
                                                  {liveChatTagsData
                                                    ?.tagPerformances.length >
                                                    0 && (
                                                    <div
                                                      style={{
                                                        marginRight: "50px",
                                                      }}
                                                    >
                                                      <Pagination
                                                        className="pagination-bar"
                                                        currentPage={
                                                          currentPage
                                                        }
                                                        totalCount={
                                                          liveChatTagsData
                                                            ?.tagPerformances
                                                            .length
                                                        }
                                                        pageSize={PageSize}
                                                        onPageChange={(page) =>
                                                          setCurrentPage(page)
                                                        }
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li key={index}></li>
                )
              ) : null;
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default LiveChatTags;
