import React from "react";
import { useState } from "react";
import { apiPost } from "../../../../Utils/apiServices";
import { toast } from "react-toastify";

const AccountInformationModal = ({
  closeModal,
  generalSettings,
  setApiCalled,
}) => {
  const [data, setData] = useState({
    organizationName: generalSettings?.organizationName,
    companyIndustry: generalSettings?.companyIndustry,
  });
  const [loader, setLoader] = useState(false);

  const updateGeneralSettings = () => {
    var newData = {
      organizationName: data.organizationName,
      companyIndustry: data.companyIndustry,
      companyProfilePic: generalSettings.companyProfilePic,
      contactPerson: generalSettings.contactPerson,
      contactNumber: generalSettings.contactNumber,
      contactEmail: generalSettings.contactEmail,
      logoUrl: generalSettings.logoUrl,
      fileId: generalSettings.fileId,
    };
    setLoader(true);
    if (data.organizationName !== "" && data.companyIndustry !== "") {
      apiPost(`/settings/general`, onSucces, onFailure, newData);
    } else {
      setLoader(false);
    }
  };
  const onSucces = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setApiCalled(true);
        setLoader(false);
        toast.success("General settings added successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1000);

      setTimeout(() => {
        closeModal();
      }, 2000);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  const onFailure = (error) => {
    console.log(error);
  };

  const companyIndustryOptions = [
    { option: "Technology" },
    { option: "Healthcare" },
    { option: "Finance" },
    { option: "Manufacturing" },
    { option: "Retail" },
    { option: "Education" },
    { option: "Real Estate" },
    { option: "Automotive" },
    { option: "Hospitality" },
    { option: "Entertainment" },
    { option: "Food and Beverage" },
    { option: "E-commerce" },
    { option: "Energy and Utilities" },
    { option: "Agriculture" },
    { option: "Transportation" },
    { option: "Telecommunications" },
    { option: "Fashion" },
    { option: "Media" },
    { option: "Non-Profit/Charity" },
    { option: "Marketing and Advertising" },
    { option: "Construction" },
    { option: "Pharmaceuticals" },
    { option: "Government/Public Sector" },
    { option: "Legal Services" },
    { option: "Fitness and Wellness" },
    { option: "Travel and Tourism" },
    { option: "Other" },
  ];

  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>Account information</span>
          </div>
          <div className="modalForm">
            <form action="">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <label htmlFor="">Organization name </label>
                    <input
                      type="text"
                      className="uk-input"
                      style={{ width: "100%" }}
                      defaultValue={generalSettings?.organizationName}
                      onChange={(e) => {
                        setData({
                          ...data,
                          organizationName: e.target.value,
                        });
                      }}
                    />
                    {data.organizationName === "" && (
                      <div className="formErrors">
                        organization name is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <label htmlFor="">Company industry</label>
                    {/* <input
                      type="text"
                      className="uk-input"
                      style={{ width: "100%" }}
                      defaultValue={generalSettings?.companyIndustry}
                      onChange={(e) => {
                        setData({
                          ...data,
                          companyIndustry: e.target.value,
                        });
                      }}
                    /> */}
                    {/* <select
                      name=""
                      className="uk-select accountInformationSelect"
                    >
                      {companyIndustryOptions.map((item) => {
                        return (
                          <option value={item.option}>{item.option}</option>
                        );
                      })}
                    </select> */}

                    <select
                      name=""
                      className="uk-select accountInformationSelect"
                      value={data.companyIndustry} // This ensures the selected option reflects the data state
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setData({
                          ...data,
                          companyIndustry: selectedValue,
                        });
                      }}
                    >
                      {companyIndustryOptions.map((item) => {
                        return (
                          <option value={item.option} key={item.option}>
                            {item.option}
                          </option>
                        );
                      })}
                    </select>

                    {data.companyIndustry === "" && (
                      <div className="formErrors">
                        industry name is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="btnwrp">
                    <button
                      type="button"
                      className="btn-1"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn-2"
                      onClick={updateGeneralSettings}
                    >
                      {loader ? (
                        <div uk-spinner="" className="loader"></div>
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountInformationModal;
