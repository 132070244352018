import React from "react";
import Multiselect from "multiselect-react-dropdown";
import { useState } from "react";
import { apiGet, apiPost } from "../../../Utils/apiServices";
import { useAuth } from "../../../Context/AuthProvider";
import { useEffect } from "react";
import $ from "jquery";
import { decryptedValue } from "../../../Utils/RouteHashConversion";
const SegmentFilterModal = ({
  closeModal,
  multiSelectTags,
  setFilterData,
  filterData,
  setSegments,
  setApiCalled,
}) => {
  const { auth } = useAuth();
  const [loader, setLoader] = useState(false);
  const [dateError, setDateError] = useState("");
  const [error, setError] = useState("");
  const [segmentCategory, setSegmentCategory] = useState();
  const [agentList, setAgentList] = useState();

  const onSelectTags = (item) => {
    setFilterData({
      ...filterData,
      contactTags: item,
    });
    setError("");
  };
  const onRemoveTags = (item) => {
    setFilterData({
      ...filterData,
      contactTags: item,
    });
  };
  useEffect(() => {
    if (filterData.category !== null) {
      getSegmentCategory();
    }
    if (filterData.agentId !== null) {
      getAgentList();
    }
  }, [filterData]);
  const handleFromDateErrors = (value) => {
    setError("");
    if (filterData.toDate === null) {
      setDateError("To Date is Missing");
    }
    if (filterData.toDate !== null && value !== "") {
      if (value > filterData.toDate) {
        setDateError("From date must be lesser than to date");
      } else {
        setDateError("");
      }
    }
  };

  const handleToDateErrors = (value) => {
    setError("");
    if (filterData.fromDate === null) {
      setDateError("From Date is Missing");
    }
    if (filterData.fromDate !== null && value !== "") {
      if (value < filterData.fromDate) {
        setDateError("To date must be greater than From date");
      } else {
        setDateError("");
      }
    }
  };
  const searchAndFilter = () => {
    var Errorvariable = "";
    const data = {
      dateStart: filterData.fromDate,
      dateEnd: filterData.toDate,
      contactTags: filterData.tags,
      category: filterData.category,
      contactTags: [],
      agentId: filterData.agentId,
      searchQuery: "",
    };
    if (
      filterData.fromDate === null &&
      filterData.toDate === null &&
      filterData.category === null &&
      filterData.agentId === null &&
      filterData.contactTags.length === 0
    ) {
      Errorvariable = "Please select parameters for filter";
      setError(Errorvariable);
    }
    if (Errorvariable === "" && dateError === "") {
      setLoader(true);
      apiPost(`/segment/filter`, onSuccess, onFailure, data);
    }
  };
  const onSuccess = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setLoader(false);
        closeModal();
      }, 1000);
      setTimeout(() => {
        setSegments(response?.data);
      }, 1200);
    }
  };
  const onFailure = (error) => {
    console.log(error);
  };
  const getSegmentCategory = () => {
    apiGet(
      `/segment/list/category`,
      onSuccessSegmentCategory,
      onFailureSegmentCategory
    );
  };
  const onSuccessSegmentCategory = (response) => {
    setSegmentCategory(response?.data);
  };
  const onFailureSegmentCategory = (error) => {
    console.log(error);
  };

  const getAgentList = () => {
    apiGet(
      `/agent/list/AGENT`,
      onSuccessAgentList,
      onFailureAgentList
    );
  };
  const onSuccessAgentList = (response) => {
    setAgentList(response?.data);
  };
  const onFailureAgentList = (error) => {
    console.log(error);
  };
  const clearFilter = () => {
    setApiCalled(true);
    $("#fromDate").val("");
    $("#toDate").val("");
    setFilterData({
      fromDate: null,
      toDate: null,
      category: null,
      contactTags: [],
      agentId: null,
      searchQuery: null,
    });
  };
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>Search fIlter</span>
          </div>
          <div className="modalForm">
            <div className="formHeading">
              <span>Search segment</span>
            </div>
            <form action="">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="formInputs">
                    <label htmlFor="from_date">From date</label>
                    <input
                      id="fromDate"
                      type="date"
                      className="uk-input"
                      value={filterData.fromDate}
                      onChange={(e) => {
                        setFilterData({
                          ...filterData,
                          fromDate: e.target.value,
                        });
                        handleFromDateErrors(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInputs">
                    <label htmlFor="to_date">To date</label>
                    <input
                      id="toDate"
                      type="date"
                      className="uk-input"
                      value={filterData.toDate}
                      onChange={(e) => {
                        setFilterData({
                          ...filterData,
                          toDate: e.target.value,
                        });
                        handleToDateErrors(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {dateError !== "" && (
                  <div className="uk-width-1-1 formErrors">{dateError}</div>
                )}
                <div className="uk-width-1-2">
                  <div className="formInputs">
                    <label htmlFor="">Segment catagory</label>
                    <select
                      name=""
                      className="uk-select"
                      onClick={() => getSegmentCategory()}
                      value={filterData.category}
                      onChange={(e) => {
                        setError("");
                        setFilterData({
                          ...filterData,
                          category: e.target.value,
                        });
                      }}
                    >
                      {segmentCategory?.length === 0 ? (
                        <>
                          <option
                            value=""
                            selected
                            disabled
                            placeholder="Please select segment"
                          >
                            Select a segment
                          </option>
                          <option value="">loading ...</option>
                        </>
                      ) : (
                        <option value="" selected disabled>
                          Select a segment category
                        </option>
                      )}
                      {segmentCategory?.map((val, index) => {
                        return (
                          <option key={index} value={val.name}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInputs">
                    <label htmlFor="">Created by</label>
                    <select
                      name=""
                      className="uk-select"
                      onClick={() => getAgentList()}
                      value={filterData.agentId}
                      onChange={(e) => {
                        setError("");
                        setFilterData({
                          ...filterData,
                          agentId: e.target.value,
                        });
                      }}
                    >
                      {agentList?.length === 0 ? (
                        <>
                          <option
                            value=""
                            selected
                            disabled
                            placeholder="Please select user"
                          >
                            Select a user
                          </option>
                          <option value="">loading ...</option>
                        </>
                      ) : (
                        <option value="" selected disabled>
                          Select a user
                        </option>
                      )}
                      {agentList?.map((val, index) => {
                        return (
                          <option key={index} value={val.publicId}>
                            {val?.firstName + " " + val?.lastName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="mulitBoxHeading">
                    <span>Search by tags</span>
                  </div>
                  <div className="customChipBox">
                    <Multiselect
                      options={multiSelectTags}
                      onSelect={onSelectTags}
                      onRemove={onRemoveTags}
                      displayValue="name"
                      selectedValues={filterData.contactTags}
                    />
                  </div>
                </div>
                {error !== "" && (
                  <div className="uk-width-1-1 formErrors">{error}</div>
                )}
                <div className="uk-width-1-2">
                  <div className="clearFilterBtn">
                    <button type="button" onClick={clearFilter}>
                      Clear filter
                    </button>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="btnwrp">
                    <button
                      type="button"
                      className="btn-1"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn-2 w-90"
                      onClick={searchAndFilter}
                    >
                      {loader ? (
                        <div uk-spinner="" className="loader"></div>
                      ) : (
                        "Search"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SegmentFilterModal;
