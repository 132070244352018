import React, { createContext, useContext, useState } from "react";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { apiPost, apiGet } from "../../Utils/apiServices";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { decryptedValue } from "../../Utils/RouteHashConversion";
import EmojiPicker from "emoji-picker-react";

export const ContextChatBot = createContext();
export const useContextChatBot = () => useContext(ContextChatBot);

export const ContextChatBotProvider = ({ children }) => {
  const params = useParams();
  const [openDialogModal, setOpenDialogModal] = useState(false);
  const [clickedSections, setClickedSections] = useState([]);
  const [textareaValue, setTextareaValue] = useState("");
  const [visibleSection, setVisibleSection] = useState("trigger");
  const [triggerValue, settriggerValue] = useState([]);
  const [inputTriggerValue, setInputTriggerValue] = useState("");
  const [triggerValueAction, settriggerValueAction] = useState([]);
  const [inputTriggerValueAction, setInputTriggerValueAction] = useState([]);
  const [isKeywordVisibleAction, setKeywordVisibleAction] = useState(false);
  const [isKeywordVisible, setKeywordVisible] = useState(false);
  const [textareaValues, setTextareaValues] = useState({});
  const [sectionData, setSectionData] = useState([]);
  const [selectSection, setSelectSection] = useState("trigger");
  const [sectionDataArray, setSectionDataArray] = useState([]);
  const [editable, setEditable] = useState(false);
  const [saveButtonClicked, setSaveButtonClicked] = useState(true);
  const [sections, setSections] = useState([]);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isDialogVisibleAction, setIsDialogVisibleAction] = useState(false);
  const [isDialogVisibleForm, setIsDialogVisibleForm] = useState(false);
  const initialSelectedDialogOnButton = Array(sections?.length).fill(null);
  const [isEditingBName, setIsEditingBName] = useState(false);
  const [textBName, setTextBName] = useState("");
  const [sectionValues, setSectionValues] = useState([]);
  const [disableItem, setDisableItem] = useState(false);
  const [disableItemLive, setdisableItemLive] = useState([]);
  const [hasTextarea, setHasTextarea] = useState(false);
  const [captionChecking, setcaptionChecking] = useState(false);
  const [disableAll, setdisableAll] = useState(false);
  const [botUpdated, setBotUpdated] = useState([]);
  const [paramsState, setParamsState] = useState("");
  const [dropdownDialogID, setDropdownDialogId] = useState("");
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState(false);
  const [teamsData, setTeamsData] = useState(false);
  const [teamNameSingle, setNameSingle] = useState("");

  const [savebotbtnClick, setSavebotbtnClick] = useState(false);

  useEffect(() => {}, [teamNameSingle]);

  const [isHandleAddButtonPressed, setIsHandleAddButtonPressed] =
    useState(false);

  const [showToast, setShowToast] = useState("");

  const [disableLive, setDisableLive] = useState(false);
  const [checkEmptyTitle, setcheckEmptyTitle] = useState(false);

  if (sections?.length > 0) {
    initialSelectedDialogOnButton[0] = "Select an existing dialog";
  }

  const companyId =
    localStorage.getItem("_companyId") === null
      ? ""
      : localStorage.getItem("_displayed") !== null
      ? decryptedValue(localStorage.getItem("_companyId"))
      : "";

  const selectedDialog = sections?.find(
    (dialog) => dialog.dialogueTitle === selectSection
  );
  const [isDialogVisibleArray, setIsDialogVisibleArray] = useState(
    new Array(selectedDialog?.settings?.length).fill(false)
  );
  const [isActionVisibleArray, setIsActionVisibleArray] = useState(
    new Array(selectedDialog?.settings?.length).fill(false)
  );
  const [isFormVisibleArray, setIsFormVisibleArray] = useState(
    new Array(selectedDialog?.settings?.length).fill(false)
  );

  const navigate = useNavigate();

  const handleNavigationChatbot = () => {
    navigate("/chatbot");
    window.location.reload();
  };

  const [bot, setBot] = useState({
    botName: "",
    companyId: companyId,
    keyWords: [],
    channelId: "WHATSAPP",
    sections: [],
  });
  const updatedKeywords = triggerValue?.filter(
    (keyword) => keyword !== "Add a trigger"
  );

  useEffect(() => {
    setBot((prevBot) => ({
      ...prevBot,
      botName: textBName,
      companyId: companyId,
      keyWords: updatedKeywords,
      sections: sections,
    }));
  }, [sections, triggerValue, companyId, textBName]);

  // edit bot builder

  useEffect(() => {
    const buttonCount = selectedDialog?.settings.filter(
      (item) => item.type === "button"
    ).length;

    if (
      buttonCount >= 1 &&
      selectedDialog?.settings.some((item) =>
        ["textarea", "image", "video", "file"].includes(item.type)
      )
    ) {
      setDisableItem(true);
    } else {
      setDisableItem(false);
    }
  }, [selectedDialog?.settings, selectedDialog?.settings.length]);

  useEffect(() => {
    if (
      (selectedDialog?.settings &&
        selectedDialog?.settings.some((item) => item.type === "image")) ||
      selectedDialog?.settings.some((item) => item.type === "video") ||
      selectedDialog?.settings.some((item) => item.type === "file") ||
      (selectedDialog?.settings.some((item) => item.type === "textarea") &&
        selectedDialog?.settings.some((item) => item.name !== null) &&
        selectedDialog?.settings.some((item) => item.value !== null))
    ) {
      setdisableItemLive(true);
    } else {
      setdisableItemLive(false);
    }

    if (
      (selectedDialog?.settings.length === 1 &&
        selectedDialog?.settings.some((item) => item.type === "image")) ||
      selectedDialog?.settings.some((item) => item.type === "video") ||
      selectedDialog?.settings.some((item) => item.type === "file") ||
      selectedDialog?.settings.some((item) => item.type === "textarea")
    ) {
      setdisableAll(true);
    } else {
      setdisableAll(false);
    }

    const buttonCount = (
      selectedDialog?.settings.filter((item) => item.type === "button") || []
    ).length;

    const hasRequiredType = selectedDialog?.settings.some((item) =>
      ["textarea", "image", "video", "file"].includes(item.type)
    );

    if (buttonCount >= 1 && buttonCount <= 3 && !hasRequiredType) {
      setHasTextarea(true);
    } else {
      setHasTextarea(false);
    }

    //for caption file and video

    if (
      (selectedDialog?.settings &&
        selectedDialog?.settings.some(
          (item) => item.type === "video" && !item.caption && buttonCount >= 1
        )) ||
      (selectedDialog?.settings &&
        selectedDialog?.settings.some(
          (item) => item.type === "file" && !item.caption && buttonCount >= 1
        ))
    ) {
      setcaptionChecking(true);
    } else {
      setcaptionChecking(false);
    }
  }, [selectedDialog?.settings, selectedDialog?.settings.length]);

  useEffect(() => {
    let isAlertLogged = false;

    sections.forEach((dialogue) => {
      dialogue.settings.forEach((item) => {
        if (item.type === "action") {
          if (
            item.keyword &&
            item.keyword.length > 0 &&
            item.keyword[0].value &&
            item.detail &&
            item.detail.type &&
            item.value
          ) {
            setDisableLive(false);
          } else {
            if (!isAlertLogged) {
              setShowToast(item.value);
              isAlertLogged = true;
            }
            if (isAlertLogged) {
              setDisableLive(true);
            }
          }
        }
      });
    });
  }, [selectedDialog?.settings, selectedDialog?.settings.length, sections]);

  useEffect(() => {
    let isAlertLogged = false;

    sections.forEach((dialogue) => {
      if (dialogue.dialogueTitle === "") {
        setcheckEmptyTitle(true);
        isAlertLogged = true; // Set isAlertLogged to true once a dialogue with an empty title is found
      }
      if (!isAlertLogged) {
        setcheckEmptyTitle(false);
      }
    });
  }, [selectedDialog, sections]);

  useEffect(() => {
    if (disableLive) {
      toast.warn(
        `Please complete all the required fields and provide a keyword to fulfill your action.`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  }, [disableLive, isHandleAddButtonPressed]);

  const [selectedDialogOnButton, setSelectedDialogOnButton] = useState(
    initialSelectedDialogOnButton
  );

  const toggleDialogVisibility = (index) => {
    if (!isDialogVisibleArray[index]) {
      const updatedVisibilityArray = [...isDialogVisibleArray];
      updatedVisibilityArray[index] = true;
      setIsDialogVisibleArray(updatedVisibilityArray);
    }
  };

  const toggleDialogHidden = (index) => {
    const updatedVisibilityArray = [...isDialogVisibleArray];
    updatedVisibilityArray[index] = false;
    setIsDialogVisibleArray(updatedVisibilityArray);
  };

  const toggleActionVisibility = (index) => {
    if (!isActionVisibleArray[index]) {
      const updatedVisibilityArray = [...isActionVisibleArray];
      updatedVisibilityArray[index] = true;
      setIsActionVisibleArray(updatedVisibilityArray);
    }
  };

  const toggleActionHidden = (index) => {
    const updatedVisibilityArray = [...isActionVisibleArray];
    updatedVisibilityArray[index] = false;
    setIsActionVisibleArray(updatedVisibilityArray);
  };

  const toggleFormVisibility = (index) => {
    if (!isFormVisibleArray[index]) {
      const updatedVisibilityArray = [...isFormVisibleArray];
      updatedVisibilityArray[index] = true;
      setIsFormVisibleArray(updatedVisibilityArray);
    }
  };

  const toggleFormHidden = (index) => {
    const updatedVisibilityArray = [...isFormVisibleArray];
    updatedVisibilityArray[index] = false;
    setIsFormVisibleArray(updatedVisibilityArray);
  };

  const toggleDialogVisibilityAgent = () => {
    setIsDialogVisible(false);
  };

  const toggleDialogVisibilityAction = () => {
    setIsDialogVisibleAction(!isDialogVisibleAction);
  };

  const toggleDialogVisibilityForm = () => {
    setIsDialogVisibleForm(!isDialogVisibleForm);
  };

  const addSection = (sectionType, name, value) => {
    const SettingDialogue = sections.find(
      (dialogue) => dialogue.dialogueTitle === selectSection
    );

    let sectionSettings = SettingDialogue?.settings;

    const newSection = {
      type: sectionType,
      name: name,
      value: value,
      id: uuidv4(),
    };
    sectionSettings?.push(newSection);

    const updatedDialogues = sections.map((dialogue) => {
      if (dialogue.dialogueTitle === selectSection) {
        return { ...dialogue, settings: sectionSettings };
      } else {
        return dialogue;
      }
    });
    setSections(updatedDialogues);

    if (sectionType === "action") {
      setIsHandleAddButtonPressed(true);
    } else {
      setIsHandleAddButtonPressed(false);
    }
  };

  const selectSectionFunc = (sectionName) => {
    setSelectSection(sectionName);
  };

  const handleEditClickBName = () => {
    setIsEditingBName(true);
  };

  const handleSaveClickBName = () => {
    setIsEditingBName(false);
  };

  const handleChangeBName = (e) => {
    setTextBName(e.target.value);
  };

  // Add this function to handle emoji insertion
  const insertEmoji = (id, sectionID, emoji) => {
    const inputField = document.getElementById(id);

    if (inputField) {
      const selectionStart = inputField.selectionStart || 0;
      const selectionEnd = inputField.selectionEnd || 0;

      const cursorPos =
        selectionStart === selectionEnd ? selectionStart : selectionEnd;

      const updatedData = sections.map((section) => {
        const updatedSettings = section?.settings.map((setting) => {
          if (setting.id === sectionID) {
            const currentValue = setting.value || "";

            // Split the text into an array of grapheme clusters
            const textArray = Array.from(currentValue);

            // Adjust cursorPos for emoji variations
            const emojiLength = Array.from(emoji).length;
            const adjustedCursorPos = cursorPos + emojiLength;

            // Insert the emoji at the adjusted cursor position
            textArray.splice(adjustedCursorPos, 0, ...Array.from(emoji));

            // Join the array back into a string
            const newValue = textArray.join("");

            return {
              ...setting,
              value: newValue,
            };
          }
          return setting;
        });

        return { ...section, settings: updatedSettings };
      });

      setSections(updatedData);
    }
  };

  // Modify the onEmojiClick function to use insertEmoji
  const onEmojiClick = (emojiData, event, id) => {
    const inputField = document.getElementById(id);
    if (inputField) {
      insertEmoji(id, emojiData.emoji); // Use insertEmoji to update state
    }
  };

  const handleInputChangeSection = (id, property, value) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings.map((setting) => {
        if (setting.id === id) {
          return {
            ...setting,
            [property]: value,
          };
        }
        return setting;
      });

      return { ...section, settings: updatedSettings };
    });
    setSections(updatedData);
  };

  const handleInputChangeSectionButton = (id, property, value) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings?.map((setting) => {
        if (setting.id === id) {
          return {
            ...setting,
            [property]: value,
            name: "button_" + value,
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });

    setSections(updatedData);
  };

  const handleInputChangeSectionAction = (id, property, newValue) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings?.map((setting) => {
        if (setting.id === id) {
          const newItemId = generateUniqueId();

          const updatedValue = Array.isArray(setting[property])
            ? [...setting[property], { value: newValue, id: newItemId }]
            : [{ value: newValue, id: newItemId }];

          return {
            ...setting,
            [property]: updatedValue,
            name: "button_" + newValue,
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });

    setSections(updatedData);
    setSectionValues([...sectionValues, newValue]);
  };

  const generateUniqueId = () => {
    return "unique_id_" + Date.now() + "_" + Math.floor(Math.random() * 1000);
  };

  const handleKeyPressKeywordSyn = (e, sectionId) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleInputChangeSectionAction(sectionId, "keyword", sectionValues);
      setSectionValues("");
      setKeywordVisibleAction(false);
    }
  };
  const handleChange = (e) => {
    setSectionValues(e.target.value);
  };

  const handleRemoveTriggerAction = (index, item) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section.settings.map((setting) => {
        if (setting.keyword && setting.keyword.length > 0) {
          const updatedKeywords = setting.keyword.filter(
            (keyword, i) =>
              !(
                i === index &&
                keyword.value === item.value &&
                keyword.id === item.id
              )
          );
          return { ...setting, keyword: updatedKeywords };
        }
        return setting;
      });

      return { ...section, settings: updatedSettings };
    });

    setSections(updatedData);
  };

  const handleAddButton = (
    sectionId,
    index,
    selectValue,
    type,
    keyword,
    validation
  ) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings?.map((setting) => {
        if (setting.id === sectionId) {
          return {
            ...setting,
            detail: {
              selectValue:
                type === "typeAgent" || type === "typeCatalog" || type == 0
                  ? null
                  : selectValue,
              type: type || "typeAgent",
              selectedId:
                type === "typeAgent" || type === "typeCatalog" || type == 0
                  ? null
                  : setting?.detail?.selectedId || dropdownDialogID,
              validation: validation || null,
            },
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });

    setSections(updatedData);

    if (!keyword) {
      toast.error("Please fill all the required fields.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDialogSelectOnButton = (dialog, index, dialogId, teamName) => {
    setDropdownDialogId(dialogId);
    const updatedSelectedDialogOnButton = [...selectedDialogOnButton];
    updatedSelectedDialogOnButton[index] = dialog;
    setSelectedDialogOnButton(updatedSelectedDialogOnButton);

    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings?.map((setting) => {
        if (setting.id === index) {
          // Create the "detail" object if it doesn't exist
          const updatedDetail = setting.detail || {};

          // Update the "selectedId" in the "detail" object
          updatedDetail.selectedId = dialogId;

          if (updatedDetail.selectValue === dialog) {
            setNameSingle(teamName);
          }

          return {
            ...setting,
            detail: updatedDetail,
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });

    setSections(updatedData);
  };

  const handleSectionClosing = (id) => {
    const updatedData = sections.map((section) => {
      if (section?.settings.some((setting) => setting.id === id)) {
        const updatedSettings = section?.settings.filter(
          (setting) => setting.id !== id
        );
        return { ...section, settings: updatedSettings };
      }
      return section;
    });

    setSections(updatedData);
  };

  const toggleKeywordVisibility = () => {
    setKeywordVisible(!isKeywordVisible);
  };

  const toggleKeywordVisibilityAction = () => {
    setKeywordVisibleAction(!isKeywordVisibleAction);
  };
  const toggleSection = (sectionName) => {
    const uniqueSectionName = `${sectionName}-${Date.now()}`;
    setClickedSections([...clickedSections, uniqueSectionName]);
  };

  const handleSectionClose = (sectionName) => {
    const updatedSections = clickedSections?.filter(
      (section) => section !== sectionName
    );
    setClickedSections(updatedSections);

    const updatedTextareaValues = { ...textareaValue };
    delete updatedTextareaValues[sectionName];
    setTextareaValue(updatedTextareaValues);
  };

  const handleOpenDilaogModal = () => {
    setOpenDialogModal(true);
  };

  const handleCloseDialogModal = () => {
    setOpenDialogModal(false);
  };

  const addTextareaValue = (sectionName, value) => {
    setTextareaValues({
      ...textareaValues,
      [sectionName]: value,
    });
  };

  const addClickedSection = (sectionName) => {
    setClickedSections([...clickedSections, sectionName]);
  };

  const removeClickedSection = (sectionName) => {
    setClickedSections(
      clickedSections?.filter((section) => section !== sectionName)
    );
  };

  const toggleSectionMain = (sectionName) => {
    if (!visibleSection) {
      setVisibleSection("starting_text");
    } else {
      setVisibleSection(sectionName);
    }
  };

  const handleInputChange = (e) => {
    setInputTriggerValue(e.target.value);
  };

  const handleInputChangeAction = (e) => {
    setInputTriggerValueAction(e.target.value);
  };

  const handleKeyPressAction = (e) => {
    if (e.key === "Enter") {
      settriggerValueAction([...triggerValueAction, inputTriggerValueAction]);
      setInputTriggerValueAction("");
      setKeywordVisibleAction(false);
    }
  };

  const handleRemoveTrigger = (itemToRemove) => {
    const updatedTriggerValue = triggerValue?.filter(
      (item) => item !== itemToRemove
    );
    settriggerValue(updatedTriggerValue);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const splitValues = inputTriggerValue
        .split(",")
        .map((value) => value.trim())
        .filter((value) => value !== "" && value !== "Add a trigger");

      if (splitValues.length > 0) {
        settriggerValue([...triggerValue, ...splitValues]);
        setInputTriggerValue("");
      }
    }
  };

  const saveBotObject = () => {
    const Dialogue = sections?.find(
      (dialogue) => dialogue?.dialogueTitle === selectSection
    );
    let tempSettings = Dialogue?.settings?.map((item, index) => {
      if (item.type === "textarea") {
        item.value = document.getElementById(selectSection + "_" + index).value;
      } else if (item.type === "image") {
        // Handle image input
        const fileInput = document.getElementById(selectSection + "_" + index);
        if (fileInput && fileInput?.files?.length > 0) {
          item.value = URL.createObjectURL(fileInput.files[0]);
        }
      } else if (item.type === "video") {
        // Handle image input
        const fileInput = document.getElementById(selectSection + "_" + index);
        if (fileInput && fileInput?.files?.length > 0) {
          item.value = URL.createObjectURL(fileInput.files[0]);
        }
      }
      return item;
    });

    if (tempSettings?.length) {
      const updatedDialogues = sections?.map((dialogue) => {
        if (dialogue?.dialogueTitle === selectSection) {
          return { ...dialogue, settings: tempSettings };
        } else {
          return dialogue;
        }
      });
      setSections(updatedDialogues);
      setSectionData(updatedDialogues);
    }
    setSaveButtonClicked(true);
    const onSuccess = (response) => {
      if (response.status === 0) {
        setBotUpdated(response?.data);

        navigate(`/chatbot`, {
          replace: true,
        });
      }
    };
    const onFailure = (error) => {
      console.log(error);
    };

    if (!disableLive && !checkEmptyTitle) {
      if ((bot !== "" && paramsState == "") || paramsState == undefined) {
        apiPost(`/bot/builder/create`, onSuccess, onFailure, bot);
        toast.success("Bot created successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
        });
      } else if (paramsState !== "" && paramsState?.length > 0) {
        apiPost(`/bot/builder/edit/${paramsState}`, onSuccess, onFailure, bot);
        toast.success("Bot Edited successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
        });
      }
    } else {
      if (disableLive) {
        toast.error(
          `Check ${showToast} action where keyword or value is missing!`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }

      if (checkEmptyTitle) {
        toast.error(`Dialog title is empty`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }

    setSaveButtonClicked(true);
  };

  //Dialog functions

  const duplicateSection = () => {
    sections.forEach((dialogue) => {
      if (dialogue.dialogueTitle === "") {
      }
    });

    setSaveButtonClicked(false);

    if (sections.length == 0) {
      let newDialogue = {
        dialogueTitle: "",
        isDefault: true,
        dialogueId: uuidv4(),
        settings: [],
      };
      setSections([...sections, newDialogue]);
    } else {
      const sectionToDuplicate = sections[0];
      if (sectionToDuplicate) {
        const newSection = {
          ...sectionToDuplicate,
          dialogueId: uuidv4(),
          dialogueTitle: "",
          isDefault: false,
          settings: [],
        };
        setSections([...sections, newSection]);
      }
      setSectionData([]);
    }
  };

  const deleteSection = (sectionId) => {
    const updatedSections = sections.filter(
      (section) => section.dialogueId !== sectionId
    );
    setSections(updatedSections);
  };

  const handleEditClick = () => {
    setEditable(!editable);
  };

  const handleSectionNameChange = (sectionId, newName) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.dialogueId === sectionId
          ? { ...section, dialogueTitle: newName }
          : section
      )
    );
  };

  //end
  const selectedDialogIndex = sections?.findIndex(
    (dialog) => dialog.dialogueTitle === selectSection
  );

  const handleFileI = (event, headerMediaType, sectionIndex) => {
    const file = event.target.files[0];
    const fileInput = event.target;

    const isImage = (file) => {
      const imageExtensions = [
        ".jpg",
        ".jpeg",
        ".gif",
        ".png",
        ".avif",
        ".AVIF",
      ];
      const extension = file.name
        .toLowerCase()
        .substring(file?.name.lastIndexOf("."));
      return imageExtensions.includes(extension);
    };

    const updateSection = (value, name) => {
      setSections((prevSections) => {
        const updatedSections = [...prevSections];
        const updatedSettings = [
          ...updatedSections[selectedDialogIndex].settings,
        ];
        updatedSettings[sectionIndex] = {
          ...updatedSettings[sectionIndex],
          value: value,
          name: name,
        };
        updatedSections[selectedDialogIndex] = {
          ...updatedSections[selectedDialogIndex],
          settings: updatedSettings,
        };
        return updatedSections;
      });
    };

    if (file?.name && isImage(file)) {
      if (file.type.startsWith("image") && isImage(file)) {
        const url = URL.createObjectURL(file);
        var formData = new FormData();
        formData.append("file", file);
        formData.append("agentPublicId", localStorage.getItem("_agentId"));
        formData.append(
          "companyId",
          localStorage.getItem("_companyId") === null
            ? ""
            : decryptedValue(localStorage.getItem("_companyId"))
        );
        const baseUrl = process.env.REACT_APP_BASEURL;
        axios
          .post(baseUrl + "/chat/upload-file", formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("_token")}`,
            },
          })
          .then(
            (response) => {
              if (response.data.status === 1) {
                updateSection(response.data.message, url);
              }
            },
            (error) => {}
          );
        fileInput.value = "";
      }
    } else {
      toast.error("Invalid Format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      fileInput.value = "";
    }
  };

  const handleFileV = (event, headerMediaType, sectionIndex) => {
    const file = event.target.files[0];
    const fileInput = event.target;

    const isVideo = (file) => {
      return file?.name.toLowerCase().endsWith(".mp4");
    };

    const updateSection = (value, name) => {
      setSections((prevSections) => {
        const updatedSections = [...prevSections];
        const updatedSettings = [
          ...updatedSections[selectedDialogIndex].settings,
        ];
        updatedSettings[sectionIndex] = {
          ...updatedSettings[sectionIndex],
          value: value,
          name: name,
        };
        updatedSections[selectedDialogIndex] = {
          ...updatedSections[selectedDialogIndex],
          settings: updatedSettings,
        };
        return updatedSections;
      });
    };

    if (file?.name && isVideo(file)) {
      if (file.type.startsWith("video") && isVideo(file)) {
        const url = URL.createObjectURL(file);
        var formData = new FormData();
        formData.append("file", file);
        formData.append("agentPublicId", localStorage.getItem("_agentId"));
        formData.append(
          "companyId",
          localStorage.getItem("_companyId") === null
            ? ""
            : decryptedValue(localStorage.getItem("_companyId"))
        );
        const baseUrl = process.env.REACT_APP_BASEURL;
        axios
          .post(baseUrl + "/chat/upload-file", formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("_token")}`,
            },
          })
          .then(
            (response) => {
              if (response.data.status === 1) {
                updateSection(response.data.message, url);
              }
            },
            (error) => {}
          );
        fileInput.value = "";
      }
    } else {
      toast.error("Invalid Format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      fileInput.value = "";
    }
  };

  const handleFileF = (event, headerMediaType, sectionIndex) => {
    const file = event.target.files[0];
    const fileInput = event.target;

    const isFile = (file) => {
      const documentExtensions = [
        // Word Documents
        ".doc",
        ".docx",
        ".dot",
        ".dotx",
        ".pdf",
        ".PDF",

        // Excel Documents
        ".xls",
        ".xlsx",
        ".xlsm",
        ".xlsb",
        ".xlt",
        ".xltx",
      ];
      const extension = file?.name
        .toLowerCase()
        .substring(file?.name.lastIndexOf("."));
      return documentExtensions.includes(extension);
    };

    const updateSection = (value, name) => {
      setSections((prevSections) => {
        const updatedSections = [...prevSections];
        const updatedSettings = [
          ...updatedSections[selectedDialogIndex].settings,
        ];
        updatedSettings[sectionIndex] = {
          ...updatedSettings[sectionIndex],
          value: value,
          name: name,
        };
        updatedSections[selectedDialogIndex] = {
          ...updatedSections[selectedDialogIndex],
          settings: updatedSettings,
        };
        return updatedSections;
      });
    };

    if (file?.name && isFile(file)) {
      const url = URL.createObjectURL(file);

      var formData = new FormData();
      formData.append("file", file);
      formData.append("agentPublicId", localStorage.getItem("_agentId"));
      formData.append(
        "companyId",
        localStorage.getItem("_companyId") === null
          ? ""
          : decryptedValue(localStorage.getItem("_companyId"))
      );
      const baseUrl = process.env.REACT_APP_BASEURL;
      axios
        .post(baseUrl + "/chat/upload-file", formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("_token")}`,
          },
        })
        .then(
          (response) => {
            if (response.data.status === 1) {
              updateSection(response.data.message, file.name);
            }
          },
          (error) => {}
        );
      fileInput.value = "";
    } else {
      toast.error("Invalid Format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      fileInput.value = "";
    }
  };

  /***************************************chat bot api**************************************************** */

  const [chatbot, setchatbot] = useState(null);

  const getchatbot = () => {
    let data = {
      accessToken: localStorage?.getItem("_token"),
      userPublicId: localStorage?.getItem("_publicId"),
    };
    setLoader(true);
    apiPost(`/bot/builder/list`, onSuccesschatbot, onFailurechatbot, data);
  };
  const onSuccesschatbot = (response) => {
    setchatbot(response?.data);
    setLoader(false);
  };
  const onFailurechatbot = (error) => {
    console.log(error);
  };

  /********************************edit params single **********************************************/
  const getchatbotSingle = () => {
    if (paramsState) {
      setLoader(true);
      apiGet(
        `/bot/builder/${paramsState}`,
        onSuccesschatbotSingle,
        onFailurechatbotSingle
      );
    }
  };
  const onSuccesschatbotSingle = (response) => {
    let data = response.data;

    try {
      let parsedBotData = JSON.parse(data?.botJsonString);
      setSections(parsedBotData?.sections);
      settriggerValue(parsedBotData?.keyWords);
      setTextBName(parsedBotData?.botName);
      setLoader(false);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };
  const onFailurechatbotSingle = (error) => {
    console.log(error);
  };

  // get id of forms

  const getFormData = () => {
    setLoader(true);
    apiGet(`/chat/flow`, onSuccessgetFormData, onFailuregetFormData);
  };
  const onSuccessgetFormData = (response) => {
    setFormData(response.data);
    setLoader(false);
  };
  const onFailuregetFormData = (error) => {
    console.log(error);
  };

  //get team

  const getTeams = () => {
    setLoader(true);
    apiGet(`/agent/team/list?searchQuery=`, onSuccessTeams, onFailureTeams);
  };
  const onSuccessTeams = (response) => {
    setTeamsData(response.data);
    setLoader(false);
  };
  const onFailureTeams = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (window.location.pathname.startsWith("/chatbot-builder")) {
      getTeams();
    }
  }, [window.location.pathname]);

  //end
  useEffect(() => {
    getchatbotSingle();
  }, [paramsState]);

  useEffect(() => {
    if (window.location.pathname.startsWith("/chatbot-builder")) {
      getFormData();
    }
  }, [window.location.pathname]);

  const createChatbot = () => {
    navigate("/chatbot-builder", { replace: true });
    window.location.reload();
  };

  /************************************************ */

  const values = {
    textareaValues,
    textareaValue,
    clickedSections,
    addTextareaValue,
    addClickedSection,
    removeClickedSection,
    toggleSection,
    handleSectionClose,
    handleOpenDilaogModal,
    handleCloseDialogModal,
    handleInputChange,
    handleKeyPress,
    triggerValue,
    settriggerValue,
    toggleSectionMain,
    visibleSection,
    setTextareaValue,
    isKeywordVisible,
    setKeywordVisible,
    inputTriggerValue,
    sectionData,
    addSection,
    toggleKeywordVisibility,
    selectSectionFunc,
    selectSection,
    handleSectionClosing,
    handleInputChangeSection,
    sectionDataArray,
    setSectionDataArray,
    setSectionData,

    //Dialog start
    sections,
    setSections,
    duplicateSection,
    deleteSection,
    handleEditClick,
    handleKeyPress,
    handleSectionNameChange,
    saveBotObject,
    saveButtonClicked,
    handleFileI,
    handleFileV,
    handleFileF,
    isDialogVisible,
    toggleDialogVisibility,
    toggleDialogVisibilityAction,
    isDialogVisibleAction,
    handleInputChangeSectionButton,
    openDialogModal,
    handleDialogSelectOnButton,
    selectedDialogOnButton,
    handleAddButton,

    handleInputChangeAction,
    handleKeyPressAction,
    inputTriggerValueAction,
    isKeywordVisibleAction,
    toggleKeywordVisibilityAction,
    triggerValueAction,
    handleRemoveTriggerAction,
    handleRemoveTrigger,
    handleEditClickBName,
    handleSaveClickBName,
    handleChangeBName,
    isEditingBName,
    textBName,
    handleInputChangeSectionAction,
    handleKeyPressKeywordSyn,
    handleChange,
    sectionValues,
    toggleDialogVisibilityAgent,
    isDialogVisibleArray,
    toggleActionVisibility,
    toggleDialogHidden,
    toggleActionHidden,
    disableItem,
    hasTextarea,
    disableAll,
    isActionVisibleArray,
    captionChecking,
    chatbot,
    getchatbot,
    paramsState,
    setchatbot,
    setParamsState,
    loader,
    createChatbot,
    onEmojiClick,
    insertEmoji,
    isFormVisibleArray,
    toggleFormVisibility,
    toggleFormHidden,
    toggleDialogVisibilityForm,
    formData,
    setIsActionVisibleArray,
    setIsFormVisibleArray,
    disableLive,
    setDisableLive,
    teamsData,
    teamNameSingle,
  };
  return (
    <ContextChatBot.Provider value={values}>{children}</ContextChatBot.Provider>
  );
};
