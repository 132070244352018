import { Camera, ChevronLeft } from "@carbon/icons-react";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { apiGet } from "../../../Utils/apiServices";

const SingleCompanyView = () => {
  const location = useLocation();
  const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;
  const publicId = location?.state?.publicId;
  const [loader, setLoader] = useState(false);

  const [data, setData] = useState(null);
  const getCompany = () => {
    apiGet(
      `/super-admin/company-with-package/${publicId}`,
      onSuccessCompany,
      onFailureCompany
    );
  };
  const onSuccessCompany = (response) => {
    setData(response);
    setLoader(true);
  };
  const onFailureCompany = (error) => {
    console.log(error);
    setLoader(false);
  };
  useEffect(() => {
    getCompany();
  }, []);

  return (
    <div
      className="adminDetailsWrp"
      style={{ minHeight: "calc(100vh - 135px)" }}
    >
      <div className="uk-container">
        <div className="backBtnWrp">
          <div className="backBtn">
            <Link to="/super-admin-company">
              <ChevronLeft /> Back to all companies
            </Link>
          </div>
        </div>

        <div className="detailsWrp">
          <div
            className="uk-grid "
            uk-grid=""
            uk-height-match="target: > div > .customStyle"
          >
            <div className="uk-width-1-3">
              <div className="customStyle">
                <div className="detailContent adminDetailBoxes heightNew">
                  <div>
                    <div className="adminImg">
                      {data?.companyLogoFileId ? (
                        <span className="InitialImage">
                          <img
                            src={chatBaseUrl + data?.companyLogoFileId}
                            alt=""
                          />
                        </span>
                      ) : (
                        <div className="adminImg">
                          <span className="InitialImage companyLogoTxt">
                            {data?.companyName && data.companyName.charAt(0)}
                          </span>
                        </div>
                      )}

                      <div
                        uk-form-custom=""
                        className="profileImgUpload uk-form-custom"
                      >
                        <input
                          type="file"
                          aria-label="Custom controls"
                          accept="image/*"
                          disabled
                        />
                      </div>
                    </div>

                    <div className="numberInput namewrp">
                      <form action="">
                        <input type="text" value={data?.companyName} disabled />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-2-3">
              <div className="customStyle">
                <div className="userProfile adminDetailBoxes heightNew">
                  <div>
                    <div className="boxHeading">
                      <span>Company profile</span>
                    </div>
                    <div uk-grid="" className="uk-grid">
                      <div className="uk-width-1-2">
                        <div className="contentBox">
                          <div className="contentBoxHeading">
                            <span>Company Name</span>
                          </div>
                          <div className="txtwrp">
                            <div className="leftTxt">
                              <p>
                                <span>{data?.companyName || "-"}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="uk-width-1-2">
                        <div className="contentBox">
                          <div className="contentBoxHeading">
                            <span>Industry</span>
                          </div>
                          <div className="txtwrp">
                            <div className="leftTxt">
                              <p>
                                <span>{data?.industry || "-"}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="uk-width-1-2 m-0">
                        <div className="contentBox">
                          <div className="contentBoxHeading">
                            <span>Type</span>
                          </div>
                          <select name="" className="uk-select borderZero">
                            <option value="" selected>
                              {data?.type || "Please select type"}
                            </option>
                            <option value="OWNER">OWNER </option>
                          </select>
                        </div>
                      </div>

                      <div className="uk-width-1-2  m-0">
                        <div className="contentBox">
                          <div className="contentBoxHeading">
                            <span>Domain</span>
                          </div>
                          <div className="txtwrp">
                            <div className="leftTxt">
                              <p>
                                <span>{data?.domain || "-"}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="uk-width-1-2 m-0">
                        <div className="contentBox">
                          <div className="contentBoxHeading">
                            <span>Company contact person name</span>
                          </div>
                          <div className="txtwrp">
                            <div className="leftTxt">
                              <p>
                                <span>{data?.contactPersonName || "-"}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="uk-width-1-2 m-0">
                        <div className="contentBox">
                          <div className="contentBoxHeading">
                            <span>Contact person WhatsApp number</span>
                          </div>
                          <div className="txtwrp">
                            <div className="leftTxt">
                              <p>
                                <span className="disabled">
                                  {data?.contactPersonNumber || "-"}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="adminDetailTabbing">
          <ul uk-tab="connect: .singleCompanyTab" className="tabBtn ">
            <li>
              <a href="/">Package Details</a>
            </li>
          </ul>

          <ul className="uk-switcher uk-margin singleCompanyTab">
            <li>
              <div className="detailFormwrp">
                <form>
                  <div className="uk-grid uk-grid-small" uk-grid="">
                    <div className="uk-width-1-2">
                      <div className="formInput">
                        <label htmlFor="f_name">Package name</label>
                        <input
                          className="uk-input"
                          type="text"
                          placeholder="Package name"
                          value={data?.title}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <div className="formInput">
                        <label htmlFor="f_name">MAU</label>
                        <input
                          className="uk-input"
                          type="text"
                          placeholder="MAU"
                          value={data?.activeUser}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <div className="formInput">
                        <label htmlFor="f_name">Conversation</label>
                        <input
                          className="uk-input"
                          type="text"
                          placeholder="Conversation"
                          value={data?.whatsappConversation}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <div className="formInput">
                        <label htmlFor="f_name">Agent</label>
                        <input
                          className="uk-input"
                          type="text"
                          placeholder="Agent"
                          value={data?.liveAgent}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <div className="formInput">
                        <label htmlFor="f_name">Price</label>
                        <input
                          className="uk-input"
                          type="text"
                          placeholder="Price"
                          value={data?.price}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SingleCompanyView;
