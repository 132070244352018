import React, { useState } from "react";
import chatNotOpenImg from "../../../assets/images/chat-icon.svg";
import checkMarkIcon from "../../../assets/images/done_all_black_24dp.svg";
import {
  WatsonHealthStudyNext,
  WatsonHealthStudyPrevious,
} from "@carbon/icons-react";
import ResolvedModal from "./Modals/ResolvedModal";
import UnassignConversationModal from "./Modals/UnassignConversationModal";
import { removeAndAddActiveClass } from "../../../Utils/jqueryFunctions";
import $ from "jquery";

const ChatNotOpen = ({
  conversationListCount,
  bulkMarkAsResolved,
  handleOpenResolvedModal,
  handleCloseResolvedModal,
  openResolvedModal,
  modalLoader,
  chatBulkMarkAsResolved,
  setChatBulkMarkAsResolved,
  setConversationListCount,
  agentList,
  bulkChatAssign,
  handleOpenUnAssignConversationModal,
  handleCloseUnAssignConversationModal,
  bulkChatUnassigned,
  openUnAssignConversationModal,
  getAgentList,
}) => {
  const [selectedAssignedAgent, setSelectedAssignedAgent] = useState("");

  return (
    <>
      <div className="uk-width-4-5 uk-padding-remove-left">
        <div className="chatSeletionWrp">
          <div className="chatSelectionContent">
            <div className="imgwrp">
              <img src={chatNotOpenImg} alt="" />
            </div>
            <div className="chatHeading">
              <p>{conversationListCount} conversations selected</p>
            </div>
            <div className="btnwrp">
              <ul>
                <li>
                  <button type="button" onClick={handleOpenResolvedModal}>
                    <img src={checkMarkIcon} alt="" />
                    Mark as Solved
                  </button>
                </li>
                <li>
                  <div className="uk-inline">
                    <button type="button" onClick={() => getAgentList()}>
                      <WatsonHealthStudyPrevious /> Assign Conversations
                    </button>
                    <div uk-dropdown="mode: click" className="assignDropdwon">
                      <ul>
                        {agentList.map((val, index) => {
                          return (
                            <li key={index} id={"assigned_id_" + index}>
                              <button
                                className={
                                  selectedAssignedAgent ===
                                  val.firstName + " " + val.lastName
                                    ? "activeBtn"
                                    : ""
                                }
                                disabled={val.status !== "ONLINE"}
                                type="button"
                                onClick={() => {
                                  bulkChatAssign(
                                    val.firstName + " " + val.lastName,
                                    val.publicId
                                  );
                                  setSelectedAssignedAgent(
                                    val.firstName + " " + val.lastName
                                  );
                                  removeAndAddActiveClass(
                                    "assigned_id_" + index,
                                    ".assignDropdwon ul li button.activeBtn",
                                    "activeBtn"
                                  );
                                }}
                              >
                                {/* {val.firstName + " " + val.lastName} */}

                                <div className="agentListItem">
                                  <div className="assignListWrp">
                                    <span
                                      className={
                                        val.status === "ONLINE"
                                          ? "markAgent onlineMark availableMarkone"
                                          : val.status === "AWAY"
                                          ? "markAgent awayMark availableMarkone"
                                          : val.status === "BREAK"
                                          ? "markAgent busyMark availableMarkone"
                                          : val.status === "OFFLINE" &&
                                            "markAgent offlineCheck availableMarkone"
                                      }
                                    ></span>
                                    <span className="agentInitital">
                                      {val.firstName.substring(0, 1)}
                                    </span>
                                    <p>{val.firstName + " " + val.lastName}</p>
                                  </div>
                                </div>
                              </button>
                            </li>
                          );
                        })}
                        {/* <li className="activeBtn">
                          <button type="button">ABBAS facebook</button>
                        </li>
                        <li>
                          <button type="button">ABBAS facebook</button>
                        </li>
                        <li>
                          <button type="button">ABBAS facebook</button>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </li>
                {/* <li>
                  <button
                    type="button"
                    onClick={handleOpenUnAssignConversationModal}
                  >
                    <WatsonHealthStudyNext /> Unassign Conversations
                  </button>
                </li> */}
                <li>
                  <button
                    type="button"
                    className="clearAllBtn"
                    onClick={() => {
                      setConversationListCount(0);
                      $(".chat-sec-content input").prop("checked", false);
                      $('.chatContactsContainer input[type="checkbox"]').prop(
                        "checked",
                        false
                      );
                    }}
                  >
                    Clear Selection
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {openResolvedModal && (
          <ResolvedModal
            conversationListCount={conversationListCount}
            closeModal={handleCloseResolvedModal}
            heading={"Mark as solved"}
            fieldLabel={"Conversations"}
            saveBtnTxt={"Confirm"}
            bulkMarkAsResolved={bulkMarkAsResolved}
            modalLoader={modalLoader}
            chatBulkMarkAsResolved={chatBulkMarkAsResolved}
            setChatBulkMarkAsResolved={setChatBulkMarkAsResolved}
            setConversationListCount={setConversationListCount}
          />
        )}
        {openUnAssignConversationModal && (
          <UnassignConversationModal
            closeModal={handleCloseUnAssignConversationModal}
            heading={"Unassign Conversations"}
            fieldLabel={`This action will affect ${conversationListCount} conversations. Are you sure?`}
            saveBtnTxt={"Confirm"}
            bulkChatUnassigned={bulkChatUnassigned}
            modalLoader={modalLoader}
          />
        )}
      </div>
    </>
  );
};

export default ChatNotOpen;
