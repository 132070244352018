import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { ChevronLeft } from "@carbon/icons-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { apiPut } from "../../../Utils/apiServices";
import { toast } from "react-toastify";

const EditPackage = () => {
  const location = useLocation();

  if (location.state === null) {
    window.location.href = "/super-admin-package-list";
  }

  const navigate = useNavigate();
  const [errorEnable, setErrorEnable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [submittedData, setSubmittedData] = useState({
    title: "",
    price: "",
    mauCharge: "",
    activeUser: "",
    whatsappConversation: "",
    liveAgent: "",
    faqChatBot: 0,
    advChatBot: 0,
    description: "",
    isMonthly: true,
    isActive: true,
    isDeleted: false,
    isPackageActive: false,
    isBucketActive: false,
    businessInitiated: "",
    customerInitiated: "",
    channel: "",
    billingCap: "",
  });

  const editPackage = (e) => {
    e.preventDefault();
    setErrorEnable(true);
    setLoader(true);
    if (submittedData.isBucketActive !== false) {
      if (
        submittedData?.title !== "" &&
        submittedData?.description !== "" &&
        submittedData.isBucketActive !== false &&
        submittedData.price !== "" &&
        submittedData.whatsappConversation !== "" &&
        submittedData.liveAgent !== "" &&
        submittedData.channel !== 0 &&
        submittedData.activeUser !== ""
      ) {
        apiPut(
          `/super-admin/packages/edit/${location?.state?.publicId}`,
          onSuccess,
          onFailure,
          submittedData
        );
      } else {
        setLoader(false);
        toast.error("Please fill all the input fields properly", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } else {
      setLoader(false);
      toast.error(
        "Enable bucket wise package and fill all the fields properly",
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        }
      );
    }
  };

  const onSuccess = (response) => {
    if (response.status === 1) {
      setTimeout(() => {
        setLoader(false);
        toast.success("package updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1000);
      setTimeout(() => {
        navigate("/super-admin-package-list", { replace: true });
      }, 2000);
    }
  };
  const onFailure = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (location.state !== null) {
      setSubmittedData({
        title: location.state.title,
        price: location.state.price,
        activeUser: location.state.activeUser,
        whatsappConversation: location.state.whatsappConversation,
        liveAgent: location.state.liveAgent,
        faqChatBot: location.state.faqChatBot,
        advChatBot: location.state.advChatBot,
        description: location.state.description,
        isMonthly: location.state.isMonthly,
        isActive: location.state.isActive,
        mauCharge: location.state.mauCharge,
        isPackageActive: location.state.isPackageActive,
        isBucketActive: location.state.isBucketActive,
        businessInitiated: location.state.businessInitiated,
        customerInitiated: location.state.customerInitiated,
        conversationCount: location.state.conversationCount,
        channel: location.state.channel,
      });
    }
  }, []);

  return (
    <div className="addTeamWrp">
      <div className="uk-container">
        <div className="backBtn">
          <Link to="/super-admin-package-list">
            <ChevronLeft /> Back to all package
          </Link>
        </div>
        <div className="addTeamHeading">
          <h3>Edit package</h3>
          <p>
            Enter details for the package, assign limits and rates associated
            with the package.
          </p>
        </div>
        <div className="addTeamBox">
          <div className="boxHeading">
            <h3>Package information</h3>
          </div>
          <div className="formwrp">
            <form onSubmit={editPackage} autoComplete="off">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-1">
                  <div className="formInput">
                    <label htmlFor="f_name">Package title</label>
                    <input
                      type="text"
                      placeholder="Package title"
                      className="uk-input"
                      value={submittedData?.title}
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          title: e.target.value,
                        });
                      }}
                    />
                    {errorEnable && submittedData?.title === "" && (
                      <div className="formErrors">Title is required</div>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="formInput">
                    <label htmlFor="f_name">Package Description</label>
                    <textarea
                      type="text"
                      placeholder="Package decription"
                      className="uk-input"
                      value={submittedData?.description}
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          description: e.target.value,
                        });
                      }}
                    />
                    {errorEnable && submittedData?.description === "" && (
                      <div className="formErrors">Description is required</div>
                    )}
                  </div>
                </div>
             
                <div className="uk-width-1-1">
                  <div className="formInput">
                    <label htmlFor="team">Channel</label>

                    <select name="team" className="uk-select">
                      <option
                        selected
                        value={submittedData?.channel}
                        onChange={(e) => {
                          setSubmittedData({
                            ...submittedData,
                            channel: e.target.value,
                          });
                        }}
                      >
                        {submittedData?.channel}
                      </option>
                    </select>
                  </div>
                  {errorEnable && submittedData.channel === "" && (
                    <div className="formErrors">Channel is required</div>
                  )}
                </div>
                <div className="uk-width-1-1">
                  <div className="adminRoleWrp">
                    <div className="switchwrp">
                      <div className="adminHeading">
                        <span>Pay to go package</span>
                      </div>
                      <div className="adminBox">
                        <div className="txtAndSwitchWrp">
                          <div className="txtAndSwitch">
                            <div className="txtwrp">
                              <p>
                                With this configuration, you only pay for what
                                you use, making it a flexible and cost-effective
                                option.
                              </p>
                            </div>
                            <div className="switchwrp">
                              <Switch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                width={26}
                                height={14}
                                handleDiameter={10}
                                onColor="#29A869"
                                onChange={() =>
                                  setSubmittedData({
                                    ...submittedData,
                                    isPackageActive:
                                      !submittedData.isPackageActive,
                                  })
                                }
                                checked={submittedData.isPackageActive}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="uk-grid uk-grid-small" uk-grid="">
                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="team">Per 100 MAU charge</label>

                              <input
                                type="number"
                                className="uk-input"
                                placeholder="MAU charge..."
                                disabled
                                value={submittedData?.mauCharge}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    mauCharge: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="team">
                                Conversation charges (Business initiated)
                              </label>

                              <input
                                type="number"
                                className="uk-input"
                                disabled
                                placeholder="Enter conversation charges (Business initiated)"
                                value={submittedData?.businessInitiated}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    businessInitiated: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="team">
                                Conversation charges (Customer initiated)
                              </label>

                              <input
                                type="number"
                                className="uk-input"
                                disabled
                                value={submittedData?.customerInitiated}
                                placeholder="Enter conversation charges (Customer initiated)"
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    customerInitiated: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="team">Billing cap</label>

                              <input
                                type="number"
                                className="uk-input"
                                disabled
                                placeholder="Enter billing cap"
                                value={submittedData?.billingCap}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    billingCap: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="team">Renewal limit</label>

                              <select
                                name="team"
                                className="uk-select"
                                disabled
                              >
                                <option value="" disabled selected hidden>
                                  Renewal limit
                                </option>
                                <option>Monthly</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="uk-width-1-1">
                  <div className="adminRoleWrp">
                    <div className="switchwrp">
                      <div className="adminHeading">
                        <span>Bucket wise package</span>
                      </div>
                      <div className="adminBox">
                        <div className="txtAndSwitchWrp">
                          <div className="txtAndSwitch">
                            <div className="txtwrp">
                              <p>Build package according to your need.</p>
                            </div>
                            <div className="switchwrp">
                              <Switch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                width={26}
                                height={14}
                                handleDiameter={10}
                                onColor="#29A869"
                                onChange={() =>
                                  setSubmittedData({
                                    ...submittedData,
                                    isBucketActive:
                                      !submittedData.isBucketActive,
                                  })
                                }
                                checked={submittedData.isBucketActive}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="uk-grid uk-grid-small" uk-grid="">
                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="team">Conversation count</label>
                              <input
                                type="text"
                                className="uk-input"
                                placeholder="Conversation count..."
                                value={submittedData?.whatsappConversation}
                                onKeyDown={(e) => {
                                  if (
                                    !/^\d*$/.test(e.key) &&
                                    ![
                                      "Backspace",
                                      "ArrowLeft",
                                      "ArrowRight",
                                      "Delete",
                                    ].includes(e.key)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value === "" || /^\d+$/.test(value)) {
                                    setSubmittedData({
                                      ...submittedData,
                                      whatsappConversation: value,
                                    });
                                  }
                                }}
                              />
                              {errorEnable &&
                                submittedData.isBucketActive &&
                                (submittedData.whatsappConversation === 0 ||
                                  submittedData.whatsappConversation ===
                                    "") && (
                                  <div className="formErrors">
                                    Conversation count is required
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="team">Live agents</label>

                              <input
                                type="text"
                                className="uk-input"
                                placeholder="Number of live agents..."
                                value={submittedData?.liveAgent}
                                onKeyDown={(e) => {
                                  if (
                                    !/^\d*$/.test(e.key) &&
                                    ![
                                      "Backspace",
                                      "ArrowLeft",
                                      "ArrowRight",
                                      "Delete",
                                    ].includes(e.key)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value === "" || /^\d+$/.test(value)) {
                                    setSubmittedData({
                                      ...submittedData,
                                      liveAgent: value,
                                    });
                                  }
                                }}
                              />
                              {errorEnable &&
                                submittedData.isBucketActive &&
                                (submittedData.liveAgent === 0 ||
                                  submittedData.liveAgent === "") && (
                                  <div className="formErrors">
                                    Live agent is required
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="team">MAU count</label>
                              <input
                                type="text"
                                className="uk-input"
                                placeholder="Enter MAU count"
                                value={submittedData?.activeUser}
                                onKeyDown={(e) => {
                                  if (
                                    !/^\d*$/.test(e.key) &&
                                    ![
                                      "Backspace",
                                      "ArrowLeft",
                                      "ArrowRight",
                                      "Delete",
                                    ].includes(e.key)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value === "" || /^\d+$/.test(value)) {
                                    setSubmittedData({
                                      ...submittedData,
                                      activeUser: value,
                                    });
                                  }

                                }}
                              />
                              {errorEnable &&
                                submittedData.isBucketActive &&
                                (submittedData.activeUser === 0 ||
                                  submittedData.activeUser === "") && (
                                  <div className="formErrors">
                                    Active user is required
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="team">Price</label>
                              <input
                                type="text"
                                className="uk-input"
                                placeholder="Enter price..."
                                value={submittedData?.price}
                                onKeyDown={(e) => {
                                  if (
                                    !/^\d*$/.test(e.key) &&
                                    ![
                                      "Backspace",
                                      "ArrowLeft",
                                      "ArrowRight",
                                      "Delete",
                                    ].includes(e.key)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value === "" || /^\d+$/.test(value)) {
                                    setSubmittedData({
                                      ...submittedData,
                                      price: value,
                                    });
                                  }
                                }}
                              />
                              {errorEnable &&
                                submittedData.isBucketActive &&
                                (submittedData.price === 0 ||
                                  submittedData.price === "") && (
                                  <div className="formErrors">
                                    Price is required
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="uk-width-1-1">
                  <div className="adminRoleWrp">
                    <div className="btnwrp">
                      <button className="btn-2 w-120" type="submit">
                        {loader ? (
                          <div uk-spinner="" className="loader"></div>
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPackage;
