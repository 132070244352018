import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";

const RoleTab = ({
  errorEnable,
  updateAgent,
  adminPermissions,
  superVisorPermissions,
  agentPermissions,
  adminSelectedPermissions,
  supervisorSelectedPermissions,
  agentSelectedPermissions,
  handleAdminPermission,
  handleSupervisorPermission,
  handleAgentPermission,
  genericRoute,
  setGenericRoute,
  loader,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleGenericRoute = () => {
    setGenericRoute(!genericRoute);
  };
  return (
    <div className="aminRoleTab">
      <div className="adminRoleWrp">
        <div className="adminHeading">
          <span>Generic User</span>
        </div>
        <div className="adminBox">
          <div className="txtAndSwitchWrp">
            <div className="txtAndSwitch">
              <div className="txtwrp">
                <p>
                  These agents exclusively handle chats routed to their
                  allocated channels.
                </p>
              </div>
              <div className="switchwrp">
                <Switch
                  onChange={() => {
                    handleGenericRoute();
                  }}
                  checked={genericRoute}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  width={26}
                  height={14}
                  handleDiameter={10}
                  onColor="#29A869"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="adminRoleWrp">
        <div className="adminHeading">
          <span>Admin Role</span>
        </div>
        <div className="adminBox">
          {/* {adminSelectedPermissions.filter((val) => val === "ALL-PERMISSION") */}
          {/* {userDetails?.agentPermissions?.filter(
            (val) => val?.permission?.name === "ALL-PERMISSION"
          ).length === 0 && ( */}

          {adminSelectedPermissions.filter((val) => val === "ALL-PERMISSION")
            .length === 0 && (
            <div className="txtAndSwitchWrp">
              <div className="txtAndSwitch">
                <div className="txtwrp">
                  <p>{adminPermissions[0].text}</p>
                </div>
                <div className="switchwrp">
                  <Switch
                    onChange={() => {
                      handleAdminPermission(
                        0,
                        "ALL-PERMISSION",
                        adminPermissions[0]["checked"]
                      );
                    }}
                    checked={adminPermissions[0]["checked"]}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={26}
                    height={14}
                    handleDiameter={10}
                    onColor="#29A869"
                  />
                </div>
              </div>
            </div>
          )}
          {/* {userDetails?.agentPermissions?.filter(
            (val) => val?.permission?.name === "ALL-PERMISSION"
          ).length > 0 && */}
          {adminSelectedPermissions?.filter((val) => val === "ALL-PERMISSION")
            .length > 0 &&
            adminPermissions?.map((val, index) => {
              return (
                <React.Fragment key={index}>
                  {val.innerHeading !== "" && (
                    <div className="boxInnerHeading">
                      <span>{val.innerHeading}</span>
                    </div>
                  )}
                  <div className="txtAndSwitchWrp">
                    {val.heading !== null && (
                      <div className="boxHeading">
                        <h3>{val.heading}</h3>
                      </div>
                    )}
                    <div className="txtAndSwitch">
                      <div className="txtwrp">
                        <p>{val.text}</p>
                      </div>
                      <div className="switchwrp">
                        <Switch
                          onChange={() => {
                            handleAdminPermission(
                              index,
                              val.roleName,
                              val.checked
                            );
                          }}
                          checked={
                            val.roleName === "LIVE-CHAT-AGENT" ||
                            val.roleName === "FORM-ACCESS-MANAGER"
                              ? val.checked
                              : true
                          }
                          uncheckedIcon={false}
                          checkedIcon={false}
                          width={26}
                          height={14}
                          handleDiameter={10}
                          onColor="#29A869"
                        />
                      </div>
                    </div>
                  </div>
                  {val.hr !== false && <hr />}
                </React.Fragment>
              );
            })}
        </div>
      </div>
      <div className="adminRoleWrp">
        <div className="adminHeading">
          <span>Supervisor</span>
        </div>

        <div className="adminBox">
          {supervisorSelectedPermissions.filter((val) => val === "SUPERVISOR")
            .length === 0 && (
            <div className="txtAndSwitchWrp">
              <div className="txtAndSwitch">
                <div className="txtwrp">
                  <p>{superVisorPermissions[0].text}</p>
                </div>
                <div className="switchwrp">
                  <Switch
                    onChange={() => {
                      handleSupervisorPermission(
                        0,
                        "SUPERVISOR",
                        superVisorPermissions[0]["checked"]
                      );
                    }}
                    checked={superVisorPermissions[0]["checked"]}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={26}
                    height={14}
                    handleDiameter={10}
                    onColor="#29A869"
                  />
                </div>
              </div>
            </div>
          )}
          {supervisorSelectedPermissions.filter((val) => val === "SUPERVISOR")
            .length > 0 &&
            superVisorPermissions.map((val, index) => {
              return (
                <React.Fragment key={index}>
                  {val.innerHeading !== "" && (
                    <div className="boxInnerHeading">
                      <span>{val.innerHeading}</span>
                    </div>
                  )}
                  <div className="txtAndSwitchWrp">
                    {val.heading !== null && (
                      <div className="boxHeading">
                        <h3>{val.heading}</h3>
                      </div>
                    )}
                    <div className="txtAndSwitch">
                      <div className="txtwrp">
                        <p>{val.text}</p>
                      </div>
                      <div className="switchwrp">
                        <Switch
                          onChange={() => {
                            handleSupervisorPermission(
                              index,
                              val.roleName,
                              val.checked
                            );
                          }}
                          checked={
                            val.roleName === "ACCOUNT-MANAGER"
                              ? true
                              : val.checked
                          }
                          uncheckedIcon={false}
                          checkedIcon={false}
                          width={26}
                          height={14}
                          handleDiameter={10}
                          onColor="#29A869"
                        />
                      </div>
                    </div>
                  </div>
                  {val.hr !== false && <hr />}
                </React.Fragment>
              );
            })}
        </div>
      </div>
      <div className="adminRoleWrp">
        <div className="adminHeading">
          <span>Agent</span>
        </div>

        <div className="adminBox">
          {agentSelectedPermissions.filter((val) => val === "AGENT").length ===
            0 && (
            <div className="txtAndSwitchWrp">
              <div className="txtAndSwitch">
                <div className="txtwrp">
                  <p>{agentPermissions[0].text}</p>
                </div>
                <div className="switchwrp">
                  <Switch
                    onChange={() => {
                      handleAgentPermission(
                        0,
                        "AGENT",
                        agentPermissions[0]["checked"]
                      );
                    }}
                    checked={agentPermissions[0]["checked"]}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={26}
                    height={14}
                    handleDiameter={10}
                    onColor="#29A869"
                  />
                </div>
              </div>
            </div>
          )}
          {agentSelectedPermissions.filter((val) => val === "AGENT").length >
            0 &&
            agentPermissions.map((val, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="txtAndSwitchWrp">
                    {val.heading !== null && (
                      <div className="boxHeading">
                        <h3>{val.heading}</h3>
                      </div>
                    )}
                    <div className="txtAndSwitch">
                      <div className="txtwrp">
                        <p>{val.text}</p>
                      </div>
                      <div className="switchwrp">
                        <Switch
                          onChange={() => {
                            handleAgentPermission(
                              index,
                              val.roleName,
                              val.checked
                            );
                          }}
                          checked={
                            val.roleName === "ACCOUNT-MANAGER"
                              ? true
                              : val.checked
                          }
                          uncheckedIcon={false}
                          checkedIcon={false}
                          width={26}
                          height={14}
                          handleDiameter={10}
                          onColor="#29A869"
                        />
                      </div>
                    </div>
                  </div>
                  {val.hr !== false && <hr />}
                </React.Fragment>
              );
            })}
        </div>
      </div>
      {errorEnable &&
        adminSelectedPermissions.filter((val) => val === "ALL-PERMISSION")
          .length === 0 &&
        supervisorSelectedPermissions.filter((val) => val === "SUPERVISOR")
          .length === 0 &&
        agentSelectedPermissions.filter((val) => val === "AGENT").length ===
          0 && <div className="formErrors">Please select role</div>}
      {errorEnable &&
        adminSelectedPermissions.filter((val) => val === "ALL-PERMISSION")
          .length === 0 &&
        supervisorSelectedPermissions.filter((val) => val === "SUPERVISOR")
          .length !== 0 &&
        supervisorSelectedPermissions.length < 3 &&
        agentSelectedPermissions.filter((val) => val === "AGENT").length ===
          0 && (
          <div className="formErrors">
            Please select atleast one supervisor permisssion
          </div>
        )}
      {errorEnable &&
        adminSelectedPermissions.filter((val) => val === "ALL-PERMISSION")
          .length === 0 &&
        supervisorSelectedPermissions.filter((val) => val === "SUPERVISOR")
          .length === 0 &&
        agentSelectedPermissions.filter((val) => val === "AGENT").length !==
          0 &&
        agentSelectedPermissions.length < 4 && (
          <div className="formErrors">
            Please select atleast one agent permisssion
          </div>
        )}
      <div className="adminDetailBtn uk-margin-top">
        <button
          type="submit"
          onClick={() =>
            location.pathname.includes("profile") === true
              ? navigate("/dashboard", { replace: true })
              : navigate("/users", { replace: true })
          }
          className="btn-2"
        >
          Cancel
        </button>
        <button type="submit" onClick={updateAgent} className="btn-1 w-90">
          {loader ? <div uk-spinner="" className="loader"></div> : "Save"}
        </button>
      </div>

      {/* <div className="adminRoleWrp">
        <div className="btnwrp">
          <button className="btn-2" onClick={addUser}>
            Save
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default RoleTab;
