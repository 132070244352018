import React from "react";
import chatNotOpenImg from "../../../assets/images/chat-icon.svg";

const ChatMsgBeforeLoadScreen = () => {
  return (
    <>
      <div className="uk-width-4-5 uk-padding-remove-left">
        <div className="chatSeletionWrp">
          <div className="chatSelectionContent">
            <div className="imgwrp">
              <img src={chatNotOpenImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatMsgBeforeLoadScreen;
