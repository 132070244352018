import React, { useEffect } from "react";
import { apiPost, apiPut } from "../../../../Utils/apiServices";
import { useState } from "react";
import { toast } from "react-toastify";

const NewQuickReplyModal = ({
  closeModal,
  setApiCalled,
  heading,
  description,
  btnText,
  editData,
}) => {
  const [loader, setLoader] = useState(false);
  const [errorEnable, setErrorEnable] = useState(false);
  const [quickText, setQuickText] = useState("");
  const [priority, setPriority] = useState("");
  const [subject, setSubject] = useState("");

  const addQuickReplyMessage = () => {
    setLoader(true);
    setErrorEnable(true);
    let data = {
      priority: priority,
      subject: subject,
    };
    if (btnText === "Update") {
      data.id = editData?.id;
      data.text = quickText;
    } else {
      data.quickText = quickText;
    }
    if (quickText !== "" && priority !== "" && subject !== "") {
      if (btnText !== "Update") {
        apiPost(`/settings/quick-reply`, onSucces, onFailure, data);
      } else {
        apiPut(`/settings/quick-reply`, onSucces, onFailure, data);
      }
    } else if (btnText === "Update") {
      closeModal();
    } else {
      setLoader(false);
    }
  };
  const onSucces = (response) => {
    setTimeout(() => {
      setApiCalled(true);
      setLoader(false);
      toast.success(
        btnText === "Update"
          ? "Quick reply updated successfully"
          : "Quick reply added successfully",
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        }
      );
    }, 1000);
    setTimeout(() => {
      closeModal();
    }, 2000);
  };
  const onFailure = (error) => {
    console.log(error);
  };
  useEffect(() => {
    if (editData !== undefined) {
      setQuickText(editData.text);
      setPriority(editData.priority);
      setSubject(editData.subject);
    }
  }, [editData]);
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>{heading}</span>
          </div>
          <div className="modalForm">
            <div className="segmentModalTxt">
              <span>{description}</span>
            </div>
            <form action="">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <label htmlFor="">Subject</label>
                    <input
                      type="text"
                      className="uk-input"
                      style={{ width: "100%" }}
                      placeholder="Please enter message subject"
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                      defaultValue={editData?.subject}
                    />
                    {subject === "" && errorEnable && (
                      <div className="formErrors">Subject is required</div>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <label htmlFor="">Quick reply message</label>
                    {/* <input
                      type="text"
                      className="uk-input"
                      style={{ width: "100%" }}
                      placeholder="Please enter quick message"
                      onChange={(e) => {
                        setQuickText(e.target.value);
                      }}
                      defaultValue={editData?.text}
                    /> */}
                    <textarea
                      name=""
                      className="uk-textarea"
                      type="textarea"
                      style={{ width: "100%" }}
                      placeholder="Please enter quick message"
                      onChange={(e) => {
                        setQuickText(e.target.value);
                      }}
                      defaultValue={editData?.text}
                    ></textarea>
                   
                    {quickText === "" && errorEnable && (
                      <div className="formErrors">
                        Quick message is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <label htmlFor="">Priority</label>
                    <input
                      type="number"
                      className="uk-input"
                      style={{ width: "100%" }}
                      placeholder="Please enter message priority"
                      onChange={(e) => {
                        setPriority(e.target.value);
                      }}
                      defaultValue={editData?.priority}
                    />
                    {priority === "" && errorEnable && (
                      <div className="formErrors">
                        Message priority is required
                      </div>
                    )}
                  </div>
                </div>

                <div className="uk-width-1-1">
                  <div className="btnwrp">
                    <button
                      type="button"
                      className="btn-1"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn-2"
                      onClick={addQuickReplyMessage}
                    >
                      {loader ? (
                        <div uk-spinner="" className="loader"></div>
                      ) : (
                        btnText
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewQuickReplyModal;
