import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { apiDelete, apiGet } from "../../Utils/apiServices";
import { OverflowMenuVertical, TrashCan } from "@carbon/icons-react";
import { toast } from "react-toastify";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";

const ListEmployees = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const navigate = useNavigate();
  const [treetData, setTreetData] = useState(null);

  // ************************************* apis start ******************************

  const getTreetEmployeeData = () => {
    apiGet(`/treat/get-employee`, onSuccess, onFailure);
  };
  const onSuccess = (response) => {
    setTreetData(response);
  };
  const onFailure = (error) => {
    console.log(error);
  };

  const deleteEmployee = (phoneNumber) => {
    apiDelete(
      `/treat/delete-employee/${phoneNumber}`,
      onSuccessDelete,
      onFailureDelete
    );
  };
  const onSuccessDelete = (response) => {
    if (response) {
      setTimeout(() => {
        toast.success("User deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1000);
      setTimeout(() => {
        getTreetEmployeeData();
      }, 2000);
    }
  };
  const onFailureDelete = (error) => {
    console.log(error);
  };

  // ************************************* apis end ******************************

  // ************************************* useeffect start ******************************

  useEffect(() => {
    getTreetEmployeeData(0);
  }, []);

  // ************************************* useeffect end ******************************

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="boradcastWrp">
        <div className="boradcastTabbing">
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #broadcastTabs"
          >
            <li>
              <a>Employee</a>
            </li>
          </ul>
          <ul className="broadcastTopBtn uk-switcher" id="broadcastTabs">
            <li>
              <button
                type="button"
                onClick={() => navigate("/import-employee", { replace: true })}
              >
                Create Employee
              </button>
            </li>
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="broadcastTabs">
            <li>
              <div className="broadcastTabWrp">
                <div className="uk-container">
                  <div className="mainHeading">
                    <h2>List Employee </h2>
                  </div>

                  <div className="analyticsTableWrp">
                    <div className="uk-grid" uk-grid=""></div>
                    <table className="uk-table">
                      <thead>
                        <tr>
                          <th>NUMBER</th>
                          <th>NAME</th>
                          <th>PHONE NUMBER</th>
                          <th>CREATE DATE TIME</th>
                          <th>UPDATE DATE TIME</th>
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {treetData?.length > 0 ? (
                          treetData.map((val, index) => {
                            return (
                              <tr key={index}>
                                <td>{val.employeeNumber}</td>
                                <td>{val.employeeName}</td>
                                <td>{val?.phoneNumber}</td>
                                <td>{val.createDateTime}</td>
                                <td>
                                  {val.updateDateTime === null
                                    ? val.createDateTime
                                    : val.updateDateTime}
                                </td>
                                <td>
                                  <div className="uk-inline">
                                    <button type="button">
                                      <OverflowMenuVertical />
                                    </button>
                                    <div
                                      uk-dropdown=""
                                      className="contactBlckDrop"
                                    >
                                      <ul>
                                        <li>
                                          <button
                                            className="trashBtn"
                                            type="button"
                                            onClick={() => {
                                              deleteEmployee(val.phoneNumber);
                                            }}
                                          >
                                            <TrashCan /> Delete
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={11} className="dataNotFound">
                              {treetData === null ? (
                                <div uk-spinner=""></div>
                              ) : (
                                "Data Not Found"
                              )}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="tablePaginationBox "></div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ListEmployees;
