import { Time } from "@carbon/icons-react";
import React from "react";
import { contactTimeInternal } from "../../../Utils/DateFunctions";

const InternalChatProfile = ({ agentDetails }) => {
  return (
    <div className="uk-width-1-5 uk-padding-remove">
      <ul className="right-sec">
        <li className="namediv">
          <div className="txtwrp">
            <p>{agentDetails?.customerName}</p>
          </div>
          <button className="uk-button uk-button-default" type="button">
            <span uk-icon="more"></span>
          </button>
          <div uk-dropdown="mode: click">
            <ul className="uk-nav uk-dropdown-nav drop-content">
              <li
              //  onClick={() => handleOpenChateditModal()}
              >
                <a>
                  <div className="iconwrp">
                    <span uk-icon="pencil"></span>
                  </div>
                  <div className="txtdiv">
                    <span>Edit</span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li className="infodiv">
          <ul>
            <li>
              <div className="txtdiv">
                <span>
                  <Time /> Contact Time:{" "}
                  {contactTimeInternal(agentDetails?.timestamp) ===
                  "Invalid Date"
                    ? "-"
                    : contactTimeInternal(agentDetails?.timestamp)}
                </span>
              </div>
            </li>
            <li>
              <div className="txtdiv">
                <span>
                  <Time /> Created:{" "}
                  {contactTimeInternal(agentDetails?.timestamp) ===
                  "Invalid Date"
                    ? "-"
                    : contactTimeInternal(agentDetails?.timestamp)}
                </span>
              </div>
            </li>
          </ul>
        </li>

        <li className="opted-sec">
          <div className="heading">
            <p>Opted in through</p>
          </div>
          <div className="btnwrp">
            <button type="button">
              <i className="fa-solid fa-hand"></i> Get Started
            </button>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default InternalChatProfile;
