import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import {
  Calendar,
  Chat,
  ChevronDown,
  Download,
  Forum,
} from "@carbon/icons-react";
import whatsappLogo from "../../assets/images/whatsapp-icon-new.jpg";
import messengerIcon from "../../assets/images/messenger-icon-new.jpg";
import instaIcon from "../../assets/images/instagram-icon-new.jpg";
import demoImg from "../../assets/images/avatar-02.png";
import moment from "moment/moment";
import UIkit from "uikit";
import DoughnutChart from "../../components/Graph/DoughnutChart";
import CalendarDate from "../../components/Calendar/CalendarDate";
import LineChart from "../../components/Graph/LineChart";
import { apiGet } from "../../Utils/apiServices";
import axios from "axios";
import { useAuth } from "../../Context/AuthProvider";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";
import DynamicDoughnutChart from "../../components/Graph/DynamicDoughChart";
import { generateColors } from "../../Utils/GraphFunctions";
import CustomizedProgressBar from "../../components/Graph/CustomizedProgressBar";
import { hasPermissions } from "../../Utils/RoutePermissions";
const baseUrl = process.env.REACT_APP_BASEURL;

const ChatbotAnalytics = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const CurrentCompanyName = CompanyName(CompanyHtml);
  const { auth } = useAuth();
  const [isSelected, setIsSelected] = useState(false);
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [selectedDateFilter, setSelectedDateFilter] = useState("This month");
  const [isApiCall, setIsApiCall] = useState(false);
  const [channel, setChannel] = useState("ALL");
  const [chatbotStats, setChatbotStats] = useState(null);
  const [chatFilterStats, setChatFilterStats] = useState(null);
  const [whatsappBotStats, setWhatsappBotStats] = useState({
    totalConversations: 0,
    window: {},
    labels: [],
    data: [],
    isCalled: false,
  });
  const colorForBotStats =
    Object.keys(whatsappBotStats?.window).length > 0 &&
    generateColors(Object.keys(whatsappBotStats?.window).length);
  const navigate = useNavigate();
  const pageTabs = [
    {
      route: "/analytics",
      name: "Overview",
      permissions: ["FINANCE-DATA-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/live-chat-analytics",
      name: "Live Chat",
      permissions: ["ANALYTICS-MANAGER", "LIVE-CHAT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/chatbot-analytics",
      name: "Chatbot",
      permissions: ["CHATBOT-SUPERVISOR", "CHAT-BOT-MANAGER"],
      active: true,
      allPermissionsMandatory: true,
    },
    {
      route: "/statistics",
      name: "Statistics",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/package-mau",
      name: "Package",
      permissions: ["ANALYTICS-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/orders",
      name: "Orders",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/transfer-logs",
      name: "Transfer Logs",
      permissions: [],
      active: false,
      allPermissionsMandatory: true,
    },
  ];
  const filterDropdown = [
    // "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];

  const handleDatePickerClose = () => {
    if (selectedDateFilter === "Custom range") {
      setIsSelected(true);
    }
    UIkit.drop("#datepickerdrop").hide(true);
  };

  const clearAllstates = () => {
    setChatbotStats(null);
    setWhatsappBotStats({
      window: [],
      totalConversations: [],
      labels: [],
      data: [],
      isCalled: false,
    });
  };
  const exportData = async () => {
    try {
      const response = await axios.get(
        baseUrl + `/bot/export/stats/filter/${STARTDATE}/${ENDDATE}`,
        {
          responseType: "arraybuffer", // Ensure the response is treated as binary data
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Chatbot.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  const onChange = (ranges) => {
    // setChatbotStats(null)
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
  };

  const handleDateFilter = (filter) => {
    setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    if (filter !== "Custom range") {
      setIsApiCall(true);
    }
  };

  const channels = [
    {
      icon: null,
      name: "All Channels",
      apiSendName: "ALL",
      disabled: false,
    },
    // {
    //   icon: whatsAppImg,
    //   name: "WhatsApp",
    //   apiSendName: "WHATSAPP",
    //   disabled: false,
    // },
    // {
    //   icon: messengerIcon,
    //   name: "Facebook",
    //   apiSendName: "FACEBOOK",
    //   disabled: true,
    // },
    // {
    //   icon: instaIcon,
    //   name: "Instagram",
    //   apiSendName: "INSTAGRAM",
    //   disabled: true,
    // },
  ];

  // const getChatbotStats = () => {
  //   apiGet(`/bot/stats`, onSuccessStats, onFailureStats);
  // };

  // const onSuccessStats = (response) => {
  //   setIsSelected(false);
  //   setIsApiCall(false);
  //   setChatbotStats(response?.data);
  // };

  // const onFailureStats = (error) => {
  //   console.log(error);
  // };

  const getChatBotAnalytics = () => {
    apiGet(
      `/detail-analytics/chatBotOverview/${STARTDATE}/${ENDDATE}`,
      onSuccessChatBotAnalytics,
      onFailureChatBotAnalytics
    );
  };

  const onSuccessChatBotAnalytics = (response) => {
    setIsApiCall(false);
    setIsSelected(false);
    // console.log(response);
    setChatbotStats(response?.data);
  };

  const onFailureChatBotAnalytics = (error) => {
    console.log(error);
  };

  const getChatbotStatsFilter = () => {
    apiGet(
      `/bot/stats/filter/${STARTDATE}/${ENDDATE}`,
      onSuccessStatsFilter,
      onFailureStatsFilter
    );
  };

  const onSuccessStatsFilter = (response) => {
    // setIsApiCall(false);
    let data = response.data;

    setChatFilterStats(response?.data);
    const groupByWindowName = (data) => {
      return data.reduce((acc, item) => {
        const windowName = item.windowName.trim(); // Trim to handle extra spaces
        if (!acc[windowName]) {
          acc[windowName] = [];
        }
        acc[windowName].push(item);
        return acc;
      }, {});
    };

    const groupedArrays = groupByWindowName(data);
    // console.log(groupedArrays);

    const totalConversations = data?.reduce(
      (sum, item) => sum + item.totalChats,
      0
    );
    // console.log(totalConversations);

    let labels = [];
    let graphdata = [];
    Object.keys(groupedArrays).map((val) => {
      labels.push(val);

      const sum = groupedArrays[val]?.reduce(
        (sum, item) => sum + item.totalChats,
        0
      );

      graphdata.push(sum);
    });
    // console.log(labels);
    // console.log(graphdata);
    // console.log(groupedArrays)
    setWhatsappBotStats({
      window: groupedArrays,
      totalConversations: totalConversations,
      labels: labels,
      data: graphdata,
      isCalled: true,
    });
  };

  const onFailureStatsFilter = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getChatBotAnalytics();
    getChatbotStatsFilter();
  }, []);

  useEffect(() => {
    if (isSelected === true) {
      setIsApiCall(true);
    }
  }, [isSelected]);
  useEffect(() => {
    if (isApiCall === false) {
      setIsSelected(false);
    }
  }, [isApiCall]);

  useEffect(() => {
    if (isApiCall == true) {
      if (selectedDateFilter === "Custom range") {
        if (isSelected === true) {
          setChatbotStats(null);
          getChatBotAnalytics();
          getChatbotStatsFilter();
        }
      } else {
        setChatbotStats(null);
        getChatBotAnalytics();
        getChatbotStatsFilter();
      }
    }
  }, [isApiCall]);

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="boradcastWrp">
        <div className="boradcastTabbing">
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #newAnalyticsTab"
          >
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                <li
                  onClick={() => {
                    navigate(val.route, { replace: true });
                  }}
                  className={val.active === true ? "uk-active" : ""}
                  key={index}
                >
                  <a>{val.name}</a>
                </li>
              ) : null;
            })}
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="newAnalyticsTab">
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                val.name === "Chatbot" ? (
                  <li key={index}>
                    <div className="overviewContent">
                      <div className="uk-container">
                        <div className="uk-grid uk-flex-middle" uk-grid="">
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsTabWrp"
                              style={{ margin: "0 0 20px" }}
                            >
                              <div className="whatappTabBtn">
                                <ul
                                  className="uk-subnav uk-subnav-pill"
                                  uk-switcher="connect: .whatsappTabBtn"
                                >
                                  <li
                                    onClick={() => {
                                      navigate("/chatbot-analytics", {
                                        replace: true,
                                      });
                                    }}
                                    className="uk-active"
                                  >
                                    <a>Overview</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/chatbot-session-analytics", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Session analytics</a>
                                  </li>
                                  {/* <li>
                              <a href="#">Conversational analytics</a>
                            </li> */}
                                  <li
                                    onClick={() => {
                                      navigate("/chatbot-user-analytics", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>User analytics</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsWhatsappContent"
                              style={{ marginTop: "16px" }}
                            >
                              <ul className="uk-switcher uk-margin whatsappTabBtn">
                                <li>
                                  <div
                                    className="overviewContent uk-padding-remove"
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <div className="uk-container">
                                      <div className="overviewTopDropdown">
                                        <div
                                          className="uk-grid uk-flex-middle"
                                          uk-grid=""
                                        >
                                          <div className="uk-width-1-2">
                                            <div className="pageHeading uk-margin-remove-bottom">
                                              <h3>
                                                Chatbot Overview{" "}
                                                <CurrentCompanyName
                                                  companyDetails={
                                                    companyDetails
                                                  }
                                                />
                                              </h3>
                                            </div>
                                          </div>

                                          <div className="uk-width-1-2 btnSection">
                                            {/* <button
                                          className="exportBtn"
                                          onClick={exportData}
                                        >
                                          <Download /> Export Data
                                        </button> */}
                                            <div className="uk-inline">
                                              <div className="agentFilterWrp">
                                                <div className="allChannelDropDown">
                                                  <div className="uk-inline tableFilterDropdown broadcastChannelFilter">
                                                    {/* <button
                                              type="button"
                                              className="dropdownBtn"
                                            >
                                              All Active Channels{" "}
                                              <ChevronDown />
                                            </button> */}
                                                    <div
                                                      uk-dropdown="mode: click"
                                                      className="dropdownNav"
                                                    >
                                                      <span className="dropdownHeading">
                                                        Channels
                                                      </span>
                                                      <ul>
                                                        {channels.map(
                                                          (val, index) => {
                                                            return (
                                                              <li
                                                                key={index}
                                                                className={
                                                                  channel ===
                                                                  val.apiSendName
                                                                    ? "activeBtn"
                                                                    : ""
                                                                }
                                                                onClick={() => {
                                                                  setChannel(
                                                                    val.apiSendName
                                                                  );
                                                                }}
                                                              >
                                                                <button
                                                                  type="button"
                                                                  disabled={
                                                                    val.disabled ===
                                                                    true
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  className={
                                                                    val.disabled ===
                                                                    true
                                                                      ? "opactityBtn"
                                                                      : ""
                                                                  }
                                                                >
                                                                  {index !==
                                                                    0 && (
                                                                    <img
                                                                      src={
                                                                        val.icon
                                                                      }
                                                                      alt=""
                                                                    />
                                                                  )}
                                                                  {val.name}
                                                                </button>
                                                              </li>
                                                            );
                                                          }
                                                        )}
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="uk-inline">
                                                  <button className="calenderBtn rangeBtn">
                                                    <Calendar />{" "}
                                                    {selectedDateFilter}
                                                    <span className="uk-inline">
                                                      <ChevronDown />
                                                    </span>
                                                  </button>
                                                  <div
                                                    uk-dropdown="mode: click"
                                                    className="calendarDropdown"
                                                    id="dateDropdownSection"
                                                  >
                                                    <div className="dropDownHeading">
                                                      <span>
                                                        Select a range
                                                      </span>
                                                    </div>
                                                    <ul>
                                                      {filterDropdown.map(
                                                        (val, index) => {
                                                          const isLastItem =
                                                            filterDropdown.length -
                                                              1 ===
                                                            index;
                                                          const isSecondLastItem =
                                                            filterDropdown.length -
                                                              2 ===
                                                            index;

                                                          return (
                                                            <React.Fragment
                                                              key={index}
                                                            >
                                                              <li
                                                                className={
                                                                  selectedDateFilter ===
                                                                  val
                                                                    ? "activeBtn"
                                                                    : ""
                                                                }
                                                                onClick={() => {
                                                                  setSelectedDateFilter(
                                                                    val
                                                                  );
                                                                  if (
                                                                    val !==
                                                                    "Custom range"
                                                                  ) {
                                                                    clearAllstates();
                                                                  }
                                                                  handleDateFilter(
                                                                    val
                                                                  );
                                                                }}
                                                              >
                                                                {isLastItem ? (
                                                                  <div className="uk-inline uk-text-left">
                                                                    <button
                                                                      className="rangeBtn"
                                                                      type="button"
                                                                    >
                                                                      <span className="rangeBtnCircle"></span>{" "}
                                                                      Custom
                                                                      range
                                                                    </button>
                                                                    <div
                                                                      id="datepickerdrop"
                                                                      uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                                      className="date-picker-container"
                                                                    >
                                                                      <CalendarDate
                                                                        onChange={
                                                                          onChange
                                                                        }
                                                                      />
                                                                      <div className="date-picker-btn-container uk-text-right">
                                                                        <button
                                                                          className="date-selection-btn"
                                                                          onClick={
                                                                            handleDatePickerClose
                                                                          }
                                                                        >
                                                                          Ok
                                                                        </button>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                ) : (
                                                                  <button
                                                                    type="button"
                                                                    className="rangeBtn"
                                                                  >
                                                                    <span className="rangeBtnCircle"></span>{" "}
                                                                    {val}
                                                                  </button>
                                                                )}
                                                              </li>
                                                              {isSecondLastItem && (
                                                                <hr
                                                                  style={{
                                                                    margin:
                                                                      "5px 0",
                                                                  }}
                                                                />
                                                              )}
                                                            </React.Fragment>
                                                          );
                                                        }
                                                      )}
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="uk-width-1-1 uk-margin-remove">
                                            <div className="overviewMainContent">
                                              <div className="mainBox">
                                                <div className="boxHeading">
                                                  <span>Summary</span>
                                                </div>
                                                <div className="boxContent">
                                                  <div
                                                    className="uk-grid"
                                                    uk-grid=""
                                                  >
                                                    <div className="uk-width-1-5 uk-first-column">
                                                      <div className="txtwrp">
                                                        <h2>
                                                          {chatbotStats ===
                                                          null ? (
                                                            <span uk-spinner=""></span>
                                                          ) : (
                                                            chatbotStats?.totalActiveChatBot.toFixed(
                                                              0
                                                            )
                                                          )}
                                                        </h2>
                                                        <span>
                                                          Active chatbot
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-1-5">
                                                      <div className="txtwrp">
                                                        <h2>
                                                          {chatbotStats ===
                                                          null ? (
                                                            <span uk-spinner=""></span>
                                                          ) : (
                                                            chatbotStats?.numberOfChats.toFixed(
                                                              0
                                                            )
                                                          )}
                                                        </h2>
                                                        <span>
                                                          Number of chats
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-1-5">
                                                      <div className="txtwrp">
                                                        <h2>
                                                          {chatbotStats ===
                                                          null ? (
                                                            <span uk-spinner=""></span>
                                                          ) : (
                                                            chatbotStats?.newUser.toFixed(
                                                              0
                                                            )
                                                          )}
                                                        </h2>
                                                        <span>New User</span>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-1-5">
                                                      <div className="txtwrp">
                                                        <h2>
                                                          {chatbotStats ===
                                                          null ? (
                                                            <span uk-spinner=""></span>
                                                          ) : (
                                                            chatbotStats?.returningUser.toFixed(
                                                              0
                                                            )
                                                          )}
                                                        </h2>
                                                        <span>
                                                          Returning user
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-1-5">
                                                      <div className="txtwrp">
                                                        <h2>
                                                          {chatbotStats ===
                                                          null ? (
                                                            <span uk-spinner=""></span>
                                                          ) : (
                                                            chatbotStats?.avgChat.toFixed(
                                                              0
                                                            )
                                                          )}
                                                        </h2>
                                                        <span>
                                                          Avg chat per user
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="halfDonutSec halfDonutSize">
                                                <div
                                                  className="uk-grid"
                                                  uk-grid=""
                                                  uk-height-match="target: > div > .halfDonutHeight"
                                                >
                                                  <div className="uk-width-1-2">
                                                    <div className="mainBox halfDonutHeight">
                                                      <div className="boxHeading">
                                                        <span>Total Users</span>
                                                      </div>
                                                      <div className="boxContent">
                                                        <div
                                                          className="chartWrp"
                                                          style={{
                                                            minHeight: "260px",
                                                            minWidth: "260px",
                                                          }}
                                                        >
                                                          {chatbotStats !==
                                                            null &&
                                                            (chatbotStats?.newUser +
                                                              chatbotStats?.returningUser >
                                                            0 ? (
                                                              <DoughnutChart
                                                                degree={180}
                                                                backgroudColor={[
                                                                  "#EDC843",
                                                                  "#1CE783",
                                                                ]}
                                                                borderColor={[
                                                                  "#EDC843",
                                                                  "#1CE783",
                                                                ]}
                                                                graphData={[
                                                                  chatbotStats?.newUser,
                                                                  chatbotStats?.returningUser,
                                                                ]}
                                                                graphlabels={[
                                                                  "New users",
                                                                  "Returning users",
                                                                ]}
                                                              />
                                                            ) : (
                                                              <DoughnutChart
                                                                degree={180}
                                                                backgroudColor={[
                                                                  "#B4B4B4",
                                                                ]}
                                                                borderColor={[
                                                                  "#B4B4B4",
                                                                ]}
                                                                graphData={[1]}
                                                                graphlabels={[
                                                                  "-",
                                                                ]}
                                                              />
                                                            ))}
                                                          <div
                                                            className="chartData"
                                                            style={{
                                                              top: "50%",
                                                            }}
                                                          >
                                                            <h3>
                                                              {isNaN(
                                                                chatbotStats?.newUser +
                                                                  chatbotStats?.returningUser
                                                              )
                                                                ? 0
                                                                : chatbotStats?.newUser +
                                                                  chatbotStats?.returningUser}
                                                            </h3>
                                                            <p>Total Users</p>
                                                          </div>
                                                        </div>
                                                        <div className="chartDataWrapper">
                                                          <ul>
                                                            <li>
                                                              <p>
                                                                <span className="yellowDot"></span>
                                                                New users
                                                              </p>
                                                            </li>
                                                            <li>
                                                              <p>
                                                                {
                                                                  chatbotStats?.newUser
                                                                }
                                                              </p>
                                                            </li>
                                                          </ul>
                                                          <ul>
                                                            <li>
                                                              <p>
                                                                <span className="lightGreenDot"></span>
                                                                Returning users
                                                              </p>
                                                            </li>
                                                            <li>
                                                              <p>
                                                                {
                                                                  chatbotStats?.returningUser
                                                                }
                                                              </p>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-2">
                                                    <div className="mainBox halfDonutHeight">
                                                      <div className="boxHeading">
                                                        <span>Total bots</span>
                                                      </div>
                                                      <div className="boxContent">
                                                        <div
                                                          className="chartWrp"
                                                          style={{
                                                            minHeight: "260px",
                                                            minWidth: "260px",
                                                          }}
                                                        >
                                                          {chatbotStats !==
                                                            null &&
                                                            (chatbotStats?.totalActiveChatBot +
                                                              chatbotStats?.inactiveChatBots >
                                                            0 ? (
                                                              <DoughnutChart
                                                                degree={180}
                                                                backgroudColor={[
                                                                  "#1EB954",
                                                                  "#2F5711",
                                                                ]}
                                                                borderColor={[
                                                                  "#1EB954",
                                                                  "#2F5711",
                                                                ]}
                                                                graphData={[
                                                                  chatbotStats?.totalActiveChatBot,
                                                                  chatbotStats?.inactiveChatBots,
                                                                ]}
                                                                graphlabels={[
                                                                  "Active bots",
                                                                  "Inactive bots",
                                                                ]}
                                                              />
                                                            ) : (
                                                              <DoughnutChart
                                                                degree={180}
                                                                backgroudColor={[
                                                                  "#B4B4B4",
                                                                ]}
                                                                borderColor={[
                                                                  "#B4B4B4",
                                                                ]}
                                                                graphData={[1]}
                                                                graphlabels={[
                                                                  "-",
                                                                ]}
                                                              />
                                                            ))}
                                                          <div
                                                            className="chartData"
                                                            style={{
                                                              top: "50%",
                                                            }}
                                                          >
                                                            <h3>
                                                              {isNaN(
                                                                chatbotStats?.totalActiveChatBot +
                                                                  chatbotStats?.inactiveChatBots
                                                              )
                                                                ? 0
                                                                : chatbotStats?.totalActiveChatBot +
                                                                  chatbotStats?.inactiveChatBots}
                                                            </h3>
                                                            <p>Total bots</p>
                                                          </div>
                                                        </div>
                                                        <div className="chartDataWrapper">
                                                          <ul>
                                                            <li>
                                                              <p>
                                                                <span className="lightGreen"></span>
                                                                Active bots
                                                              </p>
                                                            </li>
                                                            <li>
                                                              <p>
                                                                {
                                                                  chatbotStats?.totalActiveChatBot
                                                                }
                                                              </p>
                                                            </li>
                                                          </ul>
                                                          <ul>
                                                            <li>
                                                              <p>
                                                                <span className="darkGreen"></span>
                                                                Inactive bots
                                                              </p>
                                                            </li>
                                                            <li>
                                                              <p>
                                                                {
                                                                  chatbotStats?.inactiveChatBots
                                                                }
                                                              </p>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="mainBox">
                                                <div className="boxHeading">
                                                  <span>
                                                    Frequency of Dialog Inquired
                                                  </span>
                                                </div>
                                                <div className="boxContent">
                                                  <div
                                                    className="uk-grid uk-flex-middle"
                                                    uk-grid=""
                                                  >
                                                    <div className="uk-width-1-4">
                                                      <div className="chartWrp">
                                                        {whatsappBotStats?.isCalled ===
                                                          true &&
                                                        whatsappBotStats?.totalConversations >
                                                          0 ? (
                                                          <DynamicDoughnutChart
                                                            degree={360}
                                                            graphData={
                                                              whatsappBotStats?.data
                                                            }
                                                            graphLabels={
                                                              whatsappBotStats?.labels
                                                            }
                                                            colors={
                                                              colorForBotStats
                                                            }
                                                          />
                                                        ) : (
                                                          <DoughnutChart
                                                            degree={360}
                                                            backgroudColor={[
                                                              "#B4B4B4",
                                                            ]}
                                                            borderColor={[
                                                              "#B4B4B4",
                                                            ]}
                                                            graphData={[1]}
                                                            graphlabels={["-"]}
                                                          />
                                                        )}
                                                        <div className="chartData">
                                                          <h3>
                                                            {/* 8M 58S */}
                                                            {whatsappBotStats?.totalConversations ===
                                                              undefined || null
                                                              ? 0
                                                              : whatsappBotStats?.totalConversations}
                                                          </h3>
                                                          <p>
                                                            {/* {
                                                          whatsappBotStats?.totalConversations
                                                        } */}
                                                            Total conversations
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-3-4">
                                                      <div className="chartRecordData">
                                                        <div className="chartRecordDataWrp">
                                                          <div className="deliveryRate">
                                                            {/* <ul>
                                                          <li>
                                                            <p>
                                                              <span className="darkGreenDot"></span>
                                                              Menu
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="yellowDot"></span>
                                                              Menu: Sales
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="lightGreenDot"></span>
                                                              Menu: Services
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="redDot"></span>
                                                              Menu: Talk with
                                                              Agent
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="darkOrange"></span>
                                                              Menu: Talk with
                                                              Agent
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="pinkDot"></span>
                                                              Menu: Deals and
                                                              Discount
                                                            </p>
                                                          </li>
                                                        </ul> */}
                                                            <ul>
                                                              {Object.keys(
                                                                whatsappBotStats?.window
                                                              ).length > 0 &&
                                                                Object.keys(
                                                                  whatsappBotStats?.window
                                                                ).map(
                                                                  (
                                                                    val,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <li
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        <p>
                                                                          <span
                                                                            style={{
                                                                              backgroundColor:
                                                                                colorForBotStats[
                                                                                  index
                                                                                ],
                                                                            }}
                                                                          ></span>
                                                                          Menu:{" "}
                                                                          {val}
                                                                        </p>
                                                                      </li>
                                                                    );
                                                                  }
                                                                )}
                                                            </ul>
                                                          </div>
                                                          <div className="progressBar">
                                                            <ul>
                                                              {Object.keys(
                                                                whatsappBotStats?.window
                                                              ).length > 0 &&
                                                                Object.keys(
                                                                  whatsappBotStats?.window
                                                                ).map(
                                                                  (
                                                                    val,
                                                                    index
                                                                  ) => {
                                                                    // console.log(whatsappBotStats?.window[val])

                                                                    const sum =
                                                                      whatsappBotStats?.window[
                                                                        val
                                                                      ]?.reduce(
                                                                        (
                                                                          sum,
                                                                          item
                                                                        ) =>
                                                                          sum +
                                                                          item.totalChats,
                                                                        0
                                                                      );

                                                                    // whatsappBotStats?.window[val].map((item, innerIndex)=>{
                                                                    //   return(

                                                                    //   )
                                                                    // })
                                                                    return (
                                                                      // <li key={index} style={{margin:"0px"}}>
                                                                      <li
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        <progress
                                                                          key={
                                                                            index
                                                                          }
                                                                          className="uk-progress "
                                                                          value={
                                                                            sum
                                                                          }
                                                                          max={
                                                                            whatsappBotStats.totalConversations
                                                                          }
                                                                        ></progress>

                                                                        {/* <progress
                                                              className="uk-progress "
                                                              style={{
                                                                backgroundColor:
                                                                  sum > 0
                                                                    ? colorForBotStats[
                                                                        index
                                                                      ]
                                                                    : "#f8f8f8",
                                                              }}
                                                              value={sum}
                                                              max={
                                                                whatsappBotStats.totalConversations
                                                              }
                                                              // max={
                                                              //   10
                                                              // }
                                                            ></progress> */}
                                                                      </li>
                                                                    );
                                                                  }
                                                                )}
                                                            </ul>
                                                            {/* <ul>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressDarkGreen"
                                                              value="50"
                                                              max="100"
                                                            ></progress>
                                                          </li>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressYellow"
                                                              value="50"
                                                              max="100"
                                                            ></progress>
                                                          </li>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressLightGreen"
                                                              value="20"
                                                              max="100"
                                                            ></progress>
                                                          </li>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressHigh"
                                                              value="10"
                                                              max="100"
                                                            ></progress>
                                                          </li>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressDarkOrange"
                                                              value="10"
                                                              max="100"
                                                            ></progress>
                                                          </li>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressError"
                                                              value="10"
                                                              max="100"
                                                            ></progress>
                                                          </li>
                                                        </ul> */}
                                                          </div>
                                                          <div className="percentageData">
                                                            <ul>
                                                              {Object.keys(
                                                                whatsappBotStats?.window
                                                              ).length > 0 &&
                                                                Object.keys(
                                                                  whatsappBotStats?.window
                                                                ).map(
                                                                  (
                                                                    val,
                                                                    index
                                                                  ) => {
                                                                    const sum =
                                                                      whatsappBotStats?.window[
                                                                        val
                                                                      ]?.reduce(
                                                                        (
                                                                          sum,
                                                                          item
                                                                        ) =>
                                                                          sum +
                                                                          item.totalChats,
                                                                        0
                                                                      );
                                                                    return (
                                                                      <li
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        <p>
                                                                          {whatsappBotStats.totalConversations >
                                                                          0
                                                                            ? (
                                                                                (sum /
                                                                                  whatsappBotStats.totalConversations) *
                                                                                100
                                                                              ).toFixed(
                                                                                2
                                                                              ) +
                                                                              "%"
                                                                            : "0%"}
                                                                        </p>
                                                                      </li>
                                                                    );
                                                                  }
                                                                )}
                                                            </ul>

                                                            {/* <ul>
                                                          <li>
                                                            <p>58.65%</p>
                                                          </li>
                                                          <li>
                                                            <p>58.65%</p>
                                                          </li>
                                                          <li>
                                                            <p>58.65%</p>
                                                          </li>
                                                          <li>
                                                            <p>12.78%</p>
                                                          </li>
                                                          <li>
                                                            <p>3.33%</p>
                                                          </li>
                                                          <li>
                                                            <p>0.1%</p>
                                                          </li>
                                                        </ul> */}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              {/* <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>Dialogs</span>
                                            </div>
                                            <div className="boxFullTable tableCenter">
                                              <table className="uk-table uk-table-striped">
                                                <thead>
                                                  <tr>
                                                    <th className="uk-table-expand">
                                                      DIALOG
                                                    </th>
                                                    <th>Chatbot name</th>
                                                    <th>TOTAL CHATS</th>
                                                    <th>TOTAL UNIQUE USERS</th>
                                                  
                                                  
                                                    <th>
                                                    CHATS ROUTED TO AGENTS
                                                  </th>
                                                  <th>AVG CHAT TIME</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {chatFilterStats?.length >
                                                  0 ? (
                                                    chatFilterStats?.map(
                                                      (val, index) => {
                                                        // var window = dangerouslySetInnerHTML={{ __html: val.windowName }};
                                                        return (
                                                          <tr key={index}>
                                                            <td className="channelName">
                                                              <Link
                                                                to={`/chatbot-detail/${encodeURIComponent(
                                                                  val.windowName
                                                                )}/${
                                                                  val.botWidgetId
                                                                }/${STARTDATE}/${ENDDATE}`}
                                                              >
                                                                <img
                                                                  src={
                                                                    (val?.channel ===
                                                                      "WHATSAPP" &&
                                                                      whatsappLogo) ||
                                                                    (val?.channel ===
                                                                      "FACEBOOK" &&
                                                                      messengerIcon) ||
                                                                    (val?.channel ===
                                                                      "INSTAGRAM" &&
                                                                      instaIcon)
                                                                  }
                                                                  alt=""
                                                                />
                                                                {
                                                                  val?.windowName
                                                                }
                                                              </Link>
                                                            </td>
                                                            <td>
                                                              {val?.chatBotName}
                                                            </td>
                                                            <td>
                                                              {val?.totalChats}
                                                            </td>
                                                            <td>
                                                              {
                                                                val?.totalUniqueChats
                                                              }
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )
                                                  ) : (
                                                    <tr>
                                                      <td
                                                        colSpan={11}
                                                        className="dataNotFound"
                                                      >
                                                        {chatFilterStats ===
                                                        null ? (
                                                          <div uk-spinner=""></div>
                                                        ) : (
                                                          "Data Not Found"
                                                        )}
                                                      </td>
                                                    </tr>
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li></li>
                                <li></li>
                                {/* <li>
                              <div
                                className="overviewContent uk-padding-remove"
                                style={{ backgroundColor: "transparent" }}
                              >
                                <div className="uk-container">
                                  <div className="overviewTopDropdown">
                                    <div
                                      className="uk-grid uk-flex-middle"
                                      uk-grid=""
                                    >
                                      <div className="uk-width-1-2">
                                        <div className="pageHeading uk-margin-remove-bottom">
                                          <h3>Chat Bot Session Analytics</h3>
                                        </div>
                                      </div>
                                      <div className="uk-width-1-2 btnSection">
                                        <div className="agentFilterWrp">
                                          <div className="allChannelDropDown">
                                            <div className="uk-inline tableFilterDropdown broadcastChannelFilter">
                                              <button
                                                type="button"
                                                className="dropdownBtn"
                                              >
                                                All Active Channels{" "}
                                                <ChevronDown />
                                              </button>
                                              <div
                                                uk-dropdown="mode: click"
                                                className="dropdownNav"
                                              >
                                                <span className="dropdownHeading">
                                                  Channels
                                                </span>
                                                <ul>
                                                  {channels.map(
                                                    (val, index) => {
                                                      return (
                                                        <li
                                                          key={index}
                                                          className={
                                                            channel ===
                                                            val.apiSendName
                                                              ? "activeBtn"
                                                              : ""
                                                          }
                                                          onClick={() => {
                                                            setChannel(
                                                              val.apiSendName
                                                            );
                                                          }}
                                                        >
                                                          <button
                                                            type="button"
                                                            disabled={
                                                              val.disabled ===
                                                              true
                                                                ? true
                                                                : false
                                                            }
                                                            className={
                                                              val.disabled ===
                                                              true
                                                                ? "opactityBtn"
                                                                : ""
                                                            }
                                                          >
                                                            {index !== 0 && (
                                                              <img
                                                                src={val.icon}
                                                                alt=""
                                                              />
                                                            )}
                                                            {val.name}
                                                          </button>
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="uk-inline">
                                            <button className="calenderBtn rangeBtn">
                                              <Calendar /> {selectedDateFilter}
                                              <span className="uk-inline">
                                                <ChevronDown />
                                              </span>
                                            </button>
                                            <div
                                              uk-dropdown="mode: click"
                                              className="calendarDropdown"
                                              id="dateDropdownSection"
                                            >
                                              <div className="dropDownHeading">
                                                <span>Select a range</span>
                                              </div>
                                              <ul>
                                                {filterDropdown.map(
                                                  (val, index) => {
                                                    return (
                                                      <>
                                                        <li
                                                          className={
                                                            selectedDateFilter ===
                                                            val
                                                              ? "activeBtn"
                                                              : ""
                                                          }
                                                          key={index}
                                                          onClick={() =>
                                                            handleDateFilter(
                                                              val
                                                            )
                                                          }
                                                        >
                                                          {filterDropdown.length -
                                                            1 ===
                                                          index ? (
                                                            <div className="uk-inline uk-text-left">
                                                              <button
                                                                className="rangeBtn"
                                                                type="button"
                                                              >
                                                                <span className="rangeBtnCircle"></span>{" "}
                                                                Custom range
                                                              </button>
                                                              <div
                                                                id="datepickerdrop"
                                                                uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                                className="date-picker-container"
                                                              >
                                                                <CalendarDate
                                                                  onChange={
                                                                    onChange
                                                                  }
                                                                />
                                                                <div className="date-picker-btn-container uk-text-right">
                                                                  <button
                                                                    className="date-selection-btn"
                                                                    onClick={
                                                                      handleDatePickerClose
                                                                    }
                                                                  >
                                                                    Ok
                                                                  </button>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            <button
                                                              type="button"
                                                              className="rangeBtn"
                                                            >
                                                              <span className="rangeBtnCircle"></span>{" "}
                                                              {val}
                                                            </button>
                                                          )}
                                                        </li>
                                                        {filterDropdown.length -
                                                          2 ===
                                                          index && (
                                                          <hr
                                                            style={{
                                                              margin: "5px 0",
                                                            }}
                                                          />
                                                        )}
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="uk-width-1-1 uk-margin-remove">
                                        <div className="overviewMainContent">
                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>Session summary</span>
                                            </div>
                                            <div className="boxContent">
                                              <div
                                                className="uk-grid uk-flex-middle"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-2-5">
                                                  <div className="boxTimerWrp">
                                                    <p>
                                                      <Chat /> Total Chats
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="uk-width-3-5">
                                                  <div
                                                    className="uk-grid"
                                                    uk-grid=""
                                                  >
                                                    <div className="uk-width-1-2">
                                                      <div className="txtwrp timeBoxTxt">
                                                        <h2>87,236</h2>
                                                        <span>
                                                          Total bot chats
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-1-2">
                                                      <div className="txtwrp timeBoxTxt">
                                                        <h2>186,457</h2>
                                                        <span>
                                                          Avg. Bot chats per
                                                          user
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <hr />
                                              <div
                                                className="uk-grid uk-flex-middle"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-2-5">
                                                  <div className="boxTimerWrp">
                                                    <p>
                                                      <Forum /> Engaged users
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="uk-width-3-5">
                                                  <div
                                                    className="uk-grid"
                                                    uk-grid=""
                                                  >
                                                    <div className="uk-width-1-2">
                                                      <div className="txtwrp timeBoxTxt">
                                                        <h2>15,084</h2>
                                                        <span>
                                                          Total engaged Users
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-1-2">
                                                      <div className="txtwrp timeBoxTxt">
                                                        <h2>12,451</h2>
                                                        <span>
                                                          Avg. engaged bot chats
                                                          per user
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>Total bot chats</span>
                                            </div>
                                            <div className="boxContent">
                                              <div
                                                className="uk-grid uk-flex-middle"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-3-5">
                                                  <div className="overviewChart">
                                                    <LineChart
                                                      dataSet1={[
                                                        50, 20, 30, 25, 0, 40,
                                                        10,
                                                      ]}
                                                      dataSet2={[
                                                        20, 10, 40, 35, 22, 10,
                                                        30,
                                                      ]}
                                                      dataSet3={[
                                                        40, 20, 40, 50, 45, 30,
                                                        25,
                                                      ]}
                                                      graphLabels={[
                                                        "January",
                                                        "February",
                                                        "March",
                                                        "April",
                                                        "May",
                                                        "June",
                                                        "July",
                                                      ]}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="uk-width-2-5">
                                                  <div className="chartRecordData">
                                                    <div className="chartRecordDataWrp">
                                                      <div className="deliveryRate">
                                                        <div
                                                          className="detailHeading"
                                                          style={{
                                                            marginBottom:
                                                              "24px",
                                                          }}
                                                        >
                                                          <p>CHATBOT</p>
                                                        </div>
                                                        <ul>
                                                          <li>
                                                            <p>
                                                              <span className="darkGreenDot"></span>
                                                              All Chatbots
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="greenDot"></span>
                                                              Facebook Chatbots
                                                              1
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="yellowDot"></span>
                                                              Chatbots 2
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="redDot"></span>
                                                              Chatbots 3
                                                            </p>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="halfDonutSec">
                                            <div
                                              className="uk-grid"
                                              uk-grid=""
                                              uk-height-match="target: > div > .halfDonutHeight"
                                            >
                                              <div className="uk-width-1-2">
                                                <div className="mainBox halfDonutHeight">
                                                  <div className="boxHeading">
                                                    <span>
                                                      Live chat breakdown by
                                                      conversations
                                                    </span>
                                                  </div>
                                                  <div className="boxContent">
                                                    <div
                                                      className="chartWrp"
                                                      style={{
                                                        width: "200px",
                                                        height: "200px",
                                                        margin: "0 auto",
                                                      }}
                                                    >
                                                      <DoughnutChart
                                                        degree={360}
                                                        backgroudColor={[
                                                          "#2F5711",
                                                          "#1ce783",
                                                          "#EDC843",
                                                        ]}
                                                        borderColor={[
                                                          "#2F5711",
                                                          "#1ce783",
                                                          "#EDC843",
                                                        ]}
                                                        graphData={[22, 33, 15]}
                                                      />
                                                      <div
                                                        className="chartData"
                                                        style={{
                                                          top: "36%",
                                                          left: "10px",
                                                        }}
                                                      >
                                                        <h3>628</h3>
                                                        <p>Sessions</p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="chartDataWrapper"
                                                      style={{
                                                        marginTop: "40px",
                                                      }}
                                                    >
                                                      <ul
                                                        style={{
                                                          width: "252px",
                                                        }}
                                                      >
                                                        <li>
                                                          <p>
                                                            <span className="darkGreen"></span>
                                                            Engaged sessions
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>58.65%</p>
                                                        </li>
                                                      </ul>
                                                      <ul
                                                        style={{
                                                          width: "252px",
                                                        }}
                                                      >
                                                        <li>
                                                          <p>
                                                            <span className="yellowDot"></span>
                                                            Agent takeover
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>58.65%</p>
                                                        </li>
                                                      </ul>
                                                      <ul
                                                        style={{
                                                          width: "252px",
                                                        }}
                                                      >
                                                        <li>
                                                          <p>
                                                            <span className="lightGreenDot"></span>
                                                            Go to Agent
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>58.65%</p>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="uk-width-1-2">
                                                <div className="mainBox halfDonutHeight">
                                                  <div className="boxHeading">
                                                    <span>
                                                      Transferred chats
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="boxContent"
                                                    style={{
                                                      padding: "0 30px",
                                                      marginTop: "120px",
                                                    }}
                                                  >
                                                    <div
                                                      className="uk-grid uk-flex-middle"
                                                      uk-grid=""
                                                    >
                                                      <div className="uk-width-1-2">
                                                        <div className="chartTimer">
                                                          <h3>00:00:57</h3>
                                                          <p>
                                                            Avg. session
                                                            duration
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="uk-width-1-2">
                                                        <div className="chartDataWrapper chartBorderWrp">
                                                          <ul
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <li>
                                                              <p>
                                                                <span className="darkGreen"></span>
                                                                Less than 30s
                                                              </p>
                                                            </li>
                                                            <li>
                                                              <p>58.65%</p>
                                                            </li>
                                                          </ul>
                                                          <ul
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <li>
                                                              <p>
                                                                <span className="yellowDot"></span>
                                                                Less than 60s
                                                              </p>
                                                            </li>
                                                            <li>
                                                              <p>12.78%</p>
                                                            </li>
                                                          </ul>
                                                          <ul
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <li>
                                                              <p>
                                                                <span className="lightGreenDot"></span>
                                                                Less than 90s
                                                              </p>
                                                            </li>
                                                            <li>
                                                              <p>0%</p>
                                                            </li>
                                                          </ul>
                                                          <ul
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <li>
                                                              <p>
                                                                <span className="redDot"></span>
                                                                Less than 120s
                                                              </p>
                                                            </li>
                                                            <li>
                                                              <p>0%</p>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>Dialogs</span>
                                            </div>
                                            <div className="boxFullTable">
                                              <table className="uk-table uk-table-striped">
                                                <thead>
                                                  <tr>
                                                    <th className="uk-table-expand">
                                                      DIALOG
                                                    </th>
                                                    <th>TRIGGERED</th>
                                                    <th>USERS</th>
                                                    <th>Sessions</th>
                                                    <th>Agent takes over</th>
                                                    <th>Go to agent</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td className="channelName">
                                                      Default
                                                    </td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>4</td>
                                                    <td>0</td>
                                                  </tr>
                                                  <tr>
                                                    <td className="channelName">
                                                      Default
                                                    </td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>4</td>
                                                    <td>0</td>
                                                  </tr>
                                                  <tr>
                                                    <td className="channelName">
                                                      Default
                                                    </td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>4</td>
                                                    <td>0</td>
                                                  </tr>
                                                  <tr>
                                                    <td className="channelName">
                                                      Default
                                                    </td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>4</td>
                                                    <td>0</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div
                                className="overviewContent uk-padding-remove"
                                style={{ backgroundColor: "transparent" }}
                              >
                                <div className="uk-container">
                                  <div className="overviewTopDropdown">
                                    <div
                                      className="uk-grid uk-flex-middle"
                                      uk-grid=""
                                    >
                                      <div className="uk-width-1-2">
                                        <div className="pageHeading uk-margin-remove-bottom">
                                          <h3>
                                            Chat Bot Conversational Analytics
                                          </h3>
                                        </div>
                                      </div>
                                      <div className="uk-width-1-2 btnSection">
                                        <div className="agentFilterWrp">
                                          <div className="allChannelDropDown">
                                            <div className="uk-inline tableFilterDropdown broadcastChannelFilter">
                                              <button
                                                type="button"
                                                className="dropdownBtn"
                                              >
                                                All Active Channels{" "}
                                                <ChevronDown />
                                              </button>
                                              <div
                                                uk-dropdown="mode: click"
                                                className="dropdownNav"
                                              >
                                                <span className="dropdownHeading">
                                                  Channels
                                                </span>
                                                <ul>
                                                  {channels.map(
                                                    (val, index) => {
                                                      return (
                                                        <li
                                                          key={index}
                                                          className={
                                                            channel ===
                                                            val.apiSendName
                                                              ? "activeBtn"
                                                              : ""
                                                          }
                                                          onClick={() => {
                                                            setChannel(
                                                              val.apiSendName
                                                            );
                                                          }}
                                                        >
                                                          <button
                                                            type="button"
                                                            disabled={
                                                              val.disabled ===
                                                              true
                                                                ? true
                                                                : false
                                                            }
                                                            className={
                                                              val.disabled ===
                                                              true
                                                                ? "opactityBtn"
                                                                : ""
                                                            }
                                                          >
                                                            {index !== 0 && (
                                                              <img
                                                                src={val.icon}
                                                                alt=""
                                                              />
                                                            )}
                                                            {val.name}
                                                          </button>
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="uk-inline">
                                            <button className="calenderBtn rangeBtn">
                                              <Calendar /> {selectedDateFilter}
                                              <span className="uk-inline">
                                                <ChevronDown />
                                              </span>
                                            </button>
                                            <div
                                              uk-dropdown="mode: click"
                                              className="calendarDropdown"
                                              id="dateDropdownSection"
                                            >
                                              <div className="dropDownHeading">
                                                <span>Select a range</span>
                                              </div>
                                              <ul>
                                                {filterDropdown.map(
                                                  (val, index) => {
                                                    return (
                                                      <>
                                                        <li
                                                          className={
                                                            selectedDateFilter ===
                                                            val
                                                              ? "activeBtn"
                                                              : ""
                                                          }
                                                          key={index}
                                                          onClick={() =>
                                                            handleDateFilter(
                                                              val
                                                            )
                                                          }
                                                        >
                                                          {filterDropdown.length -
                                                            1 ===
                                                          index ? (
                                                            <div className="uk-inline uk-text-left">
                                                              <button
                                                                className="rangeBtn"
                                                                type="button"
                                                              >
                                                                <span className="rangeBtnCircle"></span>{" "}
                                                                Custom range
                                                              </button>
                                                              <div
                                                                id="datepickerdrop"
                                                                uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                                className="date-picker-container"
                                                              >
                                                                <CalendarDate
                                                                  onChange={
                                                                    onChange
                                                                  }
                                                                />
                                                                <div className="date-picker-btn-container uk-text-right">
                                                                  <button
                                                                    className="date-selection-btn"
                                                                    onClick={
                                                                      handleDatePickerClose
                                                                    }
                                                                  >
                                                                    Ok
                                                                  </button>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            <button
                                                              type="button"
                                                              className="rangeBtn"
                                                            >
                                                              <span className="rangeBtnCircle"></span>{" "}
                                                              {val}
                                                            </button>
                                                          )}
                                                        </li>
                                                        {filterDropdown.length -
                                                          2 ===
                                                          index && (
                                                          <hr
                                                            style={{
                                                              margin: "5px 0",
                                                            }}
                                                          />
                                                        )}
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="uk-width-1-1 uk-margin-remove">
                                        <div className="overviewMainContent">
                                          <div className="halfDonutSec halfDonutSize">
                                            <div
                                              className="uk-grid"
                                              uk-grid=""
                                              uk-height-match="target: > div > .halfDonutHeight"
                                            >
                                              <div className="uk-width-1-2">
                                                <div className="mainBox halfDonutHeight">
                                                  <div className="boxHeading">
                                                    <span>Total messages</span>
                                                  </div>
                                                  <div className="boxContent">
                                                    <div className="chartWrp">
                                                      <DoughnutChart
                                                        degree={180}
                                                        backgroudColor={[
                                                          "#EDC843",
                                                          "#1CE783",
                                                        ]}
                                                        borderColor={[
                                                          "#EDC843",
                                                          "#1CE783",
                                                        ]}
                                                        graphData={[32, 50]}
                                                        graphlabels={[
                                                          "New users",
                                                          "Returning users",
                                                        ]}
                                                      />
                                                      <div
                                                        className="chartData"
                                                        style={{ top: "50%" }}
                                                      >
                                                        <h3>21,324</h3>
                                                        <p>Exchange messages</p>
                                                      </div>
                                                    </div>
                                                    <div className="chartDataWrapper">
                                                      <ul>
                                                        <li>
                                                          <p>
                                                            <span className="yellowDot"></span>
                                                            New users
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>14,628</p>
                                                        </li>
                                                      </ul>
                                                      <ul>
                                                        <li>
                                                          <p>
                                                            <span className="lightGreenDot"></span>
                                                            Returning users
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>6,628</p>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="uk-width-1-2">
                                                <div className="mainBox halfDonutHeight">
                                                  <div className="boxHeading">
                                                    <span>
                                                      Avg. messages per session
                                                    </span>
                                                  </div>
                                                  <div className="boxContent">
                                                    <div className="chartWrp">
                                                      <DoughnutChart
                                                        degree={180}
                                                        backgroudColor={[
                                                          "#1EB954",
                                                          "#2F5711",
                                                        ]}
                                                        borderColor={[
                                                          "#1EB954",
                                                          "#2F5711",
                                                        ]}
                                                        graphData={[33, 26]}
                                                        graphlabels={[
                                                          "Active bots",
                                                          "Inactive bots",
                                                        ]}
                                                      />
                                                      <div
                                                        className="chartData"
                                                        style={{ top: "50%" }}
                                                      >
                                                        <h3>123,456</h3>
                                                        <p>
                                                          Avg. messages per
                                                          session
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className="chartDataWrapper">
                                                      <ul>
                                                        <li>
                                                          <p>
                                                            <span className="lightGreen"></span>
                                                            New users
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>12</p>
                                                        </li>
                                                      </ul>
                                                      <ul>
                                                        <li>
                                                          <p>
                                                            <span className="darkGreen"></span>
                                                            Returning users
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>0</p>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>Dialogs</span>
                                            </div>
                                            <div className="boxFullTable">
                                              <table className="uk-table uk-table-striped">
                                                <thead>
                                                  <tr>
                                                    <th className="uk-table-expand">
                                                      CHATBOT
                                                    </th>
                                                    <th>DIALOG</th>
                                                    <th>TRIGGERED</th>
                                                    <th>USERS</th>
                                                    <th>ENGAGED</th>
                                                    <th>AGENT TAKE OVER</th>
                                                    <th>GO TO AGENT</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td className="channelName">
                                                      <img
                                                        src={whatsappLogo}
                                                        alt=""
                                                      />
                                                      Default
                                                    </td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>100.0%</td>
                                                    <td>4</td>
                                                    <td>0</td>
                                                  </tr>
                                                  <tr>
                                                    <td className="channelName">
                                                      <img
                                                        src={whatsappLogo}
                                                        alt=""
                                                      />
                                                      Default
                                                    </td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>100.0%</td>
                                                    <td>4</td>
                                                    <td>0</td>
                                                  </tr>
                                                  <tr>
                                                    <td className="channelName">
                                                      <img
                                                        src={whatsappLogo}
                                                        alt=""
                                                      />
                                                      Default
                                                    </td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>100.0%</td>
                                                    <td>4</td>
                                                    <td>0</td>
                                                  </tr>
                                                  <tr>
                                                    <td className="channelName">
                                                      <img
                                                        src={whatsappLogo}
                                                        alt=""
                                                      />
                                                      Default
                                                    </td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>100.0%</td>
                                                    <td>4</td>
                                                    <td>0</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>

                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>
                                                Unrecognized intent messages
                                              </span>
                                            </div>
                                            <div className="boxFullTable">
                                              <table className="uk-table uk-table-striped">
                                                <thead>
                                                  <tr>
                                                    <th className="uk-table-expand">
                                                      MESSAGE
                                                    </th>
                                                    <th>DIALOG</th>
                                                    <th>KEYWORD</th>
                                                    <th>SYNONYMES</th>
                                                    <th>COUNT</th>
                                                    <th>USER COUNT</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td className="channelName">
                                                      Message text 62
                                                    </td>
                                                    <td>Dialog 62</td>
                                                    <td>Process_User_I...</td>
                                                    <td>Process_User_I...</td>
                                                    <td>14,893</td>
                                                    <td>2,196</td>
                                                  </tr>
                                                  <tr>
                                                    <td className="channelName">
                                                      Message text 62
                                                    </td>
                                                    <td>Dialog 62</td>
                                                    <td>Process_User_I...</td>
                                                    <td>Process_User_I...</td>
                                                    <td>14,893</td>
                                                    <td>2,196</td>
                                                  </tr>
                                                  <tr>
                                                    <td className="channelName">
                                                      Message text 62
                                                    </td>
                                                    <td>Dialog 62</td>
                                                    <td>Process_User_I...</td>
                                                    <td>Process_User_I...</td>
                                                    <td>14,893</td>
                                                    <td>2,196</td>
                                                  </tr>
                                                  <tr>
                                                    <td className="channelName">
                                                      Message text 62
                                                    </td>
                                                    <td>Dialog 62</td>
                                                    <td>Process_User_I...</td>
                                                    <td>Process_User_I...</td>
                                                    <td>14,893</td>
                                                    <td>2,196</td>
                                                  </tr>
                                                  <tr>
                                                    <td className="channelName">
                                                      Message text 62
                                                    </td>
                                                    <td>Dialog 62</td>
                                                    <td>Process_User_I...</td>
                                                    <td>Process_User_I...</td>
                                                    <td>14,893</td>
                                                    <td>2,196</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div
                                className="overviewContent uk-padding-remove"
                                style={{ backgroundColor: "transparent" }}
                              >
                                <div className="uk-container">
                                  <div className="overviewTopDropdown">
                                    <div
                                      className="uk-grid uk-flex-middle"
                                      uk-grid=""
                                    >
                                      <div className="uk-width-1-2">
                                        <div className="pageHeading uk-margin-remove-bottom">
                                          <h3>Chatbot User Analytics</h3>
                                        </div>
                                      </div>
                                      <div className="uk-width-1-2 btnSection">
                                        <div className="agentFilterWrp">
                                          <div className="allChannelDropDown">
                                            <div className="uk-inline tableFilterDropdown broadcastChannelFilter">
                                              <button
                                                type="button"
                                                className="dropdownBtn"
                                              >
                                                All Active Channels{" "}
                                                <ChevronDown />
                                              </button>
                                              <div
                                                uk-dropdown="mode: click"
                                                className="dropdownNav"
                                              >
                                                <span className="dropdownHeading">
                                                  Channels
                                                </span>
                                                <ul>
                                                  {channels.map(
                                                    (val, index) => {
                                                      return (
                                                        <li
                                                          key={index}
                                                          className={
                                                            channel ===
                                                            val.apiSendName
                                                              ? "activeBtn"
                                                              : ""
                                                          }
                                                          onClick={() => {
                                                            setChannel(
                                                              val.apiSendName
                                                            );
                                                          }}
                                                        >
                                                          <button
                                                            type="button"
                                                            disabled={
                                                              val.disabled ===
                                                              true
                                                                ? true
                                                                : false
                                                            }
                                                            className={
                                                              val.disabled ===
                                                              true
                                                                ? "opactityBtn"
                                                                : ""
                                                            }
                                                          >
                                                            {index !== 0 && (
                                                              <img
                                                                src={val.icon}
                                                                alt=""
                                                              />
                                                            )}
                                                            {val.name}
                                                          </button>
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="uk-inline">
                                            <button className="calenderBtn rangeBtn">
                                              <Calendar /> {selectedDateFilter}
                                              <span className="uk-inline">
                                                <ChevronDown />
                                              </span>
                                            </button>
                                            <div
                                              uk-dropdown="mode: click"
                                              className="calendarDropdown"
                                              id="dateDropdownSection"
                                            >
                                              <div className="dropDownHeading">
                                                <span>Select a range</span>
                                              </div>
                                              <ul>
                                                {filterDropdown.map(
                                                  (val, index) => {
                                                    return (
                                                      <>
                                                        <li
                                                          className={
                                                            selectedDateFilter ===
                                                            val
                                                              ? "activeBtn"
                                                              : ""
                                                          }
                                                          key={index}
                                                          onClick={() => {
                                                            setSelectedDateFilter(val);
                                                            val !== "Custom range" &&
                                                              handleDateFilter(val);
                                                          }}
                                                        >
                                                          {filterDropdown.length -
                                                            1 ===
                                                          index ? (
                                                            <div className="uk-inline uk-text-left">
                                                              <button
                                                                className="rangeBtn"
                                                                type="button"
                                                              >
                                                                <span className="rangeBtnCircle"></span>{" "}
                                                                Custom range
                                                              </button>
                                                              <div
                                                                id="datepickerdrop"
                                                                uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                                className="date-picker-container"
                                                              >
                                                                <CalendarDate
                                                                  onChange={
                                                                    onChange
                                                                  }
                                                                />
                                                                <div className="date-picker-btn-container uk-text-right">
                                                                  <button
                                                                    className="date-selection-btn"
                                                                    onClick={
                                                                      handleDatePickerClose
                                                                    }
                                                                  >
                                                                    Ok
                                                                  </button>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            <button
                                                              type="button"
                                                              className="rangeBtn"
                                                            >
                                                              <span className="rangeBtnCircle"></span>{" "}
                                                              {val}
                                                            </button>
                                                          )}
                                                        </li>
                                                        {filterDropdown.length -
                                                          2 ===
                                                          index && (
                                                          <hr
                                                            style={{
                                                              margin: "5px 0",
                                                            }}
                                                          />
                                                        )}
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="uk-width-1-1 uk-margin-remove">
                                        <div className="overviewMainContent">
                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>Session summary</span>
                                            </div>
                                            <div className="boxContent">
                                              <div
                                                className="uk-grid uk-flex-middle"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-2-5">
                                                  <div className="boxTimerWrp">
                                                    <p>
                                                      <Chat /> Total Chats
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="uk-width-3-5">
                                                  <div
                                                    className="uk-grid"
                                                    uk-grid=""
                                                  >
                                                    <div className="uk-width-1-3">
                                                      <div className="txtwrp timeBoxTxt">
                                                        <h2>87,236</h2>
                                                        <span>Total users</span>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-1-3">
                                                      <div className="txtwrp timeBoxTxt">
                                                        <h2>186,457</h2>
                                                        <span>New users</span>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-1-3">
                                                      <div className="txtwrp timeBoxTxt">
                                                        <h2>186,457</h2>
                                                        <span>
                                                          Returning users
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <hr />
                                              <div
                                                className="uk-grid uk-flex-middle"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-2-5">
                                                  <div className="boxTimerWrp">
                                                    <p>
                                                      <Forum /> Engaged users
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="uk-width-3-5">
                                                  <div
                                                    className="uk-grid"
                                                    uk-grid=""
                                                  >
                                                    <div className="uk-width-1-3">
                                                      <div className="txtwrp timeBoxTxt">
                                                        <h2>15,084</h2>
                                                        <span>
                                                          Total engaged users
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-1-3">
                                                      <div className="txtwrp timeBoxTxt">
                                                        <h2>12,451</h2>
                                                        <span>
                                                          New engaged users
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-1-3">
                                                      <div className="txtwrp timeBoxTxt">
                                                        <h2>12,451</h2>
                                                        <span>
                                                          Returning engaged
                                                          users
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>Total users</span>
                                            </div>
                                            <div className="boxContent">
                                              <div
                                                className="uk-grid uk-flex-middle"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-3-5">
                                                  <div className="overviewChart">
                                                    <LineChart
                                                      dataSet1={[
                                                        50, 20, 30, 25, 0, 40,
                                                        10,
                                                      ]}
                                                      dataSet2={[
                                                        20, 10, 40, 35, 22, 10,
                                                        30,
                                                      ]}
                                                      dataSet3={[
                                                        40, 20, 40, 50, 45, 30,
                                                        25,
                                                      ]}
                                                      graphLabels={[
                                                        "January",
                                                        "February",
                                                        "March",
                                                        "April",
                                                        "May",
                                                        "June",
                                                        "July",
                                                      ]}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="uk-width-2-5">
                                                  <div className="chartRecordData">
                                                    <div className="chartRecordDataWrp">
                                                      <div className="deliveryRate">
                                                        <div
                                                          className="detailHeading"
                                                          style={{
                                                            marginBottom:
                                                              "24px",
                                                          }}
                                                        >
                                                          <p>USERS</p>
                                                        </div>
                                                        <ul>
                                                          <li>
                                                            <p>
                                                              <span className="darkGreenDot"></span>
                                                              Total users
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="yellowDot"></span>
                                                              Returning users
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="greenDot"></span>
                                                              New users
                                                            </p>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>Dialogs</span>
                                            </div>
                                            <div className="boxFullTable">
                                              <table className="uk-table uk-table-striped">
                                                <thead>
                                                  <tr>
                                                    <th>CHANNEL</th>
                                                    <th>CONTACT</th>
                                                    <th>CHATBOT</th>
                                                    <th>USER STATUS</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      <img
                                                        src={whatsappLogo}
                                                        alt=""
                                                      />
                                                    </td>
                                                    <td>
                                                      <img
                                                        src={demoImg}
                                                        alt=""
                                                      />
                                                      Naveen Asim
                                                    </td>
                                                    <td className="channelName">
                                                      Default
                                                    </td>
                                                    <td>Conected</td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <img
                                                        src={whatsappLogo}
                                                        alt=""
                                                      />
                                                    </td>
                                                    <td>
                                                      <img
                                                        src={demoImg}
                                                        alt=""
                                                      />
                                                      Naveen Asim
                                                    </td>
                                                    <td className="channelName">
                                                      Default
                                                    </td>
                                                    <td>Conected</td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <img
                                                        src={whatsappLogo}
                                                        alt=""
                                                      />
                                                    </td>
                                                    <td>
                                                      <img
                                                        src={demoImg}
                                                        alt=""
                                                      />
                                                      Naveen Asim
                                                    </td>
                                                    <td className="channelName">
                                                      Default
                                                    </td>
                                                    <td>Conected</td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <img
                                                        src={whatsappLogo}
                                                        alt=""
                                                      />
                                                    </td>
                                                    <td>
                                                      <img
                                                        src={demoImg}
                                                        alt=""
                                                      />
                                                      Naveen Asim
                                                    </td>
                                                    <td className="channelName">
                                                      Default
                                                    </td>
                                                    <td>Conected</td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <img
                                                        src={whatsappLogo}
                                                        alt=""
                                                      />
                                                    </td>
                                                    <td>
                                                      <img
                                                        src={demoImg}
                                                        alt=""
                                                      />
                                                      Naveen Asim
                                                    </td>
                                                    <td className="channelName">
                                                      Default
                                                    </td>
                                                    <td>Conected</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li key={index}></li>
                )
              ) : null;
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ChatbotAnalytics;
