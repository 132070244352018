import React from "react";

const LaunchModal = ({
  closeModal,
  submittedData,
  toggleConfirmModal ,
  broadcastCost,
  selectedContact
}) => {
  
  const handleConfirm = () => {
    toggleConfirmModal(true);
    closeModal();
  };


  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
     
        <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
          <div className="filterModalContent">
            <div className="modalHeading">
              <span>Launch Broadcast {submittedData?.broadcast}</span>
            </div>
            <div className="modalForm pauseModalContent">
              <div className="formHeading">
                <span>Are you sure you want to launch this broadcast?</span>
              </div>
              <div className="txtwrp">
                <p> {submittedData?.broadcast}</p>
              </div>
              <div className="formHeading">
                <span> Total receipts</span>
              </div>
              <div className="txtwrp">
                <p>{ selectedContact.length}</p>
              </div>
              <div className="formHeading">
                <span>Payment summary</span>
              </div>
              <div className="txtwrp">
                <p>Approximate cost:  { (broadcastCost?.contact === ""
                          ? "-"
                          : "$" + broadcastCost?.contact)}</p>
              </div>
              <div className="formInputs dltModal"></div>
              <div className="btnwrp">
                <button type="button" className="btn-1" onClick={closeModal}>
                  Cancel
                </button>
                <button className="btn-2" type="submit" onClick={()=>handleConfirm()}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
  
    </div>
  );
};

export default LaunchModal;
