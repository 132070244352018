import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const CreateChatbotModal = ({ closeModal }) => {
  const navigate = useNavigate();
  const [selectedChannel, setSelectedChannel] = useState("");
  const handleCreateChatbot = () => {

    if (selectedChannel === "whatsapp") {
      navigate(`/whatsapp/chatbot-builder`, {
        replace: true,
      });
    } else if (selectedChannel === "facebook") {
      navigate(`/facebook/chatbot-builder`, {
        replace: true,
      });
    }
    else if (selectedChannel === "web") {
      navigate(`/webchatbot/builder`, {
        replace: true,
      });
    }
    // window.location.reload();
  };

  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>Create new chatbot</span>
          </div>
          <div className="modalForm">
            <div className="formHeading">
              <span>
                Please configure your chatbot. You can update this information 
                later in Settings.
              </span>
            </div>
            <form action="" autoComplete="off">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <label htmlFor="agent">Channel</label>
                    <select
                      name="channel"
                      id="channel"
                      className="uk-select"
                      onChange={(e) => setSelectedChannel(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Select an option
                      </option>
                      <option value="whatsapp">Whatsapp</option>
                      <option value="facebook">Facebook</option>
                      {/* <option value="web">Web chatbot</option> */}
                      {/* Add more options for other channels if needed */}
                    </select>
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="btnwrp">
                    <button
                      type="button"
                      className="btn-1"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn-2"
                      onClick={handleCreateChatbot}
                      disabled={!selectedChannel}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateChatbotModal;
