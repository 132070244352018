import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  Alarm,
  Calendar,
  ChevronDown,
  Download,
  Timer,
} from "@carbon/icons-react";
import moment from "moment/moment";
import UIkit from "uikit";
import DoughnutChart from "../../components/Graph/DoughnutChart";
import CalendarDate from "../../components/Calendar/CalendarDate";
import { useEffect } from "react";
import { apiGet } from "../../Utils/apiServices";
import axios from "axios";
import { useAuth } from "../../Context/AuthProvider";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";
import DynamicDoughnutChart from "../../components/Graph/DynamicDoughChart";
import {
  convertIntoThousand,
  convertMinutes,
  generateColors,
} from "../../Utils/GraphFunctions";
import { channelImages } from "../../Utils/ChannelImages";
import { hasPermissions } from "../../Utils/RoutePermissions";
const baseUrl = process.env.REACT_APP_BASEURL;

const LiveChatAnalytics = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const CurrentCompanyName = CompanyName(CompanyHtml);
  const { auth } = useAuth();
  const [tagGraphData, setTagGraphData] = useState({
    totalCount: [],
    lables: [],
    data: [],
    isCalled: false,
  });
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [isSelected, setIsSelected] = useState(false);
  const [analytics, setAnalytics] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState("This month");
  const [isApiCall, setIsApiCall] = useState(false);

  const [liveChatAnalytics, setLiveChatAnalytics] = useState(null);
  const colors = generateColors(analytics?.tagAnalytics?.length);
  const [liveChatWaiting, setLiveChatWaiting] = useState(null);

  const navigate = useNavigate();

  const pageTabs = [
    {
      route: "/analytics",
      name: "Overview",
      permissions: ["FINANCE-DATA-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/live-chat-analytics",
      name: "Live Chat",
      permissions: ["ANALYTICS-MANAGER", "LIVE-CHAT-MANAGER"],
      active: true,
      allPermissionsMandatory: true,
    },
    {
      route: "/chatbot-analytics",
      name: "Chatbot",
      permissions: ["CHATBOT-SUPERVISOR", "CHAT-BOT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/statistics",
      name: "Statistics",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/package-mau",
      name: "Package",
      permissions: ["ANALYTICS-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/orders",
      name: "Orders",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/transfer-logs",
      name: "Transfer Logs",
      permissions: [],
      active: false,
      allPermissionsMandatory: true,
    },
  ];

  const clearStates = () => {
    setTagGraphData({
      totalCount: [],
      lables: [],
      data: [],
      isCalled: false,
    });
    setAnalytics(null);
    setLiveChatWaiting(null);
  };
  // const getLiveChatanalytics = () => {
  //   apiGet(
  //     `/stats/liveChatStats/${STARTDATE}/${ENDDATE}`,
  //     onSuccessLiveChatAnalutics,
  //     onFailureLiveChatAnalutics
  //   );
  // };
  // const onSuccessLiveChatAnalutics = (response) => {
  //   setIsApiCall(false);
  //   setAnalytics(response.data);
  // };
  // const onFailureLiveChatAnalutics = (error) => {
  //   console.log(error);
  // };
  const getLiveChatanalytics = () => {
    apiGet(
      `/detail-analytics/livechat/${STARTDATE}/${ENDDATE}`,
      onSuccessLiveChatAnalutics,
      onFailureLiveChatAnalutics
    );
  };
  const onSuccessLiveChatAnalutics = (response) => {
    setIsApiCall(false);
    setIsSelected(false);
    setAnalytics(response.data);

    let totalTagCount = response.data.tagAnalytics.reduce(
      (sum, item) => sum + item.tagCount,
      0
    );
    let graphLables = [];
    response.data.tagAnalytics.map((val) => {
      return graphLables.push(val.tagName);
    });

    let data = [];
    response.data.tagAnalytics.map((val) => {
      return data.push(val.tagCount);
    });

    setTagGraphData({
      totalCount: totalTagCount,
      lables: graphLables,
      data: data,
      isCalled: true,
    });
  };
  const onFailureLiveChatAnalutics = (error) => {
    console.log(error);
  };
  const getLiveChatWaiting = () => {
    apiGet(
      `/detail-analytics/waitingTime/${STARTDATE}/${ENDDATE}`,
      onSuccessLiveChatWaiting,
      onFailureLiveChatWaiting
    );
  };
  const onSuccessLiveChatWaiting = (response) => {
    // setIsApiCall(false);

    // console.log(response);
    setLiveChatWaiting(response.data);
  };
  const onFailureLiveChatWaiting = (error) => {
    console.log(error);
  };

  const exportData = async () => {
    try {
      const response = await axios.get(
        baseUrl + `/stats/export/livechatstats/${STARTDATE}/${ENDDATE}`,
        {
          responseType: "arraybuffer", // Ensure the response is treated as binary data
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "LiveChat.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  useEffect(() => {
    getLiveChatanalytics();
    getLiveChatWaiting();
  }, []);

  const filterDropdown = [
    // "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];

  const channels = [
    {
      icon: null,
      name: "All Channels",
      apiSendName: "ALL",
      disabled: false,
    },
    // {
    //   icon: whatsAppImg,
    //   name: "WhatsApp",
    //   apiSendName: "WHATSAPP",
    //   disabled: false,
    // },
    // {
    //   icon: messengerIcon,
    //   name: "Facebook",
    //   apiSendName: "FACEBOOK",
    //   disabled: true,
    // },
    // {
    //   icon: instaIcon,
    //   name: "Instagram",
    //   apiSendName: "INSTAGRAM",
    //   disabled: true,
    // },
  ];

  const handleDatePickerClose = () => {
    if (selectedDateFilter === "Custom range") {
      setIsSelected(true);
    }
    UIkit.drop("#datepickerdropForLivechat").hide(true);
  };

  const onChange = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
  };

  useEffect(() => {
    if (isApiCall === true) {
      if (selectedDateFilter === "Custom range") {
        if (isSelected === true) {
          getLiveChatanalytics();
          getLiveChatWaiting();
        }
      } else {
        getLiveChatanalytics();
        getLiveChatWaiting();
      }
    }
  }, [isApiCall]);

  useEffect(() => {
    if (isSelected === true) {
      setIsApiCall(true);
    }
  }, [isSelected]);

  const handleDateFilter = (filter) => {
    setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    if (filter !== "Custom range") {
      setIsApiCall(true);
    }
  };

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="boradcastWrp">
        <div className="boradcastTabbing">
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #newAnalyticsTab"
          >
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                <li
                  onClick={() => {
                    navigate(val.route, { replace: true });
                  }}
                  className={val.active === true ? "uk-active" : ""}
                  key={index}
                >
                  <a>{val.name}</a>
                </li>
              ) : null;
            })}
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="newAnalyticsTab">
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                val.name === "Live Chat" ? (
                  <li key={index}>
                    <div className="overviewContent">
                      <div className="uk-container">
                        <div className="uk-grid uk-flex-middle" uk-grid="">
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsTabWrp"
                              style={{ margin: "0 0 20px" }}
                            >
                              <div className="whatappTabBtn">
                                <ul
                                  className="uk-subnav uk-subnav-pill"
                                  uk-switcher="connect: .whatsappTabBtn"
                                >
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-analytics", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Overview</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-agent", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Agents</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-routing", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Routes</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-voice-of-customer", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Voice of customer</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-channels", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a href="#">Channels</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-tags", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a href="#">Tags</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsWhatsappContent"
                              style={{ marginTop: "16px" }}
                            >
                              <ul className="uk-switcher uk-margin whatsappTabBtn">
                                <li>
                                  <div
                                    className="uk-grid uk-flex-middle"
                                    uk-grid=""
                                  >
                                    <div className="uk-width-1-2 uk-margin-remove">
                                      <div className="pageHeading uk-margin-remove-bottom">
                                        <h3>
                                          Live Chat Right Now{" "}
                                          <CurrentCompanyName
                                            companyDetails={companyDetails}
                                          />
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="uk-width-1-2 btnSection uk-margin-remove">
                                      {/* <button
                                      className="exportBtn"
                                      onClick={exportData}
                                    >
                                      <Download /> Export Data
                                    </button> */}

                                      <div className="uk-inline">
                                        <button className="calenderBtn rangeBtn">
                                          <Calendar /> {selectedDateFilter}
                                          <span className="uk-inline">
                                            <ChevronDown />
                                          </span>
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="calendarDropdown"
                                          id="dateDropdownSection"
                                        >
                                          <div className="dropDownHeading">
                                            <span>Select a range</span>
                                          </div>
                                          <ul>
                                            {filterDropdown.map(
                                              (val, index) => {
                                                return (
                                                  <>
                                                    <li
                                                      className={
                                                        selectedDateFilter ===
                                                        val
                                                          ? "activeBtn"
                                                          : ""
                                                      }
                                                      key={index}
                                                      onClick={() => {
                                                        if (
                                                          val !== "Custom range"
                                                        ) {
                                                          clearStates();
                                                        }
                                                        handleDateFilter(val);
                                                      }}
                                                    >
                                                      {filterDropdown.length -
                                                        1 ===
                                                      index ? (
                                                        <div className="uk-inline uk-text-left">
                                                          <button
                                                            className="rangeBtn"
                                                            type="button"
                                                          >
                                                            <span className="rangeBtnCircle"></span>{" "}
                                                            Custom range
                                                          </button>
                                                          <div
                                                            id="datepickerdropForLivechat"
                                                            uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                            className="date-picker-container"
                                                          >
                                                            <CalendarDate
                                                              onChange={
                                                                onChange
                                                              }
                                                            />
                                                            <div className="date-picker-btn-container uk-text-right">
                                                              <button
                                                                className="date-selection-btn"
                                                                onClick={
                                                                  handleDatePickerClose
                                                                }
                                                              >
                                                                Ok
                                                              </button>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <button
                                                          type="button"
                                                          className="rangeBtn"
                                                        >
                                                          <span className="rangeBtnCircle"></span>{" "}
                                                          {val}
                                                        </button>
                                                      )}
                                                    </li>
                                                    {filterDropdown.length -
                                                      2 ===
                                                      index && (
                                                      <hr
                                                        style={{
                                                          margin: "5px 0",
                                                        }}
                                                      />
                                                    )}
                                                  </>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="uk-width-1-1 uk-margin-remove">
                                      <div className="overviewMainContent">
                                        <div className="mainBox">
                                          <div className="boxHeading">
                                            <span>Live Chat Summary</span>
                                          </div>
                                          <div className="boxContent">
                                            <div className="uk-grid" uk-grid="">
                                              <div className="uk-width-1-6 uk-first-column">
                                                <div className="txtwrp">
                                                  <h2>
                                                    {analytics === null ? (
                                                      <span uk-spinner=""></span>
                                                    ) : (
                                                      convertIntoThousand(
                                                        analytics?.totalOpen +
                                                          analytics?.totalWaiting +
                                                          analytics?.totalSolve +
                                                          analytics?.totalClosed
                                                      )
                                                    )}
                                                  </h2>
                                                  <span>Total</span>
                                                </div>
                                              </div>
                                              <div className="uk-width-1-6">
                                                <div className="txtwrp">
                                                  <h2>
                                                    {analytics === null ? (
                                                      <span uk-spinner=""></span>
                                                    ) : (
                                                      convertIntoThousand(
                                                        analytics?.totalOpen
                                                      )
                                                    )}
                                                  </h2>
                                                  <span>Open</span>
                                                </div>
                                              </div>
                                              <div className="uk-width-1-6">
                                                <div className="txtwrp">
                                                  <h2>
                                                    {analytics === null ? (
                                                      <span uk-spinner=""></span>
                                                    ) : (
                                                      convertIntoThousand(
                                                        analytics?.totalWaiting
                                                      )
                                                    )}
                                                  </h2>
                                                  <span>Waiting</span>
                                                </div>
                                              </div>
                                              <div className="uk-width-1-6">
                                                <div className="txtwrp">
                                                  <h2>
                                                    {analytics === null ? (
                                                      <span uk-spinner=""></span>
                                                    ) : (
                                                      convertIntoThousand(
                                                        analytics?.totalSolve
                                                      )
                                                    )}
                                                  </h2>
                                                  <span>Solved</span>
                                                </div>
                                              </div>
                                              <div className="uk-width-1-6">
                                                <div className="txtwrp">
                                                  <h2>
                                                    {analytics === null ? (
                                                      <span uk-spinner=""></span>
                                                    ) : (
                                                      convertIntoThousand(
                                                        analytics?.totalClosed
                                                      )
                                                    )}
                                                  </h2>
                                                  <span>Closed</span>
                                                </div>
                                              </div>
                                              <div className="uk-width-1-6">
                                                <div className="txtwrp">
                                                  <h2>
                                                    {analytics === null ? (
                                                      <span uk-spinner=""></span>
                                                    ) : (
                                                      convertIntoThousand(
                                                        analytics?.totalUnassignedChats
                                                      )
                                                    )}
                                                  </h2>
                                                  <span>Unassigned</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="mainBox">
                                          <div className="boxHeading">
                                            <span>Live chat waiting time</span>
                                          </div>
                                          <div className="boxContent">
                                            <div
                                              className="uk-grid uk-flex-middle"
                                              uk-grid=""
                                            >
                                              <div className="uk-width-2-5">
                                                <div className="boxTimerWrp">
                                                  <p>
                                                    <Timer /> First response
                                                    time
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="uk-width-3-5">
                                                <div
                                                  className="uk-grid"
                                                  uk-grid=""
                                                >
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp timeBoxTxt">
                                                      <h2>
                                                        {liveChatWaiting ===
                                                        null ? (
                                                          <span uk-spinner=""></span>
                                                        ) : (
                                                          convertMinutes(
                                                            parseInt(
                                                              liveChatWaiting?.firstResponseMin
                                                            )
                                                          )
                                                        )}
                                                      </h2>
                                                      <span>min</span>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp timeBoxTxt">
                                                      {/* <h2>34m 23s</h2> */}
                                                      <h2>
                                                        {liveChatWaiting ===
                                                        null ? (
                                                          <span uk-spinner=""></span>
                                                        ) : (
                                                          convertMinutes(
                                                            parseInt(
                                                              liveChatWaiting?.firstResponseMax
                                                            )
                                                          )
                                                        )}
                                                      </h2>
                                                      <span>max</span>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp timeBoxTxt">
                                                      {/* <h2>1m 48s</h2> */}
                                                      <h2>
                                                        {liveChatWaiting ===
                                                        null ? (
                                                          <span uk-spinner=""></span>
                                                        ) : (
                                                          convertMinutes(
                                                            parseInt(
                                                              liveChatWaiting?.avgResponse
                                                            )
                                                          )
                                                        )}
                                                      </h2>
                                                      <span>avg</span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <hr />
                                            <div
                                              className="uk-grid uk-flex-middle"
                                              uk-grid=""
                                            >
                                              <div className="uk-width-2-5">
                                                <div className="boxTimerWrp">
                                                  <p>
                                                    <Alarm /> Handling time
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="uk-width-3-5">
                                                <div
                                                  className="uk-grid"
                                                  uk-grid=""
                                                >
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp timeBoxTxt">
                                                      {/* <h2>4s</h2> */}
                                                      <h2>
                                                        {liveChatWaiting ===
                                                        null ? (
                                                          <span uk-spinner=""></span>
                                                        ) : (
                                                          convertMinutes(
                                                            parseInt(
                                                              liveChatWaiting?.handleResponseTimeMin
                                                            )
                                                          )
                                                        )}
                                                      </h2>
                                                      <span>min</span>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp timeBoxTxt">
                                                      {/* <h2>34m 23s</h2> */}
                                                      <h2>
                                                        {" "}
                                                        {liveChatWaiting ===
                                                        null ? (
                                                          <span uk-spinner=""></span>
                                                        ) : (
                                                          convertMinutes(
                                                            parseInt(
                                                              liveChatWaiting?.handleResponseTimeMax
                                                            )
                                                          )
                                                        )}
                                                      </h2>
                                                      <span>max</span>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp timeBoxTxt">
                                                      {/* <h2>1m 48s</h2> */}
                                                      <h2>
                                                        {" "}
                                                        <h2>
                                                          {" "}
                                                          {liveChatWaiting ===
                                                          null ? (
                                                            <span uk-spinner=""></span>
                                                          ) : (
                                                            convertMinutes(
                                                              parseInt(
                                                                liveChatWaiting?.handleResponseTimeAvg
                                                              )
                                                            )
                                                          )}
                                                        </h2>
                                                      </h2>
                                                      <span>avg</span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="halfDonutSec halfDonutSize">
                                          <div
                                            className="uk-grid"
                                            uk-grid=""
                                            uk-height-match="target: > div > .halfDonutHeight"
                                          >
                                            <div className="uk-width-1-2">
                                              <div className="mainBox halfDonutHeight">
                                                <div className="boxHeading">
                                                  <span>User's staus</span>
                                                </div>
                                                <div className="boxContent">
                                                  <div
                                                    className="chartWrp"
                                                    style={{
                                                      minHeight: "260px",
                                                      minWidth: "260px",
                                                    }}
                                                  >
                                                    {analytics !== null &&
                                                      (analytics?.totalActiveAgents +
                                                        analytics?.totalOfflineAgents +
                                                        analytics?.totalAwayAgents +
                                                        analytics?.totalBusyAgents >
                                                      0 ? (
                                                        <DoughnutChart
                                                          degree={180}
                                                          backgroudColor={[
                                                            "#1EB954",
                                                            "#C9252D",
                                                            "#FF7125",
                                                            "#B4B4B4",
                                                          ]}
                                                          borderColor={[
                                                            "#1EB954",
                                                            "#C9252D",
                                                            "#FF7125",
                                                            "#B4B4B4",
                                                          ]}
                                                          graphData={[
                                                            analytics?.totalActiveAgents,
                                                            analytics?.totalAwayAgents,
                                                            analytics?.totalBusyAgents,
                                                            analytics?.totalOfflineAgents,
                                                          ]}
                                                          graphlabels={[
                                                            "Active",
                                                            "Away",
                                                            "Busy",
                                                            "Offline",
                                                          ]}
                                                        />
                                                      ) : (
                                                        <DoughnutChart
                                                          degree={180}
                                                          backgroudColor={[
                                                            "#B4B4B4",
                                                          ]}
                                                          borderColor={[
                                                            "#B4B4B4",
                                                          ]}
                                                          graphData={[1]}
                                                          graphlabels={["-"]}
                                                        />
                                                      ))}
                                                    <div
                                                      className="chartData"
                                                      style={{ top: "50%" }}
                                                    >
                                                      <h3>
                                                        {analytics?.totalActiveAgents ===
                                                        undefined
                                                          ? 0
                                                          : analytics?.totalActiveAgents}
                                                        /
                                                        {isNaN(
                                                          analytics?.totalActiveAgents +
                                                            analytics?.totalOfflineAgents +
                                                            analytics?.totalAwayAgents +
                                                            analytics?.totalBusyAgents
                                                        )
                                                          ? 0
                                                          : analytics?.totalActiveAgents +
                                                            analytics?.totalOfflineAgents +
                                                            analytics?.totalAwayAgents +
                                                            analytics?.totalBusyAgents}
                                                      </h3>
                                                      <p>Open Live Chats</p>
                                                    </div>
                                                  </div>
                                                  <div className="chartDataWrapper">
                                                    <ul>
                                                      <li>
                                                        <p>
                                                          <span className="lightGreen"></span>
                                                          Active
                                                        </p>
                                                      </li>
                                                      <li>
                                                        <p>
                                                          {
                                                            analytics?.totalActiveAgents
                                                          }
                                                        </p>
                                                      </li>
                                                    </ul>
                                                    <ul>
                                                      <li>
                                                        <p>
                                                          <span className="darkOrange"></span>
                                                          Away
                                                        </p>
                                                      </li>
                                                      <li>
                                                        <p>
                                                          {
                                                            analytics?.totalBusyAgents
                                                          }
                                                        </p>
                                                      </li>
                                                    </ul>
                                                    <ul>
                                                      <li>
                                                        <p>
                                                          <span className="redDot"></span>
                                                          Busy
                                                        </p>
                                                      </li>
                                                      <li>
                                                        <p>
                                                          {
                                                            analytics?.totalAwayAgents
                                                          }
                                                        </p>
                                                      </li>
                                                    </ul>
                                                    <ul>
                                                      <li>
                                                        <p>
                                                          <span className="grayDot"></span>
                                                          Offline
                                                        </p>
                                                      </li>
                                                      <li>
                                                        <p>
                                                          {
                                                            analytics?.totalOfflineAgents
                                                          }
                                                        </p>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="uk-width-1-2">
                                              <div className="mainBox halfDonutHeight">
                                                <div className="boxHeading">
                                                  <span>
                                                    Assigned live chats
                                                  </span>
                                                </div>
                                                <div className="boxContent">
                                                  <div
                                                    className="chartWrp"
                                                    style={{
                                                      minHeight: "260px",
                                                      minWidth: "260px",
                                                    }}
                                                  >
                                                    {analytics !== null &&
                                                      (analytics?.totalUnassignedChats +
                                                        analytics?.totalAssignedChats >
                                                      0 ? (
                                                        <DoughnutChart
                                                          degree={180}
                                                          backgroudColor={[
                                                            "#2F5711",
                                                            "#1EB954",
                                                          ]}
                                                          borderColor={[
                                                            "#2F5711",
                                                            "#1EB954",
                                                          ]}
                                                          graphData={[
                                                            analytics?.totalUnassignedChats,
                                                            analytics?.totalAssignedChats,
                                                          ]}
                                                          graphlabels={[
                                                            "Unassigned",
                                                            "Assigned",
                                                          ]}
                                                        />
                                                      ) : (
                                                        <DoughnutChart
                                                          degree={180}
                                                          backgroudColor={[
                                                            "#B4B4B4",
                                                          ]}
                                                          borderColor={[
                                                            "#B4B4B4",
                                                          ]}
                                                          graphData={[1]}
                                                          graphlabels={["-"]}
                                                        />
                                                      ))}
                                                    <div
                                                      className="chartData"
                                                      style={{ top: "50%" }}
                                                    >
                                                      <h3>
                                                        {" "}
                                                        {isNaN(
                                                          analytics?.totalUnassignedChats +
                                                            analytics?.totalAssignedChats
                                                        )
                                                          ? 0
                                                          : analytics?.totalUnassignedChats +
                                                            analytics?.totalAssignedChats}
                                                      </h3>
                                                      <p>Assigned Chats</p>
                                                    </div>
                                                  </div>
                                                  <div className="chartDataWrapper">
                                                    <ul>
                                                      <li>
                                                        <p>
                                                          <span className="darkGreen"></span>
                                                          Unassigned
                                                        </p>
                                                      </li>
                                                      <li>
                                                        <p>
                                                          {
                                                            analytics?.totalUnassignedChats
                                                          }
                                                        </p>
                                                      </li>
                                                    </ul>
                                                    <ul>
                                                      <li>
                                                        <p>
                                                          <span className="lightGreen"></span>
                                                          Assigned
                                                        </p>
                                                      </li>
                                                      <li>
                                                        <p>
                                                          {
                                                            analytics?.totalAssignedChats
                                                          }
                                                        </p>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="mainBox">
                                          <div className="boxHeading">
                                            <span>
                                              Top most used tags in live chats
                                            </span>
                                          </div>
                                          <div className="boxContent">
                                            <div className="uk-grid" uk-grid="">
                                              <div className="uk-width-1-2">
                                                <div className="chartWrp fullDonutSize">
                                                  {/* <DoughnutChart
                                                  degree={360}
                                                  backgroudColor={[
                                                    "#1F73B7",
                                                    "#84EB7F",
                                                    "#FF9F0A",
                                                    "#BBB35F",
                                                    "#485A93",
                                                    "#F6C176",
                                                    "#96CCF9",
                                                    "#E4E4E4",
                                                  ]}
                                                  borderColor={[
                                                    "#1F73B7",
                                                    "#84EB7F",
                                                    "#FF9F0A",
                                                    "#BBB35F",
                                                    "#485A93",
                                                    "#F6C176",
                                                    "#96CCF9",
                                                    "#E4E4E4",
                                                  ]}
                                                  graphData={[
                                                    12, 9, 5, 15, 6, 3, 8, 20,
                                                  ]}
                                                /> */}
                                                  {tagGraphData?.totalCount >
                                                  0 ? (
                                                    <DynamicDoughnutChart
                                                      degree={360}
                                                      graphData={
                                                        tagGraphData?.data
                                                      }
                                                      graphLabels={
                                                        tagGraphData?.lables
                                                      }
                                                      colors={colors}
                                                    />
                                                  ) : (
                                                    <DoughnutChart
                                                      degree={360}
                                                      backgroudColor={[
                                                        "#B4B4B4",
                                                      ]}
                                                      borderColor={["#B4B4B4"]}
                                                      graphData={[1]}
                                                      graphlabels={["-"]}
                                                    />
                                                  )}

                                                  <div
                                                    className="chartData"
                                                    style={{ top: "36%" }}
                                                  >
                                                    <h3>
                                                      {tagGraphData?.totalCount}
                                                    </h3>
                                                    <p>
                                                      Total Tags used in Live
                                                      Chats
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="uk-width-1-2">
                                                <div className="boxTableWrp">
                                                  <table className="uk-table uk-table-striped">
                                                    <thead>
                                                      <tr>
                                                        {/* <th>RANKING</th> */}
                                                        <th className="uk-table-expand">
                                                          TAG
                                                        </th>
                                                        <th className="uk-table-shrink">
                                                          COUNT
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {analytics?.tagAnalytics
                                                        .length > 0 ? (
                                                        analytics?.tagAnalytics.map(
                                                          (val, index) => {
                                                            return (
                                                              <tr key={index}>
                                                                {/* <td>1</td> */}
                                                                <td>
                                                                  <span
                                                                    style={{
                                                                      backgroundColor:
                                                                        colors[
                                                                          index
                                                                        ],
                                                                    }}
                                                                  ></span>
                                                                  {val.tagName}
                                                                </td>
                                                                <td>
                                                                  {val.tagCount}
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )
                                                      ) : (
                                                        <tr>
                                                          <td
                                                            colSpan={8}
                                                            className="dataNotFound"
                                                          >
                                                            {analytics ===
                                                            null ? (
                                                              <span uk-spinner=""></span>
                                                            ) : (
                                                              "Data Not Found"
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )}

                                                      {/* <tr>
                                                      <td>1</td>
                                                      <td>
                                                        <span className="blueDot"></span>
                                                        Order
                                                      </td>
                                                      <td>871</td>
                                                    </tr> */}
                                                      {/* <tr>
                                                      <td>1</td>
                                                      <td>
                                                        <span className="lightGreen"></span>
                                                        Order
                                                      </td>
                                                      <td>871</td>
                                                    </tr>
                                                    <tr>
                                                      <td>1</td>
                                                      <td>
                                                        <span className="darkOrange"></span>
                                                        Order
                                                      </td>
                                                      <td>871</td>
                                                    </tr>
                                                    <tr>
                                                      <td>1</td>
                                                      <td>
                                                        <span className="darkDrayDot"></span>
                                                        Order
                                                      </td>
                                                      <td>871</td>
                                                    </tr>
                                                    <tr>
                                                      <td>1</td>
                                                      <td>
                                                        <span className="darkBlueDot"></span>
                                                        Order
                                                      </td>
                                                      <td>871</td>
                                                    </tr>
                                                    <tr>
                                                      <td>1</td>
                                                      <td>
                                                        <span className="yellowDot"></span>
                                                        Order
                                                      </td>
                                                      <td>871</td>
                                                    </tr>
                                                    <tr>
                                                      <td>1</td>
                                                      <td>
                                                        <span className="lightBlueDot"></span>
                                                        Order
                                                      </td>
                                                      <td>871</td>
                                                    </tr>
                                                    <tr>
                                                      <td>1</td>
                                                      <td>
                                                        <span className="grayDot"></span>
                                                        Order
                                                      </td>
                                                      <td>871</td>
                                                    </tr> */}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="mainBox">
                                          <div className="boxHeading">
                                            <span>
                                              Live chat performance per channel
                                            </span>
                                          </div>
                                          <div className="boxFullTable tableCenter">
                                            <table className="uk-table uk-table-striped">
                                              <thead>
                                                <tr>
                                                  <th className="uk-table-expand">
                                                    RANKING
                                                  </th>
                                                  <th>TOTAL</th>
                                                  <th>OPEN</th>
                                                  <th>WAITING</th>
                                                  <th>SOLVED</th>
                                                  <th>CLOSED</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {analytics?.channelPerformances
                                                  ?.length > 0 ? (
                                                  analytics?.channelPerformances.map(
                                                    (val, index) => {
                                                      return (
                                                        <tr key={index}>
                                                          <td className="channelName">
                                                            <img
                                                              src={channelImages(
                                                                val.channelId
                                                              )}
                                                              alt=""
                                                            />
                                                            {val.channelId}
                                                          </td>
                                                          <td>
                                                            {val.totalChat}
                                                          </td>
                                                          <td>
                                                            {val.openChat}
                                                          </td>
                                                          <td>
                                                            {val.waitChat}
                                                          </td>
                                                          <td>
                                                            {val.solveChat}
                                                          </td>
                                                          <td>
                                                            {val.closeChat}
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )
                                                ) : (
                                                  <tr>
                                                    <td
                                                      colSpan={8}
                                                      className="dataNotFound"
                                                    >
                                                      {analytics === null ? (
                                                        <span uk-spinner=""></span>
                                                      ) : (
                                                        "Data Not Found"
                                                      )}
                                                    </td>
                                                  </tr>
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                {/* <li>
                            <div className="uk-grid uk-flex-middle" uk-grid="">
                              <div className="uk-width-1-2 uk-margin-remove">
                                <div className="pageHeading uk-margin-remove-bottom">
                                  <h3>
                                    Live Chat Agent Utilization And Performance
                                  </h3>
                                </div>
                              </div>
                              <div className="uk-width-1-2 btnSection uk-margin-remove">
                                <button className="exportBtn">
                                  <Download /> Export Data
                                </button>

                                <div className="uk-inline">
                                  <button className="calenderBtn rangeBtn">
                                    <Calendar /> {selectedDateFilter}
                                    <span className="uk-inline">
                                      <ChevronDown />
                                    </span>
                                  </button>
                                  <div
                                    uk-dropdown="mode: click"
                                    className="calendarDropdown"
                                    id="dateDropdownSection"
                                  >
                                    <div className="dropDownHeading">
                                      <span>Select a range</span>
                                    </div>
                                    <ul>
                                      {filterDropdown.map((val, index) => {
                                        return (
                                          <>
                                            <li
                                              className={
                                                selectedDateFilter === val
                                                  ? "activeBtn"
                                                  : ""
                                              }
                                              key={index}
                                              onClick={() =>
                                                handleDateFilter(val)
                                              }
                                            >
                                              {filterDropdown.length - 1 ===
                                              index ? (
                                                <div className="uk-inline uk-text-left">
                                                  <button
                                                    className="rangeBtn"
                                                    type="button"
                                                  >
                                                    <span className="rangeBtnCircle"></span>{" "}
                                                    Custom range
                                                  </button>
                                                  <div
                                                    id="datepickerdrop"
                                                    uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                    className="date-picker-container"
                                                  >
                                                    <CalendarDate
                                                      onChange={onChange}
                                                    />
                                                    <div className="date-picker-btn-container uk-text-right">
                                                      <button
                                                        className="date-selection-btn"
                                                        onClick={
                                                          handleDatePickerClose
                                                        }
                                                      >
                                                        Ok
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="rangeBtn"
                                                >
                                                  <span className="rangeBtnCircle"></span>{" "}
                                                  {val}
                                                </button>
                                              )}
                                            </li>
                                            {filterDropdown.length - 2 ===
                                              index && (
                                              <hr style={{ margin: "5px 0" }} />
                                            )}
                                          </>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="uk-width-1-1 uk-margin-remove">
                                <div className="overviewMainContent">
                                  <div className="mainBox">
                                    <div className="boxHeading">
                                      <span>
                                        Status-wise Average Distribution
                                      </span>
                                    </div>
                                    <div className="boxContent">
                                      <div className="uk-grid" uk-grid="">
                                        <div className="uk-width-1-3 uk-first-column">
                                          <div className="txtwrp">
                                            <h2>8h 24m</h2>
                                            <span>Logged in time</span>
                                          </div>
                                        </div>
                                        <div className="uk-width-1-3">
                                          <div className="txtwrp">
                                            <h2>15m</h2>
                                            <span>Away status</span>
                                          </div>
                                        </div>
                                        <div className="uk-width-1-3">
                                          <div className="txtwrp">
                                            <h2>8h 9m</h2>
                                            <span>Occupancy</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="mainBox">
                                    <div className="boxHeading">
                                      <span>Status distribution</span>
                                    </div>
                                    <div className="boxContent">
                                      <div className="uk-grid" uk-grid="">
                                        <div className="uk-width-1-4">
                                          <div className="chartWrp">
                                            <DoughnutChart
                                              degree={360}
                                              backgroudColor={[
                                                "#2F5711",
                                                "#EDC843",
                                                "#A8200D",
                                              ]}
                                              borderColor={[
                                                "#2F5711",
                                                "#EDC843",
                                                "#A8200D",
                                              ]}
                                              graphData={[12, 9, 5]}
                                            />
                                            <div className="chartData">
                                              <h3>66.06</h3>
                                              <p>Under capacity</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="uk-width-3-4">
                                          <div className="chartRecordData">
                                            <div className="distributionGraph">
                                              <div className="chartHeading">
                                                <h3>100%</h3>
                                                <p>Occupancy</p>
                                              </div>
                                              <div className="progressWrp">
                                                <progress
                                                  className="uk-progress progressNomral"
                                                  value="50"
                                                  max="100"
                                                ></progress>
                                              </div>
                                              <div className="listWrp">
                                                <div className="listTxtwrp">
                                                  <ul>
                                                    <li>
                                                      <p>
                                                        <span className="yellowDot"></span>
                                                        Under capacity
                                                      </p>
                                                    </li>
                                                    <li>
                                                      <p>
                                                        <span className="lightGreenDot"></span>
                                                        Idle
                                                      </p>
                                                    </li>
                                                    <li>
                                                      <p>
                                                        <span className="greenDot"></span>
                                                        Available
                                                      </p>
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div className="listNumWrp">
                                                  <ul>
                                                    <li>
                                                      <p>66.06%</p>
                                                    </li>
                                                    <li>
                                                      <p>33.9%</p>
                                                    </li>
                                                    <li>
                                                      <p>{"< 1%"}</p>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="mainBox">
                                    <div className="boxHeading">
                                      <span>
                                        Monthly Active User (breakup by
                                        countries)
                                      </span>
                                    </div>
                                    <div className="boxContent">
                                      <div
                                        className="uk-grid uk-flex-middle"
                                        uk-grid=""
                                      >
                                        <div className="uk-width-3-5">
                                          <div className="overviewChart">
                                            <LineChart
                                              dataSet1={[
                                                50, 20, 30, 25, 0, 40, 10,
                                              ]}
                                              dataSet2={[
                                                20, 10, 40, 35, 22, 10, 30,
                                              ]}
                                              dataSet3={[
                                                40, 20, 40, 50, 45, 30, 25,
                                              ]}
                                              graphLabels={[
                                                "January",
                                                "February",
                                                "March",
                                                "April",
                                                "May",
                                                "June",
                                                "July",
                                              ]}
                                            />
                                          </div>
                                        </div>
                                        <div className="uk-width-2-5">
                                          <div className="chartRecordData">
                                            <div className="chartRecordDataWrp">
                                              <div className="deliveryRate">
                                                <div
                                                  className="detailHeading"
                                                  style={{
                                                    marginBottom: "24px",
                                                  }}
                                                >
                                                  <p>CHANNEL</p>
                                                </div>
                                                <ul>
                                                  <li>
                                                    <p>
                                                      <span className="darkGreenDot"></span>
                                                      WhatsApp
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p>
                                                      <span className="yellowDot"></span>
                                                      Facebook Messanger
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p>
                                                      <span className="lightGreenDot"></span>
                                                      Instagram
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="mainBox">
                                    <div className="boxHeading">
                                      <span>Live chat response time</span>
                                    </div>
                                    <div className="boxContent">
                                      <div
                                        className="uk-grid uk-flex-middle"
                                        uk-grid=""
                                      >
                                        <div className="uk-width-2-5">
                                          <div className="boxTimerWrp">
                                            <p>
                                              <Timer /> First response time
                                            </p>
                                          </div>
                                        </div>
                                        <div className="uk-width-3-5">
                                          <div className="uk-grid" uk-grid="">
                                            <div className="uk-width-1-3">
                                              <div className="txtwrp timeBoxTxt">
                                                <h2>4s</h2>
                                                <span>min</span>
                                              </div>
                                            </div>
                                            <div className="uk-width-1-3">
                                              <div className="txtwrp timeBoxTxt">
                                                <h2>34m 23s</h2>
                                                <span>max</span>
                                              </div>
                                            </div>
                                            <div className="uk-width-1-3">
                                              <div className="txtwrp timeBoxTxt">
                                                <h2>1m 48s</h2>
                                                <span>avg</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <hr />
                                      <div
                                        className="uk-grid uk-flex-middle"
                                        uk-grid=""
                                      >
                                        <div className="uk-width-2-5">
                                          <div className="boxTimerWrp">
                                            <p>
                                              <Alarm /> All response time
                                            </p>
                                          </div>
                                        </div>
                                        <div className="uk-width-3-5">
                                          <div className="uk-grid" uk-grid="">
                                            <div className="uk-width-1-3">
                                              <div className="txtwrp timeBoxTxt">
                                                <h2>4s</h2>
                                                <span>min</span>
                                              </div>
                                            </div>
                                            <div className="uk-width-1-3">
                                              <div className="txtwrp timeBoxTxt">
                                                <h2>34m 23s</h2>
                                                <span>max</span>
                                              </div>
                                            </div>
                                            <div className="uk-width-1-3">
                                              <div className="txtwrp timeBoxTxt">
                                                <h2>1m 48s</h2>
                                                <span>avg</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="mainBox">
                                    <div className="boxHeading">
                                      <span>
                                        Business Initiated Messages Status
                                        Breakdown
                                      </span>
                                    </div>
                                    <div className="boxContent">
                                      <div className="uk-grid" uk-grid="">
                                        <div className="uk-width-1-4">
                                          <div className="chartWrp">
                                            <DoughnutChart
                                              degree={360}
                                              backgroudColor={[
                                                "#2F5711",
                                                "#EDC843",
                                                "#A8200D",
                                              ]}
                                              borderColor={[
                                                "#2F5711",
                                                "#EDC843",
                                                "#A8200D",
                                              ]}
                                              graphData={[12, 9, 5]}
                                            />
                                            <div className="chartData">
                                              <h3>8M 58S</h3>
                                              <p>133 Total conversations</p>
                                            </div>
                                          </div>
                                          <div className="chartRecordData uk-margin-top">
                                            <div className="chartRecordDataWrp">
                                              <div className="deliveryRate">
                                                <ul>
                                                  <li>
                                                    <p>
                                                      <span className="greenDot"></span>
                                                      Minutes
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p>
                                                      <span className="lightGreenDot"></span>
                                                      Hours
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p>
                                                      <span className="yellowDot"></span>
                                                      Days
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                              <div className="percentageData">
                                                <ul>
                                                  <li>
                                                    <p>58.65%</p>
                                                  </li>
                                                  <li>
                                                    <p>58.65%</p>
                                                  </li>
                                                  <li>
                                                    <p>58.65%</p>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="uk-width-3-4">
                                          <div className="chartRecordData">
                                            <div className="chartRecordDataWrp">
                                              <div className="deliveryRate">
                                                <ul>
                                                  <li>
                                                    <p>
                                                      <span className="greenDot"></span>
                                                      Below 5 Min
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p>
                                                      <span className="greenDot"></span>
                                                      Between 5 and 10 min
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p>
                                                      <span className="greenDot"></span>
                                                      Between 10 and 15 min
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p>
                                                      <span className="greenDot"></span>
                                                      Between 15 and 30 min
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p>
                                                      <span className="greenDot"></span>
                                                      Between 30 and 1 Hour
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p>
                                                      <span className="greenDot"></span>
                                                      Between 1 and 24 Hour
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                              <div className="progressBar">
                                                <ul>
                                                  <li>
                                                    <progress
                                                      className="uk-progress progressNomral"
                                                      value="50"
                                                      max="100"
                                                    ></progress>
                                                  </li>
                                                  <li>
                                                    <progress
                                                      className="uk-progress progressNomral"
                                                      value="50"
                                                      max="100"
                                                    ></progress>
                                                  </li>
                                                  <li>
                                                    <progress
                                                      className="uk-progress progressNomral"
                                                      value="20"
                                                      max="100"
                                                    ></progress>
                                                  </li>
                                                  <li>
                                                    <progress
                                                      className="uk-progress progressNomral"
                                                      value="10"
                                                      max="100"
                                                    ></progress>
                                                  </li>
                                                  <li>
                                                    <progress
                                                      className="uk-progress progressNomral"
                                                      value="10"
                                                      max="100"
                                                    ></progress>
                                                  </li>
                                                  <li>
                                                    <progress
                                                      className="uk-progress progressNomral"
                                                      value="10"
                                                      max="100"
                                                    ></progress>
                                                  </li>
                                                </ul>
                                              </div>
                                              <div className="percentageData">
                                                <ul>
                                                  <li>
                                                    <p>58.65%</p>
                                                  </li>
                                                  <li>
                                                    <p>58.65%</p>
                                                  </li>
                                                  <li>
                                                    <p>58.65%</p>
                                                  </li>
                                                  <li>
                                                    <p>12.78%</p>
                                                  </li>
                                                  <li>
                                                    <p>3.33%</p>
                                                  </li>
                                                  <li>
                                                    <p>0.1%</p>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="mainBox">
                                    <div className="boxHeading">
                                      <span>Utilization per agent (avg.)</span>
                                    </div>
                                    <div className="boxFullTable">
                                      <table className="uk-table uk-table-striped">
                                        <thead>
                                          <tr>
                                            <th className="uk-table-expand">
                                              AGENT
                                            </th>
                                            <th>LOGGED IN TIME</th>
                                            <th>AWAY</th>
                                            <th>OCCUPANCY</th>
                                            <th>AVAILABLE</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td className="channelName">
                                              <Link to="/live-chat-agent">
                                                <img src={demoImg} alt="" />
                                                Emre Altan
                                              </Link>
                                            </td>
                                            <td>29:18:18</td>
                                            <td>-</td>
                                            <td>29:18:18</td>
                                            <td>29:18:18</td>
                                          </tr>
                                          <tr>
                                            <td className="channelName">
                                              <img src={demoImg} alt="" />
                                              Emre Altan
                                            </td>
                                            <td>29:18:18</td>
                                            <td>-</td>
                                            <td>29:18:18</td>
                                            <td>29:18:18</td>
                                          </tr>
                                          <tr>
                                            <td className="channelName">
                                              <img src={demoImg} alt="" />
                                              Emre Altan
                                            </td>
                                            <td>29:18:18</td>
                                            <td>-</td>
                                            <td>29:18:18</td>
                                            <td>29:18:18</td>
                                          </tr>
                                          <tr>
                                            <td className="channelName">
                                              <img src={demoImg} alt="" />
                                              Emre Altan
                                            </td>
                                            <td>29:18:18</td>
                                            <td>-</td>
                                            <td>29:18:18</td>
                                            <td>29:18:18</td>
                                          </tr>
                                          <tr>
                                            <td className="channelName">
                                              <img src={demoImg} alt="" />
                                              Emre Altan
                                            </td>
                                            <td>29:18:18</td>
                                            <td>-</td>
                                            <td>29:18:18</td>
                                            <td>29:18:18</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li> */}
                                {/* <li>
                                <div
                                  className="overviewContent uk-padding-remove"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <div className="uk-container">
                                    <div className="overviewTopDropdown">
                                      <div
                                        className="uk-grid uk-flex-middle"
                                        uk-grid=""
                                      >
                                        <div className="uk-width-1-2">
                                          <div className="pageHeading uk-margin-remove-bottom">
                                            <h3>Live Chat Routes</h3>
                                          </div>
                                        </div>
                                        <div className="uk-width-1-2 btnSection">
                                          <div className="agentFilterWrp">
                                            <div className="allChannelDropDown">
                                              <div className="uk-inline tableFilterDropdown broadcastChannelFilter">
                                                <button
                                              type="button"
                                              className="dropdownBtn"
                                            >
                                              All Active Channels{" "}
                                              <ChevronDown />
                                            </button>
                                                <div
                                                  uk-dropdown="mode: click"
                                                  className="dropdownNav"
                                                >
                                                  <span className="dropdownHeading">
                                                    Channels
                                                  </span>
                                                  <ul>
                                                    {channels.map(
                                                      (val, index) => {
                                                        return (
                                                          <li
                                                            key={index}
                                                            className={
                                                              channel ===
                                                              val.apiSendName
                                                                ? "activeBtn"
                                                                : ""
                                                            }
                                                            onClick={() => {
                                                              setChannel(
                                                                val.apiSendName
                                                              );
                                                            }}
                                                          >
                                                            <button
                                                              type="button"
                                                              disabled={
                                                                val.disabled ===
                                                                true
                                                                  ? true
                                                                  : false
                                                              }
                                                              className={
                                                                val.disabled ===
                                                                true
                                                                  ? "opactityBtn"
                                                                  : ""
                                                              }
                                                            >
                                                              {index !== 0 && (
                                                                <img
                                                                  src={val.icon}
                                                                  alt=""
                                                                />
                                                              )}
                                                              {val.name}
                                                            </button>
                                                          </li>
                                                        );
                                                      }
                                                    )}
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="uk-inline">
                                              <button className="calenderBtn rangeBtn">
                                                <Calendar />{" "}
                                                {selectedDateFilter}
                                                <span className="uk-inline">
                                                  <ChevronDown />
                                                </span>
                                              </button>
                                              <div
                                                uk-dropdown="mode: click"
                                                className="calendarDropdown"
                                                id="dateDropdownSection"
                                              >
                                                <div className="dropDownHeading">
                                                  <span>Select a range</span>
                                                </div>
                                                <ul>
                                                  {filterDropdown.map(
                                                    (val, index) => {
                                                      return (
                                                        <>
                                                          <li
                                                            className={
                                                              selectedDateFilter ===
                                                              val
                                                                ? "activeBtn"
                                                                : ""
                                                            }
                                                            key={index}
                                                            onClick={() =>
                                                              handleDateFilter(
                                                                val
                                                              )
                                                            }
                                                          >
                                                            {filterDropdown.length -
                                                              1 ===
                                                            index ? (
                                                              <div className="uk-inline uk-text-left">
                                                                <button
                                                                  className="rangeBtn"
                                                                  type="button"
                                                                >
                                                                  <span className="rangeBtnCircle"></span>{" "}
                                                                  Custom range
                                                                </button>
                                                                <div
                                                                  id="datepickerdrop"
                                                                  uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                                  className="date-picker-container"
                                                                >
                                                                  <CalendarDate
                                                                    onChange={
                                                                      onChangeRouting
                                                                    }
                                                                  />
                                                                  <div className="date-picker-btn-container uk-text-right">
                                                                    <button
                                                                      className="date-selection-btn"
                                                                      onClick={
                                                                        handleDatePickerCloseRouting
                                                                      }
                                                                    >
                                                                      Ok
                                                                    </button>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              <button
                                                                type="button"
                                                                className="rangeBtn"
                                                              >
                                                                <span className="rangeBtnCircle"></span>{" "}
                                                                {val}
                                                              </button>
                                                            )}
                                                          </li>
                                                          {filterDropdown.length -
                                                            2 ===
                                                            index && (
                                                            <hr
                                                              style={{
                                                                margin: "5px 0",
                                                              }}
                                                            />
                                                          )}
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="uk-width-1-1 uk-margin-remove">
                                          <div className="overviewMainContent">
                                            <div className="mainBox">
                                              <div className="boxHeading">
                                                <span>
                                                  Average breakdown per status
                                                </span>
                                              </div>
                                              <div className="boxContent">
                                                <div
                                                  className="uk-grid"
                                                  uk-grid=""
                                                >
                                                  <div className="uk-width-1-3 uk-first-column">
                                                    <div className="txtwrp">
                                                      <h2>
                                                        {
                                                          RouteStats?.totalRoutes
                                                        }
                                                      </h2>
                                                      <span>Total routes</span>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp">
                                                      <h2>
                                                        {
                                                          RouteStats?.totalRoutesAssignedToUsers
                                                        }
                                                      </h2>
                                                      <span>
                                                        Assigned to users
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp">
                                                      <h2>
                                                        {
                                                          RouteStats?.totalRoutesAssignedToTeams
                                                        }
                                                      </h2>
                                                      <span>
                                                        Assigned to teams
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div
                                              className="liveAgentTable"
                                              style={{ marginTop: "40px" }}
                                            >
                                              <div className="searchFilter">
                                                <form action="">
                                                  <input
                                                    type="text"
                                                    placeholder="Search by contact number"
                                                  />
                                                </form>
                                              </div>
                                              <div className="analyticsTableWrp">
                                                <table className="uk-table">
                                                  <thead>
                                                    <tr>
                                                      <th>NUMBER</th>
                                                      <th className="uk-table-expand">
                                                        ROUTE NAME
                                                      </th>
                                                      <th>ASSIGNED USER</th>

                                                      <th className="uk-width-small">
                                                        <div className="uk-inline tableDropDown">
                                                          <button type="button">
                                                            STATUS
                                                            <ChevronDown />
                                                          </button>
                                                        </div>
                                                      </th>
                                                      <th className="uk-width-small">
                                                        LAST CONTACT
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {RouteStats?.routeRecords?.content.map(
                                                      (val, index) => {
                                                        return (
                                                          <tr key={index}>
                                                            <td>
                                                              {val?.number}
                                                            </td>
                                                            <td className="agnetName">
                                                              {val?.routeName}
                                                            </td>
                                                            <td>
                                                              {val?.agentName ===
                                                              ""
                                                                ? "-"
                                                                : val?.agentName}
                                                            </td>
                                                            <td>
                                                              <span className="inProcess">
                                                                {
                                                                  val?.conversationStatus
                                                                }
                                                              </span>
                                                            </td>
                                                            <td>
                                                              {convertTimestamp(
                                                                val.timeStamp
                                                              )}
                                                            </td>
                                                            <td className="broadcastTableBtn">
                                                              <button
                                                                type="button"
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={() => {
                                                                  const dataToStore =
                                                                    {
                                                                      mobile:
                                                                        val.number,
                                                                      chanel:
                                                                        val.chanel,
                                                                      agent:
                                                                        val.agent,
                                                                      agentId:
                                                                        val.agentId,
                                                                      conversationId:
                                                                        val.conversationId,
                                                                    };

                                                                  // Convert the data to a JSON string
                                                                  const dataString =
                                                                    JSON.stringify(
                                                                      dataToStore
                                                                    );

                                                                  // Store the data string in session storage
                                                                  sessionStorage.setItem(
                                                                    "myData",
                                                                    dataString
                                                                  );
                                                                  // Retrieve the data string from session storage
                                                                  const storedDataString =
                                                                    sessionStorage.getItem(
                                                                      "myData"
                                                                    );

                                                                  if (
                                                                    storedDataString
                                                                  ) {
                                                                    // Parse the data string to a JSON object
                                                                    const storedData =
                                                                      JSON.parse(
                                                                        storedDataString
                                                                      );

                                                                    // Now you can access the stored data like this:
                                                                    const mobile =
                                                                      storedData.mobile;
                                                                    const chanel =
                                                                      storedData.chanel;
                                                                    const agent =
                                                                      storedData.agent;
                                                                    const agentId =
                                                                      storedData.agentId;
                                                                    const conversationId =
                                                                      storedData.conversationId;

                                                                    navigate(
                                                                      `/chat/${encryptedValue(
                                                                        mobile
                                                                      )}`,
                                                                      {
                                                                        replace: false,
                                                                      }
                                                                    );

                                                                    // Use the retrieved data as needed
                                                                  }

                                                                  // Navigate to the desired location
                                                                }}
                                                              >
                                                                View chat
                                                              </button>
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </tbody>
                                                </table>
                                                <div className="tablePaginationBox">
                                                  <div className="uk-margin-left">
                                                    Show{" "}
                                                    <select
                                                      onChange={(e) =>
                                                        setSize(e.target.value)
                                                      }
                                                    >
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="20">
                                                        20
                                                      </option>
                                                      <option value="50">
                                                        50
                                                      </option>
                                                    </select>{" "}
                                                    entries
                                                  </div>
                                                  <div>
                                                    {pageCount > 1 && (
                                                      <ReactPaginate
                                                        previousLabel={"<"}
                                                        nextLabel={">"}
                                                        breakLabel={"...."}
                                                        pageCount={pageCount}
                                                        onPageChange={
                                                          handlePageChange
                                                        }
                                                        containerClassName={
                                                          "uk-pagination uk-float-right contatPagination"
                                                        }
                                                        activeClassName={
                                                          "uk-active"
                                                        }
                                                        marginPagesDisplayed={3}
                                                        pageRangeDisplayed={6}
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li> */}
                                {/* <li>
                            <div
                              className="overviewContent uk-padding-remove"
                              style={{ backgroundColor: "transparent" }}
                            >
                              <div className="uk-container">
                                <div className="overviewTopDropdown">
                                  <div
                                    className="uk-grid uk-flex-middle"
                                    uk-grid=""
                                  >
                                    <div className="uk-width-1-2">
                                      <div className="pageHeading uk-margin-remove-bottom">
                                        <h3>Live Chat Channels</h3>
                                      </div>
                                    </div>
                                    <div className="uk-width-1-2 btnSection">
                                      <div className="agentFilterWrp">
                                        <div className="allChannelDropDown">
                                          <div className="uk-inline tableFilterDropdown broadcastChannelFilter">
                                            <button
                                              type="button"
                                              className="dropdownBtn"
                                            >
                                              All Active Channels{" "}
                                              <ChevronDown />
                                            </button>
                                            <div
                                              uk-dropdown="mode: click"
                                              className="dropdownNav"
                                            >
                                              <span className="dropdownHeading">
                                                Channels
                                              </span>
                                              <ul>
                                                {channels.map((val, index) => {
                                                  return (
                                                    <li
                                                      key={index}
                                                      className={
                                                        channel ===
                                                        val.apiSendName
                                                          ? "activeBtn"
                                                          : ""
                                                      }
                                                      onClick={() => {
                                                        setChannel(
                                                          val.apiSendName
                                                        );
                                                      }}
                                                    >
                                                      <button
                                                        type="button"
                                                        disabled={
                                                          val.disabled === true
                                                            ? true
                                                            : false
                                                        }
                                                        className={
                                                          val.disabled === true
                                                            ? "opactityBtn"
                                                            : ""
                                                        }
                                                      >
                                                        {index !== 0 && (
                                                          <img
                                                            src={val.icon}
                                                            alt=""
                                                          />
                                                        )}
                                                        {val.name}
                                                      </button>
                                                    </li>
                                                  );
                                                })}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="uk-inline">
                                          <button className="calenderBtn rangeBtn">
                                            <Calendar /> {selectedDateFilter}
                                            <span className="uk-inline">
                                              <ChevronDown />
                                            </span>
                                          </button>
                                          <div
                                            uk-dropdown="mode: click"
                                            className="calendarDropdown"
                                            id="dateDropdownSection"
                                          >
                                            <div className="dropDownHeading">
                                              <span>Select a range</span>
                                            </div>
                                            <ul>
                                              {filterDropdown.map(
                                                (val, index) => {
                                                  return (
                                                    <>
                                                      <li
                                                        className={
                                                          selectedDateFilter ===
                                                          val
                                                            ? "activeBtn"
                                                            : ""
                                                        }
                                                        key={index}
                                                        onClick={() =>
                                                          handleDateFilter(val)
                                                        }
                                                      >
                                                        {filterDropdown.length -
                                                          1 ===
                                                        index ? (
                                                          <div className="uk-inline uk-text-left">
                                                            <button
                                                              className="rangeBtn"
                                                              type="button"
                                                            >
                                                              <span className="rangeBtnCircle"></span>{" "}
                                                              Custom range
                                                            </button>
                                                            <div
                                                              id="datepickerdrop"
                                                              uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                              className="date-picker-container"
                                                            >
                                                              <CalendarDate
                                                                onChange={
                                                                  onChange
                                                                }
                                                              />
                                                              <div className="date-picker-btn-container uk-text-right">
                                                                <button
                                                                  className="date-selection-btn"
                                                                  onClick={
                                                                    handleDatePickerClose
                                                                  }
                                                                >
                                                                  Ok
                                                                </button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <button
                                                            type="button"
                                                            className="rangeBtn"
                                                          >
                                                            <span className="rangeBtnCircle"></span>{" "}
                                                            {val}
                                                          </button>
                                                        )}
                                                      </li>
                                                      {filterDropdown.length -
                                                        2 ===
                                                        index && (
                                                        <hr
                                                          style={{
                                                            margin: "5px 0",
                                                          }}
                                                        />
                                                      )}
                                                    </>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="uk-width-1-1 uk-margin-remove">
                                      <div className="overviewMainContent">
                                        <div className="mainBox">
                                          <div
                                            className="boxHeading"
                                            style={{ border: "none" }}
                                          >
                                            <ul
                                              uk-tab="connect: #boxTabbing"
                                              className="boxTabsBtn"
                                            >
                                              <li>
                                                <a href="#">WhatsApp</a>
                                              </li>
                                              <li>
                                                <a href="#">Messenger</a>
                                              </li>
                                              <li>
                                                <a href="#">Instagram</a>
                                              </li>
                                            </ul>
                                          </div>

                                          <ul
                                            className="uk-switcher uk-margin"
                                            id="boxTabbing"
                                          >
                                            <li>
                                              <div className="boxContent">
                                                <div
                                                  className="uk-grid"
                                                  uk-grid=""
                                                >
                                                  <div className="uk-width-1-3 uk-first-column">
                                                    <div className="txtwrp">
                                                      <h2>17,824</h2>
                                                      <span>Total Users</span>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp">
                                                      <h2>16,300</h2>
                                                      <span>New Users</span>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp">
                                                      <h2>1,524</h2>
                                                      <span>
                                                        Returning Users
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="boxContent">
                                                <div
                                                  className="uk-grid"
                                                  uk-grid=""
                                                >
                                                  <div className="uk-width-1-3 uk-first-column">
                                                    <div className="txtwrp">
                                                      <h2>12,321</h2>
                                                      <span>Total Users</span>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp">
                                                      <h2>17,600</h2>
                                                      <span>New Users</span>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp">
                                                      <h2>532</h2>
                                                      <span>
                                                        Returning Users
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="boxContent">
                                                <div
                                                  className="uk-grid"
                                                  uk-grid=""
                                                >
                                                  <div className="uk-width-1-3 uk-first-column">
                                                    <div className="txtwrp">
                                                      <h2>14,100</h2>
                                                      <span>Total Users</span>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp">
                                                      <h2>12,564</h2>
                                                      <span>New Users</span>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp">
                                                      <h2>2,645</h2>
                                                      <span>
                                                        Returning Users
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>

                                        <div className="mainBox">
                                          <div className="boxHeading">
                                            <span>
                                              Live chat performance per channel
                                            </span>
                                          </div>
                                          <div className="boxFullTable">
                                            <table className="uk-table uk-table-striped">
                                              <thead>
                                                <tr>
                                                  <th className="uk-table-expand">
                                                    RANKING
                                                  </th>
                                                  <th>TOTAL</th>
                                                  <th>OPEN</th>
                                                  <th>WAITING</th>
                                                  <th>SOLVED</th>
                                                  <th>CLOSED</th>
                                                  <th>AVG. RESPONSE TIME</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td className="channelName">
                                                    <div className="channeliImgTxt">
                                                      <img
                                                        src={whatsappIcon}
                                                        alt=""
                                                      />
                                                      <span>WhatsApp</span>
                                                    </div>
                                                  </td>
                                                  <td>3,432</td>
                                                  <td>1</td>
                                                  <td>1</td>
                                                  <td>3425</td>
                                                  <td>3425</td>
                                                  <td>01:34:01</td>
                                                </tr>
                                                <tr>
                                                  <td className="channelName">
                                                    <div className="channeliImgTxt">
                                                      <img
                                                        src={whatsappIcon}
                                                        alt=""
                                                      />
                                                      <span>WhatsApp</span>
                                                    </div>
                                                  </td>
                                                  <td>3,432</td>
                                                  <td>1</td>
                                                  <td>1</td>
                                                  <td>3425</td>
                                                  <td>3425</td>
                                                  <td>01:34:01</td>
                                                </tr>
                                                <tr>
                                                  <td className="channelName">
                                                    <div className="channeliImgTxt">
                                                      <img
                                                        src={whatsappIcon}
                                                        alt=""
                                                      />
                                                      <span>WhatsApp</span>
                                                    </div>
                                                  </td>
                                                  <td>3,432</td>
                                                  <td>1</td>
                                                  <td>1</td>
                                                  <td>3425</td>
                                                  <td>3425</td>
                                                  <td>01:34:01</td>
                                                </tr>
                                                <tr>
                                                  <td className="channelName">
                                                    <div className="channeliImgTxt">
                                                      <img
                                                        src={whatsappIcon}
                                                        alt=""
                                                      />
                                                      <span>WhatsApp</span>
                                                    </div>
                                                  </td>
                                                  <td>3,432</td>
                                                  <td>1</td>
                                                  <td>1</td>
                                                  <td>3425</td>
                                                  <td>3425</td>
                                                  <td>01:34:01</td>
                                                </tr>
                                                <tr>
                                                  <td className="channelName">
                                                    <div className="channeliImgTxt">
                                                      <img
                                                        src={whatsappIcon}
                                                        alt=""
                                                      />
                                                      <span>WhatsApp</span>
                                                    </div>
                                                  </td>
                                                  <td>3,432</td>
                                                  <td>1</td>
                                                  <td>1</td>
                                                  <td>3425</td>
                                                  <td>3425</td>
                                                  <td>01:34:01</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div
                              className="overviewContent uk-padding-remove"
                              style={{ backgroundColor: "transparent" }}
                            >
                              <div className="uk-container">
                                <div className="overviewTopDropdown">
                                  <div
                                    className="uk-grid uk-flex-middle"
                                    uk-grid=""
                                  >
                                    <div className="uk-width-1-2">
                                      <div className="pageHeading uk-margin-remove-bottom">
                                        <h3>Live Chat Tags</h3>
                                      </div>
                                    </div>
                                    <div className="uk-width-1-2 btnSection">
                                      <div className="agentFilterWrp">
                                        <div className="allChannelDropDown">
                                          <div className="uk-inline tableFilterDropdown broadcastChannelFilter">
                                            <button
                                              type="button"
                                              className="dropdownBtn"
                                            >
                                              All Active Channels{" "}
                                              <ChevronDown />
                                            </button>
                                            <div
                                              uk-dropdown="mode: click"
                                              className="dropdownNav"
                                            >
                                              <span className="dropdownHeading">
                                                Channels
                                              </span>
                                              <ul>
                                                {channels.map((val, index) => {
                                                  return (
                                                    <li
                                                      key={index}
                                                      className={
                                                        channel ===
                                                        val.apiSendName
                                                          ? "activeBtn"
                                                          : ""
                                                      }
                                                      onClick={() => {
                                                        setChannel(
                                                          val.apiSendName
                                                        );
                                                      }}
                                                    >
                                                      <button
                                                        type="button"
                                                        disabled={
                                                          val.disabled === true
                                                            ? true
                                                            : false
                                                        }
                                                        className={
                                                          val.disabled === true
                                                            ? "opactityBtn"
                                                            : ""
                                                        }
                                                      >
                                                        {index !== 0 && (
                                                          <img
                                                            src={val.icon}
                                                            alt=""
                                                          />
                                                        )}
                                                        {val.name}
                                                      </button>
                                                    </li>
                                                  );
                                                })}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="uk-inline">
                                          <button className="calenderBtn rangeBtn">
                                            <Calendar /> {selectedDateFilter}
                                            <span className="uk-inline">
                                              <ChevronDown />
                                            </span>
                                          </button>
                                          <div
                                            uk-dropdown="mode: click"
                                            className="calendarDropdown"
                                            id="dateDropdownSection"
                                          >
                                            <div className="dropDownHeading">
                                              <span>Select a range</span>
                                            </div>
                                            <ul>
                                              {filterDropdown.map(
                                                (val, index) => {
                                                  return (
                                                    <>
                                                      <li
                                                        className={
                                                          selectedDateFilter ===
                                                          val
                                                            ? "activeBtn"
                                                            : ""
                                                        }
                                                        key={index}
                                                        onClick={() =>
                                                          handleDateFilter(val)
                                                        }
                                                      >
                                                        {filterDropdown.length -
                                                          1 ===
                                                        index ? (
                                                          <div className="uk-inline uk-text-left">
                                                            <button
                                                              className="rangeBtn"
                                                              type="button"
                                                            >
                                                              <span className="rangeBtnCircle"></span>{" "}
                                                              Custom range
                                                            </button>
                                                            <div
                                                              id="datepickerdrop"
                                                              uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                              className="date-picker-container"
                                                            >
                                                              <CalendarDate
                                                                onChange={
                                                                  onChange
                                                                }
                                                              />
                                                              <div className="date-picker-btn-container uk-text-right">
                                                                <button
                                                                  className="date-selection-btn"
                                                                  onClick={
                                                                    handleDatePickerClose
                                                                  }
                                                                >
                                                                  Ok
                                                                </button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <button
                                                            type="button"
                                                            className="rangeBtn"
                                                          >
                                                            <span className="rangeBtnCircle"></span>{" "}
                                                            {val}
                                                          </button>
                                                        )}
                                                      </li>
                                                      {filterDropdown.length -
                                                        2 ===
                                                        index && (
                                                        <hr
                                                          style={{
                                                            margin: "5px 0",
                                                          }}
                                                        />
                                                      )}
                                                    </>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="uk-width-1-1 uk-margin-remove">
                                      <div className="overviewMainContent">
                                        <div className="mainBox">
                                          <div className="boxHeading">
                                            <span>
                                              Top most used tags in live chats
                                            </span>
                                          </div>
                                          <div className="boxContent">
                                            <div className="uk-grid" uk-grid="">
                                              <div className="uk-width-1-2">
                                                <div className="chartWrp fullDonutSize">
                                                  <DoughnutChart
                                                    degree={360}
                                                    backgroudColor={[
                                                      "#1F73B7",
                                                      "#84EB7F",
                                                      "#FF9F0A",
                                                      "#BBB35F",
                                                      "#485A93",
                                                      "#F6C176",
                                                      "#96CCF9",
                                                      "#E4E4E4",
                                                    ]}
                                                    borderColor={[
                                                      "#1F73B7",
                                                      "#84EB7F",
                                                      "#FF9F0A",
                                                      "#BBB35F",
                                                      "#485A93",
                                                      "#F6C176",
                                                      "#96CCF9",
                                                      "#E4E4E4",
                                                    ]}
                                                    graphData={[
                                                      12, 9, 5, 15, 6, 3, 8, 20,
                                                    ]}
                                                  />
                                                  <div
                                                    className="chartData"
                                                    style={{ top: "36%" }}
                                                  >
                                                    <h3>1,214</h3>
                                                    <p>
                                                      Total Tags used in Live
                                                      Chats
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="uk-width-1-2">
                                                <div className="boxTableWrp">
                                                  <table className="uk-table uk-table-striped">
                                                    <thead>
                                                      <tr>
                                                        <th>RANKING</th>
                                                        <th className="uk-table-expand">
                                                          TAG
                                                        </th>
                                                        <th className="uk-table-shrink">
                                                          COUNT
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>1</td>
                                                        <td>
                                                          <span className="blueDot"></span>
                                                          Order
                                                        </td>
                                                        <td>871</td>
                                                      </tr>
                                                      <tr>
                                                        <td>1</td>
                                                        <td>
                                                          <span className="lightGreen"></span>
                                                          Order
                                                        </td>
                                                        <td>871</td>
                                                      </tr>
                                                      <tr>
                                                        <td>1</td>
                                                        <td>
                                                          <span className="darkOrange"></span>
                                                          Order
                                                        </td>
                                                        <td>871</td>
                                                      </tr>
                                                      <tr>
                                                        <td>1</td>
                                                        <td>
                                                          <span className="darkDrayDot"></span>
                                                          Order
                                                        </td>
                                                        <td>871</td>
                                                      </tr>
                                                      <tr>
                                                        <td>1</td>
                                                        <td>
                                                          <span className="darkBlueDot"></span>
                                                          Order
                                                        </td>
                                                        <td>871</td>
                                                      </tr>
                                                      <tr>
                                                        <td>1</td>
                                                        <td>
                                                          <span className="yellowDot"></span>
                                                          Order
                                                        </td>
                                                        <td>871</td>
                                                      </tr>
                                                      <tr>
                                                        <td>1</td>
                                                        <td>
                                                          <span className="lightBlueDot"></span>
                                                          Order
                                                        </td>
                                                        <td>871</td>
                                                      </tr>
                                                      <tr>
                                                        <td>1</td>
                                                        <td>
                                                          <span className="grayDot"></span>
                                                          Order
                                                        </td>
                                                        <td>871</td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="mainBox">
                                          <div className="boxHeading">
                                            <span>Performance by Tag</span>
                                          </div>
                                          <div className="boxFullTable">
                                            <table className="uk-table uk-table-striped">
                                              <thead>
                                                <tr>
                                                  <th className="uk-table-expand">
                                                    TAG
                                                  </th>
                                                  <th>TOTAL</th>
                                                  <th>OPEN</th>
                                                  <th>WAITING</th>
                                                  <th>SOLVED</th>
                                                  <th>CLOSED</th>
                                                  <th>AVG. RESPONSE TIME</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td className="channelName">
                                                    <Link to="/live-chat-tags">
                                                      Eid Campaign
                                                    </Link>
                                                  </td>
                                                  <td>3,432</td>
                                                  <td>1</td>
                                                  <td>1</td>
                                                  <td>3425</td>
                                                  <td>3425</td>
                                                  <td>01:34:01</td>
                                                </tr>
                                                <tr>
                                                  <td className="channelName">
                                                    Eid Campaign
                                                  </td>
                                                  <td>3,432</td>
                                                  <td>1</td>
                                                  <td>1</td>
                                                  <td>3425</td>
                                                  <td>3425</td>
                                                  <td>01:34:01</td>
                                                </tr>
                                                <tr>
                                                  <td className="channelName">
                                                    Eid Campaign
                                                  </td>
                                                  <td>3,432</td>
                                                  <td>1</td>
                                                  <td>1</td>
                                                  <td>3425</td>
                                                  <td>3425</td>
                                                  <td>01:34:01</td>
                                                </tr>
                                                <tr>
                                                  <td className="channelName">
                                                    Eid Campaign
                                                  </td>
                                                  <td>3,432</td>
                                                  <td>1</td>
                                                  <td>1</td>
                                                  <td>3425</td>
                                                  <td>3425</td>
                                                  <td>01:34:01</td>
                                                </tr>
                                                <tr>
                                                  <td className="channelName">
                                                    Eid Campaign
                                                  </td>
                                                  <td>3,432</td>
                                                  <td>1</td>
                                                  <td>1</td>
                                                  <td>3425</td>
                                                  <td>3425</td>
                                                  <td>01:34:01</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li key={index}></li>
                )
              ) : null;
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default LiveChatAnalytics;
