import React from "react";

const DeleteSegment = ({ closeModal }) => {
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>Delete Segment 'Winters Shoppers (123,231)'</span>
          </div>
          <div className="modalForm deleteSegmentModal">
            <div className="formHeading">
              <span>Are you sure you want to delete this segment?</span>
            </div>
            <div className="txtwrp">
              <p>Winters Shoppers</p>
            </div>
            <div className="formHeading">
              <span>Description:</span>
            </div>
            <div className="txtwrp" style={{ marginTop: "5px" }}>
              <p>A peak season for the retail sector during Winters Sale</p>
            </div>
            <div className="formHeading">
              <span>Contacts that match any of the following conditions:</span>
            </div>
            <div className="txtwrp" style={{ marginTop: "5px" }}>
              <p>
                1. Tags contact is tagged <b>user_registered</b>
              </p>
              <p>
                2. Tags contact is not tagged <b>preferred_phone</b>
              </p>
              <p>
                3. Tags contact is not tagged <b>loc_karachi</b>
              </p>
              <p>
                4. Tags contact is not tagged <b>loc_lahore</b>
              </p>
            </div>
            <form action="">
            <hr />
              <div className="btnwrp">
                <button type="button" className="btn-1" onClick={closeModal}>
                  Cancel
                </button>
                <button type="button" className="btn-2 deleteBtn">
                Delete segment
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteSegment;
