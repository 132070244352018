import React, { useState } from "react";
import { Link } from "react-router-dom";
import notificationSound from "../../../assets/audio/message_tone.mp3";
import {
  Chat,
  Home,
  Rocket,
  UserMultiple,
  Notification,
  Help,
  Dashboard,
  Logout,
  ChevronDown,
  Mobile,
  Checkmark,
  UserCertification,
  WatsonHealth3DCurveAutoColon,
  DocumentMultiple_01,
  Enterprise,
  Receipt,
  Settings,
  Flash,
  Analytics,
  Collaborate,
} from "@carbon/icons-react";
import siderbarLogo from "../../../assets/images/sidebar-logo.svg";
import NotificationBar from "../../Notification/Notification";
import LogoutModal from "../../Modal/LogoutModal";
import { useEffect } from "react";
import { apiGet, apiPost } from "../../../Utils/apiServices";
import { useAuth } from "../../../Context/AuthProvider";
import { removeAndAddActiveClass } from "../../../Utils/jqueryFunctions";
import { toast } from "react-toastify";
import UIkit from "uikit";
import { useNavigate } from "react-router-dom";
// import { chatBaseUrl } from "../../../Constant";
import { useWebSocket } from "../../../socket/WebSocketManager";
import {
  decryptedValue,
  encryptedValue,
} from "../../../Utils/RouteHashConversion";
import RefreshModal from "../../Modal/RefreshModal";
const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;

const Sidebar = ({
  setSelectedStatus,
  selectedStatus,
  companyImage,
  setCompanyImage,
  setAgentImage,
  agentImage,
  userPermissions,
  notifications,
  companyDetails,
  socketResponse,
  // comapnyLogo,
}) => {
  const { auth } = useAuth();
  const portalName = process.env.REACT_APP_PORTAL;

  const { socket, closeSocketConnection, isConnectionClosed } = useWebSocket();

  const audioRef = React.createRef();
  const [socketNotifications, setSocketNotifications] = useState([]);
  const navigate = useNavigate();
  const [profile, setProfile] = useState();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [companyData, setCompanyData] = useState("");

  const userRole = decryptedValue(localStorage.getItem("_role"));
  const userRoleDec = userRole;

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current
        .play()
        .then(() => {
          // Playback started successfully
        })
        .catch((error) => {
          // Handle playback error
          console.error("Audio playback error:", error);
        });
    }
  };

  useEffect(() => {
    if (notifications !== undefined) {
      setSocketNotifications((prevMessages) => [
        notifications,
        ...prevMessages,
      ]);
      // $("#buzzer").get(0).play();
      if (localStorage.getItem("_notify") === "true") {
        window.showNotification(
          `message received from ${notifications.channelId} channel`,
          notifications.notificationContent
        );
      }
      if (localStorage.getItem("_tune") === "true") {
        playAudio();
      }
    }
  }, [notifications]);

  const handleOpenLogoutModal = () => {
    setTimeout(() => {
      setOpenLogoutModal(true);
    }, 1000);
  };

  const handleCloseLogoutModal = () => {
    setOpenLogoutModal(false);
  };
  const handleSwitcherForChat = (val) => {
    localStorage.setItem("_cht_licked", val);
  };

  const changeAgentStatus = (filterValue) => {
    apiPost(
      `/agent/changeStatus/${filterValue}/${decryptedValue(auth.agentId)}`,
      onSuccessChnageAgentStatus,
      onFailureChnageAgentStatus
    );
  };
  const onSuccessChnageAgentStatus = (response) => {
    if (response.status === 1) {
      toast.success("Status changes successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
      });
    }
  };
  const onFailureChnageAgentStatus = (error) => {
    console.log(error);
  };

  const closeDrop = () => {
    UIkit.dropdown("#logoutProfileSection").hide();
  };

  const handleLogout = () => {
    let data = {
      accessToken: auth.token,
      userPublicId: decryptedValue(auth.publicId),
    };
    apiPost(`/auth/logout`, onSuccess, onFailure, data);
  };
  const onSuccess = (response) => {
    closeSocketConnection();
    setTimeout(() => {
      localStorage.clear("");
      // navigate("/", { replace: true });
      window.location.href = "/";
    }, 1000);
  };
  const onFailure = (error) => {
    console.log(error);
  };

  const handleFilterSelection = (
    targetElement,
    parentElement,
    filerName,
    filterValue
  ) => {
    removeAndAddActiveClass(targetElement, parentElement, "active");
    closeDrop();

    // setSeelctedFilter(filerName);
    // window.location.href(`/overview/?${filerName}`);
    setSelectedStatus(filerName);
    changeAgentStatus(filterValue);
  };

  const populateAgentStatus = () => {
    let result = "";
    let data = [
      { id: 1, name: "ONLINE", value: "ONLINE", class: "availableStatus" },
      { id: 2, name: "AWAY", value: "AWAY", class: "awayStatus" },
      // { id: 3, name: "BREAK", value: "BREAK", class: "busyStatus" },
      // { id: 4, name: "OFFLINE", value: "OFFLINE", class: "offline" },
    ];

    result = data.map((response, index) => {
      return (
        <li key={index} className="agentStatus">
          <div
            id={"overview_id_" + response.id}
            onClick={(e) =>
              handleFilterSelection(
                "#overview_id_" + response.id,
                ".navInnerDropDown ul li .active",
                response.name,
                response.value
              )
            }
            className={
              selectedStatus === ""
                ? "overview_id"
                : response.name === selectedStatus
                ? "active overview_id"
                : "overview_id"
            }
          >
            <span className={`${response.class} cursor`}>
              <span className="filtername">{response.name}</span>
            </span>
          </div>
          <div>
            <span className="uk-icon tickIcon">
              <Checkmark />
            </span>
          </div>
        </li>
      );
    });
    return result;
  };

  const getAgentProfile = () => {
    apiGet(`/agent/profile`, onSuccessProfile, onFailureProfile);
  };
  const onSuccessProfile = (response) => {
    if (response.status == 0) {
      setProfile(response?.data);
      setAgentImage(response?.data.imageUrl);
      setSelectedStatus(response?.data.status);
    }
  };
  const onFailureProfile = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (selectedStatus !== "") {
      if (selectedStatus === "OFFLINE") {
        if (socket.readyState !== 1) {
          socketAlive();
        } else {
          // getAgentProfile();
        }
      }
    }
  }, [selectedStatus]);

  const getCompanySettings = () => {
    const companyId = auth?.companyId;

    if (companyId) {
      apiGet(
        `/company/setting`,
        onSuccessLorealSetting,
        onFailureLorealSetting
      );
    } else {
      // Handle the case where auth or companyId is null or undefined
      console.error("auth or companyId is null or undefined");
      navigate(`/`, {
        replace: true,
      });
    }
  };

  const onSuccessLorealSetting = (response) => {
    if (response?.data?.settings === "LOREALBOT") {
      setCompanyData("LOREALBOT");
    }
    if (response?.data?.settings === "treat_employee") {
      setCompanyData("treat_employee");
    }
    if (response?.data?.settings === "ebm_pakistan") {
      setCompanyData("ebm_pakistan");
    }
  };

  const onFailureLorealSetting = (error) => {
    console.log(error);
  };

  // const getAgentStatus = () => {
  //   apiGet(`/agent/status`, onSuccessAgentStatus, onFailureAgentStatus);
  // };
  // const onSuccessAgentStatus = (response) => {
  // };
  // const onFailureAgentStatus = (error) => {
  // };
  useEffect(() => {
    setInterval(socketAlive, 1000);
    // getAgentProfile();
    getCompanySettings();
  }, []);

  useEffect(() => {
    if (socketResponse !== undefined) {
      if (socketResponse !== null) {
        if (socketResponse.type === "auth-status") {
          // console.log(socketResponse.type)
          getAgentProfile();
        }

        if (socketResponse.type === "ping-response") {
          if (socketResponse.message !== "200") {
            handleLogout();
          }
        }
      }
    }
    const myFunction = () => {
      if (socketResponse) {
        const givenTime = new Date(socketResponse.data);
        const currentTime = new Date();
        const differenceInSeconds = Math.floor(
          (currentTime - givenTime) / 1000
        );
        var usedTime = 0;
        if (differenceInSeconds < 0) {
          usedTime = 60 - parseInt(differenceInSeconds);
        } else {
          usedTime = differenceInSeconds;
        }
        if (usedTime == 108) {
          // toast.info(`Internet connection is not stable`, {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          // });
        }
        if (usedTime == 102) {
          // toast.warn(`Internet connection is not stable`, {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          // });
        }
        if (usedTime == 96) {
          // toast.error(`Internet connection is not stable`, {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          // });
        }
      }
    };

    // Set up the interval
    const intervalId = setInterval(myFunction, 1000); // 1000 milliseconds = 1 second

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [socketResponse]);
  const socketAlive = () => {
    if (socket !== null) {
      if (socket.readyState !== 1) {
        // console.log(socket.readyState)
        sendAuthMessage();
        getAgentProfile();
      }
    }
  };
  function sendAuthMessage() {
    if (socket && socket.readyState === 1) {
      // if (socket ) {
      const data = {
        username: decryptedValue(localStorage.getItem("_email")),
        password: localStorage.getItem("_token"),
      };
      socket.send(JSON.stringify({ type: "auth", data: data }));
    }
  }

  // useEffect(() => {
  //   if (profile !== undefined) {
  //     setTimeout(() => {
  //       getAgentStatus();
  //     }, 5000);
  //   }
  // }, [profile]);

  // useEffect(() => {
  //   // Function to be called every second
  //   const myFunction = () => {
  //
  //

  //     // Your logic here

  //     // if (navigator.onLine === false) {
  //     //   // if (isConnectionClosed===false) {
  //     //     toast.error(`Internet connect is not stable`, {
  //     //       position: toast.POSITION.TOP_RIGHT,
  //     //       // autoClose: 1000,
  //     //     });
  //     //   // }
  //     // }
  //   };

  //   // Set up the interval
  //   const intervalId = setInterval(myFunction, 1000); // 1000 milliseconds = 1 second

  //   // Cleanup the interval when the component unmounts
  //   return () => clearInterval(intervalId);
  // }, []); //
  const isActiveChannel = companyDetails?.activeChannels.filter(function (o) {
    return o.chanelId === "FACEBOOK";
  });

  return (
    <>
      <nav className="sideNav">
        <div className="logowrp">
          <img src={siderbarLogo} alt="" />
        </div>
        {/* <audio
          id="buzzer"
          src={notificationSound}
          type="audio/mp3"
          hidden
        ></audio> */}

        <audio ref={audioRef} src={notificationSound} />
        <div className="navwrp">
          <ul>
            {userRoleDec === "ROLE_SUPER_ADMIN" ? (
              <li>
                <Link
                  className="character-btn companyImg initialWrapper"
                  to="/super-admin-home"
                >
                  {userRoleDec !== "ROLE_SUPER_ADMIN" ? (
                    companyImage === null || companyImage === "" ? (
                      <span className="SidebarInitialImage">
                        {companyDetails?.companyName.charAt(0)}
                      </span>
                    ) : (
                      <img
                        className="imgg"
                        src={chatBaseUrl + companyImage}
                        alt=""
                      />
                    )
                  ) : (
                    <span className="SidebarInitialImage">
                      {portalName?.charAt(0)}
                    </span>
                  )}
                </Link>
              </li>
            ) : (
              <li>
                <Link
                  className="character-btn companyImg initialWrapper"
                  onClick={() => (window.location.href = "/")}
                >
                  {companyImage === null || companyImage === "" ? (
                    <span className="SidebarInitialImage">
                      {companyDetails?.companyName.charAt(0)}
                    </span>
                  ) : (
                    <img
                      className="imgg"
                      src={chatBaseUrl + companyImage}
                      alt=""
                    />
                  )}
                </Link>
              </li>
            )}

            {(userPermissions?.includes("FINANCE-DATA-MANAGER") ||
              userPermissions?.includes("ANALYTICS-MANAGER")) && (
              <li>
                <Link
                  className="character-btn"
                  to="/dashboard"
                  uk-tooltip="title: Home; pos: right"
                >
                  <Home />
                </Link>
              </li>
            )}
            {userRoleDec === "ROLE_SUPER_ADMIN" ? (
              <li>
                <Link
                  className="character-btn"
                  to="/super-admin-home"
                  uk-tooltip="title: Home; pos: right"
                >
                  <Home />
                </Link>
              </li>
            ) : null}

            {userRoleDec === "ROLE_SUPER_ADMIN" ? (
              <li>
                {/* <Link className="character-btn" to="/super-admin-users"> */}
                <Link className="character-btn" to="/super-admin-users">
                  <Enterprise />
                </Link>
                <div uk-dropdown="pos: right-top">
                  <ul className="uk-nav uk-dropdown-nav nav-dropdown">
                    <li className="uk-active">Company</li>
                    <li>
                      <Link to="/super-admin-users">Super admin</Link>
                    </li>
                    <li>
                      <Link to="/super-admin-company">Companies</Link>
                    </li>
                  </ul>
                </div>
              </li>
            ) : null}
            {userRoleDec === "ROLE_SUPER_ADMIN" ? (
              <li>
                <Link
                  className="character-btn"
                  to="/super-admin-invoice-list"
                  uk-tooltip="title: Invoice; pos: right"
                >
                  <Receipt />
                </Link>

                <div uk-dropdown="pos: right-top">
                  <ul className="uk-nav uk-dropdown-nav nav-dropdown">
                    <li className="uk-active">Invoice</li>
                    <li>
                      <Link to="/super-admin-package-list">Packages</Link>
                    </li>
                    <li>
                      <Link to="/super-admin-invoice-list">Invoices</Link>
                    </li>
                  </ul>
                </div>
              </li>
            ) : null}

            {(userPermissions?.includes("LIVE-CHAT-AGENT") ||
              userPermissions?.includes("LIVE-CHAT-MANAGER") ||
              userPermissions?.includes("CHATBOT-CONVERSATION") ||
              userPermissions?.includes("CHAT-BOT-MANAGER") ||
              userPermissions?.includes("CHATBOT-SUPERVISOR")) && (
              <li>
                <Link
                  className="character-btn"
                  to={
                    userPermissions?.includes("LIVE-CHAT-MANAGER") ||
                    userPermissions?.includes("LIVE-CHAT-AGENT")
                      ? "/chat"
                      : (userPermissions?.includes("CHATBOT-CONVERSATION") ||
                          userPermissions?.includes("CHAT-BOT-MANAGER") ||
                          userPermissions?.includes("CHATBOT-SUPERVISOR")) &&
                        "/chat-bot"
                  }
                >
                  <Chat />
                </Link>
                <div uk-dropdown="pos: right-top">
                  <ul className="uk-nav uk-dropdown-nav nav-dropdown">
                    <li className="uk-active">Chats</li>

                    <li>
                      <Link to="/chat" onClick={() => handleSwitcherForChat(0)}>
                        {userPermissions?.includes("LIVE-CHAT-MANAGER")
                          ? "All Chat"
                          : userPermissions?.includes("LIVE-CHAT-AGENT") &&
                            "My Chat"}
                      </Link>
                    </li>

                    {(userPermissions?.includes("CHATBOT-CONVERSATION") ||
                      userPermissions?.includes("CHAT-BOT-MANAGER") ||
                      userPermissions?.includes("CHATBOT-SUPERVISOR")) && (
                      <li>
                        <Link
                          to="/chat-bot"
                          onClick={() => handleSwitcherForChat(1)}
                        >
                          Bot Chat{" "}
                        </Link>
                      </li>
                    )}
                    {/* <li>
                      <Link to="/internal-chat">Internal Chat </Link>
                    </li> */}
                    {/* {userPermissions?.length > 0 &&
                      isActiveChannel.length > 0 && (
                        <li>
                          <Link to="/fb-comments">Facebook comments </Link>
                        </li>
                      )} */}
                    {/* <li>
                      <Link
                        to="http://stg-berrymail.convexinteractive.com/login-by-url?email=moiz.azam@convexinteractive.com&password=c0nv3x@123&logo_url=https://qaomni.convexinteractive.com/"
                        target="_blank"
                      >
                        Email{" "}
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </li>
            )}
            {/* <li>
              <Link
                className="character-btn"
                to="/dashboard"
                uk-tooltip="title: Automation; pos: right"
              >
                <WatsonHealth3DCurveAutoColon />
              </Link>
            </li> */}
            {userPermissions?.includes("COMMUNICATION-MANAGER") && (
              <li>
                <Link
                  className="character-btn"
                  to="/broadcast"
                  uk-tooltip="title: Broadcasts; pos: right"
                >
                  <Rocket />
                </Link>
              </li>
            )}
            {userPermissions?.includes("PEOPLE-MANAGER") && (
              <li>
                <Link className="character-btn" to="/contacts">
                  <UserMultiple />
                </Link>
                <div uk-dropdown="pos: right-top">
                  <ul className="uk-nav uk-dropdown-nav nav-dropdown">
                    <li className="uk-active">Contacts</li>
                    <li>
                      <Link to="/contacts">Contacts</Link>
                    </li>
                    <li>
                      <Link to="/segments">Segments</Link>
                    </li>
                  </ul>
                </div>
              </li>
            )}
            {(userPermissions?.includes("LIVE-CHAT-MANAGER") ||
              userPermissions?.includes("FINANCE-DATA-MANAGER") ||
              userPermissions?.includes("ANALYTICS-MANAGER")) && (
              <li>
                <Link
                  className="character-btn"
                  to={
                    // userPermissions?.includes("FINANCE-DATA-MANAGER") === true
                    //   ? "/analytics"
                    //   : userPermissions?.includes("LIVE-CHAT-MANAGER") === true
                    //   ? "/statistics"
                    //   : userPermissions?.includes("ANALYTICS-MANAGER") ==
                    //       true && "/package-mau"
                    // userPermissions?.includes("FINANCE-DATA-MANAGER") === true
                    //   ? "/statistics"
                    //   : userPermissions?.includes("LIVE-CHAT-MANAGER") === true
                    //   ? "/statistics"
                    //   : userPermissions?.includes("ANALYTICS-MANAGER") ==
                    //       true && "/package-mau"
                    userPermissions?.includes("FINANCE-DATA-MANAGER") === true
                      ? "/analytics"
                      : userPermissions?.includes("ANALYTICS-MANAGER") === true
                      ? "/live-chat-analytics"
                      : userPermissions?.includes("ANALYTICS-MANAGER") ==
                          true && "/package-mau"
                  }
                >
                  <Dashboard />
                </Link>
                <div uk-dropdown="pos: right-top">
                  <ul className="uk-nav uk-dropdown-nav nav-dropdown">
                    {/* <li className="uk-active">Insights</li> */}
                    {userPermissions?.includes("FINANCE-DATA-MANAGER") && (
                      <li>
                        <Link to="/analytics">Overview</Link>
                      </li>
                    )}
                    {userPermissions?.includes("ANALYTICS-MANAGER") &&
                      userPermissions?.includes("LIVE-CHAT-MANAGER") && (
                        <li>
                          <Link to="/live-chat-analytics">Live Chat</Link>
                        </li>
                      )}
                    {(userPermissions?.includes("CHATBOT-SUPERVISOR") ||
                      userPermissions?.includes("CHAT-BOT-MANAGER")) && (
                      <li>
                        <Link to="/chatbot-analytics">Chatbot</Link>
                      </li>
                    )}
                    {(userPermissions?.includes("LIVE-CHAT-MANAGER") ||
                      userPermissions?.includes("ANALYTICS-MANAGER") ||
                      userPermissions?.includes("CHATBOT-SUPERVISOR")) && (
                      <li>
                        <Link to="/statistics">Statistics</Link>
                      </li>
                    )}

                    {userPermissions?.includes("ANALYTICS-MANAGER") && (
                      <li>
                        <Link to="/package-mau">Packages</Link>
                      </li>
                    )}

                    {(userPermissions?.includes("LIVE-CHAT-MANAGER") ||
                      userPermissions?.includes("ANALYTICS-MANAGER") ||
                      userPermissions?.includes("CHATBOT-SUPERVISOR")) && (
                      <li>
                        <Link to="/orders">Orders</Link>
                      </li>
                    )}
                    <li>
                      <Link to="/transfer-logs">Transfer Logs</Link>
                    </li>
                    {companyData === "LOREALBOT" && (
                      <li>
                        <Link to="/loreal-stats">Loreal Stats</Link>
                      </li>
                    )}
                     {companyData === "ebm_pakistan" && (
                      <li>
                        <Link to="/ebm-stats">EBM Stats</Link>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )}
            {userPermissions?.includes("USER-TEAMS") && (
              <li>
                <Link className="character-btn" to="/users">
                  <UserCertification />
                </Link>
                <div uk-dropdown="pos: right-top">
                  <ul className="uk-nav uk-dropdown-nav nav-dropdown">
                    <li className="uk-active">User</li>
                    <li>
                      <Link to="/users">Users</Link>
                    </li>
                    <li>
                      <Link to="/teams">Teams</Link>
                    </li>
                  </ul>
                </div>
              </li>
            )}
            {userPermissions?.includes("CHAT-BOT-MANAGER") && (
              <li>
                <Link
                  className="character-btn"
                  to="/chatbot"
                  uk-tooltip="title: Chatbot; pos: right"
                >
                  <WatsonHealth3DCurveAutoColon />
                </Link>
              </li>
            )}
            {/* check shabir tile user if access  */}
            {userRoleDec !== "ROLE_SUPER_ADMIN" &&
              userPermissions?.includes("FORM-ACCESS-MANAGER") && (
                <li>
                  <Link
                    className="character-btn"
                    to="/form-central"
                    uk-tooltip="title: Forms; pos: right"
                  >
                    <DocumentMultiple_01 />
                  </Link>
                </li>
              )}

            {/* {userRoleDec !== "ROLE_SUPER_ADMIN" &&
              userPermissions?.includes("REPORTS") && (
                <li>
                  <Link
                    className="character-btn"
                    to="/advance-analytics"
                    uk-tooltip="title: Advance Analytics; pos: right"
                  >
                    <Analytics />
                  </Link>
                </li>
              )} */}
            {companyData === "treat_employee" && (
              <li>
                <Link
                  className="character-btn"
                  to="/list-employee"
                  uk-tooltip="title: Employees; pos: right"
                >
                  <Collaborate />
                </Link>
              </li>
            )}
            {/* <li>
              <Link className="character-btn" to="/dashboard">
                <Flash />
              </Link>
            </li> */}
          </ul>
          <ul className="scnd-navwrp">
            <li>
              <div className="uk-position-relative">
                <div className="versionBox">
                  <span>{process.env.REACT_APP_VERSION}</span>{" "}
                </div>
              </div>
            </li>
            {userRoleDec !== "ROLE_SUPER_ADMIN" ? (
              <>
                <li className="onlineImg">
                  <div className="userInfo ">
                    <div className="uk-inline">
                      {/* <button type="button" className="notImageAvailble"> */}
                      {(agentImage !== undefined || agentImage === "") && (
                        <button type="button" className="initialWrapper">
                          {agentImage === null ? (
                            <span className="SidebarInitialImage">
                              {decryptedValue(
                                localStorage.getItem("_userName")
                              ).substring(0, 1)}
                            </span>
                          ) : (
                            agentImage !== "" && (
                              <img
                                src={chatBaseUrl + agentImage}
                                uk-svg=""
                                alt=""
                              />
                            )
                          )}
                        </button>
                      )}

                      <div
                        uk-dropdown="pos: right-top; mode: click"
                        className="navDropDown"
                        id="logoutProfileSection"
                      >
                        <ul className="uk-nav uk-dropdown-nav">
                          <li className="userDetails">
                            <div className="userImg initialWrapper">
                              {agentImage === null || agentImage === "" ? (
                                <span className="SidebarInitialImage">
                                  {localStorage.getItem("_userName") != null
                                    ? decryptedValue(
                                        localStorage.getItem("_userName")
                                      )?.substring(0, 1)
                                    : navigate(`/`, {
                                        replace: true,
                                      })}
                                </span>
                              ) : (
                                <img src={chatBaseUrl + agentImage} alt="" />
                              )}
                              <span
                                className={
                                  (agentImage !== ""
                                    ? selectedStatus === "" &&
                                      "markProfile onlineMark"
                                    : "onlineMark") ||
                                  (selectedStatus === "ONLINE" &&
                                    "markProfile onlineMark") ||
                                  (selectedStatus === "AWAY" &&
                                    "markProfile awayMark")
                                }
                              ></span>
                            </div>
                            <div className="userData">
                              {/* <Link> */}
                              <h4>
                                {" "}
                                {profile?.firstName + " " + profile?.lastName}
                              </h4>
                              <p>
                                {localStorage.getItem("_role") != null
                                  ? decryptedValue(
                                      localStorage.getItem("_role")
                                    ).replace("ROLE_", "")
                                  : navigate(`/`, {
                                      replace: true,
                                    })}
                              </p>
                              {/* </Link> */}
                            </div>
                          </li>
                          <li className="navInnerDropDown">
                            <div className="uk-inline">
                              <button type="button">
                                <span
                                  className={
                                    (agentImage !== ""
                                      ? selectedStatus === "" &&
                                        "mark onlineMark availableMark"
                                      : "onlineMark") ||
                                    (selectedStatus === "ONLINE" &&
                                      "mark onlineMark availableMark") ||
                                    (selectedStatus === "AWAY" &&
                                      "mark awayMark availableMark")
                                  }
                                ></span>
                                {selectedStatus}
                                <ChevronDown />
                              </button>
                              <div
                                uk-dropdown="mode: click"
                                className="navDropDown"
                              >
                                <ul className="uk-nav uk-dropdown-nav">
                                  <li>
                                    <span> User status</span>
                                  </li>
                                  {populateAgentStatus()}
                                </ul>
                              </div>
                            </div>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                closeDrop();

                                navigate(
                                  `/profile/${encryptedValue(
                                    profile?.publicId
                                  )}`,
                                  {
                                    replace: true,
                                  }
                                );
                              }}
                            >
                              <Mobile /> My profile
                            </a>
                          </li>
                          <li className="dividingLine">
                            <hr />
                          </li>
                          {/* <li>
                        <div>
                          <a href="/" className="pkrWrp">
                            Add Funds
                            <span className="pkrFund">PKR 500</span>
                          </a>
                        </div>
                      </li> */}
                          {/* <li className="dividingLine">
                        <hr />
                      </li> */}
                          {/* {auth.role !== "ROLE_AGENT" && ( */}

                          {(userPermissions?.includes("PEOPLE-MANAGER") ||
                            userPermissions?.includes("LIVE-CHAT-MANAGER") ||
                            userPermissions?.includes("ANALYTICS-MANAGER")) && (
                            <>
                              <li
                                onClick={() => {
                                  let route = userPermissions?.includes(
                                    "ANALYTICS-MANAGER"
                                  )
                                    ? "/settings/general-setting"
                                    : userPermissions?.includes(
                                        "PEOPLE-MANAGER"
                                      )
                                    ? "/settings/segement"
                                    : userPermissions?.includes(
                                        "LIVE-CHAT-MANAGER"
                                      ) && "settings/live-chat-behavior";

                                  navigate(`${route}`, {
                                    replace: true,
                                  });
                                }}
                              >
                                <Link>Account Setting </Link>
                              </li>
                              <li className="dividingLine">
                                <hr />
                              </li>
                            </>
                          )}
                          {/* )} */}
                          {/* <li>
                        <a href="/">Billing and usage</a>
                      </li>
                      <li>
                        <a href="/">Pricing and plans</a>
                      </li> */}

                          <li>
                            <button
                              type="button"
                              className="logoutBtn"
                              onClick={() => {
                                closeDrop();
                                handleOpenLogoutModal();
                              }}
                            >
                              <Logout /> Logout
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <span className="userOnlineIcon"></span> */}
                  <span
                    className={
                      (agentImage !== ""
                        ? selectedStatus === "" &&
                          "mark onlineMark availableMark"
                        : "onlineMark") ||
                      (selectedStatus === "ONLINE" &&
                        "markProfileMain onlineMark") ||
                      (selectedStatus === "AWAY" && "markProfileMain awayMark")
                    }
                  ></span>
                </li>
                <li
                  className="onlineImg"
                  uk-tooltip="title: Notifications; pos: right"
                >
                  <Link to="/dashboard" uk-toggle="target: #offcanvas-usage">
                    <Notification />
                    {/* <NotificationBar notifications={notifications} /> */}
                    <NotificationBar
                      socketNotifications={socketNotifications}
                    />
                  </Link>
                  {socketNotifications?.length > 0 && (
                    <span className="onlineIcon"></span>
                  )}
                </li>
                <li>
                  <Link
                    className="character-btn"
                    to="/help"
                    uk-tooltip="title: Help; pos: right"
                  >
                    <Help />
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li onClick={() => handleOpenLogoutModal()}>
                  <Link
                    className="character-btn"
                    uk-tooltip="title: Logout; pos: right"
                  >
                    <Logout />
                  </Link>
                </li>
                <li>
                  <Link
                    className="character-btn"
                    to="/superadmin-settings"
                    uk-tooltip="title: Settings; pos: right"
                  >
                    <Settings />
                  </Link>
                </li>
                <li></li>
              </>
            )}
          </ul>
        </div>
        {openLogoutModal && <LogoutModal closeModal={handleCloseLogoutModal} />}
        {isConnectionClosed &&
          decryptedValue(localStorage.getItem("_role")) !==
            "ROLE_SUPER_ADMIN" && <RefreshModal />}
      </nav>
    </>
  );
};

export default Sidebar;
