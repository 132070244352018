import React, { useState } from "react";
import ChangePassword from "./Modal/ChangePassword";
import { useLocation, useNavigate } from "react-router-dom";
import { apiGet } from "../../../Utils/apiServices";
import { useEffect } from "react";
import { formatDate } from "../../../Utils/DateFunctions";
import { useAuth } from "../../../Context/AuthProvider";
import { decryptedValue } from "../../../Utils/RouteHashConversion";
import { validatePhoneNumber } from "../../../Utils/regexFunctions";

const DetailTab = ({
  userDetails,
  updateAgent,
  setSubmittedData,
  errorEnable,
  submittedData,
  setIsNumberValid,
  isNumberValid,
  loader,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [cityArray, setCityArray] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [team, setTeam] = useState();
  const [teams, setTeams] = useState(null);

  const { auth } = useAuth();
  const handleOpenChangePasswordModal = () => {
    setOpenChangePasswordModal(true);
  };

  const handleCloseChangePasswordModal = () => {
    setOpenChangePasswordModal(false);
  };

  useEffect(() => {
    if (userDetails !== undefined) {
      getCountry();
    }
  }, [userDetails]);
  useEffect(() => {
    if (countryList.length > 0 && userDetails != undefined) {
      let data = countryList?.filter((val) => {
        return userDetails?.country?.toLowerCase() == val?.name?.toLowerCase();
      });
      if (data.length > 0) {
        setSelectedCountry(data);
      }
    }
  }, [countryList]);
  const getCountry = () => {
    apiGet(`/countries/get-all`, onSuccessCountry, onFailureCountry);
  };
  const onSuccessCountry = (response) => {
    setCountryList(response?.data);
  };
  const onFailureCountry = (error) => {
    console.log(error);
  };
  const getCity = () => {
    const countryParam =
      selectedCountry[0].id === undefined
        ? selectedCountry[0]
        : selectedCountry[0].id || submittedData?.country;
    apiGet(`/countries/city/${countryParam}`, onSuccessCity, onFailureCity);
  };
  const onSuccessCity = (response) => {
    setCityArray(response?.data);
  };
  const onFailureCity = (error) => {
    console.log(error);
  };
  useEffect(() => {
    if (selectedCountry !== null) {
      getCity();
    }
  }, [selectedCountry]);

  // const handleCountry = (val) => {
  //   var data = countryList.filter((filter) => val === filter.name);
  //   setSelectedCountry(data);
  // };

  const handleCountry = (val) => {
    var data = countryList.filter((filter) => val === filter.name);
    // const ids = data?.map((country) => country.name);
    setSelectedCountry(data);
  };

  const getTeam = () => {
    apiGet(
      `/agent/team/list`,
      onSuccessTeam,
      onFailureTeam
    );
  };
  const onSuccessTeam = (response) => {
    setTeams(response?.data);
  };
  const onFailureTeam = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getTeam();
  }, []);

  return (
    <div className="detailFormwrp">
      {userDetails === undefined ? (
        <div uk-spinner="" className="loader"></div>
      ) : (
        // <form onSubmit={handleSubmit(updateAgent)}>
        <form>
          <div className="uk-grid uk-grid-small" uk-grid="">
            <div className="uk-width-1-2">
              <div className="formInput">
                <label htmlFor="f_name">First name</label>
                <input
                  className="uk-input"
                  type="text"
                  placeholder="Fist name"
                  defaultValue={submittedData?.firstName}
                  // {...register(`firstName`, {
                  //   required: true,
                  // })}
                  onChange={(e) =>
                    setSubmittedData({
                      ...submittedData,
                      firstName: e.target.value,
                    })
                  }
                />
                {/* {errors[`firstName`] && (
                  <div className="formErrors">{`First name is required.`}</div>
                )} */}
                {errorEnable && submittedData.firstName === "" && (
                  <div className="formErrors">First name is required.</div>
                )}
              </div>
            </div>
            <div className="uk-width-1-2">
              <div className="formInput">
                <label htmlFor="f_name">Last name</label>
                <input
                  className="uk-input"
                  type="text"
                  placeholder="Last name"
                  defaultValue={submittedData?.lastName}
                  // {...register(`lastName`, {
                  //   required: true,
                  // })}
                  onChange={(e) =>
                    setSubmittedData({
                      ...submittedData,
                      lastName: e.target.value,
                    })
                  }
                />
                {/* {errors[`lastName`] && (
                  <div className="formErrors">{`Last name is required.`}</div>
                )} */}
                {errorEnable && submittedData.lastName === "" && (
                  <div className="formErrors">last name is required.</div>
                )}
              </div>
            </div>
            {/* <div className="uk-width-1-2">
              <div className="formInput">
                <label htmlFor="f_name">Work number</label>
                <input
                  type="text"
                  placeholder="Work number"
                  defaultValue={submittedData?.phoneNumberWork}
                  onChange={(e) =>
                    setSubmittedData({
                      ...submittedData,
                      phoneNumberWork: e.target.value,
                    })
                  }
                />
                {errors[`phoneNumberWork`] && (
                  <div className="formErrors">{`Work number is required.`}</div>
                )}
                {errorEnable &&
                  (submittedData.phoneNumberWork === "" ||
                    submittedData.phoneNumberWork === null) && (
                    <div className="formErrors">Work number is required.</div>
                  )}
              </div>
            </div> */}
            <div className="uk-width-1-2">
              <div className="formInput">
                <label htmlFor="team">Team </label>
                {/* <select
                  className="uk-select"
                  name="team"
                  onChange={(e) => {
                    setSubmittedData({
                      ...submittedData,
                      team: e.target.value,
                    });
                  }}
                >
                  <option value="" disabled selected>
                    {submittedData?.team?.name
                      ? submittedData?.team?.name
                      : "Select a team..."}
                  </option>

                  {teams?.map((val, index) => {
                    return (
                      <option value={val.name} key={index}>
                        {val.name}
                      </option>
                    );
                  })}
                </select>
                
                 */}

                <select
                  className="uk-select"
                  name="team"
                  onChange={(e) => {
                    const selectedTeamName = e.target.value;
                    const selectedTeam = teams.find(
                      (team) => team.name === selectedTeamName
                    );

                    // Check if a team with the selected name was found
                    if (selectedTeam) {
                      // Set the selected team's publicId in the submittedData
                      setSubmittedData({
                        ...submittedData,
                        team: selectedTeam.publicId,
                      });
                    }
                  }}
                  value={userDetails?.team?.name || ""}
                >
                  <option value="" disabled>
                    {userDetails?.team?.name
                      ? userDetails?.team?.name
                      : "Select a team..."}
                  </option>

                  {teams?.map((val, index) => (
                    <option value={val.name} key={index}>
                      {val.name}
                    </option>
                  ))}
                </select>

                {/* {submittedData.team === "" && errorEnable && (
                      <div className="formErrors">Please select team</div>
                    )} */}
              </div>
            </div>
            <div className="uk-width-1-2">
              <div className="formInput">
                <label htmlFor="f_name">Mobile Number</label>
                <input
                  type="number"
                  className="uk-input"
                  placeholder="Mobile Number"
                  defaultValue={submittedData?.phoneNumberPrivate}
                  // {...register(`phoneNumberPersonal`, {
                  //   required: true,
                  // })}
                  onChange={(e) => {
                    let error = validatePhoneNumber(e.target.value);
                    setIsNumberValid(error);
                    setSubmittedData({
                      ...submittedData,
                      // phoneNumberPrivate: e.target.value,
                      phoneNumberPrivate: e.target.value,
                    });
                  }}
                />
                {/* {errors[`phoneNumberPersonal`] && (
                  <div className="formErrors">{`Mobile Number is required.`}</div>
                )} */}
                {/* {errorEnable && submittedData.phoneNumberPrivate === "" && ( */}
                {errorEnable &&
                  (submittedData.phoneNumberPrivate === "" ||
                    submittedData.phoneNumberPrivate === null) && (
                    <div className="formErrors">Mobile number is required.</div>
                  )}
                {!isNumberValid && submittedData.phoneNumberPrivate !== "" && (
                  <div className="formErrors">Invalid mobile number</div>
                )}
              </div>
            </div>
            <div className="uk-width-1-2">
              <div className="formInput">
                <label htmlFor="agent">
                  Country<span>*</span>
                </label>
                <select
                  name="country"
                  id="country"
                  className="uk-select"
                  onClick={() =>
                    (submittedData === undefined || countryList.length === 0) &&
                    getCountry()
                  }
                  onChange={(e) => {
                    handleCountry(e.target.value);
                    setSubmittedData({
                      ...submittedData,
                      country: e.target.value,
                    });
                  }}
                  value={submittedData?.country || ""}
                >
                  {countryList.length === 0 ? (
                    <>
                      <option value="" disabled>
                        Select a country
                      </option>
                      <option value="">loading ...</option>
                    </>
                  ) : (
                    <>
                      <option value="" disabled>
                        Select a country
                      </option>

                      {countryList.map((val, index) => (
                        <option key={index} value={val.name}>
                          {val.name}
                        </option>
                      ))}
                    </>
                  )}
                </select>

                {errorEnable && submittedData.country === "" && (
                  <div className="formErrors">Please select country</div>
                )}
              </div>
            </div>
            <div className="uk-width-1-2">
              <div className="formInput">
                <label htmlFor="agent">
                  City<span>*</span>
                </label>

                <select
                  name="city"
                  id="city"
                  className="uk-select"
                  onChange={(e) => {
                    setSubmittedData({
                      ...submittedData,
                      city: e.target.value,
                    });
                  }}
                  value={submittedData?.city || ""}
                >
                  {cityArray === null ? (
                    <>
                      <option value="" disabled>
                        Select a city
                      </option>
                      <option>loading ...</option>
                    </>
                  ) : (
                    <>
                      <option value="">Select a city</option>
                      {cityArray.length > 0 ? (
                        cityArray.map((val, index) => (
                          <option key={index} value={val?.name}>
                            {val?.name}
                          </option>
                        ))
                      ) : (
                        <option value="" disabled>
                          No data found
                        </option>
                      )}
                    </>
                  )}
                </select>

                {errorEnable && submittedData.city === "" && (
                  <div className="formErrors">Please select city</div>
                )}
              </div>
            </div>
            <div className="uk-width-1-2">
              <div className="formInput">
                <label htmlFor="f_name">Email</label>
                <input
                  className="uk-input"
                  type="text"
                  placeholder="Email"
                  defaultValue={submittedData?.email}
                  readOnly
                  onChange={(e) =>
                    setSubmittedData({
                      ...submittedData,
                      email: e.target.value,
                    })
                  }
                />
                {errorEnable && submittedData.email === "" && (
                  <div className="formErrors">Email is required.</div>
                )}
              </div>
            </div>
            <div className="uk-width-1-2">
              <div className="formInput">
                <label htmlFor="f_name">Password changed</label>
                <input
                  className="uk-input"
                  type="text"
                  value={formatDate(userDetails.lastPasswordChange)}
                  disabled
                />
              </div>
              <div className="changePassBtn">
                <button type="button" onClick={handleOpenChangePasswordModal}>
                  Change passwords
                </button>
              </div>
            </div>
            <div className="uk-width-1-1">
              <div className="adminDetailBtn">
                <button
                  type="submit"
                  onClick={() =>
                    location.pathname.includes("profile") === true
                      ? navigate("/dashboard", { replace: true })
                      : navigate("/users", { replace: true })
                  }
                  disabled={loader ? true : false}
                  className="btn-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={updateAgent}
                  className="btn-1 w-90"
                >
                  {loader ? (
                    <div uk-spinner="" className="loader"></div>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
      {openChangePasswordModal && (
        <ChangePassword
          closeModal={handleCloseChangePasswordModal}
          userDetails={userDetails}
        />
      )}
    </div>
  );
};

export default DetailTab;
