// import React, { useState } from "react";
// import Picker from "emoji-picker-react";

// const Emoji = ({ id }) => {
//   const [chosenEmoji, setChosenEmoji] = useState(null);

//   const onEmojiClick = (emojiData, event) => {
//     setChosenEmoji(emojiData);
//   };
//   const EmojiData = ({ chosenEmoji, message, setMessage }) => {
//     document.getElementById(`${id}`).value += chosenEmoji.emoji;
//   };
//   return (
//     <div>
//       <Picker onEmojiClick={onEmojiClick} disableAutoFocus={true} native="" />
//       {chosenEmoji && <EmojiData chosenEmoji={chosenEmoji} />}
//     </div>
//   );
// };
// export default Emoji;

import React from "react";
import Picker from "emoji-picker-react";
import { useEffect } from "react";
import { useContextChatBot } from "../../../pages/Chatbot/ContextChatBot";

const Emoji = ({ id }) => {

  const onEmojiClick = (emojiData, event) => {
    const inputField = document.getElementById(id);
    if (inputField) {
      inputField.value += emojiData.emoji;
    }
    };
 
  return (
    <div>
      <Picker onEmojiClick={onEmojiClick} disableAutoFocus={true} native="" />
    </div>
  );
};

export default Emoji;
