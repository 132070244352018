import { createContext, useContext, useState } from "react";
import { decryptedValue, encryptedValue } from "../Utils/RouteHashConversion";

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    token: localStorage.getItem("_token"),
    role: localStorage.getItem("_role"),
    email: localStorage.getItem("_email"),
    publicId: localStorage.getItem("_publicId"),
    idNumber: localStorage.getItem("_idNumber"),
    userName: localStorage.getItem("_userName"),
    companyId: localStorage.getItem("_companyId"),
    agentId: localStorage.getItem("_agentId"),
  });

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
};

export { AuthProvider, useAuth };
