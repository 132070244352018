import messageSeenIcon from "../../../src/assets/images/messageSeenIcon.svg";
import messageDileveredIcon from "../../../src/assets/images/messageDileveredIcon.svg";
import { Checkmark } from "@carbon/icons-react";
import DOMPurify from "dompurify";

export const displayMessageStatusIcon = (status) => {
  if (status === "SENT" || status === "sent") {
    return <Checkmark />;
  }
  if (status === "READ" || status === "read") {
    return <img src={messageSeenIcon} />;
  }
  if (status === "DELIVERED" || status === "delivered") {
    return <img src={messageDileveredIcon} />;
  }
};

export const getToolTipPosition = (sentBy) => {
  if (sentBy == "true" || sentBy == true) {
    return "right";
  }
  if (sentBy == "false" || sentBy == false) {
    return "left";
  }
};

export const formatText = (text) => {
  // Format bold text enclosed in asterisks
  if (text !== null) {
    const formattedText = text.replace(/\*([^*]+)\*/g, "<b>$1</b>");
    // Format italic text enclosed in underscores
    const formattedTextWithItalic = formattedText.replace(
      /_([^_]+)_/g,
      "<i>$1</i>"
    );

    return formattedTextWithItalic;
  } else {
    // If text is null or undefined, you can choose to handle it differently, such as returning an empty string or a message.
    // return "Invalid Message Type"; // Return an empty string in this example.
    return ""; // Return an empty string in this example.
  }
};

export const sanitizedMessage = (message) => {
  const sanitized = DOMPurify.sanitize(message, {
    ALLOWED_TAGS: ["b", "i"],
  });
  return { __html: sanitized };
};
