import { Checkmark, ChevronLeft, View, ViewOff } from "@carbon/icons-react";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { apiGet, apiPost } from "../../Utils/apiServices";
import { useAuth } from "../../Context/AuthProvider";
import { ValidateEmail, validatePhoneNumber } from "../../Utils/regexFunctions";
import { toast } from "react-toastify";
import { decryptedValue } from "../../Utils/RouteHashConversion";

const AddUser = () => {
  const navigate = useNavigate();
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [errorEnable, setErrorEnable] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [team, setTeam] = useState();
  const [errorSpan, setErrorSpan] = useState("");
  const [isNumberValid, setIsNumberValid] = useState(true);

  const [submittedData, setSubmittedData] = useState({
    email: "",
    firstName: "",
    isAvailable: true,
    lastName: "",
    password: "",
    phoneNumberPrivate: "",
    phoneNumberWork: "",
    team: null,
    country: "",
    city: "",
    confirmPassword: "",
  });
  const [cityArray, setCityArray] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [lowerCase, setLowerCase] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [numbers, setNumbers] = useState(false);
  const [lengths, setLengths] = useState(false);
  const [pwdPassword, setPwdPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [passwordRequirementsMet, setPasswordRequirementsMet] = useState(false);
  const [passwordRequirementsError, setPasswordRequirementsError] =
    useState("");
  const [genericRoute, setGenericRoute] = useState(false);
  const [adminPermissions, setAdminpermissions] = useState([
    {
      heading: null,
      text: "Manages everything related to account access and security. Assign this role to users who manage users and teams of users, apply restrictions with two-factor authentication, password policies and API keys.",
      roleName: "ALL-PERMISSION",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Account Manager",
      text: "Manages everything related to account access. Provided to all Active accounts to login and use the portal",
      roleName: "ACCOUNT-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Users and Teams",
      text: "Manage users and teams. Provide access to users, and manage their roles.",
      roleName: "USER-TEAMS",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Integrations Manager",
      text: "Grants access to versatile reporting and data handling options.Track engagement and communication efficiency Schedule reports - Troubleshoot message delivery - Exchange data",
      roleName: "INTEGRATION-MANAGER",
      checked: false,
      hr: true,
      innerHeading: "",
    },
    {
      heading: "Analytics Manager",
      text: " Grants access to versatile reporting and data handling options.Track engagement and communication efficiency Schedule reports - Troubleshoot message delivery - Exchange data",
      roleName: "ANALYTICS-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "FINANCE AND ANALYTICS",
    },
    {
      heading: "Finance Data Manager",
      text: "Manages communication costs, tracks account balance, and performs financial management tasks.",
      roleName: "FINANCE-DATA-MANAGER",
      checked: false,
      hr: true,
      innerHeading: "",
    },
    {
      heading: "Communication Manager",
      text: "Creates engagement scenarios and sends broadcast campaigns.",
      roleName: "COMMUNICATION-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "COMMUNICATION MANAGEMENT",
    },
    {
      heading: "People Manager",
      text: "Manages customer profiles on the data platform.",
      roleName: "PEOPLE-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Live Chat Manager",
      text: "Manages live chat conversations regardless of assignment. Chats can be assigned to them manually only and user reports will be shared. Grants access to versatile reporting and data handling options related to live chat conversations. Track live chat efficiency - Schedule reports - Exchange data",
      roleName: "LIVE-CHAT-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Chatbot Manager",
      text: " Manages chatbot design, support, and usage to achieve business goals. Supervises planned activities and progress. Creates and edits chatbots. Activate or deactivate the bots",
      roleName: "CHAT-BOT-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Chatbot Supervisor",
      text: "Monitor chatbots and analytics",
      roleName: "CHATBOT-SUPERVISOR",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Live Chat User",
      text: "Manages live chat conversations assigned to them Can not manage chats for others. User performance reports will be created",
      roleName: "LIVE-CHAT-AGENT",
      checked: false,
      hr: false,
      innerHeading: "",
    },

    {
      heading: "Chat bot Conversation",
      text: "View bot conversations with the customers.",
      roleName: "CHATBOT-CONVERSATION",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Form Access Manager",
      text: "Controls access to customer-filled forms. Assigned to users who need to view and manage submitted forms on the portal.",
      roleName: "FORM-ACCESS-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "FORM MANAGEMENT",
    },
  ]);
  const [superVisorPermissions, setSuperVisorPermissions] = useState([
    {
      heading: null,
      text: "Manages everything related to account access and security. Assign this role to users who manage users and teams of users, apply restrictions with two-factor authentication, password policies and API keys.",
      roleName: "SUPERVISOR",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Account Manager",
      text: "Manages everything related to account access. Provided to all Active accounts to login and use the portal",
      roleName: "ACCOUNT-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Users and Teams",
      text: "Manage users and teams. Provide access to users, and manage their roles.",
      roleName: "USER-TEAMS",
      checked: false,
      hr: true,
      box: false,
      innerHeading: "",
    },

    {
      heading: "Analytics Manager",
      text: " Grants access to versatile reporting and data handling options.Track engagement and communication efficiency Schedule reports - Troubleshoot message delivery - Exchange data",
      roleName: "ANALYTICS-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "FINANCE AND ANALYTICS",
    },
    {
      heading: "Finance Data Manager",
      text: "Manages communication costs, tracks account balance, and performs financial management tasks.",
      roleName: "FINANCE-DATA-MANAGER",
      checked: false,
      hr: true,
      innerHeading: "",
    },
    {
      heading: "Communication Manager",
      text: "Creates engagement scenarios and sends broadcast campaigns.",
      roleName: "COMMUNICATION-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "COMMUNICATION MANAGEMENT",
    },
    {
      heading: "People Manager",
      text: "Manages customer profiles on the data platform.",
      roleName: "PEOPLE-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Live Chat Manager",
      text: "Manages live chat conversations regardless of assignment. Chats can be assigned to them manually only and user reports will be shared. Grants access to versatile reporting and data handling options related to live chat conversations. Track live chat efficiency - Schedule reports - Exchange data",
      roleName: "LIVE-CHAT-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Chatbot Manager",
      text: " Manages chatbot design, support, and usage to achieve business goals. Supervises planned activities and progress. Creates and edits chatbots. Activate or deactivate the bots",
      roleName: "CHAT-BOT-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Chatbot Supervisor",
      text: "Monitor chatbots and analytics",
      roleName: "CHATBOT-SUPERVISOR",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Live Chat User",
      text: "Manages live chat conversations assigned to them Can not manage chats for others. User performance reports will be created",
      roleName: "LIVE-CHAT-AGENT",
      checked: false,
      hr: false,
      innerHeading: "",
    },

    {
      heading: "Chat bot Conversation",
      text: "View bot conversations with the customers.",
      roleName: "CHATBOT-CONVERSATION",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Form Access Manager",
      text: "Controls access to customer-filled forms. Assigned to users who need to view and manage submitted forms on the portal.",
      roleName: "FORM-ACCESS-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "FORM MANAGEMENT",
    },
  ]);
  const [agentPermissions, setAgentPermissions] = useState([
    {
      heading: null,
      text: "Manages everything related to account access and security. Assign this role to users who manage users and teams of users, apply restrictions with two-factor authentication, password policies and API keys.",
      roleName: "AGENT",
      checked: false,
      hr: false,
    },
    {
      heading: "Account Manager",
      text: "Manages everything related to account access. Provided to all Active accounts to login and use the portal",
      roleName: "ACCOUNT-MANAGER",
      checked: false,
      hr: true,
    },

    {
      heading: "Live Chat User",
      text: "Manages live chat conversations assigned to them Can not manage chats for others. User performance reports will be created",
      roleName: "LIVE-CHAT-AGENT",
      checked: true,
      hr: false,
    },

    {
      heading: "Chat bot Conversation",
      text: "View bot conversations with the customers.",
      roleName: "CHATBOT-CONVERSATION",
      checked: false,
      hr: true,
    },
    {
      heading: "Form Access Manager",
      text: "Controls access to customer-filled forms. Assigned to users who need to view and manage submitted forms on the portal.",
      roleName: "FORM-ACCESS-MANAGER",
      checked: false,
      hr: false,
    },
  ]);

  const [adminSelectedPermissions, setAdminSelectedPermissions] = useState([
    "ACCOUNT-MANAGER",
    "USER-TEAMS",
    "INTEGRATION-MANAGER",
    "ANALYTICS-MANAGER",
    "FINANCE-DATA-MANAGER",
    "COMMUNICATION-MANAGER",
    "PEOPLE-MANAGER",
    "LIVE-CHAT-MANAGER",
    "CHAT-BOT-MANAGER",
    "CHATBOT-SUPERVISOR",
    "CHATBOT-CONVERSATION",
  ]);
  const [supervisorSelectedPermissions, setSupervisorSelectedPermissions] =
    useState(["ACCOUNT-MANAGER"]);
  const [agentSelectedPermissions, setAgentSelectedPermissions] = useState([
    "ACCOUNT-MANAGER",
    "LIVE-CHAT-AGENT",
  ]);
  const { auth } = useAuth();

  const handleViewPassword = () => {
    setViewPassword(!viewPassword);
  };

  const handleViewConfirmPassword = () => {
    setViewConfirmPassword(!viewConfirmPassword);
  };
  const handleAdminPermission = (index, permission, checked) => {
    if (checked === false && index === 0) {
      let firstindex = agentSelectedPermissions
        .map(function (o) {
          return o;
        })
        .indexOf("AGENT");

      if (firstindex > -1) {
        agentSelectedPermissions.splice(firstindex, 1);
        agentPermissions[0]["checked"] = false;
        setAgentPermissions(agentPermissions);
        setAgentSelectedPermissions(agentSelectedPermissions);
      }
      let Supervisorindex = supervisorSelectedPermissions
        .map(function (o) {
          return o;
        })
        .indexOf("SUPERVISOR");

      if (Supervisorindex > -1) {
        supervisorSelectedPermissions.splice(Supervisorindex, 1);
        superVisorPermissions[0]["checked"] = false;
        setSuperVisorPermissions(superVisorPermissions);
        setSupervisorSelectedPermissions(supervisorSelectedPermissions);
      }
    }

    var adminPermissionTemp = [...adminPermissions];
    if (index === 0) {
      if (checked === false) {
        setAdminSelectedPermissions((list) => [...list, permission]);
        adminPermissionTemp[index]["checked"] = true;
        setAdminpermissions(adminPermissionTemp);
      } else {
        adminPermissionTemp[index]["checked"] = false;
        setAdminpermissions(adminPermissionTemp);
        let firstindex = adminSelectedPermissions
          .map(function (o) {
            return o;
          })
          .indexOf("ALL-PERMISSION");

        if (firstindex > -1) {
          adminSelectedPermissions.splice(firstindex, 1);
          setAdminSelectedPermissions(adminSelectedPermissions);
        }
      }
    } else {
      if (
        permission === "LIVE-CHAT-AGENT" ||
        permission === "FORM-ACCESS-MANAGER"
      ) {
        if (checked === false) {
          setAdminSelectedPermissions((list) => [...list, permission]);
          adminPermissionTemp[index]["checked"] = true;
          setAdminpermissions(adminPermissionTemp);
        } else {
          adminPermissionTemp[index]["checked"] = false;
          setAdminpermissions(adminPermissionTemp);
          const otherIndex = adminSelectedPermissions.indexOf(permission);
          if (otherIndex > -1) {
            adminSelectedPermissions.splice(otherIndex, 1);
            setAdminSelectedPermissions(adminSelectedPermissions);
          }
        }
      }
    }
  };
  const handleSupervisorPermission = (index, permission, checked) => {
    if (checked === false && index === 0) {
      let firstindex = agentSelectedPermissions
        .map(function (o) {
          return o;
        })
        .indexOf("AGENT");

      if (firstindex > -1) {
        agentSelectedPermissions.splice(firstindex, 1);
        agentPermissions[0]["checked"] = false;
        setAgentPermissions(agentPermissions);
        setAgentSelectedPermissions(agentSelectedPermissions);
      }
    }
    let adminIndex = adminSelectedPermissions
      .map(function (o) {
        return o;
      })
      .indexOf("ALL-PERMISSION");

    if (adminIndex > -1) {
      adminSelectedPermissions.splice(adminIndex, 1);
      adminPermissions[0]["checked"] = false;
      setAdminpermissions(adminPermissions);
      setAdminSelectedPermissions(adminSelectedPermissions);
    }

    var superVisorPermissionTemp = [...superVisorPermissions];
    if (permission !== "ACCOUNT-MANAGER") {
      if (index === 0) {
        if (checked === false) {
          setSupervisorSelectedPermissions((list) => [...list, permission]);
          superVisorPermissionTemp[index]["checked"] = true;
          setSuperVisorPermissions(superVisorPermissionTemp);
        } else {
          superVisorPermissionTemp[index]["checked"] = false;
          setSuperVisorPermissions(superVisorPermissionTemp);
          let firstindex = supervisorSelectedPermissions
            .map(function (o) {
              return o;
            })
            .indexOf("SUPERVISOR");

          if (firstindex > -1) {
            supervisorSelectedPermissions.splice(firstindex, 1);
            setSupervisorSelectedPermissions(supervisorSelectedPermissions);
          }
        }
      } else {
        if (checked === false) {
          setSupervisorSelectedPermissions((list) => [...list, permission]);
          superVisorPermissionTemp[index]["checked"] = true;
          setSuperVisorPermissions(superVisorPermissionTemp);
        } else {
          superVisorPermissionTemp[index]["checked"] = false;
          setSuperVisorPermissions(superVisorPermissionTemp);
          const otherIndex = supervisorSelectedPermissions.indexOf(permission);
          if (otherIndex > -1) {
            supervisorSelectedPermissions.splice(otherIndex, 1);
            setSupervisorSelectedPermissions(supervisorSelectedPermissions);
          }
        }
      }
    }
  };
  const handleAgentPermission = (index, permission, checked) => {
    let adminIndex = adminSelectedPermissions
      .map(function (o) {
        return o;
      })
      .indexOf("ALL-PERMISSION");

    if (adminIndex > -1) {
      adminSelectedPermissions.splice(adminIndex, 1);
      adminPermissions[0]["checked"] = false;
      setAdminpermissions(adminPermissions);
      setAdminSelectedPermissions(adminSelectedPermissions);
    }
    let Supervisorindex = supervisorSelectedPermissions
      .map(function (o) {
        return o;
      })
      .indexOf("SUPERVISOR");

    if (Supervisorindex > -1) {
      supervisorSelectedPermissions.splice(Supervisorindex, 1);
      superVisorPermissions[0]["checked"] = false;
      setSuperVisorPermissions(superVisorPermissions);
      setSupervisorSelectedPermissions(supervisorSelectedPermissions);
    }

    var agentPermissionsTemp = [...agentPermissions];
    if (permission !== "ACCOUNT-MANAGER") {
      if (index === 0) {
        if (checked === false) {
          setAgentSelectedPermissions((list) => [...list, permission]);
          agentPermissionsTemp[index]["checked"] = true;
          setAgentPermissions(agentPermissionsTemp);
        } else {
          agentPermissionsTemp[index]["checked"] = false;
          setAgentPermissions(agentPermissionsTemp);
          let firstindex = agentSelectedPermissions
            .map(function (o) {
              return o;
            })
            .indexOf("AGENT");

          if (firstindex > -1) {
            agentSelectedPermissions.splice(firstindex, 1);
            setAgentSelectedPermissions(agentSelectedPermissions);
          }
        }
      } else {
        if (checked === false) {
          setAgentSelectedPermissions((list) => [...list, permission]);
          agentPermissionsTemp[index]["checked"] = true;
          setAgentPermissions(agentPermissionsTemp);
        } else {
          agentPermissionsTemp[index]["checked"] = false;
          setAgentPermissions(agentPermissionsTemp);
          const otherIndex = agentSelectedPermissions.indexOf(permission);
          if (otherIndex > -1) {
            agentSelectedPermissions.splice(otherIndex, 1);
            setAgentSelectedPermissions(agentSelectedPermissions);
          }
        }
      }
    }
  };
  const getCountry = () => {
    apiGet(`/countries/get-all`, onSuccessCountry, onFailureCountry);
  };
  const onSuccessCountry = (response) => {
    setCountryList(response?.data);
  };
  const onFailureCountry = (error) => {
    console.log(error);
  };
  const getCity = () => {
    const countryParam =
      selectedCountry[0].id === undefined
        ? selectedCountry[0]
        : selectedCountry[0].id;
    apiGet(`/countries/city/${countryParam}`, onSuccessCity, onFailureCity);
  };
  const onSuccessCity = (response) => {
    setCityArray(response?.data);
  };
  const onFailureCity = (error) => {
    console.log(error);
  };

  const handleCountry = (val) => {
    var data = countryList.filter((filter) => val === filter.name);
    setSelectedCountry(data);
  };

  useEffect(() => {
    if (selectedCountry !== null) {
      getCity();
    }
  }, [selectedCountry]);

  const getTags = () => {
    apiGet(
      `/agent/team/list`,
      onSuccessTeam,
      onFailureTeam
    );
  };
  const onSuccessTeam = (response) => {
    setTeam(response?.data);
  };
  const onFailureTeam = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getTags();
  }, []);

  const handleEmailValidation = (e) => {
    var Email = ValidateEmail(e);
    if (Email === "Invalid Email") {
      setEmailError("Invalid Email Address");
    } else {
      setEmailError("Valid Email Address");
    }
  };
  const handleValidation = (e) => {
    let value = e.target.value;

    if (value === submittedData.confirmPassword) {
      setPwdPassword(true);
      setErrorSpan(" ");
    } else if (value !== password) {
      setErrorSpan("Password requirements not matched");
    } else {
      setPwdPassword(false);
    }
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const lengthRegex = /^.{8,}$/;

    const isLowercaseValid = lowercaseRegex.test(value);
    const isUppercaseValid = uppercaseRegex.test(value);
    const isNumberValid = numberRegex.test(value);
    const isLengthValid = lengthRegex.test(value);

    setLowerCase(isLowercaseValid);
    setUpperCase(isUppercaseValid);
    setNumbers(isNumberValid);
    setLengths(isLengthValid);
    setPassword(value);
  };

  const handleConfirmPwdValidation = (e) => {
    let value = e.target.value;

    if (value === password) {
      setPwdPassword(true);
      setErrorSpan(" ");
    } else if (value !== password) {
      setErrorSpan("Password requirements not matched");
    } else {
      setPwdPassword(false);
    }
  };
  const handleGenericRoute = () => {
    setGenericRoute(!genericRoute);
  };
  const addUser = (e) => {
    e.preventDefault();
    setErrorEnable(true);
    setLoader(true);
    let data = {
      agentType: "SUPERVISOR",
      companyPublicId: decryptedValue(auth.companyId),
      email: submittedData.email,
      firstName: submittedData.firstName,
      country: submittedData.country,
      city: submittedData.city,
      isAvailable: true,
      lastName: submittedData.lastName,
      password: submittedData.password,
      isGenericRoute: genericRoute,
      permissionsList:
        adminSelectedPermissions.filter((val) => val === "ALL-PERMISSION")
          .length > 0
          ? adminSelectedPermissions
          : supervisorSelectedPermissions.filter((val) => val === "SUPERVISOR")
              .length > 0
          ? supervisorSelectedPermissions
          : agentSelectedPermissions.filter((val) => val === "AGENT").length > 0
          ? agentSelectedPermissions
          : [],
      phoneNumberPrivate: submittedData.phoneNumberPrivate,
      // phoneNumberWork: submittedData.phoneNumberWork,
      role: {
        name:
          (adminSelectedPermissions.filter((val) => val === "ALL-PERMISSION")
            .length > 0 &&
            "ROLE_ADMIN") ||
          (supervisorSelectedPermissions.filter((val) => val === "SUPERVISOR")
            .length > 0 &&
            "ROLE_SUPERVISOR") ||
          (agentSelectedPermissions.filter((val) => val === "AGENT").length >
            0 &&
            "ROLE_AGENT"),
      },
      status: "ONLINE",
      team: submittedData.team,
    };
    let condition = true;
    if (
      adminSelectedPermissions.filter((val) => val === "ALL-PERMISSION")
        .length !== 0
    ) {
      if (adminSelectedPermissions.length < 12) {
        condition = false;
      }
    } else if (
      supervisorSelectedPermissions.filter((val) => val === "SUPERVISOR")
        .length !== 0
    ) {
      if (supervisorSelectedPermissions.length < 3) {
        condition = false;
      }
    } else if (
      agentSelectedPermissions.filter((val) => val === "AGENT").length !== 0
    ) {
      if (agentSelectedPermissions.length < 4) {
        condition = false;
      }
    }

    if (
      submittedData.firstName !== "" &&
      submittedData.lastName !== "" &&
      submittedData.country !== "" &&
      submittedData.city !== "" &&
      // submittedData.phoneNumberWork !== "" &&
      submittedData.password !== "" &&
      submittedData.phoneNumberPrivate !== "" &&
      submittedData.email !== "" &&
      emailError === "Valid Email Address" &&
      // submittedData.team !== "" &&
      lowerCase === true &&
      upperCase === true &&
      numbers === true &&
      lengths === true &&
      pwdPassword === true &&
      isNumberValid === true &&
      condition === true &&
      (adminSelectedPermissions.filter((val) => val === "ALL-PERMISSION")
        .length !== 0 ||
        supervisorSelectedPermissions.filter((val) => val === "SUPERVISOR")
          .length !== 0 ||
        agentSelectedPermissions.filter((val) => val === "AGENT").length !== 0)
    ) {
      apiPost(`/agent/create`, onSuccess, onFailure, data);
    } else {
      setLoader(false);
    }
  };
  const onSuccess = (response) => {
    if (response.status === 1) {
      setTimeout(() => {
        setLoader(false);
        toast.success("User added successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1000);
      setTimeout(() => {
        navigate("/users", { replace: true });
      }, 2600);
    }
  };
  const onFailure = (error) => {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoader(false);
  };
  return (
    <div className="addTeamWrp">
      <div className="uk-container">
        <div className="backBtn">
          <Link to="/users">
            <ChevronLeft /> Back to all users
          </Link>
        </div>
        <div className="addTeamHeading">
          <h3>Create user</h3>
          <p>
            Enter details for the new user, assign roles, and select teams this
            user belongs to.
          </p>
        </div>
        <div className="addTeamBox">
          <div className="boxHeading">
            <h3>User information</h3>
          </div>
          <div className="formwrp">
            <form onSubmit={addUser} autoComplete="off">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">First name</label>
                    <input
                      type="text"
                      placeholder="First name"
                      className="uk-input"
                      value={submittedData.firstName}
                      onChange={(e) => {
                        e.target.value.length <= 10 &&
                          setSubmittedData({
                            ...submittedData,
                            firstName: e.target.value,
                          });
                      }}
                    />
                    <div className="txtCounter" style={{ top: "35px" }}>
                      <span>{submittedData?.firstName?.length}</span>
                      <span>/</span>
                      <span>10</span>
                    </div>
                    {submittedData.firstName === "" && errorEnable && (
                      <div className="formErrors">First name is required</div>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">Last name</label>
                    <input
                      type="text"
                      placeholder="Last name"
                      className="uk-input"
                      value={submittedData.lastName}
                      onChange={(e) => {
                        e.target.value.length <= 10 &&
                          setSubmittedData({
                            ...submittedData,
                            lastName: e.target.value,
                          });
                      }}
                    />
                    <div className="txtCounter" style={{ top: "35px" }}>
                      <span>{submittedData?.lastName?.length}</span>
                      <span>/</span>
                      <span>10</span>
                    </div>
                    {submittedData.lastName === "" && errorEnable && (
                      <div className="formErrors">Last name is required</div>
                    )}
                  </div>
                </div>
                {/* <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="work_number">Work number</label>
                    <input
                      type="text"
                      placeholder="Work number"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          phoneNumberWork: e.target.value,
                        });
                      }}
                    />
                    {submittedData.phoneNumberWork === "" && errorEnable && (
                      <div className="formErrors">work number is required</div>
                    )}
                  </div>
                </div> */}
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="mobile_no">Mobile Number</label>
                    <input
                      type="number"
                      placeholder="Mobile Number"
                      className="uk-input"
                      value={submittedData.phoneNumberPrivate}
                      onChange={(e) => {
                        let error = validatePhoneNumber(e.target.value);
                        setIsNumberValid(error);
                        e.target.value.length <= 20 &&
                          setSubmittedData({
                            ...submittedData,
                            phoneNumberPrivate: e.target.value,
                          });
                      }}
                    />
                    <div className="txtCounter" style={{ top: "35px" }}>
                      <span>{submittedData?.phoneNumberPrivate?.length}</span>
                      <span>/</span>
                      <span>20</span>
                    </div>
                    {submittedData.phoneNumberPrivate === "" && errorEnable && (
                      <div className="formErrors">
                        Mobile number is required
                      </div>
                    )}
                    {submittedData.phoneNumberPrivate !== "" &&
                      !isNumberValid && (
                        <div className="formErrors">Invalid mobile number</div>
                      )}
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="email">Email</label>
                    <input
                      autoComplete="off"
                      type="text"
                      value={submittedData?.email}
                      placeholder="Email"
                      // value={submittedData.email}
                      className="uk-input"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          email: e.target.value,
                        });
                        handleEmailValidation(e.target.value);
                      }}
                      // autoComplete="new-email"
                    />
                    {submittedData.email === "" && errorEnable && (
                      <div className="formErrors">Email is required</div>
                    )}
                    {submittedData.email !== "" &&
                      emailError !== "Valid Email Address" && (
                        <div className="formErrors">Invalid Email Address</div>
                      )}
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="">Country</label>
                    <select
                      name=""
                      onClick={() => getCountry()}
                      className="uk-select"
                      onChange={(e) => {
                        handleCountry(e.target.value);
                        setSubmittedData({
                          ...submittedData,
                          country: e.target.value,
                        });
                      }}
                      value={submittedData.country || ""}
                    >
                      <option value="" disabled>
                        Select a country
                      </option>
                      {countryList.length === 0 ? (
                        <option value="" disabled>
                          Loading ...
                        </option>
                      ) : (
                        countryList.map((val, index) => (
                          <option key={index} value={val.name}>
                            {val.name}
                          </option>
                        ))
                      )}
                    </select>
                    {submittedData.country === "" && errorEnable && (
                      <div className="formErrors">Country is required</div>
                    )}
                  </div>
                </div>

                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="">City</label>
                    <select
                      name=""
                      className="uk-select"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          city: e.target.value,
                        });
                      }}
                      value={submittedData.city || ""}
                    >
                      {cityArray === null ? (
                        <>
                          <option
                            value=""
                            disabled
                            placeholder="Please select city"
                          >
                            Select a city
                          </option>
                          <option>Loading ...</option>
                        </>
                      ) : (
                        <>
                          <option value="" selected>
                            Select a city
                          </option>
                          {cityArray?.length > 0 ? (
                            cityArray?.map((val, index) => (
                              <option key={index} value={val?.name}>
                                {val?.name}
                              </option>
                            ))
                          ) : (
                            <option value="" disabled>
                              No data found
                            </option>
                          )}
                        </>
                      )}
                    </select>

                    {submittedData.city === "" && errorEnable && (
                      <div className="formErrors">City is required</div>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="team">Team</label>
                    <select
                      name="team"
                      className="uk-select"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          team: e.target.value,
                        });
                      }}
                      value={submittedData.team || ""}
                    >
                      <option value="" disabled>
                        Select team
                      </option>
                      {team?.map((val, index) => {
                        return (
                          <option value={val.publicId} key={index}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                    {/* {submittedData.team === "" && errorEnable && (
                      <div className="formErrors">Please select team</div>
                    )} */}
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="password">Password</label>
                    <input
                      type={viewPassword ? "text" : "password"}
                      className="uk-input"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          password: e.target.value,
                        });
                        handleValidation(e);
                      }}
                      autoComplete="off"
                    />
                    <button type="button" onClick={handleViewPassword}>
                      {viewPassword ? <View /> : <ViewOff />}
                    </button>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label>Confirm password</label>
                    <input
                      type={viewConfirmPassword ? "text" : "password"}
                      className="uk-input"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          confirmPassword: e.target.value,
                        });
                        handleConfirmPwdValidation(e);
                      }}
                      autoComplete="off"
                    />
                    <button type="button" onClick={handleViewConfirmPassword}>
                      {viewConfirmPassword ? <View /> : <ViewOff />}
                    </button>
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="passMatchWrp passwordContainerNew">
                    <ul>
                      <li>
                        <span
                          className={
                            lowerCase === true ? "active" : "nonActive"
                          }
                        >
                          <Checkmark />
                        </span>
                        <span className="checkmarktxt">Lowercase letter</span>
                      </li>
                      <li>
                        <span
                          className={
                            upperCase === true ? "active" : "nonActive"
                          }
                        >
                          <Checkmark />
                        </span>
                        <span className="checkmarktxt">Uppercase letter</span>
                      </li>
                      <li>
                        <span
                          className={numbers === true ? "active" : "nonActive"}
                        >
                          <Checkmark />
                        </span>
                        <span className="checkmarktxt">Number</span>
                      </li>
                      <li>
                        <span
                          className={lengths === true ? "active" : "nonActive"}
                        >
                          <Checkmark />
                        </span>
                        <span className="checkmarktxt">
                          Minimum 8 characters
                        </span>
                      </li>
                      <li>
                        <span
                          className={
                            pwdPassword === true ? "active" : "nonActive"
                          }
                        >
                          <Checkmark />
                        </span>
                        <span className="checkmarktxt">
                          password and confirm password
                        </span>
                      </li>
                    </ul>

                    <span
                      className="formErrors uk-margin-top "
                      style={{ display: "block" }}
                    >
                      {errorSpan}
                    </span>
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="adminRoleWrp">
                    <div className="switchwrp">
                      <div className="adminHeading">
                        <span>Generic User</span>
                      </div>
                      <div className="adminBox">
                        <div className="txtAndSwitchWrp">
                          <div className="txtAndSwitch">
                            <div className="txtwrp">
                              <p>
                                These agents handle all the chats in default
                                route
                              </p>
                            </div>
                            <div className="switchwrp">
                              <Switch
                                onChange={() => {
                                  handleGenericRoute();
                                }}
                                checked={genericRoute}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                width={26}
                                height={14}
                                handleDiameter={10}
                                onColor="#29A869"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="adminRoleWrp">
                    <div className="adminHeading">
                      <span>Admin Role</span>
                    </div>
                    <div className="adminBox">
                      {adminSelectedPermissions.filter(
                        (val) => val === "ALL-PERMISSION"
                      ).length === 0 && (
                        <div className="txtAndSwitchWrp">
                          <div className="txtAndSwitch">
                            <div className="txtwrp">
                              <p>{adminPermissions[0].text}</p>
                            </div>
                            <div className="switchwrp">
                              <Switch
                                onChange={() => {
                                  handleAdminPermission(
                                    0,
                                    "ALL-PERMISSION",
                                    adminPermissions[0]["checked"]
                                  );
                                }}
                                checked={adminPermissions[0]["checked"]}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                width={26}
                                height={14}
                                handleDiameter={10}
                                onColor="#29A869"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {adminSelectedPermissions.filter(
                        (val) => val === "ALL-PERMISSION"
                      ).length > 0 &&
                        adminPermissions?.map((val, index) => {
                          return (
                            <React.Fragment key={index}>
                              {val.innerHeading !== "" && (
                                <div className="boxInnerHeading">
                                  <span>{val.innerHeading}</span>
                                </div>
                              )}
                              <div className="txtAndSwitchWrp">
                                {val.heading !== null && (
                                  <div className="boxHeading">
                                    <h3>{val.heading}</h3>
                                  </div>
                                )}
                                <div className="txtAndSwitch">
                                  <div className="txtwrp">
                                    <p>{val.text}</p>
                                  </div>
                                  <div className="switchwrp">
                                    <Switch
                                      onChange={() => {
                                        handleAdminPermission(
                                          index,
                                          val.roleName,
                                          val.checked
                                        );
                                      }}
                                      checked={
                                        val.roleName === "LIVE-CHAT-AGENT" ||
                                        val.roleName === "FORM-ACCESS-MANAGER"
                                          ? val.checked
                                          : true
                                      }
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      width={26}
                                      height={14}
                                      handleDiameter={10}
                                      onColor="#29A869"
                                    />
                                  </div>
                                </div>
                              </div>
                              {val.hr !== false && <hr />}
                            </React.Fragment>
                          );
                        })}
                    </div>
                    <div className="adminRoleWrp">
                      <div className="adminHeading">
                        <span>Supervisor</span>
                      </div>
                      <div className="adminBox">
                        {supervisorSelectedPermissions.filter(
                          (val) => val === "SUPERVISOR"
                        ).length === 0 && (
                          <div className="txtAndSwitchWrp">
                            <div className="txtAndSwitch">
                              <div className="txtwrp">
                                <p>{superVisorPermissions[0].text}</p>
                              </div>
                              <div className="switchwrp">
                                <Switch
                                  onChange={() => {
                                    handleSupervisorPermission(
                                      0,
                                      "SUPERVISOR",
                                      superVisorPermissions[0]["checked"]
                                    );
                                  }}
                                  checked={superVisorPermissions[0]["checked"]}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  width={26}
                                  height={14}
                                  handleDiameter={10}
                                  onColor="#29A869"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {supervisorSelectedPermissions.filter(
                          (val) => val === "SUPERVISOR"
                        ).length > 0 &&
                          superVisorPermissions.map((val, index) => {
                            return (
                              <React.Fragment key={index}>
                                {val.innerHeading !== "" && (
                                  <div className="boxInnerHeading">
                                    <span>{val.innerHeading}</span>
                                  </div>
                                )}
                                <div className="txtAndSwitchWrp">
                                  {val.heading !== null && (
                                    <div className="boxHeading">
                                      <h3>{val.heading}</h3>
                                    </div>
                                  )}
                                  <div className="txtAndSwitch">
                                    <div className="txtwrp">
                                      <p>{val.text}</p>
                                    </div>
                                    <div className="switchwrp">
                                      <Switch
                                        onChange={() => {
                                          handleSupervisorPermission(
                                            index,
                                            val.roleName,
                                            val.checked
                                          );
                                        }}
                                        checked={
                                          val.roleName === "ACCOUNT-MANAGER"
                                            ? true
                                            : val.checked
                                        }
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        width={26}
                                        height={14}
                                        handleDiameter={10}
                                        onColor="#29A869"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {val.hr !== false && <hr />}
                              </React.Fragment>
                            );
                          })}
                      </div>
                    </div>
                    <div className="adminRoleWrp">
                      <div className="adminHeading">
                        <span>User</span>
                      </div>

                      <div className="adminBox">
                        {agentSelectedPermissions.filter(
                          (val) => val === "AGENT"
                        ).length === 0 && (
                          <div className="txtAndSwitchWrp">
                            <div className="txtAndSwitch">
                              <div className="txtwrp">
                                <p>{agentPermissions[0].text}</p>
                              </div>
                              <div className="switchwrp">
                                <Switch
                                  onChange={() => {
                                    handleAgentPermission(
                                      0,
                                      "AGENT",
                                      agentPermissions[0]["checked"]
                                    );
                                  }}
                                  checked={agentPermissions[0]["checked"]}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  width={26}
                                  height={14}
                                  handleDiameter={10}
                                  onColor="#29A869"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {agentSelectedPermissions.filter(
                          (val) => val === "AGENT"
                        ).length > 0 &&
                          agentPermissions.map((val, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div className="txtAndSwitchWrp">
                                  {val.heading !== null && (
                                    <div className="boxHeading">
                                      <h3>{val.heading}</h3>
                                    </div>
                                  )}
                                  <div className="txtAndSwitch">
                                    <div className="txtwrp">
                                      <p>{val.text}</p>
                                    </div>
                                    <div className="switchwrp">
                                      <Switch
                                        onChange={() => {
                                          handleAgentPermission(
                                            index,
                                            val.roleName,
                                            val.checked
                                          );
                                        }}
                                        checked={
                                          val.roleName === "ACCOUNT-MANAGER"
                                            ? true
                                            : val.checked
                                        }
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        width={26}
                                        height={14}
                                        handleDiameter={10}
                                        onColor="#29A869"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {val.hr !== false && <hr />}
                              </React.Fragment>
                            );
                          })}
                      </div>
                    </div>
                    {errorEnable &&
                      adminSelectedPermissions.filter(
                        (val) => val === "ALL-PERMISSION"
                      ).length === 0 &&
                      supervisorSelectedPermissions.filter(
                        (val) => val === "SUPERVISOR"
                      ).length === 0 &&
                      agentSelectedPermissions.filter((val) => val === "AGENT")
                        .length === 0 && (
                        <div className="formErrors">Please select role</div>
                      )}
                    {errorEnable &&
                      adminSelectedPermissions.filter(
                        (val) => val === "ALL-PERMISSION"
                      ).length === 0 &&
                      supervisorSelectedPermissions.filter(
                        (val) => val === "SUPERVISOR"
                      ).length !== 0 &&
                      supervisorSelectedPermissions.length < 3 &&
                      agentSelectedPermissions.filter((val) => val === "AGENT")
                        .length === 0 && (
                        <div className="formErrors">
                          Please select atleast one supervisor permisssion
                        </div>
                      )}
                    {errorEnable &&
                      adminSelectedPermissions.filter(
                        (val) => val === "ALL-PERMISSION"
                      ).length === 0 &&
                      supervisorSelectedPermissions.filter(
                        (val) => val === "SUPERVISOR"
                      ).length === 0 &&
                      agentSelectedPermissions.filter((val) => val === "AGENT")
                        .length !== 0 &&
                      agentSelectedPermissions.length < 4 && (
                        <div className="formErrors">
                          Please select atleast one agent permisssion
                        </div>
                      )}
                  </div>

                  {/* {passwordRequirementsMet === false && errorEnable && (
                    <div className="formErrors uk-margin-top">
                      Passwork requirement not match
                    </div>
                  )} */}

                  <div className="adminRoleWrp">
                    <div className="btnwrp">
                      <button className="btn-2 w-70" type="submit">
                        {loader ? (
                          <div uk-spinner="" className="loader"></div>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
