import Request from "../../../Help/Request";

const ApplyForSelfService = () => {
  return (
    <Request
      heading={"Apply for a self-service chatbot"}
      paragraph={
        "Please provide the details and specific requirements for the self-service chatbot feature."
      }
      type={"self service"}
      btnText={"Request a demo"}
    />
  );
};

export default ApplyForSelfService;
