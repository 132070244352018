import React from "react";

const CompanyHtml = ({ companyDetails }) => {
  return companyDetails?.childCompanies !== null &&
    companyDetails?.childCompanies.length > 1 ? (
    <>{"(" + companyDetails?.companyName + ")"}</>
  ) : null;
};

export default CompanyHtml;
