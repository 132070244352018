import { Add, ChevronLeft, TrashCan } from "@carbon/icons-react";
import React, { useEffect, useState } from "react";
import SaveModal from "./Modals/SaveModal";
import { useAuth } from "../../Context/AuthProvider";
import { apiGet, apiPost } from "../../Utils/apiServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { decryptedValue } from "../../Utils/RouteHashConversion";

const CreateNewSegment = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [segmentCategory, setSegmentCategory] = useState();
  const [loader, setLoader] = useState(false);
  const [errorEnable, setErrorEnable] = useState(false);
  const [tags, setTags] = useState([]);
  const [segmentCountry, setSegmentCountry] = useState([]);
  const [segmentCity, setSegmentCity] = useState([]);
  const [formItems, setFormItems] = useState([]);
  const [conditionError, setConditionError] = useState([]);
  const [tagNameError, setTagNameError] = useState([]);
  const [tagTypeError, setTagTypeError] = useState([]);
  const [data, setData] = useState({
    segmentCategory: "",
    segmentDescription: "",
    segmentName: "",
  });
  const [tagDropDownOptions, setTagDropDownOptions] = useState([
    {
      name: "Gender",
      value: "GENDER",
      disabled: false,
    },
    {
      name: "First Name",
      value: "FIRST_NAME",
      disabled: false,
    },
    {
      name: "Last Name",
      value: "LAST_NAME",
      disabled: false,
    },
    {
      name: "City",
      value: "CITY",
      disabled: false,
    },
    {
      name: "Country",
      value: "COUNTRY",
      disabled: false,
    },
    {
      name: "Status",
      value: "STATUS",
      disabled: false,
    },
    {
      name: "Tag",
      value: "TAG",
      disabled: false,
    },
    // {
    //   name: "Email",
    //   value: "EMAIL",
    // },
  ]);

  // const categoryArray = [
  //   {
  //     name: "Catagory1",
  //     value: "catagory1",
  //   },
  //   {
  //     name: "Catagory2",
  //     value: "catagory2",
  //   },
  // ];
  const isIsntOption = [
    {
      name: "Select condition",
      value: "",
      selected: true,
      hidden: true,
    },
    {
      name: "is",
      value: "true",
      selected: false,
    },
    {
      name: "is'nt",
      value: "false",
      selected: false,
    },
  ];
  const hasAnyValue = [
    {
      name: "Select condition",
      value: "",
      selected: true,
      hidden: true,
    },
    {
      name: "has any value",
      // value: "true",
      value: "false",
      selected: false,
    },
    {
      name: "is empty",
      // value: "false",
      value: "true",
      selected: false,
    },
  ];
  const handleOpenSaveModal = () => {
    setOpenSaveModal(true);
  };

  const handleCloseSaveModal = () => {
    setOpenSaveModal(false);
  };
  useEffect(() => {
    if (formItems !== undefined && formItems[0]?.tagType !== "") {
      handleErrors();
    }
  }, [formItems]);

  const handleErrors = () => {
    let conditionArray = [];
    let tagName = [];
    let tagType = [];
    formItems.map((val) => {
      conditionArray.push(val.condition);
      tagName.push(val.tagName);
      tagType.push(val.tagType);
    });

    const containEmptyString = (element) => element === "";
    let conditionError = conditionArray.filter(containEmptyString);
    setConditionError(conditionError);
    let tagNameError = tagName.filter(containEmptyString);
    setTagNameError(tagNameError);
    let tagTypeError = tagType.filter(containEmptyString);
    setTagTypeError(tagTypeError);
  };

  const addNewCondition = () => {
    var newCondition = {
      condition: "",
      tagName: "",
      tagType: "",
      value: "",
      conditionValue: isIsntOption,
      fieldDisplayed: "Yes",
      thirdOptionValues: tags,
    };
    setFormItems([...formItems, newCondition]);
  };
  const removeCondition = (index) => {
    var tagAllConditions = [...formItems];
    let previousLastDropdown = tagAllConditions.splice(index, 1);
    tagAllConditions.splice(index, 1);
    setFormItems(tagAllConditions);

    tagDropDownOptions.map((val, index) => {
      tagAllConditions.map((item, ind) => {
        if (item.tagType === val.value) {
          if (
            item.tagType === "GENDER" ||
            item.tagType === "FIRST_NAME" ||
            item.tagType === "LAST_NAME" ||
            item.tagType === "STATUS"
          ) {
            tagDropDownOptions[index]["disabled"] = true;
            setTagDropDownOptions(tagDropDownOptions);
          }
        } else {
          let tagIndex = tagDropDownOptions
            .map(function (o) {
              return o.value;
            })
            .indexOf(previousLastDropdown[0]?.tagType);
          if (tagIndex > -1) {
            tagDropDownOptions[tagIndex]["disabled"] = false;
            setTagDropDownOptions(tagDropDownOptions);
          }
        }
      });
    });
    // setTagDropDownOptions(tagDropDownOptions);
  };
  const addSegment = () => {
    setErrorEnable(true);
    setLoader(true);

    handleErrors();

    // let conditionArray = [];
    // let tagName = [];
    // let tagType = [];
    // formItems.map((val) => {
    //   conditionArray.push(val.condition);
    //   tagName.push(val.tagName);
    //   tagType.push(val.tagType);
    // });

    // const containEmptyString = (element) => element === "";
    // let conditionError = conditionArray.filter(containEmptyString);
    // setConditionError(conditionError);
    // let tagNameError = tagName.filter(containEmptyString);
    // setTagNameError(tagNameError);
    // let tagTypeError = tagType.filter(containEmptyString);
    // setTagTypeError(tagTypeError);

    var submittedData = {
      segmentCategory: data.segmentCategory,
      companyPublicId: decryptedValue(auth.companyId),
      segmentDescription: data.segmentDescription,
      segmentName: data.segmentName,
      segmentTags: formItems,
    };
    if (
      data.segmentCategory !== "" &&
      data.segmentDescription !== "" &&
      data.segmentName !== "" &&
      conditionError.length === 0 &&
      tagNameError.length === 0 &&
      tagTypeError.length === 0
    ) {
      apiPost(
        `/segment/create`,
        onSuccesSegment,
        onFailureSegment,
        submittedData
      );
    } else {
      setLoader(false);
    }
  };

  const onSuccesSegment = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setLoader(false);
        toast.success("Segment added successfully", {
          position: toast.POSITION.TOP_RIGHT,
          // autoClose: 1500,
        });
      }, 1000);
      setTimeout(() => {
        navigate("/segments", { replace: true });
      }, 2000);
    }
  };
  const onFailureSegment = (error) => {
    setLoader(false);
  };
  const getSegmentCategory = () => {
    apiGet(
      `/segment/list/category`,
      onSuccessSegmentCategory,
      onFailureSegmentCategory
    );
  };
  const onSuccessSegmentCategory = (response) => {
    setSegmentCategory(response?.data);
  };
  const onFailureSegmentCategory = (error) => {
    console.log(error);
  };

  const handleFormChange = (index, name, e) => {
    formItems[index][name] = e.target.value;
    if (name === "tagName") {
      formItems[index]["value"] = e.target.value;
    }
    setFormItems(formItems);
  };

  const handleDropDown = (index, e) => {
    var tagAllConditions = [...formItems];
    if (e.target.value === "GENDER") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);

      let newField = {
        condition: "",
        tagName: "",
        tagType: e.target.value,
        value: "",
        condtionName: "GENDER",
        conditionValue: isIsntOption,
        fieldDisplayed: "Yes",
        thirdOptionValues: [
          {
            name: "Select gender",
            value: "",
            selected: true,
            hidden: true,
          },
          {
            name: "Male",
            value: "male",
            selected: false,
          },
          {
            name: "Female",
            value: "female",
            selected: false,
          },
        ],
      };
      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems(data);
      tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (
              item.tagType === "GENDER" ||
              item.tagType === "FIRST_NAME" ||
              item.tagType === "LAST_NAME" ||
              item.tagType === "STATUS"
            ) {
              tagDropDownOptions[index]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          } else {
            let tagIndex = tagDropDownOptions
              .map(function (o) {
                return o.value;
              })
              .indexOf(previousLastDropdown[0]?.tagType);
            if (tagIndex > -1) {
              tagDropDownOptions[tagIndex]["disabled"] = false;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
        });
      });
    }
    if (e.target.value === "FIRST_NAME") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);
      let newField = {
        condition: "",
        tagName: null,
        tagType: e.target.value,
        value: "",
        condtionName: "FIRST_NAME",
        conditionValue: hasAnyValue,
        fieldDisplayed: "No",
        thirdOptionValues: [],
      };
      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems(data);
      tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (
              item.tagType === "GENDER" ||
              item.tagType === "FIRST_NAME" ||
              item.tagType === "LAST_NAME" ||
              item.tagType === "STATUS"
            ) {
              tagDropDownOptions[index]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
          let tagIndex = tagDropDownOptions
            .map(function (o) {
              return o.value;
            })
            .indexOf(previousLastDropdown[0].tagType);
          if (tagIndex > -1) {
            tagDropDownOptions[tagIndex]["disabled"] = false;
            setTagDropDownOptions(tagDropDownOptions);
          }
        });
      });
    }
    if (e.target.value === "LAST_NAME") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);
      let newField = {
        condition: "",
        tagName: null,
        tagType: e.target.value,
        value: "",
        condtionName: "LAST_NAME",
        conditionValue: hasAnyValue,
        fieldDisplayed: "No",
        thirdOptionValues: [],
      };
      // setFormItems([...tagAllConditions, newField]);
      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems(data);
      // enableDisableIOptions(data);
      let mapped = tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (
              item.tagType === "GENDER" ||
              item.tagType === "FIRST_NAME" ||
              item.tagType === "LAST_NAME" ||
              item.tagType === "STATUS"
            ) {
              tagDropDownOptions[index]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
          let tagIndex = tagDropDownOptions
            .map(function (o) {
              return o.value;
            })
            .indexOf(previousLastDropdown[0].tagType);
          if (tagIndex > -1) {
            tagDropDownOptions[tagIndex]["disabled"] = false;
            setTagDropDownOptions(tagDropDownOptions);
          }
        });
      });
    }
    if (e.target.value === "STATUS") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);
      let newField = {
        condition: "",
        tagName: "",
        tagType: e.target.value,
        value: "",
        condtionName: "STATUS",
        conditionValue: isIsntOption,
        fieldDisplayed: "Yes",
        thirdOptionValues: [
          {
            name: "Select status",
            value: "",
            selected: true,
            hidden: true,
          },
          {
            name: "Active",
            value: "true",
            selected: false,
          },
          {
            name: "Blocked",
            value: "false",
            selected: false,
          },
        ],
      };
      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems(data);
      // enableDisableIOptions(data);
      let mapped = tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (
              item.tagType === "GENDER" ||
              item.tagType === "FIRST_NAME" ||
              item.tagType === "LAST_NAME" ||
              item.tagType === "STATUS"
            ) {
              tagDropDownOptions[index]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
          let tagIndex = tagDropDownOptions
            .map(function (o) {
              return o.value;
            })
            .indexOf(previousLastDropdown[0].tagType);
          if (tagIndex > -1) {
            tagDropDownOptions[tagIndex]["disabled"] = false;
            setTagDropDownOptions(tagDropDownOptions);
          }
        });
      });
    }
    if (e.target.value === "TAG") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);
      let newField = {
        condition: "",
        tagName: "",
        tagType: e.target.value,
        value: "",
        condtionName: "TAG",
        conditionValue: isIsntOption,
        fieldDisplayed: "Yes",
        thirdOptionValues: tags,
      };

      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems(data);
      // enableDisableIOptions(data);
      let mapped = tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (
              item.tagType === "GENDER" ||
              item.tagType === "FIRST_NAME" ||
              item.tagType === "LAST_NAME" ||
              item.tagType === "STATUS"
            ) {
              tagDropDownOptions[index]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
          let tagIndex = tagDropDownOptions
            .map(function (o) {
              return o.value;
            })
            .indexOf(previousLastDropdown[0].tagType);
          if (tagIndex > -1) {
            tagDropDownOptions[tagIndex]["disabled"] = false;
            setTagDropDownOptions(tagDropDownOptions);
          }
        });
      });
    }
    if (e.target.value === "COUNTRY") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);
      let newField = {
        condition: "",
        tagName: "",
        tagType: e.target.value,
        value: "",
        condtionName: "COUNTRY",
        conditionValue: isIsntOption,
        fieldDisplayed: "Yes",
        thirdOptionValues: segmentCountry,
      };
      // setFormItems([...tagAllConditions, newField]);
      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems(data);
      // enableDisableIOptions(data);
      let mapped = tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (
              item.tagType === "GENDER" ||
              item.tagType === "FIRST_NAME" ||
              item.tagType === "LAST_NAME" ||
              item.tagType === "STATUS"
            ) {
              tagDropDownOptions[index]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
          let tagIndex = tagDropDownOptions
            .map(function (o) {
              return o.value;
            })
            .indexOf(previousLastDropdown[0].tagType);
          if (tagIndex > -1) {
            tagDropDownOptions[tagIndex]["disabled"] = false;
            setTagDropDownOptions(tagDropDownOptions);
          }
        });
      });
    }
    if (e.target.value === "CITY") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);
      var newField = {
        condition: "",
        tagName: "",
        tagType: e.target.value,
        value: "",
        condtionName: "CITY",
        conditionValue: isIsntOption,
        fieldDisplayed: "Yes",
        thirdOptionValues: segmentCity,
      };
      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems(data);
      // enableDisableIOptions(data);
      let mapped = tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (
              item.tagType === "GENDER" ||
              item.tagType === "FIRST_NAME" ||
              item.tagType === "LAST_NAME" ||
              item.tagType === "STATUS"
            ) {
              tagDropDownOptions[index]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
          let tagIndex = tagDropDownOptions
            .map(function (o) {
              return o.value;
            })
            .indexOf(previousLastDropdown[0]?.tagType);
          if (tagIndex > -1) {
            tagDropDownOptions[tagIndex]["disabled"] = false;
            setTagDropDownOptions(tagDropDownOptions);
          }
        });
      });
    }
  };

  const getCountry = () => {
    apiGet(
      `/segment/country/${decryptedValue(auth?.companyId)}`,
      onSuccessCountry,
      onFailureCountry
    );
  };
  const onSuccessCountry = (response) => {
    var formattedData = response?.data.map((item) => ({
      name: item,
      value: item,
      selected: false,
    }));
    var selectedOption = [
      {
        name: "Select country",
        value: "",
        selected: true,
        hidden: true,
      },
    ];
    var data = selectedOption.concat(formattedData);
    setSegmentCountry(data);
  };
  const onFailureCountry = (error) => {
    console.log(error);
  };
  const getCity = () => {
    apiGet(
      `/segment/city/${decryptedValue(auth?.companyId)}`,
      onSuccessCity,
      onFailureCity
    );
  };
  const onSuccessCity = (response) => {
    var formattedData = response?.data.map((item) => ({
      name: item,
      value: item,
      selected: false,
    }));
    var selectedOption = [
      {
        name: "Select city",
        value: "",
        selected: true,
        hidden: true,
      },
    ];
    var data = selectedOption.concat(formattedData);
    setSegmentCity(data);
  };
  const onFailureCity = (error) => {
    console.log(error);
  };
  const getTags = () => {
    apiGet(
      `/contact/tags`,
      onSuccessTags,
      onFailureTags
    );
  };
  const onSuccessTags = (response) => {
    var formattedData = response?.data.map((item) => ({
      name: item.tagName,
      value: item.tagName,
      selected: false,
    }));
    var selectedOption = [
      {
        name: "Select tag",
        value: "",
        selected: true,
        hidden: true,
      },
    ];
    var data = selectedOption.concat(formattedData);
    setTags(data);
    setFormItems([
      {
        condition: "",
        tagName: "",
        tagType: "",
        value: "",
        condtionName: "",
        conditionValue: isIsntOption,
        fieldDisplayed: "Yes",
        thirdOptionValues: data,
      },
    ]);
  };
  const onFailureTags = (error) => {
    console.log(error);
  };
  //   useEffect(()=>{
  // if(tags.length>0 && segmentCategory.length>0 && segmentCity.length>0){
  //      setFormItems([
  //       {
  //         condition: "",
  //         tagName: "",
  //         tagType: "",
  //         value: "",
  //         condtionName: "",
  //         conditionValue: isIsntOption,
  //         fieldDisplayed: "Yes",
  //         thirdOptionValues: data,
  //       },
  //     ]);
  // }
  //   },[tags, segmentCategory,segmentCity])
  useEffect(() => {
    getTags();
    getCountry();
    getCity();
    getSegmentCategory();
  }, []);


  return (
    <>
      <div className="createNewSegment">
        <div className="uk-container">
          <div className="backBTn">
            <button
              type="button"
              onClick={() => {
                navigate("/segments", { replace: true });
              }}
            >
              <ChevronLeft /> Back to Segments
            </button>
          </div>
          <div className="mainHeading">
            <h3>Create new segment</h3>
            <p>
              Please provide the details and specific requirements for this
              contact segment.
            </p>
          </div>
          <div className="mainBox">
            <div className="boxHeading">
              <h3>Segment information</h3>
            </div>
            <div className="formwrp">
              <form action="javascript:void(0);">
                <div className="uk-grid uk-grid-small" uk-grid="">
                  <div className="uk-width-1-2">
                    <div className="formInputs">
                      <label htmlFor="name">Segment name</label>
                      <input
                        type="text"
                        className="uk-input"
                        placeholder="Segment name"
                        onChange={(e) =>
                          setData({
                            ...data,
                            segmentName: e.target.value,
                          })
                        }
                      />
                    </div>
                    {errorEnable && data.segmentName === "" && (
                      <div className="formErrors">Segment name is required</div>
                    )}
                  </div>
                  <div className="uk-width-1-2">
                    <div className="formInputs">
                      <label htmlFor="name">Catagory</label>
                      <select
                        name="Catagory"
                        className="uk-select"
                        onChange={(e) =>
                          setData({
                            ...data,
                            segmentCategory: e.target.value,
                          })
                        }
                      >
                        <option value="" selected>
                          Select segment category...
                        </option>
                        {segmentCategory?.map((val, index) => {
                          return (
                            <option key={index} value={val.name}>
                              {val.name}
                            </option>
                          );
                        })}
                      </select>
                      {errorEnable && data.segmentCategory === "" && (
                        <div className="formErrors">
                          Segment category is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="uk-width-1-1">
                    <div className="formInputs">
                      <label htmlFor="desc">
                        Please briefly describe your segment
                      </label>
                      <textarea
                        name="desc"
                        className="uk-textarea"
                        onChange={(e) =>
                          setData({
                            ...data,
                            segmentDescription: e.target.value,
                          })
                        }
                        placeholder="Brief description..."
                      />
                      {errorEnable && data.segmentDescription === "" && (
                        <div className="formErrors">
                          Segment description is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="conditionswrp">
                  <div className="conditionHeading">
                    <h3>Contacts match any of the following conditions</h3>
                  </div>
                  {formItems.map((val, index) => {
                    return (
                      <div
                        className="uk-grid uk-grid-small"
                        uk-grid=""
                        key={index}
                      >
                        <div className="uk-width-1-1">
                          <div className="conditionTxt">
                            <span>Condition {index + 1}</span>
                          </div>
                        </div>
                        <div className="uk-width-1-3 uk-margin-remove-top">
                          <div className="formInputs">
                            <select
                              name="tags"
                              className="uk-select"
                              onChange={(e) => {
                                handleErrors();
                                handleDropDown(index, e);
                              }}
                            >
                              <option value="" selected hidden disabled>
                                Please select a condition
                              </option>
                              {tagDropDownOptions.map((val, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={val.value}
                                    disabled={val.disabled}
                                    hidden={val.hidden}
                                  >
                                    {val.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="uk-width-1-3 uk-margin-remove-top">
                          <div className="formInputs">
                            <select
                              name="condition"
                              className="uk-select"
                              onChange={(e) => {
                                handleFormChange(index, "condition", e);
                                handleErrors();
                              }}
                            >
                              {val.conditionValue.map((valed, ind) => {
                                return (
                                  <option
                                    value={valed.value}
                                    selected={valed.selected}
                                    key={ind}
                                    hidden={valed.hidden}
                                  >
                                    {valed.name}
                                  </option>
                                );
                              })}
                            </select>

                            {val.fieldDisplayed !== "Yes" && (
                              <div
                                className="btnwrp"
                                onClick={() => removeCondition(index)}
                              >
                                {index !== 0 && (
                                  <button type="button" className="dltBtn">
                                    <TrashCan />
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        {val.fieldDisplayed === "Yes" && (
                          <div className="uk-width-1-3 uk-margin-remove-top">
                            <div className="formInputs">
                              <select
                                name="tagName"
                                className="uk-select"
                                onChange={(e) => {
                                  handleFormChange(index, "tagName", e);
                                  handleErrors();
                                }}
                              >
                                {val.thirdOptionValues.map((valeed, indii) => {
                                  return (
                                    <option
                                      value={valeed.value}
                                      selected={valeed.selected}
                                      key={indii}
                                      hidden={valeed.hidden}
                                    >
                                      {valeed.name}
                                    </option>
                                  );
                                })}
                              </select>
                              {index !== 0 && (
                                <div
                                  className="btnwrp"
                                  onClick={() => removeCondition(index)}
                                >
                                  <button type="button" className="dltBtn">
                                    <TrashCan />
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                {(errorEnable && conditionError.length !== 0) ||
                  (errorEnable && tagNameError.length !== 0) ||
                  (errorEnable && tagTypeError.length !== 0) ? (
                    <div className="formErrors">
                      Please select all condition parameters
                    </div>
                  ) : null} 
             
                  
                  <div className="uk-grid uk-grid-small" uk-grid="">
                    <div className="uk-width-1-1">
                      <div className="clearBtn">
                        <button type="button" onClick={addNewCondition}>
                          <Add /> Create new condition
                        </button>
                      </div>
                    </div>
                    <div className="uk-width-1-1">
                      <div className="saveAndCancelBtn">
                        {/* <button type="button" className="btn-1">
                          Cancel
                        </button> */}
                        <button
                          type="button"
                          className="btn-2 w-130"
                          // onClick={handleOpenSaveModal}
                          onClick={addSegment}
                        >
                          {loader ? (
                            <div uk-spinner="" className="loader"></div>
                          ) : (
                            "Add Segment"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {openSaveModal && <SaveModal closeModal={handleCloseSaveModal} />}
      </div>
    </>
  );
};

export default CreateNewSegment;
