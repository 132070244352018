import React from "react";

const Reports = () => {
  return (
    <div className="reports-main">
      <div className="uk-grid uk-flex-middle" uk-grid="">
        <div className="uk-width-1-1">
          <iframe
            src="https://app.powerbi.com/view?r=eyJrIjoiYmFiOTMyOGYtNTU2NC00NWI5LTkzY2UtNGQ4YmYxNTA1N2I5IiwidCI6IjZiNzU5Yzc3LTQ4YTAtNDI3Mi04ZGYwLWM4YTNhMjQxNDE4ZiIsImMiOjl9"
            title="reports"
            width={"100%"}
            height={"100%"}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Reports;
