import React, { useEffect, useState } from "react";
import {
  Edit,
  OverflowMenuVertical,
  TrashCan,
  View,
} from "@carbon/icons-react";
import UIkit from "uikit";
import CalendarDate from "../../components/Calendar/CalendarDate";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import moment from "moment";
import whatsappIcon from "../../assets/images/whatsapp-icon-new.jpg";
import messengerIcon from "../../assets/images/messenger-icon-new.jpg";
import instaIcon from "../../assets/images/instagram-icon-new.jpg";
import CreateChatbotModal from "./modal/CreateChatbotModal";
import { apiDelete, apiGet, apiPost } from "../../Utils/apiServices";
import { toast } from "react-toastify";
import DeleteModal from "../../components/Modal/DeleteModal";
import EditRequestModal from "./modal/EditRequestModal";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";
import ScriptGenerator from "../Webchatbot/ScriptGenerator";
import { channelImages } from "../../Utils/ChannelImages";

const Chatbot = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const CurrentCompanyName = CompanyName(CompanyHtml);
  const [openChatbotModal, setOpenChatbotModal] = useState(false);
  // const [chatbot, setchatbot] = useState(null);
  const [dltLoader, setDltLoader] = useState(false);
  const [loader, setLoader] = useState(true);
  const [openAdminDeleteModal, setOpenAdminDeleteModal] = useState(false);
  const [openViewScriptModal, setOpenViewScriptModal] = useState(false);
  const [opencreateBot, setOpencreateBot] = useState(false);
  const [deletedchatbotPublicId, setDeletedchatbotPublicId] = useState();
  const [channelId, setChannelId] = useState();
  const [scriptView, setScriptView] = useState();
  const [botData, setBotData] = useState();
  const [DateChange, setDateChange] = useState(false);
  const [editRequestModal, setEditRequestModal] = useState(false);
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const handleOpenEditModal = () => {
    setEditRequestModal(true);
  };

  const handleCloseEditModal = () => {
    setEditRequestModal(false);
  };

  const handleOpenChatbotModal = () => {
    setOpenChatbotModal(true);
  };

  const handleCloseChatbotModal = () => {
    setOpenChatbotModal(false);
  };
  const navigate = useNavigate();
  const onChange = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
    setDateChange(true);
  };

  const handleCloseAdminDeleteModal = () => {
    setOpenAdminDeleteModal(false);
  };
  const handlecreatebotModalClose = () => {
    setOpencreateBot(false);
  };
  const handleOpenAdminDeleteModal = (publicId, channelId) => {
    setOpenAdminDeleteModal(true);
    setDeletedchatbotPublicId(publicId);
    setChannelId(channelId);
  };
  const handleOpenViewScriptModal = (publicId) => {
    setOpenViewScriptModal(true);
    setScriptView(publicId);
  };
  const handleCLoseViewScriptModal = (publicId) => {
    setOpenViewScriptModal(false);
    navigate("/chatbot", { replace: true });
  };

  const handleOpenCreateBot = () => {
    setOpenChatbotModal(true);
  };

  const deletechatbot = () => {
    setDltLoader(true);
    if (channelId === "WEBCHATBOT" || channelId === "webchatbot") {
      apiDelete(`/webChatBot/${deletedchatbotPublicId}`, onSuccess, onFailure);
    } else {
      apiDelete(`/bot/${deletedchatbotPublicId}`, onSuccess, onFailure);
    }
  };
  const onSuccess = (response) => {
    if (response.status === 1) {
      setTimeout(() => {
        setDltLoader(false);
        getchatbot();
        toast.success("Chatbot deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1000);
      setTimeout(() => {
        handleCloseAdminDeleteModal();
      }, 2000);
    }
  };
  const onFailure = (error) => {
    console.log(error);
    setDltLoader(false);
  };

  useEffect(() => {
    getchatbot();
  }, []);

  const [chatbot, setchatbot] = useState([]);

  const getchatbot = () => {
    apiGet(
      `/webChatBot/builder/listAllBots`,
      onSuccesschatbot,
      onFailurechatbot
    );
  };
  const onSuccesschatbot = (response) => {
    setchatbot(response?.data);
    setLoader(false);
  };
  const onFailurechatbot = (error) => {
    console.log(error);
    setLoader(true);
  };

  const viewScripData = (botWidgetId) => {
    apiGet(
      `/webChatBot/widget/${botWidgetId}`,
      onSuccessviewScripData,
      onFailureviewScripData
    );
  };
  const onSuccessviewScripData = (data) => {
    setOpenViewScriptModal(true);
    setBotData(data);
  };
  const onFailureviewScripData = (error) => {
    setOpenViewScriptModal(false);
    toast.error("Script is not available");
    console.error("Error fetching data:", error);
  };
  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="chatbotWrp">
        <div className="chatbotHeader">
          <div className="uk-grid uk-flex-middle" uk-grid="">
            <div className="uk-width-1-2">
              <div className="headerHeading">
                <h3>Chatbot</h3>
              </div>
            </div>
            <div className="uk-width-1-2">
              <div className="headerBtn">
                <button
                  type="button"
                  className="btn-1"
                  onClick={() => handleOpenCreateBot()}
                >
                  Create Chatbot
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="broadcastContentWrp">
          <div className="broadcastTabWrp">
            <div className="uk-container">
              <div className="mainHeading">
                <h2>
                  All Chatbots
                  <CurrentCompanyName companyDetails={companyDetails} />
                </h2>
              </div>
              <div className="searchFilterWrp">
                <div className="uk-grid uk-flex-middle" uk-grid="">
                  <div className="uk-width-1-2">
                    <div className="searchFilter">
                      <form
                        action=""
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      ></form>
                    </div>
                  </div>
                  <div className="uk-width-1-2 btnSection">
                    <div className="uk-inline">
                      <div
                        uk-dropdown="mode: click"
                        className="calendarDropdown"
                      >
                        <div className="dropDownHeading">
                          <span>Select a range</span>
                        </div>
                        <ul>
                          <li className="active">
                            <button type="button" className="rangeBtn">
                              <span className="rangeBtnCircle"></span> Last 30
                              days
                            </button>
                          </li>
                          <li>
                            <button type="button" className="rangeBtn">
                              <span className="rangeBtnCircle"></span> This week
                            </button>
                          </li>
                          <li>
                            <button type="button" className="rangeBtn">
                              <span className="rangeBtnCircle"></span> Last week
                            </button>
                          </li>
                          <li>
                            <button type="button" className="rangeBtn">
                              <span className="rangeBtnCircle"></span> This
                              month
                            </button>
                          </li>
                          <hr style={{ margin: "5px 0" }} />
                          <li>
                            <div className="uk-inline uk-text-left">
                              <button className="rangeBtn" type="button">
                                {/* <CalendarHeatMap /> */}
                                <span className="rangeBtnCircle"></span> Custom
                                range
                                {/* <ChevronDown /> */}
                              </button>
                              <div
                                id="datepickerdrop"
                                uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                className="date-picker-container"
                              >
                                <CalendarDate onChange={onChange} />
                                <div className="date-picker-btn-container uk-text-right">
                                  <button className="date-selection-btn">
                                    Ok
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="analyticsTableWrp">
                <div className="uk-grid" uk-grid=""></div>
                <table className="uk-table">
                  <thead>
                    <tr>
                      <th>SERIES</th>
                      <th>
                        <div className="uk-inline tableDropDown">
                          <button type="button">
                            ALL CHANNELS
                            {/* <ChevronDown /> */}
                          </button>
                        </div>
                      </th>
                      <th className="tableCustomWidth">CHATBOT NAME</th>
                      <th>TIGGER</th>
                      <th>STATUS</th>
                      <th>CREATED BY</th>
                    </tr>
                  </thead>
                  <tbody>
                    {chatbot?.length > 0 ? (
                      chatbot?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {item?.channelId !== undefined ? (
                                <img
                                  src={channelImages(item?.channelId)}
                                  alt=""
                                />
                              ) : null}
                            </td>

                            <td className="agnetName">
                              <Link to="">{item.name}</Link>
                            </td>
                            <td>{item.botKeywords || "-"}</td>
                            <td>
                              <span className="serviceInitiated">Active</span>
                            </td>
                            <td className="agnetName">
                              {item.createdBy === null ? "-" : item.createdBy}
                            </td>
                            <td>
                              <div className="uk-inline">
                                <button type="button">
                                  <OverflowMenuVertical />
                                </button>
                                <div uk-dropdown="" className="">
                                  <div className="tableDropDown">
                                    <ul>
                                      <li>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              item.channelId === "whatsapp" ||
                                              item.channelId === "WHATSAPP"
                                            ) {
                                              window.location.href = `/whatsapp/chatbot-builder/${item.botPublicId}`;
                                            } else if (
                                              item.channelId === "facebook" ||
                                              item.channelId === "FACEBOOK"
                                            ) {
                                              window.location.href = `/facebook/chatbot-builder/${item.botPublicId}`;
                                            } else if (
                                              item.channelId === "WEBCHATBOT" ||
                                              item.channelId === "webchatbot"
                                            ) {
                                              window.location.href = `/web/chatbot-builder/${item.botPublicId}`;
                                            }
                                          }}
                                        >
                                          <Edit />{" "}
                                          {item.channelId === "WEBCHATBOT" ||
                                          item.channelId === "webchatbot"
                                            ? "Edit Chatbot"
                                            : "Edit"}
                                        </button>
                                      </li>
                                      <hr />
                                      {item.channelId === "WEBCHATBOT" ? (
                                        <>
                                          <li>
                                            <button
                                              type="button"
                                              onClick={() =>
                                                item.channelId ===
                                                  "webchatbot" ||
                                                item.channelId === "WEBCHATBOT"
                                                  ? (window.location.href = `/webchatbot/builder?webBotWidgetId=${item.webBotWidgetId}`)
                                                  : null
                                              }
                                            >
                                              <Edit /> Edit Widget
                                            </button>
                                          </li>
                                          <hr />
                                          <li>
                                            <button
                                              type="button"
                                              onClick={() =>
                                                viewScripData(
                                                  item.webBotWidgetId
                                                )
                                              }
                                            >
                                              <View /> View Script
                                            </button>
                                          </li>
                                          <hr />
                                        </>
                                      ) : null}
                                      <li>
                                        <button
                                          className="trashBtn"
                                          type="button"
                                          onClick={() =>
                                            handleOpenAdminDeleteModal(
                                              item.botPublicId,
                                              item.channelId
                                            )
                                          }
                                        >
                                          <TrashCan /> Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={11} className="dataNotFound">
                          {loader ? (
                            <div uk-spinner="" className="loader"></div>
                          ) : (
                            "Data Not Found"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* // )} */}
            </div>
          </div>
        </div>
      </div>

      {openChatbotModal && (
        <CreateChatbotModal closeModal={handleCloseChatbotModal} />
      )}

      {openAdminDeleteModal && (
        <DeleteModal
          closeModal={handleCloseAdminDeleteModal}
          dltModalTxt="Are you sure you want to delete this chatbot?"
          deleteTeam={deletechatbot}
          dltLoader={dltLoader}
          heading={"Delete Chatbot"}
        />
      )}

      {openViewScriptModal && (
        <ScriptGenerator
          data={botData}
          closeModal={handleCLoseViewScriptModal}
        />
      )}

      {/* create chatbot */}
      {opencreateBot && (
        <DeleteModal
          closeModal={handlecreatebotModalClose}
          dltModalTxt="Select platform where you want to create chatbot"
          heading={"Create Chatbot Builder"}
        />
      )}
      {editRequestModal && (
        <EditRequestModal closeModal={handleCloseEditModal} />
      )}
    </>
  );
};

export default Chatbot;
