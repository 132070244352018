import React, { useEffect, useState } from "react";
import {
  Add,
  ChatLaunch,
  ChevronLeft,
  Edit,
  OverflowMenuVertical,
} from "@carbon/icons-react";
import AddCondition from "./Modals/AddCondition";
import DeleteSegment from "./Modals/DeleteSegment";
import { useNavigate, useParams } from "react-router-dom";
import { apiGet } from "../../Utils/apiServices";
import { useAuth } from "../../Context/AuthProvider";
import whatsappImg from "../../assets/images/whatsapp-icon-new.jpg";
import messengerIcon from "../../assets/images/messenger-icon-new.jpg";
import instaIcon from "../../assets/images/instagram-icon-new.jpg";
import {
  convertTimeWithDateIntoMinHourDate,
  convertTimestamp,
} from "../../Utils/DateFunctions";
import EditContact from "./Modals/EditContact";
import {
  decryptedValue,
  encryptedValue,
} from "../../Utils/RouteHashConversion";

const WinterShoppers = ({ closeModal, multiSelectTags, formHeading }) => {
  const [showComponent, setShowComponent] = useState(false);
  const { auth } = useAuth();

  const params = useParams();
  const navigate = useNavigate();
  const [openConditionModal, setOpenConditionModal] = useState(false);
  const [openDeleteSegmentModal, setOpenDeleteSegmentModal] = useState(false);
  const [segmentContacts, setSegmentContacts] = useState(null);
  const [segmentTags, setSegmentTags] = useState();
  const [apiCalledWinter, setApiCalledWinter] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [editContactData, setEditContactData] = useState();
  const [openEditContactModal, setOpenEditContactModal] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);

  const handleOpenContactModal = () => {
    setOpenContactModal(true);
  };

  const handleCloseContactModal = () => {
    setOpenContactModal(false);
    setOpenEditContactModal(false);
  };

  const handleOpenConditionsModal = () => {
    setOpenConditionModal(true);
  };

  const handleCloseConditionsModal = () => {
    setOpenConditionModal(false);
  };

  const handleOpenContactEditModal = (data) => {
    setEditContactData(data);
    setOpenEditContactModal(true);
  };

  const handleOpenDeleteSegmentModal = () => {
    setOpenDeleteSegmentModal(true);
  };

  const handleCLoseDeleteSegmentModal = () => {
    setOpenDeleteSegmentModal(false);
  };

  const getSegmentContacts = () => {
    apiGet(
      `/contact/segment/${decryptedValue(params.publicId)}
      `,
      onSuccess,
      onFailure
    );
  };

  const onSuccess = (response) => {
    setApiCalled(false)
    setSegmentContacts(response?.data);
  };
  const onFailure = (error) => {
    console.log(error);
  };

  // useEffect(() => {
  //   if (apiCalled === true) {
  //     getContacts(apiCalled, 0);
  //   }
  //   // eslint-disable-next-line
  // }, [apiCalled]);

  useEffect(() => {
    if (apiCalled === true) {
      getSegmentContacts();
    }
  }, [apiCalled]);

  const getSegmentTags = () => {
    apiGet(
      `/segment/get/${decryptedValue(params.publicId)}
      `,
      onSuccessSegmentTags,
      onFailureSegmentTags
    );
  };

  // const getContacts = (apiCalled, page) => {
  //   if (apiCalled) {
  //     apiGet(
  //       `/contact/${decryptedValue(auth?.companyId)}?page=${page}&size=10`,
  //       onSuccess,
  //       onFailure
  //     );
  //   }
  // };

  const onSuccessSegmentTags = (response) => {
    setSegmentTags(response?.data);
  };
  const onFailureSegmentTags = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getSegmentContacts();
    getSegmentTags();
  }, []);
  useEffect(() => {
    if (apiCalledWinter === true) {
      getSegmentContacts();
      getSegmentTags();
      setApiCalledWinter(false);
    }
  }, [apiCalledWinter]);

  const handleButtonClick = () => {
    // Toggle the state to show/hide the component
    setShowComponent(!showComponent);
  };

  return (
    <div className="createNewSegment wintersShooperwrp">
      <div className="topBtns">
        <div className="uk-grid" uk-grid="">
          <div className="uk-width-1-1">
            <div className="btnwrp">
              {/* <button className="expBtn">
                <Download /> Export Data
              </button> */}
              <button
                className="addBtn"
                type="button"
                onClick={() => {
                  navigate(`/edit-segment/${params.publicId}`, {
                    replace: true,
                  });
                }}
              >
                Update Segment
              </button>
              {/* <div className="uk-inline topBarDropDown">
                <button type="button" className="menuIcon">
                  <OverflowMenuVertical />
                </button>
                <div uk-dropdown="" className="tableDropDown">
                  <ul>
                    <li>
                      <button
                        className="trashBtn"
                        type="button"
                        onClick={handleOpenDeleteSegmentModal}
                      >
                        <TrashCan /> Delete
                      </button>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="uk-container">
        <div className="backBTn">
          <button
            type="button"
            onClick={() => navigate("/segments", { replace: true })}
          >
            <ChevronLeft /> Back to Segments
          </button>
        </div>
        <div className="mainHeading">
          <h3>{segmentTags?.segmentName}</h3>
          <p>Contacts match any of the following conditions </p>
        </div>
        <div className="tagsWrp">
          <ul>
            {segmentTags?.segmentTags?.map((val, index) => {
              return (
                <li key={index}>
                  <span className="textCapatalize">
                    {val.tagName === null
                      ? val.tagType.toLowerCase().replace("_", " ")
                      : val.tagName}
                    {/* <Close /> */}
                  </span>
                </li>
              );
            })}
          </ul>
          <div className="tagBTn">
            <button type="button" onClick={handleOpenConditionsModal}>
              <Add /> Add Condition
            </button>
          </div>
        </div>
        <div className="winterShopperTable">
          <table className="uk-table">
            <thead>
              <tr>
                <th>CHANNEL</th>
                <th>NUMBER</th>
                <th>NAME</th>
                <th>GENDER</th>
                <th>EMAIL</th>
                <th>CITY</th>
                {/* <th>STATUS</th> */}
                <th>CREATED</th>
                <th>LAST CONTACT</th>
              </tr>
            </thead>
            <tbody>
              {segmentContacts?.length > 0 ? (
                segmentContacts?.map((val, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {/* <img src={whatsappIcon} alt="" /> */}
                        {val.channelId !== null ? (
                          <img
                            src={
                              (val.channelId.toLowerCase() === "whatsapp" &&
                                whatsappImg) ||
                              (val.channelId.toLowerCase() === "instagram" &&
                                instaIcon) ||
                              (val.channelId.toLowerCase() === "facebook" &&
                                messengerIcon)
                            }
                            alt=""
                            style={{ borderRadius: "50%" }}
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>{val?.mobileNumber}</td>
                      <td>{val?.firstName + " " + val?.lastName}</td>
                      <td className="textCapatalize">{val?.gender}</td>
                      <td>{val?.email}</td>
                      <td className="textCapatalize">{val?.city}</td>
                      {/* <td>{val?.status}</td> */}
                      <td>
                        {" "}
                        {convertTimeWithDateIntoMinHourDate(
                          val?.createDateTime
                        )}
                      </td>
                      <td>
                        {val?.lastContacted === null ? "-" : val?.lastContacted}
                        {/* : convertTimestamp(val?.lastContacted)} */}
                      </td>
                      <td>
                        <div className="uk-inline">
                          <button type="button">
                            <OverflowMenuVertical />
                          </button>
                          <div uk-dropdown="">
                            <div className="tableDropDown">
                              <ul>
                                <li>
                                  {/* <button
                                    type="button"
                                    onClick={() =>
                                      navigate(`/contact/${val.publicId}`, {
                                        replace: true,
                                      })
                                    }
                                  >
                                    <Edit /> Edit 123
                                  </button>  */}
                                  <button
                                    type="button"
                                    onClick={() => {
                                      handleOpenContactEditModal(val);
                                    }}
                                  >
                                    <Edit /> Edit
                                  </button>
                                </li>
                                {val?.lastContacted ? (
                                  <li>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        const dataToStore = {
                                          mobile: val.mobileNumber,
                                        };

                                        const dataString =
                                          JSON.stringify(dataToStore);

                                        sessionStorage.setItem(
                                          "myData",
                                          dataString
                                        );
                                        const storedDataString =
                                          sessionStorage.getItem("myData");

                                        if (storedDataString) {
                                          const storedData =
                                            JSON.parse(storedDataString);

                                          const mobile = storedData.mobile;
                                          navigate(
                                            `/chat/${encryptedValue(mobile)}`,
                                            {
                                              replace: false,
                                            }
                                          );
                                        }
                                      }}
                                    >
                                      <ChatLaunch /> View chat
                                    </button>
                                  </li>
                                ) : (
                                  ""
                                )}
                                {/* <li>
                                <button type="button">
                                  <Error /> Block contact
                                </button>
                              </li> */}
                                {/* <li>
                                  <button type="button">
                                    <Download /> Download data
                                  </button>
                                </li> */}
                                {/* <hr />
                                <li>
                                  <button className="trashBtn" type="button">
                                    <Error /> Block contact
                                  </button>
                                </li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={9} className="dataNotFound">
                    {segmentContacts === null ? (
                      <div uk-spinner=""></div>
                    ) : (
                      "Data Not Found"
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {openConditionModal && (
        <AddCondition
          segmentTags={segmentTags}
          closeModal={handleCloseConditionsModal}
          setApiCalledWinter={setApiCalledWinter}
        />
      )}
      {openDeleteSegmentModal && (
        <DeleteSegment closeModal={handleCLoseDeleteSegmentModal} />
      )}

      {openEditContactModal && (
        <EditContact
          closeModal={handleCloseContactModal}
          multiSelectTags={multiSelectTags}
          formHeading="Edit Contact"
          setApiCalled={setApiCalled}
          btnText={"Edit Contact"}
          editContactData={editContactData}
        />
      )}
    </div>
  );
};

export default WinterShoppers;
