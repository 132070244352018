import React from "react";
import {
  displayMessageStatusIcon,
  getToolTipPosition,
} from "../../Utils/chat/chatFunctions";
import { formatChatHoverDateTime } from "../../Utils/DateFunctions";
const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;

const ChatImage = ({ data }) => {
  return (
    <>
      {data.messageType === "media" ? (
        <div
          className="txtwrp"
          uk-tooltip={`title: ${formatChatHoverDateTime(
            data.timestamp
          )}; pos: ${getToolTipPosition(data.isSent)}`}
        >
          {(data.isSent == "true" || data.isSent == true) &&
            displayMessageStatusIcon(data.messageStatus)}
          <div
            className="customWidth"
            onClick={() => window.open(`${data?.filePath}`, "_blank")}
          >
            <img
              className="seen-tick"
              src={data?.filePath}
              alt=""
              width={"200px"}
              height={"200px"}
            />
            <div>{data.caption}</div>
          </div>
        </div>
      ) : (
        <div className="txtdiv">
          <div
            className="customWidth"
            onClick={() =>
              window.open(
                `${chatBaseUrl + "/" + data?.filePath.replace("assets/", "")}`,
                "_blank"
              )
            }
          >
            <div
              uk-tooltip={`title: ${formatChatHoverDateTime(
                data.timestamp
              )}; pos: ${getToolTipPosition(data.isSent)}`}
            >
              <img
                className="seen-tick"
                src={chatBaseUrl + "/" + data?.filePath.replace("assets/", "")}
                alt=""
                width={"200px"}
                height={"200px"}
              />
              <div>{data.caption}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatImage;
