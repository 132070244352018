import $ from "jquery";
export const removeAndAddActiveClass = (
  targetedElement,
  parentElement,
  className
) => {
  var elems = document.querySelectorAll(parentElement);
  [].forEach.call(elems, function (el) {
    el.classList.remove(className);
  });
  $(targetedElement).addClass(className);
};
