import React, { useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { apiGet, apiPost } from "../../../Utils/apiServices";
import { useAuth } from "../../../Context/AuthProvider";
import $ from "jquery";
import { useEffect } from "react";
import { decryptedValue } from "../../../Utils/RouteHashConversion";

const FilterModal = ({
  closeModal,
  setApiCalled,
  multiSelectTags,
  setContacts,
  filterModalDescTxt,
  setFilterData,
  filterData,
  setPageCount
}) => {
  const { auth } = useAuth();
  const [loader, setLoader] = useState(false);
  const [dateError, setDateError] = useState("");
  const [error, setError] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [cityArray, setCityArray] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const onSelectTags = (item) => {
    setFilterData({
      ...filterData,
      tags: item,
    });
    setError("");
  };
  const onRemoveTags = (item) => {
    setFilterData({
      ...filterData,
      tags: item,
    });
  };

  useEffect(() => {
    if (filterData?.country !== "") {
      getCountry();
    }
  }, []);

  const handleFromDateErrors = (value) => {
    setError("");
    if (filterData?.toDate === null) {
      setDateError("To Date is Missing");
    }
    if (filterData?.toDate !== null && value !== "") {
      if (value > filterData?.toDate) {
        setDateError("From date must be lesser than to date");
      } else {
        setDateError("");
      }
    }
  };
  const handleToDateErrors = (value) => {
    setError("");
    if (filterData?.fromDate === null) {
      setDateError("From Date is Missing");
    }
    if (filterData?.fromDate !== null && value !== "") {
      if (value < filterData?.fromDate) {
        setDateError("To date must be greater than From date");
      } else {
        setDateError("");
      }
    }
  };
  useEffect(() => {
    if (selectedCountry !== null) {
      getCity();
    }
  }, [selectedCountry]);
  const getCountry = () => {
    apiGet(`/countries/get-all`, onSuccessCountry, onFailureCountry);
  };
  const onSuccessCountry = (response) => {
    setCountryList(response?.data);
  };
  const onFailureCountry = (error) => {
    console.log(error);
  };
  const getCity = () => {
    apiGet(
      `/countries/city/${selectedCountry[0].id}`,
      onSuccessCity,
      onFailureCity
    );
  };
  const onSuccessCity = (response) => {
    setCityArray(response?.data);
  };
  const onFailureCity = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (countryList.length > 0) {
      let data = countryList?.filter((val) => {
        return filterData?.country == val?.name;
      });
      if (data.length > 0) {
        setSelectedCountry(data);
      }
    }
  }, [countryList]);

  const handleCountry = (val) => {
    var data = countryList.filter((filter) => val === filter.name);
    if (data.length > 0) {
      setSelectedCountry(data);
    }
  };

  const searchAndFilter = () => {
    var Errorvariable = "";
    const data = {
      dateStart: filterData.fromDate,
      dateEnd: filterData.toDate,
      country: filterData.country,
      city: filterData.city,
      gender: filterData.gender,
      contactTags: filterData.tags,
      status: filterData.Status,
      searchQuery: filterData.searchQuery,
    };
    if (
      filterData.fromDate === null &&
      filterData.toDate === null &&
      filterData.country === "" &&
      filterData.city === "" &&
      filterData.gender === "" &&
      filterData.tags.length === 0 &&
      filterData.Status === ""
    ) {
      Errorvariable = "Please select parameters for filter";
      setError(Errorvariable);
    }
    if (Errorvariable === "" && dateError === "") {
      setLoader(true);
      apiPost(`/contact/filter`, onSuccess, onFailure, data);
    }
  };
  const onSuccess = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setLoader(false);
        closeModal();
        setPageCount(0);
      }, 2000);
      setTimeout(() => {
        setContacts(response?.data);
      }, 2200);
    }
  };
  const onFailure = (error) => {
    console.log(error);
  };
  const clearFilter = () => {
    $("#fromDate").val("");
    $("#toDate").val("");
    setApiCalled(true);
    setFilterData({
      fromDate: null,
      toDate: null,
      country: "",
      city: "",
      gender: "",
      Status: "",
      countryList: "",
      tags: [],
      searchQuery: "",
    });
  };
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>Search fIlter</span>
          </div>
          <div className="modalForm">
            <div className="formHeading">
              <span>{filterModalDescTxt}</span>
            </div>
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="formInputs">
                    <label htmlFor="from_date">From date</label>
                    <input
                      id="fromDate"
                      type="date"
                      value={filterData.fromDate}
                      onChange={(e) => {
                        setFilterData({
                          ...filterData,
                          fromDate: e.target.value,
                        });
                        handleFromDateErrors(e.target.value);
                      }}
                      max={new Date().toISOString().split("T")[0]}
                      className="uk-input"
                    />
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInputs">
                    <label htmlFor="to_date">To date</label>
                    <input
                      id="toDate"
                      type="date"
                      value={filterData.toDate}
                      onChange={(e) => {
                        setFilterData({
                          ...filterData,
                          toDate: e.target.value,
                        });
                        handleToDateErrors(e.target.value);
                      }}
                      max={new Date().toISOString().split("T")[0]}
                      className="uk-input"
                    />
                  </div>
                </div>
                {dateError !== "" && (
                  <div className="uk-width-1-1 formErrors">{dateError}</div>
                )}
                <div className="uk-width-1-2">
                  <div className="formInputs">
                    <label htmlFor="country">Country</label>
                    <select
                      name="country"
                      value={filterData.country}
                      onClick={() => getCountry()}
                      onChange={(e) => {
                        setFilterData({
                          ...filterData,
                          country: e.target.value,
                        });
                        handleCountry(e.target.value);
                        setError("");
                      }}
                      className="uk-select"
                    >
                      {countryList.length === 0 ? (
                        <>
                          <option
                            value=""
                            selected
                            disabled
                            placeholder="Please select country"
                          >
                            Select a country
                          </option>
                          <option value="">loading ...</option>
                        </>
                      ) : (
                        <option value="" selected disabled>
                          Select a country
                        </option>
                      )}
                      {countryList?.map((val, index) => {
                        return (
                          <option
                            key={index}
                            value={val.name}
                            selected={
                              filterData.country !== ""
                                ? filterData?.country === val.name
                                : ""
                            }
                          >
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="uk-width-1-2">
                  <div className="formInputs">
                    <label htmlFor="city">City</label>
                    <select
                      name="city"
                      value={filterData.city}
                      onChange={(e) => {
                        setFilterData({
                          ...filterData,
                          city: e.target.value,
                        });
                        setError("");
                      }}
                      className="uk-select"
                    >
                      {cityArray === null ? (
                        <>
                          <option
                            value=""
                            selected
                            placeholder="Please select city"
                          >
                            Select a city
                          </option>
                          <option>loading ...</option>
                        </>
                      ) : (
                        <>
                          <option value="" selected>
                            Select a city
                          </option>
                          {cityArray.length > 0 ? (
                            cityArray?.map((val, index) => {
                              return (
                                <option
                                  key={index}
                                  value={val?.name}
                                  selected={
                                    filterData.city !== ""
                                      ? filterData?.city === val.name
                                      : ""
                                  }
                                >
                                  {val?.name}
                                </option>
                              );
                            })
                          ) : (
                            <option value="" disabled>
                              No data found
                            </option>
                          )}
                        </>
                      )}
                    </select>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInputs">
                    <label htmlFor="gender">Gender</label>
                    <select
                      name="gender"
                      value={filterData.gender}
                      onChange={(e) => {
                        // setGender(e.target.value);
                        setFilterData({
                          ...filterData,
                          gender: e.target.value,
                        });
                        setError("");
                      }}
                      className="uk-select"
                    >
                      <option value="" disabled hidden selected>
                        Select a gender
                      </option>
                      <option value="male">Male </option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInputs">
                    <label htmlFor="status">Status</label>
                    <select
                      name="status"
                      value={filterData.Status}
                      onChange={(e) => {
                        // setStatus(e.target.value);
                        setFilterData({
                          ...filterData,
                          Status: e.target.value,
                        });
                        setError("");
                      }}
                      className="uk-select"
                    >
                      <option value="" disabled hidden selected>
                        Select a status
                      </option>
                      <option value="active">Connected</option>
                      <option value="deactivate">Blocked</option>
                    </select>
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="mulitBoxHeading">
                    <span>Search by tags</span>
                  </div>
                  <div className="customChipBox">
                    <Multiselect
                      options={multiSelectTags}
                      onSelect={onSelectTags}
                      onRemove={onRemoveTags}
                      displayValue="name"
                      selectedValues={filterData.tags}
                      closeOnSelect={true}
                    />
                  </div>
                </div>
                {error !== "" && (
                  <div className="uk-width-1-1 formErrors">{error}</div>
                )}
                <div className="uk-width-1-2">
                  <div className="clearFilterBtn">
                    <button type="button" onClick={() => clearFilter()}>
                      Clear filter
                    </button>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="btnwrp">
                    <button
                      type="button"
                      className="btn-1"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn-2 w-90"
                      onClick={searchAndFilter}
                    >
                      {loader ? (
                        <div uk-spinner="" className="loader"></div>
                      ) : (
                        "Search"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
