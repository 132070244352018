import React, { useState } from "react";
import { apiGet, apiPost } from "../../../Utils/apiServices";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../../Context/AuthProvider";
import { decryptedValue } from "../../../Utils/RouteHashConversion";

const NewSmsModal = ({ closeModal, fetchChat, companyDetails }) => {
  const { auth } = useAuth();
  const [loader, setLoader] = useState(false);
  const [errorEnable, setErrorEnable] = useState(false);
  const [masking, setMasking] = useState();
  const [submittedData, setSubmittedData] = useState({
    masking: "",
    number: "",
    message: "",
  });

  const getMasking = () => {
    apiGet(`/sms/getMask`, onSuccessMasking, onFailureMasking);
  };
  const onSuccessMasking = (response) => {
    if (response.status === 0) {
      setMasking(response.data);
    }
  };
  const onFailureMasking = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getMasking();
  }, []);

  const sendMessage = () => {
    setErrorEnable(true);
    setLoader(true);

    let data = {
      phoneNumber: submittedData.number,
      from: companyDetails?.whatsappNumber,
      masking: submittedData.masking,
      textBody: submittedData.message,
    };
    if (
      submittedData.masking !== "" &&
      submittedData.number !== "" &&
      submittedData.message !== ""
    ) {
      apiPost(`/sms/quick-sms`, onSuccess, onFailure, data);
    } else {
      setLoader(false);
    }
  };
  const onSuccess = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setLoader(false);
        toast.success("Quick sms sent successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        fetchChat(decryptedValue(auth.idNumber));
        closeModal();
      }, 1000);
    }
  };
  const onFailure = (error) => {
    console.log(error);
    setLoader(false);
  };

  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>Quick message setup</span>
          </div>
          <div className="modalForm">
            <form action="">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <label htmlFor="from_date">
                      Mobile number(Comma seperated list)
                    </label>
                    <textarea
                      name=""
                      placeholder="92xxxxxxxxxx"
                      value={submittedData.number}
                      className="uk-textarea"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          number: e.target.value,
                        });
                      }}
                      style={{ width: "100%", paddingRight: "80px" }}
                    ></textarea>
                    {errorEnable && submittedData.number == "" && (
                      <div className="formErrors">
                        Number is required for sms
                      </div>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <label htmlFor="from_date">Select SMS Masking</label>
                    <select
                      type="text"
                      className="uk-select"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          masking: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected disabled>
                        Please select masking
                      </option>
                      {masking?.map((val, index) => {
                        return (
                          <option
                            key={index}
                            value={val.mask}
                            disabled={val.isActive === false ? true : false}
                          >
                            {val.mask}
                          </option>
                        );
                      })}
                    </select>
                    {errorEnable && submittedData.masking == "" && (
                      <div className="formErrors">
                        Masking is required for sms
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="uk-width-1-1">
                  <div className="formInputs">
                    <label htmlFor="from_date">Select Template</label>
                    <select
                      type="text"
                      className="uk-select"
                      //   onChange={(e) => {
                      //     setSubmittedData({
                      //       ...submittedData,
                      //       category: e.target.value,
                      //     });
                      //   }}
                      //   value={submittedData.category || ""}
                    >
                      <option value="" disabled>
                        Please select category
                      </option>
                      {broadcastCategory.map((val, index) => {
                                return (
                                  <option key={index} value={val}>
                                    {val}
                                  </option>
                                );
                              })}
                    </select>
                  </div>
                </div> */}

                {/* <div className="uk-width-1-1">OR</div> */}
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <label htmlFor="from_date">Type message</label>
                    <textarea
                      name=""
                      placeholder=""
                      value={submittedData.message}
                      className="uk-textarea"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          message: e.target.value,
                        });
                      }}
                      style={{ width: "100%", paddingRight: "80px" }}
                    ></textarea>
                    {errorEnable && submittedData.message == "" && (
                      <div className="formErrors">
                        Messages is required for sms
                      </div>
                    )}
                  </div>
                </div>

                <div className="uk-width-1-1">
                  <div className="btnwrp">
                    <button
                      type="button"
                      className="btn-1"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn-2 w-90"
                      onClick={sendMessage}
                    >
                      {loader ? (
                        <div uk-spinner="" className="loader"></div>
                      ) : (
                        "Send"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSmsModal;
