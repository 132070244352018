import React from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { apiDelete, apiGet, apiPost } from "../../Utils/apiServices";
import { useState } from "react";
import { useAuth } from "../../Context/AuthProvider";
import { useEffect } from "react";
import DeleteModal from "./components/Modal/DeleteModal";
import { toast } from "react-toastify";
import Switch from "react-switch";
import { Edit, OverflowMenuVertical, TrashCan } from "@carbon/icons-react";
import { convertTimeWithDateIntoMinHourDate } from "../../Utils/DateFunctions";
import {
  decryptedValue,
  encryptedValue,
} from "../../Utils/RouteHashConversion";
import { debounce } from "lodash";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";

const Teams = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const { auth } = useAuth();
  const navigate = useNavigate();
  // const [checked, setChecked] = useState(false);
  const [teams, setTeams] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [dltLoader, setDltLoader] = useState(false);
  const [deletedTeamPublicId, setDeletedTeamPublicId] = useState();
  const [searchQuery, setSeachQuery] = useState("");

  //delete modal
  const handleOpenDeleteModal = (publicId) => {
    setOpenDeleteModal(true);
    setDeletedTeamPublicId(publicId);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onChange = (e) => {
    setTeams(null);
    var searchedValue = e.target.value;
    setSeachQuery(searchedValue);
  };

  const debouncedOnChange = debounce(onChange, 1000);

  useEffect(() => {
    getTeam();
  }, [searchQuery]);

  // const handleChecked = (e, id) => {

  //   // setChecked(!checked);
  //   setChecked(e);
  //   enableTeam(id);
  // };
  const getTeam = () => {
    apiGet(
      `/agent/team/list?searchQuery=${searchQuery}`,
      onSuccessTeam,
      onFailureTeam
    );
  };
  const onSuccessTeam = (response) => {
    setTeams(response?.data);
  };
  const onFailureTeam = (error) => {
    console.log(error);
  };

  const deleteTeam = () => {
    setDltLoader(true);
    apiDelete(`/agent/team/${deletedTeamPublicId}`, onSuccess, onFailure);
  };
  const onSuccess = (response) => {
    if (response?.data === true) {
      setTimeout(() => {
        setDltLoader(false);
        toast.success("Team deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1200);
      setTimeout(() => {
        getTeam();
        handleCloseDeleteModal();
      }, 2000);
    }
  };
  const onFailure = (error) => {
    console.log(error);
    setDltLoader(false);
  };

  // const enableTeam = (id) => {
  //   apiPost(`/agent/team/${id}`, onSuccessEnable, onFailureEnable);
  // };
  // const onSuccessEnable = (response) => {
  //   if (response?.data === true) {
  //     toast.success("Team enabled successfully", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 1000,
  //     });

  //     // setChecked(!checked)
  //     getTeam();
  //   }
  // };
  // const onFailureEnable = (error) => {
  // };
  useEffect(() => {
    getTeam();
  }, []);

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="userWrp">
        <div className="userHeader">
          <div className="uk-grid uk-flex-middle" uk-grid="">
            <div className="uk-width-1-3">
              <div className="headerTabs">
                <ul
                  className="uk-subnav uk-subnav-pill"
                  uk-switcher="connect: .userTabbing"
                >
                  <li
                    onClick={() => {
                      navigate("/users", { replace: true });
                    }}
                  >
                    <a>Users</a>
                  </li>
                  <li
                    className="uk-active"
                    onClick={() => {
                      navigate("/teams", { replace: true });
                    }}
                  >
                    <a>Teams</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="uk-width-1-3">
              <div className="headerSearch">
                <form
                  action=""
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search team through name"
                    onChange={debouncedOnChange}
                  />
                </form>
              </div>
            </div>
            <div className="uk-width-1-3">
              <ul className="uk-switcher uk-margin userTabbing">
                <li>
                  <div className="headerBtn">
                    <Link to="/add-user">Add User</Link>
                  </div>
                </li>
                <li>
                  <div className="headerBtn">
                    <Link to="/create-team">Add Team</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="userTabContent">
          <div className="uk-container uk-container-large">
            <ul className="uk-switcher uk-margin userTabbing">
              <li></li>
              <li>
                <div className="userTableWrp">
                  <table className="uk-table">
                    <thead>
                      <tr>
                        <th>NAME</th>
                        <th>USERS</th>
                        <th>DESCRIPTION</th>
                        {/* <th>ENABLED TEAM</th> */}
                        <th>CREATED</th>
                      </tr>
                    </thead>
                    <tbody>
                      {teams?.length > 0 ? (
                        teams?.map((val, index) => {
                          return (
                            <tr key={index}>
                              <td className="agnetName">
                                <Link
                                  to={`/team-detail/${encryptedValue(
                                    val?.publicId
                                  )}`}
                                >
                                  {/* <img src={avatarImg} alt="" /> */}

                                  <span className="InitialImage">
                                    {val.name.substring(0, 1)}
                                  </span>
                                  {val.name}
                                </Link>
                              </td>
                              <td>{val.users}</td>
                              <td>
                                {val?.description === ""
                                  ? "-"
                                  : val?.description}
                              </td>
                              {/* <td className="tableSwitch">
                                <Switch
                                  onChange={() => {
                                    handleChecked(val?.isActive, val.publicId);
                                  }}
                                  // disabled
                                  // onChange={""}
                                  checked={
                                    (val?.isActive === true) === true
                                      ? true
                                      : false
                                  }
                                  // checked={checked}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  width={26}
                                  height={14}
                                  handleDiameter={10}
                                  onColor="#29A869"
                                />
                              </td> */}
                              <td>
                                {convertTimeWithDateIntoMinHourDate(
                                  val?.createDateTime
                                )}
                              </td>
                              <td>
                                <div className="uk-inline">
                                  <button type="button">
                                    <OverflowMenuVertical />
                                  </button>
                                  <div
                                    uk-dropdown=""
                                    className="contactBlckDrop"
                                  >
                                    <ul>
                                      <li>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            navigate(
                                              `/team-detail/${encryptedValue(
                                                val.publicId
                                              )}`,
                                              {
                                                replace: true,
                                              }
                                            )
                                          }
                                        >
                                          <Edit /> Edit
                                        </button>
                                      </li>
                                      {/* <li>
                                          <button type="button">
                                            <Download /> Download data
                                          </button>
                                        </li> */}
                                      {/* <hr />
                                        <li>
                                          <button
                                            className="trashBtn"
                                            type="button"
                                            onClick={() =>
                                              handleOpenDeleteModal(
                                                val?.publicId
                                              )
                                            }
                                          >
                                            <TrashCan /> Delete
                                          </button>
                                        </li> */}
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={5} className="dataNotFound">
                            {teams === null ? (
                              <div uk-spinner=""></div>
                            ) : (
                              "Data Not Found"
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {openDeleteModal && (
                    <DeleteModal
                      closeModal={handleCloseDeleteModal}
                      dltModalTxt="This action will impact members of the selected teams."
                      deleteTeam={deleteTeam}
                      dltLoader={dltLoader}
                      heading={"Delete Team"}
                    />
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Teams;
