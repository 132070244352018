import {
  ChatLaunch,
  ChevronDown,
  Close,
  Error,
  GenderFemale,
  MobileCheck,
  OverflowMenuHorizontal,
  Time,
  Touch_1,
  TrashCan,
} from "@carbon/icons-react";
import React, { useEffect, useState } from "react";
import whatsappLogo from "../../../assets/images/whatsapp-icon-new.jpg";
import messengerIcon from "../../../assets/images/messenger-icon-new.jpg";
import instaIcon from "../../../assets/images/instagram-icon-new.jpg";
import {
  contactCreated,
  contactTime,
  profileDateTime,
} from "../../../Utils/DateFunctions";
import { useNavigate } from "react-router-dom";
import {
  decryptedValue,
  encryptedValue,
} from "../../../Utils/RouteHashConversion";
import { useAuth } from "../../../Context/AuthProvider";

const AgentDetailModal = ({ closeModal, contactProfile }) => {
  const { auth } = useAuth();
  const [details, setDetails] = useState({
    number: null,
    channelId: null,
    agentName: null,
    agentId: null,
    publicId: null,
    companyId: null,
  });
  useEffect(() => {
    if (contactProfile !== undefined) {
      setTimeout(() => {
        setDetails({
          number: contactProfile?.conversation?.number,
          channelId: contactProfile?.contact?.channelId,
          agentName:
            contactProfile?.conversation?.agentName == ""
              ? "NA"
              : contactProfile?.conversation?.agentName,
          agentId:
            contactProfile?.conversation?.agentId == ""
              ? "NA"
              : contactProfile?.conversation?.agentId,
          publicId: contactProfile?.conversation?.publicId,
          companyId: contactProfile?.contact?.companyId,
        });
      }, 1500);
    }
  }, [contactProfile]);

  const navigate = useNavigate();
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        {details.number === null ? (
          <div className="dataNotFound">
            {/* {details === null ? <div uk-spinner=""></div> : "Data Not Found"} */}
            <div uk-spinner=""></div>
          </div>
        ) : (
          <div className="filterModalContent">
            <div className="modalHeading">
              <div className="left">
                <span>Contact profile</span>
              </div>
              <div className="rightDropdown">
                <div className="uk-inline detailDropDown">
                  <button type="button">
                    <OverflowMenuHorizontal />
                  </button>
                  <div uk-dropdown="" className="dropdownInner">
                    <ul>
                      {/* <li>
                      <button type="button">
                        <Edit />
                        Edit
                      </button>
                    </li> */}
                      <li>
                        {contactProfile?.conversation === null ? (
                          <button
                            type="button"
                            disabled={true}
                            uk-tooltip={`title: no conversation found aginst contact}; pos: right`}
                          >
                            <ChatLaunch />
                            View chat
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() =>
                              navigate(
                                `/chat/${encryptedValue(details.number)}`,
                                // {
                                //   replace: true,
                                // },
                                {
                                  state: details,
                                }
                              )
                            }
                          >
                            <ChatLaunch />
                            View chat
                          </button>
                        )}
                      </li>
                      {/* <li>
                        <button type="button">
                          <Error />
                          Block contact
                        </button>
                      </li> */}
                      {/* <li>
                      <button type="button">
                        <Download />
                        Download data
                      </button>
                    </li> */}
                      {/* <hr /> */}
                      {/* <li className="dropdownDltBtn">
                        <button type="button">
                          <TrashCan />
                          Delete
                        </button>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="closeBtn">
                  <button
                    type="button"
                    onClick={() => {
                      closeModal();
                      setDetails({
                        number: null,
                        channelId: null,
                        agentName: null,
                        agentId: null,
                        publicId: null,
                        companyId: null,
                      });
                    }}
                  >
                    <Close />
                  </button>
                </div>
              </div>
            </div>
            <div className="modalForm contactDetailModal">
              <div className="uk-grid" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="contactAgentName">
                    <h4>
                      {contactProfile?.contact?.firstName +
                        " " +
                        contactProfile?.contact?.lastName}
                    </h4>
                    <p>{contactProfile?.contact?.email}</p>
                    <ul>
                      {contactProfile?.contact.channelId === "whatsapp" && (
                        <li>
                          <img src={whatsappLogo} alt="" />
                        </li>
                      )}
                      {contactProfile?.contact.channelId === "facebook" && (
                        <li>
                          <img src={messengerIcon} alt="" />
                        </li>
                      )}
                      {contactProfile?.contact.channelId === "instagram" && (
                        <li>
                          <img src={instaIcon} alt="" />
                        </li>
                      )}
                    </ul>
                  </div>
                  <hr />
                  <div className="contactDetailList">
                    <ul>
                      <li>
                        <span>
                          <MobileCheck />
                          {contactProfile?.contact?.mobileNumber}
                        </span>
                      </li>
                      {contactProfile?.contact?.gender !== null && (
                        <li>
                          <span>
                            <GenderFemale />
                            {contactProfile?.contact?.gender}
                          </span>
                        </li>
                      )}
                      <li>
                        <span>
                          <Time />
                          Contact Time:{" "}
                          {contactProfile?.conversation
                            ? contactProfile?.contact?.updateDateTime
                            : "-"}
                        </span>
                      </li>
                      <li>
                        <span>
                          <Time />
                          Created:{" "}
                          {contactCreated(
                            contactProfile?.contact?.createDateTime
                          )}
                        </span>
                      </li>
                      {/* <li>
                      <span>
                        <MobileCheck />
                        5947368681956251
                      </span>
                    </li> */}
                    </ul>
                  </div>
                  <hr />
                  <div className="contactAddress">
                    <p>Address:</p>
                    <p>
                      {contactProfile?.contact?.address === null
                        ? "-"
                        : contactProfile?.contact?.address}
                    </p>
                  </div>
                  <div className="contactAgentName">
                    <p>Agents:</p>
                    <p>
                      {" "}
                      {contactProfile?.conversation?.agentName === "" || null
                        ? "-"
                        : contactProfile?.conversation?.agentName}
                    </p>
                  </div>
                </div>
                <div className="uk-width-1-2 contactModalRight">
                  <div className="contactNotes">
                    <div className="notesHeading">
                      <span>Notes</span>
                    </div>
                    <div className="notesList" id="toggle-usage">
                      <ul>
                        {contactProfile?.notes.map((val, index) => {
                          return (
                            <li key={index}>
                              <div className="note-text">
                                <p>{val.note}</p>
                              </div>
                              <div className="note-time">
                                <span>
                                  {profileDateTime(val.createDateTime)}
                                </span>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {contactProfile?.notes.length > 0 && (
                      <button
                        className="toggleBtn"
                        type="button"
                        uk-toggle="target: #toggle-usage"
                      >
                        Show all <ChevronDown />
                      </button>
                    )}
                  </div>
                  <hr />
                  <div className="contactTags">
                    <div className="contactHeadTop">
                      <div className="contactHeadHeding">
                        <span>Contact Tags</span>
                      </div>
                      {/* <div className="contactAddtagBtn">
                      <button type="button">
                        <Add /> Add Tag
                      </button>
                    </div> */}
                    </div>
                    <div className="contactTagsList">
                      <ul>
                        {contactProfile?.contactTags.map((val, index) => {
                          return (
                            <li key={index}>
                              <span>
                                {val.text}{" "}
                                <span uk-icon="icon: close; ratio: 0.8"></span>
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <hr />
                  <div className="contactOpted">
                    <div className="notesHeading">
                      <span>Opted in through</span>
                    </div>
                    <div className="optedBtn">
                      <button type="button" style={{ cursor: "unset" }}>
                        <Touch_1 />
                        Get Started
                      </button>
                    </div>
                  </div>
                  {/* <hr /> */}
                  {/* <div className="contactCustomFields">
                  <div className="contactHeadTop">
                    <div className="contactHeadHeding">
                      <span>Custom Fields</span>
                    </div>
                    <div className="contactAddtagBtn">
                      <button type="button">Manage</button>
                    </div>
                  </div>
                  <div className="customFieldsWrp">
                    <ul>
                      <li>
                        <span>email_address: naveenasim@gmail.com</span>
                      </li>
                      <li>
                        <span>phone_number: 03323456789012</span>
                      </li>
                    </ul>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AgentDetailModal;
