import { ChevronRight, Download } from "@carbon/icons-react";
import React from "react";
import DoughnutChart from "../../components/Graph/DoughnutChart";
import { useEffect } from "react";
import { useParams } from "react-router";
import { apiGet } from "../../Utils/apiServices";
import { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { decryptedValue } from "../../Utils/RouteHashConversion";

const BroadcastAnalyticsPreview = () => {
  const params = useParams();
  const [broadcastPreviewDetails, setBroadcastPreviewDetails] = useState();
  const getBroadcastPreviewDetails = () => {
    apiGet(
      `/broadcast/get/${decryptedValue(params.publicId)}`,
      onSuccessBroadcastPreviewDetails,
      onFailureBroadcastPreviewDetails
    );
  };
  const onSuccessBroadcastPreviewDetails = (response) => {
    setBroadcastPreviewDetails(response?.data);
  };
  const onFailureBroadcastPreviewDetails = (error) => {
    console.log(error);
  };
  useEffect(() => {
    getBroadcastPreviewDetails();
  }, []);

  return (
    <>
      <div className="createNewSegment wintersShooperwrp">
        <div className="topBtns">
          <div className="uk-grid" uk-grid="">
            <div className="uk-width-1-1">
              <div className="btnwrp">
                {/* <div className="uk-inline topBarDropDown">
                  <button type="button" className="menuIcon">
                    <OverflowMenuVertical />
                  </button>
                  <div uk-dropdown="" className="tableDropDown">
                    <ul>
                      <li>
                        <button className="trashBtn" type="button">
                          <TrashCan /> Delete
                        </button>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="uk-container">
          <div className="backBTn">
            <ul>
              <li>
                <Link to="/broadcast">
                  <span>Broadcast</span>
                </Link>
              </li>
              <li>
                <ChevronRight />
              </li>
              <li>
                <span className="currentPage">
                  {broadcastPreviewDetails?.name}
                </span>
              </li>
            </ul>
          </div>
          <div className="mainHeading">
            <h3>{broadcastPreviewDetails?.name}</h3>
          </div>
          <div className="tagsWrp">
            <ul>
              <li>
                {broadcastPreviewDetails ? (
                  <span className="completeTag redCancel">
                    {broadcastPreviewDetails?.status}
                  </span>
                ) : (
                  ""
                )}
              </li>
              <li>
                <span>
                  WhatsApp sent to {broadcastPreviewDetails?.totalTraffic}{" "}
                  Contacts
                </span>
              </li>
            </ul>
          </div>
          <div className="broadcastContentWrapper">
            <div className="broadcastTabbing">
              <ul uk-tab="connect: #broadcastInnerTab">
                <li>
                  <a href="#">Preview</a>
                </li>
                {/* <li>
                  <a disabled>Analytics</a>
                </li> */}
              </ul>

              <div className="broadcastTabContent">
                <ul className="uk-switcher uk-margin" id="broadcastInnerTab">
                  <li>
                    <div className="broadcastPreviewTab overviewContent">
                      <div className="mainBox">
                        <div className="boxHeading">
                          <span>Broadcast preview</span>
                        </div>
                        <div className="boxContent">
                          <div
                            className="mobilePreview"
                            style={{ margin: "0 auto" }}
                          >
                            <div className="mobileMsgs">
                              <div className="msgDate">
                                <span>
                                  {moment(
                                    broadcastPreviewDetails?.startDate
                                  ).format("dddd, MMMM YYYY")}
                                </span>
                              </div>
                              {broadcastPreviewDetails?.sendTemplateRequest
                                ?.template?.components !== undefined &&
                                broadcastPreviewDetails?.sendTemplateRequest?.template?.components.map(
                                  (val, index) => {
                                    return (
                                      <div key={index}>
                                        {val.type === "header" &&
                                          val.parameters.map((item, i) => {
                                            return item.type === "image" ? (
                                              <div className="imgBox" key={i}>
                                                <img
                                                  src={item.image.link}
                                                  alt=""
                                                />
                                              </div>
                                            ) : (
                                              item.type === "text" && (
                                                <div
                                                  className="molbileMsgBoxGrey"
                                                  key={i}
                                                >
                                                  <span>{item.text}</span>
                                                </div>
                                              )
                                            );
                                          })}
                                        {val.type === "body" &&
                                          val.parameters.map((item, i) => {
                                            return (
                                              <div
                                                className="molbileMsgBoxGrey"
                                                key={i}
                                              >
                                                <span>{item.text}</span>
                                              </div>
                                            );
                                          })}
                                        {val.type === "button" &&
                                          val.parameters.map((item, i) => {
                                            return (
                                              <div
                                                className="molbileMsgBoxGrey"
                                                key={i}
                                              >
                                                <button>{item.payload}</button>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BroadcastAnalyticsPreview;
