import React from "react";

const PauseAutomationModal = ({
  closeModal,
  heading,
  fieldLabel,
  saveBtnTxt,
}) => {
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open pauseModal"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <h3>{heading}</h3>
        <div>
          <form className="uk-form-stacked">
            <div>
              <label className="uk-form-label customLabel">{fieldLabel}</label>
            </div>
            <div className="uk-grid uk-grid-small" uk-grid="">
              <div className="uk-width-1-2">
                <select className="modalDropdown">
                  <option value="40">30</option>
                  <option value="40">40</option>
                </select>
              </div>
              <div className="uk-width-1-2">
                <select className="modalDropdown">
                  <option value="Minutes">Minutes</option>
                  <option value="Hours">Hours</option>
                </select>
              </div>
            </div>
            <div className="btnWrapSpaceBetween pauseBtnSection uk-margin-medium-top">
              <div>
                <button className="clearBtn">Clear filter</button>
              </div>
              <div>
                <button className="cnclBtn" onClick={closeModal}>
                  Cancel
                </button>
                <button className="saveBtn">{saveBtnTxt}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PauseAutomationModal;
