import React from "react";

const OrganizationNumerModal = ({ closeModal }) => {
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>Enter organization mobile phone</span>
          </div>
          <div className="modalForm">
            <div className="modalSmallTxt">
              <span>
                We’ll text you a one-time verification code to confirm your
                number.
              </span>
            </div>
            <form action="">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <select name="" className="uk-select">
                      <option value="">+ 92</option>
                    </select>
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <input type="text" className="uk-input" />
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="modalSmallTxt">
                    <span>
                      By providing my phone number, I agree to receive text
                      messages from BerryTalks. Standard message and data rates
                      apply.
                    </span>
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="btnwrp">
                    <button
                      type="button"
                      className="btn-1"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button type="button" className="btn-2">
                      Change
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationNumerModal;
