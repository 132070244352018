import React, { useState } from "react";
import { Analytics, Calendar, UserMultiple } from "@carbon/icons-react";
import { useEffect } from "react";
import { apiGet } from "../../Utils/apiServices";
import moment from "moment/moment";
import { toast } from "react-toastify";

const LorealStats = () => {
  const [lorealStatsData, setLorealStatsData] = useState();
  const currentDate = moment().format("YYYY-MM-DD");
  const [STARTDATE, setSTARTDATE] = useState("");
  const [ENDDATE, setENDDATE] = useState(currentDate);

  // const getLorealStats = () => {
  //   var api = "";
  //   if (STARTDATE !== "" && ENDDATE !== "") {
  //     api = `/company/lorealStats/?startDate=${STARTDATE}&&?endDate=${ENDDATE}`;
  //   } else {
  //     api = `/company/lorealStats`;
  //   }

  //   apiGet(api, onSuccessLorealStats, onFailueLorealStats);
  // };

  const getLorealStats = () => {
    let api = "/company/lorealStats";
    if (STARTDATE && ENDDATE) {
      api += `?startDate=${STARTDATE}&endDate=${ENDDATE}`;
    }

    apiGet(api, onSuccessLorealStats, onFailueLorealStats);
  };

  const onSuccessLorealStats = (response) => {
    setLorealStatsData(response.data);
  };

  const onFailueLorealStats = (error) => {
    console.log(error);
  };

  const handleStartDateChange = (e) => {
    if (moment(e.target.value).isAfter(currentDate)) {
      setSTARTDATE("");
      return;
    }
    setSTARTDATE(e.target.value);
  };

  const handleEndDateChange = (e) => {
    if (moment(e.target.value).isAfter(currentDate)) {
      setENDDATE("");
      return;
    }
    setENDDATE(e.target.value);
  };

  const handleClearFiler = () => {
    setSTARTDATE(""); 
    setENDDATE(""); 
  };

  useEffect(() => {
    getLorealStats();
  }, []);
  return (
    <>
      <div className="lorealStatsWrp">
        <div className="uk-container">
          <div className="uk-grid uk-grid-small" uk-grid="">
            <div className="uk-width-1-1">
              <div className="mainHeading">
                <h3>WhatsApp Stats</h3>
              </div>
            </div>
            <div className="uk-width-1-1">
              <div className="filterWrp">
                <div
                  className="uk-grid uk-grid-small uk-flex-right uk-flex-middle"
                  uk-grid=""
                >
                  <div className="uk-width-auto">
                    <label htmlFor="">From Date</label>
                    <input
                      id="startDate"
                      className="uk-form-width-medium uk-input"
                      type="date"
                      value={STARTDATE}
                      onChange={handleStartDateChange}
                    />
                  </div>
                  <div className="uk-width-auto">
                    <label htmlFor="">To Date</label>
                    <input
                      id="endDate"
                      className="uk-form-width-medium uk-input"
                      type="date"
                      value={ENDDATE}
                      onChange={handleEndDateChange}
                    />
                  </div>
                  <div className="uk-width-auto">
                    <button
                      type="button"
                      className="btn-1"
                      onClick={getLorealStats}
                      disabled={!STARTDATE || !ENDDATE ? true : false}
                    >
                      Search
                    </button>
                  </div>
                  <div className="uk-width-auto">
                    <button type="button" className="btn-2" onClick={handleClearFiler}>
                      Clear
                    </button>
                  </div>
                </div>
                 {/* {error && <div className="error">{error}</div>} */}
              </div>
            </div>
            <div className="uk-width-1-1">
              <div className="cardWrp">
                <div className="cardSecHeading">
                  <p>WhatsApp Stats</p>
                </div>
                <div className="uk-grid uk-grid-small" uk-grid="">
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <Calendar />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>
                            {lorealStatsData?.highestTraffic === null
                              ? ""
                              : lorealStatsData?.highestTraffic}
                          </h3>
                          <p>Highest Traffic - Day</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{lorealStatsData?.totalEntries}</h3>
                          <p>Total WhatsApp Entries Received</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{lorealStatsData?.totalCompletedEnglish}</h3>
                          <p>All complete journey english</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{lorealStatsData?.total75JourneyEN}</h3>
                          <p>75% complete english</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{lorealStatsData?.total50JourneyEN}</h3>
                          <p>50% complete english</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{lorealStatsData?.total25JourneyEN}</h3>
                          <p>25% complete english</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{lorealStatsData?.total0JourneyEN}</h3>
                          <p>Below 25% english</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{lorealStatsData?.totalCompletedUrdu}</h3>
                          <p>All complete journey urdu</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{lorealStatsData?.total75JourneyUR}</h3>
                          <p>75% complete urdu</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{lorealStatsData?.total50JourneyUR}</h3>
                          <p>50% complete urdu</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{lorealStatsData?.total25JourneyUR}</h3>
                          <p>25% complete urdu</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{lorealStatsData?.total0JourneyUR}</h3>
                          <p>Below 25% urdu</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <Analytics />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{lorealStatsData?.totalUniqueEntries}</h3>
                          <p>Total WhatsApp Unique Consumers</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>
                            {
                              lorealStatsData?.totalUniqueEnglishCompletedJourney
                            }
                          </h3>
                          <p>All complete journey unique english</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>
                            {lorealStatsData?.totalUniqueUrduCompletedJourney}
                          </h3>
                          <p>All complete journey unique urdu</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LorealStats;
