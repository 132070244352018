import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { Link, useNavigate } from "react-router-dom";
import {
  Download,
  Edit,
  OverflowMenuVertical,
  TrashCan,
} from "@carbon/icons-react";
import DeleteModal from "../../../components/Modal/DeleteModal";
import { apiDelete, apiGet, apiPost } from "../../../Utils/apiServices";
import { dataTime } from "../../../Utils/DateFunctions";
import { toast } from "react-toastify";

const CompanyList = () => {
  const navigate = useNavigate();
  const [openAdminDeleteModal, setOpenAdminDeleteModal] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(null);
  const [filterData, setfilterData] = useState(null);
  const [dltLoader, setDltLoader] = useState(false);
  const [deleteUserPublicId, setDeleteUserPublicId] = useState("");

  const handleChecked = (e, id) => {
    enableUser(id);
  };
  const handleOpenAdminDeleteModal = (publicId) => {
    setDeleteUserPublicId(publicId);
    setOpenAdminDeleteModal(true);
  };

  const enableUser = (id) => {
    apiPost(`/super-admin/disable/${id}`, onSuccessEnable, onFailureEnable);
  };
  const onSuccessEnable = (response) => {
    if (response?.status === 0) {
      setSuperAdmin(response.data);
      setTimeout(() => {
        toast.success("User status updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1200);
      getSuperAdminList();
    }
  };
  const onFailureEnable = (error) => {
    console.log(error);
  };

  const deleteUser = () => {
    setDltLoader(true);
    apiDelete(
      `/super-admin/delete/${deleteUserPublicId}`,
      onSuccess,
      onFailure
    );
  };
  const onSuccess = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setDltLoader(false);
        getSuperAdminList();
        toast.success("User deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1200);
      setTimeout(() => {
        handleCloseAdminDeleteModal();
      }, 2000);
    }
  };
  const onFailure = (error) => {
    console.log(error);
    setDltLoader(false);
  };

  const handleCloseAdminDeleteModal = () => {
    setOpenAdminDeleteModal(false);
  };

  const getSuperAdminList = () => {
    apiGet(`/super-admin/getSuperAdminList`, onSuccessStats, onFailureStats);
  };
  const onSuccessStats = (response) => {
    setSuperAdmin(response.data);
    setfilterData(response.data);
  };
  const onFailureStats = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getSuperAdminList();
  }, []);

  // useEffect(() => {
  //   enableUser();
  // }, [superAdmin]);

  const handleSearchChange = (e) => {
    var value = e.target.value;
    const results = superAdmin.filter((user) => {
      return (
        user.email.toLowerCase().includes(value.toLowerCase()) ||
        user.phoneNumber.includes(value) ||
        user.name.includes(value) // You can add more conditions as needed
      );
    });
    setfilterData(results);
  };
  return (
    <>
      <div className="userWrp">
        <div className="userHeader">
          <div className="uk-grid uk-flex-middle" uk-grid="">
            <div className="uk-width-1-3">
              <div className="headerTabs">
                <ul
                  className="uk-subnav uk-subnav-pill"
                  uk-switcher="connect: .userTabbing"
                >
                  <li
                    onClick={() => {
                      navigate("/super-admin-users", { replace: true });
                    }}
                  >
                    <a>Super Admins</a>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/super-admin-company", { replace: true });
                    }}
                  >
                    <a>Companies</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="uk-width-1-3">
              <ul className="uk-switcher uk-margin userTabbing">
                <li>
                  <div className="headerSearch">
                    <form
                      action=""
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Search admins through name, company, number or email"
                        onChange={(e) => handleSearchChange(e)}
                      />
                    </form>
                  </div>
                </li>
                <li></li>
              </ul>
            </div>
            <div className="uk-width-1-3">
              <ul className="uk-switcher uk-margin userTabbing">
                <li>
                  <div className="headerBtn">
                    <Link to="/super-admin-create-super-admin">
                      Add super admin
                    </Link>
                  </div>
                </li>
                <li></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="userTabContent">
          <div className="uk-container uk-container-large">
            <ul className="uk-switcher uk-margin userTabbing">
              <li>
                <div className="userTableWrp">
                  <table className="uk-table">
                    <thead>
                      <tr>
                        <th>NAME</th>
                        <th>NUMBER</th>
                        <th>EMAIL</th>
                        <th>COMPANY</th>
                        <th>ENABLED USER</th>
                        {/* <th>LAST LOGIN</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {filterData?.length > 0 ? (
                        filterData?.map((val, index) => {
                          return (
                            <tr key={index}>
                              <td className="agnetName">
                                {/* <Link to="/super-admin-profile"> */}
                                <Link>
                                  <span className="InitialImage">
                                    {val.name.substring(0, 1)}
                                  </span>
                                  {val.name}
                                </Link>
                                {/* ) : (
                                    <img
                                      src={chatBaseUrl + val.profilePic}
                                      alt=""
                          />
                                  ) */}
                              </td>
                              <td>{val.phoneNumber}</td>
                              <td>{val.email}</td>
                              <td>Convex Interactive</td>
                              <td className="tableSwitch">
                                <Switch
                                  onChange={() =>
                                    handleChecked(val.isActive, val.publicId)
                                  }
                                  checked={val.isActive === true ? true : false}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  width={26}
                                  height={14}
                                  handleDiameter={10}
                                  onColor="#29A869"
                                />
                              </td>
                              {/* <td>{(val.updateDateTime)}</td> */}
                              <td>
                                <div className="uk-inline">
                                  <button type="button">
                                    <OverflowMenuVertical />
                                  </button>
                                  <div
                                    uk-dropdown=""
                                    className="contactBlckDrop"
                                  >
                                    <ul>
                                      <li>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            navigate("/edit-super-admin", {
                                              state: val,
                                            });
                                          }}
                                        >
                                          <Edit /> Edit
                                        </button>
                                      </li>
                                      {/* <li>
                                        <button type="button">
                                          <Download /> Download data
                                        </button>
                                      </li>
                                      <hr
                                        style={{
                                          marginTop: "5px",
                                          marginBottom: "5px",
                                        }}
                                      /> */}
                                      <li>
                                        <button
                                          className="trashBtn"
                                          type="button"
                                          onClick={() =>
                                            handleOpenAdminDeleteModal(
                                              val.publicId
                                            )
                                          }
                                        >
                                          <TrashCan /> Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={11} className="dataNotFound">
                            {filterData === null ? (
                              <div uk-spinner=""></div>
                            ) : (
                              "Data Not Found"
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
      {openAdminDeleteModal && (
        <DeleteModal
          closeModal={handleCloseAdminDeleteModal}
          dltModalTxt="Are you sure you want to delete this user? This will affect the
                team members of selected user."
          deleteTeam={deleteUser}
          dltLoader={dltLoader}
          heading={"Delete User"}
        />
      )}
    </>
  );
};

export default CompanyList;
