import React, { useState } from "react";
import SettingSideBar from "./components/SettingSideBar";
import demoImg from "../../assets/images/avatar-02.png";
import { ChevronDown } from "@carbon/icons-react";
import Footer from "../../components/common/Footer/Footer";
import AccountInformationModal from "./components/Modal/AccountInformationModal";
import ContactPersonModal from "./components/Modal/ContactPersonModal";
import DeletePictureModal from "./components/Modal/DeletePictureModal";
import OrganizationNumerModal from "./components/Modal/OrganizationNumerModal";
import PrimaryEmailModal from "./components/Modal/PrimaryEmailModal";
import { useEffect } from "react";
import { apiGet, apiPost } from "../../Utils/apiServices";
import { useAuth } from "../../Context/AuthProvider";
// import { chatBaseUrl } from "../../Constant";
import { toast } from "react-toastify";
import { useOutletContext } from "react-router-dom";
import { useWebSocket } from "../../socket/WebSocketManager";
import { ValidateEmail, validatePhoneNumber } from "../../Utils/regexFunctions";
import { decryptedValue } from "../../Utils/RouteHashConversion";
const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;

const Setting = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const { socket } = useWebSocket();

  const { auth } = useAuth();
  const [generalSettings, setGeneralSettings] = useState();
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [temperoryImageStoreUrl, setTemperoryImageStoreUrl] = useState("");
  const [displayImage, setDisplayImage] = useState("");
  const [loader, setLoader] = useState(false);
  const [errorEnable, setErrorEnable] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [emailError, setEmailError] = useState("Valid Email Address");
  const [openInformationModal, setOpenInformationModal] = useState(false);
  const [openContactPersonModal, setOpenContactPersonModal] = useState(false);
  const [openDeletePictureModal, setOpenDeletePictureModal] = useState(false);
  const [openOraganizationNumberModal, setOpenOraganizationNumberModal] =
    useState(false);
  const [filePath, setFilePath] = useState("");
  const [openDeleteOrganizationNumber, setOpenDeleteOrganizationNumber] =
    useState(false);
  const [openPrimaryEmailModal, setOpenPrimaryEmailModal] = useState(false);
  const [openDeletePrimaryEmailModal, setOpenDeletePrimaryEmailModal] =
    useState(false);
  const [openSecendoryEmailModal, setOpenSecendoryEmailModal] = useState(false);
  const [openDeleteSecondaryModal, setOpenDeleteSecondaryModal] =
    useState(false);
  const [data, setData] = useState({
    organizationName: "",
    companyIndustry: "",
    companyProfilePic: "",
    contactPerson: "",
    contactNumber: "",
    contactEmail: "",
    logoUrl: "",
    fileId: "",
  });
  const [fileUploadError, setFileUploadError] = useState(null);
  const allowedImageFileTypes = ["image/jpeg", "image/png", "image/jpg"].join(
    ", "
  );
  const handleEmailValidation = (e) => {
    var Email = ValidateEmail(e.target.value);
    if (Email === "Invalid Email") {
      setEmailError("Invalid Email Address");
    } else {
      setEmailError("Valid Email Address");
    }
  };
  const getSettings = () => {
    apiGet("/settings/general", onSuccessGeneral, onFailureGeneral);
  };
  const onSuccessGeneral = (response) => {
    // setComapnyLogo(response?.data.logoUrl);

    setGeneralSettings(response?.data);
    setData({
      organizationName: response?.data.organizationName,
      companyIndustry: response?.data.companyIndustry,
      companyProfilePic: response?.data.companyProfilePic,
      contactPerson: response?.data.contactPerson,
      contactNumber: response?.data.contactNumber,
      contactEmail: response?.data.contactEmail,
      logoUrl: response?.data.logoUrl,
      fileId: response?.data.fileId,
    });
  };
  const onFailureGeneral = (error) => {
    console.log(error);
  };

  const handleFile = (e, allowedTypes) => {
    var selectedFile = e.target.files[0];
    const maxSizeInMB = 10; // Change this value to your desired size

    if (selectedFile) {
      if (!allowedTypes.includes(selectedFile.type)) {
        setFileUploadError("Invalid file type. Please select a valid file.");
        e.target.value = "";
      } else if (selectedFile.size / (1024 * 1024) > maxSizeInMB) {
        setFileUploadError(`File size exceeds the ${maxSizeInMB} MB limit`);
        e.target.value = "";
      } else {
        setDisplayImage(URL.createObjectURL(selectedFile));
        setFileUploadError(null);
        var formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("agentPublicId", decryptedValue(auth.agentId));
        formData.append("companyId", decryptedValue(auth.companyId));
        apiPost(
          `/chat/upload-file`,
          onSuccessFileUpload,
          onFailureFileUpload,
          formData
        );
      }
    }
  };
  const onSuccessFileUpload = (response) => {
    if (response.status === 1) {
      setData({
        ...data,
        logoUrl: response.message,
        fileId: response.message,
      });
      setFilePath(response?.message);
    }
  };
  const onFailureFileUpload = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (filePath !== "") {
      // uploadFile();

      // updateGeneralSettings();
      fetchUrl(filePath);
    }
  }, [filePath]);

  const fetchUrl = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const data = {
        fileId: filePath,
      };
      socket.send(JSON.stringify({ type: "get-file-url", data: data }));
    }
  };

  useEffect(() => {
    if (socketResponse !== undefined) {
      if (socketResponse.type == "FILE_URL") {
        setTemperoryImageStoreUrl(socketResponse?.data?.filePath);
        // setCompanyImage(socketResponse?.data.filePath);
      }
    }
  }, [socketResponse]);

  // const uploadFile = () => {
  //   apiGet(
  //     `/agent/profile/upload-pic/${filePath}`,
  //     onSuccessUploadFile,
  //     onFailureUploadFile
  //   );
  // };

  // const onSuccessUploadFile = (response) => {
  //   setApiCalled(true);
  // };
  // const onFailureUploadFile = (error) => {
  // };

  const updateGeneralSettings = () => {
    // e.preventDefault();
    setLoader(true);
    setErrorEnable(true);

    if (
      data.organizationName !== "" &&
      data.companyIndustry !== "" &&
      data.contactPerson !== "" &&
      data.contactNumber !== "" &&
      data.contactEmail !== "" &&
      emailError === "Valid Email Address" &&
      fileUploadError == null &&
      isNumberValid
    ) {
      apiPost(`/settings/general`, onSucces, onFailure, data);
    } else {
      setLoader(false);
    }
  };
  const onSucces = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setLoader(false);
        toast.success("General setting updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setApiCalled(true);
        setDisplayImage("");
        setCompanyImage(temperoryImageStoreUrl);
      }, 1000);
    }
  };
  const onFailure = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getSettings();
  }, []);
  useEffect(() => {
    if (apiCalled === true) {
      getSettings();
    }
  }, [apiCalled]);

  const handleOpenInformationModal = () => {
    setOpenInformationModal(true);
  };
  const handleCloseInformationModal = () => {
    setOpenInformationModal(false);
  };

  const handleOpenContactPersonModal = () => {
    setOpenContactPersonModal(true);
  };
  const handleCloseContactPersonModal = () => {
    setOpenContactPersonModal(false);
  };

  const handleOpenDeletePictureModal = () => {
    setOpenDeletePictureModal(true);
  };
  const handleCloseDeletePictureModal = () => {
    setOpenDeletePictureModal(false);
  };

  const handleOpenOrganizationNumberModal = () => {
    setOpenOraganizationNumberModal(true);
  };
  const handleCloseOrganizationNumberModal = () => {
    setOpenOraganizationNumberModal(false);
  };

  const handleOpenDeleteOrganizationNumber = () => {
    setOpenDeleteOrganizationNumber(true);
  };
  const handleCloseDeleteOrganizationNumber = () => {
    setOpenDeleteOrganizationNumber(false);
  };

  const handleOpenPrimaryEmailModal = () => {
    setOpenPrimaryEmailModal(true);
  };
  const handleClosePrimaryEmailModal = () => {
    setOpenPrimaryEmailModal(false);
  };

  const handleOpenDeletePrimaryEmailModal = () => {
    setOpenDeletePrimaryEmailModal(true);
  };
  const handleCloseDeletePrimaryEmailModal = () => {
    setOpenDeletePrimaryEmailModal(false);
  };

  const handleOpenSecendoryModal = () => {
    setOpenSecendoryEmailModal(true);
  };
  const handleCloseSecendoryModal = () => {
    setOpenSecendoryEmailModal(false);
  };

  const handleOpenDeleteSecondaryModal = () => {
    setOpenDeleteSecondaryModal(true);
  };
  const handleCloseDeleteSecondaryModal = () => {
    setOpenDeleteSecondaryModal(false);
  };

  return (
    <>
      <div className="settingwrp">
        <div className="topBar">
          <div className="uk-grid" uk-grid="">
            <div className="uk-width-1-1">
              <div className="txtwrp">
                <h3>Settings</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="settingContentWrp">
          <div className="uk-grid uk-grid-small" uk-grid="">
            <div className="uk-width-auto">
              <SettingSideBar userPermissions={userPermissions} />
            </div>
            <div className="uk-width-expand">
              <div className="settingWrapper">
                <div className="uk-container">
                  <div className="settingTabbing">
                    <ul
                      className="uk-subnav uk-subnav-pill tabbingBtn"
                      uk-switcher=""
                    >
                      <li>
                        <a href="#">Account</a>
                      </li>
                    </ul>

                    <ul className="uk-switcher uk-margin">
                      <li>
                        <div className="generalSettingWrp">
                          <div className="mainHeading">
                            <h3>Account settings</h3>
                            <p>
                              View and configure all your company account
                              settings.
                            </p>
                          </div>
                          <div className="boxwrp">
                            <div className="boxHeading">
                              <h6>Account information</h6>
                              <p>
                                This information is visible to others and can be
                                different from your account name if you choose.
                              </p>
                            </div>
                            <div className="formwrp">
                              <form action="javascript:void(0);">
                                <div className="uk-grid uk-grid-small uk-flex-middle">
                                  <div className="uk-width-1-3">
                                    <div className="formInput">
                                      <label htmlFor="">
                                        Organization name
                                      </label>
                                      <input
                                        type="text"
                                        className="uk-input"
                                        defaultValue={
                                          generalSettings?.organizationName
                                        }
                                        onChange={(e) => {
                                          setData({
                                            ...data,
                                            organizationName: e.target.value,
                                          });
                                        }}
                                        disabled
                                      />
                                      {data.organizationName === "" &&
                                        errorEnable && (
                                          <div className="formErrors">
                                            Organization name is required
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div className="uk-width-1-3">
                                    <div className="formInput">
                                      <label htmlFor="">Company industry</label>
                                      <input
                                        type="text"
                                        className="uk-input"
                                        defaultValue={
                                          generalSettings?.companyIndustry
                                        }
                                        onChange={(e) => {
                                          e.target.value === "" &&
                                            setErrorEnable(true);
                                          setData({
                                            ...data,
                                            companyIndustry: e.target.value,
                                          });
                                        }}
                                        disabled
                                      />
                                      {data.companyIndustry === "" &&
                                        errorEnable && (
                                          <div className="formErrors">
                                            Company name is required
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div className="uk-width-1-3">
                                    <div
                                      className="changeBtn"
                                      style={{ textAlign: "right" }}
                                    >
                                      <button
                                        type="button"
                                        onClick={handleOpenInformationModal}
                                      >
                                        Change
                                      </button>
                                    </div>
                                    {/* {temperoryImageStoreUrl !== "" && (
                                      <div className="formErrors">
                                        please save to upload image
                                      </div>
                                    )} */}
                                  </div>
                                </div>
                                <hr />
                                <div className="boxHeading">
                                  <h6>Company Logo</h6>
                                  <p>
                                    Your company logo and name appear when you
                                    log in to BerryTalks.com
                                  </p>
                                </div>
                                <div className="changeImgBox">
                                  <div className="imgwrp">
                                    {companyImage === null ||
                                    companyImage === "" ? (
                                      <span className="generalSettingImage">
                                        {generalSettings?.organizationName.charAt(
                                          0
                                        )}
                                      </span>
                                    ) : (
                                      <img
                                        // src={
                                        //   generalSettings?.logoUrl === null
                                        //     ? demoImg
                                        //     : chatBaseUrl +
                                        //       generalSettings?.logoUrl
                                        // }
                                        src={
                                          displayImage === ""
                                            ? chatBaseUrl + companyImage
                                            : displayImage
                                        }
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  {/* <span className="formErrors">
                                    {fileUploadError}
                                  </span> */}
                                  <div className="changeBtn">
                                    {/* <button
                                      type="button"
                                      onClick={handleOpenDeletePictureModal}
                                    >
                                      Remove
                                    </button> */}
                                    {/* <div
                                      className="js-upload"
                                      uk-form-custom=""
                                    >
                                      <input
                                        type="file"
                                        multiple
                                        onChange={(e) => handleFile(e)}
                                        accept="image/*"
                                      />
                                      <button type="button">Change</button>
                                    </div> */}
                                    <div className="customUploader">
                                      <input
                                        type="file"
                                        id="file"
                                        multiple
                                        onChange={(e) =>
                                          handleFile(e, allowedImageFileTypes)
                                        }
                                        accept="image/*"
                                      />
                                      <label for="file" className="btn-3">
                                        <span>Upload</span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {displayImage !== "" && (
                                  <div className="formErrors">
                                    {" "}
                                    save to upload image
                                  </div>
                                )}
                                {fileUploadError !== null && (
                                  <div className="formErrors">
                                    {fileUploadError}
                                  </div>
                                )}
                                <hr />
                                <div className="boxHeading">
                                  <h6>Account access</h6>
                                  <p>
                                    You can also add a mobile phone number and
                                    secondary email for account security,
                                    recovery, and notifications.
                                  </p>
                                </div>
                                <div
                                  className="uk-grid uk-grid-small"
                                  uk-grid=""
                                >
                                  <div className="uk-width-1-1">
                                    <div className="formInput">
                                      <label htmlFor="">
                                        Contact Person Name
                                      </label>
                                      <input
                                        type="text"
                                        className="uk-input"
                                        defaultValue={
                                          generalSettings?.contactPerson
                                        }
                                        onChange={(e) => {
                                          e.target.value === "" &&
                                            setErrorEnable(true);
                                          setData({
                                            ...data,
                                            contactPerson: e.target.value,
                                          });
                                        }}
                                      />
                                      {data.contactPerson === "" &&
                                        errorEnable && (
                                          <div className="formErrors">
                                            Contact name is required
                                          </div>
                                        )}
                                      {/* <div className="changeBtn changeBtn2">
                                        <button
                                          type="button"
                                          onClick={handleOpenContactPersonModal}
                                        >
                                          Change
                                        </button>
                                      </div> */}
                                    </div>
                                  </div>
                                  <div className="uk-width-1-1">
                                    <div className="formInput">
                                      <label htmlFor="">
                                        Organization mobile phone
                                      </label>
                                      <input
                                        type="number"
                                        className="uk-input"
                                        defaultValue={
                                          generalSettings?.contactNumber
                                        }
                                        onChange={(e) => {
                                          e.target.value === "" &&
                                            setErrorEnable(true);
                                          let error = validatePhoneNumber(
                                            e.target.value
                                          );
                                          setIsNumberValid(error);
                                          setData({
                                            ...data,
                                            contactNumber: e.target.value,
                                          });
                                        }}
                                      />
                                      {data.contactNumber === "" &&
                                        errorEnable && (
                                          <div className="formErrors">
                                            Contact number is required
                                          </div>
                                        )}
                                      {data.contactNumber !== "" &&
                                        !isNumberValid && (
                                          <div className="formErrors">
                                            Invalid contact number
                                          </div>
                                        )}
                                      {/* <div className="changeBtn changeBtn2">
                                        <button
                                          type="button"
                                          className="uk-margin-small-right"
                                          onClick={
                                            handleOpenDeleteOrganizationNumber
                                          }
                                        >
                                          Remove
                                        </button>
                                        <button
                                          type="button"
                                          onClick={
                                            handleOpenOrganizationNumberModal
                                          }
                                        >
                                          Change
                                        </button>
                                      </div> */}
                                    </div>
                                  </div>
                                  <div className="uk-width-1-1">
                                    <div className="formInput">
                                      <label htmlFor="">
                                        WhatsApp registered number
                                      </label>
                                      <input
                                        type="text"
                                        className="uk-input"
                                        disabled
                                        defaultValue={
                                          generalSettings?.contactNumber
                                        }
                                        // onChange={(e) => {
                                        //   setData({
                                        //     ...data,
                                        //     contactNumber: e.target.value,
                                        //   });
                                        // }}
                                      />
                                      {/* {data.contactNumber === "" &&
                                        errorEnable && (
                                          <div className="formErrors">
                                            contact number is required
                                          </div>
                                        )} */}
                                      {/* <div className="changeBtn changeBtn2">
                                        <button
                                          type="button"
                                          className="uk-margin-small-right"
                                          onClick={
                                            handleOpenDeleteOrganizationNumber
                                          }
                                        >
                                          Remove
                                        </button>
                                        <button
                                          type="button"
                                          onClick={
                                            handleOpenOrganizationNumberModal
                                          }
                                        >
                                          Change
                                        </button>
                                      </div> */}
                                    </div>
                                  </div>
                                  <div className="uk-width-1-1">
                                    <div className="formInput">
                                      <label htmlFor="">Primary email</label>
                                      <input
                                        type="email"
                                        className="uk-input"
                                        defaultValue={
                                          generalSettings?.contactEmail
                                        }
                                        onChange={(e) => {
                                          e.target.value === "" &&
                                            setErrorEnable(true);
                                          handleEmailValidation(e);
                                          setData({
                                            ...data,
                                            contactEmail: e.target.value,
                                          });
                                        }}
                                      />
                                      {data.contactEmail === "" &&
                                        errorEnable && (
                                          <div className="formErrors">
                                            Email is required
                                          </div>
                                        )}
                                      {data.contactEmail !== "" &&
                                        emailError !==
                                          "Valid Email Address" && (
                                          <div className="formErrors">
                                            Invalid email address
                                          </div>
                                        )}
                                      {/* <div className="changeBtn changeBtn2">
                                        <button
                                          type="button"
                                          className="uk-margin-small-right"
                                          onClick={
                                            handleOpenDeletePrimaryEmailModal
                                          }
                                        >
                                          Remove
                                        </button>
                                        <button
                                          type="button"
                                          onClick={handleOpenPrimaryEmailModal}
                                        >
                                          Change
                                        </button>
                                      </div> */}
                                    </div>
                                  </div>
                                  <div className="uk-width-1-1">
                                    <div className="formInput">
                                      {/* <label htmlFor="">Secondary email</label>
                                      <input type="text" className="uk-input" /> */}
                                      {/* <div className="changeBtn changeBtn2">
                                        <button
                                          type="button"
                                          className="uk-margin-small-right"
                                          onClick={
                                            handleOpenDeleteSecondaryModal
                                          }
                                        >
                                          Remove
                                        </button>
                                        <button
                                          type="button"
                                          onClick={handleOpenSecendoryModal}
                                        >
                                          Change
                                        </button>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                                <hr />
                                <div className="boxHeading">
                                  <h6>Account Time Zone</h6>
                                  <p>
                                    All the data in Manychat will be displayed
                                    and exported according to this timezone.
                                  </p>
                                </div>
                                <div className="settingDropdown">
                                  <div className="uk-inline">
                                    <button
                                      className="dropdownBtn"
                                      type="button"
                                      disabled
                                    >
                                      Pakistan standard time - (UTC + 5:00)
                                      {/* <ChevronDown /> */}
                                    </button>
                                    {/* <div
                                      uk-dropdown="mode: click"
                                      className="dropdownContent"
                                    >
                                      <ul>
                                        <li>
                                          <button type="button">
                                            Pakistan standard time - (UTC +
                                            5:00)
                                          </button>
                                        </li>
                                        <li>
                                          <button type="button">
                                            Pakistan standard time - (UTC +
                                            5:00)
                                          </button>
                                        </li>
                                        <li>
                                          <button type="button">
                                            Pakistan standard time - (UTC +
                                            5:00)
                                          </button>
                                        </li>
                                      </ul>
                                    </div> */}
                                  </div>
                                </div>
                                <hr />
                                <div className="boxHeading">
                                  <h6>Preferred languages</h6>
                                  <p>
                                    Select the language you'd like to use for
                                    BerryTalks, services and communications.
                                  </p>
                                </div>
                                <div className="settingDropdown">
                                  <div className="dropdownTxt">
                                    <span>Select your default language</span>
                                  </div>
                                  <div className="uk-inline">
                                    <button
                                      className="dropdownBtn"
                                      type="button"
                                      disabled
                                    >
                                      English (UK)
                                      {/* <ChevronDown /> */}
                                    </button>
                                    {/* <div
                                      uk-dropdown="mode: click"
                                      className="dropdownContent"
                                    >
                                      <ul>
                                        <li>
                                          <button type="button">
                                            English (UK)
                                          </button>
                                        </li>
                                        <li>
                                          <button type="button">
                                            English (UK)
                                          </button>
                                        </li>
                                        <li>
                                          <button type="button">
                                            English (UK)
                                          </button>
                                        </li>
                                      </ul>
                                    </div> */}
                                  </div>
                                </div>
                                <div className="btnSection">
                                  <button onClick={updateGeneralSettings}>
                                    {loader ? (
                                      <div
                                        uk-spinner=""
                                        className="loader"
                                      ></div>
                                    ) : (
                                      "Save"
                                    )}
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
      {openInformationModal && (
        <AccountInformationModal
          closeModal={handleCloseInformationModal}
          generalSettings={generalSettings}
          setApiCalled={setApiCalled}
        />
      )}
      {openContactPersonModal && (
        <ContactPersonModal closeModal={handleCloseContactPersonModal} />
      )}
      {openDeletePictureModal && (
        <DeletePictureModal
          closeModal={handleCloseDeletePictureModal}
          deleteHeading="Delete organization profile picture"
          deleteTxt="Are you sure you want to delete this organization profile picture?"
        />
      )}
      {openOraganizationNumberModal && (
        <OrganizationNumerModal
          closeModal={handleCloseOrganizationNumberModal}
        />
      )}
      {openDeleteOrganizationNumber && (
        <DeletePictureModal
          closeModal={handleCloseDeleteOrganizationNumber}
          deleteHeading="Delete organization mobile phone"
          deleteTxt="Are you sure you want to delete this organization mobile phone?"
        />
      )}
      {openPrimaryEmailModal && (
        <PrimaryEmailModal
          closeModal={handleClosePrimaryEmailModal}
          modalHeading="Change primary email"
        />
      )}
      {openDeletePrimaryEmailModal && (
        <DeletePictureModal
          closeModal={handleCloseDeletePrimaryEmailModal}
          deleteHeading="Delete organization mobile phone"
          deleteTxt="Are you sure you want to delete this organization mobile phone?"
        />
      )}
      {openSecendoryEmailModal && (
        <PrimaryEmailModal
          closeModal={handleCloseSecendoryModal}
          modalHeading="Change secondary email"
        />
      )}
      {openDeleteSecondaryModal && (
        <DeletePictureModal
          closeModal={handleCloseDeleteSecondaryModal}
          deleteHeading="Delete organization mobile phone"
          deleteTxt="Are you sure you want to delete this organization mobile phone?"
        />
      )}
    </>
  );
};

export default Setting;
