import React, { useState } from "react";
import { useContextChatBot } from "../whatsapp/ContextChatBot";

const ListMenuModal = ({ handleCloseListModal }) => {
  const { handleInputChangeSection, sections, selectedDialog, addSectionList } =
    useContextChatBot();

  const [numberOfLists, setNumberOfLists] = useState(0);
  const [formData, setFormData] = useState({
    header: "",
    description: "",
    button: "",
    menuOptions: Array.from({ length: numberOfLists }, () => ({
      menuText: "",
      menuDescription: "",
      menuId: "",
    })),
  });

  const handleNumberOfListsChange = (event) => {
    const newListCount = parseInt(event.target.textContent);
    setNumberOfLists(newListCount);
    setFormData((prevData) => ({
      ...prevData,
      menuOptions: Array.from({ length: newListCount }, () => ({
        menuText: "",
        menuDescription: "",
        menuId: "",
      })),
    }));
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleMenuOptionChange = (index, field, value) => {
    setFormData((prevData) => {
      const updatedOptions = [...prevData.menuOptions];
      updatedOptions[index] = {
        ...updatedOptions[index],
        [field]: value,
      };
      return {
        ...prevData,
        menuOptions: updatedOptions,
      };
    });
  };

  const handleSave = () => {
    let value = `{list_menu}
    {list_header_value}${formData.header}{/list_header_value}
    {list_body_description}${formData.description}{/list_body_description}
    ${formData.menuOptions
      .map(
        (option) => `
      {list_option}${option.menuText}{/list_option}
      {list_id}${option.menuId}{/list_id}
      {list_description}${option.menuDescription}{/list_description}
    `
      )
      .join("")}
    {list_button_text}${formData.button}{/list_button_text}`;

    addSectionList("textarea", value);
    handleCloseListModal();
  };

  return (
    <>
      <div
        className="customModal listMenuDModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
        uk-modal="esc-close: false; bg-close: false"
      >
        <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
          <div className="filterModalContent">
            <div className="modalHeading">
              <span>Add list modal</span>
            </div>
            <div className="formWrp">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-1 uk-grid-margin uk-first-column">
                  <div className="uk-inline listMenuInnerdropdown">
                    <button
                      className="getLocationBtn"
                      type="button"
                      aria-haspopup="true"
                    >
                      {numberOfLists !== 0 ? numberOfLists : "Number of list"}
                      <svg
                        focusable="false"
                        preserveAspectRatio="xMidYMid meet"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        aria-hidden="true"
                      >
                        <path d="M8 11L3 6 3.7 5.3 8 9.6 12.3 5.3 13 6z"></path>
                      </svg>
                    </button>
                    <div
                      uk-dropdown="mode: click"
                      className="litsDropContent uk-drop uk-dropdown"
                    >
                      <ul>
                        {Array.from({ length: 9 }, (_, i) => (
                          <li
                            key={i + 2}
                            onClick={handleNumberOfListsChange}
                            style={{ cursor: "pointer" }}
                          >
                            {i + 2}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="uk-width-1-1">
                  <div className="formLabel">
                    <span>Header text </span>
                    <div
                      className="txtCounter"
                      style={{
                        position: "relative",
                        top: "unset",
                        right: "unset",
                      }}
                    >
                      <span>{formData?.header?.length}</span>
                      <span>/</span>
                      <span>20</span>
                    </div>
                  </div>
                  <div className="formInput">
                    <textarea
                      type="text"
                      className="uk-input"
                      placeholder="Enter header text"
                      value={formData.header}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 20) {
                          handleInputChange("header", e.target.value);
                        }
                      }}
                    ></textarea>
                  </div>
                </div>

                <div className="uk-width-1-1">
                  <div className="formLabel">
                    <span>Body description </span>
                    <div
                      className="txtCounter"
                      style={{
                        position: "relative",
                        top: "unset",
                        right: "unset",
                      }}
                    >
                      <span>{formData?.description?.length}</span>
                      <span>/</span>
                      <span>30</span>
                    </div>
                  </div>
                  <div className="formInput">
                    <textarea
                      name="msg_box"
                      className="uk-textarea"
                      placeholder="Enter description"
                      value={formData.description}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 30) {
                          handleInputChange("description", e.target.value);
                        }
                      }}
                    ></textarea>
                  </div>
                </div>

                <div className="uk-width-1-1">
                  <div className="formLabel">
                    <span>Button text</span>
                    <div
                      className="txtCounter"
                      style={{
                        position: "relative",
                        top: "unset",
                        right: "unset",
                      }}
                    >
                      <span>{formData.button.length}</span>
                      <span>/</span>
                      <span>15</span>
                    </div>
                  </div>
                  <div className="formInput">
                    <input
                      type="text"
                      className="uk-input"
                      placeholder="Button text"
                      value={formData.button}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 15) {
                          handleInputChange("button", e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="uk-width-1-1">
                  {Array.from({ length: numberOfLists }, (_, index) => (
                    <div key={index}>
                      <hr
                        style={{
                          borderTop: "3px dotted #e5e5e5",
                          margin: "20px 0",
                        }}
                      />
                      <div className="uk-width-1-1">
                        <div className="formLabel">
                          <span>Menu text {index + 1}</span>
                          <div
                            className="txtCounter"
                            style={{
                              position: "relative",
                              top: "unset",
                              right: "unset",
                            }}
                          >
                            <span>
                              {formData?.menuOptions[index]?.menuText?.length}
                            </span>
                            <span>/</span>
                            <span>24</span>
                          </div>
                        </div>
                        <div className="formInput">
                          <input
                            type="text"
                            className="uk-input"
                            placeholder="Enter menu text"
                            value={formData?.menuOptions[index]?.menuText}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value.length <= 24) {
                                handleMenuOptionChange(
                                  index,
                                  "menuText",
                                  e.target.value
                                );
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className="uk-width-1-1">
                        <div className="formLabel">
                          <span>Menu description {index + 1}</span>
                          <div
                            className="txtCounter"
                            style={{
                              position: "relative",
                              top: "unset",
                              right: "unset",
                            }}
                          >
                            <span>
                              {
                                formData?.menuOptions[index]?.menuDescription
                                  ?.length
                              }
                            </span>
                            <span>/</span>
                            <span>20</span>
                          </div>
                        </div>
                        <div className="formInput">
                          <textarea
                            name="msg_box"
                            className="uk-textarea"
                            placeholder="Enter menu description"
                            value={formData.menuOptions[index]?.menuDescription}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value.length <= 20) {
                                handleMenuOptionChange(
                                  index,
                                  "menuDescription",
                                  e.target.value
                                );
                              }
                            }}
                          ></textarea>
                        </div>
                      </div>

                      <div className="uk-width-1-1">
                        <div className="formLabel">
                          <span>Unique Id {index + 1}</span>
                          <div
                            className="txtCounter"
                            style={{
                              position: "relative",
                              top: "unset",
                              right: "unset",
                            }}
                          >
                            <span>
                              {formData.menuOptions[index]?.menuId?.length}
                            </span>
                            <span>/</span>
                            <span>50</span>
                          </div>
                        </div>
                        <div className="formInput">
                          <input
                            type="text"
                            className="uk-input"
                            placeholder="Enter id"
                            value={formData.menuOptions[index]?.menuId}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value.length <= 50) {
                                handleMenuOptionChange(
                                  index,
                                  "menuId",
                                  e.target.value
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="btnwrp">
              <button
                type="button"
                className="gray-btn"
                onClick={handleCloseListModal}
              >
                Cancel
              </button>

              <button type="button" className="green-btn" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListMenuModal;
