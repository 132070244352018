import { Close } from "@carbon/icons-react";
import React from "react";

const VoiceModal = ({
  closeModal,
  startRecording,
  toggleRecording,
  isRecording,
  isRecordingStarted,
  timer,
  sendRecording,
}) => {
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div
        className="disconectAcount ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
        uk-modal=""
      >
        <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-animation-slide-bottom uk-text-center">
          <button
            className="uk-modal-close-default voiceCloseBtn"
            type="button"
            onClick={closeModal}
          >
            <Close />
          </button>

          <main className="container">
            <h1 style={{ margin: "0px" }} id="output">
              {formatTime(timer)}
            </h1>
          </main>

          <p style={{ margin: "0px" }}>
            <strong className="uk-hidden blink" id="recordingText">
              Recording....
            </strong>
          </p>
          <ol id="recordingsList"></ol>

          <section className="controls">
            <div className="voiceBtnSection">
              <button onClick={startRecording} disabled={isRecording}>
                Start
              </button>
              {/* <button onClick={pauseRecording} disabled={!isRecording}>
                Pause 
              </button>
              <button onClick={resumeRecording} disabled={isRecording}>
                Resume 
              </button> */}
              <button onClick={toggleRecording}>
                {isRecording ? "Pause Recording" : "Resume Recording"}
              </button>
              {/* <button onClick={stopRecording} disabled={!isRecording}>
                Stop Recording
              </button> */}
              {/* <button disabled={!isRecordingStarted}>Send</button> */}
              <button onClick={sendRecording}>Send</button>
            </div>
          </section>
          <div className="spinner-recording uk-hidden">
            <div className="mainspiner">
              <span uk-spinner="ratio: 1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoiceModal;
