import React from "react";

const ContactPersonModal = ({ closeModal }) => {
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>Account information</span>
          </div>
          <div className="modalForm">
            <form action="">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <label htmlFor="">First name</label>
                    <input
                      type="text"
                      className="uk-input"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <label htmlFor="">Last name</label>
                    <input type="text" className="uk-select" />
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="btnwrp">
                    <button
                      type="button"
                      className="btn-1"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button type="button" className="btn-2">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPersonModal;
