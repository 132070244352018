import React from "react";
import cloudeImg from "../../assets/images/cloud--upload.svg";
import { CheckmarkOutline } from "@carbon/icons-react";
import { apiPost } from "../../Utils/apiServices";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import empolyeeImportFile from "../../assets/file/ImportEmployee.xlsx";

const ImportEmployees = () => {
  const navigate = useNavigate();
  const [fileUploadDetails, setFileUploadDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [errorEnable, setErrorEnable] = useState(false);
  const [submittedData, setSubmittedData] = useState({
    file: null,
  });

  const handleFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedFormats = [
        "text/csv",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];

      if (!allowedFormats.includes(file.type)) {
        toast.error("Upload file with correct format", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        e.target.value = "";
        setSubmittedData({
          ...submittedData,
          file: null,
        });
      } else {
        setSubmittedData({
          ...submittedData,
          file: file,
        });
      }
    }
  };

  const fileUpload = () => {
    setLoader(true);
    var formData = new FormData();
    formData.append("file", submittedData.file);
    apiPost(
      `/treat/add-employee`,
      onSuccessFileUpload,
      onFailureFileUpload,
      formData
    );
  };
  const onSuccessFileUpload = (response) => {
    setFileUploadDetails(response?.data);
  };
  const onFailureFileUpload = (error) => {
    setLoader(false);
    if (error.message !== "Network Error") {
      if (error?.response?.data.error === "Internal Server Error") {
        toast.error("Invalid data found in the file.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    }
  };

  const FinalizeUpload = (e) => {
    e.preventDefault();
    setErrorEnable(true);
    if (submittedData.file !== null) {
      fileUpload();
    }
  };

  return (
    <>
      <div className="importContactWrp">
        {fileUploadDetails.fileId === undefined ||
        fileUploadDetails.fileId == 0 ? (
          <div className="uk-container">
            <div className="importContactTop">
              <div className="uk-grid" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="importContactHeading">
                    {/* <span>Import</span> */}
                  </div>
                </div>
                <divc className="uk-width-1-2">
                  <div className="exitBtn">
                    <a onClick={() => navigate("/list-employee")}>Exit</a>
                  </div>
                </divc>
              </div>
            </div>
            <div className="importSecWrp">
              <div className="importPageHeading">
                <h3>Upload your employees</h3>
              </div>
              <div className="importContactTxt">
                <p>Import employee list from a CSV or XLSX file.</p>
              </div>
              <div className="importContactInput">
                <div uk-form-custom="target: true">
                  <input
                    type="file"
                    aria-label="Custom controls"
                    style={{ fontSize: "16px" }}
                    onChange={(e) => handleFile(e)}
                  />
                  <input
                    className="uk-input uk-form-width-medium"
                    type="text"
                    placeholder="Browse"
                    id="filefield"
                    aria-label="Custom controls"
                    disabled
                    style={{ backgroundColor: "#fff" }}
                  />

                  {loader && <div className="loaderDiv" uk-spinner=""></div>}

                  <img src={cloudeImg} alt="" />
                </div>
                {submittedData.file === null && errorEnable && (
                  <div className="formErrors">Please upload file</div>
                )}
              </div>
              <div className="downloadTxt">
                <p>
                  You can download our sample{" "}
                  <a href={empolyeeImportFile} download="import-contact">
                    XLSX import file
                  </a>{" "}
                  and replace the sample employee with your own data.
                </p>
              </div>
            </div>

            <div className="importTagSec">
              <div className="importPageSelect">
                <form action="">
                  <div className="submitBtn">
                    <button
                      type="submit"
                      className="btn-1"
                      onClick={FinalizeUpload}
                    >
                      Finalize import
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div className="uk-container">
            <div className="importContactTop">
              <div className="uk-grid" uk-grid="">
                <div className="uk-width-1-2"></div>
                <divc className="uk-width-1-2">
                  <div className="exitBtn">
                    <a
                      onClick={() =>
                        navigate("/list-employee", { replace: true })
                      }
                    >
                      Exit
                    </a>
                  </div>
                </divc>
              </div>
            </div>
            <div className="importSecWrp">
              <div className="importPageHeading">
                <h3>Your import was successful</h3>
              </div>
              <div className="importContactTxt importConfirmationTxt">
                <p>
                  We've added{" "}
                  <span>{fileUploadDetails?.totalRecordsInserted}</span>{" "}
                  employees to your employees
                </p>
              </div>
              <div className="confirmedContactList">
                <ul>
                  <li>
                    <CheckmarkOutline />
                    <p>
                      Imported from: <span>File upload</span>
                    </p>
                  </li>
                  <li>
                    <CheckmarkOutline />
                    <p>
                      Update existing employees:{" "}
                      <span>
                        {fileUploadDetails?.totalRecordsUpdated}
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
              <div className="submitBtn">
                <button
                  type="button"
                  onClick={() => navigate("/list-employee", { replace: true })}
                >
                  View employees
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ImportEmployees;
