import {
  Camera,
  Checkmark,
  ChevronDown,
  ChevronLeft,
  Edit,
  Locked,
  Purchase,
} from "@carbon/icons-react";
import React from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import avatarImg from "../../assets/images/avatar-02.png";
import { useState } from "react";
import DeleteModal from "./components/Modal/DeleteModal";
import DetailTab from "./components/DetailTab";
import RoleTab from "./components/RoleTab";
import { apiDelete, apiGet, apiPost } from "../../Utils/apiServices";
import { useEffect } from "react";
import { useAuth } from "../../Context/AuthProvider";
import { toast } from "react-toastify";
// import { chatBaseUrl } from "../../Constant";
import moment from "moment";
import { dateWithAtTime, formatDate } from "../../Utils/DateFunctions";
import { removeAndAddActiveClass } from "../../Utils/jqueryFunctions";
import { useWebSocket } from "../../socket/WebSocketManager";
import { decryptedValue } from "../../Utils/RouteHashConversion";
const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;

const UserDetails = () => {
  const { socket } = useWebSocket();
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber
  ] = useOutletContext();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [filePath, setFilePath] = useState("");
  const [openAdminDeleteModal, setOpenAdminDeleteModal] = useState(false);
  const [selectedStatusSelection, setSelectedStatusSelection] = useState("");
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [submittedData, setSubmittedData] = useState({
    firstName: "",
    lastName: "",
    phoneNumberWork: "",
    phoneNumberPrivate: "",
    email: "",
    country: "",
    city: "",
    team: "",
  });

  const [errorEnable, setErrorEnable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [dltLoader, setDltLoader] = useState(false);
  const [userImage, setUserImage] = useState("");
  const [genericRoute, setGenericRoute] = useState(false);
  // const populateAgentStatus = () => {
  //   let result = "";
  //   let data = [
  //     { id: 1, name: "ONLINE", value: "ONLINE", class: "availableStatus" },
  //     { id: 2, name: "AWAY", value: "AWAY", class: "awayStatus" },
  //     { id: 3, name: "BREAK", value: "BREAK", class: "busyStatus" },
  //     // { id: 4, name: "OFFLINE", value: "OFFLINE", class: "offline" },
  //   ];
  //   result = data.map((response, index) => {
  //     return (
  //       <li key={index} className="agentStatus">
  //         <div
  //           id={"agentUpdate" + response.id}
  //           onClick={(e) =>
  //             handleFilterSelection(
  //               "#agentUpdate" + response.id,
  //               ".onlineDropDownWrp ul li .active",
  //               response.name,
  //               response.value
  //             )
  //           }
  //           className={
  //             response.name === selectedStatusSelection
  //               ? "active overview_id"
  //               : "overview_id"
  //           }
  //         >
  //           <span className={`${response.class} cursor`}>
  //             <span className="filtername">{response.name}</span>
  //           </span>
  //         </div>
  //         <div>
  //           <span className="uk-icon tickIcon">
  //             <Checkmark />
  //           </span>
  //         </div>
  //       </li>
  //     );
  //   });
  //   return result;
  // };

  // for roles screen
  const [checked, setChecked] = useState(false);
  const [adminPermissions, setAdminpermissions] = useState([
    {
      heading: null,
      text: "Manages everything related to account access and security. Assign this role to users who manage users and teams of users, apply restrictions with two-factor authentication, password policies and API keys.",
      roleName: "ALL-PERMISSION",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Account Manager",
      text: "Manages everything related to account access. Provided to all Active accounts to login and use the portal",
      roleName: "ACCOUNT-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Users and Teams",
      text: "Manage users and teams. Provide access to users, and manage their roles.",
      roleName: "USER-TEAMS",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Integrations Manager",
      text: "Grants access to versatile reporting and data handling options.Track engagement and communication efficiency Schedule reports - Troubleshoot message delivery - Exchange data",
      roleName: "INTEGRATION-MANAGER",
      checked: false,
      hr: true,
      innerHeading: "",
    },
    {
      heading: "Analytics Manager",
      text: " Grants access to versatile reporting and data handling options.Track engagement and communication efficiency Schedule reports - Troubleshoot message delivery - Exchange data",
      roleName: "ANALYTICS-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "FINANCE AND ANALYTICS",
    },
    {
      heading: "Finance Data Manager",
      text: "Manages communication costs, tracks account balance, and performs financial management tasks.",
      roleName: "FINANCE-DATA-MANAGER",
      checked: false,
      hr: true,
      innerHeading: "",
    },
    {
      heading: "Communication Manager",
      text: "Creates engagement scenarios and sends broadcast campaigns.",
      roleName: "COMMUNICATION-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "COMMUNICATION MANAGEMENT",
    },
    {
      heading: "People Manager",
      text: "Manages customer profiles on the data platform.",
      roleName: "PEOPLE-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Live Chat Manager",
      text: "Manages live chat conversations regardless of assignment. Chats can be assigned to them manually only and user reports will be shared. Grants access to versatile reporting and data handling options related to live chat conversations. Track live chat efficiency - Schedule reports - Exchange data",
      roleName: "LIVE-CHAT-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Chatbot Manager",
      text: " Manages chatbot design, support, and usage to achieve business goals. Supervises planned activities and progress. Creates and edits chatbots. Activate or deactivate the bots",
      roleName: "CHAT-BOT-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Chatbot Supervisor",
      text: "Monitor chatbots and analytics",
      roleName: "CHATBOT-SUPERVISOR",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Live Chat User",
      text: "Manages live chat conversations assigned to them Can not manage chats for others. User performance reports will be created",
      roleName: "LIVE-CHAT-AGENT",
      checked: false,
      hr: false,
      innerHeading: "",
    },

    {
      heading: "Chat bot Conversation",
      text: "View bot conversations with the customers.",
      roleName: "CHATBOT-CONVERSATION",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Form Access Manager",
      text: "Controls access to customer-filled forms. Assigned to users who need to view and manage submitted forms on the portal.",
      roleName: "FORM-ACCESS-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "FORM MANAGEMENT",
    },
  ]);
  const [superVisorPermissions, setSuperVisorPermissions] = useState([
    {
      heading: null,
      text: "Manages everything related to account access and security. Assign this role to users who manage users and teams of users, apply restrictions with two-factor authentication, password policies and API keys.",
      roleName: "SUPERVISOR",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Account Manager",
      text: "Manages everything related to account access. Provided to all Active accounts to login and use the portal",
      roleName: "ACCOUNT-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Users and Teams",
      text: "Manage users and teams. Provide access to users, and manage their roles.",
      roleName: "USER-TEAMS",
      checked: false,
      hr: true,
      box: false,
      innerHeading: "",
    },

    {
      heading: "Analytics Manager",
      text: " Grants access to versatile reporting and data handling options.Track engagement and communication efficiency Schedule reports - Troubleshoot message delivery - Exchange data",
      roleName: "ANALYTICS-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "FINANCE AND ANALYTICS",
    },
    {
      heading: "Finance Data Manager",
      text: "Manages communication costs, tracks account balance, and performs financial management tasks.",
      roleName: "FINANCE-DATA-MANAGER",
      checked: false,
      hr: true,
      innerHeading: "",
    },
    {
      heading: "Communication Manager",
      text: "Creates engagement scenarios and sends broadcast campaigns.",
      roleName: "COMMUNICATION-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "COMMUNICATION MANAGEMENT",
    },
    {
      heading: "People Manager",
      text: "Manages customer profiles on the data platform.",
      roleName: "PEOPLE-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Live Chat Manager",
      text: "Manages live chat conversations regardless of assignment. Chats can be assigned to them manually only and user reports will be shared. Grants access to versatile reporting and data handling options related to live chat conversations. Track live chat efficiency - Schedule reports - Exchange data",
      roleName: "LIVE-CHAT-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Chatbot Manager",
      text: " Manages chatbot design, support, and usage to achieve business goals. Supervises planned activities and progress. Creates and edits chatbots. Activate or deactivate the bots",
      roleName: "CHAT-BOT-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Chatbot Supervisor",
      text: "Monitor chatbots and analytics",
      roleName: "CHATBOT-SUPERVISOR",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Live Chat User",
      text: "Manages live chat conversations assigned to them Can not manage chats for others. User performance reports will be created",
      roleName: "LIVE-CHAT-AGENT",
      checked: false,
      hr: false,
      innerHeading: "",
    },

    {
      heading: "Chat bot Conversation",
      text: "View bot conversations with the customers.",
      roleName: "CHATBOT-CONVERSATION",
      checked: false,
      hr: false,
      innerHeading: "",
    },
    {
      heading: "Form Access Manager",
      text: "Controls access to customer-filled forms. Assigned to users who need to view and manage submitted forms on the portal.",
      roleName: "FORM-ACCESS-MANAGER",
      checked: false,
      hr: false,
      innerHeading: "FORM MANAGEMENT",
    },
  ]);
  const [agentPermissions, setAgentPermissions] = useState([
    {
      heading: null,
      text: "Manages everything related to account access and security. Assign this role to users who manage users and teams of users, apply restrictions with two-factor authentication, password policies and API keys.",
      roleName: "AGENT",
      checked: false,
      hr: false,
    },
    {
      heading: "Account Manager",
      text: "Manages everything related to account access. Provided to all Active accounts to login and use the portal",
      roleName: "ACCOUNT-MANAGER",
      checked: false,
      hr: true,
    },

    {
      heading: "Live Chat Agent",
      text: "Manages live chat conversations assigned to them Can not manage chats for others. User performance reports will be created",
      roleName: "LIVE-CHAT-AGENT",
      checked: true,
      hr: false,
    },

    {
      heading: "Chat bot Conversation",
      text: "View bot conversations with the customers.",
      roleName: "CHATBOT-CONVERSATION",
      checked: false,
      hr: true,
    },
    {
      heading: "Form Access Manager",
      text: "Controls access to customer-filled forms. Assigned to users who need to view and manage submitted forms on the portal.",
      roleName: "FORM-ACCESS-MANAGER",
      checked: false,
      hr: false,
    },
  ]);

  const [adminSelectedPermissions, setAdminSelectedPermissions] = useState([
    "ACCOUNT-MANAGER",
    "USER-TEAMS",
    "INTEGRATION-MANAGER",
    "ANALYTICS-MANAGER",
    "FINANCE-DATA-MANAGER",
    "COMMUNICATION-MANAGER",
    "PEOPLE-MANAGER",
    "LIVE-CHAT-MANAGER",
    "CHAT-BOT-MANAGER",
    "CHATBOT-SUPERVISOR",
    "CHATBOT-CONVERSATION",
  ]);
  const [supervisorSelectedPermissions, setSupervisorSelectedPermissions] =
    useState(["ACCOUNT-MANAGER"]);
  const [agentSelectedPermissions, setAgentSelectedPermissions] = useState([
    "ACCOUNT-MANAGER",
    "LIVE-CHAT-AGENT",
  ]);
  // for end roles screen

  const handleFilterSelection = (
    targetElement,
    parentElement,
    filerName,
    filterValue
  ) => {
    removeAndAddActiveClass(targetElement, parentElement, "active");

    setSelectedStatusSelection(filerName);
    // changeAgentStatus(filterValue);
  };

  const handleOpenAdminDeleteModal = () => {
    setOpenAdminDeleteModal(true);
  };

  const handleCloseAdminDeleteModal = () => {
    setOpenAdminDeleteModal(false);
  };

  const getUserDetails = () => {
    apiGet(
      `/agent/${decryptedValue(params.publicId)}`,
      onSuccessUserDetails,
      onFailureUserDetails
    );
  };
  const onSuccessUserDetails = (response) => {
    setGenericRoute(response.data.isGenericRoute);
    setUserImage(response?.data.profilePic);
    setUserDetails(response?.data);
    setSelectedStatusSelection(response?.data.status);
  };
  const onFailureUserDetails = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const deleteUser = () => {
    setDltLoader(true);
    apiDelete(
      `/agent/${decryptedValue(params.publicId)}`,
      onSuccess,
      onFailure
    );
  };
  const onSuccess = (response) => {
    if (response.status === 200) {
      setTimeout(() => {
        setDltLoader(false);
        toast.success("User deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1200);
      setTimeout(() => {
        navigate("/users", { replace: true });
        handleCloseAdminDeleteModal();
      }, 2000);
    }
  };
  const onFailure = (error) => {
    console.log(error);
    setDltLoader(false);
  };

  const updateAgent = (e) => {
    e.preventDefault();
    setErrorEnable(true);
    setLoader(true);
    let condition = true;

    if (
      adminSelectedPermissions.filter((val) => val === "ALL-PERMISSION")
        .length !== 0
    ) {
      if (adminSelectedPermissions.length < 12) {
        condition = false;
      }
    } else if (
      supervisorSelectedPermissions.filter((val) => val === "SUPERVISOR")
        .length !== 0
    ) {
      if (supervisorSelectedPermissions.length < 3) {
        condition = false;
      }
    } else if (
      agentSelectedPermissions.filter((val) => val === "AGENT").length !== 0
    ) {
      if (agentSelectedPermissions.length < 4) {
        condition = false;
      }
    }

    let data = {
      companyPublicId: userDetails?.companyPublicId,
      phoneNumberWork: submittedData.phoneNumberWork,
      phoneNumberPrivate: submittedData.phoneNumberPrivate,
      email: submittedData.email,
      country: submittedData.country,
      city: submittedData.city,
      // timeZone: "string",
      agentType: "CUSTOMER_SUPPORT",
      status: selectedStatusSelection,
      isAvailable: userDetails?.isAvailable,
      agentPublicId: userDetails?.publicId,
      firstName: submittedData?.firstName,
      lastName: submittedData?.lastName,
      team: submittedData?.team,
      // profilePic: filePath,
      // team: userDetails?.team,
      password: "",
      isGenericRoute: genericRoute,
      role: {
        name:
          (adminSelectedPermissions.filter((val) => val === "ALL-PERMISSION")
            .length > 0 &&
            "ROLE_ADMIN") ||
          (supervisorSelectedPermissions.filter((val) => val === "SUPERVISOR")
            .length > 0 &&
            "ROLE_SUPERVISOR") ||
          (agentSelectedPermissions.filter((val) => val === "AGENT").length >
            0 &&
            "ROLE_AGENT"),
      },
      permissionsList:
        (adminSelectedPermissions?.filter((val) => val === "ALL-PERMISSION")
          .length > 0 &&
          adminSelectedPermissions) ||
        (supervisorSelectedPermissions?.filter((val) => val === "SUPERVISOR")
          .length > 0 &&
          supervisorSelectedPermissions) ||
        (agentSelectedPermissions?.filter((val) => val === "AGENT").length >
          0 &&
          agentSelectedPermissions),
    };
    if (
      submittedData.email !== "" &&
      submittedData.firstName !== "" &&
      submittedData.lastName !== "" &&
      submittedData.country !== "" &&
      submittedData.city !== "" &&
      submittedData.phoneNumberPrivate !== "" &&
      submittedData.phoneNumberWork !== "" &&
      submittedData.phoneNumberPrivate !== null &&
      submittedData.phoneNumberWork !== null &&
      submittedData.team !== null &&
      condition === true &&
      isNumberValid === true &&
      data.permissionsList !== false
    ) {
      apiPost(`/agent/edit`, onSuccessAgentUpdate, onFailureAgentUpdate, data);
    } else {
      setLoader(false);
    }
  };

  const onSuccessAgentUpdate = (response) => {
    if (response.status === 1) {
      setLoader(false);
      toast.success("User updated successfully ", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setTimeout(() => {
        navigate("/users");
      }, 1000);
    }
  };
  const onFailureAgentUpdate = (error) => {
    console.log(error);
    setLoader(false);
  };
  useEffect(() => {
    if (userDetails !== undefined) {
      let adminPermissionExist =
        userDetails?.agentPermissions?.filter(
          (val) => val?.permission?.name === "ALL-PERMISSION"
        ).length > 0;
      let supervisorPermissionExist =
        userDetails?.agentPermissions?.filter(
          (val) => val?.permission?.name === "SUPERVISOR"
        ).length > 0;
      let agentPermissionExist =
        userDetails?.agentPermissions?.filter(
          (val) => val?.permission?.name === "AGENT"
        ).length > 0;

      // if (adminPermissionExist === true) {
      //   let liveChatPermission = userDetails?.agentPermissions?.filter(
      //     (val) => val?.permission?.name === "LIVE-CHAT-AGENT"
      //   );


      //   var adminPermissionTemp = [...adminPermissions];
      //   setAdminSelectedPermissions((list) => [...list, "ALL-PERMISSION"]);
      //   adminPermissionTemp[0]["checked"] = true;
      //   setAdminpermissions(adminPermissionTemp);

      //   if (liveChatPermission.length > 0) {
      //     let index = adminPermissions
      //       .map((val) => val.roleName)
      //       .indexOf("LIVE-CHAT-AGENT");
      //     adminPermissions[index]["checked"] = true;
      //     setAdminpermissions(adminPermissions);
      //   }

      // }
      if (adminPermissionExist === true) {
        var adminPermissionTemp = [...adminPermissions];
        setAdminSelectedPermissions((list) => [...list, "ALL-PERMISSION"]);
        adminPermissionTemp[0]["checked"] = true;
        setAdminpermissions(adminPermissionTemp);

        let temperoryCondition = [];
        userDetails?.agentPermissions.map((val) => {
          temperoryCondition.push(val?.permission?.name);

          adminPermissions?.map((item, index) => {
            if (val?.permission?.name === item.roleName) {
              adminPermissions[index]["checked"] = true;

              setAdminSelectedPermissions(adminPermissionTemp);
            }
          });
        });
        setAdminSelectedPermissions(temperoryCondition);
      }
      if (supervisorPermissionExist === true) {
        var superVisorPermissionTemp = [...superVisorPermissions];
        setSupervisorSelectedPermissions((list) => [...list, "SUPERVISOR"]);
        superVisorPermissionTemp[0]["checked"] = true;
        setSuperVisorPermissions(superVisorPermissionTemp);

        let temperoryCondition = [];
        userDetails?.agentPermissions.map((val) => {
          temperoryCondition.push(val?.permission?.name);

          superVisorPermissions?.map((item, index) => {
            if (val?.permission?.name === item.roleName) {
              superVisorPermissions[index]["checked"] = true;
              setSuperVisorPermissions(superVisorPermissionTemp);
            }
          });
        });
        setSupervisorSelectedPermissions(temperoryCondition);
      }
      if (agentPermissionExist === true) {
        var agentPermissionsTemp = [...agentPermissions];
        setAgentSelectedPermissions((list) => [...list, "AGENT"]);
        agentPermissionsTemp[0]["checked"] = true;
        setAgentPermissions(agentPermissionsTemp);

        let temperoryCondition = [];
        userDetails?.agentPermissions.map((val) => {
          temperoryCondition.push(val?.permission?.name);

          agentPermissions?.map((item, index) => {
            if (val?.permission?.name === item.roleName) {
              agentPermissions[index]["checked"] = true;
              setAgentPermissions(agentPermissionsTemp);
            }
          });
        });
        setAgentSelectedPermissions(temperoryCondition);
      }
    }
    setSubmittedData({
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      email: userDetails?.email,
      phoneNumberPrivate: userDetails?.phoneNumberPersonal,
      phoneNumberWork: userDetails?.phoneNumberWork,
      team: userDetails?.team !== null ? userDetails?.team?.publicId : "",
      country: userDetails?.country,
      city: userDetails?.city,
    });
  }, [userDetails]);

  const handleAdminPermission = (index, permission, checked) => {
    if (decryptedValue(params?.publicId) === decryptedValue(auth?.agentId)) {
      toast.error("Role change not permitted", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (decryptedValue(params.publicId) !== decryptedValue(auth.agentId)) {
      if (checked === false && index === 0) {
        let firstindex = agentSelectedPermissions
          .map(function (o) {
            return o;
          })
          .indexOf("AGENT");

        if (firstindex > -1) {
          agentSelectedPermissions.splice(firstindex, 1);
          agentPermissions[0]["checked"] = false;
          setAgentPermissions(agentPermissions);
          setAgentSelectedPermissions(agentSelectedPermissions);
        }
        let Supervisorindex = supervisorSelectedPermissions
          .map(function (o) {
            return o;
          })
          .indexOf("SUPERVISOR");

        if (Supervisorindex > -1) {
          supervisorSelectedPermissions.splice(Supervisorindex, 1);
          superVisorPermissions[0]["checked"] = false;
          setSuperVisorPermissions(superVisorPermissions);
          setSupervisorSelectedPermissions(supervisorSelectedPermissions);
        }
      }

      var adminPermissionTemp = [...adminPermissions];
      if (index === 0) {
        if (checked === false) {
          setAdminSelectedPermissions((list) => [...list, permission]);
          adminPermissionTemp[index]["checked"] = true;
          setAdminpermissions(adminPermissionTemp);
        } else {
          adminPermissionTemp[index]["checked"] = false;
          setAdminpermissions(adminPermissionTemp);
          let firstindex = adminSelectedPermissions
            .map(function (o) {
              return o;
            })
            .indexOf("ALL-PERMISSION");
          if (firstindex > -1) {
            adminSelectedPermissions.splice(firstindex, 1);
            setAdminSelectedPermissions(adminSelectedPermissions);
          }
        }
      } else {
        if (
          permission === "LIVE-CHAT-AGENT" ||
          permission === "FORM-ACCESS-MANAGER"
        ) {
          if (checked === false) {
            setAdminSelectedPermissions((list) => [...list, permission]);
            adminPermissionTemp[index]["checked"] = true;
            setAdminpermissions(adminPermissionTemp);
          } else {
            adminPermissionTemp[index]["checked"] = false;
            setAdminpermissions(adminPermissionTemp);
            const otherIndex = adminSelectedPermissions.indexOf(permission);
            if (otherIndex > -1) {
              adminSelectedPermissions.splice(otherIndex, 1);
              setAdminSelectedPermissions(adminSelectedPermissions);
            }
          }
        }
      }
    }
  };
  const handleSupervisorPermission = (index, permission, checked) => {
    if (decryptedValue(params.publicId) === decryptedValue(auth.agentId)) {
      toast.error("Role change not permitted", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (decryptedValue(params.publicId) !== decryptedValue(auth.agentId)) {
      if (checked === false && index === 0) {
        let firstindex = agentSelectedPermissions
          .map(function (o) {
            return o;
          })
          .indexOf("AGENT");

        if (firstindex > -1) {
          agentSelectedPermissions.splice(firstindex, 1);
          agentPermissions[0]["checked"] = false;
          setAgentPermissions(agentPermissions);
          setAgentSelectedPermissions(agentSelectedPermissions);
        }
      }
      let adminIndex = adminSelectedPermissions
        .map(function (o) {
          return o;
        })
        .indexOf("ALL-PERMISSION");

      if (adminIndex > -1) {
        adminSelectedPermissions.splice(adminIndex, 1);
        adminPermissions[0]["checked"] = false;
        setAdminpermissions(adminPermissions);
        setAdminSelectedPermissions(adminSelectedPermissions);
      }

      var superVisorPermissionTemp = [...superVisorPermissions];
      if (permission !== "ACCOUNT-MANAGER") {
        if (index === 0) {
          if (checked === false) {
            setSupervisorSelectedPermissions((list) => [...list, permission]);
            superVisorPermissionTemp[index]["checked"] = true;
            setSuperVisorPermissions(superVisorPermissionTemp);
          } else {
            superVisorPermissionTemp[index]["checked"] = false;
            setSuperVisorPermissions(superVisorPermissionTemp);
            let firstindex = supervisorSelectedPermissions
              .map(function (o) {
                return o;
              })
              .indexOf("SUPERVISOR");

            if (firstindex > -1) {
              supervisorSelectedPermissions.splice(firstindex, 1);
              setSupervisorSelectedPermissions(supervisorSelectedPermissions);
            }
          }
        } else {
          if (checked === false) {
            setSupervisorSelectedPermissions((list) => [...list, permission]);
            superVisorPermissionTemp[index]["checked"] = true;
            setSuperVisorPermissions(superVisorPermissionTemp);
          } else {
            superVisorPermissionTemp[index]["checked"] = false;
            setSuperVisorPermissions(superVisorPermissionTemp);
            const otherIndex =
              supervisorSelectedPermissions.indexOf(permission);
            if (otherIndex > -1) {
              supervisorSelectedPermissions.splice(otherIndex, 1);
              setSupervisorSelectedPermissions(supervisorSelectedPermissions);
            }
          }
        }
      }
    }
  };
  const handleAgentPermission = (index, permission, checked) => {
    if (decryptedValue(params?.publicId) === decryptedValue(auth?.agentId)) {
      toast.error("Role change not permitted", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (decryptedValue(params.publicId) !== decryptedValue(auth.agentId)) {
      let adminIndex = adminSelectedPermissions
        .map(function (o) {
          return o;
        })
        .indexOf("ALL-PERMISSION");

      if (adminIndex > -1) {
        adminSelectedPermissions.splice(adminIndex, 1);
        adminPermissions[0]["checked"] = false;
        setAdminpermissions(adminPermissions);
        setAdminSelectedPermissions(adminSelectedPermissions);
      }
      let Supervisorindex = supervisorSelectedPermissions
        .map(function (o) {
          return o;
        })
        .indexOf("SUPERVISOR");
      if (Supervisorindex > -1) {
        let removeSupervisorRole = supervisorSelectedPermissions.splice(
          Supervisorindex,
          1
        );
        supervisorSelectedPermissions.splice(Supervisorindex, 1);
        superVisorPermissions[0]["checked"] = false;
        setSuperVisorPermissions(superVisorPermissions);
        setSupervisorSelectedPermissions(supervisorSelectedPermissions);
      }

      var agentPermissionsTemp = [...agentPermissions];
      if (permission !== "ACCOUNT-MANAGER") {
        if (index === 0) {
          if (checked === false) {
            setAgentSelectedPermissions((list) => [...list, permission]);
            agentPermissionsTemp[index]["checked"] = true;
            setAgentPermissions(agentPermissionsTemp);
          } else {
            agentPermissionsTemp[index]["checked"] = false;
            setAgentPermissions(agentPermissionsTemp);
            let firstindex = agentSelectedPermissions
              .map(function (o) {
                return o;
              })
              .indexOf("AGENT");

            if (firstindex > -1) {
              agentSelectedPermissions.splice(firstindex, 1);
              setAgentSelectedPermissions(agentSelectedPermissions);
            }
          }
        } else {
          if (checked === false) {
            setAgentSelectedPermissions((list) => [...list, permission]);
            agentPermissionsTemp[index]["checked"] = true;
            setAgentPermissions(agentPermissionsTemp);
          } else {
            agentPermissionsTemp[index]["checked"] = false;
            setAgentPermissions(agentPermissionsTemp);
            const otherIndex = agentSelectedPermissions.indexOf(permission);
            if (otherIndex > -1) {
              agentSelectedPermissions.splice(otherIndex, 1);
              setAgentSelectedPermissions(agentSelectedPermissions);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (socketResponse !== undefined) {
      if (socketResponse.type == "FILE_URL") {
        setUserImage(socketResponse?.data.filePath);
      }
    }
  }, [socketResponse]);

  // const handleFile = (e) => {
  //   var file = e.target.files[0];
  //   var formData = new FormData();
  //   formData.append("file", file);
  //   formData.append("agentPublicId", auth.agentId);
  //   formData.append("companyId", auth.companyId);
  //   apiPost(
  //     `/chat/upload-file`,
  //     onSuccessFileUpload,
  //     onFailureFileUpload,
  //     formData
  //   );
  // };
  // const onSuccessFileUpload = (response) => {
  //   if (response.status === 1) {
  //     setFilePath(response.message);
  //   }
  // };
  // const onFailureFileUpload = (error) => {
  //   console.log(error);
  // };

  useEffect(() => {
    if (filePath !== "") {
      fetchUrl(filePath);
    }
  }, [filePath]);

  const fetchUrl = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const data = {
        fileId: filePath,
      };
      socket.send(JSON.stringify({ type: "get-file-url", data: data }));
    }
  };

  return (
    <div className="adminDetailsWrp">
      <div className="uk-container">
        <div className="backBtnWrp">
          <div className="backBtn">
            {location.pathname.includes("profile") === true ? (
              <Link to="/dashboard">
                <ChevronLeft /> Back to dashboard
              </Link>
            ) : (
              <Link to="/users">
                <ChevronLeft /> Back to all users
              </Link>
            )}
          </div>
          <div className="dltBtn">
            {location.pathname.includes("profile") === false && (
              <button type="button" onClick={handleOpenAdminDeleteModal}>
                Delete user
              </button>
            )}
          </div>
        </div>
        <div className="detailsWrp">
          <div
            className="uk-grid "
            uk-grid=""
            uk-height-match="target: > div > .customStyle"
          >
            <div className="uk-width-1-3">
              <div className="customStyle">
                <div className="detailContent adminDetailBoxes heightNew">
                  {userDetails?.firstName === undefined ? (
                    <div className="dataNotFound">
                      {/* {details === null ? <div uk-spinner=""></div> : "Data Not Found"} */}
                      <div uk-spinner=""></div>
                    </div>
                  ) : (
                    <div>
                      <div className="adminImg">
                        {userImage === null || userImage === undefined ? (
                          <span className="InitialImage">
                            {userDetails?.firstName.substring(0, 1)}
                          </span>
                        ) : (
                          <img src={chatBaseUrl + userImage} alt="" />
                        )}
                        {/* <div uk-form-custom="" className="profileImgUpload">
                      <input
                        type="file"
                        aria-label="Custom controls"
                        onChange={(e) => handleFile(e)}
                      />
                      <button type="button">
                        <Camera />
                      </button>
                    </div> */}
                      </div>
                      {/* <div className="onlineDropDownWrp">
                        <span
                          className={
                            (selectedStatusSelection === "ONLINE" &&
                              "markOption onlineMark") ||
                            (selectedStatusSelection === "AWAY" &&
                              "markOption awayMark") ||
                            (selectedStatusSelection === "BREAK" &&
                              "markOption busyMark")
                          }
                        ></span>
                        <p className="agentStatusName">
                          {selectedStatusSelection}
                        </p>
                        <ChevronDown />
                        <div uk-dropdown="mode: click" className="navDropDown">
                          <ul className="uk-nav uk-dropdown-nav">
                            <li>
                              <span> User status</span>
                            </li>
                            {populateAgentStatus()}
                          </ul>
                        </div>
                      </div> */}
                      <div className="numberInput namewrp">
                        <form action="">
                          <input
                            type="text"
                            // placeholder="Name"
                            value={`${
                              userDetails?.firstName +
                              " " +
                              userDetails?.lastName
                            }`}
                            disabled
                          />
                          {/* <button type="button">
                        <Edit />
                      </button> */}
                        </form>
                      </div>
                      <div className="numberInput">
                        <form action="">
                          <input
                            type="text"
                            // placeholder="Role"
                            defaultValue={
                              userDetails?.role !== null
                                ? userDetails?.role.replace("ROLE_", "")
                                : ""
                            }
                            readOnly
                            disabled
                          />
                          {/* <button type="button">
                        <Edit />
                      </button> */}
                        </form>
                      </div>
                      <div className="numberInput">
                        <form action="">
                          <input
                            type="text"
                            // placeholder="Enter phone number"
                            defaultValue={userDetails?.phoneNumberWork}
                            disabled
                          />
                          {/* <button type="button">
                        <Edit />
                      </button> */}
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="uk-width-2-3">
              <div className="customStyle">
                <div className="userProfile adminDetailBoxes heightNew">
                  {userDetails?.lastLogin === undefined ? (
                    <div className="dataNotFound">
                      {/* {details === null ? <div uk-spinner=""></div> : "Data Not Found"} */}
                      <div uk-spinner=""></div>
                    </div>
                  ) : (
                    <div>
                      <div className="boxHeading">
                        <span>User profile</span>
                      </div>
                      <div className="contentBox">
                        <div className="contentBoxHeading">
                          <span>This week usage summary (coming soon)</span>
                        </div>
                        <div className="txtwrp">
                          <div className="leftTxt">
                            <p>
                              Available minutes{" "}
                              <span>
                                {userDetails?.availableMin === null
                                  ? "-"
                                  : userDetails?.availableMin}
                              </span>
                            </p>
                          </div>
                          <div className="leftTxt">
                            <p>
                              Away minutes{" "}
                              <span>
                                {userDetails?.awayMin === null
                                  ? "-"
                                  : userDetails?.awayMin}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="contentBox">
                        <div className="contentBoxHeading">
                          <span>Account information</span>
                        </div>
                        <div className="txtwrp">
                          <div className="leftTxt">
                            <p>
                              <Purchase /> Last login{" "}
                              <span>
                                {userDetails?.lastLogin === null
                                  ? "-"
                                  : moment(userDetails?.lastLogin).format(
                                      "DD/MM/YYYY"
                                    )}
                              </span>
                            </p>
                          </div>
                          <div className="leftTxt">
                            <p>
                              <Locked /> Last password change{" "}
                              <span>
                                {userDetails?.lastPasswordChange === null
                                  ? "-"
                                  : moment(
                                      userDetails?.lastPasswordChange
                                    ).format("DD/MM/YYYY")}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="contentBox">
                        <div className="txtwrp">
                          <div className="leftTxt">
                            <p>
                              Enabled user{" "}
                              <span>
                                {dateWithAtTime(userDetails?.createDateTime)}
                              </span>
                            </p>
                          </div>
                          <div className="leftTxt">
                            <p>
                              Created by{" "}
                              <span>
                                {userDetails?.createdByName === null
                                  ? "-"
                                  : userDetails?.createdByName}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="adminDetailTabbing">
          <ul uk-tab="" className="tabBtn">
            <li>
              <a href="/">Details</a>
            </li>
            <li>
              <a href="/">Roles</a>
            </li>
          </ul>

          <ul className="uk-switcher uk-margin">
            <li>
              <DetailTab
                userDetails={userDetails}
                updateAgent={updateAgent}
                setSubmittedData={setSubmittedData}
                submittedData={submittedData}
                errorEnable={errorEnable}
                setIsNumberValid={setIsNumberValid}
                isNumberValid={isNumberValid}
                loader={loader}
              />
            </li>

            <li>
              <RoleTab
                userDetails={userDetails}
                updateAgent={updateAgent}
                checked={checked}
                adminPermissions={adminPermissions}
                setAdminpermissions={setAdminpermissions}
                superVisorPermissions={superVisorPermissions}
                setSuperVisorPermissions={setSuperVisorPermissions}
                agentPermissions={agentPermissions}
                setAgentPermissions={setAgentPermissions}
                adminSelectedPermissions={adminSelectedPermissions}
                setAdminSelectedPermissions={setAdminSelectedPermissions}
                supervisorSelectedPermissions={supervisorSelectedPermissions}
                setSupervisorSelectedPermissions={
                  setSupervisorSelectedPermissions
                }
                agentSelectedPermissions={agentSelectedPermissions}
                setAgentSelectedPermissions={setAgentSelectedPermissions}
                handleAdminPermission={handleAdminPermission}
                handleSupervisorPermission={handleSupervisorPermission}
                handleAgentPermission={handleAgentPermission}
                errorEnable={errorEnable}
                setGenericRoute={setGenericRoute}
                genericRoute={genericRoute}
                loader={loader}
              />
            </li>
          </ul>
        </div>
      </div>
      {openAdminDeleteModal && (
        <DeleteModal
          closeModal={handleCloseAdminDeleteModal}
          dltModalTxt="Are you sure you want to delete this user? This will affect the
                team members of selected user."
          deleteTeam={deleteUser}
          dltLoader={dltLoader}
          heading={"Delete User"}
        />
      )}
    </div>
  );
};

export default UserDetails;
