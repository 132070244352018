const config = {
  // baseUrl: `https://safemaxconsulting.com/api`,
  baseUrl: `https://portal.berrytalks.com/api`,
  // chatBaseUrl: `https://safemaxconsulting.com/`
  // chatBaseUrl: `https://safemaxconsulting.com/node/assets/`
  chatBaseUrl: `https://portal.berrytalks.com/node/assets/`
};

// Later in your component...
const baseUrl = config.baseUrl;
const chatBaseUrl = config.chatBaseUrl;

module.exports = {
  // baseUrl: "http://3.108.209.226:8080",
  baseUrl: baseUrl,
  chatBaseUrl: chatBaseUrl,
};
