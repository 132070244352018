import React from "react";

const ChannelBox = ({
  boxImg,
  boxHeading,
  boxTxt,
  hidden,
  isFbLogn,
  active,
}) => {
  return (
    <div className="contentBox channelBoxes">
      <div className="imgwrp">
        <img
          src={boxImg}
          alt=""
          style={{ borderRadius: boxHeading === "Messenger" ? "20%" : "50%" }}
        />
        {hidden === undefined ? (
          <span className="comingSoonTxt" hidden={hidden}>
            Coming soon
          </span>
        ) : (
          <span className="activeChannelTxt">
            {active === true ? "Active" : "Inactive"}
          </span>
        )}
      </div>
      <div className="txtwrp">
        <h3>{boxHeading}</h3>
        <p>{boxTxt}</p>
      </div>
      <div></div>
    </div>
  );
};

export default ChannelBox;
