import React from "react";
import whatsappLogo from "../../assets/images/channel-whatsapp.png";
import messengerIcon from "../../assets/images/channel-messanger.svg";
import fbIcon from "../../assets/images/channel-facebook.svg";
import instaIcon from "../../assets/images/channel-insta.svg";
import integrationSms from "../../assets/images/channel-Integrations-sms.png";
import phineIcon from "../../assets/images/channel-phone.svg";
import g963Icon from "../../assets/images/channel-g963.svg";
import googleMessenger from "../../assets/images/channel-google-messenger.svg";
import integrationEmail from "../../assets/images/channel-Integrations-email.png";
import viberIcon from "../../assets/images/channel-viber.png";
import webRtcIcon from "../../assets/images/channel-rtc.png";
import messageIcon from "../../assets/images/channel-message.svg";
import zaloIcon from "../../assets/images/channel-zalo.svg";
import KakaoTalkIcon from "../../assets/images/channel-KakaoTalk.svg";
import telegramIcon from "../../assets/images/channel-Telegram.svg";
import channelMessage from "../../assets/images/channel-dark-meesage.svg";
import roboIcon from "../../assets/images/robo-calling.png";
import channelPhone from "../../assets/images/channel-mobile.svg";
import unarmedLogo from "../../assets/images/channel-unnamed.png";
import ringIcon from "../../assets/images/channel-ring.svg";
import { Link, useNavigate } from "react-router-dom";
import { ChevronLeft } from "@carbon/icons-react";

const Channels = () => {
  const navigate = useNavigate();

  const boxData = [
    {
      id: 1,
      boxImg: whatsappLogo,
      boxHeading: "Whatsapp",
      boxContent:
        "Leverage WhatsApp to deliver real-time updates and personalized messages to your global audience.",
      boxLink: "/whatsapp-form",
    },
    {
      id: 2,
      boxImg: messengerIcon,
      boxHeading: "Messenger",
      boxContent:
        "Engage with customers directly through Messenger by connecting your Facebook Page.",
        boxLink: "/messenger-form",
    },
    {
      id: 3,
      boxImg: fbIcon,
      boxHeading: "Facebook Comments",
      boxContent:
        "Manage and respond to comments on your Facebook posts directly from our platform.",
        boxLink: "/facebook-form",
    },
    // {
    //   id: 4,
    //   boxImg: instaIcon,
    //   boxHeading: "Instagram Messaging",
    //   boxContent:
    //     "Interact with your customers via direct messages on Instagram Business Accounts.",
    //     boxLink: "/channel-form",
    // },
    // {
    //   id: 5,
    //   boxImg: instaIcon,
    //   boxHeading: "Instagram Comments",
    //   boxContent:
    //     "Reply to comments on your Instagram posts directly from our platform.",
    //     boxLink: "/channel-form",
    // },
    {
      id: 4,
      boxImg: integrationSms,
      boxHeading: "SMS",
      boxContent:
        "Utilize SMS to send timely alerts and promotions directly to your customers' mobile phones.",
        boxLink: "/channel-form",
    },
    {
      id: 5,
      boxImg: roboIcon,
      boxHeading: "Robo Calling",
      boxContent:
        "Activate our robo calling services for efficient and automated voice communication.",
        boxLink: "/voice-form",
    },
    {
      id: 6,
      boxImg: phineIcon,
      boxHeading: "Voice Confirmation",
      boxContent:
        "Enable automated voice order confirmations with integrated text-to-speech for seamless feedback.",
        boxLink: "/voice-form",
    },
    {
      id: 7,
      boxImg: webRtcIcon,
      boxHeading: "WebRTC",
      boxContent:
        "Enable WebRTC for real-time video and audio connectivity in your applications.",
        boxLink: "/channel-form",
    },
    // {
    //   id: 8,
    //   boxImg: g963Icon,
    //   boxHeading: "Apple Messages for Business",
    //   boxContent:
    //     "Interact with your customers via direct messages on Instagram Business Accounts.",
    //     boxLink: "/channel-form",
    // },
    {
      id: 8,
      boxImg: googleMessenger,
      boxHeading: "RCS Business Messaging",
      boxContent:
        "Deliver engaging, multimedia-rich messages with RCS Business Messaging for a superior customer experience.",
        boxLink: "/channel-form",
    },
    {
      id: 9,
      boxImg: integrationEmail,
      boxHeading: "Email",
      boxContent:
        "Optimize your email communications by setting up your email settings.",
        boxLink: "/channel-form",
    },
    {
      id: 10,
      boxImg: viberIcon,
      boxHeading: "Viber for Business",
      boxContent:
        "Reach your customers with secure, instant messaging through Viber for Business.",
        boxLink: "/channel-form",
    },
    // {
    //   id: 12,
    //   boxImg: messageIcon,
    //   boxHeading: "LINE",
    //   boxContent:
    //     "Connect with customers globally and leverage LINE’s rich messaging features for enhanced engagement.",
    //     boxLink: "/channel-form",
    // },
    // {
    //   id: 13,
    //   boxImg: zaloIcon,
    //   boxHeading: "Zalo",
    //   boxContent:
    //     "Communicate effectively with your customers using Zalo’s secure messaging platform.",
    //     boxLink: "/channel-form",
    // },
    // {
    //   id: 14,
    //   boxImg: KakaoTalkIcon,
    //   boxHeading: "Kakao Talk",
    //   boxContent:
    //     "Enhance customer interactions with Kakao Talk’s easy-to-use messaging platform.",
    //     boxLink: "/channel-form",
    // },
    // {
    //   id: 15,
    //   boxImg: telegramIcon,
    //   boxHeading: "Telegram",
    //   boxContent:
    //     "Facilitate customer interactions with your Telegram Bot for seamless messaging.",
    //     boxLink: "/channel-form",
    // },
    {
      id: 11,
      boxImg: channelMessage,
      boxHeading: "Live Chat",
      boxContent:
        "Provide real-time support and engage with visitors directly through live chat on your website.",
        boxLink: "/channel-form",
    },
    // {
    //   id: 17,
    //   boxImg: channelPhone,
    //   boxHeading: "Mobile Applications",
    //   boxContent:
    //     "Enhance user engagement with chat bot and live chat in your mobile app.",
    //     boxLink: "/channel-form",
    // },
    {
      id: 10,
      boxImg: unarmedLogo,
      boxHeading: "Google Play Reviews",
      boxContent:
        "Respond to app reviews directly from your Google Play developer account to improve customer satisfaction.",
        boxLink: "/channel-form",
    },
    // {
    //   id: 19,
    //   boxImg: ringIcon,
    //   boxHeading: "Web Push",
    //   boxContent:
    //     "Keep users informed with timely notifications using web push for seamless engagement.",
    //     boxLink: "/channel-form",
    // },
  ];
  return (
    <>
      <div className="uk-position-relative">
        <div className="channelsTopbar">
          <div className="uk-grid uk-flex-middle" uk-grid="">
            <div className="uk-width-1-2">
              <p>Channels</p>
            </div>
            <div className="uk-width-1-2 uk-text-right">
              <button
                type="button"
                className="btn-1"
                onClick={() => {
                  navigate("/request-form", { replace: true });
                }}
              >
                Request channel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="channelsWrp createNewSegment">
        <div className="uk-container">
          <div className="uk-grid uk-flex-middle" uk-grid="">
            <div className="uk-width-1-2">
              <div className="backBTn">
                <button
                  type="button"
                  onClick={() => {
                    navigate("/settings/general-setting", { replace: true });
                  }}
                >
                  <ChevronLeft /> Back to Settings
                </button>
              </div>
              <div className="mainHeading uk-padding-remove">
                <h3>Channel Hub</h3>
                <p>
                  View channel details and learn how to connect with your
                  customers.
                </p>
              </div>
            </div>
            <div className="uk-width-1-2">
              <div className="searchBar uk-text-right">
                <input type="text" className="uk-input" placeholder="Search" />
              </div>
            </div>
          </div>
          <div className="channelBoxWrp">
            <div
              className="uk-grid uk-grid-small"
              uk-grid=""
              uk-height-match="target: > div > a > .channelBox"
            >
              {boxData.map((data) => (
                <div className="uk-width-1-4" key={data.id}>
                  <Link to={data.boxLink}>
                    <div className="channelBox">
                      <img src={data.boxImg} alt="" />
                      <h4>{data.boxHeading}</h4>
                      <p>{data.boxContent}</p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Channels;
