const UnassignConversationModal = ({
  closeModal,
  heading,
  fieldLabel,
  saveBtnTxt,
  bulkChatUnassigned,
  modalLoader,
}) => {
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open unassignModal"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <h3>{heading}</h3>
        <div>
          <form className="uk-form-stacked" onSubmit={bulkChatUnassigned}>
            <div>
              <label className="uk-form-label unassignLabel">
                {fieldLabel}
              </label>
            </div>
            <hr />
            <div className="btnWrap uk-margin-top">
              <div></div>
              <div>
                <button className="cnclBtn" onClick={closeModal}>
                  Cancel
                </button>
                <button className="saveBtn w-130" type="submit">
                  {modalLoader ? (
                    <div uk-spinner="" className="loader"></div>
                  ) : (
                    saveBtnTxt
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UnassignConversationModal;
