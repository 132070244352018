export default function Chat(scriptText) {
  // Adding class for styling
  document.body.classList.add("preview-body");
  var primaryColor = "#f6921e";
  var chatBox = document.createElement("div");
  chatBox.id = "customChatBox";
  chatBox.classList.add("preview");
  chatBox.style.position = "fixed";
  chatBox.style.bottom = "40px";
  chatBox.style.right = "40px";
  chatBox.style.width = "375px";
  chatBox.style.border = "1px solid #ccc";
  chatBox.style.borderRadius = "12px";
  chatBox.style.backgroundColor = "#fff";
  chatBox.style.zIndex = "99999999";
  chatBox.style.display = "none";

  // Creating header container
  var header = document.createElement("div");
  header.classList.add("preview-header");
  header.style.padding = "15px";
  header.style.borderRadius = "6px 6px 0 0";
  header.style.margin = "0";
  header.style.zIndex = "99";

  var mainLogo = document.createElement("div");
  mainLogo.style.display = "flex";
  mainLogo.style.alignItems = "flex-start";
  mainLogo.style.justifyContent = "space-between";

  // Creating header logo
  var imgLogo = document.createElement("img");
  imgLogo.src = "http://localhost:3000/assets/ke-large-logo.png";
  imgLogo.alt = "logo";
  imgLogo.width = 60;
  imgLogo.height = 60;
  mainLogo.appendChild(imgLogo);

  // Creating header logo
  var crossLogo = document.createElement("img");
  crossLogo.src = "http://localhost:3000/assets/cross.png";
  crossLogo.alt = "cross";
  crossLogo.width = 40;
  crossLogo.height = 40;
  crossLogo.style.cursor = "pointer";
  crossLogo.style.height = "40px";
  mainLogo.appendChild(crossLogo);
  header.appendChild(mainLogo);

  // Creating header text
  var headerText = document.createElement("h3");
  headerText.textContent = 'Welcom to KE';
  headerText.style.color = "#fff";
  headerText.style.padding = "10px 0";
  headerText.style.fontWeight = "600";
  headerText.style.fontSize = "18px";
  headerText.style.margin = "0";
  headerText.style.fontFamily = "Helvetica";
  header.appendChild(headerText);

  // Creating header paragraph
  var headerP = document.createElement("p");
  headerP.classList.add("preview-header-p");
  headerP.textContent =
    "KE is the largest vertically integrated power utility company of Pakistan.";
  headerP.style.color = "#fff";
  headerP.style.fontWeight = "500";
  headerP.style.fontSize = "14px";
  headerP.style.fontFamily = "Helvetica";
  headerP.style.lineHeight = "23px";
  headerP.style.margin = "0";
  header.appendChild(headerP);
  chatBox.appendChild(header);

  // Creating body
  var body = document.createElement("div");
  body.classList.add("preview-body");
  body.style.padding = "15px 12px";
  body.style.position = "relative";
  body.style.background = "#FAF3EE";
  body.style.height = "350px";
  body.style.overflowY = "scroll";
  chatBox.appendChild(body);

  // Adding custom scrollbar styles to the body
  var style = document.createElement("style");
  style.innerHTML = `
          .preview-body::-webkit-scrollbar {
              background-color: #f5f5f5;
              width: 0px;
          }
          .preview-body::-webkit-scrollbar-thumb {
              background-color: #96acc3;
          }
          .preview-body::-webkit-scrollbar-track {
              background-color: #f5f5f5;
              -webkit-box-shadow: inset 0 0 0px #0000004d;
          }
               .custom-textarea::-webkit-scrollbar {
              background-color: #f5f5f5;
              width: 5px;
          }
          .custom-textarea::-webkit-scrollbar-thumb {
              background-color: #96acc3;
          }
          .custom-textarea::-webkit-scrollbar-track {
              background-color: #f5f5f5;
              -webkit-box-shadow: inset 0 0 5px #0000004d;
          }
      `;
  document.head.appendChild(style);

  // Mock sent message
  var sentMsg2 = createMessage("1", "sentMsg");
  body.appendChild(sentMsg2);

  // // Mock received message
  // var receivedMsg3 = createMessage(`Bill payments`, "recievedMsg");
  // body.appendChild(receivedMsg3);

  // // Mock received message
  var parentDiv = document.createElement("div");
  parentDiv.style.display = "flex";
  parentDiv.style.flexDirection = "column";
  parentDiv.style.alignItems = "center";
  body.appendChild(parentDiv);

  function createButton(text) {
    var button = document.createElement("button");
    button.style.display = "flex";
    button.style.flexDirection = "column";
    button.style.alignItems = "center";
    button.style.margin = "0 auto";
    button.className = "customBtn";
    button.style.background = "#fff";
    button.style.fontSize = "14px";
    button.style.fontFamily = "Helvetica";
    button.style.borderRadius = "50px";
    button.style.width = "60%";
    button.style.marginBottom = "5px";
    button.style.lineHeight = "24px";
    button.style.padding = "6px 0";
    button.textContent = text;
    return button;
  }

  // Mock sent message
  var sentMsg3 = createMessage("2 ", "sentMsg");
  body.appendChild(sentMsg3);

  // Creating footer
  var footer = document.createElement("div");
  footer.classList.add("preview-footer");
  footer.style.display = "flex";
  footer.style.justifyContent = "space-between";
  footer.style.padding = "5px 8px";

  var typeText = document.createElement("div");
  typeText.classList.add("type-text");
  typeText.style.display = "flex";
  typeText.style.borderRadius = "13px";
  typeText.style.border = "1px solid #707070";
  typeText.style.padding = "5px 8px";
  typeText.style.width = "100%";
  typeText.style.alignItems = "flex-start";

  // Creating header logo
  var attach = document.createElement("img");
  attach.src = "http://localhost:3000/assets/attachment.png";
  attach.alt = "cross";
  attach.style.width = "15px";
  attach.style.height = "15px";
  attach.style.marginTop = "3px";

  typeText.appendChild(attach);
  footer.appendChild(typeText);

  var input = document.createElement("textarea");
  input.placeholder = "Type your message here...";
  input.style.border = "none";
  input.style.fontSize = "14px";
  input.classList.add("custom-textarea");
  input.style.fontFamily = "Helvetica, Sans-Serif";
  input.style.width = "100%";
  input.style.color = "#92929e";
  input.style.outline = "none";
  input.style.background = "transparent";
  input.style.minHeight = "fit-content";
  input.style.height = "fit-content";
  input.style.maxHeight = "160px";
  input.style.overflowY = "auto";
  input.rows = "1";

  // Adjust height on input (if needed)
  input.addEventListener("input", function () {
    this.style.height = "auto"; // Reset height
    this.style.height = this.scrollHeight + "px"; // Set height based on scroll height
  });

  typeText.appendChild(input);
  footer.appendChild(typeText);
  chatBox.appendChild(footer);
  document.body.appendChild(chatBox);

  // Append chat box to the document body
  document.body.appendChild(chatBox);

  // Creating chat icon
  var chatBoxIcon = document.createElement("div");
  chatBoxIcon.style.borderRadius = "50%";
  chatBoxIcon.style.width = "80px";
  chatBoxIcon.style.height = "80px";
  chatBoxIcon.style.display = "flex";
  chatBoxIcon.style.alignItems = "center";
  chatBoxIcon.style.justifyContent = "center";
  chatBoxIcon.style.position = "fixed";
  chatBoxIcon.style.bottom = "40px";
  chatBoxIcon.style.right = "40px";
  chatBoxIcon.style.zIndex = "9999999999";
  document.body.appendChild(chatBoxIcon);

  var chatIcon = document.createElement("img");
  chatIcon.src = "http://localhost:3000/assets/chat-icon.png";
  chatIcon.alt = "chat";
  chatIcon.style.width = "50px";
  chatIcon.style.height = "auto";
  chatIcon.style.cursor = "pointer";
  chatBoxIcon.appendChild(chatIcon);

  function applyPrimaryColor(color) {
    const receivedMsgs = document.querySelectorAll(".receivedMsg");
    receivedMsgs.forEach((msg) => {
      msg.style.background = color;
    });
    chatBox.style.border = `1px solid ${color}`;
    chatBoxIcon.style.backgroundColor = color;

    header.style.backgroundColor = color;
    body.style.borderBottom = `1px solid ${color}`;
  }

  function getBotIdFromUrl() {
    var urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("botId") || "";
  }

  function getBaseURL() {
    var baseURl = document.currentScript.href;
    return baseURl;
  }

  var baseURL = getBaseURL();
  var botId = getBotIdFromUrl();
  var scriptUrl = document.currentScript.src;
  var url = new URL(scriptUrl);
  var botId = url.searchParams.get("botId");

  // Toggle chatbox visibility
  chatBoxIcon.addEventListener("click", async function () {
    chatBox.style.display = chatBox.style.display === "none" ? "block" : "none";
    chatBoxIcon.style.display =
      chatBox.style.display === "none" ? "flex" : "none";
  });
  applyPrimaryColor(primaryColor);

  // Close chatbox
  crossLogo.addEventListener("click", function () {
    chatBox.style.display = "none";
    chatBoxIcon.style.display = "flex";
  });

  input.addEventListener("keypress", function (e) {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default behavior
      var message = this.value.trim(); // Trim whitespace from message
      this.value = ""; // Clear the textarea immediately
      this.style.height = "auto";

      if (message) {
        // Only send if there's a message
        var lastMsg = body.lastElementChild;
        var isSentMsg = lastMsg && lastMsg.classList.contains("sentMsg");
        // Determine if the message should be sent or received
        var msgContainer = createMessage(
          message,
          isSentMsg ? "sentMsg" : "receivedMsg"
        );
        body.appendChild(msgContainer);
        body.scrollTop = body.scrollHeight;
      }
    }
  });

  // Function to create message element
  function createMessage(text, type, imgSrc, color) {
    var msgContainer = document.createElement("div");
    msgContainer.classList.add(type);
    msgContainer.style.display = "flex";
    msgContainer.id = "chat-Container";

    msgContainer.style.gap = "10px";
    msgContainer.style.marginBottom = "15px";
    msgContainer.style.marginTop = "15px";
    msgContainer.style.alignItems = "flex-end";
    msgContainer.style.width = "100%";

    var img = document.createElement("img");
    img.alt = "Avatar";
    img.style.width = "35px";
    img.style.height = "35px";
    img.style.transform = "translateY(20px)";

    if (imgSrc) {
      img.src = imgSrc;
    }

    var message = document.createElement("pre");
    message.classList.add("message");
    message.textContent = text;
    message.style.borderRadius = "4px";
    message.style.fontSize = "14px";
    message.style.lineHeight = "18px";
    message.style.fontFamily = "Helvetica, Sans-Serif";
    message.style.borderRadius =
      type === "sentMsg" ? "20px 20px 0 20px" : "20px 20px 20px 0";
    message.style.width = "auto";
    message.style.textWrap = "wrap";

    if (type === "sentMsg") {
      msgContainer.style.justifyContent = "flex-end";
      message.style.background = "#97ADC4";
      message.style.color = "#fff";
      message.style.padding = "8px 15px";
      message.classList.add("sentMsg");
      img.src = "http://localhost:3000/assets/girl-icon.png";
      msgContainer.appendChild(message);
      msgContainer.appendChild(img);
    } else {
      msgContainer.style.justifyContent = "flex-start";
      message.style.color = "#fff";
      message.style.padding = "15px 15px";
      message.style.backgroundColor = color;
      message.classList.add("receivedMsg");
      msgContainer.appendChild(img);
      msgContainer.appendChild(message);
    }

    return msgContainer;
  }

  fetch(`${baseURL}api/webChatBot/${botId}`)
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      primaryColor = data.color;
      if (imgLogo) {
        imgLogo.src = `${baseURL}node/assets/${data.logoImageUrl}`;
      }

      applyPrimaryColor(primaryColor);
      var chatContainer = document.getElementById("chat-Container");
      if (chatContainer) {
        // Clear previous messages if needed
        chatContainer.innerHTML = "";

        // Create and append the single message
        var receivedMessage = createMessage(
          data.welcomeMessage,
          "receivedMsg",
          imgLogo.src,
          data.color
        );
        chatContainer.appendChild(receivedMessage);

        data.suggestions.forEach((suggestion) => {
          console.log(suggestion.text);
            // chatContainer.appendChild(suggestion.text);
            // body.appendChild(button);

          const button = createButton(suggestion.text);
          parentDiv.appendChild(button);
        });
      } else {
        console.error("Chat container not found");
      }
    })
    .catch((error) => console.error("Error fetching data:", error));
};


