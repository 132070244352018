import React, { useState } from "react";
import Switch from "react-switch";
import { Checkmark, ChevronLeft, View, ViewOff } from "@carbon/icons-react";
import { Link } from "react-router-dom";
const SelectPackage = () => {
  const [loader, setLoader] = useState(false);
  const [pwdPassword, setPwdPassword] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [numbers, setNumbers] = useState(false);
  const [lengths, setLengths] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const handleViewPassword = () => {
    setViewPassword(!viewPassword);
  };
  const handleViewConfirmPassword = () => {
    setViewConfirmPassword(!viewConfirmPassword);
  };
  return (
    <div className="addTeamWrp">
      <div className="uk-container">
        <div className="backBtn">
          <Link to="/super-admin-company">
            <ChevronLeft /> Back to all companies
          </Link>
        </div>
        <div className="addTeamHeading">
          <h3>Select Package</h3>
          <p>Enter package detail to be used for company.</p>
        </div>
        <div className="addTeamBox">
          <div className="boxHeading">
            <h3>Package information</h3>
          </div>
          <div className="formwrp">
            <form autoComplete="off">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">Title</label>
                    <input
                      type="text"
                      className="uk-input"
                      placeholder="Enter Title"
                    />
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">Price</label>
                    <input
                      type="text"
                      className="uk-input"
                      placeholder="Enter Price"
                    />
                  </div>
                </div>
                {/* <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="work_number">Work number</label>
                    <input
                      type="text"
                      placeholder="Work number"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          phoneNumberWork: e.target.value,
                        });
                      }}
                    />
                    {submittedData.phoneNumberWork === "" && errorEnable && (
                      <div className="formErrors">work number is required</div>
                    )}
                  </div>
                </div> */}
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="mobile_no">WhatsApp conversation</label>
                    <input
                      type="number"
                      placeholder="5,000"
                      className="uk-input"
                    />
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="mobile_no">Live agent</label>
                    <input type="number" className="uk-input selectPackageInput" />
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="email">FAQ Chatbot</label>
                    <select name="team" className="uk-select">
                      <option>2</option>
                      <option>3</option>
                    </select>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="team">WhatsApp Number</label>
                    <input
                      autoComplete="off"
                      type="text"
                      placeholder="923167412632"
                      // value={submittedData.email}
                      className="uk-input"
                      // autoComplete="new-email"
                    />
                    {/* {submittedData.team === "" && errorEnable && (
                      <div className="formErrors">Please select team</div>
                    )} */}
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="team">Advance Chatbot</label>
                    <select name="team" className="uk-select">
                      <option>2</option>
                      <option>3</option>
                    </select>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="team">Company Public ID</label>
                    <input
                      autoComplete="off"
                      type="text"
                      placeholder="7133782601749728325"
                      // value={submittedData.email}
                      className="uk-input"
                      // autoComplete="new-email"
                    />
                    {/* {submittedData.team === "" && errorEnable && (
                      <div className="formErrors">Please select team</div>
                    )} */}
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="email">Renewal</label>
                    <select name="team" className="uk-select">
                      <option>Monthly</option>
                      <option>Weekly</option>
                    </select>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="email">Status</label>
                    <select name="team" className="uk-select">
                      <option>Active</option>
                      <option>Inactive</option>
                    </select>
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="adminRoleWrp">
                    <div className="btnwrp">
                      <button className="btn-1" type="submit">
                        Cancel
                      </button>
                      <button className="btn-2 w-70" type="submit">
                        {loader ? (
                          <div uk-spinner="" className="loader"></div>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SelectPackage;