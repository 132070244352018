import { ChevronLeft } from "@carbon/icons-react";
import { useState } from "react";
// import { baseUrl } from "../../Constant";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import UIkit from "uikit";
const baseUrl = process.env.REACT_APP_BASEURL;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [validationError, setValidationError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loader, setLoader] = useState(false);
  const [emailSent, setEmailSent] = useState("");
  const handleSendEmail = () => {
    setLoader(true);
    let forgetValue =
      inputValue === "" ? localStorage.getItem("_otpEmail") : inputValue;
    if (validationError === "" && forgetValue !== null) {
      axios
        .get(baseUrl + `/auth/forgetPassword/${forgetValue}`, {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        })
        .then(
          (response) => {
            // if (response?.data?.message === "Otp Send Successfully") {
            if (response?.data?.status === 262) {
              setTimeout(() => {
                setLoader(false);
                // setEmailSent("Sent");
                localStorage.setItem("_otpEmail", inputValue);

                // setTimeout(() => {
                //   UIkit.modal("#modal-center").show();
                // }, 4000);
                // setTimeout(() => {
                // window.location.href = `/otp`;
                //   UIkit.modal("#modal-center").hide();
                // }, 8000);

                setTimeout(() => {
                  // window.location.href = `/app/otp`;
                  navigate("/otp", { replace: true });
                }, 300);

                // }, 3000);
              }, 2500);
            } else {
              // if (response?.data.status === 200) {
              if (response?.data.status === 260) {
                setTimeout(() => {
                  setLoader(false);
                  // toast.success("Status changes successfully", {
                  //   position: toast.POSITION.TOP_RIGHT,
                  //   autoClose: 1000,
                  // });
                }, 1000);
                setTimeout(() => {
                  setEmailSent("Email has been send to admin");
                }, 2500);
              }
            }
          },
          (error) => {
            // if (
            //   error?.response?.status === 404 &&
            //   error?.response?.data?.message ===
            //     "Email Not found / User Not registered"
            // )
            if (
              error?.response?.status === 404 &&
              error?.response?.data?.status === 261
            )
              toast.error("Invalid login", {
                position: toast.POSITION.TOP_RIGHT,
              });
            setLoader(false);
          }
        );
    } else {
      setLoader(false);
      if (inputValue === "") {
        setEmailError("Email address is required"); // Set the error message
      } else {
        setEmailError(""); // Clear the error message if inputValue is not empty
      }
    }
  };
  const handleBacktoLogin = () => {
    navigate("/", { replace: true });
  };

  const validateInput = (value) => {
    // Email regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Mobile number regex pattern (Assuming a 10-digit mobile number format)
    const mobileRegex = /^[0-9]{11}$/;

    // Check if it's a valid email or a valid mobile number
    if (emailRegex.test(value)) {
      setValidationError("");
    } else if (mobileRegex.test(value)) {
      setValidationError("");
    } else {
      // setValidationError("Invalid email or mobile number");
      setValidationError("Invalid email address");
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
    if (value !== "") {
      setEmailError(""); // Clear the error message when input is not empty
    }
    validateInput(value);
  };
  return (
    <>
      {emailSent === "" && (
        <div className="forgetContainer">
          <div className="innnerSection">
            <h2 className="heading">Password Reset</h2>
            <p className="mainDescription">
              Please provide your registered email address to initiate the
              password reset process.
            </p>
            <input
              className="field"
              type="email"
              id="resetEmailInput"
              placeholder="Email Address"
              onChange={handleInputChange}
            />
            {validationError && (
              <div className="formErrors">{validationError}</div>
            )}
            {emailError && <div className="formErrors">{emailError}</div>}
            <div className="btnWrapSpaceBetween uk-margin-xlarge-top">
              <div>
                <button className="backbtn" onClick={handleBacktoLogin}>
                  <ChevronLeft /> Back to Log in
                </button>
              </div>
              <div>
                <button
                  id="resetSubmitBtn"
                  className="saveBtn w-130"
                  onClick={handleSendEmail}
                  disabled={loader ? true : false}
                >
                  {loader ? (
                    <div uk-spinner="" className="loader"></div>
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {emailSent === "Email has been send to admin" && (
        <div className="forgetContainer">
          <div className="innnerSection">
            <h2 className="heading">Request to change password</h2>
            <p className="mainDescription">
              A request to change the password has been sent to your
              administrator.{" "}
            </p>
            {/* <p className="secondaryDescription">
              You need to click the link and follow the instructions from the
              email we sent to reset your password.
            </p> */}
            <div className="uk-margin-xlarge-top">
              <button className="backbtn" onClick={handleBacktoLogin}>
                <ChevronLeft /> Back to Log in
              </button>
            </div>
            <div
              id="modal-center"
              className="uk-flex-top"
              uk-modal=""
              style={{ padding: "0" }}
            >
              <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical spinnerModal">
                <span className="modalSpinner" uk-spinner=""></span>
                <div className="redirectMsg">
                  <p>Redirecting ...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {emailSent === "Email has been send to admin" && (
        <div className="forgetContainer">
          <div className="innnerSection">
            <h2 className="heading">Email sent</h2>
            <p className="mainDescription">Reset link sent to {inputValue}</p>
            <p className="secondaryDescription">
              You need to click the link and follow the instructions from the
              email we sent to reset your password.
            </p>
            <div className="uk-margin-xlarge-top">
              <button className="backbtn" onClick={handleBacktoLogin}>
                <ChevronLeft /> Back to Log in
              </button>
            </div>
            <div
              id="modal-center"
              className="uk-flex-top"
              uk-modal=""
              style={{ padding: "0" }}
            >
              <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical spinnerModal">
                <span className="modalSpinner" uk-spinner=""></span>
                <div className="redirectMsg">
                  <p>Redirecting ...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default ForgotPassword;
