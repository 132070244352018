import React, { useEffect, useRef, useState } from "react";
import InternalChatContact from "./components/InternalChatContact";
import InternalChatMessages from "./components/InternalChatMessages";
import InternalChatProfile from "./components/InternalChatProfile";
import { useNavigate, useOutletContext } from "react-router-dom";
import { apiGet, apiGetSuperAdmin } from "../../Utils/apiServices";
import ChatMsgBeforeLoadScreen from "../chat/components/ChatMsgBeforeLoadScreen";
import { useAuth } from "../../Context/AuthProvider";
import { decryptedValue } from "../../Utils/RouteHashConversion";
import { useWebSocket } from "../../socket/WebSocketManager";

const InternalChat = () => {
  const { socket, setChatFirstContactDetails } = useWebSocket();
  const navigate = useNavigate();
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const { auth } = useAuth();
  // **************************************** state start ***************************************
  const [internalChatContactList, setInternalChatContactList] = useState();
  const [allMemebers, setAllMemebers] = useState();
  const [isActiveAllMemebers, setIsActiveAllMemebers] = useState(false);

  //   for contacts
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMoreContacts, setHasMoreContacts] = useState(true);
  // for messages
  const [paginationCalled, setPaginationCalled] = useState(false);
  const [pageForChatMessages, setPageForChatMessages] = useState(0);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [loader, setLoader] = useState(false);
  const [contactMessages, setContactMessages] = useState(null);
  const [agentDetails, setAgentDetails] = useState();
  const [openFileLoader, setOpenFileLoader] = useState(false);
  const [newUser, setNewUser] = useState();
  // const bottomRef = useRef(null);

  // for gallery start
  const [pageCount, setPageCount] = useState(0);
  const [galleryData, setGalleryData] = useState(null);
  const [apiCalledGallery, setApiCalledGallery] = useState(false);
  // for  gallery end
  const isActiveChannel = companyDetails?.activeChannels.filter(function (o) {
    return o.chanelId === "FACEBOOK";
  });
  // **************************************** state end *****************************************

  useEffect(() => {
    if (agentDetails !== undefined) {
      // console.log("agentDetails", agentDetails);
    }
  }, [agentDetails]);
  useEffect(() => {
    // console.log("internalChatContactList", internalChatContactList);
  }, [internalChatContactList]);
  useEffect(() => {
    // console.log("contactMessages", contactMessages);
  }, [contactMessages]);

  // useEffect(() => {
  //   // console.log("agentDetails", agentDetails);

  //   // if (agentDetails !== undefined) {
  //   //   console.log("agentDetails", agentDetails);
  //   //   if (agentDetails.conversationId === undefined) {
  //   //     fetchChatList();
  //   //   }
  //   // }
  //   // fetchChatList()
  // }, [internalChatContactList]);
  // **************************************** Api calling start *****************************************
  const getAllContacts = () => {
    apiGet(
      `/internal-chat/get-users/AGENT`,
      onSuccessContacts,
      onFailureContacts
    );
  };
  const onSuccessContacts = (response) => {
    if (response.status === 0) {
      // here we can remove our contact from all agents list
      let filterData = response.data.filter((val) => {
        return val.maskAgentId !== decryptedValue(auth.idNumber);
      });
      setAllMemebers(filterData);
    }
  };
  const onFailureContacts = (error) => {
    console.log(error);
    setAllMemebers([]);
  };

  const getGalleryData = (page) => {
    apiGetSuperAdmin(
      `/chat/files/${decryptedValue(auth.companyId)}?page=${page}&size=${10} `,
      onSuccess,
      onFailure
    );
  };

  const onSuccess = (response) => {
    if (response.status === 0) {
      setGalleryData(response.data.content);
      setPageCount(response?.data?.totalPages);
      setApiCalledGallery(false);
    }
  };
  const onFailure = (error) => {
    console.log(error);
  };

  const getAllContactedChatContacts = () => {
    apiGet(
      `/internal-chat/details`,
      onSuccessContactedContacts,
      onFailureContactedContacts
    );
  };
  const onSuccessContactedContacts = (response) => {
    let page = currentPage + 1;
    setCurrentPage(page);
    if (internalChatContactList === undefined) {
      setInternalChatContactList(response.data.content);
    } else {
      if (page === 1) {
        setInternalChatContactList(response.data.content);
      } else {
        if (response.data.content.length !== 0) {
          setInternalChatContactList((prevMessages) => [
            ...prevMessages,
            ...response.data.content,
          ]);
        }
      }
    }
    if (response.data.content.length === 0) {
      // here we can check if no record found so we can update state not to call api
      setHasMoreContacts(false);
    } else {
      if (response.data.content.length < 20) {
        // here we can check data exist but is lesser than 20 records means no more data exist
        setHasMoreContacts(false);
      }
    }
  };
  const onFailureContactedContacts = (error) => {
    console.log(error);
    // setAllMemebers([]);
  };

  const getChatMessage = (agentId, conversationId) => {
    // console.log(agentId);
    markRead(conversationId);
    subscribeToChat(agentId, conversationId);
    // if (decryptedValue(auth.idNumber) === agentId) {

    fetchChatList();
    // }
    setLoader(true);
    apiGet(
      `/internal-chat/details/${agentId}`,
      onSuccessChatMessages,
      onFailureChatMessages
    );
  };
  const onSuccessChatMessages = (response) => {
    if (
      response.data.content.length > 0 &&
      response.data.content.length === 20
    ) {
      setHasMoreMessages(true);
      setPageForChatMessages((prevCount) => prevCount + 1);
    } else {
      setHasMoreMessages(false);
    }
    setLoader(false);
    // setContactMessages(response?.data.content);
    setContactMessages(response?.data?.content);

    // setNewMessages(response?.data?.content);
  };
  const onFailureChatMessages = (error) => {
    console.log(error);
    setLoader(false);
  };

  // **************************************** Api calling end *****************************************

  function fetchChatList() {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const data = {
        agentPublicId: decryptedValue(auth.idNumber),
      };
      socket.send(
        JSON.stringify({ type: "update-internal-chat-list", data: data })
      );
    }
  }
  function subscribeToChat(agentId, conversationId) {
    // if (agentId === decryptedValue(auth.idNumber)) {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const data = {
        agentId: agentId,
        conversationId: conversationId,
      };
      const message = { type: "internal-subscribe-chat", data: data }; // Adjust the message structure
      console.log("Chat Subscribe", message);
      socket.send(JSON.stringify(message));
    }
    // }
  }

  function markRead(conversationId) {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const data = {
        conversationId: conversationId,
      };
      const message = { type: "internal-read-message", data: data }; // Adjust the message structure
      console.log("mark read", message);
      socket.send(JSON.stringify(message));
    }
  }

  // **************************************** useEffect block start *****************************************

  useEffect(() => {
    getAllContactedChatContacts();
    // getAllContacts();
    getGalleryData(0);
  }, []);
  // **************************************** useEffect block end *****************************************

  const handleScroll = (e) => {
    if (e?.currentTarget?.scrollTop === 0) {
      if (hasMoreMessages) {
        // console.log("calling more");
        getChatMoreMessages(agentDetails.recipientAgentId);
      }
    }
  };

  const getChatMoreMessages = (agentId) => {
    apiGet(
      `/internal-chat/details/${agentId}?page=${pageForChatMessages}`,
      onSuccessChatMoreMessages,
      onFailureChatMoreMessages
    );
  };
  const onSuccessChatMoreMessages = (response) => {
    if (
      response.data.content.length > 0 &&
      response.data.content.length === 20
    ) {
      setHasMoreMessages(true);
      setPageForChatMessages((prevCount) => prevCount + 1);
    } else {
      setHasMoreMessages(false);
    }
    setPaginationCalled(true);
    // setContactMessages(response?.data);
    setContactMessages((prev) => [...response?.data?.content, ...prev]);
  };
  const onFailureChatMoreMessages = (error) => {
    if (error.response.status === 400) {
      if (error.response.data.data === null) {
        setHasMoreMessages(false);
      }
    }
  };

  useEffect(() => {
    if (newUser !== undefined) {
      let newdata = newUser;
      newdata["recipientAgentId"] = newUser.maskAgentId;

      let filterData = internalChatContactList.filter((val) => {
        return val.recipientAgentId === newUser.maskAgentId;
      });
      if (filterData.length === 0) {
        setInternalChatContactList((prev) => [
          newdata,
          ...internalChatContactList,
        ]);
      }
    }
  }, [newUser]);

  useEffect(() => {
    if (socketResponse !== undefined) {
      if (socketResponse.type !== "ping-response") {
        console.log(socketResponse);
      }
      if (socketResponse.type == "send-message-internal-chat") {
        setPaginationCalled(false);
        let message = JSON.parse(socketResponse.data);
        // console.log("----------->", internalChatContactList);
        // console.log("----------->", agentDetails);

        if (internalChatContactList !== undefined) {
          if (
            agentDetails !== undefined &&
            (message.conversationId === agentDetails?.conversationId ||
              // agentDetails?.conversationId === undefined
              agentDetails?.recipientAgentId === message.recipientAgentId)
          ) {
            if (contactMessages !== null) {
              if (contactMessages.length === 0) {
                setContactMessages([message]);
              } else {
                setContactMessages((prevMessages) => [
                  ...prevMessages,
                  message,
                ]);
              }
            }
          }
        }

        // if (agentDetails !== undefined) {
        //   console.log("agentDetails===>", agentDetails);
        //   console.log("agentDetails===>", agentDetails.conversationId);
        //   if (agentDetails.conversationId === undefined) {
        //     let findNewContactReplaceWithNewData =
        //       internalChatContactList.filter((val) => {
        //         return val.maskAgentId === agentDetails.recipientAgentId;
        //       });
        //     console.log(findNewContactReplaceWithNewData);
        //     if (findNewContactReplaceWithNewData.length > 0) {
        //       setAgentDetails(findNewContactReplaceWithNewData);
        //     }
        //   }
        // }
      }

      if (socketResponse.type === "internal-contact-list-update") {
        // setInternalChatContactList(socketResponse?.data.content);

        // console.log("internalChatContactList====>", internalChatContactList);
        // console.log(internalChatContactList);
        // console.log(socketResponse?.data);
        if (
          internalChatContactList === undefined ||
          internalChatContactList.length === 0
        ) {
          // setTimeout(() => {
          setInternalChatContactList(socketResponse?.data.content);
          // }, 5000);
        } else {
          var data = [...internalChatContactList];
          // console.log(
          //   "internalChatContactList conditoion",
          //   internalChatContactList.length
          // );
          // console.log(
          //   "internalChatContactList conditoion",
          //   internalChatContactList
          // );
          // console.log(
          //   "socketResponse.data.content",
          //   socketResponse.data.content
          // );

          const updateArray1WithArray2 = (arr1, arr2) => {
            arr2.forEach((item2) => {
              const index = arr1.findIndex(
                (item1) => item1.recipientAgentId === item2.recipientAgentId
              );
              if (index !== -1) {
                arr1[index] = item2;
              }
            });
          };

          const updatedArray = updateArray1WithArray2(
            internalChatContactList,
            socketResponse.data.content
          );
          // console.log(updatedArray);

          if (socketResponse.data.content.length > 0) {
            // console.log("Comes to condition");
            // console.log(
            //   internalChatContactList.length ===
            //     socketResponse.data.content.length
            // );
            // console.log("Comes to condition")
            if (
              internalChatContactList.length ===
              socketResponse.data.content.length
            ) {
              // data = socketResponse.data.content
              setInternalChatContactList(socketResponse.data.content);
            } else {
              let previousData = internalChatContactList.fil;
            }
          }
          // console.log("data", data);

          // const previousData = [...internalChatContactList];
          // const newData = socketResponse?.data.content;
          // const newMsgContact = previousData.filter(
          //   (item1) => item1.conversationId === undefined
          // );
          // console.log("newMsgContact", newMsgContact);
          // const result = previousData.filter((item1) =>
          //   newData.some(
          //     (item2) => item1.conversationId !== item2.conversationId
          //   )
          // );
          // var result;
          // if (newData.length > 0) {
          // const result = previousData.filter(
          //   (item1) =>
          //     !newData.some(
          //       (item2) => item1.conversationId === item2.conversationId
          //     )
          // );
          // }
          //  else {
          //   result = previousData;
          // }

          // console.log(result);

          // previousData.filter((item1) =>
          //   newData.some(
          //     (item2) => item1.conversationId !== item2.conversationId
          //   )
          // );
          // previousData.filter((item1) =>
          //   newData.some(
          //     (item2) => item1.conversationId !== item2.conversationId
          //   )
          // );

          // if (newData.length !== 0) {
          // console.log("result, ", result);
          // console.log("previousData, ", previousData);

          // let dataToBeStore = result.length === 0 ? previousData : result;

          // if (agentDetails !== undefined) {
          //   console.log("agentDetails", agentDetails);
          //   if(agentDetails.conversationId===undefined){
          //     console.log("calling")
          //     fetchChatList()
          //   }
          // }

          // var updateddata = [];
          // if (result.length === previousData.length) {
          //   updateddata = newData;
          // } else {
          //   updateddata = previousData;
          // }

          // setInternalChatContactList(updateddata);

          if (agentDetails !== undefined) {
            // console.log("agentDetails===>", agentDetails);
            // console.log("agentDetails===>", agentDetails.conversationId);
            if (agentDetails.conversationId === undefined) {
              // let findNewContactReplaceWithNewData = updateddata.filter(
              //   (val) => {
              //     return val.recipientAgentId === agentDetails.recipientAgentId;
              //   }
              // );
              // console.log(findNewContactReplaceWithNewData);
              // if (findNewContactReplaceWithNewData.length > 0) {
              //   setAgentDetails(findNewContactReplaceWithNewData[0]);
              // }
              let findNewContactReplaceWithNewData = data.filter((val) => {
                return val.recipientAgentId === agentDetails.recipientAgentId;
              });
              // console.log(findNewContactReplaceWithNewData);
              if (findNewContactReplaceWithNewData.length > 0) {
                // getChatMessage(findNewContactReplaceWithNewData[0].recipientAgentId,findNewContactReplaceWithNewData[0].conversationId)
                setAgentDetails(findNewContactReplaceWithNewData[0]);
              }
            }
          }
        }

        // if (agentDetails !== undefined) {
        //   console.log("agentDetails===>", agentDetails);
        //   console.log("agentDetails===>", agentDetails.conversationId);
        //   if (agentDetails.conversationId === undefined) {
        //     let findNewContactReplaceWithNewData =
        //       internalChatContactList.filter((val) => {
        //         return val.maskAgentId === agentDetails.recipientAgentId;
        //       });
        //     console.log(findNewContactReplaceWithNewData);
        //     if (findNewContactReplaceWithNewData.length > 0) {
        //       console.log(findNewContactReplaceWithNewData)
        //       // setAgentDetails(findNewContactReplaceWithNewData);
        //     }
        //   }
        // }
        // }
      }
    }
  }, [socketResponse]);

  return (
    <>
      <div className="chatwrp chatwrpMessage ">
        <div className="chatWrpContent">
          <div className="chatHeader">
            <ul
              className="uk-subnav top-tabbing "
              id="rednerInternalChatTab"
              uk-switcher="connect: .chatTabbing"
            >
              {(userPermissions?.includes("LIVE-CHAT-AGENT") ||
                userPermissions?.includes("LIVE-CHAT-MANAGER")) && (
                <li>
                  <a onClick={() => navigate("/chat", { replace: true })}>
                    {userPermissions.length > 0
                      ? userPermissions?.includes("LIVE-CHAT-MANAGER")
                        ? "Live Chat"
                        : userPermissions?.includes("LIVE-CHAT-AGENT") &&
                          "My Chat"
                      : ""}
                  </a>
                </li>
              )}
              {(userPermissions?.includes("CHATBOT-CONVERSATION") ||
                userPermissions?.includes("CHAT-BOT-MANAGER") ||
                userPermissions?.includes("CHATBOT-SUPERVISOR")) && (
                <li>
                  {userPermissions.length > 0 ? (
                    <a onClick={() => navigate("/chat-bot", { replace: true })}>
                      Bot Chat
                    </a>
                  ) : (
                    ""
                  )}
                </li>
              )}
              <li className="uk-active">
                {userPermissions.length > 0 ? (
                  <a
                    onClick={() =>
                      navigate("/internal-chat", { replace: true })
                    }
                  >
                    Internal Chat
                  </a>
                ) : (
                  ""
                )}
              </li>
              <li className="uk-active">
                {userPermissions.length > 0 && isActiveChannel.length > 0 ? (
                  <a
                    onClick={() => navigate("/fb-comments", { replace: true })}
                  >
                    Facebook Comments
                  </a>
                ) : (
                  ""
                )}
              </li>
            </ul>

            <div className="chatSearchFilter">
              <ul className="uk-switcher chatTabbing">
                {(userPermissions?.includes("LIVE-CHAT-AGENT") ||
                  userPermissions?.includes("LIVE-CHAT-MANAGER")) && <li></li>}
                {(userPermissions?.includes("CHATBOT-CONVERSATION") ||
                  userPermissions?.includes("CHAT-BOT-MANAGER") ||
                  userPermissions?.includes("CHATBOT-SUPERVISOR")) && <li></li>}
                <li>
                  <div className="searchAndFilter"></div>
                </li>
              </ul>
            </div>
            <div className="chatTabContent">
              <div className="toggleSwitch  uk-margin-right">
                {/* <span className={checked && "toggleChecked"}>Away</span> */}
              </div>
              <div className="toggleSwitch"></div>
            </div>
          </div>
          <hr />
        </div>
        <div className="chatWrpItem" style={{paddingLeft: '16px'}}>
          <ul className="uk-switcher chatTabbing">
            {(userPermissions?.includes("LIVE-CHAT-AGENT") ||
              userPermissions?.includes("LIVE-CHAT-MANAGER")) && <li></li>}
            {(userPermissions?.includes("CHATBOT-CONVERSATION") ||
              userPermissions?.includes("CHAT-BOT-MANAGER") ||
              userPermissions?.includes("CHATBOT-SUPERVISOR")) && <li></li>}
            <li>
              <div className="uk-grid" uk-grid="">
                <InternalChatContact
                  internalChatContactList={internalChatContactList}
                  allMemebers={allMemebers}
                  setIsActiveAllMemebers={setIsActiveAllMemebers}
                  isActiveAllMemebers={isActiveAllMemebers}
                  hasMoreContacts={hasMoreContacts}
                  setHasMoreContacts={setHasMoreContacts}
                  setCurrentPage={setCurrentPage}
                  getChatMessage={getChatMessage}
                  setAgentDetails={setAgentDetails}
                  setContactMessages={setContactMessages}
                  setNewUser={setNewUser}
                  setPageForChatMessages={setPageForChatMessages}
                  getAllContacts={getAllContacts}
                  setAllMemebers={setAllMemebers}
                />
                {contactMessages !== null && loader === false && (
                  <>
                    <InternalChatMessages
                      contactMessages={contactMessages}
                      hasMoreMessages={hasMoreMessages}
                      pageForChatMessages={pageForChatMessages}
                      agentDetails={agentDetails}
                      openFileLoader={openFileLoader}
                      setOpenFileLoader={setOpenFileLoader}
                      getGalleryData={getGalleryData}
                      galleryData={galleryData}
                      apiCalledGallery={apiCalledGallery}
                      setApiCalledGallery={setApiCalledGallery}
                      pageCount={pageCount}
                      handleScroll={handleScroll}
                      paginationCalled={paginationCalled}
                      fetchChatList={fetchChatList}
                      // bottomRef={bottomRef}
                    />
                    <InternalChatProfile agentDetails={agentDetails} />
                  </>
                )}
                {contactMessages === null && loader === false && (
                  <ChatMsgBeforeLoadScreen />
                )}
                {loader === true && (
                  <div className="uk-width-4-5 uk-padding-remove-left">
                    <div className="chatSeletionWrp">
                      <div className="chatSelectionContent">
                        <div className=" uk-width-expand spinner">
                          <div className="uk-text-center uk-position-center ">
                            <span uk-spinner="ratio: 2" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </li>
            <li></li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default InternalChat;
