import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { apiGet } from "../Utils/apiServices";
// import { useAuth } from "../Context/AuthProvider";

const OpenRoutes = ({ children, userPermissions }) => {
  const location = useLocation();
  const [result, setResult] = useState(null);
  const navigate = useNavigate();

  const pageWiseRouting = [
    {
      route: "/dashboard",
      permission: ["FINANCE-DATA-MANAGER", "ANALYTICS-MANAGER"],
      allPermissionsMandatory: false,
    },
    {
      route: "/chat",
      permission: ["LIVE-CHAT-MANAGER", "LIVE-CHAT-AGENT"],
      allPermissionsMandatory: false,
    },
    {
      route: "/chat-bot",
      permission: [
        "CHATBOT-CONVERSATION",
        "CHAT-BOT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      allPermissionsMandatory: false,
    },
    {
      route: "/broadcast",
      permission: ["COMMUNICATION-MANAGER"],
      allPermissionsMandatory: false,
    },
    {
      route: "/contacts",
      permission: ["PEOPLE-MANAGER"],
      allPermissionsMandatory: false,
    },
    {
      route: "/users",
      permission: ["USER-TEAMS"],
      allPermissionsMandatory: false,
    },
  ];

  function hasPermissions(
    userPermissions,
    routePermissions,
    allPermissionsMandatory
  ) {
    if (allPermissionsMandatory) {
      // Check if all required permissions are present in the user's permissions
      return routePermissions.every((permission) =>
        userPermissions.includes(permission)
      );
    } else {
      // Check if at least one required permission is present in the user's permissions
      return routePermissions.some((permission) =>
        userPermissions.includes(permission)
      );
    }
  }

  function findAccessibleRoute(userPermissions) {
    for (const routeConfig of pageWiseRouting) {
      if (
        hasPermissions(
          userPermissions,
          routeConfig.permission,
          routeConfig.allPermissionsMandatory
        )
      ) {
        return routeConfig.route;
      }
    }
    // Return a default route or handle the case where no route is accessible
    return "/";
  }

  const getPermissions = () => {
    apiGet(`/auth/user-permission`, onSuccessPermissions, onFailurePermissions);
  };
  const onSuccessPermissions = (response) => {
    let permissions = [];
    response?.data.map((val) => {
      permissions?.push(val?.permission?.name);
    });
    if (response.data.length === 0) {
      setResult("");
    }

    if (permissions.length > 0) {
      // setPermissionStored(permissions);
      const accessibleRoute = findAccessibleRoute(permissions);
      if (localStorage.getItem("_displayed") === "true") {
        setResult(accessibleRoute);
      } else {
        setTimeout(() => {
          // let result = checkPermissionExistForDashboard(permissions, [
          //   "FINANCE-DATA-MANAGER",
          //   "ANALYTICS-MANAGER",
          // ]);
          setResult(result);
        }, 4200);
      }
    }
  };
  const onFailurePermissions = (error) => {
    // navigate(`/`, {
    //   replace: true,
    // });

    console.log(error);
    if (error.response.status === 401) {
      if (localStorage.getItem("_token") !== null) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("_token") !== null) {
      getPermissions();
    }
  }, []);
  // const { auth } = useAuth();
  // var token = auth?.token === null ? null : auth?.token;
  var token =
    localStorage.getItem("_token") === null
      ? null
      : localStorage.getItem("_token");

  if (token !== null) {
    if (result === "") {
      // window.location.href = "/super-admin-home"

      navigate(`/super-admin-home`, {
        replace: true,
      });
    } else {
      return <Navigate to={result} state={{ path: location.pathname }} />;
    }
  }
  return children;
};

export default OpenRoutes;

