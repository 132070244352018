import React, { createContext, useContext, useState } from "react";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { apiPost, apiGet } from "../../../Utils/apiServices";
import axios from "axios";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { decryptedValue } from "../../../Utils/RouteHashConversion";
import { useLocation } from "react-router-dom";

export const ContextChatBotWeb = createContext();
export const useContextChatBotWeb = () => useContext(ContextChatBotWeb);

export const ContextChatBotProviderWeb = ({ children }) => {
  const [openDialogModal, setOpenDialogModal] = useState(false);
  const [clickedSections, setClickedSections] = useState([]);
  const [textareaValue, setTextareaValue] = useState("");
  const [visibleSection, setVisibleSection] = useState("trigger");
  const [triggerValue, settriggerValue] = useState([]);
  const [inputTriggerValue, setInputTriggerValue] = useState("");
  const [triggerValueAction, settriggerValueAction] = useState([]);
  const [inputTriggerValueAction, setInputTriggerValueAction] = useState([]);
  const [isKeywordVisibleAction, setKeywordVisibleAction] = useState(false);
  const [isKeywordVisible, setKeywordVisible] = useState(false);
  const [textareaValues, setTextareaValues] = useState({});
  const [sectionData, setSectionData] = useState([]);
  const [selectSection, setSelectSection] = useState("trigger");
  const [sectionDataArray, setSectionDataArray] = useState([]);
  const [editable, setEditable] = useState(false);
  const [saveButtonClicked, setSaveButtonClicked] = useState(true);
  const [sections, setSections] = useState([]);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isDialogVisibleAction, setIsDialogVisibleAction] = useState(false);
  const [isDialogVisibleForm, setIsDialogVisibleForm] = useState(false);
  const initialSelectedDialogOnButton = Array(sections?.length).fill(null);
  const initialSelectedDialogOnButtonTimings = Array(sections?.length).fill(
    null
  );
  const [isEditingBName, setIsEditingBName] = useState(false);
  const [textBName, setTextBName] = useState("");
  const [sectionValues, setSectionValues] = useState([]);
  const [disableItem, setDisableItem] = useState(false);
  const [botUpdated, setBotUpdated] = useState([]);

  const [paramsState, setParamsState] = useState("");
  const [dropdownDialogID, setDropdownDialogId] = useState("");
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState(false);
  const [teamsData, setTeamsData] = useState(false);
  const [disableAll, setdisableAll] = useState(false);
  const [listMenuDisable, setlistMenuDisable] = useState(false);
  const [hasTextarea, setHasTextarea] = useState(false);
  const [getBotWidgetId, setgetBotWidgetId] = useState(null);

  const [btnCount, setbtnCount] = useState(false);
  const [teamNameSingle, setNameSingle] = useState("");
  // const [searchParams] = useSearchParams();
  // const botWidgetId = searchParams.get("botWidgetId") || "";

  useEffect(() => { }, [teamNameSingle]);
  
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const botWidgetId = params.get("botWidgetId");
  const [scriptModal, setScriptModal] = useState(false);
  const showToastName = [];
  const [disableLive, setDisableLive] = useState(false);
  if (sections?.length > 0) {
    initialSelectedDialogOnButton[0] = "Select an existing dialog";
  }
  const pathname = window.location.pathname;
  const companyId =
    localStorage.getItem("_companyId") === null
      ? ""
      : localStorage.getItem("_displayed") !== null
      ? decryptedValue(localStorage.getItem("_companyId"))
      : "";

  const selectedDialog = sections?.find(
    (dialog) => dialog.dialogueTitle === selectSection
  );
  const [isDialogVisibleArray, setIsDialogVisibleArray] = useState(
    new Array(selectedDialog?.settings?.length).fill(false)
  );
  const [isActionVisibleArray, setIsActionVisibleArray] = useState(
    new Array(selectedDialog?.settings?.length).fill(false)
  );
  const [isFormVisibleArray, setIsFormVisibleArray] = useState(
    new Array(selectedDialog?.settings?.length).fill(false)
  );
  const navigate = useNavigate();
  const [bot, setBot] = useState({
    webBotName: "",
    companyId: companyId,
    buttonKeywords: [],
    channelId: "WEBCHATBOT",
    webBotWidgetId: botWidgetId,
    sections: [],
  });
  const updatedKeywords = triggerValue?.filter(
    (keyword) => keyword !== "Add a trigger"
  );

  useEffect(() => {
    setBot((prevBot) => ({
      ...prevBot,
      webBotName: textBName,
      companyId: companyId,
      sections: sections,
    }));
  }, [sections, triggerValue, companyId, textBName]);


  useEffect(() => {
    if (paramsState) {
      setBot((prevBot) => ({
        ...prevBot,
        webBotWidgetId: getBotWidgetId
      }));
    }
  }, [ getBotWidgetId]);



  //socket images

  useEffect(() => {
    const buttonCount = selectedDialog?.settings.filter(
      (item) => item.type === "button"
    ).length;

    if (
      buttonCount >= 1 &&
      selectedDialog?.settings.some((item) =>
        ["textarea", "image", "video", "file"].includes(item.type)
      )
    ) {
      setDisableItem(true);
    } else {
      setDisableItem(false);
    }
  }, [selectedDialog?.settings, selectedDialog?.settings.length]);

  useEffect(() => {
    if (
      (selectedDialog?.settings.length === 1 &&
        selectedDialog?.settings.some((item) => item.type === "image")) ||
      selectedDialog?.settings.some((item) => item.type === "video") ||
      selectedDialog?.settings.some((item) => item.type === "file") ||
      selectedDialog?.settings.some((item) => item.type === "textarea")
    ) {
      setdisableAll(true);
    } else {
      setdisableAll(false);
    }

    const buttonCount = (
      selectedDialog?.settings.filter((item) => item.type === "button") || []
    ).length;

    const hasRequiredType = selectedDialog?.settings.some((item) =>
      ["textarea", "image", "video", "file"].includes(item.type)
    );

    if (buttonCount >= 1 && buttonCount <= 3 && !hasRequiredType) {
      setHasTextarea(true);
    } else {
      setHasTextarea(false);
    }

    if (
      buttonCount >= 3 ||
      selectedDialog?.settings.some((item) =>
        item?.value?.startsWith("{list_menu}")
      )
    ) {
      setbtnCount(true);
    } else {
      setbtnCount(false);
    }
  }, [selectedDialog?.settings, selectedDialog?.settings.length]);

  useEffect(() => {
    if (
      selectedDialog?.settings.length === 1 &&
      selectedDialog?.settings.some((item) =>
        item?.value?.startsWith("{list_menu}")
      )
    ) {
      setlistMenuDisable(true);
    } else {
      setlistMenuDisable(false);
    }
  }, [selectedDialog?.settings, selectedDialog?.settings.length]);

  //socket images
  const [selectedDialogOnButton, setSelectedDialogOnButton] = useState(
    initialSelectedDialogOnButton
  );

  const [selectedDialogOnButtonTimings, setSelectedDialogOnButtonTimings] =
    useState(initialSelectedDialogOnButtonTimings);

  const toggleDialogVisibility = (index) => {
    if (!isDialogVisibleArray[index]) {
      const updatedVisibilityArray = [...isDialogVisibleArray];
      updatedVisibilityArray[index] = true;
      setIsDialogVisibleArray(updatedVisibilityArray);
    }
  };

  const toggleDialogHidden = (index) => {
    const updatedVisibilityArray = [...isDialogVisibleArray];
    updatedVisibilityArray[index] = false;
    setIsDialogVisibleArray(updatedVisibilityArray);
  };

  const toggleActionVisibility = (index) => {
    if (!isActionVisibleArray[index]) {
      const updatedVisibilityArray = [...isActionVisibleArray];
      updatedVisibilityArray[index] = true;
      setIsActionVisibleArray(updatedVisibilityArray);
    }
  };

  const toggleActionHidden = (index) => {
    const updatedVisibilityArray = [...isActionVisibleArray];
    updatedVisibilityArray[index] = false;
    setIsActionVisibleArray(updatedVisibilityArray);
  };

  const toggleDialogVisibilityAgent = () => {
    setIsDialogVisible(false);
  };

  const toggleDialogVisibilityAction = () => {
    setIsDialogVisibleAction(!isDialogVisibleAction);
  };

  const toggleDialogVisibilityForm = () => {
    setIsDialogVisibleForm(!isDialogVisibleForm);
  };

  const addSection = (sectionType, name, value) => {
    const SettingDialogue = sections.find(
      (dialogue) => dialogue.dialogueTitle === selectSection
    );
    let sectionSettings = SettingDialogue?.settings;
    const newSection = {
      type: sectionType,
      name: name,
      value: value,
      id: uuidv4(),
    };
    sectionSettings?.push(newSection);
    const updatedDialogues = sections.map((dialogue) => {
      if (dialogue.dialogueTitle === selectSection) {
        return { ...dialogue, settings: sectionSettings };
      } else {
        return dialogue;
      }
    });
    setSections(updatedDialogues);
  };

  const addSectionTrigger = (sectionType) => {
    const newSection = {
      type: sectionType,
      id: uuidv4(),
    };

    setBot((prevBot) => ({
      ...prevBot,
      buttonKeywords: [...prevBot.buttonKeywords, newSection],
    }));
  };

  //for list textarea
  const addSectionList = (sectionType, value) => {
    const SettingDialogue = sections.find(
      (dialogue) => dialogue.dialogueTitle === selectSection
    );

    let sectionSettings = SettingDialogue?.settings;
    const newSection = {
      type: sectionType,
      value: value,
      id: uuidv4(),
    };
    sectionSettings?.push(newSection);

    const updatedDialogues = sections.map((dialogue) => {
      if (dialogue.dialogueTitle === selectSection) {
        return { ...dialogue, settings: sectionSettings };
      } else {
        return dialogue;
      }
    });
    setSections(updatedDialogues);
  };

  //end textarea list
  const selectSectionFunc = (sectionName) => {
    setSelectSection(sectionName);
  };

  const handleEditClickBName = () => {
    setIsEditingBName(true);
  };

  const handleSaveClickBName = () => {
    setIsEditingBName(false);
  };

  const handleChangeBName = (e) => {
    setTextBName(e.target.value);
  };

  const toggleFormVisibility = (index) => {
    if (!isFormVisibleArray[index]) {
      const updatedVisibilityArray = [...isFormVisibleArray];
      updatedVisibilityArray[index] = true;
      setIsFormVisibleArray(updatedVisibilityArray);
    }
  };
  const insertEmoji = (id, sectionID, emoji) => {
    const inputField = document.getElementById(id);
    if (inputField) {
      const selectionStart = inputField.selectionStart || 0;
      const selectionEnd = inputField.selectionEnd || 0;
      const cursorPos =
        selectionStart === selectionEnd ? selectionStart : selectionEnd;
      const updatedData = sections.map((section) => {
        const updatedSettings = section?.settings.map((setting) => {
          if (setting.id === sectionID) {
            const currentValue = setting.value || "";
            const textArray = Array.from(currentValue);
            const emojiLength = Array.from(emoji).length;
            const adjustedCursorPos = cursorPos + emojiLength;
            textArray.splice(adjustedCursorPos, 0, ...Array.from(emoji));
            const newValue = textArray.join("");
            return {
              ...setting,
              value: newValue,
            };
          }
          return setting;
        });

        return { ...section, settings: updatedSettings };
      });

      setSections(updatedData);
    }
  };

  const onEmojiClick = (emojiData, event, id) => {
    const inputField = document.getElementById(id);
    if (inputField) {
      insertEmoji(id, emojiData.emoji);
    }
  };

  const handleInputChangeSection = (id, property, value) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings.map((setting) => {
        if (setting.id === id) {
          return {
            ...setting,
            [property]: value,
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });
    setSections(updatedData);
  };

  const handleInputChangeSectionButton = (id, property, value, timingsText) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings?.map((setting) => {
        if (setting.id === id) {
          return {
            ...setting,
            [property]: value,
            name: "button_" + value,
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });
    setSections(updatedData);
  };

  const handleInputTimingsText = (id, property, value) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings?.map((setting) => {
        if (setting.id === id) {
          return {
            ...setting,
            timings: {
              ...setting.timings,
              [property]: value,
            },
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });
    setSections(updatedData);
  };

  const handleInputChangeSectionButtonExternal = (id, property, value) => {
    const updatedData = [...sections];
    const sectionToUpdate = updatedData.find((section) =>
      section?.settings?.find((item) => item.id === id)
    );
    if (sectionToUpdate) {
      sectionToUpdate.settings = sectionToUpdate.settings.map((setting) => {
        if (setting.id === id) {
          return {
            ...setting,
            detail: {
              ...setting.detail,
              [property]: value,
            },
          };
        }
        return setting;
      });
    }
    setSections(updatedData);
  };

  const handleInputChangeSectionAction = (id, property, newValue) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings?.map((setting) => {
        if (setting.id === id) {
          const newItemId = generateUniqueId();
          const updatedValue = Array.isArray(setting[property])
            ? [...setting[property], { value: newValue, id: newItemId }]
            : [{ value: newValue, id: newItemId }];
          return {
            ...setting,
            [property]: updatedValue,
            name: "button_" + newValue,
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });
    setSections(updatedData);
    setSectionValues([...sectionValues, newValue]);
  };

  const handleInputChangeSectionCatalogue = (
    id,
    property,
    value,
    productId
  ) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings?.map((setting) => {
        if (setting.id === id) {
          return {
            ...setting,
            detail: {
              ...setting.detail,
              [property]: value,
              name: `button_${value}`,
            },
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });
    setSections(updatedData);
  };

  const handleDialogSelectOnButtonTimings = (value, index) => {
    const updatedSelectedDialogOnButton = [...selectedDialogOnButtonTimings];
    updatedSelectedDialogOnButton[index] = value;
    setSelectedDialogOnButtonTimings(updatedSelectedDialogOnButton);
    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings?.map((setting) => {
        if (setting.id === index) {
          return {
            ...setting,
            timings: {
              ...setting.timings,
              type: value,
              startTime: "",
              endTime: "",
            },
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });
    setSections(updatedData);
  };

  const handleTimeChange = (index, timeName, value) => {
    setSections((prevSections) => {
      return prevSections.map((section) => {
        return {
          ...section,
          settings: section.settings.map((setting) => {
            if (
              setting.timings &&
              setting.timings.type === "Manual" &&
              setting.id === index
            ) {
              return {
                ...setting,
                timings: {
                  ...setting.timings,
                  [timeName]: value,
                },
              };
            }
            return setting;
          }),
        };
      });
    });
  };

  const handleAddButton = (
    sectionId,
    index,
    selectValue,
    type,
    keyword,
    validation,
    productId,
    productHeaderText,
    productFooterText,
    externalRequest
  ) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings?.map((setting) => {
        if (setting.id === sectionId) {
          return {
            ...setting,
            detail: {
              selectValue:
                type === "typeCatalog" || type === 0 ? null : selectValue,
              type: type || "typeAgent",
              selectedId:
                type === "typeAgent" || type === "typeCatalog" || type === 0
                  ? null
                  : setting?.detail?.selectedId || dropdownDialogID,
              validation: validation || null,
              ...(type === "typeCatalog"
                ? {
                    productId: productId.name,
                    productHeaderText: productHeaderText.name,
                    productFooterText: productFooterText.name,
                    catalogId: sectionId,
                  }
                : {}),
              ...(type === "typeExternal"
                ? {
                    externalRequest: externalRequest || null,
                  }
                : {}),
            },
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });
    setSections(updatedData);
  };

  const generateUniqueId = () => {
    return "unique_id_" + Date.now() + "_" + Math.floor(Math.random() * 1000);
  };

  const handleKeyPressKeywordSyn = (e, sectionId) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleInputChangeSectionAction(sectionId, "keyword", sectionValues);
      setSectionValues("");
      setKeywordVisibleAction(false);
    }
  };
  const handleChange = (e) => {
    setSectionValues(e.target.value);
  };

  const handleRemoveTriggerAction = (index, item) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section.settings.map((setting) => {
        if (setting.keyword && setting.keyword.length > 0) {
          const updatedKeywords = setting.keyword.filter(
            (keyword, i) =>
              !(
                i === index &&
                keyword.value === item.value &&
                keyword.id === item.id
              )
          );
          return { ...setting, keyword: updatedKeywords };
        }
        return setting;
      });

      return { ...section, settings: updatedSettings };
    });

    setSections(updatedData);
  };

  const handleDialogSelectOnButton = (dialog, index, dialogId, teamName) => {
    setDropdownDialogId(dialogId);
    const updatedSelectedDialogOnButton = [...selectedDialogOnButton];
    updatedSelectedDialogOnButton[index] = dialog;
    setSelectedDialogOnButton(updatedSelectedDialogOnButton);

    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings?.map((setting) => {
        if (setting.id === index) {
          // Create the "detail" object if it doesn't exist
          const updatedDetail = setting.detail || {};

          // Update the "selectedId" in the "detail" object
          updatedDetail.selectedId = dialogId;

          if (updatedDetail.selectValue === dialog) {
            setNameSingle(teamName);
          }

          return {
            ...setting,
            detail: updatedDetail,
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });

    setSections(updatedData);
  };

  const handleSelectOnButtonValidation = (validation, settingId) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings?.map((setting) => {
        if (setting.id === settingId) {
          return {
            ...setting,
            detail: {
              ...setting.detail,
              validation: validation || null,
            },
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });
    setSections(updatedData);
  };

  const handleSectionClosing = (id) => {
    const updatedData = sections.map((section) => {
      if (section?.settings.some((setting) => setting.id === id)) {
        const updatedSettings = section?.settings.filter(
          (setting) => setting.id !== id
        );
        return { ...section, settings: updatedSettings };
      }
      return section;
    });
    setSections(updatedData);
  };

  const toggleKeywordVisibility = () => {
    setKeywordVisible(!isKeywordVisible);
  };

  const toggleKeywordVisibilityAction = () => {
    setKeywordVisibleAction(!isKeywordVisibleAction);
  };
  const toggleSection = (sectionName) => {
    const uniqueSectionName = `${sectionName}-${Date.now()}`;
    setClickedSections([...clickedSections, uniqueSectionName]);
  };

  const handleSectionClose = (sectionName) => {
    const updatedSections = clickedSections?.filter(
      (section) => section !== sectionName
    );
    setClickedSections(updatedSections);

    const updatedTextareaValues = { ...textareaValue };
    delete updatedTextareaValues[sectionName];
    setTextareaValue(updatedTextareaValues);
  };

  const handleOpenDilaogModal = () => {
    setOpenDialogModal(true);
  };

  const handleCloseDialogModal = () => {
    setOpenDialogModal(false);
  };

  const addTextareaValue = (sectionName, value) => {
    setTextareaValues({
      ...textareaValues,
      [sectionName]: value,
    });
  };

  const addClickedSection = (sectionName) => {
    setClickedSections([...clickedSections, sectionName]);
  };

  const removeClickedSection = (sectionName) => {
    setClickedSections(
      clickedSections?.filter((section) => section !== sectionName)
    );
  };

  const toggleSectionMain = (sectionName) => {
    if (!visibleSection) {
      setVisibleSection("starting_text");
    } else {
      setVisibleSection(sectionName);
    }
  };

  const handleInputChange = (e) => {
    setInputTriggerValue(e.target.value);
  };

  const handleInputChangeAction = (e) => {
    setInputTriggerValueAction(e.target.value);
  };

  const handleKeyPressAction = (e) => {
    if (e.key === "Enter") {
      settriggerValueAction([...triggerValueAction, inputTriggerValueAction]);
      setInputTriggerValueAction("");
      setKeywordVisibleAction(false);
    }
  };

  const handleRemoveTrigger = (itemToRemove) => {
    const updatedTriggerValue = triggerValue?.filter(
      (item) => item !== itemToRemove
    );
    settriggerValue(updatedTriggerValue);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const splitValues = inputTriggerValue
        .split(",")
        .map((value) => value.trim())
        .filter((value) => value !== "" && value !== "Add a trigger");

      if (splitValues.length > 0) {
        settriggerValue([...triggerValue, ...splitValues]);
        setInputTriggerValue("");
      }
    }
  };

  const isAnyDialogueTitleEmpty = (array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].dialogueTitle === "") {
        return true; // Found an empty dialogueTitle
      }
    }
    return false; // No empty dialogueTitle found
  };

  const isAnyEmptyActionKeyword = () => {
    return sections.every((dialogue) => {
      return dialogue.settings.every((setting) => {
        if (
          setting.type === "action" &&
          ((setting.keyword && setting.keyword.length === 0) ||
            setting.keyword === undefined ||
            setting.keyword === "")
        ) {
          showToastName.push(setting.value);
          return false;
        } else {
          return true;
        }
      });
    });
  };

  const isTimeisEmpty = () => {
    return sections.every((dialogue) => {
      return dialogue.settings.every((setting) => {
        if (
          (setting.type === "action" || setting.type === "button") &&
          setting.timings
        ) {
          if (
            setting.timings.type === "Manual" &&
            (setting.timings.startTime === "" ||
              setting.timings.endTime === "" ||
              setting.timings.timingsText == null)
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      });
    });
  };

  // generic function
  const isAnyMediaNotUploaded = (type) => {
    return sections.every((dialogue) => {
      return dialogue.settings.some((setting) => {
        if (setting.type !== type) {
          return true;
        } else if (
          setting.type === type &&
          setting.value !== "" &&
          setting.value !== undefined
        ) {
          return true;
        }
      });
    });
  };

  const isAnyMediaNotCaption = (type) => {
    return sections.every((dialogue) => {
      return dialogue.settings.every((setting) => {
        if (
          setting.type === type &&
          (setting.caption === undefined ||
            setting.caption === null ||
            setting.caption === "")
        ) {
          return false;
        }
        return true;
      });
    });
  };

  const isSingleMediaNotUploaded = (section, type) => {
    return section.settings.some((setting) => {
      if (setting.type !== type) {
        return true;
      } else if (
        setting.type === type &&
        setting.value !== "" &&
        setting.value !== undefined
      ) {
        return true;
      }
    });
  };

  const isAnyEmptyTextArea = () => {
    return sections.every((dialogue) => {
      return dialogue.settings.every((setting) => {
        if (
          setting.type === "textarea" &&
          (setting.value === "" || setting.value === undefined)
        ) {
          return false;
        } else {
          return true;
        }
      });
    });
  };

  const isAnyRequiredFieldMissinginAllDialogs = () => {
    return sections.every((item) => {
      if (
        item.settings &&
        Array.isArray(item.settings) &&
        item.settings.length > 0
      ) {
        return item.settings.some((setting) =>
          ["textarea", "image", "video", "file"].includes(setting.type)
        );
      }
      return false;
    });
  };

  const isIndividualRequiredFieldsMissing = (section) => {
    section?.settings?.forEach((item) => {
      if (
        item?.type === "textarea" &&
        item?.type === "image" &&
        item?.type === "video" &&
        item?.type === "file"
      ) {
        return true;
      } else {
        return false;
      }
    });
  };

  const duplicateSection = () => {
    sections.forEach((dialogue) => {
      if (dialogue.dialogueTitle === "") {
      }
    });

    setSaveButtonClicked(false);
    if (sections.length == 0) {
      let newDialogue = {
        dialogueTitle: "",
        isDefault: true,
        dialogueId: uuidv4(),
        settings: [],
      };
      setSections([...sections, newDialogue]);
    } else {
      const sectionToDuplicate = sections[0];
      if (sectionToDuplicate) {
        const newSection = {
          ...sectionToDuplicate,
          dialogueId: uuidv4(),
          dialogueTitle: "",
          isDefault: false,
          settings: [],
        };
        setSections([...sections, newSection]);
      }
      setSectionData([]);
    }
  };

  const deleteSection = (sectionId) => {
    const updatedSections = sections.filter(
      (section) => section.dialogueId !== sectionId
    );
    setSections(updatedSections);
  };

  const handleEditClick = () => {
    setEditable(!editable);
  };

  const handleSectionNameChange = (sectionId, newName) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.dialogueId === sectionId
          ? { ...section, dialogueTitle: newName }
          : section
      )
    );
  };

  //end
  const selectedDialogIndex = sections?.findIndex(
    (dialog) => dialog.dialogueTitle === selectSection
  );

  const handleFileI = (event, headerMediaType, sectionIndex) => {
    const file = event.target.files[0];
    const fileInput = event.target;

    const isImage = (file) => {
      const imageExtensions = [
        ".jpg",
        ".jpeg",
        ".gif",
        ".png",
        ".avif",
        ".AVIF",
        "webp",
      ];
      const extension = file.name
        .toLowerCase()
        .substring(file?.name.lastIndexOf("."));
      return imageExtensions.includes(extension);
    };

    const updateSection = (value, name) => {
      setSections((prevSections) => {
        const updatedSections = [...prevSections];
        const updatedSettings = [
          ...updatedSections[selectedDialogIndex].settings,
        ];
        updatedSettings[sectionIndex] = {
          ...updatedSettings[sectionIndex],
          value: value,
          name: name,
        };
        updatedSections[selectedDialogIndex] = {
          ...updatedSections[selectedDialogIndex],
          settings: updatedSettings,
        };
        return updatedSections;
      });
    };

    if (file?.name) {
      if (file.size <= MAX_FILE_SIZE_BYTES) {
        if (isImage(file)) {
          const url = URL.createObjectURL(file);
          var formData = new FormData();
          formData.append("file", file);
          formData.append("agentPublicId", localStorage.getItem("_agentId"));
          formData.append(
            "companyId",
            localStorage.getItem("_companyId") === null
              ? ""
              : decryptedValue(localStorage.getItem("_companyId"))
          );
          const baseUrl = process.env.REACT_APP_BASEURL;
          axios
            .post(baseUrl + "/chat/upload-file", formData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("_token")}`,
              },
            })
            .then(
              (response) => {
                if (response.data.status === 1) {
                  updateSection(response.data.message, url);
                }
              },
              (error) => {}
            );
          fileInput.value = "";
        } else {
          toast.error("Invalid Format. Please upload a valid image file.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          fileInput.value = "";
        }
      } else {
        toast.error(
          `File is too large. Maximum size is ${MAX_FILE_SIZE_MB}MB.`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        fileInput.value = "";
      }
    } else {
      toast.error("Invalid Format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      fileInput.value = "";
    }
  };

  const MAX_FILE_SIZE_MB = 10; // Maximum file size limit in megabytes
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert to bytes

  const handleFileV = (event, headerMediaType, sectionIndex) => {
    const file = event.target.files[0];
    const fileInput = event.target;

    const isVideo = (file) => {
      return file?.name.toLowerCase().endsWith(".mp4");
    };

    const updateSection = (value, name) => {
      setSections((prevSections) => {
        const updatedSections = [...prevSections];
        const updatedSettings = [
          ...updatedSections[selectedDialogIndex].settings,
        ];
        updatedSettings[sectionIndex] = {
          ...updatedSettings[sectionIndex],
          value: value,
          name: name,
        };
        updatedSections[selectedDialogIndex] = {
          ...updatedSections[selectedDialogIndex],
          settings: updatedSettings,
        };
        return updatedSections;
      });
    };

    if (file?.name && isVideo(file)) {
      if (file.size <= MAX_FILE_SIZE_BYTES) {
        if (file.type.startsWith("video") && isVideo(file)) {
          const url = URL.createObjectURL(file);
          var formData = new FormData();
          formData.append("file", file);
          formData.append("agentPublicId", localStorage.getItem("_agentId"));
          formData.append(
            "companyId",
            localStorage.getItem("_companyId") === null
              ? ""
              : decryptedValue(localStorage.getItem("_companyId"))
          );
          const baseUrl = process.env.REACT_APP_BASEURL;
          axios
            .post(baseUrl + "/chat/upload-file", formData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("_token")}`,
              },
            })
            .then(
              (response) => {
                if (response.data.status === 1) {
                  updateSection(response.data.message, url);
                }
              },
              (error) => {}
            )
            .catch((error) => {
              if (error.response && error.response.status === 413) {
                toast.error(
                  `File is too large. Maximum size is ${MAX_FILE_SIZE_MB}MB.`,
                  {
                    position: toast.POSITION.TOP_RIGHT,
                  }
                );
              }
            });
          fileInput.value = "";
        }
      } else {
        toast.error(
          `File is too large. Maximum size is ${MAX_FILE_SIZE_MB}MB.`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        fileInput.value = "";
      }
    } else {
      toast.error("Invalid Format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      fileInput.value = "";
    }
  };

  const MAX_FILE_SIZE_MB_pdf = 10; // Maximum file size limit in megabytes
  const MAX_FILE_SIZE_BYTES_pdf = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert to bytes

  const handleFileF = (event, headerMediaType, sectionIndex) => {
    const file = event.target.files[0];
    const fileInput = event.target;

    const isDocument = (file) => {
      const documentExtensions = [
        // Word Documents
        ".doc",
        ".docx",
        ".dot",
        ".dotx",
        ".xlsx",
        ".csv",
        ".txt",
        // PDF
        ".pdf",
      ];
      const extension = file?.name
        .toLowerCase()
        .substring(file?.name.lastIndexOf("."));
      return documentExtensions.includes(extension);
    };

    const updateSection = (value, name) => {
      setSections((prevSections) => {
        const updatedSections = [...prevSections];
        const updatedSettings = [
          ...updatedSections[selectedDialogIndex].settings,
        ];
        updatedSettings[sectionIndex] = {
          ...updatedSettings[sectionIndex],
          value: value,
          name: name,
        };
        updatedSections[selectedDialogIndex] = {
          ...updatedSections[selectedDialogIndex],
          settings: updatedSettings,
        };
        return updatedSections;
      });
    };

    const fileSizeLimitMB = MAX_FILE_SIZE_MB_pdf;
    const fileSizeLimitBytes = MAX_FILE_SIZE_BYTES_pdf;

    if (file?.name && isDocument(file)) {
      if (file.size <= fileSizeLimitBytes) {
        const url = URL.createObjectURL(file);

        var formData = new FormData();
        formData.append("file", file);
        formData.append("agentPublicId", localStorage.getItem("_agentId"));
        formData.append(
          "companyId",
          localStorage.getItem("_companyId") === null
            ? ""
            : decryptedValue(localStorage.getItem("_companyId"))
        );
        const baseUrl = process.env.REACT_APP_BASEURL;
        axios
          .post(baseUrl + "/chat/upload-file", formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("_token")}`,
            },
          })
          .then(
            (response) => {
              if (response.data.status === 1) {
                updateSection(response.data.message, file.name);
              }
            },
            (error) => {}
          )
          .catch((error) => {
            if (error.response && error.response.status === 413) {
              toast.error(
                `File is too large. Maximum size is ${fileSizeLimitMB}MB.`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
            }
          });
        fileInput.value = "";
      } else {
        toast.error(
          `Invalid Format or File is too large. Maximum size is ${fileSizeLimitMB}MB.`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        fileInput.value = "";
      }
    } else {
      toast.error("Invalid Format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      fileInput.value = "";
    }
  };
  /********************************edit params single **********************************************/
  const getchatbotSingle = () => {
    if (paramsState) {
      setLoader(true);
      apiGet(
        `/webChatBot/builder/${paramsState}`,
        onSuccesschatbotSingle,
        onFailurechatbotSingle
      );
    }
  };
  const onSuccesschatbotSingle = (response) => {
    let data = response?.data;
    try {
      let parsedBotData = JSON.parse(data?.botJsonString);
      setSections(parsedBotData?.sections);
      setBot((prevBot) => ({
    ...prevBot,
    buttonKeywords: parsedBotData?.buttonKeywords || [], // Set the buttonKeywords from parsed data
  }));
      setTextBName(parsedBotData?.webBotName);
      setgetBotWidgetId(parsedBotData?.webBotWidgetId);
      setLoader(false);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };
  const onFailurechatbotSingle = (error) => {
    console.log(error);
  };

  //get team

  const getTeams = () => {
    setLoader(true);
    apiGet(`/agent/team/list?searchQuery=`, onSuccessTeams, onFailureTeams);
  };
  const onSuccessTeams = (response) => {
    setTeamsData(response.data);
    setLoader(false);
  };
  const onFailureTeams = (error) => {
    console.log(error);
  };
  useEffect(() => {
    if (
      pathname.startsWith("/whatsapp/chatbot-builder") ||
      pathname.startsWith("/facebook/chatbot-builder") ||
      pathname.startsWith("/web/chatbot-builder")
    ) {
      getTeams();
    }
  }, [pathname]);

  useEffect(() => {
    if (window.location.pathname.startsWith("/chatbot-builder")) {
      getTeams();
    }
  }, [window.location.pathname]);

  //end
  useEffect(() => {
    getchatbotSingle();
  }, [paramsState]);

  const createChatbot = () => {
    navigate("/whatsapp/chatbot-builder", { replace: true });
    window.location.reload();
  };

  const handleOpenScriptModal = () => {
    setScriptModal(true);
  };
  const handleCloseScriptModal = () => {
    setScriptModal(false);
    navigate("/chatbot", { replace: true });

  };

  //functions for validation
  const saveBotObject = () => {
    const Dialogue = sections?.find(
      (dialogue) => dialogue?.dialogueTitle === selectSection
    );
    let tempSettings = Dialogue?.settings?.map((item, index) => {
      if (item.type === "textarea") {
        item.value = document.getElementById(selectSection + "_" + index).value;
      } else if (item.type === "image") {
        // Handle image input
        const fileInput = document.getElementById(selectSection + "_" + index);
        if (fileInput && fileInput?.files?.length > 0) {
          item.value = URL.createObjectURL(fileInput.files[0]);
        }
      } else if (item.type === "video") {
        // Handle image input
        const fileInput = document.getElementById(selectSection + "_" + index);
        if (fileInput && fileInput?.files?.length > 0) {
          item.value = URL.createObjectURL(fileInput.files[0]);
        }
      } else if (item.type === "file") {
        // Handle image input
        const fileInput = document.getElementById(selectSection + "_" + index);
        if (fileInput && fileInput?.files?.length > 0) {
          item.value = URL.createObjectURL(fileInput.files[0]);
        }
      }
      return item;
    });

    if (tempSettings?.length) {
      const updatedDialogues = sections?.map((dialogue) => {
        if (dialogue?.dialogueTitle === selectSection) {
          return { ...dialogue, settings: tempSettings };
        } else {
          return dialogue;
        }
      });
      setSections(updatedDialogues);
      setSectionData(updatedDialogues);
    }
    setSaveButtonClicked(true);
    const onSuccess = (response) => {
      if (response.status === 0) {
        setParamsState("");
        setBotUpdated(response?.data);

        if ((bot !== "" && paramsState == "") || paramsState == undefined) {
          toast.success("Bot created successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1500,
          });
          handleOpenScriptModal();
        } else if (paramsState !== "" && paramsState?.length > 0) {
          toast.success("Bot Edited successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1500,
          });
          handleOpenScriptModal();
        }
      }
    };
    const onFailure = (error, response) => {
      toast.error(` ${error.message + ", " + error?.response?.data?.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
      });
    };

    //bot validations start
    if (textBName === "") {
      toast.error("Bot name is required for identification", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (sections.length === 0) {
      toast.error("Please add atleast on dialog", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (bot.buttonKeywords.length === 0) {
      toast.error("Please incorporate triggers", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (isAnyDialogueTitleEmpty(sections)) {
      toast.error("Dialog title is required", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (!isAnyRequiredFieldMissinginAllDialogs()) {
      toast.error("Please select any option from text, image, video and file", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (!isAnyEmptyActionKeyword()) {
      toast.error(`Missing keyword of ${showToastName}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (!isAnyEmptyTextArea()) {
      toast.error("Text box is empty", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (!isAnyMediaNotUploaded("image")) {
      toast.error("Please upload image", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (!isAnyMediaNotUploaded("video")) {
      toast.error("Please upload video with caption", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (!isAnyMediaNotUploaded("file")) {
      toast.error("Please upload file with caption", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (!isAnyMediaNotCaption("image")) {
      toast.error("Please provide caption", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (!isAnyMediaNotCaption("video")) {
      toast.error("Please provide caption", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (!isAnyMediaNotCaption("file")) {
      toast.error("Please provide caption", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (!isTimeisEmpty()) {
      {
        toast.error(
          "If you are selecting time, Please provide Start date and end date properly!",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1300,
          }
        );
      }
    } else if (
      textBName !== "" &&
      !isAnyDialogueTitleEmpty(sections) &&
      isAnyEmptyActionKeyword() &&
      isAnyEmptyTextArea() &&
      isTimeisEmpty() &&
      isAnyMediaNotUploaded("image") &&
      isAnyMediaNotUploaded("video") &&
      isAnyMediaNotUploaded("file") &&
      isAnyMediaNotCaption("image") &&
      isAnyMediaNotCaption("video") &&
      isAnyMediaNotCaption("file") &&
      isAnyRequiredFieldMissinginAllDialogs() &&
      sections.length > 0 &&
      bot.buttonKeywords.length !== 0
    ) {
      if ((bot !== "" && paramsState == "") || paramsState == undefined) {
        apiPost(`/webChatBot/builder/create`, onSuccess, onFailure, bot);
      } else if (paramsState !== "" && paramsState?.length > 0) {
        apiPost(
          `/webChatBot/builder/edit/${paramsState}`,
          onSuccess,
          onFailure,
          bot
        );
      }
    }
    setSaveButtonClicked(true);
  };

  /************************************************ */

  const values = {
    textareaValues,
    textareaValue,
    clickedSections,
    addTextareaValue,
    addClickedSection,
    removeClickedSection,
    toggleSection,
    handleSectionClose,
    handleOpenDilaogModal,
    handleCloseDialogModal,
    handleInputChange,
    handleKeyPress,
    triggerValue,
    settriggerValue,
    toggleSectionMain,
    visibleSection,
    setTextareaValue,
    isKeywordVisible,
    setKeywordVisible,
    inputTriggerValue,
    sectionData,
    addSection,
    addSectionList,
    toggleKeywordVisibility,
    selectSectionFunc,
    selectSection,
    handleSectionClosing,
    handleInputChangeSection,
    sectionDataArray,
    setSectionDataArray,
    setSectionData,
    sections,
    setSections,
    duplicateSection,
    deleteSection,
    handleEditClick,
    handleKeyPress,
    handleSectionNameChange,
    saveBotObject,
    saveButtonClicked,
    handleFileI,
    handleFileV,
    handleFileF,
    isDialogVisible,
    toggleDialogVisibility,
    toggleDialogVisibilityAction,
    isDialogVisibleAction,
    handleInputChangeSectionButton,
    openDialogModal,
    handleDialogSelectOnButton,
    selectedDialogOnButton,
    handleAddButton,
    handleInputChangeAction,
    handleKeyPressAction,
    inputTriggerValueAction,
    isKeywordVisibleAction,
    toggleKeywordVisibilityAction,
    triggerValueAction,
    handleRemoveTriggerAction,
    handleRemoveTrigger,
    handleEditClickBName,
    handleSaveClickBName,
    handleChangeBName,
    isEditingBName,
    textBName,
    handleInputChangeSectionAction,
    handleKeyPressKeywordSyn,
    handleChange,
    sectionValues,
    toggleDialogVisibilityAgent,
    isDialogVisibleArray,
    toggleActionVisibility,
    toggleDialogHidden,
    toggleActionHidden,
    disableItem,
    isActionVisibleArray,
    paramsState,
    setParamsState,
    loader,
    createChatbot,
    onEmojiClick,
    insertEmoji,
    setIsActionVisibleArray,
    disableLive,
    setDisableLive,
    teamsData,
    handleInputChangeSectionCatalogue,
    handleInputChangeSectionButtonExternal,
    handleDialogSelectOnButtonTimings,
    handleTimeChange,
    disableAll,
    hasTextarea,
    btnCount,
    handleSelectOnButtonValidation,
    handleInputTimingsText,
    listMenuDisable,
    handleOpenScriptModal,
    scriptModal,
    setScriptModal,
    handleCloseScriptModal,
    toggleFormVisibility,
    addSectionTrigger,
    bot,
    setBot,
    setSelectedDialogOnButton,
    getBotWidgetId
  };
  return (
    <ContextChatBotWeb.Provider value={values}>
      {children}
    </ContextChatBotWeb.Provider>
  );
};
