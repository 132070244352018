import { AddAlt, ChevronLeft } from "@carbon/icons-react";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  formatText,
  sanitizedMessage,
} from "../../../Utils/chat/chatFunctions";
import { convertTimestamp } from "../../../Utils/DateFunctions";
import { decryptedValue } from "../../../Utils/RouteHashConversion";
import { useAuth } from "../../../Context/AuthProvider";
const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;

const InternalChatContact = ({
  internalChatContactList,
  setAllMemebers,
  allMemebers,
  setIsActiveAllMemebers,
  isActiveAllMemebers,
  hasMoreContacts,
  setHasMoreContacts,
  setCurrentPage,
  getChatMessage,
  setAgentDetails,
  setContactMessages,
  setNewUser,
  setPageForChatMessages,
  getAllContacts,
}) => {
  const { auth } = useAuth();
  const [activeChatIndex, setActiveChatIndex] = useState(null);

  const toggleActiveChat = (customerId) => {
    if (activeChatIndex !== customerId) {
      setActiveChatIndex(customerId);
    }
  };

  return (
    <div className="uk-width-1-5 custom-class" style={{ paddingLeft: "24px" }}>
      <div className="chat-sec">
        <ul>
          <li className="chat-sec-content">
            {isActiveAllMemebers === false ? (
              <>
                <button
                  className="uk-button uk-button-default channel-drop-btn internal"
                  type="button"
                >
                  Internal Chat
                </button>
                <button
                  className="uk-button uk-button-default channel-drop-btn internal"
                  type="button"
                  onClick={() => {
                    setAllMemebers(undefined);
                    getAllContacts();
                    setContactMessages(null);
                    setIsActiveAllMemebers(true);
                  }}
                >
                  <AddAlt />
                </button>
              </>
            ) : (
              <button
                className="uk-button uk-button-default channel-drop-btn internal"
                type="button"
              >
                <span onClick={() => setIsActiveAllMemebers(false)}>
                  <ChevronLeft />
                </span>{" "}
                &nbsp;&nbsp;&nbsp; New Chat
              </button>
            )}
          </li>
        </ul>

        {/* for contacted user */}
        {isActiveAllMemebers === false ? (
          internalChatContactList === undefined ? (
            <div className="loaderScreen">
              <div uk-spinner="" className="loader"></div>
            </div>
          ) : (
            <div className="chatContactsContainer" id="contatedThread">
              <ul>
                <InfiniteScroll
                  style={{ overflow: "none" }}
                  dataLength={internalChatContactList?.length}
                  // next={(e) => getAllBotChatContacts(agentId, chatFilter)}
                  // dataLength={100}
                  // next={this.loadUserApi}
                  pullDownToRefreshThreshold={20}
                  hasMore={hasMoreContacts}
                  scrollableTarget="contatedThread"
                  className="conversaionsList"
                  endMessage={<center id="record_not_found"></center>}
                >
                  {internalChatContactList?.map((data, index) => (
                    <li
                      className={`all-chats ${
                        // activeChatIndex === data.id ? "activechat" : ""
                        activeChatIndex === data.customerName
                          ? "activechat"
                          : ""
                      }`}
                      // className={`all-chats`}
                      key={index}
                      // onClick={() => toggleActiveChat(data.id)}
                      // onClick={() => }
                      onClick={() => {
                        setAgentDetails(data);
                        toggleActiveChat(data.customerName);
                      }}
                    >
                      <button
                        type="button"
                        onClick={() => {
                          setPageForChatMessages(0);
                          // setAgentDetails(data);
                          getChatMessage(data.recipientAgentId, data.conversationId);
                        }}
                      >
                        <div className="iconAndName">
                          <div className="imgwrp">
                            {/* <img
                            src={
                              data.chanelId === "WHATSAPP"
                                ? whatsappLogo
                                : data.chanelId === "FACEBOOK"
                                ? messengerIcon
                                : data.chanelId === "INSTAGRAM"
                                ? instaIcon
                                : whatsappLogo
                            }
                            alt=""
                          /> */}
                            {data.profilePic !== null ? (
                              // <span >
                              <>
                                <span>
                                  {" "}
                                  <img
                                    className="user"
                                    src={chatBaseUrl + "/" + data.profilePic}
                                    alt=""
                                  />
                                </span>

                                {/* <span
                                  className={`user ${
                                    data.status === "ONLINE"
                                      ? "online"
                                      : "offlined"
                                  } `}
                                ></span> */}
                              </>
                            ) : (
                              // </span>
                              <span className="initial">
                                {data.customerName.substring(0, 1)}
                                {/* <span
                                  className={`status ${
                                    data.status === "ONLINE"
                                      ? "online"
                                      : "offlined"
                                  } `}
                                ></span> */}
                              </span>
                            )}

                            {/* <span className="initial">
                              {data.customerName.substring(0, 1)}
                            
                            </span> */}
                          </div>
                          <div className="txtwrp">
                            <h6
                              className={
                                index === activeChatIndex && "ft-weight"
                              }
                            >
                              {data.customerName}
                            </h6>
                            <p
                              dangerouslySetInnerHTML={sanitizedMessage(
                                formatText(data.lastMessage)
                              )}
                            ></p>
                          </div>
                        </div>
                        <div className="timeDivWrp">
                          <div className="timediv">
                            <p>{convertTimestamp(data.timestamp)}</p>
                            {
                            data.unReadCount !== undefined &&
                            data.unReadCount !== "0" &&
                              data.unReadCount !== null && (
                                <span className="chatUnReadCount">
                                  {data.unReadCount}
                                </span>
                              )}
                          </div>
                        </div>
                      </button>
                    </li>
                  ))}
                </InfiniteScroll>
              </ul>
            </div>
          )
        ) : null}

        {/* for all user */}
        {isActiveAllMemebers === true ? (
          allMemebers === undefined ? (
            <div className="loaderScreen">
              <div uk-spinner="" className="loader"></div>
            </div>
          ) : (
            <div className="chatContactsContainer" id="botthreadList">
              <ul>
                <InfiniteScroll
                  style={{ overflow: "none" }}
                  dataLength={allMemebers?.length}
                  // next={(e) => getAllBotChatContacts(agentId, chatFilter)}
                  // dataLength={100}
                  // next={this.loadUserApi}
                  pullDownToRefreshThreshold={20}
                  // hasMore={hasMoreContacts}
                  scrollableTarget="botthreadList"
                  className="conversaionsList"
                  endMessage={<center id="record_not_found"></center>}
                >
                  {allMemebers?.map((data, index) => (
                    <li
                      key={index}
                      // className={`all-chats ${
                      //   activeChatIndex === data.id ? "activechat" : ""
                      // }`}
                      className={"all-chats"}
                      onClick={() => {
                        if (
                          data.maskAgentId !== decryptedValue(auth.idNumber)
                        ) {
                          setIsActiveAllMemebers(false);
                          setNewUser(data);
                        }
                      }}
                      // onClick={() => toggleActiveChat(data.id)}
                      // onClick={() => toggleActiveChat(data.id)}
                    >
                      <button
                        type="button"

                        // onClick={() =>
                        //   getChatBotMessages(
                        //     data.number,
                        //     data.chanelId,
                        //     data.agentName
                        //   )
                        // }
                      >
                        <div className="iconAndName">
                          <div className="imgwrp">
                            {/* <img
                          src={
                            data.chanelId === "WHATSAPP"
                              ? whatsappLogo
                              : data.chanelId === "FACEBOOK"
                              ? messengerIcon
                              : data.chanelId === "INSTAGRAM"
                              ? instaIcon
                              : whatsappLogo
                          }
                          alt=""
                        /> */}

                            {data.profilePic !== null ? (
                              // <span >
                              <>
                                <img
                                  className="user"
                                  src={chatBaseUrl + "/" + data.profilePic}
                                  alt=""
                                />
                                <span
                                  className={`user ${
                                    data.status === "ONLINE"
                                      ? "online"
                                      : "offlined"
                                  } `}
                                ></span>
                              </>
                            ) : (
                              // </span>
                              <span className="initial">
                                {data.customerName.substring(0, 1)}
                                <span
                                  className={`status ${
                                    data.status === "ONLINE"
                                      ? "online"
                                      : "offlined"
                                  } `}
                                ></span>
                              </span>
                            )}
                          </div>
                          <div className="txtwrp">
                            <h6
                            // className={index === activeChatIndex && "ft-weight"}
                            >
                              {data.customerName}
                            </h6>
                            <p
                              dangerouslySetInnerHTML={sanitizedMessage(
                                formatText(data.agentType)
                              )}
                            ></p>
                          </div>
                        </div>
                      </button>
                    </li>
                  ))}
                </InfiniteScroll>
              </ul>
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default InternalChatContact;
