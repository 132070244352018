import React from "react";
import { useState } from "react";
import NewMessageModal from "./Modal/NewMessageModal";
import { useNavigate, useOutletContext } from "react-router";
import whatsappLogo from "../../assets/images/ic_Integrations_whatsapp.png";
import { Chat, ChevronDown } from "@carbon/icons-react";
import { useEffect } from "react";
import { apiGet } from "../../Utils/apiServices";
import { encryptedValue } from "../../Utils/RouteHashConversion";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";

const TemplateMessage = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const CurrentCompanyName = CompanyName(CompanyHtml);
  const navigate = useNavigate();
  const [templates, setTemplates] = useState(null);
  const [openNewMessageModal, setOpenNewMessageModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [categoryFilter, setCategoryFilter] = useState("");

  const handleOpenNewMessageModal = () => {
    setOpenNewMessageModal(true);
  };

  const handleCloseNewMessageModal = () => {
    setOpenNewMessageModal(false);
  };

  const getTemplate = () => {
    apiGet(`/template/`, onSuccessTemplates, onFailureTemplates);
  };
  const onSuccessTemplates = (response) => {
    setTemplates(response.data);
    setFilteredData(response.data);
  };
  const onFailureTemplates = (error) => {
    console.log(error);
  };
  useEffect(() => {
    getTemplate();
  }, []);

  const filteredResults = (filterKey) => {
    let result = templates.filter(
      (item) =>
        item.category.includes(filterKey) && item.name.includes(searchQuery)
    );
    setFilteredData(result);
  };

  useEffect(() => {
    if (categoryFilter !== "") {
      filteredResults(categoryFilter);
    }
  }, [categoryFilter]);
  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="boradcastWrp">
        <div className="boradcastTabbing">
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #broadcastTabs"
          >
            <li
              onClick={() => {
                navigate("/broadcast", { replace: true });
              }}
            >
              <a>Broadcast</a>
            </li>
            <li
              className="uk-active"
              onClick={() => {
                navigate("/template-message", { replace: true });
              }}
            >
              <a>Template Messages</a>
            </li>
            <li
              onClick={() => {
                navigate("/api-message", { replace: true });
              }}
            >
              <a>API messages</a>
            </li>
          </ul>
          <ul className="broadcastTopBtn uk-switcher" id="broadcastTabs">
            <li></li>
            <li>
              <button type="button" onClick={handleOpenNewMessageModal}>
                Create templates
              </button>
            </li>
            <li></li>
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="broadcastTabs">
            <li></li>
            <li>
              <div className="broadcastTabWrp">
                <div className="uk-container">
                  <div className="mainHeading">
                    <h2>
                      Template Message{" "}
                      <CurrentCompanyName companyDetails={companyDetails} />
                    </h2>
                  </div>
                  <div className="searchFilterWrp">
                    <div className="uk-grid uk-flex-middle" uk-grid="">
                      <div className="uk-width-1-2">
                        <div className="searchFilter">
                          <form
                            action=""
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Template name..."
                              value={searchQuery}
                              onChange={(e) => {
                                const filteredResults = templates.filter(
                                  (item) =>
                                    item.name
                                      .toLowerCase()
                                      .includes(e.target.value.toLowerCase()) &&
                                    item.category.includes(categoryFilter)
                                );
                                setSearchQuery(e.target.value);
                                setFilteredData(filteredResults);
                              }}
                            />
                          </form>
                        </div>
                      </div>
                      <div className="uk-width-1-2 btnSection">
                        <div className="uk-inline">
                          <button className="exportBtn" type="button">
                            <Chat />
                            {categoryFilter === ""
                              ? "Message category"
                              : categoryFilter}{" "}
                            <span className="uk-inline">
                              <ChevronDown />
                            </span>
                          </button>
                          <div
                            uk-dropdown="mode: click"
                            className="tempelateDropdown"
                          >
                            <div className="dropdownHeading">
                              <span>Message Tempelate Category</span>
                            </div>
                            <ul>
                              <li
                                onClick={() => setCategoryFilter("MARKETING")}
                              >
                                <div className="txtwrp">
                                  <button
                                    type="button"
                                    className={
                                      categoryFilter === "MARKETING" &&
                                      "activeBtn"
                                    }
                                  >
                                    <span>Marketing message</span>
                                    <p>
                                      Promotional offers, announcements for
                                      awarness and engagement
                                    </p>
                                  </button>
                                </div>
                              </li>
                              <li onClick={() => setCategoryFilter("UTILITY")}>
                                <div className="txtwrp">
                                  <button
                                    type="button"
                                    className={
                                      categoryFilter === "UTILITY" &&
                                      "activeBtn"
                                    }
                                  >
                                    <span>Utility</span>
                                    <p>
                                      Messages about a specific transaction,
                                      account, order or customer request.
                                    </p>
                                  </button>
                                </div>
                              </li>
                              <li
                                onClick={() => {
                                  setCategoryFilter("AUTHENTICATION");
                                }}
                              >
                                <div className="txtwrp">
                                  <button
                                    type="button"
                                    className={
                                      categoryFilter === "AUTHENTICATION" &&
                                      "activeBtn"
                                    }
                                  >
                                    <span>Autentication</span>
                                    <p>
                                      One-time passwords that your customers use
                                      to authenticate a transaction or login.
                                    </p>
                                  </button>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        {/* <div className="uk-inline">
                          <button className="calenderBtn rangeBtn">
                            <Calendar /> Last 30 days{" "}
                            <span className="uk-inline">
                              <ChevronDown />
                            </span>
                          </button>
                          <div
                            uk-dropdown="mode: click"
                            className="calendarDropdown"
                          >
                            <div className="dropDownHeading">
                              <span>Select a range</span>
                            </div>
                            <ul>
                              <li className="active">
                                <button type="button" className="rangeBtn">
                                  <span className="rangeBtnCircle"></span> Last
                                  30 days
                                </button>
                              </li>
                              <li>
                                <button type="button" className="rangeBtn">
                                  <span className="rangeBtnCircle"></span> This
                                  week
                                </button>
                              </li>
                              <li>
                                <button type="button" className="rangeBtn">
                                  <span className="rangeBtnCircle"></span> Last
                                  week
                                </button>
                              </li>
                              <li>
                                <button type="button" className="rangeBtn">
                                  <span className="rangeBtnCircle"></span> This
                                  month
                                </button>
                              </li>
                              <hr style={{ margin: "5px 0" }} />
                              <li>
                                <div className="uk-inline uk-text-left">
                                  <button className="rangeBtn" type="button">
                                    <span className="rangeBtnCircle"></span>{" "}
                                    Custom range
                                  </button>
                                  <div
                                    id="datepickerdrop"
                                    uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                    className="date-picker-container"
                                  >
                                    <CalendarDate onChange={onChange} />
                                    <div className="date-picker-btn-container uk-text-right">
                                      <button
                                        className="date-selection-btn"
                                        onClick={handleDatePickerClose}
                                      >
                                        Ok
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="templeateMessageWrp">
                    <div
                      className="uk-grid uk-grid-small"
                      uk-grid=""
                      uk-height-match="target: > div > .boxwrp"
                    >
                      {/* <div className="uk-width-1-4 tempelateBox">
                        <div className="boxwrp">
                          <div className="boxHeading">
                            <div className="left">
                              <span>MARKETING</span>
                            </div>
                            <div className="right">
                              <p>
                                <span className="onlineStatus"></span> Active
                              </p>
                            </div>
                          </div>
                          <div className="channelLogo">
                            <img src={whatsappLogo} alt="" />
                          </div>
                          <div className="channelName">
                            <h3>Collect_Customer_Feedback _and_Reviews</h3>
                          </div>
                          <div className="channelDate">
                            <span>23 Jun 2023</span>
                          </div>
                        </div>
                      </div> */}

                      {filteredData?.length > 0 ? (
                        filteredData?.map((val, index) => {
                          return (
                            <div
                              className="uk-width-1-4 tempelateBox"
                              onClick={() =>
                                navigate(
                                  `/tempelate-preview/${encryptedValue(
                                    val.id
                                  )}`,
                                  {
                                    replace: true,
                                  }
                                )
                              }
                              key={index}
                            >
                              <div className="boxwrp">
                                <div className="boxHeading">
                                  <div className="left">
                                    <span>{val.category}</span>
                                  </div>
                                  <div className="right">
                                    <p>
                                      <span
                                        className={
                                          val.status === "APPROVED"
                                            ? "onlineStatus"
                                            : val.status === "REJECTED"
                                            ? "rejectedStatus"
                                            : ""
                                        }
                                      ></span>{" "}
                                      {val.status}
                                    </p>
                                  </div>
                                </div>
                                <div className="channelLogo">
                                  <img src={whatsappLogo} alt="" />
                                </div>
                                <div className="channelName">
                                  <h3>{val.name}</h3>
                                  <h4>id: {val.id}</h4>
                                </div>
                                <div className="channelDate">
                                  {/* <span>{convertTimestamp(val.quality_score?.date)}</span> */}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : templates === null ? (
                        <div uk-spinner=""></div>
                      ) : (
                        "No data found"
                      )}

                      {/* <div className="uk-width-1-4">
                        <div className="boxwrp">
                          <div className="boxHeading">
                            <div className="left">
                              <span>AUTHENTICATION</span>
                            </div>
                            <div className="right">
                              <p>In review</p>
                            </div>
                          </div>
                          <div className="channelLogo">
                            <img src={whatsappLogo} alt="" />
                          </div>
                          <div className="channelName">
                            <h3>Customer_service_Bot</h3>
                          </div>
                          <div className="channelDate">
                            <span>23 Jun 2023</span>
                          </div>
                        </div>
                      </div>
                      <div className="uk-width-1-4">
                        <div className="boxwrp">
                          <div className="boxHeading">
                            <div className="left">
                              <span>UTILITY</span>
                            </div>
                            <div className="right">
                              <p>Rejected</p>
                            </div>
                          </div>
                          <div className="channelLogo">
                            <img src={whatsappLogo} alt="" />
                          </div>
                          <div className="channelName">
                            <h3>Make_Reservations</h3>
                          </div>
                          <div className="channelDate">
                            <span>23 Jun 2023</span>
                          </div>
                        </div>
                      </div>
                      <div className="uk-width-1-4">
                        <div className="boxwrp">
                          <div className="boxHeading">
                            <div className="left">
                              <span>UTILITY</span>
                            </div>
                            <div className="right">
                              <p>Disabled</p>
                            </div>
                          </div>
                          <div className="channelLogo">
                            <img src={whatsappLogo} alt="" />
                          </div>
                          <div className="channelName">
                            <h3>Pay_Bot</h3>
                          </div>
                          <div className="channelDate">
                            <span>23 Jun 2023</span>
                          </div>
                        </div>
                      </div>
                      <div className="uk-width-1-4">
                        <div className="boxwrp">
                          <div className="boxHeading">
                            <div className="left">
                              <span>Marketing Message</span>
                            </div>
                            <div className="right">
                              <p>
                                <span className="onlineStatus"></span> Active
                              </p>
                            </div>
                          </div>
                          <div className="channelLogo">
                            <img src={whatsappLogo} alt="" />
                          </div>
                          <div className="channelName">
                            <h3>Collect_Emails_and_Phone</h3>
                          </div>
                          <div className="channelDate">
                            <span>23 Jun 2023</span>
                          </div>
                        </div>
                      </div>
                      <div className="uk-width-1-4">
                        <div className="boxwrp">
                          <div className="boxHeading">
                            <div className="left">
                              <span>Marketing Message</span>
                            </div>
                            <div className="right">
                              <p>Paused</p>
                            </div>
                          </div>
                          <div className="channelLogo">
                            <img src={whatsappLogo} alt="" />
                          </div>
                          <div className="channelName">
                            <h3>Helpdesk_Tickets</h3>
                          </div>
                          <div className="channelDate">
                            <span>23 Jun 2023</span>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li></li>
          </ul>
        </div>
      </div>
      {openNewMessageModal && (
        <NewMessageModal closeModal={handleCloseNewMessageModal} />
      )}
    </>
  );
};

export default TemplateMessage;
