import Request from "../../../Help/Request";

const ApplyForBoradcast = () => {
  return (
    <Request
      heading={"Apply for Broadcast"}
      paragraph={
        "Please provide the details and specific requirements for the broadcast feature."
      }
      type={"broadcast"}
      btnText={"Request a demo"}
      thankyouHeading={"Thank you for submitting your demo request!"}
      thankyouPara1={
        "Your form has been successfully received. Our dedicated team will be in touch with you shortly to schedule the demo and discuss the next steps."
      }
      thankyouPara2={
        "We appreciate your interest in the broadcast feature, and we look forward to potentially collaborating with you to enhance your software and improve customer engagement."
      }
      thankyouBtn={"Back to Home"}
    />
  );
};

export default ApplyForBoradcast;
