// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import React, { Suspense } from "react";
// import { ToastContainer } from "react-toastify";
// import "./App.css";
// import "react-toastify/dist/ReactToastify.css";
// import { AuthProvider } from "./Context/AuthProvider";
// import SplashScreen from './components/loader/SplashScreen/SplashScreen'
// const OpenRoutes = React.lazy(() =>
//   import("./Routes/OpenRoutes")
// );
// const PrivateRoute = React.lazy(() => import("./Routes/PrivateRoute"));
// const Login = React.lazy(() => import("./pages/Login/Login"));
// const Footer = React.lazy(() => import("./components/common/Footer/Footer"));
// const Header = React.lazy(() => import("./components/common/Header/Header"));
// const ForgotPassword = React.lazy(() => import("./pages/Login/ForgotPassword"));
// const ChangePassword = React.lazy(() => import("./pages/Login/ChangePassword"));
// const Otp = React.lazy(() => import("./pages/Login/Otp"));
// const Dashboard = React.lazy(() => import("./pages/dashboard/Dashboard"));
// const Sidebar = React.lazy(() => import("./components/common/Sidebar/Sidebar"));
// const SidebarLayout = React.lazy(() =>
//   import("./components/common/Sidebar/SidebarLayout")
// );
// // const SplashScreen = React.lazy(() =>
// //   import("./components/loader/SplashScreen/SplashScreen")
// // );
// const Chat = React.lazy(() => import("./pages/chat/Chat"));
// const ThankYou = React.lazy(() => import("./pages/dashboard/ThankYou"));
// const Help = React.lazy(() => import("./pages/Help/Help"));
// const CreateNewSegment = React.lazy(() =>
//   import("./pages/Contacts/CreateNewSegment")
// );
// const WinterShoppers = React.lazy(() =>
//   import("./pages/Contacts/WinterShoppers")
// );
// const User = React.lazy(() => import("./pages/UserAndTeam/User"));
// const AddUser = React.lazy(() => import("./pages/UserAndTeam/AddUser"));
// const UserDetails = React.lazy(() => import("./pages/UserAndTeam/UserDetails"));
// const CreateTeam = React.lazy(() => import("./pages/UserAndTeam/CreateTeam"));
// const TeamDetail = React.lazy(() => import("./pages/UserAndTeam/TeamDetail"));
// const EditSegment = React.lazy(() => import("./pages/Contacts/EditSegment"));
// const ImportContact = React.lazy(() =>
//   import("./pages/Contacts/ImportContact")
// );
// const ImportContactConfirmation = React.lazy(() =>
//   import("./pages/Contacts/ImportContactConfirmation")
// );
// const NewAnalytics = React.lazy(() =>
//   import("./pages/NewAnalytics/NewAnalytics")
// );
// const Broadcast = React.lazy(() => import("./pages/Broadcast/Broadcast"));
// const Unauthorized = React.lazy(() =>
//   import("./pages/Unauthorized/Unauthorized")
// );
// const BroadcastAnalytics = React.lazy(() =>
//   import("./pages/Broadcast/BroadcastAnalytics")
// );
// const CreateBroadcast = React.lazy(() =>
//   import("./pages/Broadcast/CreateBroadcast")
// );
// const CreateTempelateMarketing = React.lazy(() =>
//   import("./pages/Broadcast/CreateTempelateMarketing")
// );
// const CreateTempelateUtility = React.lazy(() =>
//   import("./pages/Broadcast/CreateTempelateUtility")
// );
// const CreateTempelateAuth = React.lazy(() =>
//   import("./pages/Broadcast/CreateTempelateAuth")
// );
// const GeneralSetting = React.lazy(() =>
//   import("./pages/Setting/GeneralSetting")
// );
// const NotificationSetting = React.lazy(() =>
//   import("./pages/Setting/NotificationSetting")
// );
// const LiveChatBehavior = React.lazy(() =>
//   import("./pages/Setting/LiveChatBehavior")
// );
// const SegmentSetting = React.lazy(() =>
//   import("./pages/Setting/SegmentSetting")
// );
// const TagSetting = React.lazy(() => import("./pages/Setting/TagSetting"));
// const ChatBot = React.lazy(() => import("./pages/chat/components/Bot/ChatBot"));
// const Contacts = React.lazy(() => import("./pages/Contacts/Contacts"));
// const Segments = React.lazy(() => import("./pages/Contacts/Segments"));
// const Profile = React.lazy(() => import("./pages/Profile/Profile"));
// const Statistics = React.lazy(() => import("./pages/NewAnalytics/Statistics"));
// const Maupage = React.lazy(() => import("./pages/NewAnalytics/Maupage"));
// const Conversationpage = React.lazy(() =>
//   import("./pages/NewAnalytics/ConversationPage")
// );
// const TempelatePreview = React.lazy(() =>
//   import("./pages/Broadcast/TempelatePreview")
// );
// const Teams = React.lazy(() => import("./pages/UserAndTeam/Team"));
// const ImportComplete = React.lazy(() =>
//   import("./pages/Contacts/ImportComplete")
// );
// const FormCentral = React.lazy(() => import("./pages/FormCentral/FormCentral"));
// const ContactFormEntries = React.lazy(() =>
//   import("./pages/FormCentral/ContactFormEntries")
// );
// const LiveChatFormEntries = React.lazy(() =>
//   import("./pages/FormCentral/LiveChatFormEntries")
// );
// const CustomizeFormEntries = React.lazy(() =>
//   import("./pages/FormCentral/CustomizeFormEntries")
// );
// const CreateNewRouting = React.lazy(() =>
//   import("./pages/Setting/CreateNewRouting")
// );
// const ChatbotAnalytics = React.lazy(() =>
//   import("./pages/NewAnalytics/ChatbotAnalytics")
// );
// const LiveChatAnalytics = React.lazy(() =>
//   import("./pages/NewAnalytics/LiveChatAnalytics")
// );
// const TemplateMessage = React.lazy(() =>
//   import("./pages/Broadcast/TemplateMessage")
// );
// const ApiMessages = React.lazy(() => import("./pages/Broadcast/ApiMessages"));
// const Chatbot = React.lazy(() => import("./pages/Chatbot/Chatbot"));
// const ChatbotBuilder = React.lazy(() =>
//   import("./pages/Chatbot/ChatbotBuilder")
// );
// const ApplyForBoradcast = React.lazy(() =>
//   import("./pages/dashboard/components/ApplyForBoradcast/ApplyForBoradcast")
// );
// const ApplyForLiveAgents = React.lazy(() =>
//   import("./pages/dashboard/components/ApplyForLiveAgents/ApplyForLiveAgents")
// );
// const ApplyForSelfService = React.lazy(() =>
//   import("./pages/dashboard/components/ApplyForSelfService/ApplyForSelfService")
// );
// const EditBroadcast = React.lazy(() =>
//   import("./pages/Broadcast/EditBroadcast")
// );
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./Context/AuthProvider";
import { WebSocketProvider } from "./socket/WebSocketManager";
import { ContextChatBotProvider } from "./pages/Chatbot/whatsapp/ContextChatBot";
import { ContextChatBotProviderFacebook } from "./pages/Chatbot/facebook/ContextChatBot";
import PrivateRoute from "./Routes/PrivateRoute";
import OpenRoutes from "./Routes/OpenRoutes";

import SidebarLayout from "./components/common/Sidebar/SidebarLayout";
import {
  protectedRoutesWithSidebarLayout,
  protectedRoutesWithoutSidebarLayout,
  publicRoutes,
  superAdminRoutes,
} from "./Routes/Route";
import { decryptedValue, encryptedValue } from "./Utils/RouteHashConversion";
import NotFound from "./pages/404/NotFound";
import { ContextChatBotProviderWeb } from "./pages/Chatbot/web/ContextChatBot";
import ChatWidget from "./pages/Webchatbot/ChatWidget";

function App() {
  const showChat = window.location.href.includes("chatBox");
  const [loading, setLoading] = useState(true);
  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    // Simulate a delay to show the loader (remove in production)
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Cleanup function to clear the timeout
    return () => clearTimeout(timeout);
  }, []);
  const userRole = decryptedValue(localStorage.getItem("_role"));


  return (
    <BrowserRouter>
      <ContextChatBotProviderWeb>
        <ContextChatBotProvider>
          <ContextChatBotProviderFacebook>
            <AuthProvider>
              <WebSocketProvider>
                <ToastContainer />
                {/* =========================================Open Routes================================================ */}
                {/* {loading ? (
                <div className="centerDiv">
                  <div uk-spinner="" className="loader"></div>
                </div>
              ) : ( */}

                {/* <div className="buildVersionCard">
                <div>{process.env.REACT_APP_VERSION}</div>            
              </div> */}
                <Routes>
                  {publicRoutes.map((val, index) => {
                    return (
                      <Route
                        path={val.path}
                        key={index}
                        element={
                          <OpenRoutes userPermissions={userPermissions}>
                            <div>
                              {val.header}
                              {val.component}
                              {val.footer}
                            </div>
                          </OpenRoutes>
                        }
                      />
                    );
                  })}

                  {/* =========================================Private Routes================================================ */}

                  {protectedRoutesWithoutSidebarLayout.map((val, index) => {
                    return (
                      <Route
                        key={index}
                        path={val.path}
                        element={<PrivateRoute>{val.component}</PrivateRoute>}
                      />
                    );
                  })}

                  <Route
                    element={
                      <SidebarLayout
                        setUserPermissions={setUserPermissions}
                        userPermissions={userPermissions}
                      />
                    }
                  >
                    {userRole === "ROLE_SUPER_ADMIN"
                      ? superAdminRoutes.map((val, index) => {
                          return (
                            <Route
                              key={index}
                              path={val.path}
                              element={
                                <PrivateRoute>
                                  <div>
                                    <div uk-grid="" className="uk-grid">
                                      <div className="uk-width-auto"></div>
                                      <div
                                        className={
                                          val.padding === true
                                            ? `uk-width-expand padding60`
                                            : `uk-width-expand`
                                        }
                                      >
                                        {val.component}
                                      </div>
                                    </div>
                                  </div>
                                </PrivateRoute>
                              }
                            />
                          );
                        })
                      : protectedRoutesWithSidebarLayout.map((val, index) => {
                          const routeComponent = (
                            <Route
                              key={index}
                              path={val.path}
                              element={
                                <PrivateRoute permission={val.permission}>
                                  <div>
                                    <div uk-grid="" className="uk-grid">
                                      <div className="uk-width-auto"></div>
                                      <div
                                        className={
                                          val.padding === true
                                            ? `uk-width-expand padding60`
                                            : `uk-width-expand`
                                        }
                                      >
                                        {val.header}
                                        {val.component}
                                      </div>
                                    </div>
                                    {val.footer}
                                  </div>
                                </PrivateRoute>
                              }
                            />
                          );

                          {
                            /* if (val.role === undefined) {
                          // No role specified, render the route
                          return routeComponent;
                        } else {
                          // Role specified, check if the user has the required role
                          const userRole = decryptedValue(
                            localStorage.getItem("_role")
                          );
                          if (userRole !== undefined) {
                            const matchingRoles = val.role.filter((item) =>
                              userRole.includes(item)
                            );
                            if (matchingRoles.length > 0) {
                              return routeComponent;
                            }
                          }

                          return null;
                        }
                      })} */
                          }

                          if (localStorage.getItem("_role") !== null) {
                            if (val.role === undefined) {
                              return routeComponent;
                            } else {
                              var matchingRoles = 1;
                              // if (localStorage.getItem("_role") !== null) {
                              if (localStorage.getItem("_displayed") === null) {
                                matchingRoles = val.role.filter((item) =>
                                  localStorage.getItem("_role").includes(item)
                                );
                              } else {
                                matchingRoles = val.role.filter((item) =>
                                  decryptedValue(
                                    localStorage.getItem("_role")
                                  )?.includes(item)
                                );
                              }
                              if (matchingRoles.length > 0) {
                                return routeComponent;
                              } else {
                                return null;
                              }
                            }
                          }
                        })}
                  </Route>
                  {/* super admin */}
                  <Route path="*" element={<NotFound />} />
                  <Route path="webchat" element={<ChatWidget />} />
                </Routes>
                {/* )} */}

  
              </WebSocketProvider>
            </AuthProvider>
          </ContextChatBotProviderFacebook>
        </ContextChatBotProvider>
      </ContextChatBotProviderWeb>
    </BrowserRouter>
    // </Suspense>
  );
}

export default App;
