import React from "react";
import {
  Calendar,
  ChevronDown,
  Download,
  ChatLaunch,
  Edit,
  Error,
  OverflowMenuVertical,
  TrashCan,
} from "@carbon/icons-react";
import { useNavigate, useOutletContext } from "react-router-dom";
import whatsappLogo from "../../assets/images/whatsapp-icon.png";
import { useState } from "react";
import { useEffect } from "react";
import { apiGet } from "../../Utils/apiServices";
import { convertTimestamp } from "../../Utils/DateFunctions";
import axios from "axios";
import { useAuth } from "../../Context/AuthProvider";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";
import { hasPermissions } from "../../Utils/RoutePermissions";
const baseUrl = process.env.REACT_APP_BASEURL;

const Conversationpage = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const CurrentCompanyName = CompanyName(CompanyHtml);
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [packages, setPackages] = useState(null);
  const [filterDates, setFilterDates] = useState();
  const pageTabs = [
    {
      route: "/analytics",
      name: "Overview",
      permissions: ["FINANCE-DATA-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/live-chat-analytics",
      name: "Live Chat",
      permissions: ["ANALYTICS-MANAGER", "LIVE-CHAT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/chatbot-analytics",
      name: "Chatbot",
      permissions: ["CHATBOT-SUPERVISOR", "CHAT-BOT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/statistics",
      name: "Statistics",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/package-mau",
      name: "Package",
      permissions: ["ANALYTICS-MANAGER"],
      active: true,
      allPermissionsMandatory: true,
    },
    {
      route: "/orders",
      name: "Orders",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/transfer-logs",
      name: "Transfer Logs",
      permissions: [],
      active: false,
      allPermissionsMandatory: true,
    },
  ];
  const currentDate = new Date();
  const monthNames = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  const formattedDate = `${
    monthNames[currentDate.getMonth()]
  } ${currentDate.getFullYear()}`;

  const [selectedValue, setSelectedValue] = useState(formattedDate);

  const getpackages = () => {
    apiGet(
      `/stats/package-consumption/${currentDate.getFullYear()}/${
        currentDate.getMonth() + 1
      }`,
      onSuccesspackages,
      onFailurepackages
    );
  };
  const onSuccesspackages = (response) => {
    setPackages(response?.data);
  };
  const onFailurepackages = (error) => {
    console.log(error);
  };
  useEffect(() => {
    getpackages();
  }, []);

  const getFilterDates = () => {
    apiGet(`/stats/get-filter-date`, onSuccessDates, onFailureDates);
  };

  const onSuccessDates = (response) => {
    setFilterDates(response?.data);
  };

  const onFailureDates = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getFilterDates();
  }, []);

  const handleClick = (val) => {
    const [month, year] = val.split(" ");
    const monthString = month.toUpperCase(); // Convert the month to uppercase
    const monthMap = {
      JANUARY: "1",
      FEBRUARY: "2",
      MARCH: "3",
      APRIL: "4",
      MAY: "5",
      JUNE: "6",
      JULY: "7",
      AUGUST: "8",
      SEPTEMBER: "9",
      OCTOBER: "10",
      NOVEMBER: "11",
      DECEMBER: "12",
    };
    const monthNumberStr = monthMap[monthString];
    const monthNumber = new Date(Date.parse(`${month} 1`)).getMonth() + 1;

    if (monthNumberStr == monthNumber) {
      apiGet(
        `/stats/package-consumption/${year}/${monthNumberStr}`,
        onSuccesspackages,
        onFailurepackages
      );
    }

    setSelectedValue(val);
  };

  const getMonthYear = (value) => {
    const [month] = value.split(" ");
    const monthString = month.toUpperCase(); // Convert the month to uppercase
    const monthMap = {
      JANUARY: "1",
      FEBRUARY: "2",
      MARCH: "3",
      APRIL: "4",
      MAY: "5",
      JUNE: "6",
      JULY: "7",
      AUGUST: "8",
      SEPTEMBER: "9",
      OCTOBER: "10",
      NOVEMBER: "11",
      DECEMBER: "12",
    };
    const monthNumberStr = monthMap[monthString];
    return monthNumberStr;
  };

  const exportBySummary = async () => {
    const [month, year] = selectedValue.split(" ");
    try {
      const response = await axios.get(
        baseUrl +
          `/stats/export/summary-conversation-consumption/${year}/${getMonthYear(
            selectedValue
          )}`,
        {
          responseType: "arraybuffer", // Ensure the response is treated as binary data
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Conversation_summary.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };
  const exportByDetail = async () => {
    const [month, year] = selectedValue.split(" ");

    try {
      const response = await axios.get(
        baseUrl +
          `/stats/export/detail-conversation-consumption/${year}/${getMonthYear(
            selectedValue
          )}`,
        {
          responseType: "arraybuffer", // Ensure the response is treated as binary data
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Conversation_detail.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="boradcastWrp">
        <div className="boradcastTabbing">
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #newAnalyticsTab"
          >
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                <li
                  onClick={() => {
                    navigate(val.route, { replace: true });
                  }}
                  className={val.active === true ? "uk-active" : ""}
                  key={index}
                >
                  <a>{val.name}</a>
                </li>
              ) : null;
            })}
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="newAnalyticsTab">
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                val.name === "Package" ? (
                  <li key={index}>
                    <div className="analyticsContainer">
                      <div className="uk-container">
                        <h2 className="heading uk-margin-medium-top">
                          Package consumption{" "}
                          <CurrentCompanyName companyDetails={companyDetails} />
                        </h2>
                        <p>
                          Here, you can easily track and manage your package
                          usage.
                        </p>
                        <div
                          className="uk-grid analyticsTab uk-flex-middle"
                          uk-grid=""
                        >
                          <div className="uk-width-1-2">
                            <ul
                              className="uk-subnav uk-subnav-pill"
                              uk-switcher="connect: #analyticsTabs"
                            >
                              <li
                                onClick={() => {
                                  navigate("/package-mau", { replace: true });
                                }}
                              >
                                <a>MAU</a>
                              </li>
                              <li
                                className="uk-active"
                                onClick={() => {
                                  navigate("/package-conversation", {
                                    replace: true,
                                  });
                                }}
                              >
                                <a>Conversations</a>
                              </li>
                            </ul>
                          </div>
                          <div className="uk-width-1-2 btnSection">
                            <button
                              className="exportBtn"
                              onClick={exportBySummary}
                            >
                              <Download /> Export by summary
                            </button>
                            <button
                              className="exportBtn"
                              onClick={exportByDetail}
                            >
                              <Download /> Export by detail
                            </button>

                            <div className="uk-inline">
                              <button className="calenderBtn">
                                <Calendar /> {selectedValue}
                                <span className="uk-inline">
                                  <ChevronDown />
                                </span>
                              </button>
                              <div
                                uk-dropdown="mode: click"
                                className="calendarDropdown"
                              >
                                <div className="dropDownHeading">
                                  <span>Select a month</span>
                                </div>
                                <ul>
                                  {filterDates?.map((val, index) => {
                                    return (
                                      <li className="active" key={index}>
                                        <button
                                          type="button"
                                          value={val}
                                          onClick={() => handleClick(val)}
                                        >
                                          <span></span> {val}
                                        </button>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tabContent">
                          <ul
                            className="uk-switcher uk-margin"
                            id="analyticsTabs"
                          >
                            <li></li>
                            <li>
                              <div className="analyticsContent">
                                <div className="mainBox">
                                  <div className="boxHeading">
                                    <span>Highlights</span>
                                  </div>
                                  <div className="boxContent">
                                    <div
                                      className="uk-grid uk-flex-between"
                                      uk-grid=""
                                    >
                                      <div className="uk-width-auto">
                                        <div className="txtwrp">
                                          <h2>
                                            {packages === null ? (
                                              <div uk-spinner=""></div>
                                            ) : (
                                              packages?.totalConversations
                                            )}
                                          </h2>
                                          <span>Total conversations</span>
                                        </div>
                                      </div>
                                      <div className="uk-width-auto">
                                        <div className="txtwrp">
                                          <h2>
                                            {packages === null ? (
                                              <div uk-spinner=""></div>
                                            ) : (
                                              packages?.serviceInitiated
                                            )}
                                          </h2>
                                          <span>Service initiated</span>
                                        </div>
                                      </div>
                                      <div className="uk-width-auto">
                                        <div className="txtwrp">
                                          <h2>
                                            {packages === null ? (
                                              <div uk-spinner=""></div>
                                            ) : (
                                              packages?.ctaInitiated
                                            )}
                                          </h2>
                                          <span>Service initiated (CTA)</span>
                                        </div>
                                      </div>
                                      <div className="uk-width-auto">
                                        <div className="txtwrp">
                                          <h2>
                                            {packages === null ? (
                                              <div uk-spinner=""></div>
                                            ) : (
                                              packages?.authInitiate
                                            )}
                                          </h2>
                                          <span>Authentication</span>
                                        </div>
                                      </div>
                                      <div className="uk-width-auto">
                                        <div className="txtwrp">
                                          <h2>
                                            {packages === null ? (
                                              <div uk-spinner=""></div>
                                            ) : (
                                              packages?.utilityInitiated
                                            )}
                                          </h2>
                                          <span>Utility</span>
                                        </div>
                                      </div>
                                      <div className="uk-width-auto">
                                        <div className="txtwrp">
                                          <h2>
                                            {packages === null ? (
                                              <div uk-spinner=""></div>
                                            ) : (
                                              packages?.marketingInitiated
                                            )}
                                          </h2>
                                          <span>Marketing</span>
                                        </div>
                                      </div>
                                      <div className="uk-width-auto">
                                        <div className="txtwrp">
                                          <h2>
                                            {packages !== null && "$"}
                                            {packages === null ? (
                                              <div uk-spinner=""></div>
                                            ) : (
                                              packages?.totalCostt
                                                .toString()
                                                .slice(0, 6)
                                            )}
                                          </h2>
                                          <span>Approx Cost</span>
                                        </div>
                                      </div>
                                      {/* <div className="uk-width-auto">
                                  <div className="txtwrp">
                                    <h2>{packages?.totalConversations}</h2>
                                    <span>Total Conversations</span>
                                  </div>
                                </div> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="analyticsTableWrp">
                                  <div className="uk-grid" uk-grid="">
                                    {/* <div className="uk-width-1-1">
                                <div className="searchForm">
                                  <form action="">
                                    <input
                                      type="text"
                                      placeholder="Search by name, number"
                                    />
                                  </form>
                                </div>
                              </div> */}
                                  </div>
                                  <table className="uk-table">
                                    <thead>
                                      <tr>
                                        {/* <th>SERIES</th> */}
                                        <th>
                                          <div className="uk-inline tableDropDown">
                                            <button type="button">
                                              ALL CHANNELS
                                              {/* <ChevronDown /> */}
                                            </button>
                                            {/* <div
                                        uk-dropdown="mode: click"
                                        className="dropdownContent"
                                      >
                                        <div className="dropdownHeading">
                                          <span>Channels</span>
                                        </div>
                                        <ul>
                                          <li className="active">
                                            <button type="button">
                                              All channels
                                            </button>
                                          </li>
                                          <li>
                                            <button type="button">
                                              <img src={whatsappLogo} alt="" />{" "}
                                              WhatsApp
                                            </button>
                                          </li>
                                          <li>
                                            <button type="button">
                                              <img src={whatsappLogo} alt="" />{" "}
                                              Facebook
                                            </button>
                                          </li>
                                          <li>
                                            <button type="button">
                                              <img src={whatsappLogo} alt="" />{" "}
                                              Instagram
                                            </button>
                                          </li>
                                        </ul>
                                      </div> */}
                                          </div>
                                        </th>
                                        <th>Range</th>
                                        <th>
                                          <div className="uk-inline tableDropDown">
                                            <button type="button">
                                              CATEGORY
                                              {/* <ChevronDown /> */}
                                            </button>
                                            {/* <div
                                        uk-dropdown="mode: click"
                                        className="dropdownContent"
                                      >
                                        <div className="dropdownHeading">
                                          <span>Segment category</span>
                                        </div>
                                        <ul>
                                          <li
                                            className="active"
                                            style={{ paddingLeft: "25px" }}
                                          >
                                            <button type="button">
                                              Lead generation
                                            </button>
                                          </li>
                                          <li style={{ paddingLeft: "25px" }}>
                                            <button type="button">
                                              Promotion
                                            </button>
                                          </li>
                                        </ul>
                                      </div> */}
                                          </div>
                                        </th>
                                        <th>Type</th>
                                        <th>Country</th>
                                        {/* <th>
                                    <div className="uk-inline tableDropDown">
                                      <button type="button">
                                        STATUS 
                                        <ChevronDown />
                                      </button>
                                      <div
                                        uk-dropdown="mode: click"
                                        className="dropdownContent"
                                      >
                                        <div className="dropdownHeading">
                                          <span>Contact Staus</span>
                                        </div>
                                        <ul>
                                          <li
                                            className="active"
                                            style={{ paddingLeft: "25px" }}
                                          >
                                            <button type="button">
                                              In process
                                            </button>
                                          </li>
                                          <li style={{ paddingLeft: "25px" }}>
                                            <button type="button">
                                              Delivered
                                            </button>
                                          </li>
                                          <li style={{ paddingLeft: "25px" }}>
                                            <button type="button">Seen</button>
                                          </li>
                                          <li style={{ paddingLeft: "25px" }}>
                                            <button type="button">
                                              Failed
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </th> */}
                                        <th>Total Conversation</th>
                                        <th>Cost</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {packages?.conversationAnalyticsResponse
                                        ?.conversation_analytics?.data[0]
                                        ?.data_points.length > 0 ? (
                                        packages?.conversationAnalyticsResponse?.conversation_analytics?.data[0]?.data_points.map(
                                          (val, index) => {
                                            return (
                                              <tr key={index}>
                                                {/* <td>{index + 1}</td> */}
                                                <td>
                                                  <img
                                                    src={whatsappLogo}
                                                    alt=""
                                                  />
                                                </td>
                                                <td>
                                                  {convertTimestamp(val?.start)}{" "}
                                                  - {convertTimestamp(val?.end)}
                                                </td>
                                                <td>
                                                  {val?.conversation_category}
                                                </td>
                                                <td>
                                                  {val?.conversation_type}
                                                </td>
                                                <td>{val?.country}</td>
                                                {/* <td>
                                        <span className="serviceInitiated">
                                          {val.category}
                                        </span>
                                      </td> */}
                                                {/* <td>
                                        <span
                                          className={
                                            val.status === "PENDING"
                                              ? "failed"
                                              : "inProcess"
                                          }
                                        >
                                          {val.status}
                                        </span>
                                      </td> */}
                                                <td>{val.conversation}</td>
                                                <td>{val.cost} USD</td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ) : (
                                        <tr>
                                          <td
                                            colSpan={11}
                                            className="dataNotFound"
                                          >
                                            {packages === null ? (
                                              <div uk-spinner=""></div>
                                            ) : (
                                              "Data Not Found"
                                            )}
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li key={index}></li>
                )
              ) : null;
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Conversationpage;
