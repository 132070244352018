import React, { useState } from "react";
import SettingSideBar from "./components/SettingSideBar";
import Footer from "../../components/common/Footer/Footer";
import NewTagModal from "./components/Modal/NewTagModal";
import DeletePictureModal from "./components/Modal/DeletePictureModal";
import { useAuth } from "../../Context/AuthProvider";
import { useEffect } from "react";
import { apiGet } from "../../Utils/apiServices";
import { useOutletContext } from "react-router-dom";
import { decryptedValue } from "../../Utils/RouteHashConversion";

const TagSetting = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber
  ] = useOutletContext();
  const { auth } = useAuth();
  const [tags, setTags] = useState();
  const [deleteId, setDeleteId] = useState();
  const [openCreateTagModal, setOpenCreateTagModal] = useState(false);
  const [openEditTagModal, setOpenEditTagModal] = useState(false);
  const [editData, setEditData] = useState();
  const [openDeleteTagModal, setOpenDeleteTagModal] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);

  const getTags = () => {
    apiGet(`/contact/tags`, onSuccessTags, onFailureTags);
  };
  const onSuccessTags = (response) => {
    setApiCalled(false);
    setTags(response?.data);
  };
  const onFailureTags = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getTags();
  }, []);
  useEffect(() => {
    if (apiCalled === true) {
      setTags([]);
      getTags();
    }
  }, [apiCalled]);
  const handleOpenTagModal = () => {
    setOpenCreateTagModal(true);
  };

  const handleCloseTagModal = () => {
    setOpenCreateTagModal(false);
  };
  const handleOpenEditTagModal = (val) => {
    setEditData(val);
    setOpenEditTagModal(true);
  };

  const handleCloseEdtTagModal = () => {
    setOpenEditTagModal(false);
  };

  const handleOpenDeleteTagModal = (publicId) => {
    setDeleteId(publicId);
    setOpenDeleteTagModal(true);
  };

  const handleCloseDeleteTagModal = () => {
    setOpenDeleteTagModal(false);
  };
  return (
    <>
      <div className="settingwrp">
        <div className="topBar">
          <div className="uk-grid" uk-grid="">
            <div className="uk-width-1-1">
              <div className="txtwrp">
                <h3>Settings</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="settingContentWrp">
          <div className="uk-grid uk-grid-small" uk-grid="">
            <div className="uk-width-auto">
            <SettingSideBar  userPermissions={userPermissions}/>
            </div>
            <div className="uk-width-expand">
              <div
                className="settingWrapper"
                style={{ minHeight: "calc(100vh - 240px)" }}
              >
                <div className="uk-container">
                  <div className="settingTabbing">
                    <div className="generalSettingWrp">
                      <div className="mainHeading">
                        <h3>Tags</h3>
                        <p>View and configure all tags.</p>
                      </div>
                      <div className="boxwrp">
                        <div className="formwrp">
                          <form action="">
                            <div className="boxHeading">
                              <h6>Tags</h6>
                              <p>
                                Tags can help you organize your contacts and
                                create segmentation.
                              </p>
                            </div>
                            <div className="uk-grid uk-grid-small" uk-grid="">
                              {tags?.map((val, index) => {
                                return (
                                  <div className="uk-width-1-1" key={index}>
                                    <div className="formInput">
                                      <input
                                        type="text"
                                        className="uk-input"
                                        defaultValue={val.tagName}
                                      />
                                      <div
                                        className="changeBtn changeBtn2"
                                        style={{ top: "17%" }}
                                      >
                                        <button
                                          type="button"
                                          className="uk-margin-small-right"
                                          onClick={(e) =>
                                            handleOpenDeleteTagModal(
                                              // val.publicId
                                              val.companyPublicId
                                            )
                                          }
                                        >
                                          Delete
                                        </button>
                                        <button
                                          type="button"
                                          onClick={(e) =>
                                            handleOpenEditTagModal(val)
                                          }
                                        >
                                          Edit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}

                              {/* <div className="uk-width-1-1">
                                <div className="formInput">
                                  <input type="text" className="uk-input" />
                                  <div
                                    className="changeBtn changeBtn2"
                                    style={{ top: "17%" }}
                                  >
                                    <button
                                      type="button"
                                      className="uk-margin-small-right"
                                    >
                                      Delete
                                    </button>
                                    <button type="button">Edit</button>
                                  </div>
                                </div>
                              </div>
                              <div className="uk-width-1-1">
                                <div className="formInput">
                                  <input type="text" className="uk-input" />
                                  <div
                                    className="changeBtn changeBtn2"
                                    style={{ top: "17%" }}
                                  >
                                    <button
                                      type="button"
                                      className="uk-margin-small-right"
                                    >
                                      Delete
                                    </button>
                                    <button type="button">Edit</button>
                                  </div>
                                </div>
                              </div> */}
                              <div className="uk-width-1-1">
                                <div className="settingAddBtn">
                                  <button
                                    type="button"
                                    onClick={handleOpenTagModal}
                                  >
                                    Add new tag
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
      {openCreateTagModal && (
        <NewTagModal
          closeModal={handleCloseTagModal}
          setApiCalled={setApiCalled}
          heading={"New tag"}
          description={"Add a new tag to organize your contacts."}
          btnText={"Create"}
        />
      )}
      {openEditTagModal && (
        <NewTagModal
          closeModal={handleCloseEdtTagModal}
          setApiCalled={setApiCalled}
          heading={"Edit tag"}
          description={"Edit tag to organize your contacts."}
          btnText={"Update"}
          editData={editData}
        />
      )}
      {openDeleteTagModal && (
        <DeletePictureModal
          closeModal={handleCloseDeleteTagModal}
          deleteHeading="Delete tag"
          deleteTxt="Are you sure you want to delete this tag type?"
          deleteId={deleteId}
          setApiCalled={setApiCalled}
          deleteApi={"tag"}
        />
      )}
    </>
  );
};

export default TagSetting;
