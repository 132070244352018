export const generateColors = (numColors) => {
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    const hue = Math.floor((i * 360) / numColors);
    colors.push(`hsl(${hue}, 70%, 50%)`);
  }
  return colors;
};

// export const convertMinutes = (totalMinutes) => {
//   const hours = Math.floor(totalMinutes / 60);
//   const minutes = totalMinutes % 60;

//   if (hours > 0) {
//     return `${hours}h ${minutes.toFixed(0)}m`;
//   } else {
//     return `${minutes}m`;
//   }
// };
export const convertMinutesWithdays = (totalMinutes) => {
  const days = Math.floor(totalMinutes / 1440);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  if (days > 0) {
    return `${days} days`;
  } else if (hours > 0) {
    return `${hours}h ${minutes.toFixed(0)}m`;
  } else {
    return `${minutes}m`;
  }
};
export const convertHoursWithTime = (totalMinutes) => {
  // const days = Math.floor(totalMinutes / 1440);
  // const hours = Math.floor(totalMinutes / 60);
  const hours = Math.floor(totalMinutes);
  const minutes = totalMinutes % 60;
  // if (days > 0) {
  //   return `${days} days`;
  // } else
  if (hours > 0) {
    return `${hours}h ${minutes.toFixed(0)}m`;
  } else {
    return `${minutes.toFixed(0)}m`;
  }
};

export const convertMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (hours > 0) {
    if (hours > 24) {
      return `24h`;
    } else {
      return `${hours}h ${minutes.toFixed(0)}m`;
    }
  } else {
    return `${minutes.toFixed(0)}m`;
  }
};

// export const convertMinutes = (totalMinutes) => {
//   if (totalMinutes < 1) {
//     const totalSeconds = Math.floor(totalMinutes * 60);
//     return `${totalSeconds}s`;
//   }

//   const hours = Math.floor(totalMinutes / 60);
//   const minutes = totalMinutes % 60;

//   if (hours > 0) {
//     return `${hours}h ${minutes}m`;
//   } else {
//     return `${minutes}m`;
//   }
// };

export const findChannelExist = (data, channel) => {
  let result = data
    .map(function (o) {
      return o.chanelId;
    })
    .indexOf(channel);

  if (result > -1) {
    return true;
  } else {
    return false;
  }
};

export const convertIntoThousand = (value) => {
  // input: 123764
  // output : 123.8k
  if (value >= 1000) {
    // Convert to "K" format
    return (value / 1000).toFixed(1) + "K";
  }
  return value.toString(); // Return the original value if less than 1000
};
