import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { Link, useNavigate } from "react-router-dom";
import whatsappImg from "../../../assets/images/whatsapp-icon-new.jpg";
import {
  Download,
  Edit,
  Filter,
  OverflowMenuVertical,
  TrashCan,
  View,
} from "@carbon/icons-react";
import DeleteModal from "../../../components/Modal/DeleteModal";
import { apiGet, apiPost } from "../../../Utils/apiServices";
import berrytalk from "../../../assets/images/sidebar-logo.svg";
import telenor from "../../../assets/images/zong-icon.png";
import zong from "../../../assets/images/telenor-icon.png";
import { dataTime } from "../../../Utils/DateFunctions";
import { toast } from "react-toastify";

const CompanyList = () => {
  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
  const [companies, setCompanies] = useState(null);
  const [searchCompany, setSearchCompany] = useState(null);
  const [searchCompanyFilter, setSearchCompanyFilter] = useState(null);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [error, setError] = useState("");
  const [dateError, setDateError] = useState("");
  const [loader, setLoader] = useState(false);

  const [actualStatus, setActualStatus] = useState({});
  const [tempStatus, setTempStatus] = useState({});
  const [loading, setLoading] = useState({});

  const [filterDataSearch, setFilterDataSearch] = useState({
    fromDate: null,
    toDate: null,
    isActive: "",
    portal: "",
  });

  const getCompanyList = () => {
    apiGet(`/super-admin/getCompanies`, onSuccessStats, onFailureStats);
  };
  const onSuccessStats = (response) => {
    setCompanies(response.data);
    setSearchCompany(response.data);
    setSearchCompanyFilter(response.data)
  };
  const onFailureStats = (error) => {
    console.log(error);
  };

  //change switch **************************************************************
  const handleChecked = (portal, publicId) => {
    const newStatus = !tempStatus[publicId];
    setTempStatus((prevState) => ({
      ...prevState,
      [publicId]: newStatus,
    }));
    setLoading((prevState) => ({
      ...prevState,
      [publicId]: true,
    }));
  
    companyStatusChange(portal, publicId);
  };
  
  const companyStatusChange = (portal, publicId) => {
    apiGet(
      `/super-admin/company/${portal}/${publicId}/disable`,
      () => onSuccessEnable(publicId),
      () => onFailureEnable(publicId)
    );
  };
  
  const onSuccessEnable = (publicId) => {
    const newStatus = tempStatus[publicId];
    setActualStatus((prevState) => ({
      ...prevState,
      [publicId]: newStatus,
    }));
    setLoading((prevState) => ({
      ...prevState,
      [publicId]: false,
    }));
  
    setTimeout(() => {
      toast.success("Company status updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      getCompanyList();
    }, 1500);
  };
  
  const onFailureEnable = (publicId) => {
    setTempStatus((prevState) => ({
      ...prevState,
      [publicId]: !prevState[publicId], // Revert to previous status
    }));
    setLoading((prevState) => ({
      ...prevState,
      [publicId]: false,
    }));
    toast.error("Failed to update company status", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };
  
  useEffect(() => {
    const initialStatus = companies?.reduce((acc, company) => {
      acc[company.publicId] = company.isActive; // Assuming `isActive` indicates the company's status
      return acc;
    }, {});
    setActualStatus(initialStatus);
    setTempStatus(initialStatus);
  }, [companies]);
  //change switch **************************************************************

  const navigate = useNavigate();
  const [openAdminDeleteModal, setOpenAdminDeleteModal] = useState(false);

  const handleOpenAdminDeleteModal = () => {
    setOpenAdminDeleteModal(true);
  };

  const handleCloseAdminDeleteModal = () => {
    setOpenAdminDeleteModal(false);
  };


  const handleSearchChange = (e) => {
    const value = e.target.value;
    const results = searchCompany?.filter((user) => {
      return user?.companyName?.toLowerCase().includes(value);
    });
      setCompanies(results);
  };


  // modal start
  const handleOpenFilterModal = () => {
    setOpenFilterModal(true);
  };
  const handleCLoseFilterModal = () => {
    setOpenFilterModal(false);
    setError("");
  };

  //filter modal
  const searchAndFilter = () => {
    var Errorvariable = "";
    const data = {
      dateStart: filterDataSearch.fromDate,
      dateEnd: filterDataSearch.toDate,
      portal: filterDataSearch.portal,
      status: filterDataSearch.isActive,
    };

    if (
      ((data.dateStart === null && data.dateEnd === null) ||
        (data.dateStart === "" && data.dateEnd === "")) &&
      (data.portal === "" || data.portal === null) &&
      (data.status === undefined ||
        data.status === "" ||
        data.status === null) &&
      (filterDataSearch?.fromDate === "" ||
        filterDataSearch?.fromDate === null) &&
      (filterDataSearch?.isActive === "" ||
        filterDataSearch?.isActive === null) &&
      (filterDataSearch?.portal === "" || filterDataSearch?.portal === null) &&
      (filterDataSearch?.toDate === "" || filterDataSearch?.toDate === null) &&
      dateError === ""
    ) {
      Errorvariable = "Please select parameter for filter";
      setError(Errorvariable);
    } else if (Errorvariable === "" && error === "" && dateError === "") {
      setLoader(true);
      apiPost(`/super-admin/filterCompanies`, onSuccess, onFailure, data);
    }
  };
  const onSuccess = (response) => {
    if (response.status === 0) {
      // setTimeout(() => {
        setLoader(false);
        handleCLoseFilterModal();
      // }, 2000);
  
        setCompanies(response?.data);
  
    }
  };
  const onFailure = (error) => {
    console.log(error);
  };
  const clearFilter = () => {
    setFilterDataSearch({
      fromDate: "",
      toDate: "",
      portal: "",
      isActive: "",
    });
    setTimeout(() => {
      setLoader(false);
      getCompanyList();
    }, 2000);
    setError("");
  };

  //filter modal
  const handleToDateErrors = (value) => {
    setError("");
    if (value <= today) {
      if (!filterDataSearch.fromDate) {
        setDateError("From Date is Missing");
      }
      if (filterDataSearch.fromDate && value) {
        if (value < filterDataSearch.fromDate) {
          setDateError("To date must be greater than From date");
        } else {
          setDateError("");
        }
      }
    } else {
      if (value > today) {
        setFilterDataSearch({
          ...filterDataSearch,
          toDate: "",
        });
        setDateError("To Date exceeded with today's date");
      }
    }
  };

  const handleFromDateErrors = (value) => {
    setError("");
    if (value <= today) {
      if (!filterDataSearch.toDate) {
        setDateError("To Date is Missing");
      }
      if (filterDataSearch.toDate && value) {
        if (value > filterDataSearch.toDate) {
          setDateError("From date must be lesser than To date");
        } else {
          setDateError("");
        }
      }
    } else {
      if (value > today) {
        setFilterDataSearch({
          ...filterDataSearch,
          fromDate: "",
        });
        setDateError("From Date exceeded with today's date");
      }
    }
  };

  useEffect(() => {
    getCompanyList();
  }, []);
  //modal end

  return (
    <>
      <div className="userWrp">
        <div className="userHeader">
          <div className="uk-grid uk-flex-middle" uk-grid="">
            <div className="uk-width-1-3">
              <div className="headerTabs">
                <ul
                  className="uk-subnav uk-subnav-pill"
                  uk-switcher="connect: .userTabbing"
                >
                  <li
                    onClick={() => {
                      navigate("/super-admin-users", { replace: false });
                    }}
                  >
                    <a>Super Admins</a>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/super-admin-company", { replace: true });
                    }}
                    className="uk-active"
                  >
                    <a>Companies</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="uk-width-1-3">
              <div className="chatSearchFilter">
                <ul className="uk-switcher  userTabbing">
                  <li></li>
                  <li>
                    <div className="searchAndFilter">
                      <div className="headerSearch">
                        <form
                          action=""
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Search companies details here"
                            onChange={(e) => handleSearchChange(e)}
                          />
                        </form>
                      </div>
                      <div className="filterBtn">
                        <button
                          type="button"
                          onClick={() => handleOpenFilterModal()}
                        >
                          <Filter /> <span>Filter</span>
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="uk-width-1-3">
              <ul className="uk-switcher uk-margin userTabbing">
                <li></li>
                <li>
                  <div className="headerBtn">
                    <Link to="/super-admin-create-company">Add company</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="userTabContent">
          <div className="uk-container uk-container-large">
            <ul className="uk-switcher uk-margin userTabbing">
              <li></li>
              <li>
                <div className="userTableWrp">
                  <table className="uk-table">
                    <thead>
                      <tr>
                        <th>COMPANY</th>
                        <th>TOTAL CONVERSATIONS</th>
                        <th>MAU CONSUMPTION</th>
                        <th>PACKAGE</th>
                        <th>PORTAL</th>
                        <th>STATUS</th>
                        <th>BOARDED ON</th>
                      </tr>
                    </thead>
                    <tbody>
                      {companies?.length > 0 ? (
                        companies?.map((val, index) => {
                          return (
                            <tr key={index}>
                              <td className="agnetName">
                                {/* <Link to="/super-admin-single-company"> */}
                                <Link>
                                  <span className="InitialImage">
                                    {val?.companyName?.substring(0, 1)}
                                  </span>
                                  {val?.companyName}
                                </Link>
                                {/* ) : (
                                    <img
                                      src={chatBaseUrl + val?.profilePic}
                                      alt=""
                          />
                                  ) */}
                              </td>
                              <td>
                                {val?.totalConversation === null
                                  ? 0
                                  : val?.totalConversation?.toLocaleString()}
                              </td>
                              <td>{val?.totalMaus?.toLocaleString() || 0}</td>
                              <td>
                                PKR{" "}
                                {val?.price === null
                                  ? 0
                                  : val?.price?.toLocaleString()}
                              </td>
                              <td>
                                {val?.portal === null ||
                                val?.portal === "QA" ? (
                                  <span style={{ textAlign: "center" }}>
                                    {" "}
                                    -
                                  </span>
                                ) : (
                                  <img
                                    // src={chatBaseUrl + "/" + val?.companyLogoUrl}
                                    src={
                                      (val?.portal === "Zverse" && zong) ||
                                      (val?.portal === "BerryTalks" &&
                                        berrytalk) ||
                                      (val?.portal === "TelenorEngage" && 
                                        telenor)
                                    }
                                    alt=""
                                  />
                                )}
                              </td>
                              <td className="tableSwitch">
                                <Switch
                                  onChange={() =>
                                    handleChecked(val?.portal, val?.publicId)
                                  }
                                  checked={tempStatus?.[val?.publicId] || false}
                                  disabled={loading?.[val?.publicId] || false}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  width={26}
                                  height={14}
                                  handleDiameter={10}
                                  onColor="#29A869"
                                />
                              </td>
                              {/* <td className="tableSwitch">
                                <Switch
                                  // onChange={() =>
                                  //   handleChecked(val?.isAvailable, val?.publicId)
                                  // }
                                  // checked={"avaaible" === true ? true : false}
                                  checked={val?.isActive}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  width={26}
                                  height={14}
                                  handleDiameter={10}
                                  onColor="#29A869"
                                />
                              </td> */}
                              <td>{dataTime(val?.updateDateTime)}</td>
                              <td>
                                <div className="uk-inline">
                                  <button type="button">
                                    <OverflowMenuVertical />
                                  </button>
                                  <div
                                    uk-dropdown=""
                                    className="contactBlckDrop"
                                  >
                                    <ul>
                                      <li>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            navigate(
                                              "/super-admin-single-company-view",
                                              { state: val }
                                            )
                                          }
                                        >
                                          <View /> View
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            navigate(
                                              "/super-admin-single-company",
                                              { state: val }
                                            )
                                          }
                                        >
                                          <Edit /> Edit
                                        </button>
                                      </li>

                                      {/* <li>
                                        <button type="button">
                                          <Download /> Download data
                                        </button>
                                      </li>
                                      <hr
                                        style={{
                                          marginTop: "5px",
                                          marginBottom: "5px",
                                        }}
                                      />  */}
                                      {/* <li>
                                        <button
                                          className="trashBtn"
                                          type="button"
                                          onClick={handleOpenAdminDeleteModal}
                                        >
                                          <TrashCan /> Delete
                                        </button>
                                      </li> */}
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={11} className="dataNotFound">
                            {companies === null ? (
                              <div uk-spinner=""></div>
                            ) : (
                              "Data Not Found"
                            )}
                          </td>
                        </tr>
                      )}
                      {/* <tr>
                        <td className="agnetName">
                          <Link to="/super-admin-single-company">
                            <span className="InitialImage">
                            </span>
                            Company 01
                          </Link>

                          <img src={chatBaseUrl + val?.profilePic} alt="" />
                        </td>
                        <td>5</td>
                        <td>PKR 30,000</td>
                        <td>
                          <img src={whatsappImg} alt="" />
                        </td>
                        <td className="tableSwitch">
                          <Switch
                            // onChange={() =>
                            //   handleChecked(val?.isAvailable, val?.publicId)
                            // }
                            checked={"avaaible" === true ? true : false}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            width={26}
                            height={14}
                            handleDiameter={10}
                            onColor="#29A869"
                          />
                        </td>
                        <td>14 Jul</td>
                        <td>
                          <div className="uk-inline">
                            <button type="button">
                              <OverflowMenuVertical />
                            </button>
                            <div uk-dropdown="" className="contactBlckDrop">
                              <ul>
                                <li>
                                  <button type="button">
                                    <Edit /> Edit
                                  </button>
                                </li>
                                <li>
                                  <button type="button">
                                    <Download /> Download data
                                  </button>
                                </li>
                                <hr
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                />
                                <li>
                                  <button
                                    className="trashBtn"
                                    type="button"
                                    onClick={handleOpenAdminDeleteModal}
                                  >
                                    <TrashCan /> Delete
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {openAdminDeleteModal && (
        <DeleteModal
          closeModal={handleCloseAdminDeleteModal}
          dltModalTxt="Are you sure you want to delete this user? This will affect the
                team members of selected user."
          heading={"Delete User"}
        />
      )}

      {openFilterModal && (
        <div
          className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
          uk-modal="esc-close: false; bg-close: false"
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <div className="filterModalContent">
              <div className="modalHeading">
                <span>Search fIlter</span>
              </div>
              <div className="modalForm">
                <div className="formHeading">
                  <span>Filter by month, platform and company status</span>
                </div>
                <form
                  action=""
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="uk-grid uk-grid-small" uk-grid="">
                    <div className="uk-width-1-1">
                      <div className="formInputs">
                        <label htmlFor="from_date">From Date</label>
                        <input
                          id="fromDate"
                          type="date"
                          value={filterDataSearch?.fromDate}
                          max={today}
                          onChange={(e) => {
                            setFilterDataSearch({
                              ...filterDataSearch,
                              fromDate: e.target.value,
                            });
                            handleFromDateErrors(e.target.value);
                          }}
                          className="uk-input"
                        />
                      </div>
                    </div>

                    <div className="uk-width-1-1">
                      <div className="formInputs">
                        <label htmlFor="to_date">To date</label>
                        <input
                          id="toDate"
                          type="date"
                          value={filterDataSearch?.toDate}
                          onChange={(e) => {
                            setFilterDataSearch({
                              ...filterDataSearch,
                              toDate: e.target.value,
                            });
                            handleToDateErrors(e.target.value);
                          }}
                          className="uk-input"
                          max={today}
                        />
                      </div>
                    </div>
                    {dateError !== "" && (
                      <div className="uk-width-1-1 formErrors">{dateError}</div>
                    )}
                    <div className="uk-width-1-1">
                      <div className="formInputs">
                        <label htmlFor="portal">Portal</label>
                        <select
                          name="portal"
                          id="portal"
                          className="uk-select"
                          value={filterDataSearch?.portal}
                          onChange={(e) => {
                            setFilterDataSearch({
                              ...filterDataSearch,
                              portal: e.target.value,
                            });
                            setError("");
                          }}
                        >
                          <option value="">All</option>
                          <option value="Zverse">Zverse</option>
                          <option value="BerryTalks">Berrytalk</option>
                          <option value="TelenorEngage">Telenor engage</option>
                        </select>
                      </div>
                    </div>

                    <div className="uk-width-1-1">
                      <div className="formInputs">
                        <label htmlFor="status">Company status</label>
                        <select
                          name="status"
                          id="status"
                          className="uk-select"
                          value={filterDataSearch?.isActive}
                          onChange={(e) => {
                            setFilterDataSearch({
                              ...filterDataSearch,
                              isActive: e.target.value,
                            });
                            setError("");
                          }}
                        >
                          <option value="">Select company status</option>
                          <option value="true">Active </option>
                          <option value="false">Inactive</option>
                        </select>
                      </div>
                    </div>
                    {error !== "" && (
                      <div className="uk-width-1-1 formErrors">{error}</div>
                    )}

                    <div className="uk-width-1-1">
                      <div className="btnwrp">
                        <button
                          type="button"
                          className="btn-1"
                          onClick={() => clearFilter()}
                        >
                          Clear filter
                        </button>

                        <button
                          type="button"
                          className="btn-1"
                          onClick={handleCLoseFilterModal}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn-2 w-90"
                          onClick={searchAndFilter}
                        >
                          {loader ? (
                            <div uk-spinner="" className="loader"></div>
                          ) : (
                            "Search"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyList;
