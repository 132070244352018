import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { ChevronLeft, Image } from "@carbon/icons-react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ValidateEmail } from "../../../Utils/regexFunctions";
import { apiGet, apiPost } from "../../../Utils/apiServices";
import { decryptedValue } from "../../../Utils/RouteHashConversion";
import { useAuth } from "../../../Context/AuthProvider";

const CreateCompany = () => {
  const { auth } = useAuth();

  const navigate = useNavigate();
  const [portalList, setPortalList] = useState([]);
  const [errorEnable, setErrorEnable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [filePath, setFilePath] = useState("");
  const [submittedData, setSubmittedData] = useState({
    companyName: "",
    businessWhatsapp: "",
    contactPersonNumber: "",
    whatsappNumberId: "",
    businessId: "",
    code: "",
    industry: "",
    whatsappLink: "",
    type: "",
    domain: "",
    email: "",
    companyContactPersonName: "",
    companyLogoFileId: "",
    businessProfile: "",
    instagramId: "",
    instagramToken: "",
    facebookId: "",
    facebookToken: "",
    isFacebookActive: false,
    isInstaActive: false,
    isWhatsappActive: true,
    isPackageDetailActive: false,
    portalName: "",
    activeUser: "",
    whatsappConversation: "",
    liveAgent: "",
    price: "",
    title: "",
  });

  const handleEmailValidation = (e) => {
    var Email = ValidateEmail(e);
    if (Email === "Invalid Email") {
      setEmailError("Invalid Email Address");
    } else {
      setEmailError("Valid Email Address");
    }
  };

  // const handleFileImage = (e) => {
  //   var file = e.target.files[0];
  //   var formData = new FormData();
  //   formData.append("file", file);
  //   formData.append("agentPublicId", decryptedValue(auth.publicId));
  //   formData.append("companyId", decryptedValue(auth.companyId));
  //   apiPost(
  //     `/file/upload-file`,
  //     onSuccessFileUpload,
  //     onFailureFileUpload,
  //     formData
  //   );
  // };
  const handleFileImage = (e) => {
    var file = e.target.files[0];
    
    var allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/webp"];
    
    var maxSize = 10 * 1024 * 1024; // 10MB
  
    if (!allowedTypes.includes(file.type)) {
      showToast("Invalid image format. Only jpg, jpeg, png, and webp are allowed.");
      return;
    }
  
    if (file.size > maxSize) {
      showToast("Image size limit exceeded. The maximum allowed size is 10MB.");
      return;
    }
  
    var formData = new FormData();
    formData.append("file", file);
    formData.append("agentPublicId", decryptedValue(auth.publicId));
    formData.append("companyId", decryptedValue(auth.companyId));
  
    apiPost(
      `/file/upload-file`,
      onSuccessFileUpload,
      onFailureFileUpload,
      formData
    );
  };
  

  const showToast = (message) => {
    toast.error(message);
  };

  const onSuccessFileUpload = (response) => {
    if (response.status == 1) {
      setFilePath(response.message);
      setSubmittedData({
        ...submittedData,
        companyLogoFileId: response.message,
      });
    }
  };

  const onFailureFileUpload = (error) => {
    console.log(error);
  };

  const getPortalList = () => {
    apiGet(`/super-admin/getPortalList`, onSuccessPortal, onFailurePortal);
  };
  const onSuccessPortal = (response) => {
    setPortalList(response);
  };
  const onFailurePortal = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getPortalList();
  }, []);

  const addCompany = (e) => {
    e.preventDefault();
    setErrorEnable(true);
    setLoader(true);
    let data = {
      companyName: submittedData.companyName,
      businessWhatsapp: submittedData.businessWhatsapp,
      contactPersonNumber: submittedData.contactPersonNumber,
      whatsappNumberId: submittedData.whatsappNumberId,
      businessId: submittedData.businessId,
      code: submittedData.code,
      industry: submittedData.industry,
      whatsappLink: submittedData.whatsappLink,
      type: submittedData.type,
      domain: submittedData.domain,
      email: submittedData.email,
      companyContactPersonName: submittedData.companyContactPersonName,
      companyLogoFileId: submittedData.companyLogoFileId,
      businessProfile: submittedData.businessProfile,
      instagramId: submittedData.instagramId,
      instagramToken: submittedData.instagramToken,
      facebookId: submittedData.facebookId,
      facebookToken: submittedData.facebookToken,
      isFacebookActive: submittedData.isFacebookActive,
      isPackageDetailActive: submittedData.isPackageDetailActive,
      isInstaActive: submittedData.isInstaActive,
      isWhatsappActive: submittedData.isWhatsappActive,
      portalName: submittedData.portalName,
      activeUser: submittedData.activeUser,
      whatsappConversation: submittedData.whatsappConversation,
      liveAgent: submittedData.liveAgent,
      price: submittedData.price,
      title: submittedData.title,
    };

    if (
      submittedData.companyName !== "" &&
      submittedData.businessWhatsapp !== "" &&
      submittedData.contactPersonNumber !== "" &&
      submittedData.whatsappNumberId !== "" &&
      submittedData.businessId !== "" &&
      submittedData.code !== "" &&
      submittedData.industry !== "" &&
      submittedData.whatsappLink !== "" &&
      submittedData.type !== "" &&
      submittedData.portalName !== "" &&
      submittedData.domain !== "" &&
      submittedData.email !== "" &&
      submittedData.companyContactPersonName !== "" &&
      submittedData.businessProfile !== "" &&
      emailError === "Valid Email Address" &&
      (submittedData.isWhatsappActive ||
        submittedData.isFacebookActive ||
        submittedData.isInstaActive ||
        submittedData.isPackageDetailActive) &&
      submittedData.activeUser !== "" &&
      submittedData.activeUser >= 0 &&
      submittedData.whatsappConversation !== "" &&
      submittedData.whatsappConversation >= 0 &&
      submittedData.liveAgent !== "" &&
      submittedData.liveAgent >= 0 &&
      submittedData.price !== "" &&
      submittedData.price >= 0 &&
      submittedData.title !== ""
    ) {
      apiPost(`/super-admin/public/onBoardCompany`, onSuccess, onFailure, data);
    } else if (
      submittedData.price < 0 ||
      submittedData.liveAgent < 0 ||
      submittedData.whatsappConversation < 0 ||
      submittedData.activeUser < 0
    ) {
      showToast("Negative values are not allowed!");
      return;
    } else {
      setLoader(false);
    }
  };
  const onSuccess = (response) => {
    if (response.status === 200) {
      setTimeout(() => {
        setLoader(false);
        toast.success("Company created successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1000);
      setTimeout(() => {
        navigate("/super-admin-company", { replace: true });
      }, 2000);
    }
  };
  const onFailure = (error) => {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoader(false);
  };

  return (
    <div className="addTeamWrp">
      <div className="uk-container">
        <div className="backBtn">
          <Link to="/super-admin-company">
            <ChevronLeft /> Back to all companies
          </Link>
        </div>
        <div className="addTeamHeading">
          <h3>Create Company</h3>
          <p>
            Enter details to be used for company creation, assign or create
            super admin, select platform and package accordingly.
          </p>
        </div>
        <div className="addTeamBox">
          <div className="boxHeading">
            <h3>Company information</h3>
          </div>
          <div className="formwrp">
            <form onSubmit={addCompany} autoComplete="off">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">Company name *</label>
                    <input
                      type="text"
                      placeholder="Enter company name"
                      className="uk-input"
                      value={submittedData.companyName}
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          companyName: e.target.value,
                        });
                      }}
                    />

                    {submittedData.companyName === "" && errorEnable && (
                      <div className="formErrors">Company name is required</div>
                    )}
                  </div>
                </div>

                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">Industry *</label>
                    <input
                      type="text"
                      placeholder="IT, Mobility, Digital etc"
                      className="uk-input"
                      value={submittedData.industry}
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          industry: e.target.value,
                        });
                      }}
                    />
                    {submittedData.industry === "" && errorEnable && (
                      <div className="formErrors">Industry is required</div>
                    )}
                  </div>
                </div>

                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">Company logo *</label>
                    <div uk-form-custom="target: true" className="fileInputDiv">
                      <div className="fileInsideImg">
                        <Image />
                      </div>
                      <div className="fileInputAndBtn"></div>
                      <input
                        type="file"
                        aria-label="Custom controls"
                        className="fileInput1"
                        onChange={(e) => handleFileImage(e)}
                      />
                      <input
                        className="uk-input uk-form-width-medium fileInput2 "
                        type="text"
                        aria-label="Custom controls"
                        placeholder="Upload Image"
                        disabled
                      />
                    </div>

                    {submittedData.companyLogoFileId === "" && errorEnable && (
                      <div className="formErrors">Company logo is required</div>
                    )}
                  </div>
                </div>

                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">Type *</label>
                    <select
                      name=""
                      className="uk-select"
                      value={submittedData.type}
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          type: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected>
                        Please select type
                      </option>
                      <option value="OWNER">OWNER </option>
                    </select>
                    {submittedData.type === "" && errorEnable && (
                      <div className="formErrors">Type is required</div>
                    )}
                  </div>
                </div>

                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">Domain *</label>
                    <input
                      type="text"
                      placeholder="www.domain.com"
                      className="uk-input"
                      value={submittedData.domain}
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          domain: e.target.value,
                        });
                      }}
                    />
                    {submittedData.domain === "" && errorEnable && (
                      <div className="formErrors">Domain is required</div>
                    )}
                  </div>
                </div>

                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">Portal name *</label>
                    <select
                      name=""
                      className="uk-select"
                      value={submittedData.portalName}
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          portalName: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected>
                        Please select portal name
                      </option>
                      {portalList?.map((val, index) => {
                        return (
                          <option key={index} value={val.companyName}>
                            {val.companyName}
                          </option>
                        );
                      })}
                    </select>
                    {submittedData.portalName === "" && errorEnable && (
                      <div className="formErrors">Portal name is required</div>
                    )}
                  </div>
                </div>

                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">
                      Company contact person name *
                    </label>
                    <input
                      type="text"
                      placeholder="Company contact person name"
                      className="uk-input"
                      value={submittedData.companyContactPersonName}
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          companyContactPersonName: e.target.value,
                        });
                      }}
                    />
                    {submittedData.companyContactPersonName === "" &&
                      errorEnable && (
                        <div className="formErrors">
                          Contact person name is required
                        </div>
                      )}
                  </div>
                </div>

                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">
                      Contact person whatsapp number *
                    </label>
                    <input
                      type="number"
                      placeholder="92 XXX XXX XXXX"
                      className="uk-input"
                      value={submittedData.contactPersonNumber}
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          contactPersonNumber: e.target.value,
                        });
                      }}
                    />
                    {submittedData.contactPersonNumber === "" &&
                      errorEnable && (
                        <div className="formErrors">
                          Contact person whatsapp number is required
                        </div>
                      )}
                  </div>
                </div>

                <div className="uk-width-1-1">
                  <div className="formInput">
                    <label htmlFor="f_name">Admin email *</label>
                    <input
                      type="email"
                      placeholder="xyz@yopmail.com"
                      className="uk-input"
                      value={submittedData.email}
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          email: e.target.value,
                        });
                        handleEmailValidation(e.target.value);
                      }}
                    />
                    {submittedData.email === "" && errorEnable && (
                      <div className="formErrors">Email is required</div>
                    )}
                    {submittedData.email !== "" &&
                      emailError !== "Valid Email Address" && (
                        <div className="formErrors">Invalid Email Address</div>
                      )}
                  </div>
                </div>

                <div className="uk-width-1-1">
                  <div className="adminRoleWrp">
                    <div className="switchwrp">
                      <div className="adminHeading">
                        <span>Channel selection</span>
                      </div>
                      <div className="adminBox">
                        <div className="boxInnerHeading">
                          <span
                            style={{
                              fontSize: "15px",
                              display: "block",
                            }}
                          >
                            Choose your preferred social media platform which
                            will be used. By selecting a specific channel, you
                            can streamline your social media interactions,
                            ensuring better customer satisfaction.
                          </span>
                        </div>
                        <div className="txtAndSwitchWrp">
                          <div className="boxHeading">
                            <h3>WhatsApp</h3>
                          </div>
                          <div className="txtAndSwitch">
                            <div className="txtwrp">
                              <p>
                                Provide continuous support on WhatsApp, run
                                broadcasts and enrich customer experience.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="uk-grid uk-grid-small" uk-grid="">
                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="f_name">
                                Business WhatsApp *
                              </label>
                              <input
                                type="number"
                                placeholder="92 XXX XXX XXXX"
                                className="uk-input"
                                value={submittedData.businessWhatsapp}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    businessWhatsapp: e.target.value,
                                  });
                                }}
                              />
                              {submittedData.businessWhatsapp === "" &&
                                errorEnable && (
                                  <div className="formErrors">
                                    Business whatsapp is required
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="f_name">Country code *</label>
                              <input
                                type="text"
                                placeholder="+92"
                                className="uk-input"
                                // value="+92"
                                value={submittedData.code}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    code: e.target.value,
                                  });
                                }}
                              />
                              {submittedData.code === "" && errorEnable && (
                                <div className="formErrors">
                                  Code is required
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="f_name">
                                WhatsApp number ID *
                              </label>
                              <input
                                type="text"
                                placeholder="335xxxxxxxxxxxx"
                                className="uk-input"
                                value={submittedData.whatsappNumberId}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    whatsappNumberId: e.target.value,
                                  });
                                }}
                              />
                              {submittedData.whatsappNumberId === "" &&
                                errorEnable && (
                                  <div className="formErrors">
                                    Whatsapp number id is required
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="f_name">Business ID *</label>
                              <input
                                type="text"
                                placeholder="325xxxxxxxxxxxx"
                                className="uk-input"
                                value={submittedData.businessId}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    businessId: e.target.value,
                                  });
                                }}
                              />
                              {submittedData.businessId === "" &&
                                errorEnable && (
                                  <div className="formErrors">
                                    Business id is required
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="f_name">WhatsApp link *</label>
                              <input
                                type="text"
                                placeholder="https://api.whatsapp.com/"
                                className="uk-input"
                                value={submittedData.whatsappLink}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    whatsappLink: e.target.value,
                                  });
                                }}
                              />
                              {submittedData.whatsappLink === "" &&
                                errorEnable && (
                                  <div className="formErrors">
                                    Whatsapp link is required
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="f_name">Business Profile *</label>
                              <input
                                type="text"
                                placeholder="Your business profile"
                                className="uk-input"
                                value={submittedData.businessProfile}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    businessProfile: e.target.value,
                                  });
                                }}
                              />
                              {submittedData.businessProfile === "" &&
                                errorEnable && (
                                  <div className="formErrors">
                                    Business profile is required
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="txtAndSwitchWrp">
                          <div className="boxHeading">
                            <h3>Instagram</h3>
                          </div>
                          <div className="txtAndSwitch">
                            <div className="txtwrp">
                              <p style={{ maxWidth: "100%" }}>
                                Manages live chat conversations assigned to
                                them. Can not manage chats for others. Agent
                                performance reports will be created
                              </p>
                            </div>
                            <div className="switchwrp">
                              <Switch
                                onChange={() =>
                                  setSubmittedData({
                                    ...submittedData,
                                    isInstaActive: !submittedData.isInstaActive,
                                  })
                                }
                                checked={submittedData.isInstaActive}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                width={26}
                                height={14}
                                handleDiameter={10}
                                onColor="#29A869"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="uk-grid uk-grid-small" uk-grid="">
                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="f_name">Instagram user ID</label>
                              <input
                                disabled={!submittedData.isInstaActive}
                                type="text"
                                placeholder="Instagram user ID"
                                className="uk-input"
                                value={submittedData.instagramId}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    instagramId: e.target.value,
                                  });
                                }}
                              />
                              {submittedData.isInstaActive === true &&
                                submittedData.instagramId === "" &&
                                errorEnable && (
                                  <div className="formErrors">
                                    Instagram Id is required
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="f_name">Token</label>
                              <input
                                disabled={!submittedData.isInstaActive}
                                type="text"
                                placeholder="Token"
                                className="uk-input"
                                value={submittedData.instagramToken}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    instagramToken: e.target.value,
                                  });
                                }}
                              />
                              {submittedData.isInstaActive === true &&
                                submittedData.instagramToken === "" &&
                                errorEnable && (
                                  <div className="formErrors">
                                    Instagram Token is required
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>

                        <hr />
                        <div className="txtAndSwitchWrp">
                          <div className="boxHeading">
                            <h3>Facebook Messanger</h3>
                          </div>
                          <div className="txtAndSwitch">
                            <div className="txtwrp">
                              <p>
                                Provide continuous support on messenger and
                                enrich customer experience.
                              </p>
                            </div>
                            <div className="switchwrp">
                              <Switch
                                onChange={() =>
                                  setSubmittedData({
                                    ...submittedData,
                                    isFacebookActive:
                                      !submittedData.isFacebookActive,
                                  })
                                }
                                checked={submittedData.isFacebookActive}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                width={26}
                                height={14}
                                handleDiameter={10}
                                onColor="#29A869"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="uk-grid uk-grid-small" uk-grid="">
                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="f_name">Facebook user ID</label>
                              <input
                                type="text"
                                placeholder="Facebook user ID"
                                className="uk-input"
                                value={submittedData.facebookId}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    facebookId: e.target.value,
                                  });
                                }}
                                disabled={!submittedData.isFacebookActive}
                              />
                              {submittedData.isFacebookActive === true &&
                                submittedData.facebookId === "" &&
                                errorEnable && (
                                  <div className="formErrors">
                                    FacebookId is required
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="f_name">Token</label>
                              <input
                                disabled={!submittedData.isFacebookActive}
                                type="text"
                                placeholder="Token"
                                className="uk-input"
                                value={submittedData.facebookToken}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    facebookToken: e.target.value,
                                  });
                                }}
                              />
                              {submittedData.isFacebookActive === true &&
                                submittedData.facebookToken === "" &&
                                errorEnable && (
                                  <div className="formErrors">
                                    Facebook Token is required
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>

                        <hr />
                        <div className="txtAndSwitchWrp">
                          <div className="boxHeading">
                            <h3>Package Selection:</h3>
                          </div>
                          <div className="txtAndSwitch">
                            <div className="txtwrp">
                              <p>
                                Select a package and input MAU, conversation
                                count, agents, and price.
                              </p>
                            </div>
                            <div className="switchwrp">
                              <Switch
                                onChange={() =>
                                  setSubmittedData({
                                    ...submittedData,
                                    isPackageDetailActive:
                                      !submittedData.isPackageDetailActive,
                                  })
                                }
                                checked={submittedData.isPackageDetailActive}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                width={26}
                                height={14}
                                handleDiameter={10}
                                onColor="#29A869"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="uk-grid uk-grid-small" uk-grid="">
                          <div className="uk-width-1-1">
                            <div className="formInput">
                              <label htmlFor="f_name">Package name</label>
                              <input
                                type="text"
                                placeholder="Enter package name"
                                className="uk-input"
                                value={submittedData.title}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    title: e.target.value,
                                  });
                                }}
                                disabled={!submittedData.isPackageDetailActive}
                              />
                              {submittedData.isPackageDetailActive === true &&
                                submittedData.title === "" &&
                                errorEnable && (
                                  <div className="formErrors">
                                    Package name is required
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="f_name">MAU</label>
                              <input
                                type="number"
                                placeholder="MAU"
                                className="uk-input"
                                value={submittedData.activeUser}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    activeUser: e.target.value,
                                  });
                                }}
                                disabled={!submittedData.isPackageDetailActive}
                              />
                              {submittedData.isPackageDetailActive === true &&
                                submittedData.activeUser === "" &&
                                errorEnable && (
                                  <div className="formErrors">
                                    MAU is required
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="f_name">Conversation</label>
                              <input
                                disabled={!submittedData.isPackageDetailActive}
                                type="number"
                                placeholder="Conversation"
                                className="uk-input"
                                value={submittedData.whatsappConversation}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    whatsappConversation: e.target.value,
                                  });
                                }}
                              />
                              {submittedData.isPackageDetailActive === true &&
                                submittedData.whatsappConversation === "" &&
                                errorEnable && (
                                  <div className="formErrors">
                                    Conversation is required
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="f_name">Agent</label>
                              <input
                                disabled={!submittedData.isPackageDetailActive}
                                type="number"
                                placeholder="Agent"
                                className="uk-input"
                                value={submittedData.liveAgent}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    liveAgent: e.target.value,
                                  });
                                }}
                              />
                              {submittedData.isPackageDetailActive === true &&
                                submittedData.liveAgent === "" &&
                                errorEnable && (
                                  <div className="formErrors">
                                    Agent is required
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="uk-width-1-2">
                            <div className="formInput">
                              <label htmlFor="f_name">Price</label>
                              <input
                                disabled={!submittedData.isPackageDetailActive}
                                type="number"
                                placeholder="Price"
                                className="uk-input"
                                value={submittedData.price}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    price: e.target.value,
                                  });
                                }}
                              />
                              {submittedData.isPackageDetailActive === true &&
                                submittedData.price === "" &&
                                errorEnable && (
                                  <div className="formErrors">
                                    Price is required
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {submittedData.isInstaActive === false &&
                        submittedData.isWhatsappActive === false &&
                        submittedData.isFacebookActive === false &&
                        submittedData.isPackageDetailActive === false &&
                        errorEnable && (
                          <div className="formErrors">
                            Please select at least one channel
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="adminRoleWrp">
                    <div className="btnwrp">
                      <button
                        className="btn-1"
                        type="button"
                        onClick={() =>
                          navigate("/super-admin-users", { replace: true })
                        }
                      >
                        Cancel
                      </button>
                      <button
                        className="btn-2 w-70"
                        type="submit"
                        // onClick={() => {
                        //   navigate("/super-admin-select-package", {
                        //     replace: true,
                        //   });
                        // }}
                      >
                        {loader ? (
                          <div uk-spinner="" className="loader"></div>
                        ) : (
                          "Add"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCompany;
