import React from "react";
import SettingSideBar from "./components/SettingSideBar";
import demoImg from "../../assets/images/avatar-02.png";
import { ChevronDown } from "@carbon/icons-react";
import Footer from "../../components/common/Footer/Footer";
import SegmentCategoryModal from "./components/Modal/SegmentCategoryModal";
import { useState } from "react";
import DeletePictureModal from "./components/Modal/DeletePictureModal";
import { useAuth } from "../../Context/AuthProvider";
import { apiGet } from "../../Utils/apiServices";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

const SegmentSetting = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber
  ] = useOutletContext();
  const { auth } = useAuth();
  const [deleteId, setDeleteId] = useState();
  const [editData, setEditData] = useState();
  const [apiCalled, setApiCalled] = useState(null);
  const [segmentCategory, setSegmentCategory] = useState();
  const [openSegmentCategoryModal, setOpenSegmentCategoryModal] =
    useState(false);
  const [openEditSegmentCategoryModal, setOpenEditSegmentCategoryModal] =
    useState(false);
  const [openDeleteSegmentModal, setOpenDeleteSegmentModal] = useState(false);

  const getSegmentCategory = () => {
    apiGet(
      `/segment/list/category`,
      onSuccessSegmentCategory,
      onFailureSegmentCategory
    );
  };
  const onSuccessSegmentCategory = (response) => {
    setApiCalled(false);
    setSegmentCategory(response?.data);
  };
  const onFailureSegmentCategory = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getSegmentCategory();
  }, []);
  useEffect(() => {
    if (apiCalled === true) {
      setSegmentCategory();
      getSegmentCategory();
    }
  }, [apiCalled]);

  const handleOpenSegmentCategoryModal = () => {
    setOpenSegmentCategoryModal(true);
  };

  const handleCloseSegmentCategoryModal = () => {
    setOpenSegmentCategoryModal(false);
  };
  const handleOpenEditSegmentCategoryModal = (val) => {
    setEditData(val);
    setOpenEditSegmentCategoryModal(true);
  };

  const handleCloseEditSegmentCategoryModal = () => {
    setOpenEditSegmentCategoryModal(false);
  };

  const handleOpenDeleteSegmentModal = (publicId) => {
    setDeleteId(publicId);
    setOpenDeleteSegmentModal(true);
  };

  const handleCloseDeleteSegmentModal = () => {
    setOpenDeleteSegmentModal(false);
  };
  return (
    <>
      <div className="settingwrp">
        <div className="topBar">
          <div className="uk-grid" uk-grid="">
            <div className="uk-width-1-1">
              <div className="txtwrp">
                <h3>Settings</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="settingContentWrp">
          <div className="uk-grid uk-grid-small" uk-grid="">
            <div className="uk-width-auto">
            <SettingSideBar userPermissions={userPermissions}/>
            </div>
            <div className="uk-width-expand">
              <div
                className="settingWrapper"
                style={{ minHeight: "calc(100vh - 240px)" }}
              >
                <div className="uk-container">
                  <div className="settingTabbing">
                    <div className="generalSettingWrp">
                      <div className="mainHeading">
                        <h3>Segments</h3>
                        <p>View and configure all segment setting.</p>
                      </div>
                      <div className="boxwrp">
                        <div className="formwrp">
                          <form action="">
                            <div className="boxHeading">
                              <h6>Segment category</h6>
                              <p>
                                Segment category can help you organize your
                                customer messages and find out how customers
                                feel about your business.
                              </p>
                            </div>

                            <div className="uk-grid uk-grid-small" uk-grid="">
                              {segmentCategory?.map((val, index) => {
                                return (
                                  <div key={index} className="uk-width-1-1">
                                    <div className="formInput">
                                      <input
                                        type="text"
                                        className="uk-input"
                                        defaultValue={val.name}
                                        disabled
                                      />
                                      <div
                                        className="changeBtn changeBtn2"
                                        style={{ top: "17%" }}
                                      >
                                        <button
                                          type="button"
                                          className="uk-margin-small-right"
                                          onClick={(e) =>
                                            handleOpenDeleteSegmentModal(
                                              val.publicId
                                            )
                                          }
                                        >
                                          Delete
                                        </button>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handleOpenEditSegmentCategoryModal(
                                              val
                                            )
                                          }
                                        >
                                          Edit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}

                              <div className="uk-width-1-1">
                                <div className="settingAddBtn">
                                  <button
                                    type="button"
                                    onClick={handleOpenSegmentCategoryModal}
                                  >
                                    Add new category
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
      {openSegmentCategoryModal && (
        <SegmentCategoryModal
          closeModal={handleCloseSegmentCategoryModal}
          setApiCalled={setApiCalled}
          heading={"New segment category"}
          description={
            "Add a new segment category to find out how customers feel about your business."
          }
          btnText={"Create"}
          getSegmentCategory={getSegmentCategory}
        />
      )}
      {openEditSegmentCategoryModal && (
        <SegmentCategoryModal
          closeModal={handleCloseEditSegmentCategoryModal}
          setApiCalled={setApiCalled}
          heading={"Edit segment category"}
          description={
            "Edit segment category to find out how customers feel about your business."
          }
          btnText={"Update"}
          editData={editData}
        />
      )}
      {openDeleteSegmentModal && (
        <DeletePictureModal
          closeModal={handleCloseDeleteSegmentModal}
          deleteHeading="Delete segment category"
          deleteTxt="Are you sure you want to delete this segment category?"
          deleteId={deleteId}
          setApiCalled={setApiCalled}
          deleteApi={"segment"}
        />
      )}
    </>
  );
};

export default SegmentSetting;
