import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import girlIcon from "../../assets/images/webchatbot/girl-icon.png";
import cross from "../../assets/images/webchatbot/cross.png";
import attachment from "../../assets/images/webchatbot/attachment.png";
import chatIcon from "../../assets/images/webchatbot/chat-icon.png";
import manAvatar from "../../assets/images/webchatbot/man-avatar.png";
import { apiGet } from "../../Utils/apiServices";

const ChatWidget = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [primaryColor, setPrimaryColor] = useState("#f6921e");
  const [selectedBtnTxt, setselectedBtnTxt] = useState(undefined);
  const [botData, setBotData] = useState(null);
  const [button, setButton] = useState([]);
  const [searchParams] = useSearchParams();
  const bodyRef = useRef(null);
  const [loader, setLoader] = useState(true);
  const [welcomeMessage, setWelcomeMessage] = useState("Enter your message");
  const welcomeMessageRef = useRef(null);
  const baseURLAssets = process.env.REACT_APP_CHATASSETSURL;
  const toggleChatBox = () => {
    setIsVisible(!isVisible);
  };
  const createMessage = (text, type, imgSrc, color) => {
    const msgContainer = document.createElement("div");
    msgContainer.classList.add(type);
    msgContainer.style.display = "flex";
    msgContainer.style.gap = "10px";
    msgContainer.style.marginBottom = "15px";
    msgContainer.style.marginTop = "15px";
    msgContainer.style.alignItems = "flex-end";
    msgContainer.style.width = "100%";

    const img = document.createElement("img");
    img.alt = "Avatar";
    img.style.width = "35px";
    img.style.height = "35px";
    img.style.transform = "translateY(20px)";
    img.style.borderRadius = "50%";

    const message = document.createElement("pre");
    message.textContent = text;
    message.style.borderRadius =
      type === "sentMsg" ? "20px 20px 0 20px" : "20px 20px 20px 0";
    message.style.fontSize = "14px";
    message.style.lineHeight = "18px";
    message.style.fontFamily = "Helvetica, Sans-Serif";
    message.style.width = "auto";
    message.style.textWrap = "wrap";
    message.style.padding = "8px";

    if (type === "sentMsg") {
      msgContainer.style.justifyContent = "flex-end";
      message.style.background = "#97ADC4";
      message.style.color = "#fff";
      img.src = girlIcon;
      msgContainer.appendChild(message);
      msgContainer.appendChild(img);
    } else {
      msgContainer.style.justifyContent = "flex-start";
      message.style.color = "#fff";
      message.style.padding = "15px 15px";
      message.style.backgroundColor = color || primaryColor;
      img.src = `${baseURLAssets}${imgSrc}`;
      msgContainer.appendChild(img);
      msgContainer.appendChild(message);
    }
    return msgContainer;
  };

  const applyPrimaryColor = (color) => {
    document.documentElement.style.setProperty("--primary-color", color);
  };
  const fetchBotData = () => {
    const botWidgetId = searchParams.get("botWidgetId") || "";
    apiGet(
      `/webChatBot/widget/${botWidgetId}`,
      onSuccessFetchBotData,
      onFailureFetchBotData
    );
  };
  const onSuccessFetchBotData = (data) => {
    setBotData(data);
    setPrimaryColor(data.color);
    applyPrimaryColor(data.color);
  };
  const onFailureFetchBotData = (error) => {
    console.error("Error fetching data:", error);
  };
  useEffect(() => {
    fetchBotData();
  }, [searchParams]);

  useEffect(() => {
    if (botData) {
      const chatContainer = document.getElementById("chat-container");
      if (chatContainer) {
        chatContainer.innerHTML = "";
        const receivedMessage = createMessage(
          botData.welcomeMessage,
          "receivedMsg",
          botData.logoImageUrl,
          botData.color
        );
        chatContainer.appendChild(receivedMessage);
      }
    }
  }, [botData, baseURLAssets, primaryColor]);

  const handleWelcomeMessageChange = (e) => {
    const newMessage = e.target.value;
    setWelcomeMessage(newMessage);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
    }
  };

  const botWidgetId = searchParams.get("botWidgetId") || "";

  const getSingleChatBox = () => {
    if (botWidgetId) {
      setLoader(true);
      apiGet(
        `/webChatBot/builder/widgetId/${botWidgetId}`,
        onSuccessSingleChatBox,
        onFailureSingleChatBox
      );
    }
  };
  const onSuccessSingleChatBox = (response) => {
    setLoader(false);
    let data = response?.data;
    try {
      let parsedBotData = JSON?.parse(data?.botJsonString);
      console.log("parsedBotData", parsedBotData);
      setButton((prevBot) => ({
        ...prevBot,
        buttonKeywords: parsedBotData?.buttonKeywords || [],
      }));
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };
  const onFailureSingleChatBox = (error) => {
    console.log(error);
    setLoader(true);
  };

  useEffect(() => {
    getSingleChatBox();
  }, [botWidgetId]);

  useEffect(() => {
    if (button && Array.isArray(button.buttonKeywords)) {
      setButton(button.buttonKeywords); // Set buttons if available
    }
  }, [button]);

  return (
    <>
      <div
        id="customChatBox"
        className="preview"
        style={{
          display: isVisible ? "block" : "none",
          position: "fixed",
          bottom: "0",
          right: "0",
          width: "375px",
          border: `2px solid ${primaryColor}`,
          borderRadius: "12px",
          backgroundColor: "#fff",
          zIndex: 99999999,
        }}
      >
        <div
          className="preview-header"
          style={{
            padding: "15px",
            borderRadius: "6px 6px 0 0",
            backgroundColor: primaryColor,
            color: "#fff",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img
              src={`${baseURLAssets}${botData?.logoImageUrl}`}
              alt="logo"
              width={60}
              height={60}
              style={{ borderRadius: "50%", height: "60px !important" }}
            />
            <img
              src={cross}
              alt="close"
              width={40}
              height={40}
              style={{ cursor: "pointer", height: "40px" }}
              onClick={toggleChatBox}
            />
          </div>
          <h3
            style={{
              padding: "10px 0",
              fontSize: "18px",
              fontWeight: 600,
              margin: 0,
              color: "#fff",
            }}
          >
            K Electric
          </h3>
          <p
            style={{
              margin: 0,
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "23px",
            }}
          >
            KE is the largest vertically integrated power utility company of
            Pakistan.
          </p>
        </div>
        <div
          className="preview-body"
          ref={bodyRef}
          style={{
            padding: "15px 12px",
            position: "relative",
            background: "#FAF3EE",
            height: "250px",
            overflowY: "scroll",
          }}
        >
          <div>
            <div id="chat-container " className="chatBoxBody leftChatBox">
              <img
                alt="Avatar"
                src={`${baseURLAssets}${botData?.logoImageUrl}`}
              />
              <pre className="message sentMsg">
                KE is the largest vertically integrated power utility company of
                Pakistan power utility company of Pakistan.
              </pre>
            </div>
            <div className="chatBoxBtnMain">
              {Array.isArray(button) &&
                button?.map((item, index) => (
                  <div key={index}>
                    <button
                      className="chatBoxBtn"
                      onClick={() => setselectedBtnTxt(item.value)}
                      style={{
                        border: `1px solid ${primaryColor}`,
                        color: primaryColor,
                      }}
                    >
                      {item?.value}
                    </button>
                  </div>
                ))}
            </div>
          </div>
          {selectedBtnTxt !== undefined ? (
            <div id="chat-container " className="chatBoxBody rightChatBox">
              <pre className="message sentMsg">{selectedBtnTxt}</pre>
              <img alt="Avatar" src={manAvatar} />
            </div>
          ) : null}
        </div>
        <div
          className="preview-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px 8px",
          }}
        >
          <div
            className="type-text"
            style={{
              display: "flex",
              borderRadius: "3px",
              border: "1px solid rgb(229 218 218)",
              padding: "5px 8px",
              width: "100%",
              alignItems: "flex-start",
            }}
          >
            <img
              src={attachment}
              alt="attach"
              style={{ width: "15px", height: "15px", marginTop: "3px" }}
            />
            <textarea
              id="welcome-message"
              className="text-area-custom chatbot-widget"
              value={welcomeMessage}
              onChange={handleWelcomeMessageChange}
              onKeyPress={handleKeyPress}
              placeholder="Enter your message"
            />
          </div>
        </div>
      </div>
      <div
        onClick={toggleChatBox}
        style={{
          borderRadius: "50%",
          width: "80px",
          height: "80px",
          display: isVisible ? "none" : "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 9999999999,
          backgroundColor: "#97adc4",
        }}
      >
        {loader ? (
          <div className="flex-center">
            <div uk-spinner="" className="loader"></div>
          </div>
        ) : (
          <>
            <img
              src={chatIcon}
              alt="chat"
              style={{ width: "50px", height: "auto", cursor: "pointer" }}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ChatWidget;
