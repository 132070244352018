import React, { useEffect } from "react";
import { useState } from "react";
import NewMessageModal from "./Modal/NewMessageModal";
import { useNavigate, useOutletContext } from "react-router";
import { useAuth } from "../../Context/AuthProvider";
import moment from "moment";
import whatsappLogo from "../../assets/images/ic_Integrations_whatsapp.png";
import { Calendar, Chat, ChevronDown, Download } from "@carbon/icons-react";
import UIkit from "uikit";
import CalendarDate from "../../components/Calendar/CalendarDate";
import stockImg from "../../assets/images/table-stock-img.jpg";
import { apiDelete, apiGet, apiPost } from "../../Utils/apiServices";
import { convertTimestamp } from "../../Utils/DateFunctions";
import axios from "axios";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";
const baseUrl = process.env.REACT_APP_BASEURL;

const ApiMessages = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const CurrentCompanyName = CompanyName(CompanyHtml);
  const { auth } = useAuth();

  const navigate = useNavigate();
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [DateChange, setDateChange] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [loader, setLoader] = useState(true);

  const handleDatePickerClose = () => {
    UIkit.drop("#datepickerdrop").hide(true);
  };

  const onChange = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
    setDateChange(true);
  };

  const exportData = async () => {
    try {
      const response = await axios.post(
        baseUrl + `/broadcast/api/export`,
        {},
        {
          responseType: "arraybuffer", // Ensure the response is treated as binary data
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Api_Message.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  const apiMessages = () => {
    apiPost(`/broadcast/api`, onSuccessEnable, onFailureEnable);
  };
  const onSuccessEnable = (response) => {
    setApiData(response?.data);
    setLoader(false);
  };
  const onFailureEnable = (error) => {
    console.log(error);
  };

  useEffect(() => {
    apiMessages();
  }, []);

  const [searchFilter, setSearchFilter] = useState("");

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="boradcastWrp">
        <div className="boradcastTabbing" style={{ minHeight: "31px" }}>
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #broadcastTabs"
          >
            <li
              onClick={() => {
                navigate("/broadcast", { replace: true });
              }}
            >
              <a>Broadcast</a>
            </li>
            <li
              onClick={() => {
                navigate("/template-message", { replace: true });
              }}
            >
              <a>Template Messages</a>
            </li>
            <li
              className="uk-active"
              onClick={() => {
                navigate("/api-message", { replace: true });
              }}
            >
              <a>API messages</a>
            </li>
          </ul>
          <ul className="broadcastTopBtn uk-switcher" id="broadcastTabs">
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="broadcastTabs">
            <li></li>
            <li></li>

            <li>
              <div className="broadcastTabWrp">
                <div className="uk-container">
                  <div className="mainHeading">
                    <h2>
                      API messages{" "}
                      <CurrentCompanyName companyDetails={companyDetails} />
                    </h2>
                  </div>
                  <div className="searchFilterWrp">
                    <div className="uk-grid uk-flex-middle" uk-grid="">
                      <div className="uk-width-1-2">
                        <div className="searchFilter">
                          <form
                            action=""
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Search by email and number"
                              value={searchFilter}
                              onChange={(e) => setSearchFilter(e.target.value)}
                            />
                          </form>
                        </div>
                      </div>
                      <div className="uk-width-1-2 btnSection">
                        <button className="exportBtn" onClick={exportData}>
                          <Download /> Export Data
                        </button>

                        <div className="uk-inline">
                          {/* <button className="calenderBtn rangeBtn">
                            <Calendar /> Last 30 days{" "}
                            <span className="uk-inline">
                              <ChevronDown />
                            </span>
                          </button> */}
                          <div
                            uk-dropdown="mode: click"
                            className="calendarDropdown"
                          >
                            <div className="dropDownHeading">
                              <span>Select a range</span>
                            </div>
                            <ul>
                              <li className="active">
                                <button type="button" className="rangeBtn">
                                  <span className="rangeBtnCircle"></span> Last
                                  30 days
                                </button>
                              </li>
                              <li>
                                <button type="button" className="rangeBtn">
                                  <span className="rangeBtnCircle"></span> This
                                  week
                                </button>
                              </li>
                              <li>
                                <button type="button" className="rangeBtn">
                                  <span className="rangeBtnCircle"></span> Last
                                  week
                                </button>
                              </li>
                              <li>
                                <button type="button" className="rangeBtn">
                                  <span className="rangeBtnCircle"></span> This
                                  month
                                </button>
                              </li>
                              <hr style={{ margin: "5px 0" }} />
                              <li>
                                <div className="uk-inline uk-text-left">
                                  <button className="rangeBtn" type="button">
                                    {/* <CalendarHeatMap /> */}
                                    {/* <span className="filter-name">
                                      {moment(STARTDATE).format("Do MMMM YYYY")}{" "}
                                      - {moment(ENDDATE).format("Do MMMM YYYY")}
                                    </span> */}
                                    <span className="rangeBtnCircle"></span>{" "}
                                    Custom range
                                    {/* <ChevronDown /> */}
                                  </button>
                                  <div
                                    id="datepickerdrop"
                                    uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                    className="date-picker-container"
                                  >
                                    <CalendarDate onChange={onChange} />
                                    <div className="date-picker-btn-container uk-text-right">
                                      <button
                                        className="date-selection-btn"
                                        onClick={handleDatePickerClose}
                                      >
                                        Ok
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {/* <button type="button">
                                  <span></span> Custom range
                                </button> */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="apiTableWrp">
                    <div className="uk-grid" uk-grid="">
                      <div className="uk-width-1-1">
                        <table className="uk-table">
                          <thead>
                            <tr>
                              <th>Series</th>
                              <th>
                                Channel <ChevronDown />
                              </th>
                              <th>Mobile</th>
                              <th>Email</th>
                              <th>Message</th>
                              <th>
                                Status <ChevronDown />
                              </th>
                              <th>Created</th>
                            </tr>
                          </thead>
                          <tbody>
                            {apiData?.length > 0 ? (
                              apiData
                                ?.filter((val) => {
                                  const emailMatch =
                                    val.agentEmail.includes(searchFilter);
                                  const numberMatch =
                                    val.to.includes(searchFilter);
                                  return emailMatch || numberMatch;
                                })
                                .map((val, index) => {
                                  const broadcastDetailObj = JSON.parse(
                                    val.broadcastDetail
                                  );

                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>WhatsApp</td>
                                      <td>{val.to}</td>
                                      <td>{val.agentEmail}</td>
                                      <td className="messageCol">
                                        <div className="txtwrp">
                                          <p>
                                            Template ID:{" "}
                                            {broadcastDetailObj?.templateId}
                                            <br />
                                            Body Value:{" "}
                                            {broadcastDetailObj.param !== null
                                              ? broadcastDetailObj?.param[0]
                                                  .value
                                              : "-"}
                                            <br />
                                            Button Value:{" "}
                                            {broadcastDetailObj.param !== null
                                              ? broadcastDetailObj?.param[1]
                                                  .value
                                              : "-"}
                                          </p>
                                        </div>
                                      </td>
                                      <td>{val.status}</td>
                                      <td>{convertTimestamp(val.timeStamp)}</td>
                                    </tr>
                                  );
                                })
                            ) : (
                              <tr>
                                <td colSpan="7">
                                  {apiData === null ? (
                                    <div uk-spinner="" className="loader"></div>
                                  ) : (
                                    <p>No data found.</p>
                                  )}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ApiMessages;
