import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;
export const exportData = async (endPoint, filename, token) => {
  try {
    const response = await axios.get(baseUrl + endPoint, {
      responseType: "arraybuffer", // Ensure the response is treated as binary data
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${filename}.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error("Error downloading file: ", error);
  }
};
