import React from "react";
import convexLogo from "../../../assets/images/logoblack.svg";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { currentYear } from "../../../Utils/DateFunctions";

const InvoicePreview = () => {
  const location = useLocation();

  if (location.state === null) {
    window.location.href = "/super-admin-invoice-list";
  }

  return (
    <div className="invoicePreviewWrp">
      <div className="uk-container uk-container-small">
        <div className="uk-grid" uk-grid="">
          <div className="uk-width-1-2">
            <div className="logowrp uk-margin-bottom">
              <img src={convexLogo} alt="" />
            </div>
          </div>
          <div className="uk-width-1-2">
            <div className="mainHeading uk-margin-bottom">
              <h2>INVOICE</h2>
            </div>
          </div>
          <div className="uk-width-1-1">
            <div className="mainTxt">
              <h3>Convex Interactive</h3>
              <p style={{ width: "166px" }}>
                Ibrahim Trade Tower, #803, Shahrah-e-Faisal, Karachi.
              </p>
              <p>+92 (0)21 34327748</p>
            </div>
          </div>
          <div className="uk-width-1-2">
            <div className="mainTxt">
              <h3>Bill To:</h3>
              <p>{location?.state?.companyName}</p>
              {/* <p>Client’s Address</p> */}
              {/* <p>City, State Zip</p> */}
            </div>
          </div>
          <div className="uk-width-1-2">
            <div className="invoiceDetail">
              <ul>
                <li>
                  <div className="left">
                    <h6>Invoice#</h6>
                  </div>
                  <div className="right">
                    <p>{location.state.invoiceNumber}</p>
                  </div>
                </li>
                <li>
                  <div className="left">
                    <h6>Invoice Date</h6>
                  </div>
                  <div className="right">
                    {/* <p>Dec 15, 2023</p> */}
                    <p>
                      {moment(location?.state?.createDateTime).format(
                        "MMM DD, YYYY"
                      )}
                    </p>
                  </div>
                </li>
                <li>
                  <div className="left">
                    <h6>Due Date</h6>
                  </div>
                  <div className="right">
                    <p>
                      {moment(location?.state?.expiryDate).format(
                        "MMM DD, YYYY"
                      )}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="uk-width-1-1">
            <div className="invoiceTable">
              <table className="uk-table">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>QTY</th>
                    {/* <th>Rate</th> */}
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="tableMainHeading">
                        <p>{location.state.conversationDescription}</p>
                      </div>
                    </td>
                    <td>1</td>
                    {/* <td>PKR 10,000</td> */}
                    <td>
                      {location.state.currencyCharged}{" "}
                      {location.state.servicePrice}
                    </td>
                  </tr>
                 
                   
                </tbody>
              </table>
            </div>
          </div>
          <div className="uk-width-1-2">
            <div className="mainTxt">
              <h6>Notes</h6>
              <p>
                As per Finance Act {currentYear()}, advertising services, IT servrices & IT
                enabled services are chargeable to tax @ 4%. As all our services
                fall under this category, therefore please withhold advance tax
                @ 4% of gross invoice amount.
              </p>
            </div>
          </div>
          <div className="uk-width-1-2">
            <div className="invoicePrice">
              <ul>
                <li>
                  <div className="left">
                    <p>Sub Total</p>
                  </div>
                  <div className="right">
                    <p>{location.state.servicePrice}</p>
                  </div>
                </li>
                <li>
                  <div className="left">
                    <p>TAX ({location.state.taxPercentage}%)</p>
                  </div>
                  <div className="right">
                    <p>
                      {location.state.netTotal - location.state.servicePrice}
                    </p>
                  </div>
                </li>
                <li>
                  <div className="left">
                    <p>TOTAL</p>
                  </div>
                  <div className="right">
                    <p>
                      {location.state.currencyCharged} {location.state.netTotal}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="uk-width-1-1">
            <div className="mainTxt">
              <h6>Terms & Conditions</h6>
              <ul>
                <li>
                  <p>
                    * Payment Term mentioned above is to be stricly followed and
                    is effective from the date of this invoice. Any delay in
                    payment beyond this term will attract a penalty of 3% per
                    month.
                  </p>
                </li>
                <li>
                  <p>
                    * USD rate is is inclusive of 10% withholding tax on
                    offshore digital services as per section 6 of the Income Tax
                    Ordinance, 2001 & 5% advance tax (applicable from 1st July
                    {currentYear()}, previously 1%) as per section 236Y of Income Tax
                    Ordinance, 2001.
                  </p>
                </li>
                <li>
                  <p>
                    * The Client is bound to comply with the Terms & Conditions
                    of the Media Plan/Insertion Order specified herein.
                  </p>
                </li>
                <li>
                  <p>
                    * Kindly make payment of this invoice by crossed cheque
                    (Payee's Account Only) in favour of M/S Convex Interactive
                    (Pvt) Ltd.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicePreview;
