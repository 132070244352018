import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useContextChatBotWeb } from "../Chatbot/web/ContextChatBot";
import { useLocation } from "react-router-dom";

const ScriptGenerator = ({ closeModal, data, botWidgetIdGet, editBotWidgetId }) => {
  const [script, setScript] = useState("");
  const textareaRef = useRef(null);
  const location = useLocation();
  const botWidgetId = data?.botWidgetId;
  
  const { handleCloseScriptModal } = useContextChatBotWeb() || {};
  const navigate = useNavigate();
  useEffect(() => {
    if (botWidgetId || botWidgetIdGet || editBotWidgetId ) {
      const script = `
        (function() {
    function createIframe() {
        const botWidgetId = ${botWidgetId || botWidgetIdGet || editBotWidgetId}; 
        const iframe = document.createElement('iframe');
        iframe.src = 'http://localhost:3000/webchat?botWidgetId=${botWidgetId || botWidgetIdGet || editBotWidgetId}';
        iframe.style.border = "none";
        iframe.style.bottom = "30px";
        iframe.style.width = "378px";
        iframe.style.height = "523px";
        iframe.style.position = "fixed";
        iframe.style.display = "flex";
        iframe.style.marginLeft = "auto";
        iframe.style.padding = "0";
        iframe.style.zIndex = "9999999";
        iframe.style.background = "transparent";
        iframe.style.right = "30px";
        iframe.style.borderRadius = "12px";
        document.body.appendChild(iframe);
        iframe.onload = function() {
            try {
                const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                iframeDocument.documentElement.classList.add('custom-html-class');
            } catch (error) {
                console.error('Unable to access iframe content:', error);
            }
        };
    }
    createIframe();
})();
      `;
      setScript(script);
    } else {
      toast.error("Bot Widget ID is missing!");
    }
  }, [botWidgetId]);

  const copyToClipboard = () => {
    if (textareaRef.current) {
      textareaRef.current.select();
      document.execCommand("copy");
      toast.success("Script copied to clipboard!");

      setTimeout(() => {
        const searchParams = new URLSearchParams(window.location.search);
        if (!searchParams.has("chatBox")) {
          searchParams.append("chatBox", "true");
        }
        navigate(`/chatbot`, { replace: true });
      }, 2000);
    }
  };

  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open scriptGenerator"
      uk-modal="esc-close: false; bg-close: false">
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <h3>Web Chatbot Script </h3>
        <div className="scriptPage">
          <div className="container">
            <h2>Your widget is ready!</h2>
            <h4>
              Copy this code and place it and tag on every page of your website:
            </h4>
            <div className="mainDiv">
              <textarea ref={textareaRef} readOnly value={script} />
            </div>
          </div>
          <div
            className="closeBtn"
            style={{ textAlign: "right", marginTop: "20px" }}
          >
            <button
              type="button"
              className="btn-1 mainDiv"
              onClick={copyToClipboard}
            >
              Copy to Clipboard
            </button>
            <button
              type="button"
              className="btn-2"
              onClick={closeModal || handleCloseScriptModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ScriptGenerator;
