import React from "react";
// import { Link } from "react-router-dom";
import CalendarDate from "../../components/Calendar/CalendarDate";
import whatsAppImg from "../../assets/images/whatsapp-icon-new.jpg";
// import pakistanFlag from "../../assets/images/pakistan-flag.png";
import messengerIcon from "../../assets/images/messenger-icon-new.jpg";
import instaIcon from "../../assets/images/instagram-icon-new.jpg";
import LineChart from "../../components/Graph/LineChart";
import whatsappLogo from "../../assets/images/whatsapp-icon.png";
import {
  Calendar,
  ChevronDown,
  DocumentView,
  OverflowMenuVertical,
  TrashCan,
  WarningAltFilled,
  Download,
} from "@carbon/icons-react";
import { useState } from "react";
import { useEffect } from "react";
import { apiGet } from "../../Utils/apiServices";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  convertTimestamp,
  convertTimestampToDate,
} from "../../Utils/DateFunctions";
import moment from "moment/moment";
import UIkit from "uikit";
import DoughnutChart from "../../components/Graph/DoughnutChart";
import { compareAsc } from "date-fns";
import DynamicLineChart from "../../components/Graph/DynamicLineChart";
import {
  convertIntoThousand,
  findChannelExist,
  generateColors,
} from "../../Utils/GraphFunctions";
import DynamicDoughnutChart from "../../components/Graph/DynamicDoughChart";
import CustomizedProgressBar from "../../components/Graph/CustomizedProgressBar";
import countryJson from "../../../src/Utils/country.json";
import Pagination from "../../components/Pagination/Pagination";
import { useMemo } from "react";
import { hasPermissions } from "../../Utils/RoutePermissions";

const NewAnalytics = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  // console.log(companyDetails);
  const navigate = useNavigate();
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);

  // new work
  // const [channelName, setChannelName] = useState("ALL");

  const [channel, setChannel] = useState("all");
  const [allData, setAllData] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [whatsappBroadcasting, setWhatsappBroadcasting] = useState();
  const [whatsappHighlights, setWhatsappHighlights] = useState();
  const [
    whatsappBusinessInitiatedBreakdown,
    setWhatsappBusinessInitiatedBreakdown,
  ] = useState();
  const [whatsappMonthlyActiveUser, setWhatsappMonthlyActiveUser] = useState({
    labels: [],
    data: [],
    newMau: null,
    returningMau: null,
    totalMau: null,
  });
  const [whatsappConversationBreakDown, setWhatsappConversationBreakDown] =
    useState();
  const [
    whatsappConversationBreakDownGraph,
    setWhatsappConversationBreakDownGraph,
  ] = useState();
  const [whatsappGraph, setWhatsappGraph] = useState({
    service: [],
    serviceCta: [],
    authtication: [],
    utility: [],
    marketing: [],
    label: [],
    isCalled: false,
  });

  const [whatsappBotStats, setWhatsappBotStats] = useState({
    totalConversations: 0,
    window: {},
    labels: [],
    data: [],
  });

  const [stats, setStats] = useState({
    whatsapp: [],
    facebook: [],
    instagram: [],
  });
  const [graphCount, setGraphCount] = useState({
    whatsapp: 0,
    facebook: 0,
    instagram: 0,
  });

  // const [channelPerformance, setChannelPerformance] = useState({
  //   message: 0,
  //   deliveredRate: 0,
  //   cost: 0,
  // });
  // const [highlights, setHighlights] = useState({
  //   delivered: 0,
  //   pending: 0,
  //   failed: 0,
  // });
  const [graphLabels, setGraphLabels] = useState();
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [selectedDateFilter, setSelectedDateFilter] = useState("This month");
  const [isApiCall, setIsApiCall] = useState(false);

  // for facebook only
  const [
    facebookBusinessInitiatedBreakdown,
    setFacebookBusinessInitiatedBreakdown,
  ] = useState();
  const [
    facebookConversationBreakDownGraph,
    setFacebookConversationBreakDownGraph,
  ] = useState();

  const [newStats, setNewStats] = useState();
  const [channels, setChannels] = useState([
    {
      icon: null,
      name: "All Channels",
      apiSendName: "all",
      disabled: false,
    },
    {
      icon: whatsAppImg,
      name: "WhatsApp",
      apiSendName: "whatsapp",
      disabled: false,
    },
    {
      icon: messengerIcon,
      name: "Facebook",
      apiSendName: "facebook",
      disabled: false,
    },
    {
      icon: instaIcon,
      name: "Instagram",
      apiSendName: "instagram",
      disabled: false,
    },
  ]);

  const pageTabs = [
    {
      route: "/analytics",
      name: "Overview",
      permissions: ["FINANCE-DATA-MANAGER"],
      active: true,
      allPermissionsMandatory: true,
    },
    {
      route: "/live-chat-analytics",
      name: "Live Chat",
      permissions: ["ANALYTICS-MANAGER", "LIVE-CHAT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/chatbot-analytics",
      name: "Chatbot",
      permissions: ["CHATBOT-SUPERVISOR", "CHAT-BOT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/statistics",
      name: "Statistics",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/package-mau",
      name: "Package",
      permissions: ["ANALYTICS-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/orders",
      name: "Orders",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/transfer-logs",
      name: "Transfer Logs",
      permissions: [],
      active: false,
      allPermissionsMandatory: true,
    },
  ];

  const getCountryNameByCode = (code) => {
    const country = countryJson.find((country) => country.code === code);
    return country ? country.name : "Country not found";
  };

  const totalMessages = whatsappConversationBreakDown?.reduce(
    (sum, item) => sum + item.totalMessage,
    0
  );

  const colorForMonthlyActiveUserBreakDown =
    whatsappConversationBreakDownGraph !== undefined &&
    generateColors(Object.keys(whatsappConversationBreakDownGraph).length);
  const colorForBotStats =
    Object.keys(whatsappBotStats?.window).length > 0 &&
    generateColors(Object.keys(whatsappBotStats?.window).length);
  const colorForMonthlyActiveUser =
    whatsappMonthlyActiveUser.newMau !== null &&
    generateColors(whatsappMonthlyActiveUser?.labels.length);

  const colorForAllChannels =
    // newStats !== undefined && generateColors(newStats[Object.keys(newStats)[0]].conversations.length);
    newStats !== undefined && generateColors(Object.keys(newStats).length);

  useEffect(() => {
    // console.log(companyDetails)
    if (companyDetails !== undefined) {
      // const filteredArray = channels.filter((item) => {
      //   // Find the corresponding element in secondArray
      //   const found = companyDetails?.activeChannels.find(
      //     (channel) =>
      //       channel.chanelId.toLowerCase() !== item.apiSendName.toLocaleLowerCase()
      //   );
      //   // If found is undefined, it means there is no match, so keep the item in the array
      //   return !found;
      // });

      // var newArray = [
      //   // {
      //   //   icon: null,
      //   //   name: "All Channels",
      //   //   apiSendName: "all",
      //   //   disabled: false,
      //   // },
      //   ...filteredArray,
      // ];
      // console.log(filteredArray);
      // console.log(newArray);

      // Filter and match the arrays
      const filteredArray = companyDetails?.activeChannels
        ?.map((item) => {
          const matchedChannel = channels.find(
            (channel) => channel.apiSendName === item.chanelId.toLowerCase()
          );
          if (matchedChannel) {
            return {
              ...item,
              icon: matchedChannel.icon,
              apiSendName: matchedChannel.name.toLowerCase(),
              name: matchedChannel.name,
            };
          }
          return null;
        })
        .filter((item) => item !== null);

      // console.log(filteredArray);
      var newArray = [
        {
          icon: null,
          name: "All Channels",
          apiSendName: "all",
          disabled: false,
        },
        ...filteredArray,
      ];
      // console.log(filteredArray);
      // console.log(newArray);
      setChannels(newArray);
    }
  }, [companyDetails]);

  useEffect(() => {
    setIsSelected(false);
    setIsApiCall(false);
    setAllData(null);
    setWhatsappBroadcasting(undefined);
    setWhatsappHighlights(undefined);
    setWhatsappBusinessInitiatedBreakdown(undefined);
    setWhatsappConversationBreakDown(undefined);
    setWhatsappGraph({
      service: [],
      serviceCta: [],
      authtication: [],
      utility: [],
      marketing: [],
      label: [],
      isCalled: false,
    });
    setStats({
      whatsapp: [],
      facebook: [],
      instagram: [],
    });
    setGraphCount({
      whatsapp: 0,
      facebook: 0,
      instagram: 0,
    });

    setGraphLabels(undefined);
    setFacebookBusinessInitiatedBreakdown(undefined);
  }, [channel]);
  // useEffect(() => {
  //   // console.log(channels);

  //   // setChannel("all");WhatsappBusinessInitiatedBreakdown
  //     }, [channels]);
  // const findChannelExist = (data, channel) => {
  //   let result = data
  //     .map(function (o) {
  //       return o.chanelId;
  //     })
  //     .indexOf(channel);

  //   if (result > -1) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const clearAllWhatsaAppStates = () => {
    setWhatsappBroadcasting(undefined);
    setWhatsappHighlights(undefined);
    setWhatsappBusinessInitiatedBreakdown(undefined);
    setWhatsappConversationBreakDownGraph(undefined);
    setWhatsappConversationBreakDown(undefined);
    setWhatsappGraph({
      service: [],
      serviceCta: [],
      authtication: [],
      utility: [],
      marketing: [],
      label: [],
      isCalled: false,
    });
    setWhatsappMonthlyActiveUser({
      labels: [],
      data: [],
      newMau: null,
      returningMau: null,
      totalMau: null,
    });
    setWhatsappBotStats({
      totalConversations: 0,
      window: {},
      labels: [],
      data: [],
    });
    setStats({
      whatsapp: [],
      facebook: [],
      instagram: [],
    });
    setNewStats(undefined);
  };

  const clearAllChannelStates = () => {
    setAllData(null);
    setStats({
      whatsapp: [],
      facebook: [],
      instagram: [],
    });
    setGraphCount({
      whatsapp: 0,
      facebook: 0,
      instagram: 0,
    });
    setNewStats(undefined);
    setGraphLabels(undefined);
  };

  const clearAllFacebookStates = () => {
    // setStats({
    //   whatsapp: [],
    //   facebook: [],
    //   instagram: [],
    // });
    // setNewStats(undefined)
    // setFacebookBusinessInitiatedBreakdown(undefined);
    // setFacebookConversationBreakDownGraph(undefined);
  };

  // console.log(findChannelExist);
  const filterDropdown = [
    "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];
  const getStatsAll = () => {
    if (channel === "all") {
      // console.log(channel);
      apiGet(
        // `/stats/daily/${channel}/${STARTDATE}/${ENDDATE}`,
        `/detail-analytics/overview/${STARTDATE}/${ENDDATE}/${channel}`,
        onSuccessStats,
        onFailureStats
      );
    }
  };

  const onSuccessStats = (response) => {
    setIsApiCall(false);
    setIsSelected(false);
    setAllData(response.data);
    // console.log(response);

    let whatsappArray = [];
    let facebookArray = [];
    let instagramArray = [];
    // // filter data based on channel nam

    if (response?.data.overViewGraphResponse.length > 0) {
      let data = response?.data.overViewGraphResponse;
      for (let i = 0; i < data.length; i++) {
        if (data[i].dailyStats[0]?.chanelId === "WHATSAPP") {
          data[i]?.dailyStats.map((abc) => {
            whatsappArray.push(parseInt(abc.totalConversations));
          });
        }
        if (data[i].dailyStats[0]?.chanelId === "FACEBOOK") {
          data[i]?.dailyStats.map((abc) => {
            facebookArray.push(parseInt(abc.totalConversations));
          });
        }
        if (data[i].dailyStats[0]?.chanelId === "INSTAGRAM") {
          data[i]?.dailyStats.map((abc) => {
            instagramArray.push(parseInt(abc.totalConversations));
          });
        }
      }
    }

    const newChannelData = {};
    let data = response?.data.overViewGraphResponse;

    // // Iterate over the data
    // data.forEach(channel => {
    //   const channelId = channel.chanelId;
    //   // Initialize array for channel if it doesn't exist
    //   if (!newChannelData[channelId]) {
    //     newChannelData[channelId] = [];
    //   }

    //   channel.dailyStats.forEach(stat => {
    //     newChannelData[channelId].push(stat.totalConversations);
    //   });
    // });

    // Iterate over the data
    data.forEach((channel) => {
      const channelId = channel.chanelId;
      // Initialize array and sum for channel if they don't exist
      if (!newChannelData[channelId]) {
        newChannelData[channelId] = {
          conversations: [],
          sum: 0,
          labels: [],
        };
      }

      channel.dailyStats.forEach((stat) => {
        const totalConversations = stat.totalConversations || 0;
        const date = convertTimestampToDate(stat.date) || 0;
        newChannelData[channelId].conversations.push(totalConversations);
        newChannelData[channelId].sum += totalConversations;
        newChannelData[channelId].labels.push(date);
      });
    });

    // console.log(newChannelData);
    // console.log(
    //   Object.keys(newChannelData).map(
    //     (key) => newChannelData[key].conversations
    //   )
    // );
    // console.log(Object.keys(newStats)[0]);
    // console.log(
    //   Object.keys(newChannelData).map((key, index) => {
    //     if (index == 0) {
    //       return newChannelData[key];
    //     }
    //   })
    // );

    // console.log(Object.keys(newChannelData)[0])
    // console.log(Object.keys(newChannelData)[0]);
    // console.log(newChannelData[Object.keys(newChannelData)[0]]);
    // console.log(newStats)

    // console.log(Object.keys(newStats))
    setNewStats(newChannelData);
    // Set the state with the new channel data
    // setChannelData(newChannelData);

    // console.log(whatsappArray);
    // console.log(instagramArray);
    // console.log(facebookArray);

    // calculate total message delivered count for graph labels
    let whatsAppDataCount = whatsappArray?.reduce(
      (accumulator, currentValue) => {
        return parseInt(accumulator) + parseInt(currentValue);
      },
      0
    );
    let instagramDataCount = instagramArray?.reduce(
      (accumulator, currentValue) => {
        return parseInt(accumulator) + parseInt(currentValue);
      },
      0
    );
    let facebookDataCount = facebookArray?.reduce(
      (accumulator, currentValue) => {
        return parseInt(accumulator) + parseInt(currentValue);
      },
      0
    );
    // console.log(whatsAppDataCount);
    // getting graph labels
    let labels = [];
    response?.data?.overViewGraphResponse[0]?.dailyStats.map((val) => {
      return labels.push(val.date);
    });
    // console.log("label", labels);
    // calculate highlights data
    // let totalMessage = response?.data[0]?.channels
    //   .map((av) => {
    //     return (
    //       parseInt(av.totalDelivered) +
    //       parseInt(av.totalFailed) +
    //       parseInt(av.totalPending)
    //     );
    //   })
    //   .reduce((accumulator, currentValue) => {
    //     return parseInt(accumulator) + parseInt(currentValue);
    //   }, 0);
    // let totalDeliveredMessage = response?.data[0]?.channels
    //   .map((av) => {
    //     return parseInt(av.totalDelivered);
    //   })
    //   .reduce((accumulator, currentValue) => {
    //     return parseInt(accumulator) + parseInt(currentValue);
    //   }, 0);
    // let totalFailedMessage = response?.data[0]?.channels
    //   .map((av) => {
    //     return parseInt(av.totalFailed);
    //   })
    //   .reduce((accumulator, currentValue) => {
    //     return parseInt(accumulator) + parseInt(currentValue);
    //   }, 0);
    // let totalPendingMessage = response?.data[0]?.channels
    //   .map((av) => {
    //     return parseInt(av.totalPending);
    //   })
    //   .reduce((accumulator, currentValue) => {
    //     return parseInt(accumulator) + parseInt(currentValue);
    //   }, 0);
    // let totalCost = response?.data[0]?.channels
    //   .map((av) => {
    //     return parseInt(av.cost);
    //   })
    //   .reduce((accumulator, currentValue) => {
    //     return parseInt(accumulator) + parseInt(currentValue);
    //   }, 0);
    // let deliveryRate = (totalDeliveredMessage / totalMessage) * 100;
    // let pendingRate = (totalPendingMessage / totalMessage) * 100;
    // let failedRate = (totalFailedMessage / totalMessage) * 100;
    // setChannelPerformance({
    //   message: totalMessage === undefined ? 0 : totalMessage,
    //   deliveredRate: isNaN(deliveryRate) === true ? 0 : deliveryRate,
    //   cost: totalCost === undefined ? 0 : totalCost,
    // });
    // setHighlights({
    //   delivered: isNaN(deliveryRate) === true ? 0 : deliveryRate,
    //   pending: isNaN(pendingRate) === true ? 0 : pendingRate,
    //   failed: isNaN(failedRate) === true ? 0 : failedRate,
    // });

    // setStats({
    //   whatsapp: whatsappArray,
    //   facebook: facebookArray,
    //   instagram: instagramArray,
    // });
    setGraphCount({
      whatsapp: whatsAppDataCount,
      facebook: facebookDataCount,
      instagram: instagramDataCount,
    });

    // let convertedLabels = [];

    // labels.map((val) => {
    //   // let index = convertTimestamp(val).indexOf("-");
    //   // if (convertTimestamp(val).includes("-")) {
    //   //   convertedLabels.push("0m ago");
    //   // } else {
    //   convertedLabels.push(convertTimestampToDate(val));
    //   // }
    // });
    // setGraphLabels(convertedLabels);
  };
  const onFailureStats = (error) => {
    console.log(error);
  };

  const getWhatsappStats = () => {
    // if (channel === "whatsapp") {
    if (channel === "whatsapp" || channel === "facebook") {
      apiGet(
        `/detail-analytics/overview/${STARTDATE}/${ENDDATE}/${channel}`,
        onSuccessStats,
        onFailureStats
      );
    }
  };

  const getFacebookStats = () => {
    if (channel === "facebook") {
      apiGet(
        `/detail-analytics/overview/${STARTDATE}/${ENDDATE}/${channel}`,
        onSuccessStats,
        onFailureStats
      );
    }
  };

  // const onSuccessWhatsappStats = (response) => {
  //   setIsApiCall(false);
  //   setIsSelected(false);
  //   // console.log(response);
  // };
  // const onFailureWhatsappStats = (error) => {
  //   console.log(error);
  // };

  const getWhatsappHiglights = () => {
    // if (channel === "whatsapp") {
    if (channel === "whatsapp" || channel === "facebook") {
      apiGet(
        `/detail-analytics/whatsapp/highlights/${STARTDATE}/${ENDDATE}`,
        onSuccessWhatsappHighlights,
        onFailureWhatsappHighlights
      );
    }
  };
  const onSuccessWhatsappHighlights = (response) => {
    // console.log(response);
    setWhatsappHighlights(response.data);
  };
  const onFailureWhatsappHighlights = (error) => {
    console.log(error);
  };
  const getWhatsappGraph = () => {
    // if (channel === "whatsapp") {
    if (channel === "whatsapp" || channel === "facebook") {
      apiGet(
        `/detail-analytics/whatsapp/graph/${STARTDATE}/${ENDDATE}`,
        onSuccessWhatsappGraph,
        onFailureWhatsappGraph
      );
    }
  };
  const onSuccessWhatsappGraph = (response) => {
    // console.log("response", response);

    let serviceArray = [];
    let serviceCtaArray = [];
    let authenticationArray = [];
    let marketingArray = [];
    let utilityArray = [];

    if (response?.data.length > 0) {
      let data = response?.data;
      for (let i = 0; i < data.length; i++) {
        if (data[i].category.toLowerCase() === "service") {
          serviceArray.push(parseInt(data[i].totalConversation));
        }

        if (data[i].category.toLowerCase() === "servicecta") {
          serviceCtaArray.push(parseInt(data[i].totalConversation));
        }
        if (data[i].category.toLowerCase() === "authentication") {
          authenticationArray.push(parseInt(data[i].totalConversation));
        }
        if (data[i].category.toLowerCase() === "utility") {
          utilityArray.push(parseInt(data[i].totalConversation));
        }
        if (data[i].category.toLowerCase() === "marketing") {
          marketingArray.push(parseInt(data[i].totalConversation));
        }
      }

      // ({

      //   PK:[2],
      //   SR:[2,3],
      // })
    }

    let labels = [];
    response?.data?.map((val) => {
      return labels.push(convertTimestampToDate(val.date));
    });

    // Step 1: Remove duplicates using a Set
    const uniqueData = Array.from(new Set(labels));

    // Get the current year
    const currentYear = new Date().getFullYear();

    // Step 2: Sort the dates
    const sortedData = uniqueData.sort((a, b) => {
      const dateA = new Date(`${a} ${currentYear}`);
      const dateB = new Date(`${b} ${currentYear}`);
      return dateA - dateB;
    });
    // console.log(sortedData)

    setWhatsappGraph({
      service: serviceArray,
      serviceCta: serviceCtaArray,
      authtication: authenticationArray,
      marketing: marketingArray,
      utility: utilityArray,
      label: sortedData,
      isCalled: true,
    });
    // console.log(labels);

    // console.log(serviceArray);
    // console.log(serviceCtaArray);
    // console.log(authenticationArray);
    // console.log(utilityArray);
    // console.log(marketingArray);

    // setWhatsappGraph(response.data);
  };
  const onFailureWhatsappGraph = (error) => {
    console.log(error);
  };

  const getWhatsappDetailedBroadcastAnalytics = () => {
    // if (channel === "whatsapp") {
    if (channel === "whatsapp" || channel === "facebook") {
      apiGet(
        `/detail-analytics/broadcast`,
        onSuccessWhatsappDetailedBroadcastAnalytics,
        onFailureWhatsappDetailedBroadcastAnalytics
      );
    }
  };

  const onSuccessWhatsappDetailedBroadcastAnalytics = (response) => {
    // console.log(response);
    setCurrentPage(1);
    setWhatsappBroadcasting(response.data);
  };
  const onFailureWhatsappDetailedBroadcastAnalytics = (error) => {
    console.log(error);
  };

  const getWhatsappBusinessInitiatedBreakDown = () => {
    // if (channel === "whatsapp") {
    if (channel === "whatsapp" || channel === "facebook") {
      apiGet(
        `/detail-analytics/business/${STARTDATE}/${ENDDATE}`,
        onSuccessWhatsappBusinessInitiatedBreakDown,
        onFailureWhatsappBusinessInitiatedBreakDown
      );
    }
  };

  const onSuccessWhatsappBusinessInitiatedBreakDown = (response) => {
    // console.log(response);
    setWhatsappBusinessInitiatedBreakdown(response.data);
  };
  const onFailureWhatsappBusinessInitiatedBreakDown = (error) => {
    console.log(error);
  };

  const getWhatsappConversationBreakDown = () => {
    // if (channel === "whatsapp") {
    if (channel === "whatsapp" || channel === "facebook") {
      apiGet(
        `/detail-analytics/countryBreakDown/${STARTDATE}/${ENDDATE}`,
        onSuccessWhatsappConversationBreakDown,
        onFailureWhatsappConversationBreakDown
      );
    }
  };

  const onSuccessWhatsappConversationBreakDown = (response) => {
    // console.log(response);
    setWhatsappConversationBreakDown(response.data);
  };
  const onFailureWhatsappConversationBreakDown = (error) => {
    console.log(error);
  };
  const getWhatsappConversationBreakDownGraph = () => {
    // if (channel === "whatsapp") {
    if (channel === "whatsapp" || channel === "facebook") {
      apiGet(
        `/detail-analytics/countryBreakDownGraph/${STARTDATE}/${ENDDATE}`,
        onSuccessWhatsappConversationBreakDownGraph,
        onFailureWhatsappConversationBreakDownGraph
      );
    }
  };

  const onSuccessWhatsappConversationBreakDownGraph = (response) => {
    // console.log(response);
    // console.log(Object.keys(response.data));
    // if(Object.keys(response.data).length>0){
    //   Object.keys(response.data).map((val)=>{
    //      console.log(val)
    //   })
    // }

    let data = response.data;
    const result = {};
    for (const key in data) {
      if (response.data.hasOwnProperty(key)) {
        result[key] = data[key].map((item) => item.totalConversation);
      }
      if (response.data.hasOwnProperty(key)) {
        result["label"] = data[key].map((item) =>
          convertTimestampToDate(item.time)
        );
      }
    }

    // Step 1: Remove duplicates using a Set
    const uniqueData = Array.from(new Set(result.label));

    // Get the current year
    const currentYear = new Date().getFullYear();

    // Step 2: Sort the dates
    const sortedData = uniqueData.sort((a, b) => {
      const dateA = new Date(`${a} ${currentYear}`);
      const dateB = new Date(`${b} ${currentYear}`);
      return dateA - dateB;
    });
    // console.log(sortedData)

    result.label = sortedData;
    // console.log(result);
    // console.log(result);
    // console.log(Object.keys(result));
    // console.log(
    //   Object.keys(result)
    //   // .map((val)=>{
    //   //   return(
    //   //     result[val]
    //   //   )
    //   // }
    //   // )
    // );

    // console.log(Object.keys(result).map((key) => result[key]));

    // console.log(result);
    setWhatsappConversationBreakDownGraph(result);
  };
  const onFailureWhatsappConversationBreakDownGraph = (error) => {
    console.log(error);
  };
  const getWhatsappMinthlyActiveUser = () => {
    // if (channel === "whatsapp") {
    if (channel === "whatsapp" || channel === "facebook") {
      apiGet(
        `/detail-analytics/monthlyActiveUsers/${STARTDATE}/${ENDDATE}`,
        onSuccessWhatsappMinthlyActiveUser,
        onFailureWhatsappMinthlyActiveUser
      );
    }
  };

  const onSuccessWhatsappMinthlyActiveUser = (response) => {
    // console.log(response);
    let labels = [];
    response.data?.graphList?.map((val) => {
      labels.push(val.timeStamp);
    });
    let data = [];
    response.data?.graphList?.map((val) => {
      data.push(val.totalMau);
    });
    setWhatsappMonthlyActiveUser({
      labels: labels,
      data: data,
      newMau: response.data.newMau,
      returningMau: response.data.returningMau,
      totalMau: response.data.totalMau,
    });
  };
  const onFailureWhatsappMinthlyActiveUser = (error) => {
    console.log(error);
  };

  const getWhatsappBotStats = () => {
    // if (channel === "whatsapp") {
    if (channel === "whatsapp" || channel === "facebook") {
      apiGet(
        `/bot/stats/filter/${STARTDATE}/${ENDDATE}`,
        onSuccessWhatsappBotStats,
        onFailureWhatsappBotStats
      );
    }
  };

  const onSuccessWhatsappBotStats = (response) => {
    // console.log(response);
    let data = response.data;

    const groupByWindowName = (data) => {
      return data.reduce((acc, item) => {
        const windowName = item.windowName.trim(); // Trim to handle extra spaces
        if (!acc[windowName]) {
          acc[windowName] = [];
        }
        acc[windowName].push(item);
        return acc;
      }, {});
    };

    const groupedArrays = groupByWindowName(data);
    // console.log(groupedArrays);

    const totalConversations = data?.reduce(
      (sum, item) => sum + item.totalChats,
      0
    );
    // console.log(totalConversations);

    let labels = [];
    let graphdata = [];
    Object.keys(groupedArrays).map((val) => {
      labels.push(val);

      const sum = groupedArrays[val]?.reduce(
        (sum, item) => sum + item.totalChats,
        0
      );

      graphdata.push(sum);
    });
    // console.log(labels);
    // console.log(graphdata);

    setWhatsappBotStats({
      window: groupedArrays,
      totalConversations: totalConversations,
      labels: labels,
      data: graphdata,
    });
    // let result = {
    //   Welcome: [
    //     {
    //       windowName: " Welcome",
    //       chatBotName: "dublicate",
    //       totalChats: 0,
    //       totalUniqueChats: 0,
    //       channel: "WHATSAPP",
    //       botId: "171ISYF0NJF5JDA",
    //     },
    //   ],

    //   list: [
    //     {
    //       windowName: "list",
    //       chatBotName: "list",
    //       totalChats: 0,
    //       totalUniqueChats: 0,
    //       channel: "WHATSAPP",
    //       botId: "151IQ6KK091J2BN",
    //     },
    //     {
    //       windowName: "list",
    //       chatBotName: "sahar",
    //       totalChats: 0,
    //       totalUniqueChats: 0,
    //       channel: "WHATSAPP",
    //       botId: "131IQAADST79SB5",
    //     },
    //   ],
    //   . . . . .
    // };

    // let labels = [];
    // response.data?.graphList?.map((val) => {
    //   labels.push(val.timeStamp);
    // });
    // let data = [];
    // response.data?.graphList?.map((val) => {
    //   data.push(val.totalMau);
    // });
    // setWhatsappMonthlyActiveUser({
    //   labels: labels,
    //   data: data,
    //   newMau: response.data.newMau,
    //   returningMau: response.data.returningMau,
    //   totalMau: response.data.totalMau,
    // });
  };
  const onFailureWhatsappBotStats = (error) => {
    console.log(error);
  };

  // ****************************   facebook apis **********************
  const getFacebookBusinessInitiatedBreakDown = () => {
    if (channel === "facebook") {
      apiGet(
        `/detail-analytics/business/${STARTDATE}/${ENDDATE}`,
        onSuccessFacebookBusinessInitiatedBreakDown,
        onFailureFacebookBusinessInitiatedBreakDown
      );
    }
  };

  const onSuccessFacebookBusinessInitiatedBreakDown = (response) => {
    // console.log(response);
    setFacebookBusinessInitiatedBreakdown(response.data);
  };
  const onFailureFacebookBusinessInitiatedBreakDown = (error) => {
    console.log(error);
  };

  const getFacebookConversationBreakDownGraph = () => {
    if (channel === "facebook") {
      apiGet(
        `/detail-analytics/countryBreakDownGraph/${STARTDATE}/${ENDDATE}`,
        onSuccessFacebookConversationBreakDownGraph,
        onFailureWFacebookonversationBreakDownGraph
      );
    }
  };

  const onSuccessFacebookConversationBreakDownGraph = (response) => {
    // console.log(response);
    // console.log(Object.keys(response.data));
    // if(Object.keys(response.data).length>0){
    //   Object.keys(response.data).map((val)=>{
    //      console.log(val)
    //   })
    // }

    let data = response.data;
    const result = {};
    for (const key in data) {
      if (response.data.hasOwnProperty(key)) {
        result[key] = data[key].map((item) => item.totalConversation);
      }
      if (response.data.hasOwnProperty(key)) {
        result["label"] = data[key].map((item) =>
          convertTimestampToDate(item.time)
        );
      }
    }

    // console.log(result);
    // console.log(result);
    // console.log(Object.keys(result));
    // console.log(
    //   Object.keys(result)
    //   // .map((val)=>{
    //   //   return(
    //   //     result[val]
    //   //   )
    //   // }
    //   // )
    // );
    setFacebookConversationBreakDownGraph(result);
  };
  const onFailureWFacebookonversationBreakDownGraph = (error) => {
    console.log(error);
  };

  // **************************** end facebook apis ********************
  useEffect(() => {
    if (isApiCall == true) {
      if (selectedDateFilter === "Custom range") {
        if (isSelected === true) {
          // for all channel
          getStatsAll(0);

          // for wahtsapp
          getWhatsappStats();
          getWhatsappHiglights();
          getWhatsappDetailedBroadcastAnalytics();
          getWhatsappGraph();
          getWhatsappBusinessInitiatedBreakDown();
          getWhatsappConversationBreakDown();
          getWhatsappConversationBreakDownGraph();
          getWhatsappMinthlyActiveUser();
          getWhatsappBotStats();

          // for facebook
          getFacebookStats();
          getFacebookBusinessInitiatedBreakDown();
          getFacebookConversationBreakDownGraph();
        }
      } else {
        // for all channel
        getStatsAll(0);

        // for wahtsapp
        getWhatsappStats();
        getWhatsappHiglights();
        getWhatsappDetailedBroadcastAnalytics();
        getWhatsappGraph();
        getWhatsappBusinessInitiatedBreakDown();
        getWhatsappConversationBreakDown();
        getWhatsappConversationBreakDownGraph();
        getWhatsappMinthlyActiveUser();
        getWhatsappBotStats();

        // for facebook
        getFacebookStats();
        getFacebookBusinessInitiatedBreakDown();
        getFacebookConversationBreakDownGraph();
      }
    }
  }, [isApiCall]);

  useEffect(() => {
    // for all channels
    getStatsAll();

    // for whatsapp only
    getWhatsappStats();
    getWhatsappGraph();
    getWhatsappHiglights();
    getWhatsappDetailedBroadcastAnalytics();
    getWhatsappBusinessInitiatedBreakDown();
    getWhatsappConversationBreakDown();
    getWhatsappConversationBreakDownGraph();
    getWhatsappMinthlyActiveUser();
    getWhatsappBotStats();

    // for facebook
    getFacebookStats();
    getFacebookBusinessInitiatedBreakDown();
    getFacebookConversationBreakDownGraph();
  }, [channel]);

  const handleDatePickerClose = () => {
    if (selectedDateFilter === "Custom range") {
      setIsSelected(true);
    }
    UIkit.drop("#datepickerdrop").hide(true);
  };

  const handleDatePickerCloseWhatsapp = () => {
    if (selectedDateFilter === "Custom range") {
      setIsSelected(true);
    }
    UIkit.drop("#datepickerdropWhatsapp").hide(true);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return whatsappBroadcasting?.broadCastSummaries?.slice(
      firstPageIndex,
      lastPageIndex
    );
  }, [currentPage]);

  const onChange = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
    // setIsApiCall(true);
  };
  const handleDateFilterDropdownrClose = () => {
    UIkit.drop("#dateDropdownSection").hide(true);
  };
  const handleDateFilter = (filter) => {
    setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    if (filter !== "Custom range") {
      setIsApiCall(true);
    }
  };

  useEffect(() => {
    if (isSelected === true) {
      setIsApiCall(true);
    }
  }, [isSelected]);

  return (
    <>
      <div className="boradcastWrp">
        <div className="boradcastTabbing">
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #newAnalyticsTab"
          >
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                <li
                  onClick={() => {
                    navigate(val.route, { replace: true });
                  }}
                  className={val.active === true ? "uk-active" : ""}
                  key={index}
                >
                  <a>{val.name}</a>
                </li>
              ) : null;
            })}
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="newAnalyticsTab">
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                val.name === "Overview" ? (
                  <li key={index}>
                    {channel === "all" && (
                      <div className="overviewContent">
                        <div className="uk-container">
                          <div className="overviewTopDropdown uk-margin-bottom">
                            <div className="uk-grid uk-flex-middle" uk-grid="">
                              <div className="uk-width-1-2">
                                <div className="allChannelDropDown">
                                  <div className="uk-inline tableFilterDropdown broadcastChannelFilter">
                                    <button
                                      type="button"
                                      className="dropdownBtn"
                                    >
                                      {channel === "all"
                                        ? "All Active Channels"
                                        : channel}{" "}
                                      <ChevronDown />
                                    </button>
                                    <div
                                      uk-dropdown="mode: click"
                                      className="dropdownNav"
                                    >
                                      <span className="dropdownHeading">
                                        Channels
                                      </span>
                                      <ul>
                                        {channels?.map((val, index) => {
                                          return (
                                            <li
                                              key={index}
                                              className={
                                                channel === val.apiSendName
                                                  ? "activeBtn"
                                                  : ""
                                              }
                                              onClick={() => {
                                                setIsSelected(false);
                                                setChannel(val.apiSendName);
                                              }}
                                            >
                                              <button
                                                type="button"
                                                disabled={
                                                  val.disabled === true
                                                    ? true
                                                    : false
                                                }
                                                className={
                                                  val.disabled === true
                                                    ? "opactityBtn"
                                                    : ""
                                                }
                                              >
                                                {index !== 0 && (
                                                  <img src={val.icon} alt="" />
                                                )}
                                                {val.name}
                                              </button>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="uk-width-1-2 btnSection">
                                {/* <button className="exportBtn">
                            <Download /> Export Data
                          </button> */}

                                <div className="uk-inline">
                                  <button className="calenderBtn rangeBtn">
                                    <Calendar /> {selectedDateFilter}
                                    <span className="uk-inline">
                                      <ChevronDown />
                                    </span>
                                  </button>
                                  <div
                                    uk-dropdown="mode: click"
                                    className="calendarDropdown"
                                    id="dateDropdownSection"
                                  >
                                    <div className="dropDownHeading">
                                      <span>Select a range</span>
                                    </div>
                                    <ul>
                                      {filterDropdown.map((val, index) => {
                                        const isCustomRange =
                                          val === "Custom range";
                                        const isLastItem =
                                          filterDropdown.length - 1 === index;
                                        const isSecondLastItem =
                                          filterDropdown.length - 2 === index;

                                        return (
                                          <React.Fragment key={index}>
                                            <li
                                              className={
                                                selectedDateFilter === val
                                                  ? "activeBtn"
                                                  : ""
                                              }
                                              onClick={() => {
                                                if (!isCustomRange) {
                                                  clearAllChannelStates();
                                                }
                                                handleDateFilter(val);
                                              }}
                                            >
                                              {isLastItem ? (
                                                <div className="uk-inline uk-text-left">
                                                  <button
                                                    className="rangeBtn"
                                                    type="button"
                                                  >
                                                    <span className="rangeBtnCircle"></span>{" "}
                                                    Custom range
                                                  </button>
                                                  <div
                                                    id="datepickerdropWhatsapp"
                                                    uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                    className="date-picker-container"
                                                  >
                                                    <CalendarDate
                                                      onChange={onChange}
                                                    />
                                                    <div className="date-picker-btn-container uk-text-right">
                                                      <button
                                                        className="date-selection-btn"
                                                        onClick={
                                                          handleDatePickerCloseWhatsapp
                                                        }
                                                      >
                                                        Ok
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="rangeBtn"
                                                >
                                                  <span className="rangeBtnCircle"></span>{" "}
                                                  {val}
                                                </button>
                                              )}
                                            </li>
                                            {isSecondLastItem && (
                                              <hr style={{ margin: "5px 0" }} />
                                            )}
                                          </React.Fragment>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="overviewMainContent">
                            <div className="mainBox">
                              <div className="boxHeading">
                                <span>All channels overview</span>
                              </div>
                              <div className="boxContent">
                                <div className="uk-grid" uk-grid="">
                                  <div className="uk-width-1-1">
                                    <div
                                      className="overviewChart"
                                      style={{ minHeight: "400px" }}
                                    >
                                      {/* <div className="overviewChart" style={{minHeight:"400px"}}> */}

                                      {newStats !== undefined && (
                                        <DynamicLineChart
                                          datasets={Object.keys(newStats).map(
                                            (key) => newStats[key].conversations
                                          )}
                                          graphLabels={
                                            newStats[Object.keys(newStats)[0]]
                                              .labels
                                          }
                                          colors={colorForAllChannels}
                                          labelsForHover={Object.keys(newStats)}
                                        />
                                      )}

                                      {/* <LineChart
                                  dataSet1={stats?.whatsapp}
                                  dataSet2={stats?.facebook}
                                  dataSet3={stats?.instagram}
                                  graphLabels={graphLabels}
                                  labelsForHover={[
                                    "Whatsapp",
                                    "Facebook",
                                    "Instagram",
                                  ]}
                                /> */}
                                    </div>
                                    <div className="chartLabel">
                                      {companyDetails !== undefined ? (
                                        findChannelExist(
                                          companyDetails?.activeChannels,
                                          "FACEBOOK"
                                        ) ? (
                                          <div className="labelItem">
                                            <img src={messengerIcon} alt="" />
                                            <p>
                                              Messanger ({graphCount?.facebook})
                                            </p>
                                          </div>
                                        ) : null
                                      ) : null}

                                      {companyDetails !== undefined ? (
                                        findChannelExist(
                                          companyDetails?.activeChannels,
                                          "WHATSAPP"
                                        ) ? (
                                          <div className="labelItem">
                                            <img src={whatsAppImg} alt="" />
                                            <p>
                                              WhatsApp ({graphCount?.whatsapp})
                                            </p>
                                          </div>
                                        ) : null
                                      ) : null}

                                      {companyDetails !== undefined ? (
                                        findChannelExist(
                                          companyDetails?.activeChannels,
                                          "INSTAGRAM"
                                        ) ? (
                                          <div className="labelItem">
                                            <img src={instaIcon} alt="" />
                                            <p>
                                              Instagram ({graphCount?.instagram}
                                              )
                                            </p>
                                          </div>
                                        ) : null
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mainBox">
                              <div className="boxHeading">
                                <span>All channels performance</span>
                              </div>
                              <div className="boxContent">
                                <div className="uk-grid" uk-grid="">
                                  <div className="uk-width-1-3">
                                    <div className="txtwrp">
                                      <h2>
                                        {allData === null ? (
                                          <span uk-spinner=""></span>
                                        ) : (
                                          convertIntoThousand(
                                            parseInt(
                                              allData
                                                ?.overViewChannelPerformance
                                                ?.totalMessageSent
                                            ).toFixed(0)
                                          )
                                        )}
                                      </h2>
                                      <span>Messages</span>
                                    </div>
                                  </div>
                                  <div className="uk-width-1-3">
                                    <div className="txtwrp">
                                      <h2>
                                        {allData === null ? (
                                          <span uk-spinner=""></span>
                                        ) : (
                                          parseInt(
                                            allData?.overViewChannelPerformance
                                              ?.totalDeliveryRate
                                          ).toFixed(2)
                                        )}
                                        {allData !== null && "%"}
                                      </h2>
                                      <span>Delivery Rate</span>
                                    </div>
                                  </div>
                                  <div className="uk-width-1-3">
                                    <div className="txtwrp">
                                      <h2>
                                        {allData !== null && "$"}

                                        {allData === null ? (
                                          <span uk-spinner=""></span>
                                        ) : (
                                          parseInt(
                                            allData?.overViewChannelPerformance
                                              ?.totalCost
                                          )
                                        )}
                                      </h2>
                                      <span>Cost</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mainBox">
                              <div className="boxHeading">
                                <span>Breakdown by Status</span>
                              </div>
                              <div className="boxContent">
                                <div className="uk-grid" uk-grid="">
                                  <div className="uk-width-1-3">
                                    <div className="txtwrp">
                                      {/* <h2>{highlights?.delivered.toFixed(2)}%</h2> */}
                                      <h2>
                                        {allData === null ? (
                                          <span uk-spinner=""></span>
                                        ) : (
                                          convertIntoThousand(
                                            parseInt(
                                              allData?.breakDownStatusResponse
                                                ?.totalMessageDeliver
                                            ).toFixed(0)
                                          )
                                        )}
                                      </h2>
                                      <span>Delivered</span>
                                    </div>
                                  </div>
                                  <div className="uk-width-1-3">
                                    <div className="txtwrp">
                                      {/* <h2>{highlights?.failed.toFixed(2)}%</h2> */}
                                      <h2>
                                        {allData === null ? (
                                          <span uk-spinner=""></span>
                                        ) : (
                                          convertIntoThousand(
                                            parseInt(
                                              allData?.breakDownStatusResponse
                                                ?.totalUndelivered
                                            ).toFixed(0)
                                          )
                                        )}
                                      </h2>
                                      <span>Undeliverable</span>
                                    </div>
                                  </div>
                                  <div className="uk-width-1-3">
                                    <div className="txtwrp">
                                      {/* <h2>5.09%</h2> */}
                                      <h2>
                                        {allData === null ? (
                                          <span uk-spinner=""></span>
                                        ) : (
                                          convertIntoThousand(
                                            parseInt(
                                              allData?.breakDownStatusResponse
                                                ?.totalRejected
                                            ).toFixed(0)
                                          )
                                        )}
                                      </h2>
                                      <span>Rejected</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {channel === "whatsapp" && (
                      <div className="overviewContent">
                        <div className="uk-container">
                          <div className="overviewTopDropdown">
                            <div className="uk-grid uk-flex-middle" uk-grid="">
                              <div className="uk-width-1-2">
                                <div className="allChannelDropDown">
                                  <div className="uk-inline tableFilterDropdown broadcastChannelFilter">
                                    <button
                                      type="button"
                                      className="dropdownBtn"
                                    >
                                      {channel === "all"
                                        ? "All Active Channels"
                                        : channel}{" "}
                                      <ChevronDown />
                                    </button>
                                    <div
                                      uk-dropdown="mode: click"
                                      className="dropdownNav"
                                    >
                                      <span className="dropdownHeading">
                                        Channels
                                      </span>
                                      <ul>
                                        {channels.map((val, index) => {
                                          return (
                                            <li
                                              key={index}
                                              className={
                                                channel === val.apiSendName
                                                  ? "activeBtn"
                                                  : ""
                                              }
                                              onClick={() => {
                                                setIsSelected(false);
                                                setChannel(val.apiSendName);
                                              }}
                                            >
                                              <button
                                                type="button"
                                                disabled={
                                                  val.disabled === true
                                                    ? true
                                                    : false
                                                }
                                                className={
                                                  val.disabled === true
                                                    ? "opactityBtn"
                                                    : ""
                                                }
                                              >
                                                {index !== 0 && (
                                                  <img src={val.icon} alt="" />
                                                )}
                                                {val.name}
                                              </button>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="uk-width-1-2 btnSection">
                                {/* <button className="exportBtn">
                            <Download /> Export Data
                          </button> */}

                                <div className="uk-inline">
                                  <button className="calenderBtn rangeBtn">
                                    <Calendar /> {selectedDateFilter}
                                    <span className="uk-inline">
                                      <ChevronDown />
                                    </span>
                                  </button>
                                  <div
                                    uk-dropdown="mode: click"
                                    className="calendarDropdown"
                                    id="dateDropdownSection"
                                  >
                                    <div className="dropDownHeading">
                                      <span>Select a range</span>
                                    </div>

                                    <ul>
                                      {filterDropdown.map((val, index) => (
                                        <>
                                          <li
                                            className={
                                              selectedDateFilter === val
                                                ? "activeBtn"
                                                : ""
                                            }
                                            key={index}
                                            onClick={() => {
                                              if (val !== "Custom range") {
                                                clearAllWhatsaAppStates();
                                              }
                                              handleDateFilter(val);
                                            }}
                                          >
                                            {filterDropdown.length - 1 ===
                                            index ? (
                                              <div className="uk-inline uk-text-left">
                                                <button
                                                  className="rangeBtn"
                                                  type="button"
                                                >
                                                  <span className="rangeBtnCircle"></span>{" "}
                                                  Custom range
                                                </button>
                                                <div
                                                  id="datepickerdropWhatsapp"
                                                  uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                  className="date-picker-container"
                                                >
                                                  <CalendarDate
                                                    onChange={onChange}
                                                  />
                                                  <div className="date-picker-btn-container uk-text-right">
                                                    <button
                                                      className="date-selection-btn"
                                                      onClick={
                                                        handleDatePickerCloseWhatsapp
                                                      }
                                                    >
                                                      Ok
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <button
                                                type="button"
                                                className="rangeBtn"
                                              >
                                                <span className="rangeBtnCircle"></span>{" "}
                                                {val}
                                              </button>
                                            )}
                                          </li>
                                          {filterDropdown.length - 2 ===
                                            index && (
                                            <hr style={{ margin: "5px 0" }} />
                                          )}
                                        </>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="uk-width-1-1 uk-margin-remove-top">
                                <div className="analyticsTabWrp">
                                  <div className="whatappTabBtn">
                                    <ul
                                      className="uk-subnav uk-subnav-pill"
                                      uk-switcher="connect: .whatsappTabBtn"
                                    >
                                      <li>
                                        <a href="#">Analytics</a>
                                      </li>
                                      <li>
                                        <a href="#">Broadcast</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="analyticsWhatsappContent">
                                    <ul className="uk-switcher uk-margin whatsappTabBtn">
                                      <li>
                                        <div className="overviewMainContent">
                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>Highlights</span>
                                            </div>
                                            <div className="boxContent">
                                              <div
                                                className="uk-grid"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-1-6 uk-first-column">
                                                  <div className="txtwrp">
                                                    <h2>
                                                      {whatsappHighlights ===
                                                      undefined ? (
                                                        <span uk-spinner=""></span>
                                                      ) : (
                                                        convertIntoThousand(
                                                          whatsappHighlights?.totalConversation
                                                        )
                                                      )}
                                                    </h2>
                                                    <span>
                                                      Total conversations
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="uk-width-1-6">
                                                  <div className="txtwrp">
                                                    <h2>
                                                      {whatsappHighlights ===
                                                      undefined ? (
                                                        <span uk-spinner=""></span>
                                                      ) : (
                                                        convertIntoThousand(
                                                          whatsappHighlights?.serviceInitiate
                                                        )
                                                      )}
                                                    </h2>
                                                    <span>
                                                      Service initiated
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="uk-width-1-6">
                                                  <div className="txtwrp">
                                                    <h2>
                                                      {whatsappHighlights ===
                                                      undefined ? (
                                                        <span uk-spinner=""></span>
                                                      ) : (
                                                        convertIntoThousand(
                                                          whatsappHighlights?.serviceCta
                                                        )
                                                      )}
                                                    </h2>
                                                    <span>
                                                      Service initiated (CTA)
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="uk-width-1-6">
                                                  <div className="txtwrp">
                                                    <h2>
                                                      {whatsappHighlights ===
                                                      undefined ? (
                                                        <span uk-spinner=""></span>
                                                      ) : (
                                                        convertIntoThousand(
                                                          whatsappHighlights?.authenticate
                                                        )
                                                      )}
                                                    </h2>
                                                    <span>Authentication</span>
                                                  </div>
                                                </div>
                                                <div className="uk-width-1-6">
                                                  <div className="txtwrp">
                                                    <h2>
                                                      {whatsappHighlights ===
                                                      undefined ? (
                                                        <span uk-spinner=""></span>
                                                      ) : (
                                                        convertIntoThousand(
                                                          whatsappHighlights?.utility
                                                        )
                                                      )}
                                                    </h2>
                                                    <span>Utility</span>
                                                  </div>
                                                </div>
                                                <div className="uk-width-1-6">
                                                  <div className="txtwrp">
                                                    <h2>
                                                      {whatsappHighlights ===
                                                      undefined ? (
                                                        <span uk-spinner=""></span>
                                                      ) : (
                                                        convertIntoThousand(
                                                          whatsappHighlights?.marketing
                                                        )
                                                      )}
                                                    </h2>
                                                    <span>Marketing</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>WhatsApp Overview</span>
                                            </div>
                                            <div className="boxContent">
                                              <div
                                                className="uk-grid"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-1-1">
                                                  <div className="overviewChart">
                                                    <LineChart
                                                      dataSet1={
                                                        whatsappGraph.service
                                                      }
                                                      dataSet2={
                                                        whatsappGraph?.serviceCta
                                                      }
                                                      dataSet3={
                                                        whatsappGraph?.authtication
                                                      }
                                                      dataSet4={
                                                        whatsappGraph?.utility
                                                      }
                                                      dataSet5={
                                                        whatsappGraph?.marketing
                                                      }
                                                      graphLabels={
                                                        whatsappGraph.label
                                                      }
                                                      labelsForHover={[
                                                        "Service initiated",
                                                        "Service initiated (CTA)",
                                                        "Authentication",
                                                        "Utility",
                                                        "Marketing",
                                                      ]}
                                                    />
                                                    {/* {whatsappGraph.isCalled===true &&
                                              <DynamicLineChart
                                                dataSets={
                                                  whatsappGraph?.service
                                                  // whatsappGraph?.serviceCta,
                                                  // whatsappGraph?.authtication,
                                                  // whatsappGraph?.utility,
                                                  // whatsappGraph?.marketing,
                                                }
                                                graphLabels={
                                                  whatsappGraph?.label
                                                }
                                                colors={generateColors(5)}
                                              />
                                            } */}
                                                  </div>
                                                  <div className="chartLabel">
                                                    <div className="labelItem">
                                                      <p>Service initiated</p>
                                                    </div>
                                                    <div className="labelItem">
                                                      <p>
                                                        Service initiated (CTA)
                                                      </p>
                                                    </div>
                                                    <div className="labelItem">
                                                      <p>Authentication</p>
                                                    </div>
                                                    <div className="labelItem">
                                                      <p>Utility</p>
                                                    </div>
                                                    <div className="labelItem">
                                                      <p>Marketing</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>
                                                Business Initiated Messages
                                                Status Breakdown
                                              </span>
                                            </div>
                                            <div className="boxContent">
                                              <div
                                                className="uk-grid uk-flex-middle"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-1-4">
                                                  <div className="chartWrp">
                                                    {whatsappBusinessInitiatedBreakdown?.totalMessages >
                                                    0 ? (
                                                      <DoughnutChart
                                                        degree={360}
                                                        backgroudColor={[
                                                          "#2F5711",
                                                          "#EDC843",
                                                          "#A8200D",
                                                          "#B4B4B4",
                                                        ]}
                                                        borderColor={[
                                                          "#2F5711",
                                                          "#EDC843",
                                                          "#A8200D",
                                                          "#B4B4B4",
                                                        ]}
                                                        graphData={[
                                                          whatsappBusinessInitiatedBreakdown?.delivered,
                                                          whatsappBusinessInitiatedBreakdown?.pending,
                                                          whatsappBusinessInitiatedBreakdown?.failed,
                                                          whatsappBusinessInitiatedBreakdown?.systemError,
                                                        ]}
                                                        graphlabels={[
                                                          "Delivered",
                                                          "Pending",
                                                          "Failed",
                                                          "System Error",
                                                        ]}
                                                      />
                                                    ) : (
                                                      <DoughnutChart
                                                        degree={360}
                                                        backgroudColor={[
                                                          "#B4B4B4",
                                                        ]}
                                                        borderColor={[
                                                          "#B4B4B4",
                                                        ]}
                                                        graphData={[1]}
                                                        graphlabels={["-"]}
                                                      />
                                                    )}

                                                    <div className="chartData">
                                                      <h3>
                                                        {
                                                          whatsappBusinessInitiatedBreakdown?.totalMessages
                                                        }
                                                      </h3>
                                                      <p>
                                                        {/* {
                                                    whatsappBusinessInitiatedBreakdown?.totalMessages
                                                  }{" "} */}
                                                        Total Messages
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="uk-width-3-4">
                                                  <div className="chartRecordData">
                                                    <div className="chartRecordDataWrp">
                                                      <div className="deliveryRate">
                                                        <ul>
                                                          <li>
                                                            <p>
                                                              <span className="darkGreenDot"></span>
                                                              Delivered
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="yellowDot"></span>
                                                              Pending
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="redDot"></span>
                                                              Failed
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="grayDot"></span>
                                                              System Error
                                                            </p>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                      <div className="percentageData">
                                                        <ul>
                                                          <li>
                                                            <p>
                                                              {
                                                                whatsappBusinessInitiatedBreakdown?.delivered
                                                              }
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              {
                                                                whatsappBusinessInitiatedBreakdown?.pending
                                                              }
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              {
                                                                whatsappBusinessInitiatedBreakdown?.failed
                                                              }
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              {
                                                                whatsappBusinessInitiatedBreakdown?.systemError
                                                              }
                                                            </p>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                      <div className="progressBar">
                                                        <ul>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressDarkGreen"
                                                              value={
                                                                whatsappBusinessInitiatedBreakdown?.delivered
                                                              }
                                                              max={
                                                                whatsappBusinessInitiatedBreakdown?.totalMessages
                                                              }
                                                            ></progress>
                                                          </li>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressYellow"
                                                              value={
                                                                whatsappBusinessInitiatedBreakdown?.pending
                                                              }
                                                              max={
                                                                whatsappBusinessInitiatedBreakdown?.totalMessages
                                                              }
                                                            ></progress>
                                                          </li>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressHigh"
                                                              value={
                                                                whatsappBusinessInitiatedBreakdown?.failed
                                                              }
                                                              max={
                                                                whatsappBusinessInitiatedBreakdown?.totalMessages
                                                              }
                                                            ></progress>
                                                          </li>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressGray"
                                                              value={
                                                                whatsappBusinessInitiatedBreakdown?.systemError
                                                              }
                                                              max={
                                                                whatsappBusinessInitiatedBreakdown?.totalMessages
                                                              }
                                                            ></progress>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                      <div className="percentageData">
                                                        <ul>
                                                          <li>
                                                            <p>
                                                              {whatsappBusinessInitiatedBreakdown?.totalMessages >
                                                              0
                                                                ? (
                                                                    (whatsappBusinessInitiatedBreakdown?.delivered /
                                                                      whatsappBusinessInitiatedBreakdown?.totalMessages) *
                                                                    100
                                                                  ).toFixed(2) +
                                                                  "%"
                                                                : "0%"}
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              {whatsappBusinessInitiatedBreakdown?.totalMessages >
                                                              0
                                                                ? (
                                                                    (whatsappBusinessInitiatedBreakdown?.pending /
                                                                      whatsappBusinessInitiatedBreakdown?.totalMessages) *
                                                                    100
                                                                  ).toFixed(2) +
                                                                  "%"
                                                                : "0%"}
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              {whatsappBusinessInitiatedBreakdown?.totalMessages >
                                                              0
                                                                ? (
                                                                    (whatsappBusinessInitiatedBreakdown?.failed /
                                                                      whatsappBusinessInitiatedBreakdown?.totalMessages) *
                                                                    100
                                                                  ).toFixed(2) +
                                                                  "%"
                                                                : "0%"}
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              {whatsappBusinessInitiatedBreakdown?.totalMessages >
                                                              0
                                                                ? (
                                                                    (whatsappBusinessInitiatedBreakdown?.systemError /
                                                                      whatsappBusinessInitiatedBreakdown?.totalMessages) *
                                                                    100
                                                                  ).toFixed(2) +
                                                                  "%"
                                                                : "0%"}
                                                            </p>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>
                                                Conversations break up by
                                                country
                                              </span>
                                            </div>
                                            <div className="boxContent">
                                              <div
                                                className="uk-grid"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-2-5">
                                                  <div className="countryDetailWrp">
                                                    <div className="detailHeading">
                                                      <p>COUNTRY</p>
                                                    </div>
                                                    <ul>
                                                      {whatsappConversationBreakDown?.map(
                                                        (val, index) => {
                                                          return (
                                                            <li key={index}>
                                                              <img
                                                                src={`https://flagcdn.com/20x15/${val.country.toLowerCase()}.png`}
                                                              />
                                                              <span>
                                                                {getCountryNameByCode(
                                                                  val.country
                                                                )}
                                                              </span>{" "}
                                                            </li>
                                                          );
                                                        }
                                                      )}

                                                      {/* <li>
                                                  <img src={pakistanFlag} />
                                                  <span>Pakistan</span>
                                                </li>
                                                <li>
                                                  <img src={pakistanFlag} />
                                                  <span>Pakistan</span>
                                                </li>
                                                <li>
                                                  <img src={pakistanFlag} />
                                                  <span>Pakistan</span>
                                                </li> */}
                                                    </ul>
                                                  </div>
                                                </div>
                                                <div className="uk-width-3-5">
                                                  <div className="chartRecordData">
                                                    <div className="chartRecordDataWrp">
                                                      <div className="percentageData">
                                                        <div
                                                          className="detailHeading"
                                                          style={{
                                                            marginBottom:
                                                              "24px",
                                                          }}
                                                        >
                                                          <p>MESSAGES</p>
                                                        </div>
                                                        <ul>
                                                          {whatsappConversationBreakDown?.map(
                                                            (val, index) => {
                                                              return (
                                                                <li key={index}>
                                                                  <p>
                                                                    {
                                                                      val.totalMessage
                                                                    }
                                                                  </p>
                                                                </li>
                                                              );
                                                            }
                                                          )}
                                                        </ul>
                                                      </div>
                                                      <div className="progressBar">
                                                        <div
                                                          className="detailHeading"
                                                          style={{
                                                            marginBottom:
                                                              "24px",
                                                          }}
                                                        >
                                                          <p>MESSAGES</p>
                                                        </div>
                                                        <ul>
                                                          {whatsappConversationBreakDown?.map(
                                                            (val, index) => {
                                                              return (
                                                                <li key={index}>
                                                                  <progress
                                                                    className={`uk-progress progressDarkGreen`}
                                                                    value={
                                                                      val.totalMessage
                                                                    }
                                                                    max={
                                                                      totalMessages
                                                                    }
                                                                  ></progress>
                                                                </li>
                                                              );
                                                            }
                                                          )}

                                                          {/* <li>
                                                      <progress
                                                        className="uk-progress progressYellow"
                                                        value="50"
                                                        max="100"
                                                      ></progress>
                                                    </li>
                                                    <li>
                                                      <progress
                                                        className="uk-progress progressLightGreen"
                                                        value="20"
                                                        max="100"
                                                      ></progress>
                                                    </li>
                                                    <li>
                                                      <progress
                                                        className="uk-progress progressHigh"
                                                        value="10"
                                                        max="100"
                                                      ></progress>
                                                    </li>
                                                    <li>
                                                      <progress
                                                        className="uk-progress progressDarkOrange"
                                                        value="10"
                                                        max="100"
                                                      ></progress>
                                                    </li>
                                                    <li>
                                                      <progress
                                                        className="uk-progress progressError"
                                                        value="10"
                                                        max="100"
                                                      ></progress>
                                                    </li> */}
                                                        </ul>
                                                      </div>
                                                      <div className="percentageData">
                                                        <div
                                                          className="detailHeading"
                                                          style={{
                                                            marginBottom:
                                                              "24px",
                                                          }}
                                                        >
                                                          <p>MESSAGES</p>
                                                        </div>
                                                        <ul>
                                                          {whatsappConversationBreakDown?.map(
                                                            (val, index) => {
                                                              return (
                                                                <li>
                                                                  <p>
                                                                    {(
                                                                      (val.totalMessage /
                                                                        totalMessages) *
                                                                      100
                                                                    ).toFixed(
                                                                      2
                                                                    )}
                                                                    %
                                                                  </p>
                                                                </li>
                                                              );
                                                            }
                                                          )}
                                                          {/* <li>
                                                      <p>12.78%</p>
                                                    </li>
                                                    <li>
                                                      <p>3.33%</p>
                                                    </li>
                                                    <li>
                                                      <p>0.1%</p>
                                                    </li>
                                                    <li>
                                                      <p>0.1%</p>
                                                    </li>
                                                    <li>
                                                      <p>0.1%</p>
                                                    </li> */}
                                                        </ul>
                                                      </div>
                                                      <div className="percentageData">
                                                        <div
                                                          className="detailHeading"
                                                          style={{
                                                            marginBottom:
                                                              "24px",
                                                          }}
                                                        >
                                                          <p>COST</p>
                                                        </div>
                                                        <ul>
                                                          {whatsappConversationBreakDown?.map(
                                                            (val, index) => {
                                                              return (
                                                                <li key={index}>
                                                                  <p>
                                                                    $
                                                                    {val.cost.toFixed(
                                                                      0
                                                                    )}
                                                                  </p>
                                                                </li>
                                                              );
                                                            }
                                                          )}
                                                          {/* <li>
                                                      <p>$0.00</p>
                                                    </li>
                                                    <li>
                                                      <p>$0.00</p>
                                                    </li>
                                                    <li>
                                                      <p>$0.00</p>
                                                    </li>
                                                    <li>
                                                      <p>$0.00</p>
                                                    </li>
                                                    <li>
                                                      <p>$0.00</p>
                                                    </li> */}
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>
                                                Monthly Active User (breakup by
                                                countries)
                                              </span>
                                            </div>
                                            <div className="boxContent">
                                              <div
                                                className="uk-grid uk-flex-middle"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-3-5">
                                                  <div className="overviewChart">
                                                    {whatsappConversationBreakDownGraph !==
                                                      undefined && (
                                                      <DynamicLineChart
                                                        // datasets={Object.keys(
                                                        //   whatsappConversationBreakDownGraph
                                                        // ).map(
                                                        //   (key) =>
                                                        //     whatsappConversationBreakDownGraph[
                                                        //       key
                                                        //     ] !== "label"
                                                        // )}
                                                        datasets={Object.keys(
                                                          whatsappConversationBreakDownGraph
                                                        ).map(
                                                          (key) =>
                                                            whatsappConversationBreakDownGraph[
                                                              key
                                                            ]
                                                        )}
                                                        graphLabels={
                                                          whatsappConversationBreakDownGraph?.label
                                                        }
                                                        colors={
                                                          colorForMonthlyActiveUserBreakDown
                                                        }
                                                        labelsForHover={Object.keys(
                                                          whatsappConversationBreakDownGraph
                                                        )}
                                                      />
                                                    )}
                                                    {/* <LineChart
                                                dataSet1={stats?.instagram}
                                                dataSet2={stats?.facebook}
                                                dataSet3={stats?.whatsapp}
                                                graphLabels={whatsappConversationBreakDownGraph?.label}
                                              /> */}
                                                  </div>
                                                </div>
                                                <div className="uk-width-2-5">
                                                  <div className="chartRecordData">
                                                    <div className="chartRecordDataWrp">
                                                      <div className="deliveryRate">
                                                        <div
                                                          className="detailHeading"
                                                          style={{
                                                            marginBottom:
                                                              "24px",
                                                          }}
                                                        >
                                                          <p>COUNTRY</p>
                                                        </div>
                                                        <ul>
                                                          {/* {Object.keys(
                                                      whatsappConversationBreakDownGraph
                                                    ).map((val, index) => {
                                                      console.log(val)
                                                      console.log(whatsappConversationBreakDownGraph)
                                                      // if (whatsappConversationBreakDownGraph[val] !== "label") {
                                                      //   return (
                                                      //     <li key={index}>
                                                      //       <p>
                                                      //         <span className="darkGreenDot"></span>
                                                      //         {whatsappConversationBreakDownGraph[val][0]}
                                                      //       </p>
                                                      //     </li>
                                                      //   );
                                                      // }
                                                    })} */}
                                                          {whatsappConversationBreakDownGraph !==
                                                            undefined &&
                                                            Object.keys(
                                                              whatsappConversationBreakDownGraph
                                                            )?.map(
                                                              (val, index) => {
                                                                if (
                                                                  val !==
                                                                  "label"
                                                                ) {
                                                                  return (
                                                                    <li
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      <p>
                                                                        <span
                                                                          style={{
                                                                            backgroundColor:
                                                                              colorForMonthlyActiveUserBreakDown[
                                                                                index
                                                                              ],
                                                                          }}
                                                                        ></span>
                                                                        {val}
                                                                      </p>
                                                                    </li>
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                          {/* <li>
                                                      <p>
                                                        <span className="yellowDot"></span>
                                                        Jordan
                                                      </p>
                                                    </li> */}
                                                          {/*
                                                    <li>
                                                      <p>
                                                        <span className="lightGreenDot"></span>
                                                        Qatar
                                                      </p>
                                                    </li>
                                                    <li>
                                                      <p>
                                                        <span className="redDot"></span>
                                                        UAE
                                                      </p>
                                                    </li> */}
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="halfDonutSec">
                                            <div
                                              className="uk-grid uk-grid-small"
                                              uk-grid=""
                                              uk-height-match="target: > div > .halfDonutHeight"
                                            >
                                              <div className="uk-width-1-3">
                                                <div className="mainBox halfDonutHeight">
                                                  <div className="boxHeading">
                                                    <span>
                                                      Monthly Active User (by
                                                      user type)
                                                    </span>
                                                  </div>
                                                  <div className="boxContent">
                                                    <div className="chartWrp">
                                                      {parseInt(
                                                        whatsappMonthlyActiveUser?.newMau
                                                      ) +
                                                        parseInt(
                                                          whatsappMonthlyActiveUser?.returningMau
                                                        ) >
                                                      0 ? (
                                                        <DoughnutChart
                                                          degree={360}
                                                          backgroudColor={[
                                                            "#2F5711",
                                                            "#EDC843",
                                                          ]}
                                                          borderColor={[
                                                            "#2F5711",
                                                            "#EDC843",
                                                          ]}
                                                          graphData={[
                                                            whatsappMonthlyActiveUser?.newMau,
                                                            whatsappMonthlyActiveUser?.returningMau,
                                                          ]}
                                                          graphlabels={[
                                                            "New",
                                                            "Returning",
                                                          ]}
                                                        />
                                                      ) : (
                                                        <DoughnutChart
                                                          degree={360}
                                                          backgroudColor={[
                                                            "#B4B4B4",
                                                          ]}
                                                          borderColor={[
                                                            "#B4B4B4",
                                                          ]}
                                                          graphData={[1]}
                                                          graphlabels={["-"]}
                                                        />
                                                      )}
                                                      {/* <DynamicDoughnutChart
                                                  degree={360}
                                                  graphData={[
                                                    whatsappMonthlyActiveUser?.newMau,
                                                    whatsappMonthlyActiveUser?.returningMau,
                                                  ]}
                                                  
                                                  graphLabels={[
                                                    "New",
                                                    "Returning",
                                                  ]}
                                                  colors={generateColors(2)}
                                                /> */}

                                                      <div
                                                        className="chartData"
                                                        style={{
                                                          top: "36%",
                                                          left: "10px",
                                                        }}
                                                      >
                                                        <h3>
                                                          {convertIntoThousand(
                                                            whatsappMonthlyActiveUser?.newMau +
                                                              whatsappMonthlyActiveUser?.returningMau
                                                          )}
                                                        </h3>
                                                        <p>
                                                          Total Monthly <br />{" "}
                                                          Active Users
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="chartDataWrapper"
                                                      style={{
                                                        marginTop: "40px",
                                                      }}
                                                    >
                                                      <ul
                                                        style={{
                                                          width: "205px",
                                                        }}
                                                      >
                                                        <li>
                                                          <p>
                                                            <span className="darkGreen"></span>
                                                            New
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            {
                                                              whatsappMonthlyActiveUser?.newMau
                                                            }
                                                          </p>
                                                        </li>
                                                      </ul>
                                                      <ul
                                                        style={{
                                                          width: "205px",
                                                        }}
                                                      >
                                                        <li>
                                                          <p>
                                                            <span className="yellowDot"></span>
                                                            Returning
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            {
                                                              whatsappMonthlyActiveUser?.returningMau
                                                            }
                                                          </p>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="uk-width-2-3">
                                                <div className="mainBox halfDonutHeight">
                                                  <div className="boxHeading">
                                                    <span>
                                                      Monthly Active User
                                                    </span>
                                                  </div>
                                                  <div className="boxContent">
                                                    <LineChart
                                                      dataSet1={
                                                        whatsappMonthlyActiveUser?.data
                                                      }
                                                      graphLabels={
                                                        whatsappMonthlyActiveUser?.labels
                                                      }
                                                      colors={
                                                        colorForMonthlyActiveUser
                                                      }
                                                      labelsForHover={["MAU"]}
                                                    />
                                                    <div className="chartLabel">
                                                      <div className="labelItem">
                                                        <p>
                                                          <span className="lightGreenDot"></span>
                                                          Monthly Active Users
                                                          (MAU)
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>
                                                Frequency of Dialog Inquired -
                                                Chatbot
                                              </span>
                                            </div>
                                            <div className="boxContent">
                                              <div
                                                className="uk-grid uk-flex-middle"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-1-4">
                                                  <div className="chartWrp">
                                                    {/* <DoughnutChart
                                                degree={360}
                                                backgroudColor={[
                                                  "#29A869",
                                                  "#A8200D",
                                                  "#FFBFBD",
                                                ]}
                                                borderColor={[
                                                  "#29A869",
                                                  "#A8200D",
                                                  "#FFBFBD",
                                                ]}
                                                graphData={[12, 9, 5]}
                                              /> */}
                                                    {parseInt(
                                                      whatsappBotStats?.totalConversations
                                                    ) > 0 ? (
                                                      <DynamicDoughnutChart
                                                        degree={360}
                                                        graphData={
                                                          whatsappBotStats?.data
                                                        }
                                                        graphLabels={
                                                          whatsappBotStats?.labels
                                                        }
                                                        colors={
                                                          colorForBotStats
                                                        }
                                                      />
                                                    ) : (
                                                      <DoughnutChart
                                                        degree={360}
                                                        backgroudColor={[
                                                          "#B4B4B4",
                                                        ]}
                                                        borderColor={[
                                                          "#B4B4B4",
                                                        ]}
                                                        graphData={[1]}
                                                        graphlabels={["-"]}
                                                      />
                                                    )}
                                                    <div className="chartData">
                                                      <h3>
                                                        {whatsappBotStats?.totalConversations ===
                                                          undefined || null
                                                          ? 0
                                                          : whatsappBotStats?.totalConversations}
                                                      </h3>
                                                      <p>
                                                        {/* {
                                                    whatsappBotStats?.totalConversations
                                                  }{" "} */}
                                                        Total conversations
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="uk-width-3-4">
                                                  <div className="chartRecordData">
                                                    <div className="chartRecordDataWrp">
                                                      <div className="deliveryRate">
                                                        <ul>
                                                          {Object.keys(
                                                            whatsappBotStats?.window
                                                          ).length > 0 &&
                                                            Object.keys(
                                                              whatsappBotStats?.window
                                                            ).map(
                                                              (val, index) => {
                                                                return (
                                                                  <li
                                                                    key={index}
                                                                  >
                                                                    <p>
                                                                      <span
                                                                        style={{
                                                                          backgroundColor:
                                                                            colorForBotStats[
                                                                              index
                                                                            ],
                                                                        }}
                                                                      ></span>
                                                                      Menu:{" "}
                                                                      {val}
                                                                    </p>
                                                                  </li>
                                                                );
                                                              }
                                                            )}
                                                          {/* <li>
                                                      <p>
                                                        <span className="darkGreenDot"></span>
                                                        Menu
                                                      </p>
                                                    </li>
                                                    <li>
                                                      <p>
                                                        <span className="yellowDot"></span>
                                                        Menu: Sales
                                                      </p>
                                                    </li>
                                                    <li>
                                                      <p>
                                                        <span className="lightGreenDot"></span>
                                                        Menu: Services
                                                      </p>
                                                    </li>
                                                    <li>
                                                      <p>
                                                        <span className="redDot"></span>
                                                        Menu: Talk with Agent
                                                      </p>
                                                    </li>
                                                    <li>
                                                      <p>
                                                        <span className="darkOrange"></span>
                                                        Menu: Talk with Agent
                                                      </p>
                                                    </li>
                                                    <li>
                                                      <p>
                                                        <span className="pinkDot"></span>
                                                        Menu: Deals and Discount
                                                      </p>
                                                    </li> */}
                                                        </ul>
                                                      </div>
                                                      <div className="progressBar">
                                                        <ul>
                                                          {Object.keys(
                                                            whatsappBotStats?.window
                                                          ).length > 0 &&
                                                            Object.keys(
                                                              whatsappBotStats?.window
                                                            ).map(
                                                              (val, index) => {
                                                                // console.log(whatsappBotStats?.window[val])

                                                                const sum =
                                                                  whatsappBotStats?.window[
                                                                    val
                                                                  ]?.reduce(
                                                                    (
                                                                      sum,
                                                                      item
                                                                    ) =>
                                                                      sum +
                                                                      item.totalChats,
                                                                    0
                                                                  );

                                                                // whatsappBotStats?.window[val].map((item, innerIndex)=>{
                                                                //   return(

                                                                //   )
                                                                // })
                                                                return (
                                                                  // <li key={index} style={{margin:"0px"}}>
                                                                  <li
                                                                    key={index}
                                                                  >
                                                                    <progress
                                                                      key={
                                                                        index
                                                                      }
                                                                      className="uk-progress "
                                                                      value={
                                                                        sum
                                                                      }
                                                                      max={
                                                                        whatsappBotStats.totalConversations
                                                                      }
                                                                    ></progress>

                                                                    {/* <progress
                                                              className="uk-progress "
                                                              style={{
                                                                backgroundColor:
                                                                  sum > 0
                                                                    ? colorForBotStats[
                                                                        index
                                                                      ]
                                                                    : "#f8f8f8",
                                                              }}
                                                              value={sum}
                                                              max={
                                                                whatsappBotStats.totalConversations
                                                              }
                                                              // max={
                                                              //   10
                                                              // }
                                                            ></progress> */}
                                                                  </li>
                                                                );
                                                              }
                                                            )}
                                                        </ul>
                                                        {/* <ul>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressDarkGreen"
                                                              value="50"
                                                              max="100"
                                                            ></progress>
                                                          </li>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressYellow"
                                                              value="50"
                                                              max="100"
                                                            ></progress>
                                                          </li>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressLightGreen"
                                                              value="20"
                                                              max="100"
                                                            ></progress>
                                                          </li>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressHigh"
                                                              value="10"
                                                              max="100"
                                                            ></progress>
                                                          </li>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressDarkOrange"
                                                              value="10"
                                                              max="100"
                                                            ></progress>
                                                          </li>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressError"
                                                              value="10"
                                                              max="100"
                                                            ></progress>
                                                          </li>
                                                        </ul> */}
                                                      </div>
                                                      <div className="percentageData">
                                                        <ul>
                                                          {Object.keys(
                                                            whatsappBotStats?.window
                                                          ).length > 0 &&
                                                            Object.keys(
                                                              whatsappBotStats?.window
                                                            ).map(
                                                              (val, index) => {
                                                                const sum =
                                                                  whatsappBotStats?.window[
                                                                    val
                                                                  ]?.reduce(
                                                                    (
                                                                      sum,
                                                                      item
                                                                    ) =>
                                                                      sum +
                                                                      item.totalChats,
                                                                    0
                                                                  );
                                                                return (
                                                                  <li
                                                                    key={index}
                                                                  >
                                                                    <p>
                                                                      {whatsappBotStats.totalConversations >
                                                                      0
                                                                        ? (
                                                                            (sum /
                                                                              whatsappBotStats.totalConversations) *
                                                                            100
                                                                          ).toFixed(
                                                                            2
                                                                          ) +
                                                                          "%"
                                                                        : "0%"}
                                                                    </p>
                                                                  </li>
                                                                );
                                                              }
                                                            )}
                                                          {/* //{" "}
                                                    <li>
                                                      // <p>58.65%</p>
                                                      //{" "}
                                                    </li>
                                                    //{" "}
                                                    <li>
                                                      // <p>12.78%</p>
                                                      //{" "}
                                                    </li>
                                                    //{" "}
                                                    <li>
                                                      // <p>3.33%</p>
                                                      //{" "}
                                                    </li>
                                                    //{" "}
                                                    <li>
                                                      // <p>0.1%</p>
                                                      //{" "}
                                                    </li>
                                                    //{" "}
                                                    <li>
                                                      // <p>0.1%</p>
                                                      //{" "}
                                                    </li>
                                                    //{" "}
                                                    <li>
                                                      // <p>0.1%</p>
                                                      //{" "}
                                                    </li> */}
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="broadcastTabWrp whatsappBroadcastTab uk-padding-remove">
                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>
                                                WhatsApp Broadcast Summary
                                              </span>
                                            </div>
                                            <div className="boxContent">
                                              <div
                                                className="uk-grid"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-1-5 uk-first-column">
                                                  <div className="txtwrp">
                                                    <h2>
                                                      {whatsappBroadcasting ===
                                                      undefined ? (
                                                        <span uk-spinner=""></span>
                                                      ) : (
                                                        convertIntoThousand(
                                                          whatsappBroadcasting?.totalBroadCast
                                                        )
                                                      )}
                                                    </h2>
                                                    <span>
                                                      Total Broadcasts
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="uk-width-1-5">
                                                  <div className="txtwrp">
                                                    <h2>
                                                      {whatsappBroadcasting ===
                                                      undefined ? (
                                                        <span uk-spinner=""></span>
                                                      ) : (
                                                        convertIntoThousand(
                                                          whatsappBroadcasting?.messageDeliver
                                                        )
                                                      )}
                                                    </h2>
                                                    <span>
                                                      Messages Delivered
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="uk-width-1-5">
                                                  <div className="txtwrp">
                                                    <h2>
                                                      {whatsappBroadcasting ===
                                                      undefined ? (
                                                        <span uk-spinner=""></span>
                                                      ) : (
                                                        convertIntoThousand(
                                                          whatsappBroadcasting?.undeliveredMessage
                                                        )
                                                      )}
                                                    </h2>
                                                    <span>
                                                      Undelivered Messages
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="uk-width-1-5">
                                                  <div className="txtwrp">
                                                    <h2>
                                                      {whatsappBroadcasting ===
                                                      undefined ? (
                                                        <span uk-spinner=""></span>
                                                      ) : (
                                                        convertIntoThousand(
                                                          whatsappBroadcasting?.totalRead
                                                        )
                                                      )}
                                                    </h2>
                                                    <span>Messages Read</span>
                                                  </div>
                                                </div>
                                                <div className="uk-width-1-5">
                                                  <div className="txtwrp">
                                                    {/* <h2>94.6%</h2> */}
                                                    <h2>
                                                      {whatsappBroadcasting ===
                                                      undefined ? (
                                                        <span uk-spinner=""></span>
                                                      ) : isNaN(
                                                          whatsappBroadcasting?.deliverStatus
                                                        ) ? (
                                                        0
                                                      ) : (
                                                        whatsappBroadcasting?.deliverStatus.toLocaleString()
                                                      )}
                                                      {whatsappBroadcasting !==
                                                        undefined && "%"}
                                                    </h2>

                                                    <span>Delivery Status</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="searchFilterWrp mt-60">
                                            <div
                                              className="uk-grid uk-flex-middle"
                                              uk-grid=""
                                            >
                                              <div className="uk-width-1-2">
                                                <div className="searchFilter m-0">
                                                  {/* <form
                                              action=""
                                              onSubmit={(e) => {
                                                e.preventDefault();
                                              }}
                                            >
                                              <input
                                                type="text"
                                                placeholder="Search by broadcast name..."
                                              />
                                            </form> */}
                                                </div>
                                              </div>
                                              <div className="uk-width-1-2 btnSection">
                                                {/* <button className="exportBtn">
                                            <Download /> Export Data
                                          </button> */}

                                                {/* <div className="uk-inline">
                                            <button className="calenderBtn rangeBtn">
                                              <Calendar /> {selectedDateFilter}
                                              <span className="uk-inline">
                                                <ChevronDown />
                                              </span>
                                            </button>
                                            <div
                                              uk-dropdown="mode: click"
                                              className="calendarDropdown"
                                              id="dateDropdownSection"
                                            >
                                              <div className="dropDownHeading">
                                                <span>Select a range</span>
                                              </div>
                                              <ul>
                                                {filterDropdown.map(
                                                  (val, index) => {
                                                    return (
                                                      <>
                                                        <li
                                                          className={
                                                            selectedDateFilter ===
                                                            val
                                                              ? "activeBtn"
                                                              : ""
                                                          }
                                                          key={index}
                                                          onClick={() => {
                                                            if (
                                                              val !==
                                                              "Custom range"
                                                            ) {
                                                              clearAllWhatsaAppStates();
                                                            }
                                                            handleDateFilter(
                                                              val
                                                            );
                                                          }}
                                                        >
                                                          {filterDropdown.length -
                                                            1 ===
                                                          index ? (
                                                            <div className="uk-inline uk-text-left">
                                                              <button
                                                                className="rangeBtn"
                                                                type="button"
                                                              >
                                                                <span className="rangeBtnCircle"></span>{" "}
                                                                Custom range
                                                              </button>
                                                              <div
                                                                id="datepickerdrop"
                                                                uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                                className="date-picker-container"
                                                              >
                                                                <CalendarDate
                                                                  onChange={
                                                                    onChange
                                                                  }
                                                                />
                                                                <div className="date-picker-btn-container uk-text-right">
                                                                  <button
                                                                    className="date-selection-btn"
                                                                    onClick={
                                                                      handleDatePickerClose
                                                                    }
                                                                  >
                                                                    Ok
                                                                  </button>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            <button
                                                              type="button"
                                                              className="rangeBtn"
                                                            >
                                                              <span className="rangeBtnCircle"></span>{" "}
                                                              {val}
                                                            </button>
                                                          )}
                                                        </li>
                                                        {filterDropdown.length -
                                                          2 ===
                                                          index && (
                                                          <hr
                                                            style={{
                                                              margin: "5px 0",
                                                            }}
                                                          />
                                                        )}
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          </div> */}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="analyticsTableWrp tableCenter">
                                            <div
                                              className="uk-grid"
                                              uk-grid=""
                                            ></div>
                                            <table className="uk-table">
                                              <thead>
                                                <tr>
                                                  <th>SERIES</th>
                                                  <th>
                                                    <div className="uk-inline tableDropDown">
                                                      <button type="button">
                                                        ALL CHANNELS
                                                      </button>
                                                    </div>
                                                  </th>
                                                  <th>BROADCAST NAME</th>
                                                  <th>TRAFFIC</th>
                                                  <th>DELIVERY RATE</th>
                                                  <th>
                                                    <div className="uk-inline tableDropDown">
                                                      <button type="button">
                                                        STATUS
                                                      </button>
                                                    </div>
                                                  </th>
                                                  <th>CREATED BY</th>
                                                  <th>SCHEDULED</th>
                                                  {/* <th>COMPLETED</th> */}
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {whatsappBroadcasting !==
                                                undefined ? (
                                                  // whatsappBroadcasting?.broadCastSummaries.map(
                                                  currentTableData?.map(
                                                    (val, index) => {
                                                      return (
                                                        <tr>
                                                          <td>{index + 1}</td>
                                                          <td>
                                                            <img
                                                              src={whatsappLogo}
                                                              alt=""
                                                            />
                                                          </td>
                                                          <td className="agnetName">
                                                            {/* <Link to="/broadcast-analytics"> */}
                                                            {val.name}
                                                            {/* </Link> */}
                                                          </td>
                                                          <td>
                                                            {val.traffic ===
                                                            null
                                                              ? 0
                                                              : val.traffic.toLocaleString()}
                                                          </td>
                                                          <td>100% Delivery</td>
                                                          <td>
                                                            {val.status ===
                                                            "COMPLETED" ? (
                                                              <span className="serviceInitiated">
                                                                Delivered
                                                              </span>
                                                            ) : val.status ===
                                                              "CANCELLED" ? (
                                                              <span className="broadcastFailed">
                                                                <WarningAltFilled />{" "}
                                                                Failed
                                                              </span>
                                                            ) : val.status ===
                                                              "PENDING" ? (
                                                              <span className="inProcessYellow">
                                                                In process
                                                              </span>
                                                            ) : val.status ===
                                                              "SCHEDULED" ? (
                                                              <span className="inProcess">
                                                                Scheduled
                                                              </span>
                                                            ) : null}
                                                          </td>
                                                          <td className="agnetName">
                                                            Usama
                                                          </td>
                                                          <td>
                                                            {" "}
                                                            {val?.startDate +
                                                              " " +
                                                              val?.startTime}
                                                          </td>
                                                          {/* <td>11 Aug</td> */}
                                                          {/* <td className="broadcastTableBtn">
                                                      <button type="button">
                                                        View
                                                      </button>
                                                    </td> */}
                                                          {/* <td>
                                                      <div className="uk-inline">
                                                        <button type="button">
                                                          <OverflowMenuVertical />
                                                        </button>
                                                        <div
                                                          uk-dropdown=""
                                                          className="contactBlckDrop"
                                                        >
                                                          <ul>
                                                            <li>
                                                              <button type="button">
                                                                <DocumentView />{" "}
                                                                View
                                                              </button>
                                                            </li>
                                                            <li>
                                                              <button type="button">
                                                                <Download />{" "}
                                                                Download data
                                                              </button>
                                                            </li>
                                                            <hr />
                                                            <li>
                                                              <button
                                                                className="trashBtn"
                                                                type="button"
                                                              >
                                                                <TrashCan />{" "}
                                                                Delete
                                                              </button>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </td> */}
                                                        </tr>
                                                      );
                                                    }
                                                  )
                                                ) : (
                                                  <tr>
                                                    <td
                                                      colSpan={10}
                                                      className="dataNotFound"
                                                    >
                                                      {whatsappBroadcasting ===
                                                      undefined ? (
                                                        <span uk-spinner=""></span>
                                                      ) : (
                                                        "Data Not Found"
                                                      )}
                                                    </td>
                                                  </tr>
                                                )}
                                                {/* <tr>
                                            <td>1</td>
                                            <td>
                                              <img src={whatsappLogo} alt="" />
                                            </td>
                                            <td className="agnetName">
                                              <Link to="/broadcast-analytics">
                                                Weekend Mega Sales! 78% Off!
                                              </Link>
                                            </td>
                                            <td>200,343</td>
                                            <td>100% Delivery</td>
                                            <td>
                                              <span className="serviceInitiated">
                                                Delivered
                                              </span>
                                            </td>
                                            <td className="agnetName">Usama</td>
                                            <td>12 Aug</td>
                                            <td>11 Aug</td>
                                            <td className="broadcastTableBtn">
                                              <button type="button">
                                                View
                                              </button>
                                            </td>
                                            <td>
                                              <div className="uk-inline">
                                                <button type="button">
                                                  <OverflowMenuVertical />
                                                </button>
                                                <div
                                                  uk-dropdown=""
                                                  className="contactBlckDrop"
                                                >
                                                  <ul>
                                                    <li>
                                                      <button type="button">
                                                        <DocumentView /> View
                                                      </button>
                                                    </li>
                                                    <li>
                                                      <button type="button">
                                                        <Download /> Download
                                                        data
                                                      </button>
                                                    </li>
                                                    <hr />
                                                    <li>
                                                      <button
                                                        className="trashBtn"
                                                        type="button"
                                                      >
                                                        <TrashCan /> Delete
                                                      </button>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>1</td>
                                            <td>
                                              <img src={whatsappLogo} alt="" />
                                            </td>
                                            <td className="agnetName">
                                              Weekend Mega Sales! 78% Off!
                                            </td>
                                            <td>200,343</td>
                                            <td>100% Delivery</td>
                                            <td>
                                              <span className="inProcess">
                                                Scheduled
                                              </span>
                                            </td>
                                            <td className="agnetName">Usama</td>
                                            <td>12 Aug</td>
                                            <td>11 Aug</td>
                                            <td className="broadcastTableBtn">
                                              <button type="button">
                                                Pause
                                              </button>
                                            </td>
                                            <td>
                                              <div className="uk-inline">
                                                <button type="button">
                                                  <OverflowMenuVertical />
                                                </button>
                                                <div
                                                  uk-dropdown=""
                                                  className="contactBlckDrop"
                                                >
                                                  <ul>
                                                    <li>
                                                      <button type="button">
                                                        <DocumentView /> View
                                                      </button>
                                                    </li>
                                                    <li>
                                                      <button type="button">
                                                        <Download /> Download
                                                        data
                                                      </button>
                                                    </li>
                                                    <hr />
                                                    <li>
                                                      <button
                                                        className="trashBtn"
                                                        type="button"
                                                      >
                                                        <TrashCan /> Delete
                                                      </button>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>1</td>
                                            <td>
                                              <img src={whatsappLogo} alt="" />
                                            </td>
                                            <td className="agnetName">
                                              Weekend Mega Sales! 78% Off!
                                            </td>
                                            <td>200,343</td>
                                            <td>100% Delivery</td>
                                            <td>
                                              <span className="inProcessYellow">
                                                In process
                                              </span>
                                            </td>
                                            <td className="agnetName">Usama</td>
                                            <td>12 Aug</td>
                                            <td>11 Aug</td>
                                            <td className="broadcastTableBtn">
                                              <button type="button">
                                                Edit
                                              </button>
                                            </td>
                                            <td>
                                              <div className="uk-inline">
                                                <button type="button">
                                                  <OverflowMenuVertical />
                                                </button>
                                                <div
                                                  uk-dropdown=""
                                                  className="contactBlckDrop"
                                                >
                                                  <ul>
                                                    <li>
                                                      <button type="button">
                                                        <DocumentView /> View
                                                      </button>
                                                    </li>
                                                    <li>
                                                      <button type="button">
                                                        <Download /> Download
                                                        data
                                                      </button>
                                                    </li>
                                                    <hr />
                                                    <li>
                                                      <button
                                                        className="trashBtn"
                                                        type="button"
                                                      >
                                                        <TrashCan /> Delete
                                                      </button>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>1</td>
                                            <td>
                                              <img src={whatsappLogo} alt="" />
                                            </td>
                                            <td className="agnetName">
                                              Weekend Mega Sales! 78% Off!
                                            </td>
                                            <td>200,343</td>
                                            <td>100% Delivery</td>
                                            <td>
                                              <span className="broadcastFailed">
                                                <WarningAltFilled /> Failed
                                              </span>
                                            </td>
                                            <td className="agnetName">Usama</td>
                                            <td>12 Aug</td>
                                            <td>11 Aug</td>
                                            <td className="broadcastTableBtn">
                                              <button type="button">
                                                Stop
                                              </button>
                                            </td>
                                            <td>
                                              <div className="uk-inline">
                                                <button type="button">
                                                  <OverflowMenuVertical />
                                                </button>
                                                <div
                                                  uk-dropdown=""
                                                  className="contactBlckDrop"
                                                >
                                                  <ul>
                                                    <li>
                                                      <button type="button">
                                                        <DocumentView /> View
                                                      </button>
                                                    </li>
                                                    <li>
                                                      <button type="button">
                                                        <Download /> Download
                                                        data
                                                      </button>
                                                    </li>
                                                    <hr />
                                                    <li>
                                                      <button
                                                        className="trashBtn"
                                                        type="button"
                                                      >
                                                        <TrashCan /> Delete
                                                      </button>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </td>
                                          </tr> */}
                                              </tbody>
                                            </table>
                                            {whatsappBroadcasting !==
                                              undefined && (
                                              <div
                                                style={{ marginRight: "50px" }}
                                              >
                                                <Pagination
                                                  className="pagination-bar"
                                                  currentPage={currentPage}
                                                  totalCount={
                                                    whatsappBroadcasting
                                                      ?.broadCastSummaries
                                                      .length
                                                  }
                                                  pageSize={PageSize}
                                                  onPageChange={(page) =>
                                                    setCurrentPage(page)
                                                  }
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {channel === "facebook" && (
                      <div className="overviewContent">
                        <div className="uk-container">
                          <div className="overviewTopDropdown">
                            <div className="uk-grid uk-flex-middle" uk-grid="">
                              <div className="uk-width-1-2">
                                <div className="allChannelDropDown">
                                  <div className="uk-inline tableFilterDropdown broadcastChannelFilter">
                                    <button
                                      type="button"
                                      className="dropdownBtn"
                                    >
                                      {channel === "all"
                                        ? "All Active Channels"
                                        : channel}{" "}
                                      <ChevronDown />
                                    </button>
                                    <div
                                      uk-dropdown="mode: click"
                                      className="dropdownNav"
                                    >
                                      <span className="dropdownHeading">
                                        Channels
                                      </span>
                                      <ul>
                                        {channels.map((val, index) => {
                                          return (
                                            <li
                                              key={index}
                                              className={
                                                channel === val.apiSendName
                                                  ? "activeBtn"
                                                  : ""
                                              }
                                              onClick={() => {
                                                setIsSelected(false);
                                                setChannel(val.apiSendName);
                                              }}
                                            >
                                              <button
                                                type="button"
                                                disabled={
                                                  val.disabled === true
                                                    ? true
                                                    : false
                                                }
                                                className={
                                                  val.disabled === true
                                                    ? "opactityBtn"
                                                    : ""
                                                }
                                              >
                                                {index !== 0 && (
                                                  <img src={val.icon} alt="" />
                                                )}
                                                {val.name}
                                              </button>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="uk-width-1-2 btnSection">
                                {/* <button className="exportBtn">
                            <Download /> Export Data
                          </button> */}

                                <div className="uk-inline">
                                  <button className="calenderBtn rangeBtn">
                                    <Calendar /> {selectedDateFilter}
                                    <span className="uk-inline">
                                      <ChevronDown />
                                    </span>
                                  </button>
                                  <div
                                    uk-dropdown="mode: click"
                                    className="calendarDropdown"
                                    id="dateDropdownSection"
                                  >
                                    <div className="dropDownHeading">
                                      <span>Select a range</span>
                                    </div>

                                    <ul>
                                      {filterDropdown.map((val, index) => {
                                        return (
                                          <>
                                            <li
                                              className={
                                                selectedDateFilter === val
                                                  ? "activeBtn"
                                                  : ""
                                              }
                                              key={index}
                                              onClick={() => {
                                                if (val !== "Custom range") {
                                                  clearAllFacebookStates();
                                                }

                                                handleDateFilter(val);
                                              }}
                                            >
                                              {filterDropdown.length - 1 ===
                                              index ? (
                                                <div className="uk-inline uk-text-left">
                                                  <button
                                                    className="rangeBtn"
                                                    type="button"
                                                  >
                                                    <span className="rangeBtnCircle"></span>{" "}
                                                    Custom range
                                                  </button>
                                                  <div
                                                    id="datepickerdropWhatsapp"
                                                    uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                    className="date-picker-container"
                                                  >
                                                    <CalendarDate
                                                      onChange={onChange}
                                                    />
                                                    <div className="date-picker-btn-container uk-text-right">
                                                      <button
                                                        className="date-selection-btn"
                                                        onClick={
                                                          handleDatePickerCloseWhatsapp
                                                        }
                                                      >
                                                        Ok
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="rangeBtn"
                                                >
                                                  <span className="rangeBtnCircle"></span>{" "}
                                                  {val}
                                                </button>
                                              )}
                                            </li>
                                            {filterDropdown.length - 2 ===
                                              index && (
                                              <hr style={{ margin: "5px 0" }} />
                                            )}
                                          </>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="uk-width-1-1 uk-margin-remove-top">
                                <div className="analyticsTabWrp">
                                  <div className="whatappTabBtn">
                                    <ul
                                      className="uk-subnav uk-subnav-pill"
                                      uk-switcher="connect: .whatsappTabBtn"
                                    >
                                      <li>
                                        <a href="#">Analytics</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="analyticsWhatsappContent">
                                    <ul className="uk-switcher uk-margin whatsappTabBtn">
                                      <li>
                                        <div className="overviewMainContent">
                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>Facebook overview</span>
                                            </div>
                                            <div className="boxContent">
                                              <div
                                                className="uk-grid"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-1-1">
                                                  <div className="overviewChart">
                                                    {/* <LineChart
                                                dataSet1={stats?.whatsapp}
                                                dataSet2={stats?.facebook}
                                                dataSet3={stats?.instagram}
                                                graphLabels={graphLabels}
                                                labelsForHover={[
                                                  "Whatsapp",
                                                  "Facebook",
                                                  "Instagram",
                                                ]}
                                              /> */}
                                                    {newStats !== undefined && (
                                                      <DynamicLineChart
                                                        datasets={Object.keys(
                                                          newStats
                                                        ).map(
                                                          (key) =>
                                                            newStats[key]
                                                              .conversations
                                                        )}
                                                        graphLabels={
                                                          newStats[
                                                            Object.keys(
                                                              newStats
                                                            )[0]
                                                          ].labels
                                                        }
                                                        colors={
                                                          colorForAllChannels
                                                        }
                                                        labelsForHover={Object.keys(
                                                          newStats
                                                        )}
                                                      />
                                                    )}
                                                  </div>
                                                  <div className="chartLabel">
                                                    {companyDetails !==
                                                    undefined ? (
                                                      findChannelExist(
                                                        companyDetails?.activeChannels,
                                                        "FACEBOOK"
                                                      ) ? (
                                                        <div className="labelItem">
                                                          <img
                                                            src={messengerIcon}
                                                            alt=""
                                                          />
                                                          <p>
                                                            Messanger (
                                                            {
                                                              graphCount?.facebook
                                                            }
                                                            )
                                                          </p>
                                                        </div>
                                                      ) : null
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>
                                                Business Initiated Messages
                                                Status Breakdown
                                              </span>
                                            </div>
                                            <div className="boxContent">
                                              <div
                                                className="uk-grid uk-flex-middle"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-1-4">
                                                  <div className="chartWrp">
                                                    <DoughnutChart
                                                      degree={360}
                                                      backgroudColor={[
                                                        "#2F5711",
                                                        "#EDC843",
                                                        "#A8200D",
                                                        "#B4B4B4",
                                                      ]}
                                                      borderColor={[
                                                        "#2F5711",
                                                        "#EDC843",
                                                        "#A8200D",
                                                        "#B4B4B4",
                                                      ]}
                                                      graphData={[
                                                        facebookBusinessInitiatedBreakdown?.delivered,
                                                        facebookBusinessInitiatedBreakdown?.pending,
                                                        facebookBusinessInitiatedBreakdown?.failed,
                                                        facebookBusinessInitiatedBreakdown?.systemError,
                                                      ]}
                                                      graphlabels={[
                                                        "Delivered",
                                                        "Pending",
                                                        "Failed",
                                                        "System Error",
                                                      ]}
                                                    />
                                                    <div className="chartData">
                                                      <h3>8M 58S</h3>
                                                      <p>
                                                        {
                                                          facebookBusinessInitiatedBreakdown?.totalMessages
                                                        }{" "}
                                                        Total Messages
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="uk-width-3-4">
                                                  <div className="chartRecordData">
                                                    <div className="chartRecordDataWrp">
                                                      <div className="deliveryRate">
                                                        <ul>
                                                          <li>
                                                            <p>
                                                              <span className="darkGreenDot"></span>
                                                              Delivered
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="yellowDot"></span>
                                                              Pending
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="redDot"></span>
                                                              Failed
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="grayDot"></span>
                                                              System Error
                                                            </p>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                      <div className="percentageData">
                                                        <ul>
                                                          <li>
                                                            <p>
                                                              {
                                                                facebookBusinessInitiatedBreakdown?.delivered
                                                              }
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              {
                                                                facebookBusinessInitiatedBreakdown?.pending
                                                              }
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              {
                                                                facebookBusinessInitiatedBreakdown?.failed
                                                              }
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              {
                                                                facebookBusinessInitiatedBreakdown?.systemError
                                                              }
                                                            </p>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                      <div className="progressBar">
                                                        <ul>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressDarkGreen"
                                                              value={
                                                                facebookBusinessInitiatedBreakdown?.delivered
                                                              }
                                                              max={
                                                                facebookBusinessInitiatedBreakdown?.totalMessages
                                                              }
                                                            ></progress>
                                                          </li>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressYellow"
                                                              value={
                                                                facebookBusinessInitiatedBreakdown?.pending
                                                              }
                                                              max={
                                                                facebookBusinessInitiatedBreakdown?.totalMessages
                                                              }
                                                            ></progress>
                                                          </li>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressHigh"
                                                              value={
                                                                facebookBusinessInitiatedBreakdown?.failed
                                                              }
                                                              max={
                                                                facebookBusinessInitiatedBreakdown?.totalMessages
                                                              }
                                                            ></progress>
                                                          </li>
                                                          <li>
                                                            <progress
                                                              className="uk-progress progressGray"
                                                              value={
                                                                facebookBusinessInitiatedBreakdown?.systemError
                                                              }
                                                              max={
                                                                facebookBusinessInitiatedBreakdown?.totalMessages
                                                              }
                                                            ></progress>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                      <div className="percentageData">
                                                        <ul>
                                                          <li>
                                                            <p>
                                                              {facebookBusinessInitiatedBreakdown?.totalMessageDeliver >
                                                              0
                                                                ? (
                                                                    (facebookBusinessInitiatedBreakdown?.delivered /
                                                                      facebookBusinessInitiatedBreakdown?.totalMessageDeliver) *
                                                                    100
                                                                  ).toFixed(2) +
                                                                  "%"
                                                                : "0%"}
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              {facebookBusinessInitiatedBreakdown?.totalMessageDeliver >
                                                              0
                                                                ? (
                                                                    (facebookBusinessInitiatedBreakdown?.pending /
                                                                      facebookBusinessInitiatedBreakdown?.totalMessageDeliver) *
                                                                    100
                                                                  ).toFixed(2) +
                                                                  "%"
                                                                : "0%"}
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              {facebookBusinessInitiatedBreakdown?.totalMessageDeliver >
                                                              0
                                                                ? (
                                                                    (facebookBusinessInitiatedBreakdown?.failed /
                                                                      facebookBusinessInitiatedBreakdown?.totalMessageDeliver) *
                                                                    100
                                                                  ).toFixed(2) +
                                                                  "%"
                                                                : "0%"}
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              {facebookBusinessInitiatedBreakdown?.totalMessageDeliver >
                                                              0
                                                                ? (
                                                                    (facebookBusinessInitiatedBreakdown?.systemError /
                                                                      facebookBusinessInitiatedBreakdown?.totalMessageDeliver) *
                                                                    100
                                                                  ).toFixed(2) +
                                                                  "%"
                                                                : "0%"}
                                                            </p>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>
                                                Monthly Active User (breakup by
                                                countries)
                                              </span>
                                            </div>
                                            <div className="boxContent">
                                              <div
                                                className="uk-grid uk-flex-middle"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-3-5">
                                                  <div className="overviewChart">
                                                    {whatsappConversationBreakDownGraph !==
                                                      undefined && (
                                                      <DynamicLineChart
                                                        // datasets={Object.keys(
                                                        //   whatsappConversationBreakDownGraph
                                                        // ).map(
                                                        //   (key) =>
                                                        //     whatsappConversationBreakDownGraph[
                                                        //       key
                                                        //     ] !== "label"
                                                        // )}
                                                        datasets={Object.keys(
                                                          whatsappConversationBreakDownGraph
                                                        ).map(
                                                          (key) =>
                                                            whatsappConversationBreakDownGraph[
                                                              key
                                                            ]
                                                        )}
                                                        graphLabels={
                                                          whatsappConversationBreakDownGraph?.label
                                                        }
                                                        colors={
                                                          colorForMonthlyActiveUserBreakDown
                                                        }
                                                        labelsForHover={Object.keys(
                                                          whatsappConversationBreakDownGraph
                                                        )}
                                                      />
                                                    )}
                                                    {/* <LineChart
                                                dataSet1={stats?.instagram}
                                                dataSet2={stats?.facebook}
                                                dataSet3={stats?.whatsapp}
                                                graphLabels={whatsappConversationBreakDownGraph?.label}
                                              /> */}
                                                  </div>
                                                </div>
                                                <div className="uk-width-2-5">
                                                  <div className="chartRecordData">
                                                    <div className="chartRecordDataWrp">
                                                      <div className="deliveryRate">
                                                        <div
                                                          className="detailHeading"
                                                          style={{
                                                            marginBottom:
                                                              "24px",
                                                          }}
                                                        >
                                                          <p>COUNTRY</p>
                                                        </div>
                                                        <ul>
                                                          {/* {Object.keys(
                                                      whatsappConversationBreakDownGraph
                                                    ).map((val, index) => {
                                                      console.log(val)
                                                      console.log(whatsappConversationBreakDownGraph)
                                                      // if (whatsappConversationBreakDownGraph[val] !== "label") {
                                                      //   return (
                                                      //     <li key={index}>
                                                      //       <p>
                                                      //         <span className="darkGreenDot"></span>
                                                      //         {whatsappConversationBreakDownGraph[val][0]}
                                                      //       </p>
                                                      //     </li>
                                                      //   );
                                                      // }
                                                    })} */}
                                                          {whatsappConversationBreakDownGraph !==
                                                            undefined &&
                                                            Object.keys(
                                                              whatsappConversationBreakDownGraph
                                                            )?.map(
                                                              (val, index) => {
                                                                if (
                                                                  val !==
                                                                  "label"
                                                                ) {
                                                                  return (
                                                                    <li
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      <p>
                                                                        <span
                                                                          style={{
                                                                            backgroundColor:
                                                                              colorForMonthlyActiveUserBreakDown[
                                                                                index
                                                                              ],
                                                                          }}
                                                                        ></span>
                                                                        {val}
                                                                      </p>
                                                                    </li>
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                          {/* <li>
                                                      <p>
                                                        <span className="yellowDot"></span>
                                                        Jordan
                                                      </p>
                                                    </li> */}
                                                          {/*
                                                    <li>
                                                      <p>
                                                        <span className="lightGreenDot"></span>
                                                        Qatar
                                                      </p>
                                                    </li>
                                                    <li>
                                                      <p>
                                                        <span className="redDot"></span>
                                                        UAE
                                                      </p>
                                                    </li> */}
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="halfDonutSec">
                                            <div
                                              className="uk-grid"
                                              uk-grid=""
                                              uk-height-match="target: > div > .halfDonutHeight"
                                            >
                                              <div className="uk-width-1-3">
                                                <div className="mainBox halfDonutHeight">
                                                  <div className="boxHeading">
                                                    <span>
                                                      Monthly Active User (by
                                                      user type)
                                                    </span>
                                                  </div>
                                                  <div className="boxContent">
                                                    <div className="chartWrp">
                                                      <DoughnutChart
                                                        degree={360}
                                                        backgroudColor={[
                                                          "#2F5711",
                                                          "#EDC843",
                                                        ]}
                                                        borderColor={[
                                                          "#2F5711",
                                                          "#EDC843",
                                                        ]}
                                                        graphData={[
                                                          whatsappMonthlyActiveUser?.newMau,
                                                          whatsappMonthlyActiveUser?.returningMau,
                                                        ]}
                                                        graphlabels={[
                                                          "New",
                                                          "Returning",
                                                        ]}
                                                      />

                                                      {/* <DynamicDoughnutChart
                                                  degree={360}
                                                  graphData={[
                                                    whatsappMonthlyActiveUser?.newMau,
                                                    whatsappMonthlyActiveUser?.returningMau,
                                                  ]}
                                                  
                                                  graphLabels={[
                                                    "New",
                                                    "Returning",
                                                  ]}
                                                  colors={generateColors(2)}
                                                /> */}

                                                      <div
                                                        className="chartData"
                                                        style={{
                                                          top: "36%",
                                                          left: "10px",
                                                        }}
                                                      >
                                                        <h3>
                                                          {whatsappMonthlyActiveUser?.newMau +
                                                            whatsappMonthlyActiveUser?.returningMau}
                                                        </h3>
                                                        <p>
                                                          Total Monthly <br />{" "}
                                                          Active Users
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="chartDataWrapper"
                                                      style={{
                                                        marginTop: "40px",
                                                      }}
                                                    >
                                                      <ul
                                                        style={{
                                                          width: "205px",
                                                        }}
                                                      >
                                                        <li>
                                                          <p>
                                                            <span className="darkGreen"></span>
                                                            New
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            {
                                                              whatsappMonthlyActiveUser?.newMau
                                                            }
                                                          </p>
                                                        </li>
                                                      </ul>
                                                      <ul
                                                        style={{
                                                          width: "205px",
                                                        }}
                                                      >
                                                        <li>
                                                          <p>
                                                            <span className="yellowDot"></span>
                                                            Returning
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            {
                                                              whatsappMonthlyActiveUser?.returningMau
                                                            }
                                                          </p>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="uk-width-2-3">
                                                <div className="mainBox halfDonutHeight">
                                                  <div className="boxHeading">
                                                    <span>
                                                      Monthly Active User
                                                    </span>
                                                  </div>
                                                  <div className="boxContent">
                                                    <LineChart
                                                      dataSet1={
                                                        whatsappMonthlyActiveUser?.data
                                                      }
                                                      graphLabels={
                                                        whatsappMonthlyActiveUser?.labels
                                                      }
                                                      colors={
                                                        colorForMonthlyActiveUser
                                                      }
                                                      labelsForHover={["MAU"]}
                                                    />
                                                    <div className="chartLabel">
                                                      <div className="labelItem">
                                                        <p>
                                                          <span className="lightGreenDot"></span>
                                                          Monthly Active Users
                                                          (MAU)
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>
                                                Frequency of Dialog Inquired -
                                                Chatbot
                                              </span>
                                            </div>
                                            <div className="boxContent">
                                              <div
                                                className="uk-grid uk-flex-middle"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-1-4">
                                                  <div className="chartWrp">
                                                    {/* <DoughnutChart
                                                degree={360}
                                                backgroudColor={[
                                                  "#29A869",
                                                  "#A8200D",
                                                  "#FFBFBD",
                                                ]}
                                                borderColor={[
                                                  "#29A869",
                                                  "#A8200D",
                                                  "#FFBFBD",
                                                ]}
                                                graphData={[12, 9, 5]}
                                              /> */}

                                                    <DynamicDoughnutChart
                                                      degree={360}
                                                      graphData={
                                                        whatsappBotStats?.data
                                                      }
                                                      graphLabels={
                                                        whatsappBotStats?.labels
                                                      }
                                                      colors={colorForBotStats}
                                                    />

                                                    <div className="chartData">
                                                      <h3>8M 58S</h3>
                                                      <p>
                                                        {
                                                          whatsappBotStats?.totalConversations
                                                        }{" "}
                                                        Total conversations
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="uk-width-3-4">
                                                  <div className="chartRecordData">
                                                    <div className="chartRecordDataWrp">
                                                      <div className="deliveryRate">
                                                        <ul>
                                                          {Object.keys(
                                                            whatsappBotStats?.window
                                                          ).length > 0 &&
                                                            Object.keys(
                                                              whatsappBotStats?.window
                                                            ).map(
                                                              (val, index) => {
                                                                return (
                                                                  <li
                                                                    key={index}
                                                                  >
                                                                    <p>
                                                                      <span
                                                                        style={{
                                                                          backgroundColor:
                                                                            colorForBotStats[
                                                                              index
                                                                            ],
                                                                        }}
                                                                      ></span>
                                                                      Menu:{" "}
                                                                      {val}
                                                                    </p>
                                                                  </li>
                                                                );
                                                              }
                                                            )}
                                                          {/* <li>
                                                      <p>
                                                        <span className="darkGreenDot"></span>
                                                        Menu
                                                      </p>
                                                    </li>
                                                    <li>
                                                      <p>
                                                        <span className="yellowDot"></span>
                                                        Menu: Sales
                                                      </p>
                                                    </li>
                                                    <li>
                                                      <p>
                                                        <span className="lightGreenDot"></span>
                                                        Menu: Services
                                                      </p>
                                                    </li>
                                                    <li>
                                                      <p>
                                                        <span className="redDot"></span>
                                                        Menu: Talk with Agent
                                                      </p>
                                                    </li>
                                                    <li>
                                                      <p>
                                                        <span className="darkOrange"></span>
                                                        Menu: Talk with Agent
                                                      </p>
                                                    </li>
                                                    <li>
                                                      <p>
                                                        <span className="pinkDot"></span>
                                                        Menu: Deals and Discount
                                                      </p>
                                                    </li> */}
                                                        </ul>
                                                      </div>
                                                      <div className="progressBar">
                                                        <ul>
                                                          {Object.keys(
                                                            whatsappBotStats?.window
                                                          ).length > 0 &&
                                                            Object.keys(
                                                              whatsappBotStats?.window
                                                            ).map(
                                                              (val, index) => {
                                                                // console.log(whatsappBotStats?.window[val])

                                                                const sum =
                                                                  whatsappBotStats?.window[
                                                                    val
                                                                  ]?.reduce(
                                                                    (
                                                                      sum,
                                                                      item
                                                                    ) =>
                                                                      sum +
                                                                      item.totalChats,
                                                                    0
                                                                  );

                                                                // whatsappBotStats?.window[val].map((item, innerIndex)=>{
                                                                //   return(

                                                                //   )
                                                                // })
                                                                return (
                                                                  // <li key={index} style={{margin:"0px"}}>
                                                                  <li
                                                                    key={index}
                                                                    style={{
                                                                      marginBottom:
                                                                        "1.5px",
                                                                    }}
                                                                  >
                                                                    <CustomizedProgressBar
                                                                      key={
                                                                        index
                                                                      }
                                                                      value={
                                                                        sum
                                                                      }
                                                                      max={
                                                                        whatsappBotStats.totalConversations
                                                                      }
                                                                      color={
                                                                        colorForBotStats[
                                                                          index
                                                                        ]
                                                                      }
                                                                    />

                                                                    {/* <progress
                                                              className="uk-progress "
                                                              style={{
                                                                backgroundColor:
                                                                  sum > 0
                                                                    ? colorForBotStats[
                                                                        index
                                                                      ]
                                                                    : "#f8f8f8",
                                                              }}
                                                              value={sum}
                                                              max={
                                                                whatsappBotStats.totalConversations
                                                              }
                                                              // max={
                                                              //   10
                                                              // }
                                                            ></progress> */}
                                                                  </li>
                                                                );
                                                              }
                                                            )}

                                                          {/* <li>
                                                      <progress
                                                        className="uk-progress progressDarkGreen"
                                                        value="50"
                                                        max="100"
                                                      ></progress>
                                                    </li>
                                                    <li>
                                                      <progress
                                                        className="uk-progress progressYellow"
                                                        value="50"
                                                        max="100"
                                                      ></progress>
                                                    </li>
                                                    <li>
                                                      <progress
                                                        className="uk-progress progressLightGreen"
                                                        value="20"
                                                        max="100"
                                                      ></progress>
                                                    </li>
                                                    <li>
                                                      <progress
                                                        className="uk-progress progressHigh"
                                                        value="10"
                                                        max="100"
                                                      ></progress>
                                                    </li>
                                                    <li>
                                                      <progress
                                                        className="uk-progress progressDarkOrange"
                                                        value="10"
                                                        max="100"
                                                      ></progress>
                                                    </li>
                                                    <li>
                                                      <progress
                                                        className="uk-progress progressError"
                                                        value="10"
                                                        max="100"
                                                      ></progress>
                                                    </li> */}
                                                        </ul>
                                                      </div>
                                                      <div className="percentageData">
                                                        <ul>
                                                          {Object.keys(
                                                            whatsappBotStats?.window
                                                          ).length > 0 &&
                                                            Object.keys(
                                                              whatsappBotStats?.window
                                                            ).map(
                                                              (val, index) => {
                                                                const sum =
                                                                  whatsappBotStats?.window[
                                                                    val
                                                                  ]?.reduce(
                                                                    (
                                                                      sum,
                                                                      item
                                                                    ) =>
                                                                      sum +
                                                                      item.totalChats,
                                                                    0
                                                                  );
                                                                return (
                                                                  <li
                                                                    key={index}
                                                                  >
                                                                    <p>
                                                                      {whatsappBotStats.totalConversations >
                                                                      0
                                                                        ? (sum /
                                                                            whatsappBotStats.totalConversations) *
                                                                            100 +
                                                                          "%"
                                                                        : "0%"}
                                                                    </p>
                                                                  </li>
                                                                );
                                                              }
                                                            )}
                                                          {/* //{" "}
                                                    <li>
                                                      // <p>58.65%</p>
                                                      //{" "}
                                                    </li>
                                                    //{" "}
                                                    <li>
                                                      // <p>12.78%</p>
                                                      //{" "}
                                                    </li>
                                                    //{" "}
                                                    <li>
                                                      // <p>3.33%</p>
                                                      //{" "}
                                                    </li>
                                                    //{" "}
                                                    <li>
                                                      // <p>0.1%</p>
                                                      //{" "}
                                                    </li>
                                                    //{" "}
                                                    <li>
                                                      // <p>0.1%</p>
                                                      //{" "}
                                                    </li>
                                                    //{" "}
                                                    <li>
                                                      // <p>0.1%</p>
                                                      //{" "}
                                                    </li> */}
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                ) : (
                  <li key={index}></li>
                )
              ) : null;
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default NewAnalytics;
