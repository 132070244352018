import React, { useEffect, useState } from "react";
import {
  formatText,
  sanitizedMessage,
} from "../../../Utils/chat/chatFunctions";
import { ChevronLeft, SendAltFilled } from "@carbon/icons-react";
import { apiPost } from "../../../Utils/apiServices";
import $ from "jquery";
import { decryptedValue } from "../../../Utils/RouteHashConversion";
import { useAuth } from "../../../Context/AuthProvider";
import { useOutletContext } from "react-router-dom";

const FacebookCommentMessage = ({
  fbCommentMessages,
  setFbCommentMessages,
  loadMoreComments,
  handleOpenMoreCommentSection,
  handleCloseMoreCommentSection,
  nestedComments,
  setRepliedToInfo,
  repliedToInfo,
  setNestedComments,
}) => {
  const { auth } = useAuth();
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const [firstParentComment, setFirsrParentComment] = useState(null);
  const [parentComment, setParentComment] = useState(null);
  const [repliedCommentIndex, setRepliedCommentIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dmLoading, setDmLoading] = useState(false);
  const [openDm, setOpenDm] = useState(false);
  const [dmDetails, setDmDetails] = useState();

  const sendMessage = () => {
    setLoading(true);
    let data;
    if (
      nestedComments === null
        ? document.getElementById("reply_box_parent").value !== ""
        : document.getElementById("reply_box").value !== ""
    ) {
      data = {
        postId: repliedToInfo.id,
        message:
          nestedComments === null
            ? document
                .getElementById("reply_box_parent")
                .value.replace(/\n+$/, "")
            : document.getElementById("reply_box").value.replace(/\n+$/, ""),
      };

      apiPost(
        `/facebook/comment`,
        onSuccesCommentSend,
        onFailureCommentSend,
        data
      );
    } else {
      setLoading(false);
    }
  };
  const onSuccesCommentSend = (response) => {
    if (response.status === 0) {
      setLoading(false);
      $("#reply_box").val("");
      $("#reply_box_parent").val("");
      if (nestedComments === null) {
        var tempData = [...fbCommentMessages];
        tempData[repliedCommentIndex].comment_count =
          tempData[repliedCommentIndex].comment_count + 1;
        setFbCommentMessages(tempData);
      } else {
        var tempData = [...nestedComments];
        tempData[repliedCommentIndex].comment_count =
          tempData[repliedCommentIndex].comment_count + 1;
        setNestedComments(tempData);
      }
      setRepliedToInfo(null);
    }
  };
  const onFailureCommentSend = (error) => {
    setLoading(false);
    console.log(error);
  };

  const sendDMMessage = () => {
    setDmLoading(true);
    let data;
    if (
      nestedComments === null
        ? document.getElementById("dm_box_parent").value !== ""
        : document.getElementById("dm_box").value !== ""
    ) {
      data = {
        type: "text",
        phoneNumber: dmDetails.id,
        textBody:
          nestedComments === null
            ? document.getElementById("dm_box_parent").value
            : document.getElementById("dm_box").value,
        recipientNumber: companyDetails?.whatsappNumber,
        chanelId: "FACEBOOK",
        name: decryptedValue(auth.userName),
      };

      apiPost(`/chat/send-message`, onSuccesDMSend, onFailureDmSend, data);
    } else {
      setDmLoading(false);
    }
  };
  const onSuccesDMSend = (response) => {
    if (response.status === 1) {
      setTimeout(() => {
        setDmLoading(false);
        $("#dm_box").val("");
        $("#dm_box_parent").val("");
      }, 1500);
      // setTimeout(() => {
      //   setOpenDm(false);
      //   setDmDetails(null);
      // }, 4000);
    }
  };
  const onFailureDmSend = (error) => {
    setDmLoading(false);
    console.log(error);
  };
  useEffect(() => {
    if (repliedToInfo !== null) {
      if (nestedComments === null) {
        let commentIndex = fbCommentMessages
          .map((val) => {
            return val.id;
          })
          .indexOf(repliedToInfo.id);
        setRepliedCommentIndex(commentIndex);
        $("#reply_box_parent").val(
          $("#reply_box_parent").val() + repliedToInfo.from.name + " "
        );
      } else {
        $("#reply_box").val(
          $("#reply_box").val() + repliedToInfo.from.name + " "
        );
        let commentIndex = nestedComments
          .map((val) => {
            return val.id;
          })
          .indexOf(repliedToInfo.id);
        setRepliedCommentIndex(commentIndex);
      }
    }
  }, [repliedToInfo]);

  return (
    <div className="uk-width-4-5 uk-padding-remove">
      <div className="">
        <div className="main-chat">
          <div
            className="chat-msgs"
            style={{
              height: "calc(100vh -  289px)",
            }}
          >
            {fbCommentMessages?.map((val, index) => {
              const messageKey = `index-${index}`;
              const dateKey = `date-${messageKey}`;
              return (
                <React.Fragment key={messageKey}>
                  {/* {index == 0 && pageForChatMessages > 0
                    ? hasMoreMessages && (
                        <div className="date-and-time">
                          <div className="txtwrp">
                            <div uk-spinner=""></div>
                          </div>
                        </div>
                      )
                    : null} */}
                  {index == 0 && (
                    <div className="date-and-time" key={dateKey}>
                      <div className="txtwrp">
                        <p></p>
                      </div>
                    </div>
                  )}

                  {val.from !== null && (
                    <div className={"rec-msg"} key={messageKey}>
                      <div className="txtdiv">
                        <pre>
                          {formatText(val.from.name)}
                          <br />
                          <p className="message">{val.message}</p>
                        </pre>
                      </div>
                      <div className="txtdiv">
                        <div className="commentSection">
                          <div>
                            <button>Like count</button>
                            <span>{val.like_count}</span>
                          </div>
                          <div className="uk-margin-left">
                            <button
                              onClick={() => {
                                if (repliedToInfo === null) {
                                  setRepliedToInfo(val);
                                }
                                if (val.id !== repliedToInfo.id) {
                                  $("#reply_box_parent").val("");
                                  setRepliedToInfo(val);
                                }
                              }}
                            >
                              Reply
                            </button>
                            {val.can_reply_privately === true && (
                              <button
                                className="uk-margin-left"
                                onClick={() => {
                                  setDmDetails(val);
                                }}
                              >
                                Dm
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      {val.comment_count > 0 && (
                        <div className="txtdiv">
                          <button
                            className="viewMoreBtn"
                            type="button"
                            uk-toggle="target: #offcanvas-flip"
                            onClick={() => {
                              $("#reply_box_parent").val("");
                              setFirsrParentComment(val);
                              loadMoreComments(val.id);
                              handleOpenMoreCommentSection();
                              setRepliedCommentIndex(null);
                              setRepliedToInfo(null);
                              setDmDetails(null);
                              setParentComment(val);
                            }}
                          >
                            View {val.comment_count} more comments...
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          <div className="msgBox">
            <div className="msgBoxTabbing">
              <ul uk-tab="" className="tabBtn">
                <li>
                  <a href="/">Reply</a>
                </li>
                {dmDetails !== null && dmDetails !== undefined && (
                  <li>
                    <a href="/">DM Message</a>
                  </li>
                )}
              </ul>
              <ul className="uk-switcher uk-margin tabContent">
                <li className="chatMsgBox">
                  <div className="textBox">
                    <form action="">
                      <textarea
                        name="msg_box"
                        id="reply_box_parent"
                        placeholder={
                          "Enter to send and Ctrl + Enter to start a new line "
                        }
                        disabled={
                          repliedToInfo === null || loading ? true : false
                        }
                        cols="4"
                        rows="10"
                      ></textarea>
                    </form>
                  </div>
                  <div className="submitBtn msgBoxSubmitBtn">
                    <button type="button" onClick={() => sendDMMessage()}>
                      {loading ? "Sending . . " : "send"}
                    </button>
                  </div>
                </li>
                {(dmDetails !== null || dmDetails !== undefined) && (
                  <li className="chatMsgBox">
                    <div className="textBox">
                      <form action="">
                        <textarea
                          name="msg_box"
                          id="dm_box_parent"
                          placeholder={
                            "Enter to send and Ctrl + Enter to start a new line "
                          }
                          // disabled={
                          //   repliedToInfo === null || loading ? true : false
                          // }
                          cols="4"
                          rows="10"
                        ></textarea>
                      </form>
                    </div>
                    <div className="submitBtn msgBoxSubmitBtn">
                      <button type="button" onClick={() => sendDMMessage()}>
                        {dmLoading ? "Sending . . " : "send"}
                      </button>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/*for offcanvas display more comments here  */}
      <div
        id="offcanvas-flip"
        uk-offcanvas="flip: true; overlay: true; esc-close:false;bg-close: false"
      >
        <div className="uk-offcanvas-bar comtSection">
          <button
            className="uk-offcanvas-close commentCloseBtn"
            type="button"
            onClick={() => {
              setNestedComments(null);
              setOpenDm(false);
              setDmDetails(null);
              setRepliedCommentIndex(null);
              setRepliedToInfo(null);
              handleCloseMoreCommentSection();
            }}
            uk-close=""
          ></button>
          <h3>View Comments</h3>
          <div
            className="comt-box"
            // className={
            //   repliedToInfo === null ? "comt-box" : "comt-box comt-height"
            // }
          >
            {nestedComments !== null &&
              firstParentComment?.id !== parentComment?.id && (
                <button
                  className="backBtn"
                  onClick={() => {
                    loadMoreComments(firstParentComment?.id);
                    setNestedComments(null);
                    setRepliedToInfo(null);
                    setRepliedCommentIndex(null);
                    setParentComment(firstParentComment);
                  }}
                >
                  <ChevronLeft /> Back to parent comment
                </button>
              )}
            {nestedComments === null && (
              <div className="loaderScreen">
                <div
                  uk-spinner=""
                  className="loader"
                  style={{ color: "gray" }}
                ></div>
              </div>
            )}
            {nestedComments !== null &&
              parentComment !== null &&
              parentComment.from !== null && (
                <div className="rec-msg uk-margin-bottom ">
                  <div className="txtMsg">
                    <pre>
                      {formatText(parentComment.from.name)}
                      <br />
                      <p className="message">{parentComment.message}</p>
                    </pre>
                  </div>
                </div>
              )}
            {nestedComments !== null &&
              nestedComments?.map((val, index) => {
                return (
                  val.from !== null && (
                    <div className="rec-msg uk-margin-medium-left" key={index}>
                      <div className="txtMsg">
                        <pre
                          className={
                            index === repliedCommentIndex
                              ? "selectedComment"
                              : ""
                          }
                        >
                          {formatText(val.from.name)}
                          <br />
                          <p className="message">{val.message}</p>
                        </pre>
                      </div>
                      <div className="txtMsg">
                        <div className="commentSection">
                          <div className="likeBtn">
                            <button>Like count</button>
                            <span>{val.like_count}</span>
                          </div>
                          <div className="uk-margin-left likeBtn">
                            <button
                              onClick={() => {
                                if (repliedToInfo === null) {
                                  setRepliedToInfo(val);
                                }
                                if (val.id !== repliedToInfo.id) {
                                  $("#reply_box").val("");
                                  setRepliedToInfo(val);
                                }
                              }}
                            >
                              Reply
                            </button>
                            {val.can_reply_privately === true && (
                              <button
                                className="uk-margin-left"
                                onClick={() => {
                                  setDmDetails(val);
                                  setOpenDm(true);
                                }}
                              >
                                Dm
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      {val.comment_count > 0 && (
                        <div className="txtMsg">
                          <button
                            className="viewMoreBtn"
                            type="button"
                            onClick={() => {
                              loadMoreComments(val.id);
                              setNestedComments(null);
                              setRepliedToInfo(null);
                              setRepliedCommentIndex(null);
                              setParentComment(val);
                            }}
                          >
                            View {val.comment_count} more comments...
                          </button>
                        </div>
                      )}
                      <br />
                      {openDm && dmDetails.id === val.id && (
                        <div className="DmSection">
                          <div className="replyContainer uk-margin-left">
                            <div>
                              <div className="replyto">
                                Dm to <span>{dmDetails?.from.name}</span>
                                <button
                                  className="uk-margin-left"
                                  onClick={() => {
                                    setOpenDm(false);
                                    setDmDetails(null);
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                              <textarea
                                name="msg_box"
                                id="dm_box"
                                placeholder={
                                  "Enter to send and Ctrl + Enter to start a new line "
                                }
                                // disabled={loading ? true : false}
                                cols="4"
                                rows="3"
                              ></textarea>
                              <button onClick={() => sendDMMessage()}>
                                {dmLoading ? (
                                  "Sending . . ."
                                ) : (
                                  <SendAltFilled />
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                );
              })}

            {/* <div className="replyingSection">
              {repliedToInfo !== null && (
                <div className="replyContainer">
                  <div>
                    <div className="replyto">
                      Replying to <span>{repliedToInfo?.from.name}</span>
                      <button
                        className="uk-margin-left"
                        onClick={() => setRepliedToInfo(null)}
                      >
                        Cancel
                      </button>
                    </div>

                    <textarea
                      name="msg_box"
                      id="reply_box"
                      placeholder={
                        "Enter to send and Ctrl + Enter to start a new line "
                      }
                      cols="4"
                      rows="3"
                    ></textarea>
                    <button onClick={() => sendMessage()}>
                      <SendAltFilled />
                    </button>
                  </div>
                </div>
              )}
            </div> */}
          </div>
          <div className="replyingSection">
            {repliedToInfo !== null && (
              <div className="replyContainer">
                <div>
                  <div className="replyto">
                    Replying to <span>{repliedToInfo?.from.name}</span>
                    <button
                      className="uk-margin-left"
                      onClick={() => setRepliedToInfo(null)}
                    >
                      Cancel
                    </button>
                  </div>

                  <textarea
                    name="msg_box"
                    id="reply_box"
                    placeholder={
                      "Enter to send and Ctrl + Enter to start a new line "
                    }
                    // disabled={loading ? true : false}
                    cols="4"
                    rows="3"
                  ></textarea>
                  <button onClick={() => sendMessage()}>
                    {loading ? "Sending . . ." : <SendAltFilled />}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacebookCommentMessage;
