import React, { useState } from "react";
import whatsappLogo from "../../assets/images/wa.png";
import whatsappNewLogo from "../../assets/images/whatsapp-icon-new.jpg";
import emailIcon from "../../assets/images/ic_Integrations_email.png";
import smsIcon from "../../assets/images/ic_Integrations_sms.png";
import msgIcon from "../../assets/images/message-icon.svg";
import messengerIcon from "../../assets/images/messenger-icon.png";
import instaIcon from "../../assets/images/insta-icon.png";
import ChannelBox from "./components/ChannelBox/ChannelBox";
import speakerImg from "../../assets/images/speaker-img.svg";
import chatBotImg1 from "../../assets/images/chat-bot.svg";
import chatBotImg2 from "../../assets/images/chat-bot-1.svg";
import SolutionBox from "./components/SolutionBox/SolutionBox";
import { useEffect } from "react";
import { apiGet } from "../../Utils/apiServices";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import moment from "moment/moment";
import { useAuth } from "../../Context/AuthProvider";
import {
  decryptedValue,
  encryptedValue,
} from "../../Utils/RouteHashConversion";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import { toast } from "react-toastify";
import PackageModal from "./components/PackageModal";
import { channelImages } from "../../Utils/ChannelImages";

const Dashboard = () => {
  const { auth } = useAuth();
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const channelCards = [
    {
      channel: "Whatsapp",
      channelFromName: "Whatsapp", //this will be used to check beacuse api return different names
      description:
        "Enhance customer experience, streamline user journeys, and simplify communication with WhatsApp integration.",
      image: whatsappNewLogo,
      hidden: true,
    },
    {
      channel: "Messenger",
      channelFromName: "Facebook",
      description:
        "Seamlessly integrate your Messenger accounts for efficient message handling and interactions.",
      image: messengerIcon,
      hidden: true,
    },
    {
      channel: "Instagram",
      channelFromName: "Instagram",
      description:
        "Effortlessly link your Instagram accounts to streamline responses and engagement.",
      image: instaIcon,
    },
    {
      channel: "SMS",
      channelFromName: "SMS",
      description:
        "Configure the numbers you will utilize for SMS communications.",
      image: smsIcon,
      hidden: true,
    },
    {
      channel: "Email",
      channelFromName: "Email",
      description: "Begin the configuration of your domains now.",
      image: emailIcon,
      hidden: true,
    },
    {
      channel: "Web Chat",
      channelFromName: "Web Chat",
      description:
        "Start by adding the Web Widget to your website and connect with customers across the web.",
      image: msgIcon,
    },
  ];
  const [stats, setStats] = useState();
  const [SMSStats, setSMSStats] = useState();
  const [userDetails, setUserDetails] = useState();

  const navigate = useNavigate();
  // company active whatsapp number
  const [whatsappNumber, setWhatsappNumber] = useState(null);

  const [initalScreenLoader, setInitalScreenLoader] = useState(true);
  const getStats = () => {
    // setInitalScreenLoader(true);
    apiGet(`/stats/dashboard`, onSuccessStats, onFailureStats);
  };

  const onSuccessStats = (response) => {
    setStats(response?.data);
    setInitalScreenLoader(false);
  };
  const onFailureStats = (error) => {
    console.log(error);
    setInitalScreenLoader(false);
  };
  const getSmsStats = () => {
    apiGet(`/sms/customer-details`, onSuccessSmsStats, onFailureSmsStats);
  };

  const onSuccessSmsStats = (response) => {
    setSMSStats(response);
  };
  const onFailureSmsStats = (error) => {
    console.log(error);
  };
  const isSmsActive = companyDetails?.activeChannels.filter(function (o) {
    return o.chanelId === "SMS";
  });
  useEffect(() => {
    // setInitalScreenLoader(true);
    // setInitalScreenLoader(true);
    getStats();
    getSmsStats();
    // setTimeout(() => {
    //   setInitalScreenLoader(false);
    // }, 3000);
  }, []);
  useEffect(() => {
    if (companyDetails !== undefined) {
      setWhatsappNumber(companyDetails?.whatsappNumber);
    }
  }, [companyDetails]);
  const companyName = companyDetails?.companyName;
  const packageDisplay =
    localStorage.getItem(auth.token) === null ? false : true;
  // const myPublicId = decryptedValue(localStorage?.getItem("_agentId"));

  const agentIdFromLocalStorage = localStorage?.getItem("_agentId");
  const myPublicId = agentIdFromLocalStorage
    ? decryptedValue(agentIdFromLocalStorage)
    : null;

  // Now you can use `myPublicId` without encountering the "Cannot read properties of null" error.

  const getUserDetails = () => {
    apiGet(`/agent/`, onSuccessUserDetails, onFailureUserDetails);
  };
  const onSuccessUserDetails = (response) => {
    setUserDetails(response?.data);
  };
  const onFailureUserDetails = (error) => {
    console.log(error);
  };

  const switchCompany = (companyId) => {
    apiGet(
      `/company/switch/agent/${decryptedValue(auth.agentId)}/${companyId}`,
      onSuccessSwitchCompany,
      onFailureSwitchCompany
    );
  };
  const onSuccessSwitchCompany = (response) => {
    if (response.status === 0) {
      localStorage.setItem(
        "_companyId",
        encryptedValue(response.data.publicId)
      );
      toast.success("Company switch successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  const onFailureSwitchCompany = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="dashboardWrp">
        {/* {initalScreenLoader ===true? ( */}
        <div className="uk-container uk-container-large">
          <div className="dashboardTop">
            <div uk-grid="" className="uk-grid">
              <div className="uk-width-1-2">
                <div className="dashboardIntro">
                  <h3>
                    {" "}
                    {userDetails === undefined
                      ? "loading . . ."
                      : `Hi, ${userDetails?.firstName} ${userDetails?.lastName}`}
                  </h3>
                  <div className="introDetail">
                    <span>{companyName}</span>
                    <span>|</span>
                    <span>
                      {/* {localStorage.getItem("_role").replace("ROLE_", "")} */}
                      {userDetails?.role?.slice(5)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-2">
                {companyDetails?.childCompanies !== null &&
                  companyDetails?.childCompanies.length > 1 && (
                    <div className="topDropDown">
                      <span>Active Number</span>
                      <nav uk-dropnav="mode: click">
                        <ul className="uk-subnav amoountDropDown">
                          <li>
                            <a className="amountItem">
                              {whatsappNumber}
                              <span uk-drop-parent-icon=""></span>
                            </a>
                            <div className="uk-dropdown">
                              <ul
                                className="uk-nav uk-dropdown-nav"
                                style={{
                                  textAlign: "left",
                                }}
                              >
                                {companyDetails !== undefined &&
                                  companyDetails?.childCompanies?.map(
                                    (val, index) => {
                                      return (
                                        <li
                                          className={
                                            whatsappNumber == val.whatsappNumber
                                              ? "uk-active "
                                              : ""
                                          }
                                          key={index}
                                          onClick={() => {
                                            // localStorage.setItem(
                                            //   "_num-active",
                                            //   val.whatsappNumber
                                            // );
                                            switchCompany(val.publicId);
                                            setWhatsappNumber(
                                              val.whatsappNumber
                                            );
                                          }}
                                        >
                                          <a
                                            className={
                                              whatsappNumber ==
                                              val.whatsappNumber
                                                ? "activeWhatsappNumber"
                                                : ""
                                            }
                                          >
                                            {val.companyName +
                                              " (" +
                                              val.whatsappNumber +
                                              ")"}
                                          </a>
                                        </li>
                                      );
                                    }
                                  )}
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  )}
                {/* <div className="topDropDown">
                  <span>Your balance</span>
                  <nav uk-dropnav="mode: click">
                    <ul className="uk-subnav amoountDropDown">
                      <li>
                        <a className="amountItem" href="/">
                          PKR {stats?.balance}
                          <span uk-drop-parent-icon=""></span>
                        </a>
                        <div className="uk-dropdown">
                          <ul className="uk-nav uk-dropdown-nav">
                            <li className="uk-active">
                              <a href="/">Active</a>
                            </li>
                            <li>
                              <a href="/">Item</a>
                            </li>
                            <li>
                              <a href="/">Item</a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div> */}
              </div>
            </div>
          </div>
          <div
            uk-grid=""
            className="uk-grid uk-grid-small"
            uk-height-match="target: > div > .contentBox"
          >
            <div className="uk-width-1-2">
              <div className="contentBox">
                <div className="boxHeading">
                  <div uk-grid="" className="uk-grid">
                    <div className="uk-width-1-2">
                      <div className="txtwrp">
                        <p>Package consumption</p>
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <div className="amountwrp">
                        {/* <p>Amount PKR 30,000 (expiry 25 Sep)</p> */}
                        <p>
                          Amount PKR {stats?.packagePrice.toLocaleString()}{" "}
                          (Expiry:{" "}
                          {moment(stats?.expireOn).format("DD/MM/YYYY")})
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="boxContent">
                  <div className="progressWrp">
                    <div className="progressHeading">
                      <div className="left">
                        {stats?.consumedAgent === undefined ? (
                          "Loading . . ."
                        ) : (
                          <a
                            onClick={() => {
                              userPermissions.includes("USER-TEAMS") &&
                                navigate("/users", {
                                  replace: true,
                                });
                            }}
                          >
                            Active users{" "}
                            {(
                              ((stats?.consumedAgent !== null
                                ? stats?.consumedAgent
                                : 0) /
                                (stats?.totalAgent !== null
                                  ? stats?.totalAgent
                                  : 0)) *
                              100
                            ).toFixed(2)}
                            % Consumed
                          </a>
                        )}
                      </div>
                      <div className="right">
                        <span>
                          {stats?.consumedAgent !== null
                            ? stats?.consumedAgent.toLocaleString()
                            : 0}
                          /
                          {stats?.totalAgent !== null
                            ? stats?.totalAgent.toLocaleString()
                            : 0}
                        </span>
                      </div>
                    </div>
                    <progress
                      className={
                        (
                          ((stats?.consumedAgent !== null
                            ? stats?.consumedAgent
                            : 0) /
                            (stats?.totalAgent !== null
                              ? stats?.totalAgent
                              : 0)) *
                          100
                        ).toFixed(2) > 75
                          ? "uk-progress progressHigh"
                          : "uk-progress progressNomral"
                      }
                      value={stats?.consumedAgent}
                      max={stats?.totalAgent}
                    ></progress>
                  </div>
                  <div className="progressWrp">
                    <div className="progressHeading">
                      <div className="left">
                        {stats?.totalMAUConsumed === undefined ? (
                          "Loading . . . "
                        ) : (
                          <a
                            onClick={() => {
                              (userPermissions.includes("ANALYTICS-MANAGER") ||
                                userPermissions.includes(
                                  "LIVE-CHAT-MANAGER"
                                )) &&
                                navigate("/package-mau", {
                                  replace: true,
                                });
                            }}
                          >
                            Monthly Active Users{" "}
                            {(
                              ((stats?.totalMAUConsumed !== null
                                ? stats?.totalMAUConsumed
                                : 0) /
                                (stats?.totalMAUAllowed !== null
                                  ? stats?.totalMAUAllowed
                                  : 0)) *
                              100
                            ).toFixed(2)}
                            % Consumed
                          </a>
                        )}
                      </div>
                      <div className="right">
                        <span>
                          {stats?.totalMAUConsumed !== null
                            ? stats?.totalMAUConsumed.toLocaleString()
                            : 0}
                          /
                          {stats?.totalMAUAllowed !== null
                            ? stats?.totalMAUAllowed.toLocaleString()
                            : 0}
                        </span>
                      </div>
                    </div>
                    <progress
                      className={
                        (
                          ((stats?.totalMAUConsumed !== null
                            ? stats?.totalMAUConsumed
                            : 0) /
                            (stats?.totalMAUAllowed !== null
                              ? stats?.totalMAUAllowed
                              : 0)) *
                          100
                        ).toFixed(2) > 75
                          ? "uk-progress progressHigh"
                          : "uk-progress progressNomral"
                      }
                      value={
                        stats?.totalMAUConsumed !== null
                          ? stats?.totalMAUConsumed
                          : 0
                      }
                      max={
                        stats?.totalMAUAllowed !== null
                          ? stats?.totalMAUAllowed
                          : 0
                      }
                    ></progress>
                  </div>
                  <div className="progressWrp">
                    <div className="progressHeading">
                      <div className="left">
                        {stats?.totalMAUConsumed === undefined ? (
                          "Loading . . ."
                        ) : (
                          <a
                            onClick={() => {
                              userPermissions.includes("ANALYTICS-MANAGER") &&
                                navigate("/package-conversation", {
                                  replace: true,
                                });
                            }}
                          >
                            Conversations{" "}
                            {/* {(
                            ((stats?.totalConversationConsumed !== null
                              ? stats?.totalConversationConsumed
                              : 0) /
                              (stats?.totalConversationAllowed !== null
                                ? stats?.totalConversationAllowed
                                : 0)) *
                            100local
                          ).toFixed(2)}{" "}
                          % Consumed */}
                          </a>
                        )}
                      </div>
                      <div className="right">
                        <span>
                          {stats?.totalConversationConsumed !== null
                            ? stats?.totalConversationConsumed.toLocaleString()
                            : 0}
                        </span>
                      </div>
                    </div>
                    <progress
                      className={
                        (
                          ((stats?.totalConversationConsumed !== null
                            ? stats?.totalConversationConsumed
                            : 0) /
                            (stats?.totalConversationAllowed !== null
                              ? stats?.totalConversationAllowed
                              : 0)) *
                          100
                        ).toFixed(2) > 75
                          ? "uk-progress progressHigh"
                          : "uk-progress progressNomral"
                      }
                      value={
                        stats?.totalConversationConsumed !== null
                          ? stats?.totalConversationConsumed
                          : 0
                      }
                      max={
                        stats?.totalConversationAllowed !== null
                          ? stats?.totalConversationAllowed
                          : 0
                      }
                    ></progress>
                  </div>
                </div>
              </div>
            </div>
            {isSmsActive !== undefined && isSmsActive.length > 0 ? (
              <div className="uk-width-1-2">
                <div className="contentBox">
                  <div className="boxHeading">
                    <div uk-grid="" className="uk-grid">
                      <div className="uk-width-1-2">
                        <div className="txtwrp">
                          <p>Sms consumption</p>
                        </div>
                      </div>
                      <div className="uk-width-1-2">
                        <div className="amountwrp">
                          <p>
                            (Expiry:{" "}
                            {moment(SMSStats?.sms_quota_validity).format(
                              "DD/MM/YYYY"
                            )}
                            )
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="boxContent">
                    <div className="progressWrp">
                      <div className="progressHeading">
                        <div className="left">
                          {SMSStats?.sms_quota === undefined ? (
                            "Loading . . ."
                          ) : (
                            <a>
                              Sms Quota{" "}
                              {(
                                ((SMSStats?.consumed_quota !== null
                                  ? SMSStats?.consumed_quota
                                  : 0) /
                                  (SMSStats?.sms_quota !== null
                                    ? SMSStats?.sms_quota
                                    : 0)) *
                                100
                              ).toFixed(2)}
                              % Consumed
                            </a>
                          )}
                        </div>
                        <div className="right">
                          <span>
                            {SMSStats?.consumed_quota !== null
                              ? SMSStats?.consumed_quota.toLocaleString()
                              : 0}
                            /
                            {SMSStats?.sms_quota !== null
                              ? SMSStats?.sms_quota.toLocaleString()
                              : 0}
                          </span>
                        </div>
                      </div>
                      <progress
                        className={
                          (
                            ((SMSStats?.consumed_quota !== null
                              ? SMSStats?.consumed_quota
                              : 0) /
                              (SMSStats?.sms_quota !== null
                                ? SMSStats?.sms_quota
                                : 0)) *
                            100
                          ).toFixed(2) > 75
                            ? "uk-progress progressHigh"
                            : "uk-progress progressNomral"
                        }
                        value={SMSStats?.consumed_quota}
                        max={SMSStats?.sms_quota}
                      ></progress>
                    </div>
                    <div>
                      <h5 className="maskHeading">Active masking numbers</h5>
                      {SMSStats === undefined ? (
                        "loading..."
                      ) : (
                        <span className="maskNumbers">
                          {SMSStats?.mask.map((item) => item).join(", ")}{" "}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="uk-width-1-2">
                <div className="contentBox">
                  <div className="boxHeading">
                    <div uk-grid="" className="uk-grid">
                      <div className="uk-width-1-2">
                        <div className="txtwrp">
                          <p>Active Channels</p>
                        </div>
                      </div>
                      <div className="uk-width-1-2">
                        <div className="amountwrp">
                          {/* <p>Integrate Channels</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="boxContent">
                    <div className="brandLogo">
                      {companyDetails?.activeChannels.map((val, index) => {
                        return (
                          <img
                            key={index}
                            src={channelImages(val.chanelId)}
                            alt=""
                          />
                        );
                      })}
                      {/* <span className="uk-badge">1</span> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="channelsCommingSoonSec">
            <div className="sectionHeading">
              <h3>Channels</h3>
            </div>
            <div
              className="uk-position-relative uk-visible-toggle uk-light"
              uk-slider="autoplay: true; autoplay-interval: 4000; pause-on-hover: true"
            >
              <ul
                className="uk-slider-items uk-child-width-1-2 uk-child-width-1-4@m uk-grid uk-grid-small"
                style={{ paddingBottom: "15px" }}
                uk-height-match="target: > li > .channelBoxes"
              >
                {companyDetails !== undefined &&
                  channelCards?.map((val, index) => {
                    const isActive = companyDetails.activeChannels.some(
                      (item) =>
                        item.chanelId.toLocaleLowerCase() ===
                        val.channelFromName.toLocaleLowerCase()
                    );
                    // console.log(isActive);
                    return (
                      <li key={index}>
                        <ChannelBox
                          boxImg={val.image}
                          boxHeading={val.channel}
                          boxTxt={val.description}
                          hidden={val.hidden}
                          active={isActive}
                        />
                      </li>
                    );
                  })}
                {/* <li>
                  <ChannelBox
                    boxImg={whatsappNewLogo}
                    boxHeading="Whatsapp"
                    boxTxt="Enhance customer experience, streamline user journeys, and simplify communication with WhatsApp integration."
                    hidden={true}
                  />
                </li>
                <li>
                  <ChannelBox
                    boxImg={messengerIcon}
                    boxHeading="Messenger"
                    boxTxt="Seamlessly integrate your Messenger accounts for efficient message handling and interactions."
                    hidden={true}
                  />
                </li>
                <li>
                  <ChannelBox
                    boxImg={instaIcon}
                    boxHeading="Instagram"
                    boxTxt="Effortlessly link your Instagram accounts to streamline responses and engagement."
                    // hidden={true}
                  />
                </li>
                <li>
                  <ChannelBox
                    boxImg={smsIcon}
                    boxHeading="SMS"
                    boxTxt="Configure the numbers you will utilize for SMS communications."
                  />
                </li>
                <li>
                  <ChannelBox
                    boxImg={emailIcon}
                    boxHeading="Email"
                    boxTxt="Begin the configuration of your domains now."
                  />
                </li>
                <li>
                  <ChannelBox
                    boxImg={msgIcon}
                    boxHeading="Live Chat"
                    boxTxt="Start by adding the Web Widget to your website and connect with customers across the web."
                  />
                </li> */}
              </ul>
            </div>
            {/* <div
              uk-grid=""
              className="uk-grid uk-grid-small"
              uk-height-match="target: > div > .channelBoxes"
            >
              <div className="uk-width-1-4">
                <ChannelBox
                  boxImg={emailIcon}
                  boxHeading="Email"
                  boxTxt="Begin the configuration of your domains now."
                />
              </div>
              <div className="uk-width-1-4">
                <ChannelBox
                  boxImg={messengerIcon}
                  boxHeading="Messenger"
                  boxTxt="Seamlessly integrate your Messenger accounts for efficient message handling and interactions."
                />
              </div>
              <div className="uk-width-1-4">
                <ChannelBox
                  boxImg={smsIcon}
                  boxHeading="Live Chat"
                  boxTxt="Start by adding the Web Widget to your website and connect with customers across the web."
                />
              </div>
              <div className="uk-width-1-4">
                <ChannelBox
                  boxImg={instaIcon}
                  boxHeading="Instagram"
                  boxTxt="Effortlessly link your Instagram accounts to streamline responses and engagement."
                />
              </div>
            </div> */}
          </div>
          <div className="solutionSec">
            <div className="sectionHeading">
              <h3>Solutions</h3>
            </div>
            <div
              uk-grid=""
              className="uk-grid uk-grid-small"
              uk-height-match="target: > div > .solutionBox"
            >
              <div className="uk-width-1-3">
                <SolutionBox
                  solutionBoxImg={speakerImg}
                  solutionBoxHeading="Broadcast"
                  solutionBoxTxt="Broadcast provides a quick and effortless method to send one-way messages across multiple channels using a unified visual interface."
                  solutionBoxBtn={
                    userPermissions.includes("COMMUNICATION-MANAGER")
                      ? "Run Broadcast"
                      : "Apply Broadcast"
                  }
                  btnLink={
                    userPermissions.includes("COMMUNICATION-MANAGER")
                      ? "/create-broadcast"
                      : "/apply-for-broadcast"
                  }
                  hidden={true}
                />
              </div>
              <div className="uk-width-1-3">
                <SolutionBox
                  solutionBoxImg={chatBotImg2}
                  solutionBoxHeading="Live user chats"
                  solutionBoxTxt="Engage in real-time conversations with customers through live user chats."
                  solutionBoxBtn={
                    userPermissions?.includes("LIVE-CHAT-MANAGER") ||
                    userPermissions?.includes("LIVE-CHAT-AGENT")
                      ? "View Chats"
                      : "Apply for live chat"
                  }
                  // btnLink="/apply-for-live-agents"
                  btnLink={
                    userPermissions?.includes("LIVE-CHAT-MANAGER") ||
                    userPermissions?.includes("LIVE-CHAT-AGENT")
                      ? "/chat"
                      : "/apply-for-live-agents"
                  }
                  hidden={true}
                />
              </div>
              <div className="uk-width-1-3">
                <SolutionBox
                  solutionBoxImg={chatBotImg1}
                  solutionBoxHeading="Create a Self-Service Chatbot"
                  solutionBoxTxt="Easily build a customer-centric chatbot for seamless self-service assistance and information retrieval."
                  solutionBoxBtn="Create bot"
                  btnLink="/whatsapp/chatbot-builder"
                  hidden={true}
                />
              </div>
            </div>
          </div>
        </div>
        {/* ) : (
          <div className="uk-width-4-5 uk-padding-remove-left">
            <div className="chatSeletionWrp">
              <div className="chatSelectionContent">
                <div className=" uk-width-expand spinner">
                  <div className="uk-text-center uk-position-center ">
                    <span uk-spinner="ratio: 2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
      </div>
      {packageDisplay && <PackageModal />}
    </>
  );
};

export default Dashboard;
