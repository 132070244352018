import React from "react";
import { chatMessageDayandDateConvert } from "../../Utils/DateFunctions";

const ChatDate = ({data}) => {
  return (
    <div className="date-and-time" key={data.id}>
      <div className="txtwrp">
        <p>{chatMessageDayandDateConvert(data.timestamp)}</p>
      </div>
    </div>
  );
};

export default ChatDate;
