import InfiniteScroll from "react-infinite-scroll-component";
import { apiGet } from "../../../../Utils/apiServices";
import { useEffect } from "react";
import { useAuth } from "../../../../Context/AuthProvider";
import { useState } from "react";
import { convertTimestamp } from "../../../../Utils/DateFunctions";
import { ChevronDown } from "@carbon/icons-react";
import {
  formatText,
  sanitizedMessage,
} from "../../../../Utils/chat/chatFunctions";
import { decryptedValue } from "../../../../Utils/RouteHashConversion";
import { channelImages } from "../../../../Utils/ChannelImages";
const ChatBotContact = ({
  getChatBotMessages,
  botChatContactList,
  getAllBotChatContacts,
  companyDetails,
  setSelectedChannel,
  selectedChannel,
  hasMoreContacts,
  setHasMoreContacts,
  setCurrentPage,
}) => {
  const { auth } = useAuth();
  const [agentId, setAgentId] = useState();
  const [chatFilter, setChatFilter] = useState("BOT");
  const [activeChatIndex, setActiveChatIndex] = useState(null);
  // ********************************** api call start *************************
  const getUserDetails = () => {
    apiGet(
      `/agent/${decryptedValue(auth.agentId)}`,
      onSuccessUserDetails,
      onFailureUserDetails
    );
  };
  const onSuccessUserDetails = (response) => {
    setAgentId(response?.data.publicId);
  };
  const onFailureUserDetails = (error) => {
    console.log(error);
  };
  // ********************************** api call end *************************

  // ********************************** useEffect block start *************************
  useEffect(() => {
    getAllBotChatContacts(agentId, chatFilter);
  }, [chatFilter]);
  useEffect(() => {
    if (agentId !== undefined) {
      getAllBotChatContacts(agentId, chatFilter);
    }
  }, [agentId]);
  useEffect(() => {
    getUserDetails();
  }, []);
  useEffect(() => {
    setActiveChatIndex(null);
  }, [selectedChannel]);

  const toggleActiveChat = (customerId) => {
    if (activeChatIndex !== customerId) {
      setActiveChatIndex(customerId); // Select a new chat
    }
  };

  return (
    <div className="uk-width-1-5 custom-class">
      <div className="chat-sec">
        <ul>
          <li className="chat-sec-content">
            <button
              className="uk-button uk-button-default channel-drop-btn"
              type="button"
            >
              {selectedChannel === "ALL"
                ? "ALL (CHANNELS)"
                : selectedChannel === "FACEBOOK"
                ? "MESSENGER"
                : selectedChannel}{" "}
              <ChevronDown />
            </button>
            <div uk-dropdown="mode: click" className="allCahnnelsDropdown">
              <div className="dropdownHeading">
                <span>Chanels</span>
              </div>
              <ul className="uk-nav uk-dropdown-nav channel-drop">
                <li
                  className={selectedChannel === "ALL" && "channelListActive"}
                >
                  <button
                    className={selectedChannel === "ALL" && "color-btn"}
                    onClick={() => {
                      setCurrentPage(0);
                      setHasMoreContacts(true);
                      setSelectedChannel("ALL");
                    }}
                  >
                    All (chanels)
                  </button>
                </li>
                {companyDetails?.activeChannels.map((data, index) => {
                  return (
                    <li
                      className={
                        selectedChannel === data.chanelId && "channelListActive"
                      }
                      key={index}
                    >
                      <button
                        className={
                          selectedChannel === data.chanelId
                            ? "selectedChannel active"
                            : "active"
                        }
                        onClick={() => {
                          setCurrentPage(0);
                          setHasMoreContacts(true);
                          setSelectedChannel(data.chanelId);
                        }}
                      >
                        <img src={channelImages(data.chanelId)} alt="" />
                        <div className="txtwrp">
                          <p>
                            {" "}
                            {data.chanelId === "FACEBOOK"
                              ? "MESSENGER"
                              : data.chanelId}
                          </p>
                        </div>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </li>
        </ul>
        {botChatContactList === undefined ? (
          <div className="loaderScreen">
            <div uk-spinner="" className="loader"></div>
          </div>
        ) : (
          <div className="chatContactsContainer" id="botthreadList">
            <ul>
              <InfiniteScroll
                style={{ overflow: "none" }}
                dataLength={botChatContactList?.length}
                next={(e) => getAllBotChatContacts(agentId, chatFilter)}
                // dataLength={100}
                // next={this.loadUserApi}
                pullDownToRefreshThreshold={20}
                hasMore={hasMoreContacts}
                scrollableTarget="botthreadList"
                className="conversaionsList"
                endMessage={<center id="record_not_found"></center>}
              >
                {botChatContactList?.map((data, index) => (
                  <li
                    className={`all-chats ${
                      activeChatIndex === data.id ? "activechat" : ""
                    }`}
                    key={index}
                    onClick={() => toggleActiveChat(data.id)}
                  >
                    {/* <form action="">
                    {data.status !== "RESOLVED" ? (
                      <input
                        type="checkbox"
                        onClick={() => onClickCheckbox("contacts", data, index)}
                        className="checkField"
                        value={data.id}
                        id={`contact${index}`}
                      />
                    ) : (
                      <div className="uk-margin-right"></div>
                    )}
                  </form> */}

                    <button
                      type="button"
                      onClick={() =>
                        getChatBotMessages(
                          data.number,
                          data.chanelId,
                          data.agentName
                        )
                      }
                    >
                      <div className="iconAndName">
                        <div className="imgwrp">
                          <img src={channelImages(data.chanelId)} alt="" />
                        </div>
                        <div className="txtwrp">
                          <h6
                            className={index === activeChatIndex && "ft-weight"}
                          >
                            {data.customerName}
                          </h6>
                          <p
                            dangerouslySetInnerHTML={sanitizedMessage(
                              formatText(data.lastMessage)
                            )}
                          ></p>
                        </div>
                      </div>
                      <div className="timeDivWrp">
                        <div className="timediv">
                          <p>{convertTimestamp(data.timeStamp)}</p>
                        </div>
                      </div>
                    </button>
                  </li>
                ))}
              </InfiniteScroll>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatBotContact;
