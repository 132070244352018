import React from "react";
import { useState } from "react";
import { apiPost } from "../../../../Utils/apiServices";
import { useAuth } from "../../../../Context/AuthProvider";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { decryptedValue } from "../../../../Utils/RouteHashConversion";

const SegmentCategoryModal = ({
  closeModal,
  setApiCalled,
  heading,
  description,
  btnText,
  editData,
}) => {
  const { auth } = useAuth();
  const [loader, setLoader] = useState(false);
  const [submittedData, setSubmittedData] = useState({
    name: "",
    description: "",
  });
  const [errorEnable, setErrorEnable] = useState(false);

  const addSegmentCategory = () => {
    setLoader(true);
    setErrorEnable(true);
    let data = {
      name: submittedData.name,
      description: submittedData.description,
      companyPublicId: decryptedValue(auth?.companyId),
    };
    if (btnText === "Update") {
      data.id = editData?.id;
    }
    // if (btnText === "Create") {
    if (submittedData.name !== "") {
      apiPost(`/segment/create/category`, onSucces, onFailure, data);
    } else {
      setLoader(false);
    }
    // }
    // else if (btnText === "Update") {
    // }
    // if (
    //   (submittedData.name !== "" && data?.name !== editData?.name) ||
    //   data?.description !== editData?.description
    // ) {
    //   // apiPost(`/segment/create/category`, onSucces, onFailure, data);
    // } else if (btnText === "Update") {
    //   closeModal();
    // } else {
    //   setLoader(false);
    // }
  };
  const onSucces = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setApiCalled(true);
        setLoader(false);
        toast.success(
          btnText === "Update"
            ? "Segment category updated successfully"
            : "Segment category added successfully",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }
        );
      }, 1000);
      setTimeout(() => {
        closeModal();
      }, 2000);
    }
  };
  const onFailure = (error) => {
    console.log(error);
  };
  useEffect(() => {
    if (editData !== undefined) {
      setSubmittedData({
        name: editData?.name,
        description: editData?.description,
      });
    }
  }, [editData]);
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>{heading}</span>
          </div>
          <div className="modalForm">
            <div className="segmentModalTxt">
              <span>{description}</span>
            </div>
            <form action="">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <label htmlFor="">Segment category name</label>
                    <input
                      type="text"
                      className="uk-input"
                      style={{ width: "100%" }}
                      placeholder="Segment category name"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          name: e.target.value,
                        });
                      }}
                      defaultValue={editData?.name}
                    />
                    {submittedData.name === "" && errorEnable && (
                      <div className="formErrors">name is required</div>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <label htmlFor="">Segment type discription</label>
                    <input
                      type="text"
                      className="uk-input"
                      style={{ width: "100%" }}
                      placeholder="Enter text..."
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          description: e.target.value,
                        });
                      }}
                      defaultValue={editData?.description}
                    />
                    {/* {submittedData.description === "" && errorEnable && (
                      <div className="formErrors">description is required</div>
                    )} */}
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="btnwrp">
                    <button
                      type="button"
                      className="btn-1"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn-2"
                      onClick={addSegmentCategory}
                    >
                      {loader ? (
                        <div uk-spinner="" className="loader"></div>
                      ) : (
                        btnText
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SegmentCategoryModal;
