import React, { useState } from "react";
import Switch from "react-switch";
import {
  ChevronLeft,
  Edit,
  OverflowMenuVertical,
  Download,
  TrashCan,
  Printer,
  CheckmarkOutline,
} from "@carbon/icons-react";
import { Link } from "react-router-dom";

const InvoiceDetail = () => {
  const [loader, setLoader] = useState(false);
  const [pwdPassword, setPwdPassword] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [numbers, setNumbers] = useState(false);
  const [lengths, setLengths] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

  const handleViewPassword = () => {
    setViewPassword(!viewPassword);
  };

  const handleViewConfirmPassword = () => {
    setViewConfirmPassword(!viewConfirmPassword);
  };

  return (
    <div className="addTeamWrp">
      <div className="uk-container">
        <div className="backBtn">
          <Link to="/super-admin-invoice-list">
            <ChevronLeft /> Back to all invoices
          </Link>
        </div>
        <div className="addTeamHeading">
          <h3>Detail View</h3>
          <p>
            Enter details for the new user, assign roles, and select teams this
            user belongs to.
          </p>
        </div>
        <div className="addTeamBox">
          <div className="boxHeading">
            <h3>Invoice information</h3>
          </div>
          <div className="formwrp">
            <form autoComplete="off">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">Company name</label>
                    <input
                      type="text"
                      placeholder="Enter company name"
                      className="uk-input"
                    />
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">Platform</label>
                    <input
                      type="text"
                      placeholder="Enter platform"
                      className="uk-input"
                    />
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="mobile_no">Current package type</label>
                    <input
                      type="text"
                      placeholder="Enter current package type"
                      className="uk-input"
                    />
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="mobile_no">Current package title</label>
                    <input
                      type="text"
                      placeholder="Enter package title"
                      className="uk-input"
                    />
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="email">Invoice trigger date</label>
                    <input type="date" className="uk-input" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="userTableWrp invoiceTableWrp">
          <table className="uk-table">
            <thead>
              <tr>
                <th>INVOICE NUMBER</th>
                <th>MONTH</th>
                <th className="">PACKAGE</th>
                <th>PACKAGE TYPE</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="agnetName">24586</td>
                <td>Feb</td>
                <td>Basic 1</td>
                <td>Bucket</td>
                <td>
                  <span className="failed">Unpaid</span>
                </td>
                <td>
                  <div className="uk-inline">
                    <button type="button">
                      <OverflowMenuVertical />
                    </button>
                    <div uk-dropdown="" className="contactBlckDrop">
                      <ul>
                        <li>
                          <button type="button">
                          <Printer /> Print Invoice
                          </button>
                        </li>
                        <li>
                          <button type="button">
                          <CheckmarkOutline /> Mark as Paid
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="agnetName">24586</td>
                <td>Feb</td>
                <td>Basic 1</td>
                <td>Bucket</td>
                <td>
                  <span className="statusConnected">Paid</span>
                </td>
                <td>
                  <div className="uk-inline">
                    <button type="button">
                      <OverflowMenuVertical />
                    </button>
                    <div uk-dropdown="" className="contactBlckDrop">
                      <ul>
                        <li>
                          <button type="button">
                          <Printer /> Print Invoice
                          </button>
                        </li>
                        <li>
                          <button type="button">
                          <CheckmarkOutline /> Mark as Paid
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetail;
