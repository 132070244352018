import React from "react";

const StopModal = ({ closeModal }) => {
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>Delete Broadcast 'Weekend Mega Sales! 78% Off!'</span>
          </div>
          <div className="modalForm pauseModalContent">
            <div className="formHeading">
              <span>Are you sure you want to delete this Broadcast?</span>
            </div>
            <div className="txtwrp">
              <p>
              Weekend Mega Sales! 78% Off!
              </p>
            </div>
            <div className="formHeading">
              <span>Description</span>
            </div>
            <div className="txtwrp">
              <p>
                Eid-ul-Adha 2024 lead generation campaign for increased sales
              </p>
            </div>
            <div className="formHeading">
              <span>Recipients</span>
            </div>
            <div className="txtwrp">
              <p>+92 301 8222411</p>
            </div>
            <div className="formHeading">
              <span>Payment summary</span>
            </div>
            <div className="txtwrp">
              <p>Approximate cost: $0.00</p>
            </div>
            <div className="formInputs dltModal"></div>
            <div className="btnwrp">
              <button type="button" className="btn-1" onClick={closeModal}>
                Cancel
              </button>
              <button className="btn-2 deleteBtn" type="submit">
                Pause broadcast
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StopModal;
