import React from "react";
import {
  displayMessageStatusIcon,
  getToolTipPosition,
} from "../../Utils/chat/chatFunctions";
import { formatChatHoverDateTime } from "../../Utils/DateFunctions";
const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;

const ChatPresentation = ({ data }) => {
  return (
    <>
      {data.messageType === "media" ? (
        <div className="txtwrp">
          {(data.isSent == "true" || data.isSent == true) &&
            displayMessageStatusIcon(data.messageStatus)}
          <p
            className="document"
            uk-tooltip={`title: ${formatChatHoverDateTime(
              data.timestamp
            )}; pos: ${getToolTipPosition(data.isSent)}`}
          >
            <a href={data.filePath} target="_blank">
              Click to download PPT
            </a>
            <div>{data.caption}</div>
          </p>
        </div>
      ) : (
        <div className="txtwrp">
          <p
            className="document"
            uk-tooltip={`title: ${formatChatHoverDateTime(
              data.timestamp
            )}; pos: ${getToolTipPosition(data.isSent)}`}
          >
            <a
              href={chatBaseUrl + "/" + data?.filePath.replace("assets", "")}
              target="_blank"
            >
              Click to download PPT
            </a>
            <div>{data.caption}</div>
          </p>
        </div>
      )}
    </>
  );
};

export default ChatPresentation;
