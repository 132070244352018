import { Checkmark } from "@carbon/icons-react";
import axios from "axios";
// import { baseUrl } from "../../Constant";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const baseUrl = process.env.REACT_APP_BASEURL;

const ChangePassword = () => {
  const navigate = useNavigate();
  const [errorEnable, setErrorEnable] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [numbers, setNumbers] = useState(false);
  const [lengths, setLengths] = useState(false);
  const [specialCharacters, setSpecialCharacters] = useState(false);
  const [pwdPassword, setPwdPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("_otpEmail") === null ||
      // localStorage.getItem("_otp") === null ||
      localStorage.getItem("_preAuthToken") === null
    ) {
      navigate("/", { replace: true });
    }
  }, []);
  const handleChangePassword = () => {
    setLoader(true);
    if (
      lowerCase === true &&
      upperCase === true &&
      numbers === true &&
      lengths === true &&
      pwdPassword === true &&
      specialCharacters === true
    ) {
      axios
        .post(
          baseUrl + "/auth/change-pin",
          {
            email: localStorage.getItem("_otpEmail"),
            preAuthToken: localStorage.getItem("_preAuthToken"),
            confirmPassword: confirmPassword,
            password: password,
          },
          {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          }
        )
        .then(
          (response) => {
            if (
              response?.data?.message ===
              "Password has been changed successfully, please re-login."
            ) {
              setTimeout(() => {
                setLoader(false);
                toast.success("Password changed successfully", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                });
              }, 1000);
              setTimeout(() => {
                navigate("/", { replace: true });
                localStorage.clear();
              }, 2000);
            }
          },
          (error) => {
            setLoader(false);
          }
        );
    } else {
      setLoader(false);
    }
  };
  const handleValidation = (e) => {
    setErrorEnable(true);
    let value = e.target.value;
    if (value === confirmPassword) {
      if (value !== "" && confirmPassword !== "") {
        setPwdPassword(true);
      }
    } else {
      setPwdPassword(false);
    }

    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const lengthRegex = /^.{8,10}$/;
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-=|]/;

    const isLowercaseValid = lowercaseRegex.test(value);
    const isUppercaseValid = uppercaseRegex.test(value);
    const isNumberValid = numberRegex.test(value);
    const isLengthValid = lengthRegex.test(value);
    const isSpecialCharRegex = specialCharRegex.test(value);

    setLowerCase(isLowercaseValid);
    setUpperCase(isUppercaseValid);
    setNumbers(isNumberValid);
    setLengths(isLengthValid);
    setSpecialCharacters(isSpecialCharRegex);
    setPassword(value);
  };

  const handleConfirmPwdValidation = (e) => {
    setErrorEnable(true);
    let value = e.target.value;

    if (value === password) {
      if (value !== "" && password !== "") {
        setPwdPassword(true);
      }
    } else {
      setPwdPassword(false);
    }
  };
  const handlCancel = () => {
    navigate("/", { replace: true });
  };
  return (
    <div className="changePasswordContainer">
      <div className="uk-container">
        <h2 className="heading">Change password</h2>
        <p>
          Please provide the details and specific requirements you would like to
          have for the live user chats feature.
        </p>
        <div className="innerContainer">
          <h2 className="innerheading">Set Password</h2>
          <div className="formWrap">
            <div className="txt1">Choose a new password</div>
            <input
              type="password"
              placeholder="Password"
              className="field"
              onChange={(e) => handleValidation(e)}
            />
            <div className="txt2">Repeat password</div>
            <input
              type="password"
              placeholder="Repeat Password"
              className="field"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                handleConfirmPwdValidation(e);
              }}
            />
            <div className="pwdMatchTxt">
              <div>
                <span className={lowerCase === true ? "active" : "nonActive"}>
                  <Checkmark />
                </span>
                Lowercase Letter
              </div>
              <div>
                <span className={upperCase === true ? "active" : "nonActive"}>
                  <Checkmark />
                </span>
                Uppercase letter
              </div>
              <div>
                <span className={numbers === true ? "active" : "nonActive"}>
                  <Checkmark />
                </span>
                Number
              </div>
              <div>
                <span className={lengths === true ? "active" : "nonActive"}>
                  <Checkmark />
                </span>
                8 to 10 characters
              </div>
              <div>
                <span
                  className={
                    specialCharacters === true ? "active" : "nonActive"
                  }
                >
                  <Checkmark />
                </span>
                Special characters
              </div>
              <div>
                <span className={pwdPassword === true ? "active" : "nonActive"}>
                  <Checkmark />
                </span>
                Password and confirm password match
              </div>
            </div>
            <div className="formErrors">
              {errorEnable && password === "" && confirmPassword === ""
                ? "Password and confirm password required"
                : errorEnable && password === ""
                ? "Password is required"
                : errorEnable &&
                  confirmPassword === "" &&
                  "Confirm password is required"}
            </div>
            <div className="btnWrap uk-margin-large-top">
              <div>
                <button className="cnclBtn" onClick={handlCancel}>
                  Cancel
                </button>
                <button className="saveBtn w-90" onClick={handleChangePassword}>
                  {loader ? (
                    <div uk-spinner="" className="loader"></div>
                  ) : (
                    "Reset"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
