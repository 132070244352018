import React from "react";

const FileLoader = ({ errors, closeModal }) => {
  return (
    <div 
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open "
      uk-modal="esc-close: false; bg-close: false" 
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical loaderModal">
        <div>
          {errors === null ? (
            <div>
              <p>Processing...</p>
              <span className="uk-margin-small-right" uk-spinner="ratio: 3"></span>
            </div>
          ) : (
            <div>
              <p>{errors}</p>
            </div>
          )}
        </div>
        {errors !== null && (
          <div className="btnWrap uk-margin-xlarge-top">
            <div>
              <button className="cnclBtn" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        )}
      </div> 
    </div>
  );
};

export default FileLoader;
