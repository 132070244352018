import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthProvider";
import { apiPost } from "../../Utils/apiServices";
import { decryptedValue } from "../../Utils/RouteHashConversion";

const Unauthorized = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const handleLogout = () => {
    let data = {
      accessToken: auth.token,
      userPublicId: decryptedValue(auth.publicId),
    };
    apiPost(`/auth/logout`, onSuccess, onFailure, data);
  };
  const onSuccess = (response) => {
    localStorage.clear("");
    // setTimeout(() => {
      navigate("/", { replace: true });
    // }, 1000);
  };
  const onFailure = (error) => {
    console.log(error);
  };
  return (
    <div className="unauthorizWrp">
      <h2>You are unauthorized to access!</h2>
      <button type="button">
        {/* <Link to="/dashboard">Back to Dashboard</Link> */}
        <Link to="/" onClick={handleLogout}>
          Back to Login
        </Link>
      </button>
    </div>
  );
};

export default Unauthorized;
