import { Help } from "@carbon/icons-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";

const addLineNumbers = (input) =>
  input
    .split("\n")
    .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
    .join("\n");

const EditRequestModal = ({ closeModal }) => {
  const [codeValue, setCodeValue] = useState("");
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical editRequestModal">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>Edit Request</span>
          </div>
          <div className="modalForm">
            <div className="helpBtn uk-text-right uk-margin-small-top">
              <Link to="/">
                <Help /> Help
              </Link>
            </div>
            <div className="requestTypeForm">
              <form action="" className="uk-margin-remove">
                <div className="uk-grid" uk-grid="">
                  <div className="uk-width-1-5">
                    <div className="formInput">
                      <label htmlFor="">Request Type</label>
                      <select name="" className="uk-select">
                        <option value="">POST</option>
                        <option value="">GET</option>
                        <option value="">PUT</option>
                        <option value="">DELETE</option>
                      </select>
                    </div>
                  </div>
                  <div className="uk-width-4-5">
                    <div className="formInput">
                      <label htmlFor="">
                        Request URL <span>Only https links are allowed</span>
                      </label>
                      <input
                        type="text"
                        className="uk-input"
                        placeholder="Enter URL (e.g. https://portal.berrytalks.com.com)"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="editRequestTab">
              <div className="tabBtn">
                <ul uk-tab="connect: #editRequestTab">
                  {/* <li>
                    <a href="#">Headers</a>
                  </li> */}
                  <li>
                    <a href="#">Body</a>
                  </li>
                  {/* <li>
                    <a href="#">Response</a>
                  </li>
                  <li>
                    <a href="#">Response mapping</a>
                  </li> */}
                </ul>
                <div className="submitBtn">
                  <button type="button">Test the Request</button>
                </div>
              </div>
              <div className="tabContent">
                <ul className="uk-switcher uk-margin" id="editRequestTab">
                  {/* <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </li> */}
                  <li>
                    <div className="editBodyWrp">
                      <div
                        className="uk-grid"
                        uk-grid=""
                        uk-height-match="target: > div > div"
                      >
                        <div className="uk-width-1-2">
                          <div className="formInput">
                            <label htmlFor="">Request Body</label>
                            <Editor
                              value={codeValue}
                              onValueChange={(code) => setCodeValue(code)}
                              padding={10}
                              textareaId="codeArea"
                              className="editor"
                              style={{
                                fontFamily:
                                  '"Fira code", "Fira Mono", monospace',
                                fontSize: 14,
                                outline: 0,
                              }}
                              highlight={(code) => addLineNumbers(code)}
                              placeholder="Enter request body"
                            />
                          </div>
                        </div>
                        <div className="uk-width-1-2">
                          <div className="editBodyPreview">
                            <div className="mainHeading">
                              <span>Preview</span>
                            </div>
                            <div className="txtwrp">
                              <h2>Please, Opt-in to Test the Response</h2>
                              <p>
                                You need to Opt-in as a contact of your bot,
                                since Manychat uses your own data to generate
                                the request. To pass suiting test data with
                                variables, you can edit your profile in Audience
                                after you Opt-In.
                              </p>
                            </div>
                            <div className="btnwrp">
                              <button type="button">Send in messenger</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* <li>
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur, sed do eiusmod.
                  </li>
                  <li>
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur, sed do eiusmod.
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="btnwrp">
              <button type="button" className="btn-1" onClick={closeModal}>
                Cancel
              </button>
              <button type="button" className="btn-2 w-90">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditRequestModal;
