import React, { useEffect, useState } from "react";
import { ChevronLeft, View, ViewOff } from "@carbon/icons-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ValidateEmail,
  validatePhoneNumber,
} from "../../../Utils/regexFunctions";
import { toast } from "react-toastify";
import { apiPut } from "../../../Utils/apiServices";

const EditSuperAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [errorEnable, setErrorEnable] = useState(false);
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [submittedData, setSubmittedData] = useState({
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    confirmPassword: "",
    firebaseId: "string",
    firebaseToken: "string",
    macAddress: "string",
    imageUrl: "string",
    companyId: "0001",
    role: ["ROLE_SUPER_ADMIN"],
  });

  const handleEmailValidation = (e) => {
    var Email = ValidateEmail(e);
    if (Email === "Invalid Email") {
      setEmailError("Invalid Email Address");
    } else {
      setEmailError("Valid Email Address");
    }
  };

  const editUser = (e) => {
    e.preventDefault();
    setErrorEnable(true);
    setLoader(true);
    let data = {
      email: submittedData.email,
      name: submittedData.name,
      password: submittedData.password,
      role: submittedData.role,
      phoneNumber: submittedData.phoneNumber,
      firebaseId: submittedData.firebaseId,
      firebaseToken: submittedData.firebaseToken,
      macAddress: submittedData.macAddress,
      imageUrl: submittedData.imageUrl,
      companyId: submittedData.companyId,
      id: location.state.id,
      publicId: location.state.publicId,
    };

    if (
      submittedData.name !== "" &&
      submittedData.password !== "" &&
      submittedData.phoneNumber !== "" &&
      submittedData.email !== "" &&
      emailError === "Valid Email Address" &&
      isNumberValid === true
    ) {
      apiPut(`/super-admin/edit`, onSuccess, onFailure, data);
    } else {
      setLoader(false);
    }
  };
  const onSuccess = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setLoader(false);
        toast.success("Super admin edited successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1000);
      setTimeout(() => {
        navigate("/super-admin-users", { replace: true });
      }, 2000);
    }
  };
  const onFailure = (error) => {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoader(false);
  };

  useEffect(() => {
    if (location.state !== null) {
      handleEmailValidation(location.state.email);
      setSubmittedData({
        name: location.state.name,
        email: location.state.email,
        password: location.state.password,
        phoneNumber: location.state.phoneNumber,
      });
    } else {
      navigate(("/", { replace: true }));
    }
  }, []);


  return (
    <div className="addTeamWrp">
      <div className="uk-container">
        <div className="backBtn">
          <Link to="/super-admin-users">
            <ChevronLeft /> Back to all Super Admin
          </Link>
        </div>
        <div className="addTeamHeading">
          <h3>Edit {location?.state?.name}</h3>
          <p>
          Enter updated detail you wish to edit of current user.
          </p>
        </div>
        <div className="addTeamBox">
          <div className="boxHeading">
            <h3>User information</h3>
          </div>
          <div className="formwrp">
            <form onSubmit={editUser} autoComplete="off">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">Name</label>
                    <input
                      type="text"
                      placeholder="Enter name"
                      className="uk-input"
                      value={submittedData.name}
                      onChange={(e) => {
                        e.target.value.length <= 10 &&
                          setSubmittedData({
                            ...submittedData,
                            name: e.target.value,
                          });
                      }}
                    />
                    <div className="txtCounter" style={{ top: "35px" }}>
                      <span>{submittedData?.name?.length}</span>
                      <span>/</span>
                      <span>10</span>
                    </div>
                    {submittedData.name === "" && errorEnable && (
                      <div className="formErrors">Name is required</div>
                    )}
                  </div>
                </div>
                {/* <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">Last name</label>
                    <input
                      type="text"
                      placeholder="Last name"
                      className="uk-input"
                    />
                    <div className="txtCounter" style={{ top: "35px" }}>
                      <span>0</span>
                      <span>/</span>
                      <span>10</span>
                    </div>
                  </div>
                </div> */}
                {/* <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="work_number">Work number</label>
                    <input
                      type="text"
                      placeholder="Work number"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          phoneNumberWork: e.target.value,
                        });
                      }}
                    />
                    {submittedData.phoneNumberWork === "" && errorEnable && (
                      <div className="formErrors">work number is required</div>
                    )}
                  </div>
                </div> */}
                {/* <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="mobile_no">Work number</label>
                    <input
                      type="number"
                      placeholder="Work number"
                      className="uk-input"
                    />
                    <div className="txtCounter" style={{ top: "35px" }}>
                      <span>0</span>
                      <span>/</span>
                      <span>20</span>
                    </div>
                  </div>
                </div> */}
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="mobile_no">Mobile Number</label>
                    <input
                      type="number"
                      placeholder="Mobile Number"
                      className="uk-input"
                      value={submittedData.phoneNumber}
                      onChange={(e) => {
                        let error = validatePhoneNumber(e.target.value);
                        setIsNumberValid(error);
                        e.target.value.length <= 20 &&
                          setSubmittedData({
                            ...submittedData,
                            phoneNumber: e.target.value,
                          });
                      }}
                    />
                    <div className="txtCounter" style={{ top: "35px" }}>
                      <span>{submittedData?.phoneNumber?.length}</span>
                      <span>/</span>
                      <span>20</span>
                    </div>
                    {submittedData.phoneNumber === "" && errorEnable && (
                      <div className="formErrors">Phone number is required</div>
                    )}
                    {submittedData.phoneNumber !== "" && !isNumberValid && (
                      <div className="formErrors">Invalid phone number</div>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="email">Email</label>
                    <input
                      autoComplete="off"
                      type="text"
                      placeholder="Email"
                      value={submittedData?.email}
                      className="uk-input"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          email: e.target.value,
                        });
                        handleEmailValidation(e.target.value);
                      }}
                      // autoComplete="new-email"
                    />
                    {submittedData.email === "" && errorEnable && (
                      <div className="formErrors">Email is required</div>
                    )}
                    {submittedData.email !== "" &&
                      emailError !== "Valid Email Address" && (
                        <div className="formErrors">Invalid Email Address</div>
                      )}
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="password">Password</label>
                    <input
                      autoComplete="off"
                      type="password"
                      placeholder="Password"
                      value={submittedData?.password}
                      className="uk-input"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          password: e.target.value,
                        });
                      }}
                      // autoComplete="new-email"
                    />
                    {submittedData.password === "" && errorEnable && (
                      <div className="formErrors">Password is required</div>
                    )}
                   
                  </div>
                </div>

                <div className="uk-width-1-1">
                  <div className="adminRoleWrp">
                    <div className="btnwrp">
                      <button className="btn-1" type="button"  onClick={()=>  navigate("/super-admin-users", { replace: true })}>
                        Cancel 
                      </button>
                      <button className="btn-2 w-70" type="submit">
                        {loader ? (
                          <div uk-spinner="" className="loader"></div>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSuperAdmin;
