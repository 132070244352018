import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import ChatProfile from "./components/ChatProfile";
import ChatMessages from "./components/ChatMessages";
import ChatContacts from "./components/ChatContacts";
import $ from "jquery";
import ChatNotOpen from "./components/ChatNotOpen";
import { Filter } from "@carbon/icons-react";
import { apiGet, apiGetSuperAdmin, apiPost } from "../../Utils/apiServices";
import { useAuth } from "../../Context/AuthProvider";
import ChatMsgBeforeLoadScreen from "./components/ChatMsgBeforeLoadScreen";
import { toast } from "react-toastify";
import { debounce, filter } from "lodash";
import ChatFilterModal from "../Contacts/Modals/ChatFilterModal";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useWebSocket } from "../../socket/WebSocketManager";
import { decryptedValue } from "../../Utils/RouteHashConversion";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import NewSmsModal from "../Contacts/Modals/NewSmsModal";

const Chat = () => {
  const location = useLocation();
  const params = useParams();
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const { auth } = useAuth();
  const { socket, setChatFirstContactDetails } = useWebSocket();
  const navigate = useNavigate();
  const [isRemoveSearchOnSpecificChat, setIsRemoveSearchOnSpecificChat] =
    useState(false);
  const [isChatStatusVisible, setIsChatStatusVisible] = useState(false);
  // store company receipt number
  const [companyWhatsappNumber, setCompanyWhatsappNumber] = useState("");
  const [openFileLoader, setOpenFileLoader] = useState(false);
  const [checked, setChecked] = useState(
    selectedStatus === "AWAY" ? true : false
  );
  const [notificationTune, setNotificationTune] = useState(
    (localStorage.getItem("_tune") === "false" && false) ||
      (localStorage.getItem("_tune") === "true" && true)
  );
  // for gallery start
  const [pageCount, setPageCount] = useState(0);
  const [galleryData, setGalleryData] = useState(null);
  const [apiCalledGallery, setApiCalledGallery] = useState(false);
  // for  gallery end

  const [quickReply, setQuickReply] = useState([]);
  const [apicalled, setApicalled] = useState(false);
  const [searchedKey, setSearchedKey] = useState("");
  const [selectedChannel, setSelectedChannel] = useState("ALL");
  const [modalLoader, setModalLoader] = useState(false);
  const [openSegmentFilterModal, setOpenSegmentFilterModal] = useState(false);
  const [contactMessages, setContactMessages] = useState(null);
  const [newMessages, setNewMessages] = useState();
  const [chatBulkMarkAsResolved, setChatBulkMarkAsResolved] = useState([]);
  const [tags, setTags] = useState([]);
  const [isNotesCreated, setIsNotesCreated] = useState(false);
  const [notesList, setNotesList] = useState([]);
  const [conversationListCount, setConversationListCount] = useState(0);
  const [agentList, setAgentList] = useState();
  const [loader, setLoader] = useState(false);
  const [chatDetails, setChatDetails] = useState({
    number: "",
    channel: "",
    agentName: "",
    agentId: "",
    conversationId: "",
    companyId: "",
  });
  const [contactApiDetails, setContactApiDetails] = useState({
    agentId: "",
    chatFilter: "",
  });
  const [openResolvedModal, setOpenResolvedModal] = useState(false);
  const [openUnAssignConversationModal, setUnAssignConversationModal] =
    useState(false);
  const [chatContactList, setChatContactList] = useState();
  const [multiSelectTags, setMultiSelectTags] = useState([]);
  const [chatFilter, setChatFilter] = useState("ALL");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageForChatMessages, setPageForChatMessages] = useState(0);
  const [paginationCalled, setPaginationCalled] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [hasMoreContacts, setHasMoreContacts] = useState(true);
  const [openNewSmsModal, setOpenNewSmsModal] = useState(false);
  const [filterData, setFilterData] = useState({
    fromDate: null,
    toDate: null,
    country: "",
    city: "",
    gender: "",
    Status: "",
    searchQuery: "",
    countryList: "",
    tags: [],
    agentId: "",
  });
  // for currently selected chat contact in the list
  const [activeChatIndex, setActiveChatIndex] = useState(null);

  const isActiveChannel = companyDetails?.activeChannels.filter(function (o) {
    return o.chanelId === "FACEBOOK";
  });
  const isSmsActive = companyDetails?.activeChannels.filter(function (o) {
    return o.chanelId === "SMS";
  });

  // ***************************************** start apis *****************************
  const getGalleryData = (page) => {
    apiGetSuperAdmin(
      `/chat/files/${decryptedValue(auth.companyId)}?page=${page}&size=${10} `,
      onSuccess,
      onFailure
    );
  };

  const onSuccess = (response) => {
    if (response.status === 0) {
      setGalleryData(response.data.content);
      setPageCount(response?.data?.totalPages);
      setApiCalledGallery(false);
    }
  };
  const onFailure = (error) => {
    console.log(error);
  };

  const getAllChatContacts = (agentId, chatFilter, number, isSearched) => {
    setContactApiDetails({
      agentId: agentId,
      chatFilter: chatFilter,
    });

    if (agentId !== undefined && searchedKey === "") {
      let apiParameters =
        selectedChannel === "ALL"
          ? `${chatFilter}?page=${isSearched === undefined ? currentPage : 0}`
          : `${chatFilter}?chanelId=${selectedChannel}&page=${
              number === undefined ? currentPage : number
            }`;
      apiGet(
        `/chat/contactList/${apiParameters}`,
        onSuccessChatContacts,
        onFailureChatContacts
      );
    }
  };
  const onSuccessChatContacts = (response) => {
    if (response.status === 1) {
      // if (Object.keys(params).length !== 0) {
      //   setIsRemoveSearchOnSpecificChat(true);
      // }
      let page = currentPage + 1;
      setCurrentPage(page);
      setApicalled(false);
      // setChatContactList(response?.data);
      setIsChatStatusVisible(false);
      if (chatContactList === undefined) {
        setChatContactList(response.data);
      } else {
        if (page === 1) {
          setChatContactList(response.data);
        } else {
          if (response.data.length !== 0) {
            setChatContactList((prevMessages) => [
              ...prevMessages,
              ...response.data,
              // response.data[response.data.length - 1],
            ]);
          }
        }
      }
      if (response.data.length === 0) {
        // here we can check if no record found so we can update state not to call api
        setHasMoreContacts(false);
      } else {
        if (response.data.length < 20) {
          // here we can check data exist but is lesser than 20 records means no more data exist
          setHasMoreContacts(false);
        }
      }
    }
  };
  const onFailureChatContacts = (error) => {
    console.log(error);
  };

  const getQuickReply = () => {
    apiGet(`/settings/quick-reply`, onSuccessQuickReply, onFailureQuickReply);
  };
  const onSuccessQuickReply = (response) => {
    setQuickReply(response?.data);
  };
  const onFailureQuickReply = (error) => {
    console.log(error);
  };

  const onChange = (e) => {
    var searchedValue = e.target.value;
    console.log(searchedValue);
    setSearchedKey(searchedValue);
    setChatContactList(undefined);
    setCurrentPage(0);

    setHasMoreContacts(true);
    setContactMessages(null);
    setFilterData({
      ...filterData,
      searchQuery: searchedValue,
    });
  };

  const onSearch = () => {
    const data = {
      dateStart: filterData.fromDate,
      dateEnd: filterData.toDate,
      country: filterData.country,
      city: filterData.city,
      gender: filterData.gender,
      contactTags: filterData.tags,
      status: chatFilter === "ALL" ? "" : chatFilter,
      searchQuery: searchedKey,
      companyId: decryptedValue(auth.companyId),
      agentId: filterData.agentId,
    };
    apiPost(
      `/chat/search-chat-filter?page=${currentPage}`,
      onSuccessSearch,
      onFailureSearch,
      data
    );
  };

  const onSuccessSearch = (response) => {
    if (response.status === 1) {
      let page = currentPage + 1;
      setCurrentPage(page);
      if (chatContactList === undefined) {
        setChatContactList(response.data);
      } else {
        if (page === 1) {
          setChatContactList(response.data);
        } else {
          if (response.data.length !== 0) {
            setChatContactList((prevMessages) => [
              ...prevMessages,
              ...response.data,
            ]);
          }
        }
      }
      if (response.data.length === 0) {
        // here we can check if no record found so we can update state not to call api
        setHasMoreContacts(false);
      } else {
        if (response.data.length < 20) {
          // here we can check data exist but is lesser than 20 records means no more data exist
          setHasMoreContacts(false);
        }
      }
      // setChatContactList(response?.data);
    }
  };
  const onFailureSearch = (error) => {
    console.log(error);
  };

  const changeAgentStatus = (filterValue) => {
    apiPost(
      `/agent/changeStatus/${filterValue}/${decryptedValue(auth.agentId)}`,
      onSuccessChnageAgentStatus,
      onFailureChnageAgentStatus
    );
  };
  const onSuccessChnageAgentStatus = (response) => {
    toast.success("Status changes successfully", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1500,
    });
    if (checked === true) {
      setSelectedStatus("ONLINE");
    } else {
      setSelectedStatus("AWAY");
    }
  };
  const onFailureChnageAgentStatus = (error) => {
    console.log(error);
  };

  const bulkMarkAsResolved = (e) => {
    e.preventDefault();
    setModalLoader(true);
    let data = [];
    chatBulkMarkAsResolved.map((val) => {
      data.push({
        chatStatus: "RESOLVED",
        companyId: decryptedValue(auth.companyId),
        currentAgentId: decryptedValue(auth.idNumber),
        // phoneNumber: val.id,
        phoneNumber: val.number,
      });
    });
    apiPost(
      `/chat/update-chat-status
      `,
      onSuccessBulkMarkAsResolved,
      onFailureBulkMarkAsResolved,
      data
    );
  };
  const onSuccessBulkMarkAsResolved = (response) => {
    if (response.status === 1) {
      setTimeout(() => {
        setModalLoader(false);
        toast.success("Chat Resolved Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        fetchChat(decryptedValue(auth.idNumber));
      }, 1000);
      setTimeout(() => {
        handleCloseResolvedModal();
      }, 2000);
    }
  };
  const onFailureBulkMarkAsResolved = (error) => {
    console.log(error);
    setModalLoader(false);
  };
  const bulkChatUnassigned = (e) => {
    e.preventDefault();
    setModalLoader(true);
    let data = [];
    chatBulkMarkAsResolved.map((val) => {
      data.push({
        companyId: decryptedValue(auth.companyId),
        phoneNumber: val.id,
      });
    });
    apiPost(
      `/chat/unassigned-bulk-chat
      `,
      onSuccessChatUnassigned,
      onFailureChatUnassigned,
      data
    );
  };
  const onSuccessChatUnassigned = (response) => {
    if (response.status === 1) {
      setTimeout(() => {
        setModalLoader(false);
        toast.success("Chat Resolved Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1000);
      setTimeout(() => {
        handleCloseUnAssignConversationModal();
      }, 2000);
    }
  };
  const onFailureChatUnassigned = (error) => {
    console.log(error);
    setModalLoader(false);
  };

  const getChatMessages = (
    number,
    channelId,
    agentName,
    agentId,
    conversationId,
    companyId
  ) => {
    setChatDetails({
      number: number,
      channel: channelId,
      agentName: agentName == "NA" ? "" : agentName,
      agentId: agentId == "NA" ? "" : agentId,
      conversationId: conversationId,
      companyId: companyId,
    });
    if (conversationListCount === 0) {
      setLoader(true);
      if (decryptedValue(auth.idNumber) === agentId) {
        markRead(conversationId);
      }
      if (agentId !== "NA") {
        subscribeToChat(agentId, conversationId);
      }
      if (
        searchedKey === "" &&
        // if added filter values
        filterData.fromDate === null &&
        filterData.toDate === null &&
        filterData.country === "" &&
        filterData.city === "" &&
        filterData.gender === "" &&
        filterData.Status === "" &&
        filterData.searchQuery === "" &&
        filterData.countryList === "" &&
        filterData.tags.length === 0 &&
        filterData.agentId === ""
      ) {
        fetchChat(decryptedValue(auth.idNumber));
      }
      apiGet(
        `/chat/details/${number}/${companyId}/${decryptedValue(
          auth.idNumber
        )}/${channelId}?page=${0}`,
        onSuccessChatMessages,
        onFailureChatMessages
      );
    }
  };
  const onSuccessChatMessages = (response) => {
    if (
      response.data.inboxes.length > 0 &&
      response.data.inboxes.length === 20
    ) {
      setHasMoreMessages(true);
      setPageForChatMessages((prevCount) => prevCount + 1);
    } else {
      setHasMoreMessages(false);
    }
    setLoader(false);
    setContactMessages(response?.data);
    setNewMessages(response?.data?.inboxes);
    setNotesList(response?.data?.notes);
  };

  const onFailureChatMessages = (error) => {
    console.log(error);
    setLoader(false);
  };

  const getChatMoreMessages = (
    number,
    channelId,
    agentName,
    agentId,
    conversationId
  ) => {
    apiGet(
      `/chat/details/${number}/${decryptedValue(
        auth.companyId
      )}/${decryptedValue(
        auth.idNumber
      )}/${channelId}?page=${pageForChatMessages}`,
      onSuccessChatMoreMessages,
      onFailureChatMoreMessages
    );
  };
  const onSuccessChatMoreMessages = (response) => {
    if (
      response.data.inboxes.length > 0 &&
      response.data.inboxes.length === 20
    ) {
      setHasMoreMessages(true);
      setPageForChatMessages((prevCount) => prevCount + 1);
    } else {
      setHasMoreMessages(false);
    }
    setPaginationCalled(true);
    // setContactMessages(response?.data);
    setNewMessages((prev) => [...response?.data?.inboxes, ...prev]);
  };
  const onFailureChatMoreMessages = (error) => {
    if (error.response.status === 400) {
      if (error.response.data.data === null) {
        setHasMoreMessages(false);
      }
    }
  };

  const getContactTags = () => {
    apiGet(`/contact/tags`, onSuccessContactTags, onFailureContactTags);
  };
  const onSuccessContactTags = (response) => {
    const updatedArray = response?.data?.map((element) => ({
      ...element,
      name: element.tagName,
    }));
    setMultiSelectTags(updatedArray);
  };
  const onFailureContactTags = (error) => {
    console.log(error);
  };
  // for notes
  const onSuccesNotes = (response) => {
    setNotesList(response?.data);
    setIsNotesCreated(false);
  };
  const onFailureNotes = (error) => {
    console.log(error);
  };

  const getAgentList = () => {
    apiGet(`/agent/list/AGENT`, onSuccessAgentList, onFailureAgentList);
  };
  const onSuccessAgentList = (response) => {
    // if (!userPermissions?.includes("LIVE-CHAT-AGENT")) {
    //   let filterData = response.data.filter((val) => {
    //     return val.maskAgentId !== decryptedValue(auth.idNumber);
    //   });
    //   setAgentList(filterData);
    // } else {
    //   setAgentList(response?.data);
    // }
    setAgentList(response?.data);
  };
  const onFailureAgentList = (error) => {
    console.log(error);
  };

  const getAgentStatus = () => {
    apiGet(`/auth/user-status`, onSuccessStatus, onFailureStatus);
  };
  const onSuccessStatus = (response) => {
    if (response?.data === "AWAY") {
      setChecked(true);
    }
  };
  const onFailureStatus = (error) => {
    console.log(error);
  };

  const bulkChatAssign = (name, id) => {
    let filterAgent = agentList?.filter((val) => {
      return (
        val?.firstName + " " + val?.lastName ===
        decryptedValue(localStorage.getItem("_userName"))
      );
    });
    let data = [];

    chatBulkMarkAsResolved.map((val) => {
      data.push({
        assignAgentId: id,
        chanelId: val.chanelId,
        companyId: decryptedValue(auth.companyId),
        currentAgentId: filterAgent[0]?.publicId,
        phoneNumber: val.number,
        transferReason: "Chat Transfer",
      });
    });
    apiPost(
      `/chat/transfer-bulk-chat`,
      onSuccessbulkChatAssign,
      onFailurebulkChatAssign,
      data
    );
  };
  const onSuccessbulkChatAssign = (response) => {
    if (response.status === 1) {
      toast.success(`Chat Assigned Successfully`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
      });
    }
  };
  const onFailurebulkChatAssign = (error) => {
    console.log(error);
  };
  // ***************************************** end apis *****************************

  //  ****************************************** start socket functions **************************
  function subscribeToChat(agentId, conversationId) {
    if (agentId === decryptedValue(auth.idNumber)) {
      if (socket && socket.readyState === WebSocket.OPEN) {
        const data = {
          agentId: agentId,
          conversationId: conversationId,
        };
        const message = { type: "subscribe-chat", data: data }; // Adjust the message structure
        socket.send(JSON.stringify(message));
      }
    }
  }
  function fetchChat(agentId) {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const data = {
        agentPublicId: agentId,
      };
      socket.send(JSON.stringify({ type: "update-chat-list", data: data }));
    }
  }

  function markRead(conversationId) {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const data = {
        conversationId: conversationId,
      };
      const message = { type: "read-message", data: data }; // Adjust the message structure
      socket.send(JSON.stringify(message));
    }
  }
  //  ****************************************** end socket functions **************************

  //  ****************************************** start functions **************************

  const handleScroll = (e) => {
    if (e?.currentTarget?.scrollTop === 0) {
      if (hasMoreMessages) {
        getChatMoreMessages(
          chatDetails.number,
          chatDetails.channel,
          chatDetails.agentName,
          chatDetails.agentId,
          chatDetails.conversationId
        );
      }
    }
  };
  const handleChecked = () => {
    if (checked === true) {
      changeAgentStatus("ONLINE");
      setChecked(false);
    } else {
      changeAgentStatus("AWAY");
      setChecked(true);
    }
  };

  const handleNotificationTune = () => {
    setNotificationTune(!notificationTune);
    localStorage.setItem("_tune", !notificationTune);
  };

  const onClickCheckbox = (type, data, index) => {
    let checkFieldsValue = $(".checkField:checked").length;
    var len = $(".chat-sec-content input:checked").length;
    if (type === "all" && len > 0) {
      setChatBulkMarkAsResolved(
        data.filter((val) => {
          return val.status !== "RESOLVED" && val.status !== "CLOSED";
        })
      );
      $(".chatContactsContainer input:checkbox").prop("checked", true);
      setConversationListCount(
        $('.chatContactsContainer input[type="checkbox"]').length
      );
    } else if (type === "contacts") {
      if (checkFieldsValue === $(".checkField").length) {
        $(".chat-sec-content input").prop("checked", true);
      } else {
        $(".chat-sec-content input:checked").prop("checked", false);
      }

      if (checkFieldsValue > 0) {
        if ($(`#contact${index}:checked`).length > 0) {
          setChatBulkMarkAsResolved((list) => [...list, data]);
        } else {
          let filtered = chatBulkMarkAsResolved.filter((val) => {
            return val.id !== $(`#contact${index}`).val();
          });
          setChatBulkMarkAsResolved(filtered);
        }
      }
      if (checkFieldsValue === 0) {
        setChatBulkMarkAsResolved([]);
      }

      setConversationListCount(
        $('.chatContactsContainer input[type="checkbox"]:checked').length
      );
    } else if (type === "all" && len === 0) {
      $(".chatContactsContainer input:checkbox").prop("checked", false);
      setConversationListCount(
        $('.chatContactsContainer input[type="checkbox"]:checked').length
      );
      setChatBulkMarkAsResolved([]);
    }
  };

  const debouncedOnChange = debounce(onChange, 1500);

  // for resolve modal
  const handleOpenResolvedModal = () => {
    setOpenResolvedModal(true);
  };
  const handleCloseResolvedModal = () => {
    setOpenResolvedModal(false);
  };
  // for filter modal
  const handleOpenSegmentFilterModal = () => {
    setOpenSegmentFilterModal(true);
  };

  const handleCLoseSegmentFilterModal = () => {
    setOpenSegmentFilterModal(false);
  };
  // for unassign conversation modal
  const handleOpenUnAssignConversationModal = () => {
    setUnAssignConversationModal(true);
  };
  const handleCloseUnAssignConversationModal = () => {
    setUnAssignConversationModal(false);
  };
  // for sms modal
  const handleOpenNewSmsModal = () => {
    setOpenNewSmsModal(true);
  };
  const handleCloseNewSmsModal = () => {
    setOpenNewSmsModal(false);
  };
  //  ****************************************** end functions **************************

  // ********************************** useeffect block start *************************
  // useEffect(() => {
  //   const myFunction = () => {
  //     fetchChat(decryptedValue(auth.idNumber));
  //   };

  //   // Set up the interval
  //   const intervalId = setInterval(myFunction, 1000); // 1000 milliseconds = 1 second

  //   // Cleanup the interval when the component unmounts
  //   return () => clearInterval(intervalId);
  // }, []);
  // useEffect(() => {
  //   if (chatContactList !== undefined) {
  //     let data = chatContactList[0];
  //     setChatFirstContactDetails(data);
  //   }
  // }, [chatContactList]);
  useEffect(() => {
    if (apicalled === true) {
      if (searchedKey === "") {
        getAllChatContacts(
          contactApiDetails.agentId,
          contactApiDetails.chatFilter
        );
      } else {
        onSearch();
      }
    }
  }, [apicalled]);
  useEffect(() => {
    if (selectedStatus === "AWAY") {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [selectedStatus]);

  useEffect(() => {
    document.body.classList.remove("visibleSplash");
    getAgentList();
    getContactTags();
    getAgentStatus();
    getQuickReply();
    getGalleryData(0);
  }, []);
  useEffect(() => {
    if (Object.keys(params).length !== 0) {
      // if (location.state !== null) {
      //   getChatMessages(
      //     location.state.number,
      //     location.state.chanelId,
      //     location.state.agentName,
      //     location.state.agentId,
      //     location.state.publicId,
      //     location.state.companyId
      //   );
      // }
      // }
      let data = JSON.parse(sessionStorage.getItem("myData"));
      getChatMessages(
        decryptedValue(params.number),
        data.chanel,
        data.agent,
        data.agentId,
        data.conversationId,
        decryptedValue(auth.companyId)
      );
    }
  }, [params]);
  useEffect(() => {
    if (isNotesCreated === true) {
      apiGet(
        `/chat/getNotes/${decryptedValue(auth.companyId)}/${
          chatDetails.number
        }`,
        onSuccesNotes,
        onFailureNotes
      );
    }
  }, [isNotesCreated]);
  useEffect(() => {
    if (
      currentPage === 0 &&
      chatContactList === undefined &&
      searchedKey !== "" &&
      hasMoreContacts === true
    ) {
      if (selectedChannel !== "ALL") {
        setSelectedChannel("ALL");
      }
      onSearch();
    } else if (
      currentPage === 0 &&
      chatContactList === undefined &&
      searchedKey === "" &&
      hasMoreContacts === true
    ) {
      if (contactApiDetails.chatFilter !== "") {
        getAllChatContacts(
          contactApiDetails.agentId,
          contactApiDetails.chatFilter,
          0,
          true
        );
      }
      setSearchedKey("");
    }
  }, [currentPage, chatContactList, searchedKey, hasMoreContacts]);

  useEffect(() => {
    setIsNewNotificationAvailble(false);
    // if (Object.keys(params).length !== 0) {
    //   setSearchedKey(decryptedValue(params.number));
    //   // $("#searchField").val(decryptedValue(params.number));

    //   console.log(searchedKey);
    //   console.log(isRemoveSearchOnSpecificChat);
    //   if (searchedKey === "") {
    //     // setIsRemoveSearchOnSpecificChat(false);
    //     // if (isRemoveSearchOnSpecificChat === true) {
    //     $("#searchField").val(decryptedValue(params.number));
    //     // }
    //   } else {
    //     $("#searchField").val($("#searchField").val());
    //   }
    // }
  }, [chatContactList]);

  // useEffect(() => {
  //   if (Object.keys(params).length !== 0) {
  //     if (searchedKey !== "") {
  //       let data = JSON.parse(sessionStorage.getItem("myData"));
  //       getChatMessages(
  //         decryptedValue(params.number),
  //         data.chanel,
  //         data.agent,
  //         data.agentId,
  //         data.conversationId,
  //         decryptedValue(auth.companyId)
  //       );
  //     }
  //   }
  // }, [searchedKey]);
  useEffect(() => {
    if (socketResponse !== undefined) {
      if (socketResponse.type == "notification-response") {
        fetchChat(decryptedValue(auth.idNumber));
      }
      if (socketResponse.type == "send-message-data-response") {
        setPaginationCalled(false);
        fetchChat(decryptedValue(auth.idNumber));
        if (
          newMessages !== undefined &&
          chatDetails.conversationId === socketResponse?.data?.conversationId
        ) {
          setNewMessages((prevMessages) => [
            ...prevMessages,
            socketResponse?.data,
          ]);
          setOpenFileLoader(false);
        }
      }

      if (socketResponse.type == "contact-list-update") {
        if (
          chatFilter === "ALL" &&
          searchedKey === "" &&
          filterData.agentId === "" &&
          filterData.toDate === null &&
          filterData.fromDate === null
        ) {
          if (selectedChannel !== "ALL") {
            let messages = socketResponse?.data.filter((val) => {
              return val.chanelId === selectedChannel;
            });
            // setNewMessages((prevMessages) => [...prevMessages, messages]);
            setNewMessages(messages);
          } else {
            // here we can check if it is ot been called pagination yet store contacts directly
            if (currentPage === 1 || currentPage === 0) {
              setChatContactList(socketResponse?.data);
            } else {
              let tempContacts = chatContactList;
              if (isNewNotificationAvailble === true) {
                setChatContactList([
                  ...socketResponse?.data,
                  ...tempContacts
                    .slice(20)
                    .filter((val) => val.id !== receivedMsgNumber),
                ]);
              }
              // else {
              //   setChatContactList([
              //     ...socketResponse?.data,
              //     ...tempContacts.slice(20),
              //   ]);
              // }
            }
          }
        }
      }
    }
  }, [socketResponse]);
  useEffect(() => {
    if (
      contactApiDetails.chatFilter !== "" &&
      contactApiDetails.agentId !== ""
    ) {
      getAllChatContacts(
        contactApiDetails.agentId,
        contactApiDetails.chatFilter,
        0
      );
      setChatContactList(undefined);
      setContactMessages(null);
    }
  }, [selectedChannel]);
  // ********************************** useEffect block end ***************************
  return (
    <>
      {/* {userPermissions !== undefined && ( */}
      <AccountNotify companyDetails={companyDetails} />
      <div className="chatwrp chatwrpMessage ">
        <div className="chatWrpContent">
          <div className="chatHeader">
            <ul
              className="uk-subnav top-tabbing "
              uk-switcher="connect: .chatTabbing"
            >
              <li>
                <a onClick={() => navigate("/chat", { replace: true })}>
                  {userPermissions.length > 0
                    ? userPermissions?.includes("LIVE-CHAT-MANAGER")
                      ? "Live Chat"
                      : userPermissions?.includes("LIVE-CHAT-AGENT") &&
                        "My Chat"
                    : ""}
                </a>
              </li>
              {
                // userPermissions?.includes("CHATBOT-CONVERSATION")
                (userPermissions?.includes("CHATBOT-CONVERSATION") ||
                  userPermissions?.includes("CHAT-BOT-MANAGER") ||
                  userPermissions?.includes("CHATBOT-SUPERVISOR")) && (
                  <li>
                    {userPermissions.length > 0 ? (
                      <a
                        onClick={() => navigate("/chat-bot", { replace: true })}
                      >
                        Bot Chat
                      </a>
                    ) : (
                      ""
                    )}
                  </li>
                )
              }
              {/* <li>
                {userPermissions.length > 0 ? (
                  <a
                    onClick={() =>
                      navigate("/internal-chat", { replace: true })
                    }
                  >
                    Internal Chat
                  </a>
                ) : (
                  ""
                )}
              </li> */}
              {/* <li>
                {userPermissions.length > 0 && isActiveChannel.length > 0 ? (
                  <a
                    onClick={() => navigate("/fb-comments", { replace: true })}
                  >
                    Facebook Comments
                  </a>
                ) : (
                  ""
                )}
              </li> */}
            </ul>

            <div className="chatSearchFilter">
              <ul className="uk-switcher chatTabbing">
                <li>
                  <div className="searchAndFilter">
                    <div className="searchInput">
                      <form
                        action=""
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <input
                          placeholder="Search through live chat conversations"
                          onChange={debouncedOnChange}
                          id="searchField"
                          // value={searchedKey}
                        />
                      </form>
                    </div>
                    <div className="filterBtn">
                      <button
                        type="button"
                        onClick={() => handleOpenSegmentFilterModal()}
                      >
                        <Filter /> <span>Filter</span>
                      </button>
                    </div>
                  </div>
                </li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div className="chatTabContent">
              {/* <div className="socialIcons">
                  <ul>
                    <li>
                      <img src={whatsappLogo} alt="" />
                      <span className="uk-badge">1</span>
                    </li>
                    <li>
                      <img src={messengerIcon} alt="" />
                      <span className="uk-badge">1</span>
                    </li>
                    <li>
                      <img src={instaIcon} alt="" />
                      <span className="uk-badge">1</span>
                    </li>
                  </ul>
                </div>  */}
              {isSmsActive !== undefined && isSmsActive.length > 0 && (
                <div className="uk-margin-right">
                  <button
                    className="msg"
                    onClick={() => {
                      handleOpenNewSmsModal();
                    }}
                  >
                    Send new sms
                  </button>
                </div>
              )}
              <div className="toggleSwitch  uk-margin-right">
                <label>
                  <Switch
                    onChange={handleChecked}
                    checked={checked}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={26}
                    height={14}
                    handleDiameter={10}
                    onColor="#ff7124"
                  />
                </label>
                <span className={checked && "toggleChecked"}>Away</span>
              </div>
              <div className="toggleSwitch">
                <label>
                  <Switch
                    onChange={() => handleNotificationTune()}
                    checked={notificationTune}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={26}
                    height={14}
                    handleDiameter={10}
                    onColor="#29a869"
                  />
                </label>
                <span className={checked && "toggleChecked"}>
                  Notification tune
                </span>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className="chatWrpItem">
          <ul className="uk-switcher chatTabbing">
            <li>
              <div className="uk-grid" uk-grid="">
                <ChatContacts
                  onClickCheckbox={onClickCheckbox}
                  getChatMessages={getChatMessages}
                  conversationListCount={conversationListCount}
                  chatContactList={chatContactList}
                  setChatContactList={setChatContactList}
                  getAllChatContacts={getAllChatContacts}
                  chatFilter={chatFilter}
                  setCurrentPage={setCurrentPage}
                  setChatFilter={setChatFilter}
                  userPermissions={userPermissions}
                  companyDetails={companyDetails}
                  setSelectedChannel={setSelectedChannel}
                  selectedChannel={selectedChannel}
                  hasMoreContacts={hasMoreContacts}
                  setHasMoreContacts={setHasMoreContacts}
                  setActiveChatIndex={setActiveChatIndex}
                  setContactMessages={setContactMessages}
                  activeChatIndex={activeChatIndex}
                  setSearchedKey={setSearchedKey}
                  searchedKey={searchedKey}
                  onSearch={onSearch}
                  setPageForChatMessages={setPageForChatMessages}
                  setIsChatStatusVisible={setIsChatStatusVisible}
                  isChatStatusVisible={isChatStatusVisible}
                  setCompanyWhatsappNumber={setCompanyWhatsappNumber}
                  companyWhatsappNumber={companyWhatsappNumber}
                />
                {conversationListCount === 0 &&
                  contactMessages !== null &&
                  loader === false && (
                    <>
                      <ChatMessages
                        contactMessages={contactMessages}
                        newMessages={newMessages}
                        paginationCalled={paginationCalled}
                        chatDetails={chatDetails}
                        setIsNotesCreated={setIsNotesCreated}
                        agentList={agentList}
                        userPermissions={userPermissions}
                        companyDetails={companyDetails}
                        fetchChat={fetchChat}
                        getAgentList={getAgentList}
                        socketResponse={socketResponse}
                        subscribeToChat={subscribeToChat}
                        openFileLoader={openFileLoader}
                        setOpenFileLoader={setOpenFileLoader}
                        setChatDetails={setChatDetails}
                        quickReply={quickReply}
                        handleScroll={handleScroll}
                        hasMoreMessages={hasMoreMessages}
                        pageForChatMessages={pageForChatMessages}
                        setQuickReply={setQuickReply}
                        companyWhatsappNumber={companyWhatsappNumber}
                        pageCount={pageCount}
                        galleryData={galleryData}
                        apiCalledGallery={apiCalledGallery}
                        setApiCalledGallery={setApiCalledGallery}
                        getGalleryData={getGalleryData}
                      />
                      <ChatProfile
                        chatDetails={chatDetails}
                        contactMessages={contactMessages}
                        notesList={notesList}
                        tags={tags}
                        multiSelectTags={multiSelectTags}
                      />
                    </>
                  )}
                {conversationListCount > 0 && (
                  <ChatNotOpen
                    conversationListCount={conversationListCount}
                    setConversationListCount={setConversationListCount}
                    bulkMarkAsResolved={bulkMarkAsResolved}
                    handleOpenResolvedModal={handleOpenResolvedModal}
                    handleCloseResolvedModal={handleCloseResolvedModal}
                    openResolvedModal={openResolvedModal}
                    modalLoader={modalLoader}
                    chatBulkMarkAsResolved={chatBulkMarkAsResolved}
                    setChatBulkMarkAsResolved={setChatBulkMarkAsResolved}
                    agentList={agentList}
                    bulkChatAssign={bulkChatAssign}
                    handleOpenUnAssignConversationModal={
                      handleOpenUnAssignConversationModal
                    }
                    handleCloseUnAssignConversationModal={
                      handleCloseUnAssignConversationModal
                    }
                    bulkChatUnassigned={bulkChatUnassigned}
                    openUnAssignConversationModal={
                      openUnAssignConversationModal
                    }
                    getAgentList={getAgentList}
                  />
                )}
                {contactMessages === null && loader === false && (
                  <ChatMsgBeforeLoadScreen />
                )}
                {/* permissionsLoader === false && <ChatMsgBeforeLoadScreen />} */}
                {/* {permissionsLoader && (
                    <div className="loaderScreen uk-width-4-5">
                      <div uk-spinner="" className="loader"></div>
                    </div>
                  )} */}
                {loader === true && (
                  <div className="uk-width-4-5 uk-padding-remove-left">
                    <div className="chatSeletionWrp">
                      <div className="chatSelectionContent">
                        <div className=" uk-width-expand spinner">
                          <div className="uk-text-center uk-position-center ">
                            <span uk-spinner="ratio: 2" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </li>
            <li></li>
          </ul>
        </div>
      </div>
      {/* )} */}
      {openSegmentFilterModal && (
        <ChatFilterModal
          closeModal={handleCLoseSegmentFilterModal}
          multiSelectTags={multiSelectTags}
          setChatContactList={setChatContactList}
          filterModalDescTxt=""
          agentList={agentList}
          filterData={filterData}
          setFilterData={setFilterData}
          setApicalled={setApicalled}
          setContactMessages={setContactMessages}
          chatFilter={chatFilter}
          setCurrentPage={setCurrentPage}
          setHasMoreContacts={setHasMoreContacts}
        />
      )}
      {openNewSmsModal && (
        <NewSmsModal
          closeModal={handleCloseNewSmsModal}
          fetchChat={fetchChat}
          companyDetails={companyDetails}
        />
      )}
    </>
  );
};

export default Chat;
