import React from "react";
import { formatChatHoverDateTime } from "../../Utils/DateFunctions";
import {
  displayMessageStatusIcon,
  formatText,
  getToolTipPosition,
  sanitizedMessage,
} from "../../Utils/chat/chatFunctions";

const TextMessage = ({ data }) => {
  return (
    <div className="txtdiv">
      {getToolTipPosition(data.isSent) === "right" &&
        displayMessageStatusIcon(data.messageStatus)}
      {data.body === null ? (
        <pre
          uk-tooltip={`title: ${formatChatHoverDateTime(
            data.timestamp
          )}; pos: ${getToolTipPosition(data.isSent)}`}
        >
          {data.body}
        </pre>
      ) : data.body.startsWith("https:") || data.body.startsWith("http:") ? (
        <pre>
          <a href={data.body} target="_blank">
            {data.body}
          </a>
        </pre>
      ) : (
        <pre
          uk-tooltip={`title: ${formatChatHoverDateTime(
            data.timestamp
          )}; pos: ${getToolTipPosition(data.isSent)}`}
          dangerouslySetInnerHTML={sanitizedMessage(formatText(data.body))}
        ></pre>
      )}
    </div>
  );
};

export default TextMessage;
