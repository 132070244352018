import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import {
  Calendar,
  Chat,
  ChevronDown,
  Download,
  Forum,
} from "@carbon/icons-react";
import whatsappLogo from "../../assets/images/whatsapp-icon-new.jpg";
import messengerIcon from "../../assets/images/messenger-icon-new.jpg";
import instaIcon from "../../assets/images/instagram-icon-new.jpg";
import demoImg from "../../assets/images/avatar-02.png";
import moment from "moment/moment";
import UIkit from "uikit";
import DoughnutChart from "../../components/Graph/DoughnutChart";
import CalendarDate from "../../components/Calendar/CalendarDate";
import LineChart from "../../components/Graph/LineChart";
import { apiGet } from "../../Utils/apiServices";
import axios from "axios";
import { useAuth } from "../../Context/AuthProvider";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";
import pakistanFlag from "../../assets/images/pakistan-flag.png";
import { dateWithAtTime } from "../../Utils/DateFunctions";
import countryJson from "../../../src/Utils/country.json";
import Pagination from "../../components/Pagination/Pagination";
import { hasPermissions } from "../../Utils/RoutePermissions";
const baseUrl = process.env.REACT_APP_BASEURL;

const ChatbotAnalytics = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const CurrentCompanyName = CompanyName(CompanyHtml);
  const { auth } = useAuth();
  const [isSelected, setIsSelected] = useState(false);
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [selectedDateFilter, setSelectedDateFilter] = useState("This month");
  const [isApiCall, setIsApiCall] = useState(false);
  const [channel, setChannel] = useState("ALL");
  const [chatbotStats, setChatbotStats] = useState(null);
  const [whatsappConversationBreakDown, setWhatsappConversationBreakDown] =
    useState();
  const [chatbotSessionAnalytics, setChatbotSessionAnalytics] = useState(null);
  const totalMessages = whatsappConversationBreakDown?.reduce(
    (sum, item) => sum + item.totalMessage,
    0
  );
  const [voiceDetails, setVoiceDetails] = useState(null);
  const navigate = useNavigate();
  const pageTabs = [
    {
      route: "/analytics",
      name: "Overview",
      permissions: ["FINANCE-DATA-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/live-chat-analytics",
      name: "Live Chat",
      permissions: ["ANALYTICS-MANAGER", "LIVE-CHAT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/chatbot-analytics",
      name: "Chatbot",
      permissions: ["CHATBOT-SUPERVISOR", "CHAT-BOT-MANAGER"],
      active: true,
      allPermissionsMandatory: true,
    },
    {
      route: "/statistics",
      name: "Statistics",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/package-mau",
      name: "Package",
      permissions: ["ANALYTICS-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/orders",
      name: "Orders",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/transfer-logs",
      name: "Transfer Logs",
      permissions: [],
      active: false,
      allPermissionsMandatory: true,
    },
  ];
  const filterDropdown = [
    // "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];

  const handleDatePickerClose = () => {
    if (selectedDateFilter === "Custom range") {
      setIsSelected(true);
    }
    UIkit.drop("#datepickerdrop").hide(true);
  };

  const getChatbotSessionAnalytics = () => {
    apiGet(
      `/detail-analytics/chatBotSessionAnalytics/${STARTDATE}/${ENDDATE}`,
      onSuccessChatbotSessionAnalytics,
      onFailureChatbotSessionAnalytics
    );
  };

  const onSuccessChatbotSessionAnalytics = (response) => {
    setIsApiCall(false);
    setIsSelected(false);

    // // console.log(response);

    // let result = {};
    // let data = response.data?.chatBotGraphs;
    // // const result = {};
    // // for (const key in data) {
    // //   if (response.data.hasOwnProperty(key)) {
    // //     result[key] = data[key].map((item) => item.totalConversation);
    // //   }
    // //   if (response.data.hasOwnProperty(key)) {
    // //     result["label"] = data[key].map((item) =>
    // //       convertTimestampToDate(item.time)
    // //     );
    // //   }
    // // }

    // const conversations = data.reduce((acc, bot) => {
    //   acc[bot.botName] = bot.graphPoints.map(
    //     (point) => point.totalBotConversation
    //   );
    //   return acc;
    // }, {});
    // // console.log(conversations);

    // const allDates = data
    //   .flatMap((bot) => bot.graphPoints.map((point) => point.date))
    //   .filter((date, index, self) => self.indexOf(date) === index)
    //   .sort((a, b) => new Date(a) - new Date(b));

    // // console.log(allDates);
    // result["data"] = conversations;
    // result["label"] = allDates;
    // // console.log(result);

    // // console.log(countNonEmptyArrays(result.data))

    setChatbotSessionAnalytics(response?.data);
    // setChatbotSessionGraph(result);
  };

  const onFailureChatbotSessionAnalytics = (error) => {
    console.log(error);
  };

  const getWhatsappConversationBreakDown = () => {
    apiGet(
      `/detail-analytics/countryBreakDown/${STARTDATE}/${ENDDATE}`,
      onSuccessWhatsappConversationBreakDown,
      onFailureWhatsappConversationBreakDown
    );
  };

  const onSuccessWhatsappConversationBreakDown = (response) => {
    // console.log(response);
    setWhatsappConversationBreakDown(response.data);
  };
  const onFailureWhatsappConversationBreakDown = (error) => {
    console.log(error);
  };

  const getLiveChatRoutes = (page) => {
    apiGet(
      // `/routing/stats?page=${page}&size=${size}`,
      `/detail-analytics/sentimentAnalysis/${STARTDATE}/${ENDDATE}`,
      onSuccessRouteStats,
      onFailureRouteStats
    );
  };

  const onSuccessRouteStats = (response) => {
    setIsApiCall(false);
    setIsSelected(false);
    setCurrentPage(1);
    // console.log(response.data.customersList);
    setVoiceDetails(response?.data);
    // setPageCount(response?.data?.routeRecords?.totalPages);
  };

  const onFailureRouteStats = (error) => {
    console.log(error);
  };

  const exportData = async () => {
    try {
      const response = await axios.get(
        baseUrl + `/bot/export/stats/filter/${STARTDATE}/${ENDDATE}`,
        {
          responseType: "arraybuffer", // Ensure the response is treated as binary data
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Chatbot.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  const onChange = (ranges) => {
    // setChatbotStats(null)
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
  };

  const handleDateFilter = (filter) => {
    // setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    if (filter !== "Custom range") {
      setIsApiCall(true);
    }
  };

  const channels = [
    {
      icon: null,
      name: "All Channels",
      apiSendName: "ALL",
      disabled: false,
    },
    // {
    //   icon: whatsAppImg,
    //   name: "WhatsApp",
    //   apiSendName: "WHATSAPP",
    //   disabled: false,
    // },
    // {
    //   icon: messengerIcon,
    //   name: "Facebook",
    //   apiSendName: "FACEBOOK",
    //   disabled: true,
    // },
    // {
    //   icon: instaIcon,
    //   name: "Instagram",
    //   apiSendName: "INSTAGRAM",
    //   disabled: true,
    // },
  ];

  // const getChatbotStats = () => {
  //   apiGet(`/bot/stats`, onSuccessStats, onFailureStats);
  // };

  // const onSuccessStats = (response) => {
  //   setIsSelected(false);
  //   setIsApiCall(false);
  //   setChatbotStats(response?.data);
  // };

  // const onFailureStats = (error) => {
  //   console.log(error);
  // };

  // const getChatbotStatsFilter = () => {
  //   apiGet(
  //     `/bot/stats/filter/${STARTDATE}/${ENDDATE}`,
  //     onSuccessStatsFilter,
  //     onFailureStatsFilter
  //   );
  // };

  // const onSuccessStatsFilter = (response) => {
  //   setIsApiCall(false);
  //   setChatbotStats(response?.data);
  // };

  // const onFailureStatsFilter = (error) => {
  //   console.log(error);
  // };

  useEffect(() => {
    // getChatbotStatsFilter();
    getChatbotSessionAnalytics();
    getWhatsappConversationBreakDown();
    getLiveChatRoutes();
  }, []);

  useEffect(() => {
    if (isSelected === true) {
      setIsApiCall(true);
    }
  }, [isSelected]);
  useEffect(() => {
    if (isApiCall === false) {
      setIsSelected(false);
    }
  }, [isApiCall]);

  useEffect(() => {
    if (isApiCall == true) {
      if (selectedDateFilter === "Custom range") {
        if (isSelected === true) {
          // setChatbotStats(null);
          // getChatbotStatsFilter();
          getChatbotSessionAnalytics();
          getWhatsappConversationBreakDown();
          getLiveChatRoutes();
        }
      } else {
        // setChatbotStats(null);
        // getChatbotStatsFilter();
        getChatbotSessionAnalytics();
        getWhatsappConversationBreakDown();
        getLiveChatRoutes();
      }
    }
  }, [isApiCall]);

  const getCountryNameByCode = (code) => {
    const country = countryJson.find((country) => country.code === code);
    return country ? country.name : "Country not found";
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return voiceDetails?.customersList?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="boradcastWrp">
        <div className="boradcastTabbing">
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #newAnalyticsTab"
          >
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                <li
                  onClick={() => {
                    navigate(val.route, { replace: true });
                  }}
                  className={val.active === true ? "uk-active" : ""}
                  key={index}
                >
                  <a>{val.name}</a>
                </li>
              ) : null;
            })}
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="newAnalyticsTab">
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                val.name === "Chatbot" ? (
                  <li key={index}>
                    <div className="overviewContent">
                      <div className="uk-container">
                        <div className="uk-grid uk-flex-middle" uk-grid="">
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsTabWrp"
                              style={{ margin: "0 0 20px" }}
                            >
                              <div className="whatappTabBtn">
                                <ul
                                  className="uk-subnav uk-subnav-pill"
                                  uk-switcher="connect: .whatsappTabBtn"
                                >
                                  <li
                                    onClick={() => {
                                      navigate("/chatbot-analytics", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Overview</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/chatbot-session-analytics", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Session analytics</a>
                                  </li>
                                  {/* <li>
                              <a href="#">Conversational analytics</a>
                            </li> */}
                                  <li
                                    onClick={() => {
                                      navigate("/chatbot-user-analytics", {
                                        replace: true,
                                      });
                                    }}
                                    className="uk-active"
                                  >
                                    <a>User analytics</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsWhatsappContent"
                              style={{ marginTop: "16px" }}
                            >
                              <ul className="uk-switcher uk-margin whatsappTabBtn">
                                <li></li>
                                <li></li>
                                <li>
                                  <div
                                    className="overviewContent uk-padding-remove"
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <div className="uk-container">
                                      <div className="overviewTopDropdown">
                                        <div
                                          className="uk-grid uk-flex-middle"
                                          uk-grid=""
                                        >
                                          <div className="uk-width-1-2">
                                            <div className="pageHeading uk-margin-remove-bottom">
                                              <h3>Chatbot User Analytics</h3>
                                            </div>
                                          </div>
                                          <div className="uk-width-1-2 btnSection">
                                            <div className="agentFilterWrp">
                                              {/* <div className="allChannelDropDown">
                                            <div className="uk-inline tableFilterDropdown broadcastChannelFilter">
                                              <button
                                                type="button"
                                                className="dropdownBtn"
                                              >
                                                All Active Channels{" "}
                                                <ChevronDown />
                                              </button>
                                              <div
                                                uk-dropdown="mode: click"
                                                className="dropdownNav"
                                              >
                                                <span className="dropdownHeading">
                                                  Channels
                                                </span>
                                                <ul>
                                                  {channels.map(
                                                    (val, index) => {
                                                      return (
                                                        <li
                                                          key={index}
                                                          className={
                                                            channel ===
                                                            val.apiSendName
                                                              ? "activeBtn"
                                                              : ""
                                                          }
                                                          onClick={() => {
                                                            setChannel(
                                                              val.apiSendName
                                                            );
                                                          }}
                                                        >
                                                          <button
                                                            type="button"
                                                            disabled={
                                                              val.disabled ===
                                                              true
                                                                ? true
                                                                : false
                                                            }
                                                            className={
                                                              val.disabled ===
                                                              true
                                                                ? "opactityBtn"
                                                                : ""
                                                            }
                                                          >
                                                            {index !== 0 && (
                                                              <img
                                                                src={val.icon}
                                                                alt=""
                                                              />
                                                            )}
                                                            {val.name}
                                                          </button>
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                                </ul>
                                              </div>
                                            </div>
                                          </div> */}
                                              <div className="uk-inline">
                                                <button className="calenderBtn rangeBtn">
                                                  <Calendar />{" "}
                                                  {selectedDateFilter}
                                                  <span className="uk-inline">
                                                    <ChevronDown />
                                                  </span>
                                                </button>
                                                <div
                                                  uk-dropdown="mode: click"
                                                  className="calendarDropdown"
                                                  id="dateDropdownSection"
                                                >
                                                  <div className="dropDownHeading">
                                                    <span>Select a range</span>
                                                  </div>
                                                  <ul>
                                                    {filterDropdown.map(
                                                      (val, index) => {
                                                        return (
                                                          <>
                                                            <li
                                                              className={
                                                                selectedDateFilter ===
                                                                val
                                                                  ? "activeBtn"
                                                                  : ""
                                                              }
                                                              key={index}
                                                              onClick={() => {
                                                                setSelectedDateFilter(
                                                                  val
                                                                );
                                                                val !==
                                                                  "Custom range" &&
                                                                  handleDateFilter(
                                                                    val
                                                                  );
                                                              }}
                                                            >
                                                              {filterDropdown.length -
                                                                1 ===
                                                              index ? (
                                                                <div className="uk-inline uk-text-left">
                                                                  <button
                                                                    className="rangeBtn"
                                                                    type="button"
                                                                  >
                                                                    <span className="rangeBtnCircle"></span>{" "}
                                                                    Custom range
                                                                  </button>
                                                                  <div
                                                                    id="datepickerdrop"
                                                                    uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                                    className="date-picker-container"
                                                                  >
                                                                    <CalendarDate
                                                                      onChange={
                                                                        onChange
                                                                      }
                                                                    />
                                                                    <div className="date-picker-btn-container uk-text-right">
                                                                      <button
                                                                        className="date-selection-btn"
                                                                        onClick={
                                                                          handleDatePickerClose
                                                                        }
                                                                      >
                                                                        Ok
                                                                      </button>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                <button
                                                                  type="button"
                                                                  className="rangeBtn"
                                                                >
                                                                  <span className="rangeBtnCircle"></span>{" "}
                                                                  {val}
                                                                </button>
                                                              )}
                                                            </li>
                                                            {filterDropdown.length -
                                                              2 ===
                                                              index && (
                                                              <hr
                                                                style={{
                                                                  margin:
                                                                    "5px 0",
                                                                }}
                                                              />
                                                            )}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="uk-width-1-1 uk-margin-remove">
                                            <div className="overviewMainContent">
                                              <div className="mainBox">
                                                <div className="boxHeading">
                                                  <span>Session summary</span>
                                                </div>
                                                <div className="boxContent">
                                                  <div
                                                    className="uk-grid uk-flex-middle"
                                                    uk-grid=""
                                                  >
                                                    <div className="uk-width-2-5">
                                                      <div className="boxTimerWrp">
                                                        <p>
                                                          <Chat /> Total Chats
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-3-5">
                                                      <div
                                                        className="uk-grid"
                                                        uk-grid=""
                                                      >
                                                        <div className="uk-width-1-2">
                                                          <div className="txtwrp timeBoxTxt">
                                                            <h2>
                                                              {chatbotSessionAnalytics ===
                                                              null ? (
                                                                <span uk-spinner=""></span>
                                                              ) : (
                                                                chatbotSessionAnalytics?.totalChats.toFixed(
                                                                  0
                                                                )
                                                              )}
                                                            </h2>
                                                            <span>
                                                              Total bot chats
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <div className="uk-width-1-2">
                                                          <div className="txtwrp timeBoxTxt">
                                                            <h2>
                                                              {chatbotSessionAnalytics ===
                                                              null ? (
                                                                <span uk-spinner=""></span>
                                                              ) : (
                                                                chatbotSessionAnalytics?.avgChats.toFixed(
                                                                  0
                                                                )
                                                              )}
                                                            </h2>
                                                            <span>
                                                              Avg. Bot chats per
                                                              user
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <hr />
                                                  <div
                                                    className="uk-grid uk-flex-middle"
                                                    uk-grid=""
                                                  >
                                                    <div className="uk-width-2-5">
                                                      <div className="boxTimerWrp">
                                                        <p>
                                                          <Forum /> Engaged
                                                          users
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-3-5">
                                                      <div
                                                        className="uk-grid"
                                                        uk-grid=""
                                                      >
                                                        <div className="uk-width-1-2">
                                                          <div className="txtwrp timeBoxTxt">
                                                            <h2>
                                                              {chatbotSessionAnalytics ===
                                                              null ? (
                                                                <span uk-spinner=""></span>
                                                              ) : (
                                                                chatbotSessionAnalytics?.engageUser.toFixed(
                                                                  0
                                                                )
                                                              )}
                                                            </h2>
                                                            <span>
                                                              Total engaged
                                                              Users
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <div className="uk-width-1-2">
                                                          <div className="txtwrp timeBoxTxt">
                                                            <h2>
                                                              {chatbotSessionAnalytics ===
                                                              null ? (
                                                                <span uk-spinner=""></span>
                                                              ) : (
                                                                chatbotSessionAnalytics?.avgEngageUser.toFixed(
                                                                  0
                                                                )
                                                              )}
                                                            </h2>
                                                            <span>
                                                              Avg. engaged bot
                                                              chats per user
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              {/* <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>Total users</span>
                                            </div>
                                            <div className="boxContent">
                                              <div
                                                className="uk-grid uk-flex-middle"
                                                uk-grid=""
                                              >
                                                <div className="uk-width-3-5">
                                                  <div className="overviewChart">
                                                    <LineChart
                                                      dataSet1={[
                                                        50, 20, 30, 25, 0, 40,
                                                        10,
                                                      ]}
                                                      dataSet2={[
                                                        20, 10, 40, 35, 22, 10,
                                                        30,
                                                      ]}
                                                      dataSet3={[
                                                        40, 20, 40, 50, 45, 30,
                                                        25,
                                                      ]}
                                                      graphLabels={[
                                                        "January",
                                                        "February",
                                                        "March",
                                                        "April",
                                                        "May",
                                                        "June",
                                                        "July",
                                                      ]}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="uk-width-2-5">
                                                  <div className="chartRecordData">
                                                    <div className="chartRecordDataWrp">
                                                      <div className="deliveryRate">
                                                        <div
                                                          className="detailHeading"
                                                          style={{
                                                            marginBottom:
                                                              "24px",
                                                          }}
                                                        >
                                                          <p>USERS</p>
                                                        </div>
                                                        <ul>
                                                          <li>
                                                            <p>
                                                              <span className="darkGreenDot"></span>
                                                              Total users
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="yellowDot"></span>
                                                              Returning users
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="greenDot"></span>
                                                              New users
                                                            </p>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div> */}

                                              <div className="mainBox">
                                                <div className="boxHeading">
                                                  <span>
                                                    Conversations break up by
                                                    country
                                                  </span>
                                                </div>
                                                <div className="boxContent">
                                                  <div
                                                    className="uk-grid"
                                                    uk-grid=""
                                                  >
                                                    <div className="uk-width-2-5">
                                                      <div className="countryDetailWrp">
                                                        <div className="detailHeading">
                                                          <p>COUNTRY</p>
                                                        </div>
                                                        <ul>
                                                          {whatsappConversationBreakDown?.map(
                                                            (val, index) => {
                                                              return (
                                                                <li key={index}>
                                                                  <img
                                                                    src={`https://flagcdn.com/20x15/${val.country.toLowerCase()}.png`}
                                                                  />
                                                                  {/* <span>Pakistan</span> */}
                                                                  <span>
                                                                    {getCountryNameByCode(
                                                                      val.country
                                                                    )}
                                                                  </span>
                                                                </li>
                                                              );
                                                            }
                                                          )}

                                                          {/* <li>
                                                  <img src={pakistanFlag} />
                                                  <span>Pakistan</span>
                                                </li>
                                                <li>
                                                  <img src={pakistanFlag} />
                                                  <span>Pakistan</span>
                                                </li>
                                                <li>
                                                  <img src={pakistanFlag} />
                                                  <span>Pakistan</span>
                                                </li> */}
                                                        </ul>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-3-5">
                                                      <div className="chartRecordData">
                                                        <div className="chartRecordDataWrp">
                                                          <div className="percentageData">
                                                            <div
                                                              className="detailHeading"
                                                              style={{
                                                                marginBottom:
                                                                  "24px",
                                                              }}
                                                            >
                                                              <p>MESSAGES</p>
                                                            </div>
                                                            <ul>
                                                              {whatsappConversationBreakDown?.map(
                                                                (
                                                                  val,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <li
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      <p>
                                                                        {
                                                                          val.totalMessage
                                                                        }
                                                                      </p>
                                                                    </li>
                                                                  );
                                                                }
                                                              )}
                                                            </ul>
                                                          </div>
                                                          <div className="progressBar">
                                                            <div
                                                              className="detailHeading"
                                                              style={{
                                                                marginBottom:
                                                                  "24px",
                                                              }}
                                                            >
                                                              <p>MESSAGES</p>
                                                            </div>
                                                            <ul>
                                                              {whatsappConversationBreakDown?.map(
                                                                (
                                                                  val,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <li
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      <progress
                                                                        className={
                                                                          "uk-progress progressDarkGreen"
                                                                        }
                                                                        value={
                                                                          val.totalMessage
                                                                        }
                                                                        max={
                                                                          totalMessages
                                                                        }
                                                                      ></progress>
                                                                    </li>
                                                                  );
                                                                }
                                                              )}

                                                              {/* <li>
                                                      <progress
                                                        className="uk-progress progressYellow"
                                                        value="50"
                                                        max="100"
                                                      ></progress>
                                                    </li>
                                                    <li>
                                                      <progress
                                                        className="uk-progress progressLightGreen"
                                                        value="20"
                                                        max="100"
                                                      ></progress>
                                                    </li>
                                                    <li>
                                                      <progress
                                                        className="uk-progress progressHigh"
                                                        value="10"
                                                        max="100"
                                                      ></progress>
                                                    </li>
                                                    <li>
                                                      <progress
                                                        className="uk-progress progressDarkOrange"
                                                        value="10"
                                                        max="100"
                                                      ></progress>
                                                    </li>
                                                    <li>
                                                      <progress
                                                        className="uk-progress progressError"
                                                        value="10"
                                                        max="100"
                                                      ></progress>
                                                    </li> */}
                                                            </ul>
                                                          </div>
                                                          <div className="percentageData">
                                                            <div
                                                              className="detailHeading"
                                                              style={{
                                                                marginBottom:
                                                                  "24px",
                                                              }}
                                                            >
                                                              <p>MESSAGES</p>
                                                            </div>
                                                            <ul>
                                                              {whatsappConversationBreakDown?.map(
                                                                (
                                                                  val,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <li>
                                                                      <p>
                                                                        {(
                                                                          (val.totalMessage /
                                                                            totalMessages) *
                                                                          100
                                                                        ).toFixed(
                                                                          2
                                                                        )}
                                                                        %
                                                                      </p>
                                                                    </li>
                                                                  );
                                                                }
                                                              )}
                                                              {/* <li>
                                                      <p>12.78%</p>
                                                    </li>
                                                    <li>
                                                      <p>3.33%</p>
                                                    </li>
                                                    <li>
                                                      <p>0.1%</p>
                                                    </li>
                                                    <li>
                                                      <p>0.1%</p>
                                                    </li>
                                                    <li>
                                                      <p>0.1%</p>
                                                    </li> */}
                                                            </ul>
                                                          </div>
                                                          <div className="percentageData">
                                                            <div
                                                              className="detailHeading"
                                                              style={{
                                                                marginBottom:
                                                                  "24px",
                                                              }}
                                                            >
                                                              <p>COST</p>
                                                            </div>
                                                            <ul>
                                                              {whatsappConversationBreakDown?.map(
                                                                (
                                                                  val,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <li
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      <p>
                                                                        $
                                                                        {val.cost.toFixed(
                                                                          0
                                                                        )}
                                                                      </p>
                                                                    </li>
                                                                  );
                                                                }
                                                              )}
                                                              {/* <li>
                                                      <p>$0.00</p>
                                                    </li>
                                                    <li>
                                                      <p>$0.00</p>
                                                    </li>
                                                    <li>
                                                      <p>$0.00</p>
                                                    </li>
                                                    <li>
                                                      <p>$0.00</p>
                                                    </li>
                                                    <li>
                                                      <p>$0.00</p>
                                                    </li> */}
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="mainBox">
                                                <div className="boxHeading">
                                                  <span>
                                                    CSAT Breakdown by Response
                                                  </span>
                                                </div>
                                                <div className="boxContent">
                                                  <div
                                                    className="uk-grid uk-flex-middle"
                                                    uk-grid=""
                                                  >
                                                    <div className="uk-width-1-4">
                                                      <div
                                                        className="chartWrp"
                                                        style={{
                                                          minHeight: "209px",
                                                          minWidth: "209px",
                                                        }}
                                                      >
                                                        {voiceDetails !==
                                                          null &&
                                                          (voiceDetails?.totalCustomers >
                                                          0 ? (
                                                            <DoughnutChart
                                                              degree={180}
                                                              backgroudColor={[
                                                                "#1CE783",
                                                                "#2F5711",
                                                                "#ff9f08",
                                                              ]}
                                                              borderColor={[
                                                                "#1CE783",
                                                                "#2F5711",
                                                                "#ff9f08",
                                                              ]}
                                                              graphlabels={[
                                                                "Happy",
                                                                "Unhappy",
                                                                "Neutral",
                                                              ]}
                                                              graphData={[
                                                                voiceDetails?.totalHappyCustomer,
                                                                voiceDetails?.totalNegativeCustomer,
                                                                voiceDetails?.totalNeutralCustomer,
                                                              ]}
                                                            />
                                                          ) : (
                                                            <DoughnutChart
                                                              degree={180}
                                                              backgroudColor={[
                                                                "#B4B4B4",
                                                              ]}
                                                              borderColor={[
                                                                "#B4B4B4",
                                                              ]}
                                                              graphData={[1]}
                                                              graphlabels={[
                                                                "-",
                                                              ]}
                                                            />
                                                          ))}
                                                        <div
                                                          className="chartData"
                                                          style={{ top: "45%" }}
                                                        >
                                                          <h3>
                                                            {voiceDetails !==
                                                              null &&
                                                            voiceDetails?.totalCustomers >
                                                              0
                                                              ? (
                                                                  ((voiceDetails?.totalHappyCustomer +
                                                                    voiceDetails?.totalNegativeCustomer +
                                                                    voiceDetails?.totalNeutralCustomer) /
                                                                    voiceDetails?.totalCustomers) *
                                                                  100
                                                                ).toFixed(2) +
                                                                "%"
                                                              : "0%"}
                                                          </h3>
                                                          <p>CAST Score</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-3-4">
                                                      <div className="chartRecordData">
                                                        <div className="chartRecordDataWrp">
                                                          <div className="deliveryRate">
                                                            <ul>
                                                              <li>
                                                                <p>
                                                                  <span className="darkGreenDot"></span>
                                                                  Happy
                                                                </p>
                                                              </li>
                                                              <li>
                                                                <p>
                                                                  <span className="lightGreenDot"></span>
                                                                  Unhappy
                                                                </p>
                                                              </li>
                                                              <li>
                                                                <p>
                                                                  <span className="darkOrange"></span>
                                                                  Neutral
                                                                </p>
                                                              </li>
                                                            </ul>
                                                          </div>

                                                          <div className="progressBar">
                                                            <ul>
                                                              <li>
                                                                <progress
                                                                  className="uk-progress progressDarkGreen"
                                                                  value={
                                                                    voiceDetails?.totalHappyCustomer
                                                                  }
                                                                  max={
                                                                    voiceDetails?.totalCustomers
                                                                  }
                                                                ></progress>
                                                              </li>
                                                              <li>
                                                                <progress
                                                                  className="uk-progress progressMidGreen"
                                                                  value={
                                                                    voiceDetails?.totalNegativeCustomer
                                                                  }
                                                                  max={
                                                                    voiceDetails?.totalCustomers
                                                                  }
                                                                ></progress>
                                                              </li>
                                                              <li>
                                                                <progress
                                                                  className="uk-progress progressMidGreen"
                                                                  value={
                                                                    voiceDetails?.totalNeutralCustomer
                                                                  }
                                                                  max={
                                                                    voiceDetails?.totalCustomers
                                                                  }
                                                                ></progress>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                          <div className="percentageData">
                                                            <ul>
                                                              <li>
                                                                <p>
                                                                  {(
                                                                    (voiceDetails?.totalHappyCustomer /
                                                                      voiceDetails?.totalCustomers) *
                                                                    100
                                                                  ).toFixed(2)}
                                                                  %
                                                                </p>
                                                              </li>
                                                              <li>
                                                                <p>
                                                                  {(
                                                                    (voiceDetails?.totalNegativeCustomer /
                                                                      voiceDetails?.totalCustomers) *
                                                                    100
                                                                  ).toFixed(2)}
                                                                  %
                                                                </p>
                                                              </li>
                                                              <li>
                                                                <p>
                                                                  {(
                                                                    (voiceDetails?.totalNeutralCustomer /
                                                                      voiceDetails?.totalCustomers) *
                                                                    100
                                                                  ).toFixed(2)}
                                                                  %
                                                                </p>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                          <div className="percentageData">
                                                            <ul>
                                                              <li>
                                                                <p>
                                                                  {
                                                                    voiceDetails?.totalHappyCustomer
                                                                  }
                                                                </p>
                                                              </li>
                                                              <li>
                                                                <p>
                                                                  {
                                                                    voiceDetails?.totalNegativeCustomer
                                                                  }
                                                                </p>
                                                              </li>
                                                              <li>
                                                                <p>
                                                                  {
                                                                    voiceDetails?.totalNeutralCustomer
                                                                  }
                                                                </p>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="mainBox">
                                                <div className="boxHeading">
                                                  <span>Dialogs</span>
                                                </div>
                                                <div className="boxFullTable tableCenter">
                                                  <table className="uk-table uk-table-striped tagsInTable">
                                                    <thead>
                                                      <tr>
                                                        <th className="uk-table-expand">
                                                          QUEUES
                                                        </th>
                                                        {/* <th>LIVE CHATS</th> */}
                                                        <th>SCORE</th>
                                                        <th>COMMENTS</th>
                                                        <th>SURVEYED</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {voiceDetails
                                                        ?.customersList.length >
                                                      0 ? (
                                                        currentTableData?.map(
                                                          (val, index) => {
                                                            return (
                                                              <tr key={index}>
                                                                <td className="channelName">
                                                                  {/* <img
                                                          src={demoImg}
                                                          alt=""
                                                        /> */}
                                                                  {val.agentName ===
                                                                  null
                                                                    ? "BOT"
                                                                    : val.agentName}
                                                                </td>
                                                                {/* <td>
                                                                {val.comments}
                                                              </td> */}
                                                                <td>
                                                                  <span
                                                                    className={
                                                                      val.sentiment ===
                                                                      "happy"
                                                                        ? "serviceInitiated"
                                                                        : "inProcessYellow"
                                                                    }
                                                                  >
                                                                    {
                                                                      val.sentiment
                                                                    }
                                                                  </span>
                                                                </td>
                                                                <td>
                                                                  {val.comments ===
                                                                  null
                                                                    ? "-"
                                                                    : val.comments}
                                                                </td>
                                                                <td>
                                                                  {dateWithAtTime(
                                                                    val.lastInteractions
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )
                                                      ) : (
                                                        <tr>
                                                          <td
                                                            colSpan={8}
                                                            className="dataNotFound"
                                                          >
                                                            {voiceDetails ===
                                                            null ? (
                                                              <div uk-spinner=""></div>
                                                            ) : (
                                                              "Data Not Found"
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )}
                                                      {/* <tr>
                                                      <td className="channelName">
                                                        <img
                                                          src={demoImg}
                                                          alt=""
                                                        />
                                                        Naveen Asim
                                                      </td>
                                                      <td>
                                                        Talking with Osama Alam
                                                      </td>
                                                      <td>
                                                        <span className="serviceInitiated">
                                                          Happy
                                                        </span>
                                                      </td>
                                                      <td>-</td>
                                                      <td>31/10/2022 18:21</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="channelName">
                                                        <img
                                                          src={demoImg}
                                                          alt=""
                                                        />
                                                        Naveen Asim
                                                      </td>
                                                      <td>
                                                        Talking with Osama Alam
                                                      </td>
                                                      <td>
                                                        <span className="inProcessYellow">
                                                          Unhappy
                                                        </span>
                                                      </td>
                                                      <td>-</td>
                                                      <td>31/10/2022 18:21</td>
                                                    </tr> */}
                                                    </tbody>
                                                  </table>
                                                  {voiceDetails?.customersList
                                                    .length > 0 && (
                                                    <div
                                                      style={{
                                                        marginRight: "50px",
                                                      }}
                                                    >
                                                      <Pagination
                                                        className="pagination-bar"
                                                        currentPage={
                                                          currentPage
                                                        }
                                                        totalCount={
                                                          voiceDetails
                                                            ?.customersList
                                                            .length
                                                        }
                                                        pageSize={PageSize}
                                                        onPageChange={(page) =>
                                                          setCurrentPage(page)
                                                        }
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                                {/* <div className="boxFullTable">
                                              <table className="uk-table uk-table-striped">
                                                <thead>
                                                  <tr>
                                                    <th className="uk-width-small">
                                                      USERS
                                                    </th>
                                                    <th className="uk-table-shrink uk-text-center">
                                                      CHANNEL
                                                    </th>
                                                    <th className="uk-table-expand">
                                                      CHATBOT
                                                    </th>
                                                    <th>USER STATUS</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      <img
                                                        src={demoImg}
                                                        alt=""
                                                      />
                                                      Naveen Asim
                                                    </td>
                                                    <td className="uk-text-center">
                                                      <img
                                                        src={whatsappLogo}
                                                        alt=""
                                                        className="uk-margin-remove"
                                                      />
                                                    </td>
                                                    <td className="channelName">
                                                      Default
                                                    </td>
                                                    <td className="uk-text-center">
                                                      Conected
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <img
                                                        src={demoImg}
                                                        alt=""
                                                      />
                                                      Naveen Asim
                                                    </td>
                                                    <td className="uk-text-center">
                                                      <img
                                                        src={whatsappLogo}
                                                        alt=""
                                                        className="uk-margin-remove"
                                                      />
                                                    </td>
                                                    <td className="channelName">
                                                      Default
                                                    </td>
                                                    <td className="uk-text-center">
                                                      Conected
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <img
                                                        src={demoImg}
                                                        alt=""
                                                      />
                                                      Naveen Asim
                                                    </td>
                                                    <td className="uk-text-center">
                                                      <img
                                                        src={whatsappLogo}
                                                        alt=""
                                                        className="uk-margin-remove"
                                                      />
                                                    </td>
                                                    <td className="channelName">
                                                      Default
                                                    </td>
                                                    <td className="uk-text-center">
                                                      Conected
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <img
                                                        src={demoImg}
                                                        alt=""
                                                      />
                                                      Naveen Asim
                                                    </td>
                                                    <td className="uk-text-center">
                                                      <img
                                                        src={whatsappLogo}
                                                        alt=""
                                                        className="uk-margin-remove"
                                                      />
                                                    </td>
                                                    <td className="channelName">
                                                      Default
                                                    </td>
                                                    <td className="uk-text-center">
                                                      Conected
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li key={index}></li>
                )
              ) : null;
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ChatbotAnalytics;
