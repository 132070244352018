import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { useAuth } from "../../Context/AuthProvider";
import Switch from "react-switch";
import { apiDelete, apiGet, apiPost } from "../../Utils/apiServices";
import { toast } from "react-toastify";
import { Edit, OverflowMenuVertical, TrashCan } from "@carbon/icons-react";
import DeleteModal from "./components/Modal/DeleteModal";
import { convertTimestamp } from "../../Utils/DateFunctions";
// import { chatBaseUrl } from "../../Constant";
import { debounce } from "lodash";
import {
  decryptedValue,
  encryptedValue,
} from "../../Utils/RouteHashConversion";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;

const User = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [users, setusers] = useState(null);
  const [openAdminDeleteModal, setOpenAdminDeleteModal] = useState(false);
  const [deletedUserPublicId, setDeletedUserPublicId] = useState();
  const [dltLoader, setDltLoader] = useState(false);

  const handleChecked = (e, id) => {
    enableUser(id);
  };

  const enableUser = (id) => {
    apiPost(`/agent/disable/${id}`, onSuccessEnable, onFailureEnable);
  };
  const onSuccessEnable = (response) => {
    if (response?.data === true) {
      toast.success("User enabled successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      getUsers();
    }
  };
  const onFailureEnable = (error) => {
    console.log(error);
  };
  const deleteUser = () => {
    setDltLoader(true);
    apiDelete(`/agent/${deletedUserPublicId}`, onSuccess, onFailure);
  };
  const onSuccess = (response) => {
    if (response.status === 200) {
      setTimeout(() => {
        setDltLoader(false);
        getUsers();
        toast.success("User deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1200);
      setTimeout(() => {
        handleCloseAdminDeleteModal();
      }, 2000);
    }
  };
  const onFailure = (error) => {
    console.log(error);
    setDltLoader(false);
  };
  const handleOpenAdminDeleteModal = (publicId) => {
    setOpenAdminDeleteModal(true);
    setDeletedUserPublicId(publicId);
  };

  const handleCloseAdminDeleteModal = () => {
    setOpenAdminDeleteModal(false);
  };

  const getUsers = () => {
    apiGet(
      `/agent/list/AGENT`,
      onSuccessUsers,
      onFailureUsers
    );
  };
  const onSuccessUsers = (response) => {
    setusers(response?.data);
  };
  const onFailureUsers = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const onChange = (e) => {
    setusers(null);
    var searchedValue = e.target.value;

    if (searchedValue !== "") {
      apiGet(
        `/agent/search/${searchedValue}`,
        onSuccessSearch,
        onFailureSearch
      );
    } else {
      getUsers();
    }
  };
  const debouncedOnChange = debounce(onChange, 1000);

  const onSuccessSearch = (response) => {
    if (response.status === 0) {
      setusers(response?.data);
    }
  };
  const onFailureSearch = (error) => {
    console.log(error);
  };

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="userWrp">
        <div className="userHeader">
          <div className="uk-grid uk-flex-middle" uk-grid="">
            <div className="uk-width-1-3">
              <div className="headerTabs">
                <ul
                  className="uk-subnav uk-subnav-pill"
                  uk-switcher="connect: .userTabbing"
                >
                  <li
                    onClick={() => {
                      navigate("/users", { replace: true });
                    }}
                  >
                    <a>Users</a>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/teams", { replace: true });
                    }}
                  >
                    <a>Teams</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="uk-width-1-3">
              <div className="headerSearch">
                <form
                  action=""
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search user through name"
                    onChange={debouncedOnChange}
                  />
                </form>
              </div>
            </div>
            <div className="uk-width-1-3">
              <ul className="uk-switcher uk-margin userTabbing">
                <li>
                  <div className="headerBtn">
                    <Link to="/add-user">Add User</Link>
                  </div>
                </li>
                <li>
                  <div className="headerBtn">
                    <Link to="/create-team">Add Team</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="userTabContent">
          <div className="uk-container uk-container-large">
            <ul className="uk-switcher uk-margin userTabbing">
              <li>
                <div className="userTableWrp">
                  <table className="uk-table">
                    <thead>
                      <tr>
                        <th>NAME</th>
                        <th>NUMBER</th>
                        <th>EMAIL</th>
                        <th>ROLE</th>
                        {/* <th>TEAMS</th> */}
                        <th>
                          <div className="uk-inline tableDropDown">
                            <button type="button">
                              STATUS
                              {/* <ChevronDown /> */}
                            </button>
                            {/* <div
                              uk-dropdown="mode: click"
                              className="dropdownContent"
                            >
                              <div className="dropdownHeading">
                                <span>Agent staus</span>
                              </div>
                              <ul>
                                <li
                                  className="active"
                                  style={{ paddingLeft: "25px" }}
                                >
                                  <button type="button">Available</button>
                                </li>
                                <li style={{ paddingLeft: "25px" }}>
                                  <button type="button">Away</button>
                                </li>
                                <li style={{ paddingLeft: "25px" }}>
                                  <button type="button">Busy</button>
                                </li>
                                <li style={{ paddingLeft: "25px" }}>
                                  <button type="button">offline</button>
                                </li>
                              </ul>
                            </div> */}
                          </div>
                        </th>
                        <th>ENABLED USER</th>
                        <th>LAST LOGIN</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users?.length > 0 ? (
                        users?.map((val, index) => {
                          return (
                            <tr key={index}>
                              <td className="agnetName">
                                <Link
                                  to={`/user-details/${encryptedValue(
                                    val.publicId
                                  )}`}
                                >
                                  {val.profilePic === null ||
                                  val.profilePic == "" ? (
                                    <span className="InitialImage">
                                      {val.firstName.substring(0, 1)}
                                    </span>
                                  ) : (
                                    <img
                                      src={chatBaseUrl + val.profilePic}
                                      alt=""
                                    />
                                  )}
                                  {val?.firstName + " " + val?.lastName}
                                </Link>
                              </td>
                              <td>{val?.phoneNumberWork}</td>
                              <td>{val?.email}</td>
                              <td className="textCapatalize">
                                {val?.role == null
                                  ? "-"
                                  : val?.role
                                      .replace("ROLE_", "")
                                      .toLowerCase()}
                              </td>
                              {/* <td>Creative</td> */}
                              <td>
                                <span
                                  className={`available ${
                                    val?.status === "ONLINE" ? "greenColor" : ""
                                  }`}
                                >
                                  {val?.status}
                                </span>
                              </td>
                              <td className="tableSwitch">
                                <Switch
                                  onChange={() =>
                                    handleChecked(val.isAvailable, val.publicId)
                                  }
                                  checked={
                                    val.isAvailable === true ? true : false
                                  }
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  width={26}
                                  height={14}
                                  handleDiameter={10}
                                  onColor="#29A869"
                                />
                              </td>
                              <td>
                                {/* {convertTimeWithDateIntoMinHourDate( */}

                                {/* {formatDistanceToNow(
                                  new Date(val?.createDateTime),
                                  {
                                    addSuffix: true,
                                  }
                                )} */}

                                {val?.lastLogin === null
                                  ? "-"
                                  : convertTimestamp(val?.lastLogin)}
                              </td>

                              <td>
                                <div className="uk-inline">
                                  <button type="button">
                                    <OverflowMenuVertical />
                                  </button>
                                  <div
                                    uk-dropdown=""
                                    className="contactBlckDrop"
                                  >
                                    <ul>
                                      <li>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            navigate(
                                              `/user-details/${encryptedValue(
                                                val.publicId
                                              )}`,
                                              {
                                                replace: true,
                                              }
                                            )
                                          }
                                        >
                                          <Edit /> Edit
                                        </button>
                                      </li>
                                      {/* <li>
                                          <button type="button">
                                            <Download /> Download data
                                          </button>
                                        </li> */}
                                      <hr
                                        style={{
                                          marginTop: "5px",
                                          marginBottom: "5px",
                                        }}
                                      />
                                      <li>
                                        <button
                                          className="trashBtn"
                                          type="button"
                                          onClick={() =>
                                            handleOpenAdminDeleteModal(
                                              val.publicId
                                            )
                                          }
                                        >
                                          <TrashCan /> Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={8} className="dataNotFound">
                            {users === null ? (
                              <div uk-spinner=""></div>
                            ) : (
                              "Data Not Found"
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {openAdminDeleteModal && (
                    <DeleteModal
                      closeModal={handleCloseAdminDeleteModal}
                      dltModalTxt="Are you sure you want to delete this user? This will affect the
                team members of selected user."
                      deleteTeam={deleteUser}
                      dltLoader={dltLoader}
                      heading={"Delete User"}
                    />
                  )}
                </div>
              </li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
