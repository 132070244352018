import React, { useState } from "react";
import {
  ChevronDown,
  ChevronLeft,
  Locked,
  Purchase,
  Calendar,
} from "@carbon/icons-react";
import { Link, useParams } from "react-router-dom";
import moment from "moment/moment";
import UIkit from "uikit";
import { apiGet } from "../../Utils/apiServices";
import CalendarDate from "../../components/Calendar/CalendarDate";
import { useEffect } from "react";
import { convertTimestamp } from "../../Utils/DateFunctions";
import debounce from "lodash.debounce";
import { useAuth } from "../../Context/AuthProvider";

const ChatBotInner = () => {
  const params = useParams();
  const [channel, setChannel] = useState("ALL");
  const [isSelected, setIsSelected] = useState(false);
  const [STARTDATE, setSTARTDATE] = useState(params.startDate);
  const [ENDDATE, setENDDATE] = useState(params.endDate);
  const [selectedDateFilter, setSelectedDateFilter] = useState("Custom range");
  const [isApiCall, setIsApiCall] = useState(false);
  const [chatbotDetailStats, setChatbotDetailStats] = useState();

  //filter
  const [searchInput, setSearchInput] = useState("");
  const [filteredChatbots, setFilteredChatbots] = useState([]);

  const onChange = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
  };
  const handleDateFilterDropdownrClose = () => {
    if (selectedDateFilter === "Custom range") {
      setIsSelected(true);
    }
    UIkit.drop("#dateDropdownSection").hide(true);
  };
  const handleDateFilter = (filter) => {
    // setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    if (filter !== "Custom range") {
      setIsApiCall(true);
    }
  };

  const filterDropdown = [
    // "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];
  const { auth } = useAuth();

  const channels = [
    {
      icon: null,
      name: "All Channels",
      apiSendName: "ALL",
      disabled: false,
    },
    // {
    //   icon: whatsAppImg,
    //   name: "WhatsApp",
    //   apiSendName: "WHATSAPP",
    //   disabled: false,
    // },
    // {
    //   icon: messengerIcon,
    //   name: "Facebook",
    //   apiSendName: "FACEBOOK",
    //   disabled: true,
    // },
    // {
    //   icon: instaIcon,
    //   name: "Instagram",
    //   apiSendName: "INSTAGRAM",
    //   disabled: true,
    // },
  ];

  const getChatbotDetailStats = () => {
    // apiGet(`/bot/stats/details`, onSuccessStats, onFailureStats);
    apiGet(
      `/bot/stats/details/${STARTDATE}/${ENDDATE}?windowName=${params.windowName}&botPublicId=${params.botPublicId}`,
      onSuccessStats,
      onFailureStats
    );
  };

  const onSuccessStats = (response) => {
    setIsApiCall(false);
    setChatbotDetailStats(response?.data);
  };

  const onFailureStats = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getChatbotDetailStats();
  }, []);

  useEffect(() => {
    if (isSelected === true) {
      setIsApiCall(true);
    }
  }, [isSelected]);

  useEffect(() => {
    if (isApiCall === false) {
      setIsSelected(false);
    }
  }, [isApiCall]);
  useEffect(() => {
    if (isApiCall == true) {
      if (selectedDateFilter === "Custom range") {
        if (isSelected === true) {
          setChatbotDetailStats(null);
          getChatbotDetailStats();
        }
      } else {
        setChatbotDetailStats(null);
        getChatbotDetailStats();
      }
    }
  }, [isApiCall]);

  useEffect(() => {
    // Initialize or fetch your chatbot data
    // For example, you might fetch data using getAgents function
    // and set it to the chatbotDetailStats state
    // getAgents(true, 0);
  }, []);

  const getAgents = (apiCalled, page) => {
    // Fetch or set your chatbot data and update the chatbotDetailStats state
    // For example:
    const chatbotData = []; // replace this with your actual data
    setChatbotDetailStats(chatbotData);
  };

  const handleSearch = () => {
    // Implement your search logic here
    const searchTerm = searchInput.trim().toLowerCase();
    const filteredData = chatbotDetailStats.filter((val) =>
      val.number.includes(searchTerm)
    );

    // Render your filtered data or update the UI accordingly
    // For example, you can store the filtered data in a separate state
    setFilteredChatbots(filteredData);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      debouncedHandleSearch();
    }
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  return (
    <div
      className="adminDetailsWrp"
      style={{ minHeight: "calc(100vh - 135px)" }}
    >
      <div className="uk-container">
        <div className="backBtnWrp">
          <div className="backBtn">
            <Link to="/chatbot-analytics">
              <ChevronLeft /> Back to Chat bot
            </Link>
          </div>
        </div>
        <div
          className="overviewContent uk-padding-remove"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="uk-container">
            <div className="overviewTopDropdown" style={{ marginTop: "32px" }}>
              <div className="uk-grid uk-flex-middle" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="pageHeading uk-margin-remove-bottom">
                    <h3>{params.windowName}</h3>
                  </div>
                </div>
                <div className="uk-width-1-2 btnSection">
                  <div className="agentFilterWrp">
                    <div className="uk-inline">
                      <button className="calenderBtn rangeBtn">
                        <Calendar /> {selectedDateFilter}
                        <span className="uk-inline">
                          <ChevronDown />
                        </span>
                      </button>
                      <div
                        uk-dropdown="mode: click"
                        className="calendarDropdown"
                        // id="dateDropdo"
                      >
                        <div className="dropDownHeading">
                          <span>Select a range</span>
                        </div>
                        <ul>
                          {filterDropdown.map((val, index) => {
                            return (
                              <>
                                <li
                                  className={
                                    selectedDateFilter === val
                                      ? "activeBtn"
                                      : ""
                                  }
                                  key={index}
                                  onClick={() => {
                                    setSelectedDateFilter(val);
                                    val !== "Custom range" &&
                                      setChatbotDetailStats(null);
                                    handleDateFilter(val);
                                  }}
                                >
                                  {filterDropdown.length - 1 === index ? (
                                    <div className="uk-inline uk-text-left">
                                      <button
                                        className="rangeBtn"
                                        type="button"
                                      >
                                        <span className="rangeBtnCircle"></span>{" "}
                                        Custom range
                                      </button>
                                      <div
                                        id="dateDropdownSection"
                                        uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                        className="date-picker-container"
                                      >
                                        <CalendarDate onChange={onChange} />
                                        <div className="date-picker-btn-container uk-text-right">
                                          <button
                                            className="date-selection-btn"
                                            onClick={
                                              handleDateFilterDropdownrClose
                                            }
                                          >
                                            Ok
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <button type="button" className="rangeBtn">
                                      <span className="rangeBtnCircle"></span>{" "}
                                      {val}
                                    </button>
                                  )}
                                </li>
                                {filterDropdown.length - 2 === index && (
                                  <hr style={{ margin: "5px 0" }} />
                                )}
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <div className="overviewMainContent">
                    <div
                      className="liveAgentTable"
                      style={{ marginTop: "40px" }}
                    >
                      <div className="searchFilter">
                        <form action="">
                          <input
                            type="text"
                            placeholder="Search by contact number"
                            onChange={(e) => {
                              setSearchInput(e.target.value);
                              debouncedHandleSearch();
                            }}
                            onKeyPress={handleKeyPress}
                          />
                        </form>
                      </div>
                      <div className="analyticsTableWrp">
                        <table className="uk-table">
                          <thead>
                            <tr>
                              <th>NUMBER</th>
                              <th className="uk-table-expand">DIALOG NAME</th>
                              {/* <th>ASSIGNED USER</th> */}

                              {/* <th className="uk-width-small">
                                <div className="uk-inline tableDropDown">
                                  <button type="button">
                                    STATUS
                                  </button>
                                </div>
                              </th> */}
                              <th className="uk-width-small">LAST CONTACT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {chatbotDetailStats
                              ?.filter((val) =>
                                val.number.includes(
                                  searchInput.trim().toLowerCase()
                                )
                              )
                              .map((val, index) => (
                                <tr key={index}>
                                  <td>{val?.number}</td>
                                  <td className="agentName">
                                    {val?.windowName}
                                  </td>
                                  <td>{convertTimestamp(val?.date)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBotInner;
