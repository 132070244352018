import React, { useEffect, useState } from "react";
import {
  ChevronDown,
  ChevronLeft,
  Locked,
  Purchase,
  Calendar,
} from "@carbon/icons-react";
import { Link, useParams } from "react-router-dom";
import moment from "moment/moment";
import UIkit from "uikit";
import CalendarDate from "../../components/Calendar/CalendarDate";
import DoughnutChart from "../../components/Graph/DoughnutChart";
import { apiGet } from "../../Utils/apiServices";
import {
  dateWithAtTime,
  convertTimestampToProfileDateTime,
} from "../../Utils/DateFunctions";
import { convertMinutes } from "../../Utils/GraphFunctions";
import { decryptedValue } from "../../Utils/RouteHashConversion";

const LiveChatAgent = () => {
  const params = useParams();
  const [channel, setChannel] = useState("ALL");
  const [STARTDATE, setSTARTDATE] = useState(params.startDate);
  const [ENDDATE, setENDDATE] = useState(params.endDate);
  const [agentDetails, setAgentDetails] = useState();
  const [selectedDateFilter, setSelectedDateFilter] = useState("This month");
  const [isApiCall, setIsApiCall] = useState(false);

  const handleDatePickerClose = () => {
    UIkit.drop("#datepickerdrop").hide(true);
  };

  useEffect(() => {
    getAgentDetailStats();
  }, []);
  const getAgentDetailStats = () => {
    apiGet(
      `/detail-analytics/singleAgentUtilization/${decryptedValue(
        params.publicId
      )}/2${STARTDATE}/${ENDDATE}`,
      onSuccessAgentDetailStats,
      onFailureAgentDetailStats
    );
  };

  const onSuccessAgentDetailStats = (response) => {
    // console.log(response);
    setIsApiCall(false);
    setAgentDetails(response?.data);
  };

  const onFailureAgentDetailStats = (error) => {
    console.log(error);
  };

  const onChange = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
    setIsApiCall(true);
  };
  const handleDateFilterDropdownrClose = () => {
    UIkit.drop("#dateDropdownSection").hide(true);
  };
  const handleDateFilter = (filter) => {
    setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    setIsApiCall(true);
  };

  const filterDropdown = [
    "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];

  const channels = [
    {
      icon: null,
      name: "All Channels",
      apiSendName: "ALL",
      disabled: false,
    },
    // {
    //   icon: whatsAppImg,
    //   name: "WhatsApp",
    //   apiSendName: "WHATSAPP",
    //   disabled: false,
    // },
    // {
    //   icon: messengerIcon,
    //   name: "Facebook",
    //   apiSendName: "FACEBOOK",
    //   disabled: true,
    // },
    // {
    //   icon: instaIcon,
    //   name: "Instagram",
    //   apiSendName: "INSTAGRAM",
    //   disabled: true,
    // },
  ];

  return (
    <div className="adminDetailsWrp">
      <div className="uk-container">
        <div className="backBtnWrp">
          <div className="backBtn">
            <Link to="/live-chat-agent">
              <ChevronLeft /> Back to Live Chat
            </Link>
          </div>
        </div>
        <div className="detailsWrp">
          <div
            className="uk-grid uk-grid-small"
            uk-grid=""
            uk-height-match="target: > div > .customStyle"
          >
            <div className="uk-width-1-3">
              <div className="customStyle">
                <div className="detailContent adminDetailBoxes heightNew">
                  <div>
                    <div className="adminImg">
                      {
                        <span className="InitialImage">
                          {agentDetails?.agentName.substring(0, 1)}
                        </span>
                      }

                      {/* <span className="InitialImage">A</span> */}
                      {/* <img src={demoImg} alt="" /> */}
                      {/* <div uk-form-custom="" className="profileImgUpload">
                          <input
                            type="file"
                            aria-label="Custom controls"
                            accept="image/*"
                          />
                          <button type="button">
                            <Camera />
                          </button>
                        </div> */}
                    </div>
                    <div className="onlineDropDownWrp">
                      <span
                        className={`markOption ${
                          agentDetails?.status === "ONLINE"
                            ? "onlineMark"
                            : "awayMark"
                        }`}
                      ></span>
                      <p className="agentStatusName">{agentDetails?.status}</p>
                      {/* <ChevronDown /> */}
                      {/* <div uk-dropdown="mode: click" className="navDropDown">
                          <ul className="uk-nav uk-dropdown-nav">
                            <li>
                              <span> User status</span>
                            </li>
                          </ul>
                        </div> */}
                    </div>
                    <div className="numberInput namewrp">
                      <form action="">
                        <input
                          type="text"
                          placeholder="Name"
                          value={agentDetails?.agentName}
                          disabled
                        />
                        {/* <button type="button">
                        <Edit />
                      </button> */}
                      </form>
                    </div>
                    <div className="numberInput">
                      <form action="">
                        <input
                          type="text"
                          placeholder="Role"
                          // value={agentDetails?.role.replace("ROLE_", "")}
                          value={agentDetails?.role}
                          readOnly
                          disabled
                        />
                        {/* <button type="button">
                        <Edit />
                      </button> */}
                      </form>
                    </div>
                    <div className="numberInput">
                      <form action="">
                        <input
                          type="text"
                          // placeholder="Enter phone number"
                          disabled
                        />
                        {/* <button type="button">
                        <Edit />
                      </button> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-2-3">
              <div className="customStyle">
                <div className="userProfile adminDetailBoxes heightNew">
                  <div>
                    <div className="boxHeading">
                      <span>User profile</span>
                    </div>
                    <div className="contentBox">
                      <div className="contentBoxHeading">
                        <span>This week usage summary</span>
                      </div>
                      <div className="txtwrp">
                        <div className="leftTxt">
                          <p>
                            Available minutes{" "}
                            <span>{agentDetails?.agentAvailableMin}</span>
                          </p>
                        </div>
                        <div className="leftTxt">
                          <p>
                            Away minutes{" "}
                            <span>{agentDetails?.agentAwayMin}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="contentBox">
                      <div className="contentBoxHeading">
                        <span>Account information</span>
                      </div>
                      <div className="txtwrp">
                        <div className="leftTxt">
                          <p>
                            <Purchase /> Last login{" "}
                            <span>
                              {
                                agentDetails?.agentLastLogin === null
                                  ? "-"
                                  : convertTimestampToProfileDateTime(
                                      agentDetails?.agentLastLogin
                                    )
                                // agentDetails?.agentLastLogin
                              }
                            </span>
                          </p>
                        </div>
                        <div className="leftTxt">
                          <p>
                            <Locked /> Last password change{" "}
                            <span>
                              {agentDetails?.lastPasswordChange === null
                                ? "-"
                                : moment(
                                    agentDetails?.lastPasswordChange
                                  ).format("DD/MM/YYYY HH:mm:ss")}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="contentBox" style={{ border: "none" }}>
                      <div className="txtwrp" style={{ display: "block" }}>
                        <div className="leftTxt uk-margin-small-bottom uk-margin-remove-right">
                          <p>
                            Enabled user{" "}
                            <span>
                              {dateWithAtTime(agentDetails?.createdDate)}
                            </span>
                          </p>
                        </div>
                        <div className="leftTxt uk-margin-remove-right">
                          <p>
                            Created by{" "}
                            <span>
                              {" "}
                              {agentDetails?.createdBy === null
                                ? "-"
                                : agentDetails?.createdBy}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="overviewContent uk-padding-remove"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="uk-container">
            <div className="overviewTopDropdown" style={{ marginTop: "32px" }}>
              <div className="uk-grid uk-flex-middle" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="pageHeading uk-margin-remove-bottom">
                    <h3>Utilization And Performance</h3>
                  </div>
                </div>
                <div className="uk-width-1-2 btnSection">
                  <div className="agentFilterWrp">
                    {/* <div className="allChannelDropDown">
                      <div className="uk-inline tableFilterDropdown broadcastChannelFilter">
                        <button type="button" className="dropdownBtn">
                          All Active Channels <ChevronDown />
                        </button>
                        <div uk-dropdown="mode: click" className="dropdownNav">
                          <span className="dropdownHeading">Channels</span>
                          <ul>
                            {channels.map((val, index) => {
                              return (
                                <li
                                  key={index}
                                  className={
                                    channel === val.apiSendName
                                      ? "activeBtn"
                                      : ""
                                  }
                                  onClick={() => {
                                    setChannel(val.apiSendName);
                                  }}
                                >
                                  <button
                                    type="button"
                                    disabled={
                                      val.disabled === true ? true : false
                                    }
                                    className={
                                      val.disabled === true ? "opactityBtn" : ""
                                    }
                                  >
                                    {index !== 0 && (
                                      <img src={val.icon} alt="" />
                                    )}
                                    {val.name}
                                  </button>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="uk-inline">
                      <button className="calenderBtn rangeBtn">
                        <Calendar /> {selectedDateFilter}
                        <span className="uk-inline">
                          <ChevronDown />
                        </span>
                      </button>
                      <div
                        uk-dropdown="mode: click"
                        className="calendarDropdown"
                        id="dateDropdownSection"
                      >
                        <div className="dropDownHeading">
                          <span>Select a range</span>
                        </div>
                        <ul>
                          {filterDropdown.map((val, index) => {
                            return (
                              <>
                                <li
                                  className={
                                    selectedDateFilter === val
                                      ? "activeBtn"
                                      : ""
                                  }
                                  key={index}
                                  onClick={() => handleDateFilter(val)}
                                >
                                  {filterDropdown.length - 1 === index ? (
                                    <div className="uk-inline uk-text-left">
                                      <button
                                        className="rangeBtn"
                                        type="button"
                                      >
                                        <span className="rangeBtnCircle"></span>{" "}
                                        Custom range
                                      </button>
                                      <div
                                        id="datepickerdrop"
                                        uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                        className="date-picker-container"
                                      >
                                        <CalendarDate onChange={onChange} />
                                        <div className="date-picker-btn-container uk-text-right">
                                          <button
                                            className="date-selection-btn"
                                            onClick={handleDatePickerClose}
                                          >
                                            Ok
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <button type="button" className="rangeBtn">
                                      <span className="rangeBtnCircle"></span>{" "}
                                      {val}
                                    </button>
                                  )}
                                </li>
                                {filterDropdown.length - 2 === index && (
                                  <hr style={{ margin: "5px 0" }} />
                                )}
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <div className="overviewMainContent">
                    <div className="mainBox">
                      <div className="boxHeading">
                        <span>Average breakdown per status</span>
                      </div>
                      <div className="boxContent">
                        <div className="uk-grid" uk-grid="">
                          <div className="uk-width-1-3 uk-first-column">
                            <div className="txtwrp">
                              <h2>
                                {agentDetails === undefined ? (
                                  <div uk-spinner=""></div>
                                ) : (
                                  convertMinutes(agentDetails?.totalLoggedTime)
                                )}
                              </h2>
                              <span>Logged in time</span>
                            </div>
                          </div>
                          <div className="uk-width-1-3">
                            <div className="txtwrp">
                              <h2>
                                {agentDetails === undefined ? (
                                  <div uk-spinner=""></div>
                                ) : (
                                  convertMinutes(agentDetails?.totalAwayTime)
                                )}
                              </h2>
                              <span>Away status</span>
                            </div>
                          </div>
                          <div className="uk-width-1-3">
                            <div className="txtwrp">
                              <h2>
                                {agentDetails === undefined ? (
                                  <div uk-spinner=""></div>
                                ) : (
                                  agentDetails?.totalOccupancy
                                )}
                              </h2>
                              <span>Occupancy</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mainBox">
                      <div className="boxHeading">
                        <span>Status distribution</span>
                      </div>
                      <div className="boxContent">
                        <div className="uk-grid" uk-grid="">
                          <div className="uk-width-1-4">
                            <div className="chartWrp">
                              {agentDetails !== undefined &&
                              agentDetails?.totalUnderCapacity +
                                agentDetails?.totalIdeal +
                                agentDetails?.totalAvailable >
                                0 ? (
                                <DoughnutChart
                                  degree={360}
                                  backgroudColor={[
                                    "#edc843",
                                    "#1ce783",
                                    "#29a869",
                                    ,
                                  ]}
                                  borderColor={[
                                    "#edc843",
                                    "#1ce783",
                                    "#29a869",
                                  ]}
                                  graphData={[
                                    agentDetails?.totalUnderCapacity,
                                    agentDetails?.totalIdeal,
                                    agentDetails?.totalAvailable,
                                  ]}
                                />
                              ) : (
                                <DoughnutChart
                                  degree={360}
                                  backgroudColor={["#B4B4B4"]}
                                  borderColor={["#B4B4B4"]}
                                  graphData={[1]}
                                  graphlabels={["-"]}
                                />
                              )}
                              <div className="chartData">
                                <h3>
                                  {agentDetails !== undefined
                                    ? agentDetails?.totalUnderCapacity === null
                                      ? "0.00%"
                                      : (
                                          (agentDetails?.totalUnderCapacity /
                                            agentDetails?.totalWorkingHours) *
                                          100
                                        ).toFixed(2)
                                    : "0.00%"}
                                </h3>
                                <p>Under capacity</p>
                              </div>
                            </div>
                          </div>
                          <div className="uk-width-3-4">
                            <div className="chartRecordData">
                              <div className="distributionGraph">
                                <div className="chartHeading">
                                  <h3>
                                    {agentDetails !== undefined
                                      ? (
                                          (agentDetails?.totalOccupancy /
                                            agentDetails?.totalWorkingHours) *
                                          100
                                        ).toFixed(0) + "%"
                                      : "0%"}
                                  </h3>
                                  <p>Occupancy</p>
                                </div>
                                <div className="progressWrp">
                                  <progress
                                    className="uk-progress progressNomral"
                                    value={agentDetails?.totalOccupancy}
                                    max={agentDetails?.totalWorkingHours}
                                  ></progress>
                                </div>
                                <div className="listWrp">
                                  <div className="listTxtwrp">
                                    <ul>
                                      <li>
                                        <p>
                                          <span className="yellowDot"></span>
                                          Under capacity
                                        </p>
                                      </li>
                                      <li>
                                        <p>
                                          <span className="lightGreenDot"></span>
                                          Idle
                                        </p>
                                      </li>
                                      <li>
                                        <p>
                                          <span className="greenDot"></span>
                                          Available
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="listNumWrp">
                                    <ul>
                                      <li>
                                        <p>
                                          {" "}
                                          {agentDetails !== undefined
                                            ? (
                                                (agentDetails?.totalUnderCapacity /
                                                  agentDetails?.totalWorkingHours) *
                                                100
                                              ).toFixed(0) + "%"
                                            : "0%"}
                                        </p>
                                      </li>
                                      <li>
                                        <p>
                                          {agentDetails !== undefined
                                            ? (
                                                (agentDetails?.totalIdeal /
                                                  agentDetails?.totalWorkingHours) *
                                                100
                                              ).toFixed(0) + "%"
                                            : "0%"}
                                        </p>
                                      </li>
                                      <li>
                                        <p>
                                          {agentDetails !== undefined
                                            ? (
                                                (agentDetails?.totalAvailable /
                                                  agentDetails?.totalWorkingHours) *
                                                100
                                              ).toFixed(0) + "%"
                                            : "0%"}
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mainBox">
                      <div className="boxHeading">
                        <span>Performance</span>
                      </div>
                      <div className="boxContent">
                        <div className="uk-grid" uk-grid="">
                          <div className="uk-width-1-3 uk-first-column">
                            <div className="txtwrp">
                              <h2>
                                {agentDetails === undefined ? (
                                  <div uk-spinner=""></div>
                                ) : (
                                  convertMinutes(
                                    agentDetails?.averageFirstResponse
                                  )
                                )}
                              </h2>
                              <span>First response time</span>
                            </div>
                          </div>
                          <div className="uk-width-1-3">
                            <div className="txtwrp">
                              <h2>
                                {" "}
                                {agentDetails === undefined ? (
                                  <div uk-spinner=""></div>
                                ) : (
                                  convertMinutes(
                                    agentDetails?.averageSolvedTime
                                  )
                                )}
                              </h2>
                              <span>Solved Time</span>
                            </div>
                          </div>
                          <div className="uk-width-1-3">
                            <div className="txtwrp">
                              <h2>
                                {" "}
                                {agentDetails === undefined ? (
                                  <div uk-spinner=""></div>
                                ) : (
                                  convertMinutes(agentDetails?.averageWaitTime)
                                )}
                              </h2>
                              <span>Waiting Time</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mainBox">
                      <div className="boxHeading">
                        <span>Live chat breakdown by conversations</span>
                      </div>
                      <div className="boxContent">
                        <div className="uk-grid" uk-grid="">
                          <div className="uk-width-1-5 uk-first-column">
                            <div className="txtwrp">
                              <h2>
                                {agentDetails === undefined ? (
                                  <div uk-spinner=""></div>
                                ) : agentDetails?.totalChats === null ? (
                                  0
                                ) : (
                                  agentDetails?.totalChats
                                )}
                              </h2>
                              <span>Total</span>
                            </div>
                          </div>
                          <div className="uk-width-1-5">
                            <div className="txtwrp">
                              <h2>
                                {agentDetails === undefined ? (
                                  <div uk-spinner=""></div>
                                ) : (
                                  agentDetails?.totalOpenChats
                                )}
                              </h2>
                              <span>Open</span>
                            </div>
                          </div>
                          <div className="uk-width-1-5">
                            <div className="txtwrp">
                              <h2>
                                {agentDetails === undefined ? (
                                  <div uk-spinner=""></div>
                                ) : (
                                  agentDetails?.totalWaiting
                                )}
                              </h2>
                              <span>Waiting</span>
                            </div>
                          </div>
                          <div className="uk-width-1-5">
                            <div className="txtwrp">
                              <h2>
                                {agentDetails === undefined ? (
                                  <div uk-spinner=""></div>
                                ) : (
                                  agentDetails?.totalSolved
                                )}
                              </h2>
                              <span>Solved</span>
                            </div>
                          </div>
                          <div className="uk-width-1-5">
                            <div className="txtwrp">
                              <h2>
                                {agentDetails === undefined ? (
                                  <div uk-spinner=""></div>
                                ) : (
                                  agentDetails?.totalClosed
                                )}
                              </h2>
                              <span>Closed</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="halfDonutSec">
                      <div
                        className="uk-grid"
                        uk-grid=""
                        uk-height-match="target: > div > .halfDonutHeight"
                      >
                        <div className="uk-width-1-2">
                          <div className="mainBox halfDonutHeight">
                            <div className="boxHeading">
                              <span>Agent's staus</span>
                            </div>
                            <div className="boxContent">
                              <div
                                className="chartWrp"
                                style={{
                                  width: "292px",
                                  height: "292px",
                                  margin: "0 auto",
                                }}
                              >
                                {agentDetails?.totalOpenChats +
                                  agentDetails?.totalWaiting +
                                  agentDetails?.totalSolved +
                                  agentDetails?.totalClosed >
                                0 ? (
                                  <DoughnutChart
                                    degree={180}
                                    backgroudColor={[
                                      "#2F5711",
                                      "#1ce783",
                                      "#ff7124",
                                      "#EDC843",
                                    ]}
                                    borderColor={[
                                      "#2F5711",
                                      "#1ce783",
                                      "#ff7124",
                                      "#EDC843",
                                    ]}
                                    graphData={[
                                      agentDetails?.totalOpenChats,
                                      agentDetails?.totalWaiting,
                                      agentDetails?.totalSolved,
                                      agentDetails?.totalClosed,
                                    ]}
                                    graphLabels={[
                                      "Open",
                                      "Waiting",
                                      "Solved",
                                      "Closed",
                                    ]}
                                  />
                                ) : (
                                  <DoughnutChart
                                    degree={180}
                                    backgroudColor={["#B4B4B4"]}
                                    borderColor={["#B4B4B4"]}
                                    graphData={[1]}
                                    graphlabels={["-"]}
                                  />
                                )}

                                <div
                                  className="chartData"
                                  style={{
                                    top: "45%",
                                    left: "10px",
                                  }}
                                >
                                  <h3>
                                    {agentDetails != undefined &&
                                    agentDetails?.totalChats > 0
                                      ? (
                                          ((agentDetails?.totalOpenChats +
                                            agentDetails?.totalWaiting +
                                            agentDetails?.totalSolved +
                                            agentDetails?.totalClosed) /
                                            agentDetails?.totalChats) *
                                          100
                                        ).toFixed(0)
                                      : "0%"}
                                  </h3>
                                  <p>Message Sent</p>
                                </div>
                              </div>
                              <div
                                className="chartDataWrapper"
                                style={{ marginTop: "40px" }}
                              >
                                <ul style={{ width: "252px" }}>
                                  <li>
                                    <p>
                                      <span className="darkGreen"></span>
                                      Open
                                    </p>
                                  </li>
                                  <li>
                                    <p>{agentDetails?.totalOpenChats}</p>
                                  </li>
                                </ul>
                                <ul style={{ width: "252px" }}>
                                  <li>
                                    <p>
                                      <span className="lightGreenDot"></span>
                                      Waiting
                                    </p>
                                  </li>
                                  <li>
                                    <p>{agentDetails?.totalWaiting}</p>
                                  </li>
                                </ul>
                                <ul style={{ width: "252px" }}>
                                  <li>
                                    <p>
                                      <span className="orangeDot"></span>
                                      Solved
                                    </p>
                                  </li>
                                  <li>
                                    <p>{agentDetails?.totalSolved}</p>
                                  </li>
                                </ul>
                                <ul style={{ width: "252px" }}>
                                  <li>
                                    <p>
                                      <span className="yellowDot"></span>
                                      Closed
                                    </p>
                                  </li>
                                  <li>
                                    <p>{agentDetails?.totalClosed}</p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="uk-width-1-2">
                          <div className="mainBox halfDonutHeight">
                            <div className="boxHeading">
                              <span>Assigned live chats</span>
                            </div>
                            <div className="boxContent">
                              <div
                                className="chartWrp"
                                style={{
                                  width: "292px",
                                  height: "292px",
                                  margin: "0 auto",
                                }}
                              >
                                {agentDetails?.totalChatTransferred +
                                  agentDetails?.totalChatTransferredFromOther >
                                0 ? (
                                  <DoughnutChart
                                    degree={180}
                                    backgroudColor={["#2F5711", "#1ce783"]}
                                    borderColor={["#2F5711", "#1ce783"]}
                                    graphData={[
                                      agentDetails?.totalChatTransferred,
                                      agentDetails?.totalChatTransferredFromOther,
                                    ]}
                                    graphlabels={[
                                      "Transfered",
                                      "Trasnfered to others",
                                    ]}
                                  />
                                ) : (
                                  <DoughnutChart
                                    degree={180}
                                    backgroudColor={["#B4B4B4"]}
                                    borderColor={["#B4B4B4"]}
                                    graphData={[1]}
                                    graphlabels={["-"]}
                                  />
                                )}
                                <div
                                  className="chartData"
                                  style={{
                                    top: "45%",
                                    left: "10px",
                                  }}
                                >
                                  <h3>
                                    {agentDetails != undefined &&
                                    agentDetails?.totalChats > 0
                                      ? (
                                          ((agentDetails?.totalChatTransferred +
                                            agentDetails?.totalChatTransferredFromOther) /
                                            agentDetails?.totalChats) *
                                          100
                                        ).toFixed(0)
                                      : "0%"}
                                  </h3>
                                  <p>Message Sent</p>
                                </div>
                              </div>
                              <div
                                className="chartDataWrapper"
                                style={{ marginTop: "40px" }}
                              >
                                <ul style={{ width: "252px" }}>
                                  <li>
                                    <p>
                                      <span className="darkGreen"></span>
                                      Chat Transferred to others
                                    </p>
                                  </li>
                                  <li>
                                    <p>{agentDetails?.totalChatTransferred}</p>
                                  </li>
                                </ul>
                                <ul style={{ width: "252px" }}>
                                  <li>
                                    <p>
                                      <span className="lightGreenDot"></span>
                                      Chat Transferred from others
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      {
                                        agentDetails?.totalChatTransferredFromOther
                                      }
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="liveAgentTable"
                      style={{ marginTop: "40px" }}
                    >
                      <div className="searchFilter">
                        <form action="">
                          <input
                            type="text"
                            placeholder="Search by number, name"
                          />
                        </form>
                      </div>
                      <div className="analyticsTableWrp">
                        <table className="uk-table">
                          <thead>
                            <tr>
                              <th>NUMBER</th>
                              <th>CUSTOMER'S NAME</th>

                              <th className="uk-width-small">
                                <div className="uk-inline tableDropDown">
                                  <button type="button">
                                    STATUS
                                    {/* <ChevronDown /> */}
                                  </button>
                                </div>
                              </th>
                              <th>TRANSFERRED TO</th>
                              <th>TRANSFERRED FROM</th>
                              <th>LAST CONTACT</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>0333 6726268</td>
                              <td className="agnetName">Naveen Asim</td>
                              <td>
                                <span className="inProcess">Solved</span>
                              </td>
                              <td>-</td>
                              <td>
                                <span className="solved">Yasmeen</span>
                              </td>
                              <td>12 Jun 2023</td>
                              <td className="broadcastTableBtn">
                                <button type="button">Pause</button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveChatAgent;
