import Multiselect from "multiselect-react-dropdown";
import React from "react";
import { useEffect } from "react";

const TagModal = ({
  closeModal,
  heading,
  fieldLabel,
  saveBtnTxt,
  clearBtn,
  multiSelectUsers,
  multiSelectAlreadySelectedUsers,
  setMultiSelectAlreadySelectedUsers,
  setUserOptions,
  addLoader,
  addUser,
}) => {
  const onSelectTags = (item) => {
    setUserOptions(item);
    setMultiSelectAlreadySelectedUsers(item);
  };
  const onRemoveTags = (item) => {
    setUserOptions(item);
    setMultiSelectAlreadySelectedUsers(item);
  };

  // const clearAllSeelctedOptions = () => {
  //   setUserOptions([]);
  //   setMultiSelectAlreadySelectedUsers([]);
  // };

  useEffect(() => {
    setUserOptions(multiSelectAlreadySelectedUsers);
  }, []);
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <h3>{heading}</h3>
        <div>
          <form onSubmit={addUser} className="uk-form-stacked">
            <div className="uk-margin">
              <label className="uk-form-label">{fieldLabel}</label>
              <div className="customChipBox">
                <Multiselect
                  id="saj"
                  options={multiSelectUsers}
                  selectedValues={multiSelectAlreadySelectedUsers}
                  onSelect={onSelectTags}
                  onRemove={onRemoveTags}
                  displayValue="name"
                />
              </div>
            </div>
            <div className="uk-margin-medium-top btnWrapSpaceBetween">
              <div>
                {/* <button className="clearBtn" onClick={clearAllSeelctedOptions}>
                  {clearBtn}
                </button> */}
              </div>
              <div>
                <button className="cnclBtn" onClick={closeModal}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className={
                    heading === "Remove tag" ? "saveBtn w-90" : "saveBtn w-70"
                  }
                >
                  {addLoader ? (
                    <div uk-spinner="" className="loader"></div>
                  ) : (
                    saveBtnTxt
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TagModal;
