import React, { useEffect, useState, useRef } from "react";
// import { baseUrl } from "../../Constant";
import axios from "axios";
import { toast } from "react-toastify";
// import { ChevronLeft } from "@carbon/icons-react";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
const baseUrl = process.env.REACT_APP_BASEURL;

const Otp = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [resendStatus, setResendStatus] = useState("");
  // const [resendText, setResendText] = useState("");
  useEffect(() => {
    if (localStorage.getItem("_otpEmail") === null) {
      navigate("/", { replace: true });
    }
  }, []);

  const resendForgetApiCall = () => {
    setResendStatus("Sending the code again");
    let forgetValue = localStorage.getItem("_otpEmail");

    axios
      .get(baseUrl + `/auth/forgetPassword/${forgetValue}`, {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      })
      .then(
        (response) => {
          if (response?.data?.message === "Otp Send Successfully") {
            setTimeout(() => {
              setResendStatus(""); // Clear the status message
            }, 1000);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };
  const handleBacktoLogin = () => {
    localStorage.clear();
    navigate("/forgot-password", { replace: true });
  };
  const handleOtp = () => {
    // let otpCode = localStorage.getItem("_otp");
    let otpCode =
      "" +
      $(`#field1`).val() +
      $(`#field2`).val() +
      $(`#field3`).val() +
      $(`#field4`).val();
    let otpemail = localStorage.getItem("_otpEmail");
    setLoader(true);
    axios
      .post(
        baseUrl + `/auth/verifyOTP-forgot-email`,
        {
          otp: otpCode,
          email: otpemail,
        },
        {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        }
      )
      .then(
        (response) => {
          if (response?.data?.message === "Success") {
            localStorage.setItem(
              "_preAuthToken",
              response?.data?.data?.preAuthToken
            );
            setTimeout(() => {
              setLoader(false);
              navigate("/change-password", { replace: true });
            }, 1500);
            setTimeout(() => {
              navigate("/change-password", { replace: true });
            }, 4000);
          }
        },
        (error) => {
          if (
            error?.response?.data?.message === "Invalid OTP!"
            //  &&
            // error?.response["status"] === 400
          ) {
            setLoader(false);
            toast.error("Invalid OTP code", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1200,
            });
            setTimeout(() => {
              navigate("/otp", { replace: true });
            }, 2500);
          } else if (
            error?.response?.data?.message ===
            "User not found with Email is not register : 'null'"
          ) {
            setLoader(false);
            toast.error("Go to Forget screen then proceeed ", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(() => {
              navigate("/forgot-password", { replace: true });
            }, 2500);
          } else if (
            error?.response?.data?.message ===
            "Device is temporarily locked please try again in a while."
          ) {
            setLoader(false);
            toast.error(
              "Device is temporarily locked Please try again in a while.",
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1200,
              }
            );
            setTimeout(() => {
              navigate("/otp", { replace: true });
            }, 2500);
          }
        }
      );
  };

  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const handleInput = (currentInput, nextInput) => {
    if (currentInput.current.value.length === 1) {
      nextInput.current.focus();
    }
  };

  const handleBackSpace = (currentInput, previousInput) => {
    if (currentInput.current.value.length === 0) {
      previousInput.current.focus();
    }
  };

  return (
    <div className="otpContainer">
      <div>
        <h2 className="heading">We have sent you an OTP</h2>
        <p className="mainDescription">
          Enter the security code we sent you to <br />
          {localStorage.getItem("_otpEmail")}
          <span className="changeTxt" onClick={handleBacktoLogin}>
            {" "}
            (change)
          </span>
        </p>
        <div className="otp-field">
          <input
            className="space"
            type="text"
            maxLength="1"
            id="field1"
            ref={inputRefs[0]}
            onInput={() => handleInput(inputRefs[0], inputRefs[1])}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                handleBackSpace(inputRefs[0], inputRefs[0]);
              }
            }}
          />
          <input
            className="space"
            type="text"
            maxLength="1"
            id="field2"
            ref={inputRefs[1]}
            onInput={() => handleInput(inputRefs[1], inputRefs[2])}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                handleBackSpace(inputRefs[1], inputRefs[0]);
              }
            }}
          />
          <input
            className="space"
            type="text"
            maxLength="1"
            id="field3"
            ref={inputRefs[2]}
            onInput={() => handleInput(inputRefs[2], inputRefs[3])}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                handleBackSpace(inputRefs[2], inputRefs[1]);
              }
            }}
          />
          <input
            className="space"
            type="text"
            maxLength="1"
            id="field4"
            ref={inputRefs[3]}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                handleBackSpace(inputRefs[3], inputRefs[2]);
              }
            }}
          />
        </div>
        <div className="btnWrapSpaceBetween uk-margin-medium-top">
          <div>
            <button className="backbtn" onClick={resendForgetApiCall}>
              <a className="otpbackbtn">I didn't receive a code</a>
            </button>
            {resendStatus && <div className="resendStatus">{resendStatus}</div>}
          </div>
          <div>
            <button
              className="saveBtn w-130"
              id="passwordResetBtn"
              onClick={handleOtp}
              disabled={loader ? true : false}
            >
              {loader ? <div uk-spinner="" className="loader"></div> : "Reset"}
            </button>
          </div>
        </div>
        {/* {resendText !== "" && <div className="otpResenMsg">Otp resend successfully</div>} */}
      </div>
    </div>
  );
};

export default Otp;
