import { ChevronLeft } from "@carbon/icons-react";
import React from "react";
import { Link } from "react-router-dom";
import whatsappIcon from "../../../assets/images/wa.png";
import messengerIcon from "../../../assets/images/channel-messanger.svg";
import instaIcon from "../../../assets/images/channel-insta.svg";
import fbIcon from "../../../assets/images/channel-facebook.svg";
import chevronDown from "../../../assets/images/caret--down.svg";
import checkmarkIcon from "../../../assets/images/checkmark-green.svg";

const WhatsappForm = () => {
  return (
    <div className="addTeamWrp">
      <div className="uk-container">
        <div className="backBtn">
          <Link to="/channels">
            <ChevronLeft /> Back
          </Link>
        </div>
        <div className="uk-grid" uk-grid="">
          <div className="uk-width-2-3">
            <div className="addTeamHeading">
              <h3>Request Channel</h3>
              <p>
                Request access to any channel and we can start aligning
                configuration with your needs.
              </p>
            </div>
          </div>
          <div className="uk-width-1-3">
            <div className="channelsDropdown">
              <div class="uk-inline">
                <button type="button" className="channelBtn">
                  <img src={whatsappIcon} alt="" /> WHATSAPP{" "}
                  <img src={chevronDown} alt="" />
                </button>
                <div uk-dropdown="mode: click" className="channelDropdownBox">
                  <div className="dropdownHeading">
                    <span>Channels</span>
                  </div>
                  <ul>
                    <li>
                      <div className="dropdownList">
                        <button type="button">
                          <img src={whatsappIcon} alt="" />
                          WhatsApp
                        </button>
                        <img src={checkmarkIcon} alt="" />
                      </div>
                    </li>
                    <li>
                      <div className="dropdownList">
                        <button type="button">
                          <img src={messengerIcon} alt="" />
                          Messanger
                        </button>
                      </div>
                    </li>
                    <li>
                      <div className="dropdownList">
                        <button type="button">
                          <img src={instaIcon} alt="" />
                          Instagram
                        </button>
                      </div>
                    </li>
                    <li>
                      <div className="dropdownList">
                        <button type="button">
                          <img src={fbIcon} alt="" />
                          Facebook Comments
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="addTeamBox channelFormBox">
          <div className="boxHeading">
            <h3 className="mb-16">Useful Information before you continue</h3>
            <p>
              Connect with customers globally and leverage LINE’s rich messaging
              features for enhanced engagement.is simply dummy text of the
              printing and typesetting industry.
            </p>
            <p>
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer
            </p>
            <p>
              It has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktopIt
              has survived not only five centuries, but also the leap into
              electronic
            </p>
            <p>
              Connect with customers globally and leverage LINE’s rich messaging
              features for enhanced engagement.is simply dummy text of the
              printing and typesetting industry.
            </p>
            <a href="">Learn more about our WhatsApp onboarding process</a>
          </div>
          <hr />
          <div className="boxHeading mb-2">
            <h3 className="mb-16">
              Complete the form below to help us understand you needs better
            </h3>
            <span>Expect a prompt response from our team.</span>
          </div>
          <div className="boxHeading mb-24">
            <h3>WhatsApp</h3>
          </div>
          <div className="formwrp">
            <form action="">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="">Select Destination Country</label>
                    <select name="" className="uk-select">
                      <option value="">Select Destination Country</option>
                    </select>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="">Industry</label>
                    <select name="" className="uk-select">
                      <option value="">Industry</option>
                    </select>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="">Scope of Work</label>
                    <input
                      type="text"
                      className="uk-input"
                      placeholder="Scope of Work"
                    />
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="">Monthly Volume</label>
                    <input
                      type="text"
                      className="uk-input"
                      placeholder="Monthly Volume"
                    />
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="">Verified Business Manager</label>
                    <select name="" className="uk-select">
                      <option value="">Verified Business Manager</option>
                    </select>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="">Primary Contact Email</label>
                    <input
                      type="text"
                      className="uk-input"
                      placeholder="Primary Contact Email"
                    />
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="">Alternate Email</label>
                    <input
                      type="text"
                      className="uk-input"
                      placeholder="Alternate Email"
                    />
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="formInput">
                    <label htmlFor="">
                    Message Content Example (example of intended message content you are planning to send)
                    </label>
                    <textarea
                      name=""
                      className="uk-textarea"
                      placeholder="Brief description..."
                    ></textarea>
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <span>
                    Once you have submitted the form, our team will review your
                    application and get back to you with further instructions.
                  </span>
                </div>
                <div className="uk-width-1-1">
                  <div className="btnwrp">
                    <button type="button" className="btn-1">
                      Cancel
                    </button>
                    <button type="button" className="btn-2">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsappForm;
