import React, { useEffect, useState } from "react";
import FacebookCommentContacts from "./components/FacebookCommentContacts";
import { useNavigate, useOutletContext } from "react-router-dom";
import { apiGet } from "../../Utils/apiServices";
import ChatMsgBeforeLoadScreen from "../chat/components/ChatMsgBeforeLoadScreen";
import FacebookCommentMessage from "./components/FacebookCommentMessage";

const FacebookComments = () => {
  const navigate = useNavigate();
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const [fbCommentMessages, setFbCommentMessages] = useState(null);
  const [nestedComments, setNestedComments] = useState(null);
  const [loader, setLoader] = useState(false);
  const [postDetails, setPostDetails] = useState(false);
  const [repliedToInfo, setRepliedToInfo] = useState(null);

  const [openViewMoreSection, setOpenViewMoreSection] = useState(false);

  const handleOpenMoreCommentSection = () => {
    setOpenViewMoreSection(true);
  };
  const handleCloseMoreCommentSection = () => {
    setOpenViewMoreSection(false);
  };

  const isActiveChannel = companyDetails?.activeChannels.filter(function (o) {
    return o.chanelId === "FACEBOOK";
  });

  const [fbContacts, setFbContacts] = useState();

  const getAllCommentContacts = () => {
    apiGet(
      `/facebook/post`,
      onSuccessCommentContacts,
      onFailureCommentContacts
    );
  };
  const onSuccessCommentContacts = (response) => {
    if (response.status === 0) {
      setFbContacts(response.data.data);
    }
  };
  const onFailureCommentContacts = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getAllCommentContacts();
  }, []);

  const getComments = (data) => {
    setLoader(true);
    setPostDetails(data);
    let postId = data.id;
    apiGet(
      `/facebook/${postId}/comments`,
      onSuccessComments,
      onFailureComments
    );
  };
  const onSuccessComments = (response) => {
    if (response.status === 0) {
      setFbCommentMessages(response.data.data);
    }

    setLoader(false);
  };

  const onFailureComments = (error) => {
    console.log(error);
    setLoader(false);
  };

  const loadMoreComments = (postId) => {
    apiGet(
      `/facebook/${postId}/comments`,
      onSuccessMoreComments,
      onFailureMoreComments
    );
  };
  const onSuccessMoreComments = (response) => {
    if (response.status === 0) {
      setTimeout(
        () => {
          setNestedComments(response.data.data);
        },
        15,
        500
      );
    }
  };

  const onFailureMoreComments = (error) => {
    console.log(error);
  };

  return (
    <>
      <div className="chatwrp chatwrpMessage ">
        <div className="chatWrpContent">
          <div className="chatHeader">
            <ul
              className="uk-subnav top-tabbing "
              uk-switcher="connect: .chatTabbing"
            >
              {(userPermissions?.includes("LIVE-CHAT-AGENT") ||
                userPermissions?.includes("LIVE-CHAT-MANAGER")) && (
                <li>
                  <a onClick={() => navigate("/chat", { replace: true })}>
                    {userPermissions.length > 0
                      ? userPermissions?.includes("LIVE-CHAT-MANAGER")
                        ? "Live Chat"
                        : userPermissions?.includes("LIVE-CHAT-AGENT") &&
                          "My Chat"
                      : ""}
                  </a>
                </li>
              )}
              {(userPermissions?.includes("CHATBOT-CONVERSATION") ||
                userPermissions?.includes("CHAT-BOT-MANAGER") ||
                userPermissions?.includes("CHATBOT-SUPERVISOR")) && (
                <li>
                  {userPermissions.length > 0 ? (
                    <a onClick={() => navigate("/chat-bot", { replace: true })}>
                      Bot Chat
                    </a>
                  ) : (
                    ""
                  )}
                </li>
              )}
              <li>
                {userPermissions.length > 0 ? (
                  <a
                    onClick={() =>
                      navigate("/internal-chat", { replace: true })
                    }
                  >
                    Internal Chat
                  </a>
                ) : (
                  ""
                )}
              </li>
              <li className="uk-active">
                {userPermissions.length > 0 && isActiveChannel.length > 0 ? (
                  <a
                    onClick={() => navigate("/fb-comments", { replace: true })}
                  >
                    Facebook Comments
                  </a>
                ) : (
                  ""
                )}
              </li>
            </ul>

            <div className="chatSearchFilter">
              <ul className="uk-switcher chatTabbing">
                {(userPermissions?.includes("LIVE-CHAT-AGENT") ||
                  userPermissions?.includes("LIVE-CHAT-MANAGER")) && <li></li>}
                {(userPermissions?.includes("CHATBOT-CONVERSATION") ||
                  userPermissions?.includes("CHAT-BOT-MANAGER") ||
                  userPermissions?.includes("CHATBOT-SUPERVISOR")) && <li></li>}

                <li></li>
                <li></li>
              </ul>
            </div>
            {/* <div className="chatTabContent">
              <div className="toggleSwitch  uk-margin-right">
                <label>
                  <Switch
                    onChange={handleChecked}
                    checked={checked}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={26}
                    height={14}
                    handleDiameter={10}
                    onColor="#ff7124"
                  />
                </label>
                <span className={checked && "toggleChecked"}>Away</span>
              </div>
              <div className="toggleSwitch">
                <label>
                  <Switch
                    onChange={() => handleNotificationTune()}
                    checked={notificationTune}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={26}
                    height={14}
                    handleDiameter={10}
                    onColor="#29a869"
                  />
                </label>
                <span className={checked && "toggleChecked"}>
                  Notification tune
                </span>
              </div>
            </div> */}
          </div>
          <hr />
        </div>
        <div className="chatWrpItem">
          <ul className="uk-switcher chatTabbing">
            {(userPermissions?.includes("LIVE-CHAT-AGENT") ||
              userPermissions?.includes("LIVE-CHAT-MANAGER")) && <li></li>}
            {(userPermissions?.includes("CHATBOT-CONVERSATION") ||
              userPermissions?.includes("CHAT-BOT-MANAGER") ||
              userPermissions?.includes("CHATBOT-SUPERVISOR")) && <li></li>}
            <li></li>
            <li>
              <div className="uk-grid" uk-grid="">
                <FacebookCommentContacts
                  fbContacts={fbContacts}
                  getComments={getComments}
                />

                {fbCommentMessages === null && loader === false && (
                  <ChatMsgBeforeLoadScreen />
                )}

                {fbCommentMessages !== null && loader === false && (
                  <FacebookCommentMessage
                    fbCommentMessages={fbCommentMessages}
                    setFbCommentMessages={setFbCommentMessages}
                    loadMoreComments={loadMoreComments}
                    handleOpenMoreCommentSection={handleOpenMoreCommentSection}
                    handleCloseMoreCommentSection={
                      handleCloseMoreCommentSection
                    }
                    nestedComments={nestedComments}
                    setNestedComments={setNestedComments}
                    setRepliedToInfo={setRepliedToInfo}
                    repliedToInfo={repliedToInfo}
                  />
                )}

                {loader === true && (
                  <div className="uk-width-4-5 uk-padding-remove-left">
                    <div className="chatSeletionWrp">
                      <div className="chatSelectionContent">
                        <div className=" uk-width-expand spinner">
                          <div className="uk-text-center uk-position-center ">
                            <span uk-spinner="ratio: 2" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </li>
          </ul>
        </div>

        {/* {openViewMoreSection && <ViewMoreCanvas />} */}
        {/* <ViewMoreCanvas /> */}
      </div>
    </>
  );
};

export default FacebookComments;
