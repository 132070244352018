import React from "react";
import { useState } from "react";
import { apiPost } from "../../../../Utils/apiServices";
import $ from "jquery";
import { useAuth } from "../../../../Context/AuthProvider";
import { decryptedValue } from "../../../../Utils/RouteHashConversion";
const ChatBotFilter = ({
  filterModalDescTxt,
  closeModal,
  filterData,
  setFilterData,
  setApicalled,
  setBotChatContactList,
  setBotContactMessages
}) => {
  const { auth } = useAuth();

  const [dateError, setDateError] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  const handleFromDateErrors = (value) => {
    setError("");
    if (filterData.toDate === null) {
      setDateError("To Date is Missing");
    }
    if (filterData.toDate !== null && value !== "") {
      if (value > filterData.toDate) {
        setDateError("From date must be lesser than to date");
      } else {
        setDateError("");
      }
    }
  };
  const handleToDateErrors = (value) => {
    setError("");
    if (filterData.fromDate === null) {
      setDateError("From Date is Missing");
    }
    if (filterData.fromDate !== null && value !== "") {
      if (value < filterData.fromDate) {
        setDateError("To date must be greater than From date");
      } else {
        setDateError("");
      }
    }
  };

  const searchAndFilter = () => {
    var Errorvariable = "";
    const data = {
      dateStart: filterData.fromDate,
      dateEnd: filterData.toDate,
      country: filterData.country,
      city: filterData.city,
      gender: filterData.gender,
      contactTags: filterData.tags,
      status: filterData.status,
      searchQuery: "",
      companyId: decryptedValue(auth.companyId),
      agentId: filterData.agentId,
    };

    if (filterData.fromDate === null && filterData.toDate === null) {
      Errorvariable = "Please select date for filter";
      setError(Errorvariable);
    }
    if (Errorvariable === "" && dateError === "") {
      setBotContactMessages(null)
      setLoader(true);
      apiPost(`/chat/search-chat-filter`, onSuccess, onFailure, data);
    }
  };
  const onSuccess = (response) => {
    if (response.status === 1) {
      setTimeout(() => {
        setLoader(false);
        closeModal();
      }, 2000);
      setTimeout(() => {
        setBotChatContactList(response.data);
      }, 2200);
    }
  };
  const onFailure = (error) => {
    console.log(error);
  };
  const clearFilter = () => {
    $("#fromDate").val("");
    $("#toDate").val("");
    setApicalled(true);
    setFilterData({
      fromDate: null,
      toDate: null,
      country: "",
      city: "",
      gender: "",
      Status: "",
      searchQuery: "",
      countryList: "",
      tags: [],
      agentId: "",
    });
  };

  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>Search fIlter</span>
          </div>
          <div className="modalForm">
            <div className="formHeading">
              <span>{filterModalDescTxt}</span>
            </div>
            <form action="">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="formInputs">
                    <label htmlFor="from_date">From date</label>
                    <input
                      id="fromDate"
                      type="date"
                      className="uk-input"
                      onChange={(e) => {
                        setFilterData({
                          ...filterData,
                          fromDate: e.target.value,
                        });
                        handleFromDateErrors(e.target.value);
                      }}
                      value={filterData.fromDate}
                    />
                  </div>
                </div>

                <div className="uk-width-1-2">
                  <div className="formInputs">
                    <label htmlFor="to_date">To date</label>
                    <input
                      id="toDate"
                      type="date"
                      className="uk-input"
                      onChange={(e) => {
                        setFilterData({
                          ...filterData,
                          toDate: e.target.value,
                        });
                        handleToDateErrors(e.target.value);
                      }}
                      value={filterData.toDate}
                    />
                  </div>
                </div>
                {dateError !== "" && (
                  <div className="uk-width-1-1 formErrors">{dateError}</div>
                )}
                {error !== "" && (
                  <div className="uk-width-1-1 formErrors">{error}</div>
                )}
                <div className="uk-width-1-2">
                  <div className="clearFilterBtn">
                    <button type="button" onClick={() => clearFilter()}>
                      Clear filter
                    </button>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="btnwrp">
                    <button
                      type="button"
                      className="btn-1"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn-2 w-90"
                      onClick={searchAndFilter}
                    >
                      {loader ? (
                        <div uk-spinner="" className="loader"></div>
                      ) : (
                        "Search"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBotFilter;
