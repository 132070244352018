import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({
  dataSet1,
  dataSet2,
  dataSet3,
  dataSet4,
  dataSet5,
  graphLabels,
  labelsForHover,
}) => {
  // console.log(dataSet1);
  // console.log(graphLabels);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  const labels = graphLabels || [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: labelsForHover !== undefined ? labelsForHover[0] : "Dataset 1",
        // borderColor: "rgb(255, 99, 132)",
        // backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderColor: "#1EB954",
        backgroundColor: "#1EB954",
        data: dataSet1,
        // data: [50, 20, 30, 25, 0, 40, 10],
      },
      {
        label: labelsForHover !== undefined ? labelsForHover[1] : "Dataset 2",
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        data: dataSet2,
      },
      {
        label: labelsForHover !== undefined ? labelsForHover[2] : "Dataset 3",
        // borderColor: "#1EB954",
        // backgroundColor: "#1EB954",

        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        data: dataSet3,
      },
      {
        label: labelsForHover !== undefined ? labelsForHover[3] : "Dataset 3",
        borderColor: "#1EDE54",
        backgroundColor: "#1EDE54",
        data: dataSet4,
      },
      {
        label: labelsForHover !== undefined ? labelsForHover[4] : "Dataset 3",
        borderColor: "#1GB954",
        backgroundColor: "#1GB954",
        data: dataSet5,
      },
    ],
  };
  return (
    <>
      <Line options={options} data={data} />
    </>
  );
};

export default LineChart;
