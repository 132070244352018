import React from "react";
import ContactFormTab from "./ContactFormTab";
import LiveChatFormTab from "./LiveChatFormTab";
import CustomizeFormTab from "./CustomizeFormTab";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import { useOutletContext } from "react-router-dom";

const FormCentral = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="formCentalWrp">
        <div className="uk-grid" uk-grid="">
          <div className="uk-width-1-1">
            <div className="fromCentralHeader">
              <p>Forms Central</p>
            </div>
          </div>
        </div>

        <div className="analyticsContainer formCentralContainer">
          <div className="uk-container">
            <ul
              className="uk-subnav uk-subnav-pill"
              uk-switcher="connect: #analyticsTabs"
            >
              {/* <li>
                <a>Contact Forms</a>
              </li> */}
              <li>
                <a>Live Chats Forms</a>
              </li>
              {/* <li>
                <a>Customize Forms</a>
              </li> */}
            </ul>

            <div className="tabContent">
              <ul className="uk-switcher uk-margin" id="analyticsTabs">
                {/* <li>
                  <ContactFormTab />
                </li> */}
                <li>
                  <LiveChatFormTab companyDetails={companyDetails} />
                </li>
                {/* <li>
                    <CustomizeFormTab />
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormCentral;
