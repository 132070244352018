import React from "react";
import { Link } from "react-router-dom";

const SolutionBox = ({
  solutionBoxImg,
  solutionBoxHeading,
  solutionBoxTxt,
  solutionBoxBtn,
  btnLink,
  hidden,
}) => {
  return (
    <div className="contentBox solutionBox">
      <div className="imgwrp uk-flex uk-flex-middle uk-flex-between">
        <img src={solutionBoxImg} alt="" />
        <span className="comingSoonTxt" hidden={hidden}>
          Coming soon
        </span>
      </div>
      <div className="txtwrp">
        <h3>{solutionBoxHeading}</h3>
        <p>{solutionBoxTxt}</p>
      </div>
      <div className="btnwrp">
        <Link to={btnLink}>{solutionBoxBtn}</Link>
        {/* <Link to="/broadcast">{solutionBoxBtn}</Link> */}
      </div>
    </div>
  );
};

export default SolutionBox;
