import React, { useEffect } from "react";
import { apiPost } from "../../../../Utils/apiServices";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../../../Context/AuthProvider";
import { decryptedValue } from "../../../../Utils/RouteHashConversion";

const NewTagModal = ({
  closeModal,
  setApiCalled,
  heading,
  description,
  btnText,
  editData,
}) => {
  const { auth } = useAuth();
  const [loader, setLoader] = useState(false);
  const [errorEnable, setErrorEnable] = useState(false);
  const [tagName, setTagName] = useState("");

  const addTag = () => {
    setLoader(true);
    setErrorEnable(true);
    let data = {
      tagName: tagName,
      tagPrefix: tagName.replaceAll(" ", "_"),
      companyPublicId: decryptedValue(auth?.companyId),
    };
    if (btnText === "Update") {
      data.id = editData?.id;
    }
    if (tagName !== "" && data.tagName !== editData?.tagName) {
      apiPost(`/contact/tags/create`, onSucces, onFailure, data);
    } else if (btnText === "Update") {
      closeModal();
    } else {
      setLoader(false);
    }
  };
  const onSucces = (response) => {
    setTimeout(() => {
      setApiCalled(true);
      setLoader(false);
      toast.success(
        btnText === "Update"
          ? "Tag updated successfully"
          : "Tag added successfully",
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        }
      );
    }, 1000);
    setTimeout(() => {
      closeModal();
    }, 2000);
  };
  const onFailure = (error) => {
    console.log(error);
  };
  useEffect(() => {
    if (editData !== undefined) {
      setTagName(editData.tagName);
    }
  }, [editData]);
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>{heading}</span>
          </div>
          <div className="modalForm">
            <div className="segmentModalTxt">
              <span>{description}</span>
            </div>
            <form action="">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <label htmlFor="">Tag name</label>
                    <input
                      type="text"
                      className="uk-input"
                      style={{ width: "100%" }}
                      placeholder="Please enter tag name"
                      onChange={(e) => {
                        setTagName(e.target.value);
                      }}
                      defaultValue={editData?.tagName}
                    />
                    {tagName === "" && errorEnable && (
                      <div className="formErrors">tag name is required</div>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="btnwrp">
                    <button
                      type="button"
                      className="btn-1"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button type="button" className="btn-2" onClick={addTag}>
                      {loader ? (
                        <div uk-spinner="" className="loader"></div>
                      ) : (
                        btnText
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTagModal;
