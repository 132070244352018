import React, { useState } from "react";
import { Add, ChevronDown, ChevronLeft, TrashCan } from "@carbon/icons-react";
import Multiselect from "multiselect-react-dropdown";
import Switch from "react-switch";
import { useAuth } from "../../Context/AuthProvider";
import { apiGet, apiPost } from "../../Utils/apiServices";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { decryptedValue } from "../../Utils/RouteHashConversion";

const CreateNewRouting = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { auth } = useAuth();
  const [checked, setChecked] = useState(false);
  const [errorEnable, setErrorEnable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [teams, setTeams] = useState();
  const [routingEditData, setRoutingEditData] = useState();
  const [segmentCountry, setSegmentCountry] = useState([]);
  // const [teamMember, setTeamMember] = useState();
  const [users, setusers] = useState();
  const [selectedMembers, setSelectedMembers] = useState({
    // teamMembers: [],
    userMembers: [],
  });
  const [submittedData, setSubmittedData] = useState({
    name: "",
    channel: "",
    assignType: "",
    selectedTeamPublicId: "",
  });
  const [tags, setTags] = useState([]);
  const [formItems, setFormItems] = useState([]);
  const [conditionError, setConditionError] = useState([]);
  const [tagNameError, setTagNameError] = useState([]);
  const [tagTypeError, setTagTypeError] = useState([]);
  const [tagDropDownOptions, setTagDropDownOptions] = useState([
    // {
    //   name: "Gender",
    //   value: "GENDER",
    //   disabled: false,
    // },
    // {
    //   name: "Country",
    //   value: "COUNTRY",
    //   disabled: false,
    // },
    {
      name: "Tag",
      value: "TAG",
      disabled: false,
    },
    {
      name: "Number",
      value: "NUMBER",
      disabled: false,
    },
  ]);
  const [daysData, setDaysData] = useState([
    {
      dayOff: false,
      day: "Monday",
      startTime: "",
      endTime: "",
      // companyId: "",
      // id: "",
      // publicId: "",
    },
    {
      day: "Tuesday",
      dayOff: false,
      startTime: "",
      endTime: "",
      // companyId: "",
      // id: "",
      // publicId: "",
    },
    {
      day: "Wednesday",
      dayOff: false,
      startTime: "",
      endTime: "",
      // companyId: "",
      // id: "",
      // publicId: "",
    },
    {
      day: "Thursday",
      dayOff: false,
      startTime: "",
      endTime: "",
      // companyId: "",
      // id: "",
      // publicId: "",
    },
    {
      day: "Friday",
      dayOff: false,
      startTime: "",
      endTime: "",
      // companyId: "",
      // id: "",
      // publicId: "",
    },
    {
      day: "Saturday",
      dayOff: false,
      startTime: "",
      endTime: "",
      // companyId: "",
      // id: "",
      // publicId: "",
    },
    {
      day: "Sunday",
      dayOff: false,
      startTime: "",
      endTime: "",
      // companyId: "",
      // id: "",
      // publicId: "",
    },
  ]);
  const [daysDataCustomized, setDaysDataCustomized] = useState([
    {
      dayOff: false,
      day: "Monday",
      startTime: "",
      endTime: "",
    },
    {
      day: "Tuesday",
      dayOff: false,
      startTime: "",
      endTime: "",
    },
    {
      day: "Wednesday",
      dayOff: false,
      startTime: "",
      endTime: "",
    },
    {
      day: "Thursday",
      dayOff: false,
      startTime: "",
      endTime: "",
    },
    {
      day: "Friday",
      dayOff: false,
      startTime: "",
      endTime: "",
    },
    {
      day: "Saturday",
      dayOff: false,
      startTime: "",
      endTime: "",
    },
    {
      day: "Sunday",
      dayOff: false,
      startTime: "",
      endTime: "",
    },
  ]);

  const [workingHours, setWorkingHours] = useState();
  const [workingHoursType, setWorkingHoursType] = useState("Default");
  const routingAssignOptions = ["Team", "User"];
  const handleChecked = () => {
    setChecked(true);
  };

  useEffect(() => {
    if (params.publicId !== undefined) {
      getRoutingDetails();
    }
    getCountry();
  }, []);

  const getRoutingDetails = () => {
    apiGet(
      `/routing/${params.publicId}`,
      onSuccessRoutingDetails,
      onFailureRoutingDetails
    );
  };

  const onSuccessRoutingDetails = (response) => {
    setRoutingEditData(response);
  };
  const onFailureRoutingDetails = (error) => {
    console.log(error);
  };
  const getCountry = () => {
    apiGet(
      `/segment/country/${decryptedValue(auth?.companyId)}`,
      onSuccessCountry,
      onFailureCountry
    );
  };
  const onSuccessCountry = (response) => {
    var formattedData = response?.data.map((item) => ({
      name: item,
      value: item,
      selected: false,
    }));
    var selectedOption = [
      {
        name: "Select country",
        value: "",
        selected: true,
      },
    ];
    var data = selectedOption.concat(formattedData);
    setSegmentCountry(data);
  };
  const onFailureCountry = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (routingEditData !== undefined) {
      let type =
        (routingEditData?.routeDestination?.type === "AGENT" && "User") ||
        (routingEditData?.routeDestination?.type === "TEAM" && "Team");
      setSubmittedData({
        name: routingEditData?.name,
        channel: routingEditData?.channel,
        assignType: type,
        selectedTeamPublicId: routingEditData?.routeDestination.value,
      });
      setWorkingHoursType(
        routingEditData?.isDefaultWorkingHour === true
          ? "Default"
          : "Customized"
      );
      setDaysDataCustomized([
        {
          dayOff: routingEditData?.workHours[0].dayOff,
          day: "Monday",
          startTime: routingEditData?.workHours[0].startTime,
          endTime: routingEditData?.workHours[0].endTime,
        },
        {
          day: "Tuesday",
          dayOff: routingEditData?.workHours[1].dayOff,
          startTime: routingEditData?.workHours[1].startTime,
          endTime: routingEditData?.workHours[1].endTime,
        },
        {
          day: "Wednesday",
          dayOff: routingEditData?.workHours[2].dayOff,
          startTime: routingEditData?.workHours[2].startTime,
          endTime: routingEditData?.workHours[2].endTime,
        },
        {
          day: "Thursday",
          dayOff: routingEditData?.workHours[3].dayOff,
          startTime: routingEditData?.workHours[3].startTime,
          endTime: routingEditData?.workHours[3].endTime,
        },
        {
          day: "Friday",
          dayOff: routingEditData?.workHours[4].dayOff,
          startTime: routingEditData?.workHours[4].startTime,
          endTime: routingEditData?.workHours[4].endTime,
        },
        {
          day: "Saturday",
          dayOff: routingEditData?.workHours[5].dayOff,
          startTime: routingEditData?.workHours[5].startTime,
          endTime: routingEditData?.workHours[5].endTime,
        },
        {
          day: "Sunday",
          dayOff: routingEditData?.workHours[6].dayOff,
          startTime: routingEditData?.workHours[6].startTime,
          endTime: routingEditData?.workHours[6].endTime,
        },
      ]);
    }
  }, [routingEditData]);
  useEffect(() => {
    if (routingEditData !== undefined) {
      tagDropDownOptions.map((val, index) => {
        routingEditData?.routeCondition.map((item, ind) => {
          if (item.condition === val.value) {
            if (
              // item.condition === "GENDER" ||
              // item.condition === "COUNTRY" ||
              item.condition === "TAG"
            ) {
              tagDropDownOptions[index]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
        });
      });

      var newData = [];
      routingEditData?.routeCondition?.map((val) => {
        newData.push({
          condition: val.logical === "IS" ? "true" : "false",
          tagName: val.value,
          tagType: val.condition,
          value: "",
          condtionName: val.condition,
          conditionValue:
            val.condition === "GENDER" && val.logical === "IS"
              ? isIsntOptionTrue
              : val.condition === "GENDER" && val.logical === "ISNT"
              ? isIsntOptionFalse
              : val.condition === "TAG" && val.logical === "IS"
              ? isIsntOptionTrue
              : val.condition === "TAG" && val.logical === "ISNT"
              ? isIsntOptionFalse
              : val.condition === "COUNTRY" && val.logical === "IS"
              ? isIsntOptionTrue
              : val.condition === "COUNTRY" && val.logical === "ISNT"
              ? isIsntOptionFalse
              : val.condition === "NUMBER" && val.logical === "IS"
              ? isIsntOptionTrue
              : val.condition === "NUMBER" && val.logical === "ISNT"
              ? isIsntOptionFalse
              : [],

          thirdOptionValues:
            val.condition === "GENDER"
              ? [
                  {
                    name: "Select gender",
                    value: "",
                    selcted: true,
                  },
                  {
                    name: "Male",
                    value: "male",
                    selcted: false,
                  },
                  {
                    name: "Female",
                    value: "female",
                    selcted: false,
                  },
                ]
              : val.condition === "TAG"
              ? tags
              : val.condition === "NUMBER"
              ? []
              : val.condition === "COUNTRY" && segmentCountry,
        });
        setFormItems(newData);
      });
    }
  }, [routingEditData]);

  const isIsntOptionTrue = [
    {
      name: "Select condition",
      value: "",
      selected: false,
    },
    {
      name: "is",
      value: "true",
      selected: true,
    },
    {
      name: "is'nt",
      value: "false",
      selected: false,
    },
  ];
  const isIsntOptionFalse = [
    {
      name: "Select condition",
      value: "",
      selected: false,
    },
    {
      name: "is",
      value: "true",
      selected: false,
    },
    {
      name: "is'nt",
      value: "false",
      selected: true,
    },
  ];
  const createRouting = () => {
    let routeConditions = [];
    formItems.map((val, index) => {
      return routeConditions.push({
        condition: val.condtionName,
        logical:
          val.condition === "true" ? "IS" : val.condition === "false" && `ISNT`,
        value: val.tagName,
      });
    });
    setErrorEnable(true);
    let conditionArray = [];
    let tagName = [];
    let tagType = [];
    formItems.map((val) => {
      conditionArray.push(val.condition);
      tagName.push(val.tagName);
      tagType.push(val.tagType);
    });

    const containEmptyString = (element) => element === "";
    let conditionError = conditionArray.filter(containEmptyString);
    setConditionError(conditionError);
    let tagNameError = tagName.filter(containEmptyString);
    setTagNameError(tagNameError);
    let tagTypeError = tagType.filter(containEmptyString);
    setTagTypeError(tagTypeError);

    let convertAgentIntoPublicIds = [];
    selectedMembers.userMembers.map((val) => {
      convertAgentIntoPublicIds.push(val.publicId);
    });
    let newData = {
      name: submittedData.name,
      channel: submittedData.channel,
      companyId: decryptedValue(auth.companyId),
      routeCondition: routeConditions,
      routeDestination:
        submittedData.assignType === "Team"
          ? {
              type: "TEAM",
              value: submittedData.selectedTeamPublicId,
              agentId: [],
            }
          : {
              type: "AGENT",
              agentId: convertAgentIntoPublicIds,
            },
      workHours: workingHoursType == "Default" ? daysData : daysDataCustomized,
      isDefaultWorkingHour: workingHoursType == "Default" ? true : false,
    };
    if (params.publicId !== undefined) {
      newData.publicId = routingEditData.publicId;
    }
    setLoader(true);
    if (
      submittedData.name !== "" &&
      submittedData.channel !== "" &&
      conditionError.length === 0 &&
      tagNameError.length === 0 &&
      tagTypeError.length === 0 &&
      submittedData.assignType !== ""
      // &&
      // routeCondition.length !== 0
    ) {
      if (submittedData.assignType === "Team") {
        if (
          submittedData.selectedTeamPublicId !== ""
          // &&
          // selectedMembers.teamMembers.length !== 0
        ) {
          apiPost(`/routing`, onSuccess, onFailure, newData);
        }
      } else if (submittedData.assignType === "User") {
        if (selectedMembers.userMembers.length !== 0) {
          apiPost(`/routing`, onSuccess, onFailure, newData);
        }
      }
    } else {
      setLoader(false);
    }
  };
  const onSuccess = (response) => {
    if (response.publicId !== undefined) {
      if (params.publicId === undefined) {
        toast.success(`Routing added successfully`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
      if (params.publicId !== undefined) {
        toast.success(`Routing updated successfully`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
      setLoader(false);
      setTimeout(() => {
        navigate("/settings/live-chat-behavior", { replace: true });
      }, 2000);
    }
  };
  const onFailure = (error) => {
    console.log(error);
  };

  const addNewCondition = () => {
    var newCondition = {
      condition: "",
      tagName: "",
      tagType: "",
      value: "",
      conditionValue: isIsntOption,
      thirdOptionValues: tags,
    };
    setFormItems([...formItems, newCondition]);
  };
  const removeCondition = (index) => {
    var tagAllConditions = [...formItems];
    let previousLastDropdown = tagAllConditions.splice(index, 1);
    tagAllConditions.splice(index, 1);
    setFormItems(tagAllConditions);

    tagDropDownOptions.map((val, index) => {
      tagAllConditions.map((item, ind) => {
        if (item.tagType === val.value) {
          if (
            // item.tagType === "GENDER" ||
            // item.tagType === "COUNTRY" ||
            item.tagType === "TAG" ||
            item.tagType === "NUMBER"
          ) {
            tagDropDownOptions[index]["disabled"] = true;
            setTagDropDownOptions(tagDropDownOptions);
          }
        } else {
          let tagIndex = tagDropDownOptions
            .map(function (o) {
              return o.value;
            })
            .indexOf(previousLastDropdown[0]?.tagType);
          if (tagIndex > -1) {
            tagDropDownOptions[tagIndex]["disabled"] = false;
            setTagDropDownOptions(tagDropDownOptions);
          }
        }
      });
    });
    // setTagDropDownOptions(tagDropDownOptions);
  };
  const handleDropDown = (index, e) => {
    var tagAllConditions = [...formItems];
    if (e.target.value === "GENDER") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);

      let newField = {
        condition: "",
        tagName: "",
        tagType: e.target.value,
        value: "",
        condtionName: "GENDER",
        conditionValue: isIsntOption,
        thirdOptionValues: [
          {
            name: "Select gender",
            value: "",
            selected: true,
          },
          {
            name: "Male",
            value: "male",
            selected: false,
          },
          {
            name: "Female",
            value: "female",
            selected: false,
          },
        ],
      };
      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems(data);

      tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (item.tagType === "GENDER") {
              tagDropDownOptions[index]["disabled"] = true;
              // tagDropDownOptions[2]["disabled"] = true;
              // tagDropDownOptions[3]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          } else {
            let tagIndex = tagDropDownOptions
              .map(function (o) {
                return o.value;
              })
              .indexOf(previousLastDropdown[0]?.tagType);
            if (tagIndex > -1) {
              tagDropDownOptions[tagIndex]["disabled"] = false;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
        });
      });
    }
    if (e.target.value === "TAG") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);
      let newField = {
        condition: "",
        tagName: "",
        tagType: e.target.value,
        value: "",
        condtionName: "TAG",
        conditionValue: isIsntOption,
        thirdOptionValues: tags,
      };

      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems(data);
      // enableDisableIOptions(data);
      let mapped = tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (item.tagType === "TAG") {
              tagDropDownOptions[index]["disabled"] = true;
              // tagDropDownOptions[0]["disabled"] = true;
              // tagDropDownOptions[1]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
          let tagIndex = tagDropDownOptions
            .map(function (o) {
              return o.value;
            })
            .indexOf(previousLastDropdown[0].tagType);
          if (tagIndex > -1) {
            tagDropDownOptions[tagIndex]["disabled"] = false;
            setTagDropDownOptions(tagDropDownOptions);
          }
        });
      });
    }
    if (e.target.value === "NUMBER") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);
      let newField = {
        condition: "",
        tagName: "",
        tagType: e.target.value,
        value: "",
        condtionName: "NUMBER",
        conditionValue: isIsntOption,
        thirdOptionValues: [],
      };

      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems([newField]);
      // enableDisableIOptions(data);
      let mapped = tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (item.tagType === "NUMBER") {
              tagDropDownOptions[index]["disabled"] = true;
              tagDropDownOptions[0]["disabled"] = false;
              // tagDropDownOptions[1]["disabled"] = false;
              // tagDropDownOptions[2]["disabled"] = false;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
          let tagIndex = tagDropDownOptions
            .map(function (o) {
              return o.value;
            })
            .indexOf(previousLastDropdown[0].tagType);
          if (tagIndex > -1) {
            tagDropDownOptions[tagIndex]["disabled"] = false;
            setTagDropDownOptions(tagDropDownOptions);
          }
        });
      });
    }
    if (e.target.value === "COUNTRY") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);
      let newField = {
        condition: "",
        tagName: "",
        tagType: e.target.value,
        value: "",
        condtionName: "COUNTRY",
        conditionValue: isIsntOption,
        thirdOptionValues: segmentCountry,
      };
      // setFormItems([...tagAllConditions, newField]);
      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems(data);
      // enableDisableIOptions(data);
      let mapped = tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (item.tagType === "COUNTRY") {
              tagDropDownOptions[index]["disabled"] = true;
              // tagDropDownOptions[2]["disabled"] = true;
              // tagDropDownOptions[3]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          } else {
            let tagIndex = tagDropDownOptions
              .map(function (o) {
                return o.value;
              })
              .indexOf(previousLastDropdown[0]?.tagType);
            if (tagIndex > -1) {
              tagDropDownOptions[tagIndex]["disabled"] = false;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
        });
      });
    }
  };
  const handleFormChange = (index, name, e) => {
    let data = [...formItems];
    data[index][name] = e.target.value;
    setFormItems(data);
  };

  const getTags = () => {
    apiGet(
      `/contact/tags`,
      onSuccessTags,
      onFailureTags
    );
  };
  const onSuccessTags = (response) => {
    var mapped = response?.data.map((item) => ({
      name: item.tagName,
      value: item.tagName,
      selected: false,
    }));
    var selectDescArray = [
      {
        name: "Select tag",
        value: "",
        selected: true,
      },
    ];
    var concatinatedArray = selectDescArray.concat(mapped);
    setTags(concatinatedArray);
    if (params.publicId === undefined) {
      setFormItems([
        {
          condition: "",
          tagName: "",
          tagType: "",
          value: "",
          condtionName: "",
          conditionValue: isIsntOption,
          thirdOptionValues: concatinatedArray,
        },
      ]);
    }
  };
  const onFailureTags = (error) => {
    console.log(error);
  };

  const isIsntOption = [
    {
      name: "Select condition",
      value: "",
      selected: true,
    },
    {
      name: "is",
      value: "true",
      selected: false,
    },
    {
      name: "is'nt",
      value: "false",
      selected: false,
    },
  ];

  const getWorkingHours = () => {
    apiGet(`/settings/work`, onSuccessWorkingHours, onFailureWorkingHours);
  };
  const onSuccessWorkingHours = (response) => {
    setWorkingHours(response?.data);
  };
  const onFailureWorkingHours = (error) => {
    console.log(error);
  };

  const getTeam = () => {
    apiGet(
      `/agent/team/list`,
      onSuccessTeam,
      onFailureTeam
    );
  };
  const onSuccessTeam = (response) => {
    setTeams(response?.data);
  };
  const onFailureTeam = (error) => {
    console.log(error);
  };

  const getUsers = () => {
    apiGet(
      `/agent/list/AGENT`,
      onSuccessUsers,
      onFailureUsers
    );
  };
  const onSuccessUsers = (response) => {
    const updatedArray = response?.data?.map((element) => ({
      ...element,
      name: element?.firstName + " " + element.lastName,
    }));

    setusers(updatedArray);
  };
  const onFailureUsers = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (users !== undefined) {
      if (params.publicId !== undefined) {
        const memebers = users?.filter((val) =>
          routingEditData?.routeDestination?.agentId.includes(val.publicId)
        );
        setSelectedMembers({
          ...selectedMembers,
          userMembers: memebers,
        });
      }
    }
  }, [users]);
  useEffect(() => {
    getTags();
    getWorkingHours();
  }, []);

  useEffect(() => {
    if (submittedData.assignType === "Team") {
      getTeam();
    }

    if (submittedData.assignType === "User") {
      getUsers();
    }
  }, [submittedData.assignType]);

  useEffect(() => {
    if (workingHours !== undefined) {
      setDaysData([
        {
          dayOff: workingHours?.workSettingsList[0].dayOff,
          day: "Monday",
          startTime: workingHours?.workSettingsList[0]?.startTime,
          endTime: workingHours?.workSettingsList[0]?.endTime,
          companyId: workingHours?.workSettingsList[0]?.companyId,
          id: workingHours?.workSettingsList[0]?.id,
          publicId: workingHours?.workSettingsList[0]?.publicId,
        },
        {
          dayOff: workingHours?.workSettingsList[1].dayOff,
          day: "Tuesday",
          startTime: workingHours?.workSettingsList[1]?.startTime,
          endTime: workingHours?.workSettingsList[1]?.endTime,
          companyId: workingHours?.workSettingsList[1]?.companyId,
          id: workingHours?.workSettingsList[1]?.id,
          publicId: workingHours?.workSettingsList[1]?.publicId,
        },
        {
          dayOff: workingHours?.workSettingsList[2].dayOff,
          day: "Wednesday",
          startTime: workingHours?.workSettingsList[2]?.startTime,
          endTime: workingHours?.workSettingsList[2]?.startTime,
          companyId: workingHours?.workSettingsList[2]?.companyId,
          id: workingHours?.workSettingsList[2]?.id,
          publicId: workingHours?.workSettingsList[2]?.publicId,
        },
        {
          dayOff: workingHours?.workSettingsList[3].dayOff,
          day: "Thursday",
          startTime: workingHours?.workSettingsList[3]?.startTime,
          endTime: workingHours?.workSettingsList[3]?.endTime,
          companyId: workingHours?.workSettingsList[3]?.companyId,
          id: workingHours?.workSettingsList[3]?.id,
          publicId: workingHours?.workSettingsList[3]?.publicId,
        },
        {
          dayOff: workingHours?.workSettingsList[4].dayOff,
          day: "Friday",
          startTime: workingHours?.workSettingsList[4]?.startTime,
          endTime: workingHours?.workSettingsList[4]?.endTime,
          companyId: workingHours?.workSettingsList[4]?.companyId,
          id: workingHours?.workSettingsList[4]?.id,
          publicId: workingHours?.workSettingsList[4]?.publicId,
        },
        {
          dayOff: workingHours?.workSettingsList[5].dayOff,
          day: "Saturday",
          startTime: workingHours?.workSettingsList[5]?.startTime,
          endTime: workingHours?.workSettingsList[5]?.endTime,
          companyId: workingHours?.workSettingsList[5]?.companyId,
          id: workingHours?.workSettingsList[5]?.id,
          publicId: workingHours?.workSettingsList[5]?.publicId,
        },
        {
          dayOff: workingHours?.workSettingsList[6].dayOff,
          day: "Sunday",
          startTime: workingHours?.workSettingsList[6]?.startTime,
          endTime: workingHours?.workSettingsList[6]?.endTime,
          companyId: workingHours?.workSettingsList[6]?.companyId,
          id: workingHours?.workSettingsList[6]?.id,
          publicId: workingHours?.workSettingsList[6]?.publicId,
        },
      ]);
    }
  }, [workingHours]);
  const handleDateChange = (index, dateName, e) => {
    let value = e.target.value;
    var daysDataTemp = [...daysData];
    if (dateName === "startTime") {
      daysDataTemp[index]["startTime"] = value;
      setDaysData(daysDataTemp);
    }

    if (dateName === "endTime") {
      daysDataTemp[index]["endTime"] = value;
      setDaysData(daysDataTemp);
    }
  };
  const handleDateChangeCustomized = (index, dateName, e) => {
    let value = e.target.value;
    var daysDataTemp = [...daysDataCustomized];
    if (dateName === "startTime") {
      daysDataTemp[index]["startTime"] = value;
      setDaysDataCustomized(daysDataTemp);
    }

    if (dateName === "endTime") {
      daysDataTemp[index]["endTime"] = value;
      setDaysDataCustomized(daysDataTemp);
    }
  };
  const handleChange = (index, checked) => {
    // var daysDataTemp = [...daysData];
    // daysDataTemp[index]["dayOff"] = !daysDataTemp[index]["dayOff"];
    // daysDataTemp[index]["startTime"] = "00:00";
    // daysDataTemp[index]["endTime"] = "00:00";
    // setDaysData(daysDataTemp);
  };
  const handleChangeCustomized = (index, checked) => {
    var daysDataTemp = [...daysDataCustomized];
    daysDataTemp[index]["dayOff"] = !daysDataTemp[index]["dayOff"];
    daysDataTemp[index]["startTime"] = "00:00";
    daysDataTemp[index]["endTime"] = "00:00";
    setDaysDataCustomized(daysDataTemp);
  };

  const onSelectUsers = (item) => {
    setSelectedMembers({
      ...selectedMembers,
      userMembers: item,
    });
  };
  const onRemoveUsers = (item) => {
    setSelectedMembers({
      ...selectedMembers,
      userMembers: item,
    });
  };

  return (
    <>
      <div
        className="createNewSegment createNewRouteWrp generalSettingWrp "
        style={{ paddingBottom: "120px" }}
      >
        <div className="uk-container">
          <div className="backBTn">
            <button
              type="button"
              onClick={() =>
                navigate("/settings/live-chat-behavior", { replace: true })
              }
            >
              <ChevronLeft /> Back to Chat routing
            </button>
          </div>
          <div className="mainHeading">
            <h3>
              {params.publicId === undefined
                ? "Create new routing"
                : "Edit routing"}
            </h3>
            <p>
              Please provide the details and specific requirements for this
              contact segment.
            </p>
          </div>
          <form action="javascript:void(0);">
            <div className="mainBox">
              <div className="boxHeading">
                <h3>Routing information</h3>
              </div>
              <div className="formwrp">
                <div className="uk-grid uk-grid-small" uk-grid="">
                  <div className="uk-width-1-2">
                    <div className="formInputs">
                      <label htmlFor="name">Route name</label>
                      <input
                        type="text"
                        className="uk-input"
                        placeholder="Route name"
                        value={submittedData.name}
                        onChange={(e) =>
                          setSubmittedData({
                            ...submittedData,
                            name: e.target.value,
                          })
                        }
                      />
                      {errorEnable && submittedData.name === "" && (
                        <div className="formErrors">Route name is required</div>
                      )}
                    </div>
                  </div>
                  <div className="uk-width-1-2">
                    <div className="formInputs">
                      <label htmlFor="">Route channel</label>

                      <div className="mainBoxDropdown">
                        <div className="uk-inline" style={{ width: "100%" }}>
                          <button
                            type="button"
                            className="dropdownBtn"
                            style={{ width: "100%", minHeight: "40px" }}
                          >
                            {submittedData.channel === ""
                              ? "Please select channel"
                              : submittedData.channel}{" "}
                            <ChevronDown />
                          </button>
                          {errorEnable && submittedData.channel === "" && (
                            <div className="formErrors">
                              Route channel is required
                            </div>
                          )}
                          <div
                            uk-dropdown=""
                            className="mainDropdown"
                            style={{ width: "100%" }}
                          >
                            <div className="dropDownHeading">
                              <span>Route channels</span>
                            </div>
                            <ul>
                              <li
                                onClick={() =>
                                  setSubmittedData({
                                    ...submittedData,
                                    channel: "WhatsApp",
                                  })
                                }
                              >
                                <button type="button">WhatsApp</button>
                              </li>
                              <li
                                onClick={() =>
                                  setSubmittedData({
                                    ...submittedData,
                                    channel: "Messenger",
                                  })
                                }
                              >
                                <button type="button">Messenger</button>
                              </li>
                              <li
                                onClick={() =>
                                  setSubmittedData({
                                    ...submittedData,
                                    channel: "Instagram",
                                  })
                                }
                              >
                                <button type="button">Instagram</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="conditionswrp uk-margin-remove-top">
                  <div className="conditionHeading">
                    <h3>Routing conditions</h3>
                  </div>
                  {formItems?.map((val, index) => {
                    return (
                      <div
                        className="uk-grid uk-grid-small"
                        uk-grid=""
                        key={index}
                      >
                        <div className="uk-width-1-1">
                          <div className="conditionTxt">
                            <span>Condition {index + 1}</span>
                          </div>
                        </div>
                        <div className="uk-width-1-3 uk-margin-remove-top">
                          <div className="formInputs">
                            <select
                              name="tags"
                              onChange={(e) => {
                                handleDropDown(index, e);
                              }}
                            >
                              <option value="" selected>
                                Please select a condition
                              </option>
                              {tagDropDownOptions.map((item, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={item.value}
                                    disabled={item.disabled}
                                    selected={item.value === val.tagType}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="uk-width-1-3 uk-margin-remove-top">
                          <div className="formInputs">
                            <select
                              name="condition"
                              onChange={(e) =>
                                handleFormChange(index, "condition", e)
                              }
                            >
                              {val.conditionValue.map((valed, ind) => {
                                return (
                                  <option
                                    value={valed.value}
                                    selected={valed.value === val.condition}
                                    key={ind}
                                  >
                                    {valed.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="uk-width-1-3 uk-margin-remove-top">
                          <div className="formInputs">
                            {val.thirdOptionValues.length > 0 && (
                              <select
                                name="tagName"
                                onChange={(e) =>
                                  handleFormChange(index, "tagName", e)
                                }
                              >
                                {val.thirdOptionValues.map((valeed, indii) => {
                                  return (
                                    <option
                                      value={valeed.value}
                                      selected={
                                        valeed.tagName !== ""
                                          ? val.tagName === valeed.value
                                          : valeed.selected
                                      }
                                      key={indii}
                                    >
                                      {valeed.name}
                                    </option>
                                  );
                                })}
                              </select>
                            )}
                            {val.thirdOptionValues.length === 0 && (
                              <>
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    e.target.value.length <= 20 &&
                                    handleFormChange(index, "tagName", e)
                                  }
                                  value={val.tagName}
                                  placeholder="Enter number"
                                />
                                <div
                                  className="txtCounter"
                                  style={{ right: "40px", top: "5px" }}
                                >
                                  <span>{val.tagName.length}</span>
                                  <span>/</span>
                                  <span>20</span>
                                </div>
                              </>
                            )}
                            {index !== 0 && (
                              <div
                                className="btnwrp"
                                onClick={() => removeCondition(index)}
                              >
                                <button type="button" className="dltBtn">
                                  <TrashCan />
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {(errorEnable && conditionError.length !== 0) ||
                  (errorEnable && tagNameError.length !== 0) ||
                  (errorEnable && tagTypeError.length !== 0) ? (
                    <div className="formErrors">
                      Please select all condition parameters
                    </div>
                  ) : null}

                  <div className="uk-grid uk-grid-small" uk-grid="">
                    <div className="uk-width-1-1">
                      <div className="clearBtn">
                        {formItems
                          .map((val) => {
                            return val.tagType;
                          })
                          .indexOf("NUMBER") >
                          -1 ===
                          false && (
                          <button type="button" onClick={addNewCondition}>
                            <Add /> Add condition
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mainBox">
              <div className="boxHeading">
                <h3>Routing destination</h3>
              </div>
              <div className="routeSubHeading">
                <span>During working hours</span>
              </div>
              <div className="formwrp">
                <div className="conditionswrp uk-margin-remove-top">
                  <div className="uk-grid uk-grid-small" uk-grid="">
                    <div className="uk-width-1-1">
                      <div className="conditionTxt">
                        <span>Assign</span>
                      </div>
                    </div>
                    <div className="uk-width-1-3 uk-margin-remove-top">
                      <div className="formInputs">
                        <select
                          name="tags"
                          onChange={(e) => {
                            setSubmittedData({
                              ...submittedData,
                              assignType: e.target.value,
                            });
                          }}
                        >
                          <option value="" selected disabled>
                            Please select assign
                          </option>
                          {routingAssignOptions.map((val, index) => {
                            return (
                              <option
                                key={index}
                                value={val}
                                selected={submittedData.assignType == val}
                              >
                                {val}
                              </option>
                            );
                          })}
                        </select>
                        {errorEnable && submittedData.assignType === "" && (
                          <div className="formErrors">
                            Please select routing destination
                          </div>
                        )}
                      </div>
                    </div>
                    {submittedData?.assignType === "Team" && (
                      <div className="uk-width-1-3 uk-margin-remove-top">
                        <div className="formInputs">
                          <select
                            name="condition"
                            onChange={(e) => {
                              setSubmittedData({
                                ...submittedData,
                                selectedTeamPublicId: e.target.value,
                              });
                            }}
                          >
                            <option value="" selected disabled>
                              Please select team
                            </option>
                            {teams?.map((val, index) => {
                              return (
                                <option
                                  key={index}
                                  value={val.publicId}
                                  selected={
                                    params.publicId !== undefined &&
                                    routingEditData.routeDestination.value ===
                                      val.publicId
                                  }
                                >
                                  {val.name}
                                </option>
                              );
                            })}
                          </select>
                          {errorEnable &&
                            submittedData.selectedTeamPublicId === "" && (
                              <div className="formErrors">
                                Please select team{" "}
                              </div>
                            )}
                          {/* <div className="btnwrp">
                            <button type="button" className="dltBtn">
                              <TrashCan />
                            </button>
                          </div> */}
                        </div>
                      </div>
                    )}
                    <div className="uk-width-1-3 uk-margin-remove-top"></div>
                  </div>
                  {/* {submittedData.assignType === "Team" && (
                    <div className="uk-grid uk-grid-small" uk-grid="">
                      <div className="uk-width-1-1">
                        <div className="multiTxtHeading">
                          <span>Add team members</span>
                        </div>
                        <div className="customChipBox">
                          <Multiselect
                            displayValue="name"
                            options={teamMember}
                            selectedValues={selectedMembers.teamMembers}
                            onSelect={onSelectTeams}
                            onRemove={onRemoveTeams}
                          />
                        </div>
                        {errorEnable && selectedMembers.teamMembers.length === 0 && (
                            <div className="formErrors">
                              Please select team members
                            </div>
                          )}
                      </div>
                    </div>
                  )} */}
                  {submittedData.assignType === "User" && (
                    <div className="uk-grid uk-grid-small" uk-grid="">
                      <div className="uk-width-1-1">
                        <div className="multiTxtHeading">
                          <span>Add Users</span>
                        </div>
                        <div className="customChipBox">
                          <Multiselect
                            displayValue="name"
                            options={users}
                            selectedValues={selectedMembers.userMembers}
                            onSelect={onSelectUsers}
                            onRemove={onRemoveUsers}
                          />
                        </div>
                        {errorEnable &&
                          selectedMembers.userMembers.length === 0 && (
                            <div className="formErrors">
                              Please select users
                            </div>
                          )}
                      </div>
                    </div>
                  )}
                  {/* <div className="uk-grid uk-grid-small" uk-grid="">
                    <div className="uk-width-1-1">
                      <div
                        className="routeSubHeading"
                        style={{ marginTop: "31px" }}
                      >
                        <span>Off working hours</span>
                      </div>
                      <div className="conditionTxt">
                        <span>Assign</span>
                      </div>
                    </div>
                    <div className="uk-width-1-3 uk-margin-remove-top">
                      <div className="formInputs">
                        <input type="text" placeholder="Assign" />
                        <div className="btnwrp">
                          <button type="button" className="dltBtn">
                            <TrashCan />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="uk-width-1-3 uk-margin-remove-top"></div>
                    <div className="uk-width-1-3 uk-margin-remove-top"></div>
                  </div> */}
                  {/* <div className="uk-grid uk-grid-small" uk-grid="">
                    <div className="uk-width-1-1">
                      <div className="multiTxtHeading">
                        <span>Add agent</span>
                      </div>
                      <div className="customChipBox">
                        <Multiselect displayValue="name" />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="mainBox">
              <div className="boxHeading">
                <h3>Working hours</h3>
              </div>
              <div className="mainBoxDropdown">
                <div className="uk-inline">
                  <button type="button" className="dropdownBtn">
                    {workingHoursType} <ChevronDown />
                  </button>
                  <div uk-dropdown="" className="mainDropdown">
                    <div className="dropDownHeading">
                      <span>Working hours</span>
                    </div>
                    <ul>
                      <li onClick={() => setWorkingHoursType("Default")}>
                        <button type="button">Default</button>
                      </li>
                      <li onClick={() => setWorkingHoursType("Customized")}>
                        <button type="button">Customized</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-1">
                  <div className="boxHeading switchHeading uk-margin-small-bottom">
                    <h6>Working hours</h6>
                  </div>
                  {/* <div className="switchAndDropdown">
                    <div className="switchwrp">
                      <Switch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        width={26}
                        height={14}
                        handleDiameter={10}
                        onColor="#29a869"
                        onChange={handleChecked}
                      />

                      <div className="switchTxt">
                        <span>Monday</span>
                      </div>
                    </div>
                    <div className="settingDropdown">
                      <input
                        className="dropdownBtn"
                        id="appt-time"
                        type="time"
                        name="appt-time"
                      />
                    </div>

                    <div className="settingDropdown">
                      <input
                        className="dropdownBtn"
                        id="appt-time"
                        type="time"
                        name="appt-time"
                      />
                    </div>
                  </div> */}

                  {workingHours !== undefined &&
                    workingHoursType === "Default" &&
                    daysData.map((val, index) => (
                      <div className="formwrp" key={index}>
                        <form action="">
                          <div className="uk-grid uk-margin-bottom" uk-grid="">
                            <div className="uk-width-1-1">
                              <div className="switchAndDropdown">
                                <div className="switchwrp">
                                  <Switch
                                    checked={!val.dayOff}
                                    onChange={() =>
                                      handleChange(index, val.dayOff)
                                    }
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    width={26}
                                    height={14}
                                    handleDiameter={10}
                                    onColor="#29a869"
                                  />

                                  <div className="switchTxt">
                                    <span>{val?.day}</span>
                                  </div>
                                </div>
                                {!val.dayOff ? (
                                  <>
                                    <div className="settingDropdown">
                                      <input
                                        className="dropdownBtn"
                                        id="appt-time"
                                        type="time"
                                        name="appt-time"
                                        defaultValue={val?.startTime}
                                        onChange={(e) =>
                                          handleDateChange(
                                            index,
                                            "startTime",
                                            e
                                          )
                                        }
                                        disabled
                                      />
                                    </div>

                                    <div className="settingDropdown">
                                      <input
                                        className="dropdownBtn"
                                        id="appt-time"
                                        type="time"
                                        name="appt-time"
                                        defaultValue={val?.endTime}
                                        onChange={(e) =>
                                          handleDateChange(index, "endTime", e)
                                        }
                                        disabled
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div>{val.dayOff ? "Closed Day" : ""}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    ))}
                  {workingHoursType === "Customized" &&
                    daysDataCustomized.map((val, index) => (
                      <div className="formwrp" key={index}>
                        <form action="">
                          <div className="uk-grid uk-margin-bottom" uk-grid="">
                            <div className="uk-width-1-1">
                              <div className="switchAndDropdown">
                                <div className="switchwrp">
                                  <Switch
                                    checked={!val.dayOff}
                                    onChange={() =>
                                      handleChangeCustomized(index, val.dayOff)
                                    }
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    width={26}
                                    height={14}
                                    handleDiameter={10}
                                    onColor="#29a869"
                                  />

                                  <div className="switchTxt">
                                    <span>{val?.day}</span>
                                  </div>
                                </div>
                                {!val.dayOff ? (
                                  <>
                                    <div className="settingDropdown">
                                      <input
                                        className="dropdownBtn"
                                        id="appt-time"
                                        type="time"
                                        name="appt-time"
                                        defaultValue={val?.startTime}
                                        onChange={(e) =>
                                          handleDateChangeCustomized(
                                            index,
                                            "startTime",
                                            e
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="settingDropdown">
                                      <input
                                        className="dropdownBtn"
                                        id="appt-time"
                                        type="time"
                                        name="appt-time"
                                        defaultValue={val?.endTime}
                                        onChange={(e) =>
                                          handleDateChangeCustomized(
                                            index,
                                            "endTime",
                                            e
                                          )
                                        }
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div>{val.dayOff ? "Closed Day" : ""}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    ))}
                </div>
              </div>
              {/* <hr style={{ margin: "60px 0 32px" }} /> */}
              {/* <div className="headingAndSwitch">
                <div className="boxHeading switchHeading  uk-margin-small-bottom">
                  <h6>Away message</h6>
                </div>
              </div> */}
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-1">
                  {/* <div className="formInput" style={{ width: "650px" }}>
                    <label htmlFor="">Off day message</label>
                    <textarea name="" className="uk-textarea"></textarea>
                    <div className="txtCounter">
                      <span>0</span>
                      <span>/</span>
                      <span>100</span>
                    </div>
                  </div> */}
                </div>
                <div className="uk-width-1-1">
                  <div className="btnSection">
                    <button onClick={createRouting} className="w-90">
                      {loader ? (
                        <div uk-spinner="" className="loader"></div>
                      ) : params.publicId === undefined ? (
                        "Save"
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateNewRouting;
