import React, { useState, useEffect } from "react";
import { Filter, OverflowMenuVertical, Edit } from "@carbon/icons-react";
import { apiGet, apiPost } from "../../Utils/apiServices";
import { useAuth } from "../../Context/AuthProvider";
import debounce from "lodash.debounce";
import {
  useNavigate,
  useParams,
  Link,
  useOutletContext,
} from "react-router-dom";
import SegmentFilterModal from "./Modals/SegmentFilterModal";
import { convertTimeWithDateIntoMinHourDate } from "../../Utils/DateFunctions";
import {
  decryptedValue,
  encryptedValue,
} from "../../Utils/RouteHashConversion";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";

const Segments = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [openSegmentFilterModal, setOpenSegmentFilterModal] = useState(false);
  const [segments, setSegments] = useState(null);
  const [apiCalled, setApiCalled] = useState(false);
  const [multiSelectTags, setMultiSelectTags] = useState([]);

  // for search filter
  const [filterData, setFilterData] = useState({
    fromDate: null,
    toDate: null,
    category: null,
    contactTags: [],
    agentId: null,
    searchQuery: null,
  });

  const getFilterCount = () => {
    const { fromDate, toDate, category, contactTags, agentId, searchQuery } =
      filterData;
    let count = 0;

    if (fromDate && toDate) count++;
    if (category) count++;
    if (contactTags.length > 0) count++;
    if (agentId) count++;
    // if (searchQuery) count++;
    return count;
  };
  const noOfFilters = getFilterCount();

  const handleOpenSegmentFilterModal = () => {
    setOpenSegmentFilterModal(true);
  };

  const handleCLoseSegmentFilterModal = () => {
    setOpenSegmentFilterModal(false);
  };

  const getTags = () => {
    apiGet(`/contact/tags`, onSuccessTags, onFailureTags);
  };
  const onSuccessTags = (response) => {
    const updatedArray = response.data?.map((element) => ({
      ...element,
      name: element.tagName,
    }));
    setMultiSelectTags(updatedArray);
  };
  const onFailureTags = (error) => {
    console.log(error);
  };

  const onChange = (e) => {
    setSegments(null);
    var searchedValue = e.target.value;
    const data = {
      fromDate: null,
      toDate: null,
      category: null,
      contactTags: [],
      agentId: null,
      searchQuery: searchedValue,
    };

    if (searchedValue !== "") {
      setFilterData({
        fromDate: null,
        toDate: null,
        category: null,
        contactTags: [],
        agentId: null,
      });
      apiPost(`/segment/filter`, onSuccessSearch, onFailureSearch, data);
    } else {
      getSegments();
    }
  };
  const onSuccessSearch = (response) => {
    if (response.status === 0) {
      setSegments(response.data);
    }
  };
  const onFailureSearch = (error) => {
    console.log(error);
  };

  const getSegments = () => {
    apiGet(`/segment/`, onSuccessSegment, onFailureSegment);
  };
  const onSuccessSegment = (response) => {
    if (response?.status === 0) {
      setApiCalled(false);
      setSegments(response.data);
    }
  };
  const onFailureSegment = (error) => {
    console.log(error);
  };
  useEffect(() => {
    if (apiCalled === true) {
      getSegments();
    }
  }, [apiCalled]);
  const debouncedOnChange = debounce(onChange, 1000);
  useEffect(() => {
    getTags();
    getSegments();
  }, []);

  //   useEffect(() => {
  //     if (apiCalled === true) {
  //       getContacts(apiCalled, 0);
  //     }
  //   }, [apiCalled]);
  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="ContactContainer">
        <div className="topHeader">
          <div className="uk-grid innnerSection" uk-grid="">
            <div className="uk-width-1-3 part1">
              <ul
                className="uk-subnav uk-subnav-pill"
                id="renderSwitcher"
                uk-switcher="connect: #contactMainTabs"
              >
                <li
                  onClick={() => {
                    navigate("/contacts", { replace: true });
                  }}
                >
                  <a>Contacts</a>
                </li>
                <li
                  className="uk-active"
                  onClick={() => {
                    navigate("/segments", { replace: true });
                  }}
                >
                  <a>Segments</a>
                </li>
              </ul>
            </div>
            <div className="uk-width-1-3 part2">
              <ul className="uk-switcher uk-margin" id="contactMainTabs">
                <li></li>
                <li>
                  <div className="searchAndFilter">
                    <div className="searchInput">
                      <form
                        action=""
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <input
                          placeholder="Search segment through name"
                          onChange={debouncedOnChange}
                        />
                      </form>
                    </div>
                    <div className="filterBtn">
                      <button
                        type="button"
                        onClick={() => handleOpenSegmentFilterModal()}
                      >
                        <Filter /> <span>Filter</span>
                        {noOfFilters > 0 && (
                          <span className="filterCount">{noOfFilters}</span>
                        )}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="uk-width-1-3 part3">
              <ul className="uk-switcher uk-margin" id="contactMainTabs">
                <li></li>
                <li>
                  <div className="btnwrp">
                    <button
                      className="addBtn"
                      type="button"
                      onClick={() =>
                        navigate("/create-new-segment", { replace: true })
                      }
                    >
                      Add segment
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="tabContent">
          <ul className="uk-switcher uk-margin" id="contactMainTabs">
            <li></li>
            <li>
              <div className="segmentTable">
                <div className="uk-container">
                  <div className="segmentTableWrp">
                    <table className="uk-table">
                      <thead>
                        <tr>
                          <th>SEGMENT NAME</th>
                          <th>
                            <div className="tableDropDown">
                              <div className="uk-inline tableFilterDropdown">
                                <button type="button" className="dropdownBtn">
                                  CATEGORY
                                  {/* <ChevronDown /> */}
                                </button>
                                {/* <div
                                  uk-dropdown="mode: click"
                                  className="dropdownNav"
                                >
                                  <span className="dropdownHeading">
                                    Segment category
                                  </span>
                                  <ul>
                                    <li className="activeBtn">
                                      <button type="button">
                                        Lead generation
                                      </button>
                                    </li>
                                    <li>
                                      <button type="button">Promotion </button>
                                    </li>
                                  </ul>
                                </div> */}
                              </div>
                            </div>
                          </th>
                          <th>AUDIENCE</th>
                          <th>DESCRIPTION</th>
                          <th>CREATED BY</th>
                          <th>LAST UPDATED</th>
                        </tr>
                      </thead>
                      <tbody>
                        {segments?.length > 0 ? (
                          segments?.map((val, index) => {
                            return (
                              <tr key={index}>
                                <td className="agentName">
                                  <Link
                                    to={`/contact/segment-detail/${encryptedValue(
                                      val.publicId
                                    )}`}
                                  >
                                    {val.segmentName}
                                  </Link>
                                </td>
                                <td>{val.segmentCategory}</td>
                                <td>
                                  {val.audience === null ? "-" : val.audience}
                                </td>
                                <td className="description">
                                  {val.segmentDescription}
                                </td>
                                <td>{val?.createdBy}</td>
                                <td>
                                  {convertTimeWithDateIntoMinHourDate(
                                    val?.createDateTime
                                  )}
                                </td>
                                {/* <td>-</td> */}
                                <td>
                                  <div className="uk-inline">
                                    <button type="button">
                                      <OverflowMenuVertical />
                                    </button>
                                    <div
                                      uk-dropdown=""
                                      className="contactBlckDrop"
                                    >
                                      <ul>
                                        <li>
                                          <button
                                            type="button"
                                            onClick={() =>
                                              navigate(
                                                `/edit-segment/${encryptedValue(
                                                  val.publicId
                                                )}`,
                                                { replace: true }
                                              )
                                            }
                                          >
                                            <Edit /> Edit
                                          </button>
                                        </li>
                                        {/* <li>
                                            <button
                                              type="button"
                                              className="downLoadBtn"
                                            >
                                              <Download /> Download data
                                            </button>
                                          </li> */}
                                        {/* <hr /> */}
                                        {/* <li>
                                            <button
                                              className="trashBtn"
                                              type="button"
                                            >
                                              <TrashCan /> Delete
                                            </button>
                                          </li> */}
                                      </ul>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={7} className="dataNotFound">
                              {segments === null ? (
                                <div uk-spinner=""></div>
                              ) : (
                                "Data Not Found"
                              )}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          {openSegmentFilterModal && (
            <SegmentFilterModal
              closeModal={handleCLoseSegmentFilterModal}
              multiSelectTags={multiSelectTags}
              setFilterData={setFilterData}
              filterData={filterData}
              setSegments={setSegments}
              setApiCalled={setApiCalled}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Segments;
