import { ChevronLeft } from "@carbon/icons-react";
import React, { useState } from "react";
import { useAuth } from "../../Context/AuthProvider";
import { ValidateEmail } from "../../Utils/regexFunctions";
import { apiPost } from "../../Utils/apiServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { decryptedValue } from "../../Utils/RouteHashConversion";
// import { baseUrl } from "../../Constant";
const baseUrl = process.env.REACT_APP_BASEURL;

const Request = ({
  heading,
  paragraph,
  type,
  btnText,
  thankyouHeading,
  thankyouPara1,
  thankyouPara2,
  thanyouPara3,
  thanyouEmail,
  thankyouBtn,
}) => {
  const { auth } = useAuth();
  const [isCaptchaSelected, setIsCaptchaSelected] = useState(null);
  const navigate = useNavigate();
  const [errorEnable, setErrorEnable] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [request, setRequest] = useState({
    activityById: 0,
    companyPublicId: decryptedValue(auth.companyId),
    description: "",
    email: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    requestStatus: "",
  });

  const handleRequest = () => {
    setErrorEnable(true);
    setLoader(true);
    if (
      request.firstName !== "" &&
      request.lastName !== "" &&
      request.email !== "" &&
      request.mobileNumber !== "" &&
      request.description !== "" &&
      isCaptchaSelected !== null
    ) {
      apiPost(`/request-service/save`, onSuccess, onFailure, request);
      // axios
      //   .post(
      //     baseUrl + "/request-service/save",
      //     request,
      //     {
      //       "Access-Control-Allow-Origin": "*",
      //       "Content-Type": "application/json",
      //     }
      //   )
      //   .then(
      //     (response) => {
      //       if (response.status === 0) {
      //         setTimeout(() => {
      //           setLoader(false);
      //           // toast.success("Request added successfully", {
      //           //   position: toast.POSITION.TOP_RIGHT,
      //           // });
      //           setFormSubmitted(true);
      //           setErrorEnable(false);
      //           setRequest({
      //             activityById: 0,
      //             companyPublicId: companyPublicId,
      //             description: "",
      //             email: "",
      //             firstName: "",
      //             lastName: "",
      //             mobileNumber: "",
      //             requestStatus: "",
      //           });
      //         }, 2000);
      //       }
      //     },
      //     (error) => {
      //       if (error?.response?.data?.message) {
      //         toast.error(error?.response?.data?.message, {
      //           position: toast.POSITION.TOP_RIGHT,
      //         });
      //       }
      //       setLoader(false);
      //     }
      //   );
    } else {
      setLoader(false);
    }
  };

  const onSuccess = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setLoader(false);
        // toast.success("Request added successfully", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        setFormSubmitted(true);
        setErrorEnable(false);
        setRequest({
          activityById: 0,
          companyPublicId: decryptedValue(auth.companyId),
          description: "",
          email: "",
          firstName: "",
          lastName: "",
          mobileNumber: "",
          requestStatus: "",
        });
      }, 2000);
    }
  };
  const onFailure = (error) => {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoader(false);
  };
  const handleEmailValidation = (e) => {
    var Email = ValidateEmail(e);
    if (Email === "Invalid Email") {
      setEmailError("Invalid Email Address");
    } else {
      setEmailError("Valid Email Address");
    }
  };
  const reCaptchaOnChange = (value) => {
    setIsCaptchaSelected(value);
  };
  return (
    <>
      {formSubmitted === false ? (
        <div className="helpContainer">
          <div className="uk-container">
            <div className="btnContainer">
              <button className="backbtn" onClick={() => navigate(-1)}>
                <ChevronLeft /> Back
              </button>
            </div>
            <div>
              <h2 className="heading">{heading}</h2>
              <p>{paragraph}</p>
            </div>
            <div className="innerContainer">
              <h2 className="innerheading">User information</h2>
              <div className="formWrap uk-form-stacked">
                <div className="uk-grid uk-grid-small" uk-grid="">
                  <div className="uk-width-1-2 p-relative">
                    <label>First name</label>
                    <br />
                    <input
                      type="text"
                      className="uk-input"
                      placeholder="First name"
                      value={request.firstName}
                      onChange={(e) => {
                        e.target.value.length <= 10 &&
                          setRequest({
                            ...request,
                            firstName: e.target.value,
                          });
                      }}
                    />
                    <div className="txtCounter" style={{ top: "35px" }}>
                      <span>{request.firstName.length}</span>
                      <span>/</span>
                      <span>10</span>
                    </div>
                    <br />
                    {request.firstName === "" && errorEnable && (
                      <div className="formErrors">First name is required</div>
                    )}
                  </div>

                  <div className="uk-width-1-2 p-relative">
                    <label>Last name</label>
                    <br />
                    <input
                      type="text"
                      className="uk-input"
                      placeholder="Last name"
                      value={request.lastName}
                      onChange={(e) => {
                        e.target.value.length <= 10 &&
                          setRequest({
                            ...request,
                            lastName: e.target.value,
                          });
                      }}
                    />
                    <div className="txtCounter" style={{ top: "35px" }}>
                      <span>{request.lastName.length}</span>
                      <span>/</span>
                      <span>10</span>
                    </div>
                    <br />
                    {request.lastName === "" && errorEnable && (
                      <div className="formErrors">Last name is required</div>
                    )}
                  </div>
                  <div className="uk-width-1-2">
                    <label>Email</label>
                    <br />
                    <input
                      type="email"
                      placeholder="Email address"
                      className="uk-input"
                      value={request.email}
                      onChange={(e) => {
                        setRequest({
                          ...request,
                          email: e.target.value,
                        });
                        handleEmailValidation(e.target.value);
                      }}
                    />
                    <br />
                    {request.email === "" && errorEnable && (
                      <div className="formErrors">Email is required</div>
                    )}
                    {request.email !== "" &&
                      emailError !== "Valid Email Address" && (
                        <div className="formErrors">Invalid Email Address</div>
                      )}
                  </div>
                  <div className="uk-width-1-2 p-relative">
                    <label>Mobile number</label>
                    <br />
                    <input
                      type="number"
                      value={request.mobileNumber}
                      className="uk-input"
                      placeholder="Mobile number"
                      onChange={(e) => {
                        e.target.value.length <= 20 &&
                          setRequest({
                            ...request,
                            mobileNumber: e.target.value,
                          });
                      }}
                    />
                    <div className="txtCounter" style={{ top: "35px" }}>
                      <span>{request.mobileNumber.length}</span>
                      <span>/</span>
                      <span>20</span>
                    </div>
                    <br />
                    {request.mobileNumber === "" && errorEnable && (
                      <div className="formErrors">
                        Mobile number is required
                      </div>
                    )}
                  </div>
                  {/* <div className="uk-width-1-2">
                <label>Subject</label>
                <br />
                <input type="text" placeholder="Select a subject" />
              </div> */}
                  <div className="uk-width-1-1">
                    <label>Please briefly describe your problem</label>
                    <br />
                    <textarea
                      type="text"
                      className="uk-textarea"
                      placeholder="Brief description..."
                      value={request.description}
                      onChange={(e) => {
                        setRequest({
                          ...request,
                          description: e.target.value,
                        });
                      }}
                    />
                    <br />
                    {request.description === "" && errorEnable && (
                      <div className="formErrors">Descriptoin is required</div>
                    )}
                  </div>
                  <div className="uk-width-1-1">
                    <label>
                      Once you have submitted the form, our team will review
                      your application and get back to you with further
                      instructions.
                    </label>
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <ReCAPTCHA
                    sitekey="6LfMxhYpAAAAABVOEoefGn8CFSnez5kjDRJAjws2"
                    onChange={reCaptchaOnChange}
                  />
                  {isCaptchaSelected === null && errorEnable && (
                    <div className="formErrors">Please complete the reCaptcha </div>
                  )}
                </div>
                <div className="btnWrap uk-margin-large-top">
                  <div>
                    <button
                      className="cnclBtn"
                      onClick={() => navigate("/dashboard", { replace: true })}
                    >
                      Cancel
                    </button>
                    <button className="saveBtn" onClick={handleRequest}>
                      {loader ? (
                        <div uk-spinner="" className="loader"></div>
                      ) : (
                        btnText
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="thankyouContainer">
          <div className="innnerSection">
            <h2 className="heading">{thankyouHeading}</h2>
            <p className="mainDescription">{thankyouPara1}</p>
            <p className="mainDescription">{thankyouPara2}</p>

            <p>
              {thanyouPara3} <a href="">{thanyouEmail}</a>
            </p>

            <div className="uk-margin-medium-top">
              <button
                className="backbtn"
                onClick={() => {
                  navigate("/chat", { replace: true });
                }}
              >
                <ChevronLeft /> {thankyouBtn}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Request;
