import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
// import { baseUrl } from "../Constant";
import { useAuth } from "../Context/AuthProvider";
import { apiPost } from "../Utils/apiServices";
import { decryptedValue } from "../Utils/RouteHashConversion";
import { superAdminRoutes } from "./Route";
const baseUrl = process.env.REACT_APP_BASEURL;

const PrivateRoute = ({ children, permission }) => {
  const userRole = decryptedValue(localStorage.getItem("_role"));
  const userRoleDec = userRole;

  const { auth } = useAuth();
  const handleLogout = () => {
    let data = {
      accessToken: auth.token,
      userPublicId: decryptedValue(auth.publicId),
    };
    apiPost(`/auth/logout`, onSuccess, onFailure, data);
  };
  const onSuccess = (response) => {
    localStorage.clear();
    navigate("/", { replace: true });
  };
  const onFailure = (error) => {
    console.log(error);
  };

  const navigate = useNavigate();
  const checkPermission = async () => {
    try {
      const response = await axios.get(baseUrl + "/auth/user-permission", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("_token")}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      const data = response?.data;
      return data;
    } catch (error) {
      // console.error("Error checking permission:", error);
      if (error.response?.data.message === "User not found") {
        handleLogout();
      }

      return false;
    }
  };
  const authorized = checkPermission();

  // userRoleDec === "ROLE_SUPER_ADMIN" ? null : checkPermission();
  useEffect(() => {
    var newArray = [];
    if (authorized !== null) {
      authorized.then((hasPermission) => {
        hasPermission?.data?.map((val) => {
          newArray?.push(val?.permission?.name);
        });

        if (permission !== undefined) {
          var Authorized = newArray?.filter((item) =>
            permission.includes(item)
          );

          if (userRoleDec === "ROLE_SUPER_ADMIN" && userRoleDec === undefined) {
            if (Authorized.length === 0) {
              navigate("/", { replace: true });
            }
          }
          // else if (userRoleDec === "ROLE_AGENT") {
          //     navigate("/", { replace: true });
          //     // localStorage.clear()
          // }
          else {
            if (Authorized.length === 0) {
              // navigate("/", { replace: true });
              window.location.href = "/unauthorized";
            }
          }
        }
      });
    }
  }, [authorized, navigate]);

  if (localStorage.getItem("_token") !== undefined) {
    return children;
  }
};

export default PrivateRoute;

