import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { useAuth } from "../../Context/AuthProvider";
import moment from "moment";
// import { ChevronDown, Download } from "@carbon/icons-react";
import UIkit from "uikit";
import CalendarDate from "../../components/Calendar/CalendarDate";
import { apiGet } from "../../Utils/apiServices";
import { convertTimestamp } from "../../Utils/DateFunctions";
// import axios from "axios";
import { decryptedValue } from "../../Utils/RouteHashConversion";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";
import { hasPermissions } from "../../Utils/RoutePermissions";
const baseUrl = process.env.REACT_APP_BASEURL;

const Orders = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const CurrentCompanyName = CompanyName(CompanyHtml);
  const { auth } = useAuth();

  const navigate = useNavigate();
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [DateChange, setDateChange] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [loader, setLoader] = useState(true);
  const pageTabs = [
    {
      route: "/analytics",
      name: "Overview",
      permissions: ["FINANCE-DATA-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/live-chat-analytics",
      name: "Live Chat",
      permissions: ["ANALYTICS-MANAGER", "LIVE-CHAT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/chatbot-analytics",
      name: "Chatbot",
      permissions: ["CHATBOT-SUPERVISOR", "CHAT-BOT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/statistics",
      name: "Statistics",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/package-mau",
      name: "Package",
      permissions: ["ANALYTICS-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/orders",
      name: "Orders",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: true,
      allPermissionsMandatory: true,
    },
    {
      route: "/transfer-logs",
      name: "Transfer Logs",
      permissions: [],
      active: false,
      allPermissionsMandatory: true,
    },
  ];

  const handleDatePickerClose = () => {
    UIkit.drop("#datepickerdrop").hide(true);
  };

  const onChange = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
    setDateChange(true);
  };

  // const exportData = async () => {
  //   try {
  //     const response = await axios.post(
  //       baseUrl + `/broadcast/api/export`,
  //       {},
  //       {
  //         responseType: "arraybuffer", // Ensure the response is treated as binary data
  //         headers: {
  //           Authorization: `Bearer ${auth.token}`,
  //         },
  //       }
  //     );
  //     const blob = new Blob([response.data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "Api_Message.xlsx");
  //     document.body.appendChild(link);
  //     link.click();
  //   } catch (error) {
  //     console.error("Error downloading file: ", error);
  //   }
  // };

  const getOrders = () => {
    apiGet(`/stats/orders`, onSuccessEnable, onFailureEnable);
  };
  const onSuccessEnable = (response) => {
    setApiData(response);
    setLoader(false);
  };
  const onFailureEnable = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getOrders();
  }, []);

  // const [searchFilter, setSearchFilter] = useState("");

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="boradcastWrp">
        <div className="boradcastTabbing" style={{ minHeight: "31px" }}>
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #newAnalyticsTab"
          >
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                <li
                  onClick={() => {
                    navigate(val.route, { replace: true });
                  }}
                  className={val.active === true ? "uk-active" : ""}
                  key={index}
                >
                  <a>{val.name}</a>
                </li>
              ) : null;
            })}
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="newAnalyticsTab">
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                val.name === "Orders" ? (
                  <li key={index}>
                    <div className="broadcastTabWrp">
                      <div className="uk-container">
                        <div className="mainHeading">
                          <h2>
                            Orders{" "}
                            <CurrentCompanyName
                              companyDetails={companyDetails}
                            />
                          </h2>
                        </div>
                        <div className="searchFilterWrp">
                          <div className="uk-grid uk-flex-middle" uk-grid="">
                            <div className="uk-width-1-2">
                              <div className="searchFilter">
                                <form
                                  action=""
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  {/* <input
                                type="text"
                                placeholder="Search by email and number"
                                value={searchFilter}
                                onChange={(e) =>
                                  setSearchFilter(e.target.value)
                                }
                              /> */}
                                </form>
                              </div>
                            </div>
                            <div className="uk-width-1-2 btnSection">
                              {/* <button className="exportBtn" onClick={exportData}>
                            <Download /> Export Data
                          </button> */}

                              <div className="uk-inline">
                                {/* <button className="calenderBtn rangeBtn">
                            <Calendar /> Last 30 days{" "}
                            <span className="uk-inline">
                              <ChevronDown />
                            </span>
                          </button> */}
                                <div
                                  uk-dropdown="mode: click"
                                  className="calendarDropdown"
                                >
                                  <div className="dropDownHeading">
                                    <span>Select a range</span>
                                  </div>
                                  <ul>
                                    <li className="active">
                                      <button
                                        type="button"
                                        className="rangeBtn"
                                      >
                                        <span className="rangeBtnCircle"></span>{" "}
                                        Last 30 days
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        type="button"
                                        className="rangeBtn"
                                      >
                                        <span className="rangeBtnCircle"></span>{" "}
                                        This week
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        type="button"
                                        className="rangeBtn"
                                      >
                                        <span className="rangeBtnCircle"></span>{" "}
                                        Last week
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        type="button"
                                        className="rangeBtn"
                                      >
                                        <span className="rangeBtnCircle"></span>{" "}
                                        This month
                                      </button>
                                    </li>
                                    <hr style={{ margin: "5px 0" }} />
                                    <li>
                                      <div className="uk-inline uk-text-left">
                                        <button
                                          className="rangeBtn"
                                          type="button"
                                        >
                                          {/* <CalendarHeatMap /> */}
                                          {/* <span className="filter-name">
                                      {moment(STARTDATE).format("Do MMMM YYYY")}{" "}
                                      - {moment(ENDDATE).format("Do MMMM YYYY")}
                                    </span> */}
                                          <span className="rangeBtnCircle"></span>{" "}
                                          Custom range
                                          {/* <ChevronDown /> */}
                                        </button>
                                        <div
                                          id="datepickerdrop"
                                          uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                          className="date-picker-container"
                                        >
                                          <CalendarDate onChange={onChange} />
                                          <div className="date-picker-btn-container uk-text-right">
                                            <button
                                              className="date-selection-btn"
                                              onClick={handleDatePickerClose}
                                            >
                                              Ok
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <button type="button">
                                  <span></span> Custom range
                                </button> */}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="apiTableWrp">
                          <div className="uk-grid" uk-grid="">
                            <div className="uk-width-1-1">
                              <table className="uk-table">
                                <thead>
                                  <tr>
                                    <th>Series</th>
                                    <th>Order ID</th>
                                    <th>Order Details</th>
                                    <th>Mobile</th>
                                    <th>Status</th>
                                    <th>Created</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {apiData?.length > 0 ? (
                                    apiData?.map((val) => {
                                      return (
                                        <tr key={val.orderId}>
                                          <td>{val.orderId}</td>
                                          <td>{val.orderId}</td>
                                          <td className="messageCol">
                                            <div className="txtwrp">
                                              {val.orderDetails.map(
                                                (item, itemIndex) => {
                                                  return (
                                                    <p key={itemIndex}>
                                                      quantity: {item.quantity}{" "}
                                                      &nbsp; &nbsp; currency:{" "}
                                                      {item.currency} &nbsp;
                                                      &nbsp; Price:{" "}
                                                      {item.itemPrice === null
                                                        ? "-"
                                                        : item.itemPrice}
                                                    </p>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </td>
                                          <td>{val.phoneNumber}</td>
                                          <td>{val.status}</td>
                                          <td>
                                            {convertTimestamp(val.timeStamp)}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan={11} className="dataNotFound">
                                        {apiData === null ? (
                                          <div uk-spinner=""></div>
                                        ) : (
                                          "Data Not Found"
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li key={index}></li>
                )
              ) : null;
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Orders;
