import {
  Attachment,
  Checkmark,
  Close,
  FaceSatisfied,
  Image,
  Microphone,
  TextBold,
  TextItalic,
  Time,
} from "@carbon/icons-react";
import $ from "jquery";
import { useState } from "react";
import ReinititalModal from "./Modals/ReinititalModal";
import React, { useEffect, useRef } from "react";
import {
  eventDateTime,
  timeStampConvertIntoExpiration,
} from "../../../Utils/DateFunctions";
import {
  apiDeleteSuperAdmin,
  apiGet,
  apiGetSuperAdmin,
  apiPost,
} from "../../../Utils/apiServices";
import { useAuth } from "../../../Context/AuthProvider";
import { removeAndAddActiveClass } from "../../../Utils/jqueryFunctions";
import Emoji from "../../../components/common/Chat/Emoji";
import { toast } from "react-toastify";
import FileLoader from "./Modals/FileLoader";
import { debounce } from "lodash";
import { decryptedValue } from "../../../Utils/RouteHashConversion";
import { pdfjs } from "react-pdf";
import VoiceModal from "./Modals/VoiceModal";
import ChatMessageContainer from "../../../components/chat-messages-container/ChatMessageContainer";
import GalleryModal from "../../../components/gallery/GalleryModal";
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ChatMessages = ({
  contactMessages,
  setIsNotesCreated,
  agentList,
  chatDetails,
  userPermissions,
  chatContactList,
  newMessages,
  companyDetails,
  fetchChat,
  getAgentList,
  socketResponse,
  subscribeToChat,
  openFileLoader,
  setOpenFileLoader,
  setChatDetails,
  quickReply,
  handleScroll,
  paginationCalled,
  hasMoreMessages,
  pageForChatMessages,
  setQuickReply,
  companyWhatsappNumber,
  pageCount,
  galleryData,
  apiCalledGallery,
  setApiCalledGallery,
  getGalleryData,
}) => {
  const { auth } = useAuth();
  //  ****************************state for chat messages ********************************
  const bottomRef = useRef(null);
  const [previousMessagesHeight, setPreviousMessagesHeight] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isRecordingStarted, setIsRecordingStarted] = useState(false);
  // for gallery modal
  const [openGalleryModal, setOpenGalleryModal] = useState(false);
  // for gallery modal end
  // for image in textarea
  const [copiedImageExist, setCopiedImageExist] = useState(null);
  const [copiedImagedetails, setCopiedImagedetails] = useState(null);
  const textareaRef = useRef(null);
  // for end image in textarea
  const [isAudioFileUploadingStarted, setIsAudioFileUploadingStarted] =
    useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerIntervalRef = useRef(null);
  const [notes, setNotes] = useState("");
  const [mimType, setMimType] = useState("");
  const [expirationTime, setExpirationTime] = useState("");
  const [filterText, setFilterText] = useState("");

  const [currentAgent, setCurrentAgent] = useState({
    agentName: "",
    agentPublicId: "",
    agentId: "",
  });
  const [assignedAgent, setAssignedAgent] = useState({
    agentName: null,
    agentPublicId: "",
    agentId: "",
  });
  const [chatComplateMaintainedData, setChatComplateMaintainedData] =
    useState();
  const [assignedAgentStatus, setAssignedAgentStatus] = useState({
    agentStatus: "",
    previousAgentStatus: "",
    agentStatus: "",
    agentPublicId: "",
  });
  const [openReinitialModal, setOpenReinitialModal] = useState(false);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [openVoiceModal, setOpenVoiceModal] = useState(false);
  // const [openLocationModal, setOpenLocationModal] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(null);
  const [locationDetails, setLocationDetails] = useState({
    latitude: "",
    longitude: "",
  });
  const [message, setMessage] = useState("");
  const [statusColor, setStatusColor] = useState("");
  const [filePath, setFilePath] = useState("");
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(null);
  const [isChatExpired, setIsChatExpired] = useState(null);
  const [agentTrasnfer, setAgentTrasnfer] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);
  const [clickedButtonIndex, setClickedButtonIndex] = useState(null);
  const [chatStatusData, setChatStatusData] = useState([
    {
      id: 1,
      activeStatus: "activeClass",
      chatStatus: "greenDot",
      statusName: "OPEN",
    },
    {
      id: 2,
      activeStatus: "",
      chatStatus: "yellowDot",
      statusName: "WAITING",
    },
    {
      id: 3,
      activeStatus: "",
      chatStatus: "darGreen",
      statusName: "SOLVED",
    },
    {
      id: 4,
      activeStatus: "",
      chatStatus: "grayDot",
      statusName: "CLOSED",
      disabledStatus: true,
    },
    {
      id: 5,
      activeStatus: "",
      chatStatus: "borderDot",
      statusName: "REOPENED",
    },
  ]);

  const allowedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/jpg",
    "video/mp4",
    "audio/mp3",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "text/csv",
    "audio/ogg",
  ].join(", ");
  const allowedImageFileTypes = ["image/jpeg", "image/png", "image/jpg"].join(
    ", "
  );
  //  ****************************state for chat messages end ********************************
  // const [numPages, setNumPages] = useState();
  // const [pageNumber, setPageNumber] = useState(1);

  // const [numPagesPPT, setNumPagesPPT] = useState();
  // const [pageNumberPPT, setPageNumberPPT] = useState(1);

  // function onDocumentLoadSuccess() {
  //   setNumPages(numPages);
  // }
  // function onDocumentLoadSuccessPPT() {
  //   setNumPagesPPT(numPagesPPT);
  // }

  // ******************************************  functions starts *********************************
  // for audio recording
  // const startRecording = () => {
  //   var constraints = { audio: true, video: false };
  //   // let mv = id("mediaVideo"),
  //   //   mediaOptions = {
  //   //     audio: {
  //   //       tag: "audio",
  //   //       type: "audio/ogg",
  //   //       ext: ".ogg",
  //   //       gUM: { audio: true },
  //   //     },
  //   //   };
  //   media = mediaOptions.audio;
  //   navigator.mediaDevices
  //     .getUserMedia(constraints)
  //     .then((_stream) => {
  //       stream = _stream;
  //       recorder = new MediaRecorder(stream);
  //       recorder.ondataavailable = (e) => {
  //         chunks.push(e.data);
  //         if (recorder.state == "inactive") makeLink();
  //       };
  //       chunks = [];
  //       recorder.start();
  //     })
  //     .catch(log);
  //   if (running == 0) {
  //     running = 1;
  //     increment();
  //   } else {
  //     running = 0;
  //   }
  // };

  const handleOpenGalleryModal = () => {
    setOpenGalleryModal(true);
  };
  const handleCloseGalleryModal = () => {
    setOpenGalleryModal(false);
  };
  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };

        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/ogg",
          });

          const audioUrl = URL.createObjectURL(audioBlob);
        };

        mediaRecorderRef.current.start();
        setIsRecording(true);

        // Start the timer
        timerIntervalRef.current = setInterval(() => {
          setTimer((prevTimer) => prevTimer + 1);
        }, 1000);
      })
      .catch((error) => console.error("Error accessing microphone:", error));
  };

  const toggleRecording = () => {
    if (isRecording) {
      // If recording is in progress, pause or stop recording
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state === "recording"
      ) {
        mediaRecorderRef.current.pause();
        clearInterval(timerIntervalRef.current);
      }
    } else {
      // If not recording, start or resume recording
      if (!mediaRecorderRef.current) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = (event) => {
              if (event.data.size > 0) {
                audioChunksRef.current.push(event.data);
              }
            };

            mediaRecorderRef.current.onstop = () => {
              const audioBlob = new Blob(audioChunksRef.current, {
                type: "audio/ogg",
              });
              const audioUrl = URL.createObjectURL(audioBlob);
              setIsRecordingStarted(true);
            };
          })
          .catch((error) =>
            console.error("Error accessing microphone:", error)
          );
      }

      if (mediaRecorderRef.current.state === "paused") {
        // If paused, resume recording
        mediaRecorderRef.current.resume();
        // Resume the timer
        timerIntervalRef.current = setInterval(() => {
          setTimer((prevTimer) => prevTimer + 1);
        }, 1000);
      } else {
        // If not paused, start recording
        mediaRecorderRef.current.start();
        // Set recording started to true
        setIsRecordingStarted(true);
        // Start the timer
        timerIntervalRef.current = setInterval(() => {
          setTimer((prevTimer) => prevTimer + 1);
        }, 1000);
      }
    }

    setIsRecording(!isRecording);
  };

  const sendRecording = () => {
    if (mediaRecorderRef.current) {
      // if (mediaRecorderRef.current.state === 'recording') {
      if (
        mediaRecorderRef.current.state === "recording" ||
        mediaRecorderRef.current.state === "paused"
      ) {
        // Stop recording if it's in progress
        mediaRecorderRef.current.stop();
        setIsRecording(false);
        clearInterval(timerIntervalRef.current);
      }

      // Handle the audio data
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        // Create a Blob from recorded audio chunks
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/ogg",
        });

        // let blob = new Blob(chunks, {type: media.type }), url = URL.createObjectURL(blob);

        var wavfromblob = new File([audioBlob], "incomingaudioclip.ogg", {
          type: audioBlob.type,
          // type: "audio/ogg; codecs=opus",
        });
        setIsAudioFileUploadingStarted(true);
        handleFile(wavfromblob, allowedFileTypes, true);
        // Reset audio chunks
        audioChunksRef.current = [];

        // Perform actions with the audioBlob (e.g., send it to the server)
        // uploadAudio(audioBlob);
      };

      // If recording was in progress, the onstop event will handle the audio data
      // Otherwise, handle the audio data immediately (e.g., if the recording was already stopped)
      if (mediaRecorderRef.current.state !== "recording") {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/ogg",
        });

        // Reset audio chunks
        audioChunksRef.current = [];

        // Perform actions with the audioBlob (e.g., send it to the server)
        // uploadAudio(audioBlob);
      }
    }
  };

  const handleChatTypedBoxChange = debounce((value) => {
    setMessage(value);
  }, 300); // Adjust the debounce delay as needed

  // const handleDownload = (path) => {
  //   const imageUrl = chatBaseUrl + "/" + path.replace("assets/", "");
  //   fetch(imageUrl)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const url = URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       a.href = url;
  //       a.download = "image"; // Specify the filename for the download
  //       a.style.display = "none";
  //       document.body.appendChild(a);
  //       a.click();
  //       document.body.removeChild(a);
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading file:", error);
  //     });
  // };

  const showUnableToTransfer = () => {
    // toast.error("No Conversation Or May not be in active State to transfer", {
    toast.error("Chats can be tranfer to online agents", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleOpenVoiceModal = () => {
    setOpenVoiceModal(true);
  };
  const handleCloseVoiceModal = () => {
    if (isRecording) {
      mediaRecorderRef.current = null;
      setIsRecording(false);
      setIsRecordingStarted(false);
      clearInterval(timerIntervalRef.current);
      setTimer(0);
    }
    setOpenVoiceModal(false);
  };

  const jsonData = [
    {
      data: "value 1",
    },
    {
      data: "value 2",
    },
    {
      data: "value 3",
    },
    {
      data: "value 4",
    },
    {
      data: "value 5",
    },
  ];
  // const handleOpenLocationModal = (latitude, longitude) => {
  //   setLocationDetails({
  //     ...locationDetails,
  //     latitude: latitude,
  //     longitude: longitude,
  //   });
  //   setOpenLocationModal(true);
  // };
  // const handleCloseLocationModal = () => {
  //   setOpenLocationModal(false);
  // };
  const removeCopiedImage = () => {
    setCopiedImageExist(null);
    setCopiedImagedetails(null);
    textareaRef.current.disabled = false;
  };
  const hideEmoji = () => {
    setOpenEmoji(false);
  };
  const handleOpenReinititalModal = () => {
    setOpenReinitialModal(true);
  };
  const handleCloseReinititalModal = () => {
    setOpenReinitialModal(false);
  };
  const handleOpenEmoji = (e) => {
    setOpenEmoji(true);
  };
  const handleLoaderModal = () => {
    setFileUploadError(null);
    setOpenFileLoader(false);
  };

  // this functions is uesd for pagination in chat messages

  const handleAgentlistFilterSelection = (
    targetElement,
    parentElement,
    data
  ) => {
    setClickedButtonIndex(targetElement);
    removeAndAddActiveClass(targetElement, parentElement, "active");
    setChatComplateMaintainedData(data);
    setAssignedAgent({
      ...assignedAgent,
      agentName: data.firstName + " " + data.lastName,
      agentPublicId: data.publicId,
      agentId: data.maskAgentId,
    });
    setAgentTrasnfer(true);
  };
  const handleAgentStatusFilterSelection = (
    targetElement,
    parentElement,
    data
  ) => {
    removeAndAddActiveClass(targetElement, parentElement, "active");
    setAssignedAgentStatus({
      ...assignedAgentStatus,
      // agentStatus: data.statusName === "REOPENED" ? "OPEN" : data.statusName,
      agentStatus: data.statusName,
      previousAgentStatus: assignedAgentStatus.agentStatus,
    });
    chatStatusChange(data.statusName);
  };

  const handleAddAsterisks = () => {
    if (selectionStart !== null && selectionEnd !== null) {
      // Add asterisks only at the selected position
      const inputField = document.getElementById("type_box");

      const modifiedText =
        inputField.value.slice(0, selectionStart) +
        "*" +
        inputField.value.slice(selectionStart, selectionEnd) +
        "*" +
        inputField.value.slice(selectionEnd);
      if (inputField) {
        inputField.value = modifiedText;
      }
      setMessage(modifiedText);
    }
  };

  const handlePaste = (event) => {
    const clipboardItems = event.clipboardData.items;
    for (const item of clipboardItems) {
      if (item.type.startsWith("image/")) {
        const file = item.getAsFile();
        setCopiedImagedetails(file);
        const reader = new FileReader();
        reader.onload = (event) => {
          setCopiedImageExist(event.target.result);
          if (textareaRef.current) {
            textareaRef.current.disabled = true;
          }
        };
        reader.readAsDataURL(file);
        break;
      }
    }
  };

  const handleAddDoubleUnderscores = () => {
    if (selectionStart !== null && selectionEnd !== null) {
      // Add asterisks only at the selected position
      const inputField = document.getElementById("type_box");
      const modifiedText =
        inputField.value.slice(0, selectionStart) +
        "_" +
        inputField.value.slice(selectionStart, selectionEnd) +
        "_" +
        inputField.value.slice(selectionEnd);

      if (inputField) {
        inputField.value = modifiedText;
      }
      // setMessage(modifiedText);
    }
  };

  // const handleChange = (e) => {
  //   const newText = e.target.value;
  //   setMessage(newText);
  // };
  const handleChange = (e) => {
    const newText = e.target.value;
    setMessage(newText);
    if (newText.startsWith("/")) {
      setFilterText(newText.substring(1).toLowerCase()); // Update filter text
      // setShowQuickMessages(true); // Show the quick messages dropdown
    }
  };

  const handelSelectedQuickMessage = (value) => {
    $("#type_box").val(value);
    setMessage(value);
  };
  const debouncedHandleSelect = debounce((event) => {
    setSelectionStart(event.target.selectionStart);
    setSelectionEnd(event.target.selectionEnd);
  }, 200); // Adjust the debounce delay as needed

  // const debouncedUpdateMessage = debounce((value) => setMessage(value), 100);

  // ******************************************  functions end *********************************
  //  ***********************************  api start ************************************
  const sendNotes = () => {
    if (notes !== "") {
      let data = {
        companyId: decryptedValue(auth.companyId),
        note: notes,
        phoneNumber: chatDetails.number,
      };
      apiPost(`/chat/createNotes`, onSuccesNotes, onFailureNotes, data);
    }
  };
  const onSuccesNotes = (response) => {
    if (response.status === 1) {
      setNotes("");
      setIsNotesCreated(true);
    }
  };
  const onFailureNotes = (error) => {
    console.log(error);
  };
  const getTemplates = () => {
    apiGet(`/template/`, onSuccessTags, onFailureTags);
  };
  const onSuccessTags = (response) => {
    setTemplates(response?.data);
  };
  const onFailureTags = (error) => {
    console.log(error);
  };

  const chatTransfer = (publicId) => {
    let data = {
      assignAgentId: publicId,
      chanelId: chatDetails.channel,
      companyId: chatDetails.companyId,
      currentAgentId: currentAgent.agentId,
      phoneNumber: chatDetails.number,
      transferReason: "Chat Transfer",
    };
    apiPost(
      `/chat/transfer-chat`,
      onSuccessChatTransfer,
      onFailureChatTransfer,
      data
    );
  };
  const onSuccessChatTransfer = (response) => {
    setClickedButtonIndex(null);
    if (response?.data.message !== "Chat Transfer Successfully") {
      toast.error(response?.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

      setAssignedAgent({
        ...assignedAgent,
        agentName: currentAgent.agentName,
        agentPublicId: currentAgent.agentPublicId,
        agentId: currentAgent.agentId,
      });
    } else {
      // fetchChat(chatDetails.agentId);
      fetchChat(decryptedValue(auth.idNumber));
      toast.success(`Chat successfully transfered`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
      });
      if (chatComplateMaintainedData.status === "ONLINE") {
        if (
          chatComplateMaintainedData.maskAgentId ===
          decryptedValue(auth.idNumber)
        ) {
          setChatDetails({
            ...chatDetails,
            conversationId: response.data.prop,
          });
          subscribeToChat(
            chatComplateMaintainedData.maskAgentId,
            response.data.prop
          );
        }
      }
    }
  };
  const onFailureChatTransfer = (error) => {
    setClickedButtonIndex(null);
    console.log(error);
  };
  const chatStatusChange = (status) => {
    let data = [
      {
        chatStatus:
          status === "SOLVED"
            ? "RESOLVED"
            : status === "WAITING"
            ? "PENDING"
            : status === "OPEN"
            ? "REOPENED"
            : status,
        // companyId: decryptedValue(auth.companyId),
        companyId: chatDetails.companyId,
        currentAgentId: decryptedValue(auth.idNumber),
        phoneNumber: chatDetails?.number,
      },
    ];
    apiPost(
      `/chat/update-chat-status
      `,
      onSuccessChatStatusChange,
      onFailureChatStatusChange,
      data
    );
  };
  const onSuccessChatStatusChange = (response) => {
    if (response.status === 1) {
      toast.success(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // fetchChat(chatDetails.publicId);
      fetchChat(decryptedValue(auth.idNumber));
    }
  };
  const onFailureChatStatusChange = (error) => {
    if (error.response.status === 400) {
      toast.error("Chat cannot be reopened after 24 hours", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setAssignedAgentStatus({
        ...assignedAgentStatus,
        agentStatus: assignedAgentStatus.previousAgentStatus,
      });
    }
  };

  const sendMessage = () => {
    let data;
    if (filePath !== "") {
      var mediaPayloadStream = mimType;
      data = {
        type: "media",
        stream: mimType,
        phoneNumber: chatDetails.number,
        textBody: $("type_box").val(),
        // recipientNumber: companyDetails?.whatsappNumber,
        recipientNumber: companyWhatsappNumber,
        chanelId: chatDetails.channel,
        name: chatDetails.agentName,
        agentId: decryptedValue(auth.idNumber),
        [mediaPayloadStream]: {
          fileId: filePath,
        },
      };
      apiPost(`/chat/send-message`, onSuccesChatSend, onFailureChatSend, data);
    } else if (
      message !== "" ||
      document.getElementById("type_box").value !== ""
    ) {
      data = {
        type: "text",
        phoneNumber: chatDetails.number,
        textBody: document.getElementById("type_box").value,
        // recipientNumber: companyDetails?.whatsappNumber,
        recipientNumber: companyWhatsappNumber,
        chanelId: chatDetails.channel,
        name: chatDetails.agentName,
        agentId: decryptedValue(auth.idNumber),
      };
      if (chatDetails?.channel === "SMS") {
        let smsData = {
          from: companyWhatsappNumber,
          to: chatDetails?.number,
          message: document.getElementById("type_box").value,
          responseType: "xml",
          customerName: chatDetails?.agentName,
          // agentId: decryptedValue(auth.idNumber)
        };
        apiPost(`/sms/send-sms`, onSuccesChatSend, onFailureChatSend, smsData);
      } else {
        apiPost(
          `/chat/send-message`,
          onSuccesChatSend,
          onFailureChatSend,
          data
        );
      }
    }
  };
  const onSuccesChatSend = (response) => {
    // if (response.status === 1) {
      setMessage("");
      $("#type_box").val("");
      setFilePath("");
      setCopiedImageExist(null);
      setCopiedImagedetails(null);
      if (isAudioFileUploadingStarted === true) {
        setIsAudioFileUploadingStarted(false);
      }
    // }
  };
  const onFailureChatSend = (error) => {
    console.log(error);
  };

  const handledelete = (fileId) => {
    apiDeleteSuperAdmin(
      `/chat/files/delete?fileId=${fileId}`,
      onSuccessDelete,
      onFailureDelete
    );
  };

  const onSuccessDelete = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        toast.success("File deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 1000);
      setTimeout(() => {
        getGalleryData(0);
      }, 1700);
    }
  };
  const onFailureDelete = (error) => {
    console.log(error);
  };

  // const handleMessage = (e) => {
  //   if (!e.ctrlKey && e.keyCode === 13) {
  //     sendMessage();
  //     e.preventDefault();
  //     e.stopPropagation();
  //     $("#type_box").prop("disabled", true);
  //     setTimeout(() => {
  //       $("#type_box").prop("disabled", false);
  //     }, 2000);
  //     return false;
  //   } else if (e.ctrlKey && e.keyCode === 13) {
  //     $("#type_box").val($("#type_box").val() + "\n");
  //   }
  // };

  const handleMessage = (e) => {
    if (!e.ctrlKey && e.keyCode === 13) {
      sendMessage();
      e.preventDefault();
      e.stopPropagation();
      $("#type_box").prop("disabled", true);
      setTimeout(() => {
        $("#type_box").prop("disabled", false);
      }, 2000);
      return false;
    } else if (e.keyCode === 191) {
      // Check if the keyCode is for '/'
      // setShowQuickMessages(true); // Show the quick messages dropdown
      setFilterText(""); // Reset filter text
    } else if (e.key.match(/[a-zA-Z]/) && message.startsWith("/")) {
      const searchText =
        message.substring(1).toLowerCase() + e.key.toLowerCase();
      setFilterText(searchText); // Update filter text
    } else if (e.ctrlKey && e.keyCode === 13) {
      $("#type_box").val($("#type_box").val() + "\n");
    }
  };

  // Filter the quick reply options based on the searchText
  const filteredQuickReply = quickReply.filter((val) =>
    val.subject.toLowerCase().startsWith(filterText)
  );

  const handleFile = (e, allowedTypes, isAudio) => {
    var selectedFile = isAudio ? e : e.target.files[0];
    let type = isAudio ? e.type : selectedFile.type;
    const maxSizeInMB = 10; // Change this value to your desired size
    if (selectedFile) {
      if (selectedFile.size / (1024 * 1024) > maxSizeInMB) {
        // alert(`File size exceeds the ${maxSizeInMB} MB limit`);
        setFileUploadError(`File size exceeds the ${maxSizeInMB} MB limit`);
        e.target.value = "";
      } else if (!allowedTypes.includes(selectedFile.type)) {
        // alert("Invalid file type. Please select a valid file.");
        setFileUploadError("Invalid file type. Please select a valid file.");
        e.target.value = "";
      } else {
        // File size and type are valid, you can proceed with the upload or further processing.
        // You might want to store the selected file in state or perform other actions here.
        setOpenFileLoader(true);
        if (type.includes("image")) {
          setMimType("image");
        }
        if (type.includes("video")) {
          setMimType("video");
        }
        if (type.includes("ogg")) {
          setMimType("audio");
        }
        if (type.includes("application") || type.includes("text/csv")) {
          setMimType("document");
        }
        if (!isAudio) {
          e.target.value = "";
        }
        var formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("agentPublicId", decryptedValue(auth.agentId));
        formData.append("companyId", decryptedValue(auth.companyId));
        apiPost(
          `/chat/upload-file`,
          onSuccessFileUpload,
          onFailureFileUpload,
          formData
        );
      }
    }
  };
  const onSuccessFileUpload = (response) => {
    if (response.status === 1) {
      setFilePath(response.message);
      setOpenFileLoader(false);
    }
  };
  const onFailureFileUpload = (error) => {
    console.log(error);
  };
  // *************************************** apis end *************************************

  // *************************************** use effects start ****************************
  useEffect(() => {
    if (fileUploadError !== null) {
      setOpenFileLoader(true);
    }
  }, [fileUploadError]);
  useEffect(() => {
    if (filePath !== "") {
      if (!isRecording) {
        mediaRecorderRef.current = null;
        setIsRecording(false);
        setIsRecordingStarted(false);
        clearInterval(timerIntervalRef.current);
        setTimer(0);
        handleCloseVoiceModal();
      }
      if (copiedImageExist !== null || isAudioFileUploadingStarted === true) {
        sendMessage();
      }
      // sendMessage();
    }
  }, [filePath]);
  useEffect(() => {
    if (isMessageBoxVisible !== null) {
      // if (isChatExpired !== null) {
      if (paginationCalled === false) {
        bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
        // bottomRef.current.scrollTop = addedMessagesHeight / 2;
      } else {
        const addedMessagesHeight = bottomRef.current.scrollHeight;

        // Set the scrollTop to the middle of the added height
        bottomRef.current.scrollTop =
          addedMessagesHeight - previousMessagesHeight;
      }

      setPreviousMessagesHeight(bottomRef.current.scrollHeight);
    }
  }, [isMessageBoxVisible, newMessages]);
  // }, [isChatExpired, newMessages]);
  useEffect(() => {
    var index = chatStatusData
      .map(function (o) {
        return o.statusName;
      })
      .indexOf(assignedAgentStatus?.agentStatus);
    if (index > -1) {
      setStatusColor(chatStatusData[index].chatStatus);
    } else {
      if (assignedAgentStatus?.agentStatus === "OPEN") {
        setStatusColor("greenDot");
      }
    }
    let filteredData;
    if (assignedAgentStatus?.agentStatus !== "OPEN") {
      filteredData = chatStatusData?.filter((val) => {
        return val.statusName !== "OPEN";
      });
      setChatStatusData(filteredData);
    }
  }, [assignedAgentStatus]);
  useEffect(() => {
    var filterAgent = [];
    if (contactMessages !== undefined) {
      let timestampValue = contactMessages?.conversation?.expiration;
      let data = timeStampConvertIntoExpiration(timestampValue);
      setExpirationTime(data);
      // isChatExpired(timestampValue)
      filterAgent = agentList?.filter((val) => {
        return (
          // val?.firstName + " " + val?.lastName ===
          // contactMessages?.conversation?.agentName
          val?.maskAgentId === contactMessages?.conversation?.agentId
        );
      });
      if (filterAgent?.length > 0) {
        setCurrentAgent({
          ...currentAgent,
          agentName: filterAgent[0]?.firstName + " " + filterAgent[0]?.lastName,
          agentPublicId: filterAgent[0]?.publicId,
          agentId: filterAgent[0].maskAgentId,
        });
        setAssignedAgent({
          ...assignedAgent,
          agentName: filterAgent[0].firstName + " " + filterAgent[0].lastName,
          agentPublicId: filterAgent[0].publicId,
          agentId: filterAgent[0].maskAgentId,
        });
      } else {
        setAssignedAgent({
          ...assignedAgent,
          agentName: "NA",
        });
      }

      setAssignedAgentStatus({
        ...assignedAgentStatus,
        agentStatus:
          contactMessages?.conversation?.status === "PENDING"
            ? "WAITING"
            : contactMessages?.conversation?.status === "RESOLVED"
            ? "SOLVED"
            : contactMessages?.conversation?.status === "ACTIVE"
            ? "OPEN"
            : contactMessages?.conversation?.status,
        agentPublicId: "",
        previousAgentStatus:
          contactMessages?.conversation?.status === "PENDING"
            ? "WAITING"
            : contactMessages?.conversation?.status === "RESOLVED"
            ? "SOLVED"
            : contactMessages?.conversation?.status === "ACTIVE"
            ? "OPEN"
            : contactMessages?.conversation?.status,
      });
    }

    // handleScroll();
    setIsChatExpired(contactMessages?.conversation?.isExpired);
    if (
      contactMessages?.conversation?.agentId === decryptedValue(auth.idNumber)
      // localStorage.getItem("_idNumber")
    ) {
      setIsMessageBoxVisible(true);
    } else {
      setIsMessageBoxVisible(false);
    }
  }, [contactMessages]);

  useEffect(() => {
    if (agentTrasnfer === true) {
      if (
        assignedAgent.agentId ===
        decryptedValue(localStorage.getItem("_idNumber"))
      ) {
        setIsMessageBoxVisible(true);
      } else {
        setIsMessageBoxVisible(false);
      }
    }
  }, [assignedAgent, agentTrasnfer]);
  // useEffect(() => {
  //   let filteredData;
  //   if (assignedAgentStatus?.agentStatus !== "OPEN") {
  //     filteredData = chatStatusData?.filter((val) => {
  //       return val.statusName !== "OPEN";
  //     });
  //     setChatStatusData(filteredData);
  //   }
  // }, [assignedAgentStatus]);
  useEffect(() => {
    if (chatComplateMaintainedData !== undefined) {
      if (chatComplateMaintainedData)
        chatTransfer(chatComplateMaintainedData.publicId);
    }
  }, [chatComplateMaintainedData]);

  // *************************************** use effects end ****************************

  return (
    <div className="uk-width-3-5 uk-padding-remove">
      <div className="">
        <div className="main-chat">
          <div className="agentChatHeader">
            <div className="chatHeaderWrp">
              <div className="agentNameDropdown">
                <nav uk-dropnav="mode: click" id="agentFilterDropdown">
                  <ul
                    className="uk-subnav agentDrop"
                    onClick={() => getAgentList()}
                  >
                    <li className="agentDropItem">
                      <a className="btn" style={{ height: "25px" }}>
                        {assignedAgent.agentName !== null &&
                          assignedAgent.agentName !== "NA" && (
                            <>
                              <span className="agentInitital">
                                {assignedAgent.agentName.substring(0, 1)}
                              </span>
                              {assignedAgent.agentName}
                              <span uk-drop-parent-icon=""></span>
                            </>
                          )}
                        {assignedAgent.agentName !== null &&
                          assignedAgent.agentName === "NA" && (
                            <>
                              Assigned to user
                              <span uk-drop-parent-icon=""></span>
                            </>
                          )}
                      </a>
                      <div className="uk-dropdown agentList">
                        <div className="dropdownHeading">
                          <span>User</span>
                        </div>
                        <ul
                          className="uk-nav uk-dropdown-nav"
                          style={{ height: "500px", overflowY: "scroll" }}
                        >
                          {agentList?.map((data, index) => {
                            return (
                              <li key={index}>
                                <button
                                  className="agentListbtn"
                                  id={"agent_id_" + index}
                                  onClick={(e) => {
                                    if (
                                      assignedAgent.agentPublicId !=
                                      data.publicId
                                    ) {
                                      data.status === "ONLINE"
                                        ? handleAgentlistFilterSelection(
                                            "#agent_id_" + index,
                                            ".agentDropItem ul li button agentListItem .active",
                                            data
                                          )
                                        : showUnableToTransfer();
                                    }
                                  }}
                                  disabled={
                                    clickedButtonIndex === "#agent_id_" + index
                                  }
                                >
                                  <div className="agentListItem">
                                    <div
                                      className={
                                        (assignedAgent.agentPublicId ==
                                          data.publicId) ==
                                        true
                                          ? "active left"
                                          : "left"
                                      }
                                    >
                                      <span
                                        className={
                                          data.status === "ONLINE"
                                            ? "markAgent onlineMark availableMarkone"
                                            : data.status === "AWAY"
                                            ? "markAgent awayMark availableMarkone"
                                            : data.status === "BREAK"
                                            ? "markAgent busyMark availableMarkone"
                                            : data.status === "OFFLINE" &&
                                              "markAgent offlineCheck availableMarkone"
                                        }
                                      ></span>
                                      <span className="agentInitital">
                                        {data.firstName.substring(0, 1)}
                                      </span>
                                      <p>
                                        {data.firstName + " " + data.lastName}
                                      </p>
                                    </div>
                                    <div
                                      className={
                                        (assignedAgent.agentPublicId ==
                                          data.publicId) ==
                                        true
                                          ? "active "
                                          : ""
                                      }
                                    >
                                      <span className="uk-icon tickIconOne">
                                        <Checkmark />
                                      </span>
                                    </div>
                                  </div>
                                </button>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
              {contactMessages !== undefined && expirationTime !== "" && (
                <div className="uk-width-expand uk-text-center">
                  <div className="timeExpireBox">
                    <Time />{" "}
                    <div
                      id="liveTimer"
                      uk-countdown={`date: ${expirationTime}`}
                    >
                      <span className="uk-countdown-hours" />h{" "}
                      <span className="uk-countdown-minutes" />m{" "}
                      <span className="uk-countdown-seconds" />s
                    </div>
                  </div>
                </div>
              )}
              <div className="chatStatus">
                <nav uk-dropnav="mode: click">
                  <ul className="uk-subnav openChatDrop">
                    <li className="openChatDropList">
                      <a className="openChatBtn">
                        <span className={statusColor}></span>
                        {assignedAgentStatus?.agentStatus}
                        <span uk-drop-parent-icon=""></span>
                      </a>
                      <div className="uk-dropdown chatStatusItem">
                        <ul className="uk-nav uk-dropdown-nav">
                          {chatStatusData?.map((data, index) => {
                            // <React.Fragment key={index + 1}>
                            return (
                              <>
                                {chatStatusData.length - 1 === index && <hr />}
                                <li key={index}>
                                  <button
                                    className="chatStatusbtn"
                                    id={"chat_status_id_" + index}
                                    onClick={(e) =>
                                      handleAgentStatusFilterSelection(
                                        "#chat_status_id_" + index,
                                        ".openChatDropList ul li button chatStatusList .active",
                                        data
                                      )
                                    }
                                    disabled={data.disabledStatus}
                                  >
                                    <div className="chatStatusList">
                                      <div
                                        className={
                                          (assignedAgentStatus?.agentStatus ==
                                            data.statusName) ==
                                          true
                                            ? "active left"
                                            : "left"
                                        }
                                      >
                                        <span
                                          className={data.chatStatus + ""}
                                        ></span>
                                        <p>{data.statusName}</p>
                                      </div>
                                      <div
                                        className={
                                          (assignedAgentStatus?.agentStatus ==
                                            data.statusName) ==
                                          true
                                            ? "active "
                                            : ""
                                        }
                                      >
                                        <span className="uk-icon tickIconTwo">
                                          <Checkmark />
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                </li>
                              </>
                            );
                            // </React.Fragment>
                          })}
                        </ul>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <div
            className="chat-msgs"
            onScroll={handleScroll}
            style={{
              height:
                isChatExpired !== null &&
                (userPermissions?.includes("LIVE-CHAT-AGENT") === true ||
                  userPermissions?.includes("LIVE-CHAT-MANAGER") === true)
                  ? "calc(100vh -  342px)"
                  : "calc(100vh - 136px)",
            }}
            ref={bottomRef}
          >
            {isChatExpired !== null &&
              isMessageBoxVisible !== null &&
              newMessages?.map((val, index) => {
                return (
                  <ChatMessageContainer
                    index={index}
                    val={val}
                    bottomRef={bottomRef}
                    newMessages={newMessages}
                    chatDetails={chatDetails}
                    hasMoreMessages={hasMoreMessages}
                    pageForChatMessages={pageForChatMessages}
                  />
                );
              })}
          </div>

          {(userPermissions?.includes("LIVE-CHAT-AGENT") ||
            userPermissions?.includes("LIVE-CHAT-MANAGER")) &&
          isChatExpired !== null &&
          !isChatExpired &&
          // here in this condition we can check if sms channel is selected so neglect isMessageBoxVisble condition
          (chatDetails.channel !== "SMS"
            ? isMessageBoxVisible === true
            : chatDetails.channel === "SMS") ? (
            <div className="msgBox">
              <div className="msgBoxTabbing">
                <ul uk-tab="" className="tabBtn">
                  <li>
                    <a href="/">Reply</a>
                  </li>
                  {chatDetails.channel !== "SMS" && (
                    <>
                      <li>
                        <a href="/">Notes</a>
                      </li>
                      <li>
                        <a href="/">Events</a>
                      </li>
                    </>
                  )}
                </ul>

                <ul className="uk-switcher uk-margin tabContent">
                  <li className="chatMsgBox">
                    {/* condition  */}
                    {assignedAgentStatus?.agentStatus === "OPEN" ||
                    assignedAgentStatus?.agentStatus === "REOPENED" ? (
                      <>
                        <div className="textBox">
                          <form action="">
                            {message.startsWith("/") && (
                              <div className="quickMessageBox">
                                <div className="container">Quick Messages</div>
                                <ul className="p0-m0">
                                  {quickReply?.length > 0 &&
                                    quickReply
                                      .filter((val) =>
                                        val.subject
                                          .toLowerCase()
                                          .startsWith(filterText)
                                      )
                                      .map((val, index) => (
                                        <li
                                          key={index}
                                          value={val.data}
                                          onClick={(e) =>
                                            handelSelectedQuickMessage(
                                              val.text,
                                              val.subject
                                            )
                                          }
                                        >
                                          <div className="heading">
                                            {val.subject}
                                          </div>
                                          <span>
                                            {val.text.slice(0, 50)}
                                            {val.text.length > 50 && " ..."}
                                          </span>
                                        </li>
                                      ))}
                                </ul>
                              </div>
                            )}

                            <textarea
                              name="msg_box"
                              id="type_box"
                              onChange={(e) => handleChange(e)}
                              onKeyDown={(e) => handleMessage(e)}
                              onSelect={(event) => debouncedHandleSelect(event)}
                              placeholder={
                                !copiedImageExist
                                  ? `Enter to send and Ctrl + Enter to start a new line \n or \n Enter / to see quick messages`
                                  : ""
                              }
                              cols="4"
                              rows="10"
                              ref={textareaRef}
                              // if sms channel is selected user doesnot add image on sms channel
                              onPaste={
                                chatDetails.channel !== "SMS" && handlePaste
                              }
                              style={{
                                cursor: copiedImageExist
                                  ? "not-allowed"
                                  : "text",
                              }}
                            ></textarea>
                            {copiedImageExist && (
                              <div className="copiedImageContainer">
                                <img src={copiedImageExist} alt="Pasted" />
                                <button className="crossSelection">
                                  <Close onClick={() => removeCopiedImage()} />
                                </button>
                              </div>
                            )}
                          </form>

                          <div className="btnwrp">
                            <div className="chatEdit">
                              {/* hide other options instead of text typing on sms channel */}
                              {copiedImageExist === null
                                ? chatDetails.channel !== "SMS" && (
                                    <ul>
                                      <li>
                                        {openEmoji && (
                                          <button id="emoji">
                                            <Emoji id="type_box" />
                                          </button>
                                        )}
                                        {!openEmoji ? (
                                          <button
                                            id="emojidiv"
                                            onClick={(e) => handleOpenEmoji()}
                                          >
                                            <FaceSatisfied />
                                          </button>
                                        ) : (
                                          <button
                                            id="emojiCross"
                                            className="emojiCross"
                                          >
                                            <Close
                                              onClick={(e) => hideEmoji()}
                                            />
                                          </button>
                                        )}
                                      </li>
                                      {/* hide options for instagram only  */}
                                      {chatDetails.channel !== "INSTAGRAM" && (
                                        <>
                                          <li>
                                            {/* <button type="button" onClick={handleBoldText}> */}
                                            <button
                                              type="button"
                                              onClick={handleAddAsterisks}
                                            >
                                              <TextBold />
                                            </button>
                                          </li>

                                          <li>
                                            <button
                                              type="button"
                                              onClick={
                                                handleAddDoubleUnderscores
                                              }
                                            >
                                              <TextItalic />
                                            </button>
                                          </li>
                                        </>
                                      )}
                                      {/* <li>
                                  <div className="js-upload" uk-form-custom="">
                                    <input
                                      type="file"
                                      id="file_image"
                                      title="Photos & Videos"
                                      accept="image/*"
                                      onChange={(e) =>
                                        handleFile(e, allowedImageFileTypes)
                                      }
                                      // onClick={handleOpenGalleryModal}
                                    />
                                    <button type="button">
                                      <Image />
                                    </button>
                                  </div>
                                </li> */}
                                      {chatDetails.channel !== "INSTAGRAM" && (
                                        <>
                                          {/* <li>
                                      <div
                                        className="js-upload"
                                        uk-form-custom=""
                                      >
                                        <input
                                          type="file"
                                          id="file_image"
                                          title="Photos & Videos"
                                          onChange={(e) =>
                                            handleFile(e, allowedFileTypes)
                                          }
                                        />
                                        <button type="button">
                                          <Attachment />
                                        </button>
                                      </div>
                                    </li> */}
                                          <li>
                                            <button
                                              type="button"
                                              onClick={() =>
                                                handleOpenGalleryModal()
                                              }
                                            >
                                              <Attachment />
                                            </button>
                                          </li>
                                          {/* <li onClick={handleOpenGalleryModal}>
                                      <button>Add Image</button>
                                    </li> */}
                                          {/* <li>
                                        <button type="button">
                                          <Location />
                                        </button>
                                      </li> */}
                                          <li>
                                            <button
                                              type="button"
                                              onClick={handleOpenVoiceModal}
                                            >
                                              <Microphone />
                                            </button>
                                          </li>
                                        </>
                                      )}
                                    </ul>
                                  )
                                : null}
                            </div>
                          </div>
                        </div>

                        <div className="submitBtn msgBoxSubmitBtn">
                          <button
                            type="button"
                            onClick={() =>
                              copiedImageExist !== null
                                ? handleFile(
                                    copiedImagedetails,
                                    allowedImageFileTypes,
                                    true
                                  )
                                : sendMessage()
                            }
                          >
                            Send{" "}
                            {/* {
                                chatDetails.channel
                                  .toLowerCase()
                                  .charAt(0)
                                  .toUpperCase() +
                                  chatDetails.channel.toLowerCase().slice(1)} */}
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="reInititalChat">
                        {chatDetails.channel === "WHATSAPP" ? (
                          <p>
                            You can reopen a chat with the user within a 24-hour
                            window. After this period, you can use{" "}
                            <a onClick={handleOpenReinititalModal}>
                              Broadcasts
                            </a>{" "}
                            to reach out to customers.
                          </p>
                        ) : (
                          <p>
                            The last interaction with this customer was more
                            than 24 hours ago. To initiate the chat customer
                            needs to connect with you
                          </p>
                        )}
                      </div>
                    )}
                  </li>

                  <li>
                    <div className="notesTab">
                      <div className="notesItem">
                        <textarea
                          name="msg_box"
                          placeholder="Enter to send and Ctrl + Enter to start a new line"
                          value={notes}
                          onChange={(e) => {
                            setNotes(e.target.value);
                          }}
                        ></textarea>
                      </div>
                      <div className="addNoteBtn">
                        <button type="button" onClick={sendNotes}>
                          Add Note
                        </button>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="eventsTab">
                      <div className="eventsItem">
                        <ul>
                          {contactMessages?.chatEvents?.map((val, index) => {
                            return (
                              <li key={index}>
                                <p>{eventDateTime(val.createDateTime)}</p>
                                <p>{val.message}</p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div className="msgBox">
              <div className="msgBoxTabbing">
                <ul uk-tab="" className="tabBtn">
                  <li>
                    <a href="/">Reply</a>
                  </li>
                  <li>
                    <a href="/">Notes</a>
                  </li>
                  <li>
                    <a href="/">Events</a>
                  </li>
                </ul>

                <ul className="uk-switcher uk-margin tabContent">
                  <li className="chatMsgBox">
                    <div className="reInititalChat">
                      {chatDetails.channel === "WHATSAPP" ? (
                        <p>
                          You can reopen a chat with the user within a 24-hour
                          window. After this period, you can use{" "}
                          <a onClick={handleOpenReinititalModal}>Broadcasts</a>{" "}
                          to reach out to customers.
                        </p>
                      ) : (
                        <p>
                          The last interaction with this customer was more than
                          24 hours ago. To initiate the chat customer needs to
                          connect with you
                        </p>
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="notesTab">
                      <div className="notesItem">
                        <textarea
                          name="msg_box"
                          placeholder="Enter to send and Ctrl + Enter to start a new line"
                          value={notes}
                          onChange={(e) => {
                            setNotes(e.target.value);
                          }}
                        ></textarea>
                      </div>
                      <div className="addNoteBtn">
                        <button type="button" onClick={sendNotes}>
                          Add Note
                        </button>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="eventsTab">
                      <div className="eventsItem">
                        <ul>
                          {contactMessages?.chatEvents?.map((val, index) => {
                            return (
                              <li key={index}>
                                <p>{eventDateTime(val.createDateTime)}</p>
                                <p>{val.message}</p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
      {openReinitialModal && (
        <ReinititalModal
          closeModal={handleCloseReinititalModal}
          templates={templates}
          contactMessages={contactMessages}
          getTemplates={getTemplates}
          socketResponse={socketResponse}
          fetchChat={fetchChat}
          chatDetails={chatDetails}
        />
      )}
      {/* {openLocationModal && (
        <LocationModal
          closeModal={handleCloseLocationModal}
          locationDetails={locationDetails}
        />
      )} */}
      {openFileLoader && (
        <FileLoader errors={fileUploadError} closeModal={handleLoaderModal} />
      )}

      {openVoiceModal && (
        <VoiceModal
          closeModal={handleCloseVoiceModal}
          startRecording={startRecording}
          isRecording={isRecording}
          toggleRecording={toggleRecording}
          isRecordingStarted={isRecordingStarted}
          sendRecording={sendRecording}
          timer={timer}
        />
      )}
      {openGalleryModal && (
        <GalleryModal
          closeModal={handleCloseGalleryModal}
          setFilePath={setFilePath}
          filePath={filePath}
          sendMessage={sendMessage}
          setMimType={setMimType}
          getGalleryData={getGalleryData}
          galleryData={galleryData}
          pageCount={pageCount}
          setApiCalledGallery={setApiCalledGallery}
          apiCalledGallery={apiCalledGallery}
          handledelete={handledelete}
        />
      )}
    </div>
  );
};

export default ChatMessages;
