import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import { Filter } from "@carbon/icons-react";
import { apiGet, apiPost } from "../../../../Utils/apiServices";
import { useAuth } from "../../../../Context/AuthProvider";
import ChatMsgBeforeLoadScreen from "../../components/ChatMsgBeforeLoadScreen";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import ChatBotContact from "../../components/Bot/ChatBotContact";
import ChatBotMessages from "../../components/Bot/ChatBotMessages";
import ChatBotProfile from "../../components/Bot/ChatBotProfile";
import { useNavigate, useOutletContext } from "react-router-dom";
import ChatBotFilter from "../Modals/ChatBotFilter";
import { decryptedValue } from "../../../../Utils/RouteHashConversion";
import AccountNotify from "../../../../components/Account-disable-notify/AccountNotify";

const ChatBot = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [checked, setChecked] = useState(
    selectedStatus === "AWAY" ? true : false
  );
  const [selectedChannel, setSelectedChannel] = useState("ALL");
  const [openChatBotFilterModal, setOpenChatBotFilterModal] = useState(false);
  const [agentList, setAgentList] = useState();
  const [contactApiDetails, setContactApiDetails] = useState({
    agentId: "",
    chatFilter: "",
  });
  const [multiSelectTags, setMultiSelectTags] = useState([]);
  const [botChatContactList, setBotChatContactList] = useState();
  const [newMessages, setNewMessages] = useState();
  const [chatBotDetails, setChatBotDetails] = useState({
    number: "",
    channel: "",
    agentName: "",
    agentId: "",
  });
  const [apicalled, setApicalled] = useState(false);
  const [botContactMessages, setBotContactMessages] = useState(null);
  const [botLoader, setBotLoader] = useState(false);
  const [filterData, setFilterData] = useState({
    fromDate: null,
    toDate: null,
    country: "",
    city: "",
    gender: "",
    Status: "",
    searchQuery: "",
    countryList: "",
    tags: [],
    agentId: "",
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMoreContacts, setHasMoreContacts] = useState(true);
  const [pageForChatMessages, setPageForChatMessages] = useState(0);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [paginationCalled, setPaginationCalled] = useState(false);

  const isActiveChannel = companyDetails?.activeChannels.filter(function (o) {
    return o.chanelId === "FACEBOOK";
  });

  // ***************************************** start apis *****************************
  const getAllBotChatContacts = (agentId, chatFilter, number, isSearched) => {
    setContactApiDetails({
      agentId: agentId,
      chatFilter: chatFilter,
    });

    if (agentId !== undefined) {
      let apiParameters =
        selectedChannel === "ALL"
          ? `${chatFilter}?page=${isSearched === undefined ? currentPage : 0}`
          : `${chatFilter}?chanelId=${selectedChannel}&page=${
              number === undefined ? currentPage : number
            }`;
      apiGet(
        `/chat/contactList/${apiParameters}
      `,
        onSuccessBotChatContacts,
        onFailureBotChatContacts
      );
    }
  };
  const onSuccessBotChatContacts = (response) => {
    if (response.status === 1) {
      let page = currentPage + 1;
      setCurrentPage(page);
      if (botChatContactList === undefined) {
        setBotChatContactList(response.data);
      } else {
        if (page === 1) {
          setBotChatContactList(response.data);
        } else {
          if (response.data.length !== 0) {
            setBotChatContactList((prevMessages) => [
              ...prevMessages,
              ...response.data,
              // response.data[response.data.length - 1],
            ]);
          }
        }
      }
      if (response.data.length === 0) {
        // here we can check if no record found so we can update state not to call api
        setHasMoreContacts(false);
      } else {
        if (response.data.length < 20) {
          // here we can check data exist but is lesser than 20 records means no more data exist
          setHasMoreContacts(false);
        }
      }
    }
  };
  const onFailureBotChatContacts = (error) => {
    console.log(error);
    setBotChatContactList([]);
  };
  const changeAgentStatus = (filterValue) => {
    apiPost(
      `/agent/changeStatus/${filterValue}/${decryptedValue(auth.agentId)}`,
      onSuccessChnageAgentStatus,
      onFailureChnageAgentStatus
    );
  };
  const onSuccessChnageAgentStatus = (response) => {
    toast.success("Status changes successfully", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1500,
    });
    if (checked === true) {
      setSelectedStatus("ONLINE");
    } else {
      setSelectedStatus("AWAY");
    }
  };
  const onFailureChnageAgentStatus = (error) => {
    console.log(error);
  };
  const getChatBotMessages = (number, channelId, agentName, agentId) => {
    setChatBotDetails({
      // ...chatDetails,
      number: number,
      channel: channelId,
      agentName: agentName,
      agentId: agentId,
    });
    setBotLoader(true);
    apiGet(
      `/chat/details/${number}/${decryptedValue(
        auth.companyId
      )}/${decryptedValue(auth.idNumber)}/${channelId}?page=${0}`,
      onSuccessChatBotMessages,
      onFailureChatBotMessages
    );
  };
  const onSuccessChatBotMessages = (response) => {
    if (
      response.data.inboxes.length > 0 &&
      response.data.inboxes.length === 20
    ) {
      setHasMoreMessages(true);
      setPageForChatMessages((prevCount) => prevCount + 1);
    } else {
      setHasMoreMessages(false);
    }
    setBotLoader(false);
    setBotContactMessages(response?.data);
    setNewMessages(response?.data?.inboxes);
  };
  const onFailureChatBotMessages = (error) => {
    console.log(error);
    setBotLoader(false);
  };
  const getContactTags = () => {
    apiGet(`/contact/tags`, onSuccessContactTags, onFailureContactTags);
  };
  const onSuccessContactTags = (response) => {
    const updatedArray = response?.data?.map((element) => ({
      ...element,
      name: element.tagName,
    }));
    setMultiSelectTags(updatedArray);
  };
  const onFailureContactTags = (error) => {
    console.log(error);
  };
  const getAgentList = () => {
    apiGet(`/agent/list/AGENT`, onSuccessAgentList, onFailureAgentList);
  };
  const onSuccessAgentList = (response) => {
    setAgentList(response?.data);
  };
  const onFailureAgentList = (error) => {
    console.log(error);
  };
  const getAgentStatus = () => {
    apiGet(`/auth/user-status`, onSuccessStatus, onFailureStatus);
  };
  const onSuccessStatus = (response) => {
    if (response?.data === "AWAY") {
      setChecked(true);
    }
  };
  const onFailureStatus = (error) => {
    console.log(error);
  };
  const onChange = (e) => {
    setCurrentPage(0);
    var searchedValue = e.target.value;
    const data = {
      dateStart: null,
      dateEnd: null,
      country: "",
      city: "",
      gender: "",
      contactTags: [],
      status: "BOT",
      searchQuery: searchedValue,
      companyId: decryptedValue(auth.companyId),
    };
    setBotContactMessages(null);
    setBotChatContactList(undefined);
    if (searchedValue !== "") {
      apiPost(
        `/chat/search-chat-filter`,
        onSuccessSearch,
        onFailureSearch,
        data
      );
    } else {
      getAllBotChatContacts(
        contactApiDetails.agentId,
        contactApiDetails.chatFilter,
        0,
        true
      );
    }
  };
  const onSuccessSearch = (response) => {
    if (response.status === 1) {
      setBotChatContactList(response.data);
    }
  };
  const onFailureSearch = (error) => {
    console.log(error);
  };

  const getChatMoreMessages = (number, channelId, agentName, agentId) => {
    apiGet(
      `/chat/details/${number}/${decryptedValue(
        auth.companyId
      )}/${decryptedValue(
        auth.idNumber
      )}/${channelId}?page=${pageForChatMessages}`,
      onSuccessChatMoreMessages,
      onFailureChatMoreMessages
    );
  };
  const onSuccessChatMoreMessages = (response) => {
    if (
      response.data.inboxes.length > 0 &&
      response.data.inboxes.length === 20
    ) {
      setHasMoreMessages(true);
      setPageForChatMessages((prevCount) => prevCount + 1);
    } else {
      setHasMoreMessages(false);
    }
    setPaginationCalled(true);
    // setContactMessages(response?.data);
    setNewMessages((prev) => [...response?.data?.inboxes, ...prev]);
  };
  const onFailureChatMoreMessages = (error) => {
    if (error.response.status === 400) {
      if (error.response.data.data === null) {
        setHasMoreMessages(false);
      }
    }
  };

  // ***************************************** end apis *****************************

  // ***************************************** useeffect block start *****************************
  useEffect(() => {
    if (apicalled === true) {
      getAllBotChatContacts(
        contactApiDetails.agentId,
        contactApiDetails.chatFilter
      );
    }
  }, [apicalled]);
  useEffect(() => {
    document.body.classList.remove("visibleSplash");
    getAgentList();
    getContactTags();
    getAgentStatus();
  }, []);
  useEffect(() => {
    if (
      contactApiDetails.chatFilter !== "" &&
      contactApiDetails.agentId !== ""
    ) {
      getAllBotChatContacts(
        contactApiDetails.agentId,
        contactApiDetails.chatFilter
      );
      setBotChatContactList(undefined);
      setBotContactMessages(null);
    }
  }, [selectedChannel]);
  // ***************************************** useeffect block end *****************************

  // ***************************************** functions block start *****************************
  const handleScroll = (e) => {
    if (e?.currentTarget?.scrollTop === 0) {
      if (hasMoreMessages) {
        getChatMoreMessages(
          chatBotDetails.number,
          chatBotDetails.channelId,
          chatBotDetails.agentName,
          chatBotDetails.agentId
        );
      }
    }
  };

  const debouncedOnChange = debounce(onChange, 1000);

  // for filter modal
  const handleOpenChatBotFilterModal = () => {
    setOpenChatBotFilterModal(true);
  };
  const handleCloseChatBotFilterModal = () => {
    setOpenChatBotFilterModal(false);
  };

  const handleChecked = () => {
    if (checked === true) {
      changeAgentStatus("ONLINE");
      setChecked(false);
    } else {
      changeAgentStatus("AWAY");
      setChecked(true);
    }
  };
  // ***************************************** functions block end *****************************

  return (
    <>
      {/* {permissionsLoader ? (
        <div className="loaderScreen">
          <div uk-spinner="" className="loader"></div>
        </div>
      ) : ( */}
      <AccountNotify companyDetails={companyDetails} />
      <div className="chatwrp chatwrpMessage ">
        <div className="chatWrpContent">
          <div className="chatHeader">
            <ul
              className="uk-subnav top-tabbing "
              id="rednerChatTab"
              uk-switcher="connect: .chatTabbing"
            >
              {(userPermissions?.includes("LIVE-CHAT-AGENT") ||
                userPermissions?.includes("LIVE-CHAT-MANAGER")) && (
                <li>
                  <a
                    href="/chat"
                    onClick={() => {
                      navigate("/chat", { replace: true });
                    }}
                  >
                    {userPermissions.length > 0
                      ? userPermissions?.includes("LIVE-CHAT-MANAGER")
                        ? "Live Chat"
                        : userPermissions?.includes("LIVE-CHAT-AGENT") &&
                          "My Chat"
                      : ""}
                  </a>
                </li>
              )}
              {/* {userPermissions.includes("CHATBOT-CONVERSATION") && ( */}
              {(userPermissions?.includes("CHATBOT-CONVERSATION") ||
                userPermissions?.includes("CHAT-BOT-MANAGER") ||
                userPermissions?.includes("CHATBOT-SUPERVISOR")) && (
                <li className="uk-active">
                  {userPermissions.length > 0 ? (
                    <a href="/chat-bot">Bot Chat</a>
                  ) : (
                    ""
                  )}
                </li>
              )}
              {/* <li>
                {userPermissions.length > 0 ? (
                  <a
                    onClick={() =>
                      navigate("/internal-chat", { replace: true })
                    }
                  >
                    Internal Chat
                  </a>
                ) : (
                  ""
                )}
              </li> */}
              {/* <li>
                {userPermissions.length > 0 && isActiveChannel.length > 0 ? (
                  <a
                    onClick={() => navigate("/fb-comments", { replace: true })}
                  >
                    Facebook Comments
                  </a>
                ) : (
                  ""
                )}
              </li> */}
              {/* )} */}
            </ul>
            <div className="chatSearchFilter">
              <ul className="uk-switcher chatTabbing">
                {(userPermissions?.includes("LIVE-CHAT-AGENT") ||
                  userPermissions?.includes("LIVE-CHAT-MANAGER")) && <li></li>}
                {(userPermissions?.includes("CHATBOT-CONVERSATION") ||
                  userPermissions?.includes("CHAT-BOT-MANAGER") ||
                  userPermissions?.includes("CHATBOT-SUPERVISOR")) && (
                  <li>
                    <div className="searchAndFilter">
                      <div className="searchInput">
                        <form action="">
                          <input
                            placeholder="Search through live chat conversations"
                            onChange={debouncedOnChange}
                          />
                        </form>
                      </div>
                      <div className="filterBtn">
                        <button
                          type="button"
                          onClick={handleOpenChatBotFilterModal}
                        >
                          <Filter /> <span>Filter</span>
                        </button>
                      </div>
                    </div>
                  </li>
                )}
                <li></li>
                <li></li>
              </ul>
            </div>
            <div className="chatTabContent">
              {/* <div className="socialIcons">
                  <ul>
                    <li>
                      <img src={whatsappLogo} alt="" />
                      <span className="uk-badge">1</span>
                    </li>
                    <li>
                      <img src={messengerIcon} alt="" />
                      <span className="uk-badge">1</span>
                    </li>
                    <li>
                      <img src={instaIcon} alt="" />
                      <span className="uk-badge">1</span>
                    </li>
                  </ul>
                </div>  */}
              <div className="toggleSwitch">
                <label>
                  <Switch
                    onChange={handleChecked}
                    checked={checked}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={26}
                    height={14}
                    handleDiameter={10}
                    onColor="#ff7124"
                  />
                </label>
                <span className={checked && "toggleChecked"}>Away</span>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className="chatWrpItem">
          <ul className="uk-switcher chatTabbing">
            {(userPermissions?.includes("LIVE-CHAT-AGENT") ||
              userPermissions?.includes("LIVE-CHAT-MANAGER")) && <li></li>}
            {(userPermissions?.includes("CHATBOT-CONVERSATION") ||
              userPermissions?.includes("CHAT-BOT-MANAGER") ||
              userPermissions?.includes("CHATBOT-SUPERVISOR")) && (
              <li>
                <div className="uk-grid" uk-grid="">
                  <ChatBotContact
                    getAllBotChatContacts={getAllBotChatContacts}
                    botChatContactList={botChatContactList}
                    getChatBotMessages={getChatBotMessages}
                    companyDetails={companyDetails}
                    setSelectedChannel={setSelectedChannel}
                    selectedChannel={selectedChannel}
                    hasMoreContacts={hasMoreContacts}
                    setHasMoreContacts={setHasMoreContacts}
                    setCurrentPage={setCurrentPage}
                  />
                  {botContactMessages !== null && botLoader === false && (
                    <>
                      <ChatBotMessages
                        botContactMessages={botContactMessages}
                        chatBotDetails={chatBotDetails}
                        agentList={agentList}
                        getAgentList={getAgentList}
                        handleScroll={handleScroll}
                        hasMoreMessages={hasMoreMessages}
                        pageForChatMessages={pageForChatMessages}
                        newMessages={newMessages}
                      />
                      <ChatBotProfile
                        botContactMessages={botContactMessages}
                        multiSelectTags={multiSelectTags}
                      />
                    </>
                  )}

                  {botContactMessages === null && botLoader === false && (
                    <ChatMsgBeforeLoadScreen />
                  )}

                  {botLoader === true && (
                    <div className="uk-width-4-5 uk-padding-remove-left">
                      <div className="chatSeletionWrp">
                        <div className="chatSelectionContent">
                          <div className=" uk-width-expand spinner">
                            <div className="uk-text-center uk-position-center ">
                              <span uk-spinner="ratio: 2" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
      {openChatBotFilterModal && (
        <ChatBotFilter
          closeModal={handleCloseChatBotFilterModal}
          filterModalDescTxt=""
          filterData={filterData}
          setFilterData={setFilterData}
          setApicalled={setApicalled}
          setBotChatContactList={setBotChatContactList}
          setBotContactMessages={setBotContactMessages}
        />
      )}
    </>
  );
};

export default ChatBot;
