import { Checkmark, Location } from "@carbon/icons-react";
import { useState } from "react";
// import { chatBaseUrl } from "../../../../Constant";
import React, { useEffect, useRef } from "react";
import {
  chatMessageDayandDateConvert,
  formatChatHoverDateTime,
} from "../../../../Utils/DateFunctions";
import { apiPost } from "../../../../Utils/apiServices";
import { useAuth } from "../../../../Context/AuthProvider";
import { removeAndAddActiveClass } from "../../../../Utils/jqueryFunctions";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import LocationModal from "../Modals/LocationModal";
import botchatassignimage from "../../../../assets/images/botchatassignimage.svg";
import { Document, Page, pdfjs } from "react-pdf";

import {
  displayMessageStatusIcon,
  formatText,
  getToolTipPosition,
  sanitizedMessage,
} from "../../../../Utils/chat/chatFunctions";
import { decryptedValue } from "../../../../Utils/RouteHashConversion";
const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ChatBotMessages = ({
  botContactMessages,
  agentList,
  chatBotDetails,
  getAgentList,
  handleScroll,
  hasMoreMessages,
  pageForChatMessages,
  newMessages,
}) => {
  const { auth } = useAuth();
  //  ****************************state for chat messages ********************************
  const bottomRef = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState();
  const [currentAgent, setCurrentAgent] = useState({
    agentName: "",
    agentPublicId: "",
  });
  const [assignedAgent, setAssignedAgent] = useState({
    agentName: "",
    agentPublicId: "",
  });
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [locationDetails, setLocationDetails] = useState({
    latitude: "",
    longitude: "",
  });
  const [clickedButtonIndex, setClickedButtonIndex] = useState(null);

  //  ****************************state for chat messages end ********************************

  // ******************************************  functions starts *********************************

  function onDocumentLoadSuccess() {
    setNumPages(numPages);
  }

  const handleOpenLocationModal = (latitude, longitude) => {
    setLocationDetails({
      ...locationDetails,
      latitude: latitude,
      longitude: longitude,
    });
    setOpenLocationModal(true);
  };
  const handleCloseLocationModal = () => {
    setOpenLocationModal(false);
  };

  const showUnableToTransfer = () => {
    toast.error("No Conversation Or May not be in active State to transfer", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleAgentlistFilterSelection = (
    targetElement,
    parentElement,
    data
  ) => {
    setClickedButtonIndex(targetElement);
    removeAndAddActiveClass(targetElement, parentElement, "active");
    setAssignedAgent({
      ...assignedAgent,
      agentName: data.firstName + " " + data.lastName,
      agentPublicId: data.publicId,
    });
    chatTransfer(data.publicId);
  };
  // ******************************************  functions end *********************************

  //  ***********************************  api start ************************************
  const chatTransfer = (publicId) => {
    let data = {
      assignAgentId: publicId,
      chanelId: chatBotDetails.channel,
      companyId: decryptedValue(auth.companyId),
      currentAgentId: currentAgent.agentPublicId,
      phoneNumber: chatBotDetails.number,
      transferReason: "Chat Transfer",
    };
    apiPost(
      `/chat/transfer-chat`,
      onSuccessChatTransfer,
      onFailureChatTransfer,
      data
    );
  };

  const onSuccessChatTransfer = (response) => {
    setClickedButtonIndex(null);
    if (response?.data.message !== "Chat Transfer Successfully") {
      toast.error(response?.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setAssignedAgent({
        ...assignedAgent,
        agentName: currentAgent.agentName,
        agentPublicId: currentAgent.agentPublicId,
      });
    } else {
      toast.success(
        `Chat successfully transfered to ${assignedAgent.agentName}`,
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
        }
      );
    }
  };
  const onFailureChatTransfer = (error) => {
    setClickedButtonIndex(null);
    console.log(error);
  };
  //  ***********************************  api end ************************************
  // *************************************** use effects start ****************************
  useEffect(() => {
    var filterAgent = [];
    if (botContactMessages !== undefined) {
      filterAgent = agentList?.filter((val) => {
        return (
          val?.firstName + " " + val?.lastName ===
          botContactMessages?.conversation?.agentName
        );
      });
      if (filterAgent?.length > 0) {
        setCurrentAgent({
          ...currentAgent,
          agentName: filterAgent[0]?.firstName + " " + filterAgent[0]?.lastName,
          agentPublicId: filterAgent[0]?.publicId,
        });
        setAssignedAgent({
          ...assignedAgent,
          agentName: filterAgent[0].firstName + " " + filterAgent[0].lastName,
          agentPublicId: filterAgent[0].publicId,
        });
      }
    }
    bottomRef.current?.scrollIntoView({ behavior: "auto" });
    // handleScroll();
  }, [newMessages]);
  // *************************************** use effects end ****************************

  return (
    <div className="uk-width-3-5 uk-padding-remove">
      <div className="">
        <div className="main-chat">
          <div className="agentChatHeader">
            <div className="chatHeaderWrp">
              <div className="agentNameDropdown">
                <nav uk-dropnav="mode: click" id="agentFilterDropdown">
                  <ul
                    className="uk-subnav agentDrop"
                    onClick={() => getAgentList()}
                  >
                    <li className="agentDropItem">
                      <a className="btn">
                        {assignedAgent.agentName === "" ? (
                          <img src={botchatassignimage} alt="" />
                        ) : (
                          <span className="agentInitital">
                            {assignedAgent.agentName.substring(0, 1)}
                          </span>
                        )}

                        {assignedAgent.agentName === ""
                          ? "Assign to user"
                          : assignedAgent.agentName}
                        <span uk-drop-parent-icon=""></span>
                      </a>
                      <div className="uk-dropdown agentList">
                        <div className="dropdownHeading">
                          <span>Users</span>
                        </div>
                        <ul
                          className="uk-nav uk-dropdown-nav"
                          style={{ height: "500px", overflowY: "scroll" }}
                        >
                          {agentList?.map((data, index) => {
                            return (
                              <>
                                <li key={index}>
                                  <button
                                    className="agentListbtn"
                                    id={"agent_idBot_" + index}
                                    onClick={(e) => {
                                      if (
                                        assignedAgent.agentPublicId !=
                                        data.publicId
                                      ) {
                                        data.status === "ONLINE"
                                          ? handleAgentlistFilterSelection(
                                              "#agent_idBot_" + index,
                                              ".agentDropItem ul li button agentListItem .active",
                                              data
                                            )
                                          : showUnableToTransfer();
                                      }
                                    }}
                                    disabled={
                                      clickedButtonIndex ===
                                      "#agent_idBot_" + index
                                    }
                                  >
                                    <div className="agentListItem">
                                      <div
                                        className={
                                          (assignedAgent.agentPublicId ==
                                            data.publicId) ==
                                          true
                                            ? "active left"
                                            : "left"
                                        }
                                      >
                                        <span
                                          className={
                                            data.status === "ONLINE"
                                              ? "markAgent onlineMark availableMarkone"
                                              : data.status === "AWAY"
                                              ? "markAgent awayMark availableMarkone"
                                              : data.status === "BREAK"
                                              ? "markAgent busyMark availableMarkone"
                                              : data.status === "OFFLINE" &&
                                                "markAgent offlineCheck availableMarkone"
                                          }
                                        ></span>
                                        <span className="agentInitital">
                                          {data.firstName.substring(0, 1)}
                                        </span>
                                        <img src={data.agentImg} alt="" />
                                        <p>
                                          {data.firstName + " " + data.lastName}
                                        </p>
                                      </div>
                                      <div
                                        className={
                                          (assignedAgent.agentPublicId ==
                                            data.publicId) ==
                                          true
                                            ? "active "
                                            : ""
                                        }
                                      >
                                        <span className="uk-icon tickIconOne">
                                          <Checkmark />
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="chatStatus"></div>
            </div>
          </div>
          <div
            className="chat-msgs"
            onScroll={handleScroll}
            style={{
              height: "calc(100vh - 136px)",
            }}
            // onScroll={(e) => handleScroll(e)}
          >
            {newMessages?.map((val, index) => {
              const messageKey = val.id || `index-${index}`;
              const dateKey = `date-${messageKey}`;
              return (
                <React.Fragment key={messageKey}>
                  {index == 0 && pageForChatMessages > 0
                    ? hasMoreMessages && (
                        <div className="date-and-time">
                          <div className="txtwrp">
                            <div uk-spinner=""></div>
                          </div>
                        </div>
                      )
                    : null}
                  {index == 0 && (
                    <div className="date-and-time" key={dateKey}>
                      <div className="txtwrp">
                        <p>{chatMessageDayandDateConvert(val.timestamp)}</p>
                      </div>
                    </div>
                  )}

                  {index !== 0
                    ? chatMessageDayandDateConvert(
                        newMessages[index - 1].timestamp
                      ) !== chatMessageDayandDateConvert(val?.timestamp) && (
                        <div className="date-and-time" key={dateKey}>
                          <div className="txtwrp">
                            <p>{chatMessageDayandDateConvert(val.timestamp)}</p>
                          </div>
                        </div>
                      )
                    : null}

                  <div
                    className={
                      val.isSent == false || val.isSent == "false"
                        ? "rec-msg"
                        : "send-msg"
                    }
                    key={messageKey}
                  >
                    {(val.messageType === "text" ||
                      val.messageType === "TEXT") && (
                      <div className="txtdiv">
                        {(val.isSent === "false" || val.isSent === false) &&
                          (val.body === null ? (
                            <pre
                              uk-tooltip={`title: ${formatChatHoverDateTime(
                                val.timestamp
                              )}; pos: ${getToolTipPosition(val.isSent)}`}
                            >
                              {val.body}
                            </pre>
                          ) : val.body.startsWith("https:") ||
                            val.body.startsWith("http:") ? (
                            <pre>
                              {" "}
                              <a href={val.body} target="_blank">
                                {val.body}
                              </a>
                            </pre>
                          ) : (
                            <pre
                              uk-tooltip={`title: ${formatChatHoverDateTime(
                                val.timestamp
                              )}; pos: ${getToolTipPosition(val.isSent)}`}
                              // dangerouslySetInnerHTML={{
                              //   __html: formatText(val.body),
                              // }}
                              dangerouslySetInnerHTML={sanitizedMessage(
                                formatText(val.body)
                              )}
                            ></pre>
                          ))}
                        {getToolTipPosition(val.isSent) === "right" &&
                          displayMessageStatusIcon(val.messageStatus)}
                        {(val.isSent === "true" || val.isSent === true) &&
                          (val.body === null ? (
                            <p
                              uk-tooltip={`title: ${formatChatHoverDateTime(
                                val.timestamp
                              )}; pos: ${getToolTipPosition(val.isSent)}`}
                            >
                              {val.body}
                            </p>
                          ) : val.body.startsWith("https:") ? (
                            <pre>
                              {" "}
                              <a href={val.body} target="_blank">
                                {val.body}
                              </a>
                            </pre>
                          ) : (
                            <pre
                              uk-tooltip={`title: ${formatChatHoverDateTime(
                                val.timestamp
                              )}; pos: ${getToolTipPosition(val.isSent)}`}
                              dangerouslySetInnerHTML={sanitizedMessage(
                                formatText(val.body)
                              )}
                            ></pre>
                          ))}
                      </div>
                    )}

                    {/* ***************************************** for send messages ************************************  */}
                    {val?.messageType === "media" &&
                      val?.filePath !== null &&
                      (chatBotDetails.channel === "INSTAGRAM" ||
                        val?.filePath?.includes("svg+xml") ||
                        val?.filePath?.includes("png") ||
                        val?.filePath?.includes("jpeg") ||
                        val?.filePath?.includes("jpg")) && (
                        <div
                          className="txtwrp"
                          uk-tooltip={`title: ${formatChatHoverDateTime(
                            val.timestamp
                          )}; pos: ${getToolTipPosition(val.isSent)}`}
                        >
                          {(val.isSent == "true" || val.isSent == true) &&
                            displayMessageStatusIcon(val.messageStatus)}
                          <div
                            className="customWidth"
                            onClick={() =>
                              window.open(`${val?.filePath}`, "_blank")
                            }
                          >
                            <img
                              className="seen-tick"
                              src={val?.filePath}
                              alt=""
                              width={"200px"}
                              height={"200px"}
                            />
                            <div>{val.caption}</div>
                          </div>
                          {(val.isSent == "false" || val.isSent == false) &&
                            displayMessageStatusIcon(val.messageStatus)}
                        </div>
                      )}
                    {val?.messageType === "media" &&
                      val?.filePath !== null &&
                      val?.filePath?.includes("mp4") && (
                        <div
                          className="txtdiv"
                          uk-tooltip={`title: ${formatChatHoverDateTime(
                            val.timestamp
                          )}; pos: ${getToolTipPosition(val.isSent)}`}
                        >
                          {(val.isSent == "true" || val.isSent == true) &&
                            displayMessageStatusIcon(val.messageStatus)}
                          <div className=" customWidth">
                            <video controls>
                              <source src={val.filePath} type="audio/ogg" />
                              Your browser does not support the audio element.
                            </video>
                            <div>{val.caption}</div>
                          </div>
                          {(val.isSent == "false" || val.isSent == false) &&
                            displayMessageStatusIcon(val.messageStatus)}
                        </div>
                      )}
                    {val?.messageType === "media" &&
                      val?.filePath !== null &&
                      val?.filePath?.includes("pdf") && (
                        <div className="txtwrp">
                          {(val.isSent == "true" || val.isSent == true) &&
                            displayMessageStatusIcon(val.messageStatus)}
                          <p
                            className="document"
                            uk-tooltip={`title: ${formatChatHoverDateTime(
                              val.timestamp
                            )}; pos: ${getToolTipPosition(val.isSent)}`}
                          >
                            <a href={val.filePath} target="_blank">
                              {/* <img src={val.filePath} alt="" /> */}
                              <Document
                                width={150}
                                className={"react-pdf__Page"}
                                file={val.filePath}
                                // file={file_sample_150kB}
                                onLoadSuccess={onDocumentLoadSuccess}
                                externalLinkTarget="_self"
                              >
                                <Page
                                  loading
                                  width={150}
                                  pageNumber={pageNumber}
                                />
                              </Document>
                              Click to download
                            </a>
                            <div>{val.caption}</div>
                          </p>

                          {(val.isSent == "false" || val.isSent == false) &&
                            displayMessageStatusIcon(val.messageStatus)}
                        </div>
                      )}
                    {val?.messageType === "media" &&
                      val?.filePath !== null &&
                      val?.filePath?.includes("wordprocessingml.document") && (
                        <div className="txtwrp">
                          {(val.isSent == "true" || val.isSent == true) &&
                            displayMessageStatusIcon(val.messageStatus)}
                          <p
                            className="document"
                            uk-tooltip={`title: ${formatChatHoverDateTime(
                              val.timestamp
                            )}; pos: ${getToolTipPosition(val.isSent)}`}
                          >
                            <a href={val.filePath} target="_blank">
                              Click to download Word file
                            </a>
                            <div>{val.caption}</div>
                          </p>
                        </div>
                      )}
                    {val?.messageType === "media" &&
                      val?.filePath !== null &&
                      (val?.filePath?.includes("ms-excel") ||
                        val?.filePath?.includes(".csv") ||
                        val?.filePath?.includes("spreadsheetml.sheet")) && (
                        <div className="txtwrp">
                          {(val.isSent == "true" || val.isSent == true) &&
                            displayMessageStatusIcon(val.messageStatus)}
                          <p
                            className="document"
                            uk-tooltip={`title: ${formatChatHoverDateTime(
                              val.timestamp
                            )}; pos: ${getToolTipPosition(val.isSent)}`}
                          >
                            <a href={val.filePath} target="_blank">
                              Click to download Excel file
                            </a>
                            <div>{val.caption}</div>
                          </p>
                        </div>
                      )}

                    {val?.messageType === "media" &&
                      val?.filePath !== null &&
                      val?.filePath?.includes("ogg") && (
                        <div
                          className="txtdiv"
                          uk-tooltip={`title: ${formatChatHoverDateTime(
                            val.timestamp
                          )}; pos: ${getToolTipPosition(val.isSent)}`}
                        >
                          {(val.isSent == "true" || val.isSent == true) &&
                            displayMessageStatusIcon(val.messageStatus)}
                          <audio controls>
                            <source src={val.filePath} type="audio/ogg" />
                            Your browser does not support the audio element.
                          </audio>
                          <div>{val.caption}</div>
                          {(val.isSent == "false" || val.isSent == false) &&
                            displayMessageStatusIcon(val.messageStatus)}
                        </div>
                      )}
                    {val?.messageType === "media" &&
                      val?.filePath !== null &&
                      val?.filePath?.includes("presentation") && (
                        <div className="txtwrp">
                          {(val.isSent == "true" || val.isSent == true) &&
                            displayMessageStatusIcon(val.messageStatus)}
                          <p
                            className="document"
                            uk-tooltip={`title: ${formatChatHoverDateTime(
                              val.timestamp
                            )}; pos: ${getToolTipPosition(val.isSent)}`}
                          >
                            <a
                              href={val.filePath}
                              target="_blank"
                              // onClick={() =>
                              //   window.open(`${val?.filePath}`, "_blank")
                              // }
                            >
                              Click to download PPT
                              {/* PPT  Document  */}
                            </a>
                            <div>{val.caption}</div>
                          </p>
                          {(val.isSent == "false" || val.isSent == false) &&
                            displayMessageStatusIcon(val.messageStatus)}
                        </div>
                      )}

                    {/* ***************************************** for send messages ************************************  */}

                    {val?.messageType === "document" &&
                      val?.mimeType?.includes("presentation") && (
                        <div className="txtwrp">
                          <p
                            className="document"
                            uk-tooltip={`title: ${formatChatHoverDateTime(
                              val.timestamp
                            )}; pos: ${getToolTipPosition(val.isSent)}`}
                            // onClick={() =>
                            //   window.open(`${val?.filePath}`, "_blank")
                            // }
                          >
                            <a
                              href={
                                chatBaseUrl +
                                "/" +
                                val?.filePath.replace("assets", "")
                              }
                              // download={"abc"}
                              target="_blank"
                            >
                              Click to download PPT
                            </a>
                            <div>{val.caption}</div>
                          </p>
                          {/* {displayMessageStatusIcon(val.messageStatus)} */}
                        </div>
                      )}

                    {val?.messageType === "document" &&
                      val?.filePath !== null &&
                      val?.filePath?.includes("wordprocessingml.document") && (
                        <div className="txtwrp">
                          <p
                            className="document"
                            uk-tooltip={`title: ${formatChatHoverDateTime(
                              val.timestamp
                            )}; pos: ${getToolTipPosition(val.isSent)}`}
                          >
                            <a
                              href={
                                chatBaseUrl +
                                "/" +
                                val?.filePath.replace("assets", "")
                              }
                              target="_blank"
                            >
                              Click to download Word file
                            </a>
                            {val.caption}
                          </p>
                        </div>
                      )}
                    {val?.messageType === "document" &&
                      val?.filePath !== null &&
                      val?.filePath?.includes("octet-stream") && (
                        <div className="txtwrp">
                          <p
                            className="document"
                            uk-tooltip={`title: ${formatChatHoverDateTime(
                              val.timestamp
                            )}; pos: ${getToolTipPosition(val.isSent)}`}
                          >
                            <a
                              href={
                                chatBaseUrl +
                                "/" +
                                val?.filePath.replace("assets", "")
                              }
                              target="_blank"
                            >
                              Click to download Excel file
                            </a>
                            {val.caption}
                          </p>
                        </div>
                      )}

                    {/* ***************************************** for received messages ************************************  */}
                    {val?.messageType === "image" &&
                      val?.mimeType?.includes("image") && (
                        <div className="txtdiv">
                          <div
                            className="customWidth"
                            onClick={() =>
                              window.open(
                                `${
                                  chatBaseUrl +
                                  "/" +
                                  val?.filePath.replace("assets/", "")
                                }`,
                                "_blank"
                              )
                            }
                          >
                            {/* <button
                          onClick={() => handleDownload(val.filePath)}
                        >
                          <Download />
                        </button> */}
                            <div
                              uk-tooltip={`title: ${formatChatHoverDateTime(
                                val.timestamp
                              )}; pos: ${getToolTipPosition(val.isSent)}`}
                            >
                              <img
                                className="seen-tick"
                                src={
                                  chatBaseUrl +
                                  "/" +
                                  val?.filePath.replace("assets/", "")
                                }
                                alt=""
                                width={"200px"}
                                height={"200px"}
                              />
                              <div>{val.caption}</div>
                            </div>
                          </div>
                          {/* {displayMessageStatusIcon(val.messageStatus)} */}
                        </div>
                      )}
                    {val?.messageType === "video" &&
                      val?.mimeType?.includes("video") && (
                        <div className="txtdiv ">
                          <div
                            className="customWidth"
                            uk-tooltip={`title: ${formatChatHoverDateTime(
                              val.timestamp
                            )}; pos: ${getToolTipPosition(val.isSent)}`}
                          >
                            <video controls>
                              <source
                                src={
                                  chatBaseUrl +
                                  "/" +
                                  val?.filePath.replace("assets/", "")
                                }
                                type="audio/ogg"
                              />
                              Your browser does not support the audio element.
                            </video>
                            <div>{val.caption}</div>
                          </div>
                          {/* {displayMessageStatusIcon(val.messageStatus)} */}
                        </div>
                      )}
                    {val?.messageType === "audio" &&
                      val?.mimeType?.includes("audio") && (
                        <div
                          className="txtdiv"
                          uk-tooltip={`title: ${formatChatHoverDateTime(
                            val.timestamp
                          )}; pos: ${getToolTipPosition(val.isSent)}`}
                        >
                          <audio controls>
                            <source
                              src={
                                chatBaseUrl +
                                "/" +
                                val?.filePath.replace("assets/", "")
                              }
                              type="audio/ogg"
                            />
                            Your browser does not support the audio element.
                          </audio>
                          <div>{val.caption}</div>
                          {/* {displayMessageStatusIcon(val.messageStatus)} */}
                        </div>
                      )}

                    {val?.messageType === "location" && (
                      <div
                        className="txtdiv"
                        uk-tooltip={`title: ${formatChatHoverDateTime(
                          val.timestamp
                        )}; pos: ${getToolTipPosition(val.isSent)}`}
                      >
                        {/* <pre>
                        <Location />
                        <Link
                          className="location"
                          onClick={() =>
                            handleOpenLocationModal(
                              val.latitude,
                              val.longitude
                            )
                          }
                        >
                          Location
                        </Link>
                      </pre> */}
                        <div>
                          <iframe
                            width="100%"
                            height="300"
                            src={`https://www.google.com/maps?q=${val.latitude},${val.longitude}&output=embed`}
                          ></iframe>
                        </div>
                        {/* {displayMessageStatusIcon(val.messageStatus)} */}
                      </div>
                    )}

                    {val?.messageType === "document" &&
                      val?.mimeType?.includes("pdf") && (
                        <div className="txtwrp">
                          <p
                            className="document"
                            uk-tooltip={`title: ${formatChatHoverDateTime(
                              val.timestamp
                            )}; pos: ${getToolTipPosition(val.isSent)}`}
                            // onClick={() =>
                            //   window.open(`${val?.filePath}`, "_blank")
                            // }
                          >
                            <a
                              href={
                                chatBaseUrl +
                                "/" +
                                val?.filePath.replace("assets", "")
                              }
                              // download={"abc"}
                              target="_blank"
                            >
                              <Document
                                width={150}
                                className={"react-pdf__Page"}
                                file={val.filePath}
                                // file={file_sample_150kB}
                                onLoadSuccess={onDocumentLoadSuccess}
                                externalLinkTarget="_self"
                              >
                                <Page
                                  loading
                                  width={150}
                                  pageNumber={pageNumber}
                                />
                              </Document>
                              Click to download
                            </a>
                            <div>{val.caption}</div>
                          </p>
                          {/* {displayMessageStatusIcon(val.messageStatus)} */}
                        </div>
                      )}

                    {val?.messageType === "contacts" && (
                      <div
                        className="txtdiv"
                        uk-tooltip={`title: ${formatChatHoverDateTime(
                          val.timestamp
                        )}; pos: ${getToolTipPosition(val.isSent)}`}
                      >
                        <div className="contactCardMain">
                          {val?.contacts?.map((item, ind) => {
                            return (
                              <div key={ind} className="contactCard">
                                <div>{item.name.formatted_name}</div>
                                {item?.phones?.map((phone, num) => {
                                  return <div key={num}>{phone.phone}</div>;
                                })}
                              </div>
                            );
                          })}
                        </div>
                        {/* {displayMessageStatusIcon(val.messageStatus)} */}
                      </div>
                    )}
                    {val?.messageType === "list" && (
                      <div
                        className="txtdiv"
                        uk-tooltip={`title: ${formatChatHoverDateTime(
                          val.timestamp
                        )}; pos: ${getToolTipPosition(val.isSent)}`}
                      >
                        {displayMessageStatusIcon(val.messageStatus)}
                        <div className="contactCardMain">
                          {val.headerValue !== null && (
                            <div>{val.headerValue}</div>
                          )}
                          <br />

                          {val.list.length > 0 &&
                            val.list.map((item, ind) => {
                              return (
                                <div key={ind}>
                                  <div>{item.title}</div>
                                  <div>
                                    {item.rows.map((newItem, indee) => {
                                      return (
                                        <div key={indee}>
                                          {newItem.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <br />
                                </div>
                              );
                            })}
                          {val.footerValue !== null && (
                            <div>{val.footerValue}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {val?.messageType === "buttons" && (
                      <div
                        className="txtdiv"
                        uk-tooltip={`title: ${formatChatHoverDateTime(
                          val.timestamp
                        )}; pos: ${getToolTipPosition(val.isSent)}`}
                      >
                        {displayMessageStatusIcon(val.messageStatus)}
                        <div className="contactCardMain">
                          {val.headerValue !== null && (
                            <div>{val.headerValue}</div>
                          )}

                          {val.buttons.length > 0 &&
                            val.buttons.map((item, ind) => {
                              return (
                                <buttons className="chatbtn" key={ind}>
                                  {item.title}
                                </buttons>
                              );
                            })}
                          {val.footerValue !== null && (
                            <div>{val.footerValue}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {val?.messageType === "button" && (
                      <div
                        className="txtdiv"
                        uk-tooltip={`title: ${formatChatHoverDateTime(
                          val.timestamp
                        )}; pos: ${getToolTipPosition(val.isSent)}`}
                      >
                        {(val.isSent == "false" || val.isSent == false) &&
                          (val.body === null ? (
                            <pre>{val.body} </pre>
                          ) : (
                            <pre
                              dangerouslySetInnerHTML={sanitizedMessage(
                                formatText(val.body)
                              )}
                            ></pre>
                          ))}
                        {/* {displayMessageStatusIcon(val.messageStatus)} */}
                        {(val.isSent == "true" || val.isSent == true) &&
                          (val.body === null ? (
                            <p>{val.body} </p>
                          ) : (
                            <p
                              dangerouslySetInnerHTML={sanitizedMessage(
                                formatText(val.body)
                              )}
                            ></p>
                          ))}
                      </div>
                    )}

                    {val?.messageType === "interactive" && (
                      <div
                        className="txtdiv"
                        uk-tooltip={`title: ${formatChatHoverDateTime(
                          val.timestamp
                        )}; pos: ${getToolTipPosition(val.isSent)}`}
                      >
                        {val.body === null ? (
                          " "
                        ) : (
                          <div
                            className={
                              val.filePath === null ? "" : "txtAndImgDiv"
                            }
                          >
                            {val.filePath !== null && (
                              <img src={val.filePath} alt="" />
                            )}
                            <pre>
                              {(() => {
                                let message = "";

                                try {
                                  // Parse the JSON body
                                  const parsedBody = JSON.parse(val.body);

                                  // Check if 'flow_token' exists
                                  if (parsedBody.hasOwnProperty("flow_token")) {
                                    message = "Form submitted successfully";
                                  } else {
                                    message = JSON.stringify(parsedBody);
                                  }
                                } catch (error) {
                                  // If parsing fails, show the body as it is
                                  message = val.body;
                                }

                                return formatText(message);
                              })()}
                            </pre>
                          </div>
                        )}
                      </div>
                    )}
                    {/* ***************************************** for received messages ************************************  */}
                    <div ref={bottomRef}></div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>

      {openLocationModal && (
        <LocationModal
          closeModal={handleCloseLocationModal}
          locationDetails={locationDetails}
        />
      )}
    </div>
  );
};

export default ChatBotMessages;
