import React from "react";
import { Edit, TrashCan, Checkmark } from "@carbon/icons-react";
import { useState, useRef } from "react";
import { useContextChatBotWeb } from "../ContextChatBot";
const Dialogs = () => {
  const {
    selectSectionFunc,
    sections,
    duplicateSection,
    deleteSection,
    handleEditClick,
    handleSectionNameChange,
    disableLive,
  } = useContextChatBotWeb();
  const inputRef = useRef(null);
  const [isInputFocused, setInputFocused] = useState(false);

  return (
    <div>
      {sections?.map((section, index) => (
        <div key={section.dialogueId}>
          <div className="addTrigger uk-flex uk-flex-middle uk-flex-between">
            <div
              className={`txtwrp transBorder ${
                isInputFocused ? "focused" : ""
              }`}
              onClick={() => {
                setInputFocused(section.dialogueId);
                selectSectionFunc(section?.dialogueTitle);
              }}
            >
              <input
                type="text"
                placeholder="Enter dialog name"
                value={section?.dialogueTitle}
                onChange={(e) => {
                  handleSectionNameChange(section.dialogueId, e.target.value);
                }}
                ref={inputRef}
              />
            </div>
            <div className="btnwrp">
              <button
                type="button"
                className={`btn-1 InvisibleCheck${
                  isInputFocused === section.dialogueId ? " visibleCheck" : ""
                }`}
                onClick={() => {
                  setInputFocused(null);
                  selectSectionFunc(section?.dialogueTitle);
                }}
              >
                <Checkmark />
              </button>
              <button type="button" className="btn-1" onClick={handleEditClick}>
                <Edit />
              </button>
              <button
                type="button"
                onClick={() => deleteSection(section.dialogueId)}
              >
                <TrashCan />
              </button>
            </div>
          </div>
        </div>
      ))}

      <div onClick={duplicateSection} className={`dialogBtn `}>
        <button type="button">Create new dialog</button>
      </div>
    </div>
  );
};

export default Dialogs;
