import React from "react";

const SelectDialogModal = ({ closeModal }) => {
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
      style={{zIndex: '10000'}}
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical selectDialogModal">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>Select a dialog</span>
          </div>
          <div className="modalForm pauseModalContent">
            <div className="selectModalBtn">
              <button type="button" onClick={closeModal}>
                <span></span>Starting step
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectDialogModal;
