import React from "react";
import {
  Calendar,
  ChevronDown,
  ChatLaunch,
  OverflowMenuVertical,
  Download,
} from "@carbon/icons-react";
import { useNavigate, useOutletContext } from "react-router-dom";
// import whatsappLogo from "../../assets/images/whatsapp-icon.png";
import { apiGet } from "../../Utils/apiServices";
import { useState } from "react";
import { useEffect } from "react";
import { convertTimestamp } from "../../Utils/DateFunctions";
import { useAuth } from "../../Context/AuthProvider";
import {
  decryptedValue,
  encryptedValue,
} from "../../Utils/RouteHashConversion";
import ReactPaginate from "react-paginate";
import axios from "axios";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";
import { channelImages } from "../../Utils/ChannelImages";
import { hasPermissions } from "../../Utils/RoutePermissions";
const baseUrl = process.env.REACT_APP_BASEURL;

const Maupage = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const CurrentCompanyName = CompanyName(CompanyHtml);
  const navigate = useNavigate();
  const [size, setSize] = useState(10);
  const { auth } = useAuth();
  const [packages, setPackages] = useState(null);
  const [filterDates, setFilterDates] = useState();
  const [contactProfile, setContactProfile] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [details, setDetails] = useState({
    number: null,
    channelId: null,
    agentName: null,
    agentId: null,
    publicId: null,
  });
  const pageTabs = [
    {
      route: "/analytics",
      name: "Overview",
      permissions: ["FINANCE-DATA-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/live-chat-analytics",
      name: "Live Chat",
      permissions: ["ANALYTICS-MANAGER", "LIVE-CHAT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/chatbot-analytics",
      name: "Chatbot",
      permissions: ["CHATBOT-SUPERVISOR", "CHAT-BOT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/statistics",
      name: "Statistics",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/package-mau",
      name: "Package",
      permissions: ["ANALYTICS-MANAGER"],
      active: true,
      allPermissionsMandatory: true,
    },
    {
      route: "/orders",
      name: "Orders",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/transfer-logs",
      name: "Transfer Logs",
      permissions: [],
      active: false,
      allPermissionsMandatory: true,
    },
  ];

  // const lastItem = filterDates && filterDates.length > 0 ? filterDates[filterDates.length - 1] : null;

  const currentDate = new Date();
  const monthNames = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  const formattedDate = `${
    monthNames[currentDate.getMonth()]
  } ${currentDate.getFullYear()}`;

  const [selectedValue, setSelectedValue] = useState(formattedDate);

  const getpackages = (page) => {
    apiGet(
      `/stats/package-consumption/${currentDate.getFullYear()}/${
        currentDate.getMonth() + 1
      }?page=${page}&size=${size}`,
      onSuccesspackages,
      onFailurepackages
    );
  };
  const onSuccesspackages = (response) => {
    setPackages(response?.data);
    setPageCount(response?.data?.contactList?.totalPages);
  };
  const onFailurepackages = (error) => {
    console.log(error);
  };
  useEffect(() => {
    getpackages(0);
  }, []);
  useEffect(() => {
    getpackages(0);
  }, [size]);

  const handlePageChange = (data) => {
    getpackages(data.selected);
    setCurrentPage(data.selected);
  };

  const getFilterDates = () => {
    apiGet(`/stats/get-filter-date`, onSuccessDates, onFailureDates);
  };

  const onSuccessDates = (response) => {
    setFilterDates(response?.data);
  };

  const onFailureDates = (error) => {
    console.log(error);
  };

  const handleClick = (val) => {
    const [month, year] = val.split(" ");
    const monthString = month.toUpperCase(); // Convert the month to uppercase
    const monthMap = {
      JANUARY: "1",
      FEBRUARY: "2",
      MARCH: "3",
      APRIL: "4",
      MAY: "5",
      JUNE: "6",
      JULY: "7",
      AUGUST: "8",
      SEPTEMBER: "9",
      OCTOBER: "10",
      NOVEMBER: "11",
      DECEMBER: "12",
    };
    const monthNumberStr = monthMap[monthString];
    const monthNumber = new Date(Date.parse(`${month} 1`)).getMonth() + 1;

    if (monthNumberStr == monthNumber) {
      apiGet(
        `/stats/package-consumption/${year}/${monthNumberStr}`,
        onSuccesspackages,
        onFailurepackages
      );
    }

    setSelectedValue(val);
  };
  const getMonthYear = (value) => {
    const [month] = value.split(" ");
    const monthString = month.toUpperCase(); // Convert the month to uppercase
    const monthMap = {
      JANUARY: "1",
      FEBRUARY: "2",
      MARCH: "3",
      APRIL: "4",
      MAY: "5",
      JUNE: "6",
      JULY: "7",
      AUGUST: "8",
      SEPTEMBER: "9",
      OCTOBER: "10",
      NOVEMBER: "11",
      DECEMBER: "12",
    };
    const monthNumberStr = monthMap[monthString];
    return monthNumberStr;
  };

  const exportBySummary = async () => {
    const [month, year] = selectedValue.split(" ");

    try {
      const response = await axios.get(
        baseUrl +
          `/stats/export/summary-package-consumption/${year}/${getMonthYear(
            selectedValue
          )}`,
        {
          responseType: "arraybuffer", // Ensure the response is treated as binary data
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Mau_summary.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };
  const exportByDetail = async () => {
    const [month, year] = selectedValue.split(" ");

    try {
      const response = await axios.get(
        baseUrl +
          `/stats/export/detail-package-consumption/${year}/${getMonthYear(
            selectedValue
          )}`,
        {
          responseType: "arraybuffer", // Ensure the response is treated as binary data
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Mau_detail.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  useEffect(() => {
    getFilterDates();
  }, []);
  const getContactProfile = (number) => {
    apiGet(
      `/chat/details/${number}/${decryptedValue(
        auth.companyId
      )}/${decryptedValue(auth.idNumber)}`,
      onSuccessContactProfile,
      onFailureContactProfile
    );
  };

  const onSuccessContactProfile = (response) => {
    setContactProfile(response?.data);
  };
  const onFailureContactProfile = (error) => {
    console.log(error);
  };
  useEffect(() => {
    if (contactProfile !== undefined) {
      setDetails({
        number: contactProfile?.conversation?.number,
        channelId: contactProfile?.contact?.channelId,
        agentName:
          contactProfile?.conversation?.agentName == ""
            ? "NA"
            : contactProfile?.conversation?.agentName,
        agentId:
          contactProfile?.conversation?.agentId == ""
            ? "NA"
            : contactProfile?.conversation?.agentId,
        publicId: contactProfile?.conversation?.publicId,
      });
    }
  }, [contactProfile]);

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="boradcastWrp">
        <div className="boradcastTabbing">
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #newAnalyticsTab"
          >
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                <li
                  onClick={() => {
                    navigate(val.route, { replace: true });
                  }}
                  className={val.active === true ? "uk-active" : ""}
                  key={index}
                >
                  <a>{val.name}</a>
                </li>
              ) : null;
            })}
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="newAnalyticsTab">
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                val.name === "Package" ? (
                  <li key={index}>
                    <div className="analyticsContainer">
                      <div className="uk-container">
                        <h2 className="heading uk-margin-medium-top">
                          Package consumption{" "}
                          <CurrentCompanyName companyDetails={companyDetails} />
                        </h2>
                        <p>
                          Here, you can easily track and manage your package
                          usage.
                        </p>
                        <div
                          className="uk-grid analyticsTab uk-flex-middle"
                          uk-grid=""
                        >
                          <div className="uk-width-1-2">
                            <ul
                              className="uk-subnav uk-subnav-pill"
                              uk-switcher="connect: #analyticsTabs"
                            >
                              <li
                                onClick={() => {
                                  navigate("/package-mau", { replace: true });
                                }}
                              >
                                <a>MAU</a>
                              </li>
                              <li
                                onClick={() => {
                                  navigate("/package-conversation", {
                                    replace: true,
                                  });
                                }}
                              >
                                <a>Conversations</a>
                              </li>
                            </ul>
                          </div>
                          <div className="uk-width-1-2 btnSection">
                            <button
                              className="exportBtn"
                              onClick={exportBySummary}
                            >
                              <Download /> Export by summary
                            </button>
                            <button
                              className="exportBtn"
                              onClick={exportByDetail}
                            >
                              <Download /> Export by detail
                            </button>

                            <div className="uk-inline">
                              <button className="calenderBtn">
                                <Calendar />
                                {selectedValue}
                                <span className="uk-inline">
                                  <ChevronDown />
                                </span>
                              </button>
                              <div
                                uk-dropdown="mode: click"
                                className="calendarDropdown"
                              >
                                <div className="dropDownHeading">
                                  <span>Select a month</span>
                                </div>
                                <ul>
                                  {filterDates?.map((val, index) => {
                                    return (
                                      <li className="active" key={index}>
                                        <button
                                          type="button"
                                          value={val}
                                          onClick={() => handleClick(val)}
                                        >
                                          <span></span> {val}
                                        </button>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tabContent">
                          <ul
                            className="uk-switcher uk-margin"
                            id="analyticsTabs"
                          >
                            <li>
                              <div className="analyticsContent">
                                <div className="mainBox">
                                  <div className="boxHeading">
                                    <span>Highlights</span>
                                  </div>
                                  <div className="boxContent">
                                    <div className="uk-grid" uk-grid="">
                                      <div className="uk-width-1-3">
                                        <div className="txtwrp">
                                          <h2>
                                            {packages === null ? (
                                              <div uk-spinner=""></div>
                                            ) : (
                                              packages?.totalMau
                                            )}
                                          </h2>
                                          <span>Total MAUs</span>
                                        </div>
                                      </div>
                                      <div className="uk-width-1-3">
                                        <div className="txtwrp">
                                          <h2>
                                            {packages === null ? (
                                              <div uk-spinner=""></div>
                                            ) : (
                                              packages?.newManu
                                            )}
                                          </h2>
                                          <span>New</span>
                                        </div>
                                      </div>
                                      <div className="uk-width-1-3">
                                        <div className="txtwrp">
                                          <h2>
                                            {packages === null ? (
                                              <div uk-spinner=""></div>
                                            ) : (
                                              packages?.returningMau
                                            )}
                                          </h2>
                                          <span>Returning</span>
                                        </div>
                                      </div>
                                      {/* <div className="uk-width-1-4">
                                    <div className="txtwrp">
                                      <h2>{packages?.growthRate}%</h2>
                                      <span>Growth rate</span>
                                    </div>
                                  </div> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="analyticsTableWrp tableCenter">
                                  <div className="uk-grid" uk-grid="">
                                    <div className="uk-width-1-1">
                                      <div className="searchForm">
                                        <form
                                          action=""
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          <input
                                            type="text"
                                            placeholder="Search by name, number"
                                          />
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                  <table className="uk-table">
                                    <thead>
                                      <tr>
                                        <th>SERIES</th>
                                        <th>
                                          <div className="uk-inline tableDropDown">
                                            <button type="button">
                                              ALL CHANNELS
                                              {/* <ChevronDown /> */}
                                            </button>
                                            {/* <div
                                        uk-dropdown="mode: click"
                                        className="dropdownContent"
                                      >
                                        <div className="dropdownHeading">
                                          <span>Channels</span>
                                        </div>
                                        <ul>
                                          <li className="active">
                                            <button type="button">
                                              All channels
                                            </button>
                                          </li>
                                          <li>
                                            <button type="button">
                                              <img src={whatsappLogo} alt="" />{" "}
                                              WhatsApp
                                            </button>
                                          </li>
                                          <li>
                                            <button type="button">
                                              <img src={whatsappLogo} alt="" />{" "}
                                              Facebook
                                            </button>
                                          </li>
                                          <li>
                                            <button type="button">
                                              <img src={whatsappLogo} alt="" />{" "}
                                              Instagram
                                            </button>
                                          </li>
                                        </ul>
                                      </div> */}
                                          </div>
                                        </th>
                                        <th>NUMBER</th>
                                        <th>NAME</th>
                                        {/* <th>CONVERSATIONS</th> */}
                                        <th>
                                          <div className="uk-inline tableDropDown">
                                            <button type="button">
                                              CUSTOMER TYPE
                                              {/* <ChevronDown /> */}
                                            </button>
                                            {/* <div
                                        uk-dropdown="mode: click"
                                        className="dropdownContent"
                                      >
                                        <div className="dropdownHeading">
                                          <span>Customer Type</span>
                                        </div>
                                        <ul>
                                          <li
                                            className="active"
                                            style={{ paddingLeft: "25px" }}
                                          >
                                            <button type="button">
                                              Returning
                                            </button>
                                          </li>
                                          <li style={{ paddingLeft: "25px" }}>
                                            <button type="button">New</button>
                                          </li>
                                        </ul>
                                      </div> */}
                                          </div>
                                        </th>
                                        <th>CREATED AT</th>
                                        <th>LAST CONTACT</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {packages?.contactList?.data.length >
                                      0 ? (
                                        packages?.contactList?.data.map(
                                          (val, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                  {val?.chanelId === null ? (
                                                    "-"
                                                  ) : (
                                                    <img
                                                      src={channelImages(
                                                        val?.chanelId
                                                      )}
                                                      alt=""
                                                    />
                                                  )}
                                                </td>
                                                <td>{val.phoneNumber}</td>
                                                <td>{val.name}</td>
                                                {/* <td>{val.totalConversation}</td> */}
                                                <td>{val.customerType}</td>
                                                <td>
                                                  {convertTimestamp(
                                                    val.createdDate
                                                  )}
                                                </td>
                                                <td>
                                                  {convertTimestamp(
                                                    val.lastSeen
                                                  )}
                                                </td>
                                                <td>
                                                  <div className="uk-inline">
                                                    <button
                                                      type="button"
                                                      onClick={() =>
                                                        getContactProfile(
                                                          val.phoneNumber
                                                        )
                                                      }
                                                    >
                                                      <OverflowMenuVertical />
                                                    </button>
                                                    <div
                                                      uk-dropdown="mode: click"
                                                      className=""
                                                    >
                                                      <div className="tableDropDown">
                                                        <ul>
                                                          {/* <li>
                                                  <button type="button">
                                                    <Edit /> Edit
                                                  </button>
                                                </li> */}
                                                          <li>
                                                            <button
                                                              type="button"
                                                              onClick={() => {
                                                                const dataToStore =
                                                                  {
                                                                    mobile:
                                                                      val?.phoneNumber,
                                                                  };

                                                                const dataString =
                                                                  JSON.stringify(
                                                                    dataToStore
                                                                  );

                                                                sessionStorage.setItem(
                                                                  "myData",
                                                                  dataString
                                                                );
                                                                const storedDataString =
                                                                  sessionStorage.getItem(
                                                                    "myData"
                                                                  );

                                                                if (
                                                                  storedDataString
                                                                ) {
                                                                  const storedData =
                                                                    JSON.parse(
                                                                      storedDataString
                                                                    );

                                                                  const mobile =
                                                                    storedData.mobile;

                                                                  navigate(
                                                                    `/chat/${encryptedValue(
                                                                      mobile
                                                                    )}`,
                                                                    {
                                                                      replace: false,
                                                                    }
                                                                  );
                                                                }
                                                              }}
                                                              // onClick={() =>
                                                              //   navigate(
                                                              //     `chat/${number}`,
                                                              //     { replace: true }
                                                              //   )
                                                              // }
                                                            >
                                                              <ChatLaunch />{" "}
                                                              View chat
                                                            </button>
                                                          </li>
                                                          {/* <li>
                                                  <button type="button">
                                                    <Error /> Block contact
                                                  </button>
                                                </li> */}
                                                          {/* <li>
                                                  <button type="button">
                                                    <Download /> Download data
                                                  </button>
                                                </li> */}
                                                          {/* <hr /> */}
                                                          {/* <li>
                                                  <button
                                                    className="trashBtn"
                                                    type="button"
                                                  >
                                                    <TrashCan /> Delete
                                                  </button>
                                                </li> */}
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ) : (
                                        <tr>
                                          <td
                                            colSpan={11}
                                            className="dataNotFound"
                                          >
                                            {packages === null ? (
                                              <div uk-spinner=""></div>
                                            ) : (
                                              "Data Not Found"
                                            )}
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                  <div className="tablePaginationBox">
                                    <div className="uk-margin-left">
                                      Show{" "}
                                      <select
                                        onChange={(e) =>
                                          setSize(e.target.value)
                                        }
                                      >
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                      </select>{" "}
                                      entries
                                    </div>
                                    <div>
                                      {pageCount > 1 && (
                                        <ReactPaginate
                                          previousLabel={"<"}
                                          nextLabel={">"}
                                          breakLabel={"...."}
                                          pageCount={pageCount}
                                          onPageChange={handlePageChange}
                                          containerClassName={
                                            "uk-pagination uk-float-right contatPagination"
                                          }
                                          activeClassName={"uk-active"}
                                          marginPagesDisplayed={3}
                                          pageRangeDisplayed={6}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li key={index}></li>
                )
              ) : null;
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Maupage;
