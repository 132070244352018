import React, { useEffect, useState } from "react";
import loaderIcon from "../../../assets/images/loader-icon.svg";
import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import { useNavigate } from "react-router-dom";
import { apiGet } from "../../../Utils/apiServices";
import { useWebSocket } from "../../../socket/WebSocketManager";
import {
  decryptedValue,
  encryptedValue,
} from "../../../Utils/RouteHashConversion";

const SplashScreen = () => {
  const { socket } = useWebSocket();
  // const [permissionStored, setPermissionStored] = useState([]);
  const navigate = useNavigate();
  const [result, setResult] = useState(null);
  const pageWiseRouting = [
    {
      route: "/dashboard",
      permission: ["FINANCE-DATA-MANAGER", "ANALYTICS-MANAGER"],
      allPermissionsMandatory: false,
    },
    {
      route: "/chat",
      permission: ["LIVE-CHAT-MANAGER", "LIVE-CHAT-AGENT"],
      allPermissionsMandatory: false,
    },
    {
      route: "/chat-bot",
      permission: [
        "CHATBOT-CONVERSATION",
        "CHAT-BOT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      allPermissionsMandatory: false,
    },
    {
      route: "/broadcast",
      permission: ["COMMUNICATION-MANAGER"],
      allPermissionsMandatory: false,
    },
    {
      route: "/contacts",
      permission: ["PEOPLE-MANAGER"],
      allPermissionsMandatory: false,
    },
    {
      route: "/users",
      permission: ["USER-TEAMS"],
      allPermissionsMandatory: false,
    },
  ];

  function hasPermissions(
    userPermissions,
    routePermissions,
    allPermissionsMandatory
  ) {
    if (allPermissionsMandatory) {
      // Check if all required permissions are present in the user's permissions
      return routePermissions.every((permission) =>
        userPermissions.includes(permission)
      );
    } else {
      // Check if at least one required permission is present in the user's permissions
      return routePermissions.some((permission) =>
        userPermissions.includes(permission)
      );
    }
  }

  // Function to find the route that the user has permission to access
  function findAccessibleRoute(userPermissions) {
    for (const routeConfig of pageWiseRouting) {
      if (
        hasPermissions(
          userPermissions,
          routeConfig.permission,
          routeConfig.allPermissionsMandatory
        )
      ) {
        return routeConfig.route;
      }
    }
    // Return a default route or handle the case where no route is accessible
    return "/default";
  }

  const getPermissions = () => {
    apiGet(`/auth/user-permission`, onSuccessPermissions, onFailurePermissions);
  };
  const onSuccessPermissions = (response) => {
    let permissions = [];
    response?.data.map((val) => {
      permissions?.push(val?.permission?.name);
    });
    if (response.data.length === 0) {
      if (localStorage.getItem("_role") === "ROLE_SUPER_ADMIN") {
        setTimeout(() => {
          setResult(true);
        }, 4200);
      }
    } else if (permissions.length > 0) {
      // setPermissionStored(permissions);
      setTimeout(() => {
        const accessibleRoute = findAccessibleRoute(permissions);
        setResult(accessibleRoute);
      }, 4200);
    }
  };
  const onFailurePermissions = (error) => {
    navigate(`/`, {
      replace: true,
    });
    console.log(error);
  };

  // const handleNavigationSAdmin = (destination) => {
  //   const userRole = localStorage.getItem("_role");

  //   if (userRole !== "ROLE_SUPER_ADMIN" ) {
  //     navigate("/default");
  //   } else {
  //     navigate(destination);
  //   }
  // };

  // // Example usage
  // handleNavigationSAdmin("/");

  // useEffect(() => {
  //   if (localStorage.getItem("_role") === "ROLE_SUPER_ADMIN") {
  //     localStorage.setItem(
  //       "_email",
  //       encryptedValue(localStorage.getItem("_email"))
  //     );
  //     localStorage.setItem(
  //       "_userName",
  //       encryptedValue(localStorage.getItem("_userName"))
  //     );
  //     localStorage.setItem(
  //       "_publicId",
  //       encryptedValue(localStorage.getItem("_publicId"))
  //     );
  //     localStorage.setItem(
  //       "_role",
  //       encryptedValue(localStorage.getItem("_role"))
  //     );
  //     localStorage.setItem(
  //       "_companyId",
  //       encryptedValue(localStorage.getItem("_companyId"))
  //     );
  //     localStorage.setItem(
  //       "_idNumber",
  //       encryptedValue(localStorage.getItem("_idNumber"))
  //     );
  //     localStorage.setItem(
  //       "_agentId",
  //       encryptedValue(localStorage.getItem("_agentId"))
  //     );
  //     setTimeout(() => {
  //       navigate("/super-admin-home");
  //     }, 7000);
  //   } else {
  //     getPermissions();
  //   }
  // }, []);

  useEffect(() => {
    getPermissions();
  }, []);

  useEffect(() => {
 
    if (result !== null && socket !== null) {


      if (socket.readyState === 1) {

        localStorage.setItem(
          "_email",
          encryptedValue(localStorage.getItem("_email"))
        );
        localStorage.setItem(
          "_userName",
          encryptedValue(localStorage.getItem("_userName"))
        );
        localStorage.setItem(
          "_publicId",
          encryptedValue(localStorage.getItem("_publicId"))
        );
        localStorage.setItem(
          "_role",
          encryptedValue(localStorage.getItem("_role"))
        );
        localStorage.setItem(
          "_companyId",
          encryptedValue(localStorage.getItem("_companyId"))
        );
        localStorage.setItem(
          "_idNumber",
          encryptedValue(localStorage.getItem("_idNumber"))
        );
        localStorage.setItem(
          "_agentId",
          encryptedValue(localStorage.getItem("_agentId"))
        );
        setTimeout(() => {
          // if (result === false) {
          //   let data = checkPermissionExistForDashboard(permissionStored, [
          //     "CHATBOT-CONVERSATION",
          //     "CHAT-BOT-MANAGER",
          //     "CHATBOT-SUPERVISOR",
          //   ]);
          //   if (data) {
          //     window.location.href = "/chat-bot";
          //   } else {
          //     window.location.href = "/chat";
          //   }
          // } else {
          //   window.location.href = "/dashboard";
          // }
          localStorage.setItem("_displayed", true);
          if (result !== null) {
      
            if (
              // decryptedValue(
              //   localStorage.getItem("_role") === "ROLE_SUPER_ADMIN"
              // )
              result === true
            ) {
              window.location.href = "/super-admin-home";
            } else {
              window.location.href = result;
            }
          }
        }, 1000);
      }
    }
  }, [result, socket]);

  useEffect(() => {
    if (localStorage.getItem("_displayed") === 'true') {
      navigate(-1);
    }
  }, []);

  return (
    <>
      <div className="splashBody">
        <div className="splashScreen">
          <div className="loadingContainer">
            <div className="loadingBox">
              <img src={loaderIcon} className="splashLogo" alt="" />
              <div className="loadingBarContainer">
                <div className="loadingbar"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="splashBehind">
          <Header />
          <div className="splashBehindMain">
            <h1 className="fade-in-text">WELCOME</h1>
            <h1 className="fade-in-text1 upperCase">
              {localStorage.getItem("_userName")}
            </h1>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default SplashScreen;
