import React, { useState, useEffect } from "react";
import {
  Download,
  Edit,
  Filter,
  OverflowMenuVertical,
  TrashCan,
} from "@carbon/icons-react";
import FilterModal from "./Modals/FilterModal";
import AddContact from "./Modals/AddContact";
import { apiGet, apiPost } from "../../Utils/apiServices";
import { useAuth } from "../../Context/AuthProvider";
import DeleteModal from "./Modals/DeleteModal";
import debounce from "lodash.debounce";
import {
  Link,
  useNavigate,
  useParams,
  useOutletContext,
} from "react-router-dom";
import AgentDetailModal from "./Modals/AgentDetailModal";
import whatsappImg from "../../assets/images/whatsapp-icon-new.jpg";
import messengerIcon from "../../assets/images/messenger-icon-new.jpg";
import instaIcon from "../../assets/images/instagram-icon-new.jpg";
import ReactPaginate from "react-paginate";
// import { ChevronDown } from "@carbon/icons-react";
import {
  convertTimeWithDateIntoMinHourDate,
  convertTimestamp,
} from "../../Utils/DateFunctions";
import { toast } from "react-toastify";
import EditContact from "./Modals/EditContact";
import { decryptedValue } from "../../Utils/RouteHashConversion";
import axios from "axios";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";

const baseUrl = process.env.REACT_APP_BASEURL;

const Contacts = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const params = useParams();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [openEditContactModal, setOpenEditContactModal] = useState(false);
  const [openDeleteContactModal, setOpenDeleteContactModal] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [contacts, setContacts] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [editContactData, setEditContactData] = useState();
  const [deleteContactData, setDeleteContactData] = useState();
  const [tags, setTags] = useState([]);
  const [multiSelectTags, setMultiSelectTags] = useState([]);
  const [contactProfile, setContactProfile] = useState();
  const [size, setSize] = useState(10);

  // for search filter
  const [filterData, setFilterData] = useState({
    fromDate: null,
    toDate: null,
    country: "",
    city: "",
    gender: "",
    Status: "",
    countryList: "",
    tags: [],
    searchQuery: "",
  });

  const getFilterCount = () => {
    const {
      fromDate,
      toDate,
      country,
      city,
      gender,
      status,
      countryList,
      tags,
      searchQuery,
    } = filterData;
    let count = 0;

    if (fromDate && toDate) count++;
    if (country) count++;
    if (city) count++;
    if (gender) count++;
    if (status) count++;
    if (countryList) count++;
    if (tags.length > 0) count++;
    // if (searchQuery) count++;

    return count;
  };
  const noOfFilters = getFilterCount();
  
  const handleOpenFilterModal = () => {
    setOpenFilterModal(true);
  };

  const handleCLoseFilterModal = () => {
    setOpenFilterModal(false);
  };

  const handleOpenContactModal = () => {
    setOpenContactModal(true);
  };

  const handleCloseContactModal = () => {
    setOpenContactModal(false);
  };

  const handleOpenContactEditModal = (data) => {
    setEditContactData(data);
    setOpenEditContactModal(true);
  };

  const handleCloseContactEditModal = () => {
    setOpenEditContactModal(false);
  };
  const handleOpenDeleteModal = (data) => {
    setDeleteContactData(data);
    setOpenDeleteContactModal(true);
  };

  const handleCoseDeleteModal = () => {
    setOpenDeleteContactModal(false);
  };

  const handleOpenDetailModal = (number) => {
    getContactProfile(number);
    setOpenDetailModal(true);
  };

  const handleCloseDetailModal = () => {
    setOpenDetailModal(false);
  };

  const getSingleContact = () => {
    apiGet(
      `/contact/get/${params?.publicId}`,
      onSuccessSingleContact,
      onFailureSingleContact
    );
  };
  const onSuccessSingleContact = (response) => {
    setEditContactData(response?.data);
  };
  const onFailureSingleContact = (error) => {
    console.log(error);
  };
  const blockContact = (publicId) => {
    apiGet(
      `/contact/block/${publicId}`,
      onSuccessBlockContact,
      onFailureBlockContact
    );
  };
  const onSuccessBlockContact = (response) => {
    if (response.data === true) {
      toast.success("Contact blocked successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      getContacts(true, currentPage);
    }
  };
  const onFailureBlockContact = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if ((params.publicId !== undefined) === true) {
      getSingleContact();
      setOpenEditContactModal(true);
    }

    getContacts(true, 0);
    getTags();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getContacts(true, 0);
  }, [size]);
  useEffect(() => {
    if (tags.length > 0) {
      getConvertedtags(tags);
    }
  }, [tags]);

  useEffect(() => {
    if (apiCalled === true) {
      getContacts(apiCalled, 0);
    }
    // eslint-disable-next-line
  }, [apiCalled]);

  const handlePageChange = (data) => {
    getContacts(true, data.selected);
    setCurrentPage(data.selected);
  };
  const getConvertedtags = (tag) => {
    var mapped = tag.map((item) => ({
      name: item.tagName,
      id: item.id,
      tagName: item.tagName,
      tagPrefix: item.tagPrefix,
    }));
    setMultiSelectTags(mapped);
  };

  const exportData = async () => {
    try {
      const response = await axios.get(baseUrl + `/contact/export/`, {
        responseType: "arraybuffer", // Ensure the response is treated as binary data
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Contact.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  const getTags = () => {
    apiGet(`/contact/tags`, onSuccessTags, onFailureTags);
  };
  const onSuccessTags = (response) => {
    setTags(response?.data);
  };
  const onFailureTags = (error) => {
    console.log(error);
  };

  const getContacts = (apiCalled, page) => {
    if (apiCalled) {
      apiGet(`/contact/?page=${page}&size=${size}`, onSuccess, onFailure);
    }
  };
  const onSuccess = (response) => {
    if (response?.status === 0) {
      setContacts(response?.data?.content);
      setPageCount(response?.data?.totalPages);
      setApiCalled(false);
    }
  };
  const onFailure = (error) => {
    console.log(error);
  };

  const onChange = (e) => {
    setContacts(null);
    setPageCount(0);
    setFilterData({
      ...filterData,
      searchQuery: e.target.value,
    });
    var searchedValue = e.target.value;
    const data = {
      dateStart: null,
      dateEnd: null,
      country: "",
      city: "",
      gender: "",
      contactTags: [],
      status: filterData.Status === "" ? "active" : filterData.Status,
      searchQuery: searchedValue,
    };

    if (searchedValue !== "") {
      setFilterData({
        fromDate: null,
        toDate: null,
        country: "",
        city: "",
        gender: "",
        Status: "",
        countryList: "",
        tags: [],
      });
      apiPost(`/contact/filter`, onSuccessSearch, onFailureSearch, data);
    } else {
      getContacts(true, 0);
    }
  };
  const onSuccessSearch = (response) => {
    if (response.status === 0) {
      setContacts(response?.data);
    }
  };
  const onFailureSearch = (error) => {
    console.log(error);
  };

  const debouncedOnChange = debounce(onChange, 1000);
  useEffect(() => {
    getContacts(true, 0);
    getTags();
  }, []);

  useEffect(() => {
    if (tags.length > 0) {
      getConvertedtags(tags);
    }
  }, [tags]);

  useEffect(() => {
    if (apiCalled === true) {
      getContacts(apiCalled, 0);
    }
  }, [apiCalled]);

  const getContactProfile = (number) => {
    apiGet(
      `/chat/details/${number}/${decryptedValue(
        auth.companyId
      )}/${decryptedValue(auth.idNumber)}`,
      onSuccessContactProfile,
      onFailureContactProfile
    );
  };

  const onSuccessContactProfile = (response) => {
    setContactProfile(response?.data);
  };
  const onFailureContactProfile = (error) => {
    console.log(error);
  };

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="ContactContainer">
        <div className="topHeader">
          <div className="uk-grid innnerSection" uk-grid="">
            <div className="uk-width-1-3 part1">
              <ul
                className="uk-subnav uk-subnav-pill"
                id="renderSwitcher"
                uk-switcher="connect: #contactMainTabs"
              >
                <li
                  onClick={() => {
                    navigate("/contacts", { replace: true });
                  }}
                >
                  <a>Contacts</a>
                </li>
                <li
                  onClick={() => {
                    navigate("/segments", { replace: true });
                  }}
                >
                  <a>Segments</a>
                </li>
              </ul>
            </div>
            <div className="uk-width-1-3 part2">
              <ul className="uk-switcher uk-margin" id="contactMainTabs">
                <li>
                  <div className="searchAndFilter">
                    <div className="searchInput">
                      <form
                        action=""
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <input
                          placeholder="Search contacts through name, number"
                          onChange={debouncedOnChange}
                        />
                      </form>
                    </div>
                    <div className="filterBtn">
                      <button
                        type="button"
                        onClick={() => handleOpenFilterModal()}
                      >
                        <Filter /> <span>Filter</span>
                        {noOfFilters > 0 && (
                          <span className="filterCount">{noOfFilters}</span>
                        )}
                      </button>
                    </div>
                  </div>
                </li>
                <li></li>
              </ul>
            </div>
            <div className="uk-width-1-3 part3">
              <ul className="uk-switcher uk-margin" id="contactMainTabs">
                <li>
                  <div className="btnwrp">
                    {/* <button className="expBtn" onClick={handleOpenDetailModal}> */}
                    <button className="expBtn" onClick={exportData}>
                      <Download /> Export Data
                    </button>
                    <button
                      className="addBtn"
                      type="button"
                      onClick={handleOpenContactModal}
                    >
                      Add Contact
                    </button>

                    <nav uk-dropnav="">
                      <ul className="uk-subnav">
                        <li>
                          <a className="menuIcon" style={{ cursor: "unset" }}>
                            <OverflowMenuVertical />
                          </a>
                          <div className="uk-dropdown topHeadingDropdown">
                            <ul className="uk-nav uk-dropdown-nav dropdownContent">
                              <li>
                                <Link
                                  to="/import-contact"
                                  //  onClick={() =>
                                  //   navigate("/import-contact", {
                                  //     replace: true,
                                  //   })
                                  // }
                                >
                                  <Download /> <span>Import contacts</span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </li>
                <li></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="tabContent">
          <ul className="uk-switcher uk-margin" id="contactMainTabs">
            <li>
              <div className="contactTable">
                <table className="uk-table uk-table-divider">
                  <thead>
                    <tr>
                      {/* <th>
                        <input type="checkbox" />
                        <div className="checkmarkWrp">
                          <nav uk-dropnav="mode: click">
                            <ul className="uk-subnav">
                              <li className="uk-padding-remove-left">
                                <a href="/contact">
                                  <span uk-drop-parent-icon=""></span>
                                </a>
                                <div className="uk-dropdown dropDownNav">
                                  <ul className="uk-nav uk-dropdown-nav dropDownContent">
                                    <li>
                                      <span className="dropDownHeading">
                                        Bulk Actions
                                      </span>
                                    </li>
                                    <li className="activeClass">
                                      <span>Add Tag</span>
                                    </li>
                                    <li>
                                      <span>Remove Tag</span>
                                    </li>
                                    <li>
                                      <span>Delete Tag</span>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </th> */}
                      <th>
                        <div className="tableDropDown">
                          <div className="uk-inline tableFilterDropdown">
                            <button type="button" className="dropdownBtn">
                              ALL CHANNELS
                            </button>
                            {/* <div
                              uk-dropdown="mode: click"
                              className="dropdownNav"
                            >
                              <span className="dropdownHeading">Channels</span>
                              <ul>
                                <li className="activeBtn">
                                  <button type="button">All channels</button>
                                </li>
                                <li>
                                  <button type="button">
                                    <img src={whatsappImg} alt="" /> WhatsApp
                                  </button>
                                </li>
                                <li>
                                  <button type="button">
                                    <img src={messengerIcon} alt="" /> Facebook
                                  </button>
                                </li>
                                <li>
                                  <button type="button">
                                    <img src={instaIcon} alt="" /> Instagram
                                  </button>
                                </li>
                              </ul>
                            </div> */}
                          </div>
                        </div>
                      </th>
                      <th>NUMBER</th>
                      <th>NAME</th>
                      <th>GENDER</th>
                      <th>EMAIL</th>
                      <th>
                        <div className="tableDropDown">
                          <div className="uk-inline tableFilterDropdown">
                            <button type="button" className="dropdownBtn">
                              CITY
                              {/* <ChevronDown /> */}
                            </button>
                            {/* <div
                              uk-dropdown="mode: click"
                              className="dropdownNav"
                            >
                              <span className="dropdownHeading">City wise</span>
                              <ul>
                                <li className="activeBtn">
                                  <button type="button">Karachi</button>
                                </li>
                                <li>
                                  <button type="button">Hyderabad</button>
                                </li>
                                <li>
                                  <button type="button">Multan</button>
                                </li>
                                <li>
                                  <button type="button">Faislabad</button>
                                </li>
                                <li>
                                  <button type="button">Islamabad</button>
                                </li>
                                <li>
                                  <button type="button">Rawalpindi</button>
                                </li>
                                <li>
                                  <button type="button">Peshawar</button>
                                </li>
                              </ul>
                            </div> */}
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="tableDropDown">
                          <div className="uk-inline tableFilterDropdown">
                            <button type="button" className="dropdownBtn">
                              STATUS
                              {/* <ChevronDown /> */}
                            </button>
                            {/* <div
                              uk-dropdown="mode: click"
                              className="dropdownNav"
                            >
                              <span className="dropdownHeading">
                                Contact Staus
                              </span>
                              <ul>
                                <li className="activeBtn">
                                  <button type="button">Blocked</button>
                                </li>
                                <li>
                                  <button type="button">Connected</button>
                                </li>
                              </ul>
                            </div> */}
                          </div>
                        </div>
                      </th>
                      <th>CREATED</th>
                      <th>LAST CONTACT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contacts?.length > 0 ? (
                      contacts?.map((val, index) => {
                        return (
                          <tr key={index}>
                            {/* <td>
                              <form action="">
                                <input type="checkbox" />
                              </form>
                            </td> */}
                            <td>
                              {val.channelId !== null && (
                                <img
                                  src={
                                    (val.channelId.toLowerCase() ===
                                      "whatsapp" &&
                                      whatsappImg) ||
                                    (val.channelId.toLowerCase() ===
                                      "instagram" &&
                                      instaIcon) ||
                                    (val.channelId.toLowerCase() ===
                                      "facebook" &&
                                      messengerIcon)
                                  }
                                  alt=""
                                />
                              )}
                            </td>
                            <td className="agentName">
                              <button
                                type="button"
                                onClick={() =>
                                  handleOpenDetailModal(val?.mobileNumber)
                                }
                              >
                                {val?.mobileNumber}
                              </button>
                            </td>
                            <td className="agentName">
                              <button
                                type="button"
                                onClick={() =>
                                  handleOpenDetailModal(val?.mobileNumber)
                                }
                              >
                                {val?.firstName +
                                  " " +
                                  (val?.lastName === null ? "" : val?.lastName)}
                              </button>
                            </td>
                            <td className="textCapatalize">
                              {val?.gender === null ? "-" : val?.gender}
                            </td>
                            <td>{val?.email === null ? "-" : val?.email}</td>
                            <td className="textCapatalize">
                              {val?.city === null ? "-" : val?.city}
                            </td>
                            <td>
                              <span
                                // className={
                                //   val?.status !== null &&
                                //   val?.status.toLowerCase() === "active"
                                //     ? "statusConnected"
                                //     : "statusBlocked"
                                // }
                                className={
                                  val?.isActive == null ||
                                  val?.isActive === true
                                    ? "statusConnected"
                                    : "statusBlocked"
                                }
                              >
                                {/* {val?.status !== null &&
                                val?.status.toLowerCase() === "active"
                                  ? "Connected"
                                  : "Blocked"} */}
                                {val?.isActive === null ||
                                val?.isActive === true
                                  ? "Connected"
                                  : "Blocked"}
                              </span>
                            </td>
                            <td>
                              {convertTimeWithDateIntoMinHourDate(
                                val?.createDateTime
                              )}
                            </td>
                            <td>
                              {val?.lastMessageTimeUnix === null
                                ? "-"
                                : convertTimestamp(val?.lastMessageTimeUnix)}
                            </td>
                            <td>
                              <button
                                className="editBtn tableEditBtns"
                                onClick={() => {
                                  handleOpenContactEditModal(val);
                                }}
                              >
                                <span uk-icon="icon: pencil"></span>
                              </button>
                              {/* <button
                                className="dltBtn tableEditBtns"
                                onClick={() => handleOpenDeleteModal(val)}
                              >
                                <span uk-icon="icon: trash"></span>
                              </button> */}
                              <div className="uk-inline">
                                <button
                                  type="button"
                                  className="contactBlockBtn"
                                >
                                  <OverflowMenuVertical />
                                </button>
                                <div uk-dropdown="" className="contactBlckDrop">
                                  <ul>
                                    <li>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          blockContact(val.publicId)
                                        }
                                      >
                                        {val?.isActive === true
                                          ? "Block"
                                          : "Unblock"}
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={11} className="dataNotFound">
                          {contacts === null ? (
                            <div uk-spinner=""></div>
                          ) : (
                            "Data Not Found"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="tablePaginationBox">
                  <div className="uk-margin-left">
                    Show{" "}
                    <select onChange={(e) => setSize(e.target.value)}>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>{" "}
                    entries
                  </div>
                  <div>
                    {pageCount > 1 && (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"...."}
                        pageCount={pageCount}
                        onPageChange={handlePageChange}
                        containerClassName={
                          "uk-pagination uk-float-right contatPagination"
                        }
                        activeClassName={"uk-active"}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={6}
                      />
                    )}
                  </div>
                </div>
              </div>
            </li>
            <li></li>
          </ul>
          {openFilterModal && (
            <FilterModal
              closeModal={handleCLoseFilterModal}
              setApiCalled={setApiCalled}
              multiSelectTags={multiSelectTags}
              setContacts={setContacts}
              filterModalDescTxt="Search by any date, country, city, gender, tags or Status"
              setFilterData={setFilterData}
              filterData={filterData}
              setPageCount={setPageCount}
            />
          )}

          {openContactModal && (
            <AddContact
              closeModal={handleCloseContactModal}
              multiSelectTags={multiSelectTags}
              formHeading="Add Contact"
              setApiCalled={setApiCalled}
              btnText={"Add Contact"}
            />
          )}
          {openEditContactModal && (
            <EditContact
              closeModal={handleCloseContactEditModal}
              multiSelectTags={multiSelectTags}
              formHeading="Edit Contact"
              setApiCalled={setApiCalled}
              btnText={"Edit Contact"}
              editContactData={editContactData}
            />
          )}
          {openDeleteContactModal && (
            <DeleteModal
              closeModal={handleCoseDeleteModal}
              deleteContactData={deleteContactData}
              setApiCalled={setApiCalled}
            />
          )}
          {openDetailModal && (
            <AgentDetailModal
              closeModal={handleCloseDetailModal}
              contactProfile={contactProfile}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Contacts;
