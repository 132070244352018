import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";
import whatsappIcon from "../../../assets/images/whatsapp-icon-new.jpg";
import messengerIcon from "../../../assets/images/messenger-icon-new.jpg";
import instaIcon from "../../../assets/images/instagram-icon-new.jpg";
import {
  Document,
  Download,
  Filter,
  OverflowMenuVertical,
} from "@carbon/icons-react";
import { apiGet, apiPost } from "../../../Utils/apiServices";
import berrytalk from "../../../assets/images/sidebar-logo.svg";
import telenor from "../../../assets/images/zong-icon.png";
import zong from "../../../assets/images/telenor-icon.png";
import {
  convertTimeWithDateIntoMinHourDate,
  convertTimestamp,
  dateWithAtTime,
} from "../../../Utils/DateFunctions";
import { toast } from "react-toastify";
import axios from "axios";
import { useAuth } from "../../../Context/AuthProvider";

const InvoiceList = () => {
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
  const [invoice, setInvoice] = useState(null);
  const [searchInvoice, setSearchInvoice] = useState(null);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [error, setError] = useState("");
  const [dateError, setDateError] = useState("");
  const [loader, setLoader] = useState(false);
  const baseUrl = process.env.REACT_APP_BASEURL_SUPERADMIN;
  const { auth } = useAuth();

  const [actualStatus, setActualStatus] = useState({});
  const [tempStatus, setTempStatus] = useState({});
  const [loading, setLoading] = useState({});

  const [filterDataSearch, setFilterDataSearch] = useState({
    fromDate: null,
    toDate: null,
    portal: "",
    isPaid: "",
  });
  const getInvoices = () => {
    apiGet(`/super-admin/getInvoices`, onSuccessInvoice, onFailureInvoice);
  };
  const onSuccessInvoice = (response) => {
    setInvoice(response);
    setSearchInvoice(response);
  };
  const onFailureInvoice = (error) => {
    console.log(error);
  };

  const handleOpenFilterModal = () => {
    setOpenFilterModal(true);
  };
  const handleCLoseFilterModal = () => {
    setOpenFilterModal(false);
    setError("");
  };

  const handleChecked = (invoiceId, portal) => {
    const newStatus = !tempStatus[invoiceId];
    setTempStatus((prevState) => ({
      ...prevState,
      [invoiceId]: newStatus,
    }));
    setLoading((prevState) => ({
      ...prevState,
      [invoiceId]: true,
    }));

    markInvoicePaid(invoiceId, portal, newStatus ? "PAID" : "UNPAID");
  };

  const markInvoicePaid = (invoiceId, portal, statusmark) => {
    apiGet(
      `/super-admin/company/${invoiceId}/${portal}/${statusmark}`,
      (response) =>
        onSuccessInvoicePaid(response, invoiceId, statusmark !== "UNPAID"),
      () => onFailureInvoicePaid(invoiceId)
    );
  };

  const onSuccessInvoicePaid = (response, invoiceId, newStatus) => {
    if (response?.status === 0) {
      setActualStatus((prevState) => ({
        ...prevState,
        [invoiceId]: newStatus,
      }));
      setLoading((prevState) => ({
        ...prevState,
        [invoiceId]: false,
      }));
      toast.success("Invoice status updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });

      getInvoices();
    }
  };

  const onFailureInvoicePaid = (invoiceId) => {
    setTempStatus((prevState) => ({
      ...prevState,
      [invoiceId]: !prevState[invoiceId],
    }));
    setLoading((prevState) => ({
      ...prevState,
      [invoiceId]: false,
    }));
    toast.error("Failed to update invoice status", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };

  useEffect(() => {
    const initialStatus = invoice?.reduce((acc, invoice) => {
      acc[invoice.id] = invoice.status !== "UNPAID";
      return acc;
    }, {});
    setActualStatus(initialStatus);
    setTempStatus(initialStatus);
  }, [invoice]);

  // Function to get the platform logo based on the platform name
  const getPlatformLogo = (platform) => {
    switch (platform?.trim().toUpperCase()) {
      case "WHATSAPP":
        return (
          <img
            src={whatsappIcon}
            alt="WhatsApp Logo"
            className="uk-margin-small-right"
          />
        );
      case "FACEBOOK":
        return (
          <img
            src={messengerIcon}
            alt="Facebook Logo"
            className="uk-margin-small-right"
          />
        );
      case "INSTAGRAM":
        return (
          <img
            src={instaIcon}
            alt="Instagram Logo"
            className="uk-margin-small-right"
          />
        );
      // Add more cases for other platforms as needed
      default:
        return null; // No logo for unknown platforms
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    if (value === "") {
      getInvoices(); // Reset to original data if search input is empty
    } else {
      const results = searchInvoice.filter((user) => {
        return (
          user.invoiceNumber.toLowerCase().includes(value) ||
          user.companyName.toLowerCase().includes(value) ||
          user.type.toLowerCase().includes(value) ||
          user.packageName.toLowerCase().includes(value)
        );
      });
      setInvoice(results); // Update invoice state with filtered results
    }
  };

  //filter modal

  const searchAndFilter = () => {
    var Errorvariable = "";
    const data = {
      dateStart: filterDataSearch.fromDate,
      dateEnd: filterDataSearch.toDate,
      portal: filterDataSearch.portal,
      status: filterDataSearch.isPaid,
    };

    if (
      ((data.dateStart === null && data.dateEnd === null) ||
        (data.dateStart === "" && data.dateEnd === "")) &&
      (data.portal === "" || data.portal === null) &&
      (data.status === undefined ||
        data.status === "" ||
        data.status === null) &&
      (filterDataSearch?.fromDate === "" ||
        filterDataSearch?.fromDate === null) &&
      (filterDataSearch?.isPaid === "" || filterDataSearch?.isPaid === null) &&
      (filterDataSearch?.portal === "" || filterDataSearch?.portal === null) &&
      (filterDataSearch?.toDate === "" || filterDataSearch?.toDate === null) &&
      dateError === ""
    ) {
      Errorvariable = "Please select parameters for filter";
      setError(Errorvariable);
    } else if (Errorvariable === "" && error === "" && dateError === "") {
      setLoader(true);
      apiPost(`/super-admin/filterInvoices`, onSuccess, onFailure, data);
    }
  };
  const onSuccess = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setLoader(false);
        handleCLoseFilterModal();
      }, 1000);
      setTimeout(() => {
        setInvoice(response?.data);
      }, 2000);
    }
  };
  const onFailure = (error) => {
    console.log(error);
  };
  const clearFilter = () => {
    setFilterDataSearch({
      fromDate: "",
      toDate: "",
      portal: "",
      isPaid: "",
    });
    setTimeout(() => {
      setLoader(false);

      getInvoices();
    }, 1000);
    setError("");
  };

  //filter modal
  //filter modal
  const handleToDateErrors = (value) => {
    setError("");
    if (value <= today) {
      if (!filterDataSearch.fromDate) {
        setDateError("From Date is Missing");
      }
      if (filterDataSearch.fromDate && value) {
        if (value < filterDataSearch.fromDate) {
          setDateError("To date must be greater than From date");
        } else {
          setDateError("");
        }
      }
    } else {
      if (value > today) {
        setFilterDataSearch({
          ...filterDataSearch,
          toDate: "",
        });
        setDateError("To Date exceeded with today's date");
      }
    }
  };

  const handleFromDateErrors = (value) => {
    setError("");
    if (!filterDataSearch.toDate) {
      setDateError("To Date is Missing");
    }
    if (filterDataSearch.toDate && value) {
      if (value > filterDataSearch.toDate) {
        setDateError("From date must be lesser than To date");
      } else {
        setDateError("");
      }
    }
  };

  const exportData = async () => {
    try {
      // Construct query parameters based on filterData
      const searchValue = document
        .querySelector('input[type="text"]')
        .value.trim();

      const queryParams = new URLSearchParams();

      // Add search parameter if there is a search value
      if (searchValue.trim() !== "") {
        queryParams.append("search", searchValue.trim());
      }

      // Add filtered parameters if they exist
      if (filterDataSearch.fromDate) {
        queryParams.append("start_Date", filterDataSearch.fromDate.trim());
      }

      if (filterDataSearch.toDate) {
        queryParams.append("end_Date", filterDataSearch.toDate.trim());
      }

      if (filterDataSearch.portal) {
        queryParams.append("portal", filterDataSearch.portal.trim());
      }

      if (filterDataSearch.isPaid) {
        queryParams.append("status", filterDataSearch.isPaid.trim());
      }

      const response = await axios.get(
        `${baseUrl}/super-admin/export/getInvoices?${queryParams}`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Invoices.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <>
      <div className="userWrp">
        <div className="userHeader">
          <div className="uk-grid uk-flex-middle" uk-grid="">
            <div className="uk-width-1-3">
              <div className="headerTabs">
                <ul
                  className="uk-subnav uk-subnav-pill"
                  uk-switcher="connect: .userTabbing"
                >
                  <li
                    onClick={() => {
                      navigate("/super-admin-package-list", { replace: false });
                    }}
                  >
                    <a>Packages</a>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/super-admin-invoice-list", { replace: true });
                    }}
                    className="uk-active"
                  >
                    <a>Invoices</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="uk-width-1-3">
              <div className="chatSearchFilter">
                <ul className="uk-switcher  userTabbing">
                  <li></li>
                  <li>
                    <div className="searchAndFilter">
                      <div className="headerSearch">
                        <form
                          action=""
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Search invoice through title"
                            onChange={(e) => handleSearchChange(e)}
                          />
                        </form>
                      </div>

                      <div className="filterBtn">
                        <button
                          type="button"
                          onClick={() => handleOpenFilterModal()}
                        >
                          <Filter /> <span>Filter</span>
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="uk-width-1-3">
              <div className="btnwrp text-right">
                <button className="reportButton" onClick={exportData}>
                  <Download /> Get Invoices
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="userTabContent">
          <div className="uk-container uk-container-large">
            <ul className="uk-switcher uk-margin userTabbing">
              <li></li>
              <li>
                <div className="userTableWrp">
                  <table className="uk-table">
                    <thead>
                      <tr>
                        <th>INVOICE NO.</th>
                        <th>COMPANY NAME</th>
                        <th>PACKAGE</th>
                        <th>TYPE</th>
                        <th>CHARGES</th>
                        <th>PLATFORM</th>
                        <th>CHANNELS</th>
                        <th>STATUS</th>
                        <th>PAYMENT STATUS</th>
                        <th>CREATED</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoice?.length > 0 ? (
                        invoice?.map((val, index) => {
                          return (
                            <tr key={index}>
                              <td>{val.invoiceNumber}</td>
                              <td className="agnetName">{val.companyName}</td>
                              <td>{val.packageName}</td>
                              <td>{val.type}</td>
                              <td>{val.charges.toLocaleString()}</td>
                              <td>
                                <span>
                                  {val.platform.toLowerCase() === "qa" ? (
                                    "QA"
                                  ) : (
                                    <img
                                      src={
                                        (val.platform.toLowerCase() ===
                                          "zong" &&
                                          zong) ||
                                        (val.platform.toLowerCase() ===
                                          "berrytalks" &&
                                          berrytalk) ||
                                        (val.platform.toLowerCase() ===
                                          "telenorengage" &&
                                          telenor)
                                      }
                                      alt=""
                                    />
                                  )}
                                </span>
                              </td>
                              <td>
                                {val.channels !== ""
                                  ? val.channels.split(",").map((platform) => {
                                      return getPlatformLogo(platform);
                                    })
                                  : "-"}
                              </td>

                              <td>
                                <span className="available ">{val.status}</span>
                              </td>
                              <td className="tableSwitch">
                                <Switch
                                  onChange={() =>
                                    handleChecked(val?.id, val?.platform)
                                  }
                                  checked={tempStatus?.[val?.id] || false}
                                  disabled={loading?.[val?.id] || false}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  width={26}
                                  height={14}
                                  handleDiameter={10}
                                  onColor="#29A869"
                                />
                              </td>
                              <td>{dateWithAtTime(val.createDateTime)}</td>

                              <td>
                                <div className="uk-inline">
                                  <button type="button">
                                    <OverflowMenuVertical />
                                  </button>
                                  <div
                                    uk-dropdown=""
                                    className="contactBlckDrop"
                                  >
                                    <ul>
                                      <li>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            navigate(
                                              "/invoice-preview",
                                              //  {
                                              //   replace: true,
                                              // },
                                              {
                                                state: val,
                                              }
                                            );
                                            // <Link to={{ pathname: '/invoice-preview', state: {'abc':'123'} }}>Go to Other Component</Link>
                                          }}
                                        >
                                          <Document /> View Invoice
                                        </button>
                                      </li>
                                      {/* <li>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            navigate(
                                              "/super-admin-invoice-detail",
                                              {
                                                replace: false,
                                              }
                                            );
                                          }}
                                        >
                                          <Information /> View Detail
                                        </button>
                                      </li> */}
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10} className="dataNotFound">
                            {invoice === null ? (
                              <div uk-spinner=""></div>
                            ) : (
                              "Data Not Found"
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {openFilterModal && (
        <div
          className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
          uk-modal="esc-close: false; bg-close: false"
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <div className="filterModalContent">
              <div className="modalHeading">
                <span>Search fIlter</span>
              </div>
              <div className="modalForm">
                <div className="formHeading">
                  <span>Filter by month, platform and company status</span>
                </div>
                <form
                  action=""
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="uk-grid uk-grid-small" uk-grid="">
                    <div className="uk-width-1-1">
                      <div className="formInputs">
                        <label htmlFor="from_date">Start Date</label>
                        <input
                          id="fromDate"
                          type="date"
                          value={filterDataSearch?.fromDate}
                          onChange={(e) => {
                            setFilterDataSearch({
                              ...filterDataSearch,
                              fromDate: e.target.value,
                            });
                            handleFromDateErrors(e.target.value);
                          }}
                          className="uk-input"
                          max={today}
                        />
                      </div>
                    </div>

                    <div className="uk-width-1-1">
                      <div className="formInputs">
                        <label htmlFor="to_date">To date</label>
                        <input
                          id="toDate"
                          type="date"
                          value={filterDataSearch?.toDate}
                          onChange={(e) => {
                            setFilterDataSearch({
                              ...filterDataSearch,
                              toDate: e.target.value,
                            });
                            handleToDateErrors(e.target.value);
                          }}
                          className="uk-input"
                          max={today}
                        />
                      </div>
                    </div>
                    {dateError !== "" && (
                      <div className="uk-width-1-1 formErrors">{dateError}</div>
                    )}
                    <div className="uk-width-1-1">
                      <div className="formInputs">
                        <label htmlFor="portal">Portal</label>
                        <select
                          name="portal"
                          id="portal"
                          className="uk-select"
                          value={filterDataSearch?.portal}
                          onChange={(e) => {
                            setFilterDataSearch({
                              ...filterDataSearch,
                              portal: e.target.value,
                            });
                            setError("");
                          }}
                        >
                          <option value="">All</option>
                          <option value="ZONG">Zong</option>
                          <option value="berrytalks">Berrytalk</option>
                          <option value="telenorengage">Telenor engage</option>
                        </select>
                      </div>
                    </div>

                    <div className="uk-width-1-1">
                      <div className="formInputs">
                        <label htmlFor="status">Payment status</label>
                        <select
                          name="status"
                          id="status"
                          className="uk-select"
                          value={filterDataSearch?.isPaid}
                          onChange={(e) => {
                            setFilterDataSearch({
                              ...filterDataSearch,
                              isPaid: e.target.value,
                            });
                            setError("");
                          }}
                        >
                          <option value="">Select payment status</option>
                          <option value="paid">Paid </option>
                          <option value="unpaid">Unpaid</option>
                        </select>
                      </div>
                    </div>
                    {error !== "" && (
                      <div className="uk-width-1-1 formErrors">{error}</div>
                    )}

                    <div className="uk-width-1-1">
                      <div className="btnwrp">
                        <button
                          type="button"
                          className="btn-1"
                          onClick={() => clearFilter()}
                        >
                          Clear filter
                        </button>

                        <button
                          type="button"
                          className="btn-1"
                          onClick={handleCLoseFilterModal}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn-2 w-90"
                          onClick={searchAndFilter}
                        >
                          {loader ? (
                            <div uk-spinner="" className="loader"></div>
                          ) : (
                            "Search"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InvoiceList;
