import { Close } from "@carbon/icons-react";

const LocationModal = ({ closeModal, locationDetails }) => {
  return (
    <div
      id="location"
      className="ModalStyle uk-flex-top uk-flex uk-open"
      uk-modal=""
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <button
          className="uk-modal-close-default modalBtn"
          type="button"
          onClick={closeModal}
        >
          <Close />
        </button>

        <div className="container" id="locationIframe">
          <iframe
            width="100%"
            height="300"
            src={`https://www.google.com/maps?q=${locationDetails.longitude},${locationDetails.latitude}&output=embed`}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default LocationModal;
