import { Close, Upload } from "@carbon/icons-react";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../Context/AuthProvider";
import { decryptedValue } from "../../Utils/RouteHashConversion";
import { apiPostSuperAdmin } from "../../Utils/apiServices";
import excelFile from "../../assets/images/excel-files.png";
import pdfFile from "../../assets/images/pdf-image.jpg";
import word from "../../assets/images/word.jpg";
import ppt from "../../assets/images/ppt.png";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";

const GalleryModal = ({
  closeModal,
  setFilePath,
  filePath,
  sendMessage,
  setMimType,
  getGalleryData,
  galleryData,
  pageCount,
  setApiCalledGallery,
  apiCalledGallery,
  handledelete,
}) => {
  const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;
  const { auth } = useAuth();
  const [fileUploadError, setFileUploadError] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const allowedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/jpg",
    "video/mp4",
    "audio/mp3",
    "application/pdf",
    // "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "text/csv",
    // "audio/ogg",
    "image/jpeg",
    "image/png",
    "image/jpg",
  ].join(", ");

  const shortenFilename = (filename, length = 4) => {
    const [name, ext] = filename.split(/(?=\.[^.]+$)/); // Split filename and extension
    if (name.length > length) {
      // Remove spaces from the name
      const trimmedName = name.replace(/\s/g, "");
      return `${trimmedName.slice(0, length)}..${trimmedName.slice(
        trimmedName.length - 2
      )}${ext}`;
    }
    return filename;
  };

  const handlePageChange = (data) => {
    getGalleryData(data.selected);
  };

  const handleGallerySelection = (data) => {
    if (data.fileType.includes("image")) {
      setMimType("image");
    }
    if (data.fileType.includes("video")) {
      setMimType("video");
    }
    if (
      data.fileType.includes("application") ||
      data.fileType.includes("text/csv")
    ) {
      setMimType("document");
    }
    setFilePath(data.fileId);
  };
  const handleFile = (e, allowedTypes) => {
    setIsUploaded(true);
    var selectedFile = e.target.files[0];
    let type = selectedFile.type;
    const maxSizeInMB = 10; // Change this value to your desired size
    if (selectedFile) {
      if (selectedFile.size / (1024 * 1024) > maxSizeInMB) {
        // alert(`File size exceeds the ${maxSizeInMB} MB limit`);
        setFileUploadError(`File size exceeds the ${maxSizeInMB} MB limit`);
        e.target.value = "";
        setIsUploaded(false);
      } else if (!allowedTypes.includes(selectedFile.type)) {
        // alert("Invalid file type. Please select a valid file.");
        setFileUploadError("Invalid file type. Please select a valid file.");
        e.target.value = "";
        setIsUploaded(false);
      } else {
        var formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("agentPublicId", decryptedValue(auth.agentId));
        formData.append("companyId", decryptedValue(auth.companyId));
        setTimeout(() => {
          apiPostSuperAdmin(
            `/chat/upload`,
            onSuccessFileUpload,
            onFailureFileUpload,
            formData
          );
        }, 1000);
      }
    }
  };
  const onSuccessFileUpload = (response) => {
    let fileId = response.toString();
    setFileUploadError(null);
    setIsUploaded(false);
    setFilePath(fileId);
    setApiCalledGallery(true);
  };
  const onFailureFileUpload = (error) => {
    if (error.response.status === 400) {
      toast.error("File already exist in gallery", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
      });
      setIsUploaded(false);
    }
  };

  useEffect(() => {
    if (filePath !== "") {
      if (apiCalledGallery === true) {
        getGalleryData(0);
      }
    }
  }, [filePath]);

  return (
    <div
      className="customModal gallery  ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="modalcontainer">
          <div className="modalHeading">
            <span>Gallery</span>
          </div>
          <div className="upload">
            {isUploaded ? (
              <div uk-spinner="" className="loader"></div>
            ) : (
              <div
                className="js-upload"
                uk-form-custom=""
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <input
                  type="file"
                  multiple
                  // accept="image/*"
                  onChange={(e) => handleFile(e, allowedFileTypes)}
                />
                <div className="imgwrp">
                  <Upload />
                </div>
              </div>
            )}
          </div>
          <div className="uk-margin-bottom">Upload media here</div>
          <div className="container">
            {galleryData?.length > 0 ? (
              galleryData?.map((data, index) => {
                return (
                  <div
                    className={
                      data.fileId === filePath
                        ? "gallery-container active"
                        : "gallery-container"
                    }
                    onClick={() => {
                      handleGallerySelection(data);
                    }}
                    key={index}
                  >
                    {((data.filePath.includes("png") ||
                      data.filePath.includes("PNG") ||
                      data.filePath.includes("jpeg") ||
                      data.filePath.includes("jpg")) && (
                      <div>
                        <img
                          src={
                            chatBaseUrl.replace("/assets", "") + data.filePath
                          }
                        />
                        {shortenFilename(data.fileName)}
                        <button
                          onClick={() => {
                            handledelete(data.fileId);
                          }}
                        >
                          <Close />
                        </button>
                      </div>
                    )) ||
                      (data.filePath.includes("mp4") && (
                        <div>
                          <video controls>
                            <source
                              src={
                                chatBaseUrl.replace("/assets", "") +
                                data.filePath
                              }
                            />
                            Your browser does not support the video tag.
                          </video>
                          {shortenFilename(data.fileName)}
                          <button
                            onClick={() => {
                              handledelete(data.fileId);
                            }}
                          >
                            <Close />
                          </button>
                        </div>
                      )) ||
                      (data.filePath.includes("pdf") && (
                        <div>
                          <img src={pdfFile} />
                          {shortenFilename(data.fileName)}
                          <button
                            onClick={() => {
                              handledelete(data.fileId);
                            }}
                          >
                            <Close />
                          </button>
                        </div>
                      )) ||
                      ((data.filePath.includes("xlsx") ||
                        data.filePath.includes("csv")) && (
                        <div>
                          <img src={excelFile} />
                          {shortenFilename(data.fileName)}
                          <button
                            onClick={() => {
                              handledelete(data.fileId);
                            }}
                          >
                            <Close />
                          </button>
                        </div>
                      )) ||
                      (data.filePath.includes("doc") && (
                        <div>
                          <img src={word} />
                          {shortenFilename(data.fileName)}
                          <button
                            onClick={() => {
                              handledelete(data.fileId);
                            }}
                          >
                            <Close />
                          </button>
                        </div>
                      )) ||
                      (data.filePath.includes("ppt") && (
                        <div>
                          <img src={ppt} />
                          {shortenFilename(data.fileName)}
                          <button
                            onClick={() => {
                              handledelete(data.fileId);
                            }}
                          >
                            <Close />
                          </button>
                        </div>
                      ))}
                  </div>
                );
              })
            ) : galleryData === null ? (
              <div uk-spinner=""></div>
            ) : (
              <div>No data found</div>
            )}
          </div>
          <div
            style={{ position: "absolute", right: "30px", marginTop: "10px" }}
          >
            {pageCount > 1 && (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"...."}
                pageCount={pageCount}
                onPageChange={handlePageChange}
                containerClassName={
                  "uk-pagination uk-float-right galleryPagination"
                }
                activeClassName={"uk-active"}
                marginPagesDisplayed={3}
                pageRangeDisplayed={6}
              />
            )}
          </div>
          {fileUploadError !== null && (
            <div className="formErrors uk-margin-medium-top">
              {fileUploadError}
            </div>
          )}
          <div className="btnwrp">
            <button
              type="button"
              className="btn-1"
              onClick={() => {
                setApiCalledGallery(false);
                closeModal();
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn-2 deleteBtn w-90"
              onClick={() => {
                sendMessage();
                closeModal();
              }}
              disabled={filePath === "" && true}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryModal;
