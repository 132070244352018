import {
  Camera,
  Checkmark,
  ChevronDown,
  ChevronLeft,
  Edit,
  Locked,
  Purchase,
} from "@carbon/icons-react";
import React from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useState } from "react";
import { apiGet, apiPost } from "../../../Utils/apiServices";
import { useEffect } from "react";
import { useAuth } from "../../../Context/AuthProvider";
import { toast } from "react-toastify";
// import { chatBaseUrl } from "../../Constant";
import moment from "moment";
import { dateWithAtTime, formatDate } from "../../../Utils/DateFunctions";
import { removeAndAddActiveClass } from "../../../Utils/jqueryFunctions";
import { useWebSocket } from "../../../socket/WebSocketManager";
import DetailTab from "../../UserAndTeam/components/DetailTab";
import RoleTab from "../../UserAndTeam/components/RoleTab";
import { decryptedValue } from "../../../Utils/RouteHashConversion";
import DeleteModal from "../../../components/Modal/DeleteModal";
import ChangePassword from "../../../../src/pages/UserAndTeam/components/Modal/ChangePassword";

const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;


const Profile = () => {
  const { socket } = useWebSocket();
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [filePath, setFilePath] = useState("");
  const [selectedStatusSelection, setSelectedStatusSelection] = useState("");
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [openAdminDeleteModal, setOpenAdminDeleteModal] = useState(false);
  const [submittedData, setSubmittedData] = useState({
    firstName: "",
    lastName: "",
    phoneNumberWork: "",
    phoneNumberPrivate: "",
    email: "",
    country: "",
    city: "",
    team: "",
  });
  const [genericRoute, setGenericRoute] = useState(false);
  const [errorEnable, setErrorEnable] = useState(false);
  // const [loader, setLoader] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [dltLoader, setDltLoader] = useState(false);

  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  

  const handleOpenChangePasswordModal = () => {
    setOpenChangePasswordModal(true);
  };
  const handleCloseChangePasswordModal = () => {
    setOpenChangePasswordModal(false);
  };

  const handleOpenAdminDeleteModal = () => {
    setOpenAdminDeleteModal(true);
  };

  const handleCloseAdminDeleteModal = () => {
    setOpenAdminDeleteModal(false);
  };





  // const populateAgentStatus = () => {
  //   let result = "";
  //   let data = [
  //     { id: 1, name: "ONLINE", value: "ONLINE", class: "availableStatus" },
  //     { id: 2, name: "AWAY", value: "AWAY", class: "awayStatus" },
  //     // { id: 3, name: "BREAK", value: "BREAK", class: "busyStatus" },
  //     // { id: 4, name: "OFFLINE", value: "OFFLINE", class: "offline" },
  //   ];
  //   result = data.map((response, index) => {
  //     return (
  //       <li key={index} className="agentStatus">
  //         <div
  //           id={"agentUpdate" + response.id}
  //           onClick={(e) =>
  //             handleFilterSelection(
  //               "#agentUpdate" + response.id,
  //               ".onlineDropDownWrp ul li .active",
  //               response.name,
  //               response.value
  //             )
  //           }
  //           className={
  //             response.name === selectedStatusSelection
  //               ? "active overview_id"
  //               : "overview_id"
  //           }
  //         >
  //           <span className={`${response.class} cursor`}>
  //             <span className="filtername">{response.name}</span>
  //           </span>
  //         </div>
  //         <div>
  //           <span className="uk-icon tickIcon">
  //             <Checkmark />
  //           </span>
  //         </div>
  //       </li>
  //     );
  //   });
  //   return result;
  // };

  // for roles screen
  // const [checked, setChecked] = useState(false);
  // const [adminPermissions, setAdminpermissions] = useState([
  //   {
  //     heading: null,
  //     text: "Manages everything related to account access and security. Assign this role to users who manage users and teams of users, apply restrictions with two-factor authentication, password policies and API keys.",
  //     roleName: "ALL-PERMISSION",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Account Manager",
  //     text: "Manages everything related to account access. Provided to all Active accounts to login and use the portal",
  //     roleName: "ACCOUNT-MANAGER",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Users and Teams",
  //     text: "Manage users and teams. Provide access to users, and manage their roles.",
  //     roleName: "USER-TEAMS",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Integrations Manager",
  //     text: "Grants access to versatile reporting and data handling options.Track engagement and communication efficiency Schedule reports - Troubleshoot message delivery - Exchange data",
  //     roleName: "INTEGRATION-MANAGER",
  //     checked: false,
  //     hr: true,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Analytics Manager",
  //     text: " Grants access to versatile reporting and data handling options.Track engagement and communication efficiency Schedule reports - Troubleshoot message delivery - Exchange data",
  //     roleName: "ANALYTICS-MANAGER",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "FINANCE AND ANALYTICS",
  //   },
  //   {
  //     heading: "Finance Data Manager",
  //     text: "Manages communication costs, tracks account balance, and performs financial management tasks.",
  //     roleName: "FINANCE-DATA-MANAGER",
  //     checked: false,
  //     hr: true,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Communication Manager",
  //     text: "Creates engagement scenarios and sends broadcast campaigns.",
  //     roleName: "COMMUNICATION-MANAGER",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "COMMUNICATION MANAGEMENT",
  //   },
  //   {
  //     heading: "People Manager",
  //     text: "Manages customer profiles on the data platform.",
  //     roleName: "PEOPLE-MANAGER",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Live Chat Manager",
  //     text: "Manages live chat conversations regardless of assignment. Chats can be assigned to them manually only and user reports will be shared. Grants access to versatile reporting and data handling options related to live chat conversations. Track live chat efficiency - Schedule reports - Exchange data",
  //     roleName: "LIVE-CHAT-MANAGER",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Chatbot Manager",
  //     text: " Manages chatbot design, support, and usage to achieve business goals. Supervises planned activities and progress. Creates and edits chatbots. Activate or deactivate the bots",
  //     roleName: "CHAT-BOT-MANAGER",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Chatbot Supervisor",
  //     text: "Monitor chatbots and analytics",
  //     roleName: "CHATBOT-SUPERVISOR",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Live Chat User",
  //     text: "Manages live chat conversations assigned to them Can not manage chats for others. User performance reports will be created",
  //     roleName: "LIVE-CHAT-AGENT",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "",
  //   },

  //   {
  //     heading: "Chat bot Conversation",
  //     text: "View bot conversations with the customers.",
  //     roleName: "CHATBOT-CONVERSATION",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Form Access Manager",
  //     text: "Controls access to customer-filled forms. Assigned to users who need to view and manage submitted forms on the portal.",
  //     roleName: "FORM-ACCESS-MANAGER",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "FORM MANAGEMENT",
  //   },
  // ]);
  // const [superVisorPermissions, setSuperVisorPermissions] = useState([
  //   {
  //     heading: null,
  //     text: "Manages everything related to account access and security. Assign this role to users who manage users and teams of users, apply restrictions with two-factor authentication, password policies and API keys.",
  //     roleName: "SUPERVISOR",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Account Manager",
  //     text: "Manages everything related to account access. Provided to all Active accounts to login and use the portal",
  //     roleName: "ACCOUNT-MANAGER",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Users and Teams",
  //     text: "Manage users and teams. Provide access to users, and manage their roles.",
  //     roleName: "USER-TEAMS",
  //     checked: false,
  //     hr: true,
  //     box: false,
  //     innerHeading: "",
  //   },

  //   {
  //     heading: "Analytics Manager",
  //     text: " Grants access to versatile reporting and data handling options.Track engagement and communication efficiency Schedule reports - Troubleshoot message delivery - Exchange data",
  //     roleName: "ANALYTICS-MANAGER",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "FINANCE AND ANALYTICS",
  //   },
  //   {
  //     heading: "Finance Data Manager",
  //     text: "Manages communication costs, tracks account balance, and performs financial management tasks.",
  //     roleName: "FINANCE-DATA-MANAGER",
  //     checked: false,
  //     hr: true,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Communication Manager",
  //     text: "Creates engagement scenarios and sends broadcast campaigns.",
  //     roleName: "COMMUNICATION-MANAGER",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "COMMUNICATION MANAGEMENT",
  //   },
  //   {
  //     heading: "People Manager",
  //     text: "Manages customer profiles on the data platform.",
  //     roleName: "PEOPLE-MANAGER",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Live Chat Manager",
  //     text: "Manages live chat conversations regardless of assignment. Chats can be assigned to them manually only and user reports will be shared. Grants access to versatile reporting and data handling options related to live chat conversations. Track live chat efficiency - Schedule reports - Exchange data",
  //     roleName: "LIVE-CHAT-MANAGER",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Chatbot Manager",
  //     text: " Manages chatbot design, support, and usage to achieve business goals. Supervises planned activities and progress. Creates and edits chatbots. Activate or deactivate the bots",
  //     roleName: "CHAT-BOT-MANAGER",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Chatbot Supervisor",
  //     text: "Monitor chatbots and analytics",
  //     roleName: "CHATBOT-SUPERVISOR",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Live Chat User",
  //     text: "Manages live chat conversations assigned to them Can not manage chats for others. User performance reports will be created",
  //     roleName: "LIVE-CHAT-AGENT",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "",
  //   },

  //   {
  //     heading: "Chat bot Conversation",
  //     text: "View bot conversations with the customers.",
  //     roleName: "CHATBOT-CONVERSATION",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "",
  //   },
  //   {
  //     heading: "Form Access Manager",
  //     text: "Controls access to customer-filled forms. Assigned to users who need to view and manage submitted forms on the portal.",
  //     roleName: "FORM-ACCESS-MANAGER",
  //     checked: false,
  //     hr: false,
  //     innerHeading: "FORM MANAGEMENT",
  //   },
  // ]);
  // const [agentPermissions, setAgentPermissions] = useState([
  //   {
  //     heading: null,
  //     text: "Manages everything related to account access and security. Assign this role to users who manage users and teams of users, apply restrictions with two-factor authentication, password policies and API keys.",
  //     roleName: "AGENT",
  //     checked: false,
  //     hr: false,
  //   },
  //   {
  //     heading: "Account Manager",
  //     text: "Manages everything related to account access. Provided to all Active accounts to login and use the portal",
  //     roleName: "ACCOUNT-MANAGER",
  //     checked: false,
  //     hr: true,
  //   },

  //   {
  //     heading: "Live Chat User",
  //     text: "Manages live chat conversations assigned to them Can not manage chats for others. User performance reports will be created",
  //     roleName: "LIVE-CHAT-AGENT",
  //     checked: false,
  //     hr: false,
  //   },

  //   {
  //     heading: "Chat bot Conversation",
  //     text: "View bot conversations with the customers.",
  //     roleName: "CHATBOT-CONVERSATION",
  //     checked: false,
  //     hr: true,
  //   },
  //   {
  //     heading: "Form Access Manager",
  //     text: "Controls access to customer-filled forms. Assigned to users who need to view and manage submitted forms on the portal.",
  //     roleName: "FORM-ACCESS-MANAGER",
  //     checked: false,
  //     hr: false,
  //   },
  // ]);

  // const [adminSelectedPermissions, setAdminSelectedPermissions] = useState([
  //   "ACCOUNT-MANAGER",
  //   "USER-TEAMS",
  //   "INTEGRATION-MANAGER",
  //   "ANALYTICS-MANAGER",
  //   "FINANCE-DATA-MANAGER",
  //   "COMMUNICATION-MANAGER",
  //   "PEOPLE-MANAGER",
  //   "LIVE-CHAT-MANAGER",
  //   "CHAT-BOT-MANAGER",
  //   "CHATBOT-SUPERVISOR",
  //   "CHATBOT-CONVERSATION",
  // ]);
  // const [supervisorSelectedPermissions, setSupervisorSelectedPermissions] =
  //   useState(["ACCOUNT-MANAGER"]);
  // const [agentSelectedPermissions, setAgentSelectedPermissions] = useState([
  //   "ACCOUNT-MANAGER",
  //   "LIVE-CHAT-AGENT",
  // ]);
  // for end roles screen

  // const handleFilterSelection = (
  //   targetElement,
  //   parentElement,
  //   filerName,
  //   filterValue
  // ) => {
  //   removeAndAddActiveClass(targetElement, parentElement, "active");

  //   setSelectedStatusSelection(filerName);
  //   // changeAgentStatus(filterValue);
  // };

  // const getUserDetails = () => {
  //   apiGet(
  //     `/agent/${decryptedValue(params.publicId)}`,
  //     onSuccessUserDetails,
  //     onFailureUserDetails
  //   );
  // };
  // const onSuccessUserDetails = (response) => {
  //   setGenericRoute(response.data.isGenericRoute);
  //   setUserDetails(response?.data);
  //   setSelectedStatusSelection(response?.data.status);
  // };
  // const onFailureUserDetails = (error) => {
  //   console.log(error);
  // };

  // useEffect(() => {
  //   getUserDetails();
  // }, []);

  // const updateAgent = (e) => {
  //   e.preventDefault();
  //   setErrorEnable(true);
  //   setLoader(true);
  //   let condition = true;

  //   if (
  //     adminSelectedPermissions.filter((val) => val === "ALL-PERMISSION")
  //       .length !== 0
  //   ) {
  //     if (adminSelectedPermissions.length < 12) {
  //       condition = false;
  //     }
  //   } else if (
  //     supervisorSelectedPermissions.filter((val) => val === "SUPERVISOR")
  //       .length !== 0
  //   ) {
  //     if (supervisorSelectedPermissions.length < 3) {
  //       condition = false;
  //     }
  //   } else if (
  //     agentSelectedPermissions.filter((val) => val === "AGENT").length !== 0
  //   ) {
  //     if (agentSelectedPermissions.length < 4) {
  //       condition = false;
  //     }
  //   }

  //   let data = {
  //     companyPublicId: userDetails?.companyPublicId,
  //     phoneNumberWork: submittedData.phoneNumberWork,
  //     phoneNumberPrivate: submittedData.phoneNumberPrivate,
  //     email: submittedData.email,
  //     // timeZone: "string",
  //     agentType: "CUSTOMER_SUPPORT",
  //     status: selectedStatusSelection,
  //     isAvailable: userDetails?.isAvailable,
  //     agentPublicId: userDetails?.publicId,
  //     firstName: submittedData.firstName,
  //     lastName: submittedData.lastName,
  //     country: submittedData.country,
  //     city: submittedData.city,
  //     // profilePic: filePath,
  //     team: submittedData?.team,
  //     password: "",
  //     role: {
  //       name:
  //         (adminSelectedPermissions.filter((val) => val === "ALL-PERMISSION")
  //           .length > 0 &&
  //           "ROLE_ADMIN") ||
  //         (supervisorSelectedPermissions.filter((val) => val === "SUPERVISOR")
  //           .length > 0 &&
  //           "ROLE_SUPERVISOR") ||
  //         (agentSelectedPermissions.filter((val) => val === "AGENT").length >
  //           0 &&
  //           "ROLE_AGENT"),
  //     },
  //     isGenericRoute: genericRoute,
  //     permissionsList:
  //       (adminSelectedPermissions?.filter((val) => val === "ALL-PERMISSION")
  //         .length > 0 &&
  //         adminSelectedPermissions) ||
  //       (supervisorSelectedPermissions?.filter((val) => val === "SUPERVISOR")
  //         .length > 0 &&
  //         supervisorSelectedPermissions) ||
  //       (agentSelectedPermissions?.filter((val) => val === "AGENT").length >
  //         0 &&
  //         agentSelectedPermissions),
  //   };
  //   if (
  //     submittedData.email !== "" &&
  //     submittedData.firstName !== "" &&
  //     submittedData.lastName !== "" &&
  //     submittedData.phoneNumberPrivate !== "" &&
  //     submittedData.country !== "" &&
  //     submittedData.city !== "" &&
  //     submittedData.phoneNumberWork !== "" &&
  //     submittedData.phoneNumberPrivate !== null &&
  //     submittedData.phoneNumberWork !== null &&
  //     isNumberValid === true &&
  //     data.permissionsList !== false &&
  //     condition === true
  //   ) {
  //     apiPost(`/agent/edit`, onSuccessAgentUpdate, onFailureAgentUpdate, data);
  //   } else {
  //     setLoader(false);
  //   }
  // };

  // const onSuccessAgentUpdate = (response) => {
  //   if (response.status === 1) {
  //     setLoader(false);
  //     toast.success("User updated successfully successfully", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 1000,
  //     });
  //   }
  // };
  // const onFailureAgentUpdate = (error) => {
  //   console.log(error);
  //   setLoader(false);
  //   };

  // useEffect(() => {
  //   if (userDetails !== undefined) {
  //     let adminPermissionExist =
  //       userDetails?.agentPermissions?.filter(
  //         (val) => val?.permission?.name === "ALL-PERMISSION"
  //       ).length > 0;
  //     let supervisorPermissionExist =
  //       userDetails?.agentPermissions?.filter(
  //         (val) => val?.permission?.name === "SUPERVISOR"
  //       ).length > 0;
  //     let agentPermissionExist =
  //       userDetails?.agentPermissions?.filter(
  //         (val) => val?.permission?.name === "AGENT"
  //       ).length > 0;

  //     // if (adminPermissionExist === true) {
  //     //   // console.log(adminPermissionExist,'===')
  //     //   let liveChatPermission = userDetails?.agentPermissions?.filter(
  //     //     (val) => val?.permission?.name === "LIVE-CHAT-AGENT"
  //     //   );

  //     //   // console.log(liveChatPermission,'===')

  //     //   var adminPermissionTemp = [...adminPermissions];
  //     //   // console.log(adminPermissionTemp,'===')
  //     //   setAdminSelectedPermissions((list) => [...list, "ALL-PERMISSION"]);
  //     //   adminPermissionTemp[0]["checked"] = true;
  //     //   setAdminpermissions(adminPermissionTemp);

  //     //   if (liveChatPermission.length > 0) {
  //     //     let index = adminPermissions
  //     //       .map((val) => val.roleName)
  //     //       .indexOf("LIVE-CHAT-AGENT");
  //     //       console.log(index,'===')
  //     //     adminPermissions[index]["checked"] = true;
  //     //     setAdminpermissions(adminPermissions);
  //     //   }

  //     //   console.log(adminPermissions,'===')
  //     // }
  //     if (adminPermissionExist === true) {
  //       var adminPermissionTemp = [...adminPermissions];
  //       setAdminSelectedPermissions((list) => [...list, "ALL-PERMISSION"]);
  //       adminPermissionTemp[0]["checked"] = true;
  //       setAdminpermissions(adminPermissionTemp);

  //       let temperoryCondition = [];
  //       userDetails?.agentPermissions.map((val) => {
  //         temperoryCondition.push(val?.permission?.name);

  //         adminPermissions?.map((item, index) => {
  //           if (val?.permission?.name === item.roleName) {
  //             adminPermissions[index]["checked"] = true;
  //             setAdminSelectedPermissions(adminPermissionTemp);
  //           }
  //         });
  //       });
  //       setAdminSelectedPermissions(temperoryCondition);
  //     }
  //     if (supervisorPermissionExist === true) {
  //       var superVisorPermissionTemp = [...superVisorPermissions];
  //       setSupervisorSelectedPermissions((list) => [...list, "SUPERVISOR"]);
  //       superVisorPermissionTemp[0]["checked"] = true;
  //       setSuperVisorPermissions(superVisorPermissionTemp);

  //       let temperoryCondition = [];
  //       userDetails?.agentPermissions.map((val) => {
  //         temperoryCondition.push(val?.permission?.name);

  //         superVisorPermissions?.map((item, index) => {
  //           if (val?.permission?.name === item.roleName) {
  //             superVisorPermissions[index]["checked"] = true;
  //             setSuperVisorPermissions(superVisorPermissionTemp);
  //           }
  //         });
  //       });
  //       setSupervisorSelectedPermissions(temperoryCondition);
  //     }
  //     if (agentPermissionExist === true) {
  //       var agentPermissionsTemp = [...agentPermissions];
  //       setAgentSelectedPermissions((list) => [...list, "AGENT"]);
  //       agentPermissionsTemp[0]["checked"] = true;
  //       setAgentPermissions(agentPermissionsTemp);

  //       let temperoryCondition = [];
  //       userDetails?.agentPermissions.map((val) => {
  //         temperoryCondition.push(val?.permission?.name);

  //         agentPermissions?.map((item, index) => {
  //           if (val?.permission?.name === item.roleName) {
  //             agentPermissions[index]["checked"] = true;
  //             setAgentPermissions(agentPermissionsTemp);
  //           }
  //         });
  //       });
  //       setAgentSelectedPermissions(temperoryCondition);
  //     }
  //   }
  //   setSubmittedData({
  //     firstName: userDetails?.firstName,
  //     lastName: userDetails?.lastName,
  //     email: userDetails?.email,
  //     phoneNumberPrivate: userDetails?.phoneNumberPersonal,
  //     phoneNumberWork: userDetails?.phoneNumberWork,
  //     team: userDetails?.team !== null ? userDetails?.team?.publicId : "",
  //     country: userDetails?.country,
  //     city: userDetails?.city,
  //   });
  // }, [userDetails]);

  // const handleAdminPermission = (index, permission, checked) => {
  //   toast.error("Role change not permitted", {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  //   if (location.pathname.includes("profile") === false) {
  //     if (checked === false && index === 0) {
  //       let firstindex = agentSelectedPermissions
  //         .map(function (o) {
  //           return o;
  //         })
  //         .indexOf("AGENT");

  //       if (firstindex > -1) {
  //         agentSelectedPermissions.splice(firstindex, 1);
  //         agentPermissions[0]["checked"] = false;
  //         setAgentPermissions(agentPermissions);
  //         setAgentSelectedPermissions(agentSelectedPermissions);
  //       }
  //       let Supervisorindex = supervisorSelectedPermissions
  //         .map(function (o) {
  //           return o;
  //         })
  //         .indexOf("SUPERVISOR");

  //       if (Supervisorindex > -1) {
  //         supervisorSelectedPermissions.splice(Supervisorindex, 1);
  //         superVisorPermissions[0]["checked"] = false;
  //         setSuperVisorPermissions(superVisorPermissions);
  //         setSupervisorSelectedPermissions(supervisorSelectedPermissions);
  //       }
  //     }

  //     var adminPermissionTemp = [...adminPermissions];
  //     if (index === 0) {
  //       if (checked === false) {
  //         setAdminSelectedPermissions((list) => [...list, permission]);
  //         adminPermissionTemp[index]["checked"] = true;
  //         setAdminpermissions(adminPermissionTemp);
  //       } else {
  //         adminPermissionTemp[index]["checked"] = false;
  //         setAdminpermissions(adminPermissionTemp);
  //         let firstindex = adminSelectedPermissions
  //           .map(function (o) {
  //             return o;
  //           })
  //           .indexOf("ALL-PERMISSION");
  //         if (firstindex > -1) {
  //           adminSelectedPermissions.splice(firstindex, 1);
  //           setAdminSelectedPermissions(adminSelectedPermissions);
  //         }
  //       }
  //     } else {
  //       if (
  //         permission === "LIVE-CHAT-AGENT" ||
  //         permission === "FORM-ACCESS-MANAGER"
  //       ) {
  //         if (checked === false) {
  //           setAdminSelectedPermissions((list) => [...list, permission]);
  //           adminPermissionTemp[index]["checked"] = true;
  //           setAdminpermissions(adminPermissionTemp);
  //         } else {
  //           adminPermissionTemp[index]["checked"] = false;
  //           setAdminpermissions(adminPermissionTemp);
  //           const otherIndex = adminSelectedPermissions.indexOf(permission);
  //           if (otherIndex > -1) {
  //             adminSelectedPermissions.splice(otherIndex, 1);
  //             setAdminSelectedPermissions(adminSelectedPermissions);
  //           }
  //         }
  //       }
  //     }
  //   }
  // };
  // const handleSupervisorPermission = (index, permission, checked) => {
  //   toast.error("Role change not permitted", {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  //   if (location.pathname.includes("profile") === false) {
  //     if (checked === false && index === 0) {
  //       let firstindex = agentSelectedPermissions
  //         .map(function (o) {
  //           return o;
  //         })
  //         .indexOf("AGENT");

  //       if (firstindex > -1) {
  //         agentSelectedPermissions.splice(firstindex, 1);
  //         agentPermissions[0]["checked"] = false;
  //         setAgentPermissions(agentPermissions);
  //         setAgentSelectedPermissions(agentSelectedPermissions);
  //       }
  //     }
  //     let adminIndex = adminSelectedPermissions
  //       .map(function (o) {
  //         return o;
  //       })
  //       .indexOf("ALL-PERMISSION");

  //     if (adminIndex > -1) {
  //       adminSelectedPermissions.splice(adminIndex, 1);
  //       adminPermissions[0]["checked"] = false;
  //       setAdminpermissions(adminPermissions);
  //       setAdminSelectedPermissions(adminSelectedPermissions);
  //     }

  //     var superVisorPermissionTemp = [...superVisorPermissions];
  //     if (permission !== "ACCOUNT-MANAGER") {
  //       if (index === 0) {
  //         if (checked === false) {
  //           setSupervisorSelectedPermissions((list) => [...list, permission]);
  //           superVisorPermissionTemp[index]["checked"] = true;
  //           setSuperVisorPermissions(superVisorPermissionTemp);
  //         } else {
  //           superVisorPermissionTemp[index]["checked"] = false;
  //           setSuperVisorPermissions(superVisorPermissionTemp);
  //           let firstindex = supervisorSelectedPermissions
  //             .map(function (o) {
  //               return o;
  //             })
  //             .indexOf("SUPERVISOR");

  //           if (firstindex > -1) {
  //             supervisorSelectedPermissions.splice(firstindex, 1);
  //             setSupervisorSelectedPermissions(supervisorSelectedPermissions);
  //           }
  //         }
  //       } else {
  //         if (checked === false) {
  //           setSupervisorSelectedPermissions((list) => [...list, permission]);
  //           superVisorPermissionTemp[index]["checked"] = true;
  //           setSuperVisorPermissions(superVisorPermissionTemp);
  //         } else {
  //           superVisorPermissionTemp[index]["checked"] = false;
  //           setSuperVisorPermissions(superVisorPermissionTemp);
  //           const otherIndex =
  //             supervisorSelectedPermissions.indexOf(permission);
  //           if (otherIndex > -1) {
  //             supervisorSelectedPermissions.splice(otherIndex, 1);
  //             setSupervisorSelectedPermissions(supervisorSelectedPermissions);
  //           }
  //         }
  //       }
  //     }
  //   }
  // };
  // const handleAgentPermission = (index, permission, checked) => {
  //   toast.error("Role change not permitted", {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  //   if (location.pathname.includes("profile") === false) {
  //     let adminIndex = adminSelectedPermissions
  //       .map(function (o) {
  //         return o;
  //       })
  //       .indexOf("ALL-PERMISSION");

  //     if (adminIndex > -1) {
  //       adminSelectedPermissions.splice(adminIndex, 1);
  //       adminPermissions[0]["checked"] = false;
  //       setAdminpermissions(adminPermissions);
  //       setAdminSelectedPermissions(adminSelectedPermissions);
  //     }
  //     let Supervisorindex = supervisorSelectedPermissions
  //       .map(function (o) {
  //         return o;
  //       })
  //       .indexOf("SUPERVISOR");

  //     if (Supervisorindex > -1) {
  //       supervisorSelectedPermissions.splice(Supervisorindex, 1);
  //       superVisorPermissions[0]["checked"] = false;
  //       setSuperVisorPermissions(superVisorPermissions);
  //       setSupervisorSelectedPermissions(supervisorSelectedPermissions);
  //     }

  //     var agentPermissionsTemp = [...agentPermissions];
  //     if (permission !== "ACCOUNT-MANAGER") {
  //       if (index === 0) {
  //         if (checked === false) {
  //           setAgentSelectedPermissions((list) => [...list, permission]);
  //           agentPermissionsTemp[index]["checked"] = true;
  //           setAgentPermissions(agentPermissionsTemp);
  //         } else {
  //           agentPermissionsTemp[index]["checked"] = false;
  //           setAgentPermissions(agentPermissionsTemp);
  //           let firstindex = agentSelectedPermissions
  //             .map(function (o) {
  //               return o;
  //             })
  //             .indexOf("AGENT");

  //           if (firstindex > -1) {
  //             agentSelectedPermissions.splice(firstindex, 1);
  //             setAgentSelectedPermissions(agentSelectedPermissions);
  //           }
  //         }
  //       } else {
  //         if (checked === false) {
  //           setAgentSelectedPermissions((list) => [...list, permission]);
  //           agentPermissionsTemp[index]["checked"] = true;
  //           setAgentPermissions(agentPermissionsTemp);
  //         } else {
  //           agentPermissionsTemp[index]["checked"] = false;
  //           setAgentPermissions(agentPermissionsTemp);
  //           const otherIndex = agentSelectedPermissions.indexOf(permission);
  //           if (otherIndex > -1) {
  //             agentSelectedPermissions.splice(otherIndex, 1);
  //             setAgentSelectedPermissions(agentSelectedPermissions);
  //           }
  //         }
  //       }
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (socketResponse !== undefined) {
  //     // console.log(socketResponse);
  //     if (socketResponse.type == "FILE_URL") {
  //       uploadFile();
  //       setAgentImage(socketResponse?.data.filePath);
  //     }
  //   }
  // }, [socketResponse]);

  // const handleFile = (e) => {
  //   var file = e.target.files[0];
  //   var formData = new FormData();
  //   formData.append("file", file);
  //   formData.append("agentPublicId", decryptedValue(auth.agentId));
  //   formData.append("companyId", decryptedValue(auth.companyId));
  //   apiPost(
  //     `/chat/upload-file`,
  //     onSuccessFileUpload,
  //     onFailureFileUpload,
  //     formData
  //   );
  // };
  // const onSuccessFileUpload = (response) => {
  //   if (response.status === 1) {
  //     setFilePath(response.message);
  //   }
  // };
  // const onFailureFileUpload = (error) => {
  //   console.log(error);
  // };

  // useEffect(() => {
  //   if (filePath !== "") {
  //     fetchUrl(filePath);
  //   }
  // }, [filePath]);

  // const uploadFile = () => {
  //   apiGet(
  //     `/agent/profile/upload-pic/${filePath}`,
  //     onSuccessUploadFile,
  //     onFailureUploadFile
  //   );
  // };

  // const onSuccessUploadFile = (response) => {
  //   // setApiCalled(true);
  // };
  // const onFailureUploadFile = (error) => {
  //   console.log(error);
  // };

  // const fetchUrl = () => {
  //   if (socket && socket.readyState === WebSocket.OPEN) {
  //     // console.log("Fetching URL");
  //     const data = {
  //       fileId: filePath,
  //     };
  //     socket.send(JSON.stringify({ type: "get-file-url", data: data }));
  //   }
  // };

  return (
    <div className="adminDetailsWrp">
      <div className="uk-container">
        <div className="backBtnWrp">
          <div className="backBtn">
            <Link to="/super-admin-users">
              <ChevronLeft /> Back to all Super Admin
            </Link>
          </div>
          <div className="dltBtn">
            {/* {location.pathname.includes("profile") === false && ( */}
              <button type="button" onClick={handleOpenAdminDeleteModal}>
                Delete user
              </button>
            {/* )} */}
          </div>
        </div>

        <div className="detailsWrp">
          <div
            className="uk-grid "
            uk-grid=""
            uk-height-match="target: > div > .customStyle"
          >
            <div className="uk-width-1-3">
              <div className="customStyle">
                <div className="detailContent adminDetailBoxes heightNew">
                  <div>
                    <div className="adminImg">
                      <span className="InitialImage">A</span>
                      {/* <img src={chatBaseUrl + agentImage} alt="" /> */}
                      <div
                        uk-form-custom=""
                        className="profileImgUpload uk-form-custom"
                      >
                        <input
                          type="file"
                          aria-label="Custom controls"
                          accept="image/*"
                        />
                        <button type="button">
                          <Camera />
                        </button>
                      </div>
                    </div>
                    <div className="onlineDropDownWrp">
                      <span className={"markOption onlineMark"}></span>
                      <p className="agentStatusName">Online</p>
                      <ChevronDown />
                      <div uk-dropdown="mode: click" className="navDropDown">
                        <ul className="uk-nav uk-dropdown-nav">
                          <li>
                            <span> User status</span>
                          </li>
                          <li className="agentStatus">
                            <div id="agentUpdate1" className="active overview_id">
                              <span className="availableStatus cursor">
                                <span className="filtername">ONLINE</span>
                              </span>
                            </div>
                            <div id="agentUpdate1" className="active overview_id">
                              <span className="awayStatus cursor">
                                <span className="filtername">AWAY</span>
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="numberInput namewrp">
                      <form action="">
                        <input
                          type="text"
                          // placeholder="Name"
                          value={"Deniz Khan"}
                          disabled
                        />
                        {/* <button type="button">
                          <Edit />
                        </button> */}
                      </form>
                    </div>
                    <div className="numberInput">
                      <form action="">
                        <input
                          type="text"
                          // placeholder="Role"
                          defaultValue={"Console Admin"}
                          readOnly
                          disabled
                        />
                        {/* <button type="button">
                          <Edit />
                        </button> */}
                      </form>
                    </div>
                    <div className="numberInput">
                      <form action="">
                        <input
                          type="text"
                          // placeholder="Enter phone number"
                          defaultValue={"Enter phone number"}
                          disabled
                        />
                        {/* <button type="button">
                          <Edit />
                        </button> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-2-3">
              <div className="customStyle">
                <div className="userProfile adminDetailBoxes heightNew">
                  {/* {userDetails?.lastLogin === undefined ? (
                      <div className="dataNotFound">
                        {/* {details === null ? <div uk-spinner=""></div> : "Data Not Found"} 
                        <div uk-spinner=""></div>
                      </div> */}

                  <div>
                    <div className="boxHeading">
                      <span>User profile</span>
                    </div>
                    <div className="contentBox">
                      <div className="contentBoxHeading">
                        <span>This week usage summary</span>
                      </div>
                      <div className="txtwrp">
                        <div className="leftTxt">
                          <p>
                            Available minutes <span>127</span>
                          </p>
                        </div>
                        <div className="leftTxt">
                          <p>
                            Away minutes <span>283</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="contentBox">
                      <div className="contentBoxHeading">
                        <span>Account information</span>
                      </div>
                      <div className="txtwrp">
                        <div className="leftTxt">
                          <p>
                            <Purchase /> Last login <span>14/07/2022</span>
                          </p>
                        </div>
                        <div className="leftTxt">
                          <p>
                            <Locked /> Last password change{" "}
                            <span>14/07/2022</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="contentBox">
                      <div className="txtwrp">
                        <div className="leftTxt">
                          <p>
                            Enabled user <span>14/07/2022 at 07:55 PM</span>
                          </p>
                        </div>
                        <div className="leftTxt">
                          <p>
                            Created by <span>Console</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="adminDetailTabbing">
          <ul uk-tab="" className="tabBtn">
            <li>
              <a href="/">Details</a>
            </li>
          </ul>

          <ul className="uk-switcher uk-margin">
            <li>
              <div className="detailFormwrp">
                <form>
                  <div className="uk-grid uk-grid-small" uk-grid="">
                    <div className="uk-width-1-2">
                      <div className="formInput">
                        <label htmlFor="f_name">First name</label>
                        <input
                          className="uk-input"
                          type="text"
                          placeholder="Fist name"
                          defaultValue={"Deniz"}
                          // {...register(`firstName`, {
                          //   required: true,
                          // })}
                        />
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <div className="formInput">
                        <label htmlFor="f_name">Last name</label>
                        <input
                          className="uk-input"
                          type="text"
                          placeholder="Last name"
                          defaultValue={"Khan"}
                          // {...register(`lastName`, {
                          //   required: true,
                          // })}
                          // onChange={(e) =>
                          //   setSubmittedData({
                          //     ...submittedData,
                          //     lastName: e.target.value,
                          //   })
                          // }
                        />
                        {/* {errors[`lastName`] && (
                  <div className="formErrors">{`Last name is required.`}</div>
                )} */}
                        {/* {errorEnable && submittedData.lastName === "" && (
                            <div className="formErrors">
                              last name is required.
                            </div>
                          )} */}
                      </div>
                    </div>
                    {/* <div className="uk-width-1-2">
              <div className="formInput">
                <label htmlFor="f_name">Work number</label>
                <input
                  type="text"
                  placeholder="Work number"
                  defaultValue={submittedData?.phoneNumberWork}
                  onChange={(e) =>
                    setSubmittedData({
                      ...submittedData,
                      phoneNumberWork: e.target.value,
                    })
                  }
                />
                {errors[`phoneNumberWork`] && (
                  <div className="formErrors">{`Work number is required.`}</div>
                )}
                {errorEnable &&
                  (submittedData.phoneNumberWork === "" ||
                    submittedData.phoneNumberWork === null) && (
                    <div className="formErrors">Work number is required.</div>
                  )}
              </div>
            </div> */}
                    <div className="uk-width-1-2">
                      <div className="formInput">
                        <label htmlFor="team">Team </label>
                        {/* <select
                  className="uk-select"
                  name="team"
                  onChange={(e) => {
                    setSubmittedData({
                      ...submittedData,
                      team: e.target.value,
                    });
                  }}
                >
                  <option value="" disabled selected>
                    {submittedData?.team?.name
                      ? submittedData?.team?.name
                      : "Select a team..."}
                  </option>

                  {teams?.map((val, index) => {
                    return (
                      <option value={val.name} key={index}>
                        {val.name}
                      </option>
                    );
                  })}
                </select>
                
                 */}

                        <select
                          className="uk-select"
                          name="team"
                          // onChange={(e) => {
                          //   const selectedTeamName = e.target.value;
                          //   const selectedTeam = teams.find(
                          //     (team) => team.name === selectedTeamName
                          //   );

                          // Check if a team with the selected name was found
                          //   if (selectedTeam) {
                          //     // Set the selected team's publicId in the submittedData
                          //     setSubmittedData({
                          //       ...submittedData,
                          //       team: selectedTeam.publicId,
                          //     });
                          //   }
                          // }}
                        >
                          <option value="" disabled selected>
                            {/* {userDetails?.team?.name
                                ? userDetails?.team?.name
                                                          : "Select a team..."} */}
                            Select a team...
                          </option>
                          <option>Test</option>

                          {/* {teams?.map((val, index) => {
                              return (
                                <option value={val.name} key={index}>
                                  {val.name}
                                </option>
                              );
                            })} */}
                        </select>

                        {/* {submittedData.team === "" && errorEnable && (
                      <div className="formErrors">Please select team</div>
                    )} */}
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <div className="formInput">
                        <label htmlFor="f_name">Mobile Number</label>
                        <input
                          type="number"
                          className="uk-input"
                          placeholder="Mobile Number"
                          defaultValue={"0316 0266839"}
                          // {...register(`phoneNumberPersonal`, {
                          //   required: true,
                          // })}
                          // onChange={(e) => {
                          //   let error = validatePhoneNumber(e.target.value);
                          //   setIsNumberValid(error);
                          //   setSubmittedData({
                          //     ...submittedData,
                          //     // phoneNumberPrivate: e.target.value,
                          //     phoneNumberPrivate: e.target.value,
                          //   });
                          // }}
                        />
                        {/* {errors[`phoneNumberPersonal`] && (
                  <div className="formErrors">{`Mobile Number is required.`}</div>
                )} */}
                        {/* {errorEnable && submittedData.phoneNumberPrivate === "" && ( */}
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <div className="formInput">
                        <label htmlFor="agent">
                          Country<span>*</span>
                        </label>
                        <select
                          name="country"
                          id="country"
                          className="uk-select"

                          // defaultValue={
                          //   submittedData !== undefined ? submittedData?.country : ""
                          // }
                        >
                          <option value="" selected disabled>
                            Select a country
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <div className="formInput">
                        <label htmlFor="agent">
                          City<span>*</span>
                        </label>
                        <select
                          name="country"
                          id="country"
                          className="uk-select"

                          // defaultValue={
                          //   submittedData !== undefined ? submittedData?.country : ""
                          // }
                        >
                          <option value="" selected disabled>
                            Select a city
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <div className="formInput">
                        <label htmlFor="f_name">Email</label>
                        <input
                          className="uk-input"
                          type="text"
                          placeholder="Email"
                          defaultValue={"deniz.khan@convexinteractive.com"}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <div className="formInput">
                        <label htmlFor="f_name">Password changed</label>
                        <input
                          className="uk-input"
                          type="text"
                          value={"Enter your password"}
                          disabled
                        />
                      </div>
                      <div className="changePassBtn">
                        <button
                          type="button"
                          onClick={handleOpenChangePasswordModal}
                        >
                          Change passwords
                        </button>
                      </div>
                    </div>

                    <div className="uk-width-1-1">
                      <div className="adminDetailBtn">
                        <button type="submit" className="btn-2">
                          Cancel
                        </button>
                        <button
                          type="submit"
                          // onClick={updateAgent}
                          className="btn-1 w-90"
                        >
                          Save
                          {/* {loader ? (
                              <div uk-spinner="" className="loader"></div>
                            ) : (
                              "Save"
                            )} */}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {openChangePasswordModal && (
        <ChangePassword closeModal={handleCloseChangePasswordModal} />
      )}

      {openAdminDeleteModal && (
        <DeleteModal
          closeModal={handleCloseAdminDeleteModal}
          dltModalTxt="Are you sure you want to delete this super admin? This will affect the company and team members of selected super admin."
          // deleteTeam={deleteUser}
          dltLoader={dltLoader}
          heading={"Delete User"}
        />
      )}


    </div>
  );
};

export default Profile;
