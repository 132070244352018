import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  formatText,
  sanitizedMessage,
} from "../../../Utils/chat/chatFunctions";
import {
  convertTimestamp,
  convertTimestampLongFormat,
} from "../../../Utils/DateFunctions";
// import post from "../../../assets/images/post.gif";
import post from "../../../assets/images/post.png";

const FacebookCommentContacts = ({ fbContacts, getComments }) => {
  return (
    <div className="uk-width-1-5 custom-class">
      {fbContacts === undefined ? (
        <div className="loaderScreen">
          <div uk-spinner="" className="loader"></div>
        </div>
      ) : (
        <div className="chat-sec">
          {fbContacts === undefined ? (
            <div className="loaderScreen">
              <div uk-spinner="" className="loader"></div>
            </div>
          ) : (
            <div className="chatContactsContainer" id="threadList">
              <ul>
                <InfiniteScroll
                  style={{ overflow: "none" }}
                  dataLength={fbContacts?.length}
                  // next={(e) =>
                  //   searchedKey === ""
                  //     ? getAllChatContacts(agentId, chatFilter)
                  //     : onSearch()
                  // }
                  pullDownToRefreshThreshold={20}
                  // hasMore={hasMoreContacts}
                  // hasMore={true}
                  // scrollThreshold={0.9}
                  scrollableTarget="threadList"
                  // className="conversaionsList"
                  // endMessage={<center id="record_not_found"></center>}
                  // loader={<h4>Loading...</h4>}
                >
                  {fbContacts?.map((data, index) => {
                    return (
                      <li
                        className={`all-chats`}
                        //   className={`all-chats ${
                        //     activeChatIndex === data.id ? "activechat" : ""
                        //   }  `}
                        key={index}
                        //   onClick={() => {
                        //     setCompanyWhatsappNumber(
                        //       matchedCompanyColor === false
                        //         ? companyDetails?.whatsappNumber
                        //         : matchedCompanyColor[0].businessNumber
                        //     );
                        //     toggleActiveChat(data.id);
                        //   }}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            //   setPageForChatMessages(0);
                            getComments(data);
                          }}
                        >
                          <div className="iconAndName">
                            <div className="imgwrp">
                              <img src={post} alt="" />
                            </div>
                            {/* <div className="txtwrp uk-margin-left"> */}
                            <div className="txtwrp ">
                              <h6
                              //   className={
                              //     index === activeChatIndex && "ft-weight"
                              //   }
                              >
                                {data.from.name}
                              </h6>
                              <p
                                dangerouslySetInnerHTML={sanitizedMessage(
                                  formatText(data.message)
                                )}
                              ></p>
                            </div>
                          </div>
                          <div className="timeDivWrp">
                            <div className="timediv">
                              <p>
                                {convertTimestampLongFormat(data.created_time)}
                              </p>
                              {/* {data.unReadCount !== 0 &&
                              data.unReadCount !== null && (
                                <span className="chatUnReadCount">
                                  {data.unReadCount}
                                </span>
                              )} */}
                            </div>
                          </div>
                        </button>
                      </li>
                    );
                  })}
                </InfiniteScroll>
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FacebookCommentContacts;
