import React, { useEffect, useState } from "react";
import {
  ChevronDown,
  ChevronLeft,
  Locked,
  Purchase,
  Calendar,
  Download,
  Edit,
  OverflowMenuVertical,
  ChatLaunch,
  Error,
  TrashCan,
} from "@carbon/icons-react";
import { Link, useParams } from "react-router-dom";
import moment from "moment/moment";
import UIkit from "uikit";
import CalendarDate from "../../components/Calendar/CalendarDate";
import whatsappLogo from "../../assets/images/whatsapp-icon-new.jpg";
import messengerIcon from "../../assets/images/messenger-icon-new.jpg";
import instaIcon from "../../assets/images/instagram-icon-new.jpg";
import { decryptedValue } from "../../Utils/RouteHashConversion";
import { apiGet } from "../../Utils/apiServices";
import { dateWithAtTime } from "../../Utils/DateFunctions";

const LiveChatTagsDetail = () => {
  const params = useParams();
  const [channel, setChannel] = useState("ALL");
  const [isSelected, setIsSelected] = useState(false);
  const [STARTDATE, setSTARTDATE] = useState(params.startDate);
  const [ENDDATE, setENDDATE] = useState(params.endDate);
  const [selectedDateFilter, setSelectedDateFilter] = useState("This month");
  const [isApiCall, setIsApiCall] = useState(false);
  const [tagDetails, setTagDetails] = useState(null);

  const handleDatePickerClose = () => {
    if (selectedDateFilter === "Custom range") {
      setIsSelected(true);
    }
    UIkit.drop("#datepickerdrop").hide(true);
  };

  const onChange = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
    // setIsApiCall(true);
  };

  const handleDateFilter = (filter) => {
    setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    if (filter !== "Custom range") {
      setIsApiCall(true);
    }
  };

  const getTagDetail = () => {
    apiGet(
      `/detail-analytics/liveChatTagDetails/${params.id}/${STARTDATE}/${ENDDATE}`,
      onSuccessTagDetail,
      onFailureTagDetail
    );
  };

  const onSuccessTagDetail = (response) => {
    // console.log(response);
    setIsApiCall(false);
    setIsSelected(false);
    setTagDetails(response?.data);
  };

  const onFailureTagDetail = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getTagDetail();
  }, []);

  useEffect(() => {
    if (isApiCall == true) {
      if (selectedDateFilter === "Custom range") {
        if (isSelected === true) {
          getTagDetail();
        }
      } else {
        getTagDetail();
      }
    }
  }, [isApiCall]);

  const filterDropdown = [
    "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];

  const channels = [
    {
      icon: null,
      name: "All Channels",
      apiSendName: "ALL",
      disabled: false,
    },
    // {
    //   icon: whatsAppImg,
    //   name: "WhatsApp",
    //   apiSendName: "WHATSAPP",
    //   disabled: false,
    // },
    // {
    //   icon: messengerIcon,
    //   name: "Facebook",
    //   apiSendName: "FACEBOOK",
    //   disabled: true,
    // },
    // {
    //   icon: instaIcon,
    //   name: "Instagram",
    //   apiSendName: "INSTAGRAM",
    //   disabled: true,
    // },
  ];

  const clearAllStates = () => {
    setTagDetails(null);
  };
  return (
    <div className="adminDetailsWrp">
      <div className="uk-container">
        <div className="backBtnWrp">
          <div className="backBtn">
            <Link to="/live-chat-tags">
              <ChevronLeft /> Back to Live Chat
            </Link>
          </div>
          <div className="btnwrp">
            <button type="button">
              <Download /> Export data
            </button>
          </div>
        </div>
        <div
          className="overviewContent uk-padding-remove"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="uk-container">
            <div className="overviewTopDropdown" style={{ marginTop: "32px" }}>
              <div className="uk-grid uk-flex-middle" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="pageHeading uk-margin-remove-bottom">
                    <h3>{decryptedValue(params?.name)}</h3>
                  </div>
                </div>
                <div className="uk-width-1-2 btnSection">
                  <div className="agentFilterWrp">
                    <div className="uk-inline">
                      <button className="calenderBtn rangeBtn">
                        <Calendar /> {selectedDateFilter}
                        <span className="uk-inline">
                          <ChevronDown />
                        </span>
                      </button>
                      <div
                        uk-dropdown="mode: click"
                        className="calendarDropdown"
                        id="dateDropdownSection"
                      >
                        <div className="dropDownHeading">
                          <span>Select a range</span>
                        </div>
                        <ul>
                          {filterDropdown.map((val, index) => {
                            return (
                              <React.Fragment key={index}>
                                <li
                                  className={
                                    selectedDateFilter === val
                                      ? "activeBtn"
                                      : ""
                                  }
                                  onClick={() => {
                                    if (val !== "Custom range") {
                                      clearAllStates();
                                    }
                                    handleDateFilter(val);
                                  }}
                                >
                                  {filterDropdown.length - 1 === index ? (
                                    <div className="uk-inline uk-text-left">
                                      <button
                                        className="rangeBtn"
                                        type="button"
                                      >
                                        <span className="rangeBtnCircle"></span>{" "}
                                        Custom range
                                      </button>
                                      <div
                                        id="datepickerdrop"
                                        uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                        className="date-picker-container"
                                      >
                                        <CalendarDate onChange={onChange} />
                                        <div className="date-picker-btn-container uk-text-right">
                                          <button
                                            className="date-selection-btn"
                                            onClick={handleDatePickerClose}
                                          >
                                            Ok
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <button type="button" className="rangeBtn">
                                      <span className="rangeBtnCircle"></span>{" "}
                                      {val}
                                    </button>
                                  )}
                                </li>
                                {filterDropdown.length - 2 === index && (
                                  <hr style={{ margin: "5px 0" }} />
                                )}
                              </React.Fragment>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <div className="overviewMainContent">
                    <div className="mainBox">
                      <div className="boxHeading">
                        <span>Live chat breakdown by conversations</span>
                      </div>
                      <div className="boxContent">
                        <div className="uk-grid" uk-grid="">
                          <div className="uk-width-1-6 uk-first-column">
                            <div className="txtwrp">
                              <h2>
                                {tagDetails === null ? (
                                  <div uk-spinner=""></div>
                                ) : (
                                  tagDetails?.totalChats
                                )}
                              </h2>
                              <span>Total</span>
                            </div>
                          </div>
                          <div className="uk-width-1-6">
                            <div className="txtwrp">
                              <h2>
                                {tagDetails === null ? (
                                  <div uk-spinner=""></div>
                                ) : (
                                  tagDetails?.openChats
                                )}
                              </h2>
                              <span>Open</span>
                            </div>
                          </div>
                          <div className="uk-width-1-6">
                            <div className="txtwrp">
                              <h2>
                                {tagDetails === null ? (
                                  <div uk-spinner=""></div>
                                ) : (
                                  tagDetails?.waitingChats
                                )}
                              </h2>
                              <span>Waiting</span>
                            </div>
                          </div>
                          <div className="uk-width-1-6">
                            <div className="txtwrp">
                              <h2>
                                {tagDetails === null ? (
                                  <div uk-spinner=""></div>
                                ) : (
                                  tagDetails?.solvedChats
                                )}
                              </h2>
                              <span>Solved</span>
                            </div>
                          </div>
                          <div className="uk-width-1-6">
                            <div className="txtwrp">
                              <h2>
                                {tagDetails === null ? (
                                  <div uk-spinner=""></div>
                                ) : (
                                  tagDetails?.closedChats
                                )}
                              </h2>
                              <span>Closed</span>
                            </div>
                          </div>
                          <div className="uk-width-1-6">
                            <div className="txtwrp">
                              <h2>
                                {tagDetails === null ? (
                                  <div uk-spinner=""></div>
                                ) : (
                                  tagDetails?.unassignedChats
                                )}
                              </h2>
                              <span>Unassigned</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="liveAgentTable"
                      style={{ marginTop: "40px" }}
                    >
                      <div className="searchFilter">
                        <form action="">
                          <input
                            type="text"
                            placeholder="Search by number, name"
                          />
                        </form>
                      </div>
                      <div className="analyticsTableWrp">
                        <table className="uk-table">
                          <thead>
                            <tr>
                              <th>CHANNEL</th>
                              <th>NUMBER</th>
                              <th>NAME</th>

                              <th>GENDER</th>
                              <th>EMAIL</th>
                              <th>CITY</th>
                              <th>STATUS</th>
                              <th>CREATED</th>
                              <th>LAST CONTACT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tagDetails?.contactList.length > 0 ? (
                              tagDetails?.contactList.map((val, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <img
                                        src={
                                          val.chanelId === "WHATSAPP"
                                            ? whatsappLogo
                                            : val.chanelId === "FACEBOOK"
                                            ? messengerIcon
                                            : val.chanelId === "INSTAGRAM"
                                            ? instaIcon
                                            : whatsappLogo
                                        }
                                        alt=""
                                      />
                                    </td>
                                    <td>{val.mobileNumber}</td>
                                    <td>{val.firstName}</td>
                                    <td>{val.gender}</td>
                                    <td>{val.email}</td>
                                    <td>{val.city}</td>
                                    <td>{val.status}</td>
                                    <td>
                                      {dateWithAtTime(val.createDateTime)}
                                    </td>
                                    <td>{dateWithAtTime(val.lastContacted)}</td>
                                    {/* <td>
                                <div className="uk-inline">
                                  <button type="button">
                                    <OverflowMenuVertical />
                                  </button>
                                  <div
                                    uk-dropdown=""
                                    className="contactBlckDrop"
                                  >
                                    <ul>
                                      <li>
                                        <button type="button">
                                          <Edit /> Edit
                                        </button>
                                      </li>

                                      <li>
                                        <button type="button">
                                          <ChatLaunch /> View chat
                                        </button>
                                      </li>
                                      <li>
                                        <button type="button">
                                          <Error /> Block contact
                                        </button>
                                      </li>
                                      <li>
                                        <button type="button">
                                          <Download /> Download data
                                        </button>
                                      </li>
                                      <hr />
                                      <li>
                                        <button
                                          className="trashBtn"
                                          type="button"
                                        >
                                          <TrashCan /> Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td> */}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={12} className="dataNotFound">
                                  {tagDetails === null ? (
                                    <div uk-spinner=""></div>
                                  ) : (
                                    "Data Not Found"
                                  )}
                                </td>
                              </tr>
                            )}
                            {/* <tr>
                              <td>
                                <img src={whatsappLogo} alt="" />
                              </td>
                              <td>0300 2681869</td>
                              <td>Naveen Asim</td>
                              <td>Female</td>
                              <td>naveenasim45@gmail.com</td>
                              <td>Karachi</td>
                              <td>Connected</td>
                              <td>20 Jun</td>
                              <td>18 h ago</td>
                              <td>
                                <div className="uk-inline">
                                  <button type="button">
                                    <OverflowMenuVertical />
                                  </button>
                                  <div
                                    uk-dropdown=""
                                    className="contactBlckDrop"
                                  >
                                    <ul>
                                      <li>
                                        <button type="button">
                                          <Edit /> Edit
                                        </button>
                                      </li>

                                      <li>
                                        <button type="button">
                                          <ChatLaunch /> View chat
                                        </button>
                                      </li>
                                      <li>
                                        <button type="button">
                                          <Error /> Block contact
                                        </button>
                                      </li>
                                      <li>
                                        <button type="button">
                                          <Download /> Download data
                                        </button>
                                      </li>
                                      <hr />
                                      <li>
                                        <button
                                          className="trashBtn"
                                          type="button"
                                        >
                                          <TrashCan /> Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <img src={whatsappLogo} alt="" />
                              </td>
                              <td>0300 2681869</td>
                              <td>Naveen Asim</td>
                              <td>Female</td>
                              <td>naveenasim45@gmail.com</td>
                              <td>Karachi</td>
                              <td>Connected</td>
                              <td>20 Jun</td>
                              <td>18 h ago</td>
                              <td>
                                <div className="uk-inline">
                                  <button type="button">
                                    <OverflowMenuVertical />
                                  </button>
                                  <div
                                    uk-dropdown=""
                                    className="contactBlckDrop"
                                  >
                                    <ul>
                                      <li>
                                        <button type="button">
                                          <Edit /> Edit
                                        </button>
                                      </li>

                                      <li>
                                        <button type="button">
                                          <ChatLaunch /> View chat
                                        </button>
                                      </li>
                                      <li>
                                        <button type="button">
                                          <Error /> Block contact
                                        </button>
                                      </li>
                                      <li>
                                        <button type="button">
                                          <Download /> Download data
                                        </button>
                                      </li>
                                      <hr />
                                      <li>
                                        <button
                                          className="trashBtn"
                                          type="button"
                                        >
                                          <TrashCan /> Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <img src={whatsappLogo} alt="" />
                              </td>
                              <td>0300 2681869</td>
                              <td>Naveen Asim</td>
                              <td>Female</td>
                              <td>naveenasim45@gmail.com</td>
                              <td>Karachi</td>
                              <td>Connected</td>
                              <td>20 Jun</td>
                              <td>18 h ago</td>
                              <td>
                                <div className="uk-inline">
                                  <button type="button">
                                    <OverflowMenuVertical />
                                  </button>
                                  <div
                                    uk-dropdown=""
                                    className="contactBlckDrop"
                                  >
                                    <ul>
                                      <li>
                                        <button type="button">
                                          <Edit /> Edit
                                        </button>
                                      </li>

                                      <li>
                                        <button type="button">
                                          <ChatLaunch /> View chat
                                        </button>
                                      </li>
                                      <li>
                                        <button type="button">
                                          <Error /> Block contact
                                        </button>
                                      </li>
                                      <li>
                                        <button type="button">
                                          <Download /> Download data
                                        </button>
                                      </li>
                                      <hr />
                                      <li>
                                        <button
                                          className="trashBtn"
                                          type="button"
                                        >
                                          <TrashCan /> Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <img src={whatsappLogo} alt="" />
                              </td>
                              <td>0300 2681869</td>
                              <td>Naveen Asim</td>
                              <td>Female</td>
                              <td>naveenasim45@gmail.com</td>
                              <td>Karachi</td>
                              <td>Connected</td>
                              <td>20 Jun</td>
                              <td>18 h ago</td>
                              <td>
                                <div className="uk-inline">
                                  <button type="button">
                                    <OverflowMenuVertical />
                                  </button>
                                  <div
                                    uk-dropdown=""
                                    className="contactBlckDrop"
                                  >
                                    <ul>
                                      <li>
                                        <button type="button">
                                          <Edit /> Edit
                                        </button>
                                      </li>

                                      <li>
                                        <button type="button">
                                          <ChatLaunch /> View chat
                                        </button>
                                      </li>
                                      <li>
                                        <button type="button">
                                          <Error /> Block contact
                                        </button>
                                      </li>
                                      <li>
                                        <button type="button">
                                          <Download /> Download data
                                        </button>
                                      </li>
                                      <hr />
                                      <li>
                                        <button
                                          className="trashBtn"
                                          type="button"
                                        >
                                          <TrashCan /> Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <img src={whatsappLogo} alt="" />
                              </td>
                              <td>0300 2681869</td>
                              <td>Naveen Asim</td>
                              <td>Female</td>
                              <td>naveenasim45@gmail.com</td>
                              <td>Karachi</td>
                              <td>Connected</td>
                              <td>20 Jun</td>
                              <td>18 h ago</td>
                              <td>
                                <div className="uk-inline">
                                  <button type="button">
                                    <OverflowMenuVertical />
                                  </button>
                                  <div
                                    uk-dropdown=""
                                    className="contactBlckDrop"
                                  >
                                    <ul>
                                      <li>
                                        <button type="button">
                                          <Edit /> Edit
                                        </button>
                                      </li>

                                      <li>
                                        <button type="button">
                                          <ChatLaunch /> View chat
                                        </button>
                                      </li>
                                      <li>
                                        <button type="button">
                                          <Error /> Block contact
                                        </button>
                                      </li>
                                      <li>
                                        <button type="button">
                                          <Download /> Download data
                                        </button>
                                      </li>
                                      <hr />
                                      <li>
                                        <button
                                          className="trashBtn"
                                          type="button"
                                        >
                                          <TrashCan /> Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveChatTagsDetail;
