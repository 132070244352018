import {
  Camera,
  ChevronLeft,
  Edit,
  Purchase,
  TrashCan,
  OverflowMenuVertical,
  ChevronDown,
} from "@carbon/icons-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import DeleteModal from "./components/Modal/DeleteModal";
import TagModal from "../chat/components/Modals/TagModal";
import { useEffect } from "react";
import { apiDelete, apiGet, apiPost } from "../../Utils/apiServices";
import { toast } from "react-toastify";
import { useAuth } from "../../Context/AuthProvider";
import { convertTimestamp, dateWithAtTime } from "../../Utils/DateFunctions";
import { decryptedValue, encryptedValue } from "../../Utils/RouteHashConversion";

const TeamDetail = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteModalForTeamMember, setOpenDeleteModalForTeamMember] =
    useState(false);
  const [openTagModal, setOpenTagModal] = useState(false);
  const [teamDetail, setTeamDetail] = useState();
  const [teamMember, setTeamMember] = useState();
  const [dltLoader, setDltLoader] = useState(false);
  const [dltLoaderForTeamMember, setDltLoaderForTeamMember] = useState(false);
  const [addLoader, setAddLoader] = useState(false);
  const [teamListApiCall, setTeamListApiCall] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [deletedTeamPublicId, setDeletedTeamPublicId] = useState();

  const [userList, setuserList] = useState();
  const [multiSelectUsers, setMultiSelectUsers] = useState();
  const [loader, setLoader] = useState(false);
  const [errorEnable, setErrorEnable] = useState(false);
  const [submittedData, setSubmittedData] = useState({
    name: teamDetail?.name,
    description: teamDetail?.description,
  });
  const [multiSelectAlreadySelectedUsers, setMultiSelectAlreadySelectedUsers] =
    useState();
  const params = useParams();

  const updateAgent = (e) => {
    e.preventDefault();
    setErrorEnable(true);
    setLoader(true);
    let data = {
      name: submittedData?.name,
      description: submittedData?.description,
      publicId: (decryptedValue(params?.publicId)),
    };
    if (
      submittedData?.name !== "" &&
      submittedData?.description !== "" &&
      params?.publicId !== ""
    ) {
      apiPost(
        `/agent/team/edit`,
        onSuccessAgentUpdate,
        onFailureAgentUpdate,
        data
      );
    } else {
      setLoader(false);
    }
  };
  const onSuccessAgentUpdate = (response) => {
    setLoader(false);
    toast.success("Team updated successfully ", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
    setTimeout(() => {
      navigate("/teams");
    }, 1000);
  };
  const onFailureAgentUpdate = (error) => {
    console.log(error);
    setLoader(false);
  };

  const deleteTeam = () => {
    setDltLoader(true);
    apiDelete(`/agent/team/${decryptedValue(params?.publicId)}`, onSuccess, onFailure);
  };
  const onSuccess = (response) => {
    if (response?.data === true) {
      setTimeout(() => {
        setDltLoader(false);
        toast.success("Team deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1200);
      setTimeout(() => {
        navigate("/teams", { replace: true });
        handleCloseDeleteModal();
      }, 2000);
    }
  };
  const onFailure = (error) => {
    console.log(error);
    setDltLoader(false);
  };

  const getUserList = () => {
    apiGet(
      `/agent/list/AGENT`,
      onSuccessUserList,
      onFailureUserList
    );
  };
  const onSuccessUserList = (response) => {
    setuserList(response?.data);
  };
  const onFailureUserList = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getUserList();
  }, []);
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleOpenTeamMemberDeleteModal = (publicId) => {
    setDeletedTeamPublicId(publicId);
    setOpenDeleteModalForTeamMember(true);
  };

  const handleCloseTeamMemberDeleteModal = () => {
    setOpenDeleteModalForTeamMember(false);
  };

  const handleOpenTagModal = () => {
    setOpenTagModal(true);
  };

  const handleCloseTagModal = () => {
    setOpenTagModal(false);
  };

  const getTeamMembers = () => {
    apiGet(
      `/agent/list-by-team/${decryptedValue(params?.publicId)}`,
      onSuccessTeam,
      onFailureTeam
    );
  };
  const onSuccessTeam = (response) => {
    setTeamMember(response?.data);
  };
  const onFailureTeam = (error) => {
    console.log(error);
  };
  const getTeamDetail = () => {
    apiGet(
      `/agent/team/${decryptedValue(params?.publicId)}`,
      onSuccessTeamDetail,
      onFailureTeamDetail
    );
  };
  const onSuccessTeamDetail = (response) => {
    setTeamDetail(response?.data);
  };
  const onFailureTeamDetail = (error) => {
    console.log(error);
  };
  useEffect(() => {
    getTeamMembers();
    getTeamDetail();
  }, []);

  const requiredUserMuliSeelctOptions = (userList) => {
    const updatedArray = userList?.map((element) => ({
      ...element,
      name: element?.firstName + " " + element.lastName,
    }));
    setMultiSelectUsers(updatedArray);
  };
  const requiredUserAlreadyMuliSeelctOptions = (teamMember) => {
    const updatedArray = teamMember?.map((element) => ({
      ...element,
      name: element?.firstName + " " + element.lastName,
    }));
    setMultiSelectAlreadySelectedUsers(updatedArray);
  };

  useEffect(() => {
    setSubmittedData({
      firstName: teamDetail?.firstName,
      lastName: teamDetail?.lastName,
      name: teamDetail?.name,
      description: teamDetail?.description,
    });
  }, [teamDetail]);

  useEffect(() => {
    if (userList?.length > 0) {
      requiredUserMuliSeelctOptions(userList);
    }
  }, [userList]);
  useEffect(() => {
    if (teamMember?.length > 0) {
      requiredUserAlreadyMuliSeelctOptions(teamMember);
    }
  }, [teamMember]);
  const addTeamMembers = (e) => {
    e.preventDefault();
    setAddLoader(true);
    let data = {
      agentList: userOptions,
      teamId: decryptedValue(params.publicId),
    };
    apiPost(`/agent/team/add`, onSuccessAddTeam, onFailureAddTeam, data);
  };
  const onSuccessAddTeam = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setAddLoader(false);
        toast.success("Team memeber added successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1200);

      setTimeout(() => {
        setTeamListApiCall(true);
        handleCloseTagModal();
      }, 2000);
    }
  };
  const onFailureAddTeam = (error) => {
    console.log(error);
    setAddLoader(false);
  };

  useEffect(() => {
    if (teamListApiCall === true) {
      getTeamMembers();
    }
  }, [teamListApiCall]);

  const deleteTeamMember = () => {
    setDltLoaderForTeamMember(true);
    apiDelete(
      `/agent/team/delete/${decryptedValue(params.publicId)}/${deletedTeamPublicId}`,
      onSuccessDlt,
      onFailureDlt
    );
  };
  const onSuccessDlt = (response) => {
    if (response?.data === true) {
      setTimeout(() => {
        setDltLoaderForTeamMember(false);
        toast.success("Team member deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1200);
      setTimeout(() => {
        setTeamListApiCall(true);
        handleCloseTeamMemberDeleteModal();
      }, 2000);
    }
  };
  const onFailureDlt = (error) => {
    console.log(error);
    setDltLoaderForTeamMember(false);
  };

  return (
    <div className="adminDetailsWrp">
      <div className="uk-container">
        <div className="backBtnWrp">
          <div className="backBtn">
            <Link to="/teams">
              <ChevronLeft /> Back to all teams
            </Link>
          </div>
          <div className="dltBtn">
            <button type="button" onClick={handleOpenDeleteModal}>
              Delete team
            </button>
          </div>
        </div>
        <div className="detailsWrp">
          <div
            className="uk-grid "
            uk-grid=""
            uk-height-match="target: > div > .customStyle"
          >
            <div className="uk-width-1-3">
              <div className="customStyle">
                <div className="detailContent adminDetailBoxes userProfile">
                  <div className="adminImg">
                    <span className="singleItemImg">
                      {teamDetail?.name?.substring(0, 1)}
                    </span>

                    {/* <button type="button">
                      <Camera />
                    </button> */}
                  </div>
                  <div className="numberInput namewrp">
                    <form action="">
                      <input
                        type="text"
                        placeholder="Name"
                        defaultValue={teamDetail?.name}
                        onChange={(e) =>
                          setSubmittedData({
                            ...submittedData,
                            name: e.target.value,
                          })
                        }
                      />
                      <button type="button">
                        <Edit />
                      </button>

                      <textarea
                        name=""
                        defaultValue={teamDetail?.description}
                        placeholder="Description"
                        onChange={(e) =>
                          setSubmittedData({
                            ...submittedData,
                            description: e.target.value,
                          })
                        }
                      ></textarea>
                      <button type="button">
                        <Edit />
                      </button>
                    </form>
                  </div>

                  <div className="btnwrp">
                    {/* <button className="btn-1" type="submit">
                      Cancel
                    </button> */}
                    <button
                      className="btn-2 w-70"
                      type="submit"
                      onClick={updateAgent}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-2-3">
              <div className="customStyle">
                <div className="userProfile adminDetailBoxes">
                  <div className="boxHeading">
                    <span>Team profile</span>
                  </div>
                  <div className="contentBox">
                    <div className="contentBoxHeading">
                      <span>Routes (coming soon)</span>
                    </div>
                    <div className="txtwrp">
                      <div className="leftTxt">
                        <p>
                          No. of routes <span>-</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="contentBox">
                    <div className="contentBoxHeading">
                      <span>Team information</span>
                    </div>
                    <div className="txtwrp">
                      <div className="leftTxt">
                        <p>
                          <Purchase /> Number of users
                          <span> {teamMember?.length}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="contentBox">
                    <div className="txtwrp" style={{ display: "block" }}>
                      <div className="leftTxt uk-margin-small-bottom">
                        <p>
                          Enabled team{" "}
                          <span>
                            {dateWithAtTime(teamDetail?.createDateTime)}
                          </span>
                        </p>
                      </div>
                      <div className="leftTxt">
                        <p>
                          Created by <span>Admin</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="adminDetailTabbing">
          <div className="uk-grid" uk-grid="">
            <div className="uk-width-1-2">
              <ul uk-tab="connect: .singleTeamTable" className="tabBtn">
                <li>
                  <a href="/">Team members</a>
                </li>
              </ul>
            </div>
            <div className="uk-width-1-2">
              <div className="addMemberBtn">
                <button type="button" onClick={handleOpenTagModal}>
                  Add members
                </button>
              </div>
            </div>
          </div>

          <ul className="uk-switcher uk-margin singleTeamTable">
            <li>
              <div className="userTableWrp">
                <table className="uk-table">
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>NUMBER</th>
                      <th>EMAIL</th>
                      <th>ROLE</th>
                      <th>
                        <div className="uk-inline tableDropDown">
                          <button type="button">
                            STATUS <ChevronDown />
                          </button>
                          <div
                            uk-dropdown="mode: click"
                            className="dropdownContent"
                          >
                            <div className="dropdownHeading">
                              <span>User status</span>
                            </div>
                            <ul>
                              <li
                                className="active"
                                style={{ paddingLeft: "25px" }}
                              >
                                <button type="button">Available</button>
                              </li>
                              <li style={{ paddingLeft: "25px" }}>
                                <button type="button">Away</button>
                              </li>
                              <li style={{ paddingLeft: "25px" }}>
                                <button type="button">Busy</button>
                              </li>
                              <li style={{ paddingLeft: "25px" }}>
                                <button type="button">offline</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </th>
                      <th>LAST LOGIN</th>
                    </tr>
                  </thead>
                  <tbody>
                    {teamMember?.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td className="agnetName">
                            <Link to={`/user-details/${encryptedValue(val.publicId)}`}>
                              <span className="userInitital">{val.firstName.charAt(0)}</span>
                              {val.firstName + " " + val.lastName}
                            </Link>
                          </td>
                          <td>{val?.phoneNumberPersonal}</td>
                          <td>{val?.email}</td>
                          <td>{val?.role}</td>
                          <td>
                            <span className="available">{val?.status}</span>
                          </td>
                          {/* <td>{val?.updateDateTime}</td> */}
                          <td>
                            {val?.updateDateTime === null
                              ? "-"
                              : convertTimestamp(val?.updateDateTime)}
                          </td>
                          <td>
                            <div className="uk-inline">
                              <button type="button">
                                <OverflowMenuVertical />
                              </button>
                              <div uk-dropdown="" className="">
                                <div className="tableDropDown">
                                  <ul>
                                    <li>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          navigate(
                                            `/user-details/${encryptedValue(val.publicId)}`,
                                            { replace: true }
                                          )
                                        }
                                      >
                                        <Edit /> Edit
                                      </button>
                                    </li>
                                    <hr />
                                    <li>
                                      <button
                                        className="trashBtn"
                                        type="button"
                                        onClick={() =>
                                          handleOpenTeamMemberDeleteModal(
                                            val?.publicId
                                          )
                                        }
                                      >
                                        <TrashCan /> Delete
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {openDeleteModal && (
        <DeleteModal
          closeModal={handleCloseDeleteModal}
          // dltModalTxt="This will affect users who are Creative Team members of selected teams."
          dltModalTxt="Delete the team"
          deleteTeam={deleteTeam}
          dltLoader={dltLoader}
          heading={"Delete Team"}
        />
      )}
      {openTagModal && (
        <TagModal
          closeModal={handleCloseTagModal}
          heading={"Add team"}
          addUser={addTeamMembers}
          addLoader={addLoader}
          setUserOptions={setUserOptions}
          fieldLabel={"Type to add team members"}
          saveBtnTxt={"Add"}
          clearBtn={"Clear team"}
          multiSelectUsers={multiSelectUsers}
          multiSelectAlreadySelectedUsers={multiSelectAlreadySelectedUsers}
          setMultiSelectAlreadySelectedUsers={
            setMultiSelectAlreadySelectedUsers
          }
        />
      )}
      {openDeleteModalForTeamMember && (
        <DeleteModal
          closeModal={handleCloseTeamMemberDeleteModal}
          dltModalTxt="This will affect users who are Creative Team members of selected teams."
          deleteTeam={deleteTeamMember}
          dltLoader={dltLoaderForTeamMember}
          heading={"Delete User"}
        />
      )}
    </div>
  );
};

export default TeamDetail;
