import React from "react";

const AccountNotify = ({ companyDetails }) => {
  return companyDetails?.isDisable === true ? (
    <div className="accountNotify">{companyDetails?.disableReason + "!!"}</div>
  ) : (
    <></>
  );
};

export default AccountNotify;
