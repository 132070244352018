import React, { useState } from "react";
import {
  ChevronDown,
  ChevronLeft,
  Locked,
  Purchase,
  Calendar,
} from "@carbon/icons-react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import UIkit from "uikit";
import CalendarDate from "../../components/Calendar/CalendarDate";
import DoughnutChart from "../../components/Graph/DoughnutChart";

const LiveChatRoute = () => {
  const [channel, setChannel] = useState("ALL");
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [selectedDateFilter, setSelectedDateFilter] = useState("This month");
  const [isApiCall, setIsApiCall] = useState(false);

  const handleDatePickerClose = () => {
    UIkit.drop("#datepickerdrop").hide(true);
  };

  const onChange = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
    setIsApiCall(true);
  };
  const handleDateFilterDropdownrClose = () => {
    UIkit.drop("#dateDropdownSection").hide(true);
  };
  const handleDateFilter = (filter) => {
    setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    setIsApiCall(true);
  };

  const filterDropdown = [
    "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];

  const channels = [
    {
      icon: null,
      name: "All Channels",
      apiSendName: "ALL",
      disabled: false,
    },
    // {
    //   icon: whatsAppImg,
    //   name: "WhatsApp",
    //   apiSendName: "WHATSAPP",
    //   disabled: false,
    // },
    // {
    //   icon: messengerIcon,
    //   name: "Facebook",
    //   apiSendName: "FACEBOOK",
    //   disabled: true,
    // },
    // {
    //   icon: instaIcon,
    //   name: "Instagram",
    //   apiSendName: "INSTAGRAM",
    //   disabled: true,
    // },
  ];

  return (
    <div className="adminDetailsWrp">
      <div className="uk-container">
        <div className="backBtnWrp">
          <div className="backBtn">
            <Link to="/live-chat-routing">
              <ChevronLeft /> Back to Live Chat
            </Link>
          </div>
        </div>
        <div
          className="overviewContent uk-padding-remove"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="uk-container">
            <div className="overviewTopDropdown" style={{marginTop: '32px'}}>
              <div className="uk-grid uk-flex-middle" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="pageHeading uk-margin-remove-bottom">
                    <h3>Sales Department Routing</h3>
                  </div>
                </div>
                <div className="uk-width-1-2 btnSection">
                  <div className="agentFilterWrp">
                    <div className="uk-inline">
                      <button className="calenderBtn rangeBtn">
                        <Calendar /> {selectedDateFilter}
                        <span className="uk-inline">
                          <ChevronDown />
                        </span>
                      </button>
                      <div
                        uk-dropdown="mode: click"
                        className="calendarDropdown"
                        id="dateDropdownSection"
                      >
                        <div className="dropDownHeading">
                          <span>Select a range</span>
                        </div>
                        <ul>
                          {filterDropdown.map((val, index) => {
                            return (
                              <>
                                <li
                                  className={
                                    selectedDateFilter === val
                                      ? "activeBtn"
                                      : ""
                                  }
                                  key={index}
                                  onClick={() => handleDateFilter(val)}
                                >
                                  {filterDropdown.length - 1 === index ? (
                                    <div className="uk-inline uk-text-left">
                                      <button
                                        className="rangeBtn"
                                        type="button"
                                      >
                                        <span className="rangeBtnCircle"></span>{" "}
                                        Custom range
                                      </button>
                                      <div
                                        id="datepickerdrop"
                                        uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                        className="date-picker-container"
                                      >
                                        <CalendarDate onChange={onChange} />
                                        <div className="date-picker-btn-container uk-text-right">
                                          <button
                                            className="date-selection-btn"
                                            onClick={handleDatePickerClose}
                                          >
                                            Ok
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <button type="button" className="rangeBtn">
                                      <span className="rangeBtnCircle"></span>{" "}
                                      {val}
                                    </button>
                                  )}
                                </li>
                                {filterDropdown.length - 2 === index && (
                                  <hr style={{ margin: "5px 0" }} />
                                )}
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <div className="overviewMainContent">
                    <div className="mainBox">
                      <div className="boxHeading">
                        <span>Chats breakdown per status</span>
                      </div>
                      <div className="boxContent">
                        <div className="uk-grid" uk-grid="">
                          <div className="uk-width-1-6">
                            <div className="txtwrp">
                              <h2>320</h2>
                              <span>Total</span>
                            </div>
                          </div>
                          <div className="uk-width-1-6">
                            <div className="txtwrp">
                              <h2>267</h2>
                              <span>Open</span>
                            </div>
                          </div>
                          <div className="uk-width-1-6">
                            <div className="txtwrp">
                              <h2>53</h2>
                              <span>Waiting</span>
                            </div>
                          </div>
                          <div className="uk-width-1-6">
                            <div className="txtwrp">
                              <h2>53</h2>
                              <span>Solved</span>
                            </div>
                          </div>
                          <div className="uk-width-1-6">
                            <div className="txtwrp">
                              <h2>53</h2>
                              <span>Closed</span>
                            </div>
                          </div>
                          <div className="uk-width-1-6">
                            <div className="txtwrp">
                              <h2>53</h2>
                              <span>Unassigned</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="liveAgentTable"
                      style={{ marginTop: "40px" }}
                    >
                      <div className="searchFilter">
                        <form action="">
                          <input
                            type="text"
                            placeholder="Search by number, name"
                          />
                        </form>
                      </div>
                      <div className="analyticsTableWrp">
                        <table className="uk-table">
                          <thead>
                            <tr>
                              <th>NUMBER</th>
                              <th className="uk-table-expand">NAME</th>
                              <th>ASSIGNED USER</th>

                              <th className="uk-width-small">
                                <div className="uk-inline tableDropDown">
                                  <button type="button">
                                    STATUS
                                    {/* <ChevronDown /> */}
                                  </button>
                                </div>
                              </th>
                              <th className="uk-width-small">LAST CONTACT</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>0333 6726268</td>
                              <td className="agnetName">Naveen Asim</td>
                              <td>Emre Altan</td>
                              <td>
                                <span className="inProcess">Solved</span>
                              </td>
                              <td>12 Jun 2023</td>
                              <td className="broadcastTableBtn">
                                <button type="button">View chat</button>
                              </td>
                            </tr>
                            <tr>
                              <td>0333 6726268</td>
                              <td className="agnetName">Naveen Asim</td>
                              <td>Emre Altan</td>
                              <td>
                                <span className="inProcess">Solved</span>
                              </td>
                              <td>12 Jun 2023</td>
                              <td className="broadcastTableBtn">
                                <button type="button">View chat</button>
                              </td>
                            </tr>
                            <tr>
                              <td>0333 6726268</td>
                              <td className="agnetName">Naveen Asim</td>
                              <td>Emre Altan</td>
                              <td>
                                <span className="inProcess">Solved</span>
                              </td>
                              <td>12 Jun 2023</td>
                              <td className="broadcastTableBtn">
                                <button type="button">View chat</button>
                              </td>
                            </tr>
                            <tr>
                              <td>0333 6726268</td>
                              <td className="agnetName">Naveen Asim</td>
                              <td>Emre Altan</td>
                              <td>
                                <span className="inProcess">Solved</span>
                              </td>
                              <td>12 Jun 2023</td>
                              <td className="broadcastTableBtn">
                                <button type="button">View chat</button>
                              </td>
                            </tr>
                            <tr>
                              <td>0333 6726268</td>
                              <td className="agnetName">Naveen Asim</td>
                              <td>Emre Altan</td>
                              <td>
                                <span className="inProcess">Solved</span>
                              </td>
                              <td>12 Jun 2023</td>
                              <td className="broadcastTableBtn">
                                <button type="button">View chat</button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveChatRoute;
