import React from "react";
import {
  Add,
  ChevronDown,
  ChevronLeft,
  Close,
  Document,
  DocumentBlank,
  FaceSatisfied,
  Idea,
  Image,
  Map,
  Play,
  TextBold,
  TextItalic,
  WarningAlt,
} from "@carbon/icons-react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import mobileImg from "../../assets/images/mobile-img.jpg";
import chatVideo from "../../assets/images/preview-video.mp4";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { apiPost } from "../../Utils/apiServices";
import { useRef } from "react";
import { toast } from "react-toastify";
import EmojiPicker from "emoji-picker-react";

const CreateTempelateMarketing = () => {
  const [openEmoji, setOpenEmoji] = useState(false);
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);

  const handleAddAsterisks = () => {
    if (selectionStart !== null && selectionEnd !== null) {
      const inputField = document.getElementById("templateTextarea");
      // Add asterisks only at the selected position
      const modifiedText =
        inputField.value.slice(0, selectionStart) +
        "*" +
        inputField.value.slice(selectionStart, selectionEnd) +
        "*" +
        inputField.value.slice(selectionEnd);
      if (inputField) {
        inputField.value = modifiedText;
      }
      setBodyInputStringText(inputField.value);
    }
  };

  const handleAddDoubleUnderscores = () => {
    if (selectionStart !== null && selectionEnd !== null) {
      const inputField = document.getElementById("templateTextarea");

      // Add asterisks only at the selected position
      const modifiedText =
        inputField.value.slice(0, selectionStart) +
        "_" +
        inputField.value.slice(selectionStart, selectionEnd) +
        "_" +
        inputField.value.slice(selectionEnd);
      if (inputField) {
        inputField.value = modifiedText;
      }
      setBodyInputStringText(inputField.value);
    }
  };

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const params = useParams();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const selectedRadioButton = watch("headerType");

  // states
  const [category, setCategory] = useState("");
  const [errorEnable, setErrorEnable] = useState(false);
  const templateLanguage = [
    { name: "LanguageCodeAfrikaans", value: "af" },
    { name: "Albanian", value: "sq" },
    { name: "Arabic", value: "ar" },
    { name: "Azerbaijani", value: "az" },
    { name: "Bengali", value: "bn" },
    { name: "Bulgarian", value: "bg" },
    { name: "Catalan", value: "ca" },
    { name: "Chinese (CHN)", value: "zh_CN" },
    { name: "Chinese (HKG)", value: "zh_HK" },
    { name: "Chinese (TAI)", value: "zh_TW" },
    { name: "Croatian", value: "hr" },
    { name: "Czech", value: "cs" },
    { name: "Danish", value: "da" },
    { name: "Dutch", value: "nl" },
    { name: "English", value: "en" },
    { name: "English (UK)", value: "en_GB" },
    { name: "English (US)", value: "en_US" },
    { name: "Estonian", value: "et" },
    { name: "Filipino", value: "fil" },
    { name: "French", value: "fr" },
    { name: "Georgian", value: "ka" },
    { name: "German", value: "de" },
    { name: "Greek", value: "el" },
    { name: "Gujarati", value: "gu" },
    { name: "Hausa", value: "ha" },
    { name: "Hebrew", value: "he" },
    { name: "Hindi", value: "hi" },
    { name: "Hungarian", value: "hu" },
    { name: "Indonesian", value: "id" },
    { name: "Irish", value: "ga" },
    { name: "Italian", value: "it" },
    { name: "Japanese", value: "ja" },
    { name: "Kannada", value: "kn" },
    { name: "Kazakh", value: "kk" },
    { name: "Kinyarwanda", value: "rw_RW" },
    { name: "Korean", value: "ko" },
    { name: "Kyrgyz (Kyrgyzstan)", value: "ky_KG" },
    { name: "Lao", value: "lo" },
    { name: "Latvian", value: "lv" },
    { name: "Macedonian", value: "ms" },
    { name: "Malay", value: "ms" },
    { name: "Malayalam", value: "ml" },
    { name: "Marathi", value: "mr" },
    { name: "Norwegian", value: "nb" },
    { name: "Persian", value: "fa" },
    { name: "Polish", value: "pl" },
    { name: "Portuguese (BR)", value: "pt_BR" },
    { name: "Portuguese (POR)", value: "pt_PT" },
    { name: "Punjabi", value: "pa" },
    { name: "Romanian", value: "ro" },
    { name: "Russian", value: "ru" },
    { name: "Serbian", value: "sr" },
    { name: "Slovak", value: "sk" },
    { name: "Slovenian", value: "sl" },
    { name: "Spanish", value: "es" },
    { name: " Spanish (MEX)", value: "es_MX" },
    { name: "Swahili", value: "sw" },
    { name: "Swedish", value: "sv" },
    { name: "Tamil", value: "ta" },
    { name: "Telugu", value: "te" },
    { name: "Thai", value: "th" },
    { name: "Turkish", value: "tr" },
    { name: "Ukrainian", value: "uk" },
    { name: "Urdu", value: "ur" },
    { name: "Uzbek", value: "uz" },
    { name: "Vietnamese", value: "vi" },
    { name: "Zulu", value: "zu" },
    { name: "Uzbek", value: "uz" },
  ];
  const [submittedData, setSubmittedData] = useState({
    language: "",
    name: "",
    description: "",
  });

  // ************************** states for header  *************************
  const [isMediaUpload, setIsMediaUpload] = useState(false);
  const [displayImage, setDisplayImage] = useState({
    image: "",
    video: "",
    document: "",
  });
  const [headers, setHeaders] = useState({
    type: "Text",
    file: "",
    text: "",
  });
  //for error validation
  const [headerTextArray, setHeaderTextArray] = useState([]);
  const [variableCount, setVariableCount] = useState(0);
  const [variables, setVariables] = useState([]);
  const [headerInputStringText, setHeaderInputStringText] = useState("");
  // for headermedia uploaded id store in this state
  const [headerMediaUploadId, setHeaderMediaUploadId] = useState("");
  // only for validation error display on header text fields
  const [formDataForHeadertext, setFormDataForHeadertext] =
    useState(headerTextArray);
  const [headerTextErrors, setHeaderTextErrors] = useState({});
  const [headerMediaType, setHeaderMediaType] = useState("");
  const [headermediaUploadedErrorMessage, setHeadermediaUploadedErrorMessage] =
    useState("");
  const [headerVaraibleArray, setHeaderVaraibleArray] = useState([]);
  const [fileMessage, setFileMessage] = useState({
    image: "",
    video: "",
    document: "",
  });

  // ************************** states for header end  *************************

  // ******************************** states for button ************************
  const [templateButtons, setTemplateButtons] = useState([]);
  const [maxLimit, setMaxLimit] = useState([
    { type: "PHONE_NUMBER", max: 0 },
    { type: "URL", max: 0 },
    { type: "COPY_CODE", max: 0 },
    { type: "QUICK_REPLY", max: null },
    { type: "MARKET_OPTIN", max: 0 },
  ]);

  // ******************************** end states for button *********************

  // ************************** states for body  *************************
  const [body, setBody] = useState({
    text: "",
  });
  //for error validation
  const [bodyTextArray, setBodyTextArray] = useState([]);
  const [variableCountForBody, setVariableCountForBody] = useState(0);
  const [bodyVariables, setBodyVariables] = useState([]);
  const [bodyInputStringText, setBodyInputStringText] = useState("");
  // only for validation error display on header text fields
  const [formDataForBodytext, setFormDataForBodytext] = useState(bodyTextArray);
  const [bodyTextErrors, setBodyTextErrors] = useState({});
  const [bodyVaraibleArray, setBodyVaraibleArray] = useState([]);
  // ************************** states for body end  *************************

  // ******************************** start states for footer *********************
  const [footer, setFooter] = useState("");
  // ******************************** end states for footer *********************

  // ******************************** start states for Authentication template *********************
  const [codeDeleiveryOption, setCodeDeleiveryOption] = useState("");
  const [autoFillData, setAutoFillData] = useState({
    packageName: "",
    signatureHash: "",
  });
  const [messageContent, setMessageContent] = useState({
    addSecurityRecommendation: false,
    codeExpirationMinutes: "",
    codeExpiryCheckBox: false,
  });
  const [buttonText, setButtonText] = useState({
    autofillText: "",
    copyText: "",
  });

  // ******************************** end states for Authentication template *********************

  // ************************* For Header Start Work ****************************
  // Function to handle input changes
  const handleHeaderVariableTextFields = (index, fieldName, value) => {
    const updatedFormData = [...formDataForHeadertext];
    updatedFormData[index][fieldName] = value;
    setFormDataForHeadertext(updatedFormData);
    setHeaderVaraibleArray(updatedFormData);
  };

  useEffect(() => {
    // Parse the headerInputStringText to identify variables
    const regex = /{{(.*?)}}/g;
    const matches = headerInputStringText.match(regex);
    if (matches) {
      const uniqueVariables = Array.from(new Set(matches));
      setVariables(uniqueVariables);
    } else {
      setVariables([]);
    }
  }, [headerInputStringText]);

  const handleHeaderVariablesInString = () => {
    setVariableCount((prevCount) => prevCount + 1);
    const newObject = {
      [`headerVariable${variableCount + 1}`]: "",
    };
    const newArray = [...headerTextArray, newObject];
    setFormDataForHeadertext(newArray);
    setHeaderTextArray(newArray);
    setHeaders((prevHeaders) => ({
      ...prevHeaders,
      text: prevHeaders.text + `{{${variableCount + 1}}}`,
    }));
    setHeaderInputStringText(
      (prevHeaders) => prevHeaders + `{{${variableCount + 1}}}`
    );
  };

  useEffect(() => {
    setCategory(params.template);
  }, []);
  const handleOpenEmoji = (e) => {
    setOpenEmoji(true);
  };
  const hideEmoji = () => {
    setOpenEmoji(false);
  };
  useEffect(() => {
    setTemplateButtons([]);
    setMaxLimit([
      { type: "PHONE_NUMBER", max: 0 },
      { type: "URL", max: 0 },
      { type: "COPY_CODE", max: 0 },
      { type: "QUICK_REPLY", max: null },
      { type: "MARKET_OPTIN", max: 0 },
    ]);
    setHeaderTextErrors({});
    setBodyTextErrors({});
  }, [category]);

  const handleInputChange = (e) => {
    const newText = e.target.value;

    const lastChar = newText.slice(-1);
    if (lastChar === "{" || lastChar === "}") {
      // Don't allow opening or closing curly braces
      return;
    }
    if (newText.length <= 100) {
      setHeaderInputStringText(newText);
    }
  };

  const handleRemoveHeaderVariableFields = (variableToRemove, index) => {
    const match = variableToRemove.match(/{{(\d+)}}/);

    if (match && match[1]) {
      // match[1] contains the extracted number
      const regexPattern = new RegExp(`\\{\\{${match[1]}\\}\\}`, "g");
      // Remove the variable from the headerInputStringText
      const updatedString = headerInputStringText.replace(regexPattern, "");
      const removeItemFromSubmittedArray = headerTextArray.splice(index, 1);
      let data = convertHeaderDataIntoSubmittedFormat(
        headerTextArray,
        "headerVariable"
      );
      setHeaderVaraibleArray(data);
      setFormDataForHeadertext(headerTextArray);
      setHeaderTextArray(headerTextArray);
      const renumberedString = renumberHeaderString(updatedString);
      setHeaderInputStringText(renumberedString);
    } else {
      // Return null or an empty string if no number is found
      return null;
    }
  };

  function renumberHeaderString(headerInputStringText) {
    // Use a regular expression to find all variable sequences
    const regex = /{{(\d+)}}/g;
    // Initialize a variable count
    let variableCountForRenumber = 1;
    // Replace each variable with a sequentially numbered variable
    const updatedString = headerInputStringText.replace(
      regex,
      (match, number) => {
        const updatedVariable = `{{${variableCountForRenumber}}}`;
        variableCountForRenumber++;
        return updatedVariable;
      }
    );
    setVariableCount(variableCountForRenumber - 1);

    return updatedString;
  }

  // function to convert data into submitted format
  const convertHeaderDataIntoSubmittedFormat = (data, parameter) => {
    let currentNumber = 1;
    let outputArray = [];
    data.forEach((obj) => {
      const newObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const newKey = `${parameter}${currentNumber++}`; // Increment the number and create the new key
          newObj[newKey] = obj[key]; // Set the value for the new key
        }
      }
      outputArray.push(newObj);
    });
    return outputArray;
  };

  // Function to validate the form
  const headerFormFieldsValidate = () => {
    const newErrors = {};
    formDataForHeadertext.forEach((data, index) => {
      const fieldName = Object.keys(data)[0]; // Assuming each object has only one key
      const value = data[fieldName];

      if (!value) {
        newErrors[fieldName] = "This field is required";
      }
    });
    setHeaderTextErrors(newErrors);
    // Return true if there are no errors, indicating the form is valid
    return Object.keys(newErrors).length === 0;
  };

  const onEmojiClick = (emojiData, event) => {
    const inputField = document.getElementById("templateTextarea");
    if (inputField) {
      inputField.value += emojiData.emoji;
      setBodyInputStringText(inputField.value);
    }
  };

  const handleFile = (uploadedFile, headerMediaType) => {
    setIsMediaUpload(true);
    var file = uploadedFile.target.files[0];
    // setHeadermediaUploadedErrorMessage('')
    if (headerMediaType === "image") {
      if (file.type.startsWith("image/") === true) {
        setDisplayImage({
          ...displayImage,
          image: URL.createObjectURL(file),
        });
        templateMediaUpload(file);
        setFileMessage({
          image: "",
          video: "",
          document: "",
        });
      } else {
        setFileMessage({
          image: "Please upload only image file",
          video: "",
          document: "",
        });
      }
    }
    if (headerMediaType === "video") {
      if (file.type.startsWith("video/") === true) {
        setDisplayImage({
          ...displayImage,
          video: URL.createObjectURL(file),
        });

        setFileMessage({
          image: "",
          video: "",
          document: "",
        });
        templateMediaUpload(file);
      } else {
        setFileMessage({
          image: "",
          video: "Please upload only video file",
          document: "",
        });
      }
    }
    if (headerMediaType === "document") {
      const acceptedTypes = [
        "application/pdf",
        "application/msword",
        "text/plain",
      ];
      if (acceptedTypes.includes(file.type) === true) {
        setDisplayImage({
          ...displayImage,
          document: URL.createObjectURL(file),
        });
        setFileMessage({
          image: "",
          video: "",
          document: "",
        });
        templateMediaUpload(file);
      } else {
        setFileMessage({
          image: "",
          video: "",
          document: "Please upload a valid document file",
        });
      }
    }
  };

  const templateMediaUpload = (file) => {
    var formData = new FormData();
    formData.append("file", file);

    apiPost(
      `/template/upload-file-meta`,
      onSuccessMediaUpload,
      onFailureMediaUpload,
      formData
    );
  };
  const onSuccessMediaUpload = (response) => {
    if (response.status === 0) {
      setHeaderMediaUploadId(response.message);
      setHeadermediaUploadedErrorMessage("");
    }
  };
  const onFailureMediaUpload = (error) => {
    if (error.response.status === 400) {
      setHeadermediaUploadedErrorMessage(error.response?.data.message);
      setHeaderMediaUploadId("");
    }
  };

  // ************************* For Header End Work ****************************

  // ************************* For Body Start Work ****************************
  // Function to handle input changes
  const handleBodyVariableTextFields = (index, fieldName, value) => {
    const updatedFormData = [...formDataForBodytext];
    updatedFormData[index][fieldName] = value;
    setFormDataForBodytext(updatedFormData);
    setBodyVaraibleArray(updatedFormData);
  };

  useEffect(() => {
    // Parse the bodyInputStringText to identify variables
    const regex = /{{(.*?)}}/g;
    const matches = bodyInputStringText.match(regex);
    if (matches) {
      const uniqueVariables = Array.from(new Set(matches));

      setBodyVariables(uniqueVariables);
    } else {
      setBodyVariables([]);
    }
  }, [bodyInputStringText]);

  const handleBodyVariablesInString = () => {
    setVariableCountForBody((prevCount) => prevCount + 1);
    const newObject = {
      [`bodyVariable${variableCountForBody + 1}`]: "",
    };
    const newArray = [...bodyTextArray, newObject];
    setFormDataForBodytext(newArray);
    setBodyTextArray(newArray);
    setBody((prevHeaders) => ({
      text: prevHeaders.text + `{{${variableCountForBody + 1}}}`,
    }));
    setBodyInputStringText(
      (prevHeaders) => prevHeaders + `{{${variableCountForBody + 1}}}`
    );
  };

  const handleInputChangeForBody = (e) => {
    const newText = e.target.value;

    const lastChar = newText.slice(-1);
    if (lastChar === "{" || lastChar === "}") {
      // Don't allow opening or closing curly braces
      return;
    }
    if (newText.length <= 1024) {
      setBodyInputStringText(newText);
    }
  };

  const handleRemoveBodyVariableFields = (variableToRemove, index) => {
    const match = variableToRemove.match(/{{(\d+)}}/);

    if (match && match[1]) {
      // match[1] contains the extracted number
      const regexPattern = new RegExp(`\\{\\{${match[1]}\\}\\}`, "g");
      // Remove the variable from the headerInputStringText
      const updatedString = bodyInputStringText.replace(regexPattern, "");

      const removeItemFromSubmittedArray = bodyTextArray.splice(index, 1);
      let data = convertBodyDataIntoSubmittedFormat(
        bodyTextArray,
        "bodyVariable"
      );
      setBodyVaraibleArray(data);
      setFormDataForBodytext(bodyTextArray);
      setBodyTextArray(bodyTextArray);
      const renumberedString = renumberBodyStringString(updatedString);
      setBodyInputStringText(renumberedString);
    } else {
      // Return null or an empty string if no number is found
      return null;
    }
  };

  function renumberBodyStringString(bodyInputStringText) {
    // Use a regular expression to find all variable sequences
    const regex = /{{(\d+)}}/g;
    // Initialize a variable count
    let variableCountForRenumber = 1;
    // Replace each variable with a sequentially numbered variable
    const updatedString = bodyInputStringText.replace(
      regex,
      (match, number) => {
        const updatedVariable = `{{${variableCountForRenumber}}}`;
        variableCountForRenumber++;
        return updatedVariable;
      }
    );
    setVariableCountForBody(variableCountForRenumber - 1);

    return updatedString;
  }

  // function to convert data into submitted format
  const convertBodyDataIntoSubmittedFormat = (data, parameter) => {
    let currentNumber = 1;
    let outputArray = [];
    data.forEach((obj) => {
      const newObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const newKey = `${parameter}${currentNumber++}`; // Increment the number and create the new key
          newObj[newKey] = obj[key]; // Set the value for the new key
        }
      }
      outputArray.push(newObj);
    });
    return outputArray;
  };

  // Function to validate the form
  const bodyFormFieldsValidate = () => {
    const newErrors = {};
    formDataForBodytext.forEach((data, index) => {
      const fieldName = Object.keys(data)[0]; // Assuming each object has only one key
      const value = data[fieldName];

      if (!value) {
        newErrors[fieldName] = "This field is required";
      }
    });
    setBodyTextErrors(newErrors);
    // Return true if there are no errors, indicating the form is valid
    return Object.keys(newErrors).length === 0;
  };

  // ************************* For Body End Work ****************************

  // ************************* For button Start Work ****************************
  //  for phone number
  const handlePhonenumberButtonClick = () => {
    const newButton = {
      type: "PHONE_NUMBER",
      max: 1,
      id: "CALL_NOW",
      text: "",
      phone_number: "",
    };

    const newMaxForPhoneNumber = 1;
    // Use map to update the "PHONE_NUMBER" objects
    const updatedMaxLimit = maxLimit.map((item) => {
      if (item.type === "PHONE_NUMBER") {
        // Update the "PHONE_NUMBER" object
        return { ...item, max: newMaxForPhoneNumber };
      }
      // Keep other objects unchanged
      return item;
    });
    setMaxLimit(updatedMaxLimit);
    setTemplateButtons([...templateButtons, newButton]);
  };
  const handleCallOfferCodeButtonClick = () => {
    const newButton = {
      type: "COPY_CODE",
      max: 1,
      id: "copy_code",
      text: "",
    };

    const newMaxForCopyCode = 1;
    // Use map to update the "COPY_CODE" objects
    const updatedMaxLimit = maxLimit.map((item) => {
      if (item.type === "COPY_CODE") {
        // Update the "COPY_CODE" object
        return { ...item, max: newMaxForCopyCode };
      }

      // Keep other objects unchanged
      return item;
    });

    updatedMaxLimit[3]["max"] = "unlimited";
    setMaxLimit(updatedMaxLimit);
    setTemplateButtons([...templateButtons, newButton]);
  };
  const handleUrlButtonClick = () => {
    const newButton = {
      type: "URL",
      max: 0,
      id: "",
      text: "",
      webType: "static",
      // buttonVariable: [null],
      url: "",
    };

    const newMaxForCopyCode = 1;
    // Use map to update the "COPY_CODE" objects
    const updatedMaxLimit = maxLimit.map((item) => {
      if (item.type === "URL") {
        let prevmax = item.max;
        // Update the "COPY_CODE" object
        return { ...item, max: prevmax + newMaxForCopyCode };
      }
      // Keep other objects unchanged
      return item;
    });
    setMaxLimit(updatedMaxLimit);
    setTemplateButtons([...templateButtons, newButton]);
  };
  const handleMarketButtonClick = () => {
    const newButton = {
      type: "MARKET_OPTIN",
      max: null,
      text: "",
    };
    const newMaxForMarket = 1;
    // Use map to update the "COPY_CODE" objects
    const updatedMaxLimit = maxLimit.map((item) => {
      if (item.type === "MARKET_OPTIN") {
        // Update the "COPY_CODE" object
        return { ...item, max: newMaxForMarket };
      }

      // Keep other objects unchanged
      return item;
    });

    setMaxLimit(updatedMaxLimit);
    setTemplateButtons([...templateButtons, newButton]);
  };
  const handleQuickReplyButtonClick = () => {
    const newButton = {
      type: "QUICK_REPLY",
      max: null,
      text: "",
    };
    const updatedMaxLimit = [...maxLimit];
    updatedMaxLimit[2]["max"] = 1;

    setMaxLimit(updatedMaxLimit);
    setTemplateButtons([...templateButtons, newButton]);
  };
  const removeButtons = (index, type) => {
    let tempbtn = [...templateButtons];
    if (type === "PHONE_NUMBER") {
      tempbtn.splice(index, 1);
      // max limit set to 0
      maxLimit[0].max = 0;
      setTemplateButtons(tempbtn);
      setMaxLimit(maxLimit);
    }
    if (type === "COPY_CODE") {
      tempbtn.splice(index, 1);
      // max limit set to 0
      maxLimit[2].max = 0;
      maxLimit[3].max = null;
      setMaxLimit(maxLimit);
      setTemplateButtons(tempbtn);
    }
    if (type === "URL") {
      tempbtn.splice(index, 1);
      // max limit set to 0
      let prevMax = maxLimit[1].max;
      maxLimit[1].max = prevMax - 1;
      setMaxLimit(maxLimit);
      setTemplateButtons(tempbtn);
    }
    if (type === "MARKET_OPTIN") {
      tempbtn.splice(index, 1);
      // max limit set to 0
      maxLimit[4].max = 0;
      setTemplateButtons(tempbtn);
      setMaxLimit(maxLimit);
    }
    if (type === "QUICK_REPLY") {
      tempbtn.splice(index, 1);
      let isQuickReplyExist = tempbtn
        .map(function (o) {
          return o.type;
        })
        .indexOf("QUICK_REPLY");
      if (isQuickReplyExist == -1) {
        maxLimit[2]["max"] = 0;
        setMaxLimit(maxLimit);
      }
      // max limit set to 0
      setTemplateButtons(tempbtn);
    }
  };
  const handleTemplatesButton = (val, index, type, fieldName, e) => {
    let { value, name } = e.target;

    let storeTempButtons = [...templateButtons];
    if (name === "buttonVariable") {
      storeTempButtons[index][name] = [value];
    } else {
      // if (type !== "COPY_CODE") {
      storeTempButtons[index][name] = value;
      // if (value == "static") {
      //   storeTempButtons[index]["buttonVariable"] = [null];
      // } else {
      //   storeTempButtons[index]["buttonVariable"] = [""];
      // }
      if (value == "static") {
        // storeTempButtons[index]["buttonVariable"] = [null];
        // storeTempButtons[index]["buttonVariable"] = [null];
        delete storeTempButtons[index]["buttonVariable"];
      } else {
        storeTempButtons[index]["buttonVariable"] = [""];
      }
      // }
      // else {
      //   storeTempButtons[index][name] = value.replace(/[^\w\s]/gi, "");
      // }
    }

    setTemplateButtons(storeTempButtons);
  };

  const webTypes = ["static", "dynamic"];
  // ************************* For button End Work ******************************

  const createTemplate = () => {
    // e.preventDefault()
    const isFormValidForHeaderText = headerFormFieldsValidate();
    const isFormValidForBodyText = bodyFormFieldsValidate();
    setErrorEnable(true);

    // for phone_number key
    const hasEmptyPhoneNumberField = templateButtons.some(
      (item) => item.phone_number === ""
    );

    // for text key
    const hasEmptyTextField = templateButtons.some((item) => item.text === "");

    // for url key
    const hasUrlField = templateButtons.some((item) => item.url === "");

    // for button varaible key
    const hasEmptyButtonVariable = templateButtons.some((item) => {
      if (item.buttonVariable && Array.isArray(item.buttonVariable)) {
        return item.buttonVariable.some((button) => button === "");
      }
      return false;
    });
    //  header text varaibles set in this array
    let headerTempVaraibleArray = [];
    headerVaraibleArray.map((val, index) => {
      headerTempVaraibleArray.push(val[`headerVariable${index + 1}`]);
    });
    //  body text varaibles set in this array
    let bodyTempVaraibleArray = [];
    bodyVaraibleArray.map((val, index) => {
      bodyTempVaraibleArray.push(val[`bodyVariable${index + 1}`]);
    });

    let headerTypeForSubmition =
      headers.type == "None"
        ? ""
        : headers.type === "Text"
        ? "TEXT"
        : headers.type === "Media" && headerMediaType;
    let newdata = {
      name: submittedData.name.toLocaleLowerCase().replace(/\s+/g, "_"),
      language: submittedData.language,
      category: category.toUpperCase(),
      headerType: headerTypeForSubmition,

      headerValue:
        (headers.type === "Text" && headerInputStringText) ||
        (headers.type === "Media" && headerMediaUploadId) ||
        (headers.type == "None" && ""),
      headerVariable:
        ((headers.type === "Media" || headers.type == "None") && []) ||
        (headers.type === "Text" && headerTempVaraibleArray),
      bodyType: "TEXT",
      bodyValue: bodyInputStringText,
      bodyVariable: bodyTempVaraibleArray,
      footer: footer,
      buttons: templateButtons,
    };

    if (category === "Authentication") {
      if (codeDeleiveryOption === "Autofill") {
        setLoader(true);
        if (
          submittedData.language !== "" &&
          submittedData.name !== "" &&
          submittedData.description !== "" &&
          codeDeleiveryOption !== "" &&
          messageContent.addSecurityRecommendation != false &&
          messageContent.codeExpiryCheckBox != false &&
          messageContent.codeExpirationMinutes != "" &&
          autoFillData.packageName !== "" &&
          autoFillData.signatureHash !== "" &&
          isAgreed !== false
        ) {
          let buttonForAutoFill = [
            {
              id: "OTP",
              type: "OTP",
              otpType: "ONE_TAP",
              text: buttonText.autofillText,
              autofillText: "Autofill",
              packageName: autoFillData.packageName,
              signatureHash: autoFillData.signatureHash,
            },
          ];

          let dataForAuthentication = {
            name: submittedData.name.toLocaleLowerCase().replace(/\s+/g, "_"),
            language: submittedData.language,
            category: category.toUpperCase(),
            bodyType: "TEXT",
            bodyText: "this is my OTP TEMPLATE",
            bodyVariable: ["WELCOME HOME"],

            addSecurityRecommendation: messageContent.addSecurityRecommendation,
            codeExpirationMinutes: messageContent.codeExpirationMinutes,
            buttons: buttonForAutoFill,
          };

          apiPost(
            `/template/create`,
            onSuccessTemplateCreate,
            onFailureTemplateCreate,
            dataForAuthentication
          );
        } else {
          setLoader(false);
        }
      }
      if (codeDeleiveryOption === "OTP") {
        setLoader(true);

        if (
          submittedData.language !== "" &&
          submittedData.name !== "" &&
          submittedData.description !== "" &&
          codeDeleiveryOption !== "" &&
          messageContent.addSecurityRecommendation != false &&
          messageContent.codeExpiryCheckBox != false &&
          messageContent.codeExpirationMinutes != "" &&
          buttonText.copyText !== "" &&
          isAgreed !== false
        ) {
          let buttonForCopyCode = [
            {
              type: "OTP",
              id: "OTP",
              otpType: "COPY_CODE",
              text: buttonText.copyText,
            },
          ];
          let dataForAuthentication = {
            name: submittedData.name.toLocaleLowerCase().replace(/\s+/g, "_"),
            language: submittedData.language,
            category: category.toUpperCase(),
            bodyType: "TEXT",
            bodyText: "this is my OTP TEMPLATE",
            bodyVariable: ["WELCOME HOME"],

            addSecurityRecommendation: messageContent.addSecurityRecommendation,
            codeExpirationMinutes: messageContent.codeExpirationMinutes,
            buttons: buttonForCopyCode,
            packageName: autoFillData.packageName,
            signatureHash: autoFillData.signatureHash,
          };

          apiPost(
            `/template/create`,
            onSuccessTemplateCreate,
            onFailureTemplateCreate,
            dataForAuthentication
          );
        } else {
          setLoader(false);
        }
      }
    } else {
      setLoader(true);
    }
    if (category !== "Authentication") {
      setLoader(true);
      if (
        submittedData.language !== "" &&
        submittedData.name !== "" &&
        submittedData.description !== "" &&
        isFormValidForBodyText == true &&
        bodyInputStringText !== "" &&
        hasEmptyTextField == false &&
        hasUrlField == false &&
        // hasEmptyButtonVariable == false &&
        hasEmptyPhoneNumberField == false &&
        isAgreed !== false
      ) {
        if (headers.type === "Text") {
          setLoader(true);
          if (
            headerInputStringText !== "" &&
            isFormValidForHeaderText === true
          ) {
            apiPost(
              `/template/create`,
              onSuccessTemplateCreate,
              onFailureTemplateCreate,
              newdata
            );
          } else {
            setLoader(false);
          }
        }

        if (headers.type === "Media") {
          setLoader(true);
          if (
            headerMediaUploadId !== "" &&
            headermediaUploadedErrorMessage === ""
          ) {
            apiPost(
              `/template/create`,
              onSuccessTemplateCreate,
              onFailureTemplateCreate,
              newdata
            );
          } else {
            setLoader(false);
          }
        }

        if (headers.type === "None") {
          apiPost(
            `/template/create`,
            onSuccessTemplateCreate,
            onFailureTemplateCreate,
            newdata
          );
        }
      } else {
        setLoader(false);
      }
    }
  };

  const onSuccessTemplateCreate = (response) => {
    if (response.status === 0) {
      toast.success("Template created successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
      });
      setTimeout(() => {
        navigate("/template-message");
      }, 1000);
      setLoader(false);
    }
  };
  const onFailureTemplateCreate = (error) => {
    setLoader(false);

    if (
      error.response?.data.error.code === 100 &&
      error.response?.data.error.message !== "Invalid parameter"
    ) {
      // toast.error(error.response?.data.error.message, {
      toast.error("Enter url e.g. https://yoururl.com", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (
      error.response?.data.error.code === 100 &&
      error.response?.data.error.error_user_msg !== undefined
    ) {
      toast.error(error.response?.data.error.error_user_msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const options = [
    <option key="default" disabled value="" selected>
      Please select code expiry
    </option>,
  ];
  for (let i = 1; i <= 60; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
        {/* {i} minute{i > 1 ? "s" : ""} */}
      </option>
    );
  }

  // const handleSelectChange = (event) => {
  //   setSelectedMinutes(parseInt(event.target.value));
  // };
  return (
    <>
      <div className="addTeamWrp createBroadcastWrp createTempelateWrp">
        <div className="uk-container">
          <div className="backBtnWrp">
            <div className="backBtn uk-margin-remove-bottom">
              <Link to="/template-message">
                <ChevronLeft /> Back to Template
              </Link>
            </div>
            {/* <div className="broadcastDltBtn">
              <button type="button">Delete template</button>
            </div> */}
          </div>
          <div className="addTeamHeading">
            <h3>New Message Template</h3>
            <p>Connect with the customer using pre-defined messages.</p>
          </div>
          <div className="createBroadcastContent">
            <div className="uk-grid" uk-grid="">
              <div className="uk-width-2-3">
                {/* <form onSubmit={handleSubmit(createTemplate)}> */}
                <form
                  action=""
                  onSubmit={(e) => {
                    e.preventDefault();
                    createTemplate();
                  }}
                >
                  <div className="addTeamBox">
                    <div className="boxHeading">
                      <h3>Edit Template</h3>
                    </div>
                    <div className="formwrp">
                      <div className="uk-grid" uk-grid="">
                        <div className="uk-width-1-2">
                          <div className="formInput">
                            <label htmlFor="">Broadcast category</label>
                            <div className="uk-inline categoryDropdownBtn">
                              <button type="button">
                                {category}
                                <ChevronDown />
                              </button>
                              <div uk-dropdown="" className="categoryDropdown">
                                <div className="dropdownHeading">
                                  <span>Message Template Catagory</span>
                                </div>
                                <ul>
                                  <li onClick={() => setCategory("Marketing")}>
                                    <button type="button">
                                      <h3>Marketing message</h3>
                                      <p>
                                        Promotional offers, announcements for
                                        awareness and engagement
                                      </p>
                                    </button>
                                  </li>
                                  <li onClick={() => setCategory("Utility")}>
                                    <button type="button">
                                      <h3>Utility message</h3>
                                      <p>
                                        About a specific transaction, account,
                                        order or customer request
                                      </p>
                                    </button>
                                  </li>
                                  <li
                                    onClick={() =>
                                      setCategory("Authentication")
                                    }
                                  >
                                    <button type="button">
                                      <h3>Authentication message</h3>
                                      <p>Authenticate a transaction or login</p>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="uk-width-1-2">
                          <div className="formInput">
                            <label htmlFor="">Language</label>
                            <select
                              name=""
                              className="uk-select"
                              onChange={(e) => {
                                setSubmittedData({
                                  ...submittedData,
                                  language: e.target.value,
                                });
                              }}
                            >
                              <option value="" selected disabled>
                                Please select language
                              </option>
                              {templateLanguage.map((val, index) => {
                                return (
                                  <option value={val.value} key={index}>
                                    {val.name}
                                  </option>
                                );
                              })}
                            </select>
                            {errorEnable && submittedData.language === "" && (
                              <div className="formErrors">
                                Template language is required
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="uk-width-1-1">
                          <div className="formInput">
                            <label htmlFor="">Name</label>
                            <input
                              type="text"
                              placeholder="Name"
                              className="uk-input"
                              value={submittedData.name}
                              onChange={(e) => {
                                e.target.value.length <= 512 &&
                                  setSubmittedData({
                                    ...submittedData,
                                    name: e.target.value,
                                  });
                              }}
                            />
                            {errorEnable && submittedData.name == "" && (
                              <div className="formErrors">
                                Broadcast name is required
                              </div>
                            )}
                            <div className="txtCounter">
                              <span>{submittedData.name.length}</span>
                              <span>/</span>
                              <span>512</span>
                            </div>
                          </div>
                        </div>
                        <div className="uk-width-1-1">
                          <div className="formInput">
                            <label htmlFor="">
                              Description <span>(optional)</span>
                            </label>
                            <textarea
                              name=""
                              className="uk-textarea"
                              value={submittedData.description}
                              onChange={(e) => {
                                e.target.value.length <= 512 &&
                                  setSubmittedData({
                                    ...submittedData,
                                    description: e.target.value,
                                  });
                              }}
                            ></textarea>
                            {errorEnable && submittedData.description == "" && (
                              <div className="formErrors">
                                Broadcast description is required
                              </div>
                            )}
                            <div className="txtCounter">
                              <span>{submittedData.description.length}</span>
                              <span>/</span>
                              <span>512</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(category === "Marketing" || category === "Utility") && (
                    <>
                      <div className="addTeamBox">
                        <div className="boxHeading">
                          <h3>Header</h3>
                          <span style={{ fontSize: "13px", marginTop: "16px" }}>
                            You want to broadcast to recipients from?
                          </span>
                        </div>
                        <div className="formwrp">
                          <div className="uk-grid" uk-grid="">
                            <div className="uk-width-1-3">
                              <div className="formInput">
                                <div className="uk-inline categoryDropdownBtn">
                                  <button type="button">
                                    {headers.type}
                                    <ChevronDown />
                                  </button>
                                  <div
                                    uk-dropdown=""
                                    className="categoryDropdown"
                                  >
                                    <div
                                      className="dropdownHeading"
                                      onClick={() => {
                                        setDisplayImage({
                                          image: "",
                                          video: "",
                                          document: "",
                                        });
                                        setHeaders({
                                          ...headers,
                                          type: "Text",
                                        });
                                      }}
                                    >
                                      <span>Text</span>
                                    </div>
                                    <div
                                      className="dropdownHeading"
                                      onClick={() =>
                                        setHeaders({
                                          ...headers,
                                          type: "Media",
                                        })
                                      }
                                    >
                                      <span>Media</span>
                                    </div>
                                    <ul>
                                      <li
                                        onClick={() =>
                                          setHeaders({
                                            ...headers,
                                            type: "None",
                                          })
                                        }
                                      >
                                        <button to="">
                                          <h3>None</h3>
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="uk-width-2-3">
                              {headers.type === "Text" && (
                                <>
                                  <div className="formInput">
                                    <input
                                      type="name"
                                      className="uk-input"
                                      value={headerInputStringText}
                                      onChange={(e) =>
                                        e.target.value.length <= 60 &&
                                        handleInputChange(e)
                                      }
                                    />
                                    <div
                                      className="txtCounter"
                                      style={{ top: "5px" }}
                                    >
                                      <span>
                                        {headerInputStringText.length}
                                      </span>
                                      <span>/</span>
                                      <span>60</span>
                                    </div>
                                  </div>
                                  {formDataForHeadertext.map((data, index) => {
                                    const fieldName = Object.keys(data)[0]; // Assuming each object has only one key

                                    return (
                                      <div
                                        key={fieldName}
                                        className="headerVariableInput"
                                      >
                                        <input
                                          className="uk-input"
                                          type="text"
                                          name={fieldName}
                                          value={data[fieldName]}
                                          onChange={(e) => {
                                            handleHeaderVariableTextFields(
                                              index,
                                              fieldName,
                                              e.target.value
                                            );
                                            headerFormFieldsValidate();
                                          }}
                                        />
                                        {headerTextErrors[fieldName] && (
                                          <div className="formErrors">
                                            {headerTextErrors[fieldName]}
                                          </div>
                                        )}
                                        <button
                                          onClick={
                                            () =>
                                              handleRemoveHeaderVariableFields(
                                                `{{${index + 1}}}`,
                                                index
                                              )
                                            // handleRemoveHeaderVariableFields(variable, index)
                                          }
                                        >
                                          delete
                                        </button>
                                      </div>
                                    );
                                  })}
                                  {variables.length < 1 && (
                                    <div className="addVariableBtn">
                                      <button
                                        type="button"
                                        onClick={handleHeaderVariablesInString}
                                      >
                                        <Add />
                                        Add variable
                                      </button>
                                    </div>
                                  )}
                                  {errorEnable &&
                                    headers.type === "Text" &&
                                    headerInputStringText == "" && (
                                      <div className="formErrors">
                                        Text is required
                                      </div>
                                    )}
                                </>
                              )}
                            </div>
                            {headers.type === "Media" && (
                              <div className="uk-width-1-1">
                                <div className="mediaInputs">
                                  <ul>
                                    <li>
                                      <div className="radioInput">
                                        <input
                                          type="radio"
                                          name="media_input"
                                          id="media_input"
                                          onChange={() => {
                                            // setHeadermediaUploadedErrorMessage("");
                                            setHeaderMediaUploadId("");
                                            setHeaderMediaType("image");
                                          }}
                                        />
                                      </div>
                                      <div className="imgwrp">
                                        <Image />
                                        <h3>Image</h3>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="radioInput">
                                        <input
                                          type="radio"
                                          name="media_input"
                                          id="media_input"
                                          onChange={() => {
                                            // setHeadermediaUploadedErrorMessage("");

                                            setHeaderMediaUploadId("");
                                            setHeaderMediaType("video");
                                          }}
                                        />
                                      </div>
                                      <div className="imgwrp">
                                        <Play />
                                        <h3>Video</h3>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="radioInput">
                                        <input
                                          type="radio"
                                          name="media_input"
                                          id="media_input"
                                          onChange={() => {
                                            // setHeadermediaUploadedErrorMessage("");

                                            setHeaderMediaUploadId("");
                                            setHeaderMediaType("document");
                                          }}
                                        />
                                      </div>
                                      <div className="imgwrp">
                                        <DocumentBlank />
                                        <h3>Document</h3>
                                      </div>
                                    </li>
                                    {/* <li>
                                  <div className="radioInput">
                                    <input
                                      type="radio"
                                      name="media_input"
                                      id="media_input"
                                      {...register(
                                        `headerMediaType`,
                                        {
                                          required: true,
                                        }
                                      )}
                                    />
                                  </div>
                                  <div className="imgwrp">
                                    <Map />
                                    <h3>Location</h3>
                                  </div>
                                </li> */}
                                  </ul>

                                  {errorEnable && headerMediaType === "" && (
                                    <div className="formErrors">
                                      {`Please choose media type.`}
                                    </div>
                                  )}
                                </div>

                                {headerMediaType === "image" && (
                                  <>
                                    <div className="mediaUploadBtn">
                                      <div
                                        className="js-upload"
                                        uk-form-custom=""
                                      >
                                        <input
                                          type="file"
                                          multiple
                                          accept="image/*"
                                          onChange={(e) =>
                                            handleFile(e, "image")
                                          }
                                        />
                                        <button type="button">
                                          <Image /> Add photo
                                        </button>
                                      </div>
                                    </div>
                                    {errorEnable &&
                                      headerMediaType == "image" &&
                                      headermediaUploadedErrorMessage == "" &&
                                      headerMediaUploadId === "" &&
                                      fileMessage.image === "" && (
                                        <div className="formErrors">
                                          {`Please upload image.`}
                                        </div>
                                      )}
                                    {isMediaUpload &&
                                      fileMessage.image === "" &&
                                      headermediaUploadedErrorMessage !==
                                        "" && (
                                        <div className="formErrors">
                                          {headermediaUploadedErrorMessage}
                                        </div>
                                      )}
                                    {isMediaUpload &&
                                      fileMessage.image !== "" && (
                                        <div className="formErrors">
                                          {fileMessage.image}
                                        </div>
                                      )}
                                    <div className="inputBottonTxt">
                                      <span>
                                        <Idea /> Choose JPG or PNG file upto 15
                                        MB size
                                      </span>
                                    </div>
                                  </>
                                )}
                                {headerMediaType === "video" && (
                                  <>
                                    <div className="mediaUploadBtn">
                                      <div
                                        className="js-upload"
                                        uk-form-custom=""
                                      >
                                        <input
                                          type="file"
                                          multiple
                                          accept="video/*"
                                          onChange={(e) =>
                                            handleFile(e, "video")
                                          }
                                        />
                                        <button type="button">
                                          <Play /> Add Video
                                        </button>
                                      </div>
                                    </div>
                                    {errorEnable &&
                                      headerMediaType == "video" &&
                                      headermediaUploadedErrorMessage == "" &&
                                      headerMediaUploadId === "" &&
                                      fileMessage.video ===
                                        ""(
                                          <div className="formErrors">
                                            {`Please upload video.`}
                                          </div>
                                        )}
                                    {isMediaUpload &&
                                      fileMessage.video === "" &&
                                      headermediaUploadedErrorMessage !==
                                        "" && (
                                        <div className="formErrors">
                                          {headermediaUploadedErrorMessage}
                                        </div>
                                      )}
                                    {isMediaUpload &&
                                      fileMessage.video !== "" && (
                                        <div className="formErrors">
                                          {fileMessage.video}
                                        </div>
                                      )}
                                    <div className="inputBottonTxt">
                                      <span>
                                        <Idea /> Choose mp4 file
                                      </span>
                                    </div>
                                  </>
                                )}
                                {headerMediaType === "document" && (
                                  <>
                                    <div className="mediaUploadBtn">
                                      <div
                                        className="js-upload"
                                        uk-form-custom=""
                                      >
                                        <input
                                          type="file"
                                          multiple
                                          accept=".pdf,.doc,.docx,.txt"
                                          onChange={(e) =>
                                            handleFile(e, "document")
                                          }
                                        />
                                        <button type="button">
                                          <DocumentBlank /> Add document
                                        </button>
                                      </div>
                                    </div>
                                    {errorEnable &&
                                      headerMediaType == "document" &&
                                      headermediaUploadedErrorMessage == "" &&
                                      headerMediaUploadId === "" &&
                                      fileMessage.document ===
                                        ""(
                                          <div className="formErrors">
                                            {`Please upload document.`}
                                          </div>
                                        )}
                                    {isMediaUpload &&
                                      fileMessage.document === "" &&
                                      headermediaUploadedErrorMessage !==
                                        "" && (
                                        <div className="formErrors">
                                          {headermediaUploadedErrorMessage}
                                        </div>
                                      )}
                                    {isMediaUpload &&
                                      fileMessage.document !== "" && (
                                        <div className="formErrors">
                                          {fileMessage.document}
                                        </div>
                                      )}
                                    <div className="inputBottonTxt">
                                      <span>
                                        <Idea /> Choose pdf or word file
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="addTeamBox">
                        <div className="boxHeading">
                          <h3>Body</h3>
                          <span style={{ fontSize: "13px", marginTop: "16px" }}>
                            Enter the text for your message in the language that
                            you've selected.
                          </span>
                        </div>
                        <div className="formwrp">
                          <div className="uk-grid" uk-grid="">
                            <div className="uk-width-1-1">
                              <div className="formInput">
                                <textarea
                                  name=""
                                  id="templateTextarea"
                                  className="uk-textarea"
                                  value={bodyInputStringText}
                                  onChange={(e) =>
                                    e.target.value.length <= 1024 &&
                                    handleInputChangeForBody(e)
                                  }
                                  onSelect={(event) => {
                                    setSelectionStart(
                                      event.target.selectionStart
                                    );
                                    setSelectionEnd(event.target.selectionEnd);
                                  }}
                                ></textarea>
                                <div
                                  className="txtCounter"
                                  style={{ top: "5px" }}
                                >
                                  <span>{bodyInputStringText.length}</span>
                                  <span>/</span>
                                  <span>1024</span>
                                </div>
                              </div>

                              <div className="textOptions">
                                <div className="txtOptionsWrp">
                                  <ul>
                                    <li>
                                      <button
                                        type="button"
                                        onClick={handleAddAsterisks}
                                      >
                                        <TextBold />
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        type="button"
                                        onClick={handleAddDoubleUnderscores}
                                      >
                                        <TextItalic />
                                      </button>
                                    </li>
                                    <li>
                                      {openEmoji && (
                                        <button id="emoji" type="button">
                                          {/* <Emoji id="templateTextarea" /> */}
                                          <EmojiPicker
                                            onEmojiClick={onEmojiClick}
                                            disableAutoFocus={true}
                                            native=""
                                          />
                                        </button>
                                      )}
                                      {!openEmoji ? (
                                        <button
                                          id="emojidiv"
                                          type="button"
                                          onClick={(e) =>
                                            // e.preventDefault() &&
                                            handleOpenEmoji()
                                          }
                                        >
                                          <FaceSatisfied />
                                        </button>
                                      ) : (
                                        <button
                                          id="emojiCross"
                                          className="emojiCross"
                                        >
                                          <Close onClick={(e) => hideEmoji()} />
                                        </button>
                                      )}
                                    </li>
                                  </ul>
                                </div>
                                {bodyVariables.length < 6 && (
                                  <div className="addVariableBtn">
                                    <button
                                      type="button"
                                      onClick={handleBodyVariablesInString}
                                    >
                                      <Add />
                                      Add variable
                                    </button>
                                  </div>
                                )}
                                {errorEnable && bodyInputStringText == "" && (
                                  <div className="formErrors">
                                    Text is required
                                  </div>
                                )}
                              </div>
                              {formDataForBodytext.map((data, index) => {
                                const fieldName = Object.keys(data)[0]; // Assuming each object has only one key

                                return (
                                  <div
                                    key={fieldName}
                                    className="headerVariableInput"
                                  >
                                    <input
                                      className="uk-input"
                                      type="text"
                                      name={fieldName}
                                      value={data[fieldName]}
                                      onChange={(e) => {
                                        handleBodyVariableTextFields(
                                          index,
                                          fieldName,
                                          e.target.value
                                        );
                                        bodyFormFieldsValidate();
                                      }}
                                      style={{ width: "85%" }}
                                    />
                                    {bodyTextErrors[fieldName] && (
                                      <div className="formErrors">
                                        {bodyTextErrors[fieldName]}
                                      </div>
                                    )}
                                    <button
                                      onClick={
                                        () =>
                                          handleRemoveBodyVariableFields(
                                            `{{${index + 1}}}`,
                                            index
                                          )
                                        // handleRemoveHeaderVariableFields(variable, index)
                                      }
                                    >
                                      delete
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="addTeamBox">
                        <div className="boxHeading">
                          <h3>Footer</h3>
                        </div>
                        <div className="formwrp">
                          <div className="uk-grid" uk-grid="">
                            <div className="uk-width-1-1">
                              <div className="formInput">
                                <label htmlFor="">
                                  Add a short text to the bottom of this
                                  message. <span>(optional)</span>
                                </label>
                                <input
                                  type="text"
                                  className="uk-input"
                                  placeholder="Enter text in English"
                                  value={footer}
                                  onChange={(e) => {
                                    e.target.value.length <= 60 &&
                                      setFooter(e.target.value);
                                  }}
                                />
                                <div
                                  className="txtCounter"
                                  // style={{ top: "5px" }}
                                >
                                  <span>{footer.length}</span>
                                  <span>/</span>
                                  <span>60</span>
                                </div>
                              </div>
                              <div className="inputBottonTxt">
                                <span>
                                  <Idea /> If you add the marketing opt-out
                                  button, the associated footer will be shown
                                  here by default.
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="addTeamBox">
                        <div className="boxHeading">
                          <h3>Buttons</h3>
                          <span>
                            Create buttons that let customers respond to your
                            message or take action.
                          </span>
                        </div>
                        <div className="formwrp">
                          <div className="uk-grid" uk-grid="">
                            <div className="uk-width-1-1">
                              <div className="formInput">
                                <div
                                  className="uk-inline categoryDropdownBtn"
                                  style={{ width: "140px" }}
                                >
                                  <button type="button">
                                    <Add /> Add Button
                                    <ChevronDown />
                                  </button>
                                  <div
                                    uk-dropdown=""
                                    className="categoryDropdown"
                                  >
                                    <div className="dropdownHeading">
                                      <span>Quick reply buttons</span>
                                    </div>
                                    <ul>
                                      {/* {category === "Marketing" && (
                                        <li>
                                          <button
                                            type="button"
                                            onClick={handleMarketButtonClick}
                                            disabled={
                                              maxLimit[4].max === 1 && true
                                            }
                                          >
                                            <h3> Marketing opt-out</h3>
                                          </button>
                                        </li>
                                      )} */}
                                      <li>
                                        <button
                                          type="button"
                                          onClick={handleQuickReplyButtonClick}
                                          disabled={
                                            maxLimit[3].max === "unlimited" &&
                                            true
                                          }
                                        >
                                          <h3>Custom</h3>
                                        </button>
                                      </li>
                                    </ul>
                                    <hr style={{ margin: "0 0 10px" }} />
                                    <div className="dropdownHeading">
                                      <span>Call-to-action buttons</span>
                                    </div>
                                    <ul>
                                      <li>
                                        <button
                                          type="button"
                                          onClick={handleUrlButtonClick}
                                          disabled={
                                            maxLimit[1].max === 2 && true
                                          }
                                        >
                                          <h3>Visit website button</h3>
                                          <p>2 buttons maximum</p>
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          type="button"
                                          onClick={handlePhonenumberButtonClick}
                                          disabled={
                                            maxLimit[0].max === 1 && true
                                          }
                                        >
                                          <h3>Call phone number</h3>
                                          <p>1 buttons maximum</p>
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          type="button"
                                          onClick={
                                            handleCallOfferCodeButtonClick
                                          }
                                          disabled={
                                            maxLimit[2].max === 1 && true
                                            // ||
                                          }
                                        >
                                          <h3>Call offer code</h3>
                                          <p>1 buttons maximum</p>
                                        </button>
                                      </li>
                                    </ul>
                                  </div>

                                  {/*  for phone number start */}
                                  {/* {templateButtons?.map((val, index) => {
                            
                                return (
                                  <div key={index}>
                                    {val.type === "PHONE_NUMBER" && (
                                      <>
                                        <div>
                                          <span>Call phone numnber</span>
                                          <span>
                                            <input
                                              type="text"
                                              name="phone_number"
                                              onChange={(e) =>
                                                handleTemplatesButton(
                                                  val,
                                                  index,
                                                  val.type,
                                                  "phone_number",
                                                  e
                                                )
                                              }
                                            />
                                          </span>
                                        </div>
                                        <div>
                                          <span>Country code</span>
                                          <span>
                                            <input
                                              type="text"
                                              name="text"
                                              onChange={(e) =>
                                                handleTemplatesButton(
                                                  val,
                                                  index,
                                                  val.type,
                                                  "text",
                                                  e
                                                )
                                              }
                                            />
                                          </span>
                                        </div>
                                        <button
                                          onClick={() =>
                                            removeButtons(index, val.type)
                                          }
                                        >
                                          delete
                                        </button>
                                      </>
                                    )}
                                    {val.type === "COPY_CODE" && (
                                      <>
                                        <div>
                                          <span>Copy Offer Code</span>
                                          <span>
                                            <input
                                              type="text"
                                              name="text"
                                              placeholder="copy offer code"
                                              onChange={(e) =>
                                                handleTemplatesButton(
                                                  val,
                                                  index,
                                                  val.type,
                                                  "text",
                                                  e
                                                )
                                              }
                                            />
                                          </span>
                                        </div>
                                        <button
                                          onClick={() =>
                                            removeButtons(index, val.type)
                                          }
                                        >
                                          delete
                                        </button>
                                      </>
                                    )}
                                  </div>
                                );
                              })} */}
                                </div>
                                {/*  for phone number end */}
                              </div>
                              <div className="inputBottonTxt">
                                <span>
                                  <Idea /> If you add more that three buttons,
                                  they will appear in a list.
                                </span>
                              </div>

                              {/* <div className="tempelateBtnWrp">
                                <div className="btnWrpHeading">
                                  <span>Quick reply</span>
                                </div>
                              </div> */}

                              {templateButtons?.map((val, index) => {
                                return (
                                  <div key={index}>
                                    {/* <div className="tempelateBtnWrp">
                                  <div className="btnWrpHeading">
                                    <span>Quick reply</span>
                                  </div>
                                </div> */}
                                    {val.type === "QUICK_REPLY" && (
                                      <div
                                        className="tempelateBtnWrp"
                                        style={{ marginTop: "24px" }}
                                      >
                                        <div className="mainBtnWrapper">
                                          <div
                                            className="uk-grid uk-grid-small"
                                            uk-grid=""
                                          >
                                            <div className="uk-width-1-3">
                                              <div className="formInput">
                                                <label htmlFor="">
                                                  Action type
                                                </label>
                                                <select
                                                  name=""
                                                  className="uk-select"
                                                >
                                                  <option value="" selected>
                                                    Custom
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                            <div className="uk-width-2-3">
                                              <div className="formAndBtnWrp">
                                                <div
                                                  className="formInput"
                                                  style={{ width: "80%" }}
                                                >
                                                  <label htmlFor="">
                                                    Button text
                                                  </label>
                                                  <input
                                                    type="text"
                                                    placeholder="Enter text in English"
                                                    className="uk-input"
                                                    name="text"
                                                    value={val.text}
                                                    onChange={(e) =>
                                                      e.target.value.length <=
                                                        25 &&
                                                      handleTemplatesButton(
                                                        val,
                                                        index,
                                                        val.type,
                                                        "text",
                                                        e
                                                      )
                                                    }
                                                  />
                                                  {errorEnable &&
                                                    val.text === "" && (
                                                      <div className="formErrors">
                                                        field is required
                                                      </div>
                                                    )}
                                                  <div
                                                    className="txtCounter"
                                                    style={{ top: "35px" }}
                                                  >
                                                    <span>
                                                      {val.text.length}
                                                    </span>
                                                    <span>/</span>
                                                    <span>25</span>
                                                  </div>
                                                </div>
                                                <div className="formDltBtn">
                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      removeButtons(
                                                        index,
                                                        val.type
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {val.type === "MARKET_OPTIN" && (
                                      <div className="tempelateBtnWrp">
                                        <div className="mainBtnWrapper">
                                          <div
                                            className="uk-grid uk-grid-small"
                                            uk-grid=""
                                          >
                                            <div className="uk-width-1-3">
                                              <div className="formInput">
                                                <label htmlFor="">Type</label>
                                                <select
                                                  name=""
                                                  className="uk-select"
                                                >
                                                  <option value="" selected>
                                                    Marketing opt-out
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                            <div className="uk-width-2-3">
                                              <div className="formAndBtnWrp">
                                                <div
                                                  className="formInput"
                                                  style={{ width: "80%" }}
                                                >
                                                  <label htmlFor="">
                                                    Button text
                                                  </label>
                                                  <input
                                                    type="text"
                                                    placeholder="Stop promotions"
                                                    className="uk-input"
                                                    name="text"
                                                    disabled
                                                  />
                                                  <div
                                                    className="txtCounter"
                                                    style={{ top: "35px" }}
                                                  >
                                                    <span>0</span>
                                                    <span>/</span>
                                                    <span>25</span>
                                                  </div>
                                                </div>
                                                <div className="formDltBtn">
                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      removeButtons(
                                                        index,
                                                        val.type
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="uk-width-1-1">
                                              <div
                                                className="formInput"
                                                style={{ width: "87%" }}
                                              >
                                                <label htmlFor="">
                                                  Footer text
                                                </label>
                                                <input
                                                  type="text"
                                                  placeholder="Not interested? Tap Stop promotions"
                                                  className="uk-input"
                                                  name="text"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                            <div className="uk-width-1-1">
                                              <div
                                                className="formCheckbox"
                                                style={{ width: "85%" }}
                                              >
                                                <input type="checkbox" />
                                                <span>
                                                  I understand that its our
                                                  responsibility to stop sending
                                                  marketing messages to
                                                  customers who opt-out.
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {val.type === "URL" && (
                                      <div
                                        className="tempelateBtnWrp"
                                        style={{ marginTop: "24px" }}
                                      >
                                        {/* <div className="btnWrpHeading">
                                      <span>Call to action</span>
                                    </div> */}
                                        <div className="mainBtnWrapper">
                                          <div
                                            className="uk-grid uk-grid-small"
                                            uk-grid=""
                                          >
                                            <div className="uk-width-1-3">
                                              <div className="formInput">
                                                <label htmlFor="">
                                                  Action type
                                                </label>
                                                <select
                                                  name=""
                                                  className="uk-select"
                                                >
                                                  <option value="" selected>
                                                    Visit website
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                            <div className="uk-width-2-3">
                                              <div className="formAndBtnWrp">
                                                <div
                                                  className="formInput"
                                                  style={{ width: "80%" }}
                                                >
                                                  <label htmlFor="">
                                                    Button text
                                                  </label>
                                                  <input
                                                    type="text"
                                                    placeholder="Enter text in English"
                                                    className="uk-input"
                                                    name="text"
                                                    value={val.text}
                                                    onChange={(e) =>
                                                      e.target.value.length <=
                                                        25 &&
                                                      handleTemplatesButton(
                                                        val,
                                                        index,
                                                        val.type,
                                                        "text",
                                                        e
                                                      )
                                                    }
                                                  />
                                                  {errorEnable &&
                                                    val.text === "" && (
                                                      <div className="formErrors">
                                                        field is required
                                                      </div>
                                                    )}
                                                  <div className="txtCounter">
                                                    <span>
                                                      {val.text.length}
                                                    </span>
                                                    <span>/</span>
                                                    <span>25</span>
                                                  </div>
                                                </div>
                                                <div className="formDltBtn">
                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      removeButtons(
                                                        index,
                                                        val.type
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="uk-width-1-1">
                                              <div>
                                                {val.webType === "dynamic" &&
                                                  `${
                                                    val.url === ""
                                                      ? `yourexample.com/?q=${
                                                          val
                                                            .buttonVariable[0] ===
                                                            null ||
                                                          val
                                                            .buttonVariable[0] ===
                                                            ""
                                                            ? "{{1}}"
                                                            : val
                                                                .buttonVariable[0]
                                                        }`
                                                      : val.url +
                                                        `/?q=${
                                                          val
                                                            .buttonVariable[0] ===
                                                            null ||
                                                          val
                                                            .buttonVariable[0] ===
                                                            ""
                                                            ? "{{1}}"
                                                            : val
                                                                .buttonVariable[0]
                                                        }`
                                                  }`}
                                              </div>
                                            </div>
                                            {/* <div>
                                          {val.webType === "dynamic" &&
                                            val.url +
                                              `/?q=${val.buttonVariable}`}
                                        </div> */}
                                            <div className="uk-width-1-3">
                                              <div className="formInput">
                                                <select
                                                  name="webType"
                                                  onChange={(e) =>
                                                    handleTemplatesButton(
                                                      val,
                                                      index,
                                                      val.type,
                                                      "webType",
                                                      e
                                                    )
                                                  }
                                                  className="uk-select"
                                                >
                                                  {webTypes.map(
                                                    (item, index) => {
                                                      return (
                                                        <option
                                                          key={index}
                                                          value={item}
                                                          selected={
                                                            val.webType === item
                                                          }
                                                        >
                                                          {item}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                            <div className="uk-width-2-3">
                                              <div className="formAndBtnWrp">
                                                <div
                                                  className="formInput"
                                                  style={{ width: "80%" }}
                                                >
                                                  <input
                                                    type="text"
                                                    placeholder="Enter website URL..."
                                                    className="uk-input"
                                                    name="url"
                                                    value={val.url}
                                                    onChange={(e) =>
                                                      e.target.value.length <=
                                                        2000 &&
                                                      handleTemplatesButton(
                                                        val,
                                                        index,
                                                        val.type,
                                                        "url",
                                                        e
                                                      )
                                                    }
                                                  />
                                                  {errorEnable &&
                                                    val.url === "" && (
                                                      <div className="formErrors">
                                                        field is required
                                                      </div>
                                                    )}
                                                  <div
                                                    className="txtCounter"
                                                    style={{ top: "5px" }}
                                                  >
                                                    <span>
                                                      {val.url.length}
                                                    </span>
                                                    <span>/</span>
                                                    <span>2000</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {val.webType === "dynamic" && (
                                              <div className="uk-width-1-1">
                                                <div className="formAndBtnWrp">
                                                  <div
                                                    className="formInput"
                                                    style={{ width: "80%" }}
                                                  >
                                                    {"{{1}}"}
                                                    <input
                                                      type="text"
                                                      placeholder="Enter website URL..."
                                                      className="uk-input"
                                                      name="buttonVariable"
                                                      value={val.buttonVariable}
                                                      onChange={(e) =>
                                                        e.target.value.length <=
                                                          200 &&
                                                        handleTemplatesButton(
                                                          val,
                                                          index,
                                                          val.type,
                                                          "buttonVariable",
                                                          e
                                                        )
                                                      }
                                                    />
                                                    {errorEnable &&
                                                      (val.buttonVariable[0] ===
                                                        null ||
                                                        val
                                                          .buttonVariable[0] ===
                                                          "") && (
                                                        <div className="formErrors">
                                                          field is required
                                                        </div>
                                                      )}
                                                    <div
                                                      className="txtCounter"
                                                      style={{ top: "5px" }}
                                                    >
                                                      <span>
                                                        {val
                                                          .buttonVariable[0] ===
                                                        null
                                                          ? 0
                                                          : val
                                                              .buttonVariable[0]
                                                              .length}
                                                      </span>
                                                      <span>/</span>
                                                      <span>200</span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {val.type === "PHONE_NUMBER" && (
                                      <div
                                        className="tempelateBtnWrp"
                                        style={{ marginTop: "24px" }}
                                      >
                                        <div className="mainBtnWrapper">
                                          <div
                                            className="uk-grid uk-grid-small"
                                            uk-grid=""
                                          >
                                            <div className="uk-width-1-3">
                                              <div className="formInput">
                                                <label htmlFor="">
                                                  Action type
                                                </label>
                                                <select
                                                  name=""
                                                  className="uk-select"
                                                >
                                                  <option value="" selected>
                                                    {/* Country code
                                                     */}
                                                    Button Text
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                            <div className="uk-width-2-3">
                                              <div className="formAndBtnWrp">
                                                <div
                                                  className="formInput"
                                                  style={{ width: "80%" }}
                                                >
                                                  <label htmlFor="">
                                                    Button text
                                                  </label>
                                                  <input
                                                    type="text"
                                                    placeholder="Enter text in English"
                                                    className="uk-input"
                                                    name="text"
                                                    value={val.text}
                                                    onChange={(e) =>
                                                      e.target.value.length <=
                                                        25 &&
                                                      handleTemplatesButton(
                                                        val,
                                                        index,
                                                        val.type,
                                                        "text",
                                                        e
                                                      )
                                                    }
                                                  />
                                                  {errorEnable &&
                                                    val.text === "" && (
                                                      <div className="formErrors">
                                                        field is required
                                                      </div>
                                                    )}
                                                  <div className="txtCounter">
                                                    <span>
                                                      {val.text.length}
                                                    </span>
                                                    <span>/</span>
                                                    <span>25</span>
                                                  </div>
                                                </div>
                                                <div className="formDltBtn">
                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      removeButtons(
                                                        index,
                                                        val.type
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="uk-width-1-3">
                                              <div className="formInput">
                                                <select
                                                  name=""
                                                  className="uk-select"
                                                >
                                                  <option value="" selected>
                                                    Call on phone
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                            <div className="uk-width-2-3">
                                              <div className="formAndBtnWrp">
                                                <div
                                                  className="formInput"
                                                  style={{ width: "80%" }}
                                                >
                                                  <input
                                                    type="text"
                                                    placeholder="Enter phone number..."
                                                    className="uk-input"
                                                    value={val.phone_number}
                                                    name="phone_number"
                                                    onChange={(e) =>
                                                      e.target.value.length <=
                                                        20 &&
                                                      handleTemplatesButton(
                                                        val,
                                                        index,
                                                        val.type,
                                                        "phone_number",
                                                        e
                                                      )
                                                    }
                                                  />
                                                  {errorEnable &&
                                                    val.phone_number === "" && (
                                                      <div className="formErrors">
                                                        field is required
                                                      </div>
                                                    )}
                                                  <div
                                                    className="txtCounter"
                                                    style={{ top: "5px" }}
                                                  >
                                                    <span>
                                                      {val.phone_number.length}
                                                    </span>
                                                    <span>/</span>
                                                    <span>20</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {val.type === "COPY_CODE" && (
                                      <div
                                        className="tempelateBtnWrp"
                                        style={{ marginTop: "24px" }}
                                      >
                                        <div className="mainBtnWrapper">
                                          <div
                                            className="uk-grid uk-grid-small"
                                            uk-grid=""
                                          >
                                            <div className="uk-width-1-3">
                                              <div className="formInput">
                                                <label htmlFor="">
                                                  Action type
                                                </label>
                                                <select
                                                  name=""
                                                  className="uk-select"
                                                >
                                                  <option value="" selected>
                                                    Copy offer code
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                            <div className="uk-width-2-3">
                                              <div className="formAndBtnWrp">
                                                <div
                                                  className="formInput"
                                                  style={{ width: "80%" }}
                                                >
                                                  <label htmlFor="">
                                                    Offer code
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="uk-input"
                                                    name="text"
                                                    value={val.text}
                                                    onChange={(e) =>
                                                      e.target.value.length <=
                                                        25 &&
                                                      handleTemplatesButton(
                                                        val,
                                                        index,
                                                        val.type,
                                                        "text",
                                                        e
                                                      )
                                                    }
                                                  />
                                                  {errorEnable &&
                                                    val.text === "" && (
                                                      <div className="formErrors">
                                                        field is required
                                                      </div>
                                                    )}
                                                  <div className="txtCounter">
                                                    <span>
                                                      {val.text.length}
                                                    </span>
                                                    <span>/</span>
                                                    <span>25</span>
                                                  </div>
                                                </div>
                                                <div className="formDltBtn">
                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      removeButtons(
                                                        index,
                                                        val.type
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {category === "Authentication" && (
                    <>
                      <div className="addTeamBox">
                        <div className="boxHeading">
                          <h3 className="uk-margin-small-bottom">
                            Code delivery
                          </h3>
                          <p className="uk-margin-remove">
                            Select the method for customers to share the code
                            from WhatsApp to your application.
                          </p>
                        </div>
                        <div className="formwrp">
                          <div className="uk-grid" uk-grid="">
                            <div className="uk-width-1-1">
                              <div className="formInput">
                                <input
                                  type="radio"
                                  name="code_radio"
                                  id="code_radio"
                                  className="broadcastRadio"
                                  onClick={() =>
                                    setCodeDeleiveryOption("Autofill")
                                  }
                                />
                                <span>Autofill</span>
                                <div className="autoFillTxt">
                                  <p>
                                    Recommended as the most convenient choice
                                    for your customers. When auto-fill is not
                                    feasible, a message containing the copy code
                                    will be shared.
                                  </p>
                                </div>
                              </div>
                              {codeDeleiveryOption === "Autofill" && (
                                <div className="formBox">
                                  <div className="formInput">
                                    <label htmlFor="">Package name</label>
                                    <input
                                      type="text"
                                      className="uk-input"
                                      placeholder="Enter text (com.example.myapplication)"
                                      onChange={(e) =>
                                        setAutoFillData({
                                          ...autoFillData,
                                          packageName: e.target.value,
                                        })
                                      }
                                    />
                                    {errorEnable &&
                                      autoFillData.packageName === "" && (
                                        <div className="formErrors">
                                          Package name is required
                                        </div>
                                      )}
                                  </div>
                                  <div className="formInput uk-margin-top">
                                    <label htmlFor="">App signature hash</label>
                                    <input
                                      type="text"
                                      className="uk-input"
                                      placeholder="Enter text"
                                      onChange={(e) =>
                                        setAutoFillData({
                                          ...autoFillData,
                                          signatureHash: e.target.value,
                                        })
                                      }
                                    />
                                    {errorEnable &&
                                      autoFillData.signatureHash === "" && (
                                        <div className="formErrors">
                                          Signature hash is required
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )}
                              <div
                                className="formInput"
                                style={{ marginTop: "34px" }}
                              >
                                <input
                                  type="radio"
                                  name="code_radio"
                                  id="code_radio"
                                  className="broadcastRadio"
                                  onClick={() => setCodeDeleiveryOption("OTP")}
                                />
                                <span>Copy code</span>
                                <div className="autoFillTxt">
                                  <p>
                                    The copy code option is ideal for customers
                                    who request the code on a device without
                                    their WhatsApp account linked.
                                  </p>
                                </div>
                              </div>
                              {errorEnable && codeDeleiveryOption === "" && (
                                <div className="formErrors">
                                  Please select code delivery option
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="addTeamBox">
                        <div className="boxHeading">
                          <h3>Message content</h3>
                          <span style={{ marginTop: "8px" }}>
                            Enter the text for your message in the language that
                            you've selected.
                          </span>
                        </div>
                        <div className="formwrp">
                          <div className="uk-grid" uk-grid="">
                            <div className="uk-width-1-1">
                              <div className="radioInput">
                                <input
                                  type="checkbox"
                                  checked={
                                    messageContent.addSecurityRecommendation
                                  }
                                  onChange={() =>
                                    setMessageContent({
                                      ...messageContent,
                                      addSecurityRecommendation:
                                        !messageContent.addSecurityRecommendation,
                                    })
                                  }
                                />
                                <span>Add security recommendation</span>
                                {errorEnable &&
                                  messageContent.addSecurityRecommendation ===
                                    false && (
                                    <div className="formErrors">
                                      This field is required
                                    </div>
                                  )}
                              </div>
                              <div className="radioInput uk-margin-top">
                                <input
                                  type="checkbox"
                                  checked={messageContent.codeExpiryCheckBox}
                                  onChange={() =>
                                    setMessageContent({
                                      ...messageContent,
                                      codeExpiryCheckBox:
                                        !messageContent.codeExpiryCheckBox,
                                    })
                                  }
                                />

                                <span>Add expiry time for the code</span>
                                {errorEnable &&
                                  messageContent.codeExpiryCheckBox ===
                                    false && (
                                    <div className="formErrors">
                                      This field is required
                                    </div>
                                  )}
                              </div>
                              {messageContent.codeExpiryCheckBox === true && (
                                <div className="formBox">
                                  <div className="formInput">
                                    <label htmlFor="">Code expires in</label>
                                    <select
                                      name=""
                                      className="uk-select"
                                      style={{ width: "322px" }}
                                      onChange={(e) =>
                                        setMessageContent({
                                          ...messageContent,
                                          codeExpirationMinutes: e.target.value,
                                        })
                                      }
                                    >
                                      {options}
                                    </select>
                                    {errorEnable &&
                                      messageContent.codeExpirationMinutes ===
                                        "" && (
                                        <div className="formErrors">
                                          Please select expiration minutes
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="addTeamBox">
                        <div className="boxHeading">
                          <h3>Buttons text</h3>
                          <span>
                            Customize button text for auto-fill and copy code.
                            Buttons are essential for the backup code delivery
                            method, even with zero-tap enabled.
                          </span>
                        </div>
                        <div className="formwrp">
                          <div className="uk-grid" uk-grid="">
                            <div className="uk-width-1-1">
                              <div className="formInput uk-width-1-2">
                                {codeDeleiveryOption === "Autofill" && (
                                  <>
                                    <label htmlFor="">Auto fill</label>
                                    <input
                                      type="text"
                                      className="uk-input"
                                      placeholder="Auto fill"
                                      value={buttonText.autofillText}
                                      onChange={(e) =>
                                        e.target.value.length <= 100 &&
                                        setButtonText({
                                          ...buttonText,
                                          autofillText: e.target.value,
                                        })
                                      }
                                    />
                                    {errorEnable &&
                                      buttonText.autofillText === "" && (
                                        <div className="formErrors">
                                          This field is required
                                        </div>
                                      )}
                                    <div className="txtCounter">
                                      <span>
                                        {buttonText.autofillText.length}
                                      </span>
                                      <span>/</span>
                                      <span>100</span>
                                    </div>
                                  </>
                                )}
                              </div>
                              {codeDeleiveryOption === "OTP" && (
                                <>
                                  <div className="formInput uk-margin-top uk-width-1-2">
                                    <label htmlFor="">Copy code</label>
                                    <input
                                      type="text"
                                      className="uk-input"
                                      placeholder="Copy code"
                                      value={buttonText.copyText}
                                      onChange={(e) =>
                                        e.target.value.length <= 100 &&
                                        setButtonText({
                                          ...buttonText,
                                          copyText: e.target.value,
                                        })
                                      }
                                    />
                                    {errorEnable &&
                                      buttonText.copyText === "" && (
                                        <div className="formErrors">
                                          This field is required
                                        </div>
                                      )}
                                    <div className="txtCounter">
                                      <span>{buttonText.copyText.length}</span>
                                      <span>/</span>
                                      <span>100</span>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="addTeamBox">
                    <div className="boxHeading">
                      <h3>Undertaking</h3>
                      <span className="uk-margin-small-bottom">
                        Please note Meta reserves the right to modify the
                        category assigned to a campaign based on their policies
                        and guidelines to ensure compliance and prevent any
                        rejections.
                      </span>
                    </div>
                    <div className="formwrp">
                      <div className="uk-grid" uk-grid="">
                        <div className="uk-width-1-1">
                          <div className="formInput">
                            <input
                              type="checkbox"
                              className="broadcastRadio"
                              checked={isAgreed}
                              onChange={() => {
                                setIsAgreed(!isAgreed);
                              }}
                            />
                            <span>Agreed</span>
                          </div>
                        </div>
                      </div>
                      {errorEnable && isAgreed !== true && (
                        <div className="formErrors">
                          Agreed undertaking to proceed
                        </div>
                      )}
                    </div>
                    <div className="btnwrp">
                      <button
                        type="button"
                        className="btn-1"
                        onClick={() =>
                          navigate("/template-message", { replace: true })
                        }
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn-2"
                        // onClick={() => createTemplate()}
                      >
                        {loader ? (
                          <div uk-spinner="" className="loader"></div>
                        ) : (
                          "Submit template"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="uk-width-1-3">
                <div className="previewHeading">
                  <h3>Message Template preview</h3>
                </div>
                <div className="mobilePreview" style={{ margin: "0 auto" }}>
                  <div className="mobileMsgs">
                    {/* <div className="msgDate">
                      <span>Friday, November 8</span>
                    </div> */}

                    {category !== "Authentication" &&
                      headers.type === "Text" && (
                        <div className="leftMsg">
                          <span>{headerInputStringText}</span>
                        </div>
                      )}
                    {category !== "Authentication" &&
                      headers.type === "Media" &&
                      headerMediaType === "image" &&
                      headermediaUploadedErrorMessage === "" &&
                      displayImage.image !== "" && (
                        <div className="imgBox">
                          <img src={displayImage.image} alt="" />
                        </div>
                      )}
                    {category !== "Authentication" &&
                      headers.type === "Media" &&
                      headerMediaType === "video" &&
                      headermediaUploadedErrorMessage === "" &&
                      displayImage.video !== "" && (
                        <div className="videoBox">
                          <video src={displayImage.video} controls></video>
                        </div>
                      )}
                    {category !== "Authentication" &&
                      headers.type === "Media" &&
                      headerMediaType === "document" &&
                      headermediaUploadedErrorMessage === "" &&
                      displayImage.document !== "" && (
                        <div className="documentMsg">
                          <p>
                            <Document /> document
                          </p>
                        </div>
                      )}
                    {category !== "Authentication" &&
                      bodyInputStringText !== "" && (
                        <div className="leftMsg">
                          <span>{bodyInputStringText}</span>
                        </div>
                      )}
                    {category !== "Authentication" && footer !== "" && (
                      <div className="leftMsg">
                        <span>{footer}</span>
                      </div>
                    )}

                    {category !== "Authentication" &&
                      templateButtons.length > 0 && (
                        <div className="MsgBox molbileMsgBox">
                          {templateButtons.map((val, index) => {
                            return (
                              val.text !== "" && (
                                <button key={index}>{val.text}</button>
                              )
                            );
                          })}
                        </div>
                      )}

                    {category === "Authentication" &&
                      codeDeleiveryOption === "Autofill" &&
                      buttonText.autofillText !== "" && (
                        <div className="MsgBox molbileMsgBox">
                          <button>{buttonText.autofillText}</button>
                        </div>
                      )}
                    {category === "Authentication" &&
                      codeDeleiveryOption === "OTP" &&
                      buttonText.copyText !== "" && (
                        <div className="MsgBox molbileMsgBox">
                          <button>{buttonText.copyText}</button>
                        </div>
                      )}
                    {/* <div className="imgBox">
                      <img src={mobileImg} alt="" />
                    </div>
                    <div className="videoBox">
                      <video src={chatVideo} controls></video>
                    </div>
                    <div className="leftMsg">
                      <span>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Molestias fugiat sit qui omnis ipsa. Provident!
                      </span>
                    </div>
                    <div className="rightMsg">
                      <span>Lorem ipsum dolor sit amet consectetur.</span>
                    </div>
                    <div className="leftMsg">
                      <span>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Molestias fugiat sit qui omnis ipsa. Provident!
                      </span>
                    </div>
                    <div className="rightMsg">
                      <span>Lorem ipsum dolor sit amet consectetur.</span>
                    </div>
                    <div className="leftMsg">
                      <span>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Molestias fugiat sit qui omnis ipsa. Provident!
                      </span>
                    </div>
                    <div className="rightMsg">
                      <span>Lorem ipsum dolor sit amet consectetur.</span>
                    </div>
                    <div className="leftMsg">
                      <span>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Molestias fugiat sit qui omnis ipsa. Provident!
                      </span>
                    </div>
                    <div className="rightMsg">
                      <span>Lorem ipsum dolor sit amet consectetur.</span>
                    </div>
                    <div className="leftMsg">
                      <span>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Molestias fugiat sit qui omnis ipsa. Provident!
                      </span>
                    </div>
                    <div className="rightMsg">
                      <span>Lorem ipsum dolor sit amet consectetur.</span>
                    </div>
                    <div className="leftMsg">
                      <span>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Molestias fugiat sit qui omnis ipsa. Provident!
                      </span>
                    </div>
                    <div className="rightMsg">
                      <span>Lorem ipsum dolor sit amet consectetur.</span>
                    </div>
                    <div className="leftMsg">
                      <span>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Molestias fugiat sit qui omnis ipsa. Provident!
                      </span>
                    </div>
                    <div className="rightMsg">
                      <span>Lorem ipsum dolor sit amet consectetur.</span>
                    </div>
                    <div className="leftMsg">
                      <span>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Molestias fugiat sit qui omnis ipsa. Provident!
                      </span>
                    </div>
                    <div className="rightMsg">
                      <span>Lorem ipsum dolor sit amet consectetur.</span>
                    </div>
                    <div className="leftMsg">
                      <span>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Molestias fugiat sit qui omnis ipsa. Provident!
                      </span>
                    </div>
                    <div className="rightMsg">
                      <span>Lorem ipsum dolor sit amet consectetur.</span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTempelateMarketing;
