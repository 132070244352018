import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import SuperAdminSettingSidear from "./SuperAdminSettingSidear";
import { apiGet } from "../../../Utils/apiServices";
import Footer from "../../../components/common/Footer/Footer";
import TaxModal from "../../Setting/components/Modal/TaxModal";
import { dateWithAtTime } from "../../../Utils/DateFunctions";
import Switch from "react-switch";

const SuperAdminSetting = () => {
  const [dollarRate, setDollarRate] = useState(null);
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dollarList, setDollarList] = useState(null);

  const getDollarRateList = () => {
    apiGet(
      "/super-admin/list/dollar",
      onSuccesGetDollarRate,
      onFailureGetDollarRate
    );
  };

  const onSuccesGetDollarRate = (response) => {
    setDollarList(response.data);
  };
  const onFailureGetDollarRate = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getDollarRateList();
  }, []);

  const handleOpenDollarModal = () => {
    setOpenModal(true);
  };
  const handleCloseDollarModal = () => {
    setOpenModal(false);
  };

  const addDollarRate = () => {
    setLoader(true);
    if (dollarRate === null) {
      setDollarRate("");
    }
    const newData = { dollarRate: dollarRate };
    if (dollarRate !== null && dollarRate !== "") {
      apiGet(
        `/super-admin/addDollarRate`,
        onSuccesdollar,
        onFailuredollar,
        newData
      );
    } else {
      setLoader(false);
    }
  };

  const onSuccesdollar = (response) => {
    if (response.status === 1) {
      setLoader(false);
      toast.success("Dollar rate added successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
      });
      getDollarRateList();
      setTimeout(() => {
        handleCloseDollarModal();
      }, 1000);
    }
  };
  const onFailuredollar = (error) => {
    console.log(error);
  };
  const handleChecked = (val) => {
    enableUser(val);
  };

  const enableUser = (val) => {
    apiGet(
      `/super-admin/disable/dollar/${val.id}`,
      onSuccessEnable,
      onFailureEnable
    );
  };
  const onSuccessEnable = (response) => {
    if (response?.status === 0) {
      toast.success("Dollar rate active successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      getDollarRateList();
    }
  };
  const onFailureEnable = (error) => {
    console.log(error);
  };
  return (
    <>
      <div className="settingwrp">
        <div className="topBar">
          <div className="uk-grid" uk-grid="">
            <div className="uk-width-1-1">
              <div className="txtwrp">
                <h3>Settings</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="settingContentWrp">
          <div className="uk-grid uk-grid-small" uk-grid="">
            <div className="uk-width-auto">
              <SuperAdminSettingSidear />
            </div>
            <div className="uk-width-expand">
              <div className="settingWrapper">
                <div className="uk-container">
                  <div className="settingTabbing">
                    <ul
                      className="uk-subnav uk-subnav-pill tabbingBtn"
                      uk-switcher=""
                    >
                      <li>
                        <a href="#">Tax</a>
                      </li>
                    </ul>

                    <ul className="uk-switcher uk-margin">
                      <li>
                        <div className="generalSettingWrp">
                          <div className="superadmin-setting">
                            <div className="mainHeading">
                              <h3>Tax settings</h3>
                              <p>
                                View and configure all your tax and dollar rate.
                              </p>
                            </div>
                            <div>
                              <button onClick={() => handleOpenDollarModal()}>
                                Add
                              </button>
                            </div>
                          </div>
                          <div className="boxwrp">
                            <div className="boxHeading">
                              <h6>Dollar prices</h6>
                              <p>
                                This information is visible to others and can be
                                different from your account name if you choose.
                              </p>
                            </div>
                            <div className="formwrp">
                              <form action="javascript:void(0);">
                                {/* <div className="uk-grid uk-grid-small uk-flex-middle">
                                  <div className="uk-width-1-3">
                                    <div className="formInput">
                                      <label htmlFor="">Tax</label>
                                      <input
                                        type="text"
                                        className="uk-input"
                                        defaultValue={
                                          generalSettings?.organizationName
                                        }
                                        onChange={(e) => {
                                          setData({
                                            ...data,
                                            organizationName: e.target.value,
                                          });
                                        }}
                                        disabled
                                      />
                                      {data.organizationName === "" &&
                                        errorEnable && (
                                          <div className="formErrors">
                                            Organization name is required
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div className="uk-width-1-3">
                                    <div className="formInput">
                                      <label htmlFor="">Percentage</label>
                                      <input
                                        type="text"
                                        className="uk-input"
                                        defaultValue={
                                          generalSettings?.companyIndustry
                                        }
                                        onChange={(e) => {
                                          e.target.value === "" &&
                                            setErrorEnable(true);
                                          setData({
                                            ...data,
                                            companyIndustry: e.target.value,
                                          });
                                        }}
                                        disabled
                                      />
                                      {data.companyIndustry === "" &&
                                        errorEnable && (
                                          <div className="formErrors">
                                            Company name is required
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div className="uk-width-1-3">
                                    <div
                                      className="changeBtn"
                                      style={{ textAlign: "right" }}
                                    >
                                      <button
                                        type="button"
                                        onClick={handleOpenDollarModal}
                                      >
                                        Update
                                      </button>
                                    </div>
                                    
                                  </div>
                                </div> */}

                                <div className="tabContent">
                                  <div className="contactTable">
                                    <table className="uk-table uk-table-divider">
                                      <thead>
                                        <tr>
                                          <th>Dollar rate</th>
                                          <th>Create date rate </th>
                                          <th>Update Time</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {dollarList?.length > 0 ? (
                                          dollarList?.map((val, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>{val.dollarRate}</td>
                                                <td className="agentName">
                                                  {dateWithAtTime(
                                                    val.createDateTime
                                                  )}
                                                </td>
                                                <td className="agentName">
                                                  <Switch
                                                    onChange={() =>
                                                      handleChecked(val)
                                                    }
                                                    checked={
                                                      val.isActive === true
                                                        ? true
                                                        : false
                                                    }
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    width={26}
                                                    height={14}
                                                    handleDiameter={10}
                                                    onColor="#29A869"
                                                  />
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan={11}
                                              className="dataNotFound"
                                            >
                                              {dollarList === null ? (
                                                <div uk-spinner=""></div>
                                              ) : (
                                                "Data Not Found"
                                              )}
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <hr />
                              </form>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <TaxModal
          closeModal={handleCloseDollarModal}
          addDollarRate={addDollarRate}
          dollarRate={dollarRate}
          setDollarRate={setDollarRate}
          loader={loader}
        />
      )}
    </>
  );
};

export default SuperAdminSetting;
