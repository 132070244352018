import React from "react";
import whatsappIcon from "../../assets/images/whatsapp-icon.png";
import messengerIcon from "../../assets/images/messenger-icon-new.jpg";
import instaIcon from "../../assets/images/instagram-icon-new.jpg";
import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { useEffect } from "react";
import { apiGet, apiPost } from "../../Utils/apiServices";
import { useAuth } from "../../Context/AuthProvider";
import PauseModal from "./Modal/PauseModal";
import StopModal from "./Modal/StopModal";
import DeleteBroadcastModal from "./Modal/DeleteBroadcastModal";
import moment from "moment";
import { Calendar, ChevronDown, Cost, Download } from "@carbon/icons-react";
import UIkit from "uikit";
import CalendarDate from "../../components/Calendar/CalendarDate";
import { toast } from "react-toastify";
import { debounce, difference } from "lodash";
import {
  decryptedValue,
  encryptedValue,
} from "../../Utils/RouteHashConversion";
import ReactPaginate from "react-paginate";
import axios from "axios";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";
import { formatChatHoverDateTime } from "../../Utils/DateFunctions";
import { channelImages } from "../../Utils/ChannelImages";
const baseUrl = process.env.REACT_APP_BASEURL;

const Broadcast = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const CurrentCompanyName = CompanyName(CompanyHtml);
  const { auth } = useAuth();
  const navigate = useNavigate();
  // const [openNewMessageModal, setOpenNewMessageModal] = useState(false);
  const [broadcast, setBroadcast] = useState(null);
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [size, setSize] = useState(10);
  const [isApiCall, setIsApiCall] = useState(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState("This month");
  const [searchString, setSearchString] = useState("");
  const [openPauseModal, setOpenPauseModal] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [openStopModal, setOpenStopModal] = useState(false);
  // const [valueButton, setValueButton] = useState("");
  const [openDeleteBroadcastModal, setOpenDeleteBroadcastModal] =
    useState(false);
  const filterDropdown = [
    "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];

  // ************************************* functions start ******************************
  const handleCLosePauseModal = () => {
    setOpenPauseModal(false);
  };
  const handleCloseStopModal = () => {
    setOpenStopModal(false);
  };
  const handleCloseDeleteBroadcastModal = () => {
    setOpenDeleteBroadcastModal(false);
  };
  const handleDatePickerClose = () => {
    UIkit.drop("#datepickerdrop").hide(true);
  };
  const handleDateFilter = (filter) => {
    setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    setIsApiCall(true);
  };
  // const handleOpenPauseModal = () => {
  //   setOpenPauseModal(true);
  // };

  // const handleOpenStopModal = () => {
  //   setOpenStopModal(true);
  // };

  // const handleOpenDeleteBroadcastModal = () => {
  //   setOpenDeleteBroadcastModal(true);
  // };

  // const handleDateFilterDropdownrClose = () => {
  //   UIkit.drop("#dateDropdownSection").hide(true);
  // };

  // const handleOpenNewMessageModal = () => {
  //   setOpenNewMessageModal(true);
  // };

  // const handleCloseNewMessageModal = () => {
  //   setOpenNewMessageModal(false);
  // };
  const onSearchChange = (e) => {
    var searchedValue = e.target.value;
    setSearchString(searchedValue);
    setIsApiCall(true);
  };
  const debouncedOnChange = debounce(onSearchChange, 1000);

  const onChange = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
    setIsApiCall(true);
  };
  // ************************************* functions end ******************************

  // ************************************* apis start ******************************
  const exportData = async () => {
    try {
      const response = await axios.get(
        baseUrl + `/broadcast/export/list/${STARTDATE}/${ENDDATE}`,

        {
          responseType: "arraybuffer", // Ensure the response is treated as binary data
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Broadcast_summary.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  const getBroadcast = (page) => {
    apiGet(
      `/broadcast/list/${STARTDATE}/${ENDDATE}?page=${page}&size=${size}&search=${searchString}`,
      onSuccess,
      onFailure
    );
  };
  const onSuccess = (response) => {
    setIsApiCall(false);
    setPageCount(response?.data?.totalPages);
    setBroadcast(response?.data?.content);
  };
  const onFailure = (error) => {
    console.log(error);
  };

  const handlePageChange = (data) => {
    getBroadcast(data.selected);
    setCurrentPage(data.selected);
  };

  const isTimeDifferenceGreaterThan10MinutesFunc = (targetDate, targetTime) => {
    // Parse the provided date and time
    const targetDateTime = new Date(`${targetDate}T${targetTime}:00`);
    // Get the current date and time
    const currentDateTime = new Date();
    // Calculate the time difference in milliseconds
    const timeDifference = currentDateTime - targetDateTime;
    // Check if the time difference is greater than 10 minutes (600,000 milliseconds)
    const absoluteTimeDifference = Math.abs(timeDifference);
    // Check if the absolute time difference is greater than 10 minutes (600,000 milliseconds)
    return absoluteTimeDifference >= 600000 ? true : false;
  };

  const changeBroadcastStatus = (status, val) => {
    let result = null;
    if (status === "CANCELLED") {
      let outcome = isTimeDifferenceGreaterThan10MinutesFunc(
        val.startDate,
        val.startTime
      );
      if (outcome === false) {
        toast.error("Cannot cancel within 10 minutes of scheduled time.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        result = outcome;
      } else {
        result = null;
      }
    }
    if (result === null) {
      apiPost(
        `/broadcast/change/status/${status}/${val.publicId}`,
        onSuccesschangeBroadcastStatus,
        onFailurechangeBroadcastStatus
      );
    }
  };
  const onSuccesschangeBroadcastStatus = (response) => {
    if (response.data === true) {
      toast.success("Broadcast status updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
      });
      getBroadcast(currentPage);
    }
  };
  const onFailurechangeBroadcastStatus = (error) => {
    console.log(error);
  };
  // ************************************* apis end ******************************

  // ************************************* useeffect start ******************************

  useEffect(() => {
    getBroadcast(0);
  }, []);

  useEffect(() => {
    if (isApiCall == true) {
      setBroadcast(null);
      getBroadcast(currentPage);
    }
  }, [isApiCall]);

  useEffect(() => {
    getBroadcast(currentPage);
  }, [size]);
  // ************************************* useeffect end ******************************

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="boradcastWrp">
        <div className="boradcastTabbing">
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #broadcastTabs"
          >
            <li
              onClick={() => {
                navigate("/broadcast", { replace: true });
              }}
            >
              <a>Broadcast</a>
            </li>
            <li
              onClick={() => {
                navigate("/template-message", { replace: true });
              }}
            >
              <a>Template Messages</a>
            </li>
            <li
              onClick={() => {
                navigate("/api-message", { replace: true });
              }}
            >
              <a>API messages</a>
            </li>
          </ul>
          <ul className="broadcastTopBtn uk-switcher" id="broadcastTabs">
            <li>
              <button
                type="button"
                onClick={() => navigate("/create-broadcast", { replace: true })}
              >
                Create Broadcast
              </button>
            </li>
            <li></li>
            <li></li>
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="broadcastTabs">
            <li>
              <div className="broadcastTabWrp">
                <div className="uk-container">
                  <div className="mainHeading">
                    <h2>
                      Broadcast{" "}
                      <CurrentCompanyName companyDetails={companyDetails} />
                    </h2>
                  </div>
                  <div className="searchFilterWrp">
                    <div className="uk-grid uk-flex-middle" uk-grid="">
                      <div className="uk-width-1-2">
                        <div className="searchFilter">
                          <form
                            action=""
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Search by broadcast name"
                              onChange={debouncedOnChange}
                            />
                          </form>
                        </div>
                      </div>
                      <div className="uk-width-1-2 btnSection">
                        <button className="exportBtn" onClick={exportData}>
                          <Download /> Export Summary Data
                        </button>

                        <div className="uk-inline">
                          <button className="calenderBtn rangeBtn">
                            <Calendar /> {selectedDateFilter}
                            <span className="uk-inline">
                              <ChevronDown />
                            </span>
                          </button>
                          <div
                            uk-dropdown="mode: click"
                            className="calendarDropdown"
                          >
                            <div className="dropDownHeading">
                              <span>Select a range</span>
                            </div>
                            <ul>
                              {filterDropdown.map((val, index) => {
                                return (
                                  <React.Fragment key={val}>
                                    <li
                                      className={
                                        selectedDateFilter === val
                                          ? "activeBtn"
                                          : ""
                                      }
                                      onClick={() => handleDateFilter(val)}
                                    >
                                      {filterDropdown.length - 1 === index ? (
                                        <div className="uk-inline uk-text-left">
                                          <button
                                            className="rangeBtn"
                                            type="button"
                                          >
                                            <span className="rangeBtnCircle"></span>{" "}
                                            Custom range
                                          </button>
                                          <div
                                            id="datepickerdrop"
                                            uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                            className="date-picker-container"
                                          >
                                            <CalendarDate onChange={onChange} />
                                            <div className="date-picker-btn-container uk-text-right">
                                              <button
                                                className="date-selection-btn"
                                                onClick={handleDatePickerClose}
                                              >
                                                Ok
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <button
                                          type="button"
                                          className="rangeBtn"
                                        >
                                          <span className="rangeBtnCircle"></span>{" "}
                                          {val}
                                        </button>
                                      )}
                                    </li>
                                    {filterDropdown.length - 2 === index && (
                                      <hr style={{ margin: "5px 0" }} />
                                    )}
                                  </React.Fragment>
                                );
                              })}
                              {/* <li className="active">
                                <button type="button" className="rangeBtn">
                                  <span className="rangeBtnCircle"></span> Last
                                  30 days
                                </button>
                              </li>
                              <li>
                                <button type="button" className="rangeBtn">
                                  <span className="rangeBtnCircle"></span> This
                                  week
                                </button>
                              </li>
                              <li>
                                <button type="button" className="rangeBtn">
                                  <span className="rangeBtnCircle"></span> Last
                                  week
                                </button>
                              </li>
                              <li>
                                <button type="button" className="rangeBtn">
                                  <span className="rangeBtnCircle"></span> This
                                  month
                                </button>
                              </li>
                              <hr style={{ margin: "5px 0" }} />
                              <li>
                                <div className="uk-inline uk-text-left">
                                  <button className="rangeBtn" type="button">
                                    <span className="rangeBtnCircle"></span>{" "}
                                    Custom range
                                  </button>
                                  <div
                                    id="datepickerdrop"
                                    uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                    className="date-picker-container"
                                  >
                                    <CalendarDate onChange={onChange} />
                                    <div className="date-picker-btn-container uk-text-right">
                                      <button
                                        className="date-selection-btn"
                                        onClick={handleDatePickerClose}
                                      >
                                        Ok
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="analyticsTableWrp">
                    <div className="uk-grid" uk-grid=""></div>
                    <table className="uk-table">
                      <thead>
                        <tr>
                          <th>SERIES</th>
                          <th>
                            <div className="uk-inline tableDropDown">
                              <button type="button">
                                ALL CHANNELS
                                {/* <ChevronDown /> */}
                              </button>
                              {/* <div
                                uk-dropdown="mode: click"
                                className="dropdownContent"
                              >
                                <div className="dropdownHeading">
                                  <span>Channels</span>
                                </div>
                                <ul>
                                  <li className="active">
                                    <button type="button">All channels</button>
                                  </li>
                                  <li>
                                    <button type="button">
                                      <img src={whatsappLogo} alt="" /> WhatsApp
                                    </button>
                                  </li>
                                </ul>
                              </div> */}
                            </div>
                          </th>
                          <th className="uk-inline">BROADCAST NAME</th>
                          <th>TRAFFIC</th>

                          <th>
                            <div className="uk-inline tableDropDown">
                              <button type="button">
                                STATUS
                                {/* <ChevronDown /> */}
                              </button>
                              {/* <div
                        uk-dropdown="mode: click"
                        className="dropdownContent"
                      >
                        <div className="dropdownHeading">
                          <span>Contact Staus</span>
                        </div>
                        <ul>
                          <li
                            className="active"
                            style={{ paddingLeft: "25px" }}
                          >
                            <button type="button">Delivered</button>
                          </li>
                          <li style={{ paddingLeft: "25px" }}>
                            <button type="button">Scheduled</button>
                          </li>
                          <li style={{ paddingLeft: "25px" }}>
                            <button type="button">Paused</button>
                          </li>
                          <li style={{ paddingLeft: "25px" }}>
                            <button type="button">In process</button>
                          </li>
                          <li style={{ paddingLeft: "25px" }}>
                            <button type="button">Draft</button>
                          </li>
                          <li style={{ paddingLeft: "25px" }}>
                            <button type="button">Failed</button>
                          </li>
                        </ul>
                      </div> */}
                            </div>
                          </th>
                          <th>CREATED BY</th>
                          <th>SCHEDULED</th>
                          {/* <th>CREATE DATE TIME</th> */}
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {broadcast?.length > 0 ? (
                          broadcast.map((val, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <img
                                    src={channelImages(val?.channel)}
                                    alt=""
                                  />
                                </td>
                                <td className="agnetName wordLimiter">
                                  <p>{val?.name}</p>
                                </td>
                                <td>{val?.traffic}</td>

                                <td>
                                  <span className="serviceInitiated">
                                    {val?.status === null ? "-" : val?.status}
                                  </span>
                                </td>
                                <td className="agnetName">{val?.agentId}</td>
                                <td>
                                  {val?.startDate + " " + val?.startTime}
                                  {/* <div className="tableTimeWrp">
                                    <span>00:00</span>
                                  </div> */}
                                </td>
                                {/* <td>
                                  {formatChatHoverDateTime(val?.timeStamp)}
                                </td> */}
                                {/* <td>
                                  -
                                  <div className="tableTimeWrp">
                                    <span>00:00</span>
                                  </div>
                                </td> */}
                                <td className="broadcastTableBtn">
                                  {val?.status === "COMPLETED" && (
                                    <button
                                      value="View"
                                      type="button"
                                      onClick={() => {
                                        navigate(
                                          `/broadcast-analytics/${encryptedValue(
                                            val?.publicId
                                          )}`,
                                          { replace: false }
                                        );
                                      }}
                                    >
                                      View
                                    </button>
                                  )}
                                  {val?.status === "PENDING" && (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        navigate(
                                          `/broadcast-analytics-preview/${encryptedValue(
                                            val?.publicId
                                          )}`,
                                          { replace: false }
                                        );
                                      }}
                                    >
                                      Preview
                                    </button>
                                  )}
                                  {val?.status === "SCHEDULED" && (
                                    <button
                                      type="button"
                                      value="Cancel"
                                      onClick={() =>
                                        changeBroadcastStatus("CANCELLED", val)
                                      }
                                    >
                                      Cancel
                                    </button>
                                  )}
                                  {val?.status === "CANCELLED" && (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        navigate(
                                          `/broadcast-analytics-preview/${encryptedValue(
                                            val?.publicId
                                          )}`,
                                          { replace: false }
                                        );
                                      }}
                                    >
                                      Preview
                                    </button>
                                  )}
                                  {val?.status === "STOPPED" && (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        changeBroadcastStatus(val?.status, val)
                                      }
                                    >
                                      Resume
                                    </button>
                                  )}
                                </td>
                                {/* <td>
                                  <div className="uk-inline">
                                    <button type="button">
                                      <OverflowMenuVertical />
                                    </button>
                                    <div uk-dropdown="" className="">
                                      <div className="tableDropDown">
                                        <ul> */}
                                {/* <li>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                navigate(
                                                  `/broadcast/${val?.publicId}`,
                                                  { replace: true }
                                                );
                                              }}
                                            >
                                              <Edit /> Edit
                                            </button>
                                          </li> */}
                                {/* <li>
                                <button type="button">
                                  <Download /> Download data
                                </button>
                              </li> */}
                                {/* <hr /> */}
                                {/* <li>
                                            <button
                                              className="trashBtn"
                                              type="button"
                                              onClick={
                                                handleOpenDeleteBroadcastModal
                                              }
                                            >
                                              <TrashCan /> Delete
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </td> */}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={11} className="dataNotFound">
                              {broadcast === null ? (
                                <div uk-spinner=""></div>
                              ) : (
                                "Data Not Found"
                              )}
                            </td>
                          </tr>
                        )}

                        {/* <tr>
                  <td>1</td>
                  <td>
                    <img src={whatsappLogo} alt="" />
                  </td>
                  <td className="agnetName">Weekend Mega Sales! 78% Off!</td>
                  <td>200,343</td>
                  <td>100% Delivery</td>
                  <td>
                    <span className="inProcess">Scheduled</span>
                  </td>
                  <td className="agnetName">Usama</td>
                  <td>12 Aug</td>
                  <td>11 Aug</td>
                  <td className="broadcastTableBtn">
                    <button type="button" onClick={handleOpenPauseModal}>
                      Pause
                    </button>
                  </td>
                  <td>
                    <div className="uk-inline">
                      <button type="button">
                        <OverflowMenuVertical />
                      </button>
                      <div uk-dropdown="" className="">
                        <div className="tableDropDown">
                          <ul>
                            <li>
                              <button type="button">
                                <DocumentView /> View
                              </button>
                            </li>
                            <li>
                              <button type="button">
                                <Download /> Download data
                              </button>
                            </li>
                            <hr />
                            <li>
                              <button className="trashBtn" type="button">
                                <TrashCan /> Delete
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    <img src={whatsappLogo} alt="" />
                  </td>
                  <td className="agnetName">Weekend Mega Sales! 78% Off!</td>
                  <td>200,343</td>
                  <td>100% Delivery</td>
                  <td>
                    <span className="inProcessYellow">In process</span>
                  </td>
                  <td className="agnetName">Usama</td>
                  <td>12 Aug</td>
                  <td>11 Aug</td>
                  <td className="broadcastTableBtn">
                    <button type="button">Edit</button>
                  </td>
                  <td>
                    <div className="uk-inline">
                      <button type="button">
                        <OverflowMenuVertical />
                      </button>
                      <div uk-dropdown="" className="">
                        <div className="tableDropDown">
                          <ul>
                            <li>
                              <button type="button">
                                <DocumentView /> View
                              </button>
                            </li>
                            <li>
                              <button type="button">
                                <Download /> Download data
                              </button>
                            </li>
                            <hr />
                            <li>
                              <button className="trashBtn" type="button">
                                <TrashCan /> Delete
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    <img src={whatsappLogo} alt="" />
                  </td>
                  <td className="agnetName">Weekend Mega Sales! 78% Off!</td>
                  <td>200,343</td>
                  <td>100% Delivery</td>
                  <td>
                    <span className="broadcastFailed">
                      <WarningAltFilled /> Failed
                    </span>
                  </td>
                  <td className="agnetName">Usama</td>
                  <td>12 Aug</td>
                  <td>11 Aug</td>
                  <td className="broadcastTableBtn">
                    <button type="button" onClick={handleOpenStopModal}>
                      Stop
                    </button>
                  </td>
                  <td>
                    <div className="uk-inline">
                      <button type="button">
                        <OverflowMenuVertical />
                      </button>
                      <div uk-dropdown="" className="">
                        <div className="tableDropDown">
                          <ul>
                            <li>
                              <button type="button">
                                <DocumentView /> View
                              </button>
                            </li>
                            <li>
                              <button type="button">
                                <Download /> Download data
                              </button>
                            </li>
                            <hr />
                            <li>
                              <button className="trashBtn" type="button">
                                <TrashCan /> Delete
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr> */}
                      </tbody>
                    </table>
                    <div className="tablePaginationBox ">
                      <div className="uk-margin-left">
                        Show{" "}
                        <select onChange={(e) => setSize(e.target.value)}>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                        </select>{" "}
                        entries
                      </div>
                      <div>
                        {pageCount > 1 && (
                          <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"...."}
                            pageCount={pageCount}
                            onPageChange={handlePageChange}
                            containerClassName={
                              "uk-pagination uk-float-right contatPagination"
                            }
                            activeClassName={"uk-active"}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={6}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {openPauseModal && (
                  <PauseModal closeModal={handleCLosePauseModal} />
                )}
                {openStopModal && (
                  <StopModal closeModal={handleCloseStopModal} />
                )}
                {openDeleteBroadcastModal && (
                  <DeleteBroadcastModal
                    closeModal={handleCloseDeleteBroadcastModal}
                  />
                )}
              </div>
            </li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Broadcast;
