import React from "react";
import { Calendar, ChevronDown, Download } from "@carbon/icons-react";
import { useState } from "react";
import { useEffect } from "react";
import { apiGet } from "../../Utils/apiServices";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  convertTimestamp,
  formatChatHoverDateTime,
  contactTime,
} from "../../Utils/DateFunctions";
import moment from "moment/moment";
import UIkit from "uikit";
import CalendarDate from "../../components/Calendar/CalendarDate";
import { debounce } from "lodash";
import ReactPaginate from "react-paginate";
import { encryptedValue } from "../../Utils/RouteHashConversion";
import axios from "axios";
import { useAuth } from "../../Context/AuthProvider";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";
import { hasPermissions } from "../../Utils/RoutePermissions";
// import CompanyHocComponent from "../../components/hoc/CompanyHocComponent";
const baseUrl = process.env.REACT_APP_BASEURL;
const Statistics = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const CurrentCompanyName = CompanyName(CompanyHtml);
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [stats, setStats] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [isApiCall, setIsApiCall] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState("This month");
  const [searchString, setSearchString] = useState("");
  const [users, setusers] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState("");
  const filterDropdown = [
    // "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];
  const pageTabs = [
    {
      route: "/analytics",
      name: "Overview",
      permissions: ["FINANCE-DATA-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/live-chat-analytics",
      name: "Live Chat",
      permissions: ["ANALYTICS-MANAGER", "LIVE-CHAT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/chatbot-analytics",
      name: "Chatbot",
      permissions: ["CHATBOT-SUPERVISOR", "CHAT-BOT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/statistics",
      name: "Statistics",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: true,
      allPermissionsMandatory: true,
    },
    {
      route: "/package-mau",
      name: "Package",
      permissions: ["ANALYTICS-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/orders",
      name: "Orders",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/transfer-logs",
      name: "Transfer Logs",
      permissions: [],
      active: false,
      allPermissionsMandatory: true,
    },
  ];
  // const [details, setDetails] = useState({
  //   number: null,
  //   channelId: null,
  //   agentName: null,
  //   agentId: null,
  //   publicId: null,
  // });

  // useEffect(() => {
  //   if (stats !== undefined) {
  //     setDetails({
  //       number: stats?.mobile,
  //       channelId: stats?.chanel,
  //       agentName: stats?.agent == "" ? "NA" : stats?.agent,
  //       agentId: stats?.agentId == "" ? "NA" : stats?.agentId,
  //       publicId: stats?.conversationId,
  //     });
  //   }
  // }, [stats]);

  // useEffect(() => {
  //   if (contactProfile !== undefined) {
  //     setDetails({
  //       number: contactProfile?.conversation?.number,
  //       channelId: contactProfile?.contact?.channelId,
  //       agentName:
  //         contactProfile?.conversation?.agentName == ""
  //           ? "NA"
  //           : contactProfile?.conversation?.agentName,
  //       agentId:
  //         contactProfile?.conversation?.agentId == ""
  //           ? "NA"
  //           : contactProfile?.conversation?.agentId,
  //       publicId: contactProfile?.conversation?.publicId,
  //     });
  //   }
  // }, [contactProfile]);

  const exportData = async () => {
    try {
      const response = await axios.get(
        baseUrl + `/stats/export/conversation-stats/${STARTDATE}/${ENDDATE}`,
        {
          responseType: "arraybuffer", // Ensure the response is treated as binary data
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Conversation_stats.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  const getStats = (page) => {
    apiGet(
      `/stats/conversation-stats/${STARTDATE}/${ENDDATE}?page=${page}&size=${size}&agent=${selectedAgent}&searchString=${searchString}`,
      onSuccessStats,
      onFailureStats
    );
  };
  const onSuccessStats = (response) => {
    setIsApiCall(false);
    setIsSelected(false);
    setPageCount(response?.data?.totalPages);
    setStats(response?.data?.content);
  };
  const onFailureStats = (error) => {
    console.log(error);
  };
  const onSearchChange = (e) => {
    // setCurrentPage(0);
    var searchedValue = e.target.value;
    setSearchString(searchedValue);
    setIsApiCall(true);
  };
  const getUsers = () => {
    apiGet(`/agent/list/AGENT`, onSuccessUsers, onFailureUsers);
  };
  const onSuccessUsers = (response) => {
    setusers(response?.data);
  };
  const onFailureUsers = (error) => {
    console.log(error);
  };

  const debouncedOnChange = debounce(onSearchChange, 1000);

  useEffect(() => {
    getStats(0);
    getUsers();
  }, []);
  useEffect(() => {
    if (isApiCall == true) {
      if (selectedDateFilter === "Custom range") {
        if (isSelected === true) {
          setStats(null);
          getStats(0);
        }
      } else {
        setStats(null);
        getStats(0);
      }
    }
  }, [isApiCall]);

  const handleDatePickerClose = () => {
    if (selectedDateFilter === "Custom range") {
      setIsSelected(true);
    }
    UIkit.drop("#datepickerdrop").hide(true);
  };
  // const handleDateFilterDropdownrClose = () => {
  //   UIkit.drop("#dateDropdownSection").hide(true);
  // };
  const handleDateFilter = (filter) => {
    // setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    if (filter !== "Custom range") {
      setIsApiCall(true);
    }
  };
  const onChange = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
    // setIsApiCall(true);
  };
  const handlePageChange = (data) => {
    setStats(null);
    getStats(data.selected);
    setCurrentPage(data.selected);
  };
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (selectedDateFilter !== "Custom range") {
  //       handleDateFilterDropdownrClose();
  //     }
  //   }, 1000);
  // }, [selectedDateFilter]);

  useEffect(() => {
    if (isSelected === true) {
      setIsApiCall(true);
    }
  }, [isSelected]);

  useEffect(() => {
    getStats(0);
  }, [size]);

  const formatTime = (timeInSeconds) => {
    // Convert seconds to minutes, hours, or days
    const minutes = Math.floor(timeInSeconds / 60);
    const hours = Math.floor(timeInSeconds / 3600);
    const days = Math.floor(timeInSeconds / 86400);

    if (days > 0) {
      return `${days} days`;
    } else if (hours > 0) {
      return `${hours} hours`;
    } else if (minutes > 0) {
      return `${minutes} minutes`;
    } else {
      return `${timeInSeconds} seconds`;
    }
  };
  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="boradcastWrp">
        <div className="boradcastTabbing">
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #newAnalyticsTab"
          >
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                <li
                  onClick={() => {
                    navigate(val.route, { replace: true });
                  }}
                  className={val.active === true ? "uk-active" : ""}
                  key={index}
                >
                  <a>{val.name}</a>
                </li>
              ) : null;
            })}
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="newAnalyticsTab">
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                val.name === "Statistics" ? (
                  <li key={index}>
                    <div
                      className="overviewContent"
                      style={{ minHeight: "100vh" }}
                    >
                      <div className="uk-container">
                        <div className="overviewTopDropdown">
                          <div className="uk-grid uk-flex-middle" uk-grid="">
                            <div className="uk-width-1-1">
                              <div className="pageHeading">
                                <h3>
                                  Statistics{" "}
                                  <CurrentCompanyName
                                    companyDetails={companyDetails}
                                  />
                                </h3>
                              </div>
                            </div>
                            <div className="uk-width-1-2 uk-margin-remove-top">
                              <div className="searchBar">
                                <form
                                  action=""
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <input
                                    type="text"
                                    placeholder="Search by user name"
                                    onChange={debouncedOnChange}
                                  />
                                </form>
                              </div>
                            </div>
                            <div className="uk-width-1-2 btnSection uk-margin-remove-top">
                              <select
                                name=""
                                className="uk-select statsSelect"
                                onChange={(e) => {
                                  setIsApiCall(true);
                                  setSelectedAgent(e.target.value);
                                }}
                              >
                                <option value="" selected>
                                  Select an agent
                                </option>
                                {users?.map((val, index) => (
                                  <option key={index} value={val.publicId}>
                                    {val.firstName + " " + val.lastName}
                                  </option>
                                ))}
                              </select>
                              <button
                                className="exportBtn"
                                onClick={exportData}
                              >
                                <Download /> Export Data
                              </button>

                              <div className="uk-inline">
                                <button className="calenderBtn rangeBtn">
                                  <Calendar /> {selectedDateFilter}
                                  <span className="uk-inline">
                                    <ChevronDown />
                                  </span>
                                </button>
                                <div
                                  uk-dropdown="mode: click"
                                  className="calendarDropdown"
                                  id="dateDropdownSection"
                                >
                                  <div className="dropDownHeading">
                                    <span>Select a range</span>
                                  </div>
                                  <ul>
                                    {filterDropdown.map((val, index) => {
                                      return (
                                        <React.Fragment key={index}>
                                          <li
                                            className={
                                              selectedDateFilter === val
                                                ? "activeBtn"
                                                : ""
                                            }
                                            onClick={() => {
                                              setSelectedDateFilter(val);
                                              val !== "Custom range" &&
                                                handleDateFilter(val);
                                            }}
                                          >
                                            {filterDropdown.length - 1 ===
                                            index ? (
                                              <div className="uk-inline uk-text-left">
                                                <button
                                                  className="rangeBtn"
                                                  type="button"
                                                >
                                                  <span className="rangeBtnCircle"></span>{" "}
                                                  Custom range
                                                </button>
                                                <div
                                                  id="datepickerdrop"
                                                  uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                  className="date-picker-container"
                                                >
                                                  <CalendarDate
                                                    onChange={onChange}
                                                  />
                                                  <div className="date-picker-btn-container uk-text-right">
                                                    <button
                                                      className="date-selection-btn"
                                                      onClick={() => {
                                                        handleDatePickerClose();
                                                      }}
                                                    >
                                                      Ok
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <button
                                                type="button"
                                                className="rangeBtn"
                                              >
                                                <span className="rangeBtnCircle"></span>{" "}
                                                {val}
                                              </button>
                                            )}
                                          </li>
                                          {filterDropdown.length - 2 ===
                                            index && (
                                            <hr style={{ margin: "5px 0" }} />
                                          )}
                                        </React.Fragment>
                                      );
                                    })}
                                    {/* <li className="active">
                                <button type="button" className="rangeBtn">
                                  <span className="rangeBtnCircle"></span> Last
                                  30 days
                                </button>
                              </li>
                              <li>
                                <button type="button" className="rangeBtn">
                                  <span className="rangeBtnCircle"></span> This
                                  week
                                </button>
                              </li>
                              <li>
                                <button type="button" className="rangeBtn">
                                  <span className="rangeBtnCircle"></span> Last
                                  week
                                </button>
                              </li>
                              <li>
                                <button type="button" className="rangeBtn">
                                  <span className="rangeBtnCircle"></span> This
                                  month
                                </button>
                              </li> */}
                                    {/* <hr style={{ margin: "5px 0" }} /> */}
                                    {/* <li className="active">
                                <div className="uk-inline uk-text-left">
                                  <button className="rangeBtn" type="button">
                                    <span className="rangeBtnCircle"></span>{" "}
                                    Custom range
                                  </button>
                                  <div
                                    id="datepickerdrop"
                                    uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                    className="date-picker-container"
                                  >
                                    <CalendarDate onChange={onChange} />
                                    <div className="date-picker-btn-container uk-text-right">
                                      <button
                                        className="date-selection-btn"
                                        onClick={handleDatePickerClose}
                                      >
                                        Ok
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li> */}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="uk-width-1-1">
                              <div className="statisticsTable">
                                <div className="uk-overflow-auto statisticsTableThumb">
                                  <table className="uk-table">
                                    <thead>
                                      <tr>
                                        {/* <th>Series</th> */}
                                        <th className="uk-table-expand">
                                          Account
                                        </th>
                                        <th className="uk-table-expand">
                                          User name
                                        </th>
                                        <th className="uk-table-expand tableExpandWidth">
                                          Channel <ChevronDown />
                                        </th>
                                        <th className="uk-table-expand tableExpandWidth">
                                          Coversation ID
                                        </th>
                                        <th className="uk-table-expand tableExpandWidth">
                                          Contact number
                                        </th>
                                        <th className="uk-table-expand">
                                          Contact name
                                        </th>
                                        <th className="uk-table-expand tableExpandWidth">
                                          First response
                                        </th>
                                        <th className="uk-table-expand tableExpandWidth">
                                          First assignment
                                        </th>
                                        <th className="uk-table-expand tableExpandWidth">
                                          Total msg
                                        </th>
                                        <th>Inbound</th>
                                        <th>Outbound</th>
                                        <th className="uk-table-expand tableExpandWidth">
                                          Status <ChevronDown />
                                        </th>
                                        <th className="uk-table-expand tableExpandWidth">
                                          Opt-in
                                        </th>
                                        <th className="uk-table-expand tableExpandWidth">
                                          Resolution time
                                        </th>
                                        <th className="uk-table-expand tableExpandWidth">
                                          Wait time
                                        </th>
                                        {/* <th className="uk-table-expand tableExpandWidth">
                                      Contact tags
                                    </th> */}
                                        <th className="uk-table-expand tableExpandWidth">
                                          Updated
                                        </th>
                                        <th className="uk-table-expand tableExpandWidth">
                                          Created
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {stats?.length > 0 ? (
                                        stats?.map((val, index) => {
                                          return (
                                            <tr key={index}>
                                              {/* <td>{index + 1}</td> */}
                                              <td>{val.account}</td>
                                              <td>
                                                {val.agent === ""
                                                  ? "Unassigned"
                                                  : val.agent.replace(
                                                      /undefined/gi,
                                                      ""
                                                    )}
                                              </td>
                                              <td>{val.chanel}</td>
                                              <td
                                                className="coloredName"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  const dataToStore = {
                                                    mobile: val.mobile,
                                                    chanel: val.chanel,
                                                    agent: val.agent,
                                                    agentId: val.agentId,
                                                    conversationId:
                                                      val.conversationId,
                                                  };

                                                  // Convert the data to a JSON string
                                                  const dataString =
                                                    JSON.stringify(dataToStore);

                                                  // Store the data string in session storage
                                                  sessionStorage.setItem(
                                                    "myData",
                                                    dataString
                                                  );
                                                  // Retrieve the data string from session storage
                                                  const storedDataString =
                                                    sessionStorage.getItem(
                                                      "myData"
                                                    );

                                                  if (storedDataString) {
                                                    // Parse the data string to a JSON object
                                                    const storedData =
                                                      JSON.parse(
                                                        storedDataString
                                                      );

                                                    // Now you can access the stored data like this:
                                                    const mobile =
                                                      storedData.mobile;
                                                    // const chanel =
                                                    //   storedData.chanel;
                                                    // const agent = storedData.agent;
                                                    // const agentId =
                                                    //   storedData.agentId;
                                                    // const conversationId =
                                                    //   storedData.conversationId;

                                                    // navigate(
                                                    //   `/chat/${encryptedValue(
                                                    //     mobile
                                                    //   )}`,
                                                    //   {
                                                    //     replace: false,
                                                    //   }
                                                    // );

                                                    window.open(
                                                      `/chat/${encryptedValue(
                                                        mobile
                                                      )}`,
                                                      "_blank"
                                                      // "rel=noopener noreferrer"
                                                    );

                                                    // Use the retrieved data as needed
                                                  }

                                                  // Navigate to the desired location
                                                }}
                                              >
                                                <a>
                                                  {val.conversationId?.substring(
                                                    0,
                                                    11
                                                  )}
                                                </a>
                                              </td>
                                              <td>{val.mobile}</td>
                                              <td>{val.customerName}</td>
                                              <td>
                                                {val.firstAgentResponseTime !==
                                                null
                                                  ? formatChatHoverDateTime(
                                                      val.firstAgentResponseTime
                                                    )
                                                  : "-"}
                                              </td>
                                              <td>
                                                {val.agentAssigmentTime === null
                                                  ? formatChatHoverDateTime(
                                                      val.conversationCreatedAt
                                                    )
                                                  : formatChatHoverDateTime(
                                                      val.agentAssigmentTime
                                                    )}
                                              </td>
                                              <td>{val.totalMessage}</td>
                                              <td>{val.inboundMessage}</td>
                                              <td>{val.outboundMessage}</td>
                                              <td>{val.conversationStatus}</td>
                                              <td>
                                                {val.optIn !== null
                                                  ? val.optIn
                                                  : "-"}
                                              </td>
                                              <td>
                                                {val.resolutionTime &&
                                                val.conversationCreatedAt
                                                  ? formatTime(
                                                      +val.resolutionTime -
                                                        +val.conversationCreatedAt
                                                    )
                                                  : "-"}
                                              </td>
                                              <td>
                                                {val.firstAgentResponseTime &&
                                                val.conversationCreatedAt !==
                                                  null
                                                  ? formatTime(
                                                      val.firstAgentResponseTime -
                                                        (val.agentAssigmentTime ===
                                                        null
                                                          ? val.conversationCreatedAt
                                                          : val.agentAssigmentTime)
                                                    )
                                                  : "-"}
                                              </td>
                                              {/* <td>-</td> */}
                                              <td>-</td>
                                              <td>
                                                {formatChatHoverDateTime(
                                                  val.conversationCreatedAt
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td
                                            colSpan={18}
                                            className="dataNotFound"
                                          >
                                            {stats === null ? (
                                              <div uk-spinner=""></div>
                                            ) : (
                                              "Data Not Found"
                                            )}
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="tablePaginationBox uk-margin-top">
                                  <div className="uk-margin-left">
                                    Show{" "}
                                    <select
                                      onChange={(e) => setSize(e.target.value)}
                                    >
                                      <option value="10">10</option>
                                      <option value="20">20</option>
                                      <option value="50">50</option>
                                    </select>{" "}
                                    entries
                                  </div>
                                  <div>
                                    {pageCount > 1 && (
                                      <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        breakLabel={"...."}
                                        pageCount={pageCount}
                                        onPageChange={handlePageChange}
                                        containerClassName={
                                          "uk-pagination uk-float-right contatPagination"
                                        }
                                        activeClassName={"uk-active"}
                                        marginPagesDisplayed={3}
                                        pageRangeDisplayed={6}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li key={index}></li>
                )
              ) : null;
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Statistics;
