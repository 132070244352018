import React, { useState } from "react";
import Switch from "react-switch";
import { Checkmark, ChevronLeft, View, ViewOff } from "@carbon/icons-react";
import { Link, useNavigate } from "react-router-dom";
import {
  ValidateEmail,
  validatePhoneNumber,
} from "../../../Utils/regexFunctions";
import { toast } from "react-toastify";
import { apiPost } from "../../../Utils/apiServices";

const CreateSuperAdmin = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [errorEnable, setErrorEnable] = useState(false);
  const [password, setPassword] = useState("");
  const [errorSpan, setErrorSpan] = useState("");
  const [pwdPassword, setPwdPassword] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [numbers, setNumbers] = useState(false);
  const [lengths, setLengths] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [submittedData, setSubmittedData] = useState({
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    confirmPassword: "",
    firebaseId: "string",
    firebaseToken: "string",
    macAddress: "string",
    imageUrl: "string",
    companyId: "0001",
    role: ["ROLE_SUPER_ADMIN"],
  });

  const handleEmailValidation = (e) => {
    var Email = ValidateEmail(e);
    if (Email === "Invalid Email") {
      setEmailError("Invalid Email Address");
    } else {
      setEmailError("Valid Email Address");
    }
  };
  const handleValidation = (e) => {
    let value = e.target.value;
    if (value === submittedData.confirmPassword) {
      setPwdPassword(true);
      setErrorSpan(" ");
    } else if (value !== password) {
      setErrorSpan("Password requirements not matched");
      setPwdPassword(false);
    }
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const lengthRegex = /^.{8,}$/;

    const isLowercaseValid = lowercaseRegex.test(value);
    const isUppercaseValid = uppercaseRegex.test(value);
    const isNumberValid = numberRegex.test(value);
    const isLengthValid = lengthRegex.test(value);

    setLowerCase(isLowercaseValid);
    setUpperCase(isUppercaseValid);
    setNumbers(isNumberValid);
    setLengths(isLengthValid);
    setPassword(value);
  };

  const handleConfirmPwdValidation = (e) => {
    let value = e.target.value;
    if (value === password) {
      setPwdPassword(true);
      setErrorSpan(" ");
    } else if (value !== password) {
      setErrorSpan("Password requirements not matched");
      setPwdPassword(false);
    }
  };

  const handleViewPassword = () => {
    setViewPassword(!viewPassword);
  };

  const handleViewConfirmPassword = () => {
    setViewConfirmPassword(!viewConfirmPassword);
  };

  const addUser = (e) => {
    e.preventDefault();
    setErrorEnable(true);
    setLoader(true);
    let data = {
      email: submittedData.email,
      name: submittedData.name,
      password: submittedData.password,
      role: ["ROLE_SUPER_ADMIN"],
      phoneNumber: submittedData.phoneNumber,
      firebaseId: "string",
      firebaseToken: "string",
      macAddress: "string",
      imageUrl: "string",
      companyId: "0001",
    };

    if (
      submittedData.name !== "" &&
      submittedData.password !== "" &&
      submittedData.phoneNumber !== "" &&
      submittedData.email !== "" &&
      emailError === "Valid Email Address" &&
      lowerCase === true &&
      upperCase === true &&
      numbers === true &&
      lengths === true &&
      pwdPassword === true &&
      isNumberValid === true
    ) {
      apiPost(`/auth/register`, onSuccess, onFailure, data);
    } else {
      setLoader(false);
    }
  };
  const onSuccess = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setLoader(false);
        toast.success("Super admin added successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1000);
      setTimeout(() => {
        navigate("/super-admin-users", { replace: true });
      }, 2000);
    }
  };
  const onFailure = (error) => {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoader(false);
  };

  // {
  //   "name": "Hasan Abbas SuperAdmin",
  //   "email": "superadmin@yopmail.com",
  //   "password": "Hasan@123",
  //   "phoneNumber": "9234523344",
  //   "firebaseId": "string",
  //   "firebaseToken": "string",
  //   "macAddress": "string",
  //   "imageUrl": "string",
  //   "companyId": "0001",
  //   "role": [
  //     "ROLE_SUPER_ADMIN"
  //   ]
  // }

  return (
    <div className="addTeamWrp">
      <div className="uk-container">
        <div className="backBtn">
          <Link to="/super-admin-users">
            <ChevronLeft /> Back to all Super Admin
          </Link>
        </div>
        <div className="addTeamHeading">
          <h3>Create Super Admin</h3>
          <p>
            Enter details for the new user, assign roles, and select teams this
            user belongs to.
          </p>
        </div>
        <div className="addTeamBox">
          <div className="boxHeading">
            <h3>User information</h3>
          </div>
          <div className="formwrp">
            <form onSubmit={addUser} autoComplete="off">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">Name</label>
                    <input
                      type="text"
                      placeholder="Enter name"
                      className="uk-input"
                      value={submittedData.name}
                      onChange={(e) => {
                        e.target.value.length <= 20 &&
                          setSubmittedData({
                            ...submittedData,
                            name: e.target.value,
                          });
                      }}
                    />
                    <div className="txtCounter" style={{ top: "35px" }}>
                      <span>{submittedData?.name?.length}</span>
                      <span>/</span>
                      <span>20</span>
                    </div>
                    {submittedData.name === "" && errorEnable && (
                      <div className="formErrors">Name is required</div>
                    )}
                  </div>
                </div>
                {/* <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">Last name</label>
                    <input
                      type="text"
                      placeholder="Last name"
                      className="uk-input"
                    />
                    <div className="txtCounter" style={{ top: "35px" }}>
                      <span>0</span>
                      <span>/</span>
                      <span>10</span>
                    </div>
                  </div>
                </div> */}
                {/* <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="work_number">Work number</label>
                    <input
                      type="text"
                      placeholder="Work number"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          phoneNumberWork: e.target.value,
                        });
                      }}
                    />
                    {submittedData.phoneNumberWork === "" && errorEnable && (
                      <div className="formErrors">work number is required</div>
                    )}
                  </div>
                </div> */}
                {/* <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="mobile_no">Work number</label>
                    <input
                      type="number"
                      placeholder="Work number"
                      className="uk-input"
                    />
                    <div className="txtCounter" style={{ top: "35px" }}>
                      <span>0</span>
                      <span>/</span>
                      <span>20</span>
                    </div>
                  </div>
                </div> */}
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="mobile_no">Mobile Number</label>
                    <input
                      type="number"
                      placeholder="92 XXX XXX XXXX"
                      className="uk-input"
                      value={submittedData.phoneNumber}
                      onChange={(e) => {
                        let error = validatePhoneNumber(e.target.value);
                        setIsNumberValid(error);
                        e.target.value.length <= 20 &&
                          setSubmittedData({
                            ...submittedData,
                            phoneNumber: e.target.value,
                          });
                      }}
                    />
                    <div className="txtCounter" style={{ top: "35px" }}>
                      <span>{submittedData?.phoneNumber?.length}</span>
                      <span>/</span>
                      <span>20</span>
                    </div>
                    {submittedData.phoneNumber === "" && errorEnable && (
                      <div className="formErrors">Phone number is required</div>
                    )}
                    {submittedData.phoneNumber !== "" && !isNumberValid && (
                      <div className="formErrors">Invalid phone number</div>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="email">Email</label>
                    <input
                      autoComplete="off"
                      type="text"
                      placeholder="Enter email address"
                      value={submittedData?.email}
                      className="uk-input"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          email: e.target.value,
                        });
                        handleEmailValidation(e.target.value);
                      }}
                      // autoComplete="new-email"
                    />
                    {submittedData.email === "" && errorEnable && (
                      <div className="formErrors">Email is required</div>
                    )}
                    {submittedData.email !== "" &&
                      emailError !== "Valid Email Address" && (
                        <div className="formErrors">Invalid Email Address</div>
                      )}
                  </div>
                </div>
                {/* <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="team">Team</label>
                    <select name="team" className="uk-select">
                      <option value="" disabled selected>
                        Select team...
                      </option>
                      <option>option1</option>
                    </select>
                    {submittedData.team === "" && errorEnable && (
                      <div className="formErrors">Please select team</div>
                    )}
                  </div>
                </div> */}
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="password">Password</label>
                    <input
                      type={viewPassword ? "text" : "password"}
                      className="uk-input"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          password: e.target.value,
                        });
                        handleValidation(e);
                      }}
                      autoComplete="off"
                      placeholder="Enter password"
                    />
                    <button type="button" onClick={handleViewPassword}>
                      {viewPassword ? <View /> : <ViewOff />}
                    </button>
                    {submittedData.password === "" && errorEnable && (
                      <div className="formErrors">Password is required</div>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label>Confirm password</label>
                    <input
                      type={viewConfirmPassword ? "text" : "password"}
                      className="uk-input"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          confirmPassword: e.target.value,
                        });
                        handleConfirmPwdValidation(e);
                      }}
                      autoComplete="off"
                      placeholder="Enter confirm password"
                    />
                    <button type="button" onClick={handleViewConfirmPassword}>
                      {viewConfirmPassword ? <View /> : <ViewOff />}
                    </button>
                    {submittedData.confirmPassword === "" && errorEnable && (
                      <div className="formErrors">Confirm password is required</div>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="passMatchWrp passwordContainerNew">
                    <ul>
                      <li>
                        <span
                          className={
                            lowerCase === true ? "active" : "nonActive"
                          }
                        >
                          <Checkmark />
                        </span>
                        <span className="checkmarktxt">Lowercase letter</span>
                      </li>
                      <li>
                        <span
                          className={
                            upperCase === true ? "active" : "nonActive"
                          }
                        >
                          <Checkmark />
                        </span>
                        <span className="checkmarktxt">Uppercase letter</span>
                      </li>
                      <li>
                        <span
                          className={numbers === true ? "active" : "nonActive"}
                        >
                          <Checkmark />
                        </span>
                        <span className="checkmarktxt">Number</span>
                      </li>
                      <li>
                        <span
                          className={lengths === true ? "active" : "nonActive"}
                        >
                          <Checkmark />
                        </span>
                        <span className="checkmarktxt">
                          Minimum 8 characters
                        </span>
                      </li>
                      <li>
                        <span
                          className={
                            pwdPassword === true ? "active" : "nonActive"
                          }
                        >
                          <Checkmark />
                        </span>
                        <span className="checkmarktxt">
                          password and confirm password
                        </span>
                      </li>
                    </ul>

                    <span
                      className="formErrors uk-margin-top "
                      style={{ display: "block" }}
                    >
                      {errorSpan}
                    </span>
                  </div>
                </div>
                {/* <div className="uk-width-1-1">
                  <div className="adminRoleWrp">
                    <div className="switchwrp">
                      <div className="adminHeading">
                        <span>Super Admin Telenor Engage</span>
                      </div>
                      <div className="adminBox">
                        <div className="txtAndSwitchWrp">
                          <div className="txtAndSwitch">
                            <div className="txtwrp">
                              <p>
                                Manages everything related to company
                                registration, company access, account access and
                                security. Assign this role to users belonging to
                                Telenor Engage who require a holistic view of
                                activity through Telenor Engage platform.
                              </p>
                            </div>
                            <div className="switchwrp">
                              <Switch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                width={26}
                                height={14}
                                handleDiameter={10}
                                onColor="#29A869"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="uk-width-1-1">
                  <div className="adminRoleWrp">
                    <div className="btnwrp">
                      <button
                        className="btn-1"
                        type="button"
                        onClick={() =>
                          navigate("/super-admin-users", { replace: true })
                        }
                      >
                        Cancel
                      </button>
                      <button className="btn-2 w-70" type="submit">
                        {loader ? (
                          <div uk-spinner="" className="loader"></div>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSuperAdmin;
