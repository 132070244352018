import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import moment from "moment/moment";
import UIkit from "uikit";
import {
  Calendar,
  ChevronDown,
  OverflowMenuVertical,
  Download,
} from "@carbon/icons-react";
import CalendarDate from "../../components/Calendar/CalendarDate";
import { Link } from "react-router-dom";
import { apiGet } from "../../Utils/apiServices";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";
import { channelImages } from "../../Utils/ChannelImages";
import Pagination from "../../components/Pagination/Pagination";
import { encryptedValue } from "../../Utils/RouteHashConversion";
import { exportData } from "../../Utils/Export";
import { useAuth } from "../../Context/AuthProvider";

const LiveChatFormTab = ({ companyDetails }) => {
  let PageSize = 10;
  const { auth } = useAuth();
  const [currentPage, setCurrentPage] = useState(0);
  const CurrentCompanyName = CompanyName(CompanyHtml);
  const [selectedDateFilter, setSelectedDateFilter] = useState("This month");
  const [formData, setFormData] = useState(null);
  const [isApiCall, setIsApiCall] = useState(false);
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const filterDropdown = [
    "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];

  const handleDatePickerClose = () => {
    UIkit.drop("#datepickerdrop").hide(true);
  };
  const handleDateFilterDropdownrClose = () => {
    UIkit.drop("#dateDropdownSection").hide(true);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return formData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const handleDateFilter = (filter) => {
    setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    setIsApiCall(true);
  };

  const onChange = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
    setIsApiCall(true);
  };

  const getLiveChatFormData = () => {
    apiGet(`/chat/forms`, onSuccessFormData, onFailurFormData);
  };

  const onSuccessFormData = (response) => {
    setCurrentPage(1);
    setFormData(response?.data);
  };

  const onFailurFormData = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getLiveChatFormData();
  }, []);

  return (
    <>
      <div className="uk-grid analyticsTab uk-flex-middle" uk-grid="">
        <div className="uk-width-1-2">
          <h2 className="heading">
            Live Chats Forms{" "}
            <CurrentCompanyName companyDetails={companyDetails} />
          </h2>
        </div>
        <div className="uk-width-1-2 btnSection">
          {/* <button className="exportBtn">
                        <Download /> Export Data
                      </button> */}

          <div className="uk-inline">
            <button className="calenderBtn rangeBtn">
              <Calendar /> {selectedDateFilter}
              <span className="uk-inline">
                <ChevronDown />
              </span>
            </button>
            <div
              uk-dropdown="mode: click"
              className="calendarDropdown"
              id="dateDropdownSection"
            >
              <div className="dropDownHeading">
                <span>Select a range</span>
              </div>
              <ul>
                {filterDropdown.map((val, index) => {
                  return (
                    <React.Fragment key={index}>
                      <li
                        className={
                          selectedDateFilter === val ? "activeBtn" : ""
                        }
                        onClick={() => handleDateFilter(val)}
                      >
                        {filterDropdown.length - 1 === index ? (
                          <div className="uk-inline uk-text-left">
                            <button className="rangeBtn" type="button">
                              <span className="rangeBtnCircle"></span> Custom
                              range
                            </button>
                            <div
                              id="datepickerdrop"
                              uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                              className="date-picker-container"
                            >
                              <CalendarDate onChange={onChange} />
                              <div className="date-picker-btn-container uk-text-right">
                                <button
                                  className="date-selection-btn"
                                  onClick={handleDatePickerClose}
                                >
                                  Ok
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <button type="button" className="rangeBtn">
                            <span className="rangeBtnCircle"></span> {val}
                          </button>
                        )}
                      </li>
                      {filterDropdown.length - 2 === index && (
                        <hr style={{ margin: "5px 0" }} />
                      )}
                    </React.Fragment>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="analyticsContent">
        <div className="analyticsTableWrp uk-margin-top">
          <div className="uk-grid" uk-grid="">
            <div className="uk-width-1-1">
              <div className="searchForm">
                <form
                  action=""
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search by broadcast by name and date..."
                  />
                </form>
              </div>
            </div>
          </div>
          <table className="uk-table">
            <thead>
              <tr>
                {/* <th>SERIES</th> */}
                <th>
                  <div className="uk-inline tableDropDown">
                    <button type="button">
                      {/* ALL CHANNELS */}
                      CHANNEL
                      {/* <ChevronDown /> */}
                    </button>
                    {/* <div uk-dropdown="mode: click" className="dropdownContent">
                      <div className="dropdownHeading">
                        <span>Channels</span>
                      </div>
                      <ul>
                        <li className="active">
                          <button type="button">All channels</button>
                        </li>
                        <li>
                          <button type="button">
                            <img src={whatsappLogo} alt="" /> WhatsApp
                          </button>
                        </li>
                        <li>
                          <button type="button">
                            <img src={whatsappLogo} alt="" /> Facebook
                          </button>
                        </li>
                        <li>
                          <button type="button">
                            <img src={whatsappLogo} alt="" /> Instagram
                          </button>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </th>
                <th>FORM NAME</th>
                <th>TRAFIC</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {formData?.length > 0 ? (
                currentTableData?.map((val, index) => {
                  return (
                    <tr key={index}>
                      {/* <td>{index + 1}</td> */}
                      <td>
                        <img src={channelImages(val.chanelId)} alt="" />
                      </td>
                      <td className="agnetName">{val?.formName}</td>
                      <td>{val?.entries}</td>
                      <td>{val?.formStatus}</td>
                      <td className="entriesBtn">
                        <Link
                          to={`/live-chat-form-entries/${
                            val?.formId
                          }/${encryptedValue(val?.formName)}`}
                        >
                          Entries
                        </Link>
                      </td>
                      <td>
                        <div className="uk-inline">
                          <button type="button">
                            <OverflowMenuVertical />
                          </button>
                          <div
                            uk-dropdown="mode: click"
                            className="formCentralTableDrop"
                          >
                            <div className="tableDropDown">
                              <ul>
                                <li>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      exportData(
                                        `/chat/export/forms/${val.formId}`,
                                        "Form",
                                        auth.token
                                      )
                                    }
                                  >
                                    <Download /> Download data
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8} className="dataNotFound">
                    {formData === null ? (
                      <div uk-spinner=""></div>
                    ) : (
                      "Data Not Found"
                    )}
                  </td>
                </tr>
              )}

              {/* <tr>
                <td colspan="5" className="dataNotFound">
                  No Data Found
                </td>
              </tr> */}
            </tbody>
          </table>
          {formData?.length > 0 && (
            <div
              style={{
                marginRight: "50px",
              }}
            >
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={formData.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LiveChatFormTab;
