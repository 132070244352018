import React, { useMemo, useState } from "react";
import { useNavigate, useOutletContext, Link } from "react-router-dom";
import {
  Alarm,
  Calendar,
  ChevronDown,
  Download,
  Timer,
} from "@carbon/icons-react";
import demoImg from "../../assets/images/wa.png";
import moment from "moment/moment";
import UIkit from "uikit";
import DoughnutChart from "../../components/Graph/DoughnutChart";
import CalendarDate from "../../components/Calendar/CalendarDate";
import { useEffect } from "react";
import { apiGet } from "../../Utils/apiServices";
import axios from "axios";
import { useAuth } from "../../Context/AuthProvider";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";
import LineChart from "../../components/Graph/LineChart";
import {
  convertHoursWithTime,
  convertMinutes,
  convertMinutesWithdays,
  findChannelExist,
} from "../../Utils/GraphFunctions";
import { convertTimestampToDate } from "../../Utils/DateFunctions";
import { encryptedValue } from "../../Utils/RouteHashConversion";
import Pagination from "../../components/Pagination/Pagination";
import { hasPermissions } from "../../Utils/RoutePermissions";

const baseUrl = process.env.REACT_APP_BASEURL;

const LiveChatAnalytics = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const CurrentCompanyName = CompanyName(CompanyHtml);
  const { auth } = useAuth();
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [isSelected, setIsSelected] = useState(false);
  const [analytics, setAnalytics] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState("This month");
  const [isApiCall, setIsApiCall] = useState(false);
  const [solvedConversation, setSolvedConversation] = useState({
    whatsapp: [],
    facebook: [],
    instagram: [],
    // whatsapp: 0,
    // facebook: 0,
    // instagram: 0,
    labels: [],
  });
  const [responseTime, setResponseTime] = useState(null);
  const [utilization, setUtilization] = useState(null);
  const [resolution, setResolution] = useState();
  const pageTabs = [
    {
      route: "/analytics",
      name: "Overview",
      permissions: ["FINANCE-DATA-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/live-chat-analytics",
      name: "Live Chat",
      permissions: ["ANALYTICS-MANAGER", "LIVE-CHAT-MANAGER"],
      active: true,
      allPermissionsMandatory: true,
    },
    {
      route: "/chatbot-analytics",
      name: "Chatbot",
      permissions: ["CHATBOT-SUPERVISOR", "CHAT-BOT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/statistics",
      name: "Statistics",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/package-mau",
      name: "Package",
      permissions: ["ANALYTICS-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/orders",
      name: "Orders",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/transfer-logs",
      name: "Transfer Logs",
      permissions: [],
      active: false,
      allPermissionsMandatory: true,
    },
  ];
  const navigate = useNavigate();
  const getLiveChatanalytics = () => {
    apiGet(
      // `/stats/liveChatStats/${STARTDATE}/${ENDDATE}`,
      `/detail-analytics/agentUtilization/${STARTDATE}/${ENDDATE}`,
      onSuccessLiveChatAnalutics,
      onFailureLiveChatAnalutics
    );
  };
  const onSuccessLiveChatAnalutics = (response) => {
    // console.log(response);
    setIsApiCall(false);
    setAnalytics(response.data);
  };
  const onFailureLiveChatAnalutics = (error) => {
    console.log(error);
  };

  const getLiveChatSolvedConversation = () => {
    apiGet(
      `/detail-analytics/solvedConversation/${STARTDATE}/${ENDDATE}`,
      onSuccessLiveChatSolvedConversation,
      onFailureLiveChatSolvedConversation
    );
  };
  const onSuccessLiveChatSolvedConversation = (response) => {
    let whatsappArray = [];
    let facebookArray = [];
    let instagramArray = [];

    if (response?.data.length > 0) {
      let data = response?.data;
      for (let i = 0; i < data.length; i++) {
        if (data[i].chanelId === "WHATSAPP") {
          data[i]?.graphList.map((abc) => {
            whatsappArray.push(parseInt(abc.totalConversation));
          });
        }
        if (data[i].chanelId === "FACEBOOK") {
          data[i]?.graphList.map((abc) => {
            facebookArray.push(parseInt(abc.totalConversation));
          });
        }
        if (data[i].chanelId === "INSTAGRAM") {
          data[i]?.graphList.map((abc) => {
            instagramArray.push(parseInt(abc.totalConversation));
          });
        }
      }
    }

    // console.log(whatsappArray)
    // console.log(facebookArray)
    // console.log(instagramArray)
    // let whatsAppDataCount = whatsappArray?.reduce(
    //   (accumulator, currentValue) => {
    //     return parseInt(accumulator) + parseInt(currentValue);
    //   },
    //   0
    // );
    // let instagramDataCount = instagramArray?.reduce(
    //   (accumulator, currentValue) => {
    //     return parseInt(accumulator) + parseInt(currentValue);
    //   },
    //   0
    // );
    // let facebookDataCount = facebookArray?.reduce(
    //   (accumulator, currentValue) => {
    //     return parseInt(accumulator) + parseInt(currentValue);
    //   },
    //   0
    // );

    // console.log(whatsAppDataCount)
    // console.log(instagramDataCount)
    // console.log(facebookDataCount)
    let labels = [];
    response?.data[0]?.graphList.map((val) => {
      return labels.push(val.date);
    });

    // console.log(labels);
    setSolvedConversation({
      whatsapp: whatsappArray,
      facebook: facebookArray,
      instagram: instagramArray,
      // whatsapp: whatsAppDataCount,
      // facebook: facebookDataCount,
      // instagram: instagramDataCount,
      labels: labels,
    });

    // console.log(response);
  };
  const onFailureLiveChatSolvedConversation = (error) => {
    console.log(error);
  };

  const getLiveChatResponse = () => {
    apiGet(
      `/detail-analytics/waitingTime/${STARTDATE}/${ENDDATE}`,
      onSuccessResponse,
      onFailureResponse
    );
  };
  const onSuccessResponse = (response) => {
    // console.log(response);
    setResponseTime(response.data);
  };
  const onFailureResponse = (error) => {
    console.log(error);
  };

  const getLiveChatResolution = () => {
    apiGet(
      `/detail-analytics/solvedConversationTiming/${STARTDATE}/${ENDDATE}`,
      onSuccessResolution,
      onFailureResolution
    );
  };
  const onSuccessResolution = (response) => {
    // console.log(response);
    const sum =
      // response?.data[`1 hour to 24 hours`] +
      response?.data[`Between 1 hour to 24 hours`] +
      // response?.data[`5-10 min`] +
      response?.data[`Between 5 and 10 min`] +
      // response?.data[`10-15 min`] +
      response?.data[`Between 10 and 15 min`] +
      // response?.data[`15-30 min`] +
      response?.data[`Between 15 and 30 min`] +
      // response?.data[`30 min to 1 hour`] +
      response?.data[`Between 30 min to 1 hour`] +
      response?.data[`Below 5 min`];
    // console.log(sum);
    //     console.log(typeof sum);
    // console.log(parseInt(sum)===NaN?0:sum)
    response.data["sum"] = isNaN(sum) ? 0 : sum;
    setResolution(response.data);
  };
  const onFailureResolution = (error) => {
    console.log(error);
  };

  const getLiveChatUtilization = () => {
    apiGet(
      `/detail-analytics/agentUtilizationList/${STARTDATE}/${ENDDATE}`,
      onSuccessUtilization,
      onFailureUtilization
    );
  };
  const onSuccessUtilization = (response) => {
    // console.log(response);
    setCurrentPage(1);
    setUtilization(response.data);
  };
  const onFailureUtilization = (error) => {
    console.log(error);
  };

  const exportData = async () => {
    try {
      const response = await axios.get(
        baseUrl + `/stats/export/livechatstats/${STARTDATE}/${ENDDATE}`,
        {
          responseType: "arraybuffer", // Ensure the response is treated as binary data
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "LiveChat.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  useEffect(() => {
    getLiveChatanalytics();
    getLiveChatSolvedConversation();
    getLiveChatResponse();
    getLiveChatResolution();
    getLiveChatUtilization();
  }, []);

  const filterDropdown = [
    // "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];

  const channels = [
    {
      icon: null,
      name: "All Channels",
      apiSendName: "ALL",
      disabled: false,
    },
    // {
    //   icon: whatsAppImg,
    //   name: "WhatsApp",
    //   apiSendName: "WHATSAPP",
    //   disabled: false,
    // },
    // {
    //   icon: messengerIcon,
    //   name: "Facebook",
    //   apiSendName: "FACEBOOK",
    //   disabled: true,
    // },
    // {
    //   icon: instaIcon,
    //   name: "Instagram",
    //   apiSendName: "INSTAGRAM",
    //   disabled: true,
    // },
  ];

  const handleDatePickerClose = () => {
    if (selectedDateFilter === "Custom range") {
      setIsSelected(true);
    }
    UIkit.drop("#datepickerdropForLivechat").hide(true);
  };

  const onChange = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
  };

  useEffect(() => {
    if (isApiCall === false) {
      setIsSelected(false);
    }
  }, [isApiCall]);

  useEffect(() => {
    if (isSelected === true) {
      setIsApiCall(true);
    }
  }, [isSelected]);

  useEffect(() => {
    if (isApiCall == true) {
      if (selectedDateFilter === "Custom range") {
        if (isSelected === true) {
          // setAnalytics(null);
          getLiveChatanalytics();
          getLiveChatSolvedConversation();
          getLiveChatResponse();
          getLiveChatResolution();
          getLiveChatUtilization();
        }
      } else {
        // setAnalytics(null);
        getLiveChatanalytics();
        getLiveChatSolvedConversation();
        getLiveChatResponse();
        getLiveChatResolution();
        getLiveChatUtilization();
      }
    }
  }, [isApiCall]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return utilization?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const handleDateFilter = (filter) => {
    setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    if (filter !== "Custom range") {
      setIsApiCall(true);
    }
  };

  const clearStates = () => {
    setAnalytics(null);
    setSolvedConversation({
      whatsapp: [],
      facebook: [],
      instagram: [],
      // whatsapp: 0,
      // facebook: 0,
      // instagram: 0,
      labels: [],
    });
    setResponseTime(null);
    setResolution(undefined);
  };

  // useEffect(() => {
  //   console.log(resolution);
  // }, [resolution]);

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="boradcastWrp">
        <div className="boradcastTabbing">
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #newAnalyticsTab"
          >
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                <li
                  onClick={() => {
                    navigate(val.route, { replace: true });
                  }}
                  className={val.active === true ? "uk-active" : ""}
                  key={index}
                >
                  <a>{val.name}</a>
                </li>
              ) : null;
            })}
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="newAnalyticsTab">
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                val.name === "Live Chat" ? (
                  <li key={index}>
                    <div className="overviewContent">
                      <div className="uk-container">
                        <div className="uk-grid uk-flex-middle" uk-grid="">
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsTabWrp"
                              style={{ margin: "0 0 20px" }}
                            >
                              <div className="whatappTabBtn">
                                <ul
                                  className="uk-subnav uk-subnav-pill"
                                  uk-switcher="connect: .whatsappTabBtn"
                                >
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-analytics", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Overview</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-agent", {
                                        replace: true,
                                      });
                                    }}
                                    className="uk-active"
                                  >
                                    <a>Agents</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-routing", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Routes</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-voice-of-customer", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Voice of customer</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-channels", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a href="#">Channels</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-tags", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a href="#">Tags</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsWhatsappContent"
                              style={{ marginTop: "16px" }}
                            >
                              <ul className="uk-switcher uk-margin whatsappTabBtn">
                                <li></li>
                                <li>
                                  <div
                                    className="uk-grid uk-flex-middle"
                                    uk-grid=""
                                  >
                                    <div className="uk-width-1-2 uk-margin-remove">
                                      <div className="pageHeading uk-margin-remove-bottom">
                                        <h3>
                                          Live Chat Agent Utilization And
                                          Performance
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="uk-width-1-2 btnSection uk-margin-remove">
                                      {/* <button className="exportBtn">
                                      <Download /> Export Data
                                    </button> */}

                                      <div className="uk-inline">
                                        <button className="calenderBtn rangeBtn">
                                          <Calendar /> {selectedDateFilter}
                                          <span className="uk-inline">
                                            <ChevronDown />
                                          </span>
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="calendarDropdown"
                                          id="dateDropdownSection"
                                        >
                                          <div className="dropDownHeading">
                                            <span>Select a range</span>
                                          </div>
                                          <ul>
                                            {filterDropdown.map(
                                              (val, index) => {
                                                const isLastItem =
                                                  filterDropdown.length - 1 ===
                                                  index;
                                                const isSecondLastItem =
                                                  filterDropdown.length - 2 ===
                                                  index;

                                                return (
                                                  <React.Fragment key={index}>
                                                    <li
                                                      className={
                                                        selectedDateFilter ===
                                                        val
                                                          ? "activeBtn"
                                                          : ""
                                                      }
                                                      onClick={() => {
                                                        if (
                                                          val !== "Custom range"
                                                        ) {
                                                          clearStates();
                                                        }
                                                        handleDateFilter(val);
                                                      }}
                                                    >
                                                      {isLastItem ? (
                                                        <div className="uk-inline uk-text-left">
                                                          <button
                                                            className="rangeBtn"
                                                            type="button"
                                                          >
                                                            <span className="rangeBtnCircle"></span>{" "}
                                                            Custom range
                                                          </button>
                                                          <div
                                                            id="datepickerdropForLivechat"
                                                            uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                            className="date-picker-container"
                                                          >
                                                            <CalendarDate
                                                              onChange={
                                                                onChange
                                                              }
                                                            />
                                                            <div className="date-picker-btn-container uk-text-right">
                                                              <button
                                                                className="date-selection-btn"
                                                                onClick={
                                                                  handleDatePickerClose
                                                                }
                                                              >
                                                                Ok
                                                              </button>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <button
                                                          type="button"
                                                          className="rangeBtn"
                                                        >
                                                          <span className="rangeBtnCircle"></span>{" "}
                                                          {val}
                                                        </button>
                                                      )}
                                                    </li>
                                                    {isSecondLastItem && (
                                                      <hr
                                                        style={{
                                                          margin: "5px 0",
                                                        }}
                                                      />
                                                    )}
                                                  </React.Fragment>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="uk-width-1-1 uk-margin-remove">
                                      <div className="overviewMainContent">
                                        <div className="mainBox">
                                          <div className="boxHeading">
                                            <span>
                                              Status-wise Average Distribution
                                            </span>
                                          </div>
                                          <div className="boxContent">
                                            <div className="uk-grid" uk-grid="">
                                              <div className="uk-width-1-3 uk-first-column">
                                                <div className="txtwrp">
                                                  <h2>
                                                    {analytics === null ? (
                                                      <span uk-spinner=""></span>
                                                    ) : (
                                                      // convertMinutes(
                                                      //   analytics?.loggedInTime
                                                      // )
                                                      convertMinutesWithdays(
                                                        analytics?.loggedInTime
                                                      )
                                                    )}
                                                  </h2>
                                                  <span>Logged in time</span>
                                                </div>
                                              </div>
                                              <div className="uk-width-1-3">
                                                <div className="txtwrp">
                                                  <h2>
                                                    {analytics === null ? (
                                                      <span uk-spinner=""></span>
                                                    ) : (
                                                      // convertMinutes(
                                                      //   analytics?.AwayStatus
                                                      // )
                                                      convertMinutesWithdays(
                                                        analytics?.AwayStatus
                                                      )
                                                    )}
                                                  </h2>
                                                  <span>Away status</span>
                                                </div>
                                              </div>
                                              <div className="uk-width-1-3">
                                                <div className="txtwrp">
                                                  <h2>
                                                    {analytics === null ? (
                                                      <span uk-spinner=""></span>
                                                    ) : (
                                                      // convertMinutes(
                                                      //   analytics?.occupancy
                                                      // )
                                                      convertMinutesWithdays(
                                                        analytics?.occupancy
                                                      )
                                                    )}
                                                  </h2>
                                                  <span>Occupancy</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="mainBox">
                                          <div className="boxHeading">
                                            <span>Status distribution</span>
                                          </div>
                                          <div className="boxContent">
                                            <div className="uk-grid" uk-grid="">
                                              <div className="uk-width-1-4">
                                                <div
                                                  className="chartWrp"
                                                  style={{
                                                    minHeight: "200px",
                                                    minWidth: "200px",
                                                  }}
                                                >
                                                  {analytics !== null &&
                                                    (analytics?.totalWorkingHours >
                                                    0 ? (
                                                      <DoughnutChart
                                                        degree={360}
                                                        backgroudColor={[
                                                          "#edc843",
                                                          "#1ce783",
                                                          "#29a869",
                                                        ]}
                                                        borderColor={[
                                                          "#edc843",
                                                          "#1ce783",
                                                          "#29a869",
                                                        ]}
                                                        graphData={[
                                                          // analytics?.underCapacity,
                                                          // analytics?.idle,
                                                          // analytics?.available,
                                                          Math.abs(
                                                            (analytics?.underCapacity /
                                                              analytics?.totalWorkingHours) *
                                                              100
                                                          ) > 100
                                                            ? 100
                                                            : (analytics?.underCapacity /
                                                                analytics?.totalWorkingHours) *
                                                              100,

                                                          Math.abs(
                                                            (analytics?.idle /
                                                              analytics?.totalWorkingHours) *
                                                              100
                                                          ) > 100
                                                            ? 100
                                                            : (analytics?.idle /
                                                                analytics?.totalWorkingHours) *
                                                              100,

                                                          Math.abs(
                                                            (analytics?.available /
                                                              analytics?.totalWorkingHours) *
                                                              100
                                                          ) > 100
                                                            ? 100
                                                            : (analytics?.available /
                                                                analytics?.totalWorkingHours) *
                                                              100,
                                                        ]}
                                                        graphlabels={[
                                                          "Under Capacity",
                                                          "Idle",
                                                          "Availible",
                                                        ]}
                                                      />
                                                    ) : (
                                                      <DoughnutChart
                                                        degree={360}
                                                        backgroudColor={[
                                                          "#B4B4B4",
                                                        ]}
                                                        borderColor={[
                                                          "#B4B4B4",
                                                        ]}
                                                        graphData={[1]}
                                                        graphlabels={["-"]}
                                                      />
                                                    ))}

                                                  {/* <DoughnutChart
                                                  degree={360}
                                                  backgroudColor={["#B4B4B4"]}
                                                  borderColor={["#B4B4B4"]}
                                                  graphData={[1]}
                                                  graphlabels={["-"]}
                                                /> */}

                                                  <div className="chartData">
                                                    <h3>
                                                      {analytics?.underCapacity ===
                                                      undefined
                                                        ? 0
                                                        : analytics?.underCapacity}
                                                    </h3>
                                                    <p>Under capacity</p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="uk-width-3-4">
                                                <div className="chartRecordData">
                                                  <div className="distributionGraph">
                                                    <div className="chartHeading">
                                                      <h3>
                                                        {analytics !== null
                                                          ? (
                                                              (analytics?.occupancy /
                                                                analytics?.totalWorkingHours) *
                                                              100
                                                            ).toFixed(0) + "%"
                                                          : "0%"}
                                                      </h3>
                                                      <p>Occupancy</p>
                                                    </div>
                                                    <div className="progressWrp">
                                                      <progress
                                                        className="uk-progress progressNomral"
                                                        value={
                                                          analytics?.occupancy
                                                        }
                                                        max={
                                                          analytics?.totalWorkingHours
                                                        }
                                                      ></progress>
                                                    </div>
                                                    <div className="listWrp">
                                                      <div className="listTxtwrp">
                                                        <ul>
                                                          <li>
                                                            <p>
                                                              <span className="yellowDot"></span>
                                                              Under capacity
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="lightGreenDot"></span>
                                                              Idle
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="greenDot"></span>
                                                              Available
                                                            </p>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                      <div className="listNumWrp">
                                                        <ul>
                                                          <li>
                                                            <p>
                                                              {analytics !==
                                                              null
                                                                ? Math.abs(
                                                                    (analytics?.underCapacity /
                                                                      analytics?.totalWorkingHours) *
                                                                      100
                                                                  ) > 100
                                                                  ? "100%"
                                                                  : (
                                                                      (analytics?.underCapacity /
                                                                        analytics?.totalWorkingHours) *
                                                                      100
                                                                    ).toFixed() +
                                                                    "%"
                                                                : "0%"}
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              {analytics !==
                                                              null
                                                                ? Math.abs(
                                                                    (analytics?.idle /
                                                                      analytics?.totalWorkingHours) *
                                                                      100
                                                                  ) > 100
                                                                  ? "100%"
                                                                  : (
                                                                      (analytics?.idle /
                                                                        analytics?.totalWorkingHours) *
                                                                      100
                                                                    ).toFixed() +
                                                                    "%"
                                                                : "0%"}
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              {analytics !==
                                                              null
                                                                ? Math.abs(
                                                                    (analytics?.available /
                                                                      analytics?.totalWorkingHours) *
                                                                      100
                                                                  ) > 100
                                                                  ? "100%"
                                                                  : (
                                                                      (analytics?.available /
                                                                        analytics?.totalWorkingHours) *
                                                                      100
                                                                    ).toFixed() +
                                                                    "%"
                                                                : "0%"}
                                                            </p>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        {/* <div className="mainBox">
                                        <div className="boxHeading">
                                          <span>Solved Conversations</span>
                                        </div>
                                        <div className="boxContent">
                                          <div
                                            className="uk-grid uk-flex-middle"
                                            uk-grid=""
                                          >
                                            <div className="uk-width-3-5">
                                              <div className="overviewChart">
                                                <LineChart
                                                  dataSet1={
                                                    solvedConversation?.whatsapp
                                                  }
                                                  dataSet2={
                                                    solvedConversation?.facebook
                                                  }
                                                  dataSet3={
                                                    solvedConversation?.instagram
                                                  }
                                                  graphLabels={
                                                    solvedConversation?.labels
                                                  }
                                                  labelsForHover={[
                                                    "Whatsapp",
                                                    "Facebook",
                                                    "Instagram",
                                                  ]}
                                                />
                                              </div>
                                            </div>
                                            <div className="uk-width-2-5">
                                              <div className="chartRecordData">
                                                <div className="chartRecordDataWrp">
                                                  <div className="deliveryRate">
                                                    <div
                                                      className="detailHeading"
                                                      style={{
                                                        marginBottom: "24px",
                                                      }}
                                                    >
                                                      <p>CHANNEL</p>
                                                    </div>
                                                    <ul>
                                                      {companyDetails !==
                                                      undefined ? (
                                                        findChannelExist(
                                                          companyDetails?.activeChannels,
                                                          "WHATSAPP"
                                                        ) ? (
                                                          <li>
                                                            <p>
                                                              <span className="darkGreenDot"></span>
                                                              WhatsApp
                                                            </p>
                                                          </li>
                                                        ) : null
                                                      ) : null}
                                                      {companyDetails !==
                                                      undefined ? (
                                                        findChannelExist(
                                                          companyDetails?.activeChannels,
                                                          "FACEBOOK"
                                                        ) ? (
                                                          <li>
                                                            <p>
                                                              <span className="yellowDot"></span>
                                                              Facebook Messanger
                                                            </p>
                                                          </li>
                                                        ) : null
                                                      ) : null}
                                                      {companyDetails !==
                                                      undefined ? (
                                                        findChannelExist(
                                                          companyDetails?.activeChannels,
                                                          "INSTAGRAM"
                                                        ) ? (
                                                          <li>
                                                            <p>
                                                              <span className="lightGreenDot"></span>
                                                              Instagram
                                                            </p>
                                                          </li>
                                                        ) : null
                                                      ) : null}
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}

                                        <div className="mainBox">
                                          <div className="boxHeading">
                                            <span>Live chat response time</span>
                                          </div>
                                          <div className="boxContent">
                                            <div
                                              className="uk-grid uk-flex-middle"
                                              uk-grid=""
                                            >
                                              <div className="uk-width-2-5">
                                                <div className="boxTimerWrp">
                                                  <p>
                                                    <Timer /> First response
                                                    time
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="uk-width-3-5">
                                                <div
                                                  className="uk-grid"
                                                  uk-grid=""
                                                >
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp timeBoxTxt">
                                                      <h2>
                                                        {" "}
                                                        {responseTime ===
                                                        null ? (
                                                          <span uk-spinner=""></span>
                                                        ) : (
                                                          convertMinutes(
                                                            responseTime?.firstResponseMin
                                                          )
                                                        )}
                                                      </h2>
                                                      <span>min</span>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp timeBoxTxt">
                                                      <h2>
                                                        {" "}
                                                        {responseTime ===
                                                        null ? (
                                                          <span uk-spinner=""></span>
                                                        ) : (
                                                          convertMinutes(
                                                            responseTime?.firstResponseMax
                                                          )
                                                        )}
                                                      </h2>
                                                      <span>max</span>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp timeBoxTxt">
                                                      <h2>
                                                        {" "}
                                                        {responseTime ===
                                                        null ? (
                                                          <span uk-spinner=""></span>
                                                        ) : (
                                                          convertMinutes(
                                                            responseTime?.avgResponse
                                                          )
                                                        )}
                                                      </h2>
                                                      <span>avg</span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <hr />
                                            <div
                                              className="uk-grid uk-flex-middle"
                                              uk-grid=""
                                            >
                                              <div className="uk-width-2-5">
                                                <div className="boxTimerWrp">
                                                  <p>
                                                    <Alarm /> All response time
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="uk-width-3-5">
                                                <div
                                                  className="uk-grid"
                                                  uk-grid=""
                                                >
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp timeBoxTxt">
                                                      <h2>
                                                        {responseTime ===
                                                        null ? (
                                                          <span uk-spinner=""></span>
                                                        ) : (
                                                          convertMinutes(
                                                            responseTime?.handleResponseTimeMin
                                                          )
                                                        )}
                                                      </h2>
                                                      <span>min</span>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp timeBoxTxt">
                                                      <h2>
                                                        {" "}
                                                        {responseTime ===
                                                        null ? (
                                                          <span uk-spinner=""></span>
                                                        ) : (
                                                          convertMinutes(
                                                            responseTime?.handleResponseTimeMax
                                                          )
                                                        )}
                                                      </h2>
                                                      <span>max</span>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-3">
                                                    <div className="txtwrp timeBoxTxt">
                                                      <h2>
                                                        {responseTime ===
                                                        null ? (
                                                          <span uk-spinner=""></span>
                                                        ) : (
                                                          convertMinutes(
                                                            responseTime?.handleResponseTimeAvg
                                                          )
                                                        )}
                                                      </h2>
                                                      <span>avg</span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="mainBox">
                                          <div className="boxHeading">
                                            <span>
                                              Business Initiated Messages Status
                                              Breakdown
                                            </span>
                                          </div>
                                          <div className="boxContent">
                                            <div className="uk-grid" uk-grid="">
                                              <div className="uk-width-1-4">
                                                <div className="chartWrp">
                                                  {resolution?.sum > 0 ? (
                                                    <DoughnutChart
                                                      degree={360}
                                                      backgroudColor={[
                                                        "#29a869",
                                                        "#1ce783",
                                                        // "#A8200D",
                                                      ]}
                                                      borderColor={[
                                                        "#29a869",
                                                        "#1ce783",
                                                        // "#A8200D",
                                                      ]}
                                                      graphData={
                                                        resolution !== undefined
                                                          ? [
                                                              resolution?.sum -
                                                                resolution[
                                                                  `Between 1 hour to 24 hours`
                                                                ],
                                                              resolution[
                                                                `Between 1 hour to 24 hours`
                                                              ],
                                                            ]
                                                          : [0]
                                                      }
                                                      graphlabels={[
                                                        "Minutes",
                                                        "Hours",
                                                        "Daily",
                                                      ]}
                                                    />
                                                  ) : (
                                                    <DoughnutChart
                                                      degree={360}
                                                      backgroudColor={[
                                                        "#B4B4B4",
                                                      ]}
                                                      borderColor={["#B4B4B4"]}
                                                      graphData={[1]}
                                                      graphlabels={["-"]}
                                                    />
                                                  )}
                                                  <div className="chartData">
                                                    <h3>{resolution?.sum}</h3>
                                                    {/* <p>133 Total conversations</p> */}
                                                  </div>
                                                </div>
                                                <div className="chartRecordData uk-margin-top">
                                                  <div className="chartRecordDataWrp">
                                                    <div className="deliveryRate">
                                                      <ul>
                                                        <li>
                                                          <p>
                                                            <span className="greenDot"></span>
                                                            Minutes
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            <span className="lightGreenDot"></span>
                                                            Hours
                                                          </p>
                                                        </li>
                                                        {/* <li>
                                                        <p>
                                                          <span className="yellowDot"></span>
                                                          Days
                                                        </p>
                                                      </li> */}
                                                      </ul>
                                                    </div>
                                                    <div className="percentageData">
                                                      <ul>
                                                        <li>
                                                          <p>
                                                            {resolution?.sum > 0
                                                              ? (
                                                                  ((resolution?.sum -
                                                                    resolution[
                                                                      // `Between 1 and 24 Hour`
                                                                      `Between 1 hour to 24 hours`
                                                                    ]) /
                                                                    resolution.sum) *
                                                                  100
                                                                ).toFixed(2) +
                                                                "%"
                                                              : "0%"}
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            {resolution?.sum > 0
                                                              ? (
                                                                  (resolution[
                                                                    // `Between 1 and 24 Hour`
                                                                    `Between 1 hour to 24 hours`
                                                                  ] /
                                                                    resolution.sum) *
                                                                  100
                                                                ).toFixed(2) +
                                                                "%"
                                                              : "0%"}
                                                          </p>
                                                        </li>
                                                        {/* <li>
                                                        <p>58.65%</p>
                                                      </li> */}
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="uk-width-3-4">
                                                <div className="chartRecordData">
                                                  <div className="chartRecordDataWrp">
                                                    <div className="deliveryRate">
                                                      <ul>
                                                        <li>
                                                          <p>
                                                            <span className="greenDot"></span>
                                                            Below 5 Min
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            <span className="greenDot"></span>
                                                            Between 5 and 10 min
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            <span className="greenDot"></span>
                                                            Between 10 and 15
                                                            min
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            <span className="greenDot"></span>
                                                            Between 15 and 30
                                                            min
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            <span className="greenDot"></span>
                                                            Between 30 and 1
                                                            Hour
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            <span className="greenDot"></span>
                                                            Between 1 and 24
                                                            Hour
                                                          </p>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                    <div className="progressBar">
                                                      <ul>
                                                        <li>
                                                          <progress
                                                            className="uk-progress progressNomral"
                                                            value={
                                                              resolution !==
                                                              undefined
                                                                ? resolution[
                                                                    "Below 5 min"
                                                                  ]
                                                                : 0
                                                            }
                                                            max={
                                                              resolution?.sum
                                                            }
                                                          ></progress>
                                                        </li>
                                                        <li>
                                                          <progress
                                                            className="uk-progress progressNomral"
                                                            value={
                                                              resolution !==
                                                              undefined
                                                                ? resolution[
                                                                    // "5-10 min"
                                                                    "Between 5 and 10 min"
                                                                  ]
                                                                : 0
                                                            }
                                                            max={
                                                              resolution?.sum
                                                            }
                                                          ></progress>
                                                        </li>
                                                        <li>
                                                          <progress
                                                            className="uk-progress progressNomral"
                                                            value={
                                                              resolution !==
                                                              undefined
                                                                ? resolution[
                                                                    // "10-15 min"
                                                                    "Between 10 and 15 min"
                                                                  ]
                                                                : 0
                                                            }
                                                            max={
                                                              resolution?.sum
                                                            }
                                                          ></progress>
                                                        </li>
                                                        <li>
                                                          <progress
                                                            className="uk-progress progressNomral"
                                                            value={
                                                              resolution !==
                                                              undefined
                                                                ? resolution[
                                                                    // "15-30 min"
                                                                    "Between 15 and 30 min"
                                                                  ]
                                                                : 0
                                                            }
                                                            max={
                                                              resolution?.sum
                                                            }
                                                          ></progress>
                                                        </li>
                                                        <li>
                                                          <progress
                                                            className="uk-progress progressNomral"
                                                            value={
                                                              resolution !==
                                                              undefined
                                                                ? resolution[
                                                                    // "30 min to 1 hour"
                                                                    "Between 30 min to 1 hour"
                                                                  ]
                                                                : 0
                                                            }
                                                            max={
                                                              resolution?.sum
                                                            }
                                                          ></progress>
                                                        </li>
                                                        <li>
                                                          <progress
                                                            className="uk-progress progressNomral"
                                                            value={
                                                              resolution !==
                                                              undefined
                                                                ? resolution[
                                                                    // "1 hour to 24 hours"
                                                                    "Between 1 hour to 24 hours"
                                                                  ]
                                                                : 0
                                                            }
                                                            max={
                                                              resolution?.sum
                                                            }
                                                          ></progress>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                    <div className="percentageData">
                                                      <ul>
                                                        <li>
                                                          <p>
                                                            {resolution?.sum > 0
                                                              ? (
                                                                  (resolution[
                                                                    "Below 5 min"
                                                                  ] /
                                                                    resolution?.sum) *
                                                                  100
                                                                ).toFixed(2) +
                                                                "%"
                                                              : "0%"}
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            {" "}
                                                            {resolution?.sum > 0
                                                              ? (
                                                                  (resolution[
                                                                    // "5-10 min"
                                                                    "Between 5 and 10 min"
                                                                  ] /
                                                                    resolution?.sum) *
                                                                  100
                                                                ).toFixed(2) +
                                                                "%"
                                                              : "0%"}
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            {" "}
                                                            {resolution?.sum > 0
                                                              ? (
                                                                  (resolution[
                                                                    // "10-15 min"
                                                                    "Between 10 and 15 min"
                                                                  ] /
                                                                    resolution?.sum) *
                                                                  100
                                                                ).toFixed(2) +
                                                                "%"
                                                              : "0%"}
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            {" "}
                                                            {resolution?.sum > 0
                                                              ? (
                                                                  (resolution[
                                                                    // "15-30 min"
                                                                    "Between 15 and 30 min"
                                                                  ] /
                                                                    resolution?.sum) *
                                                                  100
                                                                ).toFixed(2) +
                                                                "%"
                                                              : "0%"}
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            {" "}
                                                            {resolution?.sum > 0
                                                              ? (
                                                                  (resolution[
                                                                    // "30 min to 1 hour"
                                                                    "Between 30 min to 1 hour"
                                                                  ] /
                                                                    resolution?.sum) *
                                                                  100
                                                                ).toFixed(2) +
                                                                "%"
                                                              : "0%"}
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            {" "}
                                                            {resolution?.sum > 0
                                                              ? (
                                                                  (resolution[
                                                                    // "1 hour to 24 hours"
                                                                    "Between 1 hour to 24 hours"
                                                                  ] /
                                                                    resolution?.sum) *
                                                                  100
                                                                ).toFixed(2) +
                                                                "%"
                                                              : "0%"}
                                                          </p>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="mainBox">
                                          <div className="boxHeading">
                                            <span>
                                              Utilization per agent (avg.)
                                            </span>
                                          </div>
                                          <div className="boxFullTable tableCenter">
                                            <table className="uk-table uk-table-striped">
                                              <thead>
                                                <tr>
                                                  <th className="uk-table-expand">
                                                    AGENT
                                                  </th>
                                                  <th>LOGGED IN TIME</th>
                                                  <th>AWAY</th>
                                                  <th>OCCUPANCY</th>
                                                  <th>AVAILABLE</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {utilization?.length > 0 ? (
                                                  currentTableData?.map(
                                                    (val, index) => {
                                                      return (
                                                        <tr key={index}>
                                                          <td className="channelName">
                                                            <Link
                                                              to={`/liveChatAgent-detail/${encryptedValue(
                                                                val.agentId
                                                              )}/${STARTDATE}/${ENDDATE}`}
                                                            >
                                                              {/* <img
                                                              src={demoImg}
                                                              alt=""
                                                            /> */}
                                                              {val.agentName}
                                                            </Link>
                                                          </td>
                                                          <td>
                                                            {/* {(((val.loggedTime)/val.totalWorkingHours)*100)} */}
                                                            {convertHoursWithTime(
                                                              val.loggedTime /
                                                                val.totalWorkingHours
                                                            )}
                                                          </td>
                                                          <td>
                                                            {convertHoursWithTime(
                                                              val.awayTime /
                                                                val.totalWorkingHours
                                                            )}
                                                          </td>

                                                          <td>
                                                            {convertHoursWithTime(
                                                              val.occupancyTime /
                                                                val.totalWorkingHours
                                                            )}
                                                          </td>

                                                          <td>
                                                            {convertHoursWithTime(
                                                              val.availableTime /
                                                                val.totalWorkingHours
                                                            )}
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )
                                                ) : (
                                                  <tr>
                                                    <td
                                                      colSpan={8}
                                                      className="dataNotFound"
                                                    >
                                                      {utilization === null ? (
                                                        <span uk-spinner=""></span>
                                                      ) : (
                                                        "Data Not Found"
                                                      )}
                                                    </td>
                                                  </tr>
                                                )}
                                                {/* <tr>
                                                <td className="channelName">
                                                  <Link to="/live-chat-agent-detail">
                                                    <img src={demoImg} alt="" />
                                                    Emre Altan
                                                  </Link>
                                                </td>
                                                <td>29:18:18</td>
                                                <td>-</td>
                                                <td>29:18:18</td>
                                                <td>29:18:18</td>
                                              </tr>
                                              <tr>
                                                <td className="channelName">
                                                  <img src={demoImg} alt="" />
                                                  Emre Altan
                                                </td>
                                                <td>29:18:18</td>
                                                <td>-</td>
                                                <td>29:18:18</td>
                                                <td>29:18:18</td>
                                              </tr>
                                              <tr>
                                                <td className="channelName">
                                                  <img src={demoImg} alt="" />
                                                  Emre Altan
                                                </td>
                                                <td>29:18:18</td>
                                                <td>-</td>
                                                <td>29:18:18</td>
                                                <td>29:18:18</td>
                                              </tr>
                                              <tr>
                                                <td className="channelName">
                                                  <img src={demoImg} alt="" />
                                                  Emre Altan
                                                </td>
                                                <td>29:18:18</td>
                                                <td>-</td>
                                                <td>29:18:18</td>
                                                <td>29:18:18</td>
                                              </tr>
                                              <tr>
                                                <td className="channelName">
                                                  <img src={demoImg} alt="" />
                                                  Emre Altan
                                                </td>
                                                <td>29:18:18</td>
                                                <td>-</td>
                                                <td>29:18:18</td>
                                                <td>29:18:18</td>
                                              </tr> */}
                                              </tbody>
                                            </table>
                                            {utilization?.length > 0 && (
                                              <div
                                                style={{ marginRight: "50px" }}
                                              >
                                                <Pagination
                                                  className="pagination-bar"
                                                  currentPage={currentPage}
                                                  totalCount={
                                                    utilization.length
                                                  }
                                                  pageSize={PageSize}
                                                  onPageChange={(page) =>
                                                    setCurrentPage(page)
                                                  }
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li key={index}></li>
                )
              ) : null;
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default LiveChatAnalytics;
