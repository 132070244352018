import { createContext, useContext, useEffect, useState } from "react";
import { decryptedValue } from "../Utils/RouteHashConversion";

const WebSocketContext = createContext();
export const useWebSocket = () => {
  return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children }) => {
  const socketUrl = process.env.REACT_APP_SOCKETURL;
  const [socket, setSocket] = useState(null);
  const [isConnectionClosed, setIsConnectionClosed] = useState(false);
  const [chatFirstContactDetails, setChatFirstContactDetails] = useState(null);

  useEffect(() => {
    // Check if the user is logged in based on the presence of relevant data in localStorage
    const isLoggedIn =
      !!localStorage.getItem("_email") && !!localStorage.getItem("_token");

    if (!isLoggedIn) {
      return; // No need to establish a WebSocket connection when the user is not logged in
    }
    const newSocket = new WebSocket(socketUrl);

    newSocket.onopen = () => {
      console.log("WebSocket connection established");
      sendAuthMessage();

      // You can call sendAuthMessage() here if needed
    };

    // newSocket.onclose = () => {
    //   // sendAuthMessage();

    // };
    newSocket.onclose = (event) => {
      if (event.code === 1000) {
        // Normal closure, no need to reconnect
        console.log("WebSocket connection closed gracefully.");
      } else {
        // Handle reconnection or other actions as needed
        // alert("WebSocket connection closed unexpectedly. Please refresh the page.");
        // setSocket(newSocket)
        // setIsConnectionClosed(true);
        // window.location.reload()
        newSocket.onopen = () => {
          console.log("WebSocket connection established");
          sendAuthMessage();

          // You can call sendAuthMessage() here if needed
        };

      }
    };

    newSocket.onerror = () => {
    };

    setSocket(newSocket);

    const keepAliveInterval = setInterval(() => {
      if (newSocket && newSocket.readyState === WebSocket.OPEN) {
        const data = { token: localStorage.getItem("_token") };
        const message = { type: "ping", data: data };
        // console.log("I am keeping this socket alive");
        newSocket.send(JSON.stringify(message));
      }
    }, 1000); // 1 seconds in milliseconds
    // }, 300000); // 30 seconds in milliseconds

    const keepAliveContactList = setInterval(() => {
      if (newSocket && newSocket.readyState === WebSocket.OPEN) {
        const forFetchContactList = {
          agentPublicId: decryptedValue(localStorage.getItem("_idNumber")),
        };
        const messageForFetchContactList = {
          type: "update-chat-list",
          data: forFetchContactList,
        };
        if (
          window.location.pathname === "/chat"
          //  &&
          // decryptedValue(localStorage.getItem("_role")) !== "ROLE_ADMIN"
        ) {
          newSocket.send(JSON.stringify(messageForFetchContactList));
        }
      }
    }, 30000); //30s in millseconds
    // }, 1000); //30s in millseconds

    return () => {
      sendAuthMessage();
      clearInterval(keepAliveInterval);
      clearInterval(keepAliveContactList);
      if (socket) {
        socket.close();
      }
    };
  }, []);
  function sendAuthMessage() {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const data = {
        username: decryptedValue(localStorage.getItem("_email")),
        password: localStorage.getItem("_token"),
      };
      socket.send(JSON.stringify({ type: "auth", data: data }));
    }
  }

  // Function to close the WebSocket connection
  const closeSocketConnection = () => {
    if (socket) {
      socket.close();
    }
  };

  return (
    <WebSocketContext.Provider
      value={{
        socket,
        isConnectionClosed,
        closeSocketConnection,
        setChatFirstContactDetails,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
