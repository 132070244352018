import React, { useEffect, useState, useRef } from "react";
import loginImg from "../../assets/images/login-img.png";
import convexImg from "../../assets/images/convex-logo.png";
import metaLogo from "../../assets/images/meta-logo.png";
import berrytalksLogo from "../../assets/images/berrytalks-logo-large.png";
import { View, ViewOff } from "@carbon/icons-react";
import axios from "axios";
// import { baseUrl } from "../../Constant";
import { toast } from "react-toastify";
import { useAuth } from "../../Context/AuthProvider";
import { ValidateEmail } from "../../Utils/regexFunctions";
import ReCAPTCHA from "react-google-recaptcha";
// import { socket } from "../../socket";
const baseUrl = process.env.REACT_APP_BASEURL;

const Login = () => {
  const recaptchaRef = useRef(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [isCaptchaSelected, setIsCaptchaSelected] = useState(null);
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [errorEnable, setErrorEnable] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const { setAuth } = useAuth();

  const handleViewPassword = () => {
    setViewPassword(!viewPassword);
  };

  useEffect(() => {
    if (window.self !== window.top) {
      window.top.location = window.location.href;
    }

    if (localStorage.getItem("_ver1") === null && isUserLoggedIn) {
      if (localStorage.getItem("_token") !== null) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  }, []);

  const handleLogin = (e) => {
    setErrorEnable(true);
    e.preventDefault();
    setLoader(true);
    if (
      user.email !== "" &&
      user.password !== "" &&
      emailError === "Valid Email Address" &&
      isCaptchaSelected !== null
    ) {
      axios
        .post(
          baseUrl + "/auth/login",
          {
            email: user.email,
            password: user.password,
            captchaResponse: isCaptchaSelected,
          },
          {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          }
        )
        .then(
          (response) => {
            if (response.status === 200) {
              setIsUserLoggedIn(true);
              localStorage.setItem(
                "_token",
                response?.data?.data?.tokenDetails?.token
              );
              localStorage.setItem(
                "_email",
                response?.data?.data?.userDetails?.email
              );
              localStorage.setItem(
                "_publicId",
                response?.data?.data?.userDetails?.publicId
              );
              localStorage.setItem(
                "_role",
                response?.data?.data?.userDetails?.roles[0]?.name
              );
              localStorage.setItem(
                "_userName",
                response?.data?.data?.userDetails?.name
              );
              localStorage.setItem(
                "_companyId",
                response?.data?.data?.userDetails?.companyId
              );
              localStorage.setItem(
                "_idNumber",
                response?.data?.data?.userDetails?.idNumber
              );
              localStorage.setItem(
                "_agentId",
                response?.data?.data?.userDetails?.agentId
              );
              localStorage.setItem("_tune", true);
              localStorage.setItem("_ver1", process.env.REACT_APP_VERSION);
              setAuth((auth) => ({
                ...auth,
                token: response?.data?.data?.tokenDetails?.token,
                role: response?.data?.data?.userDetails?.roles[0]?.name,
                email: response?.data?.data?.userDetails?.email,
                publicId: response?.data?.data?.userDetails?.publicId,
                idNumber: response?.data?.data?.userDetails?.idNumber,
                userName: response?.data?.data?.userDetails?.name,
                companyId: response?.data?.data?.userDetails?.companyId,
                agentId: response?.data?.data?.userDetails?.agentId,
              }));

              // getUserDetails();

              setTimeout(() => {
                setLoader(false);
                toast.success("Login successfully", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 800,
                });
              }, 500);
              setTimeout(() => {
                window.location.href = "/welcome";
                // navigate("/welcome", { replace: true });
              }, 2000);
              setErrorEnable(false);
            }
          },
          (error) => {
            if (
              error?.response?.data?.message === "reCAPTCHA verification failed"
            ) {
              setIsCaptchaSelected(null);
              recaptchaRef.current.reset();
            } else if (error.message === "Network Error") {
              toast.error("Network Error", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            } else if (error?.response?.data?.message === "Bad credentials") {
              toast.error("Invalid Credentials !", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            } else if (error?.response?.data?.message === "Email not Found") {
              toast.error("User not found !", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            } else if (error?.response?.data?.message === "USER DISABLED") {
              toast.error("Account Disabled", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            }
            setErrorEnable(false);
            setLoader(false);
            setIsUserLoggedIn(false);
          }
        );
    } else {
      setLoader(false);
    }
  };

  const handleEmailValidation = (e) => {
    var Email = ValidateEmail(e.target.value);
    if (Email === "Invalid Email") {
      setEmailError("Invalid Email Address");
    } else {
      setEmailError("Valid Email Address");
    }
  };

  const reCaptchaOnChange = (value) => {
    setIsCaptchaSelected(value);
  };

  return (
    <>
      <div className="loginWrp">
        <div uk-grid="" className="uk-grid">
          <div className="uk-width-1-2">
            <div className="login-left">
              <div className="txtwrp">
                <h2>
                  Elevate Communications with Future-Ready <span>CPaaS</span>{" "}
                  Solution
                </h2>
              </div>
              <div className="imgwrp">
                <img src={loginImg} alt="" />
              </div>
              <div className="login-footer">
                <ul>
                  <li>
                    <img src={convexImg} alt="" />
                  </li>
                  <li>
                    <img src={metaLogo} alt="" />
                  </li>
                </ul>
                <a href="https://www.convexinteractive.com/" target="_blank">
                  www.convexinteractive.com
                </a>
              </div>
            </div>
          </div>
          <div className="uk-width-1-2">
            <div className="formwrp">
              <form onSubmit={(e) => handleLogin(e)}>
                <div className="platformIcon">
                  <img src={berrytalksLogo} alt="" />
                </div>
                <div className="loginHeading">
                  <h2>Login</h2>
                </div>
                <div uk-gird="">
                  <div className="uk-width-1-1">
                    <div className="inputField">
                      <input
                        type="text"
                        id="loginEmailInput"
                        placeholder="Email Address"
                        autoComplete="off"
                        onChange={(e) => {
                          setUser({ ...user, email: e.target.value });
                          handleEmailValidation(e);
                        }}
                      />

                      {user.email === "" && errorEnable && (
                        <div className="errors">Email is Required</div>
                      )}
                      {user.email !== "" &&
                        emailError !== "Valid Email Address" && (
                          <div className="errors">Invalid Email Address</div>
                        )}
                    </div>
                  </div>
                  <div className="uk-width-1-1">
                    <div className="inputField">
                      <input
                        type={viewPassword ? "text" : "password"}
                        placeholder="Password"
                        id="loginPasswordInput"
                        autoComplete="off"
                        onChange={(e) =>
                          setUser({ ...user, password: e.target.value })
                        }
                      />
                      {user.password &&
                        (viewPassword ? (
                          <View
                            className="viewIcon"
                            onClick={handleViewPassword}
                          />
                        ) : (
                          <ViewOff
                            className="viewIcon"
                            onClick={handleViewPassword}
                          />
                        ))}
                    </div>
                  </div>
                  {user.password === "" && errorEnable && (
                    <div className="uk-width-1-1 errors">
                      Password is Required
                    </div>
                  )}

                  <div className="uk-width-1-1">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      // sitekey="6LfMxhYpAAAAABVOEoefGn8CFSnez5kjDRJAjws2"
                      sitekey={process.env.REACT_APP_CAPTACHA}
                      onChange={reCaptchaOnChange}
                    />
                    {isCaptchaSelected === null && errorEnable && (
                      <div className="errors">
                        Please complete the reCaptcha{" "}
                      </div>
                    )}
                  </div>
                  <div className="uk-width-1-1">
                    <div className="fbInstaBtn">
                      <button type="submit" className="login">
                        {loader ? (
                          <div uk-spinner="" className="loader"></div>
                        ) : (
                          "Log in"
                        )}
                      </button>
                      <button
                        type="button"
                        className="btn-fb"
                        onClick={() => (window.location.href = "/signup")}
                      >
                        Signup
                      </button>
                      {/* <button type="button" className="btn-fb">
                        Login with Facebook
                      </button> */}
                      {/* <button type="button" className="btn-insta">
                        Login with Instagram
                      </button> */}
                    </div>
                    <div className="btnwrp">
                      <div
                        className="forgetBtn"
                        style={{ textAlign: "center" }}
                      >
                        <a href="/forgot-password">Forgot password?</a>
                      </div>
                      {/* <div className="loginBtn w-90">
                        <button
                          type="submit"
                          disabled={loader ? true : false}
                          id="loginSubmitBtn"
                        >
                          {loader ? (
                            <div uk-spinner="" className="loader"></div>
                          ) : (
                            "Log in"
                          )}
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
