import { useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import {
  // Alarm,
  // Calendar,
  // ChevronDown,
  // Timer,
  Download,
} from "@carbon/icons-react";
import demoImg from "../../assets/images/wa.png";
import moment from "moment/moment";
import UIkit from "uikit";
// import DoughnutChart from "../../components/Graph/DoughnutChart";
// import CalendarDate from "../../components/Calendar/CalendarDate";
import { useEffect } from "react";
import { apiGet } from "../../Utils/apiServices";
// import LineChart from "../../components/Graph/LineChart";
import { convertTimestamp } from "../../Utils/DateFunctions";
import {
  encryptedValue,
  decryptedValue,
} from "../../Utils/RouteHashConversion";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { useAuth } from "../../Context/AuthProvider";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";
import { useMemo } from "react";
import Pagination from "../../components/Pagination/Pagination";
import { hasPermissions } from "../../Utils/RoutePermissions";
const baseUrl = process.env.REACT_APP_BASEURL;

const LiveChatRouting = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const CurrentCompanyName = CompanyName(CompanyHtml);
  const { auth } = useAuth();
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [analytics, setAnalytics] = useState();
  const [channel, setChannel] = useState("ALL");
  const [selectedDateFilter, setSelectedDateFilter] = useState("This month");
  const [isApiCall, setIsApiCall] = useState(false);
  const [RouteStats, setRouteStats] = useState(null);
  const [size, setSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  // const [currentPage, setCurrentPage] = useState(0);
  const [isSelected, setIsSelected] = useState(false);
  const pageTabs = [
    {
      route: "/analytics",
      name: "Overview",
      permissions: ["FINANCE-DATA-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/live-chat-analytics",
      name: "Live Chat",
      permissions: ["ANALYTICS-MANAGER", "LIVE-CHAT-MANAGER"],
      active: true,
      allPermissionsMandatory: true,
    },
    {
      route: "/chatbot-analytics",
      name: "Chatbot",
      permissions: ["CHATBOT-SUPERVISOR", "CHAT-BOT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/statistics",
      name: "Statistics",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/package-mau",
      name: "Package",
      permissions: ["ANALYTICS-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/orders",
      name: "Orders",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/transfer-logs",
      name: "Transfer Logs",
      permissions: [],
      active: false,
      allPermissionsMandatory: true,
    },
  ];

  const navigate = useNavigate();

  const getLiveChatRoutes = (page) => {
    apiGet(
      `/routing/stats?page=${page}&size=${size}`,
      onSuccessRouteStats,
      onFailureRouteStats
    );
  };

  const onSuccessRouteStats = (response) => {
    setCurrentPage(1);
    setIsApiCall(false);
    setRouteStats(response?.data);
    setPageCount(response?.data?.routeRecords?.totalPages);
  };

  const onFailureRouteStats = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getLiveChatRoutes(0);
  }, []);
  useEffect(() => {
    if (isApiCall == true) {
      getLiveChatRoutes(0);
    } else {
      if (isApiCall === false) {
        setIsSelected(false);
      }
    }
  }, [isApiCall]);

  //   useEffect(() => {
  //     getLiveChatRoutes(0);
  //   }, [size]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return RouteStats?.routeRecords?.content?.slice(
      firstPageIndex,
      lastPageIndex
    );
  }, [currentPage]);

  const filterDropdown = [
    "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];

  const channels = [
    {
      icon: null,
      name: "All Channels",
      apiSendName: "ALL",
      disabled: false,
    },
    // {
    //   icon: whatsAppImg,
    //   name: "WhatsApp",
    //   apiSendName: "WHATSAPP",
    //   disabled: false,
    // },
    // {
    //   icon: messengerIcon,
    //   name: "Facebook",
    //   apiSendName: "FACEBOOK",
    //   disabled: true,
    // },
    // {
    //   icon: instaIcon,
    //   name: "Instagram",
    //   apiSendName: "INSTAGRAM",
    //   disabled: true,
    // },
  ];

  const handleDatePickerCloseRouting = () => {
    if (selectedDateFilter === "Custom range") {
      setIsSelected(true);
    }
    UIkit.drop("#datepickerdrop").hide(true);
  };

  const onChangeRouting = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
  };

  const handleDateFilter = (filter) => {
    // setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    if (filter !== "Custom range") {
      setIsApiCall(true);
    }
  };

  const handlePageChange = (data) => {
    getLiveChatRoutes(data.selected);
    setCurrentPage(data.selected);
  };

  // useEffect(() => {
  //   getLiveChatRoutes(0);
  // }, [size]);

  const exportData = async () => {
    try {
      const response = await axios.get(baseUrl + `/routing/export/stats`, {
        responseType: "arraybuffer", // Ensure the response is treated as binary data
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Routing.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="boradcastWrp">
        <div className="boradcastTabbing">
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #newAnalyticsTab"
          >
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                <li
                  onClick={() => {
                    navigate(val.route, { replace: true });
                  }}
                  className={val.active === true ? "uk-active" : ""}
                  key={index}
                >
                  <a>{val.name}</a>
                </li>
              ) : null;
            })}
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="newAnalyticsTab">
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                val.name === "Live Chat" ? (
                  <li key={index}>
                    <div className="overviewContent">
                      <div className="uk-container">
                        <div className="uk-grid uk-flex-middle" uk-grid="">
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsTabWrp"
                              style={{ margin: "0 0 20px" }}
                            >
                              <div className="whatappTabBtn">
                                <ul
                                  className="uk-subnav uk-subnav-pill"
                                  uk-switcher="connect: .whatsappTabBtn"
                                >
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-analytics", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Overview</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-agent", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Agents</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-routing", {
                                        replace: true,
                                      });
                                    }}
                                    className="uk-active"
                                  >
                                    <a>Routes</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-voice-of-customer", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Voice of customer</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-channels", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a href="#">Channels</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-tags", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a href="#">Tags</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsWhatsappContent"
                              style={{ marginTop: "16px" }}
                            >
                              <ul className="uk-switcher uk-margin whatsappTabBtn">
                                <li></li>
                                <li></li>
                                <li>
                                  <div
                                    className="overviewContent uk-padding-remove"
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <div className="uk-container">
                                      <div className="overviewTopDropdown">
                                        <div
                                          className="uk-grid uk-flex-middle"
                                          uk-grid=""
                                        >
                                          <div className="uk-width-1-2">
                                            <div className="pageHeading uk-margin-remove-bottom">
                                              <h3>
                                                Live Chat Routes{" "}
                                                <CurrentCompanyName
                                                  companyDetails={
                                                    companyDetails
                                                  }
                                                />{" "}
                                              </h3>
                                            </div>
                                          </div>
                                          <div className="uk-width-1-2 btnSection">
                                            {/* <button
                                            className="exportBtn"
                                            onClick={exportData}
                                          >
                                            <Download /> Export Data
                                          </button> */}
                                            <div className="uk-inline">
                                              <div className="agentFilterWrp">
                                                <div className="allChannelDropDown">
                                                  <div className="uk-inline tableFilterDropdown broadcastChannelFilter">
                                                    {/* <button
                                              type="button"
                                              className="dropdownBtn"
                                            >
                                              All Active Channels{" "}
                                              <ChevronDown />
                                            </button> */}
                                                    <div
                                                      uk-dropdown="mode: click"
                                                      className="dropdownNav"
                                                    >
                                                      <span className="dropdownHeading">
                                                        Channels
                                                      </span>
                                                      <ul>
                                                        {channels.map(
                                                          (val, index) => {
                                                            return (
                                                              <li
                                                                key={index}
                                                                className={
                                                                  channel ===
                                                                  val.apiSendName
                                                                    ? "activeBtn"
                                                                    : ""
                                                                }
                                                                onClick={() => {
                                                                  setChannel(
                                                                    val.apiSendName
                                                                  );
                                                                }}
                                                              >
                                                                <button
                                                                  type="button"
                                                                  disabled={
                                                                    val.disabled ===
                                                                    true
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  className={
                                                                    val.disabled ===
                                                                    true
                                                                      ? "opactityBtn"
                                                                      : ""
                                                                  }
                                                                >
                                                                  {index !==
                                                                    0 && (
                                                                    <img
                                                                      src={
                                                                        val.icon
                                                                      }
                                                                      alt=""
                                                                    />
                                                                  )}
                                                                  {val.name}
                                                                </button>
                                                              </li>
                                                            );
                                                          }
                                                        )}
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* <div className="uk-inline">
                                              <button className="calenderBtn rangeBtn">
                                                <Calendar />{" "}
                                                {selectedDateFilter}
                                                <span className="uk-inline">
                                                  <ChevronDown />
                                                </span>
                                              </button>
                                              <div
                                                uk-dropdown="mode: click"
                                                className="calendarDropdown"
                                                id="dateDropdownSection"
                                              >
                                                <div className="dropDownHeading">
                                                  <span>Select a range</span>
                                                </div>
                                                <ul>
                                                  {filterDropdown.map(
                                                    (val, index) => {
                                                      return (
                                                        <>
                                                          <li
                                                            className={
                                                              selectedDateFilter ===
                                                              val
                                                                ? "activeBtn"
                                                                : ""
                                                            }
                                                            key={index}
                                                            onClick={() => {
                                                              setSelectedDateFilter(
                                                                val
                                                              );
                                                              val !==
                                                                "Custom range" &&
                                                                handleDateFilter(
                                                                  val
                                                                );
                                                            }}
                                                          >
                                                            {filterDropdown.length -
                                                              1 ===
                                                            index ? (
                                                              <div className="uk-inline uk-text-left">
                                                                <button
                                                                  className="rangeBtn"
                                                                  type="button"
                                                                >
                                                                  <span className="rangeBtnCircle"></span>{" "}
                                                                  Custom range
                                                                </button>
                                                                <div
                                                                  id="datepickerdrop"
                                                                  uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                                  className="date-picker-container"
                                                                >
                                                                  <CalendarDate
                                                                    onChange={
                                                                      onChangeRouting
                                                                    }
                                                                  />
                                                                  <div className="date-picker-btn-container uk-text-right">
                                                                    <button
                                                                      className="date-selection-btn"
                                                                      onClick={
                                                                        handleDatePickerCloseRouting
                                                                      }
                                                                    >
                                                                      Ok
                                                                    </button>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              <button
                                                                type="button"
                                                                className="rangeBtn"
                                                              >
                                                                <span className="rangeBtnCircle"></span>{" "}
                                                                {val}
                                                              </button>
                                                            )}
                                                          </li>
                                                          {filterDropdown.length -
                                                            2 ===
                                                            index && (
                                                            <hr
                                                              style={{
                                                                margin: "5px 0",
                                                              }}
                                                            />
                                                          )}
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </ul>
                                              </div>
                                            </div> */}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="uk-width-1-1 uk-margin-remove">
                                            <div className="overviewMainContent">
                                              <div className="mainBox">
                                                <div className="boxHeading">
                                                  <span>
                                                    Average breakdown per status
                                                  </span>
                                                </div>
                                                <div className="boxContent">
                                                  <div
                                                    className="uk-grid"
                                                    uk-grid=""
                                                  >
                                                    <div className="uk-width-1-3 uk-first-column">
                                                      <div className="txtwrp">
                                                        <h2>
                                                          {RouteStats ===
                                                          null ? (
                                                            <div uk-spinner=""></div>
                                                          ) : (
                                                            RouteStats?.totalRoutes
                                                          )}
                                                        </h2>
                                                        <span>
                                                          Total routes
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-1-3">
                                                      <div className="txtwrp">
                                                        <h2>
                                                          {RouteStats ===
                                                          null ? (
                                                            <div uk-spinner=""></div>
                                                          ) : (
                                                            RouteStats?.totalRoutesAssignedToUsers
                                                          )}
                                                        </h2>
                                                        <span>
                                                          Assigned to users
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-1-3">
                                                      <div className="txtwrp">
                                                        <h2>
                                                          {RouteStats ===
                                                          null ? (
                                                            <div uk-spinner=""></div>
                                                          ) : (
                                                            RouteStats?.totalRoutesAssignedToTeams
                                                          )}
                                                        </h2>
                                                        <span>
                                                          Assigned to teams
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="mainBox">
                                                <div className="boxHeading">
                                                  <span>Route details</span>
                                                </div>
                                                <div className="boxFullTable tableCenter">
                                                  <table className="uk-table uk-table-striped">
                                                    <thead>
                                                      <tr>
                                                        {/* <th className="uk-table-expand">
                                                        ROUTE
                                                      </th>
                                                      <th>OPEN</th>
                                                      <th>CLOSED</th>
                                                      <th>SOLVED</th>
                                                      <th>WAITING</th>
                                                      <th>
                                                        AVG. RESPONSE TIME
                                                      </th>
                                                      <th>AVG HANDLING TIME</th> */}
                                                        <th className="uk-table-expand">
                                                          ROUTE
                                                        </th>
                                                        <th>CUSTOMER NAME</th>
                                                        <th>ROUTE REASON</th>
                                                        <th>
                                                          CONVERSATION STATUS
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {RouteStats?.routeRecords
                                                        ?.content.length > 0 ? (
                                                        currentTableData?.map(
                                                          (val, index) => {
                                                            return (
                                                              <tr key={index}>
                                                                <td className="channelName">
                                                                  {/* <Link to="/live-chat-route-detail"> */}
                                                                  {
                                                                    val.routeName
                                                                  }
                                                                  {/* </Link> */}
                                                                </td>
                                                                <td>
                                                                  {
                                                                    val.customerName
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {
                                                                    val.routeReason
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {
                                                                    val.conversationStatus
                                                                  }
                                                                </td>
                                                                {/* <td>0</td>
                                                              <td>0</td>
                                                              <td>0</td>
                                                              <td>0</td>
                                                              <td>29:18:18</td>
                                                              <td>01:34:01</td> */}
                                                              </tr>
                                                            );
                                                          }
                                                        )
                                                      ) : (
                                                        <tr>
                                                          <td
                                                            colSpan={8}
                                                            className="dataNotFound"
                                                          >
                                                            {RouteStats ===
                                                            null ? (
                                                              <div uk-spinner=""></div>
                                                            ) : (
                                                              "Data Not Found"
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )}
                                                      {/* <tr>
                                                      <td className="channelName">
                                                        <Link to="/live-chat-route-detail">
                                                          Sales Department
                                                          Routing
                                                        </Link>
                                                      </td>
                                                      <td>0</td>
                                                      <td>0</td>
                                                      <td>0</td>
                                                      <td>0</td>
                                                      <td>29:18:18</td>
                                                      <td>01:34:01</td>
                                                    </tr> */}
                                                    </tbody>
                                                  </table>
                                                  {RouteStats !== null && (
                                                    <div
                                                      style={{
                                                        marginRight: "50px",
                                                      }}
                                                    >
                                                      <Pagination
                                                        className="pagination-bar"
                                                        currentPage={
                                                          currentPage
                                                        }
                                                        totalCount={
                                                          RouteStats
                                                            ?.routeRecords
                                                            ?.content.length
                                                        }
                                                        pageSize={PageSize}
                                                        onPageChange={(page) =>
                                                          setCurrentPage(page)
                                                        }
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li key={index}></li>
                )
              ) : null;
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default LiveChatRouting;
