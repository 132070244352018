import React from "react";
import blueHornImg from "../../../assets/images/bluehorn-icon.svg";
import bellIcon from "../../../assets/images/bell-icon.svg";
import keyIcon from "../../../assets/images/key-icon.svg";
import { useState } from "react";
import { useNavigate } from "react-router";

const NewMessageModal = ({ closeModal }) => {
  const [selectedTemplateType, setSelectedTemplateType] = useState("");
  const [errorEnable, setErrorEnable] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = () => {
    setErrorEnable(true);
    if (selectedTemplateType !== "") {
      navigate(`/create-template/${selectedTemplateType}`);
    }
  };
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>New Messages Template</span>
          </div>
          <div className="modalForm pauseModalContent">
            <div className="txtwrp" style={{ paddingTop: "24px" }}>
              <p>Select a catagory that best describe your message template.</p>
            </div>
            <div className="newMessageList">
              <ul>
                <li
                  onClick={() => setSelectedTemplateType("Marketing")}
                  className={
                    selectedTemplateType === "Marketing" && "activeTemplateType"
                  }
                >
                  <div className="messageListItem">
                    <div className="left">
                      <img src={blueHornImg} alt="" />
                    </div>
                    <div className="right">
                      <h4>Marketing</h4>
                      <p>
                        Promotions or information about your business, products
                        or services. Or any message that isn't utility or
                        authentication.
                      </p>
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => setSelectedTemplateType("Utility")}
                  className={
                    selectedTemplateType === "Utility" && "activeTemplateType"
                  }
                >
                  <div className="messageListItem">
                    <div className="left">
                      <img src={bellIcon} alt="" />
                    </div>
                    <div className="right">
                      <h4>Utility</h4>
                      <p>
                        Messages about a specific transaction, account, order or
                        customer request.
                      </p>
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => setSelectedTemplateType("Authentication")}
                  className={
                    selectedTemplateType === "Authentication" &&
                    "activeTemplateType"
                  }
                >
                  <div className="messageListItem">
                    <div className="left">
                      <img src={keyIcon} alt="" />
                    </div>
                    <div className="right">
                      <h4>Authentication</h4>
                      <p>
                        One-time passwords that your customers use to
                        authenticate a transaction or login.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              {errorEnable && selectedTemplateType === "" && (
                <div className="formErrors">
                  {"Please select template message type"}
                </div>
              )}
            </div>
            <div className="formInputs dltModal"></div>
            <div className="btnwrp">
              <button type="button" className="btn-1" onClick={closeModal}>
                Cancel
              </button>
              <button className="btn-2" type="submit" onClick={handleNavigate}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMessageModal;
