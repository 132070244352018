import {
  ChevronRight,
  Download,
  OverflowMenuVertical,
  TrashCan,
  WatsonHealthStatusPartialFail,
  // WarningAltFilled,
} from "@carbon/icons-react";
import React from "react";
import DoughnutChart from "../../components/Graph/DoughnutChart";
import { useEffect } from "react";
import { useParams } from "react-router";
import { apiGet } from "../../Utils/apiServices";
import { useState } from "react";
import { convertDateAndTimeIndividually } from "../../Utils/DateFunctions";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  decryptedValue,
  encryptedValue,
} from "../../Utils/RouteHashConversion";
import axios from "axios";
import { useAuth } from "../../Context/AuthProvider";
const baseUrl = process.env.REACT_APP_BASEURL;

const BroadcastAnalytics = () => {
  const { auth } = useAuth();
  const params = useParams();
  const [broadcastDetails, setBroadcastDetails] = useState();
  const [broadcastPreviewDetails, setBroadcastPreviewDetails] = useState({});
  const getBroadcastDetails = () => {
    apiGet(
      `/broadcast/stats/${decryptedValue(params.publicId)}`,
      onSuccessBroadcastDetails,
      onFailureBroadcastDetails
    );
  };
  const onSuccessBroadcastDetails = (response) => {
    setBroadcastDetails(response?.data);
  };
  const onFailureBroadcastDetails = (error) => {
    console.log(error);
  };
  const getBroadcastPreviewDetails = () => {
    apiGet(
      `/broadcast/get/${decryptedValue(params.publicId)}`,
      onSuccessBroadcastPreviewDetails,
      onFailureBroadcastPreviewDetails
    );
  };
  const onSuccessBroadcastPreviewDetails = (response) => {
    setBroadcastPreviewDetails(response?.data);
  };
  const onFailureBroadcastPreviewDetails = (error) => {
    console.log(error);
  };
  useEffect(() => {
    getBroadcastPreviewDetails();
    getBroadcastDetails();
  }, []);

  const exportData = async () => {
    try {
      const response = await axios.get(
        baseUrl + `/broadcast/export/stats/${decryptedValue(params.publicId)}`,
        {
          responseType: "arraybuffer", // Ensure the response is treated as binary data
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Broadcast_detail.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  return (
    <>
      <div className="createNewSegment wintersShooperwrp">
        <div className="topBtns">
          <div className="uk-grid" uk-grid="">
            <div className="uk-width-1-1">
              <div className="btnwrp">
                <button
                  className="expBtn broadcastExportBtn"
                  onClick={exportData}
                >
                  <Download /> Get report
                </button>
                {/* <div className="uk-inline topBarDropDown">
                  <button type="button" className="menuIcon">
                    <OverflowMenuVertical />
                  </button>
                  <div uk-dropdown="" className="tableDropDown">
                    <ul>
                      <li>
                        <button className="trashBtn" type="button">
                          <TrashCan /> Delete
                        </button>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="uk-container">
          <div className="backBTn">
            <ul>
              <li>
                <Link to="/broadcast">
                  <span>Broadcast</span>
                </Link>
              </li>
              <li>
                <ChevronRight />
              </li>
              <li>
                <span className="currentPage">{broadcastDetails?.name}</span>
              </li>
            </ul>
          </div>
          <div className="mainHeading">
            <h3>{broadcastDetails?.name}</h3>
          </div>
          <div className="tagsWrp">
            {broadcastDetails !== undefined && (
              <ul>
                <li>
                  {broadcastDetails ? (
                    <span className="completeTag">
                      {broadcastDetails?.status}
                    </span>
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  <span>
                    WhatsApp sent to {broadcastDetails?.totalTraffic} Contacts
                  </span>
                </li>
                <li>
                  <span>
                    {(
                      (broadcastDetails?.totalDeliverMessage /
                        broadcastDetails?.totalTraffic) *
                      100
                    ).toFixed(2)}
                    % Delivery Rate
                  </span>
                </li>
                {/* <li>
                <span>29 Valid Recipients</span>
              </li> */}
                <li>
                  <span>
                    Started at{" "}
                    {convertDateAndTimeIndividually(
                      broadcastDetails?.startDateTime,
                      "date"
                    )}
                  </span>
                </li>
                <li>
                  <span>
                    Completed at{" "}
                    {convertDateAndTimeIndividually(
                      broadcastDetails?.startDateTime,
                      "date"
                    )}{" "}
                    ,{" "}
                    {convertDateAndTimeIndividually(
                      broadcastDetails?.startDateTime,
                      "time"
                    )}
                  </span>
                </li>
              </ul>
            )}
          </div>
          <div className="broadcastContentWrapper">
            <div className="broadcastTabbing">
              <ul uk-tab="connect: #broadcastInnerTab">
                <li>
                  <a href="#">Analytics</a>
                </li>
                <li>
                  <a href="#">Preview</a>
                </li>
              </ul>

              <div className="broadcastTabContent">
                <ul className="uk-switcher uk-margin" id="broadcastInnerTab">
                  <li>
                    <div className="overviewContent">
                      <div className="mainBox">
                        <div className="boxHeading">
                          <span>Broadcast Highlights</span>
                        </div>
                        <div className="boxContent">
                          <div className="uk-grid" uk-grid="">
                            <div className="uk-width-1-5 uk-first-column">
                              <div className="txtwrp">
                                <h2>
                                  {broadcastDetails?.totalTraffic ===
                                  undefined ? (
                                    <div uk-spinner=""></div>
                                  ) : (
                                    broadcastDetails?.totalTraffic
                                  )}
                                </h2>
                                <span>
                                  Traffic{" "}
                                  <WatsonHealthStatusPartialFail uk-tooltip="Number of recipient numbers uploaded via contacts, Excel, or segments for broadcasting." />
                                </span>
                              </div>
                            </div>
                            <div className="uk-width-1-5">
                              <div className="txtwrp">
                                <h2>
                                  {broadcastDetails?.totalSentMessage ===
                                  undefined ? (
                                    <div uk-spinner=""></div>
                                  ) : (
                                    broadcastDetails?.totalSentMessage
                                  )}
                                </h2>
                                <span>
                                  Sent
                                  <WatsonHealthStatusPartialFail uk-tooltip="Number of messages sent to Meta from the omni-channel platform for broadcasting." />
                                </span>
                              </div>
                            </div>
                            <div className="uk-width-1-5">
                              <div className="txtwrp">
                                <h2>
                                  {broadcastDetails?.totalDeliverMessage ===
                                  undefined ? (
                                    <div uk-spinner=""></div>
                                  ) : (
                                    broadcastDetails?.totalDeliverMessage
                                  )}
                                </h2>
                                <span>
                                  Delivered
                                  {/* <WatsonHealthStatusPartialFail uk-tooltip="Number of recipients who received the broadcast, ensuring" /> */}
                                  <WatsonHealthStatusPartialFail
                                    uk-tooltip={
                                      "Number of recipients who received the broadcast, ensuring \n - Internet connectivity is established. \n - WhatsApp is enabled. \n - Recipient has not blocked the sender."
                                    }
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="uk-width-1-5">
                              <div className="txtwrp">
                                <h2>
                                  {broadcastDetails?.totalReadMessage ===
                                  undefined ? (
                                    <div uk-spinner=""></div>
                                  ) : (
                                    broadcastDetails?.totalReadMessage
                                  )}
                                </h2>
                                <span>
                                  Read messages
                                  <WatsonHealthStatusPartialFail
                                    uk-tooltip={
                                      "Number of recipients who read the broadcast, ensuring \n - Read receipt is enabled on the recipient's phone. "
                                    }
                                  />
                                </span>
                              </div>
                            </div>
                            {/* <div className="uk-width-1-5">
                              <div className="txtwrp">
                                <h2>{broadcastDetails?.totalUnreadMessage}</h2>
                                <span>Unread messages</span>
                              </div>
                            </div> */}
                            <div className="uk-width-1-5">
                              <div className="txtwrp">
                                <h2>
                                  {broadcastDetails?.cost === undefined ? (
                                    <div uk-spinner=""></div>
                                  ) : (
                                    "$" + broadcastDetails?.cost.toFixed(2)
                                  )}
                                </h2>
                                <span>Estimated cost</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mainBox">
                        <div className="boxHeading">
                          <span>Broadcast Delivery rate</span>
                        </div>
                        <div className="boxContent">
                          <div className="uk-grid uk-flex-middle" uk-grid="">
                            <div className="uk-width-1-4">
                              <div className="chartWrp">
                                <DoughnutChart
                                  degree={360}
                                  backgroudColor={[
                                    "#29A869",
                                    "#A8200D",
                                    "#FFBFBD",
                                  ]}
                                  borderColor={[
                                    "#29A869",
                                    "#A8200D",
                                    "#FFBFBD",
                                  ]}
                                  graphData={[
                                    broadcastDetails?.totalDeliverMessage,
                                    broadcastDetails?.totalFailMessage,
                                    broadcastDetails?.totalSystemError,
                                  ]}
                                  graphlabels={[
                                    "Delivered",
                                    "Failed",
                                    "System Error",
                                  ]}
                                />
                                <div className="chartData">
                                  <h3>
                                    {" "}
                                    {(
                                      (broadcastDetails?.totalDeliverMessage /
                                        broadcastDetails?.totalTraffic) *
                                      100
                                    ).toFixed(2)}
                                    %
                                  </h3>
                                  <p>Delivered</p>
                                  <p>
                                    {broadcastDetails?.totalDeliverMessage} of{" "}
                                    {broadcastDetails?.totalTraffic}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="uk-width-3-4">
                              <div className="chartRecordData">
                                <div className="chartRecordDataWrp">
                                  <div className="deliveryRate">
                                    <h3>DELIVERY RATE</h3>
                                    <ul>
                                      <li>
                                        <p>
                                          <span className="greenDot"></span>
                                          Delivered
                                        </p>
                                      </li>
                                      <li>
                                        <p>
                                          <span className="redDot"></span>Failed
                                        </p>
                                      </li>
                                      <li>
                                        <p>
                                          <span className="pinkDot"></span>
                                          System Error
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="progressBar">
                                    <ul>
                                      <li>
                                        <progress
                                          className="uk-progress progressNomral"
                                          value={
                                            broadcastDetails?.totalDeliverMessage
                                          }
                                          max={broadcastDetails?.totalTraffic}
                                        ></progress>
                                      </li>
                                      <li>
                                        <progress
                                          className="uk-progress progressHigh"
                                          value={
                                            broadcastDetails?.totalFailMessage
                                          }
                                          max={broadcastDetails?.totalTraffic}
                                        ></progress>
                                      </li>
                                      <li>
                                        <progress
                                          className="uk-progress progressError"
                                          value={
                                            broadcastDetails?.totalSystemError
                                          }
                                          max={broadcastDetails?.totalTraffic}
                                        ></progress>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="percentageData">
                                    <h3>PERCENTAGE</h3>
                                    <ul>
                                      <li>
                                        <p>
                                          {" "}
                                          {(
                                            (broadcastDetails?.totalDeliverMessage /
                                              broadcastDetails?.totalTraffic) *
                                            100
                                          ).toFixed(2)}
                                          %
                                        </p>
                                      </li>
                                      <li>
                                        <p>
                                          {" "}
                                          {(
                                            (broadcastDetails?.totalFailMessage /
                                              broadcastDetails?.totalTraffic) *
                                            100
                                          ).toFixed(2)}
                                          %
                                        </p>
                                      </li>
                                      <li>
                                        <p>
                                          {" "}
                                          {(
                                            (broadcastDetails?.totalSystemError /
                                              broadcastDetails?.totalTraffic) *
                                            100
                                          ).toFixed(2)}
                                          %
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="totalData">
                                    <h3>TOTAL</h3>
                                    <ul>
                                      <li>
                                        <p>
                                          {
                                            broadcastDetails?.totalDeliverMessage
                                          }
                                        </p>
                                      </li>
                                      <li>
                                        <p>
                                          {broadcastDetails?.totalFailMessage}
                                        </p>
                                      </li>
                                      <li>
                                        <p>
                                          {broadcastDetails?.totalSystemError}
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="halfDonutSec halfDonutSize">
                        <div
                          className="uk-grid"
                          uk-grid=""
                          uk-height-match="target: > div > .halfDonutHeight"
                        >
                          <div className="uk-width-1-2">
                            <div className="mainBox halfDonutHeight">
                              <div className="boxHeading">
                                <span>Engaged Audience</span>
                              </div>
                              <div className="boxContent">
                                <div className="chartWrp">
                                  <DoughnutChart
                                    degree={180}
                                    backgroudColor={["#2F5711", "#1EB954"]}
                                    borderColor={["#2F5711", "#1EB954"]}
                                    graphData={[
                                      broadcastDetails?.totalReadMessage,
                                      broadcastDetails?.totalUnreadMessage,
                                    ]}
                                    graphlabels={["Read", "UnRead"]}
                                  />
                                  <div
                                    className="chartData"
                                    style={{ top: "50%" }}
                                  >
                                    <h3>
                                      {(
                                        (broadcastDetails?.totalSentMessage /
                                          broadcastDetails?.totalTraffic) *
                                        100
                                      ).toFixed(2)}
                                      %
                                    </h3>
                                    <p>Message Sent</p>
                                  </div>
                                </div>
                                <div className="chartDataWrapper">
                                  <ul>
                                    <li>
                                      <p>
                                        <span className="darkGreen"></span>Read
                                      </p>
                                    </li>
                                    <li>
                                      <p>
                                        {broadcastDetails?.totalReadMessage}
                                      </p>
                                    </li>
                                  </ul>
                                  <ul>
                                    <li>
                                      <p>
                                        <span className="lightGreen"></span>
                                        Unread
                                      </p>
                                    </li>
                                    <li>
                                      <p>
                                        {broadcastDetails?.totalUnreadMessage}
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="uk-width-1-2">
                            <div className="mainBox halfDonutHeight">
                              <div className="boxHeading">
                                <span>Broadcast Summary</span>
                              </div>
                              <div className="boxContent">
                                <div className="uk-grid" uk-grid="">
                                  <div className="uk-width-1-1">
                                    <div className="contentHeading">
                                      <span>Broadcast</span>
                                    </div>
                                    <div className="contetnPara">
                                      <p>{broadcastDetails?.name}</p>
                                      <p>{broadcastDetails?.description}</p>
                                    </div>
                                  </div>
                                  <div className="uk-width-1-1">
                                    <div className="recipientsWrp">
                                      <div className="contentHeading">
                                        <span>Recipients</span>
                                      </div>
                                      <ul>
                                        <li>
                                          <div className="contetnPara">
                                            <p>Total receipts</p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="contentHeading">
                                            <span>
                                              {broadcastDetails?.totalTraffic}
                                            </span>
                                          </div>
                                        </li>
                                      </ul>
                                      <ul>
                                        <li>
                                          <div className="contetnPara">
                                            <p>Total messages</p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="contentHeading">
                                            <span>
                                              {broadcastDetails?.totalTraffic}
                                            </span>
                                          </div>
                                        </li>
                                      </ul>
                                      <ul>
                                        <li>
                                          <div className="contetnPara">
                                            <p>Catagory</p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="contentHeading">
                                            <span>
                                              {broadcastDetails?.category}
                                            </span>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    <div
                                      className="recipientsWrp"
                                      style={{ marginTop: "32px" }}
                                    >
                                      <div className="contentHeading">
                                        <span>Payment summary</span>
                                      </div>
                                      <ul>
                                        <li>
                                          <div className="contetnPara">
                                            <p>Approximate cost</p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="contentHeading">
                                            <span>
                                              $
                                              {broadcastDetails?.cost.toFixed(
                                                4
                                              )}
                                            </span>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="mainBox">
                        <div className="boxHeading">
                          <span>Broadcast overview</span>
                        </div>
                        <div className="boxContent">
                          <div className="uk-grid" uk-grid="">
                            <div className="uk-width-1-1">
                              <div className="overviewChart">
                                <LineChart
                                  dataSet1={[50, 20, 30, 25, 0, 40, 10]}
                                />
                              </div>
                              <div className="chartLabel">
                                <div className="labelItem">
                                  <img src={whatsAppImg} alt="" />
                                  <p>WhatsApp (2,234)</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className="mainBox">
                        <div className="boxHeading">
                          <span>Recipients</span>
                        </div>
                        <div className="boxContent" style={{ padding: "0" }}>
                          <div className="analyticsTableWrp broadcastInnerTable">
                            <div className="uk-grid" uk-grid=""></div>
                            <table className="uk-table uk-table-striped">
                              <thead>
                                <tr>
                                  <th>RECIPIENTS MOBILE</th>
                                  {/* <th>NAME</th> */}
                                  <th>STATUS</th>
                                  <th>ENGAGED</th>
                                  {/* <th>START DATE</th> */}
                                  <th>SENT DATE</th>
                                  <th>SENT TIME</th>
                                </tr>
                              </thead>
                              <tbody>
                                {broadcastDetails?.recipient?.map(
                                  (val, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{val.number}</td>
                                        {/* <td>{val?.name}</td> */}
                                        <td>
                                          <span className="serviceInitiated">
                                            {val.deliveryStatus}
                                          </span>
                                        </td>
                                        <td>
                                          {val.deliveryStatus === "DELIVERED"
                                            ? val.status
                                            : "-"}
                                        </td>
                                        {/* <td> */}
                                        {/* {convertDateAndTimeIndividually(
                                            val.updateDateTime,
                                            "date"
                                          )} */}
                                        {/* {val.updateDateTime}
                                        </td> */}
                                        <td>
                                          {convertDateAndTimeIndividually(
                                            val?.dateTime,
                                            "date"
                                          )}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertDateAndTimeIndividually(
                                            val?.dateTime,
                                            "time"
                                          )}{" "}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                                {/* <tr>
                                  <td>0300 2681869</td>
                                  <td>Naveen Asim</td>
                                  <td>
                                    <span className="serviceInitiated">
                                      Delivered
                                    </span>
                                  </td>
                                  <td>Read</td>
                                  <td>10 Aug</td>
                                  <td>10 Aug</td>
                                  <td>18:21</td>
                                </tr>
                                <tr>
                                  <td>0300 2681869</td>
                                  <td>Naveen Asim</td>
                                  <td>
                                    <span className="broadcastFailed">
                                      {" "}
                                      Failed
                                    </span>
                                  </td>
                                  <td>Read</td>
                                  <td>10 Aug</td>
                                  <td>10 Aug</td>
                                  <td>18:21</td>
                                </tr>
                                <tr>
                                  <td>0300 2681869</td>
                                  <td>Naveen Asim</td>
                                  <td>
                                    <span className="broadcastFailed">
                                      <WarningAltFilled /> System error
                                    </span>
                                  </td>
                                  <td>Read</td>
                                  <td>10 Aug</td>
                                  <td>10 Aug</td>
                                  <td>18:21</td>
                                </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="broadcastPreviewTab overviewContent">
                      <div className="mainBox">
                        <div className="boxHeading">
                          <span>Broadcast preview</span>
                        </div>
                        <div className="boxContent">
                          <div
                            className="mobilePreview"
                            style={{ margin: "0 auto" }}
                          >
                            <div className="mobileMsgs">
                              <div className="msgDate">
                                <span>
                                  {moment(
                                    broadcastPreviewDetails?.startDate
                                  ).format("dddd, MMMM YYYY")}
                                </span>
                              </div>
                              {broadcastPreviewDetails?.sendTemplateRequest
                                ?.template?.components !== undefined &&
                                broadcastPreviewDetails?.sendTemplateRequest?.template?.components.map(
                                  (val, index) => {
                                    return (
                                      <div key={index}>
                                        {val.type === "header" &&
                                          val.parameters.map((item, i) => {
                                            return item.type === "image" ? (
                                              <div className="imgBox" key={i}>
                                                <img
                                                  src={item.image.link}
                                                  alt=""
                                                />
                                              </div>
                                            ) : (
                                              item.type === "text" && (
                                                <div
                                                  className="molbileMsgBoxGrey"
                                                  key={i}
                                                >
                                                  <span>{item.text}</span>
                                                </div>
                                              )
                                            );
                                          })}
                                        {val.type === "body" &&
                                          val.parameters.map((item, i) => {
                                            return (
                                              <div
                                                className="molbileMsgBoxGrey"
                                                key={i}
                                              >
                                                <span>{item.text}</span>
                                              </div>
                                            );
                                          })}
                                        {val.type === "button" &&
                                          val.parameters.map((item, i) => {
                                            return (
                                              <div
                                                className="molbileMsgBoxGrey"
                                                key={i}
                                              >
                                                <button>{item.payload}</button>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BroadcastAnalytics;
