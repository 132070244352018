import React from "react";
import { NavLink } from "react-router-dom";

const SuperAdminSettingSidear = () => {
  return (
    <div className="settingSideBar">
      <div className="sideBarHeading">
        <h3>Main</h3>
      </div>
      <ul>
        <li>
          <NavLink to="/superadmin-settings" activeClassName="activeLink">
            Taxes
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default SuperAdminSettingSidear;
