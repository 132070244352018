import { ChevronLeft, Document } from "@carbon/icons-react";
import React from "react";
import { Link, useParams } from "react-router-dom";
// import mobileImg from "../../assets/images/mobile-img.jpg";
// import chatVideo from "../../assets/images/preview-video.mp4";
import { useEffect } from "react";
import { apiGet } from "../../Utils/apiServices";
import { useState } from "react";
import { decryptedValue } from "../../Utils/RouteHashConversion";

const TempelatePreview = () => {
  const templateLanguage = [
    { name: "LanguageCodeAfrikaans", value: "af" },
    { name: "Albanian", value: "sq" },
    { name: "Arabic", value: "ar" },
    { name: "Azerbaijani", value: "az" },
    { name: "Bengali", value: "bn" },
    { name: "Bulgarian", value: "bg" },
    { name: "Catalan", value: "ca" },
    { name: "Chinese (CHN)", value: "zh_CN" },
    { name: "Chinese (HKG)", value: "zh_HK" },
    { name: "Chinese (TAI)", value: "zh_TW" },
    { name: "Croatian", value: "hr" },
    { name: "Czech", value: "cs" },
    { name: "Danish", value: "da" },
    { name: "Dutch", value: "nl" },
    { name: "English", value: "en" },
    { name: "English (UK)", value: "en_GB" },
    { name: "English (US)", value: "en_US" },
    { name: "Estonian", value: "et" },
    { name: "Filipino", value: "fil" },
    { name: "French", value: "fr" },
    { name: "Georgian", value: "ka" },
    { name: "German", value: "de" },
    { name: "Greek", value: "el" },
    { name: "Gujarati", value: "gu" },
    { name: "Hausa", value: "ha" },
    { name: "Hebrew", value: "he" },
    { name: "Hindi", value: "hi" },
    { name: "Hungarian", value: "hu" },
    { name: "Indonesian", value: "id" },
    { name: "Irish", value: "ga" },
    { name: "Italian", value: "it" },
    { name: "Japanese", value: "ja" },
    { name: "Kannada", value: "kn" },
    { name: "Kazakh", value: "kk" },
    { name: "Kinyarwanda", value: "rw_RW" },
    { name: "Korean", value: "ko" },
    { name: "Kyrgyz (Kyrgyzstan)", value: "ky_KG" },
    { name: "Lao", value: "lo" },
    { name: "Latvian", value: "lv" },
    { name: "Macedonian", value: "ms" },
    { name: "Malay", value: "ms" },
    { name: "Malayalam", value: "ml" },
    { name: "Marathi", value: "mr" },
    { name: "Norwegian", value: "nb" },
    { name: "Persian", value: "fa" },
    { name: "Polish", value: "pl" },
    { name: "Portuguese (BR)", value: "pt_BR" },
    { name: "Portuguese (POR)", value: "pt_PT" },
    { name: "Punjabi", value: "pa" },
    { name: "Romanian", value: "ro" },
    { name: "Russian", value: "ru" },
    { name: "Serbian", value: "sr" },
    { name: "Slovak", value: "sk" },
    { name: "Slovenian", value: "sl" },
    { name: "Spanish", value: "es" },
    { name: " Spanish (MEX)", value: "es_MX" },
    { name: "Swahili", value: "sw" },
    { name: "Swedish", value: "sv" },
    { name: "Tamil", value: "ta" },
    { name: "Telugu", value: "te" },
    { name: "Thai", value: "th" },
    { name: "Turkish", value: "tr" },
    { name: "Ukrainian", value: "uk" },
    { name: "Urdu", value: "ur" },
    { name: "Uzbek", value: "uz" },
    { name: "Vietnamese", value: "vi" },
    { name: "Zulu", value: "zu" },
    { name: "Uzbek", value: "uz" },
  ];
  const params = useParams();
  const [previewDetails, setPreviewDetails] = useState();
  const [language, setLanguage] = useState();
  const getTemplatePreviewDetails = () => {
    apiGet(
      `/template/details/${decryptedValue(params.publicId)}`,
      onSuccessTemplatePreviewDetails,
      onFailureTemplatePreviewDetails
    );
  };
  const onSuccessTemplatePreviewDetails = (response) => {
    setPreviewDetails(response?.data);
  };
  const onFailureTemplatePreviewDetails = (error) => {
    console.log(error);
  };
  useEffect(() => {
    getTemplatePreviewDetails();
  }, []);

  useEffect(() => {
    let previewLanguage = templateLanguage.filter((val) => {
      return val.value == previewDetails?.language;
    });
    if (previewLanguage.length > 0) {
      setLanguage(previewLanguage[0].name);
    }
  }, [previewDetails]);
  return (
    <div className="addTeamWrp createBroadcastWrp">
      <div className="uk-container">
        <div className="backBtnWrp">
          <div className="backBtn uk-margin-remove-bottom">
            <Link to="/template-message">
              <ChevronLeft /> Back to Template
            </Link>
          </div>
          {/* <div className="editTempeleteBtn">
            <button type="button">Edit template</button>
          </div> */}
        </div>
        <div className="addTeamHeading">
          <h3>{previewDetails?.name}</h3>
          <p>
            {previewDetails?.status} - {previewDetails?.category}
            {/* - updated 15
            Aug 2023 */}
          </p>
        </div>
        <div className="createBroadcastContent">
          <div className="uk-grid" uk-grid="">
            <div className="uk-width-1-2">
              <div className="broadcastRight tempelatePreviewWrp">
                <div className="broadcastTxtWrp">
                  <p>Template name</p>
                  <span>{previewDetails?.name}</span>
                </div>
                <div className="broadcastTxtWrp">
                  <p>Status</p>
                  <span>{previewDetails?.status}</span>
                </div>
                {/* <div className="broadcastTxtWrp">
                  <p>Description</p>
                  <span>
                    Eid-ul-Adha 2024 lead generation campaign for increased
                    sales
                  </span>
                </div> */}
                <div className="broadcastLising">
                  <div className="listingWrp">
                    <div className="left">
                      <span>Catagory</span>
                    </div>
                    <div className="right">
                      <span>{previewDetails?.category}</span>
                    </div>
                  </div>
                  <div className="listingWrp">
                    <div className="left">
                      <span>Language</span>
                    </div>
                    <div className="right">
                      <span>{language}</span>
                    </div>
                  </div>
                </div>
                {/* <div className="broadcastTxtWrp">
                  <p style={{ color: "#454545" }}>Performance overview</p>
                </div>
                <div className="broadcastLising">
                  <div className="listingWrp">
                    <div className="left">
                      <span>Messages sent</span>
                    </div>
                    <div className="right">
                      <span>13,527</span>
                    </div>
                  </div>
                  <div className="listingWrp">
                    <div className="left">
                      <span>Messages Read Rate</span>
                    </div>
                    <div className="right">
                      <span>89%</span>
                    </div>
                  </div>
                </div> */}
                {/* <div className="broadcastTxtWrp">
                  <p style={{ color: "#454545" }}>Template history</p>
                </div>
                <div className="broadcastLising">
                  <div className="listingWrp">
                    <div className="left">
                      <span>Created by</span>
                    </div>
                    <div className="right">
                      <span>Azlan Khan</span>
                    </div>
                  </div>
                  <div className="listingWrp">
                    <div className="left">
                      <span>Created date</span>
                    </div>
                    <div className="right">
                      <span>10 Aug, 2023 at 2:30 pm</span>
                    </div>
                  </div>
                  <div className="listingWrp">
                    <div className="left">
                      <span>Updated date</span>
                    </div>
                    <div className="right">
                      <span>15 Aug 2023 at 3:04 pm</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="uk-width-1-2">
              <div className="previewHeading" style={{ textAlign: "left" }}>
                <h3>Broadcast preview</h3>
              </div>
              <div className="mobilePreview">
                <div className="mobileMsgs">
                  {/* <div className="msgDate">
                    <span>Friday, November 8</span>
                  </div> */}

                  {previewDetails?.components.map((val, index) => {
                    return (
                      <>
                        {val.type === "HEADER" && val.format === "IMAGE" && (
                          <div className="imgBox" key={index}>
                            <img
                              src={
                                val.example === undefined
                                  ? ""
                                  : val.example.header_handle[0]
                              }
                              alt=""
                            />
                          </div>
                        )}
                        {val.type === "HEADER" && val.format === "VIDEO" && (
                          <div className="videoBox" key={index}>
                            <video
                              src={
                                val.example === undefined
                                  ? ""
                                  : val.example.header_handle[0]
                              }
                              controls
                            ></video>
                          </div>
                        )}
                        {val.type === "HEADER" && val.format === "DOCUMENT" && (
                          <div className="documentMsg">
                            <p>
                              <Document /> document
                            </p>
                          </div>
                        )}
                        {val.type === "HEADER" && val.format === "TEXT" && (
                          <div className="leftMsg">
                            <span>{val.text}</span>
                          </div>
                        )}
                        {val.type === "BODY" && (
                          // <div className="MsgBox molbileMsgBox">
                          //   <button>{val.text}</button>
                          // </div>
                          <div className="leftMsg">
                            <span>{val.text}</span>
                          </div>
                        )}
                        {val.type === "FOOTER" && (
                          <div className="leftMsg">
                            <span>{val.text}</span>
                          </div>
                        )}
                        {val.type === "BUTTONS" &&
                          val.buttons.map((item, i) => {
                            return (
                              <div key={i} className="MsgBox molbileMsgBox">
                                <button>{item.text}</button>
                              </div>
                            );
                          })}
                      </>
                    );
                  })}
                  {/* <div className="imgBox">
                    <img src={mobileImg} alt="" />
                  </div>
                  <div className="videoBox">
                    <video src={chatVideo} controls></video>
                  </div>
                  <div className="leftMsg">
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Molestias fugiat sit qui omnis ipsa. Provident!
                    </span>
                  </div>
                  <div className="rightMsg">
                    <span>Lorem ipsum dolor sit amet consectetur.</span>
                  </div>
                  <div className="leftMsg">
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Molestias fugiat sit qui omnis ipsa. Provident!
                    </span>
                  </div>
                  <div className="rightMsg">
                    <span>Lorem ipsum dolor sit amet consectetur.</span>
                  </div>
                  <div className="leftMsg">
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Molestias fugiat sit qui omnis ipsa. Provident!
                    </span>
                  </div>
                  <div className="rightMsg">
                    <span>Lorem ipsum dolor sit amet consectetur.</span>
                  </div>
                  <div className="leftMsg">
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Molestias fugiat sit qui omnis ipsa. Provident!
                    </span>
                  </div>
                  <div className="rightMsg">
                    <span>Lorem ipsum dolor sit amet consectetur.</span>
                  </div>
                  <div className="leftMsg">
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Molestias fugiat sit qui omnis ipsa. Provident!
                    </span>
                  </div>
                  <div className="rightMsg">
                    <span>Lorem ipsum dolor sit amet consectetur.</span>
                  </div>
                  <div className="leftMsg">
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Molestias fugiat sit qui omnis ipsa. Provident!
                    </span>
                  </div>
                  <div className="rightMsg">
                    <span>Lorem ipsum dolor sit amet consectetur.</span>
                  </div>
                  <div className="leftMsg">
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Molestias fugiat sit qui omnis ipsa. Provident!
                    </span>
                  </div>
                  <div className="rightMsg">
                    <span>Lorem ipsum dolor sit amet consectetur.</span>
                  </div>
                  <div className="leftMsg">
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Molestias fugiat sit qui omnis ipsa. Provident!
                    </span>
                  </div>
                  <div className="rightMsg">
                    <span>Lorem ipsum dolor sit amet consectetur.</span>
                  </div>
                  <div className="leftMsg">
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Molestias fugiat sit qui omnis ipsa. Provident!
                    </span>
                  </div>
                  <div className="rightMsg">
                    <span>Lorem ipsum dolor sit amet consectetur.</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TempelatePreview;
