import React from "react";
import { useState } from "react";
import { Edit } from "@carbon/icons-react";
import { useContextChatBotFacebook } from "../ContextChatBot";
import { Close } from "@carbon/icons-react";

const TriggerChatbot = () => {
  const {
    handleInputChange,
    handleKeyPress,
    triggerValue,
    inputTriggerValue,
    isKeywordVisible,
    toggleKeywordVisibility,
    handleRemoveTrigger,
    handleEditClickBName,
    handleSaveClickBName,
    handleChangeBName,
    isEditingBName,
    textBName,
  } = useContextChatBotFacebook();

  return (
    <div>
      <div className="triggerOptionWrp">
        <div className="topHeading">
          <h3>
            {isEditingBName ? (
              <input
                type="text"
                value={textBName}
                onChange={handleChangeBName}
                placeholder="Enter Bot Name "
                className="triggerInput"
              />
            ) : (
              textBName || "Enter bot name"
            )}
            {isEditingBName ? (
              <button className="triggerBtn" onClick={handleSaveClickBName}>
                Save
              </button>
            ) : (
              <Edit onClick={handleEditClickBName} />
            )}
          </h3>
        </div>
        <div className="triggerOptionContent">
          <div className="instructionTxt">
            <span>A trigger is an event that starts your Flow. </span> <br/>
            <span>To close the bot type exit.</span>
          </div>

          {triggerValue
            ?.filter((item) => item !== "Add a trigger")
            .map((item, index) => (
              <div className="broadcastContacts" key={index}>
                <ul>
                  <li>
                    <span>
                      {item}
                      <button
                        type="button"
                        onClick={() => handleRemoveTrigger(item)}
                      >
                        <Close />
                      </button>
                    </span>
                  </li>
                </ul>
              </div>
            ))}

          <div className="keywordMessage">
            <p>Message contains</p>
            {isKeywordVisible ? (
              <input
                type="text"
                placeholder="Add keyword"
                className="uk-input"
                value={inputTriggerValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
              />
            ) : (
              <button
                type="button"
                className="keywordBtn"
                // onClick={() => toggleSection("keyword")}
                onClick={toggleKeywordVisibility}
              >
                + Keyword
              </button>
            )}
   
          </div>
   
        </div>
      </div>
    </div>
  );
};

export default TriggerChatbot;
