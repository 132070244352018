import React, { useState } from "react";
import SettingSideBar from "./components/SettingSideBar";
// import demoImg from "../../assets/images/avatar-02.png";
import { ChevronDown } from "@carbon/icons-react";
import Footer from "../../components/common/Footer/Footer";
import Switch from "react-switch";
// import Multiselect from "multiselect-react-dropdown";
import { Close } from "@carbon/icons-react";
import { apiGet, apiPost, apiPut } from "../../Utils/apiServices";
import { useEffect } from "react";
import { ValidateEmail } from "../../Utils/regexFunctions";
import { toast } from "react-toastify";
import UIkit from "uikit";
import { useOutletContext } from "react-router-dom";

const NotificationSetting = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber
  ] = useOutletContext();
  const [loader, setLoader] = useState(false);
  const [errorEnable, setErrorEnable] = useState(false);
  const [expireNotificationToggle, setExpireNotificationToggle] =
    useState(false);
  const [consumeNotificationToggle, setConsumeNotificationToggle] =
    useState(false);
  const [notificationPrioirty, setNotificationPrioirty] =
    useState("10 Days prior");

  const [notifiction, setNotification] = useState();
  const [if90Consumed, setIf90Consumed] = useState(false);
  const [if100Consumed, setIf100Consumed] = useState(false);
  // const [checked, setChecked] = useState(false);
  // const [showEmailInput, setShowEmailInput] = useState(false);
  // const [showEmailInputNotify, setShowEmailInputNotify] = useState(false);

  const [emailAddress, setEmailAddress] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [emailAddressNotify, setEmailAddressNotify] = useState("");
  const [emailListNotify, setEmailListNotify] = useState([]);

  // const handleEmailCheckboxChange = () => {
  //   setShowEmailInput(!showEmailInput);
  // };

  const closeDrop = () => {
    UIkit.dropdown("#expiryDropdown").hide();
  };

  const handleEmailInputChange = (e) => {
    setEmailAddress(e.target.value);
  };
  const handleEmailInputChangeNotify = (e) => {
    setEmailAddressNotify(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the Enter key from adding a new line
      let email = ValidateEmail(emailAddress);
      if (email === "Valid Email") {
        addEmailAddress();
      }
    }
  };

  const addEmailAddress = () => {
    if (emailAddress.trim() !== "") {
      setEmailList([...emailList, emailAddress]);
      setEmailAddress(""); // Clear the input field
    }
  };

  const handleKeyPressNotify = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the Enter key from adding a new line
      let email = ValidateEmail(emailAddressNotify);
      if (email === "Valid Email") {
        addEmailAddressNotify();
      }
    }
  };

  const addEmailAddressNotify = () => {
    if (emailAddressNotify.trim() !== "") {
      setEmailListNotify([...emailListNotify, emailAddressNotify]);
      setEmailAddressNotify(""); // Clear the input field
    }
  };

  const removeEmailAddress = (indexToRemove) => {
    setEmailList((prevEmailList) =>
      prevEmailList.filter((_, index) => index !== indexToRemove)
    );
  };
  const removeEmailAddressNotify = (indexToRemove) => {
    setEmailListNotify((prevEmailList) =>
      prevEmailList.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleChecked = () => {
    setExpireNotificationToggle(!expireNotificationToggle);
  };
  const handleCheckedForMau = () => {
    setConsumeNotificationToggle(!consumeNotificationToggle);
  };

  // const handleCheckboxChange = () => {
  //   setShowEmailInput(!showEmailInput);
  // };
  // const handleCheckboxChangeNotify = () => {
  //   setShowEmailInputNotify(!showEmailInputNotify);
  // };

  function isValidEmail(email) {
    // Regular expression for email validation
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

    return emailRegex.test(email);
  }

  const getNotificationSetting = () => {
    apiGet(`/settings/notification`, onSuccess, onFailure);
  };
  const onSuccess = (response) => {
    if (response.data !== null) {
      setNotification(response.data);
    }
  };
  const onFailure = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (notifiction !== undefined) {
      setConsumeNotificationToggle(
        notifiction.consumeNotificationToggle == "true" ? true : false
      );
      setNotificationPrioirty(notifiction.expireNotification);
      setExpireNotificationToggle(
        notifiction.expireNotificationToggle == "true" ? true : false
      );
      setEmailList(notifiction.emailNotifications.split(", "));
      setEmailListNotify(notifiction.consumedNotificationEmail.split(", "));
      setIf100Consumed(notifiction.if90Consumed);
      setIf90Consumed(notifiction.if100Consumed);
    }
  }, [notifiction]);
  const saveNotification = () => {
    let data = {
      expireNotificationToggle: expireNotificationToggle,
      consumeNotificationToggle: consumeNotificationToggle,
      expireNotification: notificationPrioirty,
      consumedNotificationEmail: emailListNotify.join(", "),
      emailNotifications: emailList.join(", "),
      if90Consumed: if90Consumed,
      if100Consumed: if100Consumed,
    };

    if (notifiction !== undefined) {
      data.publicId = notifiction.publicId;
    }
    setLoader(true);
    setErrorEnable(true);
    if (
      // expireNotificationToggle === true &&
      // consumeNotificationToggle === true &&
      emailList.length !== 0 &&
      emailListNotify.length !== 0 &&
      emailAddress == "" &&
      emailAddressNotify == ""
    ) {
      if (notifiction !== undefined) {
        apiPut(
          "/settings/notification",
          onSuccessUpdateNotification,
          onFailureUpdateNotification,
          data
        );
      }
      if (notifiction === undefined) {
        apiPost(
          "/settings/notification-save",
          onSuccessSaveNotification,
          onFailureSaveNotification,
          data
        );
      }
    } else {
      setLoader(false);
    }
  };
  const onSuccessSaveNotification = (response) => {
    toast.success("Notification saved successfully", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
    setLoader(false);
  };
  const onFailureSaveNotification = (error) => {
    console.log(error);
    setLoader(false);
  };
  const onSuccessUpdateNotification = (response) => {
    toast.success("Notification updated successfully", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
    setLoader(false);
  };
  const onFailureUpdateNotification = (error) => {
    console.log(error);
    setLoader(false);
  };
  useEffect(() => {
    getNotificationSetting();
  }, []);
  return (
    <>
      <div className="settingwrp">
        <div className="topBar">
          <div className="uk-grid" uk-grid="">
            <div className="uk-width-1-1">
              <div className="txtwrp">
                <h3>Settings</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="settingContentWrp">
          <div className="uk-grid uk-grid-small" uk-grid="">
            <div className="uk-width-auto">
              <SettingSideBar userPermissions={userPermissions}/>
            </div>
            <div className="uk-width-expand">
              <div className="settingWrapper">
                <div className="uk-container">
                  <div className="settingTabbing">
                    <div className="generalSettingWrp">
                      <div className="mainHeading">
                        <h3>Notifications</h3>
                        <p>
                          View all notifications and configure your notification
                          subscriptions.
                        </p>
                      </div>
                      <div className="boxwrp">
                        <form action="javascript:void(0);">
                          <div className="headingAndSwitch">
                            <div className="boxHeading">
                              <h6>'Notify Package Expiration' Action</h6>
                              <p>
                                Enable to let BerryTalks send you a notification
                                prior to the expiration of your package.
                              </p>
                            </div>
                            <div className="switchwrp">
                              <Switch
                                onChange={handleChecked}
                                checked={expireNotificationToggle}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                width={26}
                                height={14}
                                handleDiameter={10}
                                onColor="#29a869"
                              />
                            </div>
                          </div>
                          <div className="formwrp">
                            {expireNotificationToggle && (
                              <>
                                <div className="settingDropdown">
                                  <div className="dropdownTxt">
                                    <span>Expiry notification priority</span>
                                  </div>
                                  <div className="uk-inline">
                                    <button
                                      className="dropdownBtn"
                                      type="button"
                                    >
                                      {notificationPrioirty}
                                      <ChevronDown />
                                    </button>
                                    <div
                                      uk-dropdown="mode: click"
                                      className="dropdownContent"
                                      id="expiryDropdown"
                                    >
                                      <ul>
                                        <li
                                          onClick={() => {
                                            closeDrop();
                                            setNotificationPrioirty(
                                              "10 Days prior"
                                            );
                                          }}
                                        >
                                          <button
                                            type="button"
                                            onClick={closeDrop}
                                          >
                                            10 Days prior
                                          </button>
                                        </li>
                                        <li
                                          onClick={() => {
                                            closeDrop();
                                            setNotificationPrioirty(
                                              "5 Days prior"
                                            );
                                          }}
                                        >
                                          <button type="button">
                                            5 Days prior
                                          </button>
                                        </li>
                                        <li
                                          onClick={() => {
                                            closeDrop();
                                            setNotificationPrioirty(
                                              "1 Days prior"
                                            );
                                          }}
                                        >
                                          <button type="button">
                                            1 Day prior
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="uk-grid uk-grid-small"
                                  uk-grid=""
                                >
                                  <div className="uk-width-1-1">
                                    <div className="multiSelectWrp">
                                      <div className="multiSelectTxt">
                                        <span>Notify me through</span>
                                      </div>
                                      <div>
                                        <div className="hiddenField">
                                          <input
                                            type="email"
                                            id="email"
                                            className="uk-input"
                                            placeholder="Enter Email Address"
                                            value={emailAddress}
                                            onKeyPress={handleKeyPress}
                                            onChange={(e) =>
                                              handleEmailInputChange(e)
                                            }
                                            disabled={
                                              emailList.length == 3 && true
                                            }
                                          />
                                        </div>
                                        {errorEnable &&
                                          emailList.length === 0 &&
                                          emailAddress === "" && (
                                            <div className="formErrors">
                                              Email is required
                                            </div>
                                          )}
                                        {errorEnable && emailAddress !== "" && (
                                          <div className="formErrors">
                                            Press enter to add emil address
                                          </div>
                                        )}
                                        <div className="broadcastContacts">
                                          <ul>
                                            {emailList.map((email, index) => (
                                              <li key={index}>
                                                <span>
                                                  {email}
                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      removeEmailAddress(index)
                                                    }
                                                  >
                                                    <Close />
                                                  </button>
                                                </span>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>

                                      {/* <div className="multiSelect">
                                    <Multiselect
                                      options={options}
                                      displayValue="name"
                                    />
                                  </div> */}
                                      {/* <div className="radioInput">
                                    <input type="checkbox" />
                                    <span>SMS</span>
                                  </div>
                                  <div className="multiSelect">
                                    <Multiselect
                                      options={options}
                                      displayValue="name"
                                    />
                                  </div> */}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            <hr />
                            <div className="headingAndSwitch">
                              <div className="boxHeading">
                                <h6>'Notify Package Consumption' Action</h6>
                                <p>
                                  Enable to let BerryTalks send you a
                                  notification regarding your
                                  package consumption.
                                </p>
                              </div>
                              <div className="switchwrp">
                                <Switch
                                  onChange={handleCheckedForMau}
                                  checked={consumeNotificationToggle}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  width={26}
                                  height={14}
                                  handleDiameter={10}
                                  onColor="#29a869"
                                />
                              </div>
                            </div>
                            <div className="uk-grid uk-grid-small" uk-grid="">
                              <div className="uk-width-1-1">
                                <div className="multiSelectWrp">
                                  {/* <div className="multiSelectTxt">
                                    <span>Notify me when the MAU limit is</span>
                                  </div>
                                  <div className="radioInput">
                                    <input type="checkbox" />
                                    <span>Email</span>
                                  </div> */}
                                  {/* <div className="radioInput">
                                    <input type="checkbox" />
                                    <span>SMS</span>
                                  </div> */}
                                </div>
                                {consumeNotificationToggle && (
                                  <>
                                    <div className="multiSelectWrp">
                                      <div className="multiSelectTxt">
                                        <span>
                                          Notify me when the MAU limit is
                                        </span>
                                      </div>
                                      <div className="radioInput">
                                        <input
                                          type="checkbox"
                                          checked={if90Consumed}
                                          onChange={() =>
                                            setIf90Consumed(!if90Consumed)
                                          }
                                        />
                                        <span>90% consumed</span>
                                      </div>
                                      <div className="radioInput">
                                        <input
                                          type="checkbox"
                                          checked={if100Consumed}
                                          onChange={() =>
                                            setIf100Consumed(!if100Consumed)
                                          }
                                        />
                                        <span>100% consumed</span>
                                      </div>
                                      <div className="multiSelectTxt">
                                        <span>Notify me through</span>
                                      </div>
                                      {/* <div className="radioInput">
                                        <input
                                          type="checkbox"
                                          checked={showEmailInputNotify}
                                          onChange={handleCheckboxChangeNotify}
                                        />
                                        <span>Email</span>
                                      </div> */}
                                      {/* <div className="multiSelect">
                                    <Multiselect
                                      options={options}
                                      displayValue="name"
                                    />
                                  </div> */}

                                      <div className="hiddenField">
                                        <input
                                          type="email"
                                          id="email"
                                          className="uk-input"
                                          placeholder="Enter Email Address"
                                          value={emailAddressNotify}
                                          onKeyPress={handleKeyPressNotify}
                                          onChange={
                                            handleEmailInputChangeNotify
                                          }
                                          disabled={
                                            emailListNotify.length == 3 && true
                                          }
                                        />
                                      </div>
                                      {errorEnable &&
                                        emailListNotify.length === 0 &&
                                        emailAddressNotify === "" && (
                                          <div className="formErrors">
                                            email is required
                                          </div>
                                        )}
                                      {errorEnable &&
                                        emailAddressNotify !== "" && (
                                          <div className="formErrors">
                                            press enter to add emil address
                                          </div>
                                        )}
                                      <div className="broadcastContacts">
                                        <ul>
                                          {emailListNotify.map(
                                            (email, index) => (
                                              <li key={index}>
                                                <span>
                                                  {email}

                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      removeEmailAddressNotify(
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <Close />
                                                  </button>
                                                </span>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>

                                      {/* <div className="radioInput">
                                    <input type="checkbox" />
                                    <span>SMS</span>
                                  </div> */}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>

                            {/* <hr /> */}

                            {/* <div className="headingAndSwitch">
                              <div className="boxHeading">
                                <h6>'Notify Package Expiration' Action</h6>
                                <p>
                                  Enable to let BerryTalks send you a
                                  notification prior to the expiration of your
                                  package.
                                </p>
                              </div>
                              <div className="switchwrp">
                                <Switch
                                  onChange={handleChecked}
                                  checked={checked}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  width={26}
                                  height={14}
                                  handleDiameter={10}
                                  onColor="#29a869"
                                />
                              </div>
                            </div>
                            <div className="uk-grid uk-grid-small" uk-grid="">
                              <div className="uk-width-1-1">
                                <div className="multiSelectWrp">
                                  <div className="multiSelectTxt">
                                    <span>Notify me when</span>
                                  </div>
                                  <div className="radioInput">
                                    <input type="checkbox" />
                                    <span>Account login with new IP</span>
                                  </div>
                                  <div className="radioInput">
                                    <input type="checkbox" />
                                    <span>User changes the password</span>
                                  </div>
                                </div>
                                <div className="multiSelectWrp">
                                  <div className="multiSelectTxt">
                                    <span>Notify me through</span>
                                  </div>
                                  <div className="radioInput">
                                    <input type="checkbox" />
                                    <span>Email</span>
                                  </div>
                                  <div className="multiSelect">
                                    <Multiselect
                                      options={options}
                                      displayValue="name"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            {/* <hr /> */}
                            {/* <div className="boxHeading">
                              <h6>Live Chat Notifications</h6>
                              <p>
                                Activate notifications sound for new messages
                                and assigned conversations.
                              </p>
                            </div> */}
                            <div className="uk-grid uk-grid-small" uk-grid="">
                              {/* <div className="uk-width-1-1">
                                <div className="multiSelectWrp">
                                  <div className="multiSelectTxt">
                                    <span>Notify me when</span>
                                  </div>
                                  <div className="radioInput">
                                    <input type="checkbox" />
                                    <span>
                                      A conversation is assigned to me
                                    </span>
                                  </div>
                                </div>
                              </div> */}
                              {/* <div className="uk-width-1-1">
                                <div className="multiSelectWrp">
                                  <div className="multiSelectTxt  uk-margin-remove-top">
                                    <span>Notification tone</span>
                                  </div>
                                  <div className="switchwrp uk-margin-top">
                                    <Switch
                                      onChange={handleChecked}
                                      checked={checked}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      width={26}
                                      height={14}
                                      handleDiameter={10}
                                      onColor="#29a869"
                                    />
                                  </div>
                                </div>
                              </div> */}
                              <div className="uk-width-1-1">
                                <div className="btnSection">
                                  <button
                                    onClick={saveNotification}
                                    className="w-90"
                                  >
                                    {
                                      loader ? (
                                        <div
                                          uk-spinner=""
                                          className="loader"
                                        ></div>
                                      ) : (
                                        // (notifiction !== undefined ? (
                                        //   "Save"
                                        // ) : (
                                        //   "Update"
                                        // )
                                        "Save"
                                      )
                                      // )
                                    }
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationSetting;
