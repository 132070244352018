import React, { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { apiGet, apiPost } from "../../../Utils/apiServices";
import { useAuth } from "../../../Context/AuthProvider";
import { ValidateEmail } from "../../../Utils/regexFunctions";
import { toast } from "react-toastify";
import $ from "jquery";
import { decryptedValue } from "../../../Utils/RouteHashConversion";

const AddContact = ({
  closeModal,
  multiSelectTags,
  formHeading,
  setApiCalled,
  btnText,
}) => {
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { auth } = useAuth();
  const [contact, setContact] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    mobileNumber: "",
    email: "",
    country: "",
    city: "",
    address: "",
    contactTags: tags,
    companyId: decryptedValue(auth.companyId),
    isActive: true,
    status: "active",
  });
  const [errorEnable, setErrorEnable] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [emailError, setEmailError] = useState("Valid Email Address");
  const [loader, setLoader] = useState(false);
  const [cityArray, setCityArray] = useState(null);

  const genderArray = [
    {
      value: "male",
      name: "Male",
    },
    {
      value: "female",
      name: "Female",
    },
  ];
  const onSelectTags = (item) => {
    setTags(item);
    setSelectedTags(item);
  };
  const onRemoveTags = (item) => {
    setTags(item);
    setSelectedTags(item);
  };

  const getCountry = () => {
    apiGet(`/countries/get-all`, onSuccessCountry, onFailureCountry);
  };
  const onSuccessCountry = (response) => {
    setCountryList(response?.data);
  };
  const onFailureCountry = (error) => {
    console.log(error);
  };
  // const getCity = () => {
  //   apiGet(`/countries/cityName/${selectedCountry}`, onSuccessCity, onFailureCity);
  //   // apiGet(
  //   //   `/countries/city/${countryId}`,
  //   //   onSuccessCity,
  //   //   onFailureCity
  //   // );
  // };

  const getCity = () => {
    const countryParam =
      selectedCountry[0].id === undefined
        ? selectedCountry[0]
        : selectedCountry[0].id;
    apiGet(`/countries/city/${countryParam}`, onSuccessCity, onFailureCity);
  };

  const onSuccessCity = (response) => {
    setCityArray(response.data);
  };
  const onFailureCity = (error) => {
    console.log(error);
  };

  const handleCountry = (val) => {
    var data = countryList.filter((filter) => val === filter.name);
    // const ids = data.map((country) => country.name);
    setSelectedCountry(data);
  };

  useEffect(() => {
    if (selectedCountry !== null) {
      getCity();
    }
  }, [selectedCountry]);

  const handleEmailValidation = (e) => {
    var Email = ValidateEmail(e);
    if (Email === "Invalid Email") {
      setEmailError("Invalid Email Address");
    } else {
      setEmailError("Valid Email Address");
    }
  };
  const addContacts = () => {
    setErrorEnable(true);
    setLoader(true);
    setContact({
      ...contact,
      contactTags: tags,
    });
    if (
      contact.firstName !== "" &&
      contact.lastName !== "" &&
      contact.gender !== "" &&
      contact.mobileNumber !== "" &&
      contact.email !== "" &&
      contact.country !== "" &&
      contact.city !== "" &&
      contact.address !== ""
      // &&
      // tags.length !== 0
    ) {
      contact["contactTags"] = tags;
      apiPost(`/contact/create`, onSuccess, onFailure, contact);
    } else {
      setLoader(false);
    }
  };

  const onSuccess = (response) => {
    if (response.status === 0) {
      setApiCalled(true);
      setTimeout(() => {
        setLoader(false);
        toast.success("Contact added successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1000);
      setTimeout(() => {
        closeModal();
      }, 2000);
    }
  };
  const onFailure = (error) => {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoader(false);
  };
  const onSuccessEdit = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setLoader(false);
        toast.success("Contact updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        closeModal();
      }, 1000);

      setApiCalled(true);
    }
  };
  const onFailureEdit = (error) => {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoader(false);
  };

  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical contactModal">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>{formHeading}</span>
          </div>
          <div className="modalForm">
            <div className="formHeading">
              <span className="formErrors">
                Please fill in the mandatory fields to create the account
              </span>
            </div>
            <form action="" autoComplete="off">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="uk-grid uk-grid-small" uk-grid="">
                    <div className="uk-width-1-2">
                      <div className="formInputs">
                        <label htmlFor="f_name">
                          First name<span>*</span>
                        </label>
                        <input
                          type="text"
                          id="firstName"
                          placeholder="First name"
                          className="uk-input"
                          value={contact.firstName}
                          onChange={(e) => {
                            e.target.value.length <= 10 &&
                              setContact({
                                ...contact,
                                firstName: e.target.value,
                              });
                          }}
                          style={{ paddingRight: "50px" }}
                        />
                        <div className="txtCounter" style={{ top: "35px" }}>
                          <span>{contact?.firstName?.length}</span>
                          <span>/</span>
                          <span>10</span>
                        </div>
                      </div>
                      {$("#firstName").val() === "" && errorEnable && (
                        <div className="formErrors">First name is required</div>
                      )}
                    </div>
                    <div className="uk-width-1-2">
                      <div className="formInputs">
                        <label htmlFor="f_name">
                          last name<span>*</span>
                        </label>
                        <input
                          type="text"
                          id="lastName"
                          placeholder="Last name"
                          className="uk-input"
                          value={contact.lastName}
                          onChange={(e) => {
                            e.target.value.length <= 10 &&
                              setContact({
                                ...contact,
                                lastName: e.target.value,
                              });
                          }}
                        />
                        <div className="txtCounter" style={{ top: "35px" }}>
                          <span>{contact?.lastName?.length}</span>
                          <span>/</span>
                          <span>10</span>
                        </div>
                      </div>
                      {$("#lastName").val() === "" && errorEnable && (
                        <div className="formErrors">Last name is required</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInputs">
                    <label htmlFor="agent">
                      Gender<span>*</span>
                    </label>
                    <select
                      name="gender"
                      id="gender"
                      className="uk-select"
                      onChange={(e) => {
                        setContact({
                          ...contact,
                          gender: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected>
                        Select a gender
                      </option>
                      {genderArray.map((val, index) => {
                        return (
                          <option key={index} value={val.value}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {$("#gender").val() === "" && errorEnable && (
                    <div className="formErrors">gender is required</div>
                  )}
                </div>
                <div className="uk-width-1-2">
                  <div className="formInputs">
                    <label htmlFor="f_name">
                      Mobile number<span>*</span>
                    </label>
                    <input
                      type="number"
                      id="mobileNumber"
                      placeholder="Mobile number"
                      className="uk-input"
                      value={contact.mobileNumber}
                      onChange={(e) => {
                        e.target.value.length <= 20 &&
                          setContact({
                            ...contact,
                            mobileNumber: e.target.value,
                          });
                      }}
                    />
                    <div className="txtCounter" style={{ top: "35px" }}>
                      <span>{contact?.mobileNumber?.length}</span>
                      <span>/</span>
                      <span>20</span>
                    </div>
                  </div>
                  {$("#mobileNumber").val() === "" && errorEnable && (
                    <div className="formErrors">Mobile number is required</div>
                  )}
                </div>
                <div className="uk-width-1-2">
                  <div className="formInputs">
                    <label htmlFor="f_name">
                      Email<span>*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      placeholder="Email"
                      className="uk-input"
                      onChange={(e) => {
                        setContact({
                          ...contact,
                          email: e.target.value,
                        });
                        handleEmailValidation(e.target.value);
                      }}
                    />
                  </div>
                  {$("#email").val() === "" && errorEnable && (
                    <div className="formErrors">Email is required</div>
                  )}
                  {contact.email !== "" &&
                    emailError !== "Valid Email Address" && (
                      <div className="formErrors">Invalid Email Address</div>
                    )}
                </div>
                <div className="uk-width-1-2">
                  <div className="formInputs">
                    <label htmlFor="agent">
                      Country<span>*</span>
                    </label>
                    <select
                      name="country"
                      id="country"
                      className="uk-select"
                      onClick={() => countryList.length == 0 && getCountry()}
                      onChange={(e) => {
                        handleCountry(e.target.value);
                        setContact({
                          ...contact,
                          country: e.target.value,
                        });
                      }}
                    >
                      {countryList.length === 0 ? (
                        <>
                          <option
                            value=""
                            selected
                            disabled
                            placeholder="Please select country"
                          >
                            Select a country
                          </option>
                          <option value="">loading ...</option>
                        </>
                      ) : (
                        <option
                          value=""
                          selected
                          disabled
                          placeholder="Please select country"
                          hidden
                        >
                          {" "}
                          Select a country
                        </option>
                      )}
                      {countryList?.map((val, index) => {
                        return (
                          <option key={index} value={val.name}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {contact.country === "" && errorEnable && (
                    <div className="formErrors">Country is required</div>
                  )}
                </div>

                <div className="uk-width-1-2">
                  <div className="formInputs">
                    <label htmlFor="agent">
                      City<span>*</span>
                    </label>

                    <select
                      name="city"
                      id="city"
                      className="uk-select"
                      onChange={(e) => {
                        setContact({
                          ...contact,
                          city: e.target.value,
                        });
                      }}
                      disabled={contact.country === "" && true}
                    >
                      {cityArray === null ? (
                        <>
                          <option
                            value=""
                            selected
                            placeholder="Please select city"
                          >
                            Select a city
                          </option>
                          <option>loading ...</option>
                        </>
                      ) : (
                        <>
                          <option value="" selected hidden >
                            Select a city
                          </option>
                          {cityArray.length > 0 ? (
                            cityArray?.map((val, index) => {
                              return (
                                <option
                                  key={index}
                                  value={val?.name}
                                  selected={
                                    contact?.city?.toLowerCase() ===
                                    val?.name?.toLowerCase()
                                  }
                                >
                                  {val?.name}
                                </option>
                              );
                            })
                          ) : (
                            <option value="" disabled>
                              No data found
                            </option>
                          )}
                        </>
                      )}
                    </select>
                  </div>
                  {contact.city === "" && errorEnable && (
                    <div className="formErrors">City is required</div>
                  )}
                </div>
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <label htmlFor="f_name">
                      Address<span>*</span>
                    </label>
                    <input
                      id="address"
                      type="text"
                      placeholder="Enter address..."
                      className="uk-input"
                      style={{ width: "98%" }}
                      onChange={(e) => {
                        setContact({
                          ...contact,
                          address: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {$("#address").val() === "" && errorEnable && (
                    <div className="formErrors">Address is required</div>
                  )}
                </div>
                <div className="uk-width-1-1">
                  <div className="mulitBoxHeading">
                    <span>Tags</span>
                  </div>
                  <div className="customChipBox">
                    <Multiselect
                      selectedValues={selectedTags}
                      options={multiSelectTags}
                      onSelect={onSelectTags}
                      onRemove={onRemoveTags}
                      displayValue="name"
                    />
                  </div>
                  {/* {tags.length === 0 && errorEnable && (
                    <div className="formErrors">tags is required</div>
                  )} */}
                </div>
                {/* <div className="uk-width-1-1">
                  <div className="mulitBoxHeading">
                    <span>Custom user field</span>
                  </div>
                  <div className="customChipBox">
                    <Multiselect
                      id="saj"
                      options={multiSelectTags}
                      // selectedValues={this.state.tags}
                      // onSelect={this.onSelectTags}
                      // onRemove={this.onRemoveTags}
                      displayValue="name"
                    />
                  </div>
                </div> */}
                <div className="uk-width-1-1">
                  <div className="btnwrp">
                    <button
                      type="button"
                      className="btn-1"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn-2 w-130"
                      onClick={addContacts}
                    >
                      {btnText === "Add Contact" &&
                        (loader ? (
                          <div uk-spinner="" className="loader"></div>
                        ) : (
                          "Add Contact"
                        ))}
                      {btnText === "Edit Contact" &&
                        (loader ? (
                          <div uk-spinner="" className="loader"></div>
                        ) : (
                          "Edit Contact"
                        ))}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddContact;
