import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import {
  Calendar,
  Chat,
  ChevronDown,
  Download,
  Forum,
} from "@carbon/icons-react";
import moment from "moment/moment";
import UIkit from "uikit";
import DoughnutChart from "../../components/Graph/DoughnutChart";
import CalendarDate from "../../components/Calendar/CalendarDate";
import LineChart from "../../components/Graph/LineChart";
import { apiGet } from "../../Utils/apiServices";
import axios from "axios";
import { useAuth } from "../../Context/AuthProvider";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";
import { generateColors } from "../../Utils/GraphFunctions";
import DynamicLineChart from "../../components/Graph/DynamicLineChart";
import { channelImages } from "../../Utils/ChannelImages";
import Pagination from "../../components/Pagination/Pagination";
import { hasPermissions } from "../../Utils/RoutePermissions";
const baseUrl = process.env.REACT_APP_BASEURL;

const ChatbotAnalytics = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const CurrentCompanyName = CompanyName(CompanyHtml);
  const { auth } = useAuth();
  const [isSelected, setIsSelected] = useState(false);
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [chatFilterStats, setChatFilterStats] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState("This month");
  const [isApiCall, setIsApiCall] = useState(false);
  // const [channel, setChannel] = useState("ALL");
  // const [chatbotStats, setChatbotStats] = useState(null);
  const [chatbotSessionAnalytics, setChatbotSessionAnalytics] = useState(null);
  // const [chatbotSessionGraph, setChatbotSessionGraph] = useState({
  //   label: [],
  //   data: [],
  // });
  const [chatbotSessionGraph, setChatbotSessionGraph] = useState();
  const pageTabs = [
    {
      route: "/analytics",
      name: "Overview",
      permissions: ["FINANCE-DATA-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/live-chat-analytics",
      name: "Live Chat",
      permissions: ["ANALYTICS-MANAGER", "LIVE-CHAT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/chatbot-analytics",
      name: "Chatbot",
      permissions: ["CHATBOT-SUPERVISOR", "CHAT-BOT-MANAGER"],
      active: true,
      allPermissionsMandatory: true,
    },
    {
      route: "/statistics",
      name: "Statistics",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/package-mau",
      name: "Package",
      permissions: ["ANALYTICS-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/orders",
      name: "Orders",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/transfer-logs",
      name: "Transfer Logs",
      permissions: [],
      active: false,
      allPermissionsMandatory: true,
    },
  ];
  const countNonEmptyArrays = (obj) => {
    let count = 0;
    for (const key in obj) {
      if (obj[key].length > 0) {
        count++;
      }
    }
    return count;
  };
  const colorForGraph =
    chatbotSessionGraph !== undefined &&
    generateColors(countNonEmptyArrays(chatbotSessionGraph.data));
  const colorForGraphLabels =
    chatbotSessionGraph !== undefined &&
    generateColors(Object.keys(chatbotSessionGraph?.data).length);
  const navigate = useNavigate();

  const clearAllStats = () => {
    // setCurrentPage(0);fix
    setChatbotSessionAnalytics(null);
    setChatbotSessionGraph(undefined);
    setChatFilterStats(null);
  };
  const filterDropdown = [
    // "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];

  const handleDatePickerClose = () => {
    if (selectedDateFilter === "Custom range") {
      setIsSelected(true);
    }
    UIkit.drop("#datepickerdrop").hide(true);
  };

  const exportData = async () => {
    try {
      const response = await axios.get(
        baseUrl + `/bot/export/stats/filter/${STARTDATE}/${ENDDATE}`,
        {
          responseType: "arraybuffer", // Ensure the response is treated as binary data
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Chatbot.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  const onChange = (ranges) => {
    // setChatbotStats(null)
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
  };

  const handleDateFilter = (filter) => {
    setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    if (filter !== "Custom range") {
      setIsApiCall(true);
    }
  };

  useEffect(() => {
    if (isSelected === true) {
      setIsApiCall(true);
    }
  }, [isSelected]);

  const channels = [
    {
      icon: null,
      name: "All Channels",
      apiSendName: "ALL",
      disabled: false,
    },
    // {
    //   icon: whatsAppImg,
    //   name: "WhatsApp",
    //   apiSendName: "WHATSAPP",
    //   disabled: false,
    // },
    // {
    //   icon: messengerIcon,
    //   name: "Facebook",
    //   apiSendName: "FACEBOOK",
    //   disabled: true,
    // },
    // {
    //   icon: instaIcon,
    //   name: "Instagram",
    //   apiSendName: "INSTAGRAM",
    //   disabled: true,
    // },
  ];

  // const getChatbotStats = () => {
  //   apiGet(`/bot/stats`, onSuccessStats, onFailureStats);
  // };

  // const onSuccessStats = (response) => {
  //   setIsSelected(false);
  //   setIsApiCall(false);
  //   setChatbotStats(response?.data);
  // };

  // const onFailureStats = (error) => {
  //   console.log(error);
  // };

  // const getChatbotStatsFilter = () => {
  //   apiGet(
  //     `/bot/stats/filter/${STARTDATE}/${ENDDATE}`,
  //     onSuccessStatsFilter,
  //     onFailureStatsFilter
  //   );
  // };

  // const onSuccessStatsFilter = (response) => {
  //   setIsApiCall(false);
  //   setChatbotStats(response?.data);
  // };

  // const onFailureStatsFilter = (error) => {
  //   console.log(error);
  // };

  const getChatbotStatsFilter = () => {
    apiGet(
      `/bot/stats/filter/${STARTDATE}/${ENDDATE}`,
      onSuccessStatsFilter,
      onFailureStatsFilter
    );
  };

  const onSuccessStatsFilter = (response) => {
    // setIsApiCall(false);
    setCurrentPage(1);
    setChatFilterStats(response?.data);
  };

  const onFailureStatsFilter = (error) => {
    console.log(error);
  };
  const getChatbotSessionAnalytics = () => {
    apiGet(
      `/detail-analytics/chatBotSessionAnalytics/${STARTDATE}/${ENDDATE}`,
      onSuccessChatbotSessionAnalytics,
      onFailureChatbotSessionAnalytics
    );
  };

  const onSuccessChatbotSessionAnalytics = (response) => {
    setIsApiCall(false);
    setIsSelected(false);

    // console.log(response);

    let result = {};
    let data = response.data?.chatBotGraphs;
    // const result = {};
    // for (const key in data) {
    //   if (response.data.hasOwnProperty(key)) {
    //     result[key] = data[key].map((item) => item.totalConversation);
    //   }
    //   if (response.data.hasOwnProperty(key)) {
    //     result["label"] = data[key].map((item) =>
    //       convertTimestampToDate(item.time)
    //     );
    //   }
    // }

    const conversations = data.reduce((acc, bot) => {
      acc[bot.botName] = bot.graphPoints.map(
        (point) => point.totalBotConversation
      );
      return acc;
    }, {});
    // console.log(conversations);

    const allDates = data
      .flatMap((bot) => bot.graphPoints.map((point) => point.date))
      .filter((date, index, self) => self.indexOf(date) === index)
      .sort((a, b) => new Date(a) - new Date(b));

    // console.log(allDates);
    result["data"] = conversations;
    result["label"] = allDates;
    // console.log(result);

    // console.log(countNonEmptyArrays(conversations))

    setChatbotSessionAnalytics(response?.data);
    setChatbotSessionGraph(result);
  };

  const onFailureChatbotSessionAnalytics = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getChatbotSessionAnalytics();
    getChatbotStatsFilter();
  }, []);

  useEffect(() => {
    if (isSelected === true) {
      setIsApiCall(true);
    }
  }, [isSelected]);
  useEffect(() => {
    if (isApiCall === false) {
      setIsSelected(false);
    }
  }, [isApiCall]);

  useEffect(() => {
    if (isApiCall == true) {
      if (selectedDateFilter === "Custom range") {
        if (isSelected === true) {
          // setChatbotStats(null);
          getChatbotSessionAnalytics();
          getChatbotStatsFilter();
        }
      } else {
        // setChatbotStats(null);
        getChatbotSessionAnalytics();
        getChatbotStatsFilter();
      }
    }
  }, [isApiCall]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return chatFilterStats?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="boradcastWrp">
        <div className="boradcastTabbing">
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #newAnalyticsTab"
          >
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                <li
                  onClick={() => {
                    navigate(val.route, { replace: true });
                  }}
                  className={val.active === true ? "uk-active" : ""}
                  key={index}
                >
                  <a>{val.name}</a>
                </li>
              ) : null;
            })}
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="newAnalyticsTab">
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                val.name === "Chatbot" ? (
                  <li key={index}>
                    <div className="overviewContent">
                      <div className="uk-container">
                        <div className="uk-grid uk-flex-middle" uk-grid="">
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsTabWrp"
                              style={{ margin: "0 0 20px" }}
                            >
                              <div className="whatappTabBtn">
                                <ul
                                  className="uk-subnav uk-subnav-pill"
                                  uk-switcher="connect: .whatsappTabBtn"
                                >
                                  <li
                                    onClick={() => {
                                      navigate("/chatbot-analytics", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Overview</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/chatbot-session-analytics", {
                                        replace: true,
                                      });
                                    }}
                                    className="uk-active"
                                  >
                                    <a href="#">Session analytics</a>
                                  </li>
                                  {/* <li>
                              <a href="#">Conversational analytics</a>
                            </li> */}
                                  <li
                                    onClick={() => {
                                      navigate("/chatbot-user-analytics", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>User analytics</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsWhatsappContent"
                              style={{ marginTop: "16px" }}
                            >
                              <ul className="uk-switcher uk-margin whatsappTabBtn">
                                <li></li>
                                <li>
                                  <div
                                    className="overviewContent uk-padding-remove"
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <div className="uk-container">
                                      <div className="overviewTopDropdown">
                                        <div
                                          className="uk-grid uk-flex-middle"
                                          uk-grid=""
                                        >
                                          <div className="uk-width-1-2">
                                            <div className="pageHeading uk-margin-remove-bottom">
                                              <h3>
                                                Chat Bot Session Analytics
                                              </h3>
                                            </div>
                                          </div>
                                          <div className="uk-width-1-2 btnSection">
                                            <div className="agentFilterWrp">
                                              {/* <div className="allChannelDropDown">
                                            <div className="uk-inline tableFilterDropdown broadcastChannelFilter">
                                              <button
                                                type="button"
                                                className="dropdownBtn"
                                              >
                                                All Active Channels{" "}
                                                <ChevronDown />
                                              </button>
                                              <div
                                                uk-dropdown="mode: click"
                                                className="dropdownNav"
                                              >
                                                <span className="dropdownHeading">
                                                  Channels
                                                </span>
                                                <ul>
                                                  {channels.map(
                                                    (val, index) => {
                                                      return (
                                                        <li
                                                          key={index}
                                                          className={
                                                            channel ===
                                                            val.apiSendName
                                                              ? "activeBtn"
                                                              : ""
                                                          }
                                                          onClick={() => {
                                                            setChannel(
                                                              val.apiSendName
                                                            );
                                                          }}
                                                        >
                                                          <button
                                                            type="button"
                                                            disabled={
                                                              val.disabled ===
                                                              true
                                                                ? true
                                                                : false
                                                            }
                                                            className={
                                                              val.disabled ===
                                                              true
                                                                ? "opactityBtn"
                                                                : ""
                                                            }
                                                          >
                                                            {index !== 0 && (
                                                              <img
                                                                src={val.icon}
                                                                alt=""
                                                              />
                                                            )}
                                                            {val.name}
                                                          </button>
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                                </ul>
                                              </div>
                                            </div>
                                          </div> */}
                                              <div className="uk-inline">
                                                <button className="calenderBtn rangeBtn">
                                                  <Calendar />{" "}
                                                  {selectedDateFilter}
                                                  <span className="uk-inline">
                                                    <ChevronDown />
                                                  </span>
                                                </button>
                                                <div
                                                  uk-dropdown="mode: click"
                                                  className="calendarDropdown"
                                                  id="dateDropdownSection"
                                                >
                                                  <div className="dropDownHeading">
                                                    <span>Select a range</span>
                                                  </div>
                                                  <ul>
                                                    {filterDropdown.map(
                                                      (val, index) => {
                                                        return (
                                                          <>
                                                            <li
                                                              className={
                                                                selectedDateFilter ===
                                                                val
                                                                  ? "activeBtn"
                                                                  : ""
                                                              }
                                                              key={index}
                                                              onClick={() => {
                                                                setCurrentPage(0)
                                                                if (
                                                                  val !==
                                                                  "Custom range"
                                                                ) {
                                                                  clearAllStats();
                                                                }
                                                                handleDateFilter(
                                                                  val
                                                                );
                                                              }}
                                                            >
                                                              {filterDropdown.length -
                                                                1 ===
                                                              index ? (
                                                                <div className="uk-inline uk-text-left">
                                                                  <button
                                                                    className="rangeBtn"
                                                                    type="button"
                                                                  >
                                                                    <span className="rangeBtnCircle"></span>{" "}
                                                                    Custom range
                                                                  </button>
                                                                  <div
                                                                    id="datepickerdrop"
                                                                    uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                                    className="date-picker-container"
                                                                  >
                                                                    <CalendarDate
                                                                      onChange={
                                                                        onChange
                                                                      }
                                                                    />
                                                                    <div className="date-picker-btn-container uk-text-right">
                                                                      <button
                                                                        className="date-selection-btn"
                                                                        onClick={
                                                                          handleDatePickerClose
                                                                        }
                                                                      >
                                                                        Ok
                                                                      </button>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                <button
                                                                  type="button"
                                                                  className="rangeBtn"
                                                                >
                                                                  <span className="rangeBtnCircle"></span>{" "}
                                                                  {val}
                                                                </button>
                                                              )}
                                                            </li>
                                                            {filterDropdown.length -
                                                              2 ===
                                                              index && (
                                                              <hr
                                                                style={{
                                                                  margin:
                                                                    "5px 0",
                                                                }}
                                                              />
                                                            )}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="uk-width-1-1 uk-margin-remove">
                                            <div className="overviewMainContent">
                                              <div className="mainBox">
                                                <div className="boxHeading">
                                                  <span>Session summary</span>
                                                </div>
                                                <div className="boxContent">
                                                  <div
                                                    className="uk-grid uk-flex-middle"
                                                    uk-grid=""
                                                  >
                                                    <div className="uk-width-2-5">
                                                      <div className="boxTimerWrp">
                                                        <p>
                                                          <Chat /> Total Chats
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-3-5">
                                                      <div
                                                        className="uk-grid"
                                                        uk-grid=""
                                                      >
                                                        <div className="uk-width-1-2">
                                                          <div className="txtwrp timeBoxTxt">
                                                            <h2>
                                                              {chatbotSessionAnalytics ===
                                                              null ? (
                                                                <span uk-spinner=""></span>
                                                              ) : (
                                                                chatbotSessionAnalytics?.totalChats.toFixed(
                                                                  0
                                                                )
                                                              )}
                                                            </h2>
                                                            <span>
                                                              Total bot chats
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <div className="uk-width-1-2">
                                                          <div className="txtwrp timeBoxTxt">
                                                            <h2>
                                                              {chatbotSessionAnalytics ===
                                                              null ? (
                                                                <span uk-spinner=""></span>
                                                              ) : (
                                                                chatbotSessionAnalytics?.avgChats.toFixed(
                                                                  0
                                                                )
                                                              )}
                                                            </h2>
                                                            <span>
                                                              Avg. Bot chats per
                                                              user
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <hr />
                                                  <div
                                                    className="uk-grid uk-flex-middle"
                                                    uk-grid=""
                                                  >
                                                    <div className="uk-width-2-5">
                                                      <div className="boxTimerWrp">
                                                        <p>
                                                          <Forum /> Engaged
                                                          users
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-3-5">
                                                      <div
                                                        className="uk-grid"
                                                        uk-grid=""
                                                      >
                                                        <div className="uk-width-1-2">
                                                          <div className="txtwrp timeBoxTxt">
                                                            <h2>
                                                              {chatbotSessionAnalytics ===
                                                              null ? (
                                                                <span uk-spinner=""></span>
                                                              ) : (
                                                                chatbotSessionAnalytics?.engageUser.toFixed(
                                                                  0
                                                                )
                                                              )}
                                                            </h2>
                                                            <span>
                                                              Total engaged
                                                              Users
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <div className="uk-width-1-2">
                                                          <div className="txtwrp timeBoxTxt">
                                                            <h2>
                                                              {chatbotSessionAnalytics ===
                                                              null ? (
                                                                <span uk-spinner=""></span>
                                                              ) : (
                                                                chatbotSessionAnalytics?.avgEngageUser.toFixed(
                                                                  0
                                                                )
                                                              )}
                                                            </h2>
                                                            <span>
                                                              Avg. engaged bot
                                                              chats per user
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="halfDonutSec halfDonutSize">
                                                <div
                                                  className="uk-grid"
                                                  uk-grid=""
                                                  uk-height-match="target: > div > .halfDonutHeight"
                                                >
                                                  <div className="uk-width-1-2">
                                                    <div className="mainBox halfDonutHeight">
                                                      <div className="boxHeading">
                                                        <span>
                                                          Total messages
                                                        </span>
                                                      </div>
                                                      <div className="boxContent">
                                                        <div
                                                          className="chartWrp"
                                                          style={{
                                                            minHeight: "260px",
                                                            minWidth: "260px",
                                                          }}
                                                        >
                                                          {chatbotSessionAnalytics !==
                                                            null &&
                                                            (chatbotSessionAnalytics?.totalChats >
                                                            0 ? (
                                                              <DoughnutChart
                                                                degree={180}
                                                                backgroudColor={[
                                                                  "#EDC843",
                                                                  "#1CE783",
                                                                ]}
                                                                borderColor={[
                                                                  "#EDC843",
                                                                  "#1CE783",
                                                                ]}
                                                                graphData={[
                                                                  chatbotSessionAnalytics?.newUsers,
                                                                  chatbotSessionAnalytics?.returningUsers,
                                                                ]}
                                                                graphlabels={[
                                                                  "New users",
                                                                  "Returning users",
                                                                ]}
                                                              />
                                                            ) : (
                                                              <DoughnutChart
                                                                degree={180}
                                                                backgroudColor={[
                                                                  "#B4B4B4",
                                                                ]}
                                                                borderColor={[
                                                                  "#B4B4B4",
                                                                ]}
                                                                graphData={[1]}
                                                                graphlabels={[
                                                                  "-",
                                                                ]}
                                                              />
                                                            ))}

                                                          <div
                                                            className="chartData"
                                                            style={{
                                                              top: "50%",
                                                            }}
                                                          >
                                                            <h3>
                                                              {chatbotSessionAnalytics?.totalChats ===
                                                              undefined
                                                                ? 0
                                                                : isNaN(
                                                                    chatbotSessionAnalytics?.totalChats
                                                                  )
                                                                ? 0
                                                                : chatbotSessionAnalytics?.totalChats}
                                                            </h3>
                                                            <p>
                                                              Exchanged messages
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div className="chartDataWrapper">
                                                          <ul>
                                                            <li>
                                                              <p>
                                                                <span className="yellowDot"></span>
                                                                New users
                                                              </p>
                                                            </li>
                                                            <li>
                                                              <p>
                                                                {
                                                                  chatbotSessionAnalytics?.newUsers
                                                                }
                                                              </p>
                                                            </li>
                                                          </ul>
                                                          <ul>
                                                            <li>
                                                              <p>
                                                                <span className="lightGreenDot"></span>
                                                                Returning users
                                                              </p>
                                                            </li>
                                                            <li>
                                                              <p>
                                                                {
                                                                  chatbotSessionAnalytics?.returningUsers
                                                                }
                                                              </p>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="uk-width-1-2">
                                                    <div className="mainBox halfDonutHeight">
                                                      <div className="boxHeading">
                                                        <span>
                                                          Avg. messages per
                                                          session
                                                        </span>
                                                      </div>
                                                      <div className="boxContent">
                                                        <div
                                                          className="chartWrp"
                                                          style={{
                                                            minHeight: "260px",
                                                            minWidth: "260px",
                                                          }}
                                                        >
                                                          {chatbotSessionAnalytics !==
                                                            null &&
                                                            (chatbotSessionAnalytics?.totalChats >
                                                            0 ? (
                                                              <DoughnutChart
                                                                degree={180}
                                                                backgroudColor={[
                                                                  "#1EB954",
                                                                  "#2F5711",
                                                                ]}
                                                                borderColor={[
                                                                  "#1EB954",
                                                                  "#2F5711",
                                                                ]}
                                                                graphData={[
                                                                  chatbotSessionAnalytics?.inboundMessage,
                                                                  chatbotSessionAnalytics?.outBoundMessage,
                                                                ]}
                                                                graphlabels={[
                                                                  "Active bots",
                                                                  "Inactive bots",
                                                                ]}
                                                              />
                                                            ) : (
                                                              <DoughnutChart
                                                                degree={180}
                                                                backgroudColor={[
                                                                  "#B4B4B4",
                                                                ]}
                                                                borderColor={[
                                                                  "#B4B4B4",
                                                                ]}
                                                                graphData={[1]}
                                                                graphlabels={[
                                                                  "-",
                                                                ]}
                                                              />
                                                            ))}
                                                          <div
                                                            className="chartData"
                                                            style={{
                                                              top: "50%",
                                                            }}
                                                          >
                                                            <h3>
                                                              {chatbotSessionAnalytics?.totalChats !==
                                                              0
                                                                ? isNaN(
                                                                    (chatbotSessionAnalytics?.inboundMessage +
                                                                      chatbotSessionAnalytics?.outBoundMessage) /
                                                                      chatbotSessionAnalytics?.totalChats
                                                                  )
                                                                  ? 0
                                                                  : (
                                                                      (chatbotSessionAnalytics?.inboundMessage +
                                                                        chatbotSessionAnalytics?.outBoundMessage) /
                                                                      chatbotSessionAnalytics?.totalChats
                                                                    ).toFixed(2)
                                                                : "0"}
                                                            </h3>
                                                            <p>
                                                              Avg. messages per
                                                              session
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div className="chartDataWrapper">
                                                          <ul>
                                                            <li>
                                                              <p>
                                                                <span className="lightGreen"></span>
                                                                Inbound
                                                              </p>
                                                            </li>
                                                            <li>
                                                              <p>
                                                                {
                                                                  chatbotSessionAnalytics?.inboundMessage
                                                                }
                                                              </p>
                                                            </li>
                                                          </ul>
                                                          <ul>
                                                            <li>
                                                              <p>
                                                                <span className="darkGreen"></span>
                                                                Outbound
                                                              </p>
                                                            </li>
                                                            <li>
                                                              <p>
                                                                {
                                                                  chatbotSessionAnalytics?.outBoundMessage
                                                                }
                                                              </p>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="mainBox">
                                                <div className="boxHeading">
                                                  <span>Total bot chats</span>
                                                </div>
                                                <div className="boxContent">
                                                  <div
                                                    className="uk-grid uk-flex-middle"
                                                    uk-grid=""
                                                  >
                                                    <div className="uk-width-3-5">
                                                      <div className="overviewChart">
                                                        {/* chatbotSessionGraph */}
                                                        {/* <LineChart
                                                      
                                                      
                                                    /> */}

                                                        {chatbotSessionGraph !==
                                                          undefined && (
                                                          <DynamicLineChart
                                                            datasets={Object.keys(
                                                              chatbotSessionGraph.data
                                                            ).map(
                                                              (key) =>
                                                                chatbotSessionGraph
                                                                  .data[key]
                                                            )}
                                                            graphLabels={
                                                              chatbotSessionGraph?.label
                                                            }
                                                            colors={
                                                              colorForGraph
                                                            }
                                                            labelsForHover={Object.keys(
                                                              chatbotSessionGraph.data
                                                            )}
                                                          />
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-2-5">
                                                      <div className="chartRecordData">
                                                        <div className="chartRecordDataWrp">
                                                          <div className="deliveryRate">
                                                            <div
                                                              className="detailHeading"
                                                              style={{
                                                                marginBottom:
                                                                  "24px",
                                                              }}
                                                            >
                                                              <p>CHATBOT</p>
                                                            </div>
                                                            <ul>
                                                              {chatbotSessionGraph !==
                                                                undefined &&
                                                                Object.keys(
                                                                  chatbotSessionGraph.data
                                                                )?.map(
                                                                  (
                                                                    val,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <li
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        <p>
                                                                          <span
                                                                            style={{
                                                                              backgroundColor:
                                                                                colorForGraphLabels[
                                                                                  index
                                                                                ],
                                                                            }}
                                                                          ></span>
                                                                          {val}
                                                                        </p>
                                                                      </li>
                                                                    );
                                                                  }
                                                                )}

                                                              {/* <li>
                                                            <p>
                                                              <span className="darkGreenDot"></span>
                                                              All Chatbots
                                                            </p>
                                                          </li> */}
                                                              {/* <li>
                                                            <p>
                                                              <span className="greenDot"></span>
                                                              Facebook Chatbots
                                                              1
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="yellowDot"></span>
                                                              Chatbots 2
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <p>
                                                              <span className="redDot"></span>
                                                              Chatbots 3
                                                            </p>
                                                          </li> */}
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              {/* <div className="halfDonutSec">
                                            <div
                                              className="uk-grid"
                                              uk-grid=""
                                              uk-height-match="target: > div > .halfDonutHeight"
                                            >
                                              <div className="uk-width-1-2">
                                                <div className="mainBox halfDonutHeight">
                                                  <div className="boxHeading">
                                                    <span>
                                                      Session expiry reason
                                                    </span>
                                                  </div>
                                                  <div className="boxContent">
                                                    <div
                                                      className="chartWrp"
                                                      style={{
                                                        width: "200px",
                                                        height: "200px",
                                                        margin: "0 auto",
                                                      }}
                                                    >
                                                      <DoughnutChart
                                                        degree={360}
                                                        backgroudColor={[
                                                          "#2F5711",
                                                          "#1ce783",
                                                          "#EDC843",
                                                        ]}
                                                        borderColor={[
                                                          "#2F5711",
                                                          "#1ce783",
                                                          "#EDC843",
                                                        ]}
                                                        graphData={[22, 33, 15]}
                                                      />
                                                      <div
                                                        className="chartData"
                                                        style={{
                                                          top: "36%",
                                                          left: "10px",
                                                        }}
                                                      >
                                                        <h3>628</h3>
                                                        <p>Sessions</p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="chartDataWrapper"
                                                      style={{
                                                        marginTop: "40px",
                                                      }}
                                                    >
                                                      <ul
                                                        style={{
                                                          width: "252px",
                                                        }}
                                                      >
                                                        <li>
                                                          <p>
                                                            <span className="darkGreen"></span>
                                                            Engaged sessions
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>58.65%</p>
                                                        </li>
                                                      </ul>
                                                      <ul
                                                        style={{
                                                          width: "252px",
                                                        }}
                                                      >
                                                        <li>
                                                          <p>
                                                            <span className="yellowDot"></span>
                                                            Agent takeover
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>58.65%</p>
                                                        </li>
                                                      </ul>
                                                      <ul
                                                        style={{
                                                          width: "252px",
                                                        }}
                                                      >
                                                        <li>
                                                          <p>
                                                            <span className="lightGreenDot"></span>
                                                            Go to Agent
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>58.65%</p>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="uk-width-1-2">
                                                <div className="mainBox halfDonutHeight">
                                                  <div className="boxHeading">
                                                    <span>
                                                      Session duration
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="boxContent"
                                                    style={{
                                                      padding: "0 30px",
                                                      marginTop: "120px",
                                                    }}
                                                  >
                                                    <div
                                                      className="uk-grid uk-flex-middle"
                                                      uk-grid=""
                                                    >
                                                      <div className="uk-width-1-2">
                                                        <div className="chartTimer">
                                                          <h3>00:00:57</h3>
                                                          <p>
                                                            Avg. session
                                                            duration
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="uk-width-1-2">
                                                        <div className="chartDataWrapper chartBorderWrp">
                                                          <ul
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <li>
                                                              <p>
                                                                <span className="darkGreen"></span>
                                                                Less than 30s
                                                              </p>
                                                            </li>
                                                            <li>
                                                              <p>58.65%</p>
                                                            </li>
                                                          </ul>
                                                          <ul
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <li>
                                                              <p>
                                                                <span className="yellowDot"></span>
                                                                Less than 60s
                                                              </p>
                                                            </li>
                                                            <li>
                                                              <p>12.78%</p>
                                                            </li>
                                                          </ul>
                                                          <ul
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <li>
                                                              <p>
                                                                <span className="lightGreenDot"></span>
                                                                Less than 90s
                                                              </p>
                                                            </li>
                                                            <li>
                                                              <p>0%</p>
                                                            </li>
                                                          </ul>
                                                          <ul
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <li>
                                                              <p>
                                                                <span className="redDot"></span>
                                                                Less than 120s
                                                              </p>
                                                            </li>
                                                            <li>
                                                              <p>0%</p>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div> */}

                                              {/* <div className="mainBox">
                                            <div className="boxHeading">
                                              <span>Dialogs</span>
                                            </div>
                                            <div className="boxFullTable tableCenter">
                                              <table className="uk-table uk-table-striped">
                                                <thead>
                                                  <tr>
                                                    <th className="uk-table-expand">
                                                      DIALOG
                                                    </th>
                                                    <th>TRIGGERED</th>
                                                    <th>USERS</th>
                                                    <th>Sessions</th>
                                                    <th>COMPLETED</th>
                                                    <th>EXPIRED SESSIONS</th>
                                                    <th>Agent takes over</th>
                                                    <th>Go to agent</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {chatbotSessionAnalytics
                                                    ?.chatBotGraphs.length >
                                                  0 ? (
                                                    chatbotSessionAnalytics?.chatBotGraphs.map(
                                                      (val, index) => {
                                                        return (
                                                          <tr key={index}>
                                                            <td className="channelName">
                                                              Default
                                                            </td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>
                                                              <b>100.0%</b>
                                                            </td>
                                                            <td>4</td>
                                                            <td>4</td>
                                                            <td>0</td>
                                                          </tr>
                                                        );
                                                      }
                                                    )
                                                  ) : (
                                                    <tr>
                                                      <td
                                                        colSpan={12}
                                                        className="dataNotFound"
                                                      >
                                                        {chatbotSessionAnalytics ===
                                                        null ? (
                                                          <div uk-spinner=""></div>
                                                        ) : (
                                                          "Data Not Found"
                                                        )}
                                                      </td>
                                                    </tr>
                                                  )}
                                                  <tr>
                                                    <td className="channelName">
                                                      Default
                                                    </td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>
                                                      <b>100.0%</b>
                                                    </td>
                                                    <td>4</td>
                                                    <td>4</td>
                                                    <td>0</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div> */}

                                              <div className="mainBox">
                                                <div className="boxHeading">
                                                  <span>Dialogs</span>
                                                </div>
                                                <div className="boxFullTable tableCenter">
                                                  <table className="uk-table uk-table-striped">
                                                    <thead>
                                                      <tr>
                                                        <th className="uk-table-expand">
                                                          DIALOG
                                                        </th>
                                                        <th>Chatbot name</th>
                                                        <th>TOTAL CHATS</th>
                                                        <th>
                                                          TOTAL UNIQUE USERS
                                                        </th>

                                                        {/* <th>
                                                    CHATS ROUTED TO AGENTS
                                                  </th>
                                                  <th>AVG CHAT TIME</th> */}
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {chatFilterStats?.length >
                                                      0 ? (
                                                        currentTableData?.map(
                                                          (val, index) => {
                                                            // var window = dangerouslySetInnerHTML={{ __html: val.windowName }};
                                                            return (
                                                              <tr key={index}>
                                                                <td className="channelName">
                                                                  <Link
                                                                    to={`/chatbot-detail/${encodeURIComponent(
                                                                      val.windowName
                                                                    )}/${
                                                                      val.botId
                                                                    }/${STARTDATE}/${ENDDATE}`}
                                                                  >
                                                                    <img
                                                                      src={channelImages(
                                                                        val.channel
                                                                      )}
                                                                      alt=""
                                                                    />
                                                                    {
                                                                      val?.windowName
                                                                    }
                                                                  </Link>
                                                                </td>
                                                                <td>
                                                                  {
                                                                    val?.chatBotName
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {
                                                                    val?.totalChats
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {
                                                                    val?.totalUniqueChats
                                                                  }
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )
                                                      ) : (
                                                        <tr>
                                                          <td
                                                            colSpan={11}
                                                            className="dataNotFound"
                                                          >
                                                            {chatFilterStats ===
                                                            null ? (
                                                              <div uk-spinner=""></div>
                                                            ) : (
                                                              "Data Not Found"
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )}
                                                    </tbody>
                                                  </table>
                                                  {chatFilterStats?.length >
                                                    0 && (
                                                    <div
                                                      style={{
                                                        marginRight: "50px",
                                                      }}
                                                    >
                                                      <Pagination
                                                        className="pagination-bar"
                                                        currentPage={
                                                          currentPage
                                                        }
                                                        totalCount={
                                                          chatFilterStats.length
                                                        }
                                                        pageSize={PageSize}
                                                        onPageChange={(page) =>
                                                          setCurrentPage(page)
                                                        }
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li key={index}></li>
                )
              ) : null;
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ChatbotAnalytics;
