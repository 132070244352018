import React from "react";

const CustomModal = ({ closeModal, heading, fieldLabel, saveBtnTxt }) => {
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <h3>{heading}</h3>
        <div>
          <form className="uk-form-stacked">
            <div className="uk-margin">
              <label className="uk-form-label">{fieldLabel}</label>
              <input className="inputField" defaultValue={"ac@gmail.com"} />
            </div>
            <div className="btnWrap uk-margin-medium-top">
              <div>
                <button className="cnclBtn" onClick={closeModal}>
                  Cancel
                </button>
              </div>
              <div>
                <button className="saveBtn">{saveBtnTxt}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
