import InfiniteScroll from "react-infinite-scroll-component";
import { apiGet } from "../../../Utils/apiServices";
import { useEffect } from "react";
import { useAuth } from "../../../Context/AuthProvider";
import { useState } from "react";
import { convertTimestamp } from "../../../Utils/DateFunctions";
import { ChevronDown } from "@carbon/icons-react";
import {
  formatText,
  sanitizedMessage,
} from "../../../Utils/chat/chatFunctions";
import { decryptedValue } from "../../../Utils/RouteHashConversion";
import { Navigate, useNavigate } from "react-router-dom";
import $ from "jquery";
import UIkit from "uikit";
import { channelImages } from "../../../Utils/ChannelImages";
const ChatContacts = ({
  onClickCheckbox,
  getChatMessages,
  chatContactList,
  getAllChatContacts,
  setChatFilter,
  chatFilter,
  setCurrentPage,
  userPermissions,
  companyDetails,
  selectedChannel,
  setSelectedChannel,
  setChatContactList,
  hasMoreContacts,
  setHasMoreContacts,
  setActiveChatIndex,
  activeChatIndex,
  searchedKey,
  onSearch,
  setSearchedKey,
  setPageForChatMessages,
  setContactMessages,
  setIsChatStatusVisible,
  isChatStatusVisible,
  setCompanyWhatsappNumber,
  companyWhatsappNumber,
}) => {
  const { auth } = useAuth();
  const [agentId, setAgentId] = useState();
  const { navigate } = useNavigate();
  const [chatFilterDropdown, setChatFilterDropdown] = useState([
    "ALL",
    "ASSIGNED",
    "UNASSIGNED",
    "OPEN",
    "WAITING",
    "SOLVED",
    "CLOSED",
  ]);

  // const [activeChatIndex, setActiveChatIndex] = useState(null);
  const toggleActiveChat = (customerId) => {
    if (activeChatIndex !== customerId) {
      setActiveChatIndex(customerId); // Select a new chat
    }
  };

  const getUserDetails = () => {
    apiGet(
      `/agent/${decryptedValue(auth.agentId)}`,
      onSuccessUserDetails,
      onFailureUserDetails
    );
  };
  const onSuccessUserDetails = (response) => {
    setAgentId(response?.data.publicId);
  };
  const onFailureUserDetails = (error) => {
    navigate(`/`, {
      replace: true,
    });
    console.log(error);
  };
  // ********************************* api call end ******************************

  // *********************************useeffect block start ******************************
  useEffect(() => {
    getAllChatContacts(agentId, chatFilter);
  }, [chatFilter]);
  useEffect(() => {
    if (agentId !== undefined) {
      getAllChatContacts(agentId, chatFilter);
    }
  }, [agentId]);
  useEffect(() => {
    getUserDetails();
  }, []);

  const closeDropChannel = () => {
    UIkit.dropdown("#channelDrop").hide();
  };
  const closeDropFilters = () => {
    UIkit.dropdown("#filtersDrop").hide();
  };

  useEffect(() => {
    closeDropChannel();
    setActiveChatIndex(null);
  }, [selectedChannel]);

  useEffect(() => {
    closeDropFilters();
  }, [chatFilter]);
  useEffect(() => {
    if (userPermissions?.includes("LIVE-CHAT-MANAGER") === false) {
      let chatFilterList = ["ALL", "OPEN", "WAITING", "SOLVED", "CLOSED"];
      setChatFilterDropdown(chatFilterList);
    }
  }, [userPermissions]);

  // useEffect(() => {
  //   if (chatContactList !== undefined) {
  //     if (selectedChannel !== "ALL") {
  //       let filterContactChannelWise = chatContactList.filter((val) => {
  //         return val.chanelId === selectedChannel;
  //       });
  //       setChatContactList(filterContactChannelWise)
  //     }
  //   }
  // }, [chatContactList]);
  // *********************************useeffect block end ******************************

  return (
    <div className="uk-width-1-5 custom-class">
      {/* {chatContactList === undefined ? (
        <div className="loaderScreen">
          <div uk-spinner="" className="loader"></div>
        </div>
      ) : ( */}
      <div className="chat-sec">
        <ul>
          <li className="chat-sec-content">
            <form action="">
              <input
                type="checkbox"
                className="chatCheckbox"
                onClick={() => onClickCheckbox("all", chatContactList)}
              />
            </form>
            <button
              className="uk-button uk-button-default channel-drop-btn"
              type="button"
            >
              {selectedChannel === "ALL"
                ? "ALL (CHANNELS)"
                : selectedChannel === "FACEBOOK"
                ? "MESSENGER"
                : selectedChannel}{" "}
              <ChevronDown />
            </button>
            <div
              uk-dropdown="mode: click"
              className="allCahnnelsDropdown"
              id="channelDrop"
            >
              <div className="dropdownHeading">
                <span>Chanels</span>
              </div>
              <ul className="uk-nav uk-dropdown-nav channel-drop">
                <li
                  className={selectedChannel === "ALL" && "channelListActive"}
                >
                  <button
                    className={selectedChannel === "ALL" && "color-btn"}
                    onClick={() => {
                      setCurrentPage(0);
                      setHasMoreContacts(true);
                      setSelectedChannel("ALL");
                    }}
                  >
                    All (chanels)
                  </button>
                </li>

                {companyDetails?.activeChannels.map((data, index) => {
                  return (
                    <li
                      className={
                        selectedChannel === data.chanelId && "channelListActive"
                      }
                      key={index}
                    >
                      <button
                        className={
                          selectedChannel === data.chanelId
                            ? "selectedChannel active"
                            : "active"
                        }
                        onClick={() => {
                          setCurrentPage(0);
                          setHasMoreContacts(true);
                          setSelectedChannel(data.chanelId);
                          // here we can empty search bar beacuse of api call when channel is changed
                          $("#searchField").val("");
                          setSearchedKey("");
                        }}
                      >
                        <img src={channelImages(data.chanelId)} alt="" />
                        <div className="txtwrp">
                          <p>
                            {data.chanelId === "FACEBOOK"
                              ? "MESSENGER"
                              : data.chanelId}
                          </p>
                        </div>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <button
              className="uk-button uk-button-default channel-drop-btn"
              type="button"
            >
              {chatFilter} <ChevronDown />
            </button>
            <div
              uk-dropdown="mode: click"
              className="allCahnnelsDropdown"
              id="filtersDrop"
              style={{ top: "12%", left: "14%" }}
            >
              <div className="dropdownHeading">
                <span>Conversations</span>
              </div>
              <ul className="uk-nav uk-dropdown-nav channel-drop">
                {chatFilterDropdown?.map((val, index) => {
                  return (
                    <li
                      key={index}
                      className={chatFilter === val ? "channelListActive" : ""}
                      onClick={() => {
                        // setChatContactList(undefined)
                        setActiveChatIndex(null);
                        setContactMessages(null);
                        setCurrentPage(0);
                        setHasMoreContacts(true);
                        setIsChatStatusVisible(true);
                        setChatFilter(val);
                      }}
                    >
                      <button type="button" className="active">
                        {val}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </li>
        </ul>
        {chatContactList === undefined || isChatStatusVisible ? (
          <div className="loaderScreen">
            <div uk-spinner="" className="loader"></div>
          </div>
        ) : (
          <div className="chatContactsContainer" id="threadList">
            <ul>
              <InfiniteScroll
                style={{ overflow: "none" }}
                dataLength={chatContactList?.length}
                next={(e) =>
                  searchedKey === ""
                    ? getAllChatContacts(agentId, chatFilter)
                    : onSearch()
                }
                pullDownToRefreshThreshold={20}
                hasMore={hasMoreContacts}
                // hasMore={true}
                // scrollThreshold={0.9}
                scrollableTarget="threadList"
                // className="conversaionsList"
                // endMessage={<center id="record_not_found"></center>}
                // loader={<h4>Loading...</h4>}
              >
                {chatContactList?.map((data, index) => {
                  var matchedCompanyColor =
                    companyDetails.childCompanies !== null
                      ? companyDetails?.childCompanies?.filter((item) => {
                          return item.encodedId === data.companyPublicId;
                        })
                      : false;

                  return (
                    <li
                      className={`all-chats ${
                        activeChatIndex === data.id ? "activechat" : ""
                      }  `}
                      key={index}
                      onClick={() => {
                        setCompanyWhatsappNumber(
                          matchedCompanyColor === false
                            ? companyDetails?.whatsappNumber
                            : matchedCompanyColor[0].businessNumber
                        );
                        toggleActiveChat(data.id);
                      }}
                      style={{
                        backgroundColor: `${
                          matchedCompanyColor === false
                            ? "#FFFFFF"
                            : matchedCompanyColor[0].colorIdentification ===
                              null
                            ? "#FFFFFF"
                            : matchedCompanyColor[0].colorIdentification
                        }`,
                      }}
                    >
                      <form action="">
                        {data.status !== "RESOLVED" &&
                        data.status !== "CLOSED" ? (
                          <input
                            type="checkbox"
                            onClick={() =>
                              onClickCheckbox("contacts", data, index)
                            }
                            className="checkField"
                            value={data.id}
                            id={`contact${index}`}
                          />
                        ) : (
                          <div className="uk-margin-right"></div>
                        )}
                      </form>

                      <button
                        type="button"
                        onClick={() => {
                          setPageForChatMessages(0);
                          getChatMessages(
                            data.number,
                            data.chanelId,
                            data.agentName,
                            data.agentId,
                            data.publicId,
                            data.companyPublicId
                          );
                        }}
                      >
                        <div className="iconAndName">
                          <div className="imgwrp">
                            <img src={channelImages(data.chanelId)} alt="" />
                          </div>
                          <div className="txtwrp">
                            <h6
                              className={
                                index === activeChatIndex && "ft-weight"
                              }
                            >
                              {data.customerName}
                            </h6>
                            <p
                              dangerouslySetInnerHTML={sanitizedMessage(
                                formatText(data.lastMessage)
                              )}
                            ></p>
                          </div>
                        </div>
                        <div className="timeDivWrp">
                          <div className="timediv">
                            <p>{convertTimestamp(data.timeStamp)}</p>
                            {data.unReadCount !== 0 &&
                              data.unReadCount !== null && (
                                <span className="chatUnReadCount">
                                  {data.unReadCount}
                                </span>
                              )}
                            <span
                              uk-tooltip={`title: ${
                                data.status === "RESOLVED"
                                  ? "SOLVED"
                                  : data.status === "PENDING"
                                  ? "WAITING"
                                  : data.status === "ACTIVE"
                                  ? "OPEN"
                                  : data.status
                              }`}
                              className={
                                (data.status === "ACTIVE" &&
                                  "chatContactStatus greenDot") ||
                                (data.status === "PENDING" &&
                                  "chatContactStatus yellowDot") ||
                                (data.status === "RESOLVED" &&
                                  "chatContactStatus darGreen") ||
                                (data.status === "CLOSED" &&
                                  "chatContactStatus grayDot") ||
                                (data.status === "TRANSFER" &&
                                  "chatContactStatus tranferd") ||
                                (data.status === "UNASSIGNED" &&
                                  "chatContactStatus tranferd")
                              }
                            ></span>
                          </div>
                        </div>
                      </button>
                    </li>
                  );
                })}
              </InfiniteScroll>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatContacts;
