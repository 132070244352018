import React, { useEffect, useState } from "react";
// import closeImg from "../../../assets/images/close-24px.svg";
import { CheckmarkOutline } from "@carbon/icons-react";
import {
  apiGetSuperAdmin,
  apiPostSuperAdmin,
} from "../../../Utils/apiServices";
import { useAuth } from "../../../Context/AuthProvider";
import { decryptedValue } from "../../../Utils/RouteHashConversion";
import { toast } from "react-toastify";

const PackageModal = () => {
  const { auth } = useAuth();
  const [packages, setPackages] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState("");
  const getPackages = () => {
    apiGetSuperAdmin(
      `/super-admin/packages`,
      onSuccessPackage,
      onFailurePackage
    );
  };
  const onSuccessPackage = (response) => {
    if (response.status === 1) {
      setPackages(response.data);
    }
  };
  const onFailurePackage = (error) => {
    console.log(error);
  };

  const setPackage = () => {
    setLoader(true);
    const data = {
      companyPublicId: decryptedValue(auth.companyId),
      packagePublicId: selectedPackage,
    };
    if (selectedPackage !== "") {
      apiPostSuperAdmin(
        `/super-admin/packages/select`,
        onSuccess,
        onFailure,
        data
      );
    } else {
      setLoader(false);
    }
  };

  const onSuccess = (response) => {
    setTimeout(() => {
      toast.success(response, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
      });
      setLoader(false);
    }, 1000);
    setTimeout(() => {
      localStorage.removeItem(auth.token);
    }, 1500);
  };
  const onFailure = (error) => {
    if (error) {
      setTimeout(() => {
        setLoader(false);
        toast.error(error.response.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
        });
      }, 1000);
    }
  };
  useEffect(() => {
    getPackages();
  }, []);
  return (
    <div
      className="ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical pauseModalWrp">
        {/* <button type="button" className="closeBtn" >
          <img src={closeImg} alt="" />
        </button> */}
        <div className="txtwrp">
          <h3>Select your packages</h3>
          {/* <p>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s.
          </p> */}
        </div>
        <div className="packageSlider">
          <div
            className="uk-position-relative uk-visible-toggle uk-light"
            tabindex="-1"
            uk-slider=""
            style={{ overflow: "visible" }}
          >
            <div className="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@m uk-grid uk-grid-small">
              {packages?.map((val, index) => {
                return (
                  <div key={index}>
                    <div className="uk-panel">
                      <div className="packageBox">
                        <div className="boxHeading">
                          <h3>{val.title}</h3>
                          <input
                            type="radio"
                            name="package"
                            value={val.publicId}
                            checked={val.publicId === selectedPackage}
                            onChange={() => {
                              setSelectedPackage(val.publicId);
                            }}
                          />
                        </div>
                        <div className="packageList">
                          <p>{val.description}</p>
                          <ul>
                            <li>
                              <CheckmarkOutline />
                              <span>
                                {val.whatsappConversation} conversation
                              </span>
                            </li>
                            <li>
                              <CheckmarkOutline />
                              <span>{val.activeUser} MAU</span>
                            </li>
                            <li>
                              <CheckmarkOutline />
                              <span>{val.liveAgent} Agents</span>
                            </li>
                            {/* <li>
                            <CheckmarkOutline />
                            <span>1 Chatbots</span>
                          </li> */}
                          </ul>
                        </div>
                        <div className="packagePrice">
                          <p>Price</p>
                          <span>PKR {val.price}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="uk-visible@s">
              {/* <a className="uk-position-center-left-out uk-position-small" href uk-slidenav-previous="" uk-slider-item="previous"></a> */}
              <a
                className="uk-position-center-right-out uk-position-small sliderBtn"
                href
                uk-slidenav-next=""
                uk-slider-item="next"
              ></a>
            </div>
          </div>
        </div>
        <div style={{ height: "20px", marginTop: "10px" }}>
          {selectedPackage === null && (
            <div className="formErrors ">Please select package </div>
          )}
        </div>
        <div className="btnwrp">
          {/* <button type="button" className="btn-1" onClick={closeModal}>
            Back
          </button> */}
          <button
            type="button"
            className="btn-2"
            onClick={() => {
              selectedPackage === "" && setSelectedPackage(null);
              setPackage();
            }}
          >
            {loader ? <div uk-spinner=""></div> : "Ok"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PackageModal;
