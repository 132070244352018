import {
  Checkmark,
  ChevronRight,
  Download,
  Edit,
  TrashCan,
} from "@carbon/icons-react";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TriggerWindow from "./components/TriggerWindow";
import DialogWindow from "./components/DialogWindow";
import { useAuth } from "../../../Context/AuthProvider";
import { toast } from "react-toastify";
import { decryptedValue } from "../../../Utils/RouteHashConversion";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import UIkit from "uikit";
import { property } from "lodash";

const NewChatBot = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [bName, setBName] = useState("");
  const [isEditingBName, setIsEditingBName] = useState(false);
  const [isKeywordVisible, setKeywordVisible] = useState(false);
  const [inputTriggerValue, setInputTriggerValue] = useState("");
  const [triggerList, setTriggerList] = useState([]);
  const [selectSection, setSelectSection] = useState(false);
  const [isTriggerSectionOpen, setIsTriggerSectionOpen] = useState(true);
  const [keywords, setKeywords] = useState([]);
  const [sections, setSections] = useState([]);

  // for dialogue
  const [saveButtonClicked, setSaveButtonClicked] = useState(true);
  const [isInputFocused, setInputFocused] = useState(false);
  const [isDialogSectionOpen, setIsDialogSectionOpen] = useState(true);
  const inputRef = useRef(null);
  const [sectionData, setSectionData] = useState([]);
  const [editable, setEditable] = useState(false);
  const [dialogOpenIndex, setDialogOpenIndex] = useState(null);
  const [selectedDialogOpen, setSelectedDialogOpen] = useState();
  const [showInstruction, setShowInstruction] = useState(true);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [loader, setLoader] = useState(false);
  const [hasTwoItems, setHasTwoItems] = useState(false);
  const [disableAll, setdisableAll] = useState(false);
  const [disableItem, setDisableItem] = useState(false);
  const [hasFile, setHasFile] = useState(false);
  const [isHandleAddButtonPressed, setIsHandleAddButtonPressed] =
    useState(false);
  const [sectionValues, setSectionValues] = useState([]);
  const [hasImage, setHasImage] = useState(false);
  const [hasVideo, setHasVideo] = useState(false);
  const [openDialogModal, setOpenDialogModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [dropdownDialogID, setDropdownDialogId] = useState("");
  const [selectedAgent, setSelectedAgent] = useState("Assign to agent");
  const [selectDialog, setSelectedDialog] = useState(
    "Click to select a dialog"
  );
  const [isKeywordVisibleAction, setKeywordVisibleAction] = useState(false);
  const [selectForm, setSelectForm] = useState("Click to select a form");
  const [selectTeam, setSelectTeam] = useState("Click to select a team");
  const [isDialogVisibleArray, setIsDialogVisibleArray] = useState(
    new Array(selectedDialogOpen?.settings?.length).fill(false)
  );
  const [isFormVisibleArray, setIsFormVisibleArray] = useState(
    new Array(selectedDialogOpen?.settings?.length).fill(false)
  );
  const [isActionVisibleArray, setIsActionVisibleArray] = useState(
    new Array(selectedDialogOpen?.settings?.length).fill(false)
  );
  const [formData, setFormData] = useState(false);
  const [teamsData, setTeamsData] = useState(false);
  const [paramsState, setParamsState] = useState("");
  const [validationName, setValidationName] = useState("");
  const [teamNameSingle, setNameSingle] = useState("");
  const [teamName, setTeamName] = useState("");
  const [productHeaderText, setProductHeaderText] = useState({
    index: 1,
    property: "productHeaderText",
    name: "",
  });
  const [productFooterText, setProductFooterText] = useState({
    index: 2,
    property: "productFooterText",
    name: "",
  });
  const [productId, setProductId] = useState({
    index: 0,
    property: "productId",
    name: "",
  });
  const [emptyTextarea, setEmptyTextarea] = useState(false);
  const [emptyKeyword, setEmptyKeyword] = useState(false);

  const initialSelectedDialogOnButton = Array(sections?.length).fill(null);

  const selectSectionFunc = (sectionName) => {
    setSelectedDialogOpen(sectionName);
    setSelectSection(sectionName);
  };

  //  trigger functions
  const handleEditClickBName = () => {
    setIsEditingBName(true);
  };
  const handleSaveClickBName = () => {
    setIsEditingBName(false);
  };
  const toggleKeywordVisibility = () => {
    setKeywordVisible(!isKeywordVisible);
  };
  const handleTriggerInputChange = (e) => {
    setInputTriggerValue(e.target.value);
  };

  const handleTrigerKeyPress = (e) => {
    if (e.key === "Enter") {
      const splitValues = inputTriggerValue
        .split(",")
        .map((value) => value.trim())
        .filter((value) => value !== "" && value !== "Add a trigger");

      if (splitValues.length > 0) {
        setTriggerList([...triggerList, ...splitValues]);
        setInputTriggerValue("");
      }
    }
  };
  const handleRemoveTrigger = (indexToRemove) => {
    const updatedTriggerValue = [
      ...triggerList.slice(0, indexToRemove),
      ...triggerList.slice(indexToRemove + 1),
    ];
    setTriggerList(updatedTriggerValue);
  };

  // for dialog functions
  const [selectedDialogOnButton, setSelectedDialogOnButton] = useState(
    initialSelectedDialogOnButton
  );

  const handleAddButton = (
    sectionId,
    index,
    selectValue,
    type,
    keyword,
    validation
  ) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings?.map((setting) => {
        if (setting.id === sectionId) {
          return {
            ...setting,
            detail: {
              selectValue:
                type === "typeAgent" || type === "typeCatalog" || type == 0
                  ? null
                  : selectValue,
              type: type || "typeAgent",
              selectedId:
                type === "typeAgent" || type === "typeCatalog" || type == 0
                  ? null
                  : setting?.detail?.selectedId || dropdownDialogID,
              validation: validation || null,
            },
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });

    setSections(updatedData);

    if (!keyword) {
      toast.error("Please fill all the required fields.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const addNewDialogSection = () => {
    // setSaveButtonClicked(false);
    // let newDialogue = {
    //   dialogueTitle: "",
    //   isDefault: true,
    //   dialogueId: uuidv4(),
    //   settings: [],
    // };
    // setSections([...sections, newDialogue]);

    // setSaveButtonClicked(false);

    // if (sections.length == 0) {
    //   let newDialogue = {
    //     dialogueTitle: "",
    //     isDefault: true,
    //     dialogueId: uuidv4(),
    //     settings: [],
    //   };
    //   setSections([...sections, newDialogue]);
    // } else {
    //   const sectionToDuplicate = sections[0];
    //   if (sectionToDuplicate) {
    //     const newSection = {
    //       ...sectionToDuplicate,
    //       dialogueId: uuidv4(),
    //       dialogueTitle: "",
    //       isDefault: false,
    //       settings: [],
    //     };
    //     setSections([...sections, newSection]);
    //   }
    //   setSectionData([]);
    // }
    sections.forEach((dialogue) => {
      if (dialogue.dialogueTitle === "") {
      }
    });

    setSaveButtonClicked(false);

    if (sections.length == 0) {
      let newDialogue = {
        dialogueTitle: "",
        isDefault: true,
        dialogueId: uuidv4(),
        settings: [],
      };
      setSections([...sections, newDialogue]);
    } else {
      const sectionToDuplicate = sections[0];
      if (sectionToDuplicate) {
        const newSection = {
          ...sectionToDuplicate,
          dialogueId: uuidv4(),
          dialogueTitle: "",
          isDefault: false,
          settings: [],
        };
        setSections([...sections, newSection]);
      }
      setSectionData([]);
    }
  };

  const handleEditClick = () => {
    setEditable(!editable);
  };

  const handleRemoveTriggerAction = (index, item) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section.settings.map((setting) => {
        if (setting.keyword && setting.keyword.length > 0) {
          const updatedKeywords = setting.keyword.filter(
            (keyword, i) =>
              !(
                i === index &&
                keyword.value === item.value &&
                keyword.id === item.id
              )
          );
          return { ...setting, keyword: updatedKeywords };
        }
        return setting;
      });

      return { ...section, settings: updatedSettings };
    });

    setSections(updatedData);
  };

  const handleSectionNameChange = (sectionId, newName) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.dialogueId === sectionId
          ? { ...section, dialogueTitle: newName }
          : section
      )
    );
  };

  const deleteSection = (sectionId) => {
    const updatedSections = sections.filter(
      (section) => section.dialogueId !== sectionId
    );
    setSections(updatedSections);
  };

  const selectedDialogIndex = sections?.findIndex(
    (dialog) => dialog.dialogueTitle === selectSection
  );

  // Function to check if any dialogueTitle is empty
  const isAnyDialogueTitleEmpty = (array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].dialogueTitle === "") {
        return true; // Found an empty dialogueTitle
      }
    }
    return false; // No empty dialogueTitle found
  };

  const isAnyEmptyActionKeyword = (sections, isSingleDialogCheck) => {
    if (isSingleDialogCheck === undefined) {
      sections?.forEach((item) => {
        item?.settings?.forEach((item) => {
          if (
            item.type === "action" &&
            (item?.keyword === "" ||
              item?.keyword == undefined ||
              item?.keyword?.length == 0)
          ) {
            return true;
          } else {
            return false;
          }
        });
      });
    } else {
      sections?.settings?.forEach((item) => {
        if (
          item.type === "action" &&
          (item?.keyword === "" ||
            item?.keyword == undefined ||
            item?.keyword?.length == 0)
        ) {
          return true;
        } else {
          return false;
        }
      });
    }
  };
  // generic function
  const isAnyEmptyValues = (type) => {
    return sections.every((dialogue) => {
      return dialogue.settings.some((setting) => {
        if (setting.type !== type) {
          return true;
        } else if (
          setting.type === type &&
          setting.value !== "" &&
          setting.value !== undefined
        ) {
          return true;
        }
      });
    });
  };

  const isSingleEmptyValues = (section, type) => {
    return section.settings.some((setting) => {
      if (setting.type !== type) {
        return true;
      } else if (
        setting.type === type &&
        setting.value !== "" &&
        setting.value !== undefined
      ) {
        return true;
      }
    });
  };

  // generic function end
  const isAnyMediaNotUploaded = (type) => {
    return sections.every((dialogue) => {
      return dialogue.settings.some((setting) => {
        if (setting.type !== type) {
          return true;
        } else if (
          setting.type === type &&
          setting.value !== "" &&
          setting.value !== undefined
        ) {
          return true;
        }
      });
    });
  };

  const isSingleMediaNotUploaded = (section, type) => {

    return section.settings.some((setting) => {
      if (setting.type !== type) {
        return true;
      } else if (
        setting.type === type &&
        setting.value !== "" &&
        setting.value !== undefined
      ) {
        return true;
      }
    });
  };

  const isAnyEmptyTextArea = () => {
    return sections.every((dialogue) => {
      return dialogue.settings.some((setting) => {
        if (setting.type !== "textarea") {
          return true;
        } else if (
          setting.type === "textarea" &&
          setting.value !== "" &&
          setting.value !== undefined
        ) {
          return true;
        }
      });
    });
  };
  const isSingleTextAreaEmpty = (section) => {
    return section.settings.some((setting) => {
      if (setting.type !== "textarea") {
        return true;
      } else if (
        setting.type === "textarea" &&
        setting.value !== "" &&
        setting.value !== undefined
      ) {
        return true;
      }
    });
  };

  // const isDialogSettingEmpty = () => {
  //   for (let i = 0; i < sections.length; i++) {
  //     if (sections[i].settings.length === 0) {
  //       return true;
  //     }else{
  //       return false
  //     }
  //   }
  //   return false;
  // };
  const isAnyRequiredFieldMissinginAllDialogs = () => {
    return sections.every((item) => {
      if (
        item.settings &&
        Array.isArray(item.settings) &&
        item.settings.length > 0
      ) {
        return item.settings.some((setting) =>
          ["textarea", "image", "video", "file"].includes(setting.type)
        );
      }
      return false;
    });
  };

  const isIndividualRequiredFieldsMissing = (section) => {
    section?.settings?.forEach((item) => {
      if (
        item?.type === "textarea" &&
        item?.type === "image" &&
        item?.type === "video" &&
        item?.type === "file"
      ) {
        return true;
      } else {
        return false;
      }
    });
  };

  const handleInputChangeSectionCatalogue = (
    id,
    property,
    value,
    productId
  ) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings?.map((setting) => {
        if (setting.id === id) {
          return {
            ...setting,
            detail: {
              ...setting.detail,
              [property]: value,
              name: `button_${value}`,
            },
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });

    setSections(updatedData);
  };

  const toggleKeywordVisibilityAction = () => {
    setKeywordVisibleAction(!isKeywordVisibleAction);
  };

  const handleChange = (e) => {
    setSectionValues(e.target.value);
  };

  const selectedDialog = sections.find(
    (dialog) => dialog.dialogueTitle === selectSection
  );

  const handleDialogSelectOnButton = (dialog, index, dialogId, teamName) => {
    setDropdownDialogId(dialogId);
    const updatedSelectedDialogOnButton = [...selectedDialogOnButton];
    updatedSelectedDialogOnButton[index] = dialog;
    setSelectedDialogOnButton(updatedSelectedDialogOnButton);

    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings?.map((setting) => {
        if (setting.id === index) {
          // Create the "detail" object if it doesn't exist
          const updatedDetail = setting.detail || {};

          // Update the "selectedId" in the "detail" object
          updatedDetail.selectedId = dialogId;

          if (updatedDetail.selectValue === dialog) {
            setNameSingle(teamName);
          }

          return {
            ...setting,
            detail: updatedDetail,
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });

    setSections(updatedData);
  };

  const handleInputChangeSection = (id, property, value) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings.map((setting) => {
        if (setting.id === id) {
          return {
            ...setting,
            [property]: value,
          };
        }
        return setting;
      });

      return { ...section, settings: updatedSettings };
    });
    setSections(updatedData);
  };

  const handleInputChangeSectionButton = (id, property, value) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings?.map((setting) => {
        if (setting.id === id) {
          return {
            ...setting,
            [property]: value,
            name: "button_" + value,
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });

    setSections(updatedData);
  };

  const handleInputChangeSectionAction = (id, property, newValue) => {
    const updatedData = sections.map((section) => {
      const updatedSettings = section?.settings?.map((setting) => {
        if (setting.id === id) {
          const newItemId = generateUniqueId();

          const updatedValue = Array.isArray(setting[property])
            ? [...setting[property], { value: newValue, id: newItemId }]
            : [{ value: newValue, id: newItemId }];

          return {
            ...setting,
            [property]: updatedValue,
            name: "button_" + newValue,
          };
        }
        return setting;
      });
      return { ...section, settings: updatedSettings };
    });

    setSections(updatedData);
    setSectionValues([...sectionValues, newValue]);
  };

  const toggleActionVisibility = (index) => {
    if (!isActionVisibleArray[index]) {
      const updatedVisibilityArray = [...isActionVisibleArray];
      updatedVisibilityArray[index] = true;
      setIsActionVisibleArray(updatedVisibilityArray);
    }
  };

  const hideEmoji = () => {
    setOpenEmoji(false);
  };

  const handleOpenEmoji = (e) => {
    setOpenEmoji(true);
  };

  const builderDropdownClose = () => {
    UIkit.dropdown("#builderDropdown").hide();
  };

  const actionDropdownClose = () => {
    UIkit.dropdown("#actionDropdown").hide();
  };

  const actionInputDropdownClose = () => {
    UIkit.dropdown("#actionInputDropdown").hide();
  };

  const dialogDropdownClose = () => {
    UIkit.dropdown("#dialogDropdown").hide();
  };

  const userActionDropdownClose = () => {
    UIkit.dropdown("#userActionMainDropdown").hide();
  };

  const buttonDropdownClose = () => {
    UIkit.dropdown("#buttonMainDropdown").hide();
  };

  const closeButtonDropdown = (id) => {
    UIkit.dropdown(id)?.hide();
  };

  const closeButtonPressDrop = (id) => {
    UIkit.dropdown(id)?.hide();
  };

  const closeDialogDropdown = (id) => {
    UIkit.dropdown(id)?.hide();
  };

  const closeUserActionDropCancel = (id) => {
    UIkit.dropdown(id)?.hide();
  };

  const closeUserActionDrop = (id, keyword) => {
    if (keyword) {
      UIkit.dropdown(id)?.hide();
    }
  };

  const closeReplyDropdown = (id) => {
    UIkit.dropdown(id)?.hide();
  };

  const closeActionDropdown = (id) => {
    UIkit.dropdown(id)?.hide();
  };

  const closeUserDialogDrop = (id) => {
    UIkit.dropdown(id)?.hide();
  };

  useEffect(() => {
    // Check the length of settings array in the selectedDialog
    if (selectedDialog?.settings.length > 0) {
      setShowInstruction(false);
    } else {
      setShowInstruction(true);
    }
  }, [selectedDialog?.settings?.length]);

  useEffect(() => {
    if (
      selectedDialog?.settings &&
      selectedDialog?.settings.some((item) => item.type === "image") &&
      selectedDialog?.settings.some((item) => item.name !== null) &&
      selectedDialog?.settings.some((item) => item.value !== null)
    ) {
      setHasImage(true);
    } else {
      setHasImage(false);
    }

    if (
      selectedDialog?.settings &&
      selectedDialog?.settings.some((item) => item.type === "video") &&
      selectedDialog?.settings.some((item) => item.name !== null) &&
      selectedDialog?.settings.some((item) => item.value !== null)
    ) {
      setHasVideo(true);
    } else {
      setHasVideo(false);
    }
    if (
      selectedDialog?.settings &&
      selectedDialog?.settings.some((item) => item.type === "file") &&
      selectedDialog?.settings.some((item) => item.name !== null) &&
      selectedDialog?.settings.some((item) => item.value !== null)
    ) {
      setHasFile(true);
    } else {
      setHasFile(false);
    }
  }, [selectedDialog?.settings, selectedDialog?.settings.length]);

  // useEffect(() => {
  //   let isAlertLogged = false;

  //   //teaxtarea all validations
  //   sections?.forEach((item) => {
  //     item?.settings?.forEach((item) => {
  //       if (
  //         item.type === "action" &&
  //         (item?.keyword === "" ||
  //           item?.keyword == undefined ||
  //           item?.keyword?.length == 0)
  //       ) {
  //         setEmptyKeyword(true);
  //       } else {
  //         setEmptyKeyword(false);
  //       }
  //     });
  //   });

  //   sections?.forEach((item) => {
  //     item?.settings?.forEach((item) => {
  //       if (
  //         item?.type === "textarea" &&
  //         (item.value == undefined || item.value === "")
  //       ) {
  //         setEmptyTextarea(true);
  //       } else {
  //         setEmptyTextarea(false);
  //       }
  //     });
  //   });
  // }, [sections, sections.settings]);
  const handleEmojiClick = (id, sectionID) => (emojiData, event) => {
    insertEmoji(id, sectionID, emojiData.emoji);
  };
  // Add this function to handle emoji insertion
  const insertEmoji = (id, sectionID, emoji) => {
    const inputField = document.getElementById(id);

    if (inputField) {
      const selectionStart = inputField.selectionStart || 0;
      const selectionEnd = inputField.selectionEnd || 0;

      const cursorPos =
        selectionStart === selectionEnd ? selectionStart : selectionEnd;

      const updatedData = sections.map((section) => {
        const updatedSettings = section?.settings.map((setting) => {
          if (setting.id === sectionID) {
            const currentValue = setting.value || "";

            // Split the text into an array of grapheme clusters
            const textArray = Array.from(currentValue);

            // Adjust cursorPos for emoji variations
            const emojiLength = Array.from(emoji).length;
            const adjustedCursorPos = cursorPos + emojiLength;

            // Insert the emoji at the adjusted cursor position
            textArray.splice(adjustedCursorPos, 0, ...Array.from(emoji));

            // Join the array back into a string
            const newValue = textArray.join("");

            return {
              ...setting,
              value: newValue,
            };
          }
          return setting;
        });

        return { ...section, settings: updatedSettings };
      });

      setSections(updatedData);
    }
  };

  const handleKeyPressKeywordSyn = (e, sectionId) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleInputChangeSectionAction(sectionId, "keyword", sectionValues);
      setSectionValues("");
      setKeywordVisibleAction(false);
    }
  };

  const handleFileI = (event, headerMediaType, sectionIndex) => {
    const file = event.target.files[0];
    const fileInput = event.target;

    const isImage = (file) => {
      const imageExtensions = [
        ".jpg",
        ".jpeg",
        ".gif",
        ".png",
        ".avif",
        ".AVIF",
      ];
      const extension = file.name
        .toLowerCase()
        .substring(file?.name.lastIndexOf("."));
      return imageExtensions.includes(extension);
    };

    const updateSection = (value, name) => {
      setSections((prevSections) => {
        const updatedSections = [...prevSections];
        const updatedSettings = [
          ...updatedSections[selectedDialogIndex].settings,
        ];
        updatedSettings[sectionIndex] = {
          ...updatedSettings[sectionIndex],
          value: value,
          name: name,
        };
        updatedSections[selectedDialogIndex] = {
          ...updatedSections[selectedDialogIndex],
          settings: updatedSettings,
        };
        return updatedSections;
      });
    };

    if (file?.name && isImage(file)) {
      if (file.type.startsWith("image") && isImage(file)) {
        const url = URL.createObjectURL(file);
        var formData = new FormData();
        formData.append("file", file);
        formData.append("agentPublicId", localStorage.getItem("_agentId"));
        formData.append(
          "companyId",
          localStorage.getItem("_companyId") === null
            ? ""
            : decryptedValue(localStorage.getItem("_companyId"))
        );
        const baseUrl = process.env.REACT_APP_BASEURL;
        axios
          .post(baseUrl + "/chat/upload-file", formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("_token")}`,
            },
          })
          .then(
            (response) => {
              if (response.data.status === 1) {
                updateSection(response.data.message, url);
              }
            },
            (error) => {}
          );
        fileInput.value = "";
      }
    } else {
      toast.error("Invalid Format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      fileInput.value = "";
    }
  };

  const handleFileV = (event, headerMediaType, sectionIndex) => {
    const file = event.target.files[0];
    const fileInput = event.target;

    const isVideo = (file) => {
      return file?.name.toLowerCase().endsWith(".mp4");
    };

    const updateSection = (value, name) => {
      setSections((prevSections) => {
        const updatedSections = [...prevSections];
        const updatedSettings = [
          ...updatedSections[selectedDialogIndex].settings,
        ];
        updatedSettings[sectionIndex] = {
          ...updatedSettings[sectionIndex],
          value: value,
          name: name,
        };
        updatedSections[selectedDialogIndex] = {
          ...updatedSections[selectedDialogIndex],
          settings: updatedSettings,
        };
        return updatedSections;
      });
    };

    if (file?.name && isVideo(file)) {
      if (file.type.startsWith("video") && isVideo(file)) {
        const url = URL.createObjectURL(file);
        var formData = new FormData();
        formData.append("file", file);
        formData.append("agentPublicId", localStorage.getItem("_agentId"));
        formData.append(
          "companyId",
          localStorage.getItem("_companyId") === null
            ? ""
            : decryptedValue(localStorage.getItem("_companyId"))
        );
        const baseUrl = process.env.REACT_APP_BASEURL;
        axios
          .post(baseUrl + "/chat/upload-file", formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("_token")}`,
            },
          })
          .then(
            (response) => {
              if (response.data.status === 1) {
                updateSection(response.data.message, url);
              }
            },
            (error) => {}
          );
        fileInput.value = "";
      }
    } else {
      toast.error("Invalid Format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      fileInput.value = "";
    }
  };

  const handleFileF = (event, headerMediaType, sectionIndex) => {
    const file = event.target.files[0];
    const fileInput = event.target;

    const isFile = (file) => {
      const documentExtensions = [
        // Word Documents
        ".doc",
        ".docx",
        ".dot",
        ".dotx",
        ".pdf",
        ".PDF",

        // Excel Documents
        ".xls",
        ".xlsx",
        ".xlsm",
        ".xlsb",
        ".xlt",
        ".xltx",
      ];
      const extension = file?.name
        .toLowerCase()
        .substring(file?.name.lastIndexOf("."));
      return documentExtensions.includes(extension);
    };

    const updateSection = (value, name) => {
      setSections((prevSections) => {
        const updatedSections = [...prevSections];
        const updatedSettings = [
          ...updatedSections[selectedDialogIndex].settings,
        ];
        updatedSettings[sectionIndex] = {
          ...updatedSettings[sectionIndex],
          value: value,
          name: name,
        };
        updatedSections[selectedDialogIndex] = {
          ...updatedSections[selectedDialogIndex],
          settings: updatedSettings,
        };
        return updatedSections;
      });
    };

    if (file?.name && isFile(file)) {
      const url = URL.createObjectURL(file);

      var formData = new FormData();
      formData.append("file", file);
      formData.append("agentPublicId", localStorage.getItem("_agentId"));
      formData.append(
        "companyId",
        localStorage.getItem("_companyId") === null
          ? ""
          : decryptedValue(localStorage.getItem("_companyId"))
      );
      const baseUrl = process.env.REACT_APP_BASEURL;
      axios
        .post(baseUrl + "/chat/upload-file", formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("_token")}`,
          },
        })
        .then(
          (response) => {
            if (response.data.status === 1) {
              updateSection(response.data.message, file.name);
            }
          },
          (error) => {}
        );
      fileInput.value = "";
    } else {
      toast.error("Invalid Format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      fileInput.value = "";
    }
  };

  const addSection = (sectionType, name, value) => {
    const SettingDialogue = sections.find(
      (dialogue) => dialogue.dialogueTitle === selectedDialogOpen
    );

    let sectionSettings = SettingDialogue?.settings;

    const newSection = {
      type: sectionType,
      name: name,
      value: value,
      id: uuidv4(),
    };
    sectionSettings?.push(newSection);

    const updatedDialogues = sections.map((dialogue) => {
      if (dialogue.dialogueTitle === selectedDialogOpen) {
        return { ...dialogue, settings: sectionSettings };
      } else {
        return dialogue;
      }
    });
    setSections(updatedDialogues);

    if (sectionType === "action") {
      setIsHandleAddButtonPressed(true);
    } else {
      setIsHandleAddButtonPressed(false);
    }
  };

  const toggleDialogHidden = (index) => {
    const updatedVisibilityArray = [...isDialogVisibleArray];
    updatedVisibilityArray[index] = false;
    setIsDialogVisibleArray(updatedVisibilityArray);
  };

  const toggleFormVisibility = (index) => {
    if (!isFormVisibleArray[index]) {
      const updatedVisibilityArray = [...isFormVisibleArray];
      updatedVisibilityArray[index] = true;
      setIsFormVisibleArray(updatedVisibilityArray);
    }
  };

  const generateUniqueId = () => {
    return "unique_id_" + Date.now() + "_" + Math.floor(Math.random() * 1000);
  };

  const handleOpenDilaogModal = () => {
    setOpenDialogModal(true);
  };
  const handleSectionClosing = (id) => {
    const updatedData = sections.map((section) => {
      if (section?.settings.some((setting) => setting.id === id)) {
        const updatedSettings = section?.settings.filter(
          (setting) => setting.id !== id
        );
        return { ...section, settings: updatedSettings };
      }
      return section;
    });

    setSections(updatedData);
  };
  const addChatBot = () => {
    let data = {
      botName: bName,
      channelId: "WHATSAPP",
      companyId: decryptedValue(auth.companyId),
      keyWords: keywords,
      sections: sections,
    };

    if (bName === "") {
      toast.error("Bot name is required for identification", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (triggerList.length === 0) {
      toast.error("Trigger keyword is required", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (sections.length === 0) {
      toast.error("Please add atleast on dialog", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (isAnyDialogueTitleEmpty(sections)) {
      toast.error("Dialog title is required", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    }
    // else if (isDialogSettingEmpty()) {
    //   toast.error("Please add dialog options", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 1300,
    //   });
    // }
    else if (!isAnyRequiredFieldMissinginAllDialogs()) {
      toast.error("Please select any option from text, image, video and file", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    }
    // else if (isAnyDialogueTitleEmpty(sections)) {
    //   toast.error("Dialog title is required", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 1300,
    //   });
    // }
    else if (isAnyEmptyActionKeyword(sections)) {
      toast.error("Action keyword is required", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (!isAnyEmptyTextArea()) {
      toast.error("Text box is empty", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (!isAnyMediaNotUploaded("image")) {
      toast.error("Please upload image", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (!isAnyMediaNotUploaded("video")) {
      toast.error("Please upload video", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } else if (!isAnyMediaNotUploaded("file")) {
      toast.error("Please upload file", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1300,
      });
    } 
    // else if (!isAnyMediaNotUploaded("button")) {
    //   toast.error("Button is empty", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 1300,
    //   });
    // }
     else if (
      bName !== "" &&
      triggerList.length !== 0 &&
      !isAnyDialogueTitleEmpty(sections) &&
      // !isDialogSettingEmpty() &&
      !isAnyEmptyActionKeyword(sections) &&
      isAnyEmptyTextArea() &&
      isAnyMediaNotUploaded("image") &&
      isAnyMediaNotUploaded("video") &&
      isAnyMediaNotUploaded("file") &&
      // isAnyMediaNotUploaded("button") &&
      // isAnyEmptyValues("button") &&
      isAnyRequiredFieldMissinginAllDialogs() &&
      sections.length > 0
    ) {
    }
  };

  // useEffect

  return (
    <div className="chatbotWrp createNewSegment uk-padding-remove">
      <div className="chatbotHeader">
        <div className="uk-grid uk-flex-middle" uk-grid="">
          <div className="uk-width-1-2">
            <div className="backBTn uk-padding-remove">
              <ul>
                <li>
                  <span>Chatbot flows</span>
                </li>
                <li>
                  <ChevronRight />
                </li>
                <li>
                  <span className="currentPage">Feedback flow</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="uk-width-1-2">
            <div className="headerBtn">
              <button
                className="btn-2"
                type="button"
                onClick={() =>
                  navigate(`/chatbot`, {
                    replace: true,
                  })
                }
              >
                Close
              </button>
              <button
                // title={hasTextarea ? "Choose one element with button" : ""}
                // type="button"
                className="btn-1 uk-margin-right"
                onClick={addChatBot}
                // disabled={hasTextarea || captionChecking}
              >
                Set live
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="chatbotBuilderWrp ">
        <div className="uk-grid " uk-grid="">
          <div className="uk-width-3-5">
            <div className="chatbotBuilderLeft">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="triggerWrp">
                    <div className="stepHeading">
                      <span>Trigger </span>
                    </div>
                    <div
                      className={`addTrigger uk-flex uk-flex-middle uk-flex-between`}
                      onClick={() => {
                        // selectSectionFunc("trigger");
                        setIsTriggerSectionOpen(true);
                        setIsDialogSectionOpen(false);
                      }}
                    >
                      <div className="txtwrp" style={{ cursor: "pointer" }}>
                        <span>
                          <span>
                            {triggerList?.length === 1
                              ? triggerList[0] // Display the single value without joining
                              : triggerList
                                  ?.filter((item) => item !== "Add a trigger")
                                  .join(", ") || "Add a trigger"}
                          </span>
                        </span>
                      </div>
                      <div className="btnwrp"></div>
                    </div>

                    <div className="stepHeading">
                      <span>Dialogs</span>
                    </div>

                    <div>
                      <div>
                        {sections?.map((section, index) => (
                          <div key={section.dialogueId}>
                            <div
                              className={`addTrigger uk-flex uk-flex-middle uk-flex-between ${
                                section.dialogueTitle === "" ||
                                section.settings.length === 0 ||
                                isIndividualRequiredFieldsMissing(section) ||
                                !isSingleTextAreaEmpty(section) ||
                                // !isSingleMediaNotUploaded(section, "button") ||
                                !isSingleMediaNotUploaded(section, "image") ||
                                !isSingleMediaNotUploaded(section, "video") ||
                                !isSingleMediaNotUploaded(section, "file")
                                  ? // ||
                                    // !isAnyRequiredFieldMissinginAllDialogs()
                                    // isAnyEmptyActionKeyword(section, "individual")
                                    // ||
                                    // isAnyEmptyTextArea

                                    "bd-red"
                                  : ""
                              }`}
                            >
                              <div
                                className={`txtwrp transBorder ${
                                  isInputFocused ? "focused" : ""
                                }`}
                                onClick={() => {
                                  setInputFocused(section.dialogueId);
                                  selectSectionFunc(section?.dialogueTitle);
                                  setSelectedDialogOpen(section);
                                }}
                              >
                                <input
                                  type="text"
                                  placeholder="Enter bot name"
                                  value={section?.dialogueTitle}
                                  onChange={(e) => {
                                    handleSectionNameChange(
                                      section.dialogueId,
                                      e.target.value
                                    );
                                  }}
                                  onClick={() => {
                                    setIsTriggerSectionOpen(false);
                                    setIsDialogSectionOpen(true);
                                  }}
                                  ref={inputRef}
                                />
                              </div>
                              <div className="btnwrp">
                                <button
                                  type="button"
                                  className={`btn-1 InvisibleCheck${
                                    isInputFocused === section.dialogueId
                                      ? "visibleCheck"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    setInputFocused(null);
                                    selectSectionFunc(section?.dialogueTitle);
                                  }}
                                >
                                  <Checkmark />
                                </button>
                                <button
                                  type="button"
                                  className="btn-1"
                                  onClick={handleEditClick}
                                >
                                  <Edit />
                                </button>
                                <button
                                  type="button"
                                  onClick={() =>
                                    deleteSection(section.dialogueId)
                                  }
                                >
                                  <TrashCan />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}

                        <div
                          onClick={addNewDialogSection}
                          className={`dialogBtn `}
                        >
                          <button type="button">Create new dialog</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  {/* Trigger section end */}
                  {isTriggerSectionOpen && (
                    <TriggerWindow
                      setBName={setBName}
                      bName={bName}
                      setIsEditingBName={setIsEditingBName}
                      isEditingBName={isEditingBName}
                      handleEditClickBName={handleEditClickBName}
                      handleSaveClickBName={handleSaveClickBName}
                      toggleKeywordVisibility={toggleKeywordVisibility}
                      handleTriggerInputChange={handleTriggerInputChange}
                      handleTrigerKeyPress={handleTrigerKeyPress}
                      setIsTriggerSectionOpen={setIsTriggerSectionOpen}
                      setIsDialogSectionOpen={setIsDialogSectionOpen}
                      isKeywordVisible={isKeywordVisible}
                      inputTriggerValue={inputTriggerValue}
                      triggerList={triggerList}
                      handleRemoveTrigger={handleRemoveTrigger}
                      onClick={() => {
                        setIsTriggerSectionOpen(true);
                        setIsDialogSectionOpen(false);
                      }}
                    />
                  )}
                  {/* Trigger section end */}

                  {/* Button listing start */}
                  {isDialogSectionOpen && (
                    <DialogWindow
                      onClick={() => {
                        setIsTriggerSectionOpen(false);
                        setIsDialogSectionOpen(true);
                      }}
                      sections={sections}
                      setSelectedDialogOpen={setSelectedDialogOpen}
                      selectedDialogOpen={selectedDialogOpen}
                      showInstruction={showInstruction}
                      handleInputChangeSection={handleInputChangeSection}
                      openEmoji={openEmoji}
                      handleEmojiClick={handleEmojiClick}
                      hideEmoji={hideEmoji}
                      handleOpenEmoji={handleOpenEmoji}
                      loader={loader}
                      addSection={addSection}
                      hasTwoItems={hasTwoItems}
                      disableAll={disableAll}
                      disableItem={disableItem}
                      hasFile={hasFile}
                      isHandleAddButtonPressed={isHandleAddButtonPressed}
                      generateUniqueId={generateUniqueId}
                      sectionValues={sectionValues}
                      setSectionValues={setSectionValues}
                      handleSectionClosing={handleSectionClosing}
                      hasVideo={hasVideo}
                      hasImage={hasImage}
                      handleOpenDilaogModal={handleOpenDilaogModal}
                      handleFileI={handleFileI}
                      handleFileV={handleFileV}
                      handleFileF={handleFileF}
                      builderDropdownClose={builderDropdownClose}
                      actionDropdownClose={actionDropdownClose}
                      actionInputDropdownClose={actionInputDropdownClose}
                      dialogDropdownClose={dialogDropdownClose}
                      userActionDropdownClose={userActionDropdownClose}
                      buttonDropdownClose={buttonDropdownClose}
                      closeButtonDropdown={closeButtonDropdown}
                      closeButtonPressDrop={closeButtonPressDrop}
                      closeUserActionDropCancel={closeUserActionDropCancel}
                      closeUserActionDrop={closeUserActionDrop}
                      closeReplyDropdown={closeReplyDropdown}
                      closeActionDropdown={closeActionDropdown}
                      closeUserDialogDrop={closeUserDialogDrop}
                      selectedDialogOnButton={selectedDialogOnButton}
                      handleAddButton={handleAddButton}
                      actionType={actionType}
                      handleInputChangeSectionButton={
                        handleInputChangeSectionButton
                      }
                      setSelectedAgent={setSelectedAgent}
                      selectedAgent={selectedAgent}
                      toggleDialogHidden={toggleDialogHidden}
                      selectForm={selectForm}
                      selectTeam={selectTeam}
                      setIsFormVisibleArray={setIsFormVisibleArray}
                      isFormVisibleArray={isFormVisibleArray}
                      isActionVisibleArray={isActionVisibleArray}
                      setIsActionVisibleArray={setIsActionVisibleArray}
                      toggleActionVisibility={toggleActionVisibility}
                      toggleFormVisibility={toggleFormVisibility}
                      setSelectForm={setSelectForm}
                      setSelectTeam={setSelectTeam}
                      handleDialogSelectOnButton={handleDialogSelectOnButton}
                      teamsData={teamsData}
                      formData={formData}
                      paramsState={paramsState}
                      validationName={validationName}
                      setValidationName={setValidationName}
                      handleInputChangeSectionCatalogue={
                        handleInputChangeSectionCatalogue
                      }
                      productHeaderText={productHeaderText}
                      setProductHeaderText={setProductHeaderText}
                      productFooterText={productFooterText}
                      setProductFooterText={setProductFooterText}
                      productId={productId}
                      setProductId={setProductId}
                      teamName={teamName}
                      setTeamName={setTeamName}
                      sectionData={sectionData}
                      toggleKeywordVisibilityAction={
                        toggleKeywordVisibilityAction
                      }
                      handleChange={handleChange}
                      handleRemoveTriggerAction={handleRemoveTriggerAction}
                      isKeywordVisibleAction={isKeywordVisibleAction}
                      setKeywordVisibleAction={setKeywordVisibleAction}
                      handleKeyPressKeywordSyn={handleKeyPressKeywordSyn}
                      setSelectedDialog={setSelectedDialog}
                      selectDialog={selectDialog}
                      selectedDialog={selectedDialog}
                      selectSection={selectSection}
                    />
                  )}
                  {/* Button listing end */}
                </div>
              </div>
            </div>
          </div>

          <div className="uk-width-2-5">
            <div
              className="mobilePreview uk-margin-top"
              style={{ height: "545px", width: "270px" }}
            >
              <div className="mobileMsgs" style={{ height: "450px" }}>
                <div className="msgDate">
                  <span>Friday, November 8</span>
                </div>
                {sections !== undefined &&
                  sections[selectedDialogIndex]?.settings?.map(
                    (item, index) => (
                      <div key={index}>
                        <span className="p-0 uk-margin-top-m-0">
                          {item.type === "textarea" ? (
                            <div className="leftMsg">
                              <pre>{item.value || "..."}</pre>
                            </div>
                          ) : item.type === "image" && item.value ? (
                            <div className="image-container leftMsg">
                              <img src={item.name} alt={`Uploaded ${index}`} />
                              <pre className="p-0 uk-margin-top-m-0">
                                {" "}
                                {item.caption}
                              </pre>
                            </div>
                          ) : item.type === "video" && item.name ? (
                            <div className="image-container leftMsg">
                              <video src={item.name} controls></video>
                              <pre className="p-0 uk-margin-top-m-0">
                                {" "}
                                {item.caption}
                              </pre>
                            </div>
                          ) : item.type === "file" && item.value ? (
                            <div className="leftMsg">
                              <span>
                                <Download />{" "}
                                {item.name ? item.name : "Document.pdf"}
                                <pre className="p-0 uk-margin-top-m-0">
                                  {" "}
                                  {item.caption}
                                </pre>
                              </span>
                            </div>
                          ) : item.type === "button" ? (
                            <button type="button" className="chatbotPreviewBtn">
                              {item.value || "..."}
                            </button>
                          ) : item.type === "action" ? null : null}
                        </span>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewChatBot;
