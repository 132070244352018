import { Camera, ChevronLeft, Image } from "@carbon/icons-react";
import React, { useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import TagModal from "../../chat/components/Modals/TagModal";
import { useState } from "react";
import DeleteModal from "../../../components/Modal/DeleteModal";
import { apiGet, apiPut, apiPost } from "../../../Utils/apiServices";
import { toast } from "react-toastify";
import { decryptedValue } from "../../../Utils/RouteHashConversion";
import { useAuth } from "../../../Context/AuthProvider";
import { useWebSocket } from "../../../socket/WebSocketManager";

const SingleCompany = () => {
  const { auth } = useAuth();
  const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();

  const [openAdminDeleteModal, setOpenAdminDeleteModal] = useState(false);
  const location = useLocation();
  const publicId = location?.state?.publicId;
  const [filePath, setFilePath] = useState("");
  const { socket } = useWebSocket();

  const [loader, setLoader] = useState(false);
  const [loaderGet, setLoaderGet] = useState(false);
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const [openTagModal, setOpenTagModal] = useState(false);
  const handleCloseAdminDeleteModal = () => {
    setOpenAdminDeleteModal(false);
  };

  const showToast = (message) => {
    toast.error(message);
  };

  const handleCloseTagModal = () => {
    setOpenTagModal(false);
  };

  const getCompany = () => {
    apiGet(
      `/super-admin/company-with-package/${publicId}`,
      onSuccessCompany,
      onFailureCompany
    );
  };
  const onSuccessCompany = (response) => {
    setData(response);
    setLoader(false);
    setLoaderGet(false);
  };
  const onFailureCompany = (error) => {
    console.log(error);
    setLoader(true);
    setLoaderGet(true);
  };
  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    if (filePath !== "") {
      setData({
        ...data,
        companyLogoFileId: filePath,
      });
    }
  }, [filePath]);

  const handleFileImage = (e) => {
    var file = e.target.files[0];
    var allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/webp"];
    var maxSize = 10 * 1024 * 1024; // 10MB
    if (!allowedTypes.includes(file.type)) {
      showToast(
        "Invalid image format. Only jpg, jpeg, png, and webp are allowed."
      );
      return;
    }
    if (file.size > maxSize) {
      showToast("Image size limit exceeded. The maximum allowed size is 10MB.");
      return;
    }
    var formData = new FormData();
    setImageUrl(URL.createObjectURL(file));
    formData.append("file", file);
    formData.append("agentPublicId", decryptedValue(auth.publicId));
    formData.append("companyId", decryptedValue(auth.companyId));
    apiPost(
      `/file/upload-file`,
      onSuccessFileUpload,
      onFailureFileUpload,
      formData
    );
  };
  const onSuccessFileUpload = (response) => {
    if (response.status == 1) {
      setFilePath(response.message);
    }
  };
  const onFailureFileUpload = (error) => {
    console.log(error);
  };
  const editCompany = () => {
    if (
      
      data?.companyName !== null &&
      data?.industry !== null &&
      data?.type !== null &&
      data?.domain !== null &&
      data?.contactPersonName !== null &&
      data?.contactPersonNumber !== null &&
     (data.activeUser !== null ||
      data.activeUser >= 0) &&
      (data.whatsappConversation !== null ||
      data.whatsappConversation >= 0) &&
      (data.liveAgent !== null ||
      data.liveAgent >= 0) &&
      (data.price !== null ||
      data.price !== "" ||
      data.price >= 0) &&
      data.title !== null
    ) {
      let updatedData = {
        activeUser: data.activeUser,
        whatsappConversation: data.whatsappConversation,
        liveAgent: data.liveAgent,
        price: data.price,
        title: data.title,
        contactPersonNumber: data?.contactPersonNumber,
        industry: data?.industry,
        domain: data?.domain,
        type: data?.type,
        companyName: data.companyName,
        contactPersonName: data?.contactPersonName,
        companyLogoFileId: data?.companyLogoFileId,
      };
      apiPut(
        `/super-admin/company-with-package/edit/${publicId}`,
        onSuccessEdit,
        onFailureEdit,
        updatedData
      );
    }
    else if (
      data?.price < 0 ||
      data?.liveAgent < 0 ||
      data?.whatsappConversation < 0 ||
      data?.activeUser < 0
    ) {
      showToast("Negative values are not allowed!");
      return;
    } 
  };

  const onSuccessEdit = (response) => {
    if (response.status === 200) {
      setTimeout(() => {
        setLoader(false);
        toast.success("Company updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1000);
      setTimeout(() => {
        navigate("/super-admin-company", { replace: true });
      }, 2000);
    }
  };

  const onFailureEdit = (error) => {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  

    setLoader(true);
  };

  // function getFileUrl(filePath) {
  //   if (socket && socket.readyState === WebSocket.OPEN) {
  //     const data = {
  //       companyLogoFileId: filePath,
  //     };
  //     const message = { type: "get-file-url", data: data };
  //     socket.send(JSON.stringify(message));
  //   }
  // }

  // useEffect(() => {
  //   if (filePath !== "") {
  //     getFileUrl(filePath);
  //   }
  // }, [filePath]);

  // useEffect(() => {
  //   if (socketResponse !== undefined) {
  //     console.log("soccc", socketResponse);
  //     if (socketResponse.type == "FILE_URL") {
  //       // setSendUrl(chatBaseUrl + socketResponse?.data.filePath);
  //     }
  //   }
  // }, [socketResponse]);

  return (
    <div
      className="adminDetailsWrp"
      style={{ minHeight: "calc(100vh - 135px)" }}
    >
      <div className="uk-container">
        <div className="backBtnWrp">
          <div className="backBtn">
            <Link to="/super-admin-company">
              <ChevronLeft /> Back to all companies
            </Link>
          </div>
          <div className="dltBtn">
            {/* {location.pathname.includes("profile") === false && ( */}
            {/* <button type="button" onClick={handleOpenAdminDeleteModal}>
              Delete company
            </button> */}
            {/* )} */}
          </div>
        </div>
        {loaderGet ? (
          <div className="centerDiv">
            <div uk-spinner="" className="loader"></div>
          </div>
        ) : (
          <>
            <div className="detailsWrp">
              <div
                className="uk-grid "
                uk-grid=""
                uk-height-match="target: > div > .customStyle"
              >
                <div className="uk-width-1-3">
                  <div className="customStyle">
                    <div className="detailContent adminDetailBoxes heightNew">
                      <div>
                        <div className="adminImg">
                          {data?.companyLogoFileId ? (
                            <span className="">
                              <img
                                src={
                                  imageUrl ||
                                  chatBaseUrl + data?.companyLogoFileId
                                }
                                alt=""
                              />
                            </span>
                          ) : (
                            <div className="adminImg">
                              <span className="InitialImage companyLogoTxt">
                                {data?.companyName &&
                                  data.companyName.charAt(0)}
                              </span>
                            </div>
                          )}

                          <div
                            uk-form-custom=""
                            className="profileImgUpload uk-form-custom"
                          >
                            <button type="button">
                              <Camera />
                              <input
                                type="file"
                                aria-label="Custom controls"
                                accept="image/*"
                                onChange={(e) => handleFileImage(e)}
                                style={{ top: "-13px" }}
                              />
                            </button>
                          </div>
                        </div>

                        <div className="numberInput namewrp">
                          <form action="">
                            <input
                              type="text"
                              // placeholder="Name"
                              value={data?.companyName}
                              disabled
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="uk-width-2-3">
                  <div className="customStyle">
                    <div className="userProfile adminDetailBoxes heightNew">
                      <div>
                        <div className="boxHeading">
                          <span>Company profile</span>
                        </div>
                        <div uk-grid="" className="uk-grid">
                          <div className="uk-width-1-2">
                            <div className="contentBox">
                              <div className="contentBoxHeading">
                                <span>Company Name</span>
                              </div>
                              <div className="txtwrp">
                                <div className="leftTxt">
                                  <p>
                                    <span>{data?.companyName || "-"}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="uk-width-1-2">
                            <div className="contentBox">
                              <div className="contentBoxHeading">
                                <span>Industry</span>
                              </div>
                              <div className="txtwrp">
                                <div className="leftTxt">
                                  <p>
                                    <span>{data?.industry || "-"}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="uk-width-1-2 m-0">
                            <div className="contentBox">
                              <div className="contentBoxHeading">
                                <span>Type</span>
                              </div>
                              <select
                                name=""
                                className="uk-select borderZero"
                                // value={submittedData.type}
                                // onChange={(e) => {
                                //   setSubmittedData({
                                //     ...submittedData,
                                //     type: e.target.value,
                                //   });
                                // }}
                              >
                                <option value="" selected>
                                  {data?.type || "Please select type"}
                                </option>
                                <option value="OWNER">OWNER </option>
                              </select>
                            </div>
                          </div>

                          <div className="uk-width-1-2  m-0">
                            <div className="contentBox">
                              <div className="contentBoxHeading">
                                <span>Domain</span>
                              </div>
                              <div className="txtwrp">
                                <div className="leftTxt">
                                  <p>
                                    <span>{data?.domain || "-"}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="uk-width-1-2 m-0">
                            <div className="contentBox">
                              <div className="contentBoxHeading">
                                <span>Company contact person name</span>
                              </div>
                              <div className="txtwrp">
                                <div className="leftTxt">
                                  <p>
                                    <span>
                                      {data?.contactPersonName || "-"}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="uk-width-1-2 m-0">
                            <div className="contentBox">
                              <div className="contentBoxHeading">
                                <span>Contact person WhatsApp number</span>
                              </div>
                              <div className="txtwrp">
                                <div className="leftTxt">
                                  <p>
                                    <span className="disabled">
                                      {data?.contactPersonNumber || "-"}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="adminDetailTabbing">
              <ul uk-tab="connect: .singleCompanyTab" className="tabBtn ">
                <li>
                  <a href="/">Package Details</a>
                </li>
              </ul>

              <ul className="uk-switcher uk-margin singleCompanyTab">
                <li>
                  <div className="detailFormwrp">
                    <form>
                      <div className="uk-grid uk-grid-small" uk-grid="">
                        <div className="uk-width-1-2">
                          <div className="formInput">
                            <label htmlFor="f_name">Package name</label>
                            <input
                              className="uk-input"
                              type="text"
                              placeholder="Package name"
                              value={data?.title}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  title: e.target.value,
                                });
                              }}
                              defaultValue={
                                data?.title !== undefined ? data?.title : ""
                              }
                            />
                          </div>
                        </div>
                        <div className="uk-width-1-2">
                          <div className="formInput">
                            <label htmlFor="f_name">MAU</label>
                            <input
                              className="uk-input"
                              type="text"
                              placeholder="MAU"
                              value={data?.activeUser}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  activeUser: e.target.value,
                                });
                              }}
                              defaultValue={
                                data?.activeUser !== undefined
                                  ? data?.activeUser
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div className="uk-width-1-2">
                          <div className="formInput">
                            <label htmlFor="f_name">Conversation</label>
                            <input
                              className="uk-input"
                              type="text"
                              placeholder="Conversation"
                              value={data?.whatsappConversation}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  whatsappConversation: e.target.value,
                                });
                              }}
                              defaultValue={
                                data?.whatsappConversation !== undefined
                                  ? data?.whatsappConversation
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div className="uk-width-1-2">
                          <div className="formInput">
                            <label htmlFor="f_name">Agent</label>
                            <input
                              className="uk-input"
                              type="text"
                              placeholder="Agent"
                              value={data?.liveAgent}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  liveAgent: e.target.value,
                                });
                              }}
                              defaultValue={
                                data?.liveAgent !== undefined
                                  ? data?.liveAgent
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div className="uk-width-1-2">
                          <div className="formInput">
                            <label htmlFor="f_name">Price</label>
                            <input
                              className="uk-input"
                              type="text"
                              placeholder="Price"
                              value={data?.price}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  price: e.target.value,
                                });
                              }}
                              defaultValue={
                                data?.price !== undefined ? data?.price : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="uk-width-1-1 addTeamWrp m-0">
                    <div className="btnwrp m-0">
                      <button
                        className="btn-1"
                        type="button"
                        onClick={() =>
                          navigate("/super-admin-company", { replace: true })
                        }
                      >
                        Cancel
                      </button>
                      <button
                        className="btn-2 w-70"
                        type="submit"
                        onClick={() => editCompany()}
                      >
                
                          Save
                   
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>

      {openTagModal && (
        <TagModal
          closeModal={handleCloseTagModal}
          heading={"Add team"}
          fieldLabel={"Type to add team members"}
          saveBtnTxt={"Add"}
          clearBtn={"Clear team"}
        />
      )}

      {openAdminDeleteModal && (
        <DeleteModal
          closeModal={handleCloseAdminDeleteModal}
          dltModalTxt="This will affect users who are Creative Team members of selected teams."
          // deleteTeam={deleteUser}
          heading={"Delete User"}
        />
      )}
    </div>
  );
};

export default SingleCompany;
