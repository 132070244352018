import { ChevronLeft } from "@carbon/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const ThankYou = () => {
  const navigate = useNavigate();
  return (
    <div className="thankyouContainer">
      <div className="innnerSection">
        <h2 className="heading">Thank you for submitting your demo request!</h2>
        <p className="mainDescription">
          Your form has been successfully received. Our dedicated team will be
          in touch with you shortly to schedule the demo and discuss the next
          steps.
        </p>
        <p className="mainDescription">
          We appreciate your interest in the live user chats feature, and we
          look forward to potentially collaborating with you to enhance your
          software and improve customer engagement.
        </p>
        <div className="uk-margin-xlarge-top">
          <button
            className="backbtn"
            onClick={() => {
              navigate("/", { replace: true });
            }}
          >
            <ChevronLeft /> Back to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
