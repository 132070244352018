import React from "react";
import { formatChatHoverDateTime } from "../../Utils/DateFunctions";
import {
  displayMessageStatusIcon,
  getToolTipPosition,
} from "../../Utils/chat/chatFunctions";
const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;

const ChatVideo = ({ data }) => {
  return (
    <>
      {data.messageType === "media" ? (
        <div
          className="txtdiv"
          uk-tooltip={`title: ${formatChatHoverDateTime(
            data.timestamp
          )}; pos: ${getToolTipPosition(data.isSent)}`}
        >
          {(data.isSent == "true" || data.isSent == true) &&
            displayMessageStatusIcon(data.messageStatus)}
          <div className=" customWidth">
            <video controls>
              <source src={data.filePath} type="audio/ogg" />
              Your browser does not support the audio element.
            </video>
            <div>{data.caption}</div>
          </div>
        </div>
      ) : (
        <div className="txtdiv ">
          <div
            className="customWidth"
            uk-tooltip={`title: ${formatChatHoverDateTime(
              data.timestamp
            )}; pos: ${getToolTipPosition(data.isSent)}`}
          >
            <video controls>
              <source
                src={chatBaseUrl + "/" + data?.filePath.replace("assets/", "")}
                type="audio/ogg"
              />
              Your browser does not support the audio element.
            </video>
            <div>{data.caption}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatVideo;
