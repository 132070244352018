import { ChevronLeft, Close, Image } from "@carbon/icons-react";
import React from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
// import * as yup from "yup"; // You'll need to install and import 'yup'
import { apiGet, apiPost, apiPut } from "../../Utils/apiServices";
import { useAuth } from "../../Context/AuthProvider";
import { debounce } from "lodash";
import { useEffect } from "react";
// import { chatBaseUrl } from "../../Constant";
import { toast } from "react-toastify";
import { useWebSocket } from "../../socket/WebSocketManager";
import contactImportFile from "../../assets/file/ImportContacts.xlsx";
import { decryptedValue } from "../../Utils/RouteHashConversion";
const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;
const EditBroadcast = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const params = useParams();
  const { auth } = useAuth();
  const { socket } = useWebSocket();
  const navigate = useNavigate();
  const [audienceSelection, setAudienceSelection] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [contacts, setContacts] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [errorEnable, setErrorEnable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [contactDropdownLoader, setContactDropdownLoader] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [templateId, setTemplateId] = useState();
  const [templateDetails, setTemplateDetails] = useState({
    header: {},
    body: {},
    footer: {},
    buttons: {},
    details: {},
    filterButtons: [],
  });
  const [importError, setImportError] = useState("");
  const [broadcastCost, setBroadcastCost] = useState({
    contact: "",
    segment: "",
    import: "",
  });
  const [uploadedImportFileId, setUploadedImportFileId] = useState("");
  const [imageExist, setImageExist] = useState(false);
  const [videoExist, setVideoExist] = useState(false);
  const [displayImage, setDisplayImage] = useState();
  const [sendUrl, setSendUrl] = useState("");
  const [selectedContact, setSelectedContact] = useState([]);
  const [selectedSegmentContact, setSelectedSegmentContact] = useState([]);
  const [contactValue, setContactValue] = useState("");
  const [submittedData, setSubmittedData] = useState({
    broadcast: "",
    description: "",
    category: "",
    language: "",
    segmentId: "",
    startDate: "",
    startTime: "",
    templateName: "",
    file: null,
  });
  const [broadcastEditData, setBroadcastEditData] = useState({});
  const [segments, setSegments] = useState();
  const [apiData, setApiData] = useState({});
  // const schema = yup.object().shape({
  //   broadcast: yup.string().required("Broadcast name is required"),
  //   description: yup.string().required("Description is required"),
  //   category: yup.string().required("Broadcast categry is required"),
  //   language: yup.string().required("Broadcast langugae is required"),
  //   startDate: yup.string().required("Date is required"),
  //   startTime: yup.string().required("Time is required"),
  //   segmentId:
  //     audienceSelection === "segments" &&
  //     yup.string().required("Segment is required"),
  //   template: yup.string().required("Template is required"),
  //   // media:
  //   //   templateDetails?.header.format === "IMAGE" &&
  //   //   yup.string().required("Media is required"),
  //   // headerParameter: yup
  //   //   .array()
  //   //   .of(yup.string().required("Header parameter is required")),
  //   buttonParameter:
  //     // templateDetails?.buttons.map((val)=>{
  //     //   val.example
  //     // }) &&
  //     templateDetails?.buttons?.buttons !== undefined &&
  //     templateDetails?.buttons.buttons.map(function (o) {
  //       return o.example;
  //     }).length > 0 &&
  //     yup.array().of(yup.string().required("Button parameter is required")),
  //   // parameter:
  //   //   templateDetails?.body?.example?.body_text[0].length > 0 &&
  //   //   yup.array().of(yup.string().required("Body parameter is required")),
  // });

  // const hasExampleInButtons = () => {
  //   return templateDetails?.buttons.buttons.some(
  //     (button) => "example" in button
  //   );
  // };
  // const {
  //   control,
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm({
  //   resolver: async (values) => {
  //     try {
  //       const result = await schema.validate(values, {
  //         abortEarly: false,
  //       });
  //       return {
  //         values: result,
  //         errors: {},
  //       };
  //     } catch (validationErrors) {
  //       const errors = validationErrors.inner.reduce((acc, err) => {
  //         acc[err.path] = err.message;
  //         return acc;
  //       }, {});
  //       return {
  //         values: {},
  //         errors,
  //       };
  //     }
  //   },
  // });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const broadcastCategory = ["Marketing", "Utility"];
  const broadcastLanguage = ["English", "Urdu", "Arabic"];

  function getFileUrl(filePath) {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const data = {
        fileId: filePath,
      };
      const message = { type: "get-file-url", data: data };
      socket.send(JSON.stringify(message));
    }
  }

  useEffect(() => {
    if (filePath !== "") {
      getFileUrl(filePath);
    }
  }, [filePath]);

  useEffect(() => {
    if (socketResponse !== undefined) {
      if (socketResponse.type == "FILE_URL") {
        setSendUrl(chatBaseUrl + socketResponse?.data.filePath);
      }
    }
  }, [socketResponse]);

  const handleFile = (uploadedFile) => {
    var file = uploadedFile.target.files[0];
    setDisplayImage(URL.createObjectURL(file));
    var formData = new FormData();
    formData.append("file", file);
    formData.append("agentPublicId", decryptedValue(auth.agentId));
    formData.append("companyId", decryptedValue(auth.companyId));

    apiPost(
      `/chat/upload-file`,
      onSuccessFileUpload,
      onFailureFileUpload,
      formData
    );
  };
  const onSuccessFileUpload = (response) => {
    if (response.status == 1) {
      setFilePath(response.message);
    }
  };
  const onFailureFileUpload = (error) => {
    console.log(error);
  };
  const handleSelectedTemplate = (templatePublicId) => {
    let selectedTemplate = templates.filter((val) => {
      return val.id === templatePublicId;
    });
    setSubmittedData({
      ...submittedData,
      templateName: selectedTemplate[0].name,
    });
    if (selectedTemplate.length > 0) {
      let id = selectedTemplate[0].id;
      setTemplateId(selectedTemplate[0].id);
    }
    getTemplateDetial(templatePublicId);
  };

  const getTemplateDetial = (templateId) => {
    apiGet(
      `/template/details/${templateId}`,
      onSuccessTemplateDetail,
      onFailureTemplateDetail
    );
  };
  const onSuccessTemplateDetail = (response) => {
    // for header
    let headerIndex = response?.data?.components
      .map(function (o) {
        return o.type;
      })
      .indexOf("HEADER");

    // for body
    let bodyIndex = response?.data?.components
      .map(function (o) {
        return o.type;
      })
      .indexOf("BODY");

    // for footer
    let footerIndex = response?.data?.components
      .map(function (o) {
        return o.type;
      })
      .indexOf("FOOTER");

    // for buttons
    let buttonIndex = response?.data?.components
      .map(function (o) {
        return o.type;
      })
      .indexOf("BUTTONS");
    let filterOnlyExamplButtons = [];
    if (buttonIndex > -1) {
      response?.data?.components[buttonIndex].buttons.map((val) => {
        if (val.example !== undefined) {
          filterOnlyExamplButtons.push(val);
        }
      });
    }

    setTemplateDetails({
      header: headerIndex > -1 ? response?.data?.components[headerIndex] : {},
      body: bodyIndex > -1 ? response?.data?.components[bodyIndex] : {},
      footer: footerIndex > -1 ? response?.data?.components[footerIndex] : {},
      buttons: buttonIndex > -1 ? response?.data?.components[buttonIndex] : {},
      filterButtons: filterOnlyExamplButtons,
      details: response?.data,
    });
    if (headerIndex > -1) {
      if (response?.data?.components[headerIndex].format === "IMAGE") {
        setImageExist(true);
      } else {
        setImageExist(false);
      }
      if (response?.data?.components[headerIndex].format === "VIDEO") {
        setVideoExist(true);
      } else {
        setVideoExist(false);
      }
    } else {
      setImageExist(false);
      setVideoExist(false);
    }
  };
  const onFailureTemplateDetail = (error) => {
    console.log(error);
  };

  useEffect(() => {
    // for header

    let body = [];
    let button = [];
    broadcastEditData?.sendTemplateRequest?.template.components.map(
      (val, index) => {
        if (val.type === "body") {
          // val.parameters.map((item,ind)=>{
          //   setValue(`parameter${ind+1}`,item.text)
          // })
          body.push(val);
        }
        if (val.type === "button") {
          // setValue(`buttonParameter${index+1}`,val.parameters[0].payload)

          // .map((item,ind)=>{
          //   setValue(`buttonParameter${ind+1}`,item.payload
          //   )
          // })
          button.push(val);
        }
      }
    );

    body.map((val, index) => {
      val.parameters.map((item, ind) => {
        setValue(`parameter${ind + 1}`, item.text);
      });
    });
    button.map((val, index) => {
      setValue(`buttonParameter${index + 1}`, val.parameters[0].payload);
    });
  }, [templateDetails]);

  const getTemplates = () => {
    apiGet(`/template/`, onSuccessTemplate, onFailureTemplate);
  };
  const onSuccessTemplate = (response) => {
    setTemplates(response?.data);
  };
  const onFailureTemplate = (error) => {
    console.log(error);
  };
  const getBroadcastDetail = () => {
    apiGet(
      `/broadcast/get/${params.publicId}`,
      onSuccessBroadcastDetail,
      onFailureBroadcastDetail
    );
  };
  const onSuccessBroadcastDetail = (response) => {
    getTemplateDetial(response.data.templateId);
    setBroadcastEditData(response.data);
    // setSubmittedData({
    //   ...submittedData,
    //   broadcast: response.data.name,
    //   description: response.data.description,
    //   category: response.data.category,
    //   language: response.data.language,
    //   startDate: response.data.startDate,
    //   startTime: response.data.startTime,
    //   templateName: response.data.sendTemplateRequest.template.name,
    // });
    // setTemplateId(response.data.templateId);

    if (
      response.data.contactNumber.length > 0 &&
      response.data.segmentId === ""
    ) {
      setAudienceSelection("contacts");
      setSelectedContact(response.data.contactList);
    }
    if (response.data.segmentId !== "") {
      getSegmentDetailsWithNumber(response.data.segmentId);
      getSegment();
      setSubmittedData({
        ...submittedData,
        segmentId: response.data.segmentId,
      });
      setAudienceSelection("segments");
    }
  };
  const onFailureBroadcastDetail = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (Object.keys(broadcastEditData).length > 0) {
      setSubmittedData({
        ...submittedData,
        broadcast: broadcastEditData.name,
        description: broadcastEditData.description,
        category: broadcastEditData.category,
        language: broadcastEditData.language,
        startDate: broadcastEditData.startDate,
        startTime: broadcastEditData.startTime,
        templateName: broadcastEditData.sendTemplateRequest.template.name,
      });
      setTemplateId(broadcastEditData.templateId);
    }
  }, [broadcastEditData]);

  useEffect(() => {
    getBroadcastDetail();
    getTemplates();
  }, []);

  // for contacts
  const firstThreeNumbers = selectedContact.slice(0, 3);
  const remainingNumbersCount = selectedContact.length - 3;
  const formattedNumbers = firstThreeNumbers
    .map((obj) => obj.mobileNumber)
    .join(", ");

  // for segments
  const firstThreeNumbersSegments = selectedSegmentContact.slice(0, 3);
  const remainingNumbersCountSegments = selectedSegmentContact.length - 3;
  const formattedNumbersSegments = firstThreeNumbersSegments.join(", ");

  const editBroadcast = (data) => {
    setErrorEnable(true);

    let bodyParameters = [];
    templateDetails?.body?.example !== undefined &&
      templateDetails?.body?.example.body_text[0].map((val, index) => {
        bodyParameters.push({
          type: "text",
          text: data[`parameter${index + 1}`],
        });
      });

    var buttonParameters = [];
    templateDetails?.filterButtons.map((val, index) => {
      buttonParameters.push({
        type: "button",
        sub_type: val.type,
        index: index,
        parameters: [
          {
            type: "payload",
            payload: data[`buttonParameter${index + 1}`],
          },
        ],
      });
    });

    var headerParameters = [];
    if (
      templateDetails?.header.example !== undefined &&
      templateDetails?.header.format == "TEXT"
    ) {
      templateDetails?.header?.example.header_text.map((val, index) => {
        headerParameters.push({
          type: "text",
          text: data[`headerParameter${index + 1}`],
        });
      });
    } else if (templateDetails?.header.format == "IMAGE") {
      headerParameters.push({
        type: "image",
        image: {
          link: sendUrl,
        },
      });
    }

    const convertedData = {};
    buttonParameters.forEach((item) => {
      const key = `item_${item.index}`;
      convertedData[key] = item;
    });

    let componentsData = [];
    if (bodyParameters.length !== 0) {
      componentsData.push({
        type: "body",
        parameters: bodyParameters,
      });
    }
    if (headerParameters.length !== 0) {
      componentsData.push({
        type: "header",
        parameters: headerParameters,
      });
    }
    if (buttonParameters.length !== 0) {
      componentsData.push(...buttonParameters);
    }

    let contactArray = [];
    selectedContact.map((val) => {
      contactArray.push(parseInt(val.mobileNumber));
    });
    let newdata;
    if (componentsData.length > 0) {
      newdata = {
        name: submittedData.broadcast,
        description: submittedData.description,
        category: submittedData.category,
        language: submittedData.language,
        contactNumber: contactArray,
        templateId: templateId,
        fileId: audienceSelection === "import" ? uploadedImportFileId : "",
        segmentId: submittedData.segmentId,
        startTime: submittedData.startTime,
        startDate: submittedData.startDate,
        sendTemplateRequest: {
          template: {
            name: templateDetails?.details?.name,
            language: {
              code: templateDetails?.details?.language,
            },
            components: componentsData,
          },
          category: templateDetails?.details?.category,
        },
        companyId: decryptedValue(auth.companyId),
      };
    } else {
      newdata = {
        name: submittedData.broadcast,
        description: submittedData.description,
        category: submittedData.category,
        language: submittedData.language,
        contactNumber: contactArray,
        segmentId: submittedData.segmentId,
        templateId: templateId,
        fileId: audienceSelection === "import" ? uploadedImportFileId : "",
        startTime: submittedData.startTime,
        startDate: submittedData.startDate,
        sendTemplateRequest: {
          template: {
            name: templateDetails?.details?.name,
            language: {
              code: templateDetails?.details?.language,
            },
          },
          category: templateDetails?.details?.category,
        },
        companyId: decryptedValue(auth.companyId),
      };
    }
    setApiData(newdata);
    setLoader(true);
    if (
      submittedData.broadcast !== "" &&
      submittedData.description !== "" &&
      submittedData.category !== "" &&
      submittedData.language !== "" &&
      submittedData.startDate !== "" &&
      submittedData.startTime !== "" &&
      submittedData.templateName !== "" &&
      audienceSelection !== "" &&
      isAgreed !== false &&
      ((audienceSelection === "import" &&
        importError === "" &&
        uploadedImportFileId !== "") ||
        (audienceSelection === "contacts" && selectedContact.length > 0) ||
        (audienceSelection === "segments" && submittedData.segmentId !== ""))
    ) {
      // if (templateDetails?.header.format === "IMAGE") {
      //   handleFile(data.file, newdata);
      // }
      if (imageExist == false || videoExist == false) {
        apiPut(
          `/broadcast/edit/${broadcastEditData?.publicId}`,
          onSuccessEditBroadcast,
          onFailureEditBroadcast,
          newdata
        );
      } else {
        let index = newdata.sendTemplateRequest.template.components
          .map(function (o) {
            return o.type;
          })
          .indexOf("header");

        newdata.sendTemplateRequest.template.components[
          index
        ].parameters[0].image["link"] = sendUrl;
        apiPut(
          `/broadcast/edit/${broadcastEditData?.publicId}`,
          onSuccessEditBroadcast,
          onFailureEditBroadcast,
          newdata
        );
      }
    } else {
      setLoader(false);
    }
  };
  // const sendImageBroadcast = (url) => {
  //   let index = apiData.sendTemplateRequest.template.components
  //     .map(function (o) {
  //       return o.type;
  //     })
  //     .indexOf("header");

  //   apiData.sendTemplateRequest.template.components[index].parameters[0].image[
  //     "link"
  //   ] = url;
  //   apiPost(
  //     `/broadcast/create`,
  //     onSuccessEditBroadcast,
  //     onFailureEditBroadcast,
  //     apiData
  //   );
  // };
  const onSuccessEditBroadcast = (response) => {
    if (response.data.status === 200) {
      toast.success("Broadcast updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
      });
      navigate("/broadcast", { replace: true });
      setLoader(false);
    }
  };
  const onFailureEditBroadcast = (error) => {
    console.log(error);
  };
  const getSegment = () => {
    apiGet(
      `/segment/`,
      onSuccessSegment,
      onFailureSegment
    );
  };
  const onSuccessSegment = (response) => {
    setSegments(response?.data);
  };
  const onFailureSegment = (error) => {
    console.log(error);
  };

  const onChange = (e) => {
    setContactDropdownLoader(false);
    var searchedValue = e.target.value;
    setContactValue(searchedValue);

    const data = {
      dateStart: null,
      dateEnd: null,
      country: "",
      city: "",
      gender: "",
      contactTags: [],
      status: "active",
      searchQuery: searchedValue,
    };
    setContactDropdownLoader(true);

    setShowDropdown(!!searchedValue);
    if (searchedValue !== "") {
      apiPost(
        `/contact/filter
        `,
        onSuccessSearch,
        onFailureSearch,
        data
      );
    }
    // else {
    //   getContacts(true, 0);
    // }
  };

  const onSuccessSearch = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        let removeAlreadySelectedContact;
        if (selectedContact.length > 0) {
          removeAlreadySelectedContact = response?.data.filter(
            (ar) => !selectedContact.find((rm) => rm.publicId === ar.publicId)
          );
          setContacts(removeAlreadySelectedContact);
        } else {
          setContacts(response?.data);
        }
        setContactDropdownLoader(false);
      }, 1000);
    }
  };
  // useEffect(() => {
  // }, [contacts]);
  const onFailureSearch = (error) => {
    console.log(error);
  };

  const debouncedOnChange = debounce(onChange, 1000);

  const handleImportFile = (e) => {
    var file = e.target.files[0];

    uploadImport(file);

    // setFile(file)
    // setSubmittedData({
    //   ...submittedData,
    //   file: file,
    // });
  };

  const uploadImport = (file) => {
    // setLoader(true);
    var formData = new FormData();
    formData.append("file", file);
    apiPost(
      `/broadcast/upload`,
      // `/contact/upload`,
      onSuccessUploadImport,
      onFailureUploadImport,
      formData
    );
  };
  const onSuccessUploadImport = (response) => {
    if (response?.data !== null) {
      setImportError("");
      setUploadedImportFileId(response?.data.fielId);
    }
    // else {
    //   setImportError("error");
    // }
  };
  const onFailureUploadImport = (error) => {
    console.log(error);
    if (error.response.status === 400) {
      setImportError(error.response?.data.message);
    }
  };

  // var options = [{ name: "abc" }];

  const handleSelectedContacts = (publicId) => {
    var getUserSelectedContacts = contacts?.filter((val) => {
      setContactValue("");
      setShowDropdown(false);
      return val.publicId === publicId;
    });

    setSelectedContact((prevMessages) => [
      ...prevMessages,
      ...getUserSelectedContacts,
    ]);
    var listFilteredForDisplay = contacts?.filter((val) => {
      return val.publicId !== publicId;
    });
    setContacts(listFilteredForDisplay);
  };

  const removeSelectedContact = (publicId) => {
    var removedContactItem = selectedContact?.filter((val) => {
      return val.publicId === publicId;
    });

    setContacts((prevMessages) => [...removedContactItem, ...prevMessages]);
    var updateListAfterReomveContact = selectedContact?.filter((val) => {
      return val.publicId !== publicId;
    });

    setSelectedContact(updateListAfterReomveContact);
  };

  const getBroadcastContactCost = (numbersArray) => {
    apiPost(`/broadcast/cost`, onSuccessCost, onFailureCost, {
      numbers: numbersArray,
      category: submittedData.category,
    });
  };
  const getBroadcastSegmentCost = () => {
    apiPost(`/broadcast/cost`, onSuccessCost, onFailureCost, {
      segment: submittedData.segmentId,
      category: submittedData.category,
    });
  };
  const onSuccessCost = (response) => {
    setBroadcastCost({
      ...broadcastCost,
      contact: response?.data,
    });
  };
  const onFailureCost = (error) => {
    console.log(error);
  };
  const getSegmentDetailsWithNumber = (publicId) => {
    "";
    apiGet(
      `/segment/details/${publicId}`,
      onSuccessSegmentDetails,
      onFailureSegmentDetails
    );
  };
  const onSuccessSegmentDetails = (response) => {
    setSelectedSegmentContact(response?.data.numbers);
  };
  const onFailureSegmentDetails = (error) => {
    console.log(error);
  };

  const convertSelectedContactIntoArray = () => {
    let contactArray = [];
    selectedContact.map((val) => {
      contactArray.push(parseInt(val.mobileNumber));
    });
    return contactArray;
  };
  useEffect(() => {
    if (
      audienceSelection === "contacts" &&
      selectedContact.length > 0 &&
      submittedData.category !== ""
    ) {
      getBroadcastContactCost(convertSelectedContactIntoArray());
    }
  }, [selectedContact]);
  useEffect(() => {
    if (
      audienceSelection === "contacts" &&
      selectedContact.length > 0 &&
      submittedData.category !== ""
    ) {
      getBroadcastContactCost(convertSelectedContactIntoArray());
    }
  }, [submittedData.category]);
  useEffect(() => {
    if (
      audienceSelection === "segments" &&
      selectedSegmentContact.length > 0 &&
      submittedData.category !== ""
    ) {
      getBroadcastSegmentCost(selectedSegmentContact);
    }
  }, [selectedSegmentContact]);
  useEffect(() => {
    if (
      audienceSelection === "segments" &&
      selectedSegmentContact.length > 0 &&
      submittedData.category !== ""
    ) {
      getBroadcastSegmentCost(selectedSegmentContact);
    }
  }, [submittedData.category]);

  return (
    <>
      <div className="addTeamWrp createBroadcastWrp">
        <div className="uk-container">
          <div className="backBtnWrp">
            <div className="backBtn uk-margin-remove-bottom">
              <Link to="/broadcast">
                <ChevronLeft /> Back to all broadcasts
              </Link>
            </div>
            {/* <div className="broadcastDltBtn">
              <button type="button">Delete broadcast</button>
            </div> */}
          </div>
          <div className="addTeamHeading">
            <h3>Edit broadcast</h3>
            <p>Reach your audience in real-time with broadcast.</p>
          </div>
          <div className="createBroadcastContent">
            <div className="uk-grid" uk-grid="">
              <div className="uk-width-1-2">
                <form onSubmit={handleSubmit(editBroadcast)}>
                  <div className="addTeamBox">
                    <div className="boxHeading">
                      <h3>User information</h3>
                    </div>
                    <div className="formwrp">
                      <div className="uk-grid" uk-grid="">
                        <div className="uk-width-1-1">
                          <div className="formInput">
                            <label htmlFor="">Broadcast name</label>

                            <input
                              type="text"
                              className="uk-input"
                              placeholder="Please enter broadcast name"
                              value={submittedData.broadcast}
                              onChange={(e) => {
                                e.target.value.length <= 600 &&
                                  setSubmittedData({
                                    ...submittedData,
                                    broadcast: e.target.value,
                                  });
                              }}
                              style={{ paddingRight: "100px", width: "100%" }}
                            />
                            <div className="txtCounter" style={{ top: "34px" }}>
                              <span>{submittedData.broadcast.length}</span>
                              <span>/</span>
                              <span>600</span>
                            </div>
                            {errorEnable && submittedData.broadcast == "" && (
                              <div className="formErrors">
                                Broadcast name is required
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="uk-width-1-1">
                          <div className="formInput">
                            <label htmlFor="">
                              Broadcast description
                              <span>(optional)</span>
                            </label>

                            <textarea
                              name=""
                              placeholder=""
                              className="uk-textarea"
                              value={submittedData.description}
                              onChange={(e) => {
                                setSubmittedData({
                                  ...submittedData,
                                  description: e.target.value,
                                });
                              }}
                              style={{ width: "100%", paddingRight: "80px" }}
                            ></textarea>
                            <div className="txtCounter">
                              <span>{submittedData.description.length}</span>
                              <span>/</span>
                              <span>1024</span>
                            </div>
                            {errorEnable && submittedData.description == "" && (
                              <div className="formErrors">
                                Broadcast description is required
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="uk-width-1-2">
                          <div className="formInput">
                            <label htmlFor="">Broadcast category</label>
                            <select
                              type="text"
                              onChange={(e) => {
                                setSubmittedData({
                                  ...submittedData,
                                  category: e.target.value,
                                });
                              }}
                            >
                              <option value="" selected disabled>
                                Please select category
                              </option>
                              {broadcastCategory.map((val, index) => {
                                return (
                                  <option
                                    selected={val === submittedData.category}
                                    value={val}
                                    key={index}
                                  >
                                    {val}
                                  </option>
                                );
                              })}
                            </select>
                            {errorEnable && submittedData.category == "" && (
                              <div className="formErrors">
                                Broadcast category is required
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="uk-width-1-2">
                          <div className="formInput">
                            <label htmlFor="">Language</label>
                            <select
                              type="text"
                              placeholder="Please select language"
                              onChange={(e) => {
                                setSubmittedData({
                                  ...submittedData,
                                  language: e.target.value,
                                });
                              }}
                            >
                              <option value="" selected disabled>
                                Please select language
                              </option>
                              {broadcastLanguage.map((val, index) => {
                                return (
                                  <option
                                    selected={val === submittedData.language}
                                    value={val}
                                    key={index}
                                  >
                                    {val}
                                  </option>
                                );
                              })}
                            </select>
                            {errorEnable && submittedData.language === "" && (
                              <div className="formErrors">
                                Broadcast language is required
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="addTeamBox">
                    <div className="boxHeading">
                      <h3>Audience</h3>
                      <p>You want to broadcast to recipients from?</p>
                    </div>
                    <div className="formwrp">
                      <div className="uk-grid" uk-grid="">
                        <div className="uk-width-1-3">
                          <div className="formInput">
                            <input
                              type="radio"
                              className="broadcastRadio"
                              checked={audienceSelection === "contacts"}
                              onChange={(e) => {
                                setAudienceSelection("contacts");
                              }}
                              name="audience"
                            />
                            <span>Contacts</span>
                          </div>
                        </div>
                        <div className="uk-width-1-3">
                          <div className="formInput">
                            <input
                              type="radio"
                              className="broadcastRadio"
                              checked={audienceSelection === "import"}
                              onChange={(e) => {
                                setAudienceSelection("import");
                              }}
                              name="audience"
                            />
                            <span>Import</span>
                          </div>
                        </div>
                        <div className="uk-width-1-3">
                          <div className="formInput">
                            <input
                              type="radio"
                              className="broadcastRadio"
                              checked={audienceSelection === "segments"}
                              onChange={(e) => {
                                setAudienceSelection("segments");
                              }}
                              name="audience"
                            />
                            <span>Segments</span>
                          </div>
                        </div>
                        {errorEnable && audienceSelection === "" && (
                          <p className="formErrors">
                            {"Please select audience"}
                          </p>
                        )}
                        <div className="uk-width-1-1">
                          {audienceSelection === "contacts" && (
                            // <div className="customChipBox">
                            <div className="formInput">
                              <label htmlFor="">Add Recipients</label>
                              {/* <Multiselect
                                options={options}
                                displayValue="name"
                              /> */}
                              <div className="broadcastContacts">
                                {selectedContact.length > 0 && (
                                  <ul>
                                    {selectedContact?.map((val, index) => {
                                      return (
                                        <li key={index}>
                                          <span key={index}>
                                            {val.mobileNumber}
                                            <button
                                              type="button"
                                              onClick={() =>
                                                removeSelectedContact(
                                                  val.publicId
                                                )
                                              }
                                            >
                                              <Close />
                                            </button>
                                          </span>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                )}
                              </div>
                              <input
                                type="text"
                                placeholder="Type to add contact"
                                onChange={onChange}
                                value={contactValue}
                              />

                              {showDropdown && (
                                <div className="searchDropdown">
                                  <ul className="p0-m0">
                                    {contacts?.length > 0 ? (
                                      contacts?.map((val, index) => (
                                        <li
                                          key={index}
                                          value={val.publicId}
                                          onClick={(e) =>
                                            handleSelectedContacts(val.publicId)
                                          }
                                        >
                                          {val.mobileNumber}
                                        </li>
                                      ))
                                    ) : contactDropdownLoader ? (
                                      <div>
                                        <div uk-spinner=""></div> Searching ...
                                      </div>
                                    ) : (
                                      <li>
                                        <li>No Data Found</li>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              )}
                              {errorEnable && selectedContact.length === 0 && (
                                <div className="formErrors">
                                  Please add contact
                                </div>
                              )}
                            </div>
                          )}
                          {audienceSelection === "import" && (
                            <>
                              <div
                                className="formInput"
                                style={{ width: "100%" }}
                              >
                                <div
                                  uk-form-custom="target: true"
                                  style={{ width: "100%" }}
                                >
                                  <label htmlFor="">Import</label>
                                  <input
                                    type="file"
                                    aria-label="Custom controls"
                                    style={{ width: "100%" }}
                                    onChange={(e) => handleImportFile(e)}
                                  />
                                  {/* {errorEnable && importError !== "" && (
                                  <div className="formErrors">
                                    {importError}
                                  </div>
                                )} */}
                                  <input
                                    className="uk-input uk-form-width-medium"
                                    type="text"
                                    placeholder="Select file"
                                    aria-label="Custom controls"
                                    disabled
                                    style={{ width: "100%" }}
                                  />
                                  {errorEnable &&
                                    uploadedImportFileId === "" && (
                                      // importError === "" &&
                                      <div className="formErrors">
                                        Please upload import file
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="downloadTxt uk-margin-top">
                                <p>
                                  You can download our sample{" "}
                                  <a
                                    href={contactImportFile}
                                    download="import-contact"
                                  >
                                    CSV import file
                                  </a>{" "}
                                  and replace the sample contacts with your own
                                  data.
                                </p>
                              </div>
                            </>
                          )}

                          {audienceSelection === "segments" && (
                            <div className="formInput">
                              <label htmlFor="">Segment</label>

                              <select
                                type="text"
                                onClick={() => {
                                  getSegment();
                                }}
                                onChange={(e) => {
                                  setSubmittedData({
                                    ...submittedData,
                                    segmentId: e.target.value,
                                  });
                                  getSegmentDetailsWithNumber(e.target.value);
                                }}
                              >
                                {segments?.length === 0 ? (
                                  <>
                                    <option
                                      value=""
                                      selected
                                      disabled
                                      placeholder="Please select segment"
                                    >
                                      Select a segment
                                    </option>
                                    <option value="">loading ...</option>
                                  </>
                                ) : (
                                  <option value="" selected disabled>
                                    Select a segement
                                  </option>
                                )}
                                {segments?.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.publicId}
                                      disabled={val.audience === 0 && true}
                                      selected={
                                        val.publicId === submittedData.segmentId
                                      }
                                    >
                                      {val.segmentName}
                                    </option>
                                  );
                                })}
                              </select>
                              {errorEnable &&
                                submittedData.segmentId === "" && (
                                  <div className="formErrors">
                                    segment is required
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="addTeamBox">
                    <div className="boxHeading">
                      <h3>Duration</h3>
                      <p>Campaign starting from?</p>
                    </div>
                    <div className="formwrp">
                      <div className="uk-grid" uk-grid="">
                        <div className="uk-width-1-2">
                          <div className="formInput">
                            <label htmlFor="">Start date</label>
                            <input
                              type="date"
                              onChange={(e) => {
                                setSubmittedData({
                                  ...submittedData,
                                  startDate: e.target.value,
                                });
                              }}
                              value={submittedData.startDate}
                            />
                            {errorEnable && submittedData.startDate === "" && (
                              <div className="formErrors">
                                start date is required
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="uk-width-1-2">
                          <div className="formInput">
                            <label htmlFor="">Start time</label>
                            <input
                              className="dropdownBtn"
                              id="appt-time"
                              value={submittedData.startTime}
                              onChange={(e) => {
                                setSubmittedData({
                                  ...submittedData,
                                  startTime: e.target.value,
                                });
                              }}
                              type="time"
                              name="appt-time"
                            />
                            {errorEnable && submittedData.startTime === "" && (
                              <div className="formErrors">
                                start time is required
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="addTeamBox">
                    <div className="boxHeading">
                      <h3>Select Content</h3>
                      <span>
                        Connect with the customer using pre-defined messages
                      </span>
                    </div>
                    <div className="formwrp">
                      <div className="uk-grid uk-grid-small" uk-grid="">
                        <div className="uk-width-1-1">
                          <div className="reinitiateModalInput">
                            <label htmlFor="">Template message</label>
                            <select
                              className="uk-select"
                              onChange={(e) => {
                                setFilePath("");
                                handleSelectedTemplate(e.target.value);
                                // setSubmittedData({
                                //   ...submittedData,
                                //   templateName: e.target.value,
                                // });
                              }}
                            >
                              <option value="" selected disabled>
                                Please select template
                              </option>
                              {templates.map((val, index) => {
                                return (
                                  <option
                                    selected={
                                      val.name === submittedData.templateName
                                    }
                                    key={index}
                                    value={val.id}
                                  >
                                    {val.name}
                                  </option>
                                );
                              })}
                            </select>
                            {errorEnable &&
                              submittedData.templateName === "" && (
                                <div className="formErrors">
                                  template is required
                                </div>
                              )}
                          </div>
                        </div>

                        {/* ******************* start header work  ******************* */}
                        {templateDetails?.header.example !== undefined &&
                          templateDetails?.header.format === "TEXT" &&
                          templateDetails?.header.example.header_text.map(
                            (val, index) => {
                              return (
                                <>
                                  {index === 0 && (
                                    <div className="uk-width-1-1">
                                      <label>Header</label>
                                    </div>
                                  )}

                                  <div className="uk-width-1-1" key={index}>
                                    <div className="reinitiateModalInput">
                                      <label htmlFor="">
                                        Header Parameter{index + 1}
                                      </label>
                                      <input
                                        type="text"
                                        placeholder={`Enter header parameter ${
                                          index + 1
                                        }`}
                                        className="uk-input"
                                        {...register(
                                          `headerParameter${index + 1}`,
                                          {
                                            required: true,
                                          }
                                        )}
                                      />
                                      {errors[
                                        `headerParameter${index + 1}`
                                      ] && (
                                        <div className="formErrors">
                                          {`header parameter ${
                                            index + 1
                                          } is required.`}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          )}
                        {templateDetails?.header.format === "IMAGE" && (
                          <div className="uk-width-1-1">
                            <div className="uk-width-1-1">
                              <label>Header</label>
                            </div>
                            <div className="js-upload uk-placeholder uk-text-center uk-margin-remove-bottom">
                              <span uk-icon="icon: cloud-upload"></span>
                              <div uk-form-custom="">
                                <input
                                  type="file"
                                  name="media"
                                  id="myfile"
                                  accept="image/*"
                                  // {...register(`file`, {
                                  //   required: true,
                                  // })}
                                  onChange={(e) => handleFile(e)}
                                />
                              </div>
                            </div>
                            {/* {errors[`file`] && (
                              <div className="formErrors">{`Please upload file`}</div>
                            )} */}
                            {imageExist && filePath === "" && (
                              <div className="formErrors">{`Please upload file`}</div>
                            )}
                            {/* <div className="uk-width-1-1 uk-margin-top">
                          <div className="reinitiateModalInput">
                            <label htmlFor="">File Name</label>
                            <input
                              type="text"
                              placeholder={`Enter file name`}
                              className="uk-input"
                            />
                          </div>
                        </div> */}
                          </div>
                        )}
                        {templateDetails?.header.format === "VIDEO" && (
                          <div className="uk-width-1-1">
                            <div className="uk-width-1-1">
                              <label>Header</label>
                            </div>
                            <div className="js-upload uk-placeholder uk-text-center uk-margin-remove-bottom">
                              <span uk-icon="icon: cloud-upload"></span>
                              <div uk-form-custom="">
                                <input
                                  type="file"
                                  name="media"
                                  id="myfile"
                                  accept="video/*"
                                  // {...register(`file`, {
                                  //   required: true,
                                  // })}
                                  onChange={(e) => handleFile(e)}
                                />
                              </div>
                            </div>
                            {videoExist && filePath === "" && (
                              <div className="formErrors">{`Please upload file`}</div>
                            )}
                            {/* <div className="uk-width-1-1 uk-margin-top">
                          <div className="reinitiateModalInput">
                            <label htmlFor="">File Name</label>
                            <input
                              type="text"
                              placeholder={`Enter file name`}
                              className="uk-input"
                            />
                          </div>
                        </div> */}
                          </div>
                        )}

                        {/* ******************* end header work  ******************* */}

                        {/* ******************* start body work  ******************* */}
                        {templateDetails?.body?.example !== undefined &&
                          templateDetails?.body?.example.body_text[0].map(
                            (val, index) => {
                              return (
                                <>
                                  {index === 0 && (
                                    <div className="uk-width-1-1">
                                      <label>Body</label>
                                    </div>
                                  )}
                                  <div className="uk-width-1-1" key={index}>
                                    <div className="reinitiateModalInput">
                                      <label htmlFor="">
                                        Body Parameter {index + 1}
                                      </label>
                                      <input
                                        type="text"
                                        placeholder={`Enter Body parameter ${
                                          index + 1
                                        }`}
                                        className="uk-input"
                                        // name={`parameter ${index+1}`}
                                        // onChange={(e) =>
                                        //   handleParamChangeForQuickMessage(
                                        //     e.target.value,
                                        //     index
                                        //   )
                                        // }
                                        {...register(`parameter${index + 1}`, {
                                          required: true,
                                        })}
                                      />
                                      {errors[`parameter${index + 1}`] && (
                                        <div className="formErrors">{`parameter ${
                                          index + 1
                                        } is required.`}</div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          )}
                        {/* ******************* end body work  ******************* */}

                        {/* ******************* start buttons work  ******************* */}
                        {templateDetails?.filterButtons?.map((val, index) => {
                          return (
                            <>
                              {index === 0 && (
                                <div className="uk-width-1-1">
                                  <label>Buttons</label>
                                </div>
                              )}

                              <div className="uk-width-1-1" key={index}>
                                <div className="reinitiateModalInput">
                                  <label htmlFor="">
                                    Button Parameter {index + 1}
                                  </label>
                                  <input
                                    type="text"
                                    placeholder={`Enter Button parameter ${
                                      index + 1
                                    }`}
                                    className="uk-input"
                                    {...register(
                                      `buttonParameter${index + 1}`,
                                      {
                                        required: true,
                                      }
                                    )}
                                  />
                                  {errors[`buttonParameter${index + 1}`] && (
                                    <div className="formErrors">{`button parameter ${
                                      index + 1
                                    } is required.`}</div>
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        })}
                        {/* ******************* end buttons work  ******************* */}

                        {/* <div className="uk-width-1-1">
                      <div className="btnwrp" style={{ textAlign: "left" }}>
                        <button
                          type="button"
                          className="btn-1"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                        <button
                          // type="button"
                          type="submit"
                          className="btn-2 w-90"
                          // onClick={sendTemplate}
                        >
                          {loader ? (
                            <div uk-spinner="" className="loader"></div>
                          ) : (
                            "Send"
                          )}
                        </button>
                      </div>
                    </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="addTeamBox">
                    <div className="boxHeading">
                      <h3>Undertaking</h3>
                      <span className="uk-margin-small-bottom">
                        Please be aware that selected campaigns can be edited or
                        rescheduled up to 20 minutes prior to the scheduled
                        start time.
                      </span>
                      <span>
                        After this timeframe, no further changes can be made.
                        Pausing the campaign will temporarily halt its delivery,
                        and you can resume it later.
                      </span>
                    </div>
                    <div className="formwrp">
                      <div className="uk-grid" uk-grid="">
                        <div className="uk-width-1-1">
                          <div className="formInput">
                            <input
                              type="checkbox"
                              className="broadcastRadio"
                              checked={isAgreed}
                              onChange={() => {
                                setIsAgreed(!isAgreed);
                              }}
                            />
                            <span>Agreed</span>
                          </div>
                        </div>
                      </div>
                      {errorEnable && !isAgreed && (
                        <div className="formErrors">
                          Agreed undertaking to proceed
                        </div>
                      )}
                    </div>
                    <div className="btnwrp">
                      <button type="button" className="btn-1">
                        Cancel
                      </button>
                      <button type="submit" className="btn-2">
                        {loader ? (
                          <div uk-spinner="" className="loader"></div>
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="uk-width-1-2">
                <div className="broadcastRight">
                  <div className="mainHeading">
                    <h3>Ready to broadcast</h3>
                  </div>
                  <div className="broadcastTxtWrp">
                    <p>Broadcast</p>
                    <span>
                      {submittedData.broadcast === ""
                        ? "-"
                        : submittedData.broadcast}
                    </span>
                  </div>
                  <div className="broadcastTxtWrp">
                    <p>Description</p>
                    <span>
                      {submittedData.description === ""
                        ? "-"
                        : submittedData.description}
                    </span>
                  </div>
                  <div className="broadcastLising">
                    <div className="listingWrp">
                      <div className="left">
                        <span>Catagory</span>
                      </div>
                      <div className="right">
                        <span>
                          {submittedData.category === ""
                            ? "-"
                            : submittedData.category}
                        </span>
                      </div>
                    </div>
                    <div className="listingWrp">
                      <div className="left">
                        <span>Language</span>
                      </div>
                      <div className="right">
                        <span>
                          {submittedData.language === ""
                            ? "-"
                            : submittedData.language}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="broadcastTxtWrp">
                    <p>Recipients</p>
                    {audienceSelection === "contacts" && (
                      <span>{formattedNumbers}</span>
                    )}
                    {audienceSelection === "contacts" &&
                      remainingNumbersCount > 0 && (
                        <span> +{remainingNumbersCount} more</span>
                      )}
                    {audienceSelection === "segments" && (
                      <span>{formattedNumbersSegments}</span>
                    )}
                    {audienceSelection === "segments" &&
                      remainingNumbersCountSegments > 0 && (
                        <span> +{remainingNumbersCountSegments} more</span>
                      )}
                  </div>
                  <div className="broadcastLising">
                    <div className="listingWrp">
                      <div className="left">
                        <span>Total receipts</span>
                      </div>
                      <div className="right">
                        <span>
                          {audienceSelection === "" && "-"}
                          {audienceSelection === "contacts" &&
                            selectedContact.length}
                          {audienceSelection === "segments" &&
                            selectedSegmentContact.length}
                        </span>
                      </div>
                    </div>
                    <div className="listingWrp">
                      <div className="left">
                        <span>Total messages</span>
                      </div>
                      <div className="right">
                        <span>
                          {audienceSelection === "" && "-"}
                          {audienceSelection === "contacts" &&
                            selectedContact.length}
                          {audienceSelection === "segments" &&
                            selectedSegmentContact.length}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="broadcastTxtWrp">
                    <p>Duration</p>
                    <span>
                      {submittedData.startDate}{" "}
                      {submittedData.startDate !== "" &&
                        submittedData.startTime !== "" &&
                        "at"}{" "}
                      {submittedData.startTime}
                    </span>
                  </div>
                  <div className="broadcastTxtWrp">
                    <p>Template message</p>
                    <span>
                      {submittedData.templateName === ""
                        ? "-"
                        : submittedData.templateName}
                    </span>
                  </div>
                  <div className="mainHeading">
                    <h3>Payment summary</h3>
                  </div>
                  <div className="broadcastTxtWrp">
                    <p>Approximate cost</p>
                    <span>
                      {audienceSelection === "" && "-"}
                      {audienceSelection === "contacts" &&
                        (broadcastCost.contact === ""
                          ? "-"
                          : "$" + broadcastCost.contact)}
                      {audienceSelection === "segments" &&
                        (broadcastCost.segment === ""
                          ? "-"
                          : "$" + broadcastCost.segment)}
                    </span>
                  </div>
                  <div className="mainHeading">
                    <h3>Broadcast preview</h3>
                  </div>
                  <div className="mobilePreview">
                    <div className="mobileMsgs">
                      <div className="msgDate">
                        <span>Friday, November 8</span>
                      </div>

                      {(templateDetails?.header?.type === "HEADER" ||
                        templateDetails?.body?.type === "BODY" ||
                        templateDetails?.footer?.type === "FOOTER" ||
                        templateDetails?.buttons?.type === "BUTTONS") && (
                        <>
                          {templateDetails?.header?.type === "HEADER" && (
                            <>
                              {templateDetails?.header.format === "IMAGE" && (
                                <div className="imgBox">
                                  {/* <img src={mobileImg} alt="" /> */}
                                  <img src={displayImage} alt="" />
                                  {/* <Image /> */}
                                </div>
                              )}
                              {templateDetails?.header.format === "TEXT" && (
                                <div className="leftMsg">
                                  <span>{templateDetails?.header?.text}</span>
                                </div>
                              )}
                            </>
                          )}

                          {templateDetails?.body?.type === "BODY" && (
                            <>
                              <div className="leftMsg">
                                <p>{templateDetails?.body?.text}</p>
                              </div>
                            </>
                          )}
                          {templateDetails?.footer?.type === "FOOTER" && (
                            <>
                              <div className="leftMsg">
                                <p>{templateDetails?.footer?.text}</p>
                              </div>
                            </>
                          )}
                          {templateDetails?.buttons?.type === "BUTTONS" && (
                            <>
                              {/* <div className="MsgBox molbileMsgBox reInititatModalBtn"> */}
                              <div className="MsgBox molbileMsgBox">
                                {templateDetails?.buttons?.buttons !==
                                  undefined &&
                                  templateDetails?.buttons?.buttons?.map(
                                    (item, ind) => {
                                      return (
                                        <>
                                          {item.type === "PHONE_NUMBER" && (
                                            <button key={ind}>
                                              {item.phone_number}
                                            </button>
                                          )}
                                          {item.type === "URL" && (
                                            <button key={ind}>
                                              {item.text}
                                            </button>
                                          )}
                                          {item.type === "COPY_CODE" && (
                                            <button key={ind}>
                                              {item.text}
                                            </button>
                                          )}
                                          {item.type === "QUICK_REPLY" && (
                                            <button key={ind}>
                                              {item.text}
                                            </button>
                                          )}
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {/* <div className="videoBox">
                        <video src={chatVideo} controls></video>
                      </div>
                      <div className="leftMsg">
                        <span>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Molestias fugiat sit qui omnis ipsa. Provident!
                        </span>
                      </div>
                      <div className="rightMsg">
                        <span>Lorem ipsum dolor sit amet consectetur.</span>
                      </div>
                      <div className="leftMsg">
                        <span>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Molestias fugiat sit qui omnis ipsa. Provident!
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBroadcast;
