import { Add, TrashCan } from "@carbon/icons-react";
import React from "react";
import { useAuth } from "../../../Context/AuthProvider";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { apiGet, apiPost } from "../../../Utils/apiServices";
import { decryptedValue } from "../../../Utils/RouteHashConversion";

const AddCondition = ({ closeModal, segmentTags, setApiCalledWinter }) => {
  const { auth } = useAuth();
  const [loader, setLoader] = useState(false);
  const [editData, setEditData] = useState();
  const [tags, setTags] = useState([]);
  const [errorEnable, setErrorEnable] = useState(false);
  const [formItems, setFormItems] = useState([]);
  const [conditionError, setConditionError] = useState([]);
  const [tagNameError, setTagNameError] = useState([]);
  const [tagTypeError, setTagTypeError] = useState([]);
  const [segmentCountry, setSegmentCountry] = useState([]);
  const [segmentCity, setSegmentCity] = useState([]);
  const [tagDropDownOptions, setTagDropDownOptions] = useState([
    {
      name: "Gender",
      value: "GENDER",
      disabled: false,
    },
    {
      name: "First Name",
      value: "FIRST_NAME",
      disabled: false,
    },
    {
      name: "Last Name",
      value: "LAST_NAME",
      disabled: false,
    },
    {
      name: "City",
      value: "CITY",
      disabled: false,
    },
    {
      name: "Country",
      value: "COUNTRY",
      disabled: false,
    },
    {
      name: "Status",
      value: "STATUS",
      disabled: false,
    },
    {
      name: "Tag",
      value: "TAG",
      disabled: false,
    },
  ]);
  const category = [
    {
      name: "category1",
      value: "category1",
    },
    {
      name: "category2",
      value: "category2",
    },
  ];
  const isIsntOption = [
    {
      name: "Select condition",
      value: "",
      selected: true,
    },
    {
      name: "is",
      value: "true",
      selected: false,
    },
    {
      name: "is'nt",
      value: "false",
      selected: false,
    },
  ];
  const isIsntOptionTrue = [
    {
      name: "Select condition",
      value: "",
      selected: false,
    },
    {
      name: "is",
      value: "true",
      selected: true,
    },
    {
      name: "is'nt",
      value: "false",
      selected: false,
    },
  ];
  const isIsntOptionFalse = [
    {
      name: "Select condition",
      value: "",
      selected: false,
    },
    {
      name: "is",
      value: "true",
      selected: false,
    },
    {
      name: "is'nt",
      value: "false",
      selected: true,
    },
  ];
  const hasAnyValue = [
    {
      name: "Select condition",
      value: "",
      selected: true,
    },
    {
      name: "has any value",
      // value: "true",
      value: "false",
      selected: false,
    },
    {
      name: "is empty",
      // value: "false",
      value: "true",
      selected: false,
    },
  ];
  const hasAnyValueTrue = [
    {
      name: "Select condition",
      value: "",
      selected: false,
    },
    {
      name: "has any value",
      // value: "true",
      value: "false",
      selected: true,
    },
    {
      name: "is empty",
      // value: "false",
      value: "true",
      selected: false,
    },
  ];
  const hasAnyValueFalse = [
    {
      name: "Select condition",
      value: "",
      selected: false,
    },
    {
      name: "has any value",
      // value: "true",
      value: "false",
      selected: false,
    },
    {
      name: "is empty",
      // value: "false",
      value: "true",
      selected: true,
    },
  ];
  const addNewCondition = () => {
    var newCondition = {
      condition: "",
      tagName: "",
      tagType: "",
      value: "",
      conditionValue: isIsntOption,
      fieldDisplayed: "Yes",
      thirdOptionValues: tags,
    };
    setFormItems([...formItems, newCondition]);
  };
  const removeCondition = (index) => {
    let tagAllConditions = [...formItems];
    tagAllConditions.splice(index, 1);
    setFormItems(tagAllConditions);
  };
  const editSegment = () => {
    setErrorEnable(true);
    setLoader(true);
    let conditionArray = [];
    let tagName = [];
    let tagType = [];
    formItems.map((val) => {
      conditionArray.push(val.condition);
      tagName.push(val.tagName);
      tagType.push(val.tagType);
    });

    const containEmptyString = (element) => element === "";
    let conditionError = conditionArray.filter(containEmptyString);
    setConditionError(conditionError);
    let tagNameError = tagName.filter(containEmptyString);
    setTagNameError(tagNameError);
    let tagTypeError = tagType.filter(containEmptyString);
    setTagTypeError(tagTypeError);

    var submittedData = {
      segmentCategory: segmentTags.segmentCategory,
      companyPublicId: decryptedValue(auth.companyId),
      segmentDescription: segmentTags.segmentDescription,
      segmentName: segmentTags.segmentName,
      segmentTags: formItems,
      publicId: editData.publicId,
    };

    if (
      conditionError.length === 0 &&
      tagNameError.length === 0 &&
      tagTypeError.length === 0
    ) {
      apiPost(
        `/segment/edit`,
        onSuccesSegment,
        onFailureSegment,
        submittedData
      );
    } else {
      setLoader(false);
    }
  };
  const onSuccesSegment = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setLoader(false);
        toast.success("Segment updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
        });
      }, 1500);
      setTimeout(() => {
        closeModal();
        // window.location.reload();
        setApiCalledWinter(true);
      }, 3500);
    }
  };
  const onFailureSegment = (error) => {
    setLoader(false);
  };
  const handleFormChange = (index, name, e) => {
    formItems[index][name] = e.target.value;
  };
  const handleDropDown = (index, e) => {
    var tagAllConditions = [...formItems];
    if (e.target.value === "GENDER") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);

      let newField = {
        condition: "",
        tagName: "",
        tagType: e.target.value,
        value: "",
        condtionName: "GENDER",
        conditionValue: isIsntOption,
        fieldDisplayed: "Yes",
        thirdOptionValues: [
          {
            name: "Select gender",
            value: "",
            selcted: true,
          },
          {
            name: "Male",
            value: "male",
            selcted: false,
          },
          {
            name: "Female",
            value: "female",
            selcted: false,
          },
        ],
      };
      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems(data);
      tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (
              item.tagType === "GENDER" ||
              item.tagType === "FIRST_NAME" ||
              item.tagType === "LAST_NAME" ||
              item.tagType === "STATUS"
            ) {
              tagDropDownOptions[index]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          } else {
            let tagIndex = tagDropDownOptions
              .map(function (o) {
                return o.value;
              })
              .indexOf(previousLastDropdown[0]?.tagType);
            if (tagIndex > -1) {
              tagDropDownOptions[tagIndex]["disabled"] = false;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
        });
      });
    }
    if (e.target.value === "FIRST_NAME") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);
      let newField = {
        condition: "",
        tagName: null,
        tagType: e.target.value,
        value: "",
        condtionName: "FIRST_NAME",
        conditionValue: hasAnyValue,
        fieldDisplayed: "No",
        thirdOptionValues: [],
      };
      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems(data);
      tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (
              item.tagType === "GENDER" ||
              item.tagType === "FIRST_NAME" ||
              item.tagType === "LAST_NAME" ||
              item.tagType === "STATUS"
            ) {
              tagDropDownOptions[index]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
          let tagIndex = tagDropDownOptions
            .map(function (o) {
              return o.value;
            })
            .indexOf(previousLastDropdown[0].tagType);
          if (tagIndex > -1) {
            tagDropDownOptions[tagIndex]["disabled"] = false;
            setTagDropDownOptions(tagDropDownOptions);
          }
        });
      });
    }
    if (e.target.value === "LAST_NAME") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);
      let newField = {
        condition: "",
        tagName: null,
        tagType: e.target.value,
        value: "",
        condtionName: "LAST_NAME",
        conditionValue: hasAnyValue,
        fieldDisplayed: "No",
        thirdOptionValues: [],
      };
      // setFormItems([...tagAllConditions, newField]);
      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems(data);
      // enableDisableIOptions(data);
      let mapped = tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (
              item.tagType === "GENDER" ||
              item.tagType === "FIRST_NAME" ||
              item.tagType === "LAST_NAME" ||
              item.tagType === "STATUS"
            ) {
              tagDropDownOptions[index]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
          let tagIndex = tagDropDownOptions
            .map(function (o) {
              return o.value;
            })
            .indexOf(previousLastDropdown[0].tagType);
          if (tagIndex > -1) {
            tagDropDownOptions[tagIndex]["disabled"] = false;
            setTagDropDownOptions(tagDropDownOptions);
          }
        });
      });
    }
    if (e.target.value === "STATUS") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);
      let newField = {
        condition: "",
        tagName: "",
        tagType: e.target.value,
        value: "",
        condtionName: "STATUS",
        conditionValue: isIsntOption,
        fieldDisplayed: "Yes",
        thirdOptionValues: [
          {
            name: "Select status",
            value: "",
            selcted: true,
          },
          {
            name: "Active",
            value: "true",
            selcted: false,
          },
          {
            name: "Blocked",
            value: "false",
            selcted: false,
          },
        ],
      };
      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems(data);
      // enableDisableIOptions(data);
      let mapped = tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (
              item.tagType === "GENDER" ||
              item.tagType === "FIRST_NAME" ||
              item.tagType === "LAST_NAME" ||
              item.tagType === "STATUS"
            ) {
              tagDropDownOptions[index]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
          let tagIndex = tagDropDownOptions
            .map(function (o) {
              return o.value;
            })
            .indexOf(previousLastDropdown[0].tagType);
          if (tagIndex > -1) {
            tagDropDownOptions[tagIndex]["disabled"] = false;
            setTagDropDownOptions(tagDropDownOptions);
          }
        });
      });
    }
    if (e.target.value === "TAG") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);
      let newField = {
        condition: "",
        tagName: "",
        tagType: e.target.value,
        value: "",
        condtionName: "TAG",
        conditionValue: isIsntOption,
        fieldDisplayed: "Yes",
        thirdOptionValues: tags,
      };

      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems(data);
      // enableDisableIOptions(data);
      let mapped = tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (
              item.tagType === "GENDER" ||
              item.tagType === "FIRST_NAME" ||
              item.tagType === "LAST_NAME" ||
              item.tagType === "STATUS"
            ) {
              tagDropDownOptions[index]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
          let tagIndex = tagDropDownOptions
            .map(function (o) {
              return o.value;
            })
            .indexOf(previousLastDropdown[0].tagType);
          if (tagIndex > -1) {
            tagDropDownOptions[tagIndex]["disabled"] = false;
            setTagDropDownOptions(tagDropDownOptions);
          }
        });
      });
    }
    if (e.target.value === "COUNTRY") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);
      let newField = {
        condition: "",
        tagName: "",
        tagType: e.target.value,
        value: "",
        condtionName: "COUNTRY",
        conditionValue: isIsntOption,
        fieldDisplayed: "Yes",
        thirdOptionValues: segmentCountry,
      };
      // setFormItems([...tagAllConditions, newField]);
      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems(data);
      // enableDisableIOptions(data);
      let mapped = tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (
              item.tagType === "GENDER" ||
              item.tagType === "FIRST_NAME" ||
              item.tagType === "LAST_NAME" ||
              item.tagType === "STATUS"
            ) {
              tagDropDownOptions[index]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
          let tagIndex = tagDropDownOptions
            .map(function (o) {
              return o.value;
            })
            .indexOf(previousLastDropdown[0].tagType);
          if (tagIndex > -1) {
            tagDropDownOptions[tagIndex]["disabled"] = false;
            setTagDropDownOptions(tagDropDownOptions);
          }
        });
      });
    }
    if (e.target.value === "CITY") {
      let previousLastDropdown = tagAllConditions.splice(index, 1);
      setFormItems(previousLastDropdown);
      setTagDropDownOptions(tagDropDownOptions);
      var newField = {
        condition: "",
        tagName: "",
        tagType: e.target.value,
        value: "",
        condtionName: "CITY",
        conditionValue: isIsntOption,
        fieldDisplayed: "Yes",
        thirdOptionValues: segmentCity,
      };
      let data = [
        ...tagAllConditions.slice(0, index),
        newField,
        ...tagAllConditions.slice(index),
      ];
      setFormItems(data);
      // enableDisableIOptions(data);
      let mapped = tagDropDownOptions.map((val, index) => {
        data.map((item, ind) => {
          if (item.tagType === val.value) {
            if (
              item.tagType === "GENDER" ||
              item.tagType === "FIRST_NAME" ||
              item.tagType === "LAST_NAME" ||
              item.tagType === "STATUS"
            ) {
              tagDropDownOptions[index]["disabled"] = true;
              setTagDropDownOptions(tagDropDownOptions);
            }
          }
          let tagIndex = tagDropDownOptions
            .map(function (o) {
              return o.value;
            })
            .indexOf(previousLastDropdown[0]?.tagType);
          if (tagIndex > -1) {
            tagDropDownOptions[tagIndex]["disabled"] = false;
            setTagDropDownOptions(tagDropDownOptions);
          }
        });
      });
    }
  };

  const getTags = () => {
    apiGet(`/contact/tags`, onSuccessTags, onFailureTags);
  };
  const onSuccessTags = (response) => {
    var mapped = response?.data.map((item) => ({
      name: item.tagName,
      value: item.tagName,
      selected: false,
    }));
    var selectDescArray = [
      {
        name: "Select tag tags",
        value: "",
        select: true,
      },
    ];
    var concatinatedArray = selectDescArray.concat(mapped);
    setTags(concatinatedArray);
  };
  const onFailureTags = (error) => {
    console.log(error);
  };
  const getCountry = () => {
    apiGet(
      `/segment/country/${decryptedValue(auth?.companyId)}`,
      onSuccessCountry,
      onFailureCountry
    );
  };
  const onSuccessCountry = (response) => {
    var formattedData = response?.data.map((item) => ({
      name: item,
      value: item,
      selected: false,
    }));
    var selectedOption = [
      {
        name: "Select country",
        value: "",
        selected: true,
      },
    ];
    var data = selectedOption.concat(formattedData);
    setSegmentCountry(data);
  };
  const onFailureCountry = (error) => {
    console.log(error);
  };
  const getCity = () => {
    apiGet(`/segment/city/${decryptedValue(auth?.companyId)}`, onSuccessCity, onFailureCity);
  };
  const onSuccessCity = (response) => {
    var formattedData = response?.data.map((item) => ({
      name: item,
      value: item,
      selected: false,
    }));
    var selectedOption = [
      {
        name: "Select city",
        value: "",
        selected: true,
      },
    ];
    var data = selectedOption.concat(formattedData);
    setSegmentCity(data);
  };
  const onFailureCity = (error) => {
    console.log(error);
  };
  useEffect(() => {
    getTags();
    getCity();
    getCountry();
  }, []);
  useEffect(() => {
    setEditData(segmentTags);
  }, [segmentTags]);

  useEffect(() => {
    tagDropDownOptions.map((val, index) => {
      editData?.segmentTags?.map((item, ind) => {
        if (item.tagType === val.value) {
          if (
            item.tagType === "GENDER" ||
            item.tagType === "FIRST_NAME" ||
            item.tagType === "LAST_NAME" ||
            item.tagType === "STATUS"
          ) {
            tagDropDownOptions[index]["disabled"] = true;
            setTagDropDownOptions(tagDropDownOptions);
          }
        }
      });
    });
    var newData = [];
    editData?.segmentTags?.map((val) => {
      newData.push({
        condition: val.condition,
        tagName: val.tagName,
        tagType: val.tagType,
        value: "",
        condtionName: val.tagType,
        conditionValue:
          val.tagType === "FIRST_NAME" && val.condition === true
            ? hasAnyValueTrue
            : val.tagType === "FIRST_NAME" && val.condition === false
            ? hasAnyValueFalse
            : val.tagType === "GENDER" && val.condition === true
            ? isIsntOptionTrue
            : val.tagType === "GENDER" && val.condition === false
            ? isIsntOptionFalse
            : val.tagType === "LAST_NAME" && val.condition === true
            ? hasAnyValueTrue
            : val.tagType === "LAST_NAME" && val.condition === false
            ? hasAnyValueFalse
            : val.tagType === "CITY" && val.condition === true
            ? isIsntOptionTrue
            : val.tagType === "CITY" && val.condition === false
            ? isIsntOptionFalse
            : val.tagType === "TAG" && val.condition === true
            ? isIsntOptionTrue
            : val.tagType === "TAG" && val.condition === false
            ? isIsntOptionFalse
            : val.tagType === "COUNTRY" && val.condition === true
            ? isIsntOptionTrue
            : val.tagType === "COUNTRY" && val.condition === false
            ? isIsntOptionFalse
            : val.tagType === "STATUS" && val.condition === true
            ? isIsntOptionTrue
            : val.tagType === "STATUS" && val.condition === false
            ? isIsntOptionFalse
            : [],

        fieldDisplayed:
          val.tagType === "FIRST_NAME" || val.tagType === "LAST_NAME"
            ? "NO"
            : "Yes",
        thirdOptionValues:
          val.tagType === "GENDER"
            ? [
                {
                  name: "Select gender",
                  value: "",
                  selcted: true,
                },
                {
                  name: "Male",
                  value: "male",
                  selcted: false,
                },
                {
                  name: "Female",
                  value: "female",
                  selcted: false,
                },
              ]
            : val.tagType === "FIRST_NAME" || val.tagType === "LAST_NAME"
            ? []
            : val.tagType === "STATUS"
            ? [
                {
                  name: "Select status",
                  value: "",
                  selcted: true,
                },
                {
                  name: "Active",
                  value: "true",
                  selcted: false,
                },
                {
                  name: "Blocked",
                  value: "false",
                  selcted: false,
                },
              ]
            : val.tagType === "TAG"
            ? tags
            : val.tagType === "COUNTRY"
            ? segmentCountry
            : segmentCity,
      });
    });
    setFormItems(newData);
  }, [editData, tags]);
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical addTagsModal">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>Add Condition</span>
          </div>
          <div className="modalForm">
            <div className="formHeading">
              <span>Contacts match any of the following conditions</span>
            </div>
            {/* <form action="">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-1">
                  <div className="conditionTxt">
                    <span>Condition 1</span>
                  </div>
                </div>
                <div className="uk-width-1-3 uk-margin-remove-top">
                  <div className="formInputs">
                    <select name="tags">
                      <option value="" selected hidden disabled>
                        Tags
                      </option>
                    </select>
                  </div>
                </div>
                <div className="uk-width-1-3 uk-margin-remove-top">
                  <div className="formInputs">
                    <select name="tags">
                      <option value="" selected hidden disabled>
                        is
                      </option>
                    </select>
                  </div>
                </div>
                <div className="uk-width-1-3 uk-margin-remove-top">
                  <div className="formInputs">
                    <select name="tags">
                      <option value="" selected hidden disabled>
                        eid_jun_2023
                      </option>
                    </select>
                    <div className="trashBtn">
                      <button type="button" className="dltBtn">
                        <TrashCan />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btnwrp">
                <button type="button" className="btn-1" onClick={closeModal}>
                  Cancel
                </button>
                <button type="button" className="btn-2">
                  Add
                </button>
              </div>
            </form> */}
            {formItems.map((val, index) => {
              return (
                <div className="uk-grid uk-grid-small" uk-grid="" key={index}>
                  <div className="uk-width-1-1">
                    <div className="conditionTxt">
                      <span>Condition {index + 1}</span>
                    </div>
                  </div>
                  <div className="uk-width-1-3 uk-margin-remove-top">
                    <div className="formInputs">
                      <select
                        name="tags"
                        className="uk-select"
                        onChange={(e) => {
                          handleDropDown(index, e);
                        }}
                      >
                        <option value="" selected>
                          Please select tags
                        </option>
                        {tagDropDownOptions.map((valued, index) => {
                          return (
                            <option
                              key={index}
                              value={valued.value}
                              disabled={valued.disabled === true ? true : false}
                              selected={
                                valued.value === val.tagType ? val.tagType : ""
                              }
                            >
                              {valued.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="uk-width-1-3 uk-margin-remove-top">
                    <div className="formInputs">
                      <select
                        name="condition"
                        className="uk-select"
                        onChange={(e) =>
                          handleFormChange(index, "condition", e)
                        }
                      >
                        {val.conditionValue.map((valed, ind) => {
                          return (
                            <option
                              value={valed.value}
                              selected={
                                valed.selected === true ? valed.value : ""
                                // val.condition===true
                              }
                              key={ind}
                            >
                              {valed.name}
                            </option>
                          );
                        })}
                      </select>
                      {/* {errorEnable && val.conditionValue[index].value && (
                              <div className="formErrors">
                                Please select condition
                              </div>
                            )} */}

                      {val.fieldDisplayed !== "Yes" && (
                        <div
                          className="btnwrp conditionTrashBtn"
                          onClick={() => removeCondition(index)}
                        >
                          {index !== 0 && (
                            <button type="button" className="dltBtn">
                              <TrashCan />
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {val.fieldDisplayed === "Yes" && (
                    <div className="uk-width-1-3 uk-margin-remove-top">
                      <div className="formInputs">
                        <select
                          name="tagName"
                          className="uk-select"
                          onChange={(e) =>
                            handleFormChange(index, "tagName", e)
                          }
                        >
                          {val.thirdOptionValues.map((valeed, indii) => {
                            return (
                              <option
                                value={valeed.value}
                                // selected={valeed.selcted}
                                key={indii}
                                // selected={
                                //   valeed.selected == true ? valeed.value : ""
                                // }
                                selected={val.tagName === valeed.value}
                              >
                                {valeed.name}
                              </option>
                            );
                          })}
                        </select>
                        {index !== 0 && (
                          <div
                            className="btnwrp conditionTrashBtn"
                            onClick={() => removeCondition(index)}
                          >
                            <button type="button" className="dltBtn">
                              <TrashCan />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            {(errorEnable && conditionError.length !== 0) ||
            (errorEnable && tagNameError.length !== 0) ||
            (errorEnable && tagTypeError.length !== 0) ? (
              <div className="formErrors">
                Please select all condition parameters
              </div>
            ) : null}
            <div className="uk-grid uk-grid-small" uk-grid="">
              <div className="uk-width-1-1">
                <div className="clearBtn addContitionBtn">
                  <button type="button" onClick={addNewCondition}>
                    <Add /> Add new condition
                  </button>
                </div>
              </div>
              <div className="uk-width-1-1">
                <div className="saveAndCancelBtn">
                  {/* <button type="button" className="btn-1">
                          Cancel
                        </button> */}
                  <button className="btn-1" onClick={closeModal}>
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn-2 w-130"
                    // onClick={handleOpenSaveModal}
                    onClick={editSegment}
                  >
                    {loader ? (
                      <div uk-spinner="" className="loader"></div>
                    ) : (
                      "Edit Segment"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCondition;
