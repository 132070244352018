import React, { useState } from "react";
import { apiDelete } from "../../../../Utils/apiServices";
import { toast } from "react-toastify";

const DeletePictureModal = ({
  closeModal,
  deleteHeading,
  deleteTxt,
  deleteId,
  setApiCalled,
  deleteApi,
}) => {
  const [loader, setLoader] = useState(false);

  const deleteItem = (e) => {
    e.preventDefault();
    setLoader(true);
    if (deleteApi === "tag") {
      apiDelete(`/segment/category/${deleteId}`, onSuccess, onFailure);
    }
    // if (deleteApi === "segment") {
    //   apiDelete(`/contact/deactivate/${deleteId}`, onSuccess, onFailure);
    // }
    if (deleteApi === "segment") {
      // apiDelete(`/segment/category/${deleteId}`, onSuccess, onFailure);
      apiDelete(
        `/segment/category/segmentId?segmentId=${deleteId}`,
        onSuccess,
        onFailure
      );
    }
  };
  const onSuccess = (response) => {
    setApiCalled(true);
    setTimeout(() => {
      setLoader(false);
      toast.success(
        deleteApi === "segment"
          ? "Segment categoty deleleted successfully"
          : "tag deleleted successfully",
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        }
      );
    }, 1000);
    setTimeout(() => {
      closeModal();
    }, 2000);
  };
  const onFailure = (error) => {
    console.log(error);
    setLoader(false);
  };
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>{deleteHeading}</span>
          </div>
          <div className="modalForm">
            <div className="formHeading uk-margin-large-bottom">
              <span className="deleteModalTxt">{deleteTxt}</span>
            </div>
            <hr />
            <div className="btnwrp">
              <button type="button" className="btn-1" onClick={closeModal}>
                Cancel
              </button>
              <button
                type="button"
                className="btn-2 deleteBtn"
                onClick={deleteItem}
              >
                {loader ? (
                  <div uk-spinner="" className="loader"></div>
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePictureModal;
