import { Close } from "@carbon/icons-react";
import { useEffect } from "react";
import { channelImages } from "../../../../Utils/ChannelImages";
const ResolvedModal = ({
  closeModal,
  heading,
  fieldLabel,
  saveBtnTxt,
  conversationListCount,
  bulkMarkAsResolved,
  modalLoader,
  chatBulkMarkAsResolved,
  setChatBulkMarkAsResolved,
  setConversationListCount,
}) => {
  useEffect(() => {
    let uniqueObjArray = [
      ...new Map(
        chatBulkMarkAsResolved.map((item) => [item["id"], item])
      ).values(),
    ];
    setChatBulkMarkAsResolved(uniqueObjArray);
  }, []);
  useEffect(() => {}, [chatBulkMarkAsResolved]);
  const removeItem = (id) => {
    let filteredData = chatBulkMarkAsResolved.filter((val) => {
      return val.id !== id;
    });
    setChatBulkMarkAsResolved(filteredData);
    if (filteredData.length === 0) {
      setConversationListCount(0);
    } else {
      setConversationListCount(filteredData.length);
    }
  };
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <h3>{heading}</h3>
        <div>
          <form className="uk-form-stacked" onSubmit={bulkMarkAsResolved}>
            <div>
              <label className="uk-form-label">
                {conversationListCount} Conversations will be marked as
                resolved!
              </label>
              <label className="uk-form-label customLabelResolved">
                {fieldLabel}
              </label>
            </div>
            <div className="tagContainer">
              <ul>
                {chatBulkMarkAsResolved.map((val, index) => {
                  return (
                    <li key={index}>
                      <span className="tagChip">
                        <img src={channelImages(val.chanelId)} alt="img" />
                        {val.id}
                        <span onClick={() => removeItem(val.id)}>
                          <Close onCl />
                        </span>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
            <hr />
            <div className="btnWrapSpaceBetween resolveBtnSection uk-margin-top">
              <div>
                <div className="warningDescription">
                  This action will affect {conversationListCount} conversations.
                  Are you sure?
                </div>
              </div>
              <div>
                {/* <button className="cnclBtn" onClick={closeModal}> */}
                <button className="cnclBtn" onClick={closeModal} type="button">
                  Cancel
                </button>
                <button className="saveBtn w-130" type="submit">
                  {modalLoader ? (
                    <div uk-spinner="" className="loader"></div>
                  ) : (
                    saveBtnTxt
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResolvedModal;
