import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import moment from "moment/moment";
import UIkit from "uikit";
import { Calendar, ChevronDown, ChevronRight } from "@carbon/icons-react";
import CalendarDate from "../../components/Calendar/CalendarDate";
import { Link, useParams } from "react-router-dom";
import { apiGet } from "../../Utils/apiServices";
import Pagination from "../../components/Pagination/Pagination";
import { decryptedValue } from "../../Utils/RouteHashConversion";

const LiveChatFormEntries = () => {
  let PageSize = 10;
  const params = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedDateFilter, setSelectedDateFilter] = useState("This month");
  const [isApiCall, setIsApiCall] = useState(false);
  const [liveChatFormEntries, setLiveChatFormEntries] = useState(null);
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const filterDropdown = [
    "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];

  const handleDatePickerClose = () => {
    UIkit.drop("#datepickerdrop").hide(true);
  };
  const handleDateFilterDropdownrClose = () => {
    UIkit.drop("#dateDropdownSection").hide(true);
  };

  const handleDateFilter = (filter) => {
    setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    setIsApiCall(true);
  };

  const onChange = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
    setIsApiCall(true);
  };

  const getLiveFormEntriesData = () => {
    apiGet(
      `/chat/forms/${params?.formId}`,
      onSuccessEntriesData,
      onFailureEntriesData
    );
  };

  const onSuccessEntriesData = (response) => {
    setCurrentPage(1);
    var parsedJsonData = response?.data.map((entry) => {
      return {
        ...entry,
        json: JSON.parse(entry.json),
      };
    });
    setLiveChatFormEntries(parsedJsonData);
  };

  const onFailureEntriesData = (error) => {
    console.log(error);
  };

  const allKeys = new Set();
  liveChatFormEntries?.forEach((entry) => {
    Object.keys(entry.json || {}).forEach((key) => {
      allKeys.add(key);
    });
  });

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return liveChatFormEntries?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  useEffect(() => {
    getLiveFormEntriesData();
  }, []);

  // Convert Set to Array to use in rendering
  const allKeysArray = Array.from(allKeys);

  return (
    <div
      className="overviewContent entriesFormWrp"
      style={{
        minHeight: "calc(100vh - 161px)",
      }}
    >
      <div className="uk-container">
        <div className="breadcrumbWrp">
          <ul>
            <li>
              <Link to="/form-central">
                <span>Contact Form</span>
              </Link>
            </li>
            <li>
              <span>
                <ChevronRight />
              </span>
            </li>
            <li>
              <span className="breadcrumbActive">
                {decryptedValue(params?.formName)}
              </span>
            </li>
          </ul>
        </div>

        <div className="overviewTopDropdown">
          <div className="uk-grid uk-flex-middle" uk-grid="">
            <div className="uk-width-1-1">
              <div className="pageHeading">
                <h3>Forms Data</h3>
              </div>
            </div>
            <div className="uk-width-1-2 uk-margin-remove-top">
              <div className="searchBar">
                <form
                  action=""
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search by user, name, mobile number..."
                  />
                </form>
              </div>
            </div>
            <div className="uk-width-1-2 btnSection uk-margin-remove-top">
              {/* <button className="exportBtn">
                  <Download /> Export Data
                </button> */}

              <div className="uk-inline">
                <button className="calenderBtn rangeBtn">
                  <Calendar /> {selectedDateFilter}
                  <span className="uk-inline">
                    <ChevronDown />
                  </span>
                </button>
                <div
                  uk-dropdown="mode: click"
                  className="calendarDropdown"
                  id="dateDropdownSection"
                >
                  <div className="dropDownHeading">
                    <span>Select a range</span>
                  </div>
                  <ul>
                    {filterDropdown.map((val, index) => {
                      return (
                        <>
                          <li
                            className={
                              selectedDateFilter === val ? "activeBtn" : ""
                            }
                            key={index}
                            onClick={() => handleDateFilter(val)}
                          >
                            {filterDropdown.length - 1 === index ? (
                              <div className="uk-inline uk-text-left">
                                <button className="rangeBtn" type="button">
                                  <span className="rangeBtnCircle"></span>{" "}
                                  Custom range
                                </button>
                                <div
                                  id="datepickerdrop"
                                  uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                  className="date-picker-container"
                                >
                                  <CalendarDate onChange={onChange} />
                                  <div className="date-picker-btn-container uk-text-right">
                                    <button
                                      className="date-selection-btn"
                                      onClick={handleDatePickerClose}
                                    >
                                      Ok
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <button type="button" className="rangeBtn">
                                <span className="rangeBtnCircle"></span> {val}
                              </button>
                            )}
                          </li>
                          {filterDropdown.length - 2 === index && (
                            <hr style={{ margin: "5px 0" }} />
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1">
              <div className="statisticsTable">
                <div className="uk-overflow-auto statisticsTableThumb">
                  <table className="uk-table">
                    <thead>
                      <tr>
                        <th>Series</th>
                        <th className="uk-table-expand tableExpandWidth">
                          Channel
                          {/* <ChevronDown /> */}
                        </th>
                        <th className="uk-table-expand tableExpandWidth">
                          Mobile
                        </th>
                        <th className="uk-table-expand tableExpandWidth">
                          Name
                        </th>
                        <th className="uk-table-expand tableExpandWidth">
                          Via Section
                        </th>
                        <th className="uk-table-expand tableExpandWidth">
                          Description
                        </th>

                        {allKeysArray.map((key, index) => (
                          <th
                            key={index}
                            className="uk-table-expand tableExpandWidth"
                            style={{ textTransform: "capitalize" }}
                          >
                            {key}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {liveChatFormEntries?.length > 0 ? (
                        currentTableData?.map((val, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{val?.chanelId}</td>
                              <td className="coloredName">+{val?.number}</td>
                              <td>{val?.name}</td>
                              <td>
                                {val?.complainType === null
                                  ? "-"
                                  : val?.complainType}
                              </td>
                              <td>
                                {val?.description === null
                                  ? "-"
                                  : val?.description}
                              </td>

                              {allKeysArray.map((key) => (
                                <td key={key}>
                                  {val.json && val.json[key] !== undefined ? (
                                    typeof val.json[key] === "object" ? (
                                      <pre>
                                        {JSON.stringify(val.json[key], null, 2)}
                                      </pre>
                                    ) : (
                                      val.json[key]
                                    )
                                  ) : (
                                    "-" // Placeholder if the key doesn't exist in this row's `json` object
                                  )}
                                </td>
                              ))}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={8} className="dataNotFound">
                            {liveChatFormEntries === null ? (
                              <div uk-spinner=""></div>
                            ) : (
                              "Data Not Found"
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {liveChatFormEntries?.length > 0 && (
                  <div
                    style={{
                      marginRight: "50px",
                    }}
                  >
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={liveChatFormEntries.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveChatFormEntries;
