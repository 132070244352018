import React, { useState, useEffect } from "react";
import SettingSideBar from "./components/SettingSideBar";
import Footer from "../../components/common/Footer/Footer";
import Multiselect from "multiselect-react-dropdown";
import Switch from "react-switch";
import { apiDelete, apiGet, apiPost } from "../../Utils/apiServices";
import { toast } from "react-toastify";
import { useNavigate, useOutletContext } from "react-router-dom";

const LiveChatBehavior = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const [workingHours, setWorkingHours] = useState();
  const [loader, setLoader] = useState(false);
  const [routing, setRouting] = useState();
  const navigate = useNavigate();
  const [daysData, setDaysData] = useState([
    {
      dayOff: false,
      day: "Monday",
      startTime: "",
      endTime: "",
      companyId: "",
      id: "",
      publicId: "",
    },
    {
      day: "Tuesday",
      dayOff: false,
      startTime: "",
      endTime: "",
      companyId: "",
      id: "",
      publicId: "",
    },
    {
      day: "Wednesday",
      dayOff: false,
      startTime: "",
      endTime: "",
      companyId: "",
      id: "",
      publicId: "",
    },
    {
      day: "Thursday",
      dayOff: false,
      startTime: "",
      endTime: "",
      companyId: "",
      id: "",
      publicId: "",
    },
    {
      day: "Friday",
      dayOff: false,
      startTime: "",
      endTime: "",
      companyId: "",
      id: "",
      publicId: "",
    },
    {
      day: "Saturday",
      dayOff: false,
      startTime: "",
      endTime: "",
      companyId: "",
      id: "",
      publicId: "",
    },
    {
      day: "Sunday",
      dayOff: false,
      startTime: "",
      endTime: "",
      companyId: "",
      id: "",
      publicId: "",
    },
  ]);
  const whatsapp = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 100];
  const [agentSettings, setAgentSettings] = useState({
    offHourMessage: "",
    offDayMessage: "",
    maxChatThreshold: 0,
    id: "",
    companyId: "",
    publicId: "",
    agentRoutingMessage: "",
    busyAgentMessage: "",
    confirmMessage: "",
    morningMessage: "",

  });
  const updateWorkingHours = () => {
    setLoader(true);
    let data = {
      workSettingsList: daysData,
      agentSettings: {
        companyId: agentSettings?.companyId,
        id: agentSettings?.id,
        offHourMessage: agentSettings?.offHourMessage,
        offDayMessage: agentSettings?.offDayMessage,
        maxChatThreshold: agentSettings?.maxChatThreshold,

        publicId: agentSettings?.publicId,
        agentRoutingMessage: agentSettings?.agentRoutingMessage,
        busyAgentMessage: agentSettings?.busyAgentMessage,
        confirmMessage: agentSettings?.confirmMessage,
        morningMessage: agentSettings?.morningMessage,
      },
    };

    apiPost(
      "/settings/work",
      onSuccessUpdateWorkingHours,
      onFailureUpdateWorkingHours,
      data
    );
  };
  const onSuccessUpdateWorkingHours = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setLoader(false);
        toast.success("Working hours updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1000);
    }
  };
  const onFailureUpdateWorkingHours = (error) => {
    console.log(error);
    setLoader(false);
  };

  const getWorkingHours = () => {
    apiGet(`/settings/work`, onSuccessWorkingHours, onFailureWorkingHours);
  };
  const onSuccessWorkingHours = (response) => {
    setWorkingHours(response?.data);
    setAgentSettings((prevAgentSettings) => ({
      ...prevAgentSettings,
      offHourMessage: response?.data?.agentSettings?.offHourMessage,
      offDayMessage: response?.data?.agentSettings?.offDayMessage,
      agentRoutingMessage:
        response?.data?.agentSettings?.agentRoutingMessage === null
          ? ""
          : response?.data?.agentSettings?.agentRoutingMessage,
      maxChatThreshold: response?.data?.agentSettings?.maxChatThreshold,
    
      id: response?.data?.agentSettings?.id,
      companyId: response?.data?.agentSettings?.companyId,
      publicId: response?.data?.agentSettings?.publicId,
      busyAgentMessage: response?.data?.agentSettings?.busyAgentMessage,
      confirmMessage: response?.data?.agentSettings?.confirmMessage,
      morningMessage: response?.data?.agentSettings?.morningMessage,
    }));
  };

  const onFailureWorkingHours = (error) => {
    console.log(error);
  };

  const deleteRouting = (publicId) => {
    apiDelete(
      `/routing/${publicId}`,
      onSuccessDeleteRouting,
      onFailureDeleteRouting
    );
  };
  const onSuccessDeleteRouting = (response) => {
    toast.success("Routing deleted successfully", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
    getRouting();
  };
  const onFailureDeleteRouting = (error) => {
    console.log(error);
  };
  const getRouting = () => {
    apiGet(`/routing`, onSuccessRouting, onFailureRouting);
  };
  const onSuccessRouting = (response) => {
    setRouting(response);
  };
  const onFailureRouting = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (workingHours !== undefined) {
      setDaysData([
        {
          dayOff: workingHours?.workSettingsList[0].dayOff,
          day: "Monday",
          startTime: workingHours?.workSettingsList[0]?.startTime,
          endTime: workingHours?.workSettingsList[0]?.endTime,
          companyId: workingHours?.workSettingsList[0]?.companyId,
          id: workingHours?.workSettingsList[0]?.id,
          publicId: workingHours?.workSettingsList[0]?.publicId,
        },
        {
          dayOff: workingHours?.workSettingsList[1].dayOff,
          day: "Tuesday",
          startTime: workingHours?.workSettingsList[1]?.startTime,
          endTime: workingHours?.workSettingsList[1]?.endTime,
          companyId: workingHours?.workSettingsList[1]?.companyId,
          id: workingHours?.workSettingsList[1]?.id,
          publicId: workingHours?.workSettingsList[1]?.publicId,
        },
        {
          dayOff: workingHours?.workSettingsList[2].dayOff,
          day: "Wednesday",
          startTime: workingHours?.workSettingsList[2]?.startTime,
          endTime: workingHours?.workSettingsList[2]?.endTime,
          companyId: workingHours?.workSettingsList[2]?.companyId,
          id: workingHours?.workSettingsList[2]?.id,
          publicId: workingHours?.workSettingsList[2]?.publicId,
        },
        {
          dayOff: workingHours?.workSettingsList[3].dayOff,
          day: "Thursday",
          startTime: workingHours?.workSettingsList[3]?.startTime,
          endTime: workingHours?.workSettingsList[3]?.endTime,
          companyId: workingHours?.workSettingsList[3]?.companyId,
          id: workingHours?.workSettingsList[3]?.id,
          publicId: workingHours?.workSettingsList[3]?.publicId,
        },
        {
          dayOff: workingHours?.workSettingsList[4].dayOff,
          day: "Friday",
          startTime: workingHours?.workSettingsList[4]?.startTime,
          endTime: workingHours?.workSettingsList[4]?.endTime,
          companyId: workingHours?.workSettingsList[4]?.companyId,
          id: workingHours?.workSettingsList[4]?.id,
          publicId: workingHours?.workSettingsList[4]?.publicId,
        },
        {
          dayOff: workingHours?.workSettingsList[5].dayOff,
          day: "Saturday",
          startTime: workingHours?.workSettingsList[5]?.startTime,
          endTime: workingHours?.workSettingsList[5]?.endTime,
          companyId: workingHours?.workSettingsList[5]?.companyId,
          id: workingHours?.workSettingsList[5]?.id,
          publicId: workingHours?.workSettingsList[5]?.publicId,
        },
        {
          dayOff: workingHours?.workSettingsList[6].dayOff,
          day: "Sunday",
          startTime: workingHours?.workSettingsList[6]?.startTime,
          endTime: workingHours?.workSettingsList[6]?.endTime,
          companyId: workingHours?.workSettingsList[6]?.companyId,
          id: workingHours?.workSettingsList[6]?.id,
          publicId: workingHours?.workSettingsList[6]?.publicId,
        },
      ]);
    }
  }, [workingHours]);
  useEffect(() => {
    getWorkingHours();
    getRouting();
  }, []);
  var options = [{ name: "Name" }];

  const handleChange = (index, checked) => {
    var daysDataTemp = [...daysData];
    daysDataTemp[index]["dayOff"] = !daysDataTemp[index]["dayOff"];
    daysDataTemp[index]["startTime"] = "00:00";
    daysDataTemp[index]["endTime"] = "00:00";
    setDaysData(daysDataTemp);
  };

  const handleDateChange = (index, dateName, e) => {
    let value = e.target.value;
    var daysDataTemp = [...daysData];
    if (dateName === "startTime") {
      daysDataTemp[index]["startTime"] = value;
      setDaysData(daysDataTemp);
    }

    if (dateName === "endTime") {
      daysDataTemp[index]["endTime"] = value;
      setDaysData(daysDataTemp);
    }
  };

  return (
    <>
      <div className="settingwrp">
        <div className="topBar">
          <div className="uk-grid" uk-grid="">
            <div className="uk-width-1-1">
              <div className="txtwrp">
                <h3>Settings</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="settingContentWrp">
          <div className="uk-grid uk-grid-small" uk-grid="">
            <div className="uk-width-auto">
              <SettingSideBar userPermissions={userPermissions} />
            </div>
            <div className="uk-width-expand">
              <div className="settingWrapper">
                <div className="uk-container">
                  <div className="settingTabbing">
                    <ul
                      className="uk-subnav uk-subnav-pill tabbingBtn"
                      uk-switcher=""
                    >
                      <li>
                        <a href="#">Work load</a>
                      </li>
                      <li>
                        <a href="#">Working hours</a>
                      </li>
                      <li>
                        <a href="#">Chat routing</a>
                      </li>
                    </ul>

                    <ul className="uk-switcher uk-margin">
                      <li>
                        <div className="generalSettingWrp">
                          <div className="mainHeading">
                            <h3>Work load</h3>
                            <p>
                              Set the default number of maximum concurrent
                              conversations an user can have at a time.
                            </p>
                          </div>
                          <div className="boxwrp">
                            <div className="headingAndSwitch">
                              <div className="boxHeading">
                                <h6>Default Auto-Assign Capacity</h6>
                                <p>
                                  Capacity represents the maximum number of
                                  concurrent conversations that can be assigned
                                  to one user over the automatic assignment.
                                  Default capacity is taken into account only
                                  when it is not defined at the user level.
                                </p>
                              </div>
                            </div>
                            <div className="formwrp">
                              <form action="">
                                <div
                                  className="uk-grid uk-grid-small"
                                  uk-grid=""
                                >
                                  <div className="uk-width-1-1">
                                    <div className="multiSelectWrp">
                                      <div className="multiSelectTxt uk-margin-remove-top">
                                        <span>
                                          Max. Concurrent Conversations
                                        </span>
                                      </div>
                                      <div className="radioInput">
                                        {/* <input type="checkbox" /> */}
                                        <span>All channels</span>
                                      </div>
                                      <div className="settingDropdown uk-margin-small-top">
                                        <select
                                          className="uk-select"
                                          onChange={(e) => {
                                            setAgentSettings({
                                              ...agentSettings,
                                              maxChatThreshold: e.target.value,
                                            });
                                          }}
                                        >
                                          <option value="" selected disabled>
                                            please select whatsapp
                                          </option>
                                          {whatsapp.map((val, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={val}
                                                selected={
                                                  workingHours?.agentSettings
                                                    ?.maxChatThreshold === val
                                                }
                                              >
                                                {val}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                      {/* <div className="radioInput">
                                        <input type="checkbox" />
                                        <span>Facebook</span>
                                      </div> */}
                                      {/* <div className="settingDropdown uk-margin-small-top">
                                        <select disabled>
                                          <option value="" selected disabled>
                                            please select facebook
                                          </option>
                                          {whatsapp.map((val, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={val}
                                                selected={
                                                  workingHours?.agentSettings
                                                    ?.maxChatThreshold === val
                                                }
                                              >
                                                {val}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                                {/* <hr /> */}
                                {/* <div className="headingAndSwitch">
                                  <div className="boxHeading">
                                    <h6>Conversation statuses and capacity</h6>
                                    <p>
                                      Decide how conversation statuses affect
                                      agent capacity for your entire account.
                                      The conversation statuses you select here
                                      will be counted into an agent's maximum
                                      capacity
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="uk-grid uk-grid-small"
                                  uk-grid=""
                                >
                                  <div className="uk-width-1-1">
                                    <div className="multiSelectWrp">
                                      <div className="multiSelectTxt uk-margin-remove-top">
                                        <span>
                                          Agents appear as Busy while working on
                                          conversations that are
                                        </span>
                                      </div>
                                      <div
                                        className="multiSelect"
                                        style={{ marginTop: "8px" }}
                                      >
                                        <Multiselect
                                          options={options}
                                          displayValue="name"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                              </form>
                            </div>
                            <div className="boxHeading uk-margin-large-top uk-margin-small-bottom">
                              <h6>Agent Transition Message</h6>
                              <p>
                                This message will appear as your chat is being
                                routed to one of our support agents for
                                personalized assistance.
                              </p>
                            </div>
                            <div className="formInput  uk-margin-large-bottom">
                              <textarea
                                name=""
                                className="uk-textarea"
                                value={agentSettings?.agentRoutingMessage}
                                style={{ paddingRight: "70px", width: "100%" }}
                                onChange={(e) => {
                                  e.target.value.length <= 1000 &&
                                    setAgentSettings({
                                      ...agentSettings,
                                      agentRoutingMessage: e.target.value,
                                    });
                                }}
                              ></textarea>
                              <div
                                className="txtCounter"
                                style={{ top: "12px" }}
                              >
                                <span>
                                  {agentSettings?.agentRoutingMessage?.length}
                                </span>
                                <span>/</span>
                                <span>1000</span>
                              </div>
                            </div>

                            {/* new textarea */}

                            <div className="boxHeading uk-margin-large-top uk-margin-small-bottom">
                              <h6>Agent Non Availability Message</h6>
                              <p>
                                This message will appear when agents are offline
                                or reached max conversation limit.
                              </p>
                            </div>
                            <div className="formInput  uk-margin-large-bottom">
                              <textarea
                                name="busyAgentMessage"
                                className="uk-textarea"
                                value={agentSettings?.busyAgentMessage}
                                style={{ paddingRight: "70px", width: "100%" }}
                                onChange={(e) => {
                                  e.target.value.length <= 300 &&
                                    setAgentSettings({
                                      ...agentSettings,
                                      busyAgentMessage: e.target.value,
                                    });
                                }}
                              ></textarea>
                              <div
                                className="txtCounter"
                                style={{ top: "12px" }}
                              >
                                <span>
                                  {agentSettings?.busyAgentMessage?.length}
                                </span>
                                <span>/</span>
                                <span>300</span>
                              </div>
                            </div>

                            {/* end textarea */}
                            <div className="btnSection">
                              <button onClick={updateWorkingHours}>
                                {loader ? (
                                  <div uk-spinner="" className="loader"></div>
                                ) : (
                                  "Save"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="generalSettingWrp">
                          <div className="mainHeading">
                            <h3>Working hours</h3>
                            <p>Set up working hours for your contact center.</p>
                          </div>
                          <div className="boxwrp">
                            <div className="headingAndSwitch">
                              <div className="boxHeading uk-margin-small-bottom">
                                <h6>Working hours</h6>
                              </div>
                            </div>
                            {workingHours !== undefined &&
                              daysData.map((val, index) => (
                                <div className="formwrp" key={index}>
                                  <form action="">
                                    <div
                                      className="uk-grid uk-margin-bottom"
                                      uk-grid=""
                                    >
                                      <div className="uk-width-1-1">
                                        <div
                                          className="switchAndDropdown"
                                          style={{ display: "block" }}
                                        >
                                          <div className="uk-grid" uk-grid="">
                                            <div className="uk-width-1-3">
                                              <div className="switchwrp">
                                                <Switch
                                                  checked={!val.dayOff}
                                                  onChange={() =>
                                                    handleChange(
                                                      index,
                                                      val.dayOff
                                                    )
                                                  }
                                                  uncheckedIcon={false}
                                                  checkedIcon={false}
                                                  width={26}
                                                  height={14}
                                                  handleDiameter={10}
                                                  onColor="#29a869"
                                                />

                                                <div className="switchTxt">
                                                  <span>{val?.day}</span>
                                                </div>
                                              </div>
                                            </div>
                                            {!val.dayOff ? (
                                              <>
                                                <div className="uk-width-1-3">
                                                  <div className="settingDropdown">
                                                    <input
                                                      className="dropdownBtn uk-input"
                                                      id="appt-time"
                                                      type="time"
                                                      name="appt-time"
                                                      defaultValue={
                                                        val?.startTime
                                                      }
                                                      onChange={(e) =>
                                                        handleDateChange(
                                                          index,
                                                          "startTime",
                                                          e
                                                        )
                                                      }
                                                      style={{ width: "100%" }}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="uk-width-1-3">
                                                  <div className="settingDropdown">
                                                    <input
                                                      className="dropdownBtn uk-input"
                                                      id="appt-time"
                                                      type="time"
                                                      name="appt-time"
                                                      defaultValue={
                                                        val?.endTime
                                                      }
                                                      onChange={(e) =>
                                                        handleDateChange(
                                                          index,
                                                          "endTime",
                                                          e
                                                        )
                                                      }
                                                      style={{ width: "100%" }}
                                                    />
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <div>
                                                {val.dayOff ? "Closed Day" : ""}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              ))}
                            <hr />
                            <div className="headingAndSwitch">
                              <div className="boxHeading uk-margin-small-bottom">
                                <h6>Away message</h6>
                              </div>
                            </div>
                            <div className="uk-grid uk-grid-small" uk-grid="">
                              <div className="uk-width-1-1">
                                <div className="formInput">
                                  <label htmlFor="">Off hour message</label>
                                  <textarea
                                    name=""
                                    className="uk-textarea"
                                    value={agentSettings?.offHourMessage}
                                    onChange={(e) => {
                                      // setText(e.target.value);
                                      e.target.value.length <= 1000 &&
                                        setAgentSettings({
                                          ...agentSettings,
                                          offHourMessage: e.target.value,
                                        });
                                    }}
                                  ></textarea>
                                  <div className="txtCounter">
                                    <span>
                                      {agentSettings?.offHourMessage?.length}
                                    </span>
                                    <span>/</span>
                                    <span>1000</span>
                                  </div>
                                </div>
                              </div>
                              <div className="uk-width-1-1">
                                <div className="formInput">
                                  <label htmlFor="">Off day message</label>
                                  <textarea
                                    name=""
                                    className="uk-textarea"
                                    value={agentSettings?.offDayMessage}
                                    onChange={(e) => {
                                      e.target.value.length <= 1000 &&
                                        setAgentSettings({
                                          ...agentSettings,
                                          offDayMessage: e.target.value,
                                        });
                                    }}
                                  ></textarea>
                                  <div className="txtCounter">
                                    <span>
                                      {agentSettings?.offDayMessage?.length}
                                    </span>
                                    <span>/</span>
                                    <span>1000</span>
                                  </div>
                                </div>
                              </div>

                              {/* starting new switch data */}
                              <div className="headingAndSwitch">
                                <div className="boxHeading uk-margin-small-bottom">
                                  <h6> Next working day chat register</h6>
                                </div>
                              </div>

                              <div className="uk-width-1-1">
                                <div className="formInput">
                                  <label htmlFor="morningMessage">
                                    This message will be sent when customer
                                    contacts in non working hours after away
                                    messages.
                                  </label>
                                  <textarea
                                    name="morningMessage"
                                    className="uk-textarea"
                                    value={agentSettings?.morningMessage}
                                    onChange={(e) => {
                                      e.target.value.length <= 100 &&
                                        setAgentSettings({
                                          ...agentSettings,
                                          morningMessage: e.target.value,
                                        });
                                    }}
                                  ></textarea>
                                  <div className="txtCounter">
                                    <span>
                                      {agentSettings?.morningMessage?.length}
                                    </span>
                                    <span>/</span>
                                    <span>100</span>
                                  </div>
                                </div>
                              </div>

                            
                              <div className="uk-width-1-1">
                                <div className="formInput  uk-margin-large-bottom">
                                  <label htmlFor="">
                                    This message will be sent when customer
                                    provide confirmation on being contacted on
                                    next working day.
                                  </label>
                                  <textarea
                                    name="confirmMessage"
                                    className="uk-textarea"
                                    value={agentSettings?.confirmMessage}
                                    style={{
                                      paddingRight: "70px",
                                      width: "100%",
                                    }}
                                    onChange={(e) => {
                                      e.target.value.length <= 300 &&
                                        setAgentSettings({
                                          ...agentSettings,
                                          confirmMessage: e.target.value,
                                        });
                                    }}
                                  ></textarea>
                                  <div
                                    className="txtCounter"
                                  >
                                    <span>
                                      {agentSettings?.confirmMessage?.length}
                                    </span>
                                    <span>/</span>
                                    <span>300</span>
                                  </div>
                                </div>
                              </div>

                              {/* ending new switch data */}

                              {/* <div className="uk-width-1-1">
                                <div className="formInput">
                                  <label htmlFor="">
                                    Agent routing message
                                  </label>
                                  <textarea
                                    name=""
                                    className="uk-textarea"
                                    value={agentSettings?.agentRoutingMessage}
                                    onChange={(e) => {
                                      e.target.value.length <= 100 &&
                                        setAgentSettings({
                                          ...agentSettings,
                                          agentRoutingMessage: e.target.value,
                                        });
                                    }}
                                  ></textarea>
                                  <div className="txtCounter">
                                    <span>
                                      {agentSettings?.agentRoutingMessage.length}
                                    </span>
                                    <span>/</span>
                                    <span>100</span>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                            <div className="btnSection">
                              <button onClick={updateWorkingHours}>
                                {loader ? (
                                  <div uk-spinner="" className="loader"></div>
                                ) : (
                                  "Save"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="settingContentWrp">
                          <div className="settingWrapper uk-padding-remove-top">
                            <div className="uk-container">
                              <div className="settingTabbing">
                                <div className="generalSettingWrp">
                                  <div className="mainHeading">
                                    <h3>Chat routing</h3>
                                    <p>
                                      Optimise chat routing based on priority to
                                      streamline customer interactions.
                                    </p>
                                  </div>
                                  <div className="boxwrp">
                                    <div className="formwrp">
                                      <form action="">
                                        <div className="boxHeading">
                                          <h6>Routing</h6>
                                          <p>
                                            Routing can help you route a contact
                                            to a specific team or user.
                                          </p>
                                        </div>
                                        <div
                                          className="uk-grid uk-grid-small"
                                          uk-grid=""
                                        >
                                          {routing?.map((val, index) => {
                                            return (
                                              <div
                                                className="uk-width-1-1"
                                                key={index}
                                              >
                                                <div className="formInput">
                                                  <input
                                                    type="text"
                                                    className="uk-input"
                                                    disabled
                                                    value={val.name}
                                                  />
                                                  <div
                                                    className="changeBtn changeBtn2"
                                                    style={{ top: "17%" }}
                                                  >
                                                    <button
                                                      type="button"
                                                      className="uk-margin-small-right"
                                                      onClick={() =>
                                                        deleteRouting(
                                                          val.publicId
                                                        )
                                                      }
                                                    >
                                                      Delete
                                                    </button>
                                                    <button
                                                      type="button"
                                                      onClick={() =>
                                                        navigate(
                                                          `/settings/live-chat-behavior/${val.publicId}`
                                                        )
                                                      }
                                                    >
                                                      Edit
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}

                                          {/* <div className="uk-width-1-1">
                                <div className="formInput">
                                  <input type="text" className="uk-input" />
                                  <div
                                    className="changeBtn changeBtn2"
                                    style={{ top: "17%" }}
                                  >
                                    <button
                                      type="button"
                                      className="uk-margin-small-right"
                                    >
                                      Delete
                                    </button>
                                    <button type="button">Edit</button>
                                  </div>
                                </div>
                              </div>
                              <div className="uk-width-1-1">
                                <div className="formInput">
                                  <input type="text" className="uk-input" />
                                  <div
                                    className="changeBtn changeBtn2"
                                    style={{ top: "17%" }}
                                  >
                                    <button
                                      type="button"
                                      className="uk-margin-small-right"
                                    >
                                      Delete
                                    </button>
                                    <button type="button">Edit</button>
                                  </div>
                                </div>
                              </div> */}
                                          <div className="uk-width-1-1">
                                            <div className="settingAddBtn">
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  navigate(
                                                    "/settings/create-new-routing",
                                                    {
                                                      replace: true,
                                                    }
                                                  );
                                                }}
                                              >
                                                Add new route
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveChatBehavior;
