import React, { useEffect } from "react";
import { useState } from "react";
import {
  ArrowRight,
  ChevronDown,
  Close,
  Copy,
  DocumentAttachment,
  DocumentAudio,
  DocumentVideo,
  Edit,
  FaceSatisfied,
  Flash,
  Image,
  Location,
  Parameter,
  Popup,
  TableOfContents,
  TextAlignJustify,
  Time,
} from "@carbon/icons-react";
import { useContextChatBotFacebook  } from "../ContextChatBot";
import { cond, property } from "lodash";
import UIkit from "uikit";
import Picker from "emoji-picker-react";
import Emoji from "../../../../components/common/Chat/Emoji";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { useWebSocket } from "../../../../socket/WebSocketManager";
import ListMenuModal from "../../components/ListMenuModal";
import { toast } from "react-toastify";
const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;

const DialogChatbot = () => {
  const {
    handleOpenDilaogModal,
    sectionData,
    addSection,
    handleSectionClosing,
    handleInputChangeSection,
    selectSection,
    sections,
    handleFileI,
    handleFileV,
    handleFileF,
    handleInputChangeSectionButton,
    handleDialogSelectOnButton,
    selectedDialogOnButton,
    handleAddButton,
    isKeywordVisibleAction,
    toggleKeywordVisibilityAction,
    handleRemoveTriggerAction,
    handleKeyPressKeywordSyn,
    handleChange,
    sectionValues,
    toggleActionVisibility,
    toggleDialogHidden,
    disableItem,
    disableAll,
    chosenEmoji,
    loader,
    insertEmoji,
    formData,
    toggleFormVisibility,
    paramsState,
    teamsData,
    handleInputChangeSectionCatalogue,
    handleInputChangeSectionButtonExternal,
    emptyKeyword,
    setSections,
    handleDialogSelectOnButtonTimings,
    handleTimeChange,
    btnCount,
    handleSelectOnButtonValidation,
    handleInputTimingsText,
    listMenuDisable,
  } = useContextChatBotFacebook();

  const selectedDialogIndex = sections?.findIndex(
    (dialog) => dialog.dialogueTitle === selectSection
  );

  const selectedDialog = sections.find(
    (dialog) => dialog.dialogueTitle === selectSection
  );
  const [openListModal, setOpenListModal] = useState(false);
  const [hasImage, setHasImage] = useState(false);
  const [hasVideo, setHasVideo] = useState(false);
  const [hasFile, setHasFile] = useState(false);
  const [hasTwoItems, setHasTwoItems] = useState(false);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [urlButton, setUrlButton] = useState("Custom");
  const [productId, setProductId] = useState({
    index: 0,
    property: "productId",
    name: "",
  });
  const [productHeaderText, setProductHeaderText] = useState({
    index: 1,
    property: "productHeaderText",
    name: "",
  });

  const [productFooterText, setProductFooterText] = useState({
    index: 2,
    property: "productFooterText",
    name: "",
  });

  useEffect(() => {
    if (
      selectedDialog?.settings &&
      selectedDialog?.settings.some((item) => item.type === "image") &&
      selectedDialog?.settings.some((item) => item.name !== null) &&
      selectedDialog?.settings.some((item) => item.value !== null)
    ) {
      setHasImage(true);
    } else {
      setHasImage(false);
    }

    if (
      selectedDialog?.settings &&
      selectedDialog?.settings.some((item) => item.type === "video") &&
      selectedDialog?.settings.some((item) => item.name !== null) &&
      selectedDialog?.settings.some((item) => item.value !== null)
    ) {
      setHasVideo(true);
    } else {
      setHasVideo(false);
    }
    if (
      selectedDialog?.settings &&
      selectedDialog?.settings.some((item) => item.type === "file") &&
      selectedDialog?.settings.some((item) => item.name !== null) &&
      selectedDialog?.settings.some((item) => item.value !== null)
    ) {
      setHasFile(true);
    } else {
      setHasFile(false);
    }
  }, [selectedDialog?.settings, selectedDialog?.settings.length]);

  const handleOpenListModal = () => {
    setOpenListModal(true);
  };

  const handleCloseListModal = () => {
    setOpenListModal(false);
  };

  const builderDropdownClose = () => {
    UIkit.dropdown("#builderDropdown").hide();
  };

  const actionDropdownClose = () => {
    UIkit.dropdown("#actionDropdown").hide();
  };

  const actionInputDropdownClose = () => {
    UIkit.dropdown("#actionInputDropdown").hide();
  };

  const dialogDropdownClose = () => {
    UIkit.dropdown("#dialogDropdown").hide();
  };

  const userActionDropdownClose = () => {
    UIkit.dropdown("#userActionMainDropdown").hide();
  };

  const buttonDropdownClose = () => {
    UIkit.dropdown("#buttonMainDropdown").hide();
  };

  const closeButtonDropdown = (id, type, name) => {
    if (type === "button" && (name == "" || name == undefined)) {
      toast.error("Button name is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      UIkit.dropdown(id)?.hide();
    }
  };

  const closeButtonPressDrop = (id) => {
    if (emptyKeyword) {
      UIkit.dropdown(id)?.hide();
    }
  };

  const closeDialogDropdown = (id) => {
    UIkit.dropdown(id)?.hide();
  };

  const closeUserActionDropCancel = (id) => {
    UIkit.dropdown(id)?.hide();
  };

  const closeUserActionDrop = (id, keyword, type, name) => {
    if (type === "action" && (keyword?.length <= 0 || keyword == undefined)) {
      toast.error("Keyword is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      type === "action" &&
      (name == "" || name == undefined || name == null)
    ) {
      toast.error("Action name is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      UIkit.dropdown(id)?.hide();
    }
  };

  const closeReplyDropdown = (id) => {
    UIkit.dropdown(id)?.hide();
  };

  const closeActionDropdown = (id) => {
    UIkit.dropdown(id)?.hide();
  };

  const closeUserDialogDrop = (id) => {
    UIkit.dropdown(id)?.hide();
  };

  const hideEmoji = () => {
    setOpenEmoji(false);
  };

  const handleOpenEmoji = (e) => {
    setOpenEmoji(true);
  };

  // useEffect(() => { }, [openEmoji])
  const handleEmojiClick = (id, sectionID) => (emojiData, event) => {
    insertEmoji(id, sectionID, emojiData.emoji);
  };

  const [showInstruction, setShowInstruction] = useState(true);

  useEffect(() => {
    // Check the length of settings array in the selectedDialog
    if (selectedDialog?.settings.length > 0) {
      setShowInstruction(false);
    } else {
      setShowInstruction(true);
    }
  }, [selectedDialog?.settings?.length]);

  //socket for images
  const { socket } = useWebSocket();
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();

  const fetchUrl = (filePath) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const data = {
        fileId: filePath,
      };
      socket.send(JSON.stringify({ type: "get-file-url", data: data }));
    }
  };

  useEffect(() => {
    if (socketResponse !== undefined) {
      if (socketResponse?.type == "FILE_URL") {
        sections[selectedDialogIndex].settings.forEach((item, index) => {
          if (
            item?.type === "image" &&
            item?.value &&
            item?.name == undefined
          ) {
            sections[selectedDialogIndex].settings[index].name =
              chatBaseUrl + socketResponse?.data?.filePath;
            setSections([...sections]);
          }
          if (
            item?.type === "video" &&
            item?.value &&
            item?.name == undefined
          ) {
            sections[selectedDialogIndex].settings[index].name =
              chatBaseUrl + socketResponse?.data?.filePath;
            setSections([...sections]);
          }
          if (item?.type === "file" && item?.value && item?.name == undefined) {
            sections[selectedDialogIndex].settings[index].name =
              socketResponse?.data?.filePath;
            setSections([...sections]);
          }
        });
      }
    }
  }, [socketResponse]);

  useEffect(() => {
    selectedDialog?.settings.forEach((item) => {
      if (item?.type === "image" && item?.value && item.name === undefined) {
        fetchUrl(item?.value);
      } else if (
        item?.type === "video" &&
        item?.value &&
        item?.name === undefined
      ) {
        fetchUrl(item?.value);
      } else if (
        item?.type === "file" &&
        item?.value &&
        item?.name === undefined
      ) {
        fetchUrl(item?.value);
      }
    });
  }, [selectedDialog?.settings]);

  //socket for images end

  const setSectionDetailType = (index, type, currentBtnId) => {
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      if (selectedDialog) {
        const selectedDialogIndex = updatedSections?.findIndex(
          (dialog) => dialog.dialogueTitle === selectedDialog.dialogueTitle
        );

        if (selectedDialogIndex !== -1) {
          const selectedButtonIndex = updatedSections[
            selectedDialogIndex
          ].settings.findIndex(
            (setting, i) =>
              (i === index && setting?.type === "button") ||
              ("action" && setting?.id === currentBtnId)
          );
          if (selectedButtonIndex !== -1) {
            const selectedButton =
              updatedSections[selectedDialogIndex].settings[
                selectedButtonIndex
              ];
            if (selectedButton) {
              selectedButton.detail = {
                ...selectedButton.detail, // Merge existing properties
                type: type,
              };
            }
            updatedSections[selectedDialogIndex].settings[selectedButtonIndex] =
              selectedButton;
          }
        }
      }
      return updatedSections;
    });
  };

  const insertTag = (sectionId, id, tag) => {
    const textArea = document.getElementById(id);
    if (!textArea) {
      console.error(`Text area with id ${id} not found`);
      return;
    }
    const cursorPos = textArea.selectionStart;
    console.log("Cursor position:", cursorPos);
    const textBefore = textArea.value.substring(0, cursorPos);
    const textAfter = textArea.value.substring(
      cursorPos,
      textArea.value.length
    );
    const newText = textBefore + tag + textAfter;
    handleInputChangeSection(sectionId, "value", newText);
  };

  //onchange for dropdownssssssssss*********************************************************************************************************

  return (
    <div>
      <div className="triggerOptionWrp">
        <div className="topHeading">
          <h3>{selectedDialog?.dialogueTitle || "Choose a Dialog"}</h3>
        </div>

        {showInstruction && (
          <div className="instructionTxt">
            <span>Use the buttons below to add text and pictures.</span>
          </div>
        )}

        <div className="triggerOptionContent">
          {/* new code started */}

          {selectedDialog?.settings?.map((section, index) => (
            <div key={index}>
              {/* Send Text start */}
              {section.type === "textarea" && (
                <div className="textInputField">
                  <div className="textInputLabel">
                    <label htmlFor="">Send Text </label>
                    <div
                      className="txtCounter"
                      style={{
                        position: "relative",
                        top: "unset",
                        right: "unset",
                      }}
                    >
                      <span>
                        {section?.value === undefined
                          ? 0
                          : section?.value?.length}
                      </span>
                      <span>/</span>
                      <span>4096</span>
                    </div>
                  </div>
                  <div className="textAreaAndBtn">
                    <div className="textInputWrp">
                      <textarea
                        name="msg_box"
                        id={selectedDialog?.dialogueTitle + "_" + index}
                        value={section.value}
                        onChange={(e) =>
                          e.target.value.length <= 4096 &&
                          handleInputChangeSection(
                            section.id,
                            "value",
                            e.target.value
                          )
                        }
                        className="uk-textarea"
                        placeholder="Enter text "
                      ></textarea>
                      <div className="emojiWrp">
                        <ul className="emojiBtnList">
                          <li>
                            {!openEmoji ? (
                              <button
                                id="emojidiv"
                                onClick={(e) => handleOpenEmoji()}
                              >
                                <FaceSatisfied />
                              </button>
                            ) : (
                              <button id="emojiCross" className="emojiCross">
                                <Close onClick={(e) => hideEmoji()} />
                              </button>
                            )}
                          </li>
                          <li>
                            <div className="uk-inline">
                              <button type="button">
                                <Parameter />
                              </button>
                              <div
                                uk-dropdown="mode: click; pos: right-center"
                                className="chatTextDropdown"
                              >
                                <div className="dropdownHeading">
                                  <span>Data fields</span>
                                </div>
                                <hr />
                                <ul>
                                  <li>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        insertTag(
                                          section.id,
                                          selectedDialog?.dialogueTitle +
                                            "_" +
                                            index,
                                          " {display_name} "
                                        )
                                      }
                                    >
                                      Display name{" "}
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        insertTag(
                                          section.id,
                                          selectedDialog?.dialogueTitle +
                                            "_" +
                                            index,
                                          " {contact_number} "
                                        )
                                      }
                                    >
                                      Contact number
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ul>
                        {openEmoji && (
                          <Picker
                            onEmojiClick={handleEmojiClick(
                              selectedDialog?.dialogueTitle + "_" + index,
                              section.id
                            )}
                            disableAutoFocus={true}
                            native=""
                          />
                        )}
                      </div>
                    </div>
                    <div className="textInputBtn">
                      <ul>
                        <li>
                          <button
                            type="button"
                            onClick={() => handleSectionClosing(section.id)}
                          >
                            <Close />
                          </button>
                        </li>
                        <li>
                          <button type="button">{/* <Copy /> */}</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {/* Send Text end */}

              {/* Send image start */}
              {/* Send image start */}
              {section.type === "image" && (
                <>
                  <div className="textInputField">
                    <div className="inputFilesWrp">
                      <label htmlFor="">Send image </label>
                      <div className="fileInputAndBtn">
                        <div
                          uk-form-custom="target: true"
                          className="fileInputDiv"
                        >
                          <div className="fileInsideImg">
                            <Image />
                          </div>
                          <div className="fileInputAndBtn"></div>
                          <input
                            type="file"
                            aria-label="Custom controls"
                            className="fileInput1"
                            id={selectedDialog?.dialogueTitle + "_" + index}
                            onChange={(e) => handleFileI(e, "image", index)}
                            // onChange={(e)=>handleFile(e)}
                          />
                          <input
                            className="uk-input uk-form-width-medium fileInput2 "
                            type="text"
                            id={selectedDialog?.dialogueTitle + "_" + index}
                            aria-label="Custom controls"
                            placeholder="Upload Image"
                            disabled
                          />
                        </div>
                        <div className="textInputBtn">
                          <ul>
                            <li>
                              <button
                                type="button"
                                onClick={() => handleSectionClosing(section.id)}
                              >
                                <Close />
                              </button>
                            </li>
                            <li>
                              <button type="button">{/* <Copy /> */}</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="textInputField uk-margin-remove-top">
                    <div className="textInputLabel">
                      <label htmlFor="">Image caption</label>
                      <div
                        className="txtCounter"
                        style={{
                          position: "relative",
                          top: "unset",
                          right: "unset",
                        }}
                      >
                        
                      </div>
                    </div>
                    <div className="textAreaAndBtn">
                      <div className="textInputWrp">
                        <textarea
                          name=""
                          value={section.caption}
                          onChange={(e) =>
                            handleInputChangeSection(
                              section.id,
                              "caption",
                              e.target.value
                            )
                          }
                          className="uk-textarea"
                          placeholder="Enter your text"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Send Image end */}

              {/* Send audio start */}
              {section.type === "audio" && (
                <div className="textInputField">
                  <div className="inputFilesWrp">
                    <label htmlFor="">Send audio</label>
                    <div className="fileInputAndBtn">
                      <div
                        uk-form-custom="target: true"
                        className="fileInputDiv"
                      >
                        <div className="fileInsideImg">
                          <DocumentAudio />
                        </div>
                        <div className="fileInputAndBtn"></div>
                        <input
                          type="file"
                          aria-label="Custom controls"
                          className="fileInput1"
                        />
                        <input
                          className="uk-input uk-form-width-medium fileInput2"
                          type="text"
                          placeholder="Upload audio"
                          aria-label="Custom controls"
                          disabled
                        />
                      </div>
                      <div className="textInputBtn">
                        <ul>
                          <li>
                            <button
                              type="button"
                              onClick={() => handleSectionClosing(section.id)}
                            >
                              <Close />
                            </button>
                          </li>
                          <li>
                            <button type="button">{/* <Copy /> */}</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Send video end */}
              {section.type === "video" && (
                <div className="textInputField">
                  <div className="inputFilesWrp">
                    <label htmlFor="">Send video</label>
                    <div className="fileInputAndBtn">
                      <div
                        uk-form-custom="target: true"
                        className="fileInputDiv"
                      >
                        <div className="fileInsideImg">
                          <DocumentVideo />
                        </div>
                        <div className="fileInputAndBtn"></div>
                        <input
                          type="file"
                          aria-label="Custom controls"
                          className="fileInput1"
                          id={selectedDialog?.dialogueTitle + "_" + index}
                          onChange={(e) => handleFileV(e, "video", index)}
                        />
                        <input
                          className="uk-input uk-form-width-medium fileInput2"
                          type="text"
                          aria-label="Custom controls"
                          disabled
                          placeholder="Upload Video"
                        />
                      </div>
                      <div className="textInputBtn">
                        <ul>
                          <li>
                            <button
                              type="button"
                              onClick={() => handleSectionClosing(section.id)}
                            >
                              <Close />
                            </button>
                          </li>
                          <li>
                            <button type="button">{/* <Copy /> */}</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="textInputLabel">
                    <label htmlFor="">Video caption</label>
                    <div
                      className="txtCounter"
                      style={{
                        position: "relative",
                        top: "unset",
                        right: "unset",
                      }}
                    >
                      {/* <span>0</span>
                      <span>/</span>
                      <span>4096</span> */}
                    </div>
                  </div>
                  <div className="textAreaAndBtn">
                    <div className="textInputWrp">
                      <textarea
                        name=""
                        value={section.caption} // Use the caption value from your data
                        onChange={(e) =>
                          handleInputChangeSection(
                            section.id,
                            "caption",
                            e.target.value
                          )
                        }
                        className="uk-textarea"
                        placeholder="Enter your text"
                      ></textarea>
                    </div>
                  </div>
                </div>
              )}
              {/* Send video end */}
              {/* Send file start */}
              {section.type === "file" && (
                <div className="textInputField">
                  <div className="inputFilesWrp">
                    <label htmlFor="">Send file</label>
                    <div className="fileInputAndBtn">
                      <div
                        uk-form-custom="target: true"
                        className="fileInputDiv"
                      >
                        <div className="fileInsideImg">
                          <DocumentAttachment />
                        </div>
                        <div className="fileInputAndBtn"></div>
                        <input
                          type="file"
                          aria-label="Custom controls"
                          className="fileInput1"
                          id={selectedDialog?.dialogueTitle + "_" + index}
                          onChange={(e) => handleFileF(e, "file", index)}
                        />
                        <input
                          className="uk-input uk-form-width-medium fileInput2"
                          type="text"
                          placeholder="Upload File"
                          aria-label="Custom controls"
                          disabled
                        />
                      </div>
                      <div className="textInputBtn">
                        <ul>
                          <li>
                            <button
                              type="button"
                              onClick={() => handleSectionClosing(section.id)}
                            >
                              <Close />
                            </button>
                          </li>
                          <li>
                            <button type="button">{/* <Copy /> */}</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="textInputLabel">
                    <label htmlFor="">File caption</label>
                    <div
                      className="txtCounter"
                      style={{
                        position: "relative",
                        top: "unset",
                        right: "unset",
                      }}
                    >
                      {/* <span>0</span>
                      <span>/</span>
                      <span>4096</span> */}
                    </div>
                  </div>
                  <div className="textAreaAndBtn">
                    <div className="textInputWrp">
                      <textarea
                        name=""
                        value={section.caption} // Use the caption value from your data
                        onChange={(e) =>
                          handleInputChangeSection(
                            section.id,
                            "caption",
                            e.target.value
                          )
                        }
                        className="uk-textarea"
                        placeholder="Enter your text"
                      ></textarea>
                    </div>
                  </div>
                </div>
              )}
              {/* Send file end */}
              {/* Send location start */}
              {section.type === "location" && (
                <>
                  <div className="textInputField">
                    <div className="inputFilesWrp">
                      <label htmlFor="">Send location</label>
                      <div className="locationAndBtn">
                        <div className="uk-inline width-90">
                          <button type="button" className="locationBtn">
                            <Location />
                            <br />
                            Add location
                          </button>
                          <div
                            uk-dropdown="mode: click; pos: right-center"
                            className="locationDropdown"
                          >
                            <div className="dropdownHeading">
                              <span>Edit location</span>
                            </div>
                            <div className="formWrp">
                              <div className="uk-grid uk-grid-small" uk-grid="">
                                <div className="uk-width-1-1">
                                  <div className="formLabel">
                                    <span>Select how to get location</span>
                                  </div>
                                  <div className="uk-inline locationInsideDropdown">
                                    <button
                                      className="getLocationBtn"
                                      type="button"
                                    >
                                      Search for location
                                      <ChevronDown />
                                    </button>
                                    <div
                                      uk-dropdown="mode: click"
                                      className="locationDropContent"
                                    >
                                      <div
                                        className="dropdownHeading"
                                        style={{ border: "none" }}
                                      >
                                        <span>How to get location</span>
                                      </div>
                                      <ul>
                                        <li>
                                          <button type="button">
                                            Search for location
                                          </button>
                                        </li>
                                        <li>
                                          <button type="button">
                                            Enter location values
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="uk-width-1-1">
                                  <div className="formLabel">
                                    <span>
                                      Location address or select on a map
                                    </span>
                                  </div>
                                  <div className="formInput">
                                    <input
                                      type="text"
                                      className="uk-input"
                                      placeholder="Start typing..."
                                    />
                                  </div>
                                </div>
                                <div className="uk-width-1-1">
                                  <div className="loctionMap">
                                    <iframe
                                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14480.001322071483!2d67.0756437!3d24.8638383!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33ea47266e6f3%3A0xcecfc18dbea98b94!2sConvex%20Interactive%20(Pvt)%20Ltd.%20Software%20Development%20and%20Digital%20Marketing%20Agency!5e0!3m2!1sen!2s!4v1696946025351!5m2!1sen!2s"
                                      allowfullscreen=""
                                      loading="lazy"
                                      referrerpolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                  </div>
                                </div>
                                <div className="uk-width-1-1">
                                  <div className="btnwrp">
                                    <button type="button" className="btn-1">
                                      Cancel
                                    </button>
                                    <button type="button" className="btn-2">
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="textInputBtn">
                          <ul>
                            <li>
                              <button
                                type="button"
                                onClick={() => handleSectionClosing(section.id)}
                              >
                                <Close />
                              </button>
                            </li>
                            <li>
                              <button type="button">{/* <Copy /> */}</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="textInputField uk-margin-remove-top">
                    <div className="textInputLabel">
                      <label htmlFor="">Location name (optional)</label>
                      <div
                        className="txtCounter"
                        style={{
                          position: "relative",
                          top: "unset",
                          right: "unset",
                        }}
                      >
                        <span>0</span>
                        <span>/</span>
                        <span>4096</span>
                      </div>
                    </div>
                    <div className="textAreaAndBtn">
                      <div className="textInputWrp">
                        <textarea
                          name=""
                          className="uk-textarea"
                          placeholder="Enter your text"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* Send location end */}
              {/* Send Delay start */}
              {section.type === "delay" && (
                <div className="inputFilesWrp">
                  {/* <label htmlFor="">Send location</label> */}
                  <div className="locationAndBtn">
                    <div className="uk-inline width-90">
                      <button type="button" className="locationBtn timeBtn">
                        <Time /> 3 seconds typing delay
                      </button>
                      <div
                        uk-dropdown="mode: click; pos: right-center"
                        className="locationDropdown"
                      >
                        <div className="dropdownHeading">
                          <span>Delay</span>
                        </div>
                        <div className="formWrp">
                          <div className="uk-grid uk-grid-small" uk-grid="">
                            <div className="uk-width-1-1">
                              <div className="formLabel">
                                <span>Delay time</span>
                              </div>
                              <div className="uk-inline locationInsideDropdown uk-flex uk-flex-middle">
                                <button
                                  className="getLocationBtn"
                                  type="button"
                                  style={{ width: "120px" }}
                                >
                                  30
                                  <ChevronDown />
                                </button>
                                <div
                                  uk-dropdown="mode: click"
                                  className="locationDropContent"
                                >
                                  {/* <div
                                            className="dropdownHeading"
                                            style={{ border: "none" }}
                                          >
                                            <span>How to get location</span>
                                          </div>
                                          <ul>
                                            <li>
                                              <button type="button">
                                                Search for location
                                              </button>
                                            </li>
                                            <li>
                                              <button type="button">
                                                Enter location values
                                              </button>
                                            </li>
                                          </ul> */}
                                </div>
                                <span className="secondsTxt">seconds</span>
                              </div>
                            </div>
                            <div className="uk-width-1-1">
                              <div className="btnwrp uk-margin-top">
                                <button type="button" className="btn-1">
                                  Cancel
                                </button>
                                <button type="button" className="btn-2">
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="textInputBtn">
                      <ul>
                        <li>
                          <button
                            type="button"
                            onClick={() => handleSectionClosing(section.id)}
                          >
                            <Close />
                          </button>
                        </li>
                        <li>
                          <button type="button">{/* <Copy /> */}</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {/* Send Delay end */}
              {/* Send Button start */}
              {section.type === "button" && (
                <>
                  <div className="textInputField">
                    <div className="textInputLabel">
                      <label htmlFor="">Add button </label>
                      <div
                        className="txtCounter"
                        style={{
                          position: "relative",
                          top: "unset",
                          right: "unset",
                        }}
                      ></div>

                      <div className="textInputBtn">
                        <ul>
                          <li>
                            <button
                              type="button"
                              onClick={() => {
                                handleSectionClosing(section.id);
                              }}
                            >
                              <Close />
                            </button>
                          </li>
                          <li>
                            <button type="button">{/* <Copy /> */}</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="textAreaAndBtn width-90">
                      <div className="buttonDisplayBtn">
                        <button type="button" className="displayBtn">
                          {section?.value || "New Button"}

                          <div></div>
                        </button>
                      </div>
                      <div className="buttonToggleBtn">
                        <div className="uk-inline width-90">
                          <button type="button" className="toggleBtn">
                            <span></span>
                          </button>
                          <div
                            uk-dropdown="mode: click; pos: right-center"
                            className="locationDropdown"
                            id={`buttonMainDropdown_${selectedDialog?.dialogueTitle?.replace(
                              /\s/g,
                              "_"
                            )}_${index}`}
                          >
                            <div className="dropdownHeading">
                              <span>Edit button </span>
                            </div>
                            <div className="formWrp">
                              <div className="uk-grid uk-grid-small" uk-grid="">
                                <div className="uk-width-1-1">
                                  <div className="formLabel">
                                    <span>Action type </span>
                                  </div>
                                  <div className="uk-inline locationInsideDropdown">
                                    <button
                                      className="getLocationBtn"
                                      type="button"
                                    >
                                      {urlButton}
                                      <ChevronDown />
                                    </button>
                                    <div
                                      uk-dropdown="mode: click"
                                      className="locationDropContent"
                                      id="builderDropdown"
                                    >
                                      <div
                                        className="dropdownHeading"
                                        style={{ border: "none" }}
                                      >
                                        <span>Action type</span>
                                      </div>
                                      <ul>
                                        <li>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              builderDropdownClose();
                                              setUrlButton("Custom");
                                            }}
                                          >
                                            Custom
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="uk-width-1-1">
                                  <div className="formLabel">
                                    <span>
                                      {" "}
                                      {urlButton === "URL"
                                        ? "URL text"
                                        : "Button text"}
                                    </span>
                                    <div
                                      className="txtCounter"
                                      style={{
                                        position: "relative",
                                        top: "unset",
                                        right: "unset",
                                      }}
                                    >
                                      <span>
                                        {section?.value === undefined
                                          ? 0
                                          : section?.value?.length}
                                      </span>
                                      <span>/</span>
                                      <span>20</span>
                                    </div>
                                  </div>
                                  <div className="formInput">
                                    <input
                                      type="text"
                                      className="uk-input"
                                      placeholder={
                                        urlButton === "URL"
                                          ? "Enter Url here"
                                          : "Enter button name"
                                      }
                                      id={`${selectedDialog?.dialogueTitle?.replace(
                                        /\s/g,
                                        "_"
                                      )}_${index}`}
                                      name={`${selectedDialog?.dialogueTitle?.replace(
                                        /\s/g,
                                        "_"
                                      )}_${index}`}
                                      value={section.value}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue.length <= 20) {
                                          handleInputChangeSectionButton(
                                            section.id,
                                            "value",
                                            e.target.value
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                {urlButton === "URL" ? null : (
                                  <div className="uk-width-1-1">
                                    <div className="formLabel">
                                      <span>When this button is pressed</span>
                                    </div>
                                    <div className="uk-inline locationInsideDropdown">
                                      <button
                                        className="getLocationBtn"
                                        type="button"
                                      >
                                        {section?.detail?.type === "typeAgent"
                                          ? `Assign to agent`
                                          : section?.detail?.type ===
                                            "typeDialog"
                                          ? "Click to select a dialog "
                                          : section?.detail?.type === "typeForm"
                                          ? "Click to select a form"
                                          : section?.detail?.type === "typeTeam"
                                          ? "Click to select a team"
                                          : section?.detail?.type ===
                                            "typeCatalog"
                                          ? "Select a catalogue"
                                          : section?.detail?.type ===
                                            "typeExternal"
                                          ? "External Request"
                                          : "Assign to agent"}

                                        <ChevronDown />
                                      </button>
                                      <div
                                        uk-dropdown="mode: click"
                                        className="locationDropContent assignUserDropdown"
                                        id={`actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}`}
                                      >
                                        <ul>
                                          <li>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                toggleDialogHidden(index);
                                                closeButtonPressDrop(
                                                  `#actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                                    /\s/g,
                                                    "_"
                                                  )}_${index}`
                                                );
                                                setSectionDetailType(
                                                  index,
                                                  "typeAgent",
                                                  section.id
                                                );
                                              }}
                                            >
                                              Assign to agent
                                            </button>
                                          </li>

                                          <li className="">
                                            <button
                                              type="button"
                                              onClick={() => {
                                                toggleActionVisibility(index);

                                                closeActionDropdown(
                                                  `#actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                                    /\s/g,
                                                    "_"
                                                  )}_${index}`
                                                );

                                                setSectionDetailType(
                                                  index,
                                                  "typeDialog",
                                                  section.id
                                                );
                                              }}
                                            >
                                              Click to select a dialog
                                            </button>
                                          </li>

                                          <li>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                toggleFormVisibility(index);

                                                closeActionDropdown(
                                                  `#actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                                    /\s/g,
                                                    "_"
                                                  )}_${index}`
                                                );
                                                setSectionDetailType(
                                                  index,
                                                  "typeForm",
                                                  section.id
                                                );
                                              }}
                                            >
                                              Click to select a form
                                            </button>
                                          </li>

                                          <li>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                toggleFormVisibility(index);
                                                closeActionDropdown(
                                                  `#actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                                    /\s/g,
                                                    "_"
                                                  )}_${index}`
                                                );
                                                setSectionDetailType(
                                                  index,
                                                  "typeTeam",
                                                  section.id
                                                );
                                              }}
                                            >
                                              Click to select a team
                                            </button>
                                          </li>

                                          <li>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                toggleDialogHidden(index);
                                                closeButtonPressDrop(
                                                  `#actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                                    /\s/g,
                                                    "_"
                                                  )}_${index}`
                                                );

                                                setSectionDetailType(
                                                  index,
                                                  "typeCatalog",
                                                  section.id
                                                );
                                              }}
                                            >
                                              Select a catalogue
                                            </button>
                                          </li>

                                          <li>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                toggleDialogHidden(index);
                                                closeButtonPressDrop(
                                                  `#actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                                    /\s/g,
                                                    "_"
                                                  )}_${index}`
                                                );

                                                setSectionDetailType(
                                                  index,
                                                  "typeExternal",
                                                  section.id
                                                );
                                              }}
                                            >
                                              External request
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {paramsState ? (
                                  section?.detail?.type === "typeAgent" ? (
                                    <>
                                      <div className="uk-width-1-1">
                                        <div className="uk-inline locationInsideDropdown">
                                          <button
                                            className="getLocationBtn"
                                            type="button"
                                          >
                                            {selectedDialogOnButton[
                                              section.id
                                            ] ||
                                              section?.detail?.selectValue ||
                                              "Select an option"}
                                            <ChevronDown />
                                          </button>
                                          <div
                                            uk-dropdown="mode: click"
                                            className="locationDropContent customDialogDropdown"
                                            id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                              /\s/g,
                                              "_"
                                            )}_${index}`}
                                          >
                                            <div
                                              className="dropdownHeading"
                                              style={{ border: "none" }}
                                            >
                                              <span>Routings </span>
                                            </div>
                                            <ul>
                                              <li>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleDialogSelectOnButton(
                                                      "Same page",
                                                      section.id
                                                    );
                                                    closeUserDialogDrop(
                                                      `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                        /\s/g,
                                                        "_"
                                                      )}_${index}`
                                                    );
                                                  }}
                                                >
                                                  Same Page
                                                </button>
                                              </li>
                                              <li>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleDialogSelectOnButton(
                                                      "Next page",
                                                      section.id
                                                    );
                                                    closeUserDialogDrop(
                                                      `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                        /\s/g,
                                                        "_"
                                                      )}_${index}`
                                                    );
                                                  }}
                                                >
                                                  Next Page
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>

                                      {/* timingsx */}
                                      <div className="uk-width-1-1">
                                        <div className="formLabel">
                                          <span>Timings</span>
                                        </div>

                                        <div className="uk-inline locationInsideDropdown">
                                          <button
                                            className="getLocationBtn"
                                            type="button"
                                          >
                                            {handleDialogSelectOnButtonTimings[
                                              section.id
                                            ] ||
                                              section?.timings?.type ||
                                              "Select timings"}
                                            <ChevronDown />
                                          </button>
                                          <div
                                            uk-dropdown="mode: click"
                                            className="locationDropContent customDialogDropdown"
                                            id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                              /\s/g,
                                              "_"
                                            )}_${index}`}
                                          >
                                            <ul>
                                              <li>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleDialogSelectOnButtonTimings(
                                                      "Default",
                                                      section.id
                                                    );
                                                    closeUserDialogDrop(
                                                      `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                        /\s/g,
                                                        "_"
                                                      )}_${index}`
                                                    );
                                                  }}
                                                >
                                                  Default
                                                </button>
                                              </li>
                                              <li>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleDialogSelectOnButtonTimings(
                                                      "Manual",
                                                      section.id
                                                    );
                                                    closeUserDialogDrop(
                                                      `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                        /\s/g,
                                                        "_"
                                                      )}_${index}`
                                                    );
                                                  }}
                                                >
                                                  Manual
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      {/* time start and end */}
                                      {section?.timings?.type === "Manual" ||
                                      handleDialogSelectOnButtonTimings[
                                        section.id
                                      ] === "Manual" ? (
                                        <>
                                          <div className="uk-width-1-1">
                                            <div className="formLabel">
                                              <span>Start time</span>
                                            </div>
                                            <div className="settingDropdown">
                                              <input
                                                className="dropdownBtn uk-input"
                                                id="appt-time"
                                                type="time"
                                                name="appt-time"
                                                defaultValue="00"
                                                value={
                                                  section?.timings?.startTime
                                                }
                                                onChange={(e) =>
                                                  handleTimeChange(
                                                    section.id,
                                                    "startTime",
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  width: "100% !important",
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="uk-width-1-1">
                                            <div className="formLabel">
                                              <span>End time</span>
                                            </div>
                                            <div className="settingDropdown">
                                              <input
                                                className="dropdownBtn uk-input"
                                                id="appt-time"
                                                type="time"
                                                name="appt-time"
                                                defaultValue="00"
                                                value={
                                                  section?.timings?.endTime
                                                }
                                                onChange={(e) =>
                                                  handleTimeChange(
                                                    section.id,
                                                    "endTime",
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  width: "100% !important",
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="uk-width-1-1">
                                            <div className="textInputWrp">
                                              <textarea
                                                name=""
                                                className="uk-textarea"
                                                placeholder="Enter your text"
                                                value={
                                                  section?.timings?.timingsText
                                                }
                                                onChange={(e) => {
                                                  handleInputTimingsText(
                                                    section.id,
                                                    "timingsText",
                                                    e.target.value
                                                  );
                                                }}
                                              ></textarea>
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                      {/* time start and end */}
                                    </>
                                  ) : section?.detail?.type === "typeDialog" ? (
                                    <div className="uk-width-1-1">
                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          {selectedDialogOnButton[section.id] ||
                                            section?.detail?.selectValue ||
                                            "Select an existing dialog "}
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent customDialogDropdown"
                                          id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}`}
                                        >
                                          <div
                                            className="dropdownHeading"
                                            style={{ border: "none" }}
                                          >
                                            <span>Dialogs </span>
                                          </div>
                                          <ul>
                                            {sections?.map((item) => (
                                              <li key={item.id}>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleDialogSelectOnButton(
                                                      item.dialogueTitle,
                                                      section.id,
                                                      item.dialogueId
                                                    );
                                                    closeUserDialogDrop(
                                                      `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                        /\s/g,
                                                        "_"
                                                      )}_${index}`
                                                    );
                                                  }}
                                                >
                                                  {item.dialogueTitle}
                                                </button>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  ) : section?.detail?.type === "typeForm" ? (
                                    <div className="uk-width-1-1">
                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          {selectedDialogOnButton[section.id] ||
                                            section?.detail?.selectValue ||
                                            "Select an existing form "}
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent customDialogDropdown"
                                          id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}`}
                                        >
                                          <div
                                            className="dropdownHeading"
                                            style={{ border: "none" }}
                                          >
                                            <span>Forms</span>
                                          </div>
                                          <ul>
                                            {formData?.data?.map((item) => (
                                              <li key={item.id}>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleDialogSelectOnButton(
                                                      item.id,
                                                      section.id,
                                                      item.dialogueId
                                                    );
                                                    closeUserDialogDrop(
                                                      `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                        /\s/g,
                                                        "_"
                                                      )}_${index}`
                                                    );
                                                  }}
                                                >
                                                  {item.id}
                                                </button>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  ) : section?.detail?.type === "typeTeam" ? (
                                    <div className="uk-width-1-1">
                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          {selectedDialogOnButton[section.id] ||
                                            section?.detail?.selectValue ||
                                            "Select an existing team "}

                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent customDialogDropdown"
                                          id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}`}
                                        >
                                          <div
                                            className="dropdownHeading"
                                            style={{ border: "none" }}
                                          >
                                            <span>Teams</span>
                                          </div>
                                          <ul>
                                            {teamsData?.map((item) => (
                                              <li key={item.publicId}>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleDialogSelectOnButton(
                                                      item.publicId,
                                                      section.id,
                                                      item.dialogueId,
                                                      item.name
                                                    );
                                                    closeUserDialogDrop(
                                                      `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                        /\s/g,
                                                        "_"
                                                      )}_${index}`
                                                    );
                                                    // setTeamName(item.name)
                                                  }}
                                                >
                                                  {item.name}
                                                </button>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  ) : section?.detail?.type ===
                                    "typeCatalog" ? (
                                    <div className="uk-width-1-1">
                                      <div className="formInput cat ">
                                        <input
                                          type="text"
                                          className="uk-input"
                                          placeholder="Enter product id "
                                          id={`${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}_${index}_header`}
                                          name={`${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}_${index}_header`}
                                          value={section?.detail?.productId}
                                          onChange={(e) => {
                                            setProductId({
                                              ...productId,
                                              [property]: e.target.value,
                                              name: e.target.value,
                                            });

                                            handleInputChangeSectionCatalogue(
                                              section.id,
                                              "productId",
                                              e.target.value,
                                              productId
                                            );
                                          }}
                                        />

                                        <input
                                          type="text"
                                          className="uk-input"
                                          placeholder="Enter product header text"
                                          id={`${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}_quantity`}
                                          name={`${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}_quantity`}
                                          value={
                                            section?.detail?.productHeaderText
                                          }
                                          onChange={(e) => {
                                            setProductHeaderText({
                                              ...productHeaderText,
                                              [property]: e.target.value,
                                              name: e.target.value,
                                            });
                                            handleInputChangeSectionCatalogue(
                                              section.id,
                                              "productHeaderText",
                                              e.target.value,
                                              productHeaderText
                                            );
                                          }}
                                        />

                                        <input
                                          type="text"
                                          className="uk-input"
                                          placeholder="Enter product footer text"
                                          id={`${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}_footer`}
                                          name={`${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}_footer`}
                                          value={
                                            section?.detail?.productFooterText
                                          }
                                          onChange={(e) => {
                                            setProductFooterText({
                                              ...productFooterText,
                                              [property]: e.target.value,
                                              name: e.target.value,
                                            });

                                            handleInputChangeSectionCatalogue(
                                              section.id,
                                              "productFooterText",
                                              e.target.value,
                                              productFooterText
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : section?.detail?.type ===
                                    "typeExternal" ? (
                                    <div className="uk-width-1-1">
                                      <div className="formInput cat ">
                                        <input
                                          type="text"
                                          className="uk-input"
                                          placeholder="Enter external request"
                                          id={`${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}_${index}_header`}
                                          name={`${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}_${index}_header`}
                                          value={
                                            section?.detail?.externalRequest
                                          }
                                          onChange={(e) => {
                                            handleInputChangeSectionButtonExternal(
                                              section.id,
                                              "externalRequest",
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : null
                                ) : section?.detail?.type === "typeAgent" ? (
                                  <>
                                    <div className="uk-width-1-1">
                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          {selectedDialogOnButton[section.id] ||
                                            section?.detail?.selectValue ||
                                            "Select an option"}
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent customDialogDropdown"
                                          id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}`}
                                        >
                                          <div
                                            className="dropdownHeading"
                                            style={{ border: "none" }}
                                          >
                                            <span>Routings </span>
                                          </div>
                                          <ul>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButton(
                                                    "Same page",
                                                    section.id
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                      /\s/g,
                                                      "_"
                                                    )}_${index}`
                                                  );
                                                }}
                                              >
                                                Same Page
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButton(
                                                    "Next page",
                                                    section.id
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                      /\s/g,
                                                      "_"
                                                    )}_${index}`
                                                  );
                                                }}
                                              >
                                                Next Page
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>

                                    {/* timingsx */}
                                    <div className="uk-width-1-1">
                                      <div className="formLabel">
                                        <span>Timings</span>
                                      </div>

                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          {handleDialogSelectOnButtonTimings[
                                            section.id
                                          ] ||
                                            section?.timings?.type ||
                                            "Select timings"}
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent customDialogDropdown"
                                          id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}`}
                                        >
                                          <ul>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButtonTimings(
                                                    "Default",
                                                    section.id
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                      /\s/g,
                                                      "_"
                                                    )}_${index}`
                                                  );
                                                }}
                                              >
                                                Default
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButtonTimings(
                                                    "Manual",
                                                    section.id
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                      /\s/g,
                                                      "_"
                                                    )}_${index}`
                                                  );
                                                }}
                                              >
                                                Manual
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    {/* time start and end */}
                                    {section?.timings?.type === "Manual" ||
                                    handleDialogSelectOnButtonTimings[
                                      section.id
                                    ] === "Manual" ? (
                                      <>
                                        <div className="uk-width-1-1">
                                          <div className="formLabel">
                                            <span>Start time </span>
                                          </div>
                                          <div className="settingDropdown">
                                            <input
                                              className="dropdownBtn uk-input"
                                              id="appt-time"
                                              type="time"
                                              name="appt-time"
                                              defaultValue="00"
                                              value={
                                                section?.timings?.startTime
                                              }
                                              onChange={(e) =>
                                                handleTimeChange(
                                                  section.id,
                                                  "startTime",
                                                  e.target.value
                                                )
                                              }
                                              style={{
                                                width: "100% !important",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                          <div className="formLabel">
                                            <span>End time</span>
                                          </div>
                                          <div className="settingDropdown">
                                            <input
                                              className="dropdownBtn uk-input"
                                              id="appt-time"
                                              type="time"
                                              name="appt-time"
                                              defaultValue="00"
                                              value={section?.timings?.endTime}
                                              onChange={(e) =>
                                                handleTimeChange(
                                                  section.id,
                                                  "endTime",
                                                  e.target.value
                                                )
                                              }
                                              style={{
                                                width: "100% !important",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                          <div className="textInputWrp">
                                            <textarea
                                              name=""
                                              className="uk-textarea"
                                              placeholder="Enter your text"
                                              value={
                                                section?.timings?.timingsText
                                              }
                                              onChange={(e) => {
                                                handleInputTimingsText(
                                                  section.id,
                                                  "timingsText",
                                                  e.target.value
                                                );
                                              }}
                                            ></textarea>
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                    {/* time start and end */}
                                  </>
                                ) : section?.detail?.type === "typeDialog" ? (
                                  <div className="uk-width-1-1">
                                    <div className="uk-inline locationInsideDropdown">
                                      <button
                                        className="getLocationBtn"
                                        type="button"
                                      >
                                        {selectedDialogOnButton[section.id] ||
                                          section?.detail?.selectValue ||
                                          "Select an existing dialog"}
                                        <ChevronDown />
                                      </button>
                                      <div
                                        uk-dropdown="mode: click"
                                        className="locationDropContent customDialogDropdown"
                                        id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}`}
                                      >
                                        <div
                                          className="dropdownHeading"
                                          style={{ border: "none" }}
                                        >
                                          <span>Dialogs</span>
                                        </div>
                                        <ul>
                                          {sections?.map((item) => (
                                            <li key={item.id}>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButton(
                                                    item.dialogueTitle,
                                                    section.id,
                                                    item.dialogueId
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                      /\s/g,
                                                      "_"
                                                    )}_${index}`
                                                  );
                                                }}
                                              >
                                                {item.dialogueTitle}
                                              </button>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ) : section?.detail?.type === "typeForm" ? (
                                  <div className="uk-width-1-1">
                                    <div className="uk-inline locationInsideDropdown">
                                      <button
                                        className="getLocationBtn"
                                        type="button"
                                      >
                                        {selectedDialogOnButton[section.id] ||
                                          section?.detail?.selectValue ||
                                          "Select an existing form "}
                                        <ChevronDown />
                                      </button>
                                      <div
                                        uk-dropdown="mode: click"
                                        className="locationDropContent customDialogDropdown"
                                        id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}`}
                                      >
                                        <div
                                          className="dropdownHeading"
                                          style={{ border: "none" }}
                                        >
                                          <span>Forms</span>
                                        </div>
                                        <ul>
                                          {formData?.data?.map((item) => (
                                            <li key={item.id}>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButton(
                                                    item.id,
                                                    section.id,
                                                    item.dialogueId
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                      /\s/g,
                                                      "_"
                                                    )}_${index}`
                                                  );
                                                }}
                                              >
                                                {item.id}
                                              </button>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ) : section?.detail?.type === "typeTeam" ? (
                                  <div className="uk-width-1-1">
                                    <div className="uk-inline locationInsideDropdown">
                                      <button
                                        className="getLocationBtn"
                                        type="button"
                                      >
                                        {selectedDialogOnButton[section.id] ||
                                          section?.detail?.selectValue ||
                                          "Select an existing team "}
                                        <ChevronDown />
                                      </button>
                                      <div
                                        uk-dropdown="mode: click"
                                        className="locationDropContent customDialogDropdown"
                                        id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}`}
                                      >
                                        <div
                                          className="dropdownHeading"
                                          style={{ border: "none" }}
                                        >
                                          <span>Teams</span>
                                        </div>
                                        <ul>
                                          {teamsData?.map((item) => (
                                            <li key={item.publicId}>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButton(
                                                    item.publicId,
                                                    section.id,
                                                    item.dialogueId,
                                                    item.name
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                      /\s/g,
                                                      "_"
                                                    )}_${index}`
                                                  );
                                                  setTeamName(item.name);
                                                }}
                                              >
                                                {item.name}
                                              </button>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ) : section?.detail?.type === "typeCatalog" ? (
                                  <div className="uk-width-1-1">
                                    <div className="formInput cat ">
                                      <input
                                        type="text"
                                        className="uk-input"
                                        placeholder="Enter product id "
                                        id={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}_${index}_header`}
                                        name={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}_${index}_header`}
                                        value={section?.detail?.productId}
                                        onChange={(e) => {
                                          setProductId({
                                            ...productId,
                                            [property]: e.target.value,
                                            name: e.target.value,
                                          });

                                          handleInputChangeSectionCatalogue(
                                            section.id,
                                            "productId",
                                            e.target.value,
                                            productId
                                          );
                                        }}
                                      />

                                      <input
                                        type="text"
                                        className="uk-input"
                                        placeholder="Enter product header text"
                                        id={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}_quantity`}
                                        name={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}_quantity`}
                                        value={
                                          section?.detail?.productHeaderText
                                        }
                                        onChange={(e) => {
                                          setProductHeaderText({
                                            ...productHeaderText,
                                            [property]: e.target.value,
                                            name: e.target.value,
                                          });
                                          handleInputChangeSectionCatalogue(
                                            section.id,
                                            "productHeaderText",
                                            e.target.value,
                                            productHeaderText
                                          );
                                        }}
                                      />

                                      <input
                                        type="text"
                                        className="uk-input"
                                        placeholder="Enter product footer text"
                                        id={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}_footer`}
                                        name={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}_footer`}
                                        value={
                                          section?.detail?.productFooterText
                                        }
                                        onChange={(e) => {
                                          setProductFooterText({
                                            ...productFooterText,
                                            [property]: e.target.value,
                                            name: e.target.value,
                                          });

                                          handleInputChangeSectionCatalogue(
                                            section.id,
                                            "productFooterText",
                                            e.target.value,
                                            productFooterText
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : section?.detail?.type === "typeExternal" ? (
                                  <div className="uk-width-1-1">
                                    <div className="formInput cat ">
                                      <input
                                        type="text"
                                        className="uk-input"
                                        placeholder="Enter external request"
                                        id={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}_${index}_header`}
                                        name={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}_${index}_header`}
                                        value={section?.detail?.externalRequest}
                                        onChange={(e) => {
                                          handleInputChangeSectionButtonExternal(
                                            section.id,
                                            "externalRequest",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                <div className="uk-width-1-1">
                                  <div className="btnwrp uk-margin-top">
                                    <button
                                      type="button"
                                      className="btn-1"
                                      onClick={() =>
                                        closeButtonDropdown(
                                          `#buttonMainDropdown_${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}`
                                        )
                                      }
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      className="btn-2"
                                      onClick={() => {
                                        handleAddButton(
                                          section.id,
                                          selectedDialogOnButton[index],
                                          selectedDialogOnButton[section.id] ||
                                            section?.detail?.selectValue,
                                          section?.detail?.type,
                                          section?.keyword,
                                          section?.detail?.validation,
                                          productId,
                                          productHeaderText,
                                          productFooterText,
                                          section?.detail?.externalRequest
                                        );
                                        closeButtonDropdown(
                                          `#buttonMainDropdown_${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}`,
                                          section.type,
                                          section?.value
                                        );
                                      }}
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Send Button end */}
              {/* Send Text start */}
              {section.type === "action" && (
                <>
                  <div className="textInputField">
                    <div className="textInputLabel">
                      <label htmlFor="">Add Action </label>
                      <div
                        className="txtCounter"
                        style={{
                          position: "relative",
                          top: "unset",
                          right: "unset",
                        }}
                      ></div>

                      <div className="textInputBtn">
                        <ul>
                          <li>
                            <button
                              type="button"
                              onClick={() => handleSectionClosing(section.id)}
                            >
                              <Close />
                            </button>
                          </li>
                          <li>
                            <button type="button"> {/* <Copy /> */}</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="textAreaAndBtn width-90">
                      <div className="buttonDisplayBtn">
                        <button type="button" className="displayBtn">
                          {section?.value || "User Action"}
                        </button>
                      </div>
                      <div className="buttonToggleBtn">
                        <div className="uk-inline width-90">
                          <button type="button" className="buttonNextBtn">
                            <Flash />
                          </button>
                          <div
                            uk-dropdown="mode: click; pos: right-center"
                            className="locationDropdown"
                            id={"userActionMainDropdown" + index}
                          >
                            <div className="dropdownHeading">
                              <span>Edit User Action</span>
                            </div>
                            <div className="formWrp">
                              <div className="uk-grid uk-grid-small" uk-grid="">
                                <div className="uk-width-1-1">
                                  <div className="formLabel">
                                    <span>Action text </span>
                                    <div
                                      className="txtCounter"
                                      style={{
                                        position: "relative",
                                        top: "unset",
                                        right: "unset",
                                      }}
                                    >
                                      <span>
                                        {section?.value === undefined
                                          ? 0
                                          : section?.value?.length}
                                      </span>
                                      <span>/</span>
                                      <span>20</span>
                                    </div>
                                  </div>
                                  <div className="formInput">
                                    <input
                                      type="text"
                                      className="uk-input"
                                      placeholder="Enter action name"
                                      id={`${selectedDialog?.dialogueTitle?.replace(
                                        /\s/g,
                                        "_"
                                      )}_${index}`}
                                      name={`$selectedDialog?.dialogueTitle?.replace(/\s/g, '_')}_${index}`}
                                      value={section.value}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue.length <= 20) {
                                          handleInputChangeSectionButton(
                                            section.id,
                                            "value",
                                            e.target.value
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                {section?.keyword?.map((item, index) => (
                                  <div
                                    className="broadcastContacts"
                                    key={index}
                                  >
                                    <ul>
                                      <li>
                                        <span>
                                          {item.value}

                                          <button
                                            type="button"
                                            onClick={() =>
                                              handleRemoveTriggerAction(index, {
                                                value: item.value,
                                                id: item.id,
                                              })
                                            }
                                          >
                                            <Close />
                                          </button>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                ))}

                                <div className="uk-width-1-1">
                                  <div className="formLabel">
                                    <span>Message contains</span>
                                  </div>
                                  <div className="dilaogModalBtn">
                                    {isKeywordVisibleAction ? (
                                      <input
                                        type="text"
                                        className="uk-input"
                                        placeholder="Enter text here"
                                        id={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}`}
                                        name={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}`}
                                        value={sectionValues}
                                        onChange={handleChange}
                                        onKeyDown={(e) =>
                                          handleKeyPressKeywordSyn(
                                            e,
                                            section.id
                                          )
                                        }
                                        style={{ marginBottom: "8px" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <button
                                      onClick={toggleKeywordVisibilityAction}
                                      type="button"
                                      className="uk-margin-small-bottom"
                                    >
                                      + Keyword
                                    </button>

                                    {/* <button
                                      type="button"
                                      onClick={toggleKeywordVisibilityAction}
                                    >
                                      + Synonyms
                                    </button> */}
                                  </div>
                                  <hr />
                                </div>

                                <div className="uk-width-1-1">
                                  <div className="formLabel">
                                    <span>When this action is pressed</span>
                                  </div>
                                  <div className="uk-inline locationInsideDropdown">
                                    <button
                                      className="getLocationBtn"
                                      type="button"
                                    >
                                      {section?.detail?.type === "typeAgent"
                                        ? `Assign to agent`
                                        : section?.detail?.type === "typeDialog"
                                        ? "Click to select a dialog "
                                        : section?.detail?.type === "typeForm"
                                        ? "Click to select a form"
                                        : section?.detail?.type === "typeTeam"
                                        ? "Click to select a team"
                                        : section?.detail?.type ===
                                          "typeCatalog"
                                        ? "Select a catalogue"
                                        : section?.detail?.type ===
                                          "typeExternal"
                                        ? "External Request"
                                        : "Assign to agent"}

                                      <ChevronDown />
                                    </button>
                                    <div
                                      uk-dropdown="mode: click"
                                      className="locationDropContent"
                                      id={`actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                        /\s/g,
                                        "_"
                                      )}_${index}`}
                                    >
                                      <ul>
                                        <li>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              toggleDialogHidden(index);
                                              closeButtonPressDrop(
                                                `#actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                                  /\s/g,
                                                  "_"
                                                )}_${index}`
                                              );
                                              setSectionDetailType(
                                                index,
                                                "typeAgent",
                                                section.id
                                              );
                                            }}
                                          >
                                            Assign to agent
                                          </button>
                                        </li>

                                        <li className="">
                                          <button
                                            type="button"
                                            onClick={() => {
                                              toggleActionVisibility(index);

                                              closeActionDropdown(
                                                `#actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                                  /\s/g,
                                                  "_"
                                                )}_${index}`
                                              );

                                              setSectionDetailType(
                                                index,
                                                "typeDialog",
                                                section.id
                                              );
                                            }}
                                          >
                                            Click to select a dialog
                                          </button>
                                        </li>

                                        <li>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              toggleFormVisibility(index);
                                              closeActionDropdown(
                                                `#actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                                  /\s/g,
                                                  "_"
                                                )}_${index}`
                                              );
                                              setSectionDetailType(
                                                index,
                                                "typeForm",
                                                section.id
                                              );
                                            }}
                                          >
                                            Click to select a form
                                          </button>
                                        </li>

                                        <li>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              toggleFormVisibility(index);
                                              closeActionDropdown(
                                                `#actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                                  /\s/g,
                                                  "_"
                                                )}_${index}`
                                              );
                                              setSectionDetailType(
                                                index,
                                                "typeTeam",
                                                section.id
                                              );
                                            }}
                                          >
                                            Click to select a team
                                          </button>
                                        </li>

                                        <li>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              toggleDialogHidden(index);
                                              closeButtonPressDrop(
                                                `#actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                                  /\s/g,
                                                  "_"
                                                )}_${index}`
                                              );

                                              setSectionDetailType(
                                                index,
                                                "typeCatalog",
                                                section.id
                                              );
                                            }}
                                          >
                                            Select a catalogue
                                          </button>
                                        </li>

                                        <li>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              toggleDialogHidden(index);
                                              closeButtonPressDrop(
                                                `#actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                                  /\s/g,
                                                  "_"
                                                )}_${index}`
                                              );

                                              setSectionDetailType(
                                                index,
                                                "typeExternal",
                                                section.id
                                              );
                                            }}
                                          >
                                            External request
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>

                                {paramsState ? (
                                  section?.detail?.type === "typeAgent" ? (
                                    <>
                                      <div className="uk-width-1-1">
                                        <div className="uk-inline locationInsideDropdown">
                                          <button
                                            className="getLocationBtn"
                                            type="button"
                                          >
                                            {selectedDialogOnButton[
                                              section.id
                                            ] ||
                                              section?.detail?.selectValue ||
                                              "Select an option"}
                                            <ChevronDown />
                                          </button>
                                          <div
                                            uk-dropdown="mode: click"
                                            className="locationDropContent customDialogDropdown"
                                            id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                              /\s/g,
                                              "_"
                                            )}_${index}`}
                                          >
                                            <div
                                              className="dropdownHeading"
                                              style={{ border: "none" }}
                                            >
                                              <span>Routings </span>
                                            </div>
                                            <ul>
                                              <li>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleDialogSelectOnButton(
                                                      "Same page",
                                                      section.id
                                                    );
                                                    closeUserDialogDrop(
                                                      `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                        /\s/g,
                                                        "_"
                                                      )}_${index}`
                                                    );
                                                  }}
                                                >
                                                  Same Page
                                                </button>
                                              </li>
                                              <li>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleDialogSelectOnButton(
                                                      "Next page",
                                                      section.id
                                                    );
                                                    closeUserDialogDrop(
                                                      `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                        /\s/g,
                                                        "_"
                                                      )}_${index}`
                                                    );
                                                  }}
                                                >
                                                  Next Page
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>

                                      {/* timingsx */}
                                      <div className="uk-width-1-1">
                                        <div className="formLabel">
                                          <span>Timings</span>
                                        </div>

                                        <div className="uk-inline locationInsideDropdown">
                                          <button
                                            className="getLocationBtn"
                                            type="button"
                                          >
                                            {handleDialogSelectOnButtonTimings[
                                              section.id
                                            ] ||
                                              section?.timings?.type ||
                                              "Select timings"}
                                            <ChevronDown />
                                          </button>
                                          <div
                                            uk-dropdown="mode: click"
                                            className="locationDropContent customDialogDropdown"
                                            id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                              /\s/g,
                                              "_"
                                            )}_${index}`}
                                          >
                                            <ul>
                                              <li>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleDialogSelectOnButtonTimings(
                                                      "Default",
                                                      section.id
                                                    );
                                                    closeUserDialogDrop(
                                                      `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                        /\s/g,
                                                        "_"
                                                      )}_${index}`
                                                    );
                                                  }}
                                                >
                                                  Default
                                                </button>
                                              </li>
                                              <li>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleDialogSelectOnButtonTimings(
                                                      "Manual",
                                                      section.id
                                                    );
                                                    closeUserDialogDrop(
                                                      `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                        /\s/g,
                                                        "_"
                                                      )}_${index}`
                                                    );
                                                  }}
                                                >
                                                  Manual
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      {/* time start and end */}
                                      {section?.timings?.type === "Manual" ||
                                      handleDialogSelectOnButtonTimings[
                                        section.id
                                      ] === "Manual" ? (
                                        <>
                                          <div className="uk-width-1-1">
                                            <div className="formLabel">
                                              <span>Start time </span>
                                            </div>
                                            <div className="settingDropdown">
                                              <input
                                                className="dropdownBtn uk-input"
                                                id="appt-time"
                                                type="time"
                                                name="appt-time"
                                                defaultValue="00"
                                                value={
                                                  section?.timings?.startTime
                                                }
                                                onChange={(e) =>
                                                  handleTimeChange(
                                                    section.id,
                                                    "startTime",
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  width: "100% !important",
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="uk-width-1-1">
                                            <div className="formLabel">
                                              <span>End time</span>
                                            </div>
                                            <div className="settingDropdown">
                                              <input
                                                className="dropdownBtn uk-input"
                                                id="appt-time"
                                                type="time"
                                                name="appt-time"
                                                defaultValue="00"
                                                value={
                                                  section?.timings?.endTime
                                                }
                                                onChange={(e) =>
                                                  handleTimeChange(
                                                    section.id,
                                                    "endTime",
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  width: "100% !important",
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="uk-width-1-1">
                                            <div className="textInputWrp">
                                              <textarea
                                                name=""
                                                className="uk-textarea"
                                                placeholder="Enter your text"
                                                value={
                                                  section?.timings?.timingsText
                                                }
                                                onChange={(e) => {
                                                  handleInputTimingsText(
                                                    section.id,
                                                    "timingsText",
                                                    e.target.value
                                                  );
                                                }}
                                              ></textarea>
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                      {/* time start and end */}
                                    </>
                                  ) : section?.detail?.type === "typeDialog" ? (
                                    <div className="uk-width-1-1">
                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          {selectedDialogOnButton[section.id] ||
                                            section?.detail?.selectValue ||
                                            "Select an existing dialog"}
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent customDialogDropdown"
                                          id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}`}
                                        >
                                          <div
                                            className="dropdownHeading"
                                            style={{ border: "none" }}
                                          >
                                            <span>Dialogs</span>
                                          </div>
                                          <ul>
                                            {sections?.map((item) => (
                                              <li key={item.id}>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleDialogSelectOnButton(
                                                      item.dialogueTitle,
                                                      section.id,
                                                      item.dialogueId
                                                    );
                                                    closeUserDialogDrop(
                                                      `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                        /\s/g,
                                                        "_"
                                                      )}_${index}`
                                                    );
                                                  }}
                                                >
                                                  {item.dialogueTitle}
                                                </button>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  ) : section?.detail?.type === "typeForm" ? (
                                    <div className="uk-width-1-1">
                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          {selectedDialogOnButton[section.id] ||
                                            section?.detail?.selectValue ||
                                            "Select an existing form "}
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent customDialogDropdown"
                                          id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}`}
                                        >
                                          <div
                                            className="dropdownHeading"
                                            style={{ border: "none" }}
                                          >
                                            <span>Forms</span>
                                          </div>
                                          <ul>
                                            {formData?.data?.map((item) => (
                                              <li key={item.id}>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleDialogSelectOnButton(
                                                      item.id,
                                                      section.id,
                                                      item.dialogueId
                                                    );
                                                    closeUserDialogDrop(
                                                      `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                        /\s/g,
                                                        "_"
                                                      )}_${index}`
                                                    );
                                                  }}
                                                >
                                                  {item.id}
                                                </button>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  ) : section?.detail?.type === "typeTeam" ? (
                                    <div className="uk-width-1-1">
                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          {selectedDialogOnButton[section.id] ||
                                            section?.detail?.selectValue ||
                                            "Select an existing team "}

                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent customDialogDropdown"
                                          id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}`}
                                        >
                                          <div
                                            className="dropdownHeading"
                                            style={{ border: "none" }}
                                          >
                                            <span>Teams</span>
                                          </div>
                                          <ul>
                                            {teamsData?.map((item) => (
                                              <li key={item.publicId}>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleDialogSelectOnButton(
                                                      item.publicId,
                                                      section.id,
                                                      item.dialogueId,
                                                      item.name
                                                    );
                                                    closeUserDialogDrop(
                                                      `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                        /\s/g,
                                                        "_"
                                                      )}_${index}`
                                                    );
                                                  }}
                                                >
                                                  {item.name}
                                                </button>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  ) : section?.detail?.type ===
                                    "typeCatalog" ? (
                                    <div className="uk-width-1-1">
                                      <div className="formInput cat ">
                                        <input
                                          type="text"
                                          className="uk-input"
                                          placeholder="Enter product id "
                                          id={`${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}_${index}_header`}
                                          name={`${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}_${index}_header`}
                                          value={section?.detail?.productId}
                                          onChange={(e) => {
                                            setProductId({
                                              ...productId,
                                              [property]: e.target.value,
                                              name: e.target.value,
                                            });

                                            handleInputChangeSectionCatalogue(
                                              section.id,
                                              "productId",
                                              e.target.value,
                                              productId
                                            );
                                          }}
                                        />

                                        <input
                                          type="text"
                                          className="uk-input"
                                          placeholder="Enter product header text"
                                          id={`${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}_quantity`}
                                          name={`${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}_quantity`}
                                          value={
                                            section?.detail?.productHeaderText
                                          }
                                          onChange={(e) => {
                                            setProductHeaderText({
                                              ...productHeaderText,
                                              [property]: e.target.value,
                                              name: e.target.value,
                                            });
                                            handleInputChangeSectionCatalogue(
                                              section.id,
                                              "productHeaderText",
                                              e.target.value,
                                              productHeaderText
                                            );
                                          }}
                                        />

                                        <input
                                          type="text"
                                          className="uk-input"
                                          placeholder="Enter product footer text"
                                          id={`${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}_footer`}
                                          name={`${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}_footer`}
                                          value={
                                            section?.detail?.productFooterText
                                          }
                                          onChange={(e) => {
                                            setProductFooterText({
                                              ...productFooterText,
                                              [property]: e.target.value,
                                              name: e.target.value,
                                            });

                                            handleInputChangeSectionCatalogue(
                                              section.id,
                                              "productFooterText",
                                              e.target.value,
                                              productFooterText
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : section?.detail?.type ===
                                    "typeExternal" ? (
                                    <div className="uk-width-1-1">
                                      <div className="formInput cat ">
                                        <input
                                          type="text"
                                          className="uk-input"
                                          placeholder="Enter external request"
                                          id={`${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}_${index}_header`}
                                          name={`${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}_${index}_header`}
                                          value={
                                            section?.detail?.externalRequest
                                          }
                                          onChange={(e) => {
                                            handleInputChangeSectionButtonExternal(
                                              section.id,
                                              "externalRequest",
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : null
                                ) : section?.detail?.type === "typeAgent" ? (
                                  <>
                                    <div className="uk-width-1-1">
                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          {selectedDialogOnButton[section.id] ||
                                            section?.detail?.selectValue ||
                                            "Select an option"}
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent customDialogDropdown"
                                          id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}`}
                                        >
                                          <div
                                            className="dropdownHeading"
                                            style={{ border: "none" }}
                                          >
                                            <span>Routings </span>
                                          </div>
                                          <ul>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButton(
                                                    "Same page",
                                                    section.id
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                      /\s/g,
                                                      "_"
                                                    )}_${index}`
                                                  );
                                                }}
                                              >
                                                Same Page
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButton(
                                                    "Next page",
                                                    section.id
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                      /\s/g,
                                                      "_"
                                                    )}_${index}`
                                                  );
                                                }}
                                              >
                                                Next Page
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>

                                    {/* timingsx */}
                                    <div className="uk-width-1-1">
                                      <div className="formLabel">
                                        <span>Timings</span>
                                      </div>

                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          {handleDialogSelectOnButtonTimings[
                                            section.id
                                          ] ||
                                            section?.timings?.type ||
                                            "Select timings"}
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent customDialogDropdown"
                                          id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                            /\s/g,
                                            "_"
                                          )}_${index}`}
                                        >
                                          <ul>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButtonTimings(
                                                    "Default",
                                                    section.id
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                      /\s/g,
                                                      "_"
                                                    )}_${index}`
                                                  );
                                                }}
                                              >
                                                Default
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButtonTimings(
                                                    "Manual",
                                                    section.id
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                      /\s/g,
                                                      "_"
                                                    )}_${index}`
                                                  );
                                                }}
                                              >
                                                Manual
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    {/* time start and end */}
                                    {section?.timings?.type === "Manual" ||
                                    handleDialogSelectOnButtonTimings[
                                      section.id
                                    ] === "Manual" ? (
                                      <>
                                        <div className="uk-width-1-1">
                                          <div className="formLabel">
                                            <span>Start time</span>
                                          </div>
                                          <div className="settingDropdown">
                                            <input
                                              className="dropdownBtn uk-input"
                                              id="appt-time"
                                              type="time"
                                              name="appt-time"
                                              defaultValue="00"
                                              value={
                                                section?.timings?.startTime
                                              }
                                              onChange={(e) =>
                                                handleTimeChange(
                                                  section.id,
                                                  "startTime",
                                                  e.target.value
                                                )
                                              }
                                              style={{
                                                width: "100% !important",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                          <div className="formLabel">
                                            <span>End time</span>
                                          </div>
                                          <div className="settingDropdown">
                                            <input
                                              className="dropdownBtn uk-input"
                                              id="appt-time"
                                              type="time"
                                              name="appt-time"
                                              defaultValue="00"
                                              value={section?.timings?.endTime}
                                              onChange={(e) =>
                                                handleTimeChange(
                                                  section.id,
                                                  "endTime",
                                                  e.target.value
                                                )
                                              }
                                              style={{
                                                width: "100% !important",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                          <div className="textInputWrp">
                                            <textarea
                                              name=""
                                              className="uk-textarea"
                                              placeholder="Enter your text"
                                              value={
                                                section?.timings?.timingsText
                                              }
                                              onChange={(e) => {
                                                handleInputTimingsText(
                                                  section.id,
                                                  "timingsText",
                                                  e.target.value
                                                );
                                              }}
                                            ></textarea>
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                    {/* time start and end */}
                                  </>
                                ) : section?.detail?.type === "typeDialog" ? (
                                  <div className="uk-width-1-1">
                                    <div className="uk-inline locationInsideDropdown">
                                      <button
                                        className="getLocationBtn"
                                        type="button"
                                      >
                                        {selectedDialogOnButton[section.id] ||
                                          section?.detail?.selectValue ||
                                          "Select an existing dialog"}
                                        <ChevronDown />
                                      </button>
                                      <div
                                        uk-dropdown="mode: click"
                                        className="locationDropContent customDialogDropdown"
                                        id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}`}
                                      >
                                        <div
                                          className="dropdownHeading"
                                          style={{ border: "none" }}
                                        >
                                          <span>Dialogs</span>
                                        </div>
                                        <ul>
                                          {sections?.map((item) => (
                                            <li key={item.id}>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButton(
                                                    item.dialogueTitle,
                                                    section.id,
                                                    item.dialogueId
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                      /\s/g,
                                                      "_"
                                                    )}_${index}`
                                                  );
                                                }}
                                              >
                                                {item.dialogueTitle}
                                              </button>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ) : section?.detail?.type === "typeForm" ? (
                                  <div className="uk-width-1-1">
                                    <div className="uk-inline locationInsideDropdown">
                                      <button
                                        className="getLocationBtn"
                                        type="button"
                                      >
                                        {selectedDialogOnButton[section.id] ||
                                          section?.detail?.selectValue ||
                                          "Select an existing form "}
                                        <ChevronDown />
                                      </button>
                                      <div
                                        uk-dropdown="mode: click"
                                        className="locationDropContent customDialogDropdown"
                                        id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}`}
                                      >
                                        <div
                                          className="dropdownHeading"
                                          style={{ border: "none" }}
                                        >
                                          <span>Forms</span>
                                        </div>
                                        <ul>
                                          {formData?.data?.map((item) => (
                                            <li key={item.id}>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButton(
                                                    item.id,
                                                    section.id,
                                                    item.dialogueId
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                      /\s/g,
                                                      "_"
                                                    )}_${index}`
                                                  );
                                                }}
                                              >
                                                {item.id}
                                              </button>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ) : section?.detail?.type === "typeTeam" ? (
                                  <div className="uk-width-1-1">
                                    <div className="uk-inline locationInsideDropdown">
                                      <button
                                        className="getLocationBtn"
                                        type="button"
                                      >
                                        {selectedDialogOnButton[section.id] ||
                                          section?.detail?.selectValue ||
                                          "Select an existing team "}
                                        <ChevronDown />
                                      </button>
                                      <div
                                        uk-dropdown="mode: click"
                                        className="locationDropContent customDialogDropdown"
                                        id={`getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}`}
                                      >
                                        <div
                                          className="dropdownHeading"
                                          style={{ border: "none" }}
                                        >
                                          <span>Teams</span>
                                        </div>
                                        <ul>
                                          {teamsData?.map((item) => (
                                            <li key={item.publicId}>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButton(
                                                    item.publicId,
                                                    section.id,
                                                    item.dialogueId,
                                                    item.name
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${selectedDialog?.dialogueTitle?.replace(
                                                      /\s/g,
                                                      "_"
                                                    )}_${index}`
                                                  );
                                                  setTeamName(item.name);
                                                }}
                                              >
                                                {item.name}
                                              </button>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ) : section?.detail?.type === "typeCatalog" ? (
                                  <div className="uk-width-1-1">
                                    <div className="formInput cat ">
                                      <input
                                        type="text"
                                        className="uk-input"
                                        placeholder="Enter product id "
                                        id={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}_${index}_header`}
                                        name={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}_${index}_header`}
                                        value={section?.detail?.productId}
                                        onChange={(e) => {
                                          setProductId({
                                            ...productId,
                                            [property]: e.target.value,
                                            name: e.target.value,
                                          });

                                          handleInputChangeSectionCatalogue(
                                            section.id,
                                            "productId",
                                            e.target.value,
                                            productId
                                          );
                                        }}
                                      />

                                      <input
                                        type="text"
                                        className="uk-input"
                                        placeholder="Enter product header text"
                                        id={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}_quantity`}
                                        name={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}_quantity`}
                                        value={
                                          section?.detail?.productHeaderText
                                        }
                                        onChange={(e) => {
                                          setProductHeaderText({
                                            ...productHeaderText,
                                            [property]: e.target.value,
                                            name: e.target.value,
                                          });
                                          handleInputChangeSectionCatalogue(
                                            section.id,
                                            "productHeaderText",
                                            e.target.value,
                                            productHeaderText
                                          );
                                        }}
                                      />

                                      <input
                                        type="text"
                                        className="uk-input"
                                        placeholder="Enter product footer text"
                                        id={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}_footer`}
                                        name={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}_footer`}
                                        value={
                                          section?.detail?.productFooterText
                                        }
                                        onChange={(e) => {
                                          setProductFooterText({
                                            ...productFooterText,
                                            [property]: e.target.value,
                                            name: e.target.value,
                                          });

                                          handleInputChangeSectionCatalogue(
                                            section.id,
                                            "productFooterText",
                                            e.target.value,
                                            productFooterText
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : section?.detail?.type === "typeExternal" ? (
                                  <div className="uk-width-1-1">
                                    <div className="formInput cat ">
                                      <input
                                        type="text"
                                        className="uk-input"
                                        placeholder="Enter external request"
                                        id={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}_${index}_header`}
                                        name={`${selectedDialog?.dialogueTitle?.replace(
                                          /\s/g,
                                          "_"
                                        )}_${index}_${index}_header`}
                                        value={section?.detail?.externalRequest}
                                        onChange={(e) => {
                                          handleInputChangeSectionButtonExternal(
                                            section.id,
                                            "externalRequest",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : null}

                                {/* For validation */}

                                <div className="uk-width-1-1">
                                  <div className="formLabel">
                                    <span>Validation </span>
                                  </div>
                                  <div className="uk-inline locationInsideDropdown">
                                    <button
                                      className="getLocationBtn"
                                      type="button"
                                    >
                                      {/* handleSelectOnButtonValidation[section.id] || */}
                                      {section?.detail?.validation ||
                                        "Choose a validation"}
                                      <ChevronDown />
                                    </button>
                                    <div
                                      uk-dropdown="mode: click"
                                      className="locationDropContent"
                                      id={`actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                        /\s/g,
                                        "_"
                                      )}_${index}`}
                                    >
                                      <ul>
                                        <li>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              handleSelectOnButtonValidation(
                                                "Number",
                                                section.id
                                              );
                                              closeButtonPressDrop(
                                                `#actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                                  /\s/g,
                                                  "_"
                                                )}_${index}`
                                              );
                                            }}
                                          >
                                            Number
                                          </button>
                                        </li>

                                        <li>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              handleSelectOnButtonValidation(
                                                "String",
                                                section.id
                                              );
                                              closeButtonPressDrop(
                                                `#actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                                  /\s/g,
                                                  "_"
                                                )}_${index}`
                                              );
                                            }}
                                          >
                                            String
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              handleSelectOnButtonValidation(
                                                "Media",
                                                section.id
                                              );
                                              closeButtonPressDrop(
                                                `#actionDropdown_${selectedDialog?.dialogueTitle?.replace(
                                                  /\s/g,
                                                  "_"
                                                )}_${index}`
                                              );
                                            }}
                                          >
                                            Media
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>

                                <div className="uk-width-1-1">
                                  <div className="btnwrp uk-margin-top">
                                    <button
                                      type="button"
                                      className="btn-1"
                                      onClick={() =>
                                        closeUserActionDropCancel(
                                          `#userActionMainDropdown${index}`
                                        )
                                      }
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      className="btn-2"
                                      onClick={() => {
                                        handleAddButton(
                                          section.id,
                                          selectedDialogOnButton[index],
                                          selectedDialogOnButton[section.id] ||
                                            section?.detail?.selectValue,
                                          section?.detail?.type,
                                          section?.keyword,
                                          section?.detail?.validation,
                                          productId,
                                          productHeaderText,
                                          productFooterText,
                                          section?.detail?.externalRequest,
                                          section?.detail?.routing
                                        );

                                        closeUserActionDrop(
                                          `#userActionMainDropdown${index}`,
                                          section?.keyword,
                                          section?.type,
                                          section?.value,
                                          "cancel"
                                        );
                                      }}
                                    >
                                      Add action
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* Send Test end */}
              {/* Send User Input start */}
              {section.type === "input" && (
                <div className="textInputField">
                  <div className="textInputLabel">
                    <label htmlFor="">User input</label>
                    <div
                      className="txtCounter"
                      style={{
                        position: "relative",
                        top: "unset",
                        right: "unset",
                      }}
                    >
                      <span>0</span>
                      <span>/</span>
                      <span>640</span>
                    </div>
                  </div>
                  <div className="textAreaAndBtn">
                    <div className="textInputWrp">
                      <textarea
                        name=""
                        className="uk-textarea"
                        placeholder="Enter your text"
                      >
                        {chosenEmoji && (
                          <img
                            src={chosenEmoji.imageUrl}
                            alt={chosenEmoji.emoji}
                          />
                        )}
                      </textarea>
                      <div className="emojiWrp">
                        <ul className="emojiBtnList">
                          <li>
                            <button type="button">
                              {/* <FaceSatisfied />
                              <EmojiPicker onEmojiClick={handleEmojiClick} /> */}
                            </button>
                          </li>
                          <li>
                            <div className="uk-inline">
                              <button type="button">
                                <Parameter />
                              </button>
                              <div
                                uk-dropdown="mode: click; pos: right-center"
                                className="chatTextDropdown"
                              >
                                <div className="dropdownHeading">
                                  <span>Data fields</span>
                                </div>
                                <hr />
                                <ul>
                                  <li>
                                    <button type="button">Display name</button>
                                  </li>
                                  <li>
                                    <button type="button">
                                      Contact number
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="textInputBtn">
                      <ul>
                        <li>
                          <button type="button">
                            <Close />
                          </button>
                        </li>
                        <li>
                          <button type="button">{/* <Copy /> */}</button>
                        </li>
                        <div className="uk-inline width-90">
                          <button type="button" className="popupBtn">
                            <Popup />
                          </button>

                          <div
                            uk-dropdown="mode: click; pos: right-center"
                            className="locationDropdown"
                          >
                            <div className="dropdownHeading">
                              <span>Edit User Input</span>
                            </div>
                            <div className="formWrp">
                              <div className="uk-grid uk-grid-small" uk-grid="">
                                <div className="uk-width-1-1">
                                  <div className="formLabel">
                                    <span>Reply type </span>
                                  </div>
                                  <div className="uk-inline locationInsideDropdown">
                                    <button
                                      className="getLocationBtn"
                                      type="button"
                                    >
                                      Number
                                      <ChevronDown />
                                    </button>
                                    <div
                                      uk-dropdown="mode: click"
                                      className="locationDropContent"
                                    >
                                      <div
                                        className="dropdownHeading"
                                        style={{ border: "none" }}
                                      >
                                        <span>Reply type</span>
                                      </div>
                                      <ul>
                                        <li className="active">
                                          <button type="button">Text</button>
                                        </li>
                                        <li>
                                          <button type="button">Number</button>
                                        </li>
                                        <li>
                                          <button type="button">Email</button>
                                        </li>
                                        <li>
                                          <button type="button">Image</button>
                                        </li>
                                        <li>
                                          <button type="button">
                                            Location
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="uk-width-1-1">
                                  <div className="formLabel">
                                    <span>Actions on successful input</span>
                                  </div>
                                  <div className="dilaogModalBtn">
                                    <button type="button">
                                      Perform actions
                                    </button>
                                  </div>
                                </div>
                                <div className="uk-width-1-1">
                                  <div className="formLabel">
                                    <span>If contact has not responded</span>
                                  </div>
                                  <div className="dilaogModalBtn">
                                    <button
                                      type="button"
                                      onClick={handleOpenDilaogModal}
                                    >
                                      Click to select a dialog
                                    </button>
                                  </div>
                                </div>
                                <div className="uk-width-1-1">
                                  <div className="btnwrp uk-margin-top">
                                    <button type="button" className="btn-1">
                                      Cancel
                                    </button>
                                    <button type="button" className="btn-2">
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {/* Send User Input end */}

              {/*List Send Text start */}
              {section.type === "list" && (
                <div className="textInputField">
                  <div className="textInputLabel">
                    <label htmlFor="">Send Text</label>
                    <div
                      className="txtCounter"
                      style={{
                        position: "relative",
                        top: "unset",
                        right: "unset",
                      }}
                    ></div>
                  </div>
                  <div className="textAreaAndBtn">
                    <div className="textInputWrp">
                      <textarea
                        name="msg_box"
                        id={selectedDialog?.dialogueTitle + "_" + index}
                        value={section.value}
                        onChange={(e) =>
                          handleInputChangeSection(
                            section.id,
                            "value",
                            e.target.value
                          )
                        }
                        className="uk-textarea"
                        placeholder="Enter text "
                        style={{ minHeight: 300 }}
                      ></textarea>
                    </div>
                    <div className="textInputBtn">
                      <ul>
                        <li>
                          <button
                            type="button"
                            onClick={() => handleSectionClosing(section.id)}
                          >
                            <Close />
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {/* List Send Text end */}
            </div>
          ))}

          {loader ? (
            <div className="centerDiv">
              <div uk-spinner="" className="loader"></div>
            </div>
          ) : (
            <>
              {sectionData?.map((section, index) => (
                <div key={index}>
                  {/* Send Text start */}
                  {section.type === "textarea" && (
                    <div className="textInputField">
                      <div className="textInputLabel">
                        <label htmlFor="">Send Text</label>
                        <div
                          className="txtCounter"
                          style={{
                            position: "relative",
                            top: "unset",
                            right: "unset",
                          }}
                        >
                          <span>0</span>
                          <span>/</span>
                          <span>4096</span>
                        </div>
                      </div>
                      <div className="textAreaAndBtn">
                        <div className="textInputWrp">
                          <textarea
                            value={section.value}
                            onChange={(e) =>
                              handleInputChangeSection(
                                section.id,
                                "value",
                                e.target.value
                              )
                            }
                            name=""
                            className="uk-textarea"
                            placeholder="Enter text"
                          >
                            {chosenEmoji && (
                              <img
                                src={chosenEmoji.imageUrl}
                                alt={chosenEmoji.emoji}
                              />
                            )}
                          </textarea>
                          <div className="emojiWrp">
                            <ul className="emojiBtnList">
                              <li>
                                <button type="button">
                                  {/* <FaceSatisfied  onEmojiClick={handleEmojiClick} />  */}
                                </button>
                              </li>
                              <li>
                                <div className="uk-inline">
                                  <button type="button">
                                    <Parameter />
                                  </button>
                                  <div
                                    uk-dropdown="mode: click; pos: right-center"
                                    className="chatTextDropdown"
                                  >
                                    <div className="dropdownHeading">
                                      <span>Data fields</span>
                                    </div>
                                    <hr />
                                    <ul>
                                      <li>
                                        <button type="button">
                                          Display name
                                        </button>
                                      </li>
                                      <li>
                                        <button type="button">
                                          Contact number
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="textInputBtn">
                          <ul>
                            <li>
                              <button
                                type="button"
                                onClick={() => handleSectionClosing(section.id)}
                              >
                                <Close />
                              </button>
                            </li>
                            <li>
                              <button type="button">{/* <Copy /> */}</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Send Text end */}

                  {/* Send image start */}
                  {section.type === "image" && (
                    <div className="textInputField uk-margin-remove-top">
                      <div className="textInputLabel">
                        <label htmlFor="">Image caption</label>
                        <div
                          className="txtCounter"
                          style={{
                            position: "relative",
                            top: "unset",
                            right: "unset",
                          }}
                        >
                          {/* <span>0</span>
                          <span>/</span>
                          <span>50</span> */}
                        </div>
                      </div>
                      <div className="textAreaAndBtn">
                        <div className="textInputWrp">
                          <textarea
                            name=""
                            value={section.caption} // Use the caption value from your data
                            onChange={(e) =>
                              handleInputChangeSection(
                                section.id,
                                "caption",
                                e.target.value
                              )
                            }
                            className="uk-textarea"
                            placeholder="Enter your text"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Send Image end */}

                  {/* Send audio start */}
                  {section.type === "audio" && (
                    <div className="textInputField">
                      <div className="inputFilesWrp">
                        <label htmlFor="">Send audio</label>
                        <div className="fileInputAndBtn">
                          <div
                            uk-form-custom="target: true"
                            className="fileInputDiv"
                          >
                            <div className="fileInsideImg">
                              <DocumentAudio />
                            </div>
                            <div className="fileInputAndBtn"></div>
                            <input
                              type="file"
                              aria-label="Custom controls"
                              className="fileInput1"
                            />
                            <input
                              className="uk-input uk-form-width-medium fileInput2"
                              type="text"
                              placeholder="Upload audio"
                              aria-label="Custom controls"
                              disabled
                            />
                          </div>
                          <div className="textInputBtn">
                            <ul>
                              <li>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleSectionClosing(section.id)
                                  }
                                >
                                  <Close />
                                </button>
                              </li>
                              <li>
                                <button type="button">{/* <Copy /> */}</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Send video end */}
                  {section.type === "video" && (
                    <div className="textInputField">
                      <div className="inputFilesWrp">
                        <label htmlFor="">Send video</label>
                        <div className="fileInputAndBtn">
                          <div
                            uk-form-custom="target: true"
                            className="fileInputDiv"
                          >
                            <div className="fileInsideImg">
                              <DocumentVideo />
                            </div>
                            <div className="fileInputAndBtn"></div>
                            <input
                              type="file"
                              aria-label="Custom controls"
                              className="fileInput1"
                            />
                            <input
                              className="uk-input uk-form-width-medium fileInput2"
                              type="text"
                              placeholder="Upload video"
                              aria-label="Custom controls"
                              disabled
                            />
                          </div>
                          <div className="textInputBtn">
                            <ul>
                              <li>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleSectionClosing(section.id)
                                  }
                                >
                                  <Close />
                                </button>
                              </li>
                              <li>
                                <button type="button">{/* <Copy /> */}</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Send video end */}
                  {/* Send file start */}
                  {section.type === "file" && (
                    <div className="textInputField">
                      <div className="inputFilesWrp">
                        <label htmlFor="">Send file</label>
                        <div className="fileInputAndBtn">
                          <div
                            uk-form-custom="target: true"
                            className="fileInputDiv"
                          >
                            <div className="fileInsideImg">
                              <DocumentAttachment />
                            </div>
                            <div className="fileInputAndBtn"></div>
                            <input
                              type="file"
                              aria-label="Custom controls"
                              className="fileInput1"
                            />
                            <input
                              className="uk-input uk-form-width-medium fileInput2"
                              type="text"
                              placeholder="Upload file"
                              aria-label="Custom controls"
                              disabled
                            />
                          </div>
                          <div className="textInputBtn">
                            <ul>
                              <li>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleSectionClosing(section.id)
                                  }
                                >
                                  <Close />
                                </button>
                              </li>
                              <li>
                                <button type="button">{/* <Copy /> */}</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Send file end */}
                  {/* Send location start */}
                  {section.type === "location" && (
                    <>
                      <div className="textInputField">
                        <div className="inputFilesWrp">
                          <label htmlFor="">Send location</label>
                          <div className="locationAndBtn">
                            <div className="uk-inline width-90">
                              <button type="button" className="locationBtn">
                                <Location />
                                <br />
                                Add location
                              </button>
                              <div
                                uk-dropdown="mode: click; pos: right-center"
                                className="locationDropdown"
                              >
                                <div className="dropdownHeading">
                                  <span>Edit location</span>
                                </div>
                                <div className="formWrp">
                                  <div
                                    className="uk-grid uk-grid-small"
                                    uk-grid=""
                                  >
                                    <div className="uk-width-1-1">
                                      <div className="formLabel">
                                        <span>Select how to get location</span>
                                      </div>
                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          Search for location
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent"
                                        >
                                          <div
                                            className="dropdownHeading"
                                            style={{ border: "none" }}
                                          >
                                            <span>How to get location</span>
                                          </div>
                                          <ul>
                                            <li>
                                              <button type="button">
                                                Search for location
                                              </button>
                                            </li>
                                            <li>
                                              <button type="button">
                                                Enter location values
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="uk-width-1-1">
                                      <div className="formLabel">
                                        <span>
                                          Location address or select on a map
                                        </span>
                                      </div>
                                      <div className="formInput">
                                        <input
                                          type="text"
                                          className="uk-input"
                                          placeholder="Start typing..."
                                        />
                                      </div>
                                    </div>
                                    <div className="uk-width-1-1">
                                      <div className="loctionMap">
                                        <iframe
                                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14480.001322071483!2d67.0756437!3d24.8638383!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33ea47266e6f3%3A0xcecfc18dbea98b94!2sConvex%20Interactive%20(Pvt)%20Ltd.%20Software%20Development%20and%20Digital%20Marketing%20Agency!5e0!3m2!1sen!2s!4v1696946025351!5m2!1sen!2s"
                                          allowfullscreen=""
                                          loading="lazy"
                                          referrerpolicy="no-referrer-when-downgrade"
                                        ></iframe>
                                      </div>
                                    </div>
                                    <div className="uk-width-1-1">
                                      <div className="btnwrp">
                                        <button type="button" className="btn-1">
                                          Cancel
                                        </button>
                                        <button type="button" className="btn-2">
                                          Add
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="textInputBtn">
                              <ul>
                                <li>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleSectionClosing(section.id)
                                    }
                                  >
                                    <Close />
                                  </button>
                                </li>
                               
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="textInputField uk-margin-remove-top">
                        <div className="textInputLabel">
                          <label htmlFor="">Location name (optional)</label>
                          <div
                            className="txtCounter"
                            style={{
                              position: "relative",
                              top: "unset",
                              right: "unset",
                            }}
                          >
                            <span>0</span>
                            <span>/</span>
                            <span>4096</span>
                          </div>
                        </div>
                        <div className="textAreaAndBtn">
                          <div className="textInputWrp">
                            <textarea
                              name=""
                              className="uk-textarea"
                              placeholder="Enter your text"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* Send location end */}
                  {/* Send Delay start */}
                  {section.type === "delay" && (
                    <div className="inputFilesWrp">
                      {/* <label htmlFor="">Send location</label> */}
                      <div className="locationAndBtn">
                        <div className="uk-inline width-90">
                          <button type="button" className="locationBtn timeBtn">
                            <Time /> 3 seconds typing delay
                          </button>
                          <div
                            uk-dropdown="mode: click; pos: right-center"
                            className="locationDropdown"
                          >
                            <div className="dropdownHeading">
                              <span>Delay</span>
                            </div>
                            <div className="formWrp">
                              <div className="uk-grid uk-grid-small" uk-grid="">
                                <div className="uk-width-1-1">
                                  <div className="formLabel">
                                    <span>Delay time</span>
                                  </div>
                                  <div className="uk-inline locationInsideDropdown uk-flex uk-flex-middle">
                                    <button
                                      className="getLocationBtn"
                                      type="button"
                                      style={{ width: "120px" }}
                                    >
                                      30
                                      <ChevronDown />
                                    </button>
                                    <div
                                      uk-dropdown="mode: click"
                                      className="locationDropContent"
                                    >
                                    
                                    </div>
                                    <span className="secondsTxt">seconds</span>
                                  </div>
                                </div>
                                <div className="uk-width-1-1">
                                  <div className="btnwrp uk-margin-top">
                                    <button type="button" className="btn-1">
                                      Cancel
                                    </button>
                                    <button type="button" className="btn-2">
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="textInputBtn">
                          <ul>
                            <li>
                              <button
                                type="button"
                                onClick={() => handleSectionClosing(section.id)}
                              >
                                <Close />
                              </button>
                            </li>
                            <li>
                              <button type="button">{/* <Copy /> */}</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Send Delay end */}
                  {/* Send Button start */}
                  {section.type === "button" && (
                    <>
                      <div className="textInputField">
                        <div className="textInputLabel">
                          <label htmlFor="">Add button</label>
                          <div
                            className="txtCounter"
                            style={{
                              position: "relative",
                              top: "unset",
                              right: "unset",
                            }}
                          >
                            <span>0</span>
                            <span>/</span>
                            <span>20</span>
                          </div>
                        </div>
                        <div className="textAreaAndBtn width-90">
                          <div className="buttonDisplayBtn">
                            <button type="button" className="displayBtn">
                              New Button #1
                            </button>
                          </div>
                          <div className="buttonToggleBtn">
                            <div className="uk-inline width-90">
                              <button type="button" className="toggleBtn">
                                <span></span>
                              </button>
                              <div
                                uk-dropdown="mode: click; pos: right-center"
                                className="locationDropdown"
                              >
                                <div className="dropdownHeading">
                                  <span>Edit button</span>
                                </div>
                                <div className="formWrp">
                                  <div
                                    className="uk-grid uk-grid-small"
                                    uk-grid=""
                                  >
                                    <div className="uk-width-1-1">
                                      <div className="formLabel">
                                        <span>Action type </span>
                                      </div>
                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          Custom
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent"
                                        >
                                          <div
                                            className="dropdownHeading"
                                            style={{ border: "none" }}
                                          >
                                            <span>Action type</span>
                                          </div>
                                          <ul>
                                            <li className="active">
                                              <button type="button">
                                                Custom
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="uk-width-1-1">
                                      <div className="formLabel">
                                        <span>Button text</span>
                                        <div
                                          className="txtCounter"
                                          style={{
                                            position: "relative",
                                            top: "unset",
                                            right: "unset",
                                          }}
                                        >
                                          <span>0</span>
                                          <span>/</span>
                                          <span>4096</span>
                                        </div>
                                      </div>
                                      <div className="formInput">
                                        <input
                                          type="text"
                                          className="uk-input"
                                          placeholder="Button text"
                                        />
                                      </div>
                                    </div>
                                    <div className="uk-width-1-1">
                                      <div className="formLabel">
                                        <span>
                                          When this button is pressed{" "}
                                        </span>
                                      </div>
                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          Assign an user
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent"
                                        >
                                          <ul>
                                            <li className="active">
                                              <button type="button">
                                                Assign to user
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="uk-width-1-1">
                                      <div className="btnwrp uk-margin-top">
                                        <button type="button" className="btn-1">
                                          Cancel
                                        </button>
                                        <button
                                          type="button"
                                          className="btn-2"
                                          onClick={() => {
                                            handleAddButton(
                                              section.id,
                                              selectedDialogOnButton[index],
                                              selectedDialogOnButton[
                                                section.id
                                              ] || section?.detail?.selectValue,
                                              section?.detail?.type,
                                              section?.keyword,
                                              section?.detail?.validation,
                                              productId,
                                              productHeaderText,
                                              productFooterText,
                                              section?.detail?.externalRequest
                                            );

                                            closeButtonDropdown(
                                              `#buttonMainDropdown_${selectedDialog?.dialogueTitle?.replace(
                                                /\s/g,
                                                "_"
                                              )}_${index}`
                                            );
                                          }}
                                        >
                                          Add
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* Send Button end */}
                  {/* Send Text start */}
                  {section.type === "action" && (
                    <>
                      <div className="textInputLabel">
                        <label htmlFor="">Add Action</label>
                      </div>
                      <div className="textInputField">
                        <div className="textAreaAndBtn width-90">
                          <div className="buttonDisplayBtn">
                            <button type="button" className="displayBtn">
                              User action: 1
                            </button>
                          </div>
                          <div className="buttonToggleBtn">
                            <div className="uk-inline width-90">
                              <button type="button" className="toggleBtn">
                                <span></span>
                              </button>
                              <div
                                uk-dropdown="mode: click; pos: right-center"
                                className="locationDropdown userActionDropdown"
                              >
                                <div className="dropdownHeading">
                                  <span>Edit User Action</span>
                                </div>
                                <div className="formWrp">
                                  <div
                                    className="uk-grid uk-grid-small"
                                    uk-grid=""
                                  >
                                    <div className="uk-width-1-1">
                                      <div className="formLabel">
                                        <span>Reply type </span>
                                      </div>
                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          Number
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent"
                                        >
                                          <div
                                            className="dropdownHeading"
                                            style={{ border: "none" }}
                                          >
                                            <span>Reply type</span>
                                          </div>
                                          <ul>
                                            <li className="active">
                                              <button type="button">
                                                Number
                                              </button>
                                            </li>
                                            <li>
                                              <button type="button">
                                                Text
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="uk-width-1-1">
                                      <div className="formLabel">
                                        <span>Message contains</span>
                                      </div>
                                      <div className="dilaogModalBtn">
                                        <button
                                          type="button"
                                          className="uk-margin-small-bottom"
                                        >
                                          + Keyword
                                        </button>
                                        <button type="button">
                                          + Synonyms
                                        </button>
                                      </div>
                                      <hr />
                                    </div>
                                    <div className="uk-width-1-1">
                                      <div className="formLabel">
                                        <span>
                                          Actions on successful input{" "}
                                        </span>
                                      </div>
                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          Select an existing dialog
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent"
                                        >
                                          <div
                                            className="dropdownHeading"
                                            style={{ border: "none" }}
                                          >
                                            <span>Action type</span>
                                          </div>
                                          <ul>
                                            <li>
                                              <button type="button">
                                                Select an existing dialog
                                              </button>
                                            </li>
                                            <li>
                                              <button type="button">
                                                Assign to user
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>

                                      <div className="uk-inline locationInsideDropdown uk-margin-top  ">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          Select Dialog
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent"
                                        >
                                          <ul>
                                            <li>
                                              <button type="button">
                                                dialog
                                              </button>
                                            </li>
                                            <li>
                                              <button type="button">
                                                dialog
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="uk-width-1-1">
                                      <div className="btnwrp uk-margin-top">
                                        <button type="button" className="btn-1">
                                          Cancel
                                        </button>
                                        <button type="button" className="btn-2">
                                          Add
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* Send Test end */}
                  {/* Send User Input start */}
                  {section.type === "input" && (
                    <div className="textInputField">
                      <div className="textInputLabel">
                        <label htmlFor="">User input</label>
                        <div
                          className="txtCounter"
                          style={{
                            position: "relative",
                            top: "unset",
                            right: "unset",
                          }}
                        >
                          <span>0</span>
                          <span>/</span>
                          <span>640</span>
                        </div>
                      </div>
                      <div className="textAreaAndBtn">
                        <div className="textInputWrp">
                          <textarea
                            name=""
                            className="uk-textarea"
                            placeholder="Enter your text"
                          >
                            {chosenEmoji && (
                              <img
                                src={chosenEmoji.imageUrl}
                                alt={chosenEmoji.emoji}
                              />
                            )}
                          </textarea>
                          <div className="emojiWrp">
                            <ul className="emojiBtnList">
                              <li>
                                <button type="button">
                                  {/* <FaceSatisfied />
                              <EmojiPicker onEmojiClick={handleEmojiClick} /> */}
                                </button>
                              </li>
                              <li>
                                <div className="uk-inline">
                                  <button type="button">
                                    <Parameter />
                                  </button>
                                  <div
                                    uk-dropdown="mode: click; pos: right-center"
                                    className="chatTextDropdown"
                                  >
                                    <div className="dropdownHeading">
                                      <span>Data fields</span>
                                    </div>
                                    <hr />
                                    <ul>
                                      <li>
                                        <button type="button">
                                          Display name
                                        </button>
                                      </li>
                                      <li>
                                        <button type="button">
                                          Contact number
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="textInputBtn">
                          <ul>
                            <li>
                              <button type="button">
                                <Close />
                              </button>
                            </li>
                            <li>
                              <button type="button">{/* <Copy /> */}</button>
                            </li>
                            <div className="uk-inline width-90">
                              <button type="button" className="popupBtn">
                                <Popup />
                              </button>

                              <div
                                uk-dropdown="mode: click; pos: right-center"
                                className="locationDropdown"
                              >
                                <div className="dropdownHeading">
                                  <span>Edit User Input</span>
                                </div>
                                <div className="formWrp">
                                  <div
                                    className="uk-grid uk-grid-small"
                                    uk-grid=""
                                  >
                                    <div className="uk-width-1-1">
                                      <div className="formLabel">
                                        <span>Reply type </span>
                                      </div>
                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          Number
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent"
                                        >
                                          <div
                                            className="dropdownHeading"
                                            style={{ border: "none" }}
                                          >
                                            <span>Reply type</span>
                                          </div>
                                          <ul>
                                            <li className="active">
                                              <button type="button">
                                                Text
                                              </button>
                                            </li>
                                            <li>
                                              <button type="button">
                                                Number
                                              </button>
                                            </li>
                                            <li>
                                              <button type="button">
                                                Email
                                              </button>
                                            </li>
                                            <li>
                                              <button type="button">
                                                Image
                                              </button>
                                            </li>
                                            <li>
                                              <button type="button">
                                                Location
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="uk-width-1-1">
                                      <div className="formLabel">
                                        <span>Actions on successful input</span>
                                      </div>
                                      <div className="dilaogModalBtn">
                                        <button type="button">
                                          Perform actions
                                        </button>
                                      </div>
                                    </div>
                                    <div className="uk-width-1-1">
                                      <div className="formLabel">
                                        <span>
                                          If contact has not responded
                                        </span>
                                      </div>
                                      <div className="dilaogModalBtn">
                                        <button
                                          type="button"
                                          onClick={handleOpenDilaogModal}
                                        >
                                          Click to select a dialog
                                        </button>
                                      </div>
                                    </div>
                                    <div className="uk-width-1-1">
                                      <div className="btnwrp uk-margin-top">
                                        <button type="button" className="btn-1">
                                          Cancel
                                        </button>
                                        <button type="button" className="btn-2">
                                          Add
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Send User Input end */}
                </div>
              ))}
            </>
          )}

          <div className={`methodBtns ${!selectSection ? "btnDisabled" : ""}`}>
            <div className="uk-grid uk-grid-small" uk-grid="">
              <div className="uk-width-1-2">
                <div className="btnwrp">
                  <button
                    type="button"
                    onClick={() => addSection("textarea")}
                    className={hasTwoItems ? "disabled" : ""}
                    disabled={hasTwoItems || disableItem || disableAll}
                  >
                    <TextAlignJustify />
                    Text
                  </button>
                </div>
              </div>

              <div
                className="uk-width-1-2"
                title={hasImage ? "Only one image is allowed" : ""}
              >
                <div className="btnwrp">
                  <button
                    type="button"
                    onClick={() => addSection("image")}
                    className={hasImage || hasTwoItems ? "disabled" : ""}
                    disabled={
                      hasImage || hasTwoItems || disableItem || disableAll
                    }
                  >
                    <Image />
                    Image
                  </button>
                </div>
              </div>

              <div
                className="uk-width-1-2"
                title={hasVideo ? "Only one video is allowed" : ""}
              >
                <div className="btnwrp">
                  <button
                    type="button"
                    onClick={() => addSection("video")}
                    className={hasVideo || hasTwoItems ? "disabled" : ""}
                    disabled={
                      hasTwoItems || hasVideo || disableItem || disableAll
                    }
                  >
                    <DocumentVideo />
                    Video
                  </button>
                </div>
              </div>
              <div
                className="uk-width-1-2"
                title={hasFile ? "Only one file is allowed" : ""}
              >
                <div className="btnwrp">
                  <button
                    type="button"
                    onClick={() => addSection("file")}
                    className={hasFile || hasTwoItems ? "disabled" : ""}
                    disabled={
                      hasTwoItems || hasFile || disableItem || disableAll
                    }
                  >
                    <DocumentAttachment />
                    File
                  </button>
                </div>
              </div>
              <div className="uk-width-1-2">
                <div className="btnwrp" title="Only two items are allowed">
                  <button
                    type="button"
                    onClick={() => {
                      addSection("button");
                    }}
                    className={hasTwoItems ? "disabled" : ""}
                    disabled={hasTwoItems || btnCount || listMenuDisable}
                  >
                    <TableOfContents />
                    Button
                  </button>
                </div>
              </div>
              <div className="uk-width-1-2">
                <div className="btnwrp">
                  <button
                    type="button"
                    onClick={() => {
                      addSection("action");
                    }}
                  >
                    <Flash />
                    User Action
                  </button>
                </div>
              </div>

              {/* <div className="uk-width-1-2">
                <div className="btnwrp">
                  <button
                    type="button"
                    onClick={handleOpenListModal}
                    disabled={
                      hasTwoItems ||
                      hasVideo ||
                      disableItem ||
                      disableAll ||
                      listMenuDisable
                    }
                  >
                    <TableOfContents />
                    Add List
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {openListModal && (
        <ListMenuModal handleCloseListModal={handleCloseListModal} />
      )}
    </div>
  );
};

export default DialogChatbot;
