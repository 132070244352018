import {
  GenderFemale,
  MobileCheck,
  Time,
  GenderMale,
} from "@carbon/icons-react";
import starsImg from "../../../../assets/images/starts.svg";
import { useEffect, useState } from "react";
import TagModal from "../Modals/TagModal";
import PauseAutomationModal from "../Modals/PauseAutomationModal";
import CustomModal from "../Modals/CustomModal";
import { apiPost } from "../../../../Utils/apiServices";
import { toast } from "react-toastify";
import { contactCreated, contactTime } from "../../../../Utils/DateFunctions";
// import AddContact from "../../../Contacts/Modals/AddContact";
import EditContact from "../../../Contacts/Modals/EditContact";

const ChatBotProfile = ({ botContactMessages, multiSelectTags }) => {
  const [multiSelectUsers, setMultiSelectUsers] = useState([]);
  const [addLoader, setAddLoader] = useState(false);
  const [openContactTagModal, setOpenContactTagModal] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [multiSelectAlreadySelectedUsers, setMultiSelectAlreadySelectedUsers] =
    useState();
  const [tagNamesData, setTagNamesData] = useState([]);
  const [openRemoveContactModal, setOpenRemoveContactModal] = useState(false);
  const [displayTags, setDisplayTags] = useState([]);
  const [openPauseAutomationModal, setOpenPauseAutomationModal] =
    useState(false);
  const [openCustomModal, setOpenCustomModal] = useState(false);
  const [openChatEditModal, setOpenChatEditModal] = useState(false);

  //for add tag modal
  const handleOpenContactbotTagsModal = () => {
    setOpenContactTagModal(true);
  };
  const handleCloseContactbotTagsModal = () => {
    setOpenContactTagModal(false);
  };
  // for chat edit modal
  const handleOpenChateditModal = () => {
    setOpenChatEditModal(true);
  };
  const handleCloseChateditModal = () => {
    setOpenChatEditModal(false);
  };
  // for remove tag modal
  const handleOpenRemoveContactbotTagsModal = () => {
    setOpenRemoveContactModal(true);
  };
  const handleCloseRemoveContactbotTagsModal = () => {
    setOpenRemoveContactModal(false);
  };
  // for remove tag modal
  const handleOpenRemoveContactTagsModal = () => {
    setOpenRemoveContactModal(true);
  };
  const handleCloseRemoveContactTagsModal = () => {
    setOpenRemoveContactModal(false);
  };
  // for pause automation modal
  const handleOpenPauseAutomationModal = () => {
    setOpenPauseAutomationModal(true);
  };
  const handleClosePauseAutomationModal = () => {
    setOpenPauseAutomationModal(false);
  };
  // for custom modal
  const handleOpenCustomModal = () => {
    setOpenCustomModal(true);
  };
  const handleCloseCustomModal = () => {
    setOpenCustomModal(false);
  };

  useEffect(() => {
    setMultiSelectUsers(multiSelectTags);
  }, []);

  useEffect(() => {
    if (botContactMessages !== undefined) {
      const updatedArray = botContactMessages?.contact?.contactTags.map(
        (element) => ({
          ...element,
          name: element.tagName,
        })
      );
      setDisplayTags(updatedArray);
      setMultiSelectAlreadySelectedUsers(updatedArray);
    }
  }, [botContactMessages]);
  const addChatbotTags = (e) => {
    e.preventDefault();
    setAddLoader(true);
    let data = [];
    let tagNameData = [];
    userOptions.map((val) => {
      data.push(val.id);
      tagNameData.push(val.tagName);
    });

    setTagNamesData(...tagNamesData, tagNameData);

    apiPost(
      // `/chat/createChatbotTags`,
      `/contact/tags/${botContactMessages?.contact?.publicId}`,
      onSuccessAddChatTag,
      onFailureAddChatTag,
      data
    );
  };
  const onSuccessAddChatTag = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setAddLoader(false);
        toast.success("Tags added successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setDisplayTags(multiSelectAlreadySelectedUsers);
      }, 1200);

      setTimeout(() => {
        handleCloseContactbotTagsModal();
      }, 2000);
    }
  };
  const onFailureAddChatTag = (error) => {
    console.log(error);
    setAddLoader(false);
  };
  const removeChatbotTags = (e) => {
    let data = userOptions;
    e.preventDefault();
    setAddLoader(true);
    apiPost(
      `/chat/deleteTags`,
      onSuccessRemoveChatTag,
      onFailureRemoveChatTag,
      data
    );
  };
  const onSuccessRemoveChatTag = (response) => {
    if (response.status === 1) {
      setTimeout(() => {
        setAddLoader(false);
        toast.success("botTags remove successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setDisplayTags(multiSelectAlreadySelectedUsers);
      }, 1200);

      setTimeout(() => {
        handleCloseRemoveContactbotTagsModal();
      }, 2000);
    }
  };
  const onFailureRemoveChatTag = (error) => {
    console.log(error);
    setAddLoader(false);
  };

  return (
    <div className="uk-width-1-5 uk-padding-remove">
      <ul className="right-sec">
        <li className="namediv">
          <div className="txtwrp">
            <p>{botContactMessages?.contact?.firstName}</p>
          </div>
          <button className="uk-button uk-button-default" type="button">
            <span uk-icon="more"></span>
          </button>
          <div uk-dropdown="mode: click">
            <ul className="uk-nav uk-dropdown-nav drop-content">
              <li onClick={() => handleOpenChateditModal()}>
                <a>
                  <div className="iconwrp">
                    <span uk-icon="pencil"></span>
                  </div>
                  <div className="txtdiv">
                    <span>Edit</span>
                  </div>
                </a>
              </li>
              {/* <li>
                <a href="/chat">
                  <div className="iconwrp">
                    <span uk-icon="ban"></span>
                  </div>
                  <div className="txtdiv">
                    <span>Block Contact</span>
                  </div>
                </a>
              </li> */}
              {/* <li>
                <a href="/chat">
                  <div className="iconwrp">
                    <span uk-icon="download"></span>
                  </div>
                  <div className="txtdiv">
                    <span>Download Data</span>
                  </div>
                </a>
              </li> */}
              {/* <li className="uk-nav-divider"></li>
              <li className="dlt-btn">
                <a href="/chat">
                  <div className="iconwrp">
                    <span uk-icon="trash"></span>
                  </div>
                  <div className="txtdiv">
                    <span>Delete</span>
                  </div>
                </a>
              </li> */}
            </ul>
          </div>
        </li>
        <li className="infodiv">
          <ul>
            <li>
              <div className="iconwrp">
                <img src="assets/images/mobile--check.svg" alt="" />
              </div>
              <div className="txtdiv">
                <span>
                  <MobileCheck /> {botContactMessages?.contact?.mobileNumber}
                </span>
              </div>
            </li>
            <li>
              <div className="iconwrp">
                <img src="assets/images/gender--female.svg" alt="" />
              </div>
              <div className="txtdiv">
                <span style={{ textTransform: "capitilize" }}>
                  {botContactMessages?.contact?.gender === "male" ? (
                    <>
                      <GenderMale /> {botContactMessages?.contact?.gender}
                    </>
                  ) : botContactMessages?.contact?.gender === "female" ? (
                    <>
                      <GenderFemale /> {botContactMessages?.contact?.gender}
                    </>
                  ) : (
                    "-"
                  )}
                </span>
              </div>
            </li>
            <li>
              <div className="txtdiv">
                <span>
                  <Time /> Contact Time:{" "}
                  {contactTime(botContactMessages?.contact?.updateDateTime)}
                </span>
              </div>
            </li>
            <li>
              <div className="txtdiv">
                <span>
                  <Time /> Created:{" "}
                  {contactCreated(botContactMessages?.contact?.createDateTime)}
                </span>
              </div>
            </li>
            {/* <li>
              <div className="txtdiv">
                <span>
                  <MobileCheck /> 5947368681956251
                </span>
              </div>
            </li> */}
          </ul>
        </li>
        {/* <li className="animation-sec">
          <div className="txtwrp">
            <p>Pause Automation</p>
            <span onClick={handleOpenPauseAutomationModal}>
              <span uk-icon="icon: plus; ratio: 0.8"></span> 30 min
            </span>
          </div>
          <div className="btnwrp">
            <button type="button">
              <img src={starsImg} alt="" /> Automatically
            </button>
          </div>
        </li> */}
        {/* <li className="notes-sec">
          <div className="heading">
            <h6>Notes</h6>
          </div>
          <ul id="chatNotesToggle">
            {notesListBot.map((val, index) => {
              return (
                <li key={index}>
                  <div className="note-text">
                    <p>{val.note}</p>
                  </div>
                  <div className="note-time">
                    <span>{profileDateTime(val.createDateTime)}</span>
                  </div>
                </li>
              );
            })}
          </ul>
          <div>
            <button
              className="uk-button uk-button-default toggle-btn show-all-btn"
              type="button"
              uk-toggle="target: #chatNotesToggle"
            >
              Show All <span uk-icon="chevron-down" className="toggle"></span>
            </button>
          </div>
        </li> */}
        <li className="contact-sec opted-sec">
          <div className="txtwrp">
            <p>Contact Tags</p>
            <span onClick={handleOpenContactbotTagsModal}>
              <span uk-icon="icon: plus; ratio: 0.8"></span> Add Tag
            </span>
          </div>
          {displayTags?.map((val, index) => {
            return (
              <div className="btnwrp" key={index}>
                <button type="button">
                  <i className="fa-solid fa-hand"></i> {val.tagName}
                  <span onClick={handleOpenRemoveContactTagsModal}>
                    {val.text} <span uk-icon="icon: close; ratio: 0.8"></span>{" "}
                  </span>
                </button>
              </div>
            );
          })}
        </li>
        <li className="opted-sec">
          <div className="heading">
            <p>Opted in through</p>
          </div>
          <div className="btnwrp">
            <button type="button">
              <i className="fa-solid fa-hand"></i> Get Started
            </button>
          </div>
        </li>
      </ul>
      {openContactTagModal && (
        <TagModal
          closeModal={handleCloseContactbotTagsModal}
          heading={"Add Tag"}
          addUser={addChatbotTags}
          addLoader={addLoader}
          setUserOptions={setUserOptions}
          fieldLabel={"Type to add botTags"}
          saveBtnTxt={"Save"}
          clearBtn={"Clear filter"}
          multiSelectUsers={multiSelectUsers}
          multiSelectAlreadySelectedUsers={multiSelectAlreadySelectedUsers}
          setMultiSelectAlreadySelectedUsers={
            setMultiSelectAlreadySelectedUsers
          }
        />
      )}
      {openRemoveContactModal && (
        <TagModal
          closeModal={handleCloseRemoveContactbotTagsModal}
          heading={"Remove tag"}
          addUser={removeChatbotTags}
          addLoader={addLoader}
          setUserOptions={setUserOptions}
          fieldLabel={"Select botTags to remove"}
          saveBtnTxt={"Remove"}
          clearBtn={"Clear filter"}
          multiSelectUsers={multiSelectUsers}
          multiSelectAlreadySelectedUsers={multiSelectAlreadySelectedUsers}
          setMultiSelectAlreadySelectedUsers={
            setMultiSelectAlreadySelectedUsers
          }
        />
      )}
      {openPauseAutomationModal && (
        <PauseAutomationModal
          closeModal={handleClosePauseAutomationModal}
          heading={"Pause Automation"}
          fieldLabel={"Increase delay by"}
          saveBtnTxt={"Add"}
        />
      )}
      {openCustomModal && (
        <CustomModal
          closeModal={handleCloseCustomModal}
          heading={"Set Custom Field"}
          fieldLabel={"email_address"}
          saveBtnTxt={"Apply"}
        />
      )}
      {openChatEditModal && (
        <EditContact
          closeModal={handleCloseChateditModal}
          multiSelectTags={multiSelectTags}
          formHeading="Edit Chat Contact"
          // setApiCalled={setApiCalled}
          btnText={"Edit Contact"}
          editContactData={botContactMessages?.contact}
        />
      )}
    </div>
  );
};

export default ChatBotProfile;
