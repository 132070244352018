import React, { useState } from "react";
import { apiDelete } from "../../../Utils/apiServices";
import { toast } from "react-toastify";

const DeleteModal = ({ closeModal, deleteContactData, setApiCalled }) => {
  const [dlttext, setDltText] = useState("Invalid");
  const [loader, setLoader] = useState(false);
  const [errorEnable, setErrorEnable] = useState(false);
  const deleteContact = (e) => {
    e.preventDefault();
    setErrorEnable(true);
    setLoader(true);
    if (dlttext === "") {
      apiDelete(
        `/contact/deactive/${deleteContactData?.publicId}`,
        onSuccess,
        onFailure
      );
    } else {
      setLoader(false);
    }
  };
  const onSuccess = (response) => {
    if (response.status === 0) {
      setApiCalled(true);
      setTimeout(() => {
        setLoader(false);
        toast.success("Contact deactivated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 2000);
      setTimeout(() => {
        closeModal();
      }, 5000);
    }
  };
  const onFailure = (error) => {
    console.log(error);
    setLoader(false);
  };
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>Delete contact data</span>
          </div>
          <div className="modalForm">
            <div className="formHeading">
              <span>
                Data deletion is final and cannot be undone. Are you sure?
              </span>
            </div>
            <div className="txtwrp">
              <p>
                Full contact’s profile information will be removed from Berry
                Talks, including Live Chat. Keep in mind that full chat history
                still will be stored by WhatsApp in Chats.
              </p>
              <p>
                We also would like to pay your attention to the fact that if
                this contact’s data was exported to another app — you should
                also delete it there or notify your contact about this
              </p>
            </div>
            <form onSubmit={deleteContact}>
              <div className="formInputs dltModal">
                <input
                  type="text"
                  className="uk-input"
                  placeholder='Confirm by typing "delete" here'
                  onChange={(e) =>
                    e.target.value.toLowerCase() === "delete"
                      ? setDltText("")
                      : setDltText("Invalid")
                  }
                />
                {errorEnable && dlttext === "Invalid" && (
                  <div className="formErrors">
                    Please type delete to proceed
                  </div>
                )}
              </div>
              <div className="btnwrp">
                <button type="button" className="btn-1" onClick={closeModal}>
                  Cancel
                </button>
                <button
                  disabled={dlttext === true ? true : false}
                  className="btn-2 deleteBtn w-90"
                  type="submit"
                >
                  {loader ? (
                    <div uk-spinner="" className="loader"></div>
                  ) : (
                    "Delete"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
