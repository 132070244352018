import React from "react";
import { Link } from "react-router-dom";
import { channelImages } from "../../Utils/ChannelImages";

const NotificationBar = ({ socketNotifications }) => {
  return (
    <div id="offcanvas-usage" uk-offcanvas="">
      <div className="uk-offcanvas-bar notificationCanvas">
        <button
          className="uk-offcanvas-close"
          type="button"
          uk-close=""
        ></button>
        <div className="notificationWrp">
          <div className="topHeading">
            <h3>Notifications</h3>
          </div>
          <div className="highPriority">
            <span>High Priority</span>
          </div>
          <div className="notificationList">
            <ul>
              {/* <li className="notSeen">
                <div className="notifitionItem">
                  <div className="imgwrp">
                    <img src={whatsappIcon} alt="" />
                  </div>
                  <div className="txtwrp">
                    <p>
                      <b>Asma Khalid</b> shared <b>document.pdf</b> for
                      clearance.
                    </p>
                    <span>20 hours ago</span>
                  </div>
                </div>
                <span className="notSeen"></span>
              </li> */}
              {socketNotifications?.map((val, index) => {
                return (
                  <li className="notSeen" key={index}>
                    <Link to="/chat">
                      <div className="notifitionItem">
                        <div className="imgwrp">
                          <img src={channelImages(val.channelId)} alt="" />
                        </div>
                        <div className="txtwrp">
                          {/* <p>
                          <b>Asma Khalid</b> shared <b>document.pdf</b> for
                          clearance.
                        </p> */}
                          <p>{val.notificationContent}</p>
                          {/* <span>20 hours ago</span> */}
                        </div>
                      </div>
                      <span className="notSeen"></span>
                    </Link>
                  </li>
                );
              })}
              {/* <li>
                <div className="notifitionItem">
                  <div className="imgwrp">
                    <img src={whatsappIcon} alt="" />
                  </div>
                  <div className="txtwrp">
                    <p>
                      <b>Asma Khalid</b> shared <b>document.pdf</b> for
                      clearance.
                    </p>
                    <span>20 hours ago</span>
                  </div>
                </div>
                <span className="seen"></span>
              </li>
              <li>
                <div className="notifitionItem">
                  <div className="imgwrp">
                    <img src={whatsappIcon} alt="" />
                  </div>
                  <div className="txtwrp">
                    <p>
                      <b>Asma Khalid</b> shared <b>document.pdf</b> for
                      clearance.
                    </p>
                    <span>20 hours ago</span>
                  </div>
                </div>
                <span className="seen"></span>
              </li>
              <li>
                <div className="notifitionItem">
                  <div className="imgwrp">
                    <img src={whatsappIcon} alt="" />
                  </div>
                  <div className="txtwrp">
                    <p>
                      <b>Asma Khalid</b> shared <b>document.pdf</b> for
                      clearance.
                    </p>
                    <span>20 hours ago</span>
                  </div>
                </div>
                <span className="seen"></span>
              </li>
              <li>
                <div className="notifitionItem">
                  <div className="imgwrp">
                    <img src={whatsappIcon} alt="" />
                  </div>
                  <div className="txtwrp">
                    <p>
                      <b>Asma Khalid</b> shared <b>document.pdf</b> for
                      clearance.
                    </p>
                    <span>20 hours ago</span>
                  </div>
                </div>
                <span className="seen"></span>
              </li>
              <li>
                <div className="notifitionItem">
                  <div className="imgwrp">
                    <img src={whatsappIcon} alt="" />
                  </div>
                  <div className="txtwrp">
                    <p>
                      <b>Asma Khalid</b> shared <b>document.pdf</b> for
                      clearance.
                    </p>
                    <span>20 hours ago</span>
                  </div>
                </div>
                <span className="seen"></span>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationBar;
