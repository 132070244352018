import React from "react";
import {
  displayMessageStatusIcon,
  getToolTipPosition,
} from "../../Utils/chat/chatFunctions";
import { formatChatHoverDateTime } from "../../Utils/DateFunctions";
const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;

const ChatAudio = ({ data }) => {
  return (
    <>
      {data.messageType === "media" ? (
        <div
          className="txtdiv"
          uk-tooltip={`title: ${formatChatHoverDateTime(
            data.timestamp
          )}; pos: ${getToolTipPosition(data.isSent)}`}
        >
          {(data.isSent == "true" || data.isSent == true) &&
            displayMessageStatusIcon(data.messageStatus)}
          <audio controls>
            <source src={data.filePath} type="audio/ogg" />
            Your browser does not support the audio element.
          </audio>
          <div>{data.caption}</div>
        </div>
      ) : (
        <div
          className="txtdiv"
          uk-tooltip={`title: ${formatChatHoverDateTime(
            data.timestamp
          )}; pos: ${getToolTipPosition(data.isSent)}`}
        >
          <audio controls>
            <source
              src={chatBaseUrl + "/" + data?.filePath.replace("assets/", "")}
              type="audio/ogg"
            />
            Your browser does not support the audio element.
          </audio>
          <div>{data.caption}</div>
        </div>
      )}
    </>
  );
};

export default ChatAudio;
