import React, { useState } from "react";
import { Analytics, Calendar, UserMultiple } from "@carbon/icons-react";
import { useEffect } from "react";
import { apiGet } from "../../Utils/apiServices";
import moment from "moment/moment";
import { toast } from "react-toastify";

const EmbStats = () => {
  const [ebmStatsData, setEbmStatsData] = useState();
  const currentDate = moment().format("YYYY-MM-DD");
  const [STARTDATE, setSTARTDATE] = useState("");
  const [ENDDATE, setENDDATE] = useState(currentDate);

  const getEbmStats = () => {
    let api = "";
    if (STARTDATE && ENDDATE) {
      api = `/ebm/getByDateRange?startDate=${STARTDATE}&endDate=${ENDDATE}`;
    } else {
      api = "/ebm/get";
    }

    apiGet(api, onSuccessEbmStats, onFailueEbmStats);
  };

  const onSuccessEbmStats = (response) => {
    setEbmStatsData(response.data);
    console.log(response, "talha");
  };

  const onFailueEbmStats = (error) => {
    console.log(error);
  };

  const handleStartDateChange = (e) => {
    if (moment(e.target.value).isAfter(currentDate)) {
      setSTARTDATE("");
      return;
    }
    setSTARTDATE(e.target.value);
  };

  const handleEndDateChange = (e) => {
    if (moment(e.target.value).isAfter(currentDate)) {
      setENDDATE("");
      return;
    }
    setENDDATE(e.target.value);
  };

  const handleClearFiler = () => {
    setSTARTDATE("");
    setENDDATE("");
  };

  useEffect(() => {
    getEbmStats();
  }, []);
  return (
    <>
      <div className="lorealStatsWrp">
        <div className="uk-container">
          <div className="uk-grid uk-grid-small" uk-grid="">
            <div className="uk-width-1-1">
              <div className="mainHeading">
                <h3>EBM Stats</h3>
              </div>
            </div>
            <div className="uk-width-1-1">
              <div className="filterWrp">
                <div
                  className="uk-grid uk-grid-small uk-flex-right uk-flex-middle"
                  uk-grid=""
                >
                  <div className="uk-width-auto">
                    <label htmlFor="">From Date</label>
                    <input
                      id="startDate"
                      className="uk-form-width-medium uk-input"
                      type="date"
                      value={STARTDATE}
                      onChange={handleStartDateChange}
                    />
                  </div>
                  <div className="uk-width-auto">
                    <label htmlFor="">To Date</label>
                    <input
                      id="endDate"
                      className="uk-form-width-medium uk-input"
                      type="date"
                      value={ENDDATE}
                      onChange={handleEndDateChange}
                    />
                  </div>
                  <div className="uk-width-auto">
                    <button
                      type="button"
                      className="btn-1"
                      onClick={getEbmStats}
                      disabled={!STARTDATE || !ENDDATE ? true : false}
                    >
                      Search
                    </button>
                  </div>
                  <div className="uk-width-auto">
                    <button
                      type="button"
                      className="btn-2"
                      onClick={handleClearFiler}
                    >
                      Clear
                    </button>
                  </div>
                </div>
                {/* {error && <div className="error">{error}</div>} */}
              </div>
            </div>
            <div className="uk-width-1-1">
              <div className="cardWrp">
                <div className="cardSecHeading">
                  <p>EBM Stats</p>
                </div>
                <div className="uk-grid uk-grid-small" uk-grid="">
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <Calendar />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{ebmStatsData?.highestTrafficPerDay}</h3>
                          <p>Highest Traffic - Day</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{ebmStatsData?.totalEntries}</h3>
                          <p>Total Entries</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{ebmStatsData?.valid}</h3>
                          <p>Valid Code Entries</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{ebmStatsData?.inValid}</h3>
                          <p>Invalid Code Entries</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>
                            {ebmStatsData?.brandWiseEntry?.Chocolicious_DC}
                          </h3>
                          <p>Chocolicious Valid Entries</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{ebmStatsData?.brandWiseEntry?.Rio_SV}</h3>
                          <p>RIO Strawberry Vanilla Valid Entries</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>
                            {ebmStatsData?.brandWiseEntry?.Rio_D_Chocolate}
                          </h3>
                          <p>RIO Double Chocolate Valid Entries</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{ebmStatsData?.brandWiseEntry?.Gluco}</h3>
                          <p>Gluco Valid Entires</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>{ebmStatsData?.brandWiseEntry?.Choco_Bite_DC}</h3>
                          <p>Choco Bites Valid Entries</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>
                            {ebmStatsData?.totalConversation === null
                              ? "-"
                              : ebmStatsData?.totalConversation}
                          </h3>
                          <p>Total Conversations</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>
                            {ebmStatsData?.uniqueEntries === null
                              ? "-"
                              : ebmStatsData?.uniqueEntries}
                          </h3>
                          <p>Total Unique Conversations</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <UserMultiple />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>
                            {ebmStatsData?.startConversation === null
                              ? "-"
                              : ebmStatsData?.startConversation}
                          </h3>
                          <p>Conversation Start</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space">
                      <div className="cardBody">
                        <div className="cardImg">
                          <span>
                            <Analytics />
                          </span>
                        </div>
                        <div className="cardTxt">
                          <h3>
                            {ebmStatsData?.endConversation === null
                              ? "-"
                              : ebmStatsData?.endConversation}
                          </h3>
                          <p>Conversation End</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmbStats;

