import React from "react";

const SaveModal = ({ closeModal }) => {
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>You're saving a segment with the following criteria</span>
          </div>
          <div className="modalForm">
            <div className="formHeading">
              <span>
              Contacts that match any of the following conditions:
              </span>
            </div>
            <div className="txtwrp saveModalTxt">
              <p>
              1. Tags contact is tagged <b>user_registered</b> 
              </p>
              <p>
              2. Tags contact is not tagged <b>preferred_phone</b> 
              </p>
            </div>
              <div className="btnwrp">
                <button type="button" className="btn-1" onClick={closeModal}>
                  Cancel
                </button>
                <button type="button" className="btn-2">
                  Save
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveModal;
