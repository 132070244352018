import { Close, Edit } from "@carbon/icons-react";
import React from "react";

const TriggerWindow = ({
  setBName,
  bName,
  isEditingBName,
  handleEditClickBName,
  handleSaveClickBName,
  toggleKeywordVisibility,
  handleTriggerInputChange,
  handleTrigerKeyPress,
  isKeywordVisible,
  inputTriggerValue,
  triggerList,
  handleRemoveTrigger,
  setIsTriggerSectionOpen,
  setIsDialogSectionOpen,
}) => {
  return (
    <div>
      <div className="triggerOptionWrp">
        <div className="topHeading">
          <h3>
            {isEditingBName ? (
              <input
                type="text"
                value={bName}
                onChange={(e) => setBName(e.target.value)}
                placeholder="Enter Bot Name "
                className="triggerInput"
                onClick={() => {
                  setIsTriggerSectionOpen(true);
                  setIsDialogSectionOpen(false);
                }}
              />
            ) : (
              bName || "Enter bot name"
            )}
            {isEditingBName ? (
              <button className="triggerBtn" onClick={handleSaveClickBName}>
                Save
              </button>
            ) : (
              <Edit onClick={handleEditClickBName} />
            )}
          </h3>
        </div>
        <div className="triggerOptionContent">
          <div className="instructionTxt">
            <span>A trigger is an event that starts your Flow. </span> <br />
            <span>To close the bot type exit.</span>
          </div>

          {triggerList
            ?.filter((item) => item !== "Add a trigger")
            .map((item, index) => (
              <div className="broadcastContacts" key={index}>
                <ul>
                  <li>
                    <span>
                      {item}
                      <button
                        type="button"
                        onClick={() => handleRemoveTrigger(index)}
                      >
                        <Close />
                      </button>
                    </span>
                  </li>
                </ul>
              </div>
            ))}

          <div className="keywordMessage">
            <p>Message contains</p>
            {isKeywordVisible ? (
              <input
                type="text"
                placeholder="Add keyword"
                className="uk-input"
                value={inputTriggerValue}
                onChange={handleTriggerInputChange}
                onKeyPress={handleTrigerKeyPress}
              />
            ) : (
              <button
                type="button"
                className="keywordBtn"
                // onClick={() => toggleSection("keyword")}
                onClick={toggleKeywordVisibility}
              >
                + Keyword
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TriggerWindow;
