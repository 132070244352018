import React, { useState } from "react";
import {
  displayMessageStatusIcon,
  getToolTipPosition,
} from "../../Utils/chat/chatFunctions";
import { formatChatHoverDateTime } from "../../Utils/DateFunctions";
import { Document, Page, pdfjs } from "react-pdf";
// import "react-pdf/dist/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;

const ChatPdf = ({ data }) => {
  // const [numPages, setNumPages] = useState();
  // const [pageNumber, setPageNumber] = useState(1);

  // function onDocumentLoadSuccess() {
  //   setNumPages(numPages);
  // }
  return (
    <>
      {data.messageType === "media" ? (
        <div className="txtwrp">
          {(data.isSent == "true" || data.isSent == true) &&
            displayMessageStatusIcon(data.messageStatus)}
          <p
            className="document"
            uk-tooltip={`title: ${formatChatHoverDateTime(
              data.timestamp
            )}; pos: ${getToolTipPosition(data.isSent)}`}
          >
            <a href={data.filePath} target="_blank">
              {/* <Document
                width={150}
                className={"react-pdf__Page"}
                file={data.filePath}
                onLoadSuccess={onDocumentLoadSuccess}
                externalLinkTarget="_self"
              >
                <Page loading width={150} pageNumber={1} />
              </Document> */}
              <iframe src={data.filePath} width="100%" height="200px" />
              Click to download
            </a>
            <div>{data.caption}</div>
          </p>
        </div>
      ) : (
        <div className="txtwrp">
          <p
            className="document"
            uk-tooltip={`title: ${formatChatHoverDateTime(
              data.timestamp
            )}; pos: ${getToolTipPosition(data.isSent)}`}
          >
            <a
              href={chatBaseUrl + "/" + data?.filePath.replace("assets", "")}
              target="_blank"
            >
              {/* <Document
                width={150}
                className={"react-pdf__Page"}
                file={data.filePath}
                onLoadSuccess={onDocumentLoadSuccess}
                externalLinkTarget="_self"
              >
                <Page loading width={150} pageNumber={1} />
              </Document> */}
              <iframe
                src={chatBaseUrl + "/" + data?.filePath.replace("assets", "")}
                width="100%"
                height="200px"
              />
              Click to download
            </a>
          </p>
        </div>
      )}
    </>
  );
};

export default ChatPdf;
