import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Edit,
  OverflowMenuVertical,
} from "@carbon/icons-react";
// import whatsappIcon from "../../../assets/images/whatsapp-icon-new.jpg";
// import berryTalksIcon from "../../../assets/images/berrytalks-platform-icon.svg";
// import zongIcon from "../../../assets/images/zong-platform-icon.svg";
import { apiGet } from "../../../Utils/apiServices";
import { dateWithAtTime } from "../../../Utils/DateFunctions";

const PackageList = () => {
  const navigate = useNavigate();
  const [packageList, setPackageList] = useState(null);
  const [filterData, setFilterData] = useState(null)

  const getPackageList = () => {
    apiGet(`/super-admin/packages`, onSuccess, onFailure);
  };
  const onSuccess = (response) => {

    setPackageList(response.data);
    setFilterData(response.data)
  };
  const onFailure = (error) => {
    console.log(error);
  };
  useEffect(() => {
    getPackageList();
  }, []);



  const searchPackage = (e) => { 
    const result = packageList?.filter((item) => { 
      return item.title.toLowerCase().includes(e.toLowerCase())
    })
      setFilterData(result)
  }

  return (
    <>
      <div className="userWrp">
        <div className="userHeader">
          <div className="uk-grid uk-flex-middle" uk-grid="">
            <div className="uk-width-1-3">
              <div className="headerTabs">
                <ul
                  className="uk-subnav uk-subnav-pill"
                  uk-switcher="connect: .userTabbing"
                >
                  <li
                    onClick={() => {
                      navigate("/super-admin-package-list", { replace: true });
                    }}
                  >
                    <a>Packages</a>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/super-admin-invoice-list", { replace: true });
                    }}
                  >
                    <a>Invoices</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="uk-width-1-3">
              <ul className="uk-switcher uk-margin userTabbing">
                <li>
                  <div className="headerSearch">
                    <form
                      action=""
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Search package through title"
                        onChange={(e)=>searchPackage(e.target.value)}
                      />
                    </form>
                  </div>
                </li>
                <li></li>
              </ul>
            </div>
            <div className="uk-width-1-3">
              <ul className="uk-switcher uk-margin userTabbing">
                <li>
                  <div className="headerBtn">
                    <Link to="/super-admin-add-package">Create package</Link>
                  </div>
                </li>
                <li></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="userTabContent">
          <div className="uk-container uk-container-large">
            <ul className="uk-switcher uk-margin userTabbing">
              <li>
                <div className="userTableWrp">
                  <table className="uk-table">
                    <thead>
                      <tr>
                        <th>TITLE</th>
                        <th>CHARGES</th>
                        {/* <th>MAU</th> */}
                        <th>AGENTS</th>
                        {/* <th>CHATBOTS</th> */}
                        {/* <th>PLATFORM</th> */}
                        {/* <th>CHANNELS</th> */}
                        {/* <th>STATUS</th> */}
                        <th>CREATED</th>
                        <th>CREATED By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterData?.length > 0 ? (
                        filterData?.map((val, index) => {
                          return (
                            <tr key={index}>
                              <td className="agnetName">{val.title}</td>
                              <td>{val.price.toLocaleString()}</td>
                              {/* <td>5000</td> */}
                              <td>{val.liveAgent}</td>
                              {/* <td>{val.advChatBot}</td> */}
                              {/* <td>
                                <img src={berryTalksIcon} alt="" />
                              </td>
                              <td>
                                <img src={whatsappIcon} alt="" />
                              </td> */}

                              {/* <td className="tableSwitch">
                                <Switch
                                  // onChange={() =>
                                  //   handleChecked(val.isAvailable, val.publicId)
                                  // }
                                  checked={"avaaible" === true ? true : false}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  width={26}
                                  height={14}
                                  handleDiameter={10}
                                  onColor="#29A869"
                                />
                              </td> */}
                              <td>{dateWithAtTime(val.createDateTime)}</td>
                              <td>{val?.updatedBy}</td>
                              <td>
                                <div className="uk-inline">
                                  <button type="button">
                                    <OverflowMenuVertical />
                                  </button>
                                  <div
                                    uk-dropdown=""
                                    className="contactBlckDrop"
                                  >
                                    <ul>
                                      <li>
                                        <button type="button"
                                        onClick={() => {
                                          navigate(
                                            "/super-admin-edit-package",
                                            
                                            {
                                              state: val,
                                            }
                                          );
                                          // <Link to={{ pathname: '/invoice-preview', state: {'abc':'123'} }}>Go to Other Component</Link>
                                        }}
                                        >
                                          <Edit /> Edit
                                        </button>
                                      </li>
                                      {/* <li>
                                        <button type="button">
                                          <Download /> Download data
                                        </button>
                                      </li> 
                                       <hr
                                        style={{
                                          marginTop: "5px",
                                          marginBottom: "5px",
                                        }}
                                      />
                                      <li>
                                        <button
                                          className="trashBtn"
                                          type="button"
                                        >
                                          <TrashCan /> Delete
                                        </button>
                                      </li> */}
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10} className="dataNotFound">
                            {filterData === null ? (
                              <div uk-spinner=""></div>
                            ) : (
                              "Data Not Found"
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackageList;
