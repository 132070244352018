import React, { useEffect, useState } from "react";
import { apiGet, apiPost } from "../../../../Utils/apiServices";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../../Context/AuthProvider";
import { useWebSocket } from "../../../../socket/WebSocketManager";
import { decryptedValue } from "../../../../Utils/RouteHashConversion";
// import { chatBaseUrl } from "../../../../Constant";
const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;

const ReinititalModal = ({
  closeModal,
  templates,
  contactMessages,
  getTemplates,
  socketResponse,
  fetchChat,
  chatDetails,
}) => {
  const { auth } = useAuth();
  const { socket } = useWebSocket();
  const [submittedData, setSubmittedData] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [templateDetails, setTemplateDetails] = useState({
    header: {},
    body: {},
    footer: {},
    buttons: {},
    details: {},
  });
  const [loader, setLoader] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [filePath, setFilePath] = useState("");
  const [imageExist, setImageExist] = useState(false);
  const [sendUrl, setSendUrl] = useState("");
  const handleSelectedTemplate = (selectedTemplate) => {
    setTemplateId(selectedTemplate);
    getTemplateDetial(selectedTemplate);
  };

  function getFileUrl(filePath) {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const data = {
        fileId: filePath,
      };
      const message = { type: "get-file-url", data: data };
      socket.send(JSON.stringify(message));
    }
  }

  useEffect(() => {
    if (filePath !== "") {
      getFileUrl(filePath);
    }
  }, [filePath]);

  useEffect(() => {
    if (socketResponse !== undefined) {
      if (socketResponse.type == "FILE_URL") {
        setSendUrl(chatBaseUrl + socketResponse?.data.filePath);
      }
    }
  }, [socketResponse]);

  useEffect(() => {
    if (sendUrl !== "") {
      sendImageTemplate(sendUrl);
    }
  }, [sendUrl]);
  const getTemplateDetial = (templateId) => {
    apiGet(`/template/details/${templateId}`, onSuccessTags, onFailureTags);
  };
  const onSuccessTags = (response) => {
    // for header
    let headerIndex = response?.data?.components
      .map(function (o) {
        return o.type;
      })
      .indexOf("HEADER");

    // for body
    let bodyIndex = response?.data?.components
      .map(function (o) {
        return o.type;
      })
      .indexOf("BODY");

    // for footer
    let footerIndex = response?.data?.components
      .map(function (o) {
        return o.type;
      })
      .indexOf("FOOTER");

    // for buttons
    let buttonIndex = response?.data?.components
      .map(function (o) {
        return o.type;
      })
      .indexOf("BUTTONS");

    setTemplateDetails({
      header: headerIndex > -1 ? response?.data?.components[headerIndex] : {},
      body: bodyIndex > -1 ? response?.data?.components[bodyIndex] : {},
      footer: footerIndex > -1 ? response?.data?.components[footerIndex] : {},
      buttons: buttonIndex > -1 ? response?.data?.components[buttonIndex] : {},
      details: response?.data,
    });
    if (headerIndex > 0) {
      if (response?.data?.components[headerIndex].format === "IMAGE") {
        setImageExist(true);
      }
    } else {
      setImageExist(false);
    }
  };
  const onFailureTags = (error) => {
    console.log(error);
  };
  const sendTemplate = (data) => {
    setLoader(true);
    let bodyParameters = [];
    templateDetails?.body?.example !== undefined &&
      templateDetails?.body?.example.body_text[0].map((val, index) => {
        bodyParameters.push({
          type: "text",
          text: data[`parameter${index + 1}`],
        });
      });

    var buttonParameters = [];
    templateDetails?.buttons?.buttons !== undefined &&
      templateDetails?.buttons?.buttons.map((val, index) => {
        if (val.example !== undefined) {
          buttonParameters.push({
            type: "button",
            sub_type: val.type,
            index: index,
            parameters: [
              {
                type: "payload",
                payload: data[`buttonParameter${index + 1}`],
              },
            ],
          });
        }
      });

    var headerParameters = [];
    if (
      templateDetails?.header.example !== undefined &&
      templateDetails?.header.format == "TEXT"
    ) {
      templateDetails?.header?.example.header_text.map((val, index) => {
        headerParameters.push({
          type: "text",
          text: data[`headerParameter${index + 1}`],
        });
      });
    } else if (templateDetails?.header.format == "IMAGE") {
      headerParameters.push({
        type: "image",
        image: {
          link: sendUrl,
        },
      });
    }

    const convertedData = {};
    buttonParameters.forEach((item) => {
      const key = `item_${item.index}`;
      convertedData[key] = item;
    });

    let componentsData = [];
    if (bodyParameters.length !== 0) {
      componentsData.push({
        type: "body",
        parameters: bodyParameters,
      });
    }
    if (headerParameters.length !== 0) {
      componentsData.push({
        type: "header",
        parameters: headerParameters,
      });
    }
    if (buttonParameters.length !== 0) {
      componentsData.push(...buttonParameters);
    }
    let newdata;
    if (componentsData.length > 0) {
      newdata = {
        // to: contactMessages?.contact?.mobileNumber,
        // category: templateDetails?.details?.category,
        // firstName: contactMessages?.contact?.firstName,
        // lastName: contactMessages?.contact?.lastName,
        // templateId: templateId,
        // template: {
        //   name: templateDetails?.details?.name,
        //   language: {
        //     code: templateDetails?.details?.language,
        //   },
        //   components: componentsData,
        // },

        name:
          contactMessages?.contact?.firstName +
          " " +
          contactMessages?.contact?.lastName,
        templateId: templateId,
        companyId: decryptedValue(auth.companyId),
        sendTemplateRequest: {
          messaging_product: "whatsapp",
          recipient_type: "individual",
          to: contactMessages?.contact?.mobileNumber,
          type: "template",
          category: templateDetails?.details?.category,
          template: {
            name: templateDetails?.details?.name,
            language: {
              code: templateDetails?.details?.language,
            },
            components: componentsData,
          },
        },
      };
    } else {
      newdata = {
        // to: contactMessages?.contact?.mobileNumber,
        // category: templateDetails?.details?.category,
        // firstName: contactMessages?.contact?.firstName,
        // lastName: contactMessages?.contact?.lastName,
        // templateId: templateId,
        // template: {
        //   name: templateDetails?.details?.name,
        //   language: {
        //     code: templateDetails?.details?.language,
        //   },
        // },

        name:
          contactMessages?.contact?.firstName +
          " " +
          contactMessages?.contact?.lastName,
        templateId: templateId,
        companyId: decryptedValue(auth.companyId),
        sendTemplateRequest: {
          messaging_product: "whatsapp",
          recipient_type: "individual",
          to: contactMessages?.contact?.mobileNumber,
          type: "template",
          category: templateDetails?.details?.category,
          template: {
            name: templateDetails?.details?.name,
            language: {
              code: templateDetails?.details?.language,
            },
          },
        },
      };
    }
    setSubmittedData(newdata);
    if (templateDetails?.header.format === "IMAGE") {
      handleFile(data.file, newdata);
    }
    if (imageExist == false) {
      apiPost(
        `/template/send-template`,
        onSuccessSendTemplate,
        onFailureSendTemplate,
        newdata
      );
    }
  };
  const onSuccessSendTemplate = (response) => {
    if (response.status === 0) {
      fetchChat(chatDetails.agentId);
      setLoader(false);
      setSendUrl("");
      closeModal();
    }
  };
  const onFailureSendTemplate = (error) => {
    console.log(error);
    setLoader(false);
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const sendImageTemplate = (url) => {
    let index = submittedData.template.components
      .map(function (o) {
        return o.type;
      })
      .indexOf("header");

    submittedData.template.components[index].parameters[0].image["link"] = url;
    apiPost(
      `/template/send-template`,
      onSuccessSendTemplate,
      onFailureSendTemplate,
      submittedData
    );
  };
  const handleFile = (uploadedFile, data) => {
    var file = uploadedFile[0];

    var formData = new FormData();
    formData.append("file", file);
    formData.append("agentPublicId", decryptedValue(auth.agentId));
    formData.append("companyId", decryptedValue(auth.companyId));
    apiPost(
      `/chat/upload-file`,
      onSuccessFileUpload,
      onFailureFileUpload,
      formData
    );
  };
  const onSuccessFileUpload = (response) => {
    if (response.status == 1) {
      setFilePath(response.message);
    }
  };
  const onFailureFileUpload = (error) => {
    console.log(error);
  };

  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog reinitiateModal uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>Re-initiate chat</span>
          </div>
          <div className="uk-grid" uk-grid="">
            <div className="uk-width-3-5 uk-margin-remove-top">
              <div className="modalForm">
                <div className="formHeading">
                  <span>
                    Connect with the customer using pre-defined messages
                  </span>
                </div>
                <form onSubmit={handleSubmit(sendTemplate)}>
                  <div className="uk-grid uk-grid-small" uk-grid="">
                    <div className="uk-width-1-1">
                      <div className="reinitiateModalInput">
                        <label htmlFor="">Template message</label>

                        <select
                          className="uk-select"
                          onChange={(e) =>
                            handleSelectedTemplate(e.target.value)
                          }
                        >
                          <option value="" selected disabled>
                            Please select template
                          </option>
                          {templates.map((val, index) => {
                            return (
                              <option key={index} value={val.id}>
                                {val.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    {/* ******************* start header work  ******************* */}
                    {templateDetails?.header.example !== undefined &&
                      templateDetails?.header.format === "TEXT" &&
                      templateDetails?.header.example.header_text.map(
                        (val, index) => {
                          return (
                            <>
                              {index === 0 && (
                                <div className="uk-width-1-1">
                                  <label>Header</label>
                                </div>
                              )}

                              <div className="uk-width-1-1" key={index}>
                                <div className="reinitiateModalInput">
                                  <label htmlFor="">
                                    Header Parameter{index + 1}
                                  </label>
                                  <input
                                    type="text"
                                    placeholder={`Enter header parameter ${
                                      index + 1
                                    }`}
                                    className="uk-input"
                                    {...register(
                                      `headerParameter${index + 1}`,
                                      {
                                        required: true,
                                      }
                                    )}
                                  />
                                  {errors[`headerParameter${index + 1}`] && (
                                    <div className="formErrors">
                                      {`header parameter ${
                                        index + 1
                                      } is required.`}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                    {templateDetails?.header.format === "IMAGE" && (
                      <div className="uk-width-1-1">
                        <div className="uk-width-1-1">
                          <label>Header</label>
                        </div>
                        <div className="js-upload uk-placeholder uk-text-center uk-margin-remove-bottom">
                          <span uk-icon="icon: cloud-upload"></span>
                          <div uk-form-custom="">
                            <input
                              type="file"
                              name="media"
                              id="myfile"
                              // onChange={this.handleFile}
                              // onChange={(e) => handleFile(e)}
                              {...register(`file`, {
                                required: true,
                              })}
                            />
                            <span className="uk-link">&nbsp; Upload file</span>
                          </div>
                        </div>

                        {/* <div className="uk-width-1-1 uk-margin-top">
                          <div className="reinitiateModalInput">
                            <label htmlFor="">File Name</label>
                            <input
                              type="text"
                              placeholder={`Enter file name`}
                              className="uk-input"
                            />
                          </div>
                        </div> */}
                        {errors[`file`] && (
                          <div className="formErrors">{`Please upload file`}</div>
                        )}
                      </div>
                    )}

                    {/* ******************* end header work  ******************* */}

                    {/* ******************* start body work  ******************* */}
                    {templateDetails?.body?.example !== undefined &&
                      templateDetails?.body?.example.body_text[0].map(
                        (val, index) => {
                          return (
                            <>
                              {index === 0 && (
                                <div className="uk-width-1-1">
                                  <label>Body</label>
                                </div>
                              )}
                              <div className="uk-width-1-1" key={index}>
                                <div className="reinitiateModalInput">
                                  <label htmlFor="">
                                    Body Parameter {index + 1}
                                  </label>
                                  <input
                                    type="text"
                                    placeholder={`Enter Body parameter ${
                                      index + 1
                                    }`}
                                    className="uk-input"
                                    // name={`parameter ${index+1}`}
                                    // onChange={(e) =>
                                    //   handleParamChangeForQuickMessage(
                                    //     e.target.value,
                                    //     index
                                    //   )
                                    // }
                                    {...register(`parameter${index + 1}`, {
                                      required: true,
                                    })}
                                  />
                                  {errors[`parameter${index + 1}`] && (
                                    <div className="formErrors">{`parameter ${
                                      index + 1
                                    } is required.`}</div>
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                    {/* ******************* end body work  ******************* */}

                    {/* ******************* start buttons work  ******************* */}
                    {templateDetails?.buttons?.buttons !== undefined &&
                      templateDetails?.buttons?.buttons?.map((val, index) => {
                        return (
                          <>
                            {index === 0 && (
                              <div className="uk-width-1-1">
                                <label>Buttons</label>
                              </div>
                            )}
                            {val.example !== undefined && (
                              <>
                                <div className="uk-width-1-1" key={index}>
                                  <div className="reinitiateModalInput">
                                    <label htmlFor="">
                                      Button Parameter {index + 1}
                                    </label>
                                    <input
                                      type="text"
                                      placeholder={`Enter Button parameter ${
                                        index + 1
                                      }`}
                                      className="uk-input"
                                      {...register(
                                        `buttonParameter${index + 1}`,
                                        {
                                          required: true,
                                        }
                                      )}
                                    />
                                    {errors[`buttonParameter${index + 1}`] && (
                                      <div className="formErrors">{`button parameter ${
                                        index + 1
                                      } is required.`}</div>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        );
                      })}
                    {/* ******************* end buttons work  ******************* */}

                    <div className="uk-width-1-1">
                      <div className="btnwrp" style={{ textAlign: "left" }}>
                        <button
                          type="button"
                          className="btn-1"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                        <button
                          // type="button"
                          type="submit"
                          className="btn-2 w-90"
                          // onClick={sendTemplate}
                        >
                          {loader ? (
                            <div uk-spinner="" className="loader"></div>
                          ) : (
                            "Send"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="uk-width-2-5">
              {(templateDetails?.header?.type === "HEADER" ||
                templateDetails?.body?.type === "BODY" ||
                templateDetails?.footer?.type === "FOOTER" ||
                templateDetails?.buttons?.type === "BUTTONS") && (
                <div className="previewBox">
                  {templateDetails?.header?.type === "HEADER" && (
                    <>
                      <span>Header</span>
                      <div className="msgBox">
                        {templateDetails?.header.format === "IMAGE" && (
                          <img src="" />
                        )}
                        {templateDetails?.header.format === "TEXT" && (
                          <p>{templateDetails?.header?.text}</p>
                        )}
                      </div>
                    </>
                  )}
                  {templateDetails?.body?.type === "BODY" && (
                    <>
                      <span className="uk-margin-top">Body</span>
                      <div className="msgBox">
                        <p>{templateDetails?.body?.text}</p>
                      </div>
                    </>
                  )}
                  {templateDetails?.footer?.type === "FOOTER" && (
                    <>
                      <span className="uk-margin-top">Footer</span>
                      <div className="msgBox">
                        <p>{templateDetails?.footer?.text}</p>
                      </div>
                    </>
                  )}
                  {templateDetails?.buttons?.type === "BUTTONS" && (
                    <>
                      <span className="uk-margin-top">Buttons</span>
                      <div className="msgBox reInititatModalBtn">
                        {templateDetails?.buttons?.buttons !== undefined &&
                          templateDetails?.buttons?.buttons?.map(
                            (item, ind) => {
                              return (
                                <>
                                  {item.type === "PHONE_NUMBER" && (
                                    <button key={ind}>
                                      {item.phone_number}
                                    </button>
                                  )}
                                  {item.type === "URL" && (
                                    <button key={ind}>
                                      <a href={item.url}>{item.text}</a>
                                    </button>
                                  )}
                                  {item.type === "COPY_CODE" && (
                                    <button key={ind}>{item.text}</button>
                                  )}
                                  {item.type === "QUICK_REPLY" && (
                                    <button key={ind}>{item.text}</button>
                                  )}
                                </>
                              );
                            }
                          )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReinititalModal;
