import React, { useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import {
  Calendar,
  ChevronDown,
  // Alarm,
  // Calendar,
  // ChevronDown,
  // Timer,
  Download,
} from "@carbon/icons-react";
import moment from "moment/moment";
import UIkit from "uikit";
import { useEffect } from "react";
import { apiGet } from "../../Utils/apiServices";
import axios from "axios";
import { useAuth } from "../../Context/AuthProvider";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";
import CalendarDate from "../../components/Calendar/CalendarDate";
import {
  convertIntoThousand,
  findChannelExist,
} from "../../Utils/GraphFunctions";
import { channelImages } from "../../Utils/ChannelImages";
import { hasPermissions } from "../../Utils/RoutePermissions";
const baseUrl = process.env.REACT_APP_BASEURL;

const LiveChatChannels = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const CurrentCompanyName = CompanyName(CompanyHtml);
  const { auth } = useAuth();
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [analytics, setAnalytics] = useState();
  const [channel, setChannel] = useState("ALL");
  const [selectedDateFilter, setSelectedDateFilter] = useState("This month");
  const [isApiCall, setIsApiCall] = useState(false);
  const [RouteStats, setRouteStats] = useState(null);
  const [size, setSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isSelected, setIsSelected] = useState(false);

  const [liveChatchannels, setLiveChatchannels] = useState({
    facebook: null,
    whatsapp: null,
    instagram: null,
    alldata: null,
  });

  const clearAllStates = () => {
    setLiveChatchannels({
      facebook: null,
      whatsapp: null,
      instagram: null,
      alldata: null,
    });
  };
  const navigate = useNavigate();

  const pageTabs = [
    {
      route: "/analytics",
      name: "Overview",
      permissions: ["FINANCE-DATA-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/live-chat-analytics",
      name: "Live Chat",
      permissions: ["ANALYTICS-MANAGER", "LIVE-CHAT-MANAGER"],
      active: true,
      allPermissionsMandatory: true,
    },
    {
      route: "/chatbot-analytics",
      name: "Chatbot",
      permissions: ["CHATBOT-SUPERVISOR", "CHAT-BOT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/statistics",
      name: "Statistics",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/package-mau",
      name: "Package",
      permissions: ["ANALYTICS-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/orders",
      name: "Orders",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/transfer-logs",
      name: "Transfer Logs",
      permissions: [],
      active: false,
      allPermissionsMandatory: true,
    },
  ];
  const getLiveChatChannelsStats = (page) => {
    apiGet(
      `/detail-analytics/liveChatChannels/${STARTDATE}/${ENDDATE}`,
      // `/routing/stats?page=${page}&size=${size}`,
      onSuccessLiveChatChannelsStats,
      onFailureLiveChatChannelsStats
    );
  };

  const onSuccessLiveChatChannelsStats = (response) => {
    // console.log(response);

    let alldata = response.data;
    setIsApiCall(false);
    setIsSelected(false);

    let whatsappArray = [];
    let facebookArray = [];
    let instagramArray = [];

    if (response?.data.length > 0) {
      let data = response?.data;
      for (let i = 0; i < data.length; i++) {
        if (data[i].chanelId === "WHATSAPP") {
          whatsappArray.push(data[i]);
        }
        if (data[i].chanelId === "FACEBOOK") {
          facebookArray.push(data[i]);
        }
        if (data[i].chanelId === "INSTAGRAM") {
          instagramArray.push(data[i]);
        }
      }
    }

    // console.log(whatsappArray);
    // console.log(facebookArray);
    // console.log(instagramArray);

    setLiveChatchannels({
      facebook: facebookArray,
      whatsapp: whatsappArray,
      instagram: instagramArray,
      alldata: alldata,
    });
    // setRouteStats(response?.data);
    // setPageCount(response?.data?.routeRecords?.totalPages);
  };

  const onFailureLiveChatChannelsStats = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (isSelected === true) {
      setIsApiCall(true);
    }
  }, [isSelected]);

  useEffect(() => {
    getLiveChatChannelsStats(0);
  }, []);
  useEffect(() => {
    if (isApiCall == true) {
      if (selectedDateFilter === "Custom range") {
        if (isSelected === true) {
          getLiveChatChannelsStats();
        }
      } else {
        getLiveChatChannelsStats();
      }
    }
  }, [isApiCall]);

  //   useEffect(() => {
  //     getLiveChatChannelsStats(0);
  //   }, [size]);
  const filterDropdown = [
    "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];

  const channels = [
    {
      icon: null,
      name: "All Channels",
      apiSendName: "ALL",
      disabled: false,
    },
    // {
    //   icon: whatsAppImg,
    //   name: "WhatsApp",
    //   apiSendName: "WHATSAPP",
    //   disabled: false,
    // },
    // {
    //   icon: messengerIcon,
    //   name: "Facebook",
    //   apiSendName: "FACEBOOK",
    //   disabled: true,
    // },
    // {
    //   icon: instaIcon,
    //   name: "Instagram",
    //   apiSendName: "INSTAGRAM",
    //   disabled: true,
    // },
  ];

  const handleDatePickerCloseRouting = () => {
    if (selectedDateFilter === "Custom range") {
      setIsSelected(true);
    }
    UIkit.drop("#datepickerdrop").hide(true);
  };

  const onChangeRouting = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
  };

  const handleDateFilter = (filter) => {
    setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    if (filter !== "Custom range") {
      setIsApiCall(true);
    }
  };

  const handleDatePickerClose = () => {
    if (selectedDateFilter === "Custom range") {
      setIsSelected(true);
    }
    UIkit.drop("#datepickerdrop").hide(true);
  };

  const onChange = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
  };

  const handlePageChange = (data) => {
    getLiveChatChannelsStats(data.selected);
    setCurrentPage(data.selected);
  };

  // useEffect(() => {
  //   getLiveChatChannelsStats(0);
  // }, [size]);

  const exportData = async () => {
    try {
      const response = await axios.get(baseUrl + `/routing/export/stats`, {
        responseType: "arraybuffer", // Ensure the response is treated as binary data
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Routing.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="boradcastWrp">
        <div className="boradcastTabbing">
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #newAnalyticsTab"
          >
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                <li
                  onClick={() => {
                    navigate(val.route, { replace: true });
                  }}
                  className={val.active === true ? "uk-active" : ""}
                  key={index}
                >
                  <a>{val.name}</a>
                </li>
              ) : null;
            })}
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="newAnalyticsTab">
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                val.name === "Live Chat" ? (
                  <li key={index}>
                    <div className="overviewContent">
                      <div className="uk-container">
                        <div className="uk-grid uk-flex-middle" uk-grid="">
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsTabWrp"
                              style={{ margin: "0 0 20px" }}
                            >
                              <div className="whatappTabBtn">
                                <ul
                                  className="uk-subnav uk-subnav-pill"
                                  uk-switcher="connect: .whatsappTabBtn"
                                >
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-analytics", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Overview</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-agent", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Agents</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-routing", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Routes</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-voice-of-customer", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Voice of customer</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-channels", {
                                        replace: true,
                                      });
                                    }}
                                    className="uk-active"
                                  >
                                    <a href="#">Channels</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-tags", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a href="#">Tags</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsWhatsappContent"
                              style={{ marginTop: "16px" }}
                            >
                              <ul className="uk-switcher uk-margin whatsappTabBtn">
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li>
                                  <div
                                    className="overviewContent uk-padding-remove"
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <div className="uk-container">
                                      <div className="overviewTopDropdown">
                                        <div
                                          className="uk-grid uk-flex-middle"
                                          uk-grid=""
                                        >
                                          <div className="uk-width-1-2">
                                            <div className="pageHeading uk-margin-remove-bottom">
                                              <h3>Live Chat Channels</h3>
                                            </div>
                                          </div>
                                          <div className="uk-width-1-2 btnSection">
                                            <div className="agentFilterWrp">
                                              <div className="allChannelDropDown"></div>
                                              <div className="uk-inline">
                                                <button className="calenderBtn rangeBtn">
                                                  <Calendar />{" "}
                                                  {selectedDateFilter}
                                                  <span className="uk-inline">
                                                    <ChevronDown />
                                                  </span>
                                                </button>
                                                <div
                                                  uk-dropdown="mode: click"
                                                  className="calendarDropdown"
                                                  id="dateDropdownSection"
                                                >
                                                  <div className="dropDownHeading">
                                                    <span>Select a range</span>
                                                  </div>
                                                  <ul>
                                                    {filterDropdown.map(
                                                      (val, index) => {
                                                        const isLastElement =
                                                          filterDropdown.length -
                                                            1 ===
                                                          index;
                                                        const isSecondLastElement =
                                                          filterDropdown.length -
                                                            2 ===
                                                          index;

                                                        return (
                                                          <React.Fragment
                                                            key={index}
                                                          >
                                                            <li
                                                              className={
                                                                selectedDateFilter ===
                                                                val
                                                                  ? "activeBtn"
                                                                  : ""
                                                              }
                                                              onClick={() => {
                                                                if (
                                                                  val !==
                                                                  "Custom range"
                                                                ) {
                                                                  clearAllStates();
                                                                }
                                                                handleDateFilter(
                                                                  val
                                                                );
                                                              }}
                                                            >
                                                              {isLastElement ? (
                                                                <div className="uk-inline uk-text-left">
                                                                  <button
                                                                    className="rangeBtn"
                                                                    type="button"
                                                                  >
                                                                    <span className="rangeBtnCircle"></span>{" "}
                                                                    Custom range
                                                                  </button>
                                                                  <div
                                                                    id="datepickerdrop"
                                                                    uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                                                                    className="date-picker-container"
                                                                  >
                                                                    <CalendarDate
                                                                      onChange={
                                                                        onChange
                                                                      }
                                                                    />
                                                                    <div className="date-picker-btn-container uk-text-right">
                                                                      <button
                                                                        className="date-selection-btn"
                                                                        onClick={
                                                                          handleDatePickerClose
                                                                        }
                                                                      >
                                                                        Ok
                                                                      </button>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                <button
                                                                  type="button"
                                                                  className="rangeBtn"
                                                                >
                                                                  <span className="rangeBtnCircle"></span>{" "}
                                                                  {val}
                                                                </button>
                                                              )}
                                                            </li>
                                                            {isSecondLastElement && (
                                                              <hr
                                                                style={{
                                                                  margin:
                                                                    "5px 0",
                                                                }}
                                                              />
                                                            )}
                                                          </React.Fragment>
                                                        );
                                                      }
                                                    )}
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="uk-width-1-1 uk-margin-remove">
                                            <div className="overviewMainContent">
                                              <div className="mainBox">
                                                <div
                                                  className="boxHeading"
                                                  style={{ border: "none" }}
                                                >
                                                  <ul
                                                    uk-tab="connect: #boxTabbing"
                                                    className="boxTabsBtn"
                                                  >
                                                    {companyDetails !==
                                                    undefined ? (
                                                      findChannelExist(
                                                        companyDetails?.activeChannels,
                                                        "WHATSAPP"
                                                      ) ? (
                                                        <li>
                                                          <a href="#">
                                                            WhatsApp
                                                          </a>
                                                        </li>
                                                      ) : null
                                                    ) : null}
                                                    {companyDetails !==
                                                    undefined ? (
                                                      findChannelExist(
                                                        companyDetails?.activeChannels,
                                                        "FACEBOOK"
                                                      ) ? (
                                                        <li>
                                                          <a href="#">
                                                            Messenger
                                                          </a>
                                                        </li>
                                                      ) : null
                                                    ) : null}
                                                    {companyDetails !==
                                                    undefined ? (
                                                      findChannelExist(
                                                        companyDetails?.activeChannels,
                                                        "INSTAGRAM"
                                                      ) ? (
                                                        <li>
                                                          <a href="#">
                                                            Instagram
                                                          </a>
                                                        </li>
                                                      ) : null
                                                    ) : null}
                                                  </ul>
                                                </div>

                                                <ul
                                                  className="uk-switcher uk-margin"
                                                  id="boxTabbing"
                                                >
                                                  {companyDetails !==
                                                  undefined ? (
                                                    findChannelExist(
                                                      companyDetails?.activeChannels,
                                                      "WHATSAPP"
                                                    ) ? (
                                                      <li>
                                                        <div className="boxContent">
                                                          <div
                                                            className="uk-grid"
                                                            uk-grid=""
                                                          >
                                                            <div className="uk-width-1-3 uk-first-column">
                                                              <div className="txtwrp">
                                                                <h2>
                                                                  {liveChatchannels?.whatsapp ===
                                                                  null ? (
                                                                    <div uk-spinner=""></div>
                                                                  ) : (
                                                                    convertIntoThousand(
                                                                      liveChatchannels
                                                                        ?.whatsapp[0]
                                                                        .totalMau
                                                                    )
                                                                  )}
                                                                </h2>
                                                                <span>
                                                                  Total Users
                                                                </span>
                                                              </div>
                                                            </div>
                                                            <div className="uk-width-1-3">
                                                              <div className="txtwrp">
                                                                <h2>
                                                                  {liveChatchannels?.whatsapp ===
                                                                  null ? (
                                                                    <div uk-spinner=""></div>
                                                                  ) : (
                                                                    convertIntoThousand(
                                                                      liveChatchannels
                                                                        ?.whatsapp[0]
                                                                        .totalNewMau
                                                                    )
                                                                  )}
                                                                </h2>
                                                                <span>
                                                                  New Users
                                                                </span>
                                                              </div>
                                                            </div>
                                                            <div className="uk-width-1-3">
                                                              <div className="txtwrp">
                                                                <h2>
                                                                  {liveChatchannels?.whatsapp ===
                                                                  null ? (
                                                                    <div uk-spinner=""></div>
                                                                  ) : (
                                                                    convertIntoThousand(
                                                                      liveChatchannels
                                                                        ?.whatsapp[0]
                                                                        .totalReturningMau
                                                                    )
                                                                  )}
                                                                </h2>
                                                                <span>
                                                                  Returning
                                                                  Users
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </li>
                                                    ) : null
                                                  ) : null}

                                                  {companyDetails !==
                                                  undefined ? (
                                                    findChannelExist(
                                                      companyDetails?.activeChannels,
                                                      "FACEBOOK"
                                                    ) ? (
                                                      <li>
                                                        <div className="boxContent">
                                                          <div
                                                            className="uk-grid"
                                                            uk-grid=""
                                                          >
                                                            <div className="uk-width-1-3 uk-first-column">
                                                              <div className="txtwrp">
                                                                <h2>
                                                                  {liveChatchannels?.facebook ===
                                                                  null ? (
                                                                    <div uk-spinner=""></div>
                                                                  ) : (
                                                                    convertIntoThousand(
                                                                      liveChatchannels
                                                                        ?.facebook[0]
                                                                        .totalMau
                                                                    )
                                                                  )}
                                                                </h2>
                                                                <span>
                                                                  Total Users
                                                                </span>
                                                              </div>
                                                            </div>
                                                            <div className="uk-width-1-3">
                                                              <div className="txtwrp">
                                                                <h2>
                                                                  {liveChatchannels?.facebook ===
                                                                  null ? (
                                                                    <div uk-spinner=""></div>
                                                                  ) : (
                                                                    convertIntoThousand(
                                                                      liveChatchannels
                                                                        ?.facebook[0]
                                                                        .totalNewMau
                                                                    )
                                                                  )}
                                                                </h2>
                                                                <span>
                                                                  New Users
                                                                </span>
                                                              </div>
                                                            </div>
                                                            <div className="uk-width-1-3">
                                                              <div className="txtwrp">
                                                                <h2>
                                                                  {liveChatchannels?.facebook ===
                                                                  null ? (
                                                                    <div uk-spinner=""></div>
                                                                  ) : (
                                                                    convertIntoThousand(
                                                                      liveChatchannels
                                                                        ?.facebook[0]
                                                                        .totalReturningMau
                                                                    )
                                                                  )}
                                                                </h2>
                                                                <span>
                                                                  Returning
                                                                  Users
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </li>
                                                    ) : null
                                                  ) : null}

                                                  {companyDetails !==
                                                  undefined ? (
                                                    findChannelExist(
                                                      companyDetails?.activeChannels,
                                                      "INSTAGRAM"
                                                    ) ? (
                                                      <li>
                                                        <div className="boxContent">
                                                          <div
                                                            className="uk-grid"
                                                            uk-grid=""
                                                          >
                                                            <div className="uk-width-1-3 uk-first-column">
                                                              <div className="txtwrp">
                                                                <h2>
                                                                  {liveChatchannels?.instagram ===
                                                                  null ? (
                                                                    <div uk-spinner=""></div>
                                                                  ) : (
                                                                    convertIntoThousand(
                                                                      liveChatchannels
                                                                        ?.instagram[0]
                                                                        .totalMau
                                                                    )
                                                                  )}
                                                                </h2>
                                                                <span>
                                                                  Total Users
                                                                </span>
                                                              </div>
                                                            </div>
                                                            <div className="uk-width-1-3">
                                                              <div className="txtwrp">
                                                                <h2>
                                                                  {liveChatchannels?.instagram ===
                                                                  null ? (
                                                                    <div uk-spinner=""></div>
                                                                  ) : (
                                                                    convertIntoThousand(
                                                                      liveChatchannels
                                                                        ?.instagram[0]
                                                                        .totalNewMau
                                                                    )
                                                                  )}
                                                                </h2>
                                                                <span>
                                                                  New Users
                                                                </span>
                                                              </div>
                                                            </div>
                                                            <div className="uk-width-1-3">
                                                              <div className="txtwrp">
                                                                <h2>
                                                                  {liveChatchannels?.instagram ===
                                                                  null ? (
                                                                    <div uk-spinner=""></div>
                                                                  ) : (
                                                                    convertIntoThousand(
                                                                      liveChatchannels
                                                                        ?.instagram[0]
                                                                        .totalReturningMau
                                                                    )
                                                                  )}
                                                                </h2>
                                                                <span>
                                                                  Returning
                                                                  Users
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </li>
                                                    ) : null
                                                  ) : null}
                                                </ul>
                                              </div>

                                              <div className="mainBox">
                                                <div className="boxHeading">
                                                  <span>
                                                    Live chat performance per
                                                    channel
                                                  </span>
                                                </div>
                                                <div className="boxFullTable tableCenter">
                                                  <table className="uk-table uk-table-striped">
                                                    <thead>
                                                      <tr>
                                                        <th className="uk-table-expand">
                                                          RANKING
                                                        </th>
                                                        <th>TOTAL</th>
                                                        <th>OPEN</th>
                                                        <th>WAITING</th>
                                                        <th>SOLVED</th>
                                                        <th>CLOSED</th>
                                                        <th>
                                                          AVG. RESPONSE TIME
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {liveChatchannels?.alldata !==
                                                      null ? (
                                                        liveChatchannels?.alldata?.map(
                                                          (val, index) => {
                                                            return (
                                                              <tr key={index}>
                                                                <td className="channelName">
                                                                  <div className="channeliImgTxt">
                                                                    <img
                                                                      src={channelImages(
                                                                        val.chanelId
                                                                      )}
                                                                      alt=""
                                                                    />
                                                                    <span>
                                                                      {
                                                                        val.channel
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td>
                                                                  {
                                                                    val.totalConversation
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {
                                                                    val.openConversation
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {
                                                                    val.totalWaiting
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {
                                                                    val.totalSolved
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {
                                                                    val.totalClosed
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {val.avgHandelTime.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )
                                                      ) : (
                                                        <tr>
                                                          <td
                                                            colSpan={8}
                                                            className="dataNotFound"
                                                          >
                                                            {liveChatchannels?.alldata ===
                                                            null ? (
                                                              <div uk-spinner=""></div>
                                                            ) : (
                                                              "Data Not Found"
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )}
                                                      {/* <td className="channelName">
                                                        <div className="channeliImgTxt">
                                                          <img
                                                            src={whatsappIcon}
                                                            alt=""
                                                          />
                                                          <span>WhatsApp</span>
                                                        </div>
                                                      </td>
                                                      <td>3,432</td>
                                                      <td>1</td>
                                                      <td>1</td>
                                                      <td>3425</td>
                                                      <td>3425</td>
                                                      <td>01:34:01</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="channelName">
                                                        <div className="channeliImgTxt">
                                                          <img
                                                            src={whatsappIcon}
                                                            alt=""
                                                          />
                                                          <span>WhatsApp</span>
                                                        </div>
                                                      </td>
                                                      <td>3,432</td>
                                                      <td>1</td>
                                                      <td>1</td>
                                                      <td>3425</td>
                                                      <td>3425</td>
                                                      <td>01:34:01</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="channelName">
                                                        <div className="channeliImgTxt">
                                                          <img
                                                            src={whatsappIcon}
                                                            alt=""
                                                          />
                                                          <span>WhatsApp</span>
                                                        </div>
                                                      </td>
                                                      <td>3,432</td>
                                                      <td>1</td>
                                                      <td>1</td>
                                                      <td>3425</td>
                                                      <td>3425</td>
                                                      <td>01:34:01</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="channelName">
                                                        <div className="channeliImgTxt">
                                                          <img
                                                            src={whatsappIcon}
                                                            alt=""
                                                          />
                                                          <span>WhatsApp</span>
                                                        </div>
                                                      </td>
                                                      <td>3,432</td>
                                                      <td>1</td>
                                                      <td>1</td>
                                                      <td>3425</td>
                                                      <td>3425</td>
                                                      <td>01:34:01</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="channelName">
                                                        <div className="channeliImgTxt">
                                                          <img
                                                            src={whatsappIcon}
                                                            alt=""
                                                          />
                                                          <span>WhatsApp</span>
                                                        </div>
                                                      </td>
                                                      <td>3,432</td>
                                                      <td>1</td>
                                                      <td>1</td>
                                                      <td>3425</td>
                                                      <td>3425</td>
                                                      <td>01:34:01</td>
                                                    </tr> */}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li key={index}></li>
                )
              ) : null;
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default LiveChatChannels;
