import React, { useState } from "react";
import SettingSideBar from "./components/SettingSideBar";
import Footer from "../../components/common/Footer/Footer";
import { useEffect } from "react";
import { apiGet } from "../../Utils/apiServices";
import { useOutletContext } from "react-router-dom";
import NewQuickReplyModal from "./components/Modal/NewQuickReplyModal";

const QuickReplyMessages = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  const [quickReply, setQuickReply] = useState();
  const [openCreateTagModal, setOpenCreateTagModal] = useState(false);
  const [openEditTagModal, setOpenEditTagModal] = useState(false);
  const [editData, setEditData] = useState();
  const [apiCalled, setApiCalled] = useState(false);

  const getQuickReply = () => {
    apiGet(`/settings/quick-reply`, onSuccessTags, onFailureTags);
  };
  const onSuccessTags = (response) => {
    setApiCalled(false);
    setQuickReply(response?.data);
  };
  const onFailureTags = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getQuickReply();
  }, []);
  useEffect(() => {
    if (apiCalled === true) {
      setQuickReply([]);
      getQuickReply();
    }
  }, [apiCalled]);
  const handleOpenTagModal = () => {
    setOpenCreateTagModal(true);
  };

  const handleCloseTagModal = () => {
    setOpenCreateTagModal(false);
  };
  const handleOpenEditTagModal = (val) => {
    setEditData(val);
    setOpenEditTagModal(true);
  };

  const handleCloseEdtTagModal = () => {
    setOpenEditTagModal(false);
  };

  return (
    <>
      <div className="settingwrp">
        <div className="topBar">
          <div className="uk-grid" uk-grid="">
            <div className="uk-width-1-1">
              <div className="txtwrp">
                <h3>Settings</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="settingContentWrp">
          <div className="uk-grid uk-grid-small" uk-grid="">
            <div className="uk-width-auto">
              <SettingSideBar userPermissions={userPermissions} />
            </div>
            <div className="uk-width-expand">
              <div
                className="settingWrapper"
                style={{ minHeight: "calc(100vh - 240px)" }}
              >
                <div className="uk-container">
                  <div className="settingTabbing">
                    <div className="generalSettingWrp">
                      <div className="mainHeading">
                        <h3>Quick Reply</h3>
                        <p>View and configure all quick replies.</p>
                      </div>
                      <div className="boxwrp">
                        <div className="formwrp">
                          <form action="">
                            <div className="boxHeading">
                              <h6>Quick Reply</h6>
                              <p>
                                Quick Reply can help you to send predefined
                                messages to your customers.
                              </p>
                            </div>
                            <div className="uk-grid uk-grid-small" uk-grid="">
                              {quickReply?.map((val, index) => {
                                return (
                                  <div className="uk-width-1-1" key={index}>
                                    <div className="formInput">
                                      <input
                                        type="text"
                                        className="uk-input"
                                        defaultValue={val.text}
                                      />
                                      <div
                                        className="changeBtn changeBtn2"
                                        style={{ top: "17%" }}
                                      >
                                        <button
                                          type="button"
                                          onClick={(e) =>
                                            handleOpenEditTagModal(val)
                                          }
                                        >
                                          Edit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}

                              <div className="uk-width-1-1">
                                <div className="settingAddBtn">
                                  <button
                                    type="button"
                                    onClick={handleOpenTagModal}
                                  >
                                    Add new quick reply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
      {openCreateTagModal && (
        <NewQuickReplyModal
          closeModal={handleCloseTagModal}
          setApiCalled={setApiCalled}
          heading={"New Quick Reply"}
          description={"Add a new quick reply to organize your chat messages."}
          btnText={"Create"}
        />
      )}
      {openEditTagModal && (
        <NewQuickReplyModal
          closeModal={handleCloseEdtTagModal}
          setApiCalled={setApiCalled}
          heading={"Edit tag"}
          description={"Edit quick reply to organize your chat messages."}
          btnText={"Update"}
          editData={editData}
        />
      )}
     
    </>
  );
};

export default QuickReplyMessages;
