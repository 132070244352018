import { ChevronLeft } from "@carbon/icons-react";
import React from "react";

const CustomizeInnerForm = () => {
  return (
    <>
      <div className="helpContainer">
        <div className="uk-container">
          <div className="btnContainer">
            <button className="backbtn">
              <ChevronLeft /> Back to Customize Forms
            </button>
          </div>
          <div>
            <h2 className="heading">0300 2681869</h2>
            <p>
              Please fill in the form below and our support team will get back
              to you as soon as possible.
            </p>
          </div>
          <div className="innerContainer">
            <h2 className="innerheading">User information</h2>
            <div className="formWrap uk-form-stacked">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-2 p-relative">
                  <label>First name</label>
                  <br />
                  <input
                    type="text"
                    className="uk-input"
                    placeholder="First name"
                  />
                  <div className="txtCounter" style={{ top: "35px" }}>
                    <span>0</span>
                    <span>/</span>
                    <span>10</span>
                  </div>
                </div>

                <div className="uk-width-1-2 p-relative">
                  <label>Last name</label>
                  <br />
                  <input
                    type="text"
                    className="uk-input"
                    placeholder="Last name"
                  />
                  <div className="txtCounter" style={{ top: "35px" }}>
                    <span>0</span>
                    <span>/</span>
                    <span>10</span>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="Email address"
                    className="uk-input"
                  />
                </div>
                <div className="uk-width-1-2 p-relative">
                  <label>Mobile number</label>
                  <br />
                  <input
                    type="number"
                    className="uk-input"
                    placeholder="Mobile number"
                  />
                  <div className="txtCounter" style={{ top: "35px" }}>
                    <span>0</span>
                    <span>/</span>
                    <span>20</span>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <label>Subject</label>
                  <br />
                  <input type="text" className="uk-input" placeholder="Select a subject" />
                </div>
                <div className="uk-width-1-1">
                  <label>Please briefly describe your problem</label>
                  <br />
                  <textarea
                    type="text"
                    className="uk-textarea"
                    placeholder="Brief description..."
                  />
                </div>
                <div className="uk-width-1-1">
                  <label>
                    Once you have submitted the form, our team will review your
                    application and get back to you with further instructions.
                  </label>
                </div>
              </div>
              <div className="btnWrap uk-margin-large-top">
                <div>
                  <button className="cnclBtn">Cancel</button>
                  <button className="saveBtn">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomizeInnerForm;
