import React from "react";
import { Link, NavLink } from "react-router-dom";

const SettingSideBar = ({ userPermissions }) => {
  return (
    <div className="settingSideBar">
      {(userPermissions?.includes("ANALYTICS-MANAGER") ||
        userPermissions?.includes("PEOPLE-MANAGER")) && (
        <div className="sideBarHeading">
          <h3>Main</h3>
        </div>
      )}
      <ul>
        {userPermissions?.includes("ANALYTICS-MANAGER") && (
          <li>
            <NavLink
              to="/settings/general-setting"
              activeClassName="activeLink"
            >
              General
            </NavLink>
          </li>
        )}
        {userPermissions?.includes("ANALYTICS-MANAGER") && (
          <li>
            <NavLink
              to="/settings/notification-setting"
              activeClassName="activeLink"
            >
              Notifications
            </NavLink>
          </li>
        )}
        {userPermissions?.includes("PEOPLE-MANAGER") && (
          <li>
            <NavLink to="/settings/segement" activeClassName="activeLink">
              Segments
            </NavLink>
          </li>
        )}
        {userPermissions?.includes("PEOPLE-MANAGER") && (
          <li>
            <NavLink to="/settings/tags" activeClassName="activeLink">
              Tags
            </NavLink>
          </li>
        )}
        {/* <li>
            <NavLink to="/channels" activeClassName="activeLink">
              Channels
            </NavLink>
          </li> */}
      </ul>
      {userPermissions?.includes("LIVE-CHAT-MANAGER") && (
        <div className="sideBarHeading">
          <h3>Chat</h3>
        </div>
      )}
      <ul>
        {userPermissions?.includes("LIVE-CHAT-MANAGER") && (
          <li>
            <NavLink
              to="/settings/live-chat-behavior"
              activeClassName="activeLink"
            >
              Live chat behavior
            </NavLink>
          </li>
        )}
        {userPermissions?.includes("PEOPLE-MANAGER") && (
          <li>
            <NavLink to="/settings/quick-reply" activeClassName="activeLink">
              Quick Reply
            </NavLink>
          </li>
        )}
        {/* {userPermissions?.includes("PEOPLE-MANAGER") && (
          <li>
            <NavLink to="/webchatbot/builder" activeClassName="activeLink">
              Web Chatbot Builder
            </NavLink>
          </li>
        )} */}
      </ul>
    </div>
  );
};

export default SettingSideBar;
