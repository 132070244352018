import React, { useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import {
  Calendar,
  ChevronDown,
  // Alarm,
  // Calendar,
  // ChevronDown,
  // Timer,
  Download,
} from "@carbon/icons-react";
import demoImg from "../../assets/images/avatar-02.png";
import moment from "moment/moment";
import UIkit from "uikit";
import { useEffect } from "react";
import { apiGet } from "../../Utils/apiServices";
import axios from "axios";
import { useAuth } from "../../Context/AuthProvider";
import AccountNotify from "../../components/Account-disable-notify/AccountNotify";
import CompanyName from "../../components/hoc/CompanyName";
import CompanyHtml from "../../components/hoc/CompanyHtml";
import CalendarDate from "../../components/Calendar/CalendarDate";
import DoughnutChart from "../../components/Graph/DoughnutChart";
import { dateWithAtTime } from "../../Utils/DateFunctions";
import Pagination from "../../components/Pagination/Pagination";
import { useMemo } from "react";
import { hasPermissions } from "../../Utils/RoutePermissions";
const baseUrl = process.env.REACT_APP_BASEURL;

const LiveChatVoice = () => {
  const [
    selectedStatus,
    setSelectedStatus,
    companyImage,
    setCompanyImage,
    agentImage,
    setAgentImage,
    setCompanyDetails,
    companyDetails,
    userPermissions,
    socketResponse,
    notifications,
    messages,
    setIsNewNotificationAvailble,
    isNewNotificationAvailble,
    setReceivedMsgNumber,
    receivedMsgNumber,
  ] = useOutletContext();
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const CurrentCompanyName = CompanyName(CompanyHtml);
  const { auth } = useAuth();
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [analytics, setAnalytics] = useState();
  const [channel, setChannel] = useState("ALL");
  const [selectedDateFilter, setSelectedDateFilter] = useState("This month");
  const [isApiCall, setIsApiCall] = useState(false);
  const [voiceDetails, setVoiceDetails] = useState(null);
  const [size, setSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  // const [currentPage, setCurrentPage] = useState(0);
  const [isSelected, setIsSelected] = useState(false);
  const pageTabs = [
    {
      route: "/analytics",
      name: "Overview",
      permissions: ["FINANCE-DATA-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/live-chat-analytics",
      name: "Live Chat",
      permissions: ["ANALYTICS-MANAGER", "LIVE-CHAT-MANAGER"],
      active: true,
      allPermissionsMandatory: true,
    },
    {
      route: "/chatbot-analytics",
      name: "Chatbot",
      permissions: ["CHATBOT-SUPERVISOR", "CHAT-BOT-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/statistics",
      name: "Statistics",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/package-mau",
      name: "Package",
      permissions: ["ANALYTICS-MANAGER"],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/orders",
      name: "Orders",
      permissions: [
        "ANALYTICS-MANAGER",
        "LIVE-CHAT-MANAGER",
        "CHATBOT-SUPERVISOR",
      ],
      active: false,
      allPermissionsMandatory: true,
    },
    {
      route: "/transfer-logs",
      name: "Transfer Logs",
      permissions: [],
      active: false,
      allPermissionsMandatory: true,
    },
  ];
  const navigate = useNavigate();
  // const [currentPage, setCurrentPage] = useState(1);
  // const [dataPerPage] = useState(10);

  // const indexOfLastData = currentPage * dataPerPage;
  // const indexOfFirstData = indexOfLastData - dataPerPage;
  // const currentData = voiceDetails?.customersList.slice(
  //   indexOfFirstData,
  //   indexOfLastData
  // );

  // const totalPages = Math.ceil(
  //   voiceDetails?.customersList.length / dataPerPage
  // );

  // const handlePageChange = (pageNumber) => {
  //   if (pageNumber >= 1 && pageNumber <= totalPages) {
  //     setCurrentPage(pageNumber);
  //   }
  // };
  const getLiveChatRoutes = (page) => {
    apiGet(
      // `/routing/stats?page=${page}&size=${size}`,
      `/detail-analytics/sentimentAnalysis/${STARTDATE}/${ENDDATE}`,
      onSuccessRouteStats,
      onFailureRouteStats
    );
  };

  const onSuccessRouteStats = (response) => {
    setCurrentPage(1);
    setIsApiCall(false);
    setIsSelected(false);
    // console.log(response);
    setVoiceDetails(response?.data);
    // setPageCount(response?.data?.routeRecords?.totalPages);
  };

  const onFailureRouteStats = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getLiveChatRoutes(0);
  }, []);
  useEffect(() => {
    if (isApiCall == true) {
      if (selectedDateFilter === "Custom range") {
        if (isSelected === true) {
          getLiveChatRoutes(0);
        }
      } else {
        getLiveChatRoutes(0);
      }
    }
  }, [isApiCall]);

  //   useEffect(() => {
  //     getLiveChatRoutes(0);
  //   }, [size]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return voiceDetails?.customersList?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const filterDropdown = [
    "Last 30 days",
    "This week",
    "Last week",
    "This month",
    "Custom range",
  ];

  const channels = [
    {
      icon: null,
      name: "All Channels",
      apiSendName: "ALL",
      disabled: false,
    },
    // {
    //   icon: whatsAppImg,
    //   name: "WhatsApp",
    //   apiSendName: "WHATSAPP",
    //   disabled: false,
    // },
    // {
    //   icon: messengerIcon,
    //   name: "Facebook",
    //   apiSendName: "FACEBOOK",
    //   disabled: true,
    // },
    // {
    //   icon: instaIcon,
    //   name: "Instagram",
    //   apiSendName: "INSTAGRAM",
    //   disabled: true,
    // },
  ];

  const clearAllStates = () => {
    setVoiceDetails(null);
  };
  const handleDateFilter = (filter) => {
    setSelectedDateFilter(filter);
    // Get the current date
    const currentDate = new Date();

    // Calculate the start date of the current week (assuming Sunday as the first day of the week)
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end date of the current week
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

    // Calculate the start date of the previous week by subtracting 7 days from the start of the current week
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);

    // Calculate the end date of the previous week by subtracting 7 days from the end of the current week
    const endOfPreviousWeek = new Date(endOfCurrentWeek);
    endOfPreviousWeek.setDate(endOfCurrentWeek.getDate() - 7);

    // Calculate the date of 30 days ago
    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);

    if (filter == "Last 30 days") {
      setSTARTDATE(moment(last30DaysDate).format("YYYY-MM-DD"));
      setENDDATE(moment(currentDate).format("YYYY-MM-DD"));
    }
    if (filter == "This week") {
      setSTARTDATE(moment(startOfCurrentWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfCurrentWeek).format("YYYY-MM-DD"));
    }
    if (filter == "Last week") {
      setSTARTDATE(moment(startOfPreviousWeek).format("YYYY-MM-DD"));
      setENDDATE(moment(endOfPreviousWeek).format("YYYY-MM-DD"));
    }
    if (filter == "This month") {
      setSTARTDATE(moment().startOf("month").format("YYYY-MM-DD"));
      setENDDATE(moment().endOf("month").format("YYYY-MM-DD"));
    }
    if (filter !== "Custom range") {
      setIsApiCall(true);
    }
  };

  useEffect(() => {
    if (isSelected === true) {
      setIsApiCall(true);
    }
  }, [isSelected]);

  const handleDatePickerClose = () => {
    if (selectedDateFilter === "Custom range") {
      setIsSelected(true);
    }
    UIkit.drop("#datepickerdrop").hide(true);
  };

  const onChange = (ranges) => {
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
  };

  // const handlePageChange = (data) => {
  //   getLiveChatRoutes(data.selected);
  //   setCurrentPage(data.selected);
  // };

  // useEffect(() => {
  //   getLiveChatRoutes(0);
  // }, [size]);

  const exportData = async () => {
    try {
      const response = await axios.get(baseUrl + `/routing/export/stats`, {
        responseType: "arraybuffer", // Ensure the response is treated as binary data
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Routing.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  return (
    <>
      <AccountNotify companyDetails={companyDetails} />
      <div className="boradcastWrp">
        <div className="boradcastTabbing">
          <ul
            className="uk-subnav uk-subnav-pill"
            uk-switcher="connect: #newAnalyticsTab"
          >
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                <li
                  onClick={() => {
                    navigate(val.route, { replace: true });
                  }}
                  className={val.active === true ? "uk-active" : ""}
                  key={index}
                >
                  <a>{val.name}</a>
                </li>
              ) : null;
            })}
          </ul>
        </div>

        <div className="broadcastContentWrp">
          <ul className="uk-switcher uk-margin" id="newAnalyticsTab">
            {pageTabs?.map((val, index) => {
              return hasPermissions(
                userPermissions,
                val.permissions,
                val.allPermissionsMandatory
              ) ? (
                val.name === "Live Chat" ? (
                  <li key={index}>
                    <div className="overviewContent">
                      <div className="uk-container">
                        <div className="uk-grid uk-flex-middle" uk-grid="">
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsTabWrp"
                              style={{ margin: "0 0 20px" }}
                            >
                              <div className="whatappTabBtn">
                                <ul
                                  className="uk-subnav uk-subnav-pill"
                                  uk-switcher="connect: .whatsappTabBtn"
                                >
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-analytics", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Overview</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-agent", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Agents</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-routing", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a>Routes</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-voice-of-customer", {
                                        replace: true,
                                      });
                                    }}
                                    className="uk-active"
                                  >
                                    <a>Voice of customer</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-channels", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a href="#">Channels</a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      navigate("/live-chat-tags", {
                                        replace: true,
                                      });
                                    }}
                                  >
                                    <a href="#">Tags</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="uk-width-1-1 uk-margin-remove-top">
                            <div
                              className="analyticsWhatsappContent"
                              style={{ marginTop: "16px" }}
                            >
                              <ul className="uk-switcher uk-margin whatsappTabBtn">
                                <li></li>
                                <li></li>
                                <li></li>
                                <li>
                                  <div
                                    className="overviewContent uk-padding-remove"
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <div className="uk-container">
                                      <div className="overviewTopDropdown">
                                        <div
                                          className="uk-grid uk-flex-middle"
                                          uk-grid=""
                                        >
                                          <div className="uk-width-1-2">
                                            <div className="pageHeading uk-margin-remove-bottom">
                                              <h3>
                                                Live Chat Customer Satisfaction{" "}
                                                <CurrentCompanyName
                                                  companyDetails={
                                                    companyDetails
                                                  }
                                                />{" "}
                                              </h3>
                                            </div>
                                          </div>
                                          <div className="uk-width-1-2 btnSection">
                                            <div className="uk-inline">
                                              <button className="calenderBtn rangeBtn">
                                                <Calendar />{" "}
                                                {selectedDateFilter}
                                                <span className="uk-inline">
                                                  <ChevronDown />
                                                </span>
                                              </button>
                                              <div
                                                uk-dropdown="mode: click"
                                                className="calendarDropdown"
                                                id="dateDropdownSection"
                                              >
                                                <div className="dropDownHeading">
                                                  <span>Select a range</span>
                                                </div>
                                                <ul>
                                                  {filterDropdown.map(
                                                    (val, index) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        <li
                                                          className={
                                                            selectedDateFilter ===
                                                            val
                                                              ? "activeBtn"
                                                              : ""
                                                          }
                                                          onClick={() => {
                                                            if (
                                                              val !==
                                                              "Custom range"
                                                            ) {
                                                              clearAllStates();
                                                            }
                                                            handleDateFilter(
                                                              val
                                                            );
                                                          }}
                                                        >
                                                          {filterDropdown.length -
                                                            1 ===
                                                          index ? (
                                                            <div className="uk-inline uk-text-left">
                                                              <button
                                                                className="rangeBtn"
                                                                type="button"
                                                              >
                                                                <span className="rangeBtnCircle"></span>{" "}
                                                                Custom range
                                                              </button>
                                                              <div
                                                                id="datepickerdrop"
                                                                uk-drop="mode: click; animation: uk-animation-slide-top-small; animate-out: true"
                                                                className="date-picker-container"
                                                              >
                                                                <CalendarDate
                                                                  onChange={
                                                                    onChange
                                                                  }
                                                                />
                                                                <div className="date-picker-btn-container uk-text-right">
                                                                  <button
                                                                    className="date-selection-btn"
                                                                    onClick={
                                                                      handleDatePickerClose
                                                                    }
                                                                  >
                                                                    Ok
                                                                  </button>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            <button
                                                              type="button"
                                                              className="rangeBtn"
                                                            >
                                                              <span className="rangeBtnCircle"></span>{" "}
                                                              {val}
                                                            </button>
                                                          )}
                                                        </li>
                                                        {filterDropdown.length -
                                                          2 ===
                                                          index && (
                                                          <hr
                                                            style={{
                                                              margin: "5px 0",
                                                            }}
                                                          />
                                                        )}
                                                      </React.Fragment>
                                                    )
                                                  )}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="uk-width-1-1 uk-margin-remove">
                                            <div className="overviewMainContent">
                                              <div className="mainBox">
                                                <div className="boxHeading">
                                                  <span>
                                                    CSAT Breakdown by Response
                                                  </span>
                                                </div>
                                                <div className="boxContent">
                                                  <div
                                                    className="uk-grid uk-flex-middle"
                                                    uk-grid=""
                                                  >
                                                    <div className="uk-width-1-4">
                                                      <div
                                                        className="chartWrp"
                                                        style={{
                                                          minHeight: "209px",
                                                          minWidth: "209px",
                                                        }}
                                                      >
                                                        {voiceDetails !==
                                                          null &&
                                                          (voiceDetails?.totalCustomers >
                                                          0 ? (
                                                            <DoughnutChart
                                                              degree={180}
                                                              backgroudColor={[
                                                                "#1CE783",
                                                                "#2F5711",
                                                                "#ff9f08",
                                                              ]}
                                                              borderColor={[
                                                                "#1CE783",
                                                                "#2F5711",
                                                                "#ff9f08",
                                                              ]}
                                                              graphlabels={[
                                                                "Happy",
                                                                "Unhappy",
                                                                "Neutral",
                                                              ]}
                                                              graphData={[
                                                                voiceDetails?.totalHappyCustomer,
                                                                voiceDetails?.totalNegativeCustomer,
                                                                voiceDetails?.totalNeutralCustomer,
                                                              ]}
                                                            />
                                                          ) : (
                                                            <DoughnutChart
                                                              degree={180}
                                                              backgroudColor={[
                                                                "#B4B4B4",
                                                              ]}
                                                              borderColor={[
                                                                "#B4B4B4",
                                                              ]}
                                                              graphData={[1]}
                                                              graphlabels={[
                                                                "-",
                                                              ]}
                                                            />
                                                          ))}
                                                        <div
                                                          className="chartData"
                                                          style={{ top: "45%" }}
                                                        >
                                                          <h3>
                                                            {voiceDetails !==
                                                              null &&
                                                              (voiceDetails?.totalCustomers >
                                                              0
                                                                ? (
                                                                    ((voiceDetails?.totalHappyCustomer +
                                                                      voiceDetails?.totalNegativeCustomer +
                                                                      voiceDetails?.totalNeutralCustomer) /
                                                                      voiceDetails?.totalCustomers) *
                                                                    100
                                                                  ).toFixed(2) +
                                                                  "%"
                                                                : "0%")}
                                                          </h3>
                                                          <p>CAST Score</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="uk-width-3-4">
                                                      <div className="chartRecordData">
                                                        <div className="chartRecordDataWrp">
                                                          <div className="deliveryRate">
                                                            <ul>
                                                              <li>
                                                                <p>
                                                                  <span className="darkGreenDot"></span>
                                                                  Happy
                                                                </p>
                                                              </li>
                                                              <li>
                                                                <p>
                                                                  <span className="lightGreenDot"></span>
                                                                  Unhappy
                                                                </p>
                                                              </li>
                                                              <li>
                                                                <p>
                                                                  <span className="darkOrange"></span>
                                                                  Neutral
                                                                </p>
                                                              </li>
                                                            </ul>
                                                          </div>

                                                          <div className="progressBar">
                                                            <ul>
                                                              <li>
                                                                <progress
                                                                  className="uk-progress progressDarkGreen"
                                                                  value={
                                                                    voiceDetails?.totalHappyCustomer
                                                                  }
                                                                  max={
                                                                    voiceDetails?.totalCustomers
                                                                  }
                                                                ></progress>
                                                              </li>
                                                              <li>
                                                                <progress
                                                                  className="uk-progress progressMidGreen"
                                                                  value={
                                                                    voiceDetails?.totalNegativeCustomer
                                                                  }
                                                                  max={
                                                                    voiceDetails?.totalCustomers
                                                                  }
                                                                ></progress>
                                                              </li>
                                                              <li>
                                                                <progress
                                                                  className="uk-progress progressMidGreen"
                                                                  value={
                                                                    voiceDetails?.totalNeutralCustomer
                                                                  }
                                                                  max={
                                                                    voiceDetails?.totalCustomers
                                                                  }
                                                                ></progress>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                          <div className="percentageData">
                                                            <ul>
                                                              <li>
                                                                <p>
                                                                  {voiceDetails ===
                                                                  null
                                                                    ? "0%"
                                                                    : (
                                                                        (voiceDetails?.totalHappyCustomer /
                                                                          voiceDetails?.totalCustomers) *
                                                                        100
                                                                      ).toFixed(
                                                                        2
                                                                      ) + "%"}
                                                                </p>
                                                              </li>
                                                              <li>
                                                                <p>
                                                                  {voiceDetails ===
                                                                  null
                                                                    ? "0%"
                                                                    : (
                                                                        (voiceDetails?.totalNegativeCustomer /
                                                                          voiceDetails?.totalCustomers) *
                                                                        100
                                                                      ).toFixed(
                                                                        2
                                                                      ) + "%"}
                                                                </p>
                                                              </li>
                                                              <li>
                                                                <p>
                                                                  {voiceDetails ===
                                                                  null
                                                                    ? "0%"
                                                                    : (
                                                                        (voiceDetails?.totalNeutralCustomer /
                                                                          voiceDetails?.totalCustomers) *
                                                                        100
                                                                      ).toFixed(
                                                                        2
                                                                      ) + " %"}
                                                                </p>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                          <div className="percentageData">
                                                            <ul>
                                                              <li>
                                                                <p>
                                                                  {
                                                                    voiceDetails?.totalHappyCustomer
                                                                  }
                                                                </p>
                                                              </li>
                                                              <li>
                                                                <p>
                                                                  {
                                                                    voiceDetails?.totalNegativeCustomer
                                                                  }
                                                                </p>
                                                              </li>
                                                              <li>
                                                                <p>
                                                                  {
                                                                    voiceDetails?.totalNeutralCustomer
                                                                  }
                                                                </p>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="mainBox">
                                                <div className="boxHeading">
                                                  <span>Surveys</span>
                                                </div>
                                                <div className="boxFullTable tableCenter">
                                                  <table className="uk-table uk-table-striped tagsInTable">
                                                    <thead>
                                                      <tr>
                                                        <th className="uk-table-expand">
                                                          QUEUES
                                                        </th>
                                                        {/* <th>LIVE CHATS</th> */}
                                                        <th>SCORE</th>
                                                        <th>COMMENTS</th>
                                                        <th>SURVEYED</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {voiceDetails
                                                        ?.customersList.length >
                                                      0 ? (
                                                        currentTableData?.map(
                                                          (val, index) => {
                                                            return (
                                                              <tr key={index}>
                                                                <td className="channelName">
                                                                  {/* <img
                                                          src={demoImg}
                                                          alt=""
                                                        /> */}
                                                                  {val.agentName ===
                                                                    null || ""
                                                                    ? "BOT"
                                                                    : val.agentName}
                                                                </td>
                                                                {/* <td>
                                                                {val.comments}
                                                              </td> */}
                                                                <td>
                                                                  <span
                                                                    className={
                                                                      val.sentiment ===
                                                                      "happy"
                                                                        ? "serviceInitiated"
                                                                        : "inProcessYellow"
                                                                    }
                                                                  >
                                                                    {
                                                                      val.sentiment
                                                                    }
                                                                  </span>
                                                                </td>
                                                                <td>
                                                                  {val.comments ===
                                                                  null
                                                                    ? "-"
                                                                    : val.comments}
                                                                </td>
                                                                <td>
                                                                  {dateWithAtTime(
                                                                    val.lastInteractions
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )
                                                      ) : (
                                                        <tr>
                                                          <td
                                                            colSpan={8}
                                                            className="dataNotFound"
                                                          >
                                                            {voiceDetails ===
                                                            null ? (
                                                              <div uk-spinner=""></div>
                                                            ) : (
                                                              "Data Not Found"
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )}
                                                      {/* <tr>
                                                      <td className="channelName">
                                                        <img
                                                          src={demoImg}
                                                          alt=""
                                                        />
                                                        Naveen Asim
                                                      </td>
                                                      <td>
                                                        Talking with Osama Alam
                                                      </td>
                                                      <td>
                                                        <span className="serviceInitiated">
                                                          Happy
                                                        </span>
                                                      </td>
                                                      <td>-</td>
                                                      <td>31/10/2022 18:21</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="channelName">
                                                        <img
                                                          src={demoImg}
                                                          alt=""
                                                        />
                                                        Naveen Asim
                                                      </td>
                                                      <td>
                                                        Talking with Osama Alam
                                                      </td>
                                                      <td>
                                                        <span className="inProcessYellow">
                                                          Unhappy
                                                        </span>
                                                      </td>
                                                      <td>-</td>
                                                      <td>31/10/2022 18:21</td>
                                                    </tr> */}
                                                    </tbody>
                                                  </table>

                                                  {voiceDetails?.customersList
                                                    .length > 0 && (
                                                    <div
                                                      style={{
                                                        marginRight: "50px",
                                                      }}
                                                    >
                                                      <Pagination
                                                        className="pagination-bar"
                                                        currentPage={
                                                          currentPage
                                                        }
                                                        totalCount={
                                                          voiceDetails
                                                            ?.customersList
                                                            .length
                                                        }
                                                        pageSize={PageSize}
                                                        onPageChange={(page) =>
                                                          setCurrentPage(page)
                                                        }
                                                      />
                                                    </div>
                                                  )}

                                                  {/* <Pagination
                                                  currentPage={currentPage}
                                                  totalPages={totalPages}
                                                  onPageChange={
                                                    handlePageChange
                                                  }
                                                /> */}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li key={index}></li>
                )
              ) : null;
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default LiveChatVoice;
