import Request from "./Request";

const Help = () => {
  return (
    <Request
      heading={"Help"}
      paragraph={
        "Please fill in the form below and our support team will get back to you as soon as possible."
      }
      type={"help"}
      btnText={"Submit"}
      thankyouHeading={"Thank you for reaching out to us!"}
      thankyouPara1={
        "Our support team will review your submission and get back to you via email within 24-48 hours. We appreciate your patience and look forward to assisting you."
      }
      thankyouPara2={
        "In the meantime, feel free to explore our knowledge base and frequently asked questions section for immediate answers to common queries."
      }
      thanyouPara3={
        "If you require urgent assistance, Please contact our support team directly at"
      }
      thanyouEmail={"support@berrytalks.com"}
      thankyouBtn={"Back to Home"}
    />
  );
};

export default Help;
