import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { apiGet } from "../../Utils/apiServices";
import { useAuth } from "../../Context/AuthProvider";
import { decryptedValue } from "../../Utils/RouteHashConversion";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

const SuperAdminHome = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [topCompanies, setTopCompanies] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [companiesDetailsInvoices, setCompaniesDetailsInvoices] =
    useState(null);
  const [loaderDashboard, setLoaderDashboard] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const chatBaseUrl = process.env.REACT_APP_CHATASSETSURL;

  const getTopCompanies = () => {
    apiGet(
      `/super-admin/dashboard/topFiveCompanies`,
      onSuccesstopCompanies,
      onFailuretopCompanies
    );
  };
  const onSuccesstopCompanies = (response) => {
    console.log(response);
    setTopCompanies(response?.data);
  };
  const onFailuretopCompanies = (error) => {
    console.log(error);
  };

  //total revenue API start
  const getGetDashboard = () => {
    apiGet(
      `/super-admin/dashboard/stats`,
      onSuccessGetDashboard,
      onFailureGetDashboard
    );
  };
  const onSuccessGetDashboard = (response) => {
    setDashboardData(response?.data);
    setLoaderDashboard(false);
  };
  const onFailureGetDashboard = (error) => {
    console.log(error);
  };

  //AllCompaniesDetailsWithInvoices API start
  const getCompaniesWithInvoices = (page) => {
    apiGet(
      `/super-admin/dashboard/getAllCompaniesDetailsWithInvoices?page=${page}`,
      onSuccessGetCompaniesWithInvoices,
      onFailureGetCompaniesWithInvoices
    );
  };

  const onSuccessGetCompaniesWithInvoices = (response) => {
    setCompaniesDetailsInvoices(response?.data?.data);
    setPageCount(response?.data?.totalPages);
  };

  const onFailureGetCompaniesWithInvoices = (error) => {
    console.log(error);
  };

  const handlePageChange = (data) => {
    getCompaniesWithInvoices(data.selected);
    setCurrentPage(data.selected);
  };

  useEffect(() => {
    getCompaniesWithInvoices(currentPage);
  }, [currentPage]);

  useEffect(() => {
    getTopCompanies();
    getGetDashboard();
  }, []);


  return (
    <div className="dashboardWrp">
      <div className="uk-container uk-container-large">
        <div className="dashboardTop">
          <div uk-grid="" className="uk-grid">
            <div className="uk-width-1-2">
              <div className="dashboardIntro">
                <h3>Hi, {decryptedValue(auth.userName)}</h3>
                <div className="introDetail">
                  <span>Super Admin</span>
                </div>
              </div>
            </div>
            <div className="uk-width-1-2">
              <div className="dashboardIntro  text-right">
                <h4 className="mb-0">Time Frame</h4>
                <div className="introDetail text-right">
                  <span>
                    {loaderDashboard ? (
                      <div uk-spinner="" className="loader"></div>
                    ) : (
                      dashboardData?.currentMonth || "-"
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          uk-grid=""
          className="uk-grid uk-grid-small"
          uk-height-match="target: > div > .contentBox"
        >
          <div className="uk-width-1-2">
            <div className="contentBox">
              <>
                <div className="boxHeading mb-2">
                  <div uk-grid="" className="uk-grid">
                    <div className="uk-width-1-2">
                      <div className="txtwrp">
                        <p>Total revenue</p>
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <div className="amountwrp">
                        <p>
                          PKR {dashboardData?.totalRevenue?.toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="boxContent">
                  <div className="progressWrp">
                    <div className="progressHeading">
                      <div className="left">
                        <a>Active Companies</a>
                      </div>
                      <div className="right">
                        <span>
                          {loaderDashboard ? (
                            <div uk-spinner="" className="loader"></div>
                          ) : dashboardData?.totalActiveCompanies !== null ? (
                            dashboardData?.totalActiveCompanies?.toLocaleString()
                          ) : (
                            0
                          )}
                        </span>
                      </div>
                    </div>
                    <progress
                      className="uk-progress progressNomral"
                      value={dashboardData?.totalActiveCompanies}
                      max={100}
                    ></progress>
                  </div>
                  <div className="progressWrp">
                    <div className="progressHeading">
                      <div className="left">
                        <a>Total inactive companies</a>
                      </div>
                      <div className="right">
                        <span>
                          {loaderDashboard ? (
                            <div uk-spinner="" className="loader"></div>
                          ) : dashboardData?.totalInActiveCompanies !== null ? (
                            dashboardData?.totalInActiveCompanies?.toLocaleString()
                          ) : (
                            0
                          )}
                        </span>
                      </div>
                    </div>
                    <progress
                      className="uk-progress progressNomral"
                      value={dashboardData?.totalInActiveCompanies}
                      max={100}
                    ></progress>
                  </div>
                  <div className="progressWrp">
                    <div className="progressHeading">
                      <div className="left">
                        <a>Total conversation</a>
                      </div>
                      <div className="right">
                        <span>
                          {loaderDashboard ? (
                            <div uk-spinner="" className="loader"></div>
                          ) : dashboardData?.totalConversation !== null ? (
                            dashboardData?.totalConversation?.toLocaleString()
                          ) : (
                            0
                          )}
                        </span>
                      </div>
                    </div>
                    <progress
                      className={"uk-progress progressNomral"}
                      value={dashboardData?.totalConversation}
                      max={100}
                    ></progress>
                  </div>
                  <div className="progressWrp">
                    <div className="progressHeading">
                      <div className="left">
                        <a>Total MAU</a>
                      </div>
                      <div className="right">
                        <span>
                          {loaderDashboard ? (
                            <div uk-spinner="" className="loader"></div>
                          ) : dashboardData?.totaMauConsumed !== null ? (
                            dashboardData?.totaMauConsumed?.toLocaleString()
                          ) : (
                            0
                          )}
                        </span>
                      </div>
                    </div>
                    <progress
                      className={"uk-progress progressNomral"}
                      value={dashboardData?.totaMauConsumed}
                      max={100}
                    ></progress>
                  </div>
                  <div className="progressWrp">
                    <div className="progressHeading">
                      <div className="left">
                        <a>Total agents</a>
                      </div>
                      <div className="right">
                        <span>
                          {loaderDashboard ? (
                            <div uk-spinner="" className="loader"></div>
                          ) : dashboardData?.totalAgentsConsumed !== null ? (
                            dashboardData?.totalAgentsConsumed?.toLocaleString()
                          ) : (
                            0
                          )}
                        </span>
                      </div>
                    </div>
                    <progress
                      className={"uk-progress progressNomral"}
                      value={dashboardData?.totalAgentsConsumed}
                      max={100}
                    />
                  </div>
                </div>
              </>
            </div>
          </div>

          <div className="uk-width-1-2">
            <div className="contentBox">
              <div className="boxHeading">
                <div uk-grid="" className="uk-grid">
                  <div className="uk-width-1-2">
                    <div className="txtwrp">
                      <p>Top 5 companies</p>
                    </div>
                  </div>
                  <div className="uk-width-1-2">
                    <div className="amountwrp">
                      {/* <p>Integrate Channels</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="boxContent">
                <div className="companyNameTable">
                  <table className="uk-table">
                    <thead>
                      <tr>
                        <th className="uk-table-expand">COMPANY NAME</th>
                        <th>PACKAGE PRICE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {topCompanies?.length > 0 ? (
                        topCompanies?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="companyNameData">
                                  {item?.companyLogoUrl ? (
                                    <span className="imgLogo">
                                      <img
                                        className="imgg"
                                        src={chatBaseUrl + item?.companyLogoUrl}
                                        alt=""
                                      />
                                    </span>
                                  ) : (
                                    <span className="blue">
                                      {item?.companyName &&
                                        item.companyName.charAt(0)}
                                    </span>
                                  )}

                                  <p>{item?.companyName}</p>
                                </div>
                              </td>
                              <td>{item?.price?.toLocaleString()}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={1}>
                            {topCompanies === null ? (
                              <div uk-spinner=""></div>
                            ) : (
                              "Data Not Found"
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1">
            <div className="companyMainTable">
              <table className="uk-table">
                <thead>
                  <tr>
                    <th>SERIES</th>
                    <th>COMPANY NAME</th>
                    <th>MAU</th>
                    <th>CONVERSATION</th>
                    <th>Status</th>
                    <th>DATE</th>
                    <th>PACKAGE</th>
                  </tr>
                </thead>

                <tbody>
                  {companiesDetailsInvoices?.length > 0 ? (
                    companiesDetailsInvoices?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <a
                              onClick={() => {
                                navigate("/super-admin-single-company-view", {
                                  state: item,
                                });
                              }}
                            >
                              {item?.companyName || "-"}
                            </a>
                          </td>
                          <td>{item?.activeUser?.toLocaleString() || "-"}</td>
                          <td>
                            {item?.whatsappConversation?.toLocaleString() ||
                              "-"}
                          </td>
                          <td>
                            <span
                              className={
                                item?.status === "PAID" ? "paid" : "unpaid"
                              }
                            >
                              {item?.status}
                            </span>
                          </td>
                          <td>{item?.invoiceDate || "-"}</td>
                          <td>{item?.price?.toLocaleString() || "-"} </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={8} className="dataNotFound">
                        {companiesDetailsInvoices === null ? (
                          <div uk-spinner=""></div>
                        ) : (
                          "Data Not Found"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {pageCount > 1 ? (
              <div>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"...."}
                  pageCount={pageCount}
                  onPageChange={handlePageChange}
                  containerClassName={
                    "uk-pagination uk-float-right contatPagination"
                  }
                  activeClassName={"uk-active"}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={6}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminHome;
