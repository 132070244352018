import axios from "axios";
import { baseUrl } from "../Constant";
import { decryptedValue } from "./RouteHashConversion";

// const getCommonHeaders = async (headers) => {
//   if (headers) {
//     if (headers["Content-Type"] === undefined) {
//       headers["Content-Type"] = "application/json";
//     }
//     headers["Access-Control-Allow-Origin"] = "*";
//   }

//   let token = await jwt();

//   if (token) {
//     headers.Authorization = `Bearer ${token}`;
//   }
//   return headers;

var apiKey;
const userRole = decryptedValue(localStorage.getItem("_role"));
if (userRole === "ROLE_SUPER_ADMIN") {
  apiKey = process.env.REACT_APP_BASEURL_SUPERADMIN;
} else {
  apiKey = process.env.REACT_APP_BASEURL;
}

const superAdminApi = process.env.REACT_APP_BASEURL_SUPERADMIN;

const jwt = async () => {
  try {
    const data = await localStorage.getItem("_token");
    if (data !== null) {
      return data;
    }
  } catch (error) {}
};

export async function apiGet(endPoint, onSuccess, onFailure) {
  let token = await jwt();
  axios
    .get(apiKey + endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (onSuccess) onSuccess(response?.data);
    })
    .catch((error) => {
      if (onFailure) onFailure(error);
    });
}

export async function apiPost(endPoint, onSuccess, onFailure, body, headers) {
  let token = await jwt();
  axios
    .post(apiKey + endPoint, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (onSuccess) onSuccess(response?.data);
    })
    .catch((error) => {
      if (onFailure) onFailure(error);
    });
}

export async function apiPut(endPoint, onSuccess, onFailure, body, headers) {
  let token = await jwt();

  axios
    .put(apiKey + endPoint, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (onSuccess) onSuccess(response?.data);
    })
    .catch((error) => {
      if (onFailure) onFailure(error);
    });
}

export async function apiDelete(endPoint, onSuccess, onFailure, headers) {
  let token = await jwt();

  axios
    .delete(apiKey + endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (onSuccess) onSuccess(response?.data);
    })
    .catch((error) => {
      if (onFailure) onFailure(error);
    });
}

export async function apiPatch(endPoint, onSuccess, onFailure, body, headers) {
  let token = await jwt();
  axios
    .patch(apiKey + endPoint, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (onSuccess) onSuccess(response?.data);
    })
    .catch((error) => {
      if (onFailure) onFailure(error);
    });
}
// for super admin apis

export async function apiPostSuperAdmin(
  endPoint,
  onSuccess,
  onFailure,
  body,
  headers
) {
  let token = await jwt();
  axios
    .post(superAdminApi + endPoint, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (onSuccess) onSuccess(response?.data);
    })
    .catch((error) => {
      if (onFailure) onFailure(error);
    });
}

export async function apiGetSuperAdmin(endPoint, onSuccess, onFailure) {
  let token = await jwt();
  axios
    .get(superAdminApi + endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (onSuccess) onSuccess(response?.data);
    })
    .catch((error) => {
      if (onFailure) onFailure(error);
    });
}

export async function apiDeleteSuperAdmin(endPoint, onSuccess, onFailure, headers) {
  let token = await jwt();

  axios
    .delete(superAdminApi + endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (onSuccess) onSuccess(response?.data);
    })
    .catch((error) => {
      if (onFailure) onFailure(error);
    });
}

