import { useState } from "react";
import { ChevronRight, Download } from "@carbon/icons-react";
import React from "react";
import TriggerChatbot from "./components/TriggerChatbot";
import DialogChatbot from "./components/DialogChatbot";
import { useContextChatBotWeb } from "./ContextChatBot";
import Dialogs from "./components/Dialogs";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import ScriptGenerator from "../../Webchatbot/ScriptGenerator";

const ChatbotBuilder = () => {
  const {
    triggerValue,
    selectSectionFunc,
    selectSection,
    sections,
    saveBotObject,
    hasTextarea,
    captionChecking,
    setParamsState,
    scriptModal,
    bot,
    getBotWidgetId,
  } = useContextChatBotWeb() || {};
  const { publicId } = useParams();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const botWidgetId = params.get("botWidgetId");
  console.log(botWidgetId);

  useEffect(() => {
    if (publicId) {
      setParamsState(publicId);
    }
  }, [publicId]);

  const selectedDialogIndex = sections?.findIndex(
    (dialog) => dialog.dialogueTitle === selectSection
  );
  const [reloadClicked, setReloadClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!reloadClicked) {
        event.preventDefault();
        event.returnValue = "Are you sure you want to leave the page?";
        return event.returnValue;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [reloadClicked]);

  const handleModalClose = () => {
    const confirmed = window.confirm(
      "Are you sure you want to leave the page?"
    );
    if (confirmed) {
      setReloadClicked(false); // Reset reloadClicked state
      setShowModal(false);
      window.location.href = "/chatbot"; // Redirect to chatbot page
    }
  };
  useEffect(() => {
    if (!reloadClicked) {
      setShowModal(true);
    }
  }, [reloadClicked]);

  function formatDate(date) {
    const options = { weekday: "long", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  const today = new Date();
  const formattedDate = formatDate(today);

  return (
    <>
      <div className="chatbotWrp createNewSegment uk-padding-remove">
        <div className="chatbotHeader">
          <div className="uk-grid uk-flex-middle" uk-grid="">
            <div className="uk-width-1-2">
              <div className="backBTn uk-padding-remove">
                <ul>
                  <li>
                    <a href="/chatbot">
                      <span>Chatbot flows</span>
                    </a>
                  </li>
                  <li>
                    <ChevronRight />
                  </li>
                  <li>
                    <span className="currentPage">Feedback flow</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="uk-width-1-2">
              <div className="headerBtn">
                <button
                  className="btn-2"
                  type="button"
                  onClick={handleModalClose}
                >
                  Close
                </button>
                <button
                  title={hasTextarea ? "Choose one element with button" : ""}
                  type="button"
                  className="btn-1 uk-margin-right"
                  onClick={saveBotObject}
                  disabled={hasTextarea || captionChecking}
                >
                  Generate Script
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="chatbotBuilderWrp ">
          <div className="uk-grid " uk-grid="">
            <div className="uk-width-3-5">
              <div className="chatbotBuilderLeft">
                <div className="uk-grid uk-grid-small" uk-grid="">
                  <div className="uk-width-1-2">
                    <div className="triggerWrp">
                      <div className="stepHeading">
                        <span>Trigger</span>
                      </div>
                      <div
                        className="addTrigger uk-flex uk-flex-middle uk-flex-between"
                        onClick={() => selectSectionFunc("trigger")}
                      >
                        <div className="txtwrp" style={{ cursor: "pointer" }}>
                          <span>
                            <span>
                              {bot?.buttonKeywords?.length +
                                " Triggers incorporated" || "Enter trigger"}
                            </span>
                          </span>
                        </div>
                        <div className="btnwrp">
                          {/* <button type="button" className="btn-1">
                            <Edit />
                          </button>
                          <button type="button">
                            <TrashCan />
                          </button> */}
                        </div>
                      </div>

                      <div className="stepHeading">
                        <span>Dialogs</span>
                      </div>

                      <div>
                        <Dialogs />
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-2">
                    {selectSection === "trigger" && <TriggerChatbot />}
                    {/* Trigger section end */}

                    {/* Button listing start */}
                    {selectSection !== "trigger" && <DialogChatbot />}
                    {/* Button listing end */}
                  </div>
                </div>
              </div>
            </div>

            <div className="uk-width-2-5">
              <div
                className="mobilePreview uk-margin-top"
                style={{ height: "545px", width: "270px" }}
              >
                <div className="mobileMsgs" style={{ height: "450px" }}>
                  <div className="msgDate">
                    <span>{formattedDate}</span>
                  </div>
                  {sections[selectedDialogIndex]?.settings !== undefined
                    ? sections[selectedDialogIndex]?.settings?.map(
                        (item, index) => (
                          <div key={index}>
                            <span className="p-0 uk-margin-top-m-0">
                              {item.type === "textarea" ? (
                                <div className="leftMsg">
                                  <pre>{item.value || "..."}</pre>
                                </div>
                              ) : item.type === "list" ? (
                                <div className="leftMsg">
                                  <pre>{item.value || "..."}</pre>
                                </div>
                              ) : item.type === "image" && item.value ? (
                                <div className="image-container leftMsg">
                                  <img
                                    src={item.name}
                                    alt={`Uploaded ${index}`}
                                  />
                                  <pre className="p-0 uk-margin-top-m-0">
                                    {item.caption}
                                  </pre>
                                </div>
                              ) : item.type === "video" &&
                                item.value === undefined ? (
                                <div className="image-container leftMsg">
                                  <video controls muted autoplay></video>
                                </div>
                              ) : item.type === "video" && item.value ? (
                                <div className="image-container leftMsg">
                                  <video src={item.name} controls></video>
                                  <pre className="p-0 uk-margin-top-m-0">
                                    {item.caption}
                                  </pre>
                                </div>
                              ) : item.type === "file" && item.value ? (
                                <div className="leftMsg">
                                  <span>
                                    <Download /> {item.name}
                                    <pre className="p-0 uk-margin-top-m-0">
                                      {" "}
                                      {item.caption}
                                    </pre>
                                  </span>
                                </div>
                              ) : item.type === "button" ? (
                                <button
                                  type="button"
                                  className="chatbotPreviewBtn"
                                >
                                  {item.value || "..."}
                                </button>
                              ) : item.type === "action" ? null : null}
                            </span>
                          </div>
                        )
                      )
                    : bot !== undefined &&
                      bot?.buttonKeywords?.map((item, index) => (
                        <div key={index}>
                          <span className="p-0 uk-margin-top-m-0">
                            <button type="button" className="chatbotPreviewBtn">
                              {item.value || "..."}
                            </button>
                          </span>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {scriptModal && (
        <ScriptGenerator
          botWidgetIdGet={botWidgetId}
          editBotWidgetId={getBotWidgetId}
        />
      )}
    </>
  );
};

export default ChatbotBuilder;
