import React from "react";
import cloudeImg from "../../assets/images/cloud--upload.svg";
import { CheckmarkOutline, Close } from "@carbon/icons-react";
import Multiselect from "multiselect-react-dropdown";
import { apiGet, apiPost } from "../../Utils/apiServices";
import { useState } from "react";
import { useEffect } from "react";
import { useAuth } from "../../Context/AuthProvider";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import contactImportFile from "../../assets/file/ImportContacts.xlsx";
import { decryptedValue } from "../../Utils/RouteHashConversion";

const ImportContact = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [fileUploadDetails, setFileUploadDetails] = useState({});
  const [finalizedResponse, setFinalizedResponse] = useState({});
  const [loader, setLoader] = useState(false);
  const [errorEnable, setErrorEnable] = useState(false);
  const [multiSelectTags, setMultiSelectTags] = useState([]);
  const [submittedData, setSubmittedData] = useState({
    status: null,
    file: null,
    tags: [],
  });

  const onSelectTags = (item) => {
    setSubmittedData({
      ...submittedData,
      tags: item,
    });
  };
  const onRemoveTags = (item) => {
    setSubmittedData({
      ...submittedData,
      tags: item,
    });
  };
  // const removeTagItems = (id) => {
  //   let newdata = submittedData.tags.filter((val) => {
  //     return val.id !== id;
  //   });
  //   onRemoveTags(newdata)
  //   // setSubmittedData({
  //   //   ...submittedData,
  //   //   tags: newdata,
  //   // });

  // };
  const handleFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedFormats = [
        "text/csv",
        "text/plain",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
  
      if (!allowedFormats.includes(file.type)) {
        toast.error("Upload file with correct format", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        e.target.value = "";
        setSubmittedData({
          ...submittedData,
          file: null,
        });
      } else {
        setSubmittedData({
          ...submittedData,
          file: file,
        });
      }
    }
  };

  const getContactTags = () => {
    apiGet(
      `/contact/tags`,
      onSuccessContactTags,
      onFailureContactTags
    );
  };
  const onSuccessContactTags = (response) => {
    const updatedArray = response?.data?.map((element) => ({
      ...element,
      // name: element.text,
      name: element.tagName,
    }));
    setMultiSelectTags(updatedArray);
  };
  const onFailureContactTags = (error) => {
    console.log(error);
  };

  const fileUpload = () => {
    setLoader(true);
    var formData = new FormData();
    formData.append("file", submittedData.file);
    apiPost(
      `/contact/upload`,
      onSuccessFileUpload,
      onFailureFileUpload,
      formData
    );
  };
  const onSuccessFileUpload = (response) => {
    setFileUploadDetails(response?.data);
  };
  const onFailureFileUpload = (error) => {
    setLoader(false);
    if (error.message !== "Network Error") {
      // if (error?.response?.data.message === "Invalid file format") {
      if (error?.response?.data.error === "Internal Server Error") {
        // toast.error(error.response?.data.message, {
        toast.error('Invalid data found in the file.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    }
  };

  const FinalizeUpload = (e) => {
    e.preventDefault();
    setErrorEnable(true);
    if (submittedData.file !== null && submittedData.status !== null) {
      fileUpload();
    }
  };

  useEffect(() => {
    if (JSON.stringify(fileUploadDetails) !== "{}")
      if (
        fileUploadDetails.fileId !== undefined &&
        fileUploadDetails.totalRecordsInserted > 0
      ) {
        tagUpload();
      } else {
        setLoader(false);
        toast.error("Contacts already exists", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        // setTimeout(() => {
        //   setSubmittedData({
        //     ...submittedData,
        //     // file: null,
        //     status: null,
        //   });
        //   setErrorEnable(false);

        //   // $("#filefield").val("");
        //   // $("#statusField").val("");
        // }, 2000);
      }
  }, [fileUploadDetails]);

  const tagUpload = () => {
    let tagsToSend = [];
    submittedData.tags.map((val) => {
      tagsToSend.push(val.id);
    });
    let data = {
      fileId: fileUploadDetails.fileId,
      contactTagsList: tagsToSend,
      status: submittedData.status == "false" ? false : true,
    };
    if (submittedData.status !== null && submittedData.file !== null) {
      apiPost(
        `/contact/upload/tags`,
        onSuccessTagsUpload,
        onFailureTagsUpload,
        data
      );
    }
  };
  const onSuccessTagsUpload = (response) => {
    setLoader(false);
    if (response.message === "TAGS added") {
      setFinalizedResponse(response?.data);
    }
  };
  const onFailureTagsUpload = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getContactTags();
  }, []);
  return (
    <>
      <div className="importContactWrp">
        {fileUploadDetails.fileId === undefined ||
        fileUploadDetails.fileId == 0 ? (
          <div className="uk-container">
            <div className="importContactTop">
              <div className="uk-grid" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="importContactHeading">
                    {/* <span>Import</span> */}
                  </div>
                </div>
                <divc className="uk-width-1-2">
                  <div className="exitBtn">
                    <a onClick={() => navigate("/contacts")}>Exit</a>
                  </div>
                </divc>
              </div>
            </div>
            <div className="importSecWrp">
              <div className="importPageHeading">
                <h3>Upload your contacts</h3>
              </div>
              <div className="importContactTxt">
                <p>Import contacts from a CSV or tab-delimited TXT file.</p>
              </div>
              <div className="importContactInput">
                <div uk-form-custom="target: true">
                  <input
                    type="file"
                    aria-label="Custom controls"
                    style={{ fontSize: "16px" }}
                    onChange={(e) => handleFile(e)}
                  />
                  <input
                    className="uk-input uk-form-width-medium"
                    type="text"
                    placeholder="Browse"
                    id="filefield"
                    aria-label="Custom controls"
                    disabled
                    style={{ backgroundColor: "#fff" }}
                  />

                  {loader && <div className="loaderDiv" uk-spinner=""></div>}

                  <img src={cloudeImg} alt="" />
                </div>
                {submittedData.file === null && errorEnable && (
                  <div className="formErrors">Please upload file</div>
                )}
              </div>
              <div className="downloadTxt">
                <p>
                  You can download our sample{" "}
                  <a href={contactImportFile} download="import-contact">
                    CSV import file
                  </a>{" "}
                  and replace the sample contacts with your own data.
                </p>
              </div>
            </div>
            <div className="importOrganizeSec">
              <div className="importPageHeading">
                <h3>Organize your contacts status</h3>
              </div>
              <div className="importPageSelect">
                <form action="">
                  <label htmlFor="">Select a status<span className="formErrors">*</span></label>
                  <select
                    name=""
                    className="uk-select"
                    onChange={(e) => {
                      setSubmittedData({
                        ...submittedData,
                        status: e.target.value,
                      });
                    }}
                    id="statusField"
                  >
                    <option value="" selected disabled>
                      Please select status
                    </option>
                    <option value={true}>Connected</option>
                    <option value={false}>Blocked</option>
                  </select>
                  {submittedData.status === null && errorEnable && (
                    <div className="formErrors">Please select status</div>
                  )}
                </form>
              </div>
              <div className="importSelectTxt">
                <span>
                  When you choose the "Connected" status for your contacts, it
                  indicates that you've gained permission to market to them.
                </span>
              </div>
            </div>
            <div className="importTagSec">
              <div className="importPageHeading">
                <h3>Tag your contacts</h3>
              </div>
              <div className="importContactTxt">
                <p>
                  Easily organize your contacts using simple words or phrases
                  that make the most sense to you.
                </p>
              </div>
              <div className="importPageSelect">
                <form action="">
                  <label htmlFor="">Search for or create tags</label>
                  {/* <div className="importTags">
                  <ul>
                    {submittedData.tags?.map((val, index) => {
                      return (
                        <li key={index}>
                          <span>
                            {val.name}
                            <button
                              type="button"
                              onClick={() => removeTagItems(val.id)}
                            >
                              <Close />
                            </button>
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div> */}
                  <div className="tagsMultiSelect">
                    <Multiselect
                      options={multiSelectTags}
                      onSelect={onSelectTags}
                      onRemove={onRemoveTags}
                      displayValue="name"
                    />
                  </div>
                  <div className="importSelectTxt">
                    <span>Start typing to add a custom tag</span>
                  </div>
                  <div className="submitBtn">
                    <button
                      type="submit"
                      className="btn-1"
                      onClick={FinalizeUpload}
                    >
                      Finalize import
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div className="uk-container">
            <div className="importContactTop">
              <div className="uk-grid" uk-grid="">
                <div className="uk-width-1-2">
                  {/* <div className="importContactHeading">
                    <span>Confirmation</span>
                  </div> */}
                </div>
                <divc className="uk-width-1-2">
                  <div className="exitBtn">
                    <a onClick={() => navigate("/contacts", { replace: true })}>
                      Exit
                    </a>
                  </div>
                </divc>
              </div>
            </div>
            <div className="importSecWrp">
              {/* <div className="importPageHeading">
                <h3>These contacts are already in your audience</h3>
              </div> */}
              {/* <div className="importContactTxt importConfirmationTxt">
                <p>
                  We have not added any contacts to your "Convex Interactive"
                  audience.
                </p>
              </div> */}
              <div className="importPageHeading">
                <h3>Your import was successful</h3>
              </div>
              <div className="importContactTxt importConfirmationTxt">
                <p>
                  We've added{" "}
                  <span>{fileUploadDetails?.totalRecordsInserted}</span>{" "}
                  contacts to your contacts
                </p>
              </div>
              <div className="confirmedContactList">
                <ul>
                  <li>
                    <CheckmarkOutline />
                    <p>
                      Imported from: <span>File upload</span>
                    </p>
                  </li>
                  <li>
                    <CheckmarkOutline />
                    <p>
                      Update existing contacts:{" "}
                      <span>
                        {fileUploadDetails?.totalRecords -
                          fileUploadDetails?.totalRecordsInserted}
                      </span>
                    </p>
                  </li>
                  {/* <li>
                    <CheckmarkOutline />
                    <p>
                      Tagged: <span>None</span>
                    </p>
                  </li> */}
                </ul>
              </div>
              <div className="submitBtn">
                <button
                  type="button"
                  onClick={() => navigate("/contacts", { replace: true })}
                >
                  View contacts
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ImportContact;
