import React, { useEffect } from "react";
import { useState } from "react";
import { ChevronDown, Edit } from "@carbon/icons-react";
import { useContextChatBotWeb } from "../ContextChatBot";
import { Close } from "@carbon/icons-react";
import UIkit from "uikit";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

const TriggerChatbot = () => {
  const {
    handleEditClickBName,
    handleSaveClickBName,
    handleChangeBName,
    isEditingBName,
    textBName,
    sections,
    handleDialogSelectOnButton,
    selectedDialogOnButton,
    toggleActionVisibility,
    toggleDialogHidden,
    paramsState,
    teamsData,
    handleDialogSelectOnButtonTimings,
    handleTimeChange,
    handleInputTimingsText,
    toggleFormVisibility,
    emptyKeyword,
    addSectionTrigger,
    bot,
    setBot,
    setSelectedDialogOnButton,
    triggerValue,
  } = useContextChatBotWeb();

  const closeUserDialogDrop = (id) => {
    UIkit.dropdown(id)?.hide();
  };
  const closeActionDropdown = (id) => {
    UIkit.dropdown(id)?.hide();
  };

  const setSectionDetailType = (type, currentBtnId) => {
    setBot((prevBot) => {
      const updatedKeyWords = prevBot.buttonKeywords.map((section) => {
        if (section.id === currentBtnId) {
          return { ...section, type: type, actionType: "BUTTON_KEYWORD" };
        }
        return section;
      });
      return {
        ...prevBot,
        buttonKeywords: updatedKeyWords,
      };
    });
  };

  const handleInputChangeSectionButton = (id, value, timingsText) => {
    setBot((prevBot) => {
      const updatedValue = prevBot.buttonKeywords.map((setting) => {
        if (setting.id === id) {
          return {
            ...setting,
            value: value,
          };
        }
        return setting;
      });
      return {
        ...prevBot,
        buttonKeywords: updatedValue,
      };
    });
  };

  const closeButtonPressDrop = (id) => {
    if (emptyKeyword) {
      UIkit.dropdown(id)?.hide();
    }
  };

  const closeButtonDropdown = (id, type, name) => {
    if (type === "button" && (name == "" || name == undefined)) {
      toast.error("Button name is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      UIkit.dropdown(id)?.hide();
    }
  };

  const handleSectionClosing = (id) => {
    setBot((prevBot) => ({
      ...prevBot,
      buttonKeywords: prevBot.buttonKeywords.filter(
        (keyword) => keyword.id !== id
      ),
    }));
  };

  const handleDialogSelectOnButtonTrigger = (
    dialog,
    index,
    dialogId,
    teamName
  ) => {
    const updatedSelectedDialogOnButton = [...selectedDialogOnButton];
    updatedSelectedDialogOnButton[index] = dialog;
    setSelectedDialogOnButton(updatedSelectedDialogOnButton);

    setBot((prevBot) => {
      const updatedKeyWords = prevBot.buttonKeywords.map((keyword) => {
        if (keyword.id === index) {
          return { ...keyword, selectedId: dialogId, selectValue: dialog };
        }
        return keyword;
      });

      return {
        ...prevBot,
        buttonKeywords: updatedKeyWords,
      };
    });
  };

  const handleInputChangeSectionButtonExternal = (id, property, value) => {
    setBot((prevBot) => {
      const updatedKeyWords = prevBot.buttonKeywords.map((keyword) => {
        if (keyword.id === id) {
          return {
            ...keyword,
            [property]: value, // Update the keyWords object with the new value
          };
        }
        return keyword;
      });

      return {
        ...prevBot,
        buttonKeywords: updatedKeyWords,
      };
    });
  };

  return (
    <div>
      <div className="triggerOptionWrp">
        <div className="topHeading">
          <h3>
            {isEditingBName ? (
              <input
                type="text"
                value={textBName}
                onChange={handleChangeBName}
                placeholder="Enter Bot Name"
                className="triggerInput"
              />
            ) : (
              textBName || "Enter bot name"
            )}
            {isEditingBName ? (
              <button className="triggerBtn" onClick={handleSaveClickBName}>
                Save
              </button>
            ) : (
              <Edit onClick={handleEditClickBName} />
            )}
          </h3>
        </div>
        <div className="triggerOptionContent">
          <div className="instructionTxt">
            <span>A trigger is an event that starts your Flow.</span>{" "}
            <br />
            <span>To close the bot type exit.</span>
          </div>

          <div className="keywordMessage ">
            <button
              type="button"
              className="keywordBtn"
              onClick={() => addSectionTrigger("button")}
            >
              Add Button
            </button>
          </div>

          {/* Send Button start */}
          {paramsState
            ? //we have param state
              bot.buttonKeywords?.map((section, index) => (
                <div key={index}>
                  <div className="textInputField">
                    <div className="textInputLabel">
                      <label htmlFor="">Add button </label>
                      <div
                        className="txtCounter"
                        style={{
                          position: "relative",
                          top: "unset",
                          right: "unset",
                        }}
                      ></div>

                      <div className="textInputBtn">
                        <ul>
                          <li>
                            <button
                              type="button"
                              onClick={() => {
                                handleSectionClosing(section.id);
                              }}
                            >
                              <Close />
                            </button>
                          </li>
                          <li>
                            <button type="button">{/* <Copy /> */}</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="textAreaAndBtn width-90">
                      <div className="buttonDisplayBtn">
                        <button type="button" className="displayBtn">
                          {section?.value || "New Button"}
                        </button>
                      </div>
                      <div className="buttonToggleBtn">
                        <div className="uk-inline width-90">
                          <button type="button" className="toggleBtn">
                            <span></span>
                          </button>
                          <div
                            uk-dropdown="mode: click; pos: right-center"
                            className="locationDropdown"
                            id={`buttonMainDropdown_${index}`}
                          >
                            <div className="dropdownHeading">
                              <span>Edit button</span>
                            </div>
                            <div className="formWrp">
                              <div className="uk-grid uk-grid-small" uk-grid="">
                                <div className="uk-width-1-1">
                                  <div className="formLabel">
                                    <span>Button text</span>
                                    <div
                                      className="txtCounter"
                                      style={{
                                        position: "relative",
                                        top: "unset",
                                        right: "unset",
                                      }}
                                    >
                                      <span>
                                        {section?.value === undefined
                                          ? 0
                                          : section?.value?.length}
                                      </span>
                                      <span>/</span>
                                      <span>20</span>
                                    </div>
                                  </div>
                                  <div className="formInput">
                                    <input
                                      type="text"
                                      className="uk-input"
                                      placeholder="Enter button name"
                                      id={`$keywordBtn{index}`}
                                      name={`$keywordBtn{index}`}
                                      value={section.value}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue.length <= 20) {
                                          handleInputChangeSectionButton(
                                            section.id,
                                            e.target.value
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="uk-width-1-1">
                                  <div className="formLabel">
                                    <span>When this button is pressed</span>
                                  </div>
                                  <div className="uk-inline locationInsideDropdown">
                                    <button
                                      className="getLocationBtn"
                                      type="button"
                                    >
                                      {section?.type === "typeAgent"
                                        ? `Assign to agent`
                                        : section?.type === "typeDialog"
                                        ? "Click to select a dialog "
                                        : section?.type === "typeTeam"
                                        ? "Click to select a team"
                                        : section?.type === "typeExternal"
                                        ? "External Request"
                                        : "Assign to agent"}

                                      <ChevronDown />
                                    </button>
                                    <div
                                      uk-dropdown="mode: click"
                                      className="locationDropContent newDropdown"
                                      id={`actionDropdown_${index}`}
                                    >
                                      <ul>
                                        <li>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              toggleDialogHidden(index);
                                              closeButtonPressDrop(
                                                `#actionDropdown_${index}`
                                              );
                                              setSectionDetailType(
                                                "typeAgent",
                                                section.id
                                              );
                                            }}
                                          >
                                            Assign to agent
                                          </button>
                                        </li>

                                        <li className="">
                                          <button
                                            type="button"
                                            onClick={() => {
                                              toggleActionVisibility(index);

                                              closeActionDropdown(
                                                `#actionDropdown_${index}`
                                              );

                                              setSectionDetailType(
                                                "typeDialog",
                                                section.id
                                              );
                                            }}
                                          >
                                            Click to select a dialog
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              toggleFormVisibility(index);
                                              closeActionDropdown(
                                                `#actionDropdown_${index}`
                                              );
                                              setSectionDetailType(
                                                "typeTeam",
                                                section.id
                                              );
                                            }}
                                          >
                                            Click to select a team
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              toggleDialogHidden(index);
                                              closeButtonPressDrop(
                                                `#actionDropdown_${index}`
                                              );

                                              setSectionDetailType(
                                                "typeExternal",
                                                section.id
                                              );
                                            }}
                                          >
                                            External request
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>

                                {section?.type === "typeAgent" ? (
                                  <>
                                    <div className="uk-width-1-1">
                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          {selectedDialogOnButton[section.id] ||
                                            section?.selectValue ||
                                            "Select an option"}
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent customDialogDropdown"
                                          id={`getLocationBtn_${index}`}
                                        >
                                          <div
                                            className="dropdownHeading"
                                            style={{ border: "none" }}
                                          >
                                            <span>Routings </span>
                                          </div>
                                          <ul>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButton(
                                                    "Same page",
                                                    section.id
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${index}`
                                                  );
                                                }}
                                              >
                                                Same Page
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButton(
                                                    "Next page",
                                                    section.id
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${index}`
                                                  );
                                                }}
                                              >
                                                Next Page
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>

                                    {/* timingsx */}
                                    <div className="uk-width-1-1">
                                      <div className="formLabel">
                                        <span>Timings</span>
                                      </div>

                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          {handleDialogSelectOnButtonTimings[
                                            section.id
                                          ] ||
                                            section?.timings?.type ||
                                            "Select timings"}
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent customDialogDropdown"
                                          id={`getLocationBtn_${index}`}
                                        >
                                          <ul>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButtonTimings(
                                                    "Default",
                                                    section.id
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${index}`
                                                  );
                                                }}
                                              >
                                                Default
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButtonTimings(
                                                    "Manual",
                                                    section.id
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${index}`
                                                  );
                                                }}
                                              >
                                                Manual
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    {/* time start and end */}
                                    {section?.timings?.type === "Manual" ||
                                    handleDialogSelectOnButtonTimings[
                                      section.id
                                    ] === "Manual" ? (
                                      <>
                                        <div className="uk-width-1-1">
                                          <div className="formLabel">
                                            <span>Start time</span>
                                          </div>
                                          <div className="settingDropdown">
                                            <input
                                              className="dropdownBtn uk-input"
                                              id="appt-time"
                                              type="time"
                                              name="appt-time"
                                              defaultValue="00"
                                              value={
                                                section?.timings?.startTime
                                              }
                                              onChange={(e) =>
                                                handleTimeChange(
                                                  section.id,
                                                  "startTime",
                                                  e.target.value
                                                )
                                              }
                                              style={{
                                                width: "100% !important",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                          <div className="formLabel">
                                            <span>End time</span>
                                          </div>
                                          <div className="settingDropdown">
                                            <input
                                              className="dropdownBtn uk-input"
                                              id="appt-time"
                                              type="time"
                                              name="appt-time"
                                              defaultValue="00"
                                              value={section?.timings?.endTime}
                                              onChange={(e) =>
                                                handleTimeChange(
                                                  section.id,
                                                  "endTime",
                                                  e.target.value
                                                )
                                              }
                                              style={{
                                                width: "100% !important",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                          <div className="textInputWrp">
                                            <textarea
                                              name=""
                                              className="uk-textarea"
                                              placeholder="Enter your text"
                                              value={
                                                section?.timings?.timingsText
                                              }
                                              onChange={(e) => {
                                                handleInputTimingsText(
                                                  section.id,
                                                  "timingsText",
                                                  e.target.value
                                                );
                                              }}
                                            ></textarea>
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                    {/* time start and end */}
                                  </>
                                ) : section?.type === "typeDialog" ? (
                                  <div className="uk-width-1-1">
                                    <div className="uk-inline locationInsideDropdown">
                                      <button
                                        className="getLocationBtn"
                                        type="button"
                                      >
                                        {selectedDialogOnButton[section.id] ||
                                          section?.selectValue ||
                                          "Select an existing dialog "}
                                        <ChevronDown />
                                      </button>
                                      <div
                                        uk-dropdown="mode: click"
                                        className="locationDropContent customDialogDropdown"
                                        id={`getLocationBtn_${index}`}
                                      >
                                        <div
                                          className="dropdownHeading"
                                          style={{ border: "none" }}
                                        >
                                          <span>Dialogs </span>
                                        </div>
                                        <ul>
                                          {sections?.map((item) => (
                                            <li key={item.id}>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButtonTrigger(
                                                    item.dialogueTitle,
                                                    section.id,
                                                    item.dialogueId
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${index}`
                                                  );
                                                }}
                                              >
                                                {item.dialogueTitle}
                                              </button>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ) : section?.type === "typeTeam" ? (
                                  <div className="uk-width-1-1">
                                    <div className="uk-inline locationInsideDropdown">
                                      <button
                                        className="getLocationBtn"
                                        type="button"
                                      >
                                        {selectedDialogOnButton[section.id] ||
                                          section?.selectValue ||
                                          "Select an existing team "}

                                        <ChevronDown />
                                      </button>
                                      <div
                                        uk-dropdown="mode: click"
                                        className="locationDropContent customDialogDropdown"
                                        id={`getLocationBtn_${index}`}
                                      >
                                        <div
                                          className="dropdownHeading"
                                          style={{ border: "none" }}
                                        >
                                          <span>Teams</span>
                                        </div>
                                        <ul>
                                          {teamsData?.map((item) => (
                                            <li key={item.publicId}>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButtonTrigger(
                                                    item.publicId,
                                                    section.id,
                                                    item.dialogueId,
                                                    item.name
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${index}`
                                                  );
                                                }}
                                              >
                                                {item.name}
                                              </button>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ) : section?.type === "typeExternal" ? (
                                  <div className="uk-width-1-1">
                                    <div className="formInput cat ">
                                      <input
                                        type="text"
                                        className="uk-input"
                                        placeholder="Enter external request"
                                        id={`${index}_${index}_header`}
                                        name={`${index}_${index}_header`}
                                        value={section?.externalRequest}
                                        onChange={(e) => {
                                          handleInputChangeSectionButtonExternal(
                                            section.id,
                                            "externalRequest",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                <div className="uk-width-1-1">
                                  <div className="btnwrp uk-margin-top">
                                    <button
                                      type="button"
                                      className="btn-1"
                                      onClick={() =>
                                        closeButtonDropdown(
                                          `#buttonMainDropdown_${index}`,
                                          section.type,
                                          section?.value
                                        )
                                      }
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      className="btn-2"
                                      onClick={() => {
                                        closeButtonDropdown(
                                          `#buttonMainDropdown_${index}`,
                                          section.type,
                                          section?.value
                                        );
                                      }}
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : //we do not have paramstate
              bot?.buttonKeywords?.map((section, index) => (
                <div key={index}>
                  <div className="textInputField">
                    <div className="textInputLabel">
                      <label htmlFor="">Add button </label>
                      <div
                        className="txtCounter"
                        style={{
                          position: "relative",
                          top: "unset",
                          right: "unset",
                        }}
                      ></div>

                      <div className="textInputBtn">
                        <ul>
                          <li>
                            <button
                              type="button"
                              onClick={() => {
                                handleSectionClosing(section.id);
                              }}
                            >
                              <Close />
                            </button>
                          </li>
                          <li>
                            <button type="button">{/* <Copy /> */}</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="textAreaAndBtn width-90">
                      <div className="buttonDisplayBtn">
                        <button type="button" className="displayBtn">
                          {section?.value || "New Button"}
                        </button>
                      </div>
                      <div className="buttonToggleBtn">
                        <div className="uk-inline width-90">
                          <button type="button" className="toggleBtn">
                            <span></span>
                          </button>
                          <div
                            uk-dropdown="mode: click; pos: right-center"
                            className="locationDropdown"
                            id={`buttonMainDropdown_${index}`}
                          >
                            <div className="dropdownHeading">
                              <span>Edit button</span>
                            </div>
                            <div className="formWrp">
                              <div className="uk-grid uk-grid-small" uk-grid="">
                                <div className="uk-width-1-1">
                                  <div className="formLabel">
                                    <span>Button text</span>
                                    <div
                                      className="txtCounter"
                                      style={{
                                        position: "relative",
                                        top: "unset",
                                        right: "unset",
                                      }}
                                    >
                                      <span>
                                        {section?.value === undefined
                                          ? 0
                                          : section?.value?.length}
                                      </span>
                                      <span>/</span>
                                      <span>20</span>
                                    </div>
                                  </div>
                                  <div className="formInput">
                                    <input
                                      type="text"
                                      className="uk-input"
                                      placeholder="Enter button name"
                                      id={`$keywordBtn{index}`}
                                      name={`$keywordBtn{index}`}
                                      value={section.value}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue.length <= 20) {
                                          handleInputChangeSectionButton(
                                            section.id,
                                            e.target.value
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="uk-width-1-1">
                                  <div className="formLabel">
                                    <span>When this button is pressed</span>
                                  </div>
                                  <div className="uk-inline locationInsideDropdown">
                                    <button
                                      className="getLocationBtn"
                                      type="button"
                                    >
                                      {section?.type === "typeAgent"
                                        ? `Assign to agent`
                                        : section?.type === "typeDialog"
                                        ? "Click to select a dialog "
                                        : section?.type === "typeTeam"
                                        ? "Click to select a team"
                                        : section?.type === "typeExternal"
                                        ? "External Request"
                                        : "Assign to agent"}

                                      <ChevronDown />
                                    </button>
                                    <div
                                      uk-dropdown="mode: click"
                                      className="locationDropContent newDropdown"
                                      id={`actionDropdown_${index}`}
                                    >
                                      <ul>
                                        <li>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              toggleDialogHidden(index);
                                              closeButtonPressDrop(
                                                `#actionDropdown_${index}`
                                              );
                                              setSectionDetailType(
                                                "typeAgent",
                                                section.id
                                              );
                                            }}
                                          >
                                            Assign to agent
                                          </button>
                                        </li>

                                        <li className="">
                                          <button
                                            type="button"
                                            onClick={() => {
                                              toggleActionVisibility(index);

                                              closeActionDropdown(
                                                `#actionDropdown_${index}`
                                              );

                                              setSectionDetailType(
                                                "typeDialog",
                                                section.id
                                              );
                                            }}
                                          >
                                            Click to select a dialog
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              toggleFormVisibility(index);
                                              closeActionDropdown(
                                                `#actionDropdown_${index}`
                                              );
                                              setSectionDetailType(
                                                "typeTeam",
                                                section.id
                                              );
                                            }}
                                          >
                                            Click to select a team
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              toggleDialogHidden(index);
                                              closeButtonPressDrop(
                                                `#actionDropdown_${index}`
                                              );

                                              setSectionDetailType(
                                                "typeExternal",
                                                section.id
                                              );
                                            }}
                                          >
                                            External request
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>

                                {section?.type === "typeAgent" ? (
                                  <>
                                    <div className="uk-width-1-1">
                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          {selectedDialogOnButton[section.id] ||
                                            section?.selectValue ||
                                            "Select an option"}
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent customDialogDropdown"
                                          id={`getLocationBtn_${index}`}
                                        >
                                          <div
                                            className="dropdownHeading"
                                            style={{ border: "none" }}
                                          >
                                            <span>Routings </span>
                                          </div>
                                          <ul>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButton(
                                                    "Same page",
                                                    section.id
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${index}`
                                                  );
                                                }}
                                              >
                                                Same Page
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButton(
                                                    "Next page",
                                                    section.id
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${index}`
                                                  );
                                                }}
                                              >
                                                Next Page
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>

                                    {/* timingsx */}
                                    <div className="uk-width-1-1">
                                      <div className="formLabel">
                                        <span>Timings</span>
                                      </div>

                                      <div className="uk-inline locationInsideDropdown">
                                        <button
                                          className="getLocationBtn"
                                          type="button"
                                        >
                                          {handleDialogSelectOnButtonTimings[
                                            section.id
                                          ] ||
                                            section?.timings?.type ||
                                            "Select timings"}
                                          <ChevronDown />
                                        </button>
                                        <div
                                          uk-dropdown="mode: click"
                                          className="locationDropContent customDialogDropdown"
                                          id={`getLocationBtn_${index}`}
                                        >
                                          <ul>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButtonTimings(
                                                    "Default",
                                                    section.id
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${index}`
                                                  );
                                                }}
                                              >
                                                Default
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButtonTimings(
                                                    "Manual",
                                                    section.id
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${index}`
                                                  );
                                                }}
                                              >
                                                Manual
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    {/* time start and end */}
                                    {section?.timings?.type === "Manual" ||
                                    handleDialogSelectOnButtonTimings[
                                      section.id
                                    ] === "Manual" ? (
                                      <>
                                        <div className="uk-width-1-1">
                                          <div className="formLabel">
                                            <span>Start time</span>
                                          </div>
                                          <div className="settingDropdown">
                                            <input
                                              className="dropdownBtn uk-input"
                                              id="appt-time"
                                              type="time"
                                              name="appt-time"
                                              defaultValue="00"
                                              value={
                                                section?.timings?.startTime
                                              }
                                              onChange={(e) =>
                                                handleTimeChange(
                                                  section.id,
                                                  "startTime",
                                                  e.target.value
                                                )
                                              }
                                              style={{
                                                width: "100% !important",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                          <div className="formLabel">
                                            <span>End time</span>
                                          </div>
                                          <div className="settingDropdown">
                                            <input
                                              className="dropdownBtn uk-input"
                                              id="appt-time"
                                              type="time"
                                              name="appt-time"
                                              defaultValue="00"
                                              value={section?.timings?.endTime}
                                              onChange={(e) =>
                                                handleTimeChange(
                                                  section.id,
                                                  "endTime",
                                                  e.target.value
                                                )
                                              }
                                              style={{
                                                width: "100% !important",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                          <div className="textInputWrp">
                                            <textarea
                                              name=""
                                              className="uk-textarea"
                                              placeholder="Enter your text"
                                              value={
                                                section?.timings?.timingsText
                                              }
                                              onChange={(e) => {
                                                handleInputTimingsText(
                                                  section.id,
                                                  "timingsText",
                                                  e.target.value
                                                );
                                              }}
                                            ></textarea>
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                    {/* time start and end */}
                                  </>
                                ) : section?.type === "typeDialog" ? (
                                  <div className="uk-width-1-1">
                                    <div className="uk-inline locationInsideDropdown">
                                      <button
                                        className="getLocationBtn"
                                        type="button"
                                      >
                                        {selectedDialogOnButton[section.id] ||
                                          section?.selectValue ||
                                          "Select an existing dialog "}
                                        <ChevronDown />
                                      </button>
                                      <div
                                        uk-dropdown="mode: click"
                                        className="locationDropContent customDialogDropdown"
                                        id={`getLocationBtn_${index}`}
                                      >
                                        <div
                                          className="dropdownHeading"
                                          style={{ border: "none" }}
                                        >
                                          <span>Dialogs </span>
                                        </div>
                                        <ul>
                                          {sections?.map((item) => (
                                            <li key={item.id}>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButtonTrigger(
                                                    item.dialogueTitle,
                                                    section.id,
                                                    item.dialogueId
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${index}`
                                                  );
                                                }}
                                              >
                                                {item.dialogueTitle}
                                              </button>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ) : section?.type === "typeTeam" ? (
                                  <div className="uk-width-1-1">
                                    <div className="uk-inline locationInsideDropdown">
                                      <button
                                        className="getLocationBtn"
                                        type="button"
                                      >
                                        {selectedDialogOnButton[section.id] ||
                                          section?.selectValue ||
                                          "Select an existing team "}

                                        <ChevronDown />
                                      </button>
                                      <div
                                        uk-dropdown="mode: click"
                                        className="locationDropContent customDialogDropdown"
                                        id={`getLocationBtn_${index}`}
                                      >
                                        <div
                                          className="dropdownHeading"
                                          style={{ border: "none" }}
                                        >
                                          <span>Teams</span>
                                        </div>
                                        <ul>
                                          {teamsData?.map((item) => (
                                            <li key={item.publicId}>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleDialogSelectOnButtonTrigger(
                                                    item.publicId,
                                                    section.id,
                                                    item.dialogueId,
                                                    item.name
                                                  );
                                                  closeUserDialogDrop(
                                                    `#getLocationBtn_${index}`
                                                  );
                                                }}
                                              >
                                                {item.name}
                                              </button>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ) : section?.type === "typeExternal" ? (
                                  <div className="uk-width-1-1">
                                    <div className="formInput cat ">
                                      <input
                                        type="text"
                                        className="uk-input"
                                        placeholder="Enter external request"
                                        id={`${index}_${index}_header`}
                                        name={`${index}_${index}_header`}
                                        value={section?.externalRequest}
                                        onChange={(e) => {
                                          handleInputChangeSectionButtonExternal(
                                            section.id,
                                            "externalRequest",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                <div className="uk-width-1-1">
                                  <div className="btnwrp uk-margin-top">
                                    <button
                                      type="button"
                                      className="btn-1"
                                      onClick={() =>
                                        closeButtonDropdown(
                                          `#buttonMainDropdown_${index}`,
                                          section.type,
                                          section?.value
                                        )
                                      }
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      className="btn-2"
                                      onClick={() => {
                                        closeButtonDropdown(
                                          `#buttonMainDropdown_${index}`,
                                          section.type,
                                          section?.value
                                        );
                                      }}
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

          {/* Send Button end */}
        </div>
      </div>
    </div>
  );
};

export default TriggerChatbot;
