import whatsappLogo from "../assets/images/wa.png";
import smsIcon from "../assets/images/ic_Integrations_sms.png";
import messengerIcon from "../assets/images/messenger-icon.png";
import instaIcon from "../assets/images/insta-icon.png";
import webchatbot from "../assets/images/webchatbot/chatbot-icon.png";



const channel = [
  { channel: "whatsapp", image: whatsappLogo },
  { channel: "instagram", image: instaIcon },
  { channel: "facebook", image: messengerIcon },
  { channel: "sms", image: smsIcon },
  { channel: "webchatbot", image: webchatbot },
];
export const channelImages = (channelName) => {
  var result = channel?.filter((val) => {
    return val.channel.toLowerCase() === channelName.toLowerCase();
  });

  return result.length > 0 ? result[0].image : null;

};
