import { useState } from "react";
import { ChevronRight, Download } from "@carbon/icons-react";
import React from "react";
import SelectDialogModal from "./modal/SelectDialogModal";
import TriggerChatbot from "./components/TriggerChatbot";
import DialogChatbot from "./components/DialogChatbot";
import { useContextChatBot } from "./ContextChatBot";
import Dialogs from "./components/Dialogs";
import chatVideo from "../../../assets/images/preview-video.mp4";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

const ChatbotBuilder = () => {
  const {
    triggerValue = [],
    openDialogModal,
    selectSectionFunc,
    selectSection,
    sections,
    saveBotObject,
    disableItemLive,
    hasTextarea,
    captionChecking,
    setchatbot,
    setParamsState,
    disableLive,
    btnCount,
    textBName,
    updatedKeywords,
    companyId,
  } = useContextChatBot() || {};
  const { publicId } = useParams();
  // setParamsState(publicId);
  const navigate = useNavigate();

  useEffect(() => {
    if (publicId) {
      setParamsState(publicId);
    }
  }, [publicId]);

  const selectedDialogIndex = sections?.findIndex(
    (dialog) => dialog.dialogueTitle === selectSection
  );

  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Function to replace URLs with clickable hyperlinks
  const linkifyText = (text) => {
    return text.split(urlRegex).map((part, index) => {
      if (index % 2 === 1) {
        // If it's an odd index, it's a URL, so make it a hyperlink
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      // Otherwise, it's plain text
      return part;
    });
  };

  const [reloadClicked, setReloadClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!reloadClicked) {
        event.preventDefault();
        event.returnValue = 'Are you sure you want to leave the page?';
        return event.returnValue;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [reloadClicked]);

  const handleModalClose = () => {
    const confirmed = window.confirm('Are you sure you want to leave the page?');
    if (confirmed) {
      setReloadClicked(false); // Reset reloadClicked state
      setShowModal(false);
      window.location.href = '/chatbot'; // Redirect to chatbot page
    }
  };

  useEffect(() => {
    if (!reloadClicked) {
      setShowModal(true);
    }
  }, [reloadClicked]);
  

  function formatDate(date) {
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  // Get today's date
  const today = new Date();

  // Format the date
  const formattedDate = formatDate(today);



  return (
    <>
      <div className="chatbotWrp createNewSegment uk-padding-remove">
        <div className="chatbotHeader">
          <div className="uk-grid uk-flex-middle" uk-grid="">
            <div className="uk-width-1-2">
              <div className="backBTn uk-padding-remove">
                <ul>
                  <li>
                    <a href="/chatbot">
                      <span>Chatbot flows</span>
                      </a>
                  </li>
                  <li>
                    <ChevronRight />
                  </li>
                  <li>
                    <span className="currentPage">Feedback flow</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="uk-width-1-2">
              <div className="headerBtn">
                <button
                  className="btn-2"
                  type="button"
                  onClick={handleModalClose }
                >
                  Close
                </button>
                <button
                  title={hasTextarea ? "Choose one element with button" : ""}
                  type="button"
                  className="btn-1 uk-margin-right"
                  onClick={saveBotObject}
                  disabled={hasTextarea || captionChecking}
                >
                  Set live
                </button>
                {/*<div className="uk-inline topBarDropDown">
                  <button type="button" className="menuIcon">
                    <OverflowMenuVertical />
                  </button>
                  <div uk-dropdown="" className="tableDropDown">
                    <ul>
                      <li>
                        <button className="trashBtn" type="button">
                          <TrashCan /> Delete
                        </button>
                      </li>
                    </ul>
                  </div> 
                </div>*/}
              </div>
            </div>
          </div>
        </div>
        <div className="chatbotBuilderWrp ">
          <div className="uk-grid " uk-grid="">
            <div className="uk-width-3-5">
              <div className="chatbotBuilderLeft">
                <div className="uk-grid uk-grid-small" uk-grid="">
                  <div className="uk-width-1-2">
                    <div className="triggerWrp">
                      <div className="stepHeading">
                        <span>Trigger</span>
                      </div>
                      <div
                        className="addTrigger uk-flex uk-flex-middle uk-flex-between"
                        onClick={() => selectSectionFunc("trigger")}
                      >
                        <div className="txtwrp" style={{ cursor: "pointer" }}>
                          <span>
                            <span>
                              {triggerValue?.length === 1
                                ? triggerValue[0] // Display the single value without joining
                                : triggerValue
                                    ?.filter((item) => item !== "Add a trigger")
                                    .join(", ") || "Add a trigger"}
                            </span>
                          </span>
                        </div>
                        <div className="btnwrp">
                          {/* <button type="button" className="btn-1">
                            <Edit />
                          </button>
                          <button type="button">
                            <TrashCan />
                          </button> */}
                        </div>
                      </div>

                      <div className="stepHeading">
                        <span>Dialogs</span>
                      </div>

                      <div>
                        <Dialogs />
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-2">
                    {/* Trigger section end */}
                    {selectSection === "trigger" && <TriggerChatbot />}
                    {/* Trigger section end */}

                    {/* Button listing start */}
                    {selectSection !== "trigger" && <DialogChatbot />}
                    {/* Button listing end */}
                  </div>
                </div>
              </div>
            </div>

            <div className="uk-width-2-5">
              <div
                className="mobilePreview uk-margin-top"
                style={{ height: "545px", width: "270px" }}
              >
                <div className="mobileMsgs" style={{ height: "450px" }}>
                  <div className="msgDate">
                    <span>{formattedDate}</span>
                  </div>
                  {sections !== undefined &&
                    sections[selectedDialogIndex]?.settings?.map(
                      (item, index) => (
                        <div key={index}>
                          <span className="p-0 uk-margin-top-m-0">
                            {item.type === "textarea" ? (
                              <div className="leftMsg">
                                <pre>{item.value || "..."}</pre>
                              </div>
                            ): 
                            item.type === "list" ? (
                              <div className="leftMsg">
                                <pre>{item.value || "..."}</pre>
                              </div>
                            )
                            : item.type === "image" && item.value ? (
                              <div className="image-container leftMsg">
                                <img
                                  src={item.name}
                                  alt={`Uploaded ${index}`}
                                />
                                <pre className="p-0 uk-margin-top-m-0">
                                  {item.caption}
                                </pre>
                              </div>
                            ) : item.type === "video" &&
                              item.value === undefined ? (
                              <div className="image-container leftMsg">
                                <video controls muted autoplay></video>
                              </div>
                            ) : item.type === "video" && item.value ? (
                              <div className="image-container leftMsg">
                                <video src={item.name} controls></video>
                                <pre className="p-0 uk-margin-top-m-0">
                                  {item.caption}
                                </pre>
                              </div>
                            ) : item.type === "file" && item.value ? (
                              <div className="leftMsg">
                                <span>
                                  <Download /> {item.name}
                                  <pre className="p-0 uk-margin-top-m-0">
                                    {" "}
                                    {item.caption}
                                  </pre>
                                </span>
                              </div>
                            ) : item.type === "button" ? (
                              <button
                                type="button"
                                className="chatbotPreviewBtn"
                              >
                                {item.value || "..."}
                              </button>
                            ) : item.type === "action" ? null : null}
                          </span>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {openDialogModal && (
          <SelectDialogModal closeModal={() => handleCloseDialogModal()} />
        )} */}
      </div>
    </>
  );
};

export default ChatbotBuilder;
