import React from "react";
import { useState } from "react";

const TaxModal = ({
  closeModal,
  addDollarRate,
  dollarRate,
  setDollarRate,
  loader
}) => {

  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>Add Dollar</span>
          </div>
          <div className="modalForm">
            <form action="">
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-1">
                  <div className="formInputs">
                    <label htmlFor="">Dollar rate</label>
                    <input
                      type="number"
                      className="uk-input"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setDollarRate(e.target.value);
                      }}
                    />
                    {dollarRate === "" && (
                      <div className="formErrors">Dollar Rate is required</div>
                    )}
                  </div>
                </div>

                <div className="uk-width-1-1">
                  <div className="btnwrp">
                    <button
                      type="button"
                      className="btn-1"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn-2"
                      onClick={addDollarRate}
                    >
                      {loader ? (
                        <div uk-spinner="" className="loader"></div>
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaxModal;
