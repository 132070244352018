import React, { useEffect, useRef, useState } from "react";
import {
  displayMessageStatusIcon,
  formatText,
  getToolTipPosition,
  sanitizedMessage,
} from "../../../Utils/chat/chatFunctions";
import {
  chatMessageDayandDateConvert,
  formatChatHoverDateTime,
} from "../../../Utils/DateFunctions";
import {
  Attachment,
  Close,
  FaceSatisfied,
  Image,
  Microphone,
  TextBold,
  TextItalic,
} from "@carbon/icons-react";
import { apiDeleteSuperAdmin, apiPost } from "../../../Utils/apiServices";
import $ from "jquery";
import { useAuth } from "../../../Context/AuthProvider";
import { decryptedValue } from "../../../Utils/RouteHashConversion";
import { debounce } from "lodash";
import Emoji from "../../../components/common/Chat/Emoji";
import GalleryModal from "../../../components/gallery/GalleryModal";
import VoiceModal from "../../chat/components/Modals/VoiceModal";
import FileLoader from "../../chat/components/Modals/FileLoader";
import { toast } from "react-toastify";

const InternalChatMessages = ({
  contactMessages,
  hasMoreMessages,
  pageForChatMessages,
  agentDetails,
  openFileLoader,
  setOpenFileLoader,
  getGalleryData,
  galleryData,
  apiCalledGallery,
  setApiCalledGallery,
  pageCount,
  handleScroll,
  paginationCalled,
  fetchChatList,
  // bottomRef
}) => {
  const { auth } = useAuth();
  const bottomRef = useRef(null);
  const [message, setMessage] = useState("");
  const [mimType, setMimType] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(null);
  const [filePath, setFilePath] = useState("");
  const [isAudioFileUploadingStarted, setIsAudioFileUploadingStarted] =
    useState(false);
  const mediaRecorderRef = useRef(null);
  const timerIntervalRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [copiedImageExist, setCopiedImageExist] = useState(null);
  const [copiedImagedetails, setCopiedImagedetails] = useState(null);
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);
  const textareaRef = useRef(null);
  const [filterText, setFilterText] = useState("");
  const [openEmoji, setOpenEmoji] = useState(false);
  const [openVoiceModal, setOpenVoiceModal] = useState(false);
  const [isRecordingStarted, setIsRecordingStarted] = useState(false);
  const [openGalleryModal, setOpenGalleryModal] = useState(false);
  const [timer, setTimer] = useState(0);
  const [previousMessagesHeight, setPreviousMessagesHeight] = useState(0);

  const allowedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/jpg",
    "video/mp4",
    "audio/mp3",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "text/csv",
    "audio/ogg",
  ].join(", ");
  const allowedImageFileTypes = ["image/jpeg", "image/png", "image/jpg"].join(
    ", "
  );

  const handleFile = (e, allowedTypes, isAudio) => {
    var selectedFile = isAudio ? e : e.target.files[0];
    let type = isAudio ? e.type : selectedFile.type;
    const maxSizeInMB = 10; // Change this value to your desired size
    if (selectedFile) {
      if (selectedFile.size / (1024 * 1024) > maxSizeInMB) {
        // alert(`File size exceeds the ${maxSizeInMB} MB limit`);
        setFileUploadError(`File size exceeds the ${maxSizeInMB} MB limit`);
        e.target.value = "";
      } else if (!allowedTypes.includes(selectedFile.type)) {
        // alert("Invalid file type. Please select a valid file.");
        setFileUploadError("Invalid file type. Please select a valid file.");
        e.target.value = "";
      } else {
        // File size and type are valid, you can proceed with the upload or further processing.
        // You might want to store the selected file in state or perform other actions here.
        setOpenFileLoader(true);
        if (type.includes("image")) {
          setMimType("image");
        }
        if (type.includes("video")) {
          setMimType("video");
        }
        if (type.includes("ogg")) {
          setMimType("audio");
        }
        if (type.includes("application") || type.includes("text/csv")) {
          setMimType("document");
        }
        if (!isAudio) {
          e.target.value = "";
        }
        var formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("agentPublicId", decryptedValue(auth.agentId));
        formData.append("companyId", decryptedValue(auth.companyId));
        apiPost(
          `/chat/upload-file`,
          onSuccessFileUpload,
          onFailureFileUpload,
          formData
        );
      }
    }
  };
  const onSuccessFileUpload = (response) => {
    if (response.status === 1) {
      setFilePath(response.message);
      setOpenFileLoader(false);
    }
  };
  const onFailureFileUpload = (error) => {
    console.log(error);
  };

  const sendMessage = () => {
    let data;
    if (filePath !== "") {
      var mediaPayloadStream = mimType;
      data = {
        type: "media",
        stream: mimType,
        name: agentDetails.customerName,
        recipientAgentId: agentDetails?.recipientAgentId,
        [mediaPayloadStream]: {
          fileId: filePath,
        },
      };
      apiPost(`/internal-chat/send`, onSuccesChatSend, onFailureChatSend, data);
    } else if (
      message !== "" ||
      document.getElementById("type_box").value !== ""
    ) {
      data = {
        type: "text",
        textBody: document.getElementById("type_box").value,
        name: agentDetails?.customerName,
        recipientAgentId: agentDetails?.recipientAgentId,
      };

      apiPost(`/internal-chat/send`, onSuccesChatSend, onFailureChatSend, data);
    }
  };
  const onSuccesChatSend = (response) => {
    if (response.status === 1) {
      fetchChatList()
      setMessage("");
      $("#type_box").val("");
      setFilePath("")
      // setFilePath("");
      // setCopiedImageExist(null);
      // setCopiedImagedetails(null);
      // if (isAudioFileUploadingStarted === true) {
      //   setIsAudioFileUploadingStarted(false);
      // }
    }
  };
  const onFailureChatSend = (error) => {
    console.log(error);
  };
  const handledelete = (fileId) => {
    apiDeleteSuperAdmin(
      `/chat/files/delete?fileId=${fileId}`,
      onSuccessDelete,
      onFailureDelete
    );
  };

  const onSuccessDelete = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        toast.success("File deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 1000);
      setTimeout(() => {
        getGalleryData(0);
      }, 1700);
    }
  };
  const onFailureDelete = (error) => {
    console.log(error);
  };

  const handleChange = (e) => {
    const newText = e.target.value;
    setMessage(newText);
    // if (newText.startsWith("/")) {
    //   setFilterText(newText.substring(1).toLowerCase()); // Update filter text
    //   // setShowQuickMessages(true); // Show the quick messages dropdown
    // }
  };

  const handleLoaderModal = () => {
    setFileUploadError(null);
    setOpenFileLoader(false);
  };
  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };

        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/ogg",
          });

          const audioUrl = URL.createObjectURL(audioBlob);
        };

        mediaRecorderRef.current.start();
        setIsRecording(true);

        // Start the timer
        timerIntervalRef.current = setInterval(() => {
          setTimer((prevTimer) => prevTimer + 1);
        }, 1000);
      })
      .catch((error) => console.error("Error accessing microphone:", error));
  };

  const toggleRecording = () => {
    if (isRecording) {
      // If recording is in progress, pause or stop recording
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state === "recording"
      ) {
        mediaRecorderRef.current.pause();
        clearInterval(timerIntervalRef.current);
      }
    } else {
      // If not recording, start or resume recording
      if (!mediaRecorderRef.current) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = (event) => {
              if (event.data.size > 0) {
                audioChunksRef.current.push(event.data);
              }
            };

            mediaRecorderRef.current.onstop = () => {
              const audioBlob = new Blob(audioChunksRef.current, {
                type: "audio/ogg",
              });
              const audioUrl = URL.createObjectURL(audioBlob);
              setIsRecordingStarted(true);
            };
          })
          .catch((error) =>
            console.error("Error accessing microphone:", error)
          );
      }

      if (mediaRecorderRef.current.state === "paused") {
        // If paused, resume recording
        mediaRecorderRef.current.resume();
        // Resume the timer
        timerIntervalRef.current = setInterval(() => {
          setTimer((prevTimer) => prevTimer + 1);
        }, 1000);
      } else {
        // If not paused, start recording
        mediaRecorderRef.current.start();
        // Set recording started to true
        setIsRecordingStarted(true);
        // Start the timer
        timerIntervalRef.current = setInterval(() => {
          setTimer((prevTimer) => prevTimer + 1);
        }, 1000);
      }
    }

    setIsRecording(!isRecording);
  };
  const sendRecording = () => {
    if (mediaRecorderRef.current) {
      // if (mediaRecorderRef.current.state === 'recording') {
      if (
        mediaRecorderRef.current.state === "recording" ||
        mediaRecorderRef.current.state === "paused"
      ) {
        // Stop recording if it's in progress
        mediaRecorderRef.current.stop();
        setIsRecording(false);
        clearInterval(timerIntervalRef.current);
      }

      // Handle the audio data
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        // Create a Blob from recorded audio chunks
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/ogg",
        });

        // let blob = new Blob(chunks, {type: media.type }), url = URL.createObjectURL(blob);

        var wavfromblob = new File([audioBlob], "incomingaudioclip.ogg", {
          type: audioBlob.type,
          // type: "audio/ogg; codecs=opus",
        });
        setIsAudioFileUploadingStarted(true);
        handleFile(wavfromblob, allowedFileTypes, true);
        // Reset audio chunks
        audioChunksRef.current = [];

        // Perform actions with the audioBlob (e.g., send it to the server)
        // uploadAudio(audioBlob);
      };

      // If recording was in progress, the onstop event will handle the audio data
      // Otherwise, handle the audio data immediately (e.g., if the recording was already stopped)
      if (mediaRecorderRef.current.state !== "recording") {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/ogg",
        });

        // Reset audio chunks
        audioChunksRef.current = [];

        // Perform actions with the audioBlob (e.g., send it to the server)
        // uploadAudio(audioBlob);
      }
    }
  };

//   useEffect(()=>{
// console.log(agentDetails)
//   },[agentDetails])

  const handleAddAsterisks = () => {
    if (selectionStart !== null && selectionEnd !== null) {
      // Add asterisks only at the selected position
      const inputField = document.getElementById("type_box");

      const modifiedText =
        inputField.value.slice(0, selectionStart) +
        "*" +
        inputField.value.slice(selectionStart, selectionEnd) +
        "*" +
        inputField.value.slice(selectionEnd);
      if (inputField) {
        inputField.value = modifiedText;
      }
      setMessage(modifiedText);
    }
  };

  const debouncedHandleSelect = debounce((event) => {
    setSelectionStart(event.target.selectionStart);
    setSelectionEnd(event.target.selectionEnd);
  }, 200); // Adjust the debounce delay as needed

  const handlePaste = (event) => {
    const clipboardItems = event.clipboardData.items;
    for (const item of clipboardItems) {
      if (item.type.startsWith("image/")) {
        const file = item.getAsFile();
        setCopiedImagedetails(file);
        const reader = new FileReader();
        reader.onload = (event) => {
          setCopiedImageExist(event.target.result);
          if (textareaRef.current) {
            textareaRef.current.disabled = true;
          }
        };
        reader.readAsDataURL(file);
        break;
      }
    }
  };

  const handleMessage = (e) => {
    if (!e.ctrlKey && e.keyCode === 13) {
      sendMessage();
      e.preventDefault();
      e.stopPropagation();
      $("#type_box").prop("disabled", true);
      setTimeout(() => {
        $("#type_box").prop("disabled", false);
      }, 2000);
      return false;
    } else if (e.keyCode === 191) {
      // Check if the keyCode is for '/'
      // setShowQuickMessages(true); // Show the quick messages dropdown
      setFilterText(""); // Reset filter text
    } else if (e.key.match(/[a-zA-Z]/) && message.startsWith("/")) {
      const searchText =
        message.substring(1).toLowerCase() + e.key.toLowerCase();
      setFilterText(searchText); // Update filter text
    } else if (e.ctrlKey && e.keyCode === 13) {
      $("#type_box").val($("#type_box").val() + "\n");
    }
  };

  const removeCopiedImage = () => {
    setCopiedImageExist(null);
    setCopiedImagedetails(null);
    textareaRef.current.disabled = false;
  };

  const handleOpenEmoji = (e) => {
    setOpenEmoji(true);
  };
  const hideEmoji = () => {
    setOpenEmoji(false);
  };
  const handleAddDoubleUnderscores = () => {
    if (selectionStart !== null && selectionEnd !== null) {
      // Add asterisks only at the selected position
      const inputField = document.getElementById("type_box");
      const modifiedText =
        inputField.value.slice(0, selectionStart) +
        "_" +
        inputField.value.slice(selectionStart, selectionEnd) +
        "_" +
        inputField.value.slice(selectionEnd);

      if (inputField) {
        inputField.value = modifiedText;
      }
      // setMessage(modifiedText);
    }
  };
  const handleOpenGalleryModal = () => {
    setOpenGalleryModal(true);
  };
  const handleOpenVoiceModal = () => {
    setOpenVoiceModal(true);
  };

  const handleCloseVoiceModal = () => {
    if (isRecording) {
      mediaRecorderRef.current = null;
      setIsRecording(false);
      setIsRecordingStarted(false);
      clearInterval(timerIntervalRef.current);
      setTimer(0);
    }
    setOpenVoiceModal(false);
  };

  const handleCloseGalleryModal = () => {
    setOpenGalleryModal(false);
  };

  useEffect(() => {
    if (filePath !== "") {
      if (!isRecording) {
        mediaRecorderRef.current = null;
        setIsRecording(false);
        setIsRecordingStarted(false);
        clearInterval(timerIntervalRef.current);
        setTimer(0);
        handleCloseVoiceModal();
      }
      if (copiedImageExist !== null || isAudioFileUploadingStarted === true) {
        sendMessage();
      }
      // sendMessage();
    }
  }, [filePath]);

  useEffect(() => {
    // if (isChatExpired !== null) {
    if (paginationCalled === false) {
      bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
      // bottomRef.current.scrollTop = addedMessagesHeight / 2;
    } else {
      const addedMessagesHeight = bottomRef.current.scrollHeight;

      // Set the scrollTop to the middle of the added height
      bottomRef.current.scrollTop = 
        addedMessagesHeight - previousMessagesHeight;
    }

    // console.log(bottomRef.current.scrollTop)
    // console.log(bottomRef.current.scroll)

    setPreviousMessagesHeight(bottomRef.current.scrollHeight);
  }, [contactMessages]);
  // }, [ newMessages]);

  return (
    <div className="uk-width-3-5 uk-padding-remove">
      <div className="">
        <div className="main-chat">
          <div
            className="chat-msgs"
            onScroll={handleScroll}
            style={{
              height: "calc(100vh -  289px)",
            }}
            ref={bottomRef}

            // onScroll={(e) => handleScroll(e)}
          >
            {
            contactMessages.length>0 &&
            contactMessages?.map((val, index) => {
              // const messageKey = val.id || `index-${index}`;
              const messageKey = `index-${index}`;
              const dateKey = `date-${messageKey}`;
              return (
                <React.Fragment key={messageKey}>
                  {index == 0 && pageForChatMessages > 0
                    ? hasMoreMessages && (
                        <div className="date-and-time">
                          <div className="txtwrp">
                            <div uk-spinner=""></div>
                          </div>
                        </div>
                      )
                    : null}
                  {index == 0 && (
                    <div className="date-and-time" key={dateKey}>
                      <div className="txtwrp">
                        <p>{chatMessageDayandDateConvert(val.timestamp)}</p>
                      </div>
                    </div>
                  )}

                  {index !== 0
                    ? chatMessageDayandDateConvert(
                        contactMessages[index - 1].timestamp
                      ) !== chatMessageDayandDateConvert(val?.timestamp) && (
                        <div className="date-and-time" key={dateKey}>
                          <div className="txtwrp">
                            <p>{chatMessageDayandDateConvert(val.timestamp)}</p>
                          </div>
                        </div>
                      )
                    : null}

                  <div
                    className={
                      val.senderAgentId !== decryptedValue(auth.idNumber)
                        ? "rec-msg"
                        : "send-msg"
                    }
                    key={messageKey}
                  >
                    {val.type === "text" && (
                      <div className="txtdiv">
                        {val.textBody.startsWith("https:") ||
                        val.textBody.startsWith("http:") ? (
                          <pre>
                            <a href={val.body} target="_blank">
                              {val.body}
                            </a>
                          </pre>
                        ) : (
                          <pre
                            uk-tooltip={`title: ${formatChatHoverDateTime(
                              val.timestamp
                            )}; pos: ${getToolTipPosition(
                              val.senderAgentId ===
                                decryptedValue(auth.idNumber)
                                ? true
                                : false
                            )}`}
                            dangerouslySetInnerHTML={sanitizedMessage(
                              formatText(val.textBody)
                            )}
                          ></pre>
                        )}
                      </div>
                    )}

                    {/*for image  */}
                    {val?.type === "media" &&
                      val?.filePath !== null &&
                      (val?.filePath?.includes("svg+xml") ||
                        val?.filePath?.includes("png") ||
                        val?.filePath?.includes("jpeg") ||
                        val?.filePath?.includes("jpg")) && (
                        <div
                          className="txtwrp"
                          uk-tooltip={`title: ${formatChatHoverDateTime(
                            val.timestamp
                          )}; pos: ${getToolTipPosition(
                            val.senderAgentId === decryptedValue(auth.idNumber)
                              ? true
                              : false
                          )}`}
                        >
                          <div
                            className="customWidth"
                            onClick={() =>
                              window.open(`${val?.filePath}`, "_blank")
                            }
                          >
                            <img
                              className="seen-tick"
                              src={val?.filePath}
                              alt=""
                              width={"200px"}
                              height={"200px"}
                            />
                            <div>{val.caption}</div>
                          </div>
                        </div>
                      )}
                    {/*for video  */}
                    {val?.type === "media" &&
                      val?.filePath !== null &&
                      val?.filePath?.includes("mp4") && (
                        <div
                          className="txtdiv"
                          uk-tooltip={`title: ${formatChatHoverDateTime(
                            val.timestamp
                          )}; pos: ${getToolTipPosition(
                            val.senderAgentId === decryptedValue(auth.idNumber)
                              ? true
                              : false
                          )}`}
                        >
                          <div className=" customWidth">
                            <video controls>
                              <source src={val.filePath} type="audio/ogg" />
                              Your browser does not support the audio element.
                            </video>
                            <div>{val.caption}</div>
                          </div>
                        </div>
                      )}
                    {/* for pdf */}
                    {val?.type === "media" &&
                      val?.filePath !== null &&
                      val?.filePath?.includes("pdf") && (
                        <div className="txtwrp">
                          <p
                            className="document"
                            uk-tooltip={`title: ${formatChatHoverDateTime(
                              val.timestamp
                            )}; pos: ${getToolTipPosition(
                              val.senderAgentId ===
                                decryptedValue(auth.idNumber)
                                ? true
                                : false
                            )}`}
                          >
                            <a href={val.filePath} target="_blank">
                              <iframe
                                src={val.filePath}
                                width="100%"
                                height="200px"
                              />
                              Click to download
                            </a>
                            <div>{val.caption}</div>
                          </p>
                        </div>
                      )}
                    {/* for word */}
                    {val?.type === "media" &&
                      val?.filePath !== null &&
                      val?.filePath?.includes("docx") && (
                        <div className="txtwrp">
                          <p
                            className="document"
                            uk-tooltip={`title: ${formatChatHoverDateTime(
                              val.timestamp
                            )}; pos: ${getToolTipPosition(
                              val.senderAgentId ===
                                decryptedValue(auth.idNumber)
                                ? true
                                : false
                            )}`}
                          >
                            <a href={val.filePath} target="_blank">
                              Click to download Word file
                            </a>
                            <div>{val.caption}</div>
                          </p>
                        </div>
                      )}
                    {/* for excel file  */}
                    {val?.type === "media" &&
                      val?.filePath !== null &&
                      (val?.filePath?.includes("ms-excel") ||
                        val?.filePath?.includes(".csv") ||
                        val?.filePath?.includes("spreadsheetml.sheet")) && (
                        <div className="txtwrp">
                          <p
                            className="document"
                            uk-tooltip={`title: ${formatChatHoverDateTime(
                              val.timestamp
                            )}; pos: ${getToolTipPosition(
                              val.senderAgentId ===
                                decryptedValue(auth.idNumber)
                                ? true
                                : false
                            )}`}
                          >
                            <a href={val.filePath} target="_blank">
                              Click to download Excel file
                            </a>
                            <div>{val.caption}</div>
                          </p>
                        </div>
                      )}

                    {val?.type === "media" &&
                      val?.filePath !== null &&
                      (val?.filePath?.includes("ogg") ||
                        val?.filePath?.includes("mp3")) && (
                        <div
                          className="txtdiv"
                          uk-tooltip={`title: ${formatChatHoverDateTime(
                            val.timestamp
                          )}; pos: ${getToolTipPosition(
                            val.senderAgentId === decryptedValue(auth.idNumber)
                              ? true
                              : false
                          )}`}
                        >
                          <audio controls>
                            <source src={val.filePath} />
                            Your browser does not support the audio element.
                          </audio>
                          <div>{val.caption}</div>
                        </div>
                      )}
                    {/* {val?.messageType === "media" &&
                      val?.filePath !== null &&
                      val?.filePath?.includes("presentation") && (
                        <div className="txtwrp">
                          {(val.isSent == "true" || val.isSent == true) &&
                            displayMessageStatusIcon(val.messageStatus)}
                          <p
                            className="document"
                            uk-tooltip={`title: ${formatChatHoverDateTime(
                              val.timestamp
                            )}; pos: ${getToolTipPosition(val.isSent)}`}
                          >
                            <a
                              href={val.filePath}
                              target="_blank"
                              // onClick={() =>
                              //   window.open(`${val?.filePath}`, "_blank")
                              // }
                            >
                              Click to download PPT
                            </a>
                            <div>{val.caption}</div>
                          </p>
                          {(val.isSent == "false" || val.isSent == false) &&
                            displayMessageStatusIcon(val.messageStatus)}
                        </div>
                      )} */}

                    {/* ***************************************** for send messages ************************************  */}

                    {/* ***************************************** for received messages ************************************  */}

                    {/* ***************************************** for received messages ************************************  */}
                    {/* <div ref={bottomRef}></div> */}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <div className="msgBox">
            <div className="msgBoxTabbing">
              <ul uk-tab="" className="tabBtn">
                <li>
                  <a href="/">Reply</a>
                </li>
                {/* <li>
                  <a href="/">Notes</a>
                </li>
                <li>
                  <a href="/">Events</a>
                </li> */}
              </ul>

              <ul className="uk-switcher uk-margin tabContent">
                <li className="chatMsgBox">
                  <div className="textBox">
                    <form action="">
                      <textarea
                        name="msg_box"
                        id="type_box"
                        onChange={(e) => handleChange(e)}
                        onKeyDown={(e) => handleMessage(e)}
                        onSelect={(event) => debouncedHandleSelect(event)}
                        placeholder={
                          !copiedImageExist
                            ? `Enter to send and Ctrl + Enter to start a new line \n or \n Enter / to see quick messages`
                            : ""
                        }
                        cols="4"
                        rows="10"
                        ref={textareaRef}
                        onPaste={handlePaste}
                        style={{
                          cursor: copiedImageExist ? "not-allowed" : "text",
                        }}
                      ></textarea>
                      {copiedImageExist && (
                        <div className="copiedImageContainer">
                          <img src={copiedImageExist} alt="Pasted" />
                          <button className="crossSelection">
                            <Close onClick={() => removeCopiedImage()} />
                          </button>
                        </div>
                      )}
                    </form>

                    <div className="btnwrp">
                      <div className="chatEdit">
                        {copiedImageExist === null ? (
                          <ul>
                            <li>
                              {openEmoji && (
                                <button id="emoji">
                                  <Emoji id="type_box" />
                                </button>
                              )}
                              {!openEmoji ? (
                                <button
                                  id="emojidiv"
                                  onClick={(e) => handleOpenEmoji()}
                                >
                                  <FaceSatisfied />
                                </button>
                              ) : (
                                <button id="emojiCross" className="emojiCross">
                                  <Close onClick={(e) => hideEmoji()} />
                                </button>
                              )}
                            </li>
                            {/* hide options for instagram only  */}

                            <li>
                              {/* <button type="button" onClick={handleBoldText}> */}
                              <button
                                type="button"
                                onClick={handleAddAsterisks}
                              >
                                <TextBold />
                              </button>
                            </li>

                            <li>
                              <button
                                type="button"
                                onClick={handleAddDoubleUnderscores}
                              >
                                <TextItalic />
                              </button>
                            </li>

                            {/* <li>
                            <div className="js-upload" uk-form-custom="">
                              <input
                                type="file"
                                id="file_image"
                                title="Photos & Videos"
                                accept="image/*"
                                onChange={(e) =>
                                  handleFile(e, allowedImageFileTypes)
                                }
                                // onClick={handleOpenGalleryModal}
                              />
                              <button type="button">
                                <Image />
                              </button>
                            </div>
                          </li> */}

                            <li>
                              <button
                                type="button"
                                onClick={() => handleOpenGalleryModal()}
                              >
                                <Attachment />
                              </button>
                            </li>

                            <li>
                              <button
                                type="button"
                                onClick={handleOpenVoiceModal}
                              >
                                <Microphone />
                              </button>
                            </li>
                          </ul>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="submitBtn msgBoxSubmitBtn">
                    <button
                      type="button"
                      onClick={() =>
                        copiedImageExist !== null
                          ? handleFile(
                              copiedImagedetails,
                              allowedImageFileTypes,
                              true
                            )
                          : sendMessage()
                      }
                    >
                      Send{" "}
                      {/* {
                                chatDetails.channel
                                  .toLowerCase()
                                  .charAt(0)
                                  .toUpperCase() +
                                  chatDetails.channel.toLowerCase().slice(1)} */}
                    </button>
                  </div>
                </li>
                {/* <li>
                  <div className="notesTab">
                    <div className="notesItem">
                      <textarea
                        name="msg_box"
                        placeholder="Enter to send and Ctrl + Enter to start a new line"
                        // value={notes}
                        // onChange={(e) => {
                        //   setNotes(e.target.value);
                        // }}
                      ></textarea>
                    </div>
                    <div className="addNoteBtn">
                      <button
                        type="button"
                        // onClick={sendNotes}
                      >
                        Add Note
                      </button>
                    </div>
                  </div>
                </li> */}
                {/* <li>
                  <div className="eventsTab">
                    <div className="eventsItem">
                      <ul>
                        {contactMessages?.chatEvents?.map((val, index) => {
                          return (
                            <li key={index}>
                              <p>{eventDateTime(val.createDateTime)}</p>
                              <p>{val.message}</p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {openFileLoader && (
        <FileLoader errors={fileUploadError} closeModal={handleLoaderModal} />
      )}

      {openVoiceModal && (
        <VoiceModal
          closeModal={handleCloseVoiceModal}
          startRecording={startRecording}
          isRecording={isRecording}
          toggleRecording={toggleRecording}
          isRecordingStarted={isRecordingStarted}
          sendRecording={sendRecording}
          timer={timer}
        />
      )}
      {openGalleryModal && (
        <GalleryModal
          closeModal={handleCloseGalleryModal}
          setFilePath={setFilePath}
          filePath={filePath}
          sendMessage={sendMessage}
          setMimType={setMimType}
          getGalleryData={getGalleryData}
          galleryData={galleryData}
          pageCount={pageCount}
          setApiCalledGallery={setApiCalledGallery}
          apiCalledGallery={apiCalledGallery}
          handledelete={handledelete}
        />
      )}
    </div>
  );
};

export default InternalChatMessages;
