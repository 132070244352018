import CryptoJS from "crypto-js";

// const secretKey = process.env.REACT_APP_SECRET_HASH_KEY;
// const secretKey = 'wdsOP0k1mxcnm23dkk1234dfp35FGVIOMHHBHVHGVG110b';

const secretKey = localStorage.getItem("_token");

export const encryptedValue = (originalValue, key) => {
  const cipherText = CryptoJS.AES.encrypt(
    originalValue,
    key !== undefined ? key : secretKey
  ).toString();
  // Convert to URL-safe Base64 encoding
  const urlSafeBase64 = CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(cipherText)
  );
  // Remove all non-alphanumeric characters
  const cleanedString = urlSafeBase64.replace(/[^a-zA-Z0-9]/g, "");
  return cleanedString;
};

export const decryptedValue = (encryptedText) => {
  try {
    const cipherText = CryptoJS.enc.Base64.parse(encryptedText).toString(
      CryptoJS.enc.Utf8
    );
    const decryptedBytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return decryptedBytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    // if (window.location.pathname !== "/") {
    //   localStorage.clear();
    //   window.location.href = "/";
    // }
  }
};

export const tokenEncryption = (originalValue, key) => {

  // if (!originalValue || typeof originalValue !== 'string') {
  //     throw new Error('Invalid input value');
  // }

  // if (!secretKey || typeof secretKey !== 'string') {
  //     throw new Error('Invalid secret key');
  // }

  const cipherText = CryptoJS.AES.encrypt(
    originalValue,
    originalValue
  ).toString();
  return cipherText;
};

export const tokenDecryptValue = (encryptedValue) => {
  // if (!encryptedValue || typeof encryptedValue !== 'string') {
  //     throw new Error('Invalid encrypted value');
  // }

  // if (!secretKey || typeof secretKey !== 'string') {
  //     throw new Error('Invalid secret key');
  // }

  // Decrypt the encrypted value
  const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
  const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);

  return decryptedValue;
};
