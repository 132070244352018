import React, { useEffect, useState } from "react";
import avatar from "../../assets/images/webchatbot/avatar.png";
import {
  AddAlt,
  Attachment,
  CloseOutline,
  Image,
  TrashCan,
} from "@carbon/icons-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "react-switch";
import { decryptedValue } from "../../Utils/RouteHashConversion";
import { useAuth } from "../../Context/AuthProvider";
import { apiGet, apiPost, apiPut } from "../../Utils/apiServices";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useContextChatBotWeb } from "../Chatbot/web/ContextChatBot";

const Webchatbot = () => {
  const [botWidgetId, setbotWidgetId] = useState(null);
  const [color, setColor] = useState("#F6921E");
  const [welcomeMessage, setWelcomeMessage] = useState(
    "👋 Hi! How can we help?"
  );
  const [websiteBaseURL, setWebsiteBaseURL] = useState("");
  const script = null;

  const [isIdentifier, setIsIdentifier] = useState(false);
  const [email, setEmail] = useState(false);
  const [name, setName] = useState(false);
  const [number, setNumber] = useState(false);
  const [errors, setErrors] = useState({ email: "", number: "" });
  const [imageUpload, setImageUpload] = useState(null);
  const [searchParams] = useSearchParams();
  const [imagePreview, setImagePreview] = useState(null);
  const [botData, setBotData] = useState(null);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BASEURL;
  const baseURLAssets = process.env.REACT_APP_CHATASSETSURL;
  const { auth } = useAuth();

  useEffect(() => {
    setbotWidgetId(Math.floor(1000000000 + Math.random() * 90000000));
  }, []);

  const handleColorChange = (newColor) => {
    setColor(newColor);
  };

  const { setBot } = useContextChatBotWeb() || {};

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const validExtensions = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "image/webp",
      ];
      if (!validExtensions.includes(file.type)) {
        toast.error("Only JPG, JPEG, PNG, and WEBP files are allowed.");
        setImagePreview(null);
        setImageUpload(null);
        return;
      }
      if (file.size > 10 * 1024 * 1024) {
        toast.error("File size must be less than 10MB.");
        setImagePreview(null);
        setImageUpload(null);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
        setImageUpload(file);
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("agentPublicId", decryptedValue(auth.publicId));
      formData.append("companyId", decryptedValue(auth.companyId));
      apiPost(
        `/webChatBot/upload-logo`,
        onSuccessFileUpload,
        onFailureFileUpload,
        formData
      );
    }
  };

  const onSuccessFileUpload = (response) => {
    if (response.status === 1) {
      setImageUpload(response.message);
      toast.success("Image uploaded successfully");
    }
  };

  const onFailureFileUpload = (errorData) => {
    if (errorData?.message) {
      toast.error(errorData.message);
    } else {
      toast.error("Image upload failed");
    }
  };

  const handleChecked = () => {
    setIsIdentifier(!isIdentifier);
    setErrors({});
  };
  const handleInputClick = (event) => {
    const input = event.target;
    input.classList.toggle("selectedInput");
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    setImageUpload(null);
  };

  const webBotWidgetId = searchParams.get("webBotWidgetId");
  const handleSubmit = (event) => {
    event.preventDefault();
    const nameInput = document.querySelector('input[placeholder="Name"]');
    const emailInput = document.querySelector('input[placeholder="Email"]');
    const numberInput = document.querySelector('input[placeholder="Number"]');

    const isName = nameInput.classList.contains("selectedInput");
    const isEmail = emailInput.classList.contains("selectedInput");
    const isNumber = numberInput.classList.contains("selectedInput");

    if (isIdentifier) {
      if (!isEmail && !isNumber) {
        toast.error("Please provide either an email or a number.");
        return false;
      }
    }

    if (!imageUpload) {
      toast.error("Image is required!");
      return;
    }

    if (!color || !welcomeMessage || !botWidgetId || !websiteBaseURL) {
      toast.error("Please fill in all the required fields!");
      return;
    }

    const requestBody = {
      color,
      welcomeMessage,
      websiteBaseURL,
      botWidgetId,
      logoImageFileId: imageUpload,
      isIdentifier,
      nameIdentifier: isName || false,
      emailIdentifier: isEmail || false,
      numberIdentifier: isNumber || false,
      companyPublicId: decryptedValue(auth.companyId),
      botScript: script,
    };

    const searchParams = new URLSearchParams(window.location.search);
    const webBotWidgetId = searchParams.get("webBotWidgetId");

    if (webBotWidgetId) {
      const requestBodyUpdated = {
        color,
        welcomeMessage,
        websiteBaseURL,
        logoImageFileId: imageUpload,
        isIdentifier,
        nameIdentifier: isName || false,
        emailIdentifier: isEmail || false,
        numberIdentifier: isNumber || false,
      };
      apiPut(
        `/webChatBot/update-widget-details/${webBotWidgetId}`,
        onSuccessSubmitUpdate,
        onFailureSubmitUpdate,
        requestBodyUpdated
      );
    } else {
      apiPost(
        `/webChatBot/save-widget-details`,
        onSuccessSubmit,
        onFailureSubmit,
        requestBody
      );
    }
  };

  const onSuccessSubmit = (response) => {
    if (response.status === 200) {
      setTimeout(() => {
        toast.success("Widget saved!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1000);

      setTimeout(() => {
        window.location.href = `/web/chatbot-builder?botWidgetId=${botWidgetId}`;
      }, 2000);
    }
  };

  const onFailureSubmit = (errorData) => {
    if (errorData?.message) {
      toast.error(errorData.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error("Widget saved successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const onSuccessSubmitUpdate = (response) => {
    if (response.status === 200) {
      setTimeout(() => {
        toast.success("Widget updated successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1000);
      setTimeout(() => {
        navigate("/chatbot", { replace: true });
      }, 2000);
    }
  };

  const onFailureSubmitUpdate = (errorData) => {
    if (errorData?.message) {
      toast.error(errorData.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchBotData = () => {
    const botWidgetId = searchParams.get("webBotWidgetId") || "";
    apiGet(
      `/webChatBot/widget/${botWidgetId}`,
      onSuccessFetchBotData,
      onFailureFetchBotData
    );
  };

  const onSuccessFetchBotData = (data) => {
    setLoader(false);
    setBotData(data);
    setWelcomeMessage(data?.welcomeMessage);
    setWebsiteBaseURL(data?.websiteBaseURL);
    setImagePreview(baseURLAssets + data?.logoImageUrl);
    setImageUpload(baseURLAssets + data?.logoImageUrl);
    setColor(data?.color);
    setName(data?.nameIdentifier);
    setEmail(data?.emailIdentifier);
    setNumber(data?.numberIdentifier);
    setIsIdentifier(data?.isIdentifier);
  };

  const onFailureFetchBotData = (error) => {
    console.error("Error fetching data:", error);
    setLoader(true);
  };

  console.log("webBotWidgetId", webBotWidgetId);

  useEffect(() => {
    if (webBotWidgetId) {
      fetchBotData();
      getSingleChatBox();
    }
  }, [searchParams]);

  return (
    <div className="settingwrp webchatbotCustom">
      <div className="container">
        {webBotWidgetId && loader ? (
          <div className="flex-center">
            <div uk-spinner="" className="loader"></div>
          </div>
        ) : (
          <>
            <ToastContainer />
            <h2 className="main-heading">
              Customize the widget to suit your brand
            </h2>
            <div className="subheader">(you can change this later)</div>
            <div className="row">
              <div className="col-50">
                {/* Logo Section */}
                <div className="form-group">
                  <h3 className="mid-head">Logo</h3>
                  <div className="logoDesign">
                    <div className="logo-container">
                      <div className="custom-image-logo">
                        {!imagePreview && <Image />}
                        {imagePreview && (
                          <img
                            src={imagePreview}
                            alt="Uploaded"
                            width="100%"
                            height="100%"
                          />
                        )}
                        <div className="icon-actions custom-flex custom-flex-middle custom-flex-center">
                          <button
                            className="custom-plus-btn"
                            type="button"
                            onClick={() =>
                              imagePreview
                                ? handleRemoveImage()
                                : document.getElementById("file-input").click()
                            }
                          >
                            {imagePreview ? <CloseOutline /> : <AddAlt />}
                            <input
                              id="file-input"
                              type="file"
                              accept="image/png, image/jpeg"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Error Message */}
                {/* Removed since we're using toast.error */}

                {/* Color Section */}
                <div className="form-group">
                  <h3 className="mid-head">Color</h3>
                  <div className="color-options">
                    {["#0000ff", "#ff0000", "#800080", "#00ff00"].map(
                      (colorOption) => (
                        <div
                          key={colorOption}
                          className={`color-option ${
                            color === colorOption ? "selected" : ""
                          }`}
                          style={{ background: colorOption }}
                          onClick={() => handleColorChange(colorOption)}
                        />
                      )
                    )}
                    <input
                      type="color"
                      id="customColor"
                      name="customColor"
                      value={color}
                      className="color-picker"
                      onChange={(e) => handleColorChange(e.target.value)}
                    />
                  </div>
                </div>

                {/* Welcome Message Section */}
                <div className="form-group">
                  <h3 className="mid-head">Welcome Message</h3>
                  <textarea
                    id="welcome-message"
                    className="text-area-custom"
                    value={welcomeMessage}
                    onChange={(e) => setWelcomeMessage(e.target.value)}
                    placeholder="👋 Hi! How can we help?"
                  />
                  <h3 className="mid-head mt-0">Website URL</h3>

                  <input
                    type="text"
                    id="website_baseURl"
                    className="text-area-custom inputBaseURL"
                    value={websiteBaseURL}
                    onChange={(e) => setWebsiteBaseURL(e.target.value)}
                    placeholder="Enter your website URL"
                  />
                </div>

                {/* Suggestions Section */}
                <div className="form-group formgroupSugg">
                  <div className="identifier">
                    <h3 className="mid-head">Identifiers</h3>
                    <Switch
                      onChange={handleChecked}
                      checked={isIdentifier}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      width={26}
                      height={14}
                      handleDiameter={10}
                      onColor="#29A869"
                    />
                  </div>
                  <div className="subheader">
                    Enable identifiers in order to populate and store name,
                    email & number
                  </div>
                  <div className="suggestion-mian">
                    <div className="type-text">
                      <input
                        type="text"
                        placeholder="Name"
                        value="Name"
                        onChange={(e) => setName(e.target.value)}
                        onClick={handleInputClick}
                        disabled={!isIdentifier}
                        className={name ? "selectedInput" : ""}
                      />
                    </div>
                    <div className="type-text">
                      <input
                        type="text"
                        value="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        onClick={handleInputClick}
                        placeholder="Email"
                        disabled={!isIdentifier}
                        className={email ? "selectedInput" : ""}
                      />
                      {errors.email && (
                        <div className="error">{errors.email}</div>
                      )}
                    </div>
                    <div className="type-text">
                      <input
                        type="text"
                        value="Number"
                        onChange={(e) => setNumber(e.target.value)}
                        onClick={handleInputClick}
                        placeholder="Number"
                        disabled={!isIdentifier}
                        className={number ? "selectedInput" : ""}
                      />
                      {errors.number && (
                        <div className="error">{errors.number}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Preview Section */}
              <div className="col-50">
                <div className="right-side">
                  <div className="preview">
                    <div
                      className="preview-header"
                      style={{ background: color }}
                    >
                      Chat Widget
                    </div>
                    <div className="preview-body">
                      <div className="recievedMsg">
                        <img
                          src={avatar}
                          alt="Avatar"
                          width="40"
                          height="auto"
                        />
                        <p className="message" style={{ background: color }}>
                          {welcomeMessage}
                        </p>
                      </div>
                    </div>
                    <div className="preview-footer">
                      <div className="type-text">
                        <input
                          type="text"
                          placeholder="Type here and press enter.."
                        />
                      </div>
                      <div className="input-icons">
                        <Attachment />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-element">
              <button
                type="button"
                onClick={handleSubmit}
                className="custom-button btn"
              >
                {webBotWidgetId ? "Update" : "Next"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Webchatbot;
