import BroadcastAnalyticsPreview from "../pages/Broadcast/BroadcastAnalyticsPreview";
import LiveChatRoute from "../pages/NewAnalytics/LiveChatRoute";
import LiveChatTagsDetail from "../pages/NewAnalytics/LiveChatTagsDetail";
import LorealStats from "../pages/NewAnalytics/LorealStats";
import CustomizeInnerForm from "../pages/FormCentral/CustomizeInnerForm";
import ChatBotInner from "../pages/NewAnalytics/ChatBotInner";
import Otp from "../pages/Login/Otp";
import Chat from "../pages/chat/Chat";
import ThankYou from "../pages/dashboard/ThankYou";
import Help from "../pages/Help/Help";
import CreateNewSegment from "../pages/Contacts/CreateNewSegment";
import WinterShoppers from "../pages/Contacts/WinterShoppers";
import User from "../pages/UserAndTeam/User";
import AddUser from "../pages/UserAndTeam/AddUser";
import UserDetails from "../pages/UserAndTeam/UserDetails";
import CreateTeam from "../pages/UserAndTeam/CreateTeam";
import TeamDetail from "../pages/UserAndTeam/TeamDetail";
import EditSegment from "../pages/Contacts/EditSegment";
import ImportContact from "../pages/Contacts/ImportContact";
import NewAnalytics from "../pages/NewAnalytics/NewAnalytics";
import Broadcast from "../pages/Broadcast/Broadcast";
import Unauthorized from "../pages/Unauthorized/Unauthorized";
import BroadcastAnalytics from "../pages/Broadcast/BroadcastAnalytics";
import CreateBroadcast from "../pages/Broadcast/CreateBroadcast";
import CreateTempelateMarketing from "../pages/Broadcast/CreateTempelateMarketing";
import GeneralSetting from "../pages/Setting/GeneralSetting";
import NotificationSetting from "../pages/Setting/NotificationSetting";
import LiveChatBehavior from "../pages/Setting/LiveChatBehavior";
import SegmentSetting from "../pages/Setting/SegmentSetting";
import TagSetting from "../pages/Setting/TagSetting";
import ChatBot from "../pages/chat/components/Bot/ChatBot";
import Contacts from "../pages/Contacts/Contacts";
import Segments from "../pages/Contacts/Segments";
import Profile from "../pages/Profile/Profile";
import Statistics from "../pages/NewAnalytics/Statistics";
import Maupage from "../pages/NewAnalytics/Maupage";
import Conversationpage from "../pages/NewAnalytics/ConversationPage";
import TempelatePreview from "../pages/Broadcast/TempelatePreview";
import Teams from "../pages/UserAndTeam/Team";
import FormCentral from "../pages/FormCentral/FormCentral";
import ContactFormEntries from "../pages/FormCentral/ContactFormEntries";
import LiveChatFormEntries from "../pages/FormCentral/LiveChatFormEntries";
import CustomizeFormEntries from "../pages/FormCentral/CustomizeFormEntries";
import CreateNewRouting from "../pages/Setting/CreateNewRouting";
import ChatbotAnalytics from "../pages/NewAnalytics/ChatbotAnalytics";
import LiveChatAnalytics from "../pages/NewAnalytics/LiveChatAnalytics";
import TemplateMessage from "../pages/Broadcast/TemplateMessage";
import ApiMessages from "../pages/Broadcast/ApiMessages";
import Chatbot from "../pages/Chatbot/Chatbot";
import ChatbotBuilderW from "../pages/Chatbot/whatsapp/ChatbotBuilder";
import ChatbotBuilderF from "../pages/Chatbot/facebook/ChatbotBuilder";
import EditBroadcast from "../pages/Broadcast/EditBroadcast";
import LiveChatAgentInner from "../pages/NewAnalytics/LiveChatAgentInner";
import Login from "../pages/Login/Login";
import Footer from "../components/common/Footer/Footer";
import SplashScreen from "../components/loader/SplashScreen/SplashScreen";
import Dashboard from "../pages/dashboard/Dashboard";
import ForgotPassword from "../pages/Login/ForgotPassword";
import ChangePassword from "../pages/Login/ChangePassword";
import Header from "../components/common/Header/Header";
import ApplyForBoradcast from "../pages/dashboard/components/ApplyForBoradcast/ApplyForBoradcast";
import ApplyForLiveAgents from "../pages/dashboard/components/ApplyForLiveAgents/ApplyForLiveAgents";
import ApplyForSelfService from "../pages/dashboard/components/ApplyForSelfService/ApplyForSelfService";
import SuperAdminHome from "../pages/superAdmin/SuperAdminHome";
import SuperAdminList from "../pages/superAdmin/userCompanies/SuperAdminList";
import UserDetail from "../pages/superAdmin/userCompanies/UserDetail";
import CreateSuperAdmin from "../pages/superAdmin/userCompanies/CreateSuperAdmin";
import CompanyList from "../pages/superAdmin/userCompanies/CompanyList";
import SelectPackage from "../pages/superAdmin/userCompanies/SelectPackage";
import CreateCompany from "../pages/superAdmin/userCompanies/CreateCompany";
import SingleCompany from "../pages/superAdmin/userCompanies/SingleCompany";
import PackageList from "../pages/superAdmin/userCompanies/PackageList";
import AddPackage from "../pages/superAdmin/userCompanies/AddPackage";
import InvoiceList from "../pages/superAdmin/userCompanies/InvoiceList";
import InvoiceDetail from "../pages/superAdmin/userCompanies/InvoiceDetail";
import InvoicePreview from "../pages/superAdmin/userCompanies/InvoicePreview";
import NotFound from "../pages/404/NotFound";
import NewChatBot from "../pages/new chat bot/new-chatbot/NewChatBot";
import LiveChatRouting from "../pages/NewAnalytics/LiveChatRouting";
import SuperAdminSetting from "../pages/superAdmin/Settings/SuperAdminSetting";
import QuickReplyMessages from "../pages/Setting/QuickReplyMessages";
import EditSuperAdmin from "../pages/superAdmin/userCompanies/EditSuperAdmin";
import EditPackage from "../pages/superAdmin/userCompanies/EditPackage";
import SingleCompanyView from "../pages/superAdmin/userCompanies/SingleCompanyView";
import Orders from "../pages/NewAnalytics/Orders";
import Signup from "../pages/Signup/Signup";
import Reports from "../pages/Reports/Reports";
import ListEmployees from "../pages/TreetGroup/ListEmployees";
import ImportEmployees from "../pages/TreetGroup/ImportEmployees";
import Webchatbot from "../pages/Webchatbot/Webchatbot";
import { Script } from "@carbon/icons-react";
import ScriptGenerator from "../pages/Webchatbot/ScriptGenerator";
import ChatWID from "../pages/Webchatbot/Chat";
import ChatWidget from "../pages/Webchatbot/ChatWidget";
import ChatbotBuilderWeb from "../pages/Chatbot/web/ChatbotBuilder";
import InternalChat from "../pages/InternalChat/InternalChat";
import FacebookComments from "../pages/FacebookComments/FacebookComments";
import LiveChatAgents from "../pages/NewAnalytics/LiveChatAgents";
import LiveChatVoice from "../pages/NewAnalytics/LiveChatVoice";
import LiveChatChannels from "../pages/NewAnalytics/LiveChatChannels";
import LiveChatTags from "../pages/NewAnalytics/LiveChatTags";
import ChatbotSessionAnalytics from "../pages/NewAnalytics/ChatbotSessionAnalytics";
import ChatbotUserAnalytics from "../pages/NewAnalytics/ChatbotUserAnalytics";
import Channels from "../pages/channels/Channels";
import WhatsappForm from "../pages/channels/components/WhatsappForm";
import VoiceForm from "../pages/channels/components/VoiceForm";
import RequestForm from "../pages/channels/components/RequestForm";
import MessengerForm from "../pages/channels/components/MessengerForm";
import FacebookForm from "../pages/channels/components/FacebookForm";
import ChannelForm from "../pages/channels/components/ChannelForm";
import TransferLogs from "../pages/NewAnalytics/TransferLogs";
import EmbStats from "../pages/NewAnalytics/EmbStats";

export const publicRoutes = [
  {
    path: "/",
    component: <Login />,
    // footer: <Footer />,
  },
  {
    path: "/signup",
    component: <Signup />,
    footer: <Footer />,
  },

  {
    path: "/forgot-password",
    header: <Header />,
    component: <ForgotPassword />,
    footer: <Footer />,
  },
  {
    path: "/support",
    component: <Help />,
    header: <Header />,
  },
  {
    path: "/otp",
    header: <Header />,
    component: <Otp />,
    footer: <Footer />,
  },
  {
    path: "/change-password",
    header: <Header />,
    component: <ChangePassword />,
    footer: <Footer color="#F5F5F5" />,
  },
];
export const protectedRoutesWithoutSidebarLayout = [
  {
    path: "/welcome",
    component: <SplashScreen />,
  },
  {
    path: "/unauthorized",
    component: <Unauthorized />,
  },
  {
    path: "*",
    component: <NotFound />,
  },
  {
    path: "/invoice-preview",
    component: <InvoicePreview />,
  },
];
export const protectedRoutesWithSidebarLayout = [
  {
    path: "/dashboard",
    component: <Dashboard />,
    footer: <Footer extraPadding="70px" />,
    permission: ["FINANCE-DATA-MANAGER", "ANALYTICS-MANAGER"],
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/apply-for-broadcast",
    component: <ApplyForBoradcast />,
    header: <Header />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/apply-for-live-agents",
    component: <ApplyForLiveAgents />,
    header: <Header />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/apply-for-self-service",
    component: <ApplyForSelfService />,
    header: <Header />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/thank-you",
    component: <ThankYou />,
    header: <Header />,
    footer: <Footer color="#F5F5F5" />,
  },
  {
    path: "/chat",
    component: <Chat />,
    permission: [
      "LIVE-CHAT-AGENT",
      "LIVE-CHAT-MANAGER",
      "CHATBOT-CONVERSATION",
    ],
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR", "ROLE_AGENT"],
  },
  {
    path: "/chat-bot",
    component: <ChatBot />,
    permission: [
      "CHATBOT-CONVERSATION",
      "CHAT-BOT-MANAGER",
      "CHATBOT-SUPERVISOR",
    ],
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR", "ROLE_AGENT"],
  },
  {
    path: "/chat/:number",
    component: <Chat />,
    permission: [
      "LIVE-CHAT-AGENT",
      "LIVE-CHAT-MANAGER",
      "CHATBOT-CONVERSATION",
    ],
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR", "ROLE_AGENT"],
  },
  {
    path: "/chatbot",
    component: <Chatbot />,
    padding: true,
  },
  {
    path: "/chatbot/:publicId",
    component: <Chatbot />,
    padding: true,
  },

  // {
  //   path: "/:chatbot",
  //   component: <Chatbot />,
  //   padding: true,
  // },

  {
    path: "/whatsapp/chatbot-builder",
    component: <ChatbotBuilderW />,
    padding: true,
  },
  {
    path: "/new-chatbot",
    component: <NewChatBot />,
    padding: true,
  },
  {
    path: "/whatsapp/chatbot-builder/:publicId",
    component: <ChatbotBuilderW />,
    padding: true,
  },
  {
    path: "/facebook/chatbot-builder",
    component: <ChatbotBuilderF />,
    padding: true,
  },
  {
    path: "/facebook/chatbot-builder/:publicId",
    component: <ChatbotBuilderF />,
    padding: true,
  },

  //webchatbot builder
  {
    path: "/web/chatbot-builder",
    component: <ChatbotBuilderWeb />,
    padding: true,
  },
  {
    path: "/web/chatbot-builder/:publicId",
    component: <ChatbotBuilderWeb />,
    padding: true,
  },

  // {
  //   path: "/chatbot-builder",
  //   component: <ChatbotBuilder />,
  //   padding: true,
  // },
  // {
  //   path: "/chatbot-builder/:publicId",
  //   component: <ChatbotBuilder />,
  //   padding: true,
  // },
  {
    path: "/broadcast",
    component: <Broadcast />,
    permission: ["COMMUNICATION-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/broadcast-analytics/:publicId",
    component: <BroadcastAnalytics />,
    permission: ["COMMUNICATION-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/broadcast-analytics-preview/:publicId",
    component: <BroadcastAnalyticsPreview />,
    permission: ["COMMUNICATION-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/broadcast/:publicId",
    component: <EditBroadcast />,
    permission: ["COMMUNICATION-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/create-broadcast",
    component: <CreateBroadcast />,
    permission: ["COMMUNICATION-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/template-message",
    component: <TemplateMessage />,
    permission: ["COMMUNICATION-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/tempelate-preview/:publicId",
    permission: ["COMMUNICATION-MANAGER"],
    component: <TempelatePreview />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/create-template/:template",
    component: <CreateTempelateMarketing />,
    permission: ["COMMUNICATION-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/api-message",
    component: <ApiMessages />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/contacts",
    component: <Contacts />,
    permission: ["PEOPLE-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/import-contact",
    permission: ["PEOPLE-MANAGER"],
    component: <ImportContact />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/segments",
    component: <Segments />,
    permission: ["PEOPLE-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/create-new-segment",
    component: <CreateNewSegment />,
    permission: ["PEOPLE-MANAGER"],
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/contact/segment-detail/:publicId",
    component: <WinterShoppers />,
    permission: ["PEOPLE-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/edit-segment/:publicId",
    component: <EditSegment />,
    permission: ["PEOPLE-MANAGER"],
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/analytics",
    component: <NewAnalytics />,
    permission: ["FINANCE-DATA-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/statistics",
    component: <Statistics />,
    permission: ["LIVE-CHAT-MANAGER", "CHATBOT-SUPERVISOR"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/orders",
    component: <Orders />,
    permission: ["LIVE-CHAT-MANAGER", "CHATBOT-SUPERVISOR"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },

  {
    path: "/package-mau",
    component: <Maupage />,
    permission: ["ANALYTICS-MANAGER", "LIVE-CHAT-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/package-conversation",
    component: <Conversationpage />,
    permission: ["ANALYTICS-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/live-chat-analytics",
    component: <LiveChatAnalytics />,
    permission: ["ANALYTICS-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/live-chat-routing",
    component: <LiveChatRouting />,
    permission: ["ANALYTICS-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  // {
  //   path: "/live-chat-agent-detail",
  //   component: <LiveChatAgentInner />,
  //   permission: ["ANALYTICS-MANAGER"],
  //   footer: <Footer extraPadding="70px" />,
  //   padding: true,
  //   role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  // },
  {
    path: "/live-chat-agent",
    component: <LiveChatAgents />,
    permission: ["ANALYTICS-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/live-chat-route-detail",
    component: <LiveChatRoute />,
    permission: ["ANALYTICS-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/live-chat-channels",
    component: <LiveChatChannels />,
    permission: ["ANALYTICS-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/live-chat-voice-of-customer",
    component: <LiveChatVoice />,
    permission: ["ANALYTICS-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },

  {
    path: "/chatbot-detail/:windowName/:botPublicId/:startDate/:endDate",
    component: <ChatBotInner />,
    permission: ["ANALYTICS-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  // {
  //   path: "/live-chat-tags-detail",
  //   component: <LiveChatTagsDetail />,
  //   permission: ["ANALYTICS-MANAGER"],
  //   footer: <Footer extraPadding="70px" />,
  //   padding: true,
  //   role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  // },
  {
    path: "/live-chat-tags",
    component: <LiveChatTags />,
    permission: ["ANALYTICS-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/chatbot-analytics",
    component: <ChatbotAnalytics />,
    permission: ["ANALYTICS-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/chatbot-session-analytics",
    component: <ChatbotSessionAnalytics />,
    permission: ["ANALYTICS-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/chatbot-user-analytics",
    component: <ChatbotUserAnalytics />,
    permission: ["ANALYTICS-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/users",
    component: <User />,
    permission: ["USER-TEAMS"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/user-details/:publicId",
    component: <UserDetails />,
    permission: ["USER-TEAMS"],
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/add-user",
    component: <AddUser />,
    permission: ["USER-TEAMS"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/teams",
    component: <Teams />,
    permission: ["USER-TEAMS"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/create-team",
    component: <CreateTeam />,
    permission: ["USER-TEAMS"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/team-detail/:publicId",
    component: <TeamDetail />,
    permission: ["USER-TEAMS"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/profile/:publicId",
    component: <Profile />,
    padding: true,
  },
  {
    path: "/settings/general-setting",
    component: <GeneralSetting />,
    permission: ["ANALYTICS-MANAGER"],
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
    padding: true,
  },
  {
    path: "/settings/notification-setting",
    component: <NotificationSetting />,
    permission: ["ANALYTICS-MANAGER"],
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/settings/segement",
    component: <SegmentSetting />,
    permission: ["PEOPLE-MANAGER"],
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },

  {
    path: "/settings/tags",
    component: <TagSetting />,
    permission: ["PEOPLE-MANAGER"],
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/settings/quick-reply",
    component: <QuickReplyMessages />,
    permission: ["PEOPLE-MANAGER"],
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/webchatbot/builder",
    component: <Webchatbot />,
    // permission: ["PEOPLE-MANAGER"],
    padding: true,
    // role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/webchatbot/builder/:publicId",
    component: <Webchatbot />,
    // permission: ["PEOPLE-MANAGER"],
    padding: true,
    // role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/webchatbot/script",
    component: <ScriptGenerator />,
    // permission: ["PEOPLE-MANAGER"],
    padding: true,
    // role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/webchatbot/chat",
    component: <ChatWID />,
    // permission: ["PEOPLE-MANAGER"],
    padding: true,
    // role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  // {
  //   path: "/Webchatbot/ChatWidget",
  //   component: <ChatWidget />,
  //   // permission: ["PEOPLE-MANAGER"],
  //   padding: true,
  //   // role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  // },
  {
    path: "/Webchatbot/ChatWidget/:publicId",
    component: <ChatWidget />,
    // permission: ["PEOPLE-MANAGER"],
    padding: true,
    // role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/settings/live-chat-behavior",
    component: <LiveChatBehavior />,
    permission: ["LIVE-CHAT-MANAGER"],
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/settings/live-chat-behavior/:publicId",
    component: <CreateNewRouting />,
    permission: ["LIVE-CHAT-MANAGER"],
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/settings/create-new-routing",
    component: <CreateNewRouting />,
    footer: <Footer extraPadding="70px" />,
    permission: ["LIVE-CHAT-MANAGER"],
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/help",
    component: <Help />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },

  {
    path: "/form-central",
    component: <FormCentral />,
    footer: <Footer extraPadding="70px" />,
    permission: ["FORM-ACCESS-MANAGER"],
    padding: true,
  },
  // {
  //   path: "/advance-analytics",
  //   component: <Reports />,
  //   footer: <Footer extraPadding="70px" />,
  //   // permission: ["FORM-ACCESS-MANAGER"],
  //   padding: true,
  // },
  {
    path: "/contact-form-entries",
    component: <ContactFormEntries />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/live-chat-form-entries/:formId/:formName",
    component: <LiveChatFormEntries />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/customize-form-entries",
    component: <CustomizeFormEntries />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/customize-form",
    component: <CustomizeInnerForm />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },

  {
    path: "/loreal-stats",
    component: <LorealStats />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/ebm-stats",
    component: <EmbStats />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/list-employee",
    component: <ListEmployees />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/channels",
    component: <Channels />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/whatsapp-form",
    component: <WhatsappForm />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/messenger-form",
    component: <MessengerForm />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/facebook-form",
    component: <FacebookForm />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/voice-form",
    component: <VoiceForm />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/request-form",
    component: <RequestForm />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/channel-form",
    component: <ChannelForm />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/import-employee",
    component: <ImportEmployees />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  // {
  //   path: "/internal-chat",
  //   component: <InternalChat />,
  //   // padding: true,
  // },
  // {
  //   path: "/fb-comments",
  //   component: <FacebookComments />,
  //   padding: true,
  // },
  {
    path: "/liveChatAgent-detail/:publicId/:startDate/:endDate",
    component: <LiveChatAgentInner />,
    permission: ["ANALYTICS-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/live-chat-tags-detail/:name/:id/:startDate/:endDate",
    component: <LiveChatTagsDetail />,
    permission: ["ANALYTICS-MANAGER"],
    footer: <Footer extraPadding="70px" />,
    padding: true,
    role: ["ROLE_ADMIN", "ROLE_SUPERVISOR"],
  },
  {
    path: "/transfer-logs",
    component: <TransferLogs />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
];

//super admin
export const superAdminRoutes = [
  {
    path: "/super-admin-home",
    component: <SuperAdminHome />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/super-admin-users",
    component: <SuperAdminList />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/super-admin-profile",
    component: <UserDetail />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/super-admin-create-super-admin",
    component: <CreateSuperAdmin />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/edit-super-admin",
    component: <EditSuperAdmin />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/super-admin-company",
    component: <CompanyList />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/super-admin-select-package",
    component: <SelectPackage />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/super-admin-create-company",
    component: <CreateCompany />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/super-admin-single-company",
    component: <SingleCompany />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/super-admin-single-company-view",
    component: <SingleCompanyView />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/super-admin-package-list",
    component: <PackageList />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/super-admin-add-package",
    component: <AddPackage />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/super-admin-edit-package",
    component: <EditPackage />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/super-admin-invoice-list",
    component: <InvoiceList />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/superadmin-settings",
    component: <SuperAdminSetting />,
    padding: true,
  },
  {
    path: "/super-admin-invoice-detail",
    component: <InvoiceDetail />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/support",
    component: <Help />,
    header: <Header />,
  },
  {
    path: "/help",
    component: <Help />,
    footer: <Footer extraPadding="70px" />,
    padding: true,
  },
  {
    path: "/profile/:publicId",
    component: <Profile />,
    padding: true,
  },
];
