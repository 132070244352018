import React, { useState } from "react";
import berryTalkIcon from "../../assets/images/berry-talk-icon.svg";
import { ValidateEmail } from "../../Utils/regexFunctions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { apiPost, apiPostSuperAdmin } from "../../Utils/apiServices";
import { toast } from "react-toastify";
import GreetModal from "../../components/Modal/GreetModal";
import { useAuth } from "../../Context/AuthProvider";

const Signup = () => {
  const { setAuth } = useAuth();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [openGreetModal, setOpenGreetModal] = useState(false);
  const [numberEntered, setNumberEntered] = useState(false);
  const [phone, setPhone] = useState("");

  const [viewPassword, setViewPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [errorEnable, setErrorEnable] = useState(false);
  const [poc, setPoc] = useState({
    companyContactPersonName: "",
    companyContactPersonNumber: "",
    companyContactPersonEmail: "",
  });
  // const handleViewPassword = () => {
  //   setViewPassword(!viewPassword);
  // };

  const handleOpenGreetModal = () => {
    setOpenGreetModal(true);
  };

  const handleSignup = (e) => {
    let data = JSON.parse(localStorage.getItem("sign"));
    let code = JSON.parse(localStorage.getItem("code"));
    e.preventDefault();
    setLoader(true);
    setErrorEnable(true);
    data.companyContactPersonName = poc.companyContactPersonName;
    data.contactPersonEmail = poc.companyContactPersonEmail;
    data.contactPersonNumber = poc.companyContactPersonNumber;
    data.code = code;
    if (
      poc.companyContactPersonEmail !== "" &&
      poc.companyContactPersonName !== "" &&
      poc.companyContactPersonNumber !== "" &&
      emailError === "Valid Email Address"
    ) {
      apiPostSuperAdmin(
        // `/super-admin/public/onboard`,
        `/auth/signUp/onboard-and-login`,
        onSuccessStats,
        onFailureStats,
        data
      );
    } else {
      setLoader(false);
    }
  };

  const onSuccessStats = (response) => {
    if (response.status === 1) {
      localStorage.setItem("_token", response?.data?.tokenDetails?.token);
      localStorage.setItem("_email", response?.data?.userDetails?.email);
      localStorage.setItem("_publicId", response?.data?.userDetails?.publicId);
      localStorage.setItem(
        "_role",
        response?.data?.userDetails?.roles[0]?.name
      );
      localStorage.setItem("_userName", response?.data?.userDetails?.name);
      localStorage.setItem(
        "_companyId",
        response?.data?.userDetails?.companyId
      );
      localStorage.setItem("_idNumber", response?.data?.userDetails?.idNumber);
      localStorage.setItem("_agentId", response?.data?.userDetails?.agentId);
      localStorage.setItem("_tune", true);
      localStorage.setItem("_ver1", process.env.REACT_APP_VERSION);
      localStorage.setItem(
        `${response?.data?.tokenDetails?.token}`,
        response?.data?.tokenDetails?.token
      );
      setAuth((auth) => ({
        ...auth,
        token: response?.data?.tokenDetails?.token,
        role: response?.data?.userDetails?.roles[0]?.name,
        email: response?.data?.userDetails?.email,
        publicId: response?.data?.userDetails?.publicId,
        idNumber: response?.data?.userDetails?.idNumber,
        userName: response?.data?.userDetails?.name,
        companyId: response?.data?.userDetails?.companyId,
        agentId: response?.data?.userDetails?.agentId,
      }));
      setTimeout(() => {
        setLoader(false);
        toast.success("signup successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
        });
      }, 3500);
      setTimeout(() => {
        setIsUserLoggedIn(true);
      }, 6000);

      setTimeout(() => {
        window.location.href = "/welcome";
      }, 11500);
    }
  };
  const onFailureStats = (error) => {
    setLoader(false);
    if (error.response.data.status === 409) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 800,
      });
    } else {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 800,
      });
    }
  };

  const handleEmailValidation = (e) => {
    var Email = ValidateEmail(e.target.value);
    if (Email === "Invalid Email") {
      setEmailError("Invalid Email Address");
    } else {
      setEmailError("Valid Email Address");
    }
  };

  const handlePhoneChange = (newPhone, countryData) => {
    if (Object.keys(countryData).length !== 0) {
      setPhone(newPhone);
      // Passing the phone number to the global scope
      window.globalPhone = newPhone;
      window.globalCode = countryData.dialCode;

      const phoneNumber = parsePhoneNumberFromString(
        newPhone,
        countryData.countryCode.toUpperCase()
      );
      // console.log("phoneNumber", phoneNumber);
      if (phoneNumber !== undefined) {
        window.globalValidPhone = phoneNumber.isValid();
        // return phoneNumber.isValid();
      } else {
        window.globalValidPhone = false;
        // return false;
      }
    }
  };
  const handlePocPhone = (newPhone, countryData) => {
    if (Object.keys(countryData).length !== 0) {
      setPoc({
        ...poc,
        companyContactPersonNumber: newPhone,
      });
    }
  };

  return (
    <>
      {!isUserLoggedIn ? (
        <div className="loginWrp">
          <div uk-grid="" className="uk-grid">
            <div className="uk-width-1-2">
              <div className="imgwrp">
                <img src={berryTalkIcon} alt="" />
              </div>
            </div>
            <div className="uk-width-1-2">
              <div className="formwrp">
                <form name="basic-form" id="basic-form" action="javascript:;">
                  <div className="loginHeading">
                    <h2>Signup</h2>
                  </div>
                  <ul
                    className="uk-subnav uk-subnav-pill signupTab"
                    uk-switcher="connect: #newAnalyticsTab"
                  >
                    <li>
                      <a href="#">Comapny Detail</a>
                    </li>
                    <li>
                      <a href="#">WABA Signup</a>
                    </li>
                    <li>
                      <a href="#">POC Detail</a>
                    </li>
                  </ul>
                  <div className="uk-switcher uk-margin" id="newAnalyticsTab">
                    <div>
                      <div uk-gird="">
                        <div className="uk-width-1-1">
                          <div className="inputField">
                            <input
                              type="text"
                              placeholder="Company Name"
                              id="companyName"
                              name="companyName"
                            />
                            <div className="errors"></div>
                          </div>
                        </div>

                        <div className="uk-width-1-1">
                          <div style={{ marginBottom: "16px" }}>
                            <PhoneInput
                              country={"pk"}
                              placeholder="Whatsapp Number"
                              className="signupInput"
                              id="whatsapp"
                              name="whatsapp"
                              value={phone}
                              onChange={handlePhoneChange}
                            />
                            {phone === "" && numberEntered && (
                              <div className="errors">
                                whatsapp number is Required
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="uk-width-1-1">
                          <div className="inputField">
                            <input
                              type="text"
                              placeholder="Domain"
                              id="domainName"
                              name="domainName"
                            />
                            <div className="errors"></div>
                          </div>
                        </div>
                        <div className="uk-width-1-1">
                          <div className="inputField">
                            <input
                              type="text"
                              id="signUpEmail"
                              name="signUpEmail"
                              placeholder="Email Address"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="uk-width-1-1">
                          <div className="inputField">
                            <input
                              type={viewPassword ? "text" : "password"}
                              placeholder="Password"
                              id="SignupPasswordInput"
                              name="SignupPasswordInput"
                              autoComplete="off"
                            />

                            <div className="errors"></div>
                          </div>
                        </div>

                        <div className="uk-width-1-1">
                          <div className="signUpwrp">
                            <div className="forgetBtn"></div>
                            <div className="loginBtn w-90">
                              <button
                                type="submit"
                                disabled={loader ? true : false}
                                id="submit-btn"
                                onClick={() => setNumberEntered(true)}
                              >
                                {loader ? (
                                  <div uk-spinner="" className="loader"></div>
                                ) : (
                                  "Next"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="signuploader">
                      <div uk-spinner=""></div>
                    </div>
                    <div>
                      <div>
                        <div uk-gird="">
                          <div className="uk-width-1-1">
                            <div className="inputField">
                              <input
                                type="text"
                                placeholder="full name"
                                value={poc.companyContactPersonName}
                                onChange={(e) => {
                                  setPoc({
                                    ...poc,
                                    companyContactPersonName: e.target.value,
                                  });
                                }}
                              />
                              {poc.companyContactPersonName === "" &&
                                errorEnable && (
                                  <div className="errors">Name is Required</div>
                                )}
                            </div>
                          </div>

                          <div className="uk-width-1-1">
                            <div style={{ marginBottom: "16px" }}>
                              <PhoneInput
                                country={"pk"}
                                placeholder="mobile numer"
                                value={poc.companyContactPersonNumber}
                                onChange={handlePocPhone}
                              />
                              {poc.companyContactPersonNumber === "" &&
                                errorEnable && (
                                  <div className="errors">
                                    Mobile number is Required
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="uk-width-1-1">
                            <div className="inputField">
                              <input
                                type="text"
                                value={poc.companyContactPersonEmail}
                                placeholder="Email Address"
                                autoComplete="off"
                                onChange={(e) => {
                                  handleEmailValidation(e);
                                  setPoc({
                                    ...poc,
                                    companyContactPersonEmail: e.target.value,
                                  });
                                }}
                              />
                              {poc.companyContactPersonEmail === "" &&
                                errorEnable && (
                                  <div className="errors">
                                    Email is Required
                                  </div>
                                )}
                              {poc.companyContactPersonEmail !== "" &&
                                emailError !== "Valid Email Address" && (
                                  <div className="errors">
                                    Invalid Email Address
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="uk-width-1-1">
                            <div className="signUpwrp">
                              <div className="forgetBtn"></div>
                              <div
                                className={`loginBtn ${
                                  loader ? "w-90" : "w-110"
                                }`}
                              >
                                <button
                                  // type="submit"
                                  disabled={loader ? true : false}
                                  onClick={handleSignup}
                                >
                                  {loader ? (
                                    <div uk-spinner="" className="loader"></div>
                                  ) : (
                                    "Sign up"
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {openGreetModal && <GreetModal />}
        </div>
      ) : (
        <div className="redirecting">
          <div>
            <div>Redirecting . . . </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Signup;
