import { Checkmark, View, ViewOff } from "@carbon/icons-react";
import React, { useState } from "react";
import { apiPost } from "../../../../Utils/apiServices";
import { toast } from "react-toastify";
import { useAuth } from "../../../../Context/AuthProvider";

const ChangePassword = ({ closeModal, userDetails }) => {
  const { auth } = useAuth();
  const [currentPassword, setCurrentPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState(false);
  const [errorEnable, setErrorEnable] = useState(false);
  const [errorSpan, setErrorSpan] = useState("");
  const [passwordValidate, setPasswordValidate] = useState({
    lowerCase: false,
    upperCase: false,
    numbers: false,
    lengths: false,
    pwdPassword: false,
    specialCharacters: false,
  });
  const [submittedData, setSubmittedData] = useState({
    password: "",
    confirmNewPassword: "",
  });
  // const [lowerCase, setLowerCase] = useState(false);
  // const [upperCase, setUpperCase] = useState(false);
  // const [numbers, setNumbers] = useState(false);
  // const [lengths, setLengths] = useState(false);
  const [loader, setLoader] = useState(false);
  const [pwdPassword, setPwdPassword] = useState(false);
  const [password, setPassword] = useState("");
  // const handleCurrentPassword = () => {
  //   setCurrentPassword(!currentPassword);
  // };

  const handleNewPassword = () => {
    setNewPassword(!newPassword);
  };

  const handleConfirmNewPassword = () => {
    setConfirmNewPassword(!confirmNewPassword);
  };
  //   )
  const changePassword = (e) => {
    e.preventDefault();
    setErrorEnable(true);
    setLoader(true);
    let data = {
      password: submittedData.password,
      confirmPassword: submittedData.confirmNewPassword,
      phoneNumber: userDetails.phoneNumberWork,
      accessToken: auth.token,
    };
    if (
      passwordValidate.lowerCase === true &&
      passwordValidate.upperCase === true &&
      passwordValidate.numbers === true &&
      passwordValidate.lengths === true &&
      passwordValidate.specialCharacters === true &&
      pwdPassword === true &&
      submittedData.password !== "" &&
      submittedData.confirmNewPassword !== ""
    ) {
      apiPost(`/auth/change-pin-profile`, onSuccess, onFailure, data);
    } else {
      setLoader(false);
    }
  };
  const onSuccess = (response) => {
    if (response.status === 0) {
      setTimeout(() => {
        setLoader(false);
        toast.success("Password changed successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }, 1000);
      setTimeout(() => {
        closeModal();
      }, 1500);
    }
  };
  const onFailure = (error) => {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    // setLoader(false);
  };

  const handleValidation = (e) => {
    setErrorEnable(true);
    let value = e.target.value;

    if (value === submittedData.confirmNewPassword) {
      if (value !== "" && submittedData.confirmNewPassword !== "") {
        setPwdPassword(true);
        setErrorSpan(" ");
      }
    } else {
      setPwdPassword(false);
      setErrorSpan("Password requirements not matched");
      // setErrorSpan("Password requirements not matched");
    }

    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const lengthRegex = /^.{8,}$/;
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-=|]/;

    const isLowercaseValid = lowercaseRegex.test(value);
    const isUppercaseValid = uppercaseRegex.test(value);
    const isNumberValid = numberRegex.test(value);
    const isLengthValid = lengthRegex.test(value);
    const isSpecialCharRegex = specialCharRegex.test(value);

    setPasswordValidate({
      lowerCase: isLowercaseValid,
      upperCase: isUppercaseValid,
      numbers: isNumberValid,
      lengths: isLengthValid,
      specialCharacters: isSpecialCharRegex,
    });
    // setLowerCase(isLowercaseValid);
    // setUpperCase(isUppercaseValid);
    // setNumbers(isNumberValid);
    // setLengths(isLengthValid);
    setPassword(value);
  };

  const handleConfirmPwdValidation = (e) => {
    setErrorEnable(true);
    let value = e.target.value;
    // setConfirmPassword(value);
    if (value === password) {
      if (value !== "" && password !== "") {
        setPwdPassword(true);
        setErrorSpan(" ");
      }
    } else {
      setPwdPassword(false);
      setErrorSpan("Password requirements not matched");
    }
  };
  return (
    <div
      className="customModal ModalStyle uk-flex-top uk-open uk-flex uk-flex uk-open"
      uk-modal="esc-close: false; bg-close: false"
    >
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <div className="filterModalContent">
          <div className="modalHeading">
            <span>Change password</span>
          </div>
          <div className="modalForm">
            <div className="changePasswordInput">
              <div className="uk-grid" uk-grid="">
                {/* <div className="uk-width-1-1">
                  <div className="changePassField">
                    <label htmlFor="">Current Password</label>
                    <input type={currentPassword ? "type" : "password"} />
                    <button type="button" onClick={handleCurrentPassword}>
                      <View />
                    </button>
                  </div>
                </div> */}
                <div className="uk-width-1-1">
                  <div className="changePassField">
                    <label htmlFor="">Choose a new password</label>
                    <input
                      type={newPassword ? "type" : "password"}
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          password: e.target.value,
                        });
                        handleValidation(e);
                      }}
                      autoComplete="off"
                    />
                    <button type="button" onClick={handleNewPassword}>
                      {newPassword ? <View /> : <ViewOff />}
                    </button>
                  </div>
                </div>
                <div className="uk-width-1-1 uk-margin-remove-top">
                  <div className="passMatchWrp">
                    <ul>
                      <li>
                        <span
                          // className="checkmarkIcon"
                          className={
                            passwordValidate.lowerCase === true
                              ? "active"
                              : "checkmarkIcon"
                          }
                        >
                          <Checkmark />{" "}
                        </span>
                        <span className="checkmarktxt">Lowercase letter</span>
                      </li>
                      <li>
                        <span
                          // className="checkmarkIcon"
                          className={
                            passwordValidate.upperCase === true
                              ? "active"
                              : "checkmarkIcon"
                          }
                        >
                          <Checkmark />{" "}
                        </span>
                        <span className="checkmarktxt">Uppercase letter</span>
                      </li>
                      <li>
                        <span
                          // className="checkmarkIcon"
                          className={
                            passwordValidate.numbers === true
                              ? "active"
                              : "checkmarkIcon"
                          }
                        >
                          <Checkmark />{" "}
                        </span>
                        <span className="checkmarktxt">Number</span>
                      </li>
                      <li>
                        <span
                          className={
                            passwordValidate.lengths === true
                              ? "active"
                              : "checkmarkIcon"
                          }
                        >
                          <Checkmark />{" "}
                        </span>
                        <span className="checkmarktxt">
                          Minimum 8 characters
                        </span>
                      </li>
                      <li>
                        <span
                          className={
                            passwordValidate.specialCharacters === true
                              ? "active"
                              : "checkmarkIcon"
                          }
                        >
                          <Checkmark />{" "}
                        </span>
                        <span className="checkmarktxt">Special characters</span>
                      </li>
                      <li>
                        <span
                          className={
                            pwdPassword === true ? "active" : "checkmarkIcon"
                          }
                        >
                          <Checkmark />{" "}
                        </span>
                        <span className="checkmarktxt">
                          Password and confirm password match
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="changePassField">
                    <label htmlFor="">Confirm new password</label>
                    <input
                      type={confirmNewPassword ? "type" : "password"}
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          confirmNewPassword: e.target.value,
                        });

                        handleConfirmPwdValidation(e);
                      }}
                      autoComplete="off"
                    />
                    <button type="button" onClick={handleConfirmNewPassword}>
                      {confirmNewPassword ? <View /> : <ViewOff />}
                    </button>
                  </div>
                </div>

                {/* <span className="formErrors">{errorSpan}</span> */}
                <div className="formErrors">
                  {errorEnable &&
                  submittedData.password === "" &&
                  submittedData.confirmNewPassword === ""
                    ? "Password and confirm password required"
                    : errorEnable && submittedData.password === ""
                    ? "Password is required"
                    : errorEnable &&
                      submittedData.confirmNewPassword === "" &&
                      "Confirm password is required"}
                </div>
              </div>
            </div>
            <div className="btnwrp">
              <button type="button" className="btn-1" onClick={closeModal}>
                Cancel
              </button>
              <button type="button" className="btn-2" onClick={changePassword}>
                {loader ? <div uk-spinner="" className="loader"></div> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
