import { ChevronLeft } from "@carbon/icons-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { useAuth } from "../../Context/AuthProvider";
import { apiPost, apiGet } from "../../Utils/apiServices";
import { toast } from "react-toastify";
import { decryptedValue } from "../../Utils/RouteHashConversion";

const CreateTeam = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  var tags = [];

  const [submittedData, setSubmittedData] = useState({
    companyId: decryptedValue(auth.companyId),
    description: "",
    isActive: true,
    name: "",
  });
  const [errorEnable, setErrorEnable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [users, setusers] = useState(null);
  const [userAll, setUserAll] = useState(null);

  const createTeam = (e) => {
    e.preventDefault();
    setErrorEnable(true);
    setLoader(true);
    if (submittedData.name !== "") {
      apiPost(
        `/agent/create/team`,
        onSuccessTeam,
        onFailureTeam,
        submittedData
      );
    } else {
      setLoader(false);
    }
  };
  const onSuccessTeam = (response) => {
    setTimeout(() => {
      setLoader(false);
      toast.success("Team added successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }, 1000);
    setTimeout(() => {
      navigate("/teams", { replace: true });
    }, 2600);
  };
  const onFailureTeam = (error) => {
    console.log(error);
  };

  const getUsers = () => {
    apiGet(
      `/agent/list/AGENT`,
      onSuccessUsers,
      onFailureUsers
    );
  };
  const onSuccessUsers = (response) => {
    setusers(response?.data);
  };
  const onFailureUsers = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getUsers();
  }, []);

  users?.forEach((user) => {
    tags.push({ name: user.firstName + " " + user.lastName });
  });

  return (
    <div className="addTeamWrp">
      <div className="uk-container">
        <div className="backBtn">
          <Link to="/teams">
            <ChevronLeft /> Back to all teams
          </Link>
        </div>
        <div className="addTeamHeading">
          <h3>Create team</h3>
          <p>
            Enter details for the new team, and select team members from a list
            of users.
          </p>
        </div>
        <div className="addTeamBox">
          <div className="boxHeading">
            <h3>Team information</h3>
          </div>
          <div className="formwrp">
            <form onSubmit={createTeam}>
              <div className="uk-grid uk-grid-small" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="f_name">Team name</label>
                    <input
                      type="text"
                      className="uk-input"
                      placeholder="Team name"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          name: e.target.value,
                        });
                      }}
                    />
                    {errorEnable && submittedData.name === "" && (
                      <div className="formErrors"> Name is required</div>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="formInput">
                    <label htmlFor="desc" className="uk-flex">
                      Description <span>(optional)</span>
                    </label>
                    <textarea
                      name="desc"
                      className="uk-textarea"
                      placeholder="Description"
                      onChange={(e) => {
                        setSubmittedData({
                          ...submittedData,
                          description: e.target.value,
                        });
                      }}
                    ></textarea>
                    {/* {errorEnable && submittedData.description === "" && (
                      <div className="formErrors"> description is required</div>
                    )} */}
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="multiSelectWrp">
                    <label className="uk-form-label multiSelectLabel">
                      Add team members
                    </label>
                    <div className="customChipBox">
                      <Multiselect
                        id="saj"
                        options={tags}
                        // selectedValues={this.state.tags}
                        // onSelect={this.onSelectTags}
                        // onRemove={this.onRemoveTags}
                        displayValue="name"
                      />
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <div className="btnwrp">
                    {/* <button type="button" className="btn-1">
                      Cancel
                    </button> */}
                    <button type="submit" className="btn-2 w-70">
                      {loader ? (
                        <div uk-spinner="" className="loader"></div>
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTeam;
