import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { apiGet } from "../../../Utils/apiServices";
import { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import { useAuth } from "../../../Context/AuthProvider";
import { useWebSocket } from "../../../socket/WebSocketManager";
import siderbarLogo from "../../../assets/images/sidebar-logo.svg";
import {
  decryptedValue,
  encryptedValue,
} from "../../../Utils/RouteHashConversion";

const SidebarLayout = ({ userPermissions, setUserPermissions }) => {
  const { auth } = useAuth();
  const { socket } = useWebSocket();
  const { navigate } = useNavigate();

  const [notifications, setNotifications] = useState();
  const [messages, setMessages] = useState();
  // const [userPermissions, setUserPermissions] = useState([]);
  const [socketResponse, setsocketResponse] = useState();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [companyImage, setCompanyImage] = useState("");
  const [agentImage, setAgentImage] = useState("");
  const [companyDetails, setCompanyDetails] = useState();
  // this state is used to check if new notification occur then we can update contact list accordingly
  const [isNewNotificationAvailble, setIsNewNotificationAvailble] =
    useState(false);
  // here we can store msg come from the number for contact list
  const [receivedMsgNumber, setReceivedMsgNumber] = useState("");
  const getPermissions = () => {
    apiGet(`/auth/user-permission`, onSuccessPermissions, onFailurePermissions);
  };
  const onSuccessPermissions = (response) => {
    let permissions = [];
    
    response?.data?.map((val) => {
      permissions?.push(val?.permission?.name);
    });
    setUserPermissions(permissions);
  };
  const onFailurePermissions = (error) => {
    if (error) {
      // navigate(`/`, {
      //   replace: true,

      // })
      console.log(error);
    }
  };
  const getCompanyDetails = () => {
    const companyId = auth?.companyId;
    if (companyId) {
      apiGet(
        `/company/get`,
        onSuccessCompany,
        onFailureCompanys
      );
    }
  };
  const onSuccessCompany = (response) => {
    setCompanyDetails(response?.data);
    setCompanyImage(response?.data.logoUrl);
  };
  const onFailureCompanys = (error) => {
    console.log(error);
  };

  useEffect(() => {
    getPermissions();
    if (decryptedValue(localStorage.getItem("_role")) !== "ROLE_SUPER_ADMIN") {
      getCompanyDetails();
    }
  }, []);

  // useEffect(() => {
  //   const userRole = localStorage.getItem("_role");
  //   const userRoleDec = decryptedValue(userRole);
  //   if (userRoleDec !== "ROLE_SUPER_ADMIN") {
  //     getPermissions();
  //   }
  // }, []);
  function fetchChat(agentId) {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const data = {
        agentPublicId: agentId,
      };
      socket.send(JSON.stringify({ type: "update-chat-list", data: data }));
    }
  }
  
  useEffect(() => {
    if (socket) {
      // socket.onopen = () => {
      // };

      socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message !== null) {
          setsocketResponse(message);

          if (message.type === "auth-request") {
            sendAuthMessage();
          }
          if (message.type === "notification-response") {
            fetchChat(decryptedValue(auth.idNumber));
            if (
              message.data.notificationContent.includes(
                "You have received a new message from"
              )
            ) {
              let phoneNumber = message.data.notificationContent.replace(
                /You have received a new message from\s*/,
                ""
              );
              setReceivedMsgNumber(phoneNumber);
              setIsNewNotificationAvailble(true);
            }

            setNotifications(message.data);
          }
          if (message.type === "send-message-data-response") {
            setMessages(message.data);
          }
        }
      };

      // socket.onclose = () => {
      //   sendAuthMessage();
      //   // ("WebSocket connection closed");
      // };

      // socket.onerror = () => {
      //   // console.error("WebSocket error");
      // };
    }

    // Cleanup when the component unmounts
    // return () => {
    //   if (socket) {
    //     sendAuthMessage();
    //     socket.close();
    //   }
    // };
  }, [socket]);

  function sendAuthMessage() {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const data = {
        username: decryptedValue(auth.email),
        password: auth.token,
      };
      socket.send(JSON.stringify({ type: "auth", data: data }));
    }
  }
  const onCancel = () => {
    localStorage.setItem("_notify", "false");
    notifyFalse();
  };
  const onAllow = () => {
    localStorage.setItem("_notify", "true");
    notifyTrue();
  };

  const notifyTrue = () => {
    if (localStorage.getItem("_notify", "true")) {
      document.querySelector(".notify-alert-box").style.display = "none";
    }
  };

  const notifyFalse = () => {
    if (localStorage.getItem("_notify", "false")) {
      document.querySelector(".notify-alert-box").style.display = "none";
    }
  };
  if (localStorage.getItem("_token") !== null) {
    if (localStorage.getItem("_notify") === null) {
      notifyTrue();
      notifyFalse();
    }
  }
  return (
    <>
      <Sidebar
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        setCompanyImage={setCompanyImage}
        companyImage={companyImage}
        setAgentImage={setAgentImage}
        agentImage={agentImage}
        userPermissions={userPermissions}
        notifications={notifications}
        companyDetails={companyDetails}
        socketResponse={socketResponse}
        // comapnyLogo={comapnyLogo}
      />
      <Outlet
        context={[
          selectedStatus,
          setSelectedStatus,
          companyImage,
          setCompanyImage,
          agentImage,
          setAgentImage,
          setCompanyDetails,
          companyDetails,
          userPermissions,
          socketResponse,
          notifications,
          messages,
          setIsNewNotificationAvailble,
          isNewNotificationAvailble,
          setReceivedMsgNumber,
          receivedMsgNumber,
        ]}
      />

      {decryptedValue(localStorage.getItem("_role")) !== "ROLE_SUPER_ADMIN" &&
        localStorage.getItem("_notify") == null && (
          <div className="notify-alert-box" id="notifyPermission">
            <img src={siderbarLogo} />
            <p>We'd like to send you notifications of the latest live chat.</p>
            <div className="buttons">
              <button onClick={onCancel}>Cancel</button>
              <button onClick={onAllow}>Allow</button>
            </div>
          </div>
        )}
    </>
  );
};

export default SidebarLayout;
